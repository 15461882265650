import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ReactiveFormCheckboxComponent, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-list-item-checkbox',
  templateUrl: './list-item-checkbox.component.html',
  styleUrls: ['./list-item-checkbox.component.scss'],
  providers: [
    { provide: ReactiveFormItemComponent, useExisting: forwardRef(() => ListItemCheckboxComponent), },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemCheckboxComponent extends ReactiveFormCheckboxComponent {

}
