import { Deserializable } from './deserializable';
import { SaleSystemLabel } from '../shared/labels/sale-system-label';
import { FeaturedSystemLabel } from '../shared/labels/featured-system-label';
import { NewSystemLabel } from '../shared/labels/new-system-label';
import { Label } from '../shared/label';
import { LowStockSystemLabel } from '../shared/labels/low-stock-system-label';
import { RestockSystemLabel } from '../shared/labels/restock-system-label';
import { Type } from '@angular/core';
import { MenuLabel } from '../utils/dto/menu-label-type';

export class DeserializeLabelHelper {

  static getPolymorphicLabelObject<T extends Deserializable>(label: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), label);
    };
    switch (label?.id) {
      case MenuLabel.Featured:  return createPolymorphicObject(FeaturedSystemLabel);
      case MenuLabel.LowStock:  return createPolymorphicObject(LowStockSystemLabel);
      case MenuLabel.Restocked: return createPolymorphicObject(RestockSystemLabel);
      case MenuLabel.Sale:      return createPolymorphicObject(SaleSystemLabel);
      case MenuLabel.New:       return createPolymorphicObject(NewSystemLabel);
      default:                  return createPolymorphicObject(Label);
    }
  }

}
