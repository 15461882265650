import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Breadcrumb } from '../../../../../models/shared/stylesheet/breadcrumb';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';

@Component({
  selector: 'app-edit-collection-header',
  templateUrl: './edit-collection-header.component.html',
  styleUrls: [
    './edit-collection-header.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionHeaderComponent extends BaseComponent {

  @Input() collection: TemplateCollection;
  @Input() crumbs: Breadcrumb[];
  @Input() disablePublish: boolean;
  @Input() publishButtonDisabledText: string;
  @Input() headerText: string;
  @Input() headerSubtext: string;
  @Input() disableSave: boolean;
  @Input() formDisableSubmit: boolean;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() lastSavedTimeText: string;
  @Input() mergeKey: string;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() formPristine: boolean = true;
  @Output() publishCollection = new EventEmitter();
  @Output() submitForms = new EventEmitter();
  @Output() showLiveView = new EventEmitter();

}
