import { Menu } from '../../menu/dto/menu';
import { DateUtils } from '../../../utils/date-utils';
import { Orientation } from '../../utils/dto/orientation-type';
import { Location } from '../../company/dto/location';
import { BaseDisplay } from './base-display';
import { TemplateCollection } from '../../template/dto/template-collection';
import { DisplayableItem } from '../../../views/shared/components/displayable-content/displayable-item-container/displayable-item-preview/displayable-item';
import { DisplayableSubItem } from '../../shared/displayable-subitem';
import { PendingDisplay } from '../../template/dto/pending-display';
import { PagableObject } from '../../protocols/pagableObject';
import { SortUtils } from '../../../utils/sort-utils';

export type DisplayByLocationAndProvince = {
  provinces: {
    name: string;
    locations: LocationDisplayGrouping[];
  }[];
};

export type LocationDisplayGrouping = {
  location: Location;
  displays: Display[];
};

export class Display extends BaseDisplay implements DisplayableItem, PagableObject {

  public locationId: number;
  public url: string;
  public shortUrl: string;
  public configurationIds: string[];
  public configurations: Menu[];
  public lastSession: number;
  public templateCollectionIds: string[];
  public templateCollections: TemplateCollection[];
  public pagingKey: string;
  // Cache
  public cachedTime: number;

  static getDisplaysFromIds(ids: string[], displayList: Display[]): Display[] {
    return ids?.map(id => displayList?.find((d) => d?.id === id))?.filterNulls() ?? [];
  }

  static groupDisplaysByLocationAndProvince(
    displays: Display[],
    allLocations: Location[]
  ): DisplayByLocationAndProvince {
    const locations = allLocations
      ?.filter((location) => displays?.some(display => display?.locationId === location?.id))
      ?.sort((a, b) => a?.name?.localeCompare(b?.name));
    const provinces = locations
      ?.map((l) => l?.state || Location.incompleteLocationGroupName)
      ?.unique()
      ?.sort(SortUtils.sortSpecifiedStringLast(Location.incompleteLocationGroupName));
    const result: DisplayByLocationAndProvince = {provinces: []};
    provinces?.forEach((p) => result.provinces.push({name: p, locations: []}));
    locations?.forEach((l) => {
      result.provinces.find((p) => {
        return p?.name === Location.incompleteLocationGroupName ? !l?.state : p?.name === l?.state;
      })?.locations?.push({
        location: l,
        displays: displays?.filter((d) => d?.locationId === l?.id)?.sort((a, b) => a?.name?.localeCompare(b?.name))
      });
    });
    return result;
  }

  static createDisplaysFromPendingDisplay(pendingDisplay: PendingDisplay): Display[] {
    return pendingDisplay?.locationIds?.map((id) => {
      const data = {
        displaySize: pendingDisplay?.displaySize,
        locationId: id,
        name: pendingDisplay?.name,
      };
      return window?.injector?.Deserialize.instanceOf(Display, data);
    });
  }

  public override onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    this.configurationIds = Array.from(this.configurationIds || []);
    this.configurations = Deserialize?.arrayOf(Menu, this.configurations) ?? [];
    this.templateCollectionIds = Array.from(this.templateCollectionIds || []);
    this.templateCollections = Deserialize?.arrayOf(TemplateCollection, this.templateCollections) ?? [];
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/DisplayDTO.go
  public override onSerialize(): Display {
    const dto = Object.assign(new Display(), super.onSerialize());
    dto.configurationIds = this.configurationIds;
    dto.lastSession = this.lastSession;
    dto.locationId = this.locationId;
    dto.templateCollectionIds = this.templateCollectionIds;
    return dto;
  }

  static buildArrayCacheKey(companyId, locationId: number): string {
    return `Displays-${companyId}-${locationId}`;
  }

  static buildCacheKey(companyId, locationId: number, displayId: string): string {
    return `Display-${companyId}-${locationId}-${displayId}`;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(companyId: number): string {
    return Display.buildCacheKey(companyId, this.locationId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  public isLandscape(): boolean {
    return this.displaySize?.orientation === Orientation.Landscape;
  }

  public isPortrait(): boolean {
    const portrait = this.displaySize?.orientation === Orientation.Portrait;
    const reversePortrait = this.displaySize?.orientation === Orientation.ReversePortrait;
    return portrait || reversePortrait;
  }

  public updateMenu(updatedMenu: Menu, removeItems: boolean): void {
    const updatedMenus = this.configurations?.shallowCopy() ?? [];
    const index = updatedMenus?.findIndex(c => c?.id === updatedMenu?.id);
    if (removeItems && index > -1) {
      updatedMenus?.splice(index, 1);
    } else if (index > -1) {
      updatedMenus[index] = updatedMenu;
    }
    this.configurations = updatedMenus;
    this.configurationIds = updatedMenus?.map(c => c.id);
  }

  public updateTemplateCollections(updatedCollections: TemplateCollection[], removeItems: boolean): void {
    this.templateCollections = this.templateCollections?.shallowCopy() ?? [];
    this.templateCollectionIds = this.templateCollectionIds?.shallowCopy() ?? [];
    updatedCollections?.forEach(collection => {
      const index = this.templateCollections?.findIndex(c => c?.id === collection?.id);
      if (removeItems && index > -1) {
        this.templateCollectionIds?.remove(collection?.id);
        this.templateCollections?.splice(index, 1);
      } else if (index > -1) {
        this.templateCollections?.splice(index, 1, collection);
      } else if (this.templateCollectionIds?.includes(collection?.id)) {
        this.templateCollections?.push(collection);
      }
    });
  }

  getMenus(): Menu[] {
    return this.configurations ?? [];
  }

  getAddedMenuAndTemplateCollectionCount(): number {
    return (this.configurationIds?.length ?? 0) + (this.templateCollectionIds?.length);
  }

  /**
   * Wait until consolidation is complete before returning a typed deserialized object.
   *
   * @param data - array of paginated JSON data
   */
  consolidatePagedData(data: any[]): Display {
    const main = data?.firstOrNull();
    const remainingPages = data?.slice(1);
    remainingPages?.forEach(fragment => {
      main.configurations = (main.configurations ?? [])?.concat((fragment?.configurations ?? []));
      main.templateCollections = (main.templateCollections ?? [])?.concat((fragment?.templateCollections ?? []));
    });
    return window.injector.Deserialize.instanceOf(Display, main);
  }

  isFirstItemInRotation(menuId: string): boolean {
    const order: [string, number][] = [];
    this.options?.rotationOrder?.forEach((priority, id) => order.push([id, priority]));
    order?.sort(([idA, priorityA], [idB, priorityB]) => priorityA - priorityB);
    const [firstMenuId] = order?.firstOrNull() || [null, null];
    return firstMenuId === menuId;
  }

  getOrderedContent(): Menu[] {
    return [...(this.configurations || []), ...(this.templateCollections || [])]
      ?.sort((a, b) => {
        const aPriority = this.options?.rotationOrder?.get(a?.id);
        const bPriority = this.options?.rotationOrder?.get(b?.id);
        return aPriority - bPriority;
      })
      ?.map(item => {
        return item instanceof TemplateCollection
          ? item?.getActiveTemplatesAtLocation(this.locationId) ?? []
          : item;
      })
      ?.flatten<Menu[]>()
      ?? [];
  }

  getOrderedContentIds(): string[] {
    return this.getOrderedContent()?.map(item => item?.id) ?? [];
  }

  /* *************************** Displayable Item Interface *************************** */

  active: boolean;
  tag: string;

  displayableItemPreviewContentIds(locationId: number): string[] {
    return this.getOrderedContentIds();
  }

  displayableItemShouldShowSeeMoreCard(): boolean {
    return false;
  }

  displayableItemTotalCount(): number {
    return this.getOrderedContentIds()?.length ?? 0;
  }

  displayableItemContainsStackedContent(): boolean {
    return false;
  }

  displayableItemActiveText(): string {
    return '';
  }

  displayableItemDeployedCount(): number {
    return 0;
  }

  displayableItemDeployedCountIcon(): string {
    return '';
  }

  displayableItemDeployedCountTooltipText(): string {
    return '';
  }

  displayableItemHasSmartFilters(): boolean {
    return false;
  }

  displayableItemInactiveText(): string {
    return '';
  }

  displayableItemIsActive(): boolean {
    return false;
  }

  displayableItemShowActiveBadge(): boolean {
    return false;
  }

  displayableItemShowDeployedCountIndicator(): boolean {
    return false;
  }

  displayableItemSmartFilterIndicatorTooltip(): string {
    return '';
  }

  displayableItemSubItemList(): DisplayableSubItem[] {
    const menuIcon = 'assets/icons/dark/outline/document-text.svg';
    const templateIcon = 'assets/icons/dark/solid/template.svg';
    const collectionIcon = 'assets/icons/dark/outline/template-collection.svg';
    const subItemArray: DisplayableSubItem[] = [];
    this.configurations?.forEach(s => {
      const configIcon = !!s.templateId ? templateIcon : menuIcon;
      subItemArray.push(new DisplayableSubItem(s?.name, configIcon, s?.id));
    });
    this.templateCollections?.forEach(tc => {
      subItemArray.push(new DisplayableSubItem(tc?.name, collectionIcon, tc?.id));
    });
    const displayPrios = this.options?.rotationOrder;
    subItemArray.sort((a, b) => displayPrios?.get(a?.id) - displayPrios?.get(b?.id));
    if (subItemArray?.length > 5) {
      const andMoreItem = new DisplayableSubItem(`+${subItemArray.length - 5} more`, null);
      subItemArray.splice(5, subItemArray.length - 5);
      subItemArray.push(andMoreItem);
    }
    return subItemArray;
  }

  displayableItemSubtitle(): string {
    return 'Menus';
  }

  displayableItemIsTemplatedMenu(): boolean {
    return false;
  }

  /* ********************************************************************************** */

  override getUniqueIdentifier(...opts: any): string {
    return super.getUniqueIdentifier() + `-
      ${this.locationId}-
      ${this.url}-
      ${this.shortUrl}-
      ${this.configurationIds?.join(',')}-
      ${this.configurations?.map(c => c.getUniqueIdentifier())?.join(',')}-
      ${this.lastSession}
    `;
  }

}
