<div class="row menu-section-smart-filters-container">
  <app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"> </app-loading>

  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Smart Filters</p>
    <p class="bs-edit-section-description">
      Quickly add products to menus with smart filters instead of adding products one-by-one.
    </p>
    <div fxLayout="column" [style.gap.rem]="'0.5'">
      <lib-button-primary
        *ngIf="(viewModel.hasSmartFilters$ | push) && !(viewModel.isPrintReportMenu$ | push)"
        [disabled]="(viewModel.isTemplatedSection$ | push) || !formPristine"
        [disabledTooltip]="
          (viewModel.isTemplatedSection$ | push | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        (buttonClicked)="viewModel.addSmartFilters()"
        style="align-self: flex-start">
        Add Smart Filter
      </lib-button-primary>
      <app-smart-filter-large-sync-button
        *rxIf="(viewModel.hasSmartFilters$ | push) && !templateMode"
        appThrottleClick
        [syncing]="viewModel.section$ | isSectionSyncingSmartFilters | push"
        [disabled]="templateMode || !formPristine"
        [ngbTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="viewModel.syncSmartFilters()">
      </app-smart-filter-large-sync-button>
      <lib-reactive-form-group
        *rxIf="(viewModel.hasSmartFilters$ | push) && (viewModel.section$ | push)?.getLayoutType()?.isAnyGrid()"
        [bindTo]="viewModel.section$ | push"
        [mergeKey]="mergeKey"
        style="display: flex">
        <lib-reactive-form-switch
          class="reverse-row-switch"
          [label]="'Auto-Update Grid Columns'"
          [bindingProperty]="'autoUpdateGridColumns'"
          [inputName]="'Auto-Update Grid Columns'"
          [disabledTooltip]="autoSaving ? null : 'This property may only be edited on the template'"
          [disabled]="autoSaving || (viewModel.isTemplatedSection$ | push)">
        </lib-reactive-form-switch>
      </lib-reactive-form-group>
    </div>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8">
    <div *rxIf="!(viewModel.hasSmartFilters$ | push)">
      <div class="bs-edit-section-title">Any smart filters added to this section will show up here.</div>
      <lib-button-primary
        [disabled]="(viewModel.isTemplatedSection$ | push) || !formPristine"
        [disabledTooltip]="
          (viewModel.isTemplatedSection$ | push | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        (buttonClicked)="viewModel.addSmartFilters()"
        class="mt-16px">
        Add Smart Filter
      </lib-button-primary>
    </div>

    <app-smart-filter-added-to-section
      *ngFor="let smartFilterId of viewModel.addedSmartFilterIds$ | push; let last = last; let index = index"
      [autoSaving]="autoSaving"
      [syncing]="viewModel.section$ | isSectionSyncingSmartFilters | push"
      [index]="index"
      [last]="last"
      [menu]="menu"
      [section]="section"
      [templateMode]="templateMode"
      [smartFilterId]="smartFilterId"
      [formPristine]="formPristine">
    </app-smart-filter-added-to-section>
  </div>
</div>
<hr class="mt-24px" />
