import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { TimeUnit } from '../../../../../../models/enum/shared/time-unit.enum';
import { SegmentedControlOption } from '../../../../../../models/shared/stylesheet/segmented-control-option';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { DateUtils } from '../../../../../../utils/date-utils';

export const INITIAL_STARTING_SINCE_VALUE_DAYS = 7;
export const MAX_STARTING_SINCE_VALUE_DAYS = 90;

// Provided by Logged In Scope
@Injectable()
export class ShowInventorySinceViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _unitOfMeasure = new BehaviorSubject<TimeUnit>(TimeUnit.Days);
  public readonly unitOfMeasure$ = this._unitOfMeasure.pipe(distinctUntilChanged());
  public readonly timeUnitOptions$ = of(ShowInventorySinceViewModel.getTimeUnitOptions());

  private _newInventorySince = new BehaviorSubject<number>(INITIAL_STARTING_SINCE_VALUE_DAYS);
  private readonly _changeInventorySince = new BehaviorSubject<number>(7);
  public readonly changeInventorySince$ = this._changeInventorySince as Observable<number>;
  public readonly newInventorySince$ = this.unitOfMeasure$.pipe(this.unitOfMeasureCorrection());
  public readonly showNewInventorySincePlaceHolder$ = this.unitOfMeasure$.pipe(this.timePlaceHolder());
  public readonly lengthInSeconds$ = combineLatest([
    this.unitOfMeasure$,
    this.newInventorySince$
  ]).pipe(map(ShowInventorySinceViewModel.getLengthInSeconds));

  public readonly inventorySinceLabel$ = of('Show New Inventory From the Last');
  public readonly showNewInventorySinceMaxValue$ = this.unitOfMeasure$.pipe(ShowInventorySinceViewModel.maxValue());
  public readonly maxValueErrorMessage$ = this.unitOfMeasure$.pipe(ShowInventorySinceViewModel.errorMessage());

  private static getLengthInSeconds([unitOfMeasure, newInventorySince]): number {
    switch (unitOfMeasure) {
      case TimeUnit.Days:  return DateUtils.daysToSeconds(newInventorySince);
      case TimeUnit.Hours: return DateUtils.hoursToSeconds(newInventorySince);
      default:             return 0;
    }
  }

  private static getMaxValueForUnitOfMeasure(unitOfMeasure: TimeUnit): number {
    switch (unitOfMeasure) {
      case TimeUnit.Days:  return MAX_STARTING_SINCE_VALUE_DAYS;
      case TimeUnit.Hours: return 24;
    }
  }

  private static getPlaceHolderText(unitOfMeasure: TimeUnit): string {
    switch (unitOfMeasure) {
      case TimeUnit.Days:  return 'Enter days here';
      case TimeUnit.Hours: return 'Enter hours here';
    }
  }

  private static getMaxValueErrorMessage(unitOfMeasure: TimeUnit): Map<string, string> {
    const errorMap = new Map<string, string>([
      ['integersOnly', 'Positive numbers only'],
      ['min', 'Minimum value is 1'],
    ]);
    const maxErrMessage = `Maximum is ${MAX_STARTING_SINCE_VALUE_DAYS} days`;
    switch (unitOfMeasure) {
      case TimeUnit.Days:  errorMap.set('max', maxErrMessage);  break;
      case TimeUnit.Hours: errorMap.set('max', 'Maximum is 24 hours'); break;
    }
    return errorMap;
  }

  private static getAdjustedTimeRangeForChangeUnitChange(unitOfMeasure: TimeUnit, newInventorySince: number): number {
    switch (unitOfMeasure) {
      case TimeUnit.Days: {
        if (newInventorySince <= -1) return 0;
        if (newInventorySince > MAX_STARTING_SINCE_VALUE_DAYS) {
          return MAX_STARTING_SINCE_VALUE_DAYS;
        }
        if (!newInventorySince)      return null;
        else                         return newInventorySince;
      }
      case TimeUnit.Hours: {
        if (newInventorySince <= -1) return 0;
        if (newInventorySince > 24)  return 24;
        if (!newInventorySince)      return null;
        else                         return newInventorySince;
      }
    }
    return newInventorySince;
  }

  private static getAdjustedTimeRange(unitOfMeasure: TimeUnit, newInventorySince: number): number {
    switch (unitOfMeasure) {
      case TimeUnit.Days: {
        return (!newInventorySince || newInventorySince <= -1 || newInventorySince > MAX_STARTING_SINCE_VALUE_DAYS)
          ? 0
          : newInventorySince;
      }
      case TimeUnit.Hours: {
        return (!newInventorySince || newInventorySince <= -1 || newInventorySince > 24)
          ? 0
          : newInventorySince;
      }
    }
    return newInventorySince;
  }

  private static filterOutInvalidTimeRange(unitOfMeasure: TimeUnit, newInventorySince: number): boolean {
    switch (unitOfMeasure) {
      case TimeUnit.Days:
        return newInventorySince === null
            || (newInventorySince > -1 && newInventorySince <= MAX_STARTING_SINCE_VALUE_DAYS);
      case TimeUnit.Hours:
        return newInventorySince === null || (newInventorySince > -1 && newInventorySince <= 24);
    }
  }

  // Initializers

  private static getTimeUnitOptions(): SegmentedControlOption[] {
    const options = [
      new SegmentedControlOption('Days', TimeUnit.Days),
      new SegmentedControlOption('Hours', TimeUnit.Hours)
    ];
    options.firstOrNull().selected = true;
    return options;
  }

  // Operators

  private static maxValue(): OperatorFunction<TimeUnit, number> {
    return map(ShowInventorySinceViewModel.getMaxValueForUnitOfMeasure);
  }

  private static errorMessage(): OperatorFunction<TimeUnit, Map<string, string>> {
    return map(ShowInventorySinceViewModel.getMaxValueErrorMessage);
  }

  // If you use functions and not arrow-functions here, then 'this' inside newInventorySinceCorrectionPipe
  // will be globally scoped, and not set to ShowInventorySinceViewModel. To fix this, we use
  // the bind() method to set the 'this' to the ShowInventorySinceViewModel. This is a common
  // gotcha for new javascript developers. Watch out for it!
  private unitOfMeasureCorrection(): OperatorFunction<TimeUnit, any> {
    return switchMap(this.newInventorySinceCorrectionPipe.bind(this));
  }

  private timePlaceHolder(): OperatorFunction<TimeUnit, string> {
    return map(units => ShowInventorySinceViewModel.getPlaceHolderText(units));
  }

  // Connectors

  public connectToUnitOfMeasure = (sc: SegmentedControlOption[]) => this._unitOfMeasure.next(sc?.firstOrNull()?.value);
  public connectToLengthInSeconds = (lengthInSeconds: number) => this._newInventorySince.next(lengthInSeconds);

  // Couplers

  private newInventorySinceCorrectionPipe(units: TimeUnit): Observable<number> {
    const currentValue = this._newInventorySince.getValue();
    const adjusted = ShowInventorySinceViewModel.getAdjustedTimeRangeForChangeUnitChange(units, currentValue);
    if (currentValue !== adjusted) this._changeInventorySince.next(adjusted);
    else this._changeInventorySince.next(currentValue);
    return this._newInventorySince.pipe(
      distinctUntilChanged(),
      map(newInventorySince => ShowInventorySinceViewModel.getAdjustedTimeRange(units, newInventorySince)),
      filter(newInventorySince => {
        return ShowInventorySinceViewModel.filterOutInvalidTimeRange(units, newInventorySince);
      })
    );
  }

}
