import { ChangeDetectionStrategy, Component, Injector, ViewChild } from '@angular/core';
import { EditCardStackMenuViewModel } from './edit-card-stack-menu-view-model';
import { EditCardStackMenuCombinedLoadingOptsViewModel } from './edit-card-stack-menu-combined-loading-opts-view-model';
import { EditCardStackSectionViewModel } from './edit-card-stack-section-view-model';
import { EditStaticSectionMenuComponent } from '../shared/edit-static-section-menu/edit-static-section-menu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../services/toast-service';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-edit-card-stack-menu',
  templateUrl: './edit-card-stack-menu.component.html',
  styleUrls: [
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [
    EditCardStackMenuViewModel,
    EditCardStackSectionViewModel,
    EditCardStackMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackMenuComponent extends EditStaticSectionMenuComponent {

  constructor(
    public override viewModel: EditCardStackMenuViewModel,
    public override sectionViewModel: EditCardStackSectionViewModel,
    public override sharedLoadingState: EditCardStackMenuCombinedLoadingOptsViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngbModal: NgbModal,
    injector: Injector,
    sanitizer: DomSanitizer,
    toastService: ToastService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      viewModel,
      sectionViewModel,
      sharedLoadingState,
      activatedRoute,
      router,
      ngbModal,
      injector,
      sanitizer,
      toastService,
      activeRoute
    );
  }

  @ViewChild(ReactiveFormMergeGroupsComponent) override formCapture: ReactiveFormMergeGroupsComponent;

}
