import { Deserializable } from '../protocols/deserializable';
import { Asset } from '../image/dto/asset';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class SmartFilterCategory implements Deserializable, UniquelyIdentifiable {

  public locationId: number;
  public id: string;
  public name: string;
  public image: Asset;
  public priority: number;

  onDeserialize() {
    this.image = window?.injector?.Deserialize?.instanceOf(Asset, this.image);
  }

  getUniqueIdentifier(): string {
    return `
      -${this.locationId}
      -${this.id}
      -${this.name}
      -${this.image?.md5Hash}
      -${this.priority}
    `;
  }

}
