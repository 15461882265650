import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-print-job-header',
  templateUrl: './print-job-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobHeaderComponent {

  @Input() title: string;
  @Input() showBottomBorder: boolean = false;

}
