import { Pipe, PipeTransform } from '@angular/core';
import { CuratedVariantBadgeSection } from 'src/app/models/product/dto/curated-variant-badge-section';

@Pipe({
  name: 'countBadges'
})
export class CountBadgesPipe implements PipeTransform {

  transform(value: CuratedVariantBadgeSection): number {
    return value?.badgeCount() || 0;
  }

}
