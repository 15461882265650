import { Selectable } from '../../protocols/selectable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class Tag implements Selectable, UniquelyIdentifiable {

  public title: string;
  public value: string;

  constructor(value: string) {
    this.title = value?.toUpperCase();
    this.value = value;
  }

  static placeholderTag(title: string): Tag {
    const t = new Tag(null);
    t.title = title;
    return t;
  }

  getSelectionTitle(): string {
    return this.title || this.value;
  }

  getSelectionValue(): any {
    return this.value;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }

  getUniqueIdentifier(): string {
    return this.value;
  }

}
