<div
  matRipple
  fxLayout="row"
  fxLayoutAlign="center center"
  class="smart-filter-indicator-container"
  [style.height]="height"
  [style.width]="width"
  [style.backgroundColor]="backgroundColor">
  <img
    class="smart-filter-indicator"
    alt="added by smart filter icon"
    [src]="'assets/icons/light/outline/lightning-bolt.svg'"
    [style.transform]="'scale(' + iconScale + ')'" />
</div>
