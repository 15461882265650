import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuDetailsSectionComponent } from '../../shared/menu-section-details/menu-details-section.component';
import { CardStackDetailsSectionViewModel } from './card-stack-details-section-view-model';

@Component({
  selector: 'app-card-stack-details-section',
  templateUrl: './card-stack-details-section.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  providers: [CardStackDetailsSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackDetailsSectionComponent extends MenuDetailsSectionComponent {

  constructor(public override viewModel: CardStackDetailsSectionViewModel) {
    super(viewModel);
  }

}
