import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComponentCanDeactivate } from '../../../../models/protocols/component-can-deactivate';
import { EditTemplateMenuSectionViewModel } from './edit-template-menu-section-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { EditMenuSectionViewModel } from '../../../menu/viewModels/edit-menu-section-view-model';
import { EditTemplateMenuSectionCombinedLoadingOptsViewModel } from './edit-template-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-template-menu-section',
  templateUrl: './edit-template-menu-section.component.html',
  styleUrls: [
    './edit-template-menu-section.component.scss',
    '../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [
    EditTemplateMenuSectionViewModel,
    EditMenuSectionViewModel,
    EditTemplateMenuSectionCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMenuSectionComponent extends BaseComponent implements ComponentCanDeactivate {

  constructor(
    public viewModel: EditTemplateMenuSectionViewModel,
    public combinedLoadingOptsViewModel: EditTemplateMenuSectionCombinedLoadingOptsViewModel
  ) {
    super();
  }

  @Input() dispersedAndMergeKey = 'editTemplatedSection';

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

}
