import { Pipe, PipeTransform } from '@angular/core';
import { SmartFilterGrouping } from '../../../models/automation/smart-filter-grouping';

@Pipe({
  name: 'castAsSmartFilterGrouping'
})
export class CastAsSmartFilterGroupingPipe implements PipeTransform {

  transform(value: any): SmartFilterGrouping|null {
    return (value instanceof SmartFilterGrouping) ? value : null;
  }

}
