import { Component, Injector } from '@angular/core';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionService } from '../../../../../../services/action.service';
import { WebMenusContainerViewModel } from './web-menus-container-view-model';
import { DisplayableContentContainerComponent } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component';
import { DisplayableContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container-view-model';

@Component({
  selector: 'app-web-menus-container',
  templateUrl: '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component.html',
  styleUrls: [
    '../../../../../shared/components/displayable-content/displayable-content-container/displayable-container.component.scss',
    './web-menus-container.component.scss'
  ],
  providers: [{ provide: DisplayableContentContainerViewModel, useExisting: WebMenusContainerViewModel }]
})
export class WebMenusContainerComponent extends DisplayableContentContainerComponent {

  constructor(
    public override viewModel: WebMenusContainerViewModel, // Provided by Logged In Scope
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector,
    actionService: ActionService
  ) {
    super(viewModel, navigationService, ngbModal, injector, actionService);
  }

}
