import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { Menu } from '../models/menu/dto/menu';
import { ModalUtils } from '../utils/modal-utils';
import { PrintMenuLiveViewModalComponent } from '../views/live-view/print-menu-live-view-modal/print-menu-live-view-modal.component';

export class ModalPrintMenuLiveView {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    printMenu: Menu
  ) {
    const modalRef = ngbModal.open(
      PrintMenuLiveViewModalComponent,
      ModalUtils.printMenuLiveViewOptions(injector, printMenu?.displaySize?.orientation)
    );
    const ref = (modalRef.componentInstance as PrintMenuLiveViewModalComponent);
    ref.viewModel.connectToPrintMenu(printMenu);
  }

}
