import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditLabelStackMenuCombinedLoadingOptsViewModel } from '../edit-label-stack-menu-combined-loading-opts-view-model';
import { EditCardStackMenuBodyComponent } from '../../edit-card-stack-menu/edit-card-stack-menu-body/edit-card-stack-menu-body.component';
import { EditLabelStackMenuViewModel } from '../edit-label-stack-menu-view-model';
import { EditLabelStackSectionViewModel } from '../edit-label-stack-section-view-model';

@Component({
  selector: 'app-edit-label-stack-menu-body',
  templateUrl: './edit-label-stack-menu-body.component.html',
  providers: [EditLabelStackMenuCombinedLoadingOptsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditLabelStackMenuBodyComponent extends EditCardStackMenuBodyComponent {

  constructor(
    public override viewModel: EditLabelStackMenuViewModel,
    public override sectionViewModel: EditLabelStackSectionViewModel,
    public override sharedLoadingState: EditLabelStackMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, sectionViewModel, sharedLoadingState);
  }

  override setupBindings = (): void => {};
  override setupViews = (): void => {};

}
