import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { CompactMenu } from '../../../../../../models/menu/dto/compact-menu';
import { DisplayMenuSectionBodyViewModel } from './display-menu-section-body-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-display-menu-section-body',
  templateUrl: './display-menu-section-body.component.html',
  styleUrls: ['./display-menu-section-body.component.scss'],
  providers: [DisplayMenuSectionBodyViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionBodyComponent extends BaseComponent {

  constructor(
    public viewModel: DisplayMenuSectionBodyViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToCompactMenu') menu: CompactMenu;
  @Input() productOverflowTitle: string;
  @Input() mergeKey: string;
  @Input() @ViewModelConnector('connectToBindTo') bindTo: any;
  @Input() loopDuration: number;
  @Input() nMenus: number;
  @Input() index: number;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToMenuIsPartOfCollection') menuIsPartOfCollection = false;
  @Output() openInformationModal = new EventEmitter();

}
