import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';

@Component({
  selector: 'app-display-menu-section-header-container',
  templateUrl: './display-menu-section-header-container.component.html',
  styleUrls: ['./display-menu-section-header-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionHeaderContainerComponent extends BaseComponent {

  @Input() menuWarningTooltip: string;

}
