import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditDriveThruMenuViewModel } from '../edit-drive-thru-menu-view-model';
import { EditMarketingMenuBodyComponent } from '../../edit-marketing-menu-body/edit-marketing-menu-body.component';

@Component({
  selector: 'app-edit-drive-thru-menu-body',
  templateUrl: './edit-drive-thru-menu-body.component.html',
  styleUrls: ['./edit-drive-thru-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDriveThruMenuBodyComponent extends EditMarketingMenuBodyComponent {

  constructor(
    public override viewModel: EditDriveThruMenuViewModel, // provided by parent component
  ) {
    super(viewModel);
  }

}
