<div class="form-container">
  <div class="label-preview-container mt-4">
    <app-label-inflator
      [zoom]="1"
      [displayLabelInterface]="
        viewModel.labelPreview$
          | async
          | getDisplayLabelInterfaceForCreateCustomLabel : viewModel.labelComputationData$
          | async
      ">
    </app-label-inflator>
  </div>

  <lib-reactive-form-group [bindTo]="viewModel.initialLabel" [mergeKey]="'addNewLabelFormCapture'">
    <lib-reactive-form-text
      [inputName]="'labelName'"
      [label]="'Label Text'"
      [placeholder]="'Enter label text'"
      [bindingProperty]="'text'"
      [required]="true"
      [maxLength]="30"
      [textTransform]="ClientTypes.TextTransform.Uppercase"
      (valueChanged)="viewModel.labelPropertyChanged($event, ClientTypes.EditLabelPropertyKey.LabelText)">
    </lib-reactive-form-text>

    <lib-reactive-form-column-layout nColumns="2">
      <lib-reactive-form-color-square
        [inputName]="'labelColor'"
        [label]="'Label Color'"
        [bindingProperty]="'color'"
        [required]="true"
        [clearable]="false"
        [presetColors]="viewModel.colorPalette$ | async"
        (valueChanged)="viewModel.labelPropertyChanged($event, ClientTypes.EditLabelPropertyKey.LabelColor)">
      </lib-reactive-form-color-square>
      <lib-reactive-form-color-square
        [inputName]="'labelTextColor'"
        [label]="'Label Text Color'"
        [bindingProperty]="'textColor'"
        [required]="true"
        [clearable]="false"
        [presetColors]="viewModel.colorPalette$ | async"
        (valueChanged)="viewModel.labelPropertyChanged($event, ClientTypes.EditLabelPropertyKey.LabelTextColor)">
      </lib-reactive-form-color-square>
    </lib-reactive-form-column-layout>

    <ng-container *ngIf="viewModel.allowedToCreateSmartLabel$ | async">
      <app-reactive-form-smart-filter-list
        #smartFilters
        [inputName]="'smartFilterIds'"
        [bindingProperty]="'smartFilterIds'">
      </app-reactive-form-smart-filter-list>
      <lib-reactive-form-switch
        *ngIf="viewModel.allowedToCreateSmartLabel$ | async"
        class="remove-existing-products-switch"
        [class.disabled]="!(smartFilters.smartFilterIds$ | async)?.length"
        [disabled]="!(smartFilters.smartFilterIds$ | async)?.length"
        [inputName]="'removeExistingProductsSwitch'"
        [bindingProperty]="'removeExistingOnSync'"
        [labelOnRight]="true"
        [label]="'Remove from existing products'"
        [programmaticallyChangeValue]="!(smartFilters.smartFilterIds$ | async)?.length ? false : undefined"
        [tooltip]="
          smartFilters.smartFilterIds$ | async | getRemoveFromExistingProductsTooltip : ClientTypes.SmartDataType.Labels
        ">
      </lib-reactive-form-switch>
    </ng-container>
  </lib-reactive-form-group>
</div>
