<lib-reactive-form-group [dispersedKey]="dispersedKey" [mergeKey]="mergeKey" [bindTo]="job">
  <lib-reactive-form-text
    [inputName]="'name'"
    [label]="label"
    [placeholder]="placeholder"
    [inlineLabel]="false"
    [disabled]="viewOnly"
    [maxLength]="128"
    [programmaticallyChangeValue]="job?.name | dateTimeStringIfUnixStringElseOriginal"
    [bindingProperty]="'name'">
  </lib-reactive-form-text>
</lib-reactive-form-group>
