import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { EditVariantContainer } from '../../edit-variant-container';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Variant } from '../../../../../../../models/product/dto/variant';
import { LabelGroupStyling } from '../../../../../../../models/shared/stylesheet/label-group-styling';
import { LabelGroupItem, LabelItemType } from '../../../../../../../models/shared/stylesheet/label-group-item';
import { Location } from '../../../../../../../models/company/dto/location';
import { PriceUtils } from '../../../../../../../utils/price-utils';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { VariantPriceSource } from '../../../../../../../models/enum/shared/variant-price-source.enum';
import { EditVariantFormObject } from '../../../../forms/edit-variant-form/edit-variant-form-object';
import { VariantPricing } from '../../../../../../../models/product/dto/variant-pricing';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { map } from 'rxjs/operators';
import { VariantTypeDefinition } from '../../../../../../../models/utils/dto/variant-type-definition';
import { PriceFormat } from '../../../../../../../models/utils/dto/price-format-type';
import { InventoryProvider } from '../../../../../../../models/utils/dto/inventory-provider-type';
import { ProviderUtils } from '../../../../../../../utils/provider-utils';

@Injectable()
export class EditVariantPricingViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  public priceFormat$ = this.locationDomainModel.priceFormat$;
  protected inventoryProvider$ = this.companyDomainModel.inventoryProvider$;

  // Variant
  public variantPreviewPrice$ = new BehaviorSubject<string>(null);
  public variantPreviewPriceSource$ = new BehaviorSubject<string>(null);
  public variantPriceSourceMap = new Map<string, string>();
  public variantFormObject$ = combineLatest([
    this.container.currentLocation$,
    this.container.companyId$,
    this.container.variant$,
    this.companyDomainModel.companyName$,
    this.priceFormat$,
    this.inventoryProvider$
  ]).pipe(
    map(([location, companyId, variant, companyName, priceStream, provider]) => {
      this.getLocationPricingLabelItems(variant, location, companyId, companyName, priceStream, provider);
      return new EditVariantFormObject(variant, companyId, location.id);
    })
  );

  // Label Group
  public labelGroupStyling: LabelGroupStyling = new LabelGroupStyling();
  public promotionGroupStyling: LabelGroupStyling = this.getPromotionLabelGroupStyling();
  public locationLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);
  public locationTaxLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);
  public locationPricingTierLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);
  public companyLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);
  public companyTaxLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);
  public companyPricingTierLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);
  public promotionLabelGroupItems$ = new BehaviorSubject<LabelGroupItem[]>(null);

  // Promo Conditionals
  private _showBanner = new BehaviorSubject<boolean>(false);
  public showBanner$ = this._showBanner as Observable<boolean>;
  private _isPromo = new BehaviorSubject<boolean>(false);
  public isPromo$ = this._isPromo as Observable<boolean>;

  public getPromotionLabelGroupStyling(): LabelGroupStyling {
    const style = new LabelGroupStyling();
    style.numberColumns = 2;
    return style;
  }

  public getLocationPricingLabelItems(
    v: Variant,
    location: Location,
    companyId: number,
    compName: string,
    priceFormat: PriceFormat,
    provider: InventoryProvider
  ) {
    this.variantPriceSourceMap = new Map<string, string>();
    let promotionItems: LabelGroupItem[];
    const variantIsFlower = VariantTypeDefinition.isFlowerByGramType(v?.variantType);

    const companyPromo = v?.locationPromotion?.isCompanyPromo;
    const activeLocationPromotion = v?.locationPromotion?.isActive() && !companyPromo;
    const activeCompanyPromotion = v?.locationPromotion?.isActive() && companyPromo;

    const locationPricing = v.locationPricing?.find(lp => lp?.locationId === location?.id);
    const companyPricing = v.locationPricing?.find(lp => lp?.locationId === companyId);
    const companyPromotion = activeCompanyPromotion
      ? companyPricing?.getPromotionPriceOrNull(priceFormat, v?.locationPromotion)
      : null;
    const locationPromotion = activeLocationPromotion
      ? locationPricing?.getPromotionPriceOrNull(priceFormat, v?.locationPromotion)
      : null;
    const locationSalePrice = locationPricing?.getSalePriceOrNull(priceFormat);
    const companySalePrice = companyPricing?.getSalePriceOrNull(priceFormat);
    const locationPrice = locationPricing?.getPriceWithoutDiscountsOrNull(priceFormat);
    const companyPrice = companyPricing?.getPriceWithoutDiscountsOrNull(priceFormat);

    const isPromo = activeLocationPromotion || activeCompanyPromotion;
    const isSale = locationSalePrice > 0 || companySalePrice > 0;
    this._isPromo.next(isPromo);

    // Location
    const locName = location?.name;
    const locationItems = this.generateLocationPricingLabelGroupItems(locName, locationPricing, priceFormat, provider);
    const locationTaxItems = this.getTaxLabelItems(locationPricing);
    const locationTierItems = this.getPricingTierLabelItems(locationPricing, variantIsFlower);

    // Company
    const companyItems = this.generateCompanyPricingLabelGroupItems(compName, companyPricing, priceFormat, provider);
    const companyTaxItems = this.getTaxLabelItems(companyPricing);
    const companyTierItems = this.getPricingTierLabelItems(companyPricing, variantIsFlower);

    // Promotion
    if (activeCompanyPromotion || activeLocationPromotion) {
      promotionItems = this.generatePromoLabelGroupItems(locationPricing, companyPricing, v, priceFormat, provider);
    }

    this._showBanner.next(isPromo && isSale);

    this.locationLabelGroupItems$.next(locationItems);
    this.locationTaxLabelGroupItems$.next(locationTaxItems);
    this.locationPricingTierLabelGroupItems$.next(locationTierItems);
    this.companyLabelGroupItems$.next(companyItems);
    this.companyTaxLabelGroupItems$.next(companyTaxItems);
    this.companyPricingTierLabelGroupItems$.next(companyTierItems);
    this.promotionLabelGroupItems$.next(promotionItems);

    // By setting this map in the reverse hierarchy order, we ensure that duplicate values still respect the hierarchy
    // Location Promo > Company Promo > Location Sale > Company Sale > Location Price > Company Price
    this.variantPriceSourceMap.set(PriceUtils.formatPrice(companyPrice), VariantPriceSource.CompanyPrice);
    this.variantPriceSourceMap.set(PriceUtils.formatPrice(locationPrice), VariantPriceSource.LocationPrice);
    this.variantPriceSourceMap.set(PriceUtils.formatPrice(companySalePrice), VariantPriceSource.CompanySalePrice);
    this.variantPriceSourceMap.set(PriceUtils.formatPrice(locationSalePrice), VariantPriceSource.LocationSalePrice);
    this.variantPriceSourceMap.set(PriceUtils.formatPrice(companyPromotion), VariantPriceSource.CompanyPromoPrice);
    this.variantPriceSourceMap.set(PriceUtils.formatPrice(locationPromotion), VariantPriceSource.LocationPromoPrice);

    const previewPrice = v?.getVisiblePrice(location.id, null, priceFormat, false)?.toFixed(2);
    this.variantPreviewPrice$.next(previewPrice);
    let source;
    // Checking if price is set on the variant
    if (v?.price <= 0 && v?.locationPricing?.every(lp => lp?.price <= 0)) {
      source = null;
    } else {
      source = this.variantPriceSourceMap.get('$' + previewPrice);
    }
    // Replace the term 'Promotion' if the POS provider uses a different term
    const promoDiscountTerm = ProviderUtils.getPromotionsTabName(provider).slice(0, -1);
    if (!!source && source.includes('Promotion') && promoDiscountTerm !== 'Promotion') {
      source = source.replace('Promotion', promoDiscountTerm);
    }
    this.variantPreviewPriceSource$.next(source);
  }

  generateLocationPricingLabelGroupItems(
    title: string,
    locationPricing: VariantPricing,
    priceFormat: PriceFormat,
    provider: InventoryProvider
  ): LabelGroupItem[] {
    const locationPriceWithoutDiscounts = locationPricing?.getPriceWithoutDiscountsOrNull(priceFormat);
    const items: LabelGroupItem[] = [];
    const locationTitle = new LabelGroupItem();
    locationTitle.itemType = LabelItemType.Title;
    locationTitle.value = `Location: ${title}`;
    items.push(locationTitle);

    const locationSubtitle = new LabelGroupItem();
    locationSubtitle.itemType = LabelItemType.Subtitle;
    locationSubtitle.value = `Pricing`;
    items.push(locationSubtitle);

    const locationPrice = new LabelGroupItem();
    locationPrice.label = 'Location Price';
    locationPrice.tooltipText = `The price of the product for ${title}.`;
    locationPrice.value = PriceUtils.formatPrice(locationPriceWithoutDiscounts) || '--';
    locationPrice.inlineLabel = true;
    locationPrice.inlineFlexEnd = true;
    locationPrice.boldText = true;
    items.push(locationPrice);

    const locationSaleItem = new LabelGroupItem();
    locationSaleItem.label = `Location Sale Price`;
    locationSaleItem.tooltipText = 'The price of the product after the location specific sale '
      + 'discount has been applied.';
    locationSaleItem.value = PriceUtils.formatSalePrice(locationPricing, priceFormat, provider) || '--';
    locationSaleItem.inlineLabel = true;
    locationSaleItem.inlineFlexEnd = true;
    locationSaleItem.boldText = true;
    items.push(locationSaleItem);

    return items;
  }

  generateCompanyPricingLabelGroupItems(
    title: string,
    companyPricing: VariantPricing,
    priceFormat: PriceFormat,
    provider: InventoryProvider
  ): LabelGroupItem[] {
    const companyPriceWithoutDiscounts = companyPricing?.getPriceWithoutDiscountsOrNull(priceFormat);
    const companyAvgPriceWithoutDiscounts = companyPricing?.getAveragePriceWithoutDiscountsOrNull(priceFormat);
    const items: LabelGroupItem[] = [];
    const companyTitle = new LabelGroupItem();
    companyTitle.itemType = LabelItemType.Title;
    companyTitle.value = `Company: ${title}`;
    items.push(companyTitle);

    const companySubtitle = new LabelGroupItem();
    companySubtitle.itemType = LabelItemType.Subtitle;
    companySubtitle.value = `Pricing`;
    items.push(companySubtitle);

    const companyPrice = new LabelGroupItem();
    companyPrice.label = 'Company Price';
    companyPrice.tooltipText = `The price of the product for ${title}.`;
    companyPrice.value = PriceUtils.formatPrice(companyPriceWithoutDiscounts) || '--';
    companyPrice.inlineLabel = true;
    companyPrice.inlineFlexEnd = true;
    companyPrice.boldText = true;
    items.push(companyPrice);

    const companySaleItem = new LabelGroupItem();
    companySaleItem.label = 'Company Sale Price';
    companySaleItem.tooltipText = 'The price of the product after the company wide sale discount has been applied.';
    companySaleItem.value = PriceUtils.formatSalePrice(companyPricing, priceFormat, provider) || '--';
    companySaleItem.inlineLabel = true;
    companySaleItem.inlineFlexEnd = true;
    companySaleItem.boldText = true;
    items.push(companySaleItem);

    const companyAveragePrice = new LabelGroupItem();
    companyAveragePrice.label = 'Company Average Price';
    companyAveragePrice.tooltipText = 'The average price of the product across all locations within the company.';
    companyAveragePrice.value = PriceUtils.formatPrice(companyAvgPriceWithoutDiscounts) || '--';
    companyAveragePrice.inlineLabel = true;
    companyAveragePrice.inlineFlexEnd = true;
    companyAveragePrice.boldText = true;
    items.push(companyAveragePrice);

    return items;
  }

  generatePromoLabelGroupItems(
    locationPricing: VariantPricing,
    companyPricing: VariantPricing,
    v: Variant,
    priceFormat: PriceFormat,
    provider: InventoryProvider
  ): LabelGroupItem[] {
    const promotion = v?.locationPromotion;
    const active = promotion?.isActive();
    const items: LabelGroupItem[] = [];
    // Get proper terminology for POS provider promotion/discount (remove plural convention)
    const promoDiscountTerm = ProviderUtils.getPromotionsTabName(provider).slice(0, -1);

    const promotionTitle = new LabelGroupItem();
    promotionTitle.itemType = LabelItemType.Title;
    promotionTitle.value = `Active ${promoDiscountTerm}`;

    const name = new LabelGroupItem();
    name.label = `${promoDiscountTerm} Name`;
    name.value = promotion?.name;

    const promoDiscountAmount = new LabelGroupItem();
    promoDiscountAmount.label = `${promoDiscountTerm} Amount`;
    promoDiscountAmount.value = promotion?.getDiscountAmountString();

    const locationPromoPrice = new LabelGroupItem();
    locationPromoPrice.label = `Location ${promoDiscountTerm} Price`;
    if (!promotion?.isCompanyPromo && active) {
      const promotionPrice = locationPricing?.getPromotionPriceOrNull(priceFormat, promotion);
      locationPromoPrice.value = PriceUtils.formatPrice(promotionPrice) || '--';
    }

    const companyPromoPrice = new LabelGroupItem();
    companyPromoPrice.label = `Company ${promoDiscountTerm} Price`;
    if (promotion?.isCompanyPromo && active) {
      const promotionPrice = companyPricing?.getPromotionPriceOrNull(priceFormat, promotion);
      companyPromoPrice.value = PriceUtils.formatPrice(promotionPrice) || '--';
    }

    // Items layout left to right spread out across two columns
    items.push(promotionTitle);
    items.push(name);
    items.push(locationPromoPrice);
    items.push(promoDiscountAmount);
    items.push(companyPromoPrice);
    return items;
  }

  private getTaxLabelItems(pricing: VariantPricing): LabelGroupItem[] {
    if (pricing?.taxRates?.length) {
      const items: LabelGroupItem[] = [];

      const spacer = new LabelGroupItem();
      spacer.itemType = LabelItemType.Spacer;
      items.push(spacer);
      items.push(spacer);

      const title = new LabelGroupItem();
      title.itemType = LabelItemType.Subtitle;
      title.value = `Taxes`;
      items.push(title);

      const showTaxLayer = pricing?.taxRates?.map(t => t.layer).unique(false)?.length > 1;
      pricing?.taxRates?.forEach(tax => {
        const taxItem = new LabelGroupItem();
        taxItem.itemType = LabelItemType.Text;
        // Added one because a user will identify with 1 better than 0 when counting layers
        taxItem.label = tax.name;
        if (showTaxLayer) {
          taxItem.label += ' (Layer ' + (tax.layer + 1) + ')';
        }
        if (tax.flatRateAmount > 0) {
          taxItem.value = '$' + Math.round(tax.flatRateAmount * 100) / 100;
        } else {
          taxItem.value = Math.round(tax.percent * 100) / 100 + '%';
        }
        taxItem.inlineLabel = true;
        taxItem.inlineFlexEnd = true;
        items.push(taxItem);
      });
      return items;
    } else {
      return null;
    }
  }

  private getPricingTierLabelItems(pricing: VariantPricing, isFlower: boolean): LabelGroupItem[] {
    if (pricing?.pricingTiers?.length) {
      const items: LabelGroupItem[] = [];

      const spacer = new LabelGroupItem();
      spacer.itemType = LabelItemType.Spacer;
      items.push(spacer);
      items.push(spacer);

      const title = new LabelGroupItem();
      title.itemType = LabelItemType.Subtitle;
      title.value = `Pricing Tiers`;
      items.push(title);

      pricing?.pricingTiers?.forEach((tier, i) => {
        const tierItem = new LabelGroupItem();
        tierItem.itemType = LabelItemType.Text;
        if (isFlower) {
          if (i + 1 === pricing.pricingTiers.length) {
            tierItem.label = `${tier.startWeight}g+`;
          } else {
            tierItem.label = `${tier.startWeight}g - ${tier.endWeight}g`;
          }
          tierItem.value = '$' + tier.price.toFixed(2) + '/g';
        } else {
          if (i + 1 === pricing.pricingTiers.length) {
            tierItem.label = `${tier.startQuantity}+`;
          } else {
            tierItem.label = `${tier.startQuantity} - ${tier.endQuantity}`;
          }
          tierItem.value = '$' + tier.price.toFixed(2) + '/unit';

        }
        tierItem.inlineLabel = true;
        tierItem.inlineFlexEnd = true;
        items.push(tierItem);
      });
      return items;
    } else {
      return null;
    }
  }

}
