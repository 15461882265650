import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TemplateViewLocationsViewModel } from './template-view-locations-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-template-view-locations',
  templateUrl: './template-view-locations.component.html',
  styleUrls: ['./template-view-locations.component.scss'],
  providers: [TemplateViewLocationsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateViewLocationsComponent extends BaseModalComponent {

  constructor(
    public viewModel: TemplateViewLocationsViewModel,
    protected activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

}
