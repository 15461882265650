import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Variant } from '../../../models/product/dto/variant';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'variantFromId'
})
export class VariantFromIdPipe implements PipeTransform {

  transform(id: string, variants$: Observable<Variant[]>): Observable<Variant> {
    return variants$.pipe(
      map(variants => variants?.find(v => v?.id === id))
    );
  }

}
