import { Asset } from '../image/dto/asset';
import { DateUtils } from '../../utils/date-utils';
import { Deserializable } from '../protocols/deserializable';
import { Previewable } from '../protocols/previewable';
import { Cachable } from '../protocols/cachable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export abstract class Preview implements Deserializable, Previewable, Cachable, UniquelyIdentifiable {

  public constructor(id?: string, preview?: Asset, locationId?: number) {
    this.id = id;
    this.preview = preview;
    this.locationId = locationId;
  }

  public id: string;
  public preview: Asset;
  public cachedTime: number;
  public locationId: number;

  public onDeserialize() {
    this.preview = window?.injector?.Deserialize?.instanceOf(Asset, this.preview);
  }

  public over5MinutesOld(): boolean {
    return ((this.preview?.timestamp ?? 0) + (DateUtils.unixOneMinute() * 5)) < DateUtils.currentTimestamp();
  }

  cacheKey(): string {
    return `${this.id}-${this.locationId}`;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.id}-
      ${this.preview.getUniqueIdentifier()}-
      ${this.cachedTime}
      ${this.locationId}
    `;
  }

}
