import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Type, ViewChild } from '@angular/core';
import { LabelInflatorContainerDirective } from './label-inflator-container.directive';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { ComputeLabelInterface } from '../label/compute-label-interface';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { LabelComponent } from '../label/label-component';
import { FlagLabelComponent } from '../flag-label/flag-label.component';
import { BasicRoundBorderLabelComponent } from '../basic-round-border-label/basic-round-border-label.component';
import { DisplayLabelInterface } from '../label/display-label-interface';
import { Label } from '../../../models/shared/label';
import { SystemLabel } from '../../../models/shared/labels/system-label';
import { DisplaySaleLabelInterface } from '../label/display-sale-label-interface';
import { DistinctUtils } from '../../../utils/distinct-utils';

/**
 * Don't use multiple interfaces at a time. Component was designed to use one at a time.
 */
@Component({
  selector: 'app-label-inflator',
  templateUrl: './label-inflator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelInflatorComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  @Input() computeLabelInterface: ComputeLabelInterface;
  @Input() displayLabelInterface: DisplayLabelInterface;
  @Input() displaySaleLabelInterface: DisplaySaleLabelInterface;
  @Input() zoom: number = 1;
  @Output() clearClicked = new EventEmitter<Label>(true);
  @ViewChild(LabelInflatorContainerDirective, {static: true}) contentContainer: LabelInflatorContainerDirective;

  private _currentLabelText = new BehaviorSubject<string>('');
  public computedLabelText$ = this._currentLabelText.pipe(distinctUntilChanged());

  private _currentLabelStyle = new BehaviorSubject<LabelStyle>(null);
  public currentLabelStyle$ = this._currentLabelStyle.pipe(distinctUntilChanged());

  private _computedPrimaryLabel = new BehaviorSubject<Label>(null);
  public computedPrimaryLabel$ = this._computedPrimaryLabel.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _computedSecondaryLabels = new BehaviorSubject<Label[]>(null);
  public computedSecondaryLabels$ = this._computedSecondaryLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _computedSortedSystemLabels = new BehaviorSubject<SystemLabel[]>([]);
  public computedSortedSystemLabels$ = this._computedSortedSystemLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _addedBySmartFilter = new BehaviorSubject<boolean>(false);
  public addedBySmartFilter$ = this._addedBySmartFilter as Observable<boolean>;

  private labelTextSub: Subscription;
  private clearClickedSub: Subscription;
  private primarySub: Subscription;
  private secSub: Subscription;
  private systemLabelsSub: Subscription;
  private addedBySmartFilterSub: Subscription;

  private compInstance: LabelComponent;

  ngOnInit(): void {
    this.inflateLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const interfaceChanged = !!changes.computeLabelInterface
                          || !!changes.displayLabelInterface
                          || !!changes.displaySaleLabelInterface;
    if (interfaceChanged) this.inflateLabel();
    if (!!this.compInstance) {
      if (!!changes.zoom) this.compInstance.zoom = this.zoom;
      this.compInstance.ngOnChanges(changes);
    }
  }

  inflateLabel() {
    this._currentLabelStyle.next(this.getLabelStyleEnum());
    const componentFactory = this
        .componentFactoryResolver
        .resolveComponentFactory<LabelComponent>(this.getLabelComponentType());
    this.contentContainer.viewContainerRef.clear();
    const componentRef = this
        .contentContainer
        .viewContainerRef
        .createComponent(componentFactory);
    this.compInstance = (componentRef.instance as LabelComponent);
    this.passThroughInputs();
    this.passThroughOutputs();
  }

  private passThroughInputs(): void {
    this.compInstance.computeLabelInterface = this.computeLabelInterface;
    this.compInstance.displayLabelInterface = this.displayLabelInterface;
    this.compInstance.displaySaleLabelInterface = this.displaySaleLabelInterface;
    this.compInstance.zoom = this.zoom;
  }

  private passThroughOutputs(): void {
    this.labelTextSub?.unsubscribe();
    this.labelTextSub = this.compInstance.viewModel.labelText$.subscribe(text => this._currentLabelText.next(text));
    this.clearClickedSub?.unsubscribe();
    this.clearClickedSub = this.compInstance.clearClicked$.subscribe(clear => this.clearClicked.emit(clear));
    this.primarySub?.unsubscribe();
    this.primarySub = this.compInstance.viewModel.label$
      .pipe(debounceTime(1))
      .subscribe(l => this._computedPrimaryLabel.next(l));
    this.secSub?.unsubscribe();
    this.secSub = this.compInstance.viewModel.secondaryLabels$
      .pipe(debounceTime(1))
      .subscribe(l => this._computedSecondaryLabels.next(l));
    this.systemLabelsSub?.unsubscribe();
    this.systemLabelsSub = this.compInstance.viewModel
      .sortedSystemLabelPool$
      .pipe(debounceTime(1))
      .subscribe(systemLabels => this._computedSortedSystemLabels.next(systemLabels));
    this.addedBySmartFilterSub?.unsubscribe();
    this.addedBySmartFilterSub = this.compInstance.viewModel.labelAddedBySmartFilter$
      .pipe(debounceTime(1))
      .subscribe(addedBySmartFilter => this._addedBySmartFilter.next(addedBySmartFilter));
  }

  private getLabelStyleEnum(): LabelStyle {
    const overrideLabelStyle = this.displayLabelInterface?.overrideLabelStyleIfDefined();
    const labelStyleInterface = this.displayLabelInterface
      ?? this.displaySaleLabelInterface
      ?? this.computeLabelInterface;
    const locationLabelStyleEnum = labelStyleInterface?.getLocationConfigForLabelComponent()?.labelStyle;
    const companyLabelStyleEnum = labelStyleInterface?.getCompanyConfigForLabelComponent()?.labelStyle;
    return overrideLabelStyle || locationLabelStyleEnum || companyLabelStyleEnum || LabelStyle.Default;
  }

  private getLabelComponentType(): Type<LabelComponent> {
    const labelStyleEnum = this.getLabelStyleEnum();
    switch (labelStyleEnum) {
      case LabelStyle.Flag: return FlagLabelComponent;
    }
    return BasicRoundBorderLabelComponent;
  }

  ngOnDestroy(): void {
    this.labelTextSub?.unsubscribe();
    this.clearClickedSub?.unsubscribe();
    this.primarySub?.unsubscribe();
    this.secSub?.unsubscribe();
    this.systemLabelsSub?.unsubscribe();
    this.addedBySmartFilterSub?.unsubscribe();
  }

}
