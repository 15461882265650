import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EditMarketingMenuBodyComponent } from '../../edit-marketing-menu-body/edit-marketing-menu-body.component';
import { EditSmartPlaylistMenuViewModel } from '../edit-smart-playlist-menu-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-smart-playlist-menu-body',
  templateUrl: './edit-smart-playlist-menu-body.component.html',
  styleUrls: ['./edit-smart-playlist-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSmartPlaylistMenuBodyComponent extends EditMarketingMenuBodyComponent {

  constructor(
    public override viewModel: EditSmartPlaylistMenuViewModel
  ) {
    super(viewModel);
  }

  @Input() sharedVM: EditSmartPlaylistMenuViewModel;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);
  @Output() replacingImage = new EventEmitter<boolean>(true);

}
