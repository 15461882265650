import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';
import { PrintLabelTemplatesViewModel } from '../print-label-templates-view-model';

@Component({
  selector: 'app-print-label-template-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintLabelTemplateListComponent extends DisplayableItemListComponent {

  constructor(
    printLabelTemplatesViewModel: PrintLabelTemplatesViewModel // Provided by parent component
  ) {
    super(printLabelTemplatesViewModel);
  }

}
