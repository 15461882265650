<div fxLayout="row" fxLayoutAlign=" center" [style.gap.rem]="2">
  <app-header-title-text>
    <img *ngIf="menu?.isTemplatedMenu()" class="template-icon" src="assets/icons/dark/solid/template.svg" alt="" />
    {{ menu?.getSelectionTitle() }}
  </app-header-title-text>
  <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="menu">
    <lib-reactive-form-switch
      [inputName]="'active'"
      [label]="'Active'"
      [labelOnRight]="false"
      [inlineLabel]="true"
      [disabled]="disableActiveToggle || !formPristine"
      [disabledTooltip]="
        (formPristine | saveChangesTooltip) || 'Menu cannot be set to inactive while assigned to a display.'
      "
      [bindingProperty]="'active'"
      [selectedValue]="true"
      [notSelectedValue]="false"
      (valueChanged)="toggleActiveState($event)">
    </lib-reactive-form-switch>
  </lib-reactive-form-group>
  <div fxFlex></div>
</div>
