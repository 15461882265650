import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { ProductGroupingInfoListItemViewModel } from './product-grouping-info-list-item-view-model';
import { Product } from '../../../../../../models/product/dto/product';
import { OverrideProductGroup } from '../../../../../../models/product/dto/override-product-group';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-product-grouping-info-list-item',
  templateUrl: './product-grouping-info-list-item.component.html',
  styleUrls: ['./product-grouping-info-list-item.component.scss'],
  providers: [ProductGroupingInfoListItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupingInfoListItemComponent extends BaseComponent {

  constructor(
    public viewModel: ProductGroupingInfoListItemViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToOverrideProductGroup') overrideProductGroup: OverrideProductGroup;
  @Input() @ViewModelConnector('connectToProduct') product: Product;
  @Output() deleteFromGroup = new EventEmitter<string>(true);

}
