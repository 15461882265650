import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RecurringDetails } from '../recurring-details';

@Injectable()
export class RecurringDetailsFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _recurringDetails = new BehaviorSubject<RecurringDetails>(null);
  public recurringDetails$ = this._recurringDetails as Observable<RecurringDetails>;
  connectToRecurringDetails = (recurringDetails: RecurringDetails) => this._recurringDetails.next(recurringDetails);

}
