import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { BulkPrintJob } from '../../../../models/automation/bulk-print-job';
import { formatDate } from '@angular/common';
import { BsError } from '../../../../models/shared/bs-error';
import { BulkPrintJobDomainModel } from '../../../../domainModels/bulk-print-job-domain-model';
import { iiif } from '../../../../utils/observable.extensions';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';
import { map, switchMap, tap } from 'rxjs/operators';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';

@Injectable()
export abstract class PrintJobViewModel extends BaseModalViewModel {

  protected constructor(
    ngbModal: NgbModal,
    router: Router,
    protected userDomainModel: UserDomainModel,
    protected activeModal: NgbActiveModal,
    protected bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected companyDomainModel: CompanyDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected smartFiltersDomainModel: SmartFiltersDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected toastService: ToastService,
  ) {
    super(router, ngbModal);
    this.constructNewBulkPrintJob();
  }

  protected userId$ = this.userDomainModel.userId$;
  protected companyId$ = this.companyDomainModel.companyId$;
  protected locationId$ = this.locationDomainModel.locationId$;

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  protected menuId$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplateId$,
    this.menuDomainModel.activeHydratedMenuId$
  );

  protected _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  protected _bulkPrintJob = new BehaviorSubject<BulkPrintJob | null>(null);
  public bulkPrintJob$ = this._bulkPrintJob as Observable<BulkPrintJob>;
  connectToBulkPrintJob(bulkPrintJob: BulkPrintJob) { this._bulkPrintJob.next(bulkPrintJob); }

  public currentDate$ = timer(0, 5000).pipe(map(() => this.getFormattedTimeString()));

  protected getFormattedTimeString(): string {
    return formatDate(new Date(), 'MMM d, y h:mm a', 'en');
  }

  public createBulkPrintJob(): void {
    this.bulkPrintJob$.once(bulkPrintJob => {
      const lmCreatePrintJob = 'Creating Bulk Print Job';
      const lmSyncSmartFilters = 'Syncing Smart Filters';
      this._loadingOpts.addRequest(lmCreatePrintJob);
      this._loadingOpts.addRequest(lmSyncSmartFilters);
      this.bulkPrintJobDomainModel.bulkPrintJobContainsStackedContentAtMenuLevel(bulkPrintJob).pipe(
        switchMap(containsStackedContent => {
          const async = !containsStackedContent;
          return this.smartFiltersDomainModel.syncSmartFiltersBeforePrintJob(bulkPrintJob?.menuIds, async).pipe(
            tap(() => this._loadingOpts.removeRequest(lmSyncSmartFilters))
          );
        }),
        switchMap(() => this.bulkPrintJobDomainModel.createBulkPrintJob(bulkPrintJob))
      ).subscribe({
        complete: () => {
          this._loadingOpts.removeRequest(lmCreatePrintJob);
          this._loadingOpts.removeRequest(lmSyncSmartFilters);
          this.toastService.publishSuccessMessage('Bulk Print Job Created', 'Success');
          this.activeModal.close(null);
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(lmCreatePrintJob);
          this._loadingOpts.removeRequest(lmSyncSmartFilters);
          this.toastService.publishError(err);
        }
      });
    });
  }

  protected constructNewBulkPrintJob(): void {
    combineLatest([
      this.companyId$,
      this.locationId$,
      this.userId$,
    ]).once(([companyId, locationId, userId])  => {
      const newJob = window?.injector?.Deserialize?.instanceOf(BulkPrintJob, {});
      newJob.locationId = locationId;
      newJob.companyId = companyId;
      newJob.recipientIds = [userId];
      this.connectToBulkPrintJob(newJob);
    });
  }

}
