<app-loading
  [hidden]="!(combinedLoadingOptsViewModel.isLoading$ | push)"
  [options]="combinedLoadingOptsViewModel.loadingOpts$ | push">
</app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="dispersedAndMergeKey"
  (formsAutoSubmitted$)="viewModel.saveTemplateSection(true)"
  (formsSubmitted$)="viewModel.saveTemplateSection(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-section-container>
  <app-edit-template-menu-section-header
    [crumbs]="viewModel.breadcrumbs$ | push"
    [hideSectionNavButton]="viewModel.hideSectionNavButton$ | push"
    [disableSectionNavOptionIds]="viewModel.disabledSectionNavOptionIds$ | push"
    [sectionNavOptions]="viewModel.sectionNavOptions$ | push"
    [autoSaveLoadingOpts]="combinedLoadingOptsViewModel.autoSaveLoadingOpts$ | push"
    [lastAutoSavedText]="combinedLoadingOptsViewModel.lastAutoSavedTimeText$ | push"
    [disableSubmit]="formCapture.disableSubmit$ | push"
    [removingVariants]="viewModel.removingVariants$ | push"
    [section]="viewModel.activeSectionTemplate$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [showLiveView]="viewModel.showLiveView"
    (submitForms)="formCapture.submitForms($event)"
    (handleDropdownSelection)="viewModel.handleDropdownSelection($event)">
  </app-edit-template-menu-section-header>

  <app-edit-menu-section-body
    [templateMode]="true"
    [dispersedAndMergeKey]="dispersedAndMergeKey"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push">
  </app-edit-menu-section-body>

  <app-edit-template-menu-section-footer
    [autoSavingLoadingOpts]="combinedLoadingOptsViewModel.autoSaveLoadingOpts$ | push"
    [lastAutoSaveText]="combinedLoadingOptsViewModel.lastAutoSavedTimeText$ | push"
    [formDisabledSubmit]="formCapture.disableSubmit$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    [isPrintReportSection]="viewModel.isPrintReportSection$ | push"
    [removingVariants]="viewModel.removingVariants$ | push"
    (deleteSection)="viewModel.deleteSection()"
    (duplicateSection)="viewModel.duplicateSection()"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-template-menu-section-footer>
</app-edit-menu-section-container>
