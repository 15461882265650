import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { RecurringDetailsFormViewModel } from './recurring-details-form-view-model';
import { RecurringDetails } from '../recurring-details';
import { ReactiveFormCheckboxesComponent, ReactiveFormTimeComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-recurring-details-form',
  templateUrl: './recurring-details-form.component.html',
  styleUrls: ['./recurring-details-form.component.scss'],
  providers: [RecurringDetailsFormViewModel]
})
export class RecurringDetailsFormComponent extends BaseComponent {

  constructor(
    public viewModel: RecurringDetailsFormViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToRecurringDetails') recurringDetails: RecurringDetails;
  @Input() mergeKey: string;
  @ViewChild('StartTime') startTime: ReactiveFormTimeComponent;
  @ViewChild('EndTime') endTime: ReactiveFormTimeComponent;
  @ViewChild('DaysOfWeekCheckboxes') daysCheckboxes: ReactiveFormCheckboxesComponent;

  parseDaysOfWeek = (numbers: number[]) => {
    let selectedDays: number[] = [];
    if (numbers instanceof Array) {
      selectedDays = numbers.filterNulls();
    }
    return selectedDays;
  };

  switchRecurringTimes(recurring: boolean) {
    if (recurring) {
      this.startTime.getSelfAsFormItem().setValue('09:00');
      this.endTime.getSelfAsFormItem().setValue('21:00');
    } else {
      this.startTime.getSelfAsFormItem().setValue('');
      this.endTime.getSelfAsFormItem().setValue('');
    }
    this.daysCheckboxes.boxes?.forEach((box) => {
      box.getSelfAsFormItem().setValue(null);
    });
  }

}
