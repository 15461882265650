import { LabelGroupItem, LabelItemType } from '../../../../models/shared/stylesheet/label-group-item';
import { Theme } from '../../../../models/menu/dto/theme';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';

export class ThemeDetailsForm {

  static getItems(theme: Theme): LabelGroupItem[] {
    const items: LabelGroupItem[] = [];

    const descriptionItem = new LabelGroupItem();
    descriptionItem.itemType = LabelItemType.SmallText;
    descriptionItem.hideLabel = true;
    descriptionItem.value = theme.description;

    const featuresTitle = new LabelGroupItem();
    featuresTitle.itemType = LabelItemType.Title;
    featuresTitle.value = 'Theme Features';

    const sectionImageItem = new LabelGroupItem();
    sectionImageItem.itemType = LabelItemType.IconListItem;
    sectionImageItem.label = 'Section Image';
    sectionImageItem.inlineLabel = true;
    sectionImageItem.equalInlineLabel = true;
    sectionImageItem.iconSrc = theme.themeFeatures.sectionImage
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    sectionImageItem.tooltipText = 'Theme supports the ability for section headers to have a background image.';
    sectionImageItem.customWidthPercentage = '100';

    const featuredProductsItem = new LabelGroupItem();
    featuredProductsItem.itemType = LabelItemType.IconListItem;
    featuredProductsItem.label = 'Featured Products';
    featuredProductsItem.inlineLabel = true;
    featuredProductsItem.equalInlineLabel = true;
    featuredProductsItem.iconSrc = theme.themeFeatures.featuredProducts
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    featuredProductsItem.tooltipText = 'Theme supports the ability to set featured products.';
    featuredProductsItem.customWidthPercentage = '100';

    const backgroundMediaItem = new LabelGroupItem();
    backgroundMediaItem.itemType = LabelItemType.IconListItem;
    backgroundMediaItem.label = 'Background Media';
    backgroundMediaItem.inlineLabel = true;
    backgroundMediaItem.equalInlineLabel = true;
    backgroundMediaItem.iconSrc = theme.themeFeatures.backgroundMedia
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    const printTheme = theme?.supportedMenuTypes.contains(MenuType.PrintMenu)
      || theme?.supportedMenuTypes.contains(MenuType.PrintCardMenu)
      || theme?.supportedMenuTypes?.contains(MenuType.PrintLabelMenu);
    backgroundMediaItem.tooltipText = `Theme supports the ability to upload background
    images${printTheme ? '' : '/videos'}.`;
    backgroundMediaItem.customWidthPercentage = '100';

    const backgroundOpacityItem = new LabelGroupItem();
    backgroundOpacityItem.itemType = LabelItemType.IconListItem;
    backgroundOpacityItem.label = 'Background Opacity';
    backgroundOpacityItem.inlineLabel = true;
    backgroundOpacityItem.equalInlineLabel = true;
    backgroundOpacityItem.iconSrc = theme.themeFeatures.backgroundOpacity
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    backgroundOpacityItem.tooltipText = 'Theme supports the ability to adjust the opacity of the background color.';
    backgroundOpacityItem.customWidthPercentage = '100';

    const titleTextColorItem = new LabelGroupItem();
    titleTextColorItem.itemType = LabelItemType.IconListItem;
    titleTextColorItem.label = 'Title Text Color';
    titleTextColorItem.inlineLabel = true;
    titleTextColorItem.equalInlineLabel = true;
    titleTextColorItem.iconSrc = theme.themeFeatures.titleTextColor
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    titleTextColorItem.tooltipText = 'Theme supports the ability to change the title text color.';
    titleTextColorItem.customWidthPercentage = '100';

    const bodyTextColorItem = new LabelGroupItem();
    bodyTextColorItem.itemType = LabelItemType.IconListItem;
    bodyTextColorItem.label = 'Body Text Color';
    bodyTextColorItem.inlineLabel = true;
    bodyTextColorItem.equalInlineLabel = true;
    bodyTextColorItem.iconSrc = theme.themeFeatures.bodyTextColor
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    bodyTextColorItem.tooltipText = 'Theme supports the ability to change the body text color.';
    bodyTextColorItem.customWidthPercentage = '100';

    const bodyBackgroundColorItem = new LabelGroupItem();
    bodyBackgroundColorItem.itemType = LabelItemType.IconListItem;
    bodyBackgroundColorItem.label = 'Background Color Overlay';
    bodyBackgroundColorItem.inlineLabel = true;
    bodyBackgroundColorItem.equalInlineLabel = true;
    bodyBackgroundColorItem.iconSrc = theme.themeFeatures.bodyBackgroundColor
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    bodyBackgroundColorItem.tooltipText = 'Theme supports the ability to change the menus background color.';
    bodyBackgroundColorItem.customWidthPercentage = '100';

    const blurredBackgroundItem = new LabelGroupItem();
    blurredBackgroundItem.itemType = LabelItemType.IconListItem;
    blurredBackgroundItem.label = 'Blurred Background';
    blurredBackgroundItem.inlineLabel = true;
    blurredBackgroundItem.equalInlineLabel = true;
    blurredBackgroundItem.iconSrc = theme.themeFeatures.blurredBackground
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    blurredBackgroundItem.tooltipText = 'Theme supports the ability to apply a blur to the background image.';
    blurredBackgroundItem.customWidthPercentage = '100';

    const supportedBadges = new LabelGroupItem();
    supportedBadges.itemType = LabelItemType.IconListItem;
    supportedBadges.label = 'Badges Per Line';
    supportedBadges.inlineLabel = true;
    supportedBadges.equalInlineLabel = true;
    supportedBadges.value = `${theme?.themeFeatures?.badgeCount}`;
    supportedBadges.tooltipText = 'The maximum number of badges that can be displayed per line on the menu.';
    supportedBadges.customWidthPercentage = '100';

    const sectionHeaderTextColor = new LabelGroupItem();
    sectionHeaderTextColor.itemType = LabelItemType.IconListItem;
    sectionHeaderTextColor.label = 'Section Header Text Color';
    sectionHeaderTextColor.inlineLabel = true;
    sectionHeaderTextColor.equalInlineLabel = true;
    sectionHeaderTextColor.iconSrc = theme.themeFeatures.sectionHeaderTextColor
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    sectionHeaderTextColor.tooltipText = 'Theme supports the ability to change section header text color.';
    sectionHeaderTextColor.customWidthPercentage = '100';

    const sectionBodyTextColor = new LabelGroupItem();
    sectionBodyTextColor.itemType = LabelItemType.IconListItem;
    sectionBodyTextColor.label = 'Section Body Text Color';
    sectionBodyTextColor.inlineLabel = true;
    sectionBodyTextColor.equalInlineLabel = true;
    sectionBodyTextColor.iconSrc = theme.themeFeatures.sectionBodyTextColor
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    sectionBodyTextColor.tooltipText = 'Theme supports the ability to change section body text color.';
    sectionBodyTextColor.customWidthPercentage = '100';

    const titleSectionBackgroundImage = new LabelGroupItem();
    titleSectionBackgroundImage.itemType = LabelItemType.IconListItem;
    titleSectionBackgroundImage.label = 'Title Section Background Image';
    titleSectionBackgroundImage.inlineLabel = true;
    titleSectionBackgroundImage.equalInlineLabel = true;
    titleSectionBackgroundImage.iconSrc = theme.themeFeatures.titleSectionBackgroundImage
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    titleSectionBackgroundImage.tooltipText = 'Theme supports the ability to apply a background image '
      + 'to title sections.';
    titleSectionBackgroundImage.customWidthPercentage = '100';

    const titleSectionSubtitle = new LabelGroupItem();
    titleSectionSubtitle.itemType = LabelItemType.IconListItem;
    titleSectionSubtitle.label = 'Title Section Subtitle';
    titleSectionSubtitle.inlineLabel = true;
    titleSectionSubtitle.equalInlineLabel = true;
    titleSectionSubtitle.iconSrc = theme.themeFeatures.titleSectionSubtitle
      ? 'assets/icons/green/outline/check-circle.svg'
      : 'assets/icons/red/outline/x-circle.svg';
    titleSectionSubtitle.tooltipText = 'Theme supports the ability to add a subtitle to title sections.';
    titleSectionSubtitle.customWidthPercentage = '100';

    const isMarketingTheme = (theme.supportedMenuTypes.length === 1)
      && (theme.supportedMenuTypes?.firstOrNull() === MenuType.MarketingMenu);

    items.push(descriptionItem);
    if (!isMarketingTheme) {
      // Ignore features section for Marketing menus
      items.push(featuresTitle);
      items.push(backgroundOpacityItem);
      items.push(backgroundMediaItem);
      items.push(blurredBackgroundItem);
      items.push(bodyBackgroundColorItem);
      items.push(bodyTextColorItem);
      items.push(titleTextColorItem);
      items.push(featuredProductsItem);
      items.push(supportedBadges);
      items.push(sectionImageItem);
      items.push(sectionHeaderTextColor);
      items.push(sectionBodyTextColor);
      items.push(titleSectionBackgroundImage);
      items.push(titleSectionSubtitle);
    }

    return items;
  }

}
