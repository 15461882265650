import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { combineLatest, Observable } from 'rxjs';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import { DisplayLabelInterface } from '../label/display-label-interface';
import { map } from 'rxjs/operators';
import { LabelUtils } from '../utils/label-utils';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';

@Pipe({
  name: 'getDisplayLabelInterfaceForLabelActionCard'
})
export class GetDisplayLabelInterfaceForLabelActionCardPipe implements PipeTransform {

  transform(
    label: Label,
    configs$: Observable<[LocationConfiguration, CompanyConfiguration]>,
    styling$: Observable<[LabelStyle, SaleLabelFormat]>
  ): Observable<DisplayLabelInterface> {
    return combineLatest([configs$, styling$]).pipe(
      map(([configs, styling]) => {
        return LabelUtils.getDisplayLabelInterfaceForLabelActionCard(label, configs, styling);
      })
    );
  }

}
