import { Deserializable } from '../../protocols/deserializable';

export class ChangePasswordRequest implements Deserializable {

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  public accessToken: string = '';
  public oldPassword: string = '';
  public newPassword: string = '';
  onDeserialize = () => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-authenticator/blob/dev/models/ChangePasswordRequest.go
  onSerialize() {
    const dto = Object.create(ChangePasswordRequest.prototype);
    dto.accessToken = this.accessToken;
    dto.oldPassword = this.oldPassword;
    dto.newPassword = this.newPassword;
    return dto;
  }

}
