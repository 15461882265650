import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Asset } from '../../../../../../models/image/dto/asset';
import { SectionMediaFormViewModel } from './section-media-form-view-model';
import { BaseReactiveFormComponent, ReactiveFormNumberComponent } from '@mobilefirstdev/reactive-form';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';

@Component({
  selector: 'app-section-media-form',
  templateUrl: './section-media-form.component.html',
  styleUrls: ['./section-media-form.component.scss'],
  providers: [SectionMediaFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionMediaFormComponent extends BaseReactiveFormComponent implements OnChanges {

  constructor(
    public viewModel: SectionMediaFormViewModel
  ) {
    super();
  }

  @ViewChild('displayDuration') displayDuration: ReactiveFormNumberComponent;
  @Input() override mergeKey: string;
  @Input() override bindTo: Menu|MenuTemplate;
  @Input() sectionId: string;
  @Input() mediaDuration: number;
  @Input() isTemplatedMenu: boolean = false;
  @Input() file: Asset;
  @Input() groupMediaEnabled: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isTemplatedMenu) this.viewModel.connectToIsTemplatedMenu(this.isTemplatedMenu);
    if (changes.file) this.viewModel.connectToFile(this.file);
    if (changes.bindTo) this.viewModel.connectToBindTo(this.bindTo);
    if (changes.sectionId) this.viewModel.connectToSectionId(this.sectionId);
    if (changes.mediaDuration) this.viewModel.connectToMediaDuration(this.mediaDuration);
    if (changes.groupMediaEnabled) this.viewModel.connectToGroupMediaEnabled(this.groupMediaEnabled);
  }

}
