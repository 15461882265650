import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getInitialValueFromMap',
})
export class GetInitialValueFromMapPipe implements PipeTransform {

  transform(variantCountMap: Map<string, number>, variantId: string): number {
    return variantCountMap?.get(variantId) ?? 0;
  }

}
