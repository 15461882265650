import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelStackPreviewViewModel } from './label-stack-preview-view-model';
import { CardStackPreviewComponent } from '../card-stack-preview/card-stack-preview.component';

@Component({
  selector: 'app-label-stack-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: [
    '../displayable-item-preview/displayable-item-preview.component.scss',
    './label-stack-preview.component.scss'
  ],
  providers: [LabelStackPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackPreviewComponent extends CardStackPreviewComponent {

  constructor(
    public override viewModel: LabelStackPreviewViewModel
  ) {
    super(viewModel);
  }

}
