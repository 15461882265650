import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { BsError } from '../../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../../services/toast-service';
import { Section } from '../../../../../../models/menu/dto/section';
import { map } from 'rxjs/operators';
import { SectionType } from '../../../../../../models/utils/dto/section-type-definition';
import { SmartFiltersDomainModel } from '../../../../../../domainModels/smart-filters-domain-model';
import { SyncSmartFilterService } from '../../../../../../services/smart-filter-sync.service';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { ModalNewMenuSection } from '../../../../../../modals/modal-new-menu-section';
import { ActivatedRoute, Router } from '@angular/router';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPromptForSectionDelete } from '../../../../../../modals/modal-prompt-for-section-delete';

@Injectable()
export class MenuSectionPreviewViewModel extends BaseViewModel {

  constructor(
    private toastService: ToastService,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private syncSmartFilterService: SyncSmartFilterService,
    private productDomainModel: ProductDomainModel,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private injector: Injector,
    private router: Router,
    private sharedViewModel: EditProductMenuViewModel,
  ) {
    super();
  }

  public readonly currentLocationProducts$ = this.productDomainModel.currentLocationProducts$;
  protected override readonly _loadingOpts = new BehaviorSubject<LoadingOptions>(this.getLoadingSpinner());

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  private readonly _menu = new BehaviorSubject<Menu>(null);
  public readonly menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);
  public templatedMenu$ = this.menu$.pipe(map(menu => !!menu?.templateId));

  private readonly _section = new BehaviorSubject<Section>(null);
  public readonly section$ = this._section as Observable<Section>;
  connectToSection = (s: Section) => this._section.next(s);

  private readonly _formAutoSubmitted = new Subject<any[]>();
  public readonly formAutoSubmitted$ = this._formAutoSubmitted as Observable<any[]>;
  connectToFormAutoSubmitted = (formAutoSubmitted: any[]) => this._formAutoSubmitted.next(formAutoSubmitted);

  public readonly lastSmartFilterSync$ = this.section$.pipe(map(s => s?.lastSmartFilterSync));

  public readonly autoSaving$ = this.sharedViewModel.autoSaving$;
  public readonly menuSectionsLastIndex$ = this.sharedViewModel.menuSectionsLastIndex$;

  public isPrintReportMenu$ = this.menu$.pipe(map(menu => menu?.isPrintReportMenu()));

  public isEditableSection$ = this.section$.pipe(map(s => s?.sectionType !== SectionType.PageBreak));
  public hasSmartFilters$ = this.section$.pipe(map(s => s?.hasSmartFilters()));

  public allSmartFilters$ = this.smartFiltersDomainModel.allSmartFilters$;
  public appliedSmartFilters$ = combineLatest([
    this.allSmartFilters$,
    this.section$
  ]).pipe(
    map(([allSmartFilters, section]) => {
      const sectionSmartFilters = allSmartFilters?.filter(sf => section?.smartFilterIds?.includes(sf?.id));
      return sectionSmartFilters?.map(sf => sf?.name)?.filterNulls()?.join('\n');
    })
  );

  public readonly headerWarningForCUOM$ = of(
    'This menu cannot display Cannabis Unit of Measure in the header, as this section contains '
    + 'products with different units of measure.'
  );

  public readonly hideCUOMHeaderWarning$ = combineLatest([
    this.menu$,
    this.section$,
    this.currentLocationProducts$
  ]).pipe(
    map(([menu, section, products]) => {
      if (menu?.menuOptions?.showCUOMInHeader) {
        const variants = products
          ?.filter(product => section?.productIds?.includes(product?.id))
          ?.flatMap(v => v.variants)
          ?.filter(v => section?.enabledVariantIds?.contains(v.id));
        if (variants?.length > 0) {
          return !!(variants.map(c => c.cannabisUnitOfMeasure).uniqueInstance());
        }
      }
      return true;
    })
  );

  public readonly showVariantCount$ = this.section$.pipe(
    map(section => section?.isProductSection())
  );

  private getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.backgroundColor = '#FFF';
    return opts;
  }

  syncSectionSmartFilters(menu: Menu, sectionBeforeSync: Section) {
    this.syncSmartFilterService.syncSectionSmartFilters(menu, sectionBeforeSync).subscribe(sectionAfterSync => {
      if (!!sectionAfterSync) {
        this.toastService.publishBannerSuccess(sectionBeforeSync.whatChangedString(sectionAfterSync));
      }
    }, (error: BsError) => {
      this.toastService.publishBannerFailed(error?.message);
      throwError(error);
    });
  }

  editSection() {
    combineLatest([
      this.menu$,
      this.section$,
      this.isEditableSection$,
    ]).once(([menu, section, isEditable]) => {
      if (isEditable) {
        const url = section?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      }
    });
  }

  openDuplicateSectionModal() {
    combineLatest([
      this.menu$,
      this.section$
    ]).once(([menu, section]) => {
      const onClose = (sec) => {
        const url = sec?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalNewMenuSection.open(this.ngbModal, this.injector, menu, section, onClose);
    });
  }

  promptForDeleteSection() {
    this.section$.once(section => {
      const confirmation = (cont) => { if (cont) this.sharedViewModel.deleteSection(section); };
      ModalPromptForSectionDelete.open(this.ngbModal, this.injector, section, confirmation);
    });
  }

}
