import { Deserializable } from '../../protocols/deserializable';
import { Product } from './product';
import { Pagable } from '../../protocols/pagable';

/**
 * This object represents a group of products that will be consolidated into a single product.
 */
export class OverrideProductGroup implements Deserializable, Pagable {

  constructor(
    companyId?: number,
    name?: string,
    productIds?: string[]
  ) {
    if (Number.isFinite(companyId)) this.companyId = companyId;
    this.name = name;
    this.productIds = productIds;
  }

  companyId: number;
  id: string = '';
  name: string = '';
  productIds: string[] = [];

  // Hydrated Product properties
  consolidatedProduct: Product;
  products: Product[];
  removedProducts: Product[];

  onDeserialize() {
    this.productIds = Array.from(this.productIds || []);
    this.consolidatedProduct = window?.injector?.Deserialize?.instanceOf(Product, this.consolidatedProduct);
    this.products = window?.injector?.Deserialize?.arrayOf(Product, this.products) || [];
    this.removedProducts = window?.injector?.Deserialize?.arrayOf(Product, this.removedProducts) || [];
    this.products?.forEach(product => product.overrideGroupName = this.name);
  }

  onSerialize() {
    const dto = Object.create(OverrideProductGroup.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.name = this.name;
    dto.productIds = this.productIds;
    return dto;
  }

  getStartKey(): string {
    return this.id;
  }

  forceToActive(): void {
    this.consolidatedProduct?.forceToActive();
    this.products?.forEach(product => product.forceToActive());
    this.removedProducts?.forEach(product => product.forceToActive());
  }

}
