import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CompactMenu } from '../../../../../../models/menu/dto/compact-menu';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { of } from 'rxjs';

@Component({
  selector: 'app-display-menu-section-header',
  templateUrl: './display-menu-section-header.component.html',
  styleUrls: [
    './display-menu-section-header.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionHeaderComponent extends BaseComponent implements OnChanges {

  @Input() menuWarningTooltip: string;
  @Input() menu: CompactMenu;
  @Input() disableButtons: boolean;
  @Input() nMenus: number;
  @Input() allTemplates: MenuTemplate[];
  @Input() locationId: number;
  @Input() isUserTemplateAdmin: boolean = false;
  @Input() companySupportsTemplates: boolean = false;
  @Input() formPristine: boolean = true;
  @Output() removeMenu = new EventEmitter<CompactMenu>();
  @Output() editMenu = new EventEmitter<CompactMenu>();

  public menuIsTemplateAndMissingPermission: boolean;
  public draftTemplateTooltip$ = of(`This template is in 'Draft'`);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu || changes.isUserTemplateAdmin || changes.companySupportsTemplates) {
      const cantUse = (!this.isUserTemplateAdmin || !this.companySupportsTemplates);
      this.menuIsTemplateAndMissingPermission = this.menu?.isTemplate && cantUse;
    }
  }

}
