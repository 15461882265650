<div class="card location-card" [ngClass]="cardClass">
  <div class="location-header">
    <div class="location-icon-header">
      <img
        class="location-icon"
        alt="/assets/icons/light/outline/location-marker.svg"
        src="/assets/icons/light/outline/location-marker.svg" />
      <img
        *ngIf="card.data.id === (viewModel.defaultLocationId$ | push)"
        class="location-icon"
        alt="/assets/icons/light/outline/badge-check.svg"
        src="/assets/icons/light/outline/badge-check.svg"
        [ngbTooltip]="'Default Location'"
        placement="bottom" />
      <!-- Edit Location Button -->
      <div
        (click)="openEditLocation()"
        [ngbTooltip]="'Edit Location'"
        class="edit-location-button-container"
        placement="bottom">
        <button class="bs-button dark-neutral-button rounded-icon-button">
          <img [src]="'assets/icons/light/outline/pencil.svg'" alt="" class="icon-button-icon" />
        </button>
      </div>
    </div>
    <div class="location-name mt-10px">{{ card.data.name }}</div>
    <div class="location-address">{{ card.data.address }}</div>
    <div class="location-city">{{ card.data.getCityProv() }}</div>
  </div>
  <div class="location-card-label">
    <div class="location-button-container">
      <button (click)="editMenus()" class="bs-button neutral-button location-button">Edit Menus</button>
      <button (click)="editDisplays()" class="bs-button neutral-button location-button">Edit Displays</button>
    </div>
  </div>
</div>
