<div class="modal-padding">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      [inputName]="'productType'"
      [label]="'Product Type'"
      [placeholder]="'Select a product type'"
      [inlineLabel]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [required]="false"
      [dropdowns]="viewModel.dropdownProductTypes$ | push"
      [tooltip]="'The smart filter will contain all products that match this product type.'"
      [bindingProperty]="'productType'"
      [dispersedKey]="dispersedKey"
      [clearable]="!(viewModel.isViewOnly$ | push)"
      [useClearableButton]="false"
      [addToAtLeastNItemsFilledInPool]="true"
      (valueChanged)="listenToProductType($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #variantType
      [inputName]="'variantType'"
      [label]="'Variant Type'"
      [placeholder]="'Select a variant type'"
      [inlineLabel]="false"
      [disabled]="!(viewModel.enableVariantType$ | push)"
      [required]="false"
      [clearable]="viewModel.enableVariantType$ | push"
      [useClearableButton]="false"
      [tooltip]="'The smart filter will contain all products that match this variant type.'"
      [dropdowns]="dropdownVariantTypes | push"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'variantType'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-drop-down
    [inputName]="'classification'"
    [label]="'Strain Type'"
    [placeholder]="'Select a strain type'"
    [inlineLabel]="false"
    [disabled]="viewModel.isViewOnly$ | push"
    [required]="false"
    [clearable]="!(viewModel.isViewOnly$ | push)"
    [useClearableButton]="false"
    [tooltip]="'The smart filter will contain all products that match this strain.'"
    [dropdowns]="viewModel.dropdownClassificationTypes$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'classification'"
    [dispersedKey]="dispersedKey">
  </lib-reactive-form-drop-down>

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-currency
      [inputName]="'minPrice'"
      [label]="'Minimum Price'"
      [placeholder]="'Minimum price of products'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [maxValue]="viewModel.maxPrice$ | push"
      [tooltip]="'The smart filter will include products with a price greater than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'minPrice'"
      [dispersedKey]="dispersedKey"
      [align]="'left'"
      (valueChanged)="viewModel.connectToMinPrice($event)">
    </lib-reactive-form-currency>

    <lib-reactive-form-currency
      [inputName]="'maxPrice'"
      [label]="'Maximum Price'"
      [placeholder]="'Maximum price of products'"
      [inlineLabel]="false"
      [required]="false"
      [minValue]="viewModel.minPrice$ | push"
      [disabled]="viewModel.isViewOnly$ | push"
      [tooltip]="'The smart filter will include products with a price less than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'maxPrice'"
      [dispersedKey]="dispersedKey"
      [align]="'left'"
      (valueChanged)="viewModel.connectToMaxPrice($event)">
    </lib-reactive-form-currency>

    <lib-reactive-form-currency
      [inputName]="'minSecondaryPrice'"
      [label]="'Minimum Secondary Price'"
      [placeholder]="'Minimum Secondary price of products'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [minValue]="0.01"
      [maxValue]="viewModel.maxSecondaryPrice$ | push"
      [tooltip]="'The smart filter will include products with a secondary price greater than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'minSecondaryPrice'"
      [dispersedKey]="dispersedKey"
      [align]="'left'"
      (valueChanged)="viewModel.connectToMinSecondaryPrice($event)">
    </lib-reactive-form-currency>

    <lib-reactive-form-currency
      [inputName]="'maxSecondaryPrice'"
      [label]="'Maximum Secondary Price'"
      [placeholder]="'Maximum Secondary price of products'"
      [inlineLabel]="false"
      [required]="false"
      [minValue]="viewModel.minSecondaryPrice$ | push"
      [disabled]="viewModel.isViewOnly$ | push"
      [tooltip]="'The smart filter will include products with a secondary price less than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'maxSecondaryPrice'"
      [dispersedKey]="dispersedKey"
      [align]="'left'"
      (valueChanged)="viewModel.connectToMaxSecondaryPrice($event)">
    </lib-reactive-form-currency>
  </lib-reactive-form-column-layout>
</div>
