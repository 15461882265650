import { Injectable } from '@angular/core';
import { DisplayableContentContainerViewModel } from './displayable-content-container-view-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { AnimatorService } from '../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { shareReplay, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';

@Injectable()
export abstract class MenusContentContainerViewModel extends DisplayableContentContainerViewModel {

  protected constructor(
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    userDomainModel: UserDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute
  ) {
    super(
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  abstract getMenuTypeFromSelectedTab(selectedTab: number): MenuType;

  public readonly locationText$ = of(null);

  public readonly tags$ = this.selectedTab$.pipe(
    switchMap(selectedTab => this.menuDomainModel.getMenuTagsFor(this.getMenuTypeFromSelectedTab(selectedTab))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
