<div class="smart-filter-card">
  <div class="content-container">
    <img
      class="smart-filter-category-icon"
      alt="filter-icon"
      [ngClass]="{ 'smart-filter-category-icon-background': smartFilter.locationId === -1 }"
      [src]="
        smartFilter.locationId === -1
          ? 'assets/icons/light/outline/lightning-bolt.svg'
          : 'assets/icons/dark/outline/lightning-bolt.svg'
      " />
    <div class="text-container ms-2">
      <div class="smart-filter-name">{{ smartFilter.name }}</div>
    </div>
    <img class="x-icon" (click)="removeSmartFilter()" [src]="'assets/icons/dark/solid/x.svg'" alt="x icon" />
  </div>
</div>
