<div class="bs-bold f16px mt-16px mb-8px">Include changes since previous print job</div>
<lib-reactive-form-group>
  <ng-container *rxFor="let stack of viewModel.selectedCardStacksWithPreviousJobs$ | push; trackBy: 'id'">
    <div
      *rxIf="!viewOnly || (job | stackPrintJobIncludeChangesSince : stack)"
      class="d-flex justify-content-between align-items-center gap-3 mb-16px">
      <div class="d-flex align-items-center gap-1 w-50">
        <span *rxIf="stack?.templateId; else stackIcon">
          <img src="assets/icons/dark/solid/template.svg" alt="Templated Menu" [style.height]="'1rem'" />
        </span>
        <ng-template #stackIcon>
          <span>
            <img src="assets/icons/dark/outline/category-cards.svg" alt="Stacked Menu" [style.height]="'1rem'" />
          </span>
        </ng-template>
        <span class="f12px bs-medium flex">{{ stack?.name }}</span>
      </div>
      <div class="w-50">
        <lib-reactive-form-drop-down
          [required]="true"
          [hideLabel]="true"
          [inputName]="stack?.id"
          [placeholder]="(viewModel.viewOnly$ | push) ? 'Archived Print Job' : ''"
          [disabled]="viewModel.viewOnly$ | push"
          [programmaticallyChangeValue]="
            viewModel.successfulLocationBulkPrintJobs$ | preSelectedPreviousJobId : viewModel.job$ : stack?.id | push
          "
          [dropdowns]="viewModel.successfulLocationBulkPrintJobs$ | MenuPrintJobs : stack?.id | push"
          (valueChanged)="handleDropdownValueChange(stack?.id, $event)">
        </lib-reactive-form-drop-down>
      </div>
    </div>
  </ng-container>
  <app-all-stack-cards-printed
    *rxIf="(viewModel.allStackCardsPrintedList$ | push)?.length"
    [stacks]="viewModel.allStackCardsPrintedList$ | push : 'immediate'"
    [description]="viewModel.allStackCardsPrintedDescription$ | push : 'immediate'">
  </app-all-stack-cards-printed>
</lib-reactive-form-group>
