import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateLabelStackMenuViewModel } from './edit-template-label-stack-menu-view-model';
import { EditLabelStackMenuViewModel } from '../../../../menu/components/edit-menu/edit-label-stack-menu/edit-label-stack-menu-view-model';
import { EditLabelStackSectionViewModel } from '../../../../menu/components/edit-menu/edit-label-stack-menu/edit-label-stack-section-view-model';
import { EditTemplateLabelStackMenuCombinedLoadingOptsViewModel } from './edit-template-label-stack-menu-combined-loading-opts-view-model';
import { EditTemplateCardStackMenuComponent } from '../edit-template-card-stack-menu/edit-template-card-stack-menu.component';

@Component({
  selector: 'app-edit-template-label-stack-menu',
  templateUrl: './edit-template-label-stack-menu.component.html',
  styleUrls: ['./edit-template-label-stack-menu.component.scss'],
  providers: [
    EditTemplateLabelStackMenuViewModel,
    EditLabelStackMenuViewModel,
    EditLabelStackSectionViewModel,
    EditTemplateLabelStackMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLabelStackMenuComponent extends EditTemplateCardStackMenuComponent {

  constructor(
    public override viewModel: EditTemplateLabelStackMenuViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateLabelStackMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
