<div>
  <div class="smart-filter-variants-filter-container">
    <lib-reactive-search-bar
      [searchThrough]="viewModel.variants$ | push"
      [searchTheseProperties]="['name', 'THC', 'minTHC', 'maxTHC', 'CBD', 'minCBD', 'maxCBD', 'price']"
      (searchText)="viewModel.connectToSearchString($event)"
      (searchHits)="viewModel.connectToSearchedVariants($event)">
    </lib-reactive-search-bar>
  </div>
  <div class="smart-filter-variants-data-table-container">
    <lib-reactive-form-group [dispersedKey]="viewModel.dispersedKey$ | push">
      <app-smart-filter-variants-data-table [variants]="viewModel.searchedVariants$ | push" [nItemsToDisplay]="25">
      </app-smart-filter-variants-data-table>
    </lib-reactive-form-group>
  </div>
</div>
