import type { HydratedVariantBadge } from '../../../models/product/dto/hydrated-variant-badge';
import type { Variant } from '../../../models/product/dto/variant';
import { SortUtils } from '../../../utils/sort-utils';

export class TableBadgeUtils {

  static getAllBadgesForVariants(variants: Variant[]): HydratedVariantBadge[] {
    const locationBadges = variants
      ?.flatMap((variant) => variant?.getLocationBadges())
      ?.uniqueByProperty('id')
      ?.sort(SortUtils.sortBadgesByNameAscending);
    const companyBadges = () => variants
      ?.flatMap((variant) => variant?.getCompanyBadges())
      ?.uniqueByProperty('id')
      ?.sort(SortUtils.sortBadgesByNameAscending);
    return locationBadges?.length ? locationBadges : companyBadges() ?? [];
  }

}
