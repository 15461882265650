<div class="reset-default" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    class="text-link"
    [popper]="popperContent"
    [popperShowOnStart]="false"
    [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
    [popperModifiers]="popperModifier"
    [popperHideOnClickOutside]="true"
    [popperHideOnScroll]="false"
    [popperPreventOverflow]="true"
    [popperPositionFixed]="true"
    [popperApplyClass]="'options-picker-popper'"
    [popperStyles]="popperStyles"
    [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMEND">
    Reset to Default
  </div>
  <popper-content #popperContent [style.z-index]="1500">
    <app-default-column-options-picker
      class="default-picker"
      [themeDefault]="themeDefault"
      (selected)="resetClicked.emit($event)"></app-default-column-options-picker>
  </popper-content>
</div>
