import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';
import { PrintLabelTemplatesViewModel } from './print-label-templates-view-model';

@Component({
  selector: 'app-print-label-templates',
  templateUrl: './print-label-templates.component.html',
  styleUrls: ['./print-label-templates.component.scss'],
  providers: [PrintLabelTemplatesViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintLabelTemplatesComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintLabelTemplatesViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

  override setupBindings() {
  }

  override setupViews() {
  }

}
