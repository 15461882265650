import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuSectionSmartFiltersViewModel } from './menu-section-smart-filters-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { SmartFilterAddedToSectionSharedViewModel } from '../../../../../smart-filters/components/smart-filter-added-to-section/smart-filter-added-to-section-shared-view-model';

@Component({
  selector: 'app-menu-section-smart-filters',
  templateUrl: './menu-section-smart-filters.component.html',
  styleUrls: ['./menu-section-smart-filters.component.scss'],
  providers: [MenuSectionSmartFiltersViewModel, SmartFilterAddedToSectionSharedViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionSmartFiltersComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: MenuSectionSmartFiltersViewModel,
    public addedSmartFiltersSharedViewModel: SmartFilterAddedToSectionSharedViewModel,
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() autoSaving: boolean;
  @Input() mergeKey: string;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() isTemplatedSection: boolean = false;
  @Input() formPristine: boolean = true;

  override setupViews() {
    this.viewModel.connectToMenu(this.menu);
    this.addedSmartFiltersSharedViewModel.connectToMenu(this.menu);
    this.viewModel.connectToSection(this.section);
    this.addedSmartFiltersSharedViewModel.connectToSection(this.section);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) {
      this.viewModel.connectToMenu(this.menu);
      this.addedSmartFiltersSharedViewModel.connectToMenu(this.menu);
    }
    if (changes.section) {
      this.viewModel.connectToSection(this.section);
      this.addedSmartFiltersSharedViewModel.connectToSection(this.section);
    }
    if (changes.isTemplatedSection) this.viewModel.connectToIsTemplatedSection(this.isTemplatedSection);
  }

}
