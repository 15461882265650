import { BaseModalViewModel } from '../../../../../../../models/base/base-modal-view-model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Orderable } from '../../../../../../../models/protocols/orderable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { Label, LocationPriorityOffset, UnsetLabelPriority } from '../../../../../../../models/shared/label';
import { SortUtils } from '../../../../../../../utils/sort-utils';
import { LabelStyle } from '../../../../../../../models/enum/shared/label-style.enum';
import { SaleLabelFormat } from '../../../../../../../models/utils/dto/sale-label-format-type';

@Injectable()
export class LabelPriorityEditorModalViewModel extends BaseModalViewModel {

  private _originalLabels = new BehaviorSubject<Label[]>(null);

  private _labels = new BehaviorSubject<Label[]>(null);
  public labels$ = this._labels as Observable<Label[]>;
  public connectToLabels = (ls: Label[]) => {
    this._labels.next(ls.deepCopy());
    this._originalLabels.next(ls.deepCopy());
  };

  private _changesMade = new BehaviorSubject<boolean>(null);
  public changesMade$ = this._changesMade as Observable<boolean>;

  private _allowUnsetPriority = new BehaviorSubject<boolean>(true);
  public allowUnsetPriority$ = this._allowUnsetPriority as Observable<boolean>;
  public connectToAllowUnsetPriority = (allow: boolean) => this._allowUnsetPriority.next(allow);

  private _activeLabelStyle = new BehaviorSubject<LabelStyle>(null);
  public activeLabelStyle$ = this._activeLabelStyle as Observable<LabelStyle>;
  public connectToActiveLabelStyle = (s: LabelStyle) => this._activeLabelStyle.next(s);

  private _activeSaleLabelFormat = new BehaviorSubject<SaleLabelFormat>(null);
  public activeSaleLabelFormat$ = this._activeSaleLabelFormat as Observable<SaleLabelFormat>;
  public connectToActiveSaleLabelFormat = (s: SaleLabelFormat) => this._activeSaleLabelFormat.next(s);

  public supportsLocationLabels$ = this.companyDomainModel.companySupportsLocationLabels$;

  public reorderCardClearable$ = combineLatest([
    this.allowUnsetPriority$,
    this.supportsLocationLabels$,
  ]).pipe(
    map(([allowUnset, supportsLocationLables]) => {
      return allowUnset && supportsLocationLables;
    })
  );

  public lockedLabels$ = this.labels$.pipe(
    map(labels => {
      return labels.filter(l => l?.isCompanyManaged && l?.labelPriorityIsCompanyDefault())
        .sort(SortUtils.sortLabelsByPriority);
    })
  );

  public draggableLabels$ = new BehaviorSubject<Label[]>([]);

  public showDragInstructions$ = combineLatest([
    this.lockedLabels$,
    this.draggableLabels$
  ]).pipe(
    map(([locked, draggable]) => {
      return locked?.length > 0 || draggable?.length > 0;
    })
  );

  private filterDraggable = combineLatest([
    this.labels$,
    this.allowUnsetPriority$,
    this.supportsLocationLabels$,
  ]).subscribeWhileAlive({
    owner: this,
    next: ([labels, allowUnsetPriority, supportsLocationLabels]) => {
      let filtered: Label[];
      if (allowUnsetPriority) {
        // Unset priority is only allowed for company labels where location overrides are supported.
        // Do not include the unset priority label in the draggable list.
        filtered = labels?.filter(l => l?.priority !== UnsetLabelPriority);
      } else if (supportsLocationLabels) {
        // Location Order
        // Labels can be reordered if they do nt have a company default order set OR have a location priority set
        filtered = labels?.filter(l => l?.priority === UnsetLabelPriority || l?.priority >= LocationPriorityOffset);
      } else {
        // Company Order where location override is not enabled
        // All labels can be reordered and require an order to be set
        filtered = labels;
      }
      this.draggableLabels$.next(filtered?.sort(SortUtils.sortLabelsByPriority) || []);
    }
  });

  public editableLabels$ = combineLatest([
    this.labels$,
    this.draggableLabels$,
    this.lockedLabels$,
    this.allowUnsetPriority$
  ]).pipe(
    map(([labels, draggableLabels, lockedLabels, allowUnsetPriority]) => {
      if (allowUnsetPriority) {
        return labels?.filter(l => !lockedLabels?.includes(l) && !draggableLabels?.includes(l));
      } else {
        return null;
      }
    })
  );

  constructor(
    private activeModal: NgbActiveModal,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public drop(event: CdkDragDrop<Orderable[]>): void {
    combineLatest([
      this.draggableLabels$,
      this.locationDomainModel.locationId$
    ]).pipe(take(1)).subscribe(([draggableLabels, locId]) => {
      const copy = draggableLabels.shallowCopy();
      moveItemInArray(copy, event.previousIndex, event.currentIndex);
      copy.forEach((item, index) => {
        const p = item.locationId === locId ? index + LocationPriorityOffset : index;
        item.setOrderableValue(p);
      });
      this.draggableLabels$.next(copy.sort(SortUtils.sortLabelsByPriority));
      this._changesMade.next(true);
    });
  }

  public savePriorityChanges(): void {
    combineLatest([
      this.labels$,
      this._originalLabels
    ]).pipe(take(1)).subscribe(([editedLabels, originalLabels]) => {
      const labelsWithPriorityChanges = editedLabels?.filter(item => !originalLabels?.includes(item));
      if (labelsWithPriorityChanges.length > 0) {
        this.activeModal.close(labelsWithPriorityChanges);
      } else {
        this.activeModal.dismiss();
      }
    });
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public makeCardEditable(l: Label): void {
    this.draggableLabels$.pipe(take(1)).subscribe(labels => {
      const nextPrio = labels?.last()?.priority ?? 0;
      l.priority = nextPrio + 1;
      this.findAndReplaceLabels(l);
    });
  }

  public makeCardNonEditable(l: Label): void {
    l.priority = UnsetLabelPriority;
    this.findAndReplaceLabels(l);
  }

  private findAndReplaceLabels(l: Label): void {
    this.labels$.pipe(take(1)).subscribe(labels => {
      const labelsCopy = labels.shallowCopy();
      const i = labelsCopy.findIndex(ls => ls?.id === l.id && ls?.locationId === l?.locationId);
      if (i > -1) {
        labelsCopy.splice(i, 1);
      }
      labelsCopy.push(l);
      this._labels.next(labelsCopy);
    });
    this._changesMade.next(true);
  }

}
