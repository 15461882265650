import { TypeDefinition } from './type-definition';

export enum BulkJobSource {
  BulkJobSource_BulkPrint = 'BulkJobSource_BulkPrint',
  BulkJobSource_EditCardStack = 'BulkJobSource_EditCardStack'
}

export class BulkJobSourceType extends TypeDefinition {

  override value: BulkJobSource;

}
