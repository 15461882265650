<div class="row mt-24px">
  <div class="col-4">
    <p class="bs-section-subtitle">Media</p>
    <div class="bs-section-subtext mt-16px">Upload at least one image or video to appear on the store display.</div>
    <div fxLayout="row wrap" class="mt-16px" [style.gap.rem]="0.5">
      <lib-button-neutral
        *ngIf="!(sharedVM?.isTemplatedMenu$ | async)"
        [iconSrc]="'assets/icons/dark/outline/photograph.svg'"
        [disabled]="formHasErrors || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="addNewMediaClicked(uploadDiv)">
        Add Media
      </lib-button-neutral>
      <lib-button-neutral
        *ngIf="(sharedVM?.orderedMedia$ | async)?.length > 1 && !(sharedVM?.isTemplatedMenu$ | async)"
        [iconSrc]="'assets/icons/dark/outline/photograph.svg'"
        (buttonClicked)="reorderMediaClicked()">
        Reorder Media
      </lib-button-neutral>
    </div>
  </div>
  <div class="col-8">
    <app-menu-media
      *ngFor="let media of sharedVM?.orderedMedia$ | async; trackBy: sharedVM.trackMediaBy; let i = index"
      [isDisabledByTemplates]="sharedVM?.isTemplatedMenu$ | async"
      [mergeKey]="mergeKey"
      [sharedViewModel]="sharedVM"
      [destructiveButtonText]="'Remove Media'"
      [file]="media.asset"
      [index]="i"
      (deleteMedia)="viewModel?.deleteMedia($event)"
      [menu]="viewModel?.sharedViewModel.menu$ | async"
      [formPristine]="formPristine">
    </app-menu-media>
    <div #uploadDiv class="file-upload-container">
      <app-upload-asset
        #mediaUpload
        *ngIf="!(sharedVM?.isTemplatedMenu$ | async)"
        [allowImage]="true"
        [allowVideo]="true"
        [displayList]="false"
        [id]="0"
        [loadingOpts]="sharedVM?.fileUploadLoadingOpts | async"
        [maxAssets]="5"
        [parentHandler]="this"
        [formPristine]="formPristine">
      </app-upload-asset>
    </div>
  </div>
</div>
