import { Component, ViewChild } from '@angular/core';
import { WebViewModel } from './web-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss'],
  providers: [WebViewModel]
})
export class WebComponent extends BaseComponent {

  constructor(
    public viewModel: WebViewModel,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  @ViewChild('tabController') tabController: TabBarComponent;

  override setupBindings(): void {
    // Capture the fragment if available and navigates to a specific tab
    const fragSub = this.activatedRoute.fragment.notNull().subscribe(fragment => {
      switch (fragment?.toLowerCase()) {
        case 'productmenus': this.tabController.tabSelected(0); break;
      }
    });
    this.pushSub(fragSub);
  }

}
