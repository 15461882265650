import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { EditFeaturedCategoryMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Section } from '../../../../../models/menu/dto/section';
import { ColorUtils } from '../../../../../utils/color-utils';

@Injectable()
export class FeaturedCategoryViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private sharedViewModel: EditFeaturedCategoryMenuViewModel;
  private _sharedViewModel = new BehaviorSubject<EditFeaturedCategoryMenuViewModel>(null);
  public sharedViewModel$ = this._sharedViewModel.asObservable();
  private _section = new BehaviorSubject<Section>(null);
  public section$ = this._section as Observable<Section>;
  public hasSmartFilters$ = this.section$.pipe(map(s => s?.hasSmartFilters()));
  public cardNumberBackgroundColor$ = this.section$.pipe(
    map(section => section?.metadata?.productsHeaderBackgroundColor || ColorUtils.BUDSENSE_BLACK_COLOR)
  );
  public cardNumberTextColor$ = this.cardNumberBackgroundColor$.pipe(
    map(backgroundColor => {
      return ColorUtils.isDarkColor(backgroundColor)
        ? ColorUtils.BUDSENSE_WHITE_COLOR
        : ColorUtils.BUDSENSE_BLACK_COLOR;
    })
  );

  // Index
  public _index = new BehaviorSubject<number>(null);
  public index$ = this._index.asObservable();
  public cardNumber$ = combineLatest([
    this.sharedViewModel$.pipe(switchMap(vm => vm?.cardStartAt$)),
    this.index$,
  ]).pipe(
    map(([startAt, index]) => {
      if (isNaN(parseInt(startAt, 10)) || parseInt(startAt, 10) < 1) {
        return index + 1;
      } else {
        return parseInt(startAt, 10) + index;
      }
    })
  );

  init(sharedVM?: EditFeaturedCategoryMenuViewModel) {
    if (sharedVM) {
      this.sharedViewModel = sharedVM;
      this._sharedViewModel.next(sharedVM);
    }
  }

  public connectToIndex = (i: number) => this._index.next(i);
  public connectToSection = (s: Section) => this._section.next(s);

}
