import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from '../edit-template-marketing-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingFeaturedCategoryMenuViewModel } from './edit-template-marketing-featured-category-menu-view-model';
import { EditFeaturedCategoryMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class EditTemplateMarketingFeaturedCategoryMenuCombinedLoadingOptsViewModel
  extends EditTemplateMarketingMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingFeaturedCategoryMenuViewModel: EditTemplateMarketingFeaturedCategoryMenuViewModel,
    protected editFeaturedCategoryMenuViewModel: EditFeaturedCategoryMenuViewModel,
  ) {
    super(editTemplateMarketingFeaturedCategoryMenuViewModel, editFeaturedCategoryMenuViewModel);
  }

}
