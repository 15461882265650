import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { DisplayableContentContainerViewModel } from '../displayable-content-container/displayable-content-container-view-model';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SortUtils } from '../../../../../utils/sort-utils';
import { DisplayableItem } from '../displayable-item-container/displayable-item-preview/displayable-item';
import { DisplayableType } from '../../../../../models/enum/shared/displayableType.enum';
import { DisplayableItemFilterByActive } from '../../../../../models/enum/shared/displayable-item-filter-by.active';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { NavigationService } from '../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';
import { ModalCreateMenu } from '../../../../../modals/modal-create-menu';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';

export abstract class DisplayableItemsViewModel extends BaseViewModel {

  public readonly ITEMS_PER_PAGE = 15;
  public readonly MAX_PAGES = 10;

  protected constructor(
    protected containerViewModel: DisplayableContentContainerViewModel,
    protected navigationService: NavigationService,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
    this.generateMenuTypeOptions();
    this.generateInactiveStateFilterOptions();
    this.listenForEmptyState();
  }

  protected abstract itemsToFilter$: Observable<DisplayableItem[]>;

  protected abstract setEmptyState(
    searchText: string,
    tag: string,
    filterInactiveMenus: DisplayableItemFilterByActive
  );

  public filterByTag$ = this.containerViewModel.activeTagString$;

  protected inactiveItemsFilter$ = this.containerViewModel.selectFilterByInactiveState$;

  protected _emptyStateTitle = new BehaviorSubject<string>('');
  public emptyStateTitle$ = this._emptyStateTitle as Observable<string>;

  protected _emptyStateDesc = new BehaviorSubject<string>('');
  public emptyStateDesc$ = this._emptyStateDesc as Observable<string>;

  private _filteredDisplayableItems = new BehaviorSubject<DisplayableItem[]>(null);
  public filteredDisplayableItems$ = this._filteredDisplayableItems as Observable<DisplayableItem[]>;
  connectToFilteredDisplayableItems = (items: DisplayableItem[]) => this._filteredDisplayableItems.next(items);

  private _paginatedItems = new BehaviorSubject<DisplayableItem[]>(null);
  public paginatedItems$ = this._paginatedItems as Observable<DisplayableItem[]>;
  public connectToPaginatedItems = (paginatedItems: DisplayableItem[]) => this._paginatedItems.next(paginatedItems);

  public selectedDisplayableType$ = this.containerViewModel.selectedDisplayableType$;

  public numberOfItems$ = this.filteredDisplayableItems$.pipe(
    distinctUntilChanged(),
    map(m => m?.length),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public noItems$ = this.filteredDisplayableItems$.pipe(
    distinctUntilChanged(),
    map(m => m?.length === 0 || !m),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private generateMenuTypeOptions() {
    const dropdownOptions: DropDownItem[] = [];
    const all = new DropDownItem('All', DisplayableType.All);
    const menus = new DropDownItem('Menus', DisplayableType.NonTemplatedMenu);
    const templates = new DropDownItem('Templates', DisplayableType.TemplatedMenu);
    dropdownOptions.push(all, menus, templates);
    this.containerViewModel.connectToMenuTypeFilterOptions(dropdownOptions);
  }

  protected generateInactiveStateFilterOptions() {
    const dropdownOptions: DropDownItem[] = [];
    const all = new DropDownItem('All', DisplayableItemFilterByActive.All);
    const active = new DropDownItem('Active', DisplayableItemFilterByActive.Active);
    const inactive = new DropDownItem('Inactive', DisplayableItemFilterByActive.Inactive);
    dropdownOptions.push(all, active, inactive);
    this.containerViewModel.connectToInactiveStateFilterOptions(dropdownOptions);
  }

  public listenForEmptyState(): void {
    combineLatest([
      this.containerViewModel.searchText$,
      this.containerViewModel.activeTagString$,
      this.inactiveItemsFilter$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([searchText, tag, filterInactiveMenus]) => this.setEmptyState(searchText, tag, filterInactiveMenus)
    });
  }

  // Call from within child, else itemsToFilter$ will be undefined
  protected setupFilter(): void {
    this.startFiltering();
    this.itemsToFilter$.subscribeWhileAlive({
      owner: this,
      next: (items: DisplayableItem[]) => {
        this.containerViewModel.connectToItemsToFilter(items);
      }
    });
  }

  private startFiltering(): void {
    combineLatest([
      this.containerViewModel.searchedItems$,
      this.filterByTag$,
      this.inactiveItemsFilter$,
      this.selectedDisplayableType$
    ]).pipe(debounceTime(10))
      .subscribeWhileAlive({
        owner: this,
        next: ([displayableItems, tagString, inactiveFilter, displayableType]) => {
          let filteredItems: DisplayableItem[] = displayableItems;
          if (!!tagString) {
            filteredItems = filteredItems?.filter(item => item?.tag?.toUpperCase() === tagString?.toUpperCase());
          }
          switch (displayableType) {
            case DisplayableType.NonTemplatedMenu:
              filteredItems = filteredItems?.filter(item => !item.displayableItemIsTemplatedMenu());
              break;
            case DisplayableType.TemplatedMenu:
              filteredItems = filteredItems?.filter(item => item.displayableItemIsTemplatedMenu());
              break;
          }
          switch (inactiveFilter) {
            case DisplayableItemFilterByActive.Active:
              filteredItems = filteredItems?.filter(item => item?.displayableItemIsActive());
              break;
            case DisplayableItemFilterByActive.Inactive:
              filteredItems = filteredItems?.filter(item => !item?.displayableItemIsActive());
              break;
          }
          filteredItems = filteredItems?.sort(SortUtils.menusByNameAsc);
          this.connectToFilteredDisplayableItems(filteredItems);
        }
      });
  }

  protected updateEmptyStateHelper(
    [searchText, tag, filterInactiveMenus]: [string, string, DisplayableItemFilterByActive],
    emptyStateLambdas: {
      noSearchTextNoTag: () => void,
      noSearchTextWithTag: () => void,
      searchTextNoTag: () => void,
      searchTextWithTag: () => void
    }
  ): void {
    switch (true) {
      case !searchText && !tag:
        emptyStateLambdas?.noSearchTextNoTag?.();
        break;
      case !searchText && tag?.length > 0:
        emptyStateLambdas?.noSearchTextWithTag?.();
        break;
      case searchText?.length > 0 && !tag:
        emptyStateLambdas?.searchTextNoTag?.();
        break;
      case searchText?.length > 0 && tag?.length > 0:
        emptyStateLambdas?.searchTextWithTag?.();
        break;
    }
  }

  protected displayableItemFilterBySwitchCase(data: {
    filterDisplayableItemBy: DisplayableItemFilterByActive,
    all: () => void,
    active: () => void,
    inactive: () => void
  }): void {
    switch (data?.filterDisplayableItemBy) {
      case DisplayableItemFilterByActive.Active:
        data?.active?.();
        break;
      case DisplayableItemFilterByActive.Inactive:
        data?.inactive?.();
        break;
      default:
        data?.all?.();
        break;
    }
  }

  protected createMenu(mt: MenuType = MenuType.DisplayMenu): void {
    const onClose = (menu: Menu) => {
      if (menu) {
        this.navigationService.navigateToEditMenuOrTemplate(menu);
      }
    };
    ModalCreateMenu.open(this.ngbModal, this.injector, mt, onClose);
  }

}
