import { Deserializable } from '../protocols/deserializable';

export class DisplayableSubItem implements Deserializable {

  public name: string;
  public iconSrc: string;
  public id: string;

  constructor(name: string, iconSrc: string, id: string = null) {
    this.name = name;
    this.iconSrc = iconSrc;
    this.id = id;
  }

  onDeserialize() {
  }

}
