import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { CardStackPrintConfig } from '../../../../../../../models/automation/card-stack-print-config';

@Component({
  selector: 'app-stack-smart-bulk-print-config-form',
  templateUrl: './stack-smart-bulk-print-config-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartBulkPrintConfigFormComponent {

  @Input() configMap: Map<string, CardStackPrintConfig>;
  @Input() job: BulkPrintJob;
  @Input() mergeKey: string;
  @Input() viewOnly: boolean;

}
