import { Deserializable } from '../../protocols/deserializable';
import { Display } from '../../display/dto/display';

export class Device implements Deserializable {

  public companyId: number;
  public connectedAt: number;
  public locationId: number;
  public name: string;
  public id: string;
  public lastRegister: number;
  public code: string;
  public displayId: string;
  public display: Display;
  public macAddress: string;
  public serialNumber: string;

  static newDevice(): Device {
    const d = new Device();
    d.name = '';
    d.code = '';
    d.displayId = '';
    d.display = null;
    return d;
  }

  public onDeserialize() {
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/DeviceDTO.go
  public onSerialize() {
    const dto = Object.create(Device.prototype);
    dto.companyId = this.companyId;
    dto.connectedAt = this.connectedAt;
    dto.locationId = this.locationId;
    dto.id = this.id;
    dto.name = this.name;
    dto.lastRegister = this.lastRegister;
    dto.code = this.code;
    dto.displayId = this.displayId;
    dto.macAddress = this.macAddress;
    dto.serialNumber = this.serialNumber;
    return dto;
  }

}
