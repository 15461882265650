<div class="badge-container" fxLayout="column" [ngStyle]="{ cursor: editable ? 'pointer' : 'default' }">
  <div class="badge-img">
    <app-loading *ngIf="loadingOptions.isLoading" [options]="loadingOptions"></app-loading>
    <app-asset
      *ngIf="!loadingOptions.isLoading"
      [asset]="viewModel?.badge?.image"
      [scaleFit]="true"
      [cachePolicy]="ClientTypes.CachePolicy.Service"
      [borderRadius]="'0.625rem'">
    </app-asset>
    <div
      *ngIf="editable"
      matRipple
      appThrottleClick
      class="editable-container"
      [throttleInMilliSec]="1000"
      (throttleClick)="editClicked.emit(this.badge)">
      <div class="editable"></div>
      <div class="editable-text-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="editable-text">Edit</div>
      </div>
    </div>
    <app-smart-filters-indicator
      *ngIf="!!viewModel.badge?.smartFilterIds?.length && showSmartFilterIndicator"
      [hideNumberIfOneSmartFilter]="true"
      [nSmartFilters]="viewModel?.badge?.smartFilterIds?.length"></app-smart-filters-indicator>
    <app-remove-button
      *ngIf="deletable"
      appThrottleClick
      class="remove-button"
      [width]="'1rem'"
      [height]="'1rem'"
      [throttleInMilliSec]="1000"
      (throttleClick)="deleteBadge()">
    </app-remove-button>
  </div>
  <div class="badge-text mt-8px">{{ viewModel.badge.name }}</div>
</div>
