import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../../models/menu/dto/section';

@Pipe({
  name: 'getSectionPreviewTitle'
})
export class GetSectionPreviewTitlePipe implements PipeTransform {

  transform(section: Section): string {
    return section?.getSectionTitle();
  }

}
