import { Deserializable } from '../../protocols/deserializable';
import { DateUtils } from '../../../utils/date-utils';
import { LabelStyle } from '../../enum/shared/label-style.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { SaleLabelFormat } from '../../utils/dto/sale-label-format-type';
import { InventoryReceivingWindow } from './inventory-receiving-window';
import { PriceFormat } from '../../utils/dto/price-format-type';
import type { SectionSortOption } from '../../utils/dto/section-sort-type';
import { Observable, of } from 'rxjs';
import { VariantType } from '../../utils/dto/variant-type-definition';
import { TaxGroupToVariantTypesReq } from '../shared/tax-group-to-variant-types-req';
import { window } from 'ngx-bootstrap/utils';

export class LocationConfiguration implements Deserializable, UniquelyIdentifiable {

  public companyId: number;
  public locationId: number;
  public saleLabelFormat: SaleLabelFormat;
  public labelStyle: LabelStyle;
  public priceFormat: PriceFormat;
  public salePriceFontStyle: string;
  public salePriceFontColor: string;
  public smartFilterIgnoredVariants: string[]; // Variant IDs;
  public inventoryReceivingWindow: InventoryReceivingWindow;
  public primarySorting: SectionSortOption; // defaults to brand
  public secondarySorting: SectionSortOption; // defaults to THC
  public secondaryPriceGroupId: string;
  public defaultTaxGroup: string;
  public variantTaxGroups: Map<VariantType, string>;
  // Sync Configuration
  public lastSmartFilterSync: number;
  public lastSmartDisplayAttributeSync: number;
  public lastProductSync: number;
  public lastInventorySync: number;
  public lastLotInfoSync: number;
  public lastPricingSync: number;
  public lastLabelSync: number;
  public dateCreated: number;
  // Last modified is used to determine if the user's location config is out of sync when performing update
  public lastModified: number;

  // Client only properties
  public formattedLastSmartFilterSync: string;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    const inventoryReceivingWindow = this.inventoryReceivingWindow ?? new InventoryReceivingWindow();
    this.inventoryReceivingWindow = Deserialize?.instanceOf(InventoryReceivingWindow, inventoryReceivingWindow);
    this.formattedLastSmartFilterSync = DateUtils.formatUnixToLastSyncTime(this.lastSmartFilterSync);
    this.variantTaxGroups = Deserialize?.genericMap(this.variantTaxGroups) ?? new Map();
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/LocationConfigurationDTO.go
  onSerialize() {
    const dto = Object.create(LocationConfiguration.prototype);
    dto.companyId = this.companyId;
    dto.locationId = this.locationId;
    dto.saleLabelFormat = this.saleLabelFormat;
    dto.labelStyle = this.labelStyle;
    dto.priceFormat = this.priceFormat;
    dto.salePriceFontStyle = this.salePriceFontStyle;
    dto.salePriceFontColor = this.salePriceFontColor;
    dto.smartFilterIgnoredVariants = this.smartFilterIgnoredVariants;
    dto.inventoryReceivingWindow = this.inventoryReceivingWindow;
    dto.primarySorting = this.primarySorting;
    dto.secondarySorting = this.secondarySorting;
    dto.defaultTaxGroup = this.defaultTaxGroup;
    dto.variantTaxGroups = this.variantTaxGroups;
    dto.lastSmartFilterSync = this.lastSmartFilterSync;
    dto.lastSmartDisplayAttributeSync = this.lastSmartDisplayAttributeSync;
    dto.lastProductSync = this.lastProductSync;
    dto.lastInventorySync = this.lastInventorySync;
    dto.lastLotInfoSync = this.lastLotInfoSync;
    dto.lastPricingSync = this.lastPricingSync;
    dto.lastLabelSync = this.lastLabelSync;
    dto.dateCreated = this.dateCreated;
    dto.lastModified = this.lastModified;
    return dto;
  }

  private syncTimeChanged(updated: LocationConfiguration): boolean {
    if (!updated) return false;
    const productSyncDifferent = this.lastProductSync !== updated?.lastProductSync;
    const inventorySyncDifferent = this.lastInventorySync !== updated?.lastInventorySync;
    const pricingSyncDifferent = this.lastPricingSync !== updated?.lastPricingSync;
    const lotInfoSyncDifferent = this.lastLotInfoSync !== updated?.lastLotInfoSync;
    const labelSyncDifferent = this.lastLabelSync !== updated?.lastLabelSync;
    return productSyncDifferent
      || inventorySyncDifferent
      || pricingSyncDifferent
      || lotInfoSyncDifferent
      || labelSyncDifferent;
  }

  public changesRequireProductRefresh$(updated: LocationConfiguration): Observable<boolean> {
    return of(this.syncTimeChanged(updated));
  }

  setPropertiesFromTaxGroupVariantTypesReq(req: TaxGroupToVariantTypesReq): LocationConfiguration {
    this.variantTaxGroups.clear();
    req.getAsMap().forEach((value, key) => {
      this.variantTaxGroups.set(key, value);
    });
    return this;
  }

  getUniqueIdentifier(): string {
    const inventoryReceivingWindowId = this.inventoryReceivingWindow?.getUniqueIdentifier();
    const variantTaxGroupId: string[] = [];
    this.variantTaxGroups?.forEach((val, key) => variantTaxGroupId.push(`${key}-${val}`));
    return `
      -${this.companyId}
      -${this.locationId}
      -${this.saleLabelFormat}
      -${this.labelStyle}
      -${this.priceFormat}
      -${this.salePriceFontStyle}
      -${this.salePriceFontColor}
      -${this.smartFilterIgnoredVariants.sort().join(',')}
      -${inventoryReceivingWindowId}
      -${this.primarySorting}
      -${this.secondarySorting}
      -${this.lastSmartDisplayAttributeSync}
      -${this.lastSmartFilterSync}
      -${this.lastProductSync}
      -${this.lastInventorySync}
      -${this.lastLotInfoSync}
      -${this.lastPricingSync}
      -${this.lastLabelSync}
      -${this.dateCreated}
      -${this.lastModified}
      -${this.secondaryPriceGroupId}
      -${variantTaxGroupId?.join(',')}
    `;
  }

}
