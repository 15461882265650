import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-incomplete-product-card',
  templateUrl: './incomplete-product-card.component.html',
  styleUrls: ['./incomplete-product-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IncompleteProductCardComponent {

  @Input() card: Card;
  @Input() cardClass: string;

  constructor() {
  }

}
