import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EditStaticSectionMenuViewModel } from '../shared/edit-static-section-menu/edit-static-section-menu-view-model';

@Injectable()
export class EditSpotlightMenuViewModel extends EditStaticSectionMenuViewModel {

  public override showWillNotAppearOnMenuSection$ = new BehaviorSubject<boolean>(true);

}
