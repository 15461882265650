<thead class="header-container">
  <tr>
    <th resizable>
      <div class="header-title" (click)="nameSort.clicked()">
        {{ editPrintCardMode || editPrintLabelMode ? 'Print Job' : 'Bulk Print' }} Name
      </div>
      <lib-reactive-table-asc-desc-sort
        #nameSort
        fxFlex
        [ascSort]="nameAsc"
        [descSort]="nameDesc"></lib-reactive-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title default-cursor ms-2">Sharing</div>
      <div fxFlex></div>
    </th>
    <th resizable>
      <div class="header-title default-cursor ms-2">
        {{ 'Menus' | replaceMenuWithCardOrLabel : editPrintCardMode : editPrintLabelMode }}
      </div>
      <div fxFlex></div>
    </th>
    <th resizable>
      <div class="header-title ms-2" (click)="timeStartedSort.clicked()">
        {{ editPrintCardMode || editPrintLabelMode ? 'Print ' : '' }}Type
      </div>
      <lib-reactive-table-asc-desc-sort
        #timeStartedSort
        fxFlex
        [ascSort]="timeStartedAsc"
        [descSort]="timeStartedDesc"></lib-reactive-table-asc-desc-sort>
    </th>
    <th *ngIf="!(editPrintCardMode || editPrintLabelMode)" resizable>
      <div class="header-title ms-2" (click)="timeStartedSort.clicked()">Time Started</div>
      <lib-reactive-table-asc-desc-sort
        #timeStartedSort
        fxFlex
        [ascSort]="timeStartedAsc"
        [descSort]="timeStartedDesc"></lib-reactive-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title ms-2" (click)="timeCompletedSort.clicked()">Time Completed</div>
      <lib-reactive-table-asc-desc-sort
        #timeCompletedSort
        fxFlex
        [ascSort]="timeCompletedAsc"
        [descSort]="timeCompletedDesc"></lib-reactive-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title ms-2" (click)="statusSort.clicked()">Status</div>
      <lib-reactive-table-asc-desc-sort
        #statusSort
        fxFlex
        [ascSort]="statusAsc"
        [descSort]="statusDesc"></lib-reactive-table-asc-desc-sort>
    </th>
  </tr>
</thead>
