<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div
    class="sticky-header-container"
    appStickyAutoPosition
    [stickyCollectionKey]="'new-menu-modal'"
    [stickyOrder]="0"
    [style.overflow-y]="'unset'">
    <div class="modal-header">
      <div class="modal-title">
        {{ viewModel?.modalTitle$ | async }}
      </div>
    </div>
  </div>

  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <app-menu-creation-method
      *ngIf="viewModel.isHowToCreateStep$ | async"
      [paddingLeft]="'0'"
      [paddingRight]="'0'"
      (selectedCreationType)="viewModel.setMenuCreationType($event)">
    </app-menu-creation-method>
    <app-menu-type-picker
      *ngIf="viewModel.isPickMenuSubTypeStep$ | async"
      [menuType]="viewModel.menuType$ | async"
      [paddingLeft]="'0'"
      [paddingRight]="'0'"
      (selectedSubTypes)="viewModel.setMenuSubTypes($event)">
    </app-menu-type-picker>
    <lib-reactive-form-group
      #newMenuFormGroup
      [bindTo]="viewModel.newMenu$ | async"
      [dispersedKey]="viewModel.dispersedKey$ | async"
      (canSubmit$)="this.viewModel.connectToFormIsValid($event)"
      (formSubmitted$)="viewModel.createNewMenu()">
      <app-theme-picker
        *ngIf="viewModel.isPickMenuThemeStep$ | async"
        [menuType]="viewModel.menuType$ | async"
        [menuSubtypes]="viewModel.menuSubTypes$ | async"
        [tags]="viewModel.tags$ | async"
        [dispersedKey]="viewModel.dispersedKey$ | async"
        (themeSelected)="viewModel.connectToThemeSelected($event)"
        (stackSizeSelected)="viewModel.connectToSelectedStackSize($event)"
        (orientationSelected)="viewModel.connectToSelectedOrientation($event)">
      </app-theme-picker>
    </lib-reactive-form-group>
  </div>

  <app-searchable-single-select
    [hidden]="!(viewModel.isImportFromTemplateStep$ | async)"
    [searchableItems]="viewModel.selectableTemplates$ | async"
    [noItemsBody]="'No Templates'"
    [noItemsTitle]="'Templates will appear here'"
    [stickyCollectionKey]="'new-menu-modal'"
    [stickyOrder]="1"
    [stickyZ]="9"
    (selectedItem)="viewModel.setTemplate($event)">
  </app-searchable-single-select>
</div>

<div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 1rem">
  <div>
    <lib-button-text [hidden]="viewModel.hideGoBack$ | async" (buttonClicked)="viewModel.goBack()">
      Go Back
    </lib-button-text>
  </div>
  <div [style.margin-left]="'auto'">
    <lib-button-neutral [style.margin-right]="'0.5rem'" (buttonClicked)="cancel(null)"> Cancel </lib-button-neutral>
    <lib-button-primary
      *ngIf="!(viewModel.isHowToCreateStep$ | async) && !(viewModel.isPickMenuSubTypeStep$ | async)"
      [style.margin-left]="'0.5rem'"
      [disabled]="!(viewModel.canSubmitForm$ | async)"
      (buttonClicked)="newMenuFormGroup.submitForm()">
      Save
    </lib-button-primary>
  </div>
</div>
