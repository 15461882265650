import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { EditMarketingMenuViewModel } from '../../../../viewModels/edit-marketing-menu-view-model';

@Component({ selector: 'app-edit-marketing-menu-body', template: '' })
export abstract class EditMarketingMenuBodyComponent extends BaseComponent {

  constructor(
    public viewModel: EditMarketingMenuViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;

}
