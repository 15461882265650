import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DisplayableItemContainerViewModel } from './displayable-item-container-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { DisplayableItem } from './displayable-item-preview/displayable-item';

@Component({
  selector: 'app-displayable-item-container',
  templateUrl: './displayable-item-container.component.html',
  styleUrls: ['./displayable-item-container.component.scss'],
  providers: [DisplayableItemContainerViewModel]
})
export class DisplayableItemContainerComponent extends BaseComponent implements OnChanges {

  @Input() items: DisplayableItem[];

  constructor(
    public viewModel: DisplayableItemContainerViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) this.viewModel.connectToDisplayableItems(this.items);
  }

}
