import { Component } from '@angular/core';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';
import { PrintReportTemplatesViewModel } from '../print-report-templates-view-model';

@Component({
  selector: 'app-print-report-template-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
  ],
})
export class PrintReportTemplateListComponent extends DisplayableItemListComponent {

  constructor(
    printReportTemplatesViewModel: PrintReportTemplatesViewModel // Provided by parent component
  ) {
    super(printReportTemplatesViewModel);
  }

}
