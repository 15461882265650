import { Injectable } from '@angular/core';
import { BaseService } from '@mobilefirstdev/base-angular';
import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { MenuPreviewJobStatus } from '../../../../../../models/utils/dto/menu-preview-job-status-type';
import { DateUtils } from '../../../../../../utils/date-utils';
import { BulkJobSource } from '../../../../../../models/utils/dto/bulk-job-source-type';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';
import { iiif } from '../../../../../../utils/observable.extensions';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';

@Injectable()
export class BulkPrintJobPollingService extends BaseService {

  constructor(
    private bulkPrintJobDomainModel: BulkPrintJobDomainModel
  ) {
    super();
  }

  private POLLING_TIME = 12 * 1000; // 12 seconds

  private _jobSource = new BehaviorSubject<BulkJobSource | null>(null);
  private jobSource$ = this._jobSource as Observable<BulkJobSource | null>;

  protected readonly activeBulkPrintJobs$ = this.bulkPrintJobDomainModel.activeMenuBulkPrintJobs$;
  protected readonly currentLocationBulkPrintJobs$ = this.bulkPrintJobDomainModel.currentLocationBulkPrintJobs$;

  protected isEditCardStackSource = (js: BulkJobSource): boolean => js === BulkJobSource.BulkJobSource_EditCardStack;
  protected isEditCardStackSource$ = this.jobSource$.pipe(map(js => this.isEditCardStackSource(js)));

  private currentBulkPrintJobs$ = iiif(
    this.isEditCardStackSource$,
    this.activeBulkPrintJobs$,
    this.currentLocationBulkPrintJobs$
  );

  private pollingInputJobs$ = this.currentBulkPrintJobs$.pipe(
    map(jobs => {
      return jobs?.filter(j => {
        // Continue to poll for jobs that are queued, processing, or successful but yet to return a valid download url
        const queued = MenuPreviewJobStatus[j?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Queued;
        const processing = MenuPreviewJobStatus[j?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
        const success = MenuPreviewJobStatus[j?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Success;
        const continuePolling = j?.dateCreated + DateUtils.unixOneMinute() * 10 > DateUtils.currentTimestamp();
        return (queued || processing || (success && !j?.downloadUrl)) && continuePolling;
      });
    })
  );

  private pollingTimer$ = interval(this.POLLING_TIME);
  private _lastPolledTime = new BehaviorSubject<number>(0);
  private lastPolledTime$ = this._lastPolledTime as Observable<number>;

  public startPolling(jobSource: BulkJobSource): void {
    this._jobSource.next(jobSource);
    combineLatest([
      this.pollingInputJobs$,
      this.pollingTimer$,
      this.lastPolledTime$,
    ]).pipe(
      concatMap(([jobs, _, lastPolledTime]) => {
        const hasJobs = jobs?.length > 0;
        const pollTimeElapsed = (Date.now() - lastPolledTime) > this.POLLING_TIME;
        const shouldPollForData = hasJobs && pollTimeElapsed;
        if (!shouldPollForData) return of([]);
        this._lastPolledTime.next(Date.now());
        return this.bulkPrintJobDomainModel.getUpdatedBulkPrintJobStatus(jobs).pipe(
          tap(updatedBulkPrintJobs => this.checkIfActiveBulkPrintJobsNeedUpdating(jobSource, updatedBulkPrintJobs))
        );
      })
    ).subscribeWhileAlive({ owner: this });
  }

  protected checkIfActiveBulkPrintJobsNeedUpdating(
    jobSource: BulkJobSource,
    updatedBulkPrintJobs: BulkPrintJob[]
  ): void {
    if (this.isEditCardStackSource(jobSource)) {
      updatedBulkPrintJobs?.forEach(job => this.bulkPrintJobDomainModel.updateActiveBulkPrintJob(job));
    }
  }

}
