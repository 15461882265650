<app-loading
  [hidden]="!(combinedLoadingOptsViewModel.isLoading$ | async)"
  [options]="combinedLoadingOptsViewModel.loadingOpts$ | async">
</app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'editCollection'"
  (formsAutoSubmitted$)="editDisplayViewModel.saveDisplay(true)"
  (formsSubmitted$)="editDisplayViewModel.saveDisplay(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-display-container>
  <app-edit-collection-header
    [collection]="viewModel.collection$ | async"
    [disablePublish]="
      (formCapture.hasErrors$ | async) ||
      (formCapture.unsavedChanges$ | async) ||
      (combinedLoadingOptsViewModel.autoSaving$ | async) ||
      (viewModel.collectionIsEmpty$ | async) ||
      !(viewModel.everyTemplateHasBeenPublished$ | async)
    "
    [publishButtonDisabledText]="viewModel.publishButtonDisabledText$ | async"
    [disableSave]="combinedLoadingOptsViewModel.autoSaving$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [lastSavedTimeText]="combinedLoadingOptsViewModel.lastAutoSavedTimeText$ | async"
    [headerText]="'Edit Template Collection'"
    [headerSubtext]="
      'Template collections allow multiple templated menus to be assigned ' +
      'to displays and changes to be pushed to all applicable digital displays.'
    "
    [crumbs]="viewModel.breadcrumbs$ | async"
    [autoSaveLoadingOpts]="combinedLoadingOptsViewModel.autoSaveLoadingOpts$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    (publishCollection)="viewModel.openPublishCollectionModal()"
    (showLiveView)="editDisplayViewModel.showLiveView()"
    (submitForms)="viewModel.saveChanges(formCapture)">
  </app-edit-collection-header>

  <app-edit-collection-displays
    [collection]="viewModel.collection$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [mergeKey]="'editCollection'"
    (requiredDisplaysChanged)="viewModel.connectToUpdatedRequiredDisplayIds($event)"
    (pendingDisplayChanged)="viewModel.connectToUpdatedPendingDisplay($event)"></app-edit-collection-displays>

  <app-edit-collection-details [mergeKey]="'editCollection'" [bindTo]="editDisplayViewModel.display$ | async">
  </app-edit-collection-details>

  <app-edit-display-menu-details
    [sectionTitle]="'Template Details'"
    [sectionDescription]="'Assign one or many templates to a collection and configure the rotation order and duration.'"
    [mergeKey]="'editCollection'"
    [formPristine]="formCapture.allPristine$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"></app-edit-display-menu-details>

  <div class="d-flex justify-content-end align-items-center" [style.gap.rem]="1">
    <div class="text-link destructive-link inline-text-link" (click)="viewModel.promptForDeleteCollection()">
      Delete Collection
    </div>
    <app-edit-display-actions
      [disableSave]="viewModel.autoSaving$ | async"
      [formPristine]="formCapture.allPristine$ | async"
      [formDisableSubmit]="formCapture.disableSubmit$ | async"
      [formErrorMessages]="formCapture.errorMessages$ | async"
      [formHasErrors]="formCapture.hasErrors$ | async"
      [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
      (showLiveView)="viewModel.showLiveView()"
      (submitForms)="viewModel.saveChanges(formCapture)">
    </app-edit-display-actions>
  </div>
</app-edit-display-container>
