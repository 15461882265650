import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';
import { Observable } from 'rxjs';
import { InventoryProvider } from '../../../models/utils/dto/inventory-provider-type';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'presentProductQuantityInStockToUser'
})
export class PresentProductQuantityInStockToUserPipe implements PipeTransform {

  transform(product: Product, inventoryProvider$: Observable<InventoryProvider>): Observable<string> {
    return inventoryProvider$.pipe(
      map(inventoryProvider => product?.presentQuantityInStockToUser(inventoryProvider))
    );
  }

}
