import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../image/dto/asset';
import { Section } from './section';
import { MenuStyle } from './menu-style';
import { Size } from '../../shared/size';
import { DisplayOptions } from '../../shared/display-options';
import { Selectable } from '../../protocols/selectable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { Orderable } from '../../protocols/orderable';
import { Tag } from './tag';
import { environment } from '../../../../environments/environment';
import { MenuOptions } from './menu-options';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Theme } from './theme';
import { MenuMetadata } from './menu-metadata';
import { MarketingMenuType } from '../../enum/dto/marketing-menu-type.enum';
import { HydratedVariantFeature } from '../../product/dto/hydrated-variant-feature';
import { ProductMenuType } from '../../enum/dto/product-menu-type.enum';
import { HasId } from '../../protocols/has-id';
import { MarketingTheme, ThemeId } from '../../enum/dto/theme.enum';
import { Pagable } from '../../protocols/pagable';
import { Variant } from '../../product/dto/variant';
import { FeaturedCategoryMenuCardType } from '../../utils/dto/featured-category-menu-card-type';
import { OverflowState } from '../../utils/dto/overflow-state-type';
import { OptionScale } from '../../utils/dto/option-scale-type';
import { DefaultDigitalSizeType } from '../../utils/dto/default-digital-size-type';
import { DefaultPrintSizeType } from '../../utils/dto/default-print-size-type';
import { SectionType } from '../../utils/dto/section-type-definition';
import { SizeUnit } from '../../utils/dto/size-unit-type';
import { MenuType, MenuTypeDefinition } from '../../utils/dto/menu-type-definition';
import { PillItem, PillItemInterface } from '../../shared/stylesheet/pill-item';
import { SmartFilterUtils } from '../../../utils/smart-filter-utils';
import type { MenuTemplate } from '../../template/dto/menu-template';
import { Orientation } from '../../utils/dto/orientation-type';
import { DISPLAYABLE_ITEM_PREVIEW_COUNT, DisplayableItem } from '../../../views/shared/components/displayable-content/displayable-item-container/displayable-item-preview/displayable-item';
import { PolymorphicDeserializationKey } from '../../enum/shared/polymorphic-deserialization-key.enum';
import { SectionTemplate } from '../../template/dto/section-template';
import { Breadcrumb } from '../../shared/stylesheet/breadcrumb';
import { PagableObject } from '../../protocols/pagableObject';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { SortUtils } from '../../../utils/sort-utils';
import { HydratedSection } from './hydrated-section';
import { exists } from '../../../functions/exists';
import { MenuSubtype } from '../../enum/dto/menu-subtype';
import { PrintCardMenuType } from '../../enum/dto/print-card-menu-type.enum';
import { PriceFormat } from '../../utils/dto/price-format-type';
import { Product } from '../../product/dto/product';
import { SectionColumnConfigDefaultState } from '../../utils/dto/section-column-config-default-state-type';
import { PrintLabelMenuType } from '../../enum/dto/print-label-menu-type.enum';

type ContentLoopData = [number, number, number];

export class Menu implements Deserializable, HasId, Selectable, Cachable, Orderable, UniquelyIdentifiable, Pagable,
  PillItemInterface, DisplayableItem, PagableObject, LookAheadItem {

  public id: string;
  public name: string = '';
  public companyId: number;
  public locationId: number;
  public companyLogo: Asset;
  public backgroundImage: Asset;
  public type: MenuType = MenuType.DisplayMenu;
  public theme: ThemeId;
  public sections: Section[];
  public styling: MenuStyle[];
  public displaySize: Size;
  public linkedURL: string;
  public active: boolean;
  public configurationTitle: string;
  public tag: string = '';
  public options: DisplayOptions = new DisplayOptions();
  public menuOptions: MenuOptions = new MenuOptions();
  public variantFeature: HydratedVariantFeature;
  public hydratedVariantFeature: HydratedVariantFeature;
  public overflowState: OverflowState = OverflowState.NONE;
  public hydratedTheme: Theme;
  public subTitle: string;
  public marketingAssetHashes: string[];
  public metadata: MenuMetadata = new MenuMetadata();
  public columnCount: number;
  public pagingKey: string;
  // Template - data is only set when a menu is created from a template
  public templateId?: string; // templateId used to create menu
  public template?: MenuTemplate; // template data structure associated with this menu
  // Cache
  public cachedTime: number;
  // Display Properties
  public displayPriority: number;

  static newProductMenu(): Menu {
    const m = new Menu();
    m.name = '';
    m.subTitle = '';
    m.tag = null;
    m.theme = null;
    m.active = true;
    m.hydratedTheme = new Theme();
    return m;
  }

  static newMarketingMenu(): Menu {
    const m = new Menu();
    m.name = '';
    m.subTitle = '';
    m.tag = null;
    m.theme = null;
    m.type = MenuType.MarketingMenu;
    m.options = new DisplayOptions();
    m.options.scale = OptionScale.Fit;
    m.active = true;
    return m;
  }

  static getUniqueTags(menus: Menu[]): Tag[] {
    return menus
      ?.map(map => (!!map.tag?.trim() ? new Tag(map.tag) : null))
      ?.uniqueByProperty('value')
      ?.filterNulls();
  }

  getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Menu;
  }

  /**
   * The following is done to prevent circular import warnings:
   * this.template = Deserialize?.instanceOf(Menu, this.template) as MenuTemplate;
   * Menu has been added to the polymorphic deserializer, so if the data represents a MenuTemplate, then
   * it will get deserialized as a MenuTemplate, and not as a Menu.
   */
  public onDeserialize() {
    if (!!this.templateId && !!this.template) this.addTemplatePointersToTemplatedSection();
    this.createLocalObjectReferences();
    this.checkForEmptyDisplaySize();
    if (this.isTemplatedMenu()) this.hydrateFromTemplate();
  }

  public addTemplatePointersToTemplatedSection() {
    // Section.TemplateSection is de-duped from responses that also return the Template object
    Section.setTemplatePointers(this);
  }

  protected createLocalObjectReferences(): void {
    const Deserialize = window?.injector?.Deserialize;
    this.companyLogo = Deserialize?.instanceOf(Asset, this.companyLogo);
    this.backgroundImage = Deserialize?.instanceOf(Asset, this.backgroundImage);
    this.sections = Deserialize?.arrayOf(Section, this.sections) ?? [];
    this.styling = Deserialize?.arrayOf(MenuStyle, this.styling) ?? [];
    this.displaySize = Deserialize?.instanceOf(Size, this.displaySize);
    this.options = Deserialize?.instanceOf(DisplayOptions, this.options);
    this.menuOptions = Deserialize?.instanceOf(MenuOptions, this.menuOptions);
    this.variantFeature = Deserialize?.instanceOf(HydratedVariantFeature, this.variantFeature);
    this.hydratedVariantFeature = Deserialize?.instanceOf(HydratedVariantFeature, this.hydratedVariantFeature);
    this.hydratedTheme = Deserialize?.instanceOf(Theme, this.hydratedTheme);
    this.overflowState = this.overflowState || OverflowState.NONE;
    this.marketingAssetHashes = Array.from(this.marketingAssetHashes || []);
    this.sections?.forEach(section => {
      if (!section?.rowCount) {
        section.rowCount = this.hydratedTheme?.themeFeatures?.sectionProductMaxCount ?? 0;
      }
    });
    if (!this.subTitle) this.subTitle = '';
    this.metadata = Deserialize?.instanceOf(MenuMetadata, this.metadata) ?? new MenuMetadata();
    this.sections?.sort(SortUtils.menuSectionsByPriorityAsc);
    this.template = Deserialize?.instanceOf(Menu, this.template) as MenuTemplate;
    this.deserializeTemplateSections();
  }

  private deserializeTemplateSections() {
    // Section.TemplateSection is de-duped from responses that also return the Template object
    this.sections.forEach(section => {
      const templateSection = this.template?.sections.find(ts => ts?.id === section?.templateSectionId);
      if (!!templateSection && !section.templateSection) {
        // If a template section exists, but is not already set on the Section, then set it and call hydrateFromTemplate
        section.setTemplateSection(window?.injector?.Deserialize?.instanceOf(SectionTemplate, templateSection));
      }
    });
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/MenuDTO.go
  onSerialize(): Menu {
    const dto: Menu = Object.create(Menu.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.active = this.active;
    dto.columnCount = this.columnCount;
    dto.configurationTitle = this.configurationTitle;
    dto.displaySize = this.displaySize;
    dto.locationId = this.locationId;
    dto.menuOptions = this.menuOptions;
    dto.metadata = this.metadata;
    dto.name = this.name;
    dto.options = this.options;
    dto.overflowState = this.overflowState;
    dto.subTitle = this.subTitle;
    dto.tag = this.tag;
    dto.templateId = this.templateId;
    dto.theme = this.theme;
    dto.type = this.type;
    dto.variantFeature = this.variantFeature;
    dto.linkedURL = this.linkedURL;
    return dto;
  }

  translateIntoDTO(): this {
    if (this.isTemplatedMenu()) this.dehydrateTemplatedMenu();
    return this;
  }

  getId(): string {
    return this.id;
  }

  consolidatePagedData(data: any[]): Menu {
    const main = data?.firstOrNull();
    data?.forEach(fragment => {
      main.sections = (main.sections ?? [])?.concat((fragment?.sections ?? []));
      if (!!fragment?.template) main.template = fragment.template;
    });
    return window.injector.Deserialize.instanceOf(Menu, main);
  }

  public validSizeForType(): boolean {
    let validSizeForType = false;
    switch (this.type) {
      case MenuType.DisplayMenu:
      case MenuType.MarketingMenu:
        validSizeForType = DefaultDigitalSizeType
          .defaultDigitalSizes()
          .toStringArray()
          .includes(this.displaySize?.name);
        break;
      case MenuType.PrintMenu:
      case MenuType.PrintReportMenu:
        validSizeForType = DefaultPrintSizeType
          .defaultPrintSizes()
          .toStringArray()
          .includes(this.displaySize?.name);
        break;
      case MenuType.PrintCardMenu:
      case MenuType.PrintLabelMenu:
        validSizeForType = true;
        break;
      case MenuType.WebMenu:
        validSizeForType = this.displaySize?.unit === SizeUnit.Digital;
        break;
    }
    return validSizeForType;
  }

  isWebMenu(): boolean {
    return this.type === MenuType.WebMenu;
  }

  isPrintMenu(): boolean {
    return this.type === MenuType.PrintMenu;
  }

  isPrintCardMenu(): boolean {
    return this.type === MenuType.PrintCardMenu;
  }

  isPrintLabelMenu(): boolean {
    return this.type === MenuType.PrintLabelMenu;
  }

  /**
   * Stacked content means something that can be printed, cut out, and stacked together like a deck of cards.
   */
  containsStackedContent(): boolean {
    return this.isPrintCardMenu() || this.isPrintLabelMenu();
  }

  whatTypeOfStackIsThis(): string | null {
    switch (true) {
      case this.isPrintCardMenu():  return 'card';
      case this.isPrintLabelMenu(): return 'label';
    }
    return null;
  }

  whatTypeOfMenuIsThis(): string | null {
    switch (true) {
      case this.isPrintCardMenu():  return 'card stack';
      case this.isPrintLabelMenu(): return 'label stack';
      default:                      return 'menu';
    }
  }

  isPrintReportMenu(): boolean {
    return this.type === MenuType.PrintReportMenu;
  }

  isPrintMenuOrPrintReportMenu(): boolean {
    return this.isPrintMenu() || this.isPrintReportMenu();
  }

  isPrintableMenu(): boolean {
    return this.isPrintMenuOrPrintReportMenu() || this.isPrintCardMenu() || this.isPrintLabelMenu();
  }

  isDigitalMenu(): boolean {
    return this.isDisplayMenu() || this.isMarketingMenu();
  }

  isDisplayMenu(): boolean {
    return this.type === MenuType.DisplayMenu;
  }

  isMarketingMenu(): boolean {
    return this.type === MenuType.MarketingMenu;
  }

  hasEnabledContent(): boolean {
    return Array.from(this.options?.rotationInterval?.values())?.filter(i => i > 0)?.length > 0;
  }

  isSpotlightMenu(): boolean {
    return this.hydratedTheme?.menuSubType === ProductMenuType.SpotlightMenu;
  }

  isSmartPlaylistMenu(): boolean {
    return this.hydratedTheme?.menuSubType === MarketingMenuType.SmartPlaylist;
  }

  isUrlPlaylistMenu(): boolean {
    return this.hydratedTheme?.menuSubType === MarketingMenuType.UrlPlaylist;
  }

  public isLargePrintMenu(): boolean {
    // If print menu has over 100 variants, or over 5 sections, show warning of slow load times
    const print = this.isPrintMenuOrPrintReportMenu();
    const large = (this.sections?.flatMap(s => s.enabledVariantIds)?.length > 100) || this.sections?.length > 5;
    return print && large;
  }

  isTemplatedMenu(): boolean {
    return !!this.templateId;
  }

  canDeleteRequiredTemplateMenu(locationMenus: Menu[], locationId: number): boolean {
    const templateRequiredAtLocation = this.template?.requiredLocationIds?.includes(locationId);
    let requireTemplateMenusCount = 0;
    locationMenus?.filter(m => m?.templateId === this.templateId).forEach(m => {
      if (m?.template?.requiredLocationIds?.includes(locationId)) requireTemplateMenusCount++;
    });
    return !templateRequiredAtLocation || requireTemplateMenusCount > 1;
  }

  setFeaturedVariantIds(ids: string[]) {
    this.variantFeature.variantIds = ids;
  }

  getFeaturedVariantIds(): string[] {
    if (this.variantFeature?.variantIds) {
      return this.variantFeature?.variantIds;
    } else {
      return [];
    }
  }

  public isFeaturedVariantInStock(id: string): boolean {
    const isMenuFeaturedVariantInStock = this.hydratedVariantFeature?.isFeaturedVariantInStock(id);
    const isTemplateFeaturedVariantInStock = this.template?.hydratedVariantFeature?.isFeaturedVariantInStock(id);
    return isMenuFeaturedVariantInStock || isTemplateFeaturedVariantInStock;
  }

  public isFeaturedVariantInRotation(id: string): boolean {
    const isActiveInRotation = this.options?.isActiveInRotation(id);
    const isActiveInTemplateRotation = this.template?.options?.isActiveInRotation(id);
    return isActiveInRotation || isActiveInTemplateRotation;
  }

  public getHydratedFeaturedVariants(): Variant[] {
    return [
      ...(this.hydratedVariantFeature?.variants || []),
      ...(this.template?.hydratedVariantFeature?.variants || [])
    ]?.uniqueByProperty('id');
  }

  public hasProductSectionWithVisibleContent(menuVariants: Variant[]): boolean {
    return this.getSectionsBasedOnMenuType()?.some(s => {
      return s?.isProductSectionWithVisibleContent(menuVariants);
    });
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionValue(): any {
    return this;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  static buildArrayCacheKey(companyId, locationId: number): string {
    return `Menus-${companyId}-${locationId}`;
  }

  static buildCacheKey(companyId: number, id: string): string {
    return `Menu-${companyId}-${id}`;
  }

  cacheKey(): string {
    return Menu.buildCacheKey(this.companyId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  // Orderable

  getOrderValue(): number {
    return this.displayPriority;
  }

  getOrderableTitle(): string {
    return this.name;
  }

  getOrderableUniqueId(): string {
    return this.id;
  }

  setOrderableValue(val: number) {
    this.displayPriority = val;
  }

  removeAssetFromFeaturedNameMap(variantId: string) {
    this.variantFeature?.assetNameMap?.delete(variantId);
  }

  removeAssetOptions(mediaIdentifier: string) {
    this.options?.removeId(mediaIdentifier);
  }

  /**
   * Features use (uppercased)vId, while options use (lowercased)vId.fileType as key
   */
  removeFeaturedProduct(vId: string) {
    // Remove featured variantId
    this.variantFeature?.removeId(vId);
    this.options?.removeId(vId);
    this.variantFeature?.removeVariantById(vId);
  }

  public getMenuWarningMessage(): string {
    switch (true) {
      case !this.validSizeForType(): {
        return `Preview images will not work until a valid Preview Size is set for this menu.`;
      }
      case this.isMarketingMenu() && !this.hasEnabledContent() && !this.isFeaturedCategoryMenu(): {
        let mediaName: string;
        switch (this.hydratedTheme?.menuSubType) {
          case MarketingMenuType.Playlist:   mediaName = 'media assets';      break;
          case MarketingMenuType.Featured:  mediaName = 'featured variants'; break;
          case MarketingMenuType.DriveThru: mediaName = 'products';          break;
        }
        return `No ${mediaName} are enabled on this menu.`;
      }
      case this.isMarketingMenu() && this.isFeaturedCategoryMenu() && !this.getSectionsBasedOnMenuType()?.length: {
        return `Without any Featured Category Cards, this menu will not display anything.`;
      }
      case this.isLargePrintMenu(): {
        return `Large print menus may take several minutes to generate the preview PDF.`;
      }
      default: return null;
    }
  }

  public getMenuWarningMessageTooltip(): string {
    switch (true) {
      case !this.validSizeForType(): {
        return `A Preview Size is required to indicate the size the menu preview to generate.`;
      }
      case this.isMarketingMenu() && !this.hasEnabledContent() && !this.isFeaturedCategoryMenu(): {
        let mediaName: string;
        switch (this.hydratedTheme?.menuSubType) {
          case MarketingMenuType.Playlist:   mediaName = 'media';      break;
          case MarketingMenuType.Featured:  mediaName = 'featured variants'; break;
          case MarketingMenuType.DriveThru: mediaName = 'products';          break;
        }
        return `Without any ${mediaName} enabled, this menu will not display anything! Make sure to enable at `
             + `least one item and save.`;
      }
      case this.isMarketingMenu() && this.isFeaturedCategoryMenu() && !this.getSectionsBasedOnMenuType()?.length: {
        return `This menu type needs Featured Category Cards to work properly. You can create some below.`;
      }
      case this.isLargePrintMenu(): {
        return `Print menus with a lot of sections or products, as well as menus with heavy use of assets `
             + `(like badges), can take several minutes to generate the preview PDF.`;
      }
      default: return null;
    }
  }

  private checkForEmptyDisplaySize() {
    const shouldEmptyDisplaySize = !this.displaySize?.name
       && !this.displaySize?.unit
       && !this.displaySize?.orientation
       && !this.displaySize?.height
       && !this.displaySize?.width;
    if (shouldEmptyDisplaySize) this.displaySize = null;
  }

  public generateMenuUrl(locationId: number, hideDownloadToast: boolean = true): string {
    const enviro = environment.production ? '' : `${environment.environmentName}.`;
    const cacheBuster = 'cacheBuster=' + Date.now() + Math.floor(Math.random() * 1000000);
    let url = `https://${enviro}display.mybudsense.com/#/`;
    switch (this.type) {
      case MenuType.PrintMenu:
        url += `print/${locationId.toString()}/${this.id}`;
        break;
      case MenuType.WebMenu:
        url += `web/${locationId.toString()}/${this.id}`;
        break;
      default:
        // Web and Digital menus use the same url
        url += `menu/${locationId.toString()}/${this.id}`;
    }
    if (hideDownloadToast) url += `/?hideDownloadToast=true&${cacheBuster}`;
    else url += `/?${cacheBuster}`;
    return url;
  }

  public isProductMenuWithSectionLevelOverflow(): boolean {
    const isProductMenu = (this.type === MenuType.DisplayMenu) && (this.getSubType() === ProductMenuType.ProductMenu);
    const hasSectionLevelOverflow = this.overflowState.includes('SECTION');
    return isProductMenu && hasSectionLevelOverflow;
  }

  public hasNonProductSectionThatIsVisible(): boolean {
    return this.getSectionsBasedOnMenuType()?.some(s => {
      return !s?.hideSection && ((s?.sectionType === SectionType.Title) || (s?.sectionType === SectionType.Media));
    });
  }

  public usesHydratedVariantFeatureProperty(): boolean {
    const isMarketingMenu = this?.type === MenuType.MarketingMenu;
    const isPlaylist = this.getSubType() === MarketingMenuType.Playlist;
    const isSmartPlaylist = this.getSubType() === MarketingMenuType.SmartPlaylist;
    const isFeatured = this.getSubType() === MarketingMenuType.Featured;
    const isFeatCatCarousel = this.isFeaturedCategoryCarousel();
    return isMarketingMenu && (isPlaylist || isSmartPlaylist || isFeatured || isFeatCatCarousel);
  }

  public isLoopingMarketingContent(): boolean {
    const isMarketingMenu = this?.type === MenuType.MarketingMenu;
    const isPlaylist = this.getSubType() === MarketingMenuType.Playlist;
    const isSmartPlaylist = this.getSubType() === MarketingMenuType.SmartPlaylist;
    const isFeatured = this.getSubType() === MarketingMenuType.Featured;
    const isFeatCatCarousel = this.isFeaturedCategoryCarousel();
    return isMarketingMenu && (isPlaylist || isSmartPlaylist || isFeatured || isFeatCatCarousel);
  }

  public usesLoopingSystem() {
    const card = window?.types?.initTypeDefinition(FeaturedCategoryMenuCardType, this.metadata?.cardType);
    return this.isLoopingMarketingContent() || this.isProductMenuWithSectionLevelOverflow() || card?.isCarouselCard();
  }

  public isFeaturedCategoryMenu(): boolean {
    return this.getSubType() === MarketingMenuType.Category;
  }

  public isFeaturedCategoryCarousel(): boolean {
    const cardType = this.getFeaturedCategoryCardType();
    const isFeatCat = this.getSubType() === MarketingMenuType.Category;
    return cardType?.isCarouselCard() && isFeatCat;
  }

  public getSubType(): MenuSubtype {
    return this?.hydratedTheme?.menuSubType;
  }

  public calculateLongestLoopDuration(
    menuProducts: Product[],
    menuVariants: Variant[],
    priceFormat: PriceFormat
  ): number {
    switch (true) {
      case this.isLoopingMarketingContent():
        return this.calculateMarketingLoopDuration(menuVariants, priceFormat);
      case this.isProductMenuWithSectionLevelOverflow():
        return this.calculateSectionLevelOverflowLongestSingleLoopDurationInSeconds(
          menuProducts,
          menuVariants,
          priceFormat
        );
    }
    return Number.parseInt(MenuMetadata.defaultSectionOverflowDuration, 10);
  }

  calculateSectionLevelOverflowLongestSingleLoopDurationInSeconds(
    menuProducts: Product[],
    menuVariants: Variant[],
    priceFormat: PriceFormat
  ): number {
    const duration = Number.parseInt(this.metadata?.sectionOverflowDuration, 10);
    const productSections = (s: Section) => s?.sectionType === SectionType.Product;
    const calculationData = (s: Section) => {
      return s?.getScopedVisibleLineItemDisplayDurationCalculationData(
        menuProducts,
        menuVariants,
        this,
        priceFormat,
        this.isProductMenuWithSectionLevelOverflow(),
        duration
      );
    };
    const sections = this.getSectionsBasedOnMenuType();
    const lengthRowCountDurations = sections?.filter(productSections)?.map(calculationData) || [[0, 0, 0]];
    const loopDurationCalculator = {
      [OverflowState.SECTION_PAGING]: this.longestContentLoopInSecondsForSectionOverflowPaging.bind(this),
      [OverflowState.SECTION_SCROLL]: this.longestContentLoopInSecondsForSectionOverflowScroll.bind(this)
    };
    return loopDurationCalculator[this.overflowState]?.(lengthRowCountDurations) || 0;
  }

  /**
   * The longest content loop is the time required to display all content within a section when using
   * section level paging overflow.
   *
   * This number is calculated by multiplying the number of pages in the section by the Section Overflow Duration.
   */
  private longestContentLoopInSecondsForSectionOverflowPaging(lengthRowCountDurations: ContentLoopData[]): number {
    // lineItemLimit is a number specified by the user that limits the number of line items displayed at once
    const sectionsWithLimitedLineItems = ([_, lineItemLimit]: ContentLoopData) => lineItemLimit > 0;
    const limitedLineItemSections = lengthRowCountDurations?.filter(sectionsWithLimitedLineItems);
    const calculateDurations = ([nLineItems, lineItemLimit, secs]) => Math.ceil(nLineItems / lineItemLimit) * secs;
    const durations = limitedLineItemSections?.map(calculateDurations) || [];
    const largestDuration = Math.max(...durations);
    return Number.isFinite(largestDuration) ? largestDuration : 0;
  }

  /**
   * The longest content loop is the time required to display all content within a section when using
   * section level scrolling/snapping overflow.
   *
   * This number is calculated by multiplying the number of rows in the section by the Section Overflow Duration.
   */
  private longestContentLoopInSecondsForSectionOverflowScroll(lengthRowCountDurations: ContentLoopData[]): number {
    // lineItemLimit is a number specified by the user that limits the number of line items displayed at once
    const contentLoopDurations = ([numberOfLineItems, lineItemLimit, secs]: ContentLoopData) => {
      // If the lineItemLimit is not set, then the section will not page through content.
      // We only want to calculate content loop durations for sections that page through content.
      const nItems = (lineItemLimit < 1) ? 0 : numberOfLineItems;
      return nItems * secs;
    };
    const durations = lengthRowCountDurations?.map(contentLoopDurations) || [];
    const largestContentLoop = Math.max(...durations);
    return Number.isFinite(largestContentLoop) ? largestContentLoop : 0;
  }

  public calculateMarketingLoopDuration(menuVariants: Variant[], priceFormat: PriceFormat): number {
    if (this?.type === MenuType.MarketingMenu) {
      let sum = 0;
      if (this.isFeaturedCategoryCarousel()) {
        // Sum the carouselDuration * largest card's product count
        sum = this.calculateLongestSingleLoopDurationInSeconds(menuVariants, priceFormat);
      } else {
        // Summing the duration of each product on the menu
        for (const [key, value] of (this?.options?.rotationInterval?.entries() || [])) {
          switch (this.getSubType()) {
            case MarketingMenuType.Playlist:
              sum += value;
              break;
            case MarketingMenuType.SmartPlaylist:
              const allSections = this.isTemplatedMenu()
                ? this.template?.templateSections
                : this.getSectionsBasedOnMenuType();
              const section = allSections?.find((s) => s?.id === key) as HydratedSection;
              const showOutOfStock = section?.showZeroStockItems;
              const atLeastOneSectionVariantInStock = () => menuVariants?.some(variant => {
                if (this.isTemplatedMenu()) {
                  const templatedSection = this.sections?.find(s => s?.templateSectionId === key) as HydratedSection;
                  return templatedSection?.enabledVariantIds?.includes(variant?.id) && variant?.inStock();
                }
                return section?.enabledVariantIds?.includes(variant?.id) && variant?.inStock();
              });
              if (!section?.hideSection && (showOutOfStock || atLeastOneSectionVariantInStock()) && !!section?.image) {
                sum += value;
              }
              break;
            case MarketingMenuType.Featured:
              if (this.isFeaturedVariantInStock(key)) sum += value;
              break;
            default:
              sum += 0;
          }
        }
      }
      if (Number.isInteger(sum)) {
        return sum;
      } else {
        return Number(sum.toFixed(2));
      }
    } else {
      return -1;
    }
  }

  public cardStackSupportsGridColumns(): boolean {
    return (this.hydratedTheme?.printConfig?.gridCountMap?.get(this.metadata?.printCardSize) || 0) > 0;
  }

  public getFeaturedCategoryCardType(): FeaturedCategoryMenuCardType {
    if (this.theme === MarketingTheme.FeaturedCategory) {
      return window.types.initTypeDefinition(FeaturedCategoryMenuCardType, this.metadata?.cardType);
    } else {
      return null;
    }
  }

  public calculateLongestSingleLoopDurationInSeconds(variants: Variant[], priceFormat: PriceFormat): number {
    const displayEachProductForXSeconds = this.getCarouselDurationInSeconds();
    const variantCountsPerSection = this.getSectionsBasedOnMenuType()
      ?.filter(s => s?.enabledVariantIds?.length > 0)
      ?.map(sectionWithProducts => {
        const sectionLevelOverflow = this.isProductMenuWithSectionLevelOverflow();
        return sectionWithProducts
          ?.getScopedVisibleVariantsForLineItemMode(variants, this, priceFormat, sectionLevelOverflow)
          ?.length || 0;
      }) || [0];
    return Math.max(...variantCountsPerSection, 0) * displayEachProductForXSeconds;
  }

  public getCarouselDurationInSeconds(): number {
    const showProductForXSeconds = Number.parseInt(this?.metadata?.carouselDuration || '10', 10);
    const isNumber = Number.isFinite(showProductForXSeconds);
    return isNumber ? showProductForXSeconds : 10;
  }

  public updateSection(section: Section, removeItem: boolean = false): Section {
    const updatedSections = this.sections?.shallowCopy() || [];
    const replacementIndex = updatedSections?.findIndex(s => s?.id === section?.id);
    if (removeItem) {
      if (replacementIndex > -1) updatedSections.splice(replacementIndex, 1);
    } else if (replacementIndex > -1) {
      updatedSections[replacementIndex] = section;
    } else {
      updatedSections.push(section);
    }
    this.sections = updatedSections;
    return section;
  }

  public containsAtLeastOneSmartFilterIds(ids: string[]): boolean {
    const menuSFIds = this.sections?.flatMap(s => s.smartFilterIds);
    return menuSFIds?.some(id => ids?.indexOf(id) >= 0);
  }

  public getEditMarketingMenuTitleFromSubtype(): string {
    switch (this.hydratedTheme?.menuSubType) {
      case MarketingMenuType.Playlist:  return 'Edit Playlist Menu';
      case MarketingMenuType.Featured:  return 'Edit Featured Menu';
      case MarketingMenuType.DriveThru: return 'Edit Drive-Thru Menu';
      case MarketingMenuType.Category:  return 'Edit Featured Category Menu';
      default:                          return 'Edit Marketing Menu';
    }
  }

  public getMarketingMenuDescriptionFromSubtype(): string {
    switch (this.hydratedTheme?.menuSubType) {
      case MarketingMenuType.Playlist:
        return 'Allows you to feature full-screen content that plays over and over again during '
          + 'the course of the day.';
      case MarketingMenuType.Featured:
        return 'Promote special inventory items using Featured Content Menus.';
      case MarketingMenuType.DriveThru:
        return 'Promote products as numbered cards. This allows for easy product and combo selection.';
      case MarketingMenuType.Category:
        return 'Highlight categories and associated products with large cards on a menu.';
      default:
        return 'Create marketing content for your store.';
    }
  }

  getViewAllNavigationUrl(): string {
    switch (this.type) {
      case MenuType.DisplayMenu:
      case MenuType.MarketingMenu:    return `menus/digital`;
      case MenuType.PrintMenu:
      case MenuType.PrintCardMenu:
      case MenuType.PrintLabelMenu:
      case MenuType.PrintReportMenu:  return `menus/print`;
      case MenuType.WebMenu:          return `menus/web`;
    }
    return '';
  }

  getViewAllNavigationName(): string {
    switch (this.type) {
      case MenuType.DisplayMenu:
      case MenuType.MarketingMenu:    return `Digital Menus`;
      case MenuType.PrintMenu:
      case MenuType.PrintCardMenu:
      case MenuType.PrintLabelMenu:
      case MenuType.PrintReportMenu:  return `Print Menus`;
      case MenuType.WebMenu:          return `Web Menus`;
    }
    return '';
  }

  getViewAllNavigationFragment(): string {
    switch (this.type) {
      case MenuType.MarketingMenu:    return `marketingMenus`;
      case MenuType.PrintCardMenu:    return `printCards`;
      case MenuType.PrintLabelMenu:   return `printLabels`;
      case MenuType.PrintReportMenu:  return `reports`;
      default:                        return `productMenus`;
    }
  }

  getViewAllBreadcrumb(active: boolean): Breadcrumb {
    const title = this.getViewAllNavigationName();
    const url = this.getViewAllNavigationUrl();
    const fragment = this.getViewAllNavigationFragment();
    return new Breadcrumb(title, url, fragment, active);
  }

  getEditNavigationName(): string {
    switch (this.type) {
      case MenuType.PrintMenu:                return `Edit Print Menu`;
      case MenuType.PrintCardMenu:            return `Edit Print Card Stack`;
      case MenuType.PrintLabelMenu:           return `Edit Label Stack`;
      case MenuType.PrintReportMenu:          return `Edit Report`;
      case MenuType.DisplayMenu: {
        switch (this.hydratedTheme?.menuSubType) {
          case ProductMenuType.ProductMenu:   return `Edit Product Menu`;
          case ProductMenuType.SpotlightMenu: return `Edit Spotlight Menu`;
        }
        break;
      }
      case MenuType.WebMenu: {
        switch (this.hydratedTheme?.menuSubType) {
          case ProductMenuType.ProductMenu:   return `Edit Product Web Menu`;
          case ProductMenuType.SpotlightMenu: return `Edit Spotlight Web Menu`;
        }
        break;
      }
      case MenuType.MarketingMenu: {
        switch (this.hydratedTheme?.menuSubType) {
          case MarketingMenuType.SmartPlaylist: return `Edit Smart Playlist Menu`;
          case MarketingMenuType.Playlist:      return `Edit Playlist Menu`;
          case MarketingMenuType.Featured:      return `Edit Featured Product Menu`;
          case MarketingMenuType.DriveThru:     return `Edit Drive-Thru Menu`;
          case MarketingMenuType.Category:      return `Edit Featured Category Menu`;
          case MarketingMenuType.UrlPlaylist:   return `Edit URL Playlist Menu`;
        }
        break;
      }
    }
    return '';
  }

  getEditNavigationUrl(): string {
    switch (this.type) {
      case MenuType.PrintMenu:        return `menus/print/product/${this.id}`;
      case MenuType.PrintCardMenu:    return `menus/print/stack/${this.id}`;
      case MenuType.PrintReportMenu:  return `menus/print/report/${this.id}`;
      case MenuType.PrintLabelMenu:   return `menus/print/labels/${this.id}`;
      case MenuType.DisplayMenu: {
        switch (this.hydratedTheme?.menuSubType) {
          case ProductMenuType.ProductMenu:   return `menus/digital/product/${this.id}`;
          case ProductMenuType.SpotlightMenu: return `menus/digital/spotlight/${this.id}`;
        }
        break;
      }
      case MenuType.WebMenu: {
        switch (this.hydratedTheme?.menuSubType) {
          case ProductMenuType.ProductMenu:   return `menus/web/product/${this.id}`;
          case ProductMenuType.SpotlightMenu: return `menus/web/spotlight/${this.id}`;
        }
        break;
      }
      case MenuType.MarketingMenu: {
        switch (this.hydratedTheme?.menuSubType) {
          case MarketingMenuType.Playlist:
            return `menus/digital/marketing/playlist/${this.id}`;
          case MarketingMenuType.SmartPlaylist:
            return `menus/digital/marketing/smart-playlist/${this.id}`;
          case MarketingMenuType.Featured:
            return `menus/digital/marketing/featured/${this.id}`;
          case MarketingMenuType.DriveThru:
            return `menus/digital/marketing/drive-thru/${this.id}`;
          case MarketingMenuType.Category:
            return `menus/digital/marketing/category/${this.id}`;
          case MarketingMenuType.UrlPlaylist:
            return `menus/digital/marketing/url-playlist/${this.id}`;
        }
        break;
      }
    }
    return '';
  }

  getEditBreadcrumb(active: boolean): Breadcrumb {
    return new Breadcrumb(this.getEditNavigationName(), this.getEditNavigationUrl(), undefined, active);
  }

  getStartKey(): string {
    return this.id;
  }

  getAsPillItem(clickable: boolean, selected: boolean, disabled: boolean): PillItem {
    const imgSrc = SmartFilterUtils.getAppliedOnPillIcon(this.type);
    return new PillItem(this.name, clickable, selected, disabled, imgSrc, this);
  }

  menuUsesSectionsThatContainProductsOrHasVisibleContent(menuVariants: Variant[]): boolean {
    const usesSectionsWithProducts = this.subTypeUsesSectionsThatContainProducts();
    const hasNonProductSectionThatIsVisible = this.hasNonProductSectionThatIsVisible();
    const hasProductSectionWithVisibleContent = this.hasProductSectionWithVisibleContent(menuVariants);
    return usesSectionsWithProducts && (hasNonProductSectionThatIsVisible || hasProductSectionWithVisibleContent);
  }

  subTypeUsesSectionsThatContainProducts(): boolean {
    const menuSubType = this.hydratedTheme?.menuSubType;
    const isProductMenu = menuSubType === ProductMenuType.ProductMenu;
    const isSpotlightMenu = menuSubType === ProductMenuType.SpotlightMenu;
    const isCategoryMenu = menuSubType === MarketingMenuType.Category;
    const isSmartPlaylist = menuSubType === MarketingMenuType.SmartPlaylist;
    return isProductMenu || isSpotlightMenu || isCategoryMenu || isSmartPlaylist;
  }

  public setWithWebMenuSizeProperties(): void {
    if (!this.displaySize) this.displaySize = new Size();
    this.displaySize.unit = SizeUnit.Digital;
    this.displaySize.orientation = (this?.displaySize?.height > this?.displaySize?.width)
      ? Orientation.Portrait
      : Orientation.Landscape;
  }

  public getNextRotationOrderPriority(): number {
    // Get the next value from the existing rotation order
    const fileNames = Array.from(this.options.rotationOrder.keys());
    let nextPriority = 0;
    fileNames.forEach((fn) => {
      const p = this.options?.rotationOrder?.get(fn);
      if (p > nextPriority) {
        nextPriority = p;
      }
    });
    return nextPriority + 1;
  }

  public getNextSectionPriority(): number {
    if (this.sections?.length) {
      return Math.max(...(this.sections?.map(s => s.priority) || [])) + 1;
    } else {
      return 0;
    }
  }

  private hydrateFromTemplate(): void {
    this.configurationTitle = this.template?.configurationTitle;
    this.columnCount = this.template?.columnCount;
    this.displaySize = this.template?.displaySize;
    this.metadata = this.template?.metadata;
    this.menuOptions = this.template?.menuOptions;
    this.name = this.template?.name;
    this.options = this.template?.options;
    this.overflowState = this.template?.overflowState;
    this.subTitle = this.template?.subTitle;
    this.combineSectionsWithTemplateSections();
    this.combineProductStylingWithTemplateStyling(this.template);
    this.tag = this.template?.tag;
    this.type = this.template?.type;
    this.variantFeature = this.template?.variantFeature;
    this.backgroundImage = this.template?.backgroundImage;
    this.marketingAssetHashes = this.template?.marketingAssetHashes;
    this.linkedURL = this.template?.linkedURL;
  }

  public dehydrateTemplatedMenu(): void {
    // Type is ignored because they are always present on both template and menu
    this.configurationTitle = null;
    this.columnCount = 0;
    this.displaySize = null;
    this.metadata = null;
    this.menuOptions = null;
    this.name = null;
    this.options = null;
    this.overflowState = null;
    this.subTitle = null;
    this.tag = null;
    this.variantFeature = null;
    this.styling = this.styling?.filter(s => !s?.isTemplateStyle);
    this.backgroundImage = null;
    this.marketingAssetHashes = null;
    this.linkedURL = null;
  }

  protected combineSectionsWithTemplateSections(): void {
    // A templated menu will always contain every section that exists on a template.
    this.sections?.forEach(section => section?.combineWithTemplatedData(section?.templateSection));
  }

  /**
   * Menu styling takes precedence over template styling.
   */
  protected combineProductStylingWithTemplateStyling(menuTemplate: Menu): void {
    const templateStyling = window.injector.Deserialize.arrayOf(MenuStyle, menuTemplate?.styling) ?? [];
    this.styling = this.styling ?? [];
    templateStyling?.forEach(templateStyle => {
      const overrideExists = this.styling?.find(menuStyle => {
        const projectedSectionId = templateStyle?.templateStyleTargetsWhichTemplatedSectionId(this.sections);
        const sameSection = menuStyle?.sectionId === projectedSectionId;
        const sameObjectId = menuStyle?.objectId === templateStyle?.objectId;
        return sameSection && sameObjectId;
      });
      if (!overrideExists) {
        templateStyle.configurationId = this.id;
        templateStyle.sectionId = templateStyle?.templateStyleTargetsWhichTemplatedSectionId(this.sections);
        this.styling?.push(templateStyle);
      }
    });
  }

  hasTemplateStyling(templatedSection: Section, variant: Variant): boolean {
    return !!this.template?.styling?.find(templateStyle => {
      const sameSection = templateStyle?.sectionId === templatedSection?.templateSectionId;
      const sameObjectId = templateStyle?.objectId === variant?.id;
      return templateStyle?.isTemplateStyle && sameSection && sameObjectId;
    });
  }

  getSectionsBasedOnMenuType(): Section[] {
    return this.sections;
  }

  sectionIdsWithPrimaryAssets(): string[] {
    const isHydratedSection = (section: Section): section is HydratedSection => section instanceof HydratedSection;
    return this.sections
      ?.filter(isHydratedSection)
      ?.map(s => exists(s?.image) ? s?.id : null)
      ?.filterNulls() ?? [];
  }

  sectionIdsThatHaveHiddenFlagEnabled(): string[] {
    return this.sections
      ?.filter(s => s?.hideSection)
      ?.map(s => s?.id)
      ?.filterNulls() ?? [];
  }

  requiresSortedVariantIds(): boolean {
    return MenuTypeDefinition.containsStackedContent(this.type)
        || this.hydratedTheme?.menuSubType === ProductMenuType.SpotlightMenu;
  }

  adjustDataForPrintLiveView(): this {
    if (this.isTemplatedMenu()) {
      this.sections?.forEach(section => {
        section?.columnConfig?.forEach((columnConfig, key) => {
          if (columnConfig?.defaultState === SectionColumnConfigDefaultState.Unknown) {
            section.columnConfig.set(key, null);
          }
        });
      });
    }
    return this;
  }

  /* *************************** LookAhead Item Interface ***************************** */

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  /* *************************** Displayable Item Interface *************************** */

  displayableItemPreviewContentIds(locationId: number): (string | string[])[] {
    if (this.containsStackedContent()) {
      const cardOrLabelStack = this.getSectionsBasedOnMenuType()?.firstOrNull();
      return cardOrLabelStack?.sortedVariantIds?.take(DISPLAYABLE_ITEM_PREVIEW_COUNT);
    }
    return [this.id];
  }

  displayableItemShouldShowSeeMoreCard(): boolean {
    if (this.containsStackedContent()) {
      const cardStack = this.getSectionsBasedOnMenuType()?.firstOrNull();
      return cardStack?.sortedVariantIds?.length > DISPLAYABLE_ITEM_PREVIEW_COUNT;
    }
    return false;
  }

  displayableItemTotalCount(): number {
    return this.getSectionsBasedOnMenuType()?.firstOrNull()?.sortedVariantIds?.length ?? 0;
  }

  displayableItemContainsStackedContent(): boolean {
    return this.containsStackedContent();
  }

  public displayableItemHasSmartFilters(): boolean {
    return this.getSectionsBasedOnMenuType()?.some(s => s?.hasSmartFilters());
  }

  displayableItemIsActive(): boolean {
    return this.active;
  }

  displayableItemActiveText(): string {
    return 'Active';
  }

  displayableItemInactiveText(): string {
    return 'Inactive';
  }

  displayableItemShowActiveBadge(): boolean {
    return true;
  }

  displayableItemSmartFilterIndicatorTooltip(): string {
    if (this.containsStackedContent()) return 'This menu contains smart filters.';
    return 'This menu contains sections that use smart filters.';
  }

  displayableItemShowDeployedCountIndicator(): boolean {
    return false;
  }

  displayableItemDeployedCountTooltipText(): string {
    return '';
  }

  displayableItemDeployedCountIcon(): string {
    return '';
  }

  displayableItemDeployedCount(): number {
    return null;
  }

  displayableItemSubtitle(): string {
    switch (this.getSubType()) {
      case MarketingMenuType.Playlist:
        return 'Assets';
      case MarketingMenuType.Featured:
      case MarketingMenuType.DriveThru:
      case PrintCardMenuType.PrintCardMenu:
      case PrintLabelMenuType.PrintLabelMenu:
      case ProductMenuType.SpotlightMenu:
        return 'Products';
      default:
        return 'Sections';
    }
  }

  displayableItemIsTemplatedMenu(): boolean {
    return this.isTemplatedMenu();
  }

  /* ********************************************************************************** */

  getUniqueIdentifier(): string {
    const id = this.id;
    const logoId = this.companyLogo?.getUniqueIdentifier() ?? '';
    const backgroundId = this.backgroundImage?.getUniqueIdentifier() ?? '';
    const themeId = this.theme;
    const sectionsId = this.sections?.map(s => s?.getUniqueIdentifier()).join(',') ?? '';
    const stylingId = this.styling?.map(s => s?.getUniqueIdentifier()).sort().join(',') ?? '';
    const titleId = this.configurationTitle ?? '';
    const optionsId = this.options?.getUniqueIdentifier() ?? '';
    const variantFeatureId = this.variantFeature?.getUniqueIdentifier() ?? '';
    const hydratedVariantFeatureId = this.hydratedVariantFeature?.getUniqueIdentifier() ?? '';
    const menuOptionsId = this.menuOptions?.getUniqueIdentifier() ?? '';
    const overflowId = this.overflowState ?? '';
    const marketingAssetsIs = this.marketingAssetHashes?.sort().join(',') ?? '';
    const metaData = this.metadata?.getUniqueIdentifier() ?? '';
    const tag = this.tag ?? '';
    return `${id}
      -${logoId}
      -${backgroundId}
      -${themeId}
      -${sectionsId}
      -${stylingId}
      -${titleId}
      -${optionsId}
      -${variantFeatureId}
      -${hydratedVariantFeatureId}
      -${menuOptionsId}
      -${overflowId}
      -${marketingAssetsIs}
      -${metaData}
      -${tag}`;
  }

}
