import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateViewBulkPrintJobComponent } from '../views/shared/modals/create-view-bulk-print-job/create-view-bulk-print-job.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalCreateBulkPrintJob {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
  ): void {
    ngbModal.open(
      CreateViewBulkPrintJobComponent,
      ModalUtils.editBulkPrintModalOptions(injector)
    );
  }

}
