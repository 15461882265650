import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Observable, of } from 'rxjs';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { DigitalProductTemplatesComponent } from './digital-product-templates/digital-product-templates.component';
import { DigitalMarketingTemplatesComponent } from './digital-marketing-templates/digital-marketing-templates.component';
import { DigitalTemplateCollectionsComponent } from './digital-template-collections/digital-template-collections.component';

export class DigitalTemplatesViewModel extends BaseViewModel {

    constructor() {
      super();
    }

  public tabs$: Observable<TabBarItem[]> = of([
    new TabBarItem(DigitalProductTemplatesComponent, 'Product Templates', '', false),
    new TabBarItem(DigitalMarketingTemplatesComponent, 'Marketing Templates', '', false),
    new TabBarItem(DigitalTemplateCollectionsComponent, 'Template Collections', '', false),
  ]);

}
