import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Asset } from '../../../../../../models/image/dto/asset';
import { map } from 'rxjs/operators';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MediaUtils } from '../../../../../../utils/media-utils';

@Injectable()
export class SectionMediaFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _file = new BehaviorSubject<Asset>(null);
  public file$ = this._file as Observable<Asset>;
  connectToFile = (file: Asset) => this._file.next(file);
  public fileName$ = this.file$.pipe(map(file => MediaUtils.getFileNameWithoutTimestampAndExtension(file?.fileName)));
  public fileIsVideo$ = this.file$.pipe(map(file => file?.isVideo()));

  private _bindTo = new BehaviorSubject<Menu>(null);
  public bindTo$ = this._bindTo as Observable<Menu>;
  connectToBindTo = (bindTo: Menu) => this._bindTo.next(bindTo);
  public rotationInterval$ = this.bindTo$.pipe(map(menu => menu?.options?.rotationInterval));

  private _sectionId = new BehaviorSubject<string>(null);
  public sectionId$ = this._sectionId as Observable<string>;
  connectToSectionId = (sectionId: string) => this._sectionId.next(sectionId);

  private _mediaDuration = new BehaviorSubject<number>(null);
  public mediaDuration$ = this._mediaDuration as Observable<number>;
  connectToMediaDuration = (mediaDuration: number) => this._mediaDuration.next(mediaDuration);

  private _isTemplatedMenu = new BehaviorSubject<boolean>(false);
  public isTemplatedMenu$ = this._isTemplatedMenu as Observable<boolean>;
  connectToIsTemplatedMenu = (isTemplatedMenu: boolean) => this._isTemplatedMenu.next(isTemplatedMenu);

  private _groupMediaEnabled = new BehaviorSubject<boolean>(true);
  public groupMediaEnabled$ = this._groupMediaEnabled as Observable<boolean>;
  connectToGroupMediaEnabled = (groupMediaEnabled: boolean) => this._groupMediaEnabled.next(groupMediaEnabled);

  public displayDurationEnabled$ = combineLatest([
    this.groupMediaEnabled$,
    this.fileIsVideo$
  ]).pipe(
    map(([groupMediaEnabled, fileIsVideo]) => groupMediaEnabled && !fileIsVideo)
  );

  private _loopCount = new BehaviorSubject<number>(1);
  public loopCount$ = this._loopCount as Observable<number>;
  connectToLoopCount = (loopCount: number) => this._loopCount.next(loopCount);

  public newInterval$ = combineLatest([
    this.file$,
    this.rotationInterval$,
    this.sectionId$,
    this.mediaDuration$,
    this.loopCount$
  ]).pipe(
    map(([file, rotationInterval, sectionId, mediaDuration, nLoops]) => {
      if (file.isImage()) return rotationInterval.get(sectionId);
      return mediaDuration * nLoops;
    })
  );

  private determineNumberOfLoops = combineLatest([
    this.rotationInterval$,
    this.sectionId$,
    this.mediaDuration$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([rotationInterval, sectionId, mediaDuration]) => {
      const interval = rotationInterval?.get(sectionId);
      if (!!interval && !!mediaDuration) {
        const loopCount = interval / mediaDuration;
        const durationIsRotationLength = Number.isInteger(loopCount);
        if (mediaDuration > 0 && !durationIsRotationLength) {
          this._loopCount.next(1);
        } else {
          this._loopCount.next(loopCount);
        }
      }
    }
  });

}
