import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { BaseDisplay } from '../../../models/display/dto/base-display';

@Pipe({
  name: 'menuIncludedInDisplay'
})
export class MenuIncludedInDisplayPipe implements PipeTransform {

  transform(menu: Menu, display: BaseDisplay): boolean {
    return display?.getMenus()?.map((m) => m.id)?.includes(menu?.id);
  }

}
