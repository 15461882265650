import { Selectable } from '../models/protocols/selectable';

export class ReactiveFormUtils {

  public static deleteMutationConfig = { attributes: false, childList: true, subtree: true };

  public static deleteErrorBlock(div: HTMLDivElement): void {
    const deleteMe = div?.getElementsByClassName('reactive-form-error-block');
    // delete error div, so it doesn't take up space
    for (const error of deleteMe[Symbol.iterator]()) { error.remove(); }
  }

  public static getDeleteErrorMutationObserver(div: HTMLDivElement): MutationObserver | null {
    if (!!div) {
      const callback = () => ReactiveFormUtils.deleteErrorBlock(div);
      const mutationObserver = new MutationObserver(callback);
      mutationObserver.observe(div, ReactiveFormUtils.deleteMutationConfig);
      return mutationObserver;
    }
    return null;
  }

  public static buildDivider = (title: string): Selectable => new class implements Selectable {

    getSelectionTitle = (): string => title;
    getSelectionUniqueIdentifier = (): string => '-1';
    getSelectionValue = (): any => undefined;
    getSelectionIsDisabled = (): boolean => true;

  }();

}
