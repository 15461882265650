<div class="modal-body sticky">
  <div #modalHeader class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ title }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div class="overflow-info-container">
      <div class="overflow-info-items-container">
        <div
          class="overflow-info-item"
          *ngFor="let item of viewModel.overflowInfoItems$ | async"
          (click)="onClick(item)"
          [ngClass]="{ 'info-item-selected': (viewModel.selectedInfoItem$ | async)?.item?.name === item.name }">
          <div class="overflow-info-item-name">
            {{ item.name }}
          </div>
          <div class="overflow-info-item-description">
            {{ item.description }}
          </div>
        </div>
      </div>
      <app-overflow-video
        [selectedInfoItem]="viewModel.selectedInfoItem$ | async"
        [stickyOffset]="modalHeaderHeight"
        (orientationSelected)="viewModel.updateSelectedOrientation($event)">
      </app-overflow-video>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button preferred-button">Done</button>
  </div>
</div>
