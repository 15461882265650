<app-edit-product-section-form
  *ngIf="section | isProductSection"
  [mergeKey]="mergeKey"
  [menu]="menu"
  [section]="section"
  [isTemplatedSection]="isTemplatedSection"
  [companyConfig]="companyConfig"
  [locationConfig]="locationConfig"
  (showZeroStockChanged)="showZeroStockChanged.emit($event)"
  (primarySortChanged)="primarySortChanged.emit($event)"
  (secondarySortChanged)="secondarySortChanged.emit($event)"
  [bindTo]="bindTo">
</app-edit-product-section-form>

<app-spotlight-section-form
  *ngIf="section | isSpotlightSection"
  [mergeKey]="mergeKey"
  [menu]="menu"
  [section]="section"
  [isTemplatedSection]="isTemplatedSection"
  [companyConfig]="companyConfig"
  [locationConfig]="locationConfig"
  [bindTo]="bindTo"
  (showZeroStockChanged)="showZeroStockChanged.emit($event)"
  (formAutoSubmitted)="formAutoSubmitted.emit($event)"
  (disableSubmit)="disableSubmit.emit($event)"
  (primarySortChanged)="primarySortChanged.emit($event)"
  (secondarySortChanged)="secondarySortChanged.emit($event)">
</app-spotlight-section-form>

<app-edit-featured-category-section-form
  *ngIf="section | isFeaturedCategorySection"
  [mergeKey]="mergeKey"
  [menu]="menu"
  [section]="section"
  [isTemplatedSection]="isTemplatedSection"
  [companyConfig]="companyConfig"
  [locationConfig]="locationConfig"
  (showZeroStockChanged)="showZeroStockChanged.emit($event)"
  (primarySortChanged)="primarySortChanged.emit($event)"
  (secondarySortChanged)="secondarySortChanged.emit($event)"
  [bindTo]="bindTo">
</app-edit-featured-category-section-form>

<app-edit-smart-playlist-menu-section-form
  *ngIf="section | isProductGroupSection"
  [mergeKey]="mergeKey"
  [menu]="menu"
  [section]="section"
  [isTemplatedSection]="isTemplatedSection"
  [companyConfig]="companyConfig"
  [locationConfig]="locationConfig"
  (showZeroStockChanged)="showZeroStockChanged.emit($event)"
  [bindTo]="bindTo">
</app-edit-smart-playlist-menu-section-form>

<app-edit-media-section-form
  *ngIf="section | isMediaSection"
  [mergeKey]="mergeKey"
  [menu]="menu"
  [section]="section"
  [isTemplatedSection]="isTemplatedSection"
  [companyConfig]="companyConfig"
  [locationConfig]="locationConfig"
  [bindTo]="bindTo">
</app-edit-media-section-form>

<app-edit-title-section-form
  *ngIf="section | isTitleSection"
  [mergeKey]="mergeKey"
  [menu]="menu"
  [section]="section"
  [isTemplatedSection]="isTemplatedSection"
  [companyConfig]="companyConfig"
  [locationConfig]="locationConfig"
  [bindTo]="bindTo">
</app-edit-title-section-form>
