import { PropertyLevel } from '../../../models/enum/shared/property-level.enum';

export class CannabinoidFilter {

  constructor(
    public level: PropertyLevel,
    public cannabinoid: string
  ) {
    this.name = `${this.level} ${this.cannabinoid}`;
  }

  public name: string;

}
