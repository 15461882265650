import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, OnDestroy, Output, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableEmptyStateBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { ProductGroupingTableViewModel } from '../product-grouping-table-view-model';
import { ProductGroupingTableEmptyStateViewModel } from './product-grouping-table-empty-state-view-model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-grouping-table-empty-state',
  templateUrl: './product-grouping-table-empty-state.component.html',
  styleUrls: ['./product-grouping-table-empty-state.component.scss'],
  providers: [
    {
      provide: ReactiveTableEmptyStateBluePrintComponent,
      useExisting: forwardRef(() => ProductGroupingTableEmptyStateComponent)
    },
    ProductGroupingTableEmptyStateViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupingTableEmptyStateComponent extends ReactiveTableEmptyStateBluePrintComponent
  implements OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public parentViewModel: ProductGroupingTableViewModel, // provided by parent
    public viewModel: ProductGroupingTableEmptyStateViewModel
  ) {
    super(viewRef);
  }

  @Input() searchTextAndHits: [string, any[]];
  @Output() clearSearch = new EventEmitter<void>(true);
  private destroy = new Subject<void>();

  initializeFromBluePrint(bluePrint: ProductGroupingTableEmptyStateComponent): void {
    this.searchTextAndHits = bluePrint.searchTextAndHits;
    this.viewModel.connectToSearchTextAndHits(bluePrint.searchTextAndHits);
  }

  changesFromBluePrint(changes: SimpleChanges): void {
    if (!!changes.searchTextAndHits) {
      this.searchTextAndHits = changes.searchTextAndHits.currentValue;
      this.viewModel.connectToSearchTextAndHits(changes.searchTextAndHits.currentValue);
    }
  }

  sendOutputsToBluePrint(bluePrint: ProductGroupingTableEmptyStateComponent): void {
    this.clearSearch
      .pipe(takeUntil(this.destroy))
      .subscribe(bluePrint.clearSearch);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy.next();
  }

}
