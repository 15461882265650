import { MenuPreview } from './menu-preview';

export class PrintCardPreview extends MenuPreview {

  variantIds: string[];

  public override onDeserialize() {
    super.onDeserialize();
    this.variantIds = Array.from(this.variantIds || []);
  }

}
