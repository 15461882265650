<app-loading *ngIf="sharedLoadingState.isLoading$ | push" [options]="sharedLoadingState.loadingOpts$ | push">
</app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="viewModel.mergeKey$ | push"
  (formsAutoSubmitted$)="sectionViewModel.saveStaticSectionMenu(true)"
  (formsSubmitted$)="sectionViewModel.saveStaticSectionMenu(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-container>
  <app-edit-menu-header
    [menu]="viewModel.menu$ | push"
    [crumbs]="viewModel.breadcrumbs$ | push"
    [allowLiveView]="viewModel.allowLiveView$ | push"
    [autoSaveLoadingOpts]="sharedLoadingState.autoSaveLoadingOpts$ | push"
    [headerText]="'Edit Label Stack'"
    [headerSubtext]="'Create visually stunning labels built specifically for your in-store displays.'"
    [lastSavedTimeText]="sharedLoadingState.lastAutoSavedTimeText$ | async"
    [formPristine]="formCapture.allPristine$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formDisableSubmit]="formCapture.disableSubmit$ | push"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [showLiveView]="viewModel.showLiveView"
    [toggleActiveState]="viewModel.toggleActiveState"
    [userIsTemplateAdmin]="viewModel.userIsTemplateAdmin$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    (editTemplateClicked)="viewModel.navigateToEditTemplate($event)"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-menu-header>

  <app-edit-label-stack-menu-body
    [mergeKey]="viewModel.mergeKey$ | push"
    [templateMode]="viewModel.templateMode$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [unsavedChanges]="formCapture.unsavedChanges$ | push"
    (sortedVariantIds)="viewModel.connectToSortedVariantIds($event)">
  </app-edit-label-stack-menu-body>

  <app-edit-label-stack-menu-footer
    [canDeleteTemplateMenu]="viewModel.canDeleteTemplateMenu$ | push"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | push"
    [formDisableSubmit]="formCapture.disableSubmit$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [menu]="viewModel.menu$ | push"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-label-stack-menu-footer>
</app-edit-menu-container>
