import { Section } from '../dto/section';
import { Deserializable } from '../../protocols/deserializable';

export class DuplicateMenuSectionRequest implements Deserializable {

  constructor(s: Section, addCopyToName: boolean = false) {
    this.sectionId = s.id;
    this.name = s.title + (addCopyToName ? ' (copy)' : '');
    this.baseMenuId = s.configurationId;
    this.targetMenuId = s.configurationId;
  }

  public locationId: number;
  public baseMenuId: string;
  public targetMenuId: string;
  public sectionId: string;
  public name: string;
  onDeserialize = () => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-menu-service/blob/dev/models/DuplicateConfigurationSectionRequest.go
  onSerialize() {
    const dto = Object.create(DuplicateMenuSectionRequest.prototype);
    dto.locationId = this.locationId;
    dto.baseMenuId = this.baseMenuId;
    dto.targetMenuId = this.targetMenuId;
    dto.sectionId = this.sectionId;
    dto.name = this.name;
    return dto;
  }

}
