<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)"
  (disableSubmit$)="disableSubmit.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'sectionType'"
      [label]="'Section Type'"
      [placeholder]="'Select the type of section to create'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [disabledTooltip]="''"
      [dropdowns]="viewModel.sectionTypes$ | async"
      [bindingProperty]="'sectionType'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <hr class="mt-0" />

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'title'"
      [label]="'Section Title'"
      [placeholder]="'Enter a title for this section.'"
      [inlineLabel]="false"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'title'">
    </lib-reactive-form-text>

    <lib-reactive-form-text
      *ngIf="viewModel.supportsSubtitle$ | async"
      [inputName]="'subtitle'"
      [label]="'Section Subtitle'"
      [placeholder]="'Enter a subtitle for this section.'"
      [inlineLabel]="false"
      [required]="false"
      [maxLength]="1024"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'subTitle'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'alignment'"
      [label]="'Title Alignment'"
      [placeholder]="'Select Title Alignment'"
      [inlineLabel]="false"
      [disabled]="isTemplatedSection"
      [required]="true"
      [tooltip]="viewModel.titleAlignmentToolTip$ | async"
      [dropdowns]="viewModel.titleAlignments$ | async"
      [bindingProperty]="'metadata.alignment'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <hr *ngIf="viewModel.supportsImageUpload$ | async" class="mt-0" />

  <lib-reactive-form-column-layout *ngIf="viewModel.supportsImageUpload$ | async" [nColumns]="2">
    <lib-reactive-form-drop-down
      [inputName]="'objectFit'"
      [label]="'Image Fit'"
      [placeholder]="'Select Image Fit'"
      [inlineLabel]="false"
      [disabled]="isTemplatedSection"
      [required]="true"
      [tooltip]="'Determines if the image should cover or fit within the container.'"
      [dropdowns]="viewModel.objectFitTypes$ | async"
      [bindingProperty]="'metadata.objectFit'"
      (valueChanged)="viewModel.connectToObjectFit($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'imageAlignment'"
      [label]="'Image Alignment'"
      [placeholder]="'Select Image Alignment'"
      [inlineLabel]="false"
      [disabled]="isTemplatedSection"
      [required]="true"
      [tooltip]="'Determines where the image should be horizontally aligned.'"
      [dropdowns]="viewModel.mediaAlignments$ | async"
      [bindingProperty]="'metadata.imageAlignment'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      #widthPercentage
      [inputName]="'width'"
      [label]="'Image Width'"
      [placeholder]="'Enter image width (%)'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="100"
      [disabled]="(viewModel.disableImageWidth$ | async) || isTemplatedSection"
      [bindingProperty]="'metadata.width'"
      [tooltip]="'Only editable if the image fit is set to cover.'"
      [customValueParser]="ClientTypes.ParserUtils.parseIntAsString"
      [programmaticallyChangeValue]="viewModel.setBackToFullPercentage$ | async"
      (valueChanged)="viewModel.connectToShowRepeat($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
