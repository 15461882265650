// noinspection JSUnusedLocalSymbols

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { DisplayAttribute } from '../../../models/display/dto/display-attribute';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { BaseComponent } from '../../../models/base/base-component';
import { map, switchMap, tap } from 'rxjs/operators';
import { LabelUpdate } from '../../../models/enum/dto/label-update.enum';
import { InlineLabelPickerMode } from './inline-label-picker-mode.enum';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { Label } from '../../../models/shared/label';
import { LabelUtils } from '../utils/label-utils';
import { Variant } from '../../../models/product/dto/variant';
import { LabelInflatorComponent } from '../label-inflator/label-inflator.component';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements } from 'ngx-popperjs';
import { PopperUtils } from '../../../utils/popper-utils';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';

/**
 * Creates an internal deep copy of the passed in displayAttribute.
 * Therefore, the passed in displayAttribute can be modified without affecting the original.
 *
 * Passing in a displayAttribute will cause this component to emit an updated
 * displayAttribute out of the updatedDisplayAttribute.
 *
 * Passing in a labelKey will cause this component to emit updates
 * from the updateLabelKey output.
 */
@Component({
  selector: 'app-inline-label-picker',
  templateUrl: './inline-label-picker.component.html',
  styleUrls: ['./inline-label-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineLabelPickerComponent extends BaseComponent implements AfterViewInit, OnChanges, OnDestroy {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private labelDomainModel: LabelDomainModel
  ) {
    super();
  }

  @Input() mode: InlineLabelPickerMode = InlineLabelPickerMode.DisplayAttributeInput;
  @Input() displayAttribute: DisplayAttribute;
  @Input() disabled: boolean = false;
  @Input() labelKey: string;
  @Input() popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.RIGHT;
  @Input() isCompany: boolean;
  @Input() variant: Variant;
  @Output() updateLabelKey = new EventEmitter<[string, LabelUpdate]>(true);
  @Output() updatedDisplayAttribute = new EventEmitter(true);
  @ViewChild(NgxPopperjsContentComponent) popperContent: NgxPopperjsContentComponent;
  @ViewChildren('labelInflatorComponent') labelInflatorComponent: QueryList<LabelInflatorComponent>;

  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public locationCustomLabels$  = this.labelDomainModel.locationCustomLabels$;

  private addedBySmartFilter: Subscription;
  public _addedBySmartFilter = new BehaviorSubject<boolean>(false);
  public addedBySmartFilter$ = this._addedBySmartFilter as Observable<boolean>;

  private _DA = new BehaviorSubject<DisplayAttribute>(null);
  public DA$ = this._DA as Observable<DisplayAttribute>;
  private listenToDAToSetLabelKey = this.DA$.subscribeWhileAlive({
    owner: this,
    next: da => {
      if (!!da) this._labelKey.next(da?.defaultLabel);
    }
  });

  private _variant = new BehaviorSubject<Variant>(null);
  private variant$ = this._variant.asObservable();

  private _isCompany = new BehaviorSubject<boolean>(null);
  public isCompany$ = this._isCompany as Observable<boolean>;

  private _labelKey = new BehaviorSubject<string>(null);
  public readonly labelKey$ = this._labelKey as Observable<string>;
  public label$: Observable<Label> = combineLatest([
    this.labelDomainModel.allCompanyLabels$,
    this.labelDomainModel.allLocationLabels$,
    this.labelKey$,
    this.isCompany$
  ]).pipe(
    map(([allCompLabels, allLocLabels, labelKey, isCompany]) => {
      const labelPool = isCompany ? allCompLabels : allLocLabels;
      return allLocLabels?.find(l => l?.id === labelKey) ?? allCompLabels?.find(l => l?.id === labelKey);
    })
  );

  public disableLabelModification$ = combineLatest([
    this.label$,
    this.variant$,
  ]).pipe(
    map(([label, variant]) => {
      // Do not allow label modification (add/edit) for labels explicitly set by the POS
      return variant?.posLabelIds?.contains(label?.id);
    })
  );

  public showAddButton$ = combineLatest([
    this.label$,
    this.disableLabelModification$
  ]).pipe(
    // Show the add button if no label exists AND label modification is allowed
    map(([label, disableLabelModification]) => !label && !disableLabelModification)
  );

  public showEditButton$ = combineLatest([
    this.label$,
    this.disableLabelModification$
  ]).pipe(
    // Show the edit button if a label exists AND label modification is allowed
    map(([label, disableLabelModification]) => !!label && !disableLabelModification)
  );

  public disableActionButton$ = combineLatest([
    this.showAddButton$,
    this.showEditButton$
  ]).pipe(
    map(([showAddButton, showEditButton]) => !showAddButton && !showEditButton)
  );

  public displayLabelInterface$ = combineLatest([
    this.label$,
    this.locationConfig$,
    this.companyConfig$,
    this.locationCustomLabels$,
    this.variant$,
  ]).pipe(
    map(([l, locConfig, compConfig, locationCustomLabels, v]) => {
      const isCompanyOrphan = !l?.isSystemLabel
                           && !l?.isPOSManaged
                           && locationCustomLabels?.findIndex(locL => locL?.id === l?.id) === -1;
      const clearable = !v?.posLabelIds?.contains(l?.id); // If explicitly set from POS, do not allow removal
      return LabelUtils.getDisplayLabelInterfaceForLabelPicker(l, locConfig, compConfig, clearable, isCompanyOrphan, v);
    })
  );

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['left'])];
  public popperStyles = {
    'background-color': '#FFFFFF',
  };
  public started: boolean = false;

  override setupBindings() {
    if (!!this.labelInflatorComponent?.toArray()?.length) {
      this.addedBySmartFilter?.unsubscribe();
      const labelInflatorComponent = this.labelInflatorComponent?.toArray()?.firstOrNull();
      this.addedBySmartFilter = labelInflatorComponent?.addedBySmartFilter$.subscribe(addedBySmartFilter => {
        this._addedBySmartFilter.next(addedBySmartFilter);
      });
    }
    this.labelInflatorComponent.changes.pipe(
      tap(_ => this.addedBySmartFilter?.unsubscribe()),
      map((list: QueryList<LabelInflatorComponent>) => list?.toArray()?.firstOrNull()),
      switchMap(labelInflatorComponent => labelInflatorComponent?.addedBySmartFilter$ || of(false))
    ).subscribeWhileAlive({
      owner: this,
      next: addedBySmartFilter => this._addedBySmartFilter.next(addedBySmartFilter)
    });
  }

  override setupViews(): void {
    this.createNewInstance();
    if (!!this.labelKey && this.labelKeyMode()) this._labelKey.next(this.labelKey);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.popperContent?.hide();
    this.started = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayAttribute) {
      this.createNewInstance();
    }
    if (!!changes.labelKey && this.labelKeyMode()) this._labelKey.next(this.labelKey);
    if (changes.isCompany) this._isCompany.next(this.isCompany);
    if (changes.variant) this._variant.next(this.variant);
  }

  createNewInstance() {
    if (this.displayAttributeMode()) {
      this._DA.next(window?.injector?.Deserialize?.instanceOf(DisplayAttribute, this.displayAttribute));
    }
  }

  updateDisplayAttribute(key: string) {
    if (this.displayAttributeMode()) {
      const existingDA = this._DA.getValue();
      existingDA.defaultLabel = existingDA?.defaultLabel !== key ? key : null;
      this._DA.next(existingDA);
      this.updatedDisplayAttribute.emit(this.DA$);
    } else {
      const currentLabelKey = this._labelKey.getValue();
      const updatedValue = currentLabelKey !== key ? key : null;
      const updateType = currentLabelKey !== key ? LabelUpdate.Change : LabelUpdate.Remove;
      this._labelKey.next(updatedValue);
      this.updateLabelKey.emit([key, updateType]);
    }
  }

  closePopper(): void {
    this.popperContent?.hide();
  }

  private displayAttributeMode = (): boolean => this.mode === InlineLabelPickerMode.DisplayAttributeInput;
  private labelKeyMode = (): boolean => this.mode === InlineLabelPickerMode.LabelKeyInput;

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.addedBySmartFilter?.unsubscribe();
  }

}
