<app-edit-featured-category-section-section-details
  [templateMode]="templateMode"
  [mergeKey]="dispersedAndMergeKey"
  [title]="'Category Card Details'"
  [description]="'View and edit basic information about this category card.'">
</app-edit-featured-category-section-section-details>

<app-menu-section-location-permissions
  *ngIf="(viewModel.section$ | async | isSectionTemplate) && (viewModel.section$ | async | sectionCanContainProducts)"
  [mergeKey]="dispersedAndMergeKey"
  [sectionTemplate]="viewModel.section$ | async | castToSectionTemplate">
</app-menu-section-location-permissions>

<div [hidden]="viewModel.showPrimarySectionAssetSection$ | async" class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">
      {{ viewModel.sectionPrimaryAssetTitle$ | async }}
    </p>
    <p class="bs-edit-section-description">
      {{ viewModel.sectionPrimaryAssetDesc$ | async }}
    </p>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8 menu-background-image-container">
    <app-edit-featured-category-edit-asset
      [hideLoading]="viewModel.hideSectionAssetLoading$ | async"
      [loadingOptions]="viewModel.sectionAssetLoadingOpts$ | async"
      [asset]="viewModel.sectionMedia$ | async"
      [hideAsset]="!(viewModel.sectionMedia$ | async)"
      [hideImageOptions]="viewModel.hideImageOptions$ | async"
      [assetPreviewOptions]="viewModel.sectionMediaPreview$ | async"
      [hideUploadAsset]="viewModel.hidePrimaryUploadAsset$ | async"
      [bindTo]="viewModel.section$ | async"
      [formPristine]="formPristine"
      [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
      (primaryButtonPressed)="viewModel.handleDeleteSectionAsset()"
      (secondaryButtonPressed)="viewModel.handleChangeSectionAsset()"
      (refreshButtonPressed)="viewModel.handleMenuSectionAssetRefresh($event)"
      (fileListChanged)="fileList($event, null)">
    </app-edit-featured-category-edit-asset>
  </div>
</div>
<hr [hidden]="viewModel.showPrimarySectionAssetSection$ | async" class="mt-24px" />

<div [hidden]="!(viewModel.supportsSecondarySectionAsset$ | async)" class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">
      {{ viewModel.sectionSecondaryAssetTitle$ | async }}
    </p>
    <p class="bs-edit-section-description">
      {{ viewModel.sectionSecondaryAssetDesc$ | async }}
    </p>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8 menu-background-image-container">
    <app-edit-featured-category-edit-asset
      [hideLoading]="viewModel.hideSectionAssetLoading$ | async"
      [loadingOptions]="viewModel.sectionAssetLoadingOpts$ | async"
      [asset]="viewModel.sectionSecondaryMedia$ | async"
      [hideAsset]="!(viewModel.sectionSecondaryMedia$ | async)"
      [hideImageOptions]="viewModel.hideImageOptions$ | async"
      [assetPreviewOptions]="viewModel.sectionMediaPreview$ | async"
      [hideUploadAsset]="viewModel.hideSecondaryUploadAsset$ | async"
      [uploadAssetId]="2"
      [bindTo]="viewModel.section$ | async"
      [isSecondaryImage]="true"
      [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
      (primaryButtonPressed)="viewModel.handleDeleteSectionAsset(true)"
      (secondaryButtonPressed)="viewModel.handleChangeSectionAsset(true)"
      (refreshButtonPressed)="viewModel.handleMenuSectionAssetRefresh($event, true)"
      (fileListChanged)="fileList($event, 2, true)">
    </app-edit-featured-category-edit-asset>
  </div>
</div>

<hr [hidden]="!(viewModel.supportsSecondarySectionAsset$ | async)" class="mt-24px" />

<app-menu-section-product-card-color
  [mergeKey]="dispersedAndMergeKey"
  [section]="viewModel.section$ | async"
  [cardType]="(viewModel.menu$ | async)?.getFeaturedCategoryCardType()"
  [colorPalette]="viewModel.colorPalette$ | async"></app-menu-section-product-card-color>

<app-menu-section-smart-filters
  *ngIf="viewModel.isProductSection$ | async"
  [templateMode]="templateMode"
  [menu]="viewModel.menu$ | async"
  [section]="viewModel.section$ | async"
  [autoSaving]="viewModel.autoSaving$ | async"
  [mergeKey]="dispersedAndMergeKey"
  [formPristine]="formPristine"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async">
</app-menu-section-smart-filters>

<app-edit-featured-category-section-products
  [templateMode]="templateMode"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine">
</app-edit-featured-category-section-products>
