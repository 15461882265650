import { Deserializable } from '../../protocols/deserializable';
import { DateUtils } from '../../../utils/date-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { VariantPricingTier } from './variant-pricing-tier';
import { NumberUtils } from '../../../utils/number-utils';
import { SortUtils } from '../../../utils/sort-utils';
import { PriceFormat } from '../../utils/dto/price-format-type';
import { LocationPromotion } from './location-promotion';
import { TaxRate } from './tax-rate';
import { VariantPricingProperties } from '../../protocols/variant-pricing-properties';

export class VariantPricing implements Deserializable, UniquelyIdentifiable, VariantPricingProperties {

  public locationId: number;
  public variantId: string;
  public catalogItemId: string;
  public price: number;
  public avgPrice: number;
  public overridePrice: number;
  public secondaryPrice: number;
  public overrideStartDate: number;
  public overrideStopDate: number;
  public taxesInAvgPrice: number;
  public taxesInOverridePrice: number;
  public taxesInPrice: number;
  public taxRates: TaxRate[];
  public pricingTiers: VariantPricingTier[];
  public pricingGroupPrices: Map<string, number>;
  // Not from API
  public layeredTaxDecimalRatesInOrder: number[];

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.pricingTiers = Deserialize?.arrayOf(VariantPricingTier, this.pricingTiers);
    this.taxRates = Deserialize?.arrayOf(TaxRate, this.taxRates);
    this.pricingGroupPrices = Deserialize?.genericMap(this.pricingGroupPrices) ?? new Map();
    this.layeredTaxDecimalRatesInOrder = NumberUtils.unique(this.taxRates?.map(taxRate => taxRate?.layer))
      ?.filter(n => Number.isInteger(n))
      ?.sort(SortUtils.numberAscending)
      ?.map(layer => {
        const taxSum = this.taxRates
          ?.filter(rate => rate?.layer === layer)
          ?.map(taxRate => taxRate?.percent)
          ?.reduce((total, percentage) => total + percentage, 0) || 0;
        return (taxSum ?? 0) / 100;
      }) || [];
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/VariantPricingDTO.go
  public onSerialize() {
    const dto = Object.create(VariantPricing.prototype);
    dto.locationId = this.locationId;
    dto.variantId = this.variantId;
    dto.catalogItemId = this.catalogItemId;
    dto.price = this.price;
    dto.avgPrice = this.avgPrice;
    dto.overridePrice = this.overridePrice;
    dto.taxesInPrice = this.taxesInPrice;
    dto.taxesInAvgPrice = this.taxesInAvgPrice;
    dto.taxesInOverridePrice = this.taxesInOverridePrice;
    dto.secondaryPrice = this.secondaryPrice;
    dto.overrideStartDate = this.overrideStartDate;
    dto.overrideStopDate = this.overrideStopDate;
    dto.pricingTiers = this.pricingTiers;
    dto.pricingGroupPrices = this.pricingGroupPrices;
    dto.taxRates = this.taxRates;
    return dto;
  }

  public isSaleActive(): boolean {
    const now = DateUtils.currentTimestamp();
    return (now >= this.overrideStartDate && now <= this.overrideStopDate)
        || (this.overrideStartDate === 0 && this.overrideStopDate === 0 && this.overridePrice > 0);
  }

  /**
   * @returns the current sticker price of the variant, ie the price of the variant
   * within the store at the time of purchase.
   */
  public getVisiblePrice(priceFormat: PriceFormat, lp: LocationPromotion): number {
    const format = priceFormat || PriceFormat.Default;
    const discounted = this.getBestDiscountedPriceOrNull(format, lp);
    return discounted || this.getPriceWithoutDiscountsOrNull(format);
  }

  /**
   * @returns the price of the variant without any promotions or discounts.
   */
  public getPriceWithoutDiscountsOrNull(priceFormat: PriceFormat): number {
    const format = priceFormat || PriceFormat.Default;
    const calculatePriceFor = {
      [PriceFormat.TaxesIn]: this.taxesInPrice,
      [PriceFormat.TaxesInRounded]: this.taxesInPrice,
      [PriceFormat.Default]: this.price
    };
    return calculatePriceFor[format]?.applyPriceFormatRounding(priceFormat) || null;
  }

  /**
   * @returns the average price of the variant across all locations without any promotions or discounts applied.
   */
  public getAveragePriceWithoutDiscountsOrNull(priceFormat: PriceFormat): number | null {
    const format = priceFormat || PriceFormat.Default;
    const calculatePriceFor = {
      [PriceFormat.TaxesIn]: this.taxesInAvgPrice,
      [PriceFormat.TaxesInRounded]: this.taxesInAvgPrice,
      [PriceFormat.Default]: this.avgPrice
    };
    return calculatePriceFor[format]?.applyPriceFormatRounding(priceFormat) || null;
  }

  /**
   * @returns the best discounted price applied to a product (sale price or promotion price), else null
   */
  public getBestDiscountedPriceOrNull(priceFormat: PriceFormat, lp: LocationPromotion): number | null {
    const format: PriceFormat = priceFormat || PriceFormat.Default;
    const formatSalePrice: number = this.getSalePriceOrNull(format) ?? 0;
    const formatPromotionPrice: number | null = this.getPromotionPriceOrNull(format, lp) ?? 0;
    const discountedPrices = [formatSalePrice, formatPromotionPrice]?.filter(price => price > 0);
    const bestDiscountedPrice = (discountedPrices?.length > 0) ? Math.min(...discountedPrices) : null;
    return bestDiscountedPrice || null;
  }

  /**
   * This will check if there is a promotion or sale active on this variant.
   *
   * @returns true if there is an active promotion or sale, else null
   */
  public hasDiscountedPrice(priceFormat: PriceFormat, lp: LocationPromotion): boolean {
    return this.getBestDiscountedPriceOrNull(priceFormat, lp) !== null;
  }

  /**
   * Sales and promotions are not the same thing. Therefore, a sale !== promotion, but both
   * apply the sale tag to a variant.
   *
   * @returns true if there is an active sale on this variant.
   */
  public hasActiveSale(priceFormat: PriceFormat): boolean {
    const format: PriceFormat = priceFormat || PriceFormat.Default;
    const now = DateUtils.currentTimestamp();
    const activeSale = now >= this.overrideStartDate && now <= this.overrideStopDate;
    const noStartOrStopDate = !this.overrideStartDate && !this.overrideStopDate;
    const hasSalePriceFor = {
      [PriceFormat.TaxesIn]: this.taxesInOverridePrice > 0,
      [PriceFormat.TaxesInRounded]: this.taxesInOverridePrice > 0,
      [PriceFormat.Default]: this.overridePrice > 0
    };
    const neverEndingSale = noStartOrStopDate && hasSalePriceFor[format];
    return activeSale || neverEndingSale;
  }

  /**
   * Note: promotions and sales are not the same. They are calculated differently.
   * Both apply the sale tag to a variant.
   *
   * @returns Checks if there is a sale on the variant, if yes, then return sale price,
   * if no, then return null.
   */
  public getSalePriceOrNull(priceFormat: PriceFormat): number | null {
    const format: PriceFormat = priceFormat || PriceFormat.Default;
    const formatHasActiveSale = this.hasActiveSale(format);
    const calculateSalePriceFor = {
      [PriceFormat.TaxesIn]: this.taxesInOverridePrice,
      [PriceFormat.TaxesInRounded]: this.taxesInOverridePrice,
      [PriceFormat.Default]: this.overridePrice
    };
    const price = formatHasActiveSale ? (calculateSalePriceFor[format] || null) : null;
    return price?.applyPriceFormatRounding(priceFormat) || null;
  }

  private addTaxesToPrice(price: number): number {
    let priceWithTaxes = null;
    if (isFinite(price)) {
      const calculateTaxes = (total, taxDecimal) => {
        const taxMultiplier = 1 + taxDecimal;
        return total * taxMultiplier;
      };
      priceWithTaxes = this.layeredTaxDecimalRatesInOrder?.reduce(calculateTaxes, price);
    }
    return priceWithTaxes;
  }

  /**
   * Note, promotions and sales are not the same, but both apply the sale tag to a variant.
   *
   * @returns Checks if there is a promotion on the variant, if yes, then return promotion price,
   * if no, then return null.
   */
  public getPromotionPriceOrNull(priceFormat: PriceFormat, lp: LocationPromotion): number | null {
    const format: PriceFormat = priceFormat || PriceFormat.Default;
    const priceBeforePromotion = this.price || null;
    const promotionPrice = lp?.applyPromotionDiscountOrNull(priceBeforePromotion);
    const calculatePromotionPriceFor = {
      [PriceFormat.TaxesIn]: this.addTaxesToPrice.bind(this),
      [PriceFormat.TaxesInRounded]: this.addTaxesToPrice.bind(this),
      [PriceFormat.Default]: () => promotionPrice
    };
    return calculatePromotionPriceFor[format]
      ?.(promotionPrice)
      ?.applyPriceFormatRounding(priceFormat) || null;
  }

  getUniqueIdentifier(): string {
    const pricingGroupPricesKeys: string[] = [];
    this.pricingGroupPrices?.forEach((val, key) => pricingGroupPricesKeys.push(`${key}-${val}`));
    const pricingGroupPricesId = pricingGroupPricesKeys.sort().join(',') ?? '';
    return `
      -${this.locationId}
      -${this.variantId}
      -${this.price}
      -${this.overridePrice}
      -${this.secondaryPrice}
      -${this.overrideStartDate}
      -${this.overrideStopDate}
      -${this.taxRates?.map(t => t.getUniqueIdentifier())?.sort()?.join(',')}
      -${this.pricingTiers?.map(p => p.getUniqueIdentifier())?.sort()?.join(',')}
      -${pricingGroupPricesId}
    `;
  }

}
