import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HiddenFormInputComponent } from '../../../../../shared/forms/hidden-form-input/hidden-form-input.component';

@Component({
  selector: 'app-edit-template-locations-form-item',
  templateUrl: '../../../../../shared/forms/hidden-form-input/hidden-form-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => EditTemplateLocationsFormItemComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditTemplateLocationsFormItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLocationsFormItemComponent extends HiddenFormInputComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() updatedRequiredIds: number[] | string[];
  @Output() requiredIdsChanged = new EventEmitter<number[] | string[]>();

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.updatedRequiredIds && !!this.updatedRequiredIds) {
      this.idsChanged(this.updatedRequiredIds);
    }
  }

  idsChanged(ids: number[] | string[]) {
    this.handleInputChange(ids);
    this.getSelfAsFormItem().patchValue(ids);
    this.markAsDirty();
    this.requiredIdsChanged.emit(ids);
  }

}
