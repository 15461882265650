import { Component, forwardRef } from '@angular/core';
import { FilterProductsFormComponent } from '../../../shared/components/filter-products-form/filter-products-form.component';
import { ProductPickerDataTableViewModel } from '../product-picker-data-table-view-model';

@Component({
  selector: 'app-product-picker-filter-form',
  templateUrl: '../../../shared/components/filter-products-form/filter-products-form.component.html',
  styleUrls: [
    '../../../shared/components/filter-products-form/filter-products-form.component.scss',
    './product-picker-filter-form.component.scss'
  ],
  providers: [
    {
      provide: FilterProductsFormComponent,
      useExisting: forwardRef(() => ProductPickerFilterFormComponent)
    }
  ]
})
export class ProductPickerFilterFormComponent extends FilterProductsFormComponent {

  constructor(
    public override viewModel: ProductPickerDataTableViewModel, // global (provided by root)
  ) {
    super(viewModel);
  }

}
