import { Component, ViewChild } from '@angular/core';
import { PrintViewModel } from './print-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  providers: [PrintViewModel]
})
export class PrintComponent extends BaseComponent {

  constructor(
    public viewModel: PrintViewModel,
    private activatedRoute: ActivatedRoute,
    private userDomainModel: UserDomainModel,
  ) {
    super();
  }

  @ViewChild('tabController') tabController: TabBarComponent;

  override setupBindings(): void {
    // Capture the fragment if available and navigates to a specific tab
    const fragSub = combineLatest([
      this.activatedRoute.fragment.notNull(),
      this.userDomainModel.canUsePrintCards$,
    ]).subscribe(([fragment, canUsePrintCards]) => {
      switch (fragment?.toLowerCase()) {
        case 'productmenus': this.tabController.tabSelected(0); break;
        case 'reports': this.tabController.tabSelected(1); break;
        case 'printcards': this.tabController.tabSelected(2); break;
        case 'printlabels': this.tabController.tabSelected(canUsePrintCards ? 3 : 2); break;
        case 'bulkprints': this.tabController.tabSelected(canUsePrintCards ? 4 : 3); break;
      }
    });
    this.pushSub(fragSub);
  }

}
