import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnConfigKey } from '../../../models/utils/dto/section-column-config-key-type';

@Pipe({
  name: 'columnConfigKeyName'
})
export class ColumnConfigKeyNamePipe implements PipeTransform {

  transform(k: string): string {
    switch (k) {
      case SectionColumnConfigKey.StrainType:
        return 'Strain Type';
      case SectionColumnConfigKey.QuantityAndSize:
        return 'Quantity x Size';
      case SectionColumnConfigKey.SecondaryPrice:
        return 'Secondary Price';
      default:
        return k;
    }
  }

}
