<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"></app-loading>
<iframe
  #iFrame
  loading="eager"
  id="live-view-iframe"
  [hidden]="!(viewModel.iFrameIsVisible$ | async)"
  [style.box-shadow]="viewModel.boxShadow$ | push"
  [style.height.px]="viewModel.menuHeight$ | push"
  [style.width.px]="viewModel.menuWidth$ | push"
  [frameBorder]="'0'"
  [name]="viewModel.iFrameName$ | push"
  [src]="viewModel.url$ | push"
  (load)="loaded(iFrame)">
</iframe>
