import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidatorFunctions {

  static notEmptyArrayValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (Array.isArray(value) && value.length > 0) {
      return null;
    }
    return {'notEmptyArray': {value}};
  }

  static atLeastOneItemInMapValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value instanceof Map && value?.size > 0) {
      return null;
    }
    return {'atLeastOneItemInMap': {value}};
  }

}
