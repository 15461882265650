import { EditMenuSectionViewModel } from '../../../menu/viewModels/edit-menu-section-view-model';
import { EditTemplateMenuSectionViewModel } from './edit-template-menu-section-view-model';
import { Injectable } from '@angular/core';
import { CombinedLoadingOptsViewModel } from '../../../shared/components/auto-save/combined-loading-opts-view-model';

@Injectable()
export class EditTemplateMenuSectionCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    public editTemplateMenuSectionViewModel: EditTemplateMenuSectionViewModel,
    public editMenuSectionViewModel: EditMenuSectionViewModel,
  ) {
    super([editTemplateMenuSectionViewModel, editMenuSectionViewModel]);
  }

}
