import { Component } from '@angular/core';
import { ManageLabelSmartFiltersViewModel } from './manage-label-smart-filters-view-model';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Label } from '../../../../models/shared/label';

@Component({
  selector: 'app-manage-label-smart-filters-modal-modal',
  templateUrl: './manage-label-smart-filters-modal.component.html',
  styleUrls: ['./manage-label-smart-filters-modal.component.scss'],
  providers: [ManageLabelSmartFiltersViewModel]
})
export class ManageLabelSmartFiltersModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: ManageLabelSmartFiltersViewModel,
  ) {
    super(activeModal);
  }

  public labelToManage(label: Label): void {
    this.viewModel.connectToLabel(label);
  }

}
