import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'replaceMenuWithCardStack'
})
export class ReplaceMenuWithCardStackPipe implements PipeTransform {

  transform(input: string, isCard: boolean, isLabel: boolean): string {
    if (isCard) input = StringUtils.replaceMenuWithCardStack(input);
    if (isLabel) input = StringUtils.replaceMenuWithLabelStack(input);
    return input;
  }

}
