import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import { DefaultPrintCardSize } from '../models/utils/dto/default-print-card-size-type';
import { DefaultPrintLabelSize } from '../models/utils/dto/default-print-label-size-type';
import { DefaultPrintStackSize } from '../models/enum/dto/default-print-stack-size';
import { MenuLabel } from '../models/utils/dto/menu-label-type';
import { SectionColumnConfigKey, SectionColumnConfigKeyType, SectionColumnConfigProductInfoKey } from '../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigSecondaryPricingData } from '../models/utils/dto/section-column-config-data-value-type';
import { SectionSortOption, SectionSortProductInfo, SectionSortSecondaryCannabinoids, SectionSortTerpenes, SectionSortType } from '../models/utils/dto/section-sort-type';
import { StrainClassification } from '../models/utils/dto/strain-classification-type';
import { StringUtils } from './string-utils';
import { SyncType } from '../models/utils/dto/sync-type-type';
import type { Asset } from '../models/image/dto/asset';
import type { BulkPrintJob } from '../models/automation/bulk-print-job';
import type { CuratedVariantBadgeSection } from '../models/product/dto/curated-variant-badge-section';
import type { Display } from '../models/display/dto/display';
import type { HydratedSection } from '../models/menu/dto/hydrated-section';
import type { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import type { KeyValue } from '@angular/common';
import type { Label } from '../models/shared/label';
import type { Location } from '../models/company/dto/location';
import type { Menu } from '../models/menu/dto/menu';
import type { OrderableMenuAsset } from '../models/menu/shared/orderable-menu-asset';
import type { PrioritySortableVariant } from '../models/product/shared/priority-sortable-variant';
import type { PriceFormat } from '../models/utils/dto/price-format-type';
import type { Product } from '../models/product/dto/product';
import type { Section } from '../models/menu/dto/section';
import type { SelectableSmartFilter } from '../models/automation/protocols/selectable-smart-filter';
import type { SmartFilterGrouping } from '../models/automation/smart-filter-grouping';
import type { Theme } from '../models/menu/dto/theme';
import type { Variant } from '../models/product/dto/variant';
import type { VariantBadge } from '../models/product/dto/variant-badge';
import type { VariantGroup } from '../models/product/shared/variant-group';
import { SecondaryCannabinoid } from '../models/utils/dto/secondary-cannabinoids-type-definition';

/**
 * A simplified interface for sorting in JavaScript.
 *
 * Sort functions in JavaScript take in two parameters, a and b, and return a number.
 * If the number is negative, a is sorted before b.
 * If the number is positive, b is sorted before a.
 * If the number is zero, a and b are sorted in the same order.
 * Therefore, this is a handy dandy enum to transform these numbers into a human-readable format.
 */
export enum Move {
  ALeft = -1,
  ARight = 1,
  BLeft = 1,
  BRight = -1,
  Nothing = 0
}

export enum SortOrderPosition {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3
}

export class SortUtils {

  static DEFAULT_SECTION_TERTIARY_SORT = SectionSortProductInfo.TitleAsc;
  static CANNABINOID_SORT_ORDER = [...Object.values(PrimaryCannabinoid), ...Object.values(SecondaryCannabinoid)];

  private static getMovement(num: number): Move {
    if (num < 0) return Move.ALeft;
    if (num > 0) return Move.ARight;
    return Move.Nothing;
  }

  private static getNonNullStringMovement(a: string, b: string): Move {
    if (!a) return Move.ARight;
    if (!b) return Move.BRight;
    return SortUtils.getMovement(a.localeCompare(b));
  }

  static sharedSortId(s: string) {
    return s?.replace(/_((asc|ASC)|(desc|DESC))/, '');
  }

  /* *******************************************************************************************
   *                                  Variant - Sort Options                                   *
   * *******************************************************************************************/

  static sortCannabinoidOrder = (
    a: PrimaryCannabinoid | SecondaryCannabinoid,
    b: PrimaryCannabinoid | SecondaryCannabinoid
  ) => {
    const aIndex = SortUtils.CANNABINOID_SORT_ORDER.indexOf(a);
    const bIndex = SortUtils.CANNABINOID_SORT_ORDER.indexOf(b);
    return aIndex - bIndex;
  };

  static getCannabinoidAccessor = (sortType: SectionSortOption): { cannabinoid: string, order: 'ASC'|'DESC' } => {
    const [cannabinoid, order] = sortType.split(/_(?=ASC|DESC$)/) as [string, 'ASC'|'DESC'];
    return { cannabinoid, order };
  };

  static getTerpeneAccessor = (sortType: SectionSortOption): { terpeneCamelCased: string, order: 'ASC'|'DESC' } => {
    const [terpene, order] = sortType.split(/_(?=ASC|DESC$)/) as [string, 'ASC'|'DESC'];
    const terpeneCamelCased = terpene
      .split('_')
      .map((it, index) => index === 0 ? it.toLowerCase() : StringUtils.sentenceCase(it))
      .join('');
    return { terpeneCamelCased, order };
  };

  static variantsBrandAsc = (a: Variant, b: Variant): Move => SortUtils.nonNullStringAscending(a?.brand, b?.brand);
  static variantsBrandDesc = (a: Variant, b: Variant): Move => SortUtils.nonNullStringDescending(a?.brand, b?.brand);

  /** Explicitly defined ordering */
  private static strainClassificationSortAsc = (a: StrainClassification, b: StrainClassification): Move => {
    const getOrderNumber = (strainClassification: StrainClassification) => {
      // Strain Classification is sorted in the following order: Sativa - Hybrid - Blend - Indica - CBD - Other
      switch (strainClassification) {
        case StrainClassification.Sativa:         return 1;
        case StrainClassification.SativaDominant: return 2;
        case StrainClassification.Hybrid:         return 3;
        case StrainClassification.Blend:          return 4;
        case StrainClassification.Indica:         return 5;
        case StrainClassification.IndicaDominant: return 6;
        case StrainClassification.CBD:            return 7;
        default:                                  return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };
  /** Explicitly defined ordering */
  private static strainClassificationSortDesc = (a: StrainClassification, b: StrainClassification): Move => {
    const getOrderNumber = (strainClassification: StrainClassification) => {
      // Strain Classification is sorted in the following order: CBD - Indica - Blend - Hybrid - Sativa - Other
      switch (strainClassification) {
        case StrainClassification.CBD:            return 1;
        case StrainClassification.IndicaDominant: return 2;
        case StrainClassification.Indica:         return 3;
        case StrainClassification.Blend:          return 4;
        case StrainClassification.Hybrid:         return 5;
        case StrainClassification.SativaDominant: return 6;
        case StrainClassification.Sativa:         return 7;
        default:                                  return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };

  static variantsManufacturerAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringAscending(a?.manufacturer, b?.manufacturer);
  };
  static variantsManufacturerDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringDescending(a?.manufacturer, b?.manufacturer);
  };

  static variantsPackagedQuantityAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.numberAscending(a?.packagedQuantity, b?.packagedQuantity);
  };
  static variantsPackagedQuantityDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.numberDescending(a?.packagedQuantity, b?.packagedQuantity);
  };

  static variantsPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aVisiblePrice = a?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    const bVisiblePrice = b?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberAscending(aVisiblePrice, bVisiblePrice);
  };
  static variantsPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aVisiblePrice = a?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    const bVisiblePrice = b?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberDescending(aVisiblePrice, bVisiblePrice);
  };

  static variantsPriceAscNullsLast = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aVisiblePrice = a?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    const bVisiblePrice = b?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberAscNullsLast(aVisiblePrice, bVisiblePrice);
  };
  static variantsPriceDescNullsLast = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aVisiblePrice = a?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    const bVisiblePrice = b?.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberDescNullsLast(aVisiblePrice, bVisiblePrice);
  };

  static variantsPricePerUOMAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aPricePerUOM = a?.getPricePerUOM(locationId, companyId, priceFormat, hideSale);
    const bPricePerUOM = b?.getPricePerUOM(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberAscending(aPricePerUOM, bPricePerUOM);
  };
  static variantsPricePerUOMDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aPricePerUOM = a?.getPricePerUOM(locationId, companyId, priceFormat, hideSale);
    const bPricePerUOM = b?.getPricePerUOM(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberDescending(aPricePerUOM, bPricePerUOM);
  };

  static variantsOriginalPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aOriginalPrice = a?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    const bOriginalPrice = b?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    return SortUtils.numberAscending(aOriginalPrice, bOriginalPrice);
  };
  static variantsOriginalPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aOriginalPrice = a?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    const bOriginalPrice = b?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    return SortUtils.numberDescending(aOriginalPrice, bOriginalPrice);
  };

  static variantsSaleOriginalPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aSaleOriginalPrice = a?.getSaleOriginalPriceOrNull(locationId, companyId, priceFormat);
    const bSaleOriginalPrice = b?.getSaleOriginalPriceOrNull(locationId, companyId, priceFormat);
    return SortUtils.numberAscending(aSaleOriginalPrice, bSaleOriginalPrice);
  };
  static variantsSaleOriginalPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aSaleOriginalPrice = a?.getSaleOriginalPriceOrNull(locationId, companyId, priceFormat);
    const bSaleOriginalPrice = b?.getSaleOriginalPriceOrNull(locationId, companyId, priceFormat);
    return SortUtils.numberDescending(aSaleOriginalPrice, bSaleOriginalPrice);
  };

  static variantsOriginalAndSalePriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aSaleOriginalPrice = a?.getVisiblePrice(locationId, companyId, priceFormat, false);
    const bSaleOriginalPrice = b?.getVisiblePrice(locationId, companyId, priceFormat, false);
    return SortUtils.numberAscending(aSaleOriginalPrice, bSaleOriginalPrice);
  };
  static variantsOriginalAndSalePriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aOriginalAndSalePrice = a?.getVisiblePrice(locationId, companyId, priceFormat, false);
    const bOriginalAndSalePrice = b?.getVisiblePrice(locationId, companyId, priceFormat, false);
    return SortUtils.numberDescending(aOriginalAndSalePrice, bOriginalAndSalePrice);
  };

  static variantsTaxesInPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInPrice(locationId, companyId,);
    const bTaxesInPrice = b?.getTaxesInPrice(locationId, companyId);
    return SortUtils.numberAscending(aTaxesInPrice, bTaxesInPrice);
  };
  static variantsTaxesInPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInPrice(locationId, companyId);
    const bTaxesInPrice = b?.getTaxesInPrice(locationId, companyId);
    return SortUtils.numberDescending(aTaxesInPrice, bTaxesInPrice);
  };

  static variantsTaxesInRoundedPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInRoundedPrice(locationId, companyId,);
    const bTaxesInPrice = b?.getTaxesInRoundedPrice(locationId, companyId);
    return SortUtils.numberAscending(aTaxesInPrice, bTaxesInPrice);
  };
  static variantsTaxesInRoundedPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aTaxesInPrice = a?.getTaxesInRoundedPrice(locationId, companyId);
    const bTaxesInPrice = b?.getTaxesInRoundedPrice(locationId, companyId);
    return SortUtils.numberDescending(aTaxesInPrice, bTaxesInPrice);
  };

  static variantsPreTaxPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aPreTaxPrice = a?.getPreTaxPrice(locationId, companyId,);
    const bPreTaxPrice = b?.getPreTaxPrice(locationId, companyId);
    return SortUtils.numberAscending(aPreTaxPrice, bPreTaxPrice);
  };
  static variantsPreTaxPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aPreTaxPrice = a?.getPreTaxPrice(locationId, companyId);
    const bPreTaxPrice = b?.getPreTaxPrice(locationId, companyId);
    return SortUtils.numberDescending(aPreTaxPrice, bPreTaxPrice);
  };

  static variantsProductTypeAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringAscending(a?.productType, b?.productType);
  };
  static variantsProductTypeDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringDescending(a?.productType, b?.productType);
  };

  static variantsRotationPriorityAsc = (a: PrioritySortableVariant, b: PrioritySortableVariant): Move => {
    return SortUtils.numberAscending(a?.priority, b?.priority);
  };
  static variantsRotationPriorityDesc = (a: PrioritySortableVariant, b: PrioritySortableVariant): Move => {
    return SortUtils.numberDescending(a?.priority, b?.priority);
  };

  static variantsSecondaryPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return SortUtils.variantsPricePerUOMAsc([a, b], [locationId, companyId], [priceFormat, hideSale]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return SortUtils.variantsOriginalPriceAsc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return SortUtils.variantsSaleOriginalPriceAsc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return SortUtils.variantsOriginalAndSalePriceAsc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return SortUtils.variantsTaxesInPriceAsc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return SortUtils.variantsTaxesInRoundedPriceAsc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return SortUtils.variantsPreTaxPriceAsc([a, b], [locationId, companyId]);
      default:
        return a?.getSecondaryPrice(locationId, companyId) - b?.getSecondaryPrice(locationId, companyId);
    }
  };
  static variantsSecondaryPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return SortUtils.variantsPricePerUOMDesc([a, b], [locationId, companyId], [priceFormat, hideSale]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return SortUtils.variantsOriginalPriceDesc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return SortUtils.variantsSaleOriginalPriceDesc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return SortUtils.variantsOriginalAndSalePriceDesc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return SortUtils.variantsTaxesInPriceDesc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return SortUtils.variantsTaxesInRoundedPriceDesc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return SortUtils.variantsPreTaxPriceDesc([a, b], [locationId, companyId]);
      default:
        return b?.getSecondaryPrice(locationId, companyId) - a?.getSecondaryPrice(locationId, companyId);
    }
  };

  static variantsStockAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.numberAscending(a?.inventory?.quantityInStock, b?.inventory?.quantityInStock);
  };
  static variantsStockDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.numberDescending(a?.inventory?.quantityInStock, b?.inventory?.quantityInStock);
  };

  static variantsTitleAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringAscending(a?.getDisplayName()?.trim(), b?.getDisplayName()?.trim());
  };
  static variantsTitleDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringDescending(a?.getDisplayName()?.trim(), b?.getDisplayName()?.trim());
  };

  static variantsCannabinoidAsc = (a: Variant, b: Variant, cannabinoidCamelCased: string): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased)
      : a?.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased)
      : b?.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
    return SortUtils.numberAscending(aNumeric, bNumeric);
  };
  static variantsCannabinoidDesc = (a: Variant, b: Variant, cannabinoidCamelCased: string): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased)
      : a?.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased)
      : b?.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
    return SortUtils.numberDescending(aNumeric, bNumeric);
  };

  static variantsTerpeneAsc = (a: Variant, b: Variant, terpeneCamelCased: string): Move => {
    const aNumeric = a?.useTerpeneRange
      ? a?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
      : a?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    const bNumeric = b?.useTerpeneRange
      ? b?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
      : b?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    return SortUtils.numberAscending(aNumeric, bNumeric);
  };
  static variantsTerpeneDesc = (a: Variant, b: Variant, terpeneCamelCased: string): Move => {
    const aNumeric = a?.useTerpeneRange
      ? a?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
      : a?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    const bNumeric = b?.useTerpeneRange
      ? b?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
      : b?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    return SortUtils.numberDescending(aNumeric, bNumeric);
  };

  static variantsTopTerpeneAsc = (a: Variant, b: Variant): Move => {
    const aTopTerpene = a?.getVariantTopTerpene();
    const bTopTerpene = b?.getVariantTopTerpene();
    return SortUtils.nonNullStringAscending(aTopTerpene, bTopTerpene);
  };
  static variantsTopTerpeneDesc = (a: Variant, b: Variant): Move => {
    const aTopTerpene = a?.getVariantTopTerpene();
    const bTopTerpene = b?.getVariantTopTerpene();
    return SortUtils.nonNullStringDescending(aTopTerpene, bTopTerpene);
  };

  static variantsCannabinoidOrTerpeneAscNullsLast = (a: Variant, b: Variant, cannabinoid: string): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMinCannabinoidOrTerpene(cannabinoid)
      : a?.getNumericCannabinoidOrTerpene(cannabinoid);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMinCannabinoidOrTerpene(cannabinoid)
      : b?.getNumericCannabinoidOrTerpene(cannabinoid);
    return SortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsCannabinoidOrTerpeneDescNullsLast = (a: Variant, b: Variant, cannabinoid: string): Move => {
    const aNumeric = a?.useCannabinoidRange
      ? a?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
      : a?.getNumericCannabinoidOrTerpene(cannabinoid);
    const bNumeric = b?.useCannabinoidRange
      ? b?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
      : b?.getNumericCannabinoidOrTerpene(cannabinoid);
    return SortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsMinCannabinoidOrTerpeneAsc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberAscending(
      a?.getNumericMinCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMinCannabinoidOrTerpene(cannabinoid)
    );
  };
  static variantsMinCannabinoidOrTerpeneDesc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberDescending(
      a?.getNumericMinCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMinCannabinoidOrTerpene(cannabinoid)
    );
  };

  static variantsMinCannabinoidOrTerpeneAscNullsLast = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberAscNullsLast(
      a?.getNumericMinCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMinCannabinoidOrTerpene(cannabinoid)
    );
  };
  static variantsMinCannabinoidOrTerpeneDescNullsLast = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberDescNullsLast(
      a?.getNumericMinCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMinCannabinoidOrTerpene(cannabinoid)
    );
  };

  static variantsMaxCannabinoidOrTerpeneAsc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberAscending(
      a?.getNumericMaxCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
    );
  };
  static variantsMaxCannabinoidOrTerpeneDesc = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberDescending(
      a?.getNumericMaxCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
    );
  };

  static variantsMaxCannabinoidOrTerpeneAscNullsLast = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberAscNullsLast(
      a?.getNumericMaxCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
    );
  };
  static variantsMaxCannabinoidOrTerpeneDescNullsLast = (a: Variant, b: Variant, cannabinoid: string): Move => {
    return SortUtils.numberDescNullsLast(
      a?.getNumericMaxCannabinoidOrTerpene(cannabinoid),
      b?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
    );
  };

  static variantTotalTerpenesAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.numericStringAsc(
      a?.getTotalTerpenes(),
      b?.getTotalTerpenes()
    );
  };
  static variantTotalTerpenesDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.numericStringDesc(
      a?.getTotalTerpenes(),
      b?.getTotalTerpenes()
    );
  };

  static variantsVariantTypeAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringAscending(a?.variantType, b?.variantType);
  };
  static variantsVariantTypeDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.nonNullStringDescending(a?.variantType, b?.variantType);
  };

  static variantsUnitSizeAsc = (a: Variant, b: Variant): Move => SortUtils.numberAscending(a?.unitSize, b?.unitSize);
  static variantsUnitSizeDesc = (a: Variant, b: Variant): Move => SortUtils.numberDescending(a?.unitSize, b?.unitSize);

  static sortVariantsByUnitSizeAscElsePackagedQuantityAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.variantsUnitSizeAsc(a, b) || SortUtils.variantsPackagedQuantityAsc(a, b);
  };

  /* *******************************************************************************************
   *                            Section Variants - Sort Options                                *
   * *******************************************************************************************/

  static sectionVariantsByBrandAsc = (a: Variant, b: Variant): Move => SortUtils.variantsBrandAsc(a, b);
  static sectionVariantsByBrandDesc = (a: Variant, b: Variant): Move => SortUtils.variantsBrandDesc(a, b);

  static sectionVariantsByClassificationAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.strainClassificationSortAsc(a?.classification, b?.classification);
  };
  static sectionVariantsByClassificationDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.strainClassificationSortDesc(a?.classification, b?.classification);
  };

  static sectionVariantsByManufacturerAsc = (a: Variant, b: Variant): Move => SortUtils.variantsManufacturerAsc(a, b);
  static sectionVariantsByManufacturerDesc = (a: Variant, b: Variant): Move => SortUtils.variantsManufacturerDesc(a, b);

  static sectionVariantsByPackagedQuantityAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.variantsPackagedQuantityAsc(a, b);
  };
  static sectionVariantsByPackagedQuantityDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.variantsPackagedQuantityDesc(a, b);
  };

  static sectionVariantsByPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantsPriceAsc([a, b], [locationId, companyId], [priceFormat, hideSale]);
  };
  static sectionVariantsByPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantsPriceDesc([a, b], [locationId, companyId], [priceFormat, hideSale]);
  };

  static sectionVariantsByProductTypeAsc = (a: Variant, b: Variant): Move => SortUtils.variantsProductTypeAsc(a, b);
  static sectionVariantsByProductTypeDesc = (a: Variant, b: Variant): Move => SortUtils.variantsProductTypeDesc(a, b);

  static sectionVariantsBySecondaryPriceAsc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantsSecondaryPriceAsc([a, b], [locationId, companyId], [section, priceFormat, hideSale]);
  };
  static sectionVariantsBySecondaryPriceDesc = (
    [a, b]: [Variant, Variant],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantsSecondaryPriceDesc([a, b], [locationId, companyId], [section, priceFormat, hideSale]);
  };

  static sectionVariantsByStockAsc = (a: Variant, b: Variant): Move => SortUtils.variantsStockAsc(a, b);
  static sectionVariantsByStockDesc = (a: Variant, b: Variant): Move => SortUtils.variantsStockDesc(a, b);

  static sectionVariantsByTitleAsc = (a: Variant, b: Variant): Move => SortUtils.variantsTitleAsc(a, b);
  static sectionVariantsByTitleDesc = (a: Variant, b: Variant): Move => SortUtils.variantsTitleDesc(a, b);

  static sectionVariantsCannabinoidAsc = (a: Variant, b: Variant, cannabinoidCamelCased: string): Move => {
    return SortUtils.variantsCannabinoidAsc(a, b, cannabinoidCamelCased);
  };
  static sectionVariantsCannabinoidDesc = (a: Variant, b: Variant, cannabinoidCamelCased: string): Move => {
    return SortUtils.variantsCannabinoidDesc(a, b, cannabinoidCamelCased);
  };

  static sectionVariantsTerpeneAsc = (a: Variant, b: Variant, cannabinoidCamelCased: string): Move => {
    return SortUtils.variantsTerpeneAsc(a, b, cannabinoidCamelCased);
  };
  static sectionVariantsTerpeneDesc = (a: Variant, b: Variant, cannabinoidCamelCased: string): Move => {
    return SortUtils.variantsTerpeneDesc(a, b, cannabinoidCamelCased);
  };

  static sectionVariantsTopTerpeneAsc = (a: Variant, b: Variant): Move => {
    return SortUtils.variantsTopTerpeneAsc(a, b);
  };
  static sectionVariantsTopTerpeneDesc = (a: Variant, b: Variant): Move => {
    return SortUtils.variantsTopTerpeneDesc(a, b);
  };

  static sectionVariantsByUnitSizeAsc = (a: Variant, b: Variant): Move => SortUtils.variantsUnitSizeAsc(a, b);
  static sectionVariantsByUnitSizeDesc = (a: Variant, b: Variant): Move => SortUtils.variantsUnitSizeDesc(a, b);

  static sectionVariantsVariantTypeAsc = (a: Variant, b: Variant): Move => SortUtils.variantsVariantTypeAsc(a, b);
  static sectionVariantsVariantTypeDesc = (a: Variant, b: Variant): Move => SortUtils.variantsVariantTypeDesc(a, b);

  private static sectionVariantSorter(
    sortOption: SectionSortOption,
    [a, b]: [Variant, Variant],
    [locId, compId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move {
    const getProduct = () => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
          return SortUtils.sectionVariantsByBrandAsc(a, b);
        case SectionSortProductInfo.BrandDesc:
          return SortUtils.sectionVariantsByBrandDesc(a, b);
        case SectionSortProductInfo.CBDAsc:
          return SortUtils.sectionVariantsCannabinoidAsc(a, b, 'CBD');
        case SectionSortProductInfo.CBDDesc:
          return SortUtils.sectionVariantsCannabinoidDesc(a, b, 'CBD');
        case SectionSortProductInfo.ClassificationAsc:
          return SortUtils.sectionVariantsByClassificationAsc(a, b);
        case SectionSortProductInfo.ClassificationDesc:
          return SortUtils.sectionVariantsByClassificationDesc(a, b);
        case SectionSortProductInfo.ManufacturerAsc:
          return SortUtils.sectionVariantsByManufacturerAsc(a, b);
        case SectionSortProductInfo.ManufacturerDesc:
          return SortUtils.sectionVariantsByManufacturerDesc(a, b);
        case SectionSortProductInfo.PackagedQuantityAsc:
          return SortUtils.sectionVariantsByPackagedQuantityAsc(a, b);
        case SectionSortProductInfo.PackagedQuantityDesc:
          return SortUtils.sectionVariantsByPackagedQuantityDesc(a, b);
        case SectionSortProductInfo.PriceAsc:
          return SortUtils.sectionVariantsByPriceAsc([a, b], [locId, compId], [priceFormat, hideSale]);
        case SectionSortProductInfo.PriceDesc:
          return SortUtils.sectionVariantsByPriceDesc([a, b], [locId, compId], [priceFormat, hideSale]);
        case SectionSortProductInfo.ProductTypeAsc:
          return SortUtils.sectionVariantsByProductTypeAsc(a, b);
        case SectionSortProductInfo.ProductTypeDesc:
          return SortUtils.sectionVariantsByProductTypeDesc(a, b);
        case SectionSortProductInfo.SecondaryPriceAsc:
          return SortUtils.sectionVariantsBySecondaryPriceAsc(
            [a, b],
            [locId, compId],
            [section, priceFormat, hideSale]
          );
        case SectionSortProductInfo.SecondaryPriceDesc:
          return SortUtils.sectionVariantsBySecondaryPriceDesc(
            [a, b],
            [locId, compId],
            [section, priceFormat, hideSale]
          );
        case SectionSortProductInfo.StockAsc:
          return SortUtils.sectionVariantsByStockAsc(a, b);
        case SectionSortProductInfo.StockDesc:
          return SortUtils.sectionVariantsByStockDesc(a, b);
        case SectionSortProductInfo.TitleAsc:
          return SortUtils.sectionVariantsByTitleAsc(a, b);
        case SectionSortProductInfo.TitleDesc:
          return SortUtils.sectionVariantsByTitleDesc(a, b);
        case SectionSortProductInfo.THCAsc:
          return SortUtils.sectionVariantsCannabinoidAsc(a, b, 'THC');
        case SectionSortProductInfo.THCDesc:
          return SortUtils.sectionVariantsCannabinoidDesc(a, b, 'THC');
        case SectionSortProductInfo.TopTerpeneAsc:
          return SortUtils.sectionVariantsTopTerpeneAsc(a, b);
        case SectionSortProductInfo.TopTerpeneDesc:
          return SortUtils.sectionVariantsTopTerpeneDesc(a, b);
        case SectionSortProductInfo.TotalTerpenesAsc:
          return SortUtils.sectionVariantsTerpeneAsc(a, b, 'totalTerpene');
        case SectionSortProductInfo.TotalTerpenesDesc:
          return SortUtils.sectionVariantsTerpeneDesc(a, b, 'totalTerpene');
        case SectionSortProductInfo.UnitSizeAsc:
          return SortUtils.sectionVariantsByUnitSizeAsc(a, b);
        case SectionSortProductInfo.UnitSizeDesc:
          return SortUtils.sectionVariantsByUnitSizeDesc(a, b);
        case SectionSortProductInfo.VariantTypeAsc:
          return SortUtils.sectionVariantsVariantTypeAsc(a, b);
        case SectionSortProductInfo.VariantTypeDesc:
          return SortUtils.sectionVariantsVariantTypeDesc(a, b);
        default:
          return SortUtils.variantsTitleAsc(a, b);
      }
    };

    const getSecondaryCannabinoid = (): Move =>  {
      const { cannabinoid, order } = SortUtils.getCannabinoidAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortUtils.sectionVariantsCannabinoidAsc(a, b, cannabinoid)
        : SortUtils.sectionVariantsCannabinoidDesc(a, b, cannabinoid);
    };

    const getTerpene = (): Move => {
      const { terpeneCamelCased, order } = SortUtils.getTerpeneAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortUtils.sectionVariantsTerpeneAsc(a, b, terpeneCamelCased)
        : SortUtils.sectionVariantsTerpeneDesc(a, b, terpeneCamelCased);
    };

    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return getProduct();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpene();
    }
  }

  static sortVariantsBySectionSortOptions = (
    variants: Variant[],
    menu: Menu,
    section: Section,
    priceFormat: PriceFormat
  ): Variant[] => {
    const sorter = SortUtils.sectionVariantSorter;
    return SortUtils.sortVariantsInSection(sorter, variants, menu, section, priceFormat);
  };

  /* *******************************************************************************************
   *                             Variant Groups - Sort Options                                 *
   * *******************************************************************************************/

  static variantGroupsBrandAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringAscending(a?.getGroupBrand(), b?.getGroupBrand());
  };
  static variantGroupsBrandDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringDescending(a?.getGroupBrand(), b?.getGroupBrand());
  };

  static variantGroupsClassificationAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.strainClassificationSortAsc(a?.getGroupClassification(), b?.getGroupClassification());
  };
  static variantGroupsClassificationDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.strainClassificationSortDesc(a?.getGroupClassification(), b?.getGroupClassification());
  };

  static variantGroupsManufacturerAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringAscending(a?.getGroupManufacturer(), b?.getGroupManufacturer());
  };
  static variantGroupsManufacturerDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringDescending(a?.getGroupManufacturer(), b?.getGroupManufacturer());
  };

  static variantGroupsPackagedQuantityAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.numberAscending(a?.getMinPackageQuantity(), b?.getMinPackageQuantity());
  };
  static variantGroupsPackagedQuantityDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.numberDescending(a?.getMaxPackageQuantity(), b?.getMaxPackageQuantity());
  };

  static variantGroupsPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aGroupPrice = a?.getMinGroupPrice(locationId, companyId, priceFormat, hideSale);
    const bGroupPrice = b?.getMinGroupPrice(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberAscending(aGroupPrice, bGroupPrice);
  };
  static variantGroupsPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aGroupPrice = a?.getMinGroupPrice(locationId, companyId, priceFormat, hideSale);
    const bGroupPrice = b?.getMinGroupPrice(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberDescending(aGroupPrice, bGroupPrice);
  };

  static variantGroupsPricePerUOMAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const aMinGroupPricePerUOM = a?.getMinGroupPricePerUOM(locationId, companyId, priceFormat, hideSale);
    const bMinGroupPricePerUOM = b?.getMinGroupPricePerUOM(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberAscending(aMinGroupPricePerUOM, bMinGroupPricePerUOM);
  };
  static variantGroupsPricePerUOMDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    const bMaxGroupPricePerUOM = b?.getMaxGroupPricePerUOM(locationId, companyId, priceFormat, hideSale);
    const aMaxGroupPricePerUOM = a?.getMaxGroupPricePerUOM(locationId, companyId, priceFormat, hideSale);
    return SortUtils.numberDescending(aMaxGroupPricePerUOM, bMaxGroupPricePerUOM);
  };

  static variantGroupsOriginalPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aMinOriginalPrice = a?.getMinOriginalPrice(locationId, companyId, priceFormat);
    const bMinOriginalPrice = b?.getMinOriginalPrice(locationId, companyId, priceFormat);
    return SortUtils.numberAscending(aMinOriginalPrice, bMinOriginalPrice);
  };
  static variantGroupsOriginalPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aMinOriginalPrice = a?.getMaxOriginalPrice(locationId, companyId, priceFormat);
    const bMinOriginalPrice = b?.getMaxOriginalPrice(locationId, companyId, priceFormat);
    return SortUtils.numberDescending(aMinOriginalPrice, bMinOriginalPrice);
  };

  static variantGroupsSaleOriginalPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aMinSaleOriginalPrice = a?.getMinSaleOriginalPrice(locationId, companyId, priceFormat);
    const bMinSaleOriginalPrice = b?.getMinSaleOriginalPrice(locationId, companyId, priceFormat);
    return SortUtils.numberAscending(aMinSaleOriginalPrice, bMinSaleOriginalPrice);
  };
  static variantGroupsSaleOriginalPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aMinSaleOriginalPrice = a?.getMaxSaleOriginalPrice(locationId, companyId, priceFormat);
    const bMinSaleOriginalPrice = b?.getMaxSaleOriginalPrice(locationId, companyId, priceFormat);
    return SortUtils.numberDescending(aMinSaleOriginalPrice, bMinSaleOriginalPrice);
  };

  static variantGroupsOriginalAndSalePriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aMinOriginalAndSalePrice = a?.getMinGroupPrice(locationId, companyId, priceFormat, false);
    const bMinOriginalAndSalePrice = b?.getMinGroupPrice(locationId, companyId, priceFormat, false);
    return SortUtils.numberAscending(aMinOriginalAndSalePrice, bMinOriginalAndSalePrice);
  };
  static variantGroupsOriginalAndSalePriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat]: [PriceFormat]
  ): Move => {
    const aMinOriginalAndSalePrice = a?.getMaxGroupPrice(locationId, companyId, priceFormat, false);
    const bMinOriginalAndSalePrice = b?.getMaxGroupPrice(locationId, companyId, priceFormat, false);
    return SortUtils.numberDescending(aMinOriginalAndSalePrice, bMinOriginalAndSalePrice);
  };

  static variantGroupsTaxesInPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMinTaxesInPrice(locationId, companyId);
    const bMinTaxesInPrice = b?.getMinTaxesInPrice(locationId, companyId);
    return SortUtils.numberAscending(aMinTaxesInPrice, bMinTaxesInPrice);
  };
  static variantGroupsTaxesInPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMaxTaxesInPrice(locationId, companyId);
    const bMinTaxesInPrice = b?.getMaxTaxesInPrice(locationId, companyId);
    return SortUtils.numberDescending(aMinTaxesInPrice, bMinTaxesInPrice);
  };

  static variantGroupsTaxesInRoundedPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMinTaxesInRoundedPrice(locationId, companyId);
    const bMinTaxesInPrice = b?.getMinTaxesInRoundedPrice(locationId, companyId);
    return SortUtils.numberAscending(aMinTaxesInPrice, bMinTaxesInPrice);
  };
  static variantGroupsTaxesInRoundedPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMaxTaxesInRoundedPrice(locationId, companyId);
    const bMinTaxesInPrice = b?.getMaxTaxesInRoundedPrice(locationId, companyId);
    return SortUtils.numberDescending(aMinTaxesInPrice, bMinTaxesInPrice);
  };

  static variantGroupsPreTaxPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
  ): Move => {
    const aMinPreTaxPrice = a?.getMinPreTaxPrice(locationId, companyId);
    const bMinPreTaxPrice = b?.getMinPreTaxPrice(locationId, companyId);
    return SortUtils.numberAscending(aMinPreTaxPrice, bMinPreTaxPrice);
  };
  static variantGroupsPreTaxPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
  ): Move => {
    const aMinPreTaxPrice = a?.getMaxPreTaxPrice(locationId, companyId);
    const bMinPreTaxPrice = b?.getMaxPreTaxPrice(locationId, companyId);
    return SortUtils.numberDescending(aMinPreTaxPrice, bMinPreTaxPrice);
  };

  static variantGroupsMinSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aMinGroupSecondaryPrice = a?.getMinGroupSecondaryPrice(locationId, companyId);
    const bMinGroupSecondaryPrice = b?.getMinGroupSecondaryPrice(locationId, companyId);
    return SortUtils.numberAscending(aMinGroupSecondaryPrice, bMinGroupSecondaryPrice);
  };
  static variantGroupsMinSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aMinGroupSecondaryPrice = a?.getMinGroupSecondaryPrice(locationId, companyId);
    const bMinGroupSecondaryPrice = b?.getMinGroupSecondaryPrice(locationId, companyId);
    return SortUtils.numberDescending(aMinGroupSecondaryPrice, bMinGroupSecondaryPrice);
  };

  static variantGroupsMaxSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aMaxGroupSecondaryPrice = a?.getMaxGroupSecondaryPrice(locationId, companyId);
    const bMaxGroupSecondaryPrice = b?.getMaxGroupSecondaryPrice(locationId, companyId);
    return SortUtils.numberAscending(aMaxGroupSecondaryPrice, bMaxGroupSecondaryPrice);
  };
  static variantGroupsMaxSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number]
  ): Move => {
    const aMaxGroupSecondaryPrice = a?.getMaxGroupSecondaryPrice(locationId, companyId);
    const bMaxGroupSecondaryPrice = b?.getMaxGroupSecondaryPrice(locationId, companyId);
    return SortUtils.numberDescending(aMaxGroupSecondaryPrice, bMaxGroupSecondaryPrice);
  };

  static variantGroupsProductTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringAscending(a?.getGroupProductType(), b?.getGroupProductType());
  };
  static variantGroupsProductTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringDescending(a?.getGroupProductType(), b?.getGroupProductType());
  };

  static variantGroupsSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return SortUtils.variantGroupsPricePerUOMAsc([a, b], [locationId, companyId], [priceFormat, hideSale]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return SortUtils.variantGroupsOriginalPriceAsc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return SortUtils.variantGroupsSaleOriginalPriceAsc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return SortUtils.variantGroupsOriginalAndSalePriceAsc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return SortUtils.variantGroupsTaxesInPriceAsc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return SortUtils.variantGroupsTaxesInRoundedPriceAsc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return SortUtils.variantGroupsPreTaxPriceAsc([a, b], [locationId, companyId]);
      default:
        return SortUtils.variantGroupsMinSecondaryPriceAsc([a, b], [locationId, companyId]);
    }
  };
  static variantGroupsSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return SortUtils.variantGroupsPricePerUOMDesc([a, b], [locationId, companyId], [priceFormat, hideSale]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return SortUtils.variantGroupsOriginalPriceDesc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return SortUtils.variantGroupsSaleOriginalPriceDesc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return SortUtils.variantGroupsOriginalAndSalePriceDesc([a, b], [locationId, companyId], [priceFormat]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return SortUtils.variantGroupsTaxesInPriceDesc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return SortUtils.variantGroupsTaxesInRoundedPriceDesc([a, b], [locationId, companyId]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return SortUtils.variantGroupsPreTaxPriceDesc([a, b], [locationId, companyId]);
      default:
        return SortUtils.variantGroupsMaxSecondaryPriceDesc([a, b], [locationId, companyId]);
    }
  };

  static variantGroupsStockAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.numberAscending(a?.getMaxVariantStock(), b?.getMaxVariantStock());
  };
  static variantGroupsStockDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.numberDescending(a?.getMaxVariantStock(), b?.getMaxVariantStock());
  };

  static variantGroupsTitleAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringAscending(a?.getGroupTitle(), b?.getGroupTitle());
  };
  static variantGroupsTitleDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringDescending(a?.getGroupTitle(), b?.getGroupTitle());
  };

  static variantGroupsUnitSizeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.numberAscending(a?.getMinNumericUnitSize(), b?.getMinNumericUnitSize());
  };
  static variantGroupsUnitSizeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.numberDescending(a?.getMaxNumericUnitSize(), b?.getMaxNumericUnitSize());
  };

  static variantGroupsVariantTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringAscending(a?.getGroupVariantType(), b?.getGroupVariantType());
  };
  static variantGroupsVariantTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringDescending(a?.getGroupVariantType(), b?.getGroupVariantType());
  };

  static variantGroupsCannabinoidAsc = (a: VariantGroup, b: VariantGroup, canabinoidCamelCased: string): Move => {
    return SortUtils.numberAscending(
      a?.getMinNumericCannabinoid(canabinoidCamelCased),
      b?.getMinNumericCannabinoid(canabinoidCamelCased)
    );
  };
  static variantGroupsCannabinoidDesc = (a: VariantGroup, b: VariantGroup, canabinoidCamelCased: string): Move => {
    return SortUtils.numberDescending(
      a?.getMaxNumericCannabinoid(canabinoidCamelCased),
      b?.getMaxNumericCannabinoid(canabinoidCamelCased)
    );
  };

  static variantGroupsTerpeneAsc = (a: VariantGroup, b: VariantGroup, terpeneCamelCased: string): Move => {
    return SortUtils.numberAscending(
      a?.getMinNumericTerpene(terpeneCamelCased),
      b?.getMinNumericTerpene(terpeneCamelCased)
    );
  };
  static variantGroupsTerpeneDesc = (a: VariantGroup, b: VariantGroup, terpeneCamelCased: string): Move => {
    return SortUtils.numberDescending(
      a?.getMaxNumericTerpene(terpeneCamelCased),
      b?.getMaxNumericTerpene(terpeneCamelCased)
    );
  };

  static variantGroupsTopTerpeneAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringAscending(a?.getVariantGroupTopTerpene(), b?.getVariantGroupTopTerpene());
  };
  static variantGroupsTopTerpeneDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.nonNullStringDescending(a?.getVariantGroupTopTerpene(), b?.getVariantGroupTopTerpene());
  };

  /* *******************************************************************************************
   *                          Section Variant Groups - Sort Options                            *
   * *******************************************************************************************/

  static sectionVariantGroupsBrandAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsBrandAsc(a, b);
  };
  static sectionVariantGroupsBrandDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsBrandDesc(a, b);
  };

  static sectionVariantGroupsClassificationAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsClassificationAsc(a, b);
  };
  static sectionVariantGroupsClassificationDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsClassificationDesc(a, b);
  };

  static sectionVariantGroupsManufacturerAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsManufacturerAsc(a, b);
  };
  static sectionVariantGroupsManufacturerDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsManufacturerDesc(a, b);
  };

  static sectionVariantGroupsPackagedQuantityAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsPackagedQuantityAsc(a, b);
  };
  static sectionVariantGroupsPackagedQuantityDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsPackagedQuantityDesc(a, b);
  };

  static sectionVariantGroupsPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantGroupsPriceAsc([a, b], [locationId, companyId], [priceFormat, hideSale]);
  };
  static sectionVariantGroupsPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [priceFormat, hideSale]: [PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantGroupsPriceDesc([a, b], [locationId, companyId], [priceFormat, hideSale]);
  };

  static sectionVariantGroupsProductTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsProductTypeAsc(a, b);
  };
  static sectionVariantGroupsProductTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsProductTypeDesc(a, b);
  };

  static sectionVariantGroupsSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantGroupsSecondaryPriceAsc([a, b], [locationId, companyId], [section, priceFormat, hideSale]);
  };
  static sectionVariantGroupsSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [locationId, companyId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move => {
    return SortUtils.variantGroupsSecondaryPriceDesc([a, b], [locationId, companyId], [section, priceFormat, hideSale]);
  };

  static sectionVariantGroupsStockAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsStockAsc(a, b);
  };
  static sectionVariantGroupsStockDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsStockDesc(a, b);
  };

  static sectionVariantGroupsTitleAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsTitleAsc(a, b);
  };
  static sectionVariantGroupsTitleDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsTitleDesc(a, b);
  };

  static sectionVariantGroupsUnitSizeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsUnitSizeAsc(a, b);
  };
  static sectionVariantGroupsUnitSizeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsUnitSizeDesc(a, b);
  };

  static sectionVariantGroupsVariantTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsVariantTypeAsc(a, b);
  };
  static sectionVariantGroupsVariantTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsVariantTypeDesc(a, b);
  };

  static sectionVariantGroupsCannabinoidAsc = (
    a: VariantGroup,
    b: VariantGroup,
    canabinoidCamelCased: string
  ): Move => {
    return SortUtils.variantGroupsCannabinoidAsc(a, b, canabinoidCamelCased);
  };
  static sectionVariantGroupsCannabinoidDesc = (
    a: VariantGroup,
    b: VariantGroup,
    canabinoidCamelCased: string
  ): Move => {
    return SortUtils.variantGroupsCannabinoidDesc(a, b, canabinoidCamelCased);
  };

  static sectionVariantGroupsTerpeneAsc = (a: VariantGroup, b: VariantGroup, terpeneCamelCased: string): Move => {
    return SortUtils.variantGroupsTerpeneAsc(a, b, terpeneCamelCased);
  };
  static sectionVariantGroupsTerpeneDesc = (a: VariantGroup, b: VariantGroup, terpeneCamelCased: string): Move => {
    return SortUtils.variantGroupsTerpeneDesc(a, b, terpeneCamelCased);
  };

  static sectionVariantGroupsTopTerpeneAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsTopTerpeneAsc(a, b);
  };
  static sectionVariantGroupsTopTerpeneDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortUtils.variantGroupsTopTerpeneDesc(a, b);
  };

  private static sectionVariantGroupsSorter(
    sortOption: SectionSortOption,
    [a, b]: [VariantGroup, VariantGroup],
    [lId, cId]: [number, number],
    [section, priceFormat, hideSale]: [Section, PriceFormat, boolean]
  ): Move {
    const productSortType = (): Move => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
          return SortUtils.sectionVariantGroupsBrandAsc(a, b);
        case SectionSortProductInfo.BrandDesc:
          return SortUtils.sectionVariantGroupsBrandDesc(a, b);
        // CBDAsc uses min CBD
        case SectionSortProductInfo.CBDAsc:
          return SortUtils.sectionVariantGroupsCannabinoidAsc(a, b, 'CBD');
        // CBDDesc uses max CBD
        case SectionSortProductInfo.CBDDesc:
          return SortUtils.sectionVariantGroupsCannabinoidDesc(a, b, 'CBD');
        case SectionSortProductInfo.ClassificationAsc:
          return SortUtils.sectionVariantGroupsClassificationAsc(a, b);
        case SectionSortProductInfo.ClassificationDesc:
          return SortUtils.sectionVariantGroupsClassificationDesc(a, b);
        case SectionSortProductInfo.ManufacturerAsc:
          return SortUtils.sectionVariantGroupsManufacturerAsc(a, b);
        case SectionSortProductInfo.ManufacturerDesc:
          return SortUtils.sectionVariantGroupsManufacturerDesc(a, b);
        // PackagedQuantityAsc uses min packaged quantity
        case SectionSortProductInfo.PackagedQuantityAsc:
          return SortUtils.sectionVariantGroupsPackagedQuantityAsc(a, b);
        // PackagedQuantityDesc uses max packaged quantity
        case SectionSortProductInfo.PackagedQuantityDesc:
          return SortUtils.sectionVariantGroupsPackagedQuantityDesc(a, b);
        // PriceAsc uses min price
        case SectionSortProductInfo.PriceAsc:
          return SortUtils.sectionVariantGroupsPriceAsc([a, b], [lId, cId], [priceFormat, hideSale]);
        // PriceDesc uses max price
        case SectionSortProductInfo.PriceDesc:
          return SortUtils.sectionVariantGroupsPriceDesc([a, b], [lId, cId], [priceFormat, hideSale]);
        case SectionSortProductInfo.ProductTypeAsc:
          return SortUtils.sectionVariantGroupsProductTypeAsc(a, b);
        case SectionSortProductInfo.ProductTypeDesc:
          return SortUtils.sectionVariantGroupsProductTypeDesc(a, b);
        // PriceAsc uses min secondary price
        case SectionSortProductInfo.SecondaryPriceAsc:
          return SortUtils.sectionVariantGroupsSecondaryPriceAsc([a, b], [lId, cId], [section, priceFormat, hideSale]);
        // PriceDesc uses max secondary price
        case SectionSortProductInfo.SecondaryPriceDesc:
          return SortUtils.sectionVariantGroupsSecondaryPriceDesc([a, b], [lId, cId], [section, priceFormat, hideSale]);
        case SectionSortProductInfo.StockAsc:
          return SortUtils.sectionVariantGroupsStockAsc(a, b);
        case SectionSortProductInfo.StockDesc:
          return SortUtils.sectionVariantGroupsStockDesc(a, b);
        case SectionSortProductInfo.TitleAsc:
          return SortUtils.sectionVariantGroupsTitleAsc(a, b);
        case SectionSortProductInfo.TitleDesc:
          return SortUtils.sectionVariantGroupsTitleDesc(a, b);
        // THCAsc uses min THC
        case SectionSortProductInfo.THCAsc:
          return SortUtils.sectionVariantGroupsCannabinoidAsc(a, b, 'THC');
        // THCDesc uses max THC
        case SectionSortProductInfo.THCDesc:
          return SortUtils.sectionVariantGroupsCannabinoidDesc(a, b, 'THC');
        case SectionSortProductInfo.TopTerpeneAsc:
          return SortUtils.sectionVariantGroupsTopTerpeneAsc(a, b);
        case SectionSortProductInfo.TopTerpeneDesc:
          return SortUtils.sectionVariantGroupsTopTerpeneDesc(a, b);
        case SectionSortProductInfo.TotalTerpenesAsc:
          return SortUtils.sectionVariantGroupsTerpeneAsc(a, b, 'totalTerpene');
        case SectionSortProductInfo.TotalTerpenesDesc:
          return SortUtils.sectionVariantGroupsTerpeneDesc(a, b, 'totalTerpene');
        // UnitSizeAsc uses min unit size
        case SectionSortProductInfo.UnitSizeAsc:
          return SortUtils.sectionVariantGroupsUnitSizeAsc(a, b);
        // UnitSizeDesc uses max unit size
        case SectionSortProductInfo.UnitSizeDesc:
          return SortUtils.sectionVariantGroupsUnitSizeDesc(a, b);
        case SectionSortProductInfo.VariantTypeAsc:
          return SortUtils.sectionVariantGroupsVariantTypeAsc(a, b);
        case SectionSortProductInfo.VariantTypeDesc:
          return SortUtils.sectionVariantGroupsVariantTypeDesc(a, b);
        default:
          return SortUtils.variantGroupsTitleAsc(a, b);
      }
    };

    const getSecondaryCannabinoid = (): Move =>  {
      const { cannabinoid, order } = SortUtils.getCannabinoidAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortUtils.sectionVariantGroupsCannabinoidAsc(a, b, cannabinoid)
        : SortUtils.sectionVariantGroupsCannabinoidDesc(a, b, cannabinoid);
    };
    const getTerpene = (): Move => {
      const { terpeneCamelCased, order } = SortUtils.getTerpeneAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortUtils.sectionVariantGroupsTerpeneAsc(a, b, terpeneCamelCased)
        : SortUtils.sectionVariantGroupsTerpeneDesc(a, b, terpeneCamelCased);
    };

    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return productSortType();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpene();
    }
  }

  static variantGroupsBySortOptions = (
    variantGroups: VariantGroup[],
    menu: Menu,
    section: Section,
    priceFormat: PriceFormat
  ): VariantGroup[] => {
    const sorter = SortUtils.sectionVariantGroupsSorter;
    const sortedVariantGroups = SortUtils.sortVariantsInSection(sorter, variantGroups, menu, section, priceFormat);
    const sortVariantsInGrouping = (variantGroup: VariantGroup) => {
      variantGroup.sortVariantsBy(SortUtils.sortVariantsBySectionSortOptions, menu, section, priceFormat);
    };
    sortedVariantGroups.forEach(sortVariantsInGrouping);
    return sortedVariantGroups;
  };

  /**
   * Generic, can sort a list of variants or a list of variant groups.
   * If there is no secondary sort, then the tertiary sort becomes the secondary sort.
   * Don't do the tertiary sort if it's the same as secondary sort, otherwise always do tertiary sort.
   * Short-circuit evaluation used to prevent unnecessary function calls.
   * Primary sort always exists.
   * Secondary sort is optional.
   * Tertiary sort always exists and is hard-coded to title asc.
   *
   * @param sorter - the function that is responsible for sorting the variants
   * @param data - the list of variants or variant groups to sort
   * @param menu - the menu the variants belong to
   * @param sec - the section the variants belong to
   * @param priceFormat - the price format that the location is using
   * @returns the sorted list of variants or variant groups
   */
  static sortVariantsInSection<T>(
    sorter: (
      sortOption: SectionSortOption,
      [a, b]: [T, T],
      [lId, cId]: [number, number],
      [section, priceFormatForSort, hideSaleForSort]: [Section, PriceFormat, boolean]
    ) => Move,
    data: T[],
    menu: Menu,
    sec: Section,
    priceFormat: PriceFormat,
  ): T[] {
    const primary = sec?.sorting;
    const secondary = sec?.secondarySorting;
    const tertiary = SortUtils.DEFAULT_SECTION_TERTIARY_SORT;
    const locId = menu?.locationId;
    const compId = menu?.companyId;
    const hideSale = menu?.menuOptions?.hideSale ?? false;
    const primarySortId = SortUtils.sharedSortId(primary);
    const secondarySortId = SortUtils.sharedSortId(secondary);
    const tertiarySortId = SortUtils.sharedSortId(tertiary);
    const sortByTertiary = secondarySortId !== tertiarySortId;
    const sortVariantsInSection = (a: T, b: T) => {
      // short-circuit calculation
      return sorter(primary, [a, b], [locId, compId], [sec, priceFormat, hideSale])
          || (!!secondary ? sorter(secondary, [a, b], [locId, compId], [sec, priceFormat, hideSale]) : Move.Nothing)
          || (sortByTertiary ? sorter(tertiary, [a, b], [locId, compId], [sec, priceFormat, hideSale]) : Move.Nothing);
    };
    return data?.sort(sortVariantsInSection) || [];
  }

  /* *******************************************************************************************
   *                                Represent Sorting in UI                                    *
   * *******************************************************************************************/

  static getVariantPreviewSortSubtext(
    sortPosition: SortOrderPosition,
    menu: Menu,
    sec: HydratedSection,
    variant: Variant,
    priceFormat: PriceFormat
  ): string {
    const option = SortUtils.getSectionSortOptionFromPosition(sortPosition, sec);
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(option as SectionSortProductInfo):
        return SortUtils.getSectionSortProductInfo(option as SectionSortProductInfo, menu, sec, variant, priceFormat);
      case Object.values(SectionSortSecondaryCannabinoids).includes(option as SectionSortSecondaryCannabinoids):
        return SortUtils.getSectionSortCannabinoidsInfo(option as SectionSortSecondaryCannabinoids);
      case Object.values(SectionSortTerpenes).includes(option as SectionSortTerpenes):
        return SortUtils.getSectionSortTerpenesInfo(option as SectionSortTerpenes);
    }
  }

  static getSectionSortProductInfo(
    sortOption: SectionSortProductInfo,
    menu: Menu,
    section: HydratedSection,
    variant: Variant,
    priceFormat: PriceFormat
  ): string {
    switch (true) {
      case sortOption === SectionSortProductInfo.SecondaryPriceAsc:
      case sortOption === SectionSortProductInfo.SecondaryPriceDesc:
        return SortUtils.getSectionSortSecondaryPriceInfo(menu, section, variant, priceFormat);
      default: {
        const splitResult = sortOption.split('_');
        const productInfo = splitResult.length < 3 ? splitResult[0] : `${splitResult[0]} ${splitResult[1]}`;
        return (productInfo === PrimaryCannabinoid.THC || productInfo === PrimaryCannabinoid.CBD)
          ? productInfo
          : StringUtils.toTitleCase(productInfo);
      }
    }
  }

  static getSectionSortSecondaryPriceInfo(
    menu: Menu,
    section: HydratedSection,
    variant: Variant,
    priceFormat: PriceFormat
  ): string {
    const secondaryPriceColumnConfig = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice);
    switch (secondaryPriceColumnConfig?.dataValue) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return `Price per ${variant?.unitOfMeasure}`;
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return `Original Price`;
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return `Sale Original Price`;
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return `Original and Sale Price`;
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return `Taxes In Price`;
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return `Taxes In (Rounded) Price`;
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return `Pre Tax Price`;
    }
    if (variant?.hasLocationOrCompanySecondaryPricing()) {
      if (variant?.isLocationPrice(menu?.locationId, menu?.companyId, priceFormat)) {
        return `Location Secondary Price`;
      } else {
        return `Company Secondary Price`;
      }
    } else {
      return 'Secondary Price';
    }
  }

  static getSectionSortCannabinoidsInfo(sortOption: SectionSortSecondaryCannabinoids): string {
    return sortOption.split('_')?.firstOrNull();
  }

  static getSectionSortTerpenesInfo(sortOption: SectionSortTerpenes): string {
    const splitResult = sortOption.split('_');
    const productInfo = splitResult.length < 3 ? splitResult[0] : `${splitResult[0]} ${splitResult[1]}`;
    return StringUtils.toTitleCase(productInfo);
  }

  static getVariantPreviewSortByValue(
    sortPosition: SortOrderPosition,
    getCannabinoidText$: (cannabinoid: string) => Observable<string>,
    getTerpeneText$: (terpene: string) => Observable<string>,
    getTopTerpeneText$: () => Observable<string>,
    getTotalTerpenesText$: () => Observable<string>,
    [section, variant]: [Section, Variant],
    [quantityValue, typeText, regularPrice, secondaryPrice]: [string, string, any, string],
  ): Observable<string> {
    const sortOption = SortUtils.getSectionSortOptionFromPosition(sortPosition, section);
    const getProductInfoValue = (): Observable<string> => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
        case SectionSortProductInfo.BrandDesc:
          return of(variant?.brand);
        case SectionSortProductInfo.CBDAsc:
        case SectionSortProductInfo.CBDDesc:
          return getCannabinoidText$('CBD');
        case SectionSortProductInfo.ClassificationAsc:
        case SectionSortProductInfo.ClassificationDesc:
          return of(variant?.getFormattedClassification());
        case SectionSortProductInfo.ManufacturerAsc:
        case SectionSortProductInfo.ManufacturerDesc:
          return of(variant?.manufacturer);
        case SectionSortProductInfo.PackagedQuantityAsc:
        case SectionSortProductInfo.PackagedQuantityDesc:
          return of(variant?.packagedQuantity.toString());
        case SectionSortProductInfo.PriceAsc:
        case SectionSortProductInfo.PriceDesc:
          return of(regularPrice);
        case SectionSortProductInfo.ProductTypeAsc:
        case SectionSortProductInfo.ProductTypeDesc:
          return of(typeText);
        case SectionSortProductInfo.SecondaryPriceAsc:
        case SectionSortProductInfo.SecondaryPriceDesc:
          return of(secondaryPrice);
        case SectionSortProductInfo.StockAsc:
        case SectionSortProductInfo.StockDesc:
          return of(quantityValue);
        case SectionSortProductInfo.TitleAsc:
        case SectionSortProductInfo.TitleDesc:
          return of(variant?.getVariantTitle());
        case SectionSortProductInfo.THCAsc:
        case SectionSortProductInfo.THCDesc:
          return getCannabinoidText$('THC');
        case SectionSortProductInfo.TopTerpeneAsc:
        case SectionSortProductInfo.TopTerpeneDesc:
          return getTopTerpeneText$();
        case SectionSortProductInfo.TotalTerpenesAsc:
        case SectionSortProductInfo.TotalTerpenesDesc:
          return getTotalTerpenesText$();
        case SectionSortProductInfo.UnitSizeAsc:
        case SectionSortProductInfo.UnitSizeDesc:
          return of(variant?.getFormattedUnitSize(false));
        case SectionSortProductInfo.VariantTypeAsc:
        case SectionSortProductInfo.VariantTypeDesc:
          return of(variant?.variantType);
        default:
          return of(null);
      }
    };
    const getSecondaryCannabinoidValue = (): Observable<string> => {
      return getCannabinoidText$(sortOption.split('_')?.firstOrNull());
    };
    const getTerpenesValue = (): Observable<string> => {
      const camelizeTerpene = sortOption
        .replace(/_(ASC|DESC)$/, '')
        .split('_')
        .map((it, index) => index === 0 ? it.toLowerCase() : StringUtils.sentenceCase(it))
        .join('');
      return getTerpeneText$(camelizeTerpene);
    };
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return getProductInfoValue();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoidValue();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpenesValue();
      default:
        return of(null);
    }
  }

  static decodeSectionSortingIntoReadableString(section: Section): Observable<string|null> {
    return window.types.sectionSortOptions$.pipe(
      map((sortOptions) => {
        const primarySort = sortOptions?.find(option => option?.value === section?.sorting);
        const secondarySort = sortOptions?.find(option => option?.value === section?.secondarySorting);
        switch (true) {
          case !secondarySort && !primarySort: // binary - 00
            return null;
          case !secondarySort && !!primarySort: // binary - 01
          case !!secondarySort && !primarySort: // binary - 10
            return primarySort?.nameWithoutDashes() || secondarySort?.nameWithoutDashes();
          case !!secondarySort && !!primarySort: // binary - 11
            return `${primarySort?.nameWithoutDashes()} then by ${secondarySort?.nameWithoutDashes()}`;
        }
      })
    );
  }

  static getSectionSortOptionFromPosition(sortPosition: SortOrderPosition, section: Section): SectionSortOption {
    switch (sortPosition) {
      case SortOrderPosition.Primary:
        return section?.sorting;
      case SortOrderPosition.Secondary:
        return section?.secondarySorting;
      case SortOrderPosition.Tertiary:
        return SortUtils.DEFAULT_SECTION_TERTIARY_SORT;
    }
  }

  /* *******************************************************************************************
   *                                  Miscellaneous Sorts                                      *
   * *******************************************************************************************/

  static nameAscending = (a: { name?: string }, b: { name?: string }): Move => {
    return SortUtils.getMovement(a?.name?.localeCompare(b?.name));
  };

  static nameDescending = (a: { name?: string }, b: { name?: string }): Move => {
    return SortUtils.getMovement(b?.name?.localeCompare(a?.name));
  };

  static menuAssets = (a: OrderableMenuAsset, b: OrderableMenuAsset): Move => {
    return SortUtils.getMovement(a?.priority - b?.priority);
  };

  static byActive = (a: { displayableItemIsActive(): boolean }, b: { displayableItemIsActive(): boolean }): Move => {
    if (a?.displayableItemIsActive() && !b?.displayableItemIsActive()) return Move.ALeft;
    if (b?.displayableItemIsActive() && !a?.displayableItemIsActive()) return Move.BLeft;
    return Move.Nothing;
  };
  static menusByNameAsc = (a: { name?: string }, b: { name?: string }): Move => a?.name?.localeCompare(b?.name);
  static byActiveThenByName = (
    a: { displayableItemIsActive(): boolean; name?: string },
    b: { displayableItemIsActive(): boolean; name?: string }
  ): Move => {
    return SortUtils.byActive(a, b) || SortUtils.menusByNameAsc(a, b);
  };

  static menuSectionsByPriorityAsc = (a: Section, b: Section): Move => {
    return SortUtils.numberAscending(a?.priority, b?.priority);
  };

  static menuSectionsByDateCreatedDesc = (a: Section, b: Section): Move => {
    return SortUtils.numberDescending(a?.dateCreated, b?.dateCreated);
  };

  static sortMenusByDisplayPriority = (a: Menu, b: Menu): Move => {
    return SortUtils.numberAscending(a?.displayPriority, b?.displayPriority);
  };

  static sortDisplaysByPriority = (a: Display, b: Display): Move => SortUtils.getMovement(a?.priority - b?.priority);

  static sortBadges = (a: VariantBadge, b: VariantBadge): Move => {
    return a.id.localeCompare(b.id);
  };

  static sortBadgesByNameAscending = (a: VariantBadge, b: VariantBadge): Move => {
    return SortUtils.nullsLast(a?.name, b?.name) || a?.name?.localeCompare(b?.name);
  };

  static sortBadgesByNameDescending = (a: VariantBadge, b: VariantBadge): Move => {
    return b?.name?.localeCompare(a?.name ?? '') ?? Move.BRight;
  };

  static sortCuratedBadgeSections = (a: CuratedVariantBadgeSection, b: CuratedVariantBadgeSection): Move => {
    return a?.title?.localeCompare(b?.title);
  };

  static sortTheirsAndCuratedBadgeSections = (a: CuratedVariantBadgeSection, b: CuratedVariantBadgeSection): Move => {
    const aIsTheirs = a?.title === 'Your Badges';
    const bIsTheirs = b?.title === 'Your Badges';
    if (aIsTheirs && !bIsTheirs) return Move.ALeft;
    if (bIsTheirs && !aIsTheirs) return Move.BLeft;
    return a?.title?.localeCompare(b?.title);
  };

  static sortSelectableSmartFilterByName = (a: SelectableSmartFilter, b: SelectableSmartFilter): Move => {
    return a?.getSelectionName()?.localeCompare(b?.getSelectionName());
  };

  static sortHydratedSmartFiltersByName = (a: HydratedSmartFilter, b: HydratedSmartFilter): Move => {
    return SortUtils.nonNullStringAscending(a?.name, b?.name);
  };

  static sortSmartFilterGroupingsByPriority = (a: SmartFilterGrouping, b: SmartFilterGrouping): Move => {
    return a?.getPriority() < b?.getPriority() ? Move.ALeft : Move.BLeft;
  };

  static nullsLast(a: any, b: any): Move {
    // nulls sort after anything else
    const aIsNull = a === null || a === undefined || a === '' || a === '--' || a <= 0;
    const bIsNull = b === null || b === undefined || b === '' || b === '--' || b <= 0;
    if (aIsNull && !bIsNull) return Move.ARight;
    if (bIsNull && !aIsNull) return Move.BRight;
    return Move.Nothing;
  }

  static compareNumerically = (a: string, b: string): Move => {
    return a?.trim()?.localeCompare('' + b?.trim(), 'en', { numeric: true });
  };

  static numericStringAsc = (a: string, b: string): Move => {
    return SortUtils.nullsLast(a, b) || SortUtils.compareNumerically(a, b);
  };

  static numberAscending = (a: number, b: number): Move => SortUtils.getMovement(a - b);
  static numberDescending = (a: number, b: number): Move => SortUtils.getMovement(b - a);

  static nonNullStringAscending = (a: string, b: string): Move => SortUtils.getNonNullStringMovement(a, b);
  static nonNullStringDescending = (a: string, b: string): Move => SortUtils.getNonNullStringMovement(b, a);

  // Sort nulls last is done on purpose here
  static numericStringDesc = (a: string, b: string): Move => {
    return SortUtils.nullsLast(a, b) || SortUtils.compareNumerically(b, a);
  };
  static numberAscNullsLast = (a: number, b: number): Move => {
    return SortUtils.nullsLast(a, b) || SortUtils.getMovement(a - b);
  };
  static numberDescNullsLast = (a: number, b: number): Move => {
    return SortUtils.nullsLast(a, b) || SortUtils.getMovement(b - a);
  };

  // sort locationPicker locations
  static sortLocationByNameAsc = (a: Location, b: Location): Move => {
    return SortUtils.nonNullStringAscending(a?.name, b?.name);
  };
  static sortActiveLocationToStart = (a: Location, b: Location, activeId: number): Move => {
    if ((a?.id === activeId) && (b?.id !== activeId)) return Move.ALeft;
    if ((b?.id === activeId) && (a?.id !== activeId)) return Move.BLeft;
    return Move.Nothing;
  };
  static sortLocationPicker = (a: Location, b: Location, activeId: number): Move => {
    return SortUtils.sortActiveLocationToStart(a, b, activeId) || SortUtils.sortLocationByNameAsc(a, b);
  };

  // Labels
  static sortSystemLabelsByDefaultPriority = (a: Label, b: Label): Move => {
    const getOrderNumber = (labelSystemKey: MenuLabel) => {
      switch (labelSystemKey) {
        case MenuLabel.Sale:
          return 0;
        case MenuLabel.Featured:
          return 1;
        case MenuLabel.New:
          return 2;
        case MenuLabel.LowStock:
          return 3;
        case MenuLabel.Restocked:
          return 4;
      }
    };
    const aOrderNumber = getOrderNumber(MenuLabel[a.id]);
    const bOrderNumber = getOrderNumber(MenuLabel[b.id]);
    return SortUtils.getMovement(aOrderNumber - bOrderNumber);
  };

  static sortLabelsAlphabetically = (a: Label, b: Label): Move => {
    return a?.text?.localeCompare(b?.text, 'en', { numeric: true });
  };

  static sortLabelsByPriority = (a: Label, b: Label): Move => {
    // Move all labels with priority of -1 to the end of the list
    if ((a?.priority === -1) && (b?.priority !== -1)) return Move.ARight;
    if ((b?.priority === -1) && (a?.priority !== -1)) return Move.BRight;
    // If the labels have the same priority then sort them alphabetically
    if (a?.priority === b?.priority) return SortUtils.compareNumerically(a?.text, b?.text);
    return SortUtils.numberAscending(a?.priority, b?.priority);
  };

  static sortLabelsForLabelPicker = (a: Label, b: Label): Move => {
    // We want the featured label to be at the start of the list, followed by the rest alphabetically
    if ((a?.id === MenuLabel.Featured) && (b?.id !== MenuLabel.Featured)) return Move.ALeft;
    if ((b?.id === MenuLabel.Featured) && (a?.id !== MenuLabel.Featured)) return Move.BLeft;
    return a?.text?.localeCompare(b?.text, 'en', { numeric: true });
  };

  static sortSectionSortTypesByName = (a: SectionSortType, b: SectionSortType): Move => {
    return SortUtils.numericStringAsc(a?.name, b?.name);
  };

  static getSortableProductTitleObject = (product: Product): any => {
    return {
      id: product?.id,
      title: product?.getProductTitle()
    };
  };

  /**
   * Sort products by title on a background thread, then emit the sorted products as a list of ids.
   */
  static productNameSortWorker(products: Map<string, Product>, n: number): Observable<string[]> {
    const productData = [...(products?.values() || [])].map(p => SortUtils.getSortableProductTitleObject(p));
    return new Observable(subscriber => {
      const myWorker = new Worker(
        new URL('./../worker/product-name-sorter.worker', import.meta.url),
        { type: 'module', name: `product-worker-${n}` }
      );
      myWorker.onmessage = result => {
        subscriber.next(result?.data);
        subscriber.complete();
        myWorker.terminate();
      };
      myWorker.postMessage(productData);
    });
  }

  static columnOptionKeySortAsc = (a: SectionColumnConfigKeyType, b: SectionColumnConfigKeyType): number => {
    const priority = (key: SectionColumnConfigKey) => {
      return SectionColumnConfigKeyType.getSortOrder()?.findIndex(k => k === key);
    };
    const aOrderNumber = priority(a?.value);
    const bOrderNumber = priority(b?.value);
    return SortUtils.numberAscNullsLast(aOrderNumber, bOrderNumber);
  };

  static columnOptionKeySortDesc = (a: SectionColumnConfigKeyType, b: SectionColumnConfigKeyType): number => {
    const priority = (key: SectionColumnConfigKey) => {
      return SectionColumnConfigKeyType.getSortOrder()?.findIndex(k => k === key);
    };
    const aOrderNumber = priority(a?.value);
    const bOrderNumber = priority(b?.value);
    return SortUtils.numberDescNullsLast(aOrderNumber, bOrderNumber);
  };

  static sortSyncTypes = (a: SyncType, b: SyncType): Move => {
    const getOrderNumber = (syncType: SyncType) => {
      switch (syncType) {
        case SyncType.FullProductInfo:        return 0;
        case SyncType.Product:                return 1;
        case SyncType.Inventory:              return 2;
        case SyncType.LotInfo:                return 3;
        case SyncType.Pricing:                return 4;
        case SyncType.DisplayNames:           return 5;
        case SyncType.SmartFilters:           return 6;
        case SyncType.SmartDisplayAttributes: return 7;
        case SyncType.Promotions:             return 8;
        case SyncType.Labels:                 return 9;
        case SyncType.Location:               return 10;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return SortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };

  static sortBulkPrintJobsByMostRecent = (a: BulkPrintJob, b: BulkPrintJob): Move => {
    return SortUtils.numberDescending(a?.dateCreated, b?.dateCreated);
  };

  static sortPrintStackThemePreviewImages(
    a: Asset,
    b: Asset,
    previewImageToCardSizeMap: Map<string, DefaultPrintStackSize>
  ): Move {
    const aCardSize = previewImageToCardSizeMap?.get(a?.md5Hash);
    const bCardSize = previewImageToCardSizeMap?.get(b?.md5Hash);
    const getOrderNumber = (size: DefaultPrintStackSize) => {
      switch (size) {
        case DefaultPrintLabelSize.DefaultSize_CustomLabel2x4: return 0;
        case DefaultPrintCardSize.DefaultSize_AddressCard:     return 1;
        case DefaultPrintCardSize.DefaultSize_Custom2x2:       return 2;
        case DefaultPrintCardSize.DefaultSize_BusinessCard:    return 3;
        case DefaultPrintCardSize.DefaultSize_IndexCard:       return 4;
        case DefaultPrintCardSize.DefaultSize_PostCard:        return 5;
        case DefaultPrintCardSize.DefaultSize_Custom5x5:       return 6;
      }
    };
    const aOrderNumber = getOrderNumber(aCardSize);
    const bOrderNumber = getOrderNumber(bCardSize);
    if (aOrderNumber === bOrderNumber) return SortUtils.numberDescending(a?.timestamp, b?.timestamp);
    return SortUtils.numberAscending(aOrderNumber, bOrderNumber);
  }

  static sortSpecifiedStringLast = (value: string) => (a: string, b: string): Move => {
    const aIsSpecified = a === value;
    const bIsSpecified = b === value;
    if (aIsSpecified && !bIsSpecified) return Move.ARight;
    if (bIsSpecified && !aIsSpecified) return Move.BRight;
    return a.localeCompare(b);
  };

  static sortSpecifiedStringKeyLast = (value: string) => (a: KeyValue<string, any>, b: KeyValue<string, any>) => {
    const aKey = a.key;
    const bKey = b.key;
    return SortUtils.sortSpecifiedStringLast(value)(aKey, bKey);
  };

  /**
   * Priority: Private > Custom > Numeric String Asc
   */
  static sortPrintCardSizes(a: string, b: string, privateSizes: string[]): Move {
    const aIsPrivate = privateSizes?.includes(a);
    const bIsPrivate = privateSizes?.includes(b);
    if (aIsPrivate && !bIsPrivate) return Move.ALeft;
    if (bIsPrivate && !aIsPrivate) return Move.BLeft;
    const aIsCustom = a?.includes('Custom');
    const bIsCustom = b?.includes('Custom');
    if (aIsCustom && !bIsCustom) return Move.ALeft;
    if (bIsCustom && !aIsCustom) return Move.BLeft;
    return SortUtils.numericStringAsc(a, b);
  }

  /**
   * Priority: Private > Numeric String Asc
   */
  static sortThemes(a: Theme, b: Theme): Move {
    const aIsPrivate = a?.isPrivate();
    const bIsPrivate = b?.isPrivate();
    if (aIsPrivate && !bIsPrivate) return Move.ALeft;
    if (bIsPrivate && !aIsPrivate) return Move.BLeft;
    return SortUtils.numericStringAsc(a?.name, b?.name);
  }

}
