import { Injectable } from '@angular/core';
import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from '../edit-template-marketing-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingSmartPlaylistMenuViewModel } from './edit-template-marketing-smart-playlist-menu-view-model';
import { EditSmartPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-view-model';

@Injectable()
export class EditTemplateMarketingSmartPlaylistMenuCombinedLoadingOptsViewModel
  extends EditTemplateMarketingMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingSmartPlaylistMenuViewModel: EditTemplateMarketingSmartPlaylistMenuViewModel,
    protected editSmartPlaylistMenuViewModel: EditSmartPlaylistMenuViewModel,
  ) {
    super(editTemplateMarketingSmartPlaylistMenuViewModel, editSmartPlaylistMenuViewModel);
  }

}
