<thead class="header-container">
  <tr>
    <th resizable>
      <div class="header-title" (click)="nameSort.clicked()">Product Grouping</div>
      <lib-reactive-table-asc-desc-sort #nameSort fxFlex [ascSort]="nameAsc" [descSort]="nameDesc">
      </lib-reactive-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="productCount.clicked()">Product Count</div>
      <app-table-asc-desc-sort #productCount fxFlex [ascSort]="productCountAsc" [descSort]="productCountDesc">
      </app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="childVariantCount.clicked()">Child Variant Count</div>
      <app-table-asc-desc-sort
        #childVariantCount
        fxFlex
        [ascSort]="childVariantCountAsc"
        [descSort]="childVariantCountDesc">
      </app-table-asc-desc-sort>
    </th>
    <th resizable class="table-header-details">
      <div class="header-title details-title">Details</div>
    </th>
  </tr>
</thead>
