import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Label } from '../../../models/shared/label';
import { distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LabelTab } from '../../../models/enum/shared/label-tab.enum';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { EditLabelPropertyKey } from '../../../models/enum/shared/edit-label-property-key';

@Injectable()
export class AddNewLabelViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  private _sourceLabelTab = new BehaviorSubject<LabelTab>(null);
  public sourceLabelTab$ = this._sourceLabelTab.asObservable();
  public connectToSourceLabelTab = (t: LabelTab) => this._sourceLabelTab.next(t);

  private _currentlySelectedTabIndex = new BehaviorSubject<number>(0);
  public currentlySelectedTabIndex$ = this._currentlySelectedTabIndex as Observable<number>;
  public connectToCurrentlySelectedTab = (id: number) => this._currentlySelectedTabIndex.next(id);

  public companyLabelsToAddToLocation$ = new BehaviorSubject<Label[]>([]);
  public companySupportsLocationLabels$ = this.companyDomainModel.companySupportsLocationLabels$;
  public colorPalette$ = this.companyDomainModel.colorPalette$;

  public activeLabelStyle$ = combineLatest([
    this.sourceLabelTab$,
    this.companyDomainModel.companyConfiguration$,
    this.locationDomainModel.locationConfig$,
  ]).pipe(
    map(([tab, companyConfig, locationConfig]) => {
      if (tab === LabelTab.COMPANY_TAB) {
        return companyConfig?.labelStyle;
      } else {
        return locationConfig?.labelStyle || companyConfig?.labelStyle;
      }
    })
  );

  private readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  private readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly labelComputationData$ = combineLatest([
    this.locationConfig$,
    this.companyConfig$,
    this.activeLabelStyle$,
  ]);

  // Create a new label
  // Don't bind the form to labelPreview$ or else the form will keep reinitializing
  public initialLabel = new Label('#FFFFFF', '#000000');
  private _labelPreview = new BehaviorSubject<Label>(this.initialLabel);
  public labelPreview$ = this._labelPreview as Observable<Label>;
  private setLabelPreview = (l: Label) => this._labelPreview.next(l);

  public allowApplyToAllLocations$ = combineLatest([
    this.companySupportsLocationLabels$,
    this.sourceLabelTab$,
  ]).pipe(
    map(([supportsLocationLabels, sourceLabelTab]) => {
      return supportsLocationLabels && sourceLabelTab === LabelTab.COMPANY_TAB;
    })
  );

  public allowedToCreateSmartLabel$ = combineLatest([
    this.sourceLabelTab$,
    this.currentlySelectedTabIndex$
  ]).pipe(
    map(([tab, tabIndex]) => (tab === LabelTab.COMPANY_TAB) && (tabIndex === 0)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public stickyZIndex$ = of(2);

  public labelPropertyChanged(value: string | number, key: EditLabelPropertyKey): void {
    this.labelPreview$.deepCopy().pipe(take(1)).subscribe(l => {
      l.propertyChanged(value, key);
      this.setLabelPreview(l);
    });
  }

  public addOrRemoveLabelFromPool(l: Label) {
    this.companyLabelsToAddToLocation$.pipe(take(1)).subscribe(labels => {
      const shallowCopy = labels.shallowCopy();
      const i = shallowCopy.findIndex(ls => ls.id === l.id);
      if (i > -1) {
        shallowCopy.splice(i, 1);
      } else {
        const labelCopy: Label = window?.injector?.Deserialize?.instanceOf(Label, l);
        shallowCopy.push(labelCopy);
      }
      this.companyLabelsToAddToLocation$.next(shallowCopy);
    });
  }

}
