import { Injectable } from '@angular/core';
import { EditMarketingMenuViewModel } from '../../../../viewModels/edit-marketing-menu-view-model';
import { FormInputItem } from '../../../../../../models/shared/stylesheet/form-input-item';
import type { DriveThruProductComponent } from '../../../shared/drive-thru-product/drive-thru-product.component';
import type { DriveThruProductMediaComponent } from '../../../shared/drive-thru-product/drive-thru-product-media/drive-thru-product-media.component';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../../models/shared/display-options';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { Asset } from '../../../../../../models/image/dto/asset';
import { map } from 'rxjs/operators';
import { CardType } from '../../../../../../models/utils/dto/card-type-definition';
import { OrderableMenuAsset } from '../../../../../../models/menu/shared/orderable-menu-asset';

@Injectable()
export class EditDriveThruMenuViewModel extends EditMarketingMenuViewModel {

  // Child Forms
  private _cardType = new BehaviorSubject<CardType|null>(null);
  public cardType$ = merge(this._cardType.notNull(), this.menu$.pipe(map(m => m?.metadata?.cardType)));
  private _cardStartAt = new BehaviorSubject<string|null>(null);
  public cardStartAt$ = merge(this._cardStartAt.notNull(), this.menu$.pipe(map(m => m?.metadata?.cardStartAt)));
  public comboAssetComponents: DriveThruProductMediaComponent[] = [];
  public menuComboItems: Map<string, FormInputItem[]> = new Map<string, FormInputItem[]>();
  public menuComboComponents: DriveThruProductComponent[] = [];

  public override addOptionsToMenu(menu: Menu, orderedMedia: OrderableMenuAsset[]) {
    super.addOptionsToMenu(menu, orderedMedia);
    this.applyComboOptionsToMenu(menu);
  }

  public updateCardType(type: CardType) {
    this._cardType.next(type);
  }

  public updateCardStartAt(startAt: string) {
    this._cardStartAt.next(startAt);
  }

  public override applyTimeIntervalToMenu(menu: Menu, orderedMedia: OrderableMenuAsset[]) {
    const rotationInterval = new Map<string, number>();
    const durations = this.comboAssetComponents.map(it => it.getInterval()).filter(d => d);
    orderedMedia.forEach(m => {
      const key = m.id;
      const val = durations.find(dur => dur[0] === m.id) ? durations.find(dur => dur[0] === m.id)[1] : 0;
      rotationInterval.set(key, val);
    });
    menu.options.rotationInterval = rotationInterval;
  }

  private applyComboOptionsToMenu(menu: Menu) {
    const prices = new Map<string, number>();
    const descs = new Map<string, string>();
    const colorMap = new Map<string, string>();
    const featProdsFormData = this.menuComboComponents.map(it => it.getData());
    featProdsFormData.forEach(([featVarId, featVarFormData]) => {
      const pNotNull = featVarFormData?.price !== null;
      const pNotUndefined = featVarFormData?.price !== undefined;
      const pExists = featVarFormData?.price >= 0;
      if (pNotNull && pNotUndefined && pExists) {
        prices.set(featVarId, featVarFormData.price);
      }
      descs.set(featVarId, featVarFormData.desc);
      if (featVarFormData.color) {
        colorMap.set(featVarId, featVarFormData.color);
      }
    });
    menu.variantFeature.descriptionOverrideMap = descs;
    menu.variantFeature.priceOverrideMap = prices;
    menu.variantFeature.colorMap = colorMap;
  }

  protected override setDefaultOptionsForVariants(menu: Menu, variantIds: string[]): Observable<Menu> {
    // Set the next rotation order values for the new files
    let nextPriority = menu.getNextSectionPriority();
    variantIds.forEach((vId) => {
      menu.options.rotationOrder.set(vId, nextPriority);
      menu.options.rotationInterval.set(vId, DEFAULT_ROTATION_INTERVAL);
      nextPriority++;
    });
    return this.backgroundSaveMenuWithCompletion();
  }

  public removeAssetFromComboMenu(vId: string, f: Asset) {
    combineLatest([this.menu$, this.orderedMedia$]).once(([menu, orderedMedia]) => {
      menu.removeAssetFromFeaturedNameMap(vId);
      this.updateMediaAfterRemoval(menu, orderedMedia, f);
    });
  }

  override destroy() {
    super.destroy();
    this.comboAssetComponents = [];
    this.menuComboItems.clear();
    this.menuComboComponents = [];
  }

}
