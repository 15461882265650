import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { DisplayContentOption } from '../../../../../../models/enum/shared/display-content-option.enum';
import { SelectMenuOrCollectionViewModel } from './select-menu-or-collection-view-model';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { BaseDisplay } from '../../../../../../models/display/dto/base-display';

@Component({
  selector: 'app-select-menu-or-collection',
  templateUrl: './select-menu-or-collection.component.html',
  providers: [SelectMenuOrCollectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMenuOrCollectionComponent extends BaseComponent {

  constructor(public viewModel: SelectMenuOrCollectionViewModel) {
    super();
  }

  @Input() collectionMode: boolean = false;
  @Input() display: BaseDisplay;
  @Input() displayContentOption: DisplayContentOption = DisplayContentOption.Menu;
  @Input() emptyStateText: string;
  @Input() searchPlaceholder: string;
  @Input() itemIdsToAdd: string[];
  @Input() itemsToSearch: Menu[] | TemplateCollection[];
  @Input() title: string;
  @Output() itemClicked = new EventEmitter<string>();

}
