import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { CompactMenu } from '../../../../../models/menu/dto/compact-menu';
import { DisplayMenuSectionViewModel } from './display-menu-section-view-model';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-display-menu-section',
  templateUrl: './display-menu-section.component.html',
  styleUrls: ['./display-menu-section.component.scss'],
  providers: [DisplayMenuSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionComponent extends BaseComponent {

  constructor(
    public viewModel: DisplayMenuSectionViewModel,
    public editDisplayViewModel: EditDisplayViewModel
  ) {
    super();
  }

  @Input() mergeKey: string;
  @Input() menuIndex: number;
  @Input() menuIsPartOfCollection = false;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToMenu') menu: CompactMenu;
  @Input() @ViewModelConnector('connectToNMenusOnDisplay') nMenusOnDisplay: number;
  @Input() @ViewModelConnector('connectToAllDisplaysAreScheduled') allDisplaysAreScheduled: boolean;
  @Input() @ViewModelConnector('connectToContainerId') containerId: string;

}
