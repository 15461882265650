import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-line-divider',
  templateUrl: './text-line-divider.component.html',
  styleUrls: ['./text-line-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLineDividerComponent {

  @Input() textClass: string;
  @Input() barColor: string = '#DADBDE';
  @Input() barGap: string = '1.5rem';
  @Output() openInformationModal = new EventEmitter<boolean>();

  constructor() {
  }

}
