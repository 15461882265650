<div
  *ngIf="!computeLabelInterface?.getShutOffLabelForLabelComponent() && (viewModel?.labelText$ | async) !== ''"
  [ngbTooltip]="viewModel.labelTooltip$ | async"
  [container]="'body'">
  <div
    [style.background-color]="viewModel?.labelBackgroundColor$ | async"
    [style.color]="viewModel?.labelTextColor$ | async"
    [style.text-decoration]="displayLabelInterface?.getStrokeThroughForLabelComponent() ? 'line-through' : null"
    [style.opacity]="viewModel.labelOpacity$ | async"
    [style.zoom]="zoom"
    class="product-label">
    <app-label-locked
      *ngIf="displayLabelInterface?.getLockedForLabelInterface()"
      [heightAndWidth]="'0.85rem'"
      class="me-1">
    </app-label-locked>
    <div class="product-label-text">{{ viewModel?.labelText$ | async }}</div>
    <app-label-added-by-smart-filter-badge
      *ngIf="viewModel.labelAddedBySmartFilter$ | async"
      [style.margin-left.rem]="0.5">
    </app-label-added-by-smart-filter-badge>
    <app-label-remove-button
      *ngIf="viewModel.removable$ | async"
      [style.margin-left.rem]="0.25"
      [width]="'1rem'"
      [height]="'1rem'"
      [xScale]="0.6"
      appLabelThrottleClick
      [throttleInMilliSec]="1000"
      (throttleClick)="clearClicked()">
    </app-label-remove-button>
  </div>
</div>
