import { DisplayableItemPreviewViewModel, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES } from '../displayable-item-preview/displayable-item-preview-view-model';
import { inject, Injectable, Injector } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { DateUtils } from '../../../../../../utils/date-utils';
import { BsError } from '../../../../../../models/shared/bs-error';
import { debounceTime, delay, map, switchMap } from 'rxjs/operators';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { Size } from '../../../../../../models/shared/size';
import { ModalDuplicateMenu } from '../../../../../../modals/modal-duplicate-menu';
import { ModalMenuLiveView } from '../../../../../../modals/modal-menu-live-view';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { PreviewService } from '../../../../../../services/preview-service';
import { MenuDisplayableSubItemService } from '../displayable-item-preview/menu-displayable-sub-item.service';
import { PreviewOf } from '../../../../../../models/enum/shared/preview-of';
import { ModalPrintMenuLiveView } from '../../../../../../modals/modal-print-menu-live-view';
import { StringUtils } from '../../../../../../utils/string-utils';

@Injectable()
export class MenuPreviewViewModel extends DisplayableItemPreviewViewModel {

  constructor(
    protected templateCollectionDomainModel: TemplateCollectionDomainModel,
    protected menuSubItemService: MenuDisplayableSubItemService,
    protected menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngbModal,
      injector
    );
  }

  protected override _displayableItem: BehaviorSubject<Menu>;
  public override displayableItem$: Observable<Menu>;

  public override itemSubItems$ = this.displayableItem$.pipe(
    switchMap(item => this.menuSubItemService.displayableSubItemList(item))
  );

  public useLandscapeAspectRatio$: Observable<boolean> = of(true);
  public usePortraitAspectRatio$: Observable<boolean> = of(false);

  public dropDownMenuSections$ = combineLatest([
    this.displayableItem$,
    this.locationDomainModel.locationId$
  ]).pipe(
    map(([menu, locId]) => this.getMenuDropDownOptions(menu, locId))
  );

  protected getMenuDropDownOptions(menu: Menu, locId: number): DropDownMenuSection[] {
    const dropDownMenuSections = [];
    const sectionItems = [];
    const deletable = !(menu?.isTemplatedMenu() && menu?.template?.requiredLocationIds.contains(locId));
    sectionItems.push(new DropDownMenuItem('Edit', DisplayableAction.Edit));
    if (!menu?.isPrintMenuOrPrintReportMenu()) {
      sectionItems.push(new DropDownMenuItem('Live View', DisplayableAction.LiveView));
    }
    sectionItems.push(new DropDownMenuItem('Duplicate', DisplayableAction.Duplicate));
    if (deletable) {
      const deleteOpts = new Set<string>().add('red-text');
      sectionItems.push(new DropDownMenuItem('Delete Menu', DisplayableAction.Delete, deleteOpts));
    }
    const section = new DropDownMenuSection(null, sectionItems);
    dropDownMenuSections.push(section);
    return dropDownMenuSections;
  }

  public override templateCollectionIndicatorTooltip$ = combineLatest([
    this.templateCollectionDomainModel.templateCollections$,
    this.displayableItem$
  ]).pipe(
    map(([templateCollections, m]) => {
      const displayTemplateCollections = templateCollections?.filter(tc => tc?.templateIds?.includes(m?.templateId));
      return displayTemplateCollections?.map(tc => tc?.name).join(', ');
    })
  );

  public override showTemplateIcon$ = this.displayableItem$.pipe(map(m => m?.isTemplatedMenu()));

  protected listenForPreviewChanges(): void {
    combineLatest([
      this.displayableItem$,
      inject(MenuPreviewService).previews$,
      this.locationId$
    ]).pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: ([menu, menuPreviews, locationId]) => {
        const key = PreviewService.getPreviewKey(menu?.id, locationId, PreviewOf.MenuImage);
        const preview = menuPreviews?.get(key);
        this._itemPreviews.next(!!preview ? [preview] : null);
      }
    });
  }

  protected loadItemPreviews(menu: Menu, locId: number) {
    this.previewLoadingBundle$.once(loadingBundle => {
      const id = menu?.id;
      const key = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.MenuImage);
      const returnLastSaved = !this.menuPreviewService.isPreviewInProgress(key);
      const lm = 'Getting Preview';
      const loadingOpts = loadingBundle?.get(id);
      if (!loadingOpts?.containsRequest(lm) && !this.isRefreshing) {
        this.isRefreshing = true;
        loadingOpts?.addRequest(lm);
        const cacheImageDelay = returnLastSaved ? 500 : 12000;
        this.menuPreviewService.getMenuPreview(menu, returnLastSaved, cacheImageDelay, null, false, true).subscribe({
          next: menuPreview => {
            if (menuPreview) {
              // Add delay to account for saving of new preview
              this.isRefreshing = false;
              loadingOpts?.removeRequest(lm);
              const timestamp = menuPreview.preview.timestamp;
              const refresh = !DateUtils.unixAfterMinutesAgo(timestamp, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES);
              if (refresh) this.refreshItemPreviewInBackground(menu);
            }
          },
          error: (err: BsError) => {
            this.isRefreshing = false;
            loadingOpts?.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  protected refreshItemPreviewInBackground(m: Menu) {
    this.refreshLoadingBundle$.once(refreshBundle => {
      const lm = 'Refreshing Preview';
      const refreshPreviewLoadingOpts = refreshBundle?.get(m?.id);
      if (!refreshPreviewLoadingOpts?.containsRequest(lm) && !this.isRefreshing) {
        this.isRefreshing = true;
        refreshPreviewLoadingOpts?.addRequest(lm);
        const imageDelay = 12000;
        this.menuPreviewService.getMenuPreview(m, false, imageDelay, undefined, false, true).subscribe({
          next: (preview) => {
            this.isRefreshing = false;
            refreshPreviewLoadingOpts?.removeRequest(lm);
          },
          error: (err: BsError) => {
            this.isRefreshing = false;
            refreshPreviewLoadingOpts?.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

  public openEditItem(): void {
    this.displayableItem$.once(m => {
      this.navigationService.navigateToEditMenuOrTemplate(m);
    });
  }

  public openDeleteItemModal() {
    this.displayableItem$.once(m => {
      const opts = new ConfirmationOptions();
      const noun = m?.whatTypeOfMenuIsThis();
      opts.title = `Delete ${StringUtils.capitalizeFirstLetterOfEachWord(noun)}`;
      opts.bodyText = `Are you sure you want to delete the following ${noun}: `
        + `'${m?.name}'? This action cannot be undone.`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmation = (cont) => {
        if (cont) {
          this.deleteItem(m);
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  protected deleteItem(menu: Menu) {
    const lm = 'Deleting Menu';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.menuDomainModel.deleteMenu(menu).pipe(delay(250)).subscribe((_) => {
        // Add small delay so updated menus can propagate to menus-view-model
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Menu deleted successfully.', 'Menu Deleted');
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  openDuplicateItemModal() {
    this.displayableItem$.once(m => {
      ModalDuplicateMenu.open(this.ngbModal, this.injector, m);
    });
  }

  public openLiveViewModal(sizeOverride?: Size) {
    combineLatest([this.locationId$, this.displayableItem$]).once(([locationId, menu]) => {
      switch (true) {
        case menu?.isPrintMenuOrPrintReportMenu():
          ModalPrintMenuLiveView.open(this.ngbModal, this.injector, menu);
          break;
        default:
          ModalMenuLiveView.open(this.ngbModal, this.injector, locationId, menu, sizeOverride);
      }
    });
  }

  public copyItemURL() {
  }

}
