import { SectionColumnConfig } from './section-column-config';
import { Deserializable } from '../../protocols/deserializable';

export class ThemeSectionColumnConfig extends SectionColumnConfig implements Deserializable {

  public minColumnWidth: number = 0;
  public maxColumnWidth: number = 0;

  override onDeserialize() {
    super.onDeserialize();
    this.minColumnWidth = this.minColumnWidth || 0;
    this.maxColumnWidth = this.maxColumnWidth || 0;
  }

}
