import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-go-back-modal-footer',
  templateUrl: './go-back-modal-footer.component.html',
  styleUrls: ['./go-back-modal-footer.component.scss']
})
export class GoBackModalFooterComponent {

  @Input() showPrimaryButton: boolean;
  @Input() showBackButton: boolean;
  @Input() showCancelButton: boolean = true;
  @Input() disablePrimaryButton: boolean;
  @Input() primaryButtonText: string;
  @Output() primaryButtonClicked = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() goBack = new EventEmitter();

}
