import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { LocationNavItem } from '../models/location-nav-item';

@Pipe({
  name: 'isLocationNavItem'
})
export class IsLocationNavItemPipe implements PipeTransform {

  transform(value: NavItem): boolean {
    return value instanceof LocationNavItem;
  }

}
