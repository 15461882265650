import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class PosProductCategoryGroup implements Deserializable, UniquelyIdentifiable {

  public id: string;
  public name: string;
  public categoryIds: string[];

  onDeserialize() {
    this.categoryIds = Array.from(this.categoryIds || []);
  }

  getUniqueIdentifier(): string {
    return `${this.id}
      -${this.name}
      -${this.categoryIds.sort()}`;
  }

}

