import { Injectable } from '@angular/core';
import { ColumnOptionsFormViewModel } from '../column-options-form/column-options-form-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { ColumnOptionName, SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Injectable()
export class ColumnOptionsBrandViewModel extends ColumnOptionsFormViewModel {

  constructor(
    container: ColumnOptionsViewModel
  ) {
    super(container);
  }

  public override columnSectionKey = SectionColumnConfigKey.Brand;
  public override columnSectionName = ColumnOptionName.Brand;
  public override tooltip = 'Auto - only appears when 1 or more variants in section have a brand associated';

}
