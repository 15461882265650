import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { PrintTheme } from '../../../models/enum/dto/theme.enum';

@Pipe({
  name: 'showTitleSectionPageBreak'
})
export class ShowTitleSectionPageBreakPipe implements PipeTransform {

  transform(value: Menu): boolean {
    const isPrintCardMenu = value?.isPrintCardMenu();
    const isPrintReportMenu = value?.isPrintReportMenu();
    const isPrintLabelMenu = value?.isPrintLabelMenu();
    const isDoubleDutch = value?.theme === PrintTheme.DoubleDutch;
    return !isPrintCardMenu && !isPrintReportMenu && !isDoubleDutch && !isPrintLabelMenu;
  }

}
