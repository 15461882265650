// noinspection JSUnusedLocalSymbols

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SmartFilterRowViewModel } from '../smart-filter-row-view-model';
import { SelectableSmartFilter } from '../../../../../models/automation/protocols/selectable-smart-filter';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../../../../../models/base/base-component';
import { HydratedSmartFilter } from '../../../../../models/automation/hydrated-smart-filter';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-smart-filter-category-card',
  templateUrl: './smart-filter-category-card.component.html',
  styleUrls: ['./smart-filter-category-card.component.scss']
})
export class SmartFilterCategoryCardComponent extends BaseComponent {

  @ViewChild('card') card: ElementRef;
  @Input() smartFilterGrouping: SelectableSmartFilter;
  @Output() cardClicked = new EventEmitter<HydratedSmartFilter[]>();

  numberOfSelectedFilters: number = null;

  isSelected$ = new BehaviorSubject<boolean>(false);

  private listenToAppliedMech = this.viewModel.appliedSmartFilters$
    .notNull()
    .pipe(delay(50))
    .subscribeWhileAlive({
      owner: this,
      next: asf => {
        const selectionName = this.smartFilterGrouping?.getSelectionName();
        const allProductsSelected = selectionName === this.ClientTypes.SmartFilterCategoryAllProducts.AllProducts;
        if (allProductsSelected) {
          this.isSelected$.next(true);
          if (asf.length !== 0) {
            this.isSelected$.next(false);
          }
        } else {
          const childFilterIds = this.smartFilterGrouping?.getGroupedFilters()?.map(sf => sf.id);
          const appliedIds = asf.filter(sf => childFilterIds.indexOf(sf.id) !== -1);
          if (appliedIds.map(sf => sf.id).equals(childFilterIds)) {
            this.isSelected$.next(true);
          } else {
            this.isSelected$.next(false);
          }
        }
      }
    });

  constructor(
    public viewModel: SmartFilterRowViewModel // global, provided by root
  ) {
    super();
  }

  emitCardClicked() {
    this.cardClicked.emit(this.smartFilterGrouping.getGroupedFilters());
  }

}
