import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtils } from '../../../../utils/date-utils';

@Component({
  selector: 'app-smart-filter-small-sync-button',
  templateUrl: './smart-filter-small-sync-button.component.html',
  styleUrls: ['./smart-filter-small-sync-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterSmallSyncButtonComponent implements OnInit, OnChanges {

  @Input() timestamp: number;
  @Input() syncing: boolean;
  @Input() formPristine: boolean = true;
  private _timestamp = new BehaviorSubject<number>(null);
  public time$ = this._timestamp.notNull().pipe(
    map(t => {
      return DateUtils.formatUnixToLastSyncTime(t);
    })
  );

  constructor() {
  }

  ngOnInit() {
    this._timestamp.next(this.timestamp);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timestamp) this._timestamp.next(this.timestamp);
  }

}
