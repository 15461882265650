<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)"
  (disableSubmit$)="disableSubmit.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'title'"
      [label]="'Section Title'"
      [placeholder]="'Enter a title for this section.'"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'title'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      [inputName]="'sectionType'"
      [label]="'Section Type'"
      [placeholder]="'Select the type of section to create'"
      [disabled]="true"
      [required]="true"
      [disabledTooltip]="''"
      [dropdowns]="viewModel.sectionTypes$ | async"
      [bindingProperty]="'sectionType'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      [inputName]="'objectFit'"
      [label]="'Object Fit'"
      [placeholder]="'Select Object Fit'"
      [disabled]="isTemplatedSection"
      [required]="true"
      [tooltip]="'Determines if the object should cover or fit within the container.'"
      [dropdowns]="viewModel.objectFitTypes$ | async"
      [bindingProperty]="'metadata.objectFit'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-switch
      class="product-linked-form-switch"
      [label]="'Hide Product Group'"
      [inputName]="'hideSection'"
      [disabled]="isTemplatedSection"
      [inlineLabel]="true"
      [inlinePercentage]="50"
      [labelOnRight]="true"
      [bindingProperty]="'hideSection'">
    </lib-reactive-form-switch>
  </lib-reactive-form-column-layout>

  <div fxLayout="row">
    <lib-reactive-form-checkbox
      #showZeroStock
      [inputName]="'showOutOfStockProducts'"
      [disabled]="(viewModel.sectionHasSmartFilters$ | async) || isTemplatedSection"
      [selectedValue]="true"
      [notSelectedValue]="false"
      (valueChanged)="viewModel.connectToShowZeroStock($event)"
      [tooltip]="
        'This media will appear on the ' +
        ('menu, ' | replaceMenuWithTemplate : !!(menu | isTemplate)) +
        'regardless of associated products and their inventory level.'
      "
      [bindingProperty]="'showZeroStockItems'">
      Always Show Media
    </lib-reactive-form-checkbox>
  </div>

  <lib-reactive-form-row *ngIf="viewModel.showZeroStockNote$ | async">
    <app-note-text [fontSize]="'0.875rem'" [margin]="'1rem 0 0 0'">
      {{ viewModel.zeroStockNote$ | async }}
    </app-note-text>
  </lib-reactive-form-row>
</lib-reactive-form-group>
