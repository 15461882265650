import { Deserializable } from '../protocols/deserializable';

export class SignedUploadUrl implements Deserializable {

  public url: string;
  public expiry: number;

  onDeserialize() {
  }

}
