import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { DisplayAPI } from '../api/display-api';
import { CacheService } from '../services/cache-service';
import { Location } from '../models/company/dto/location';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { BsError } from '../models/shared/bs-error';
import { Display } from '../models/display/dto/display';
import { ToastService } from '../services/toast-service';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Menu } from '../models/menu/dto/menu';
import { CompanyDomainModel } from './company-domain-model';
import { TemplateCollection } from '../models/template/dto/template-collection';
import { LocationDomainModel } from './location-domain-model';
import { UserDomainModel } from './user-domain-model';
import { exists } from '../functions/exists';

// Provided by Logged In Scope
@Injectable()
export class DisplayDomainModel extends BaseDomainModel {

  constructor(
    private displayAPI: DisplayAPI,
    private cacheService: CacheService,
    private toastService: ToastService,
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
    this.setupBindings();
  }

  private readonly companyId$ = this.companyDomainModel.companyId$;
  private readonly locationId$ = this.locationDomainModel.locationId$;

  private _activeCurrentLocation = new BehaviorSubject<Location | null>(null);
  public activeCurrentLocation$ = this._activeCurrentLocation as Observable<Location | null>;

  private _currentLocationDisplays: BehaviorSubject<Display[]> = new BehaviorSubject<Display[]>(null);
  public currentLocationDisplays$ = this._currentLocationDisplays as Observable<Display[]>;

  private _hydratedDisplays = new BehaviorSubject<Map<string, Display>>(new Map());
  public hydratedDisplays$ = this._hydratedDisplays as Observable<Map<string, Display>>;

  private _allCompanyDisplays = new BehaviorSubject<Display[]>(null);
  public allCompanyDisplays$ = this._allCompanyDisplays as Observable<Display[]>;

  public reloadingDisplays: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  setupBindings() {
    // Bind to session company
    const sessSub = this.locationDomainModel.location$.notNull().pipe(
      withLatestFrom(this.activeCurrentLocation$, this.userDomainModel.validSession$)
    ).subscribe(([currentLocation, activeCurrentLocation, validSession]) => {
      if (validSession && exists(currentLocation) && currentLocation?.id !== activeCurrentLocation?.id) {
        // Refresh current location products
        this._activeCurrentLocation.next(currentLocation);
        this.loadDisplaysForCurrentLocation();
      }
    });
    this.pushSub(sessSub);

    // Bind to currentLocationProducts to update implicit properties
    const locDisplaysSub = this._currentLocationDisplays.notNull().pipe(
      withLatestFrom(this.companyId$, this.locationId$)
    ).subscribe(([displays, companyId, locationId]) => {
      if (companyId && locationId) {
        const cacheKey = Display.buildArrayCacheKey(companyId, locationId);
        this.cacheService.cacheArray<Display>(cacheKey, displays);
      }
    });
    this.pushSub(locDisplaysSub);
  }

  loadDisplaysForCurrentLocation(): void {
    this.reloadingDisplays.next(true);
    this.activeCurrentLocation$.pipe(
      take(1),
      switchMap((activeCurrentLocation: Location) => {
        return this.displayAPI.GetLocationDisplays(activeCurrentLocation?.id);
      })
    ).subscribe({
      next: (displays) => {
        this._currentLocationDisplays.next(displays);
        this.reloadingDisplays.next(false);
      },
      error: (error: BsError) => {
        this.reloadingDisplays.next(false);
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });
  }

  private addNewDisplayToPool = (newDisplay: Display): void => {
    // Add display to
    this.currentLocationDisplays$.pipe(take(1)).subscribe(locDisplays => {
      locDisplays.push(newDisplay);
      this._currentLocationDisplays.next(locDisplays);
    });
  };

  createDisplay(d: Display): Observable<Display> {
    return this.displayAPI.WriteDisplay(d).pipe(
      tap(display => this.addNewDisplayToPool(display))
    );
  }

  public updateDisplayPriorities(displays: Display[]): Observable<Display[]> {
    // API only returns the DTO, so all hydration will be lost. We only want to swap the priorities on domainModel
    // displays, instead of swapping out entire objects
    return this.displayAPI.UpdateDisplayPriorities(displays).pipe(
      tap((updatedDisplays) => this.swapOutPrioritiesOnDisplays(updatedDisplays))
    );
  }

  public getDisplay(displayId: string, lastModified?: number): Observable<Display> {
    // We always want to ignore last session when getting the display from the dashboard app. This value should only be
    // updated when getting from the display app (non-live view) or when updating the display from dashboard app
    return this.displayAPI.GetDisplay(displayId, true, lastModified).pipe(
      tap(display => this.replaceHydratedDisplay(display))
    );
  }

  deleteDisplay(display: Display): Observable<any> {
    return this.displayAPI.DeleteDisplay(display).pipe(
      withLatestFrom(this.currentLocationDisplays$),
      map(([_, locDisplays]) => {
        // Remove display
        const deleteIndex = locDisplays?.findIndex(d => d.id === display.id);
        if (deleteIndex > -1) {
          locDisplays?.splice(deleteIndex, 1);
        }
        this._currentLocationDisplays.next(locDisplays);
        return null;
      })
    );
  }

  public updateDisplays(displays: Display[]): Observable<Display[]> {
    // update, push update to current location displays
    return this.companyDomainModel.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        const updates = displays.map(display => {
          display.companyId = companyId;
          display.configurationIds = display?.configurations?.map(menu => menu?.id);
          return this.displayAPI.UpdateDisplay(display);
        });
        return forkJoin(updates);
      }),
      tap((updatedDisplays) => this.replaceDisplays(updatedDisplays))
    );
  }

  public removeMenuFromDisplay(menuId: string, display: Display): Observable<Display[]> {
    return this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        display.configurations = display.configurations.filter(m => m.id !== menuId);
        display.companyId = companyId;
        display.configurationIds = display.configurations.map(c => c.id);
        display.options?.removeId(menuId);
        // Perform the update
        return this.updateDisplays([display]);
      })
    );
  }

  public addMenusToDisplay(menuIds: string[], display: Display, currentLocationMenus: Menu[]): Observable<Display[]> {
    // add the menus to the display immediately to update the UI
    return this.companyDomainModel.companyId$.pipe(
      take(1),
      switchMap((companyId) => {
        const newMenus = currentLocationMenus?.filter(m => menuIds.includes(m.id));
        display.configurations = display.configurations.concat(newMenus).sort((a, b) => {
          return display.options.rotationOrder.get(a.id) - display.options.rotationOrder.get(b.id);
        });
        display.companyId = companyId;
        display.configurationIds = display.configurations.map(c => c.id);
        // Perform the update
        return this.updateDisplays([display]);
      })
    );
  }

  public removeCollectionFromDisplay(collectionId: string, display: Display): Observable<Display[]> {
    return this.companyDomainModel.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        display.templateCollections = display.templateCollections.filter(c => c.id !== collectionId);
        display.companyId = companyId;
        display.templateCollectionIds = display.templateCollections.map(c => c.id);
        display.options.removeId(collectionId);
        return this.updateDisplays([display]);
      })
    );
  }

  public addCollectionsToDisplay(collectionIds: string[], display: Display): Observable<Display[]> {
    display.templateCollectionIds = [...(display?.templateCollectionIds || []), ...(collectionIds ||  [])]?.unique();
    return this.updateDisplays([display]);
  }

  private replaceDisplays(displays: Display[], removeItems: boolean = false): void {
    this.currentLocationDisplays$.once(existingDisplays => {
      const updatedDisplays = existingDisplays?.shallowCopy() || [];
      displays.forEach((newDisp) => {
        this.replaceHydratedDisplay(newDisp);
        const replacementIndex = updatedDisplays?.findIndex(old => old?.id === newDisp?.id);
        if (removeItems) {
          if (replacementIndex > -1) updatedDisplays.splice(replacementIndex, 1);
        } else if (replacementIndex > -1) {
          updatedDisplays[replacementIndex] = newDisp;
        } else {
          updatedDisplays.push(newDisp);
        }
      });
      this._currentLocationDisplays.next(updatedDisplays);
    });
  }

  private replaceHydratedDisplay(display: Display): void {
    this.hydratedDisplays$.once(hydratedDisplays => {
      hydratedDisplays?.set(display?.id, display);
      this._hydratedDisplays.next(hydratedDisplays);
    });
  }

  private swapOutPrioritiesOnDisplays(displays: Display[]): void {
    this.currentLocationDisplays$.once(existingDisplays => {
      const updatedDisplays = existingDisplays?.shallowCopy() || [];
      displays.forEach(newDisplay => {
        this.swapOutPrioritiesOnHydratedDisplays(newDisplay);
        const replacementIndex = updatedDisplays?.findIndex(old => old?.id === newDisplay?.id);
        if (replacementIndex > -1) {
          updatedDisplays[replacementIndex].priority = newDisplay?.priority;
        }
      });
      this._currentLocationDisplays.next(updatedDisplays);
    });
  }

  private swapOutPrioritiesOnHydratedDisplays(display: Display): void {
    this.hydratedDisplays$.once(hydratedDisplays => {
      const hydratedDisplay = hydratedDisplays?.get(display?.id);
      if (!!hydratedDisplay) {
        hydratedDisplay.priority = display?.priority;
        hydratedDisplays?.set(display?.id, hydratedDisplay);
        this._hydratedDisplays.next(hydratedDisplays);
      }
    });
  }

  public updateMenusAttachedToDisplay(menus: Menu[], removeItems: boolean = false): void {
    this.currentLocationDisplays$.once(existingDisplays => {
      const updatedDisplays = existingDisplays?.shallowCopy() || [];
      updatedDisplays?.forEach(display => menus?.forEach(menu => display?.updateMenu(menu, removeItems)));
      this._currentLocationDisplays.next(updatedDisplays);
    });
  }

  public updateCollectionsAttachedToDisplay(collections: TemplateCollection[], removeItems: boolean = false): void {
    this.currentLocationDisplays$.once(existingDisplays => {
      const updatedDisplays = existingDisplays?.shallowCopy() || [];
      updatedDisplays?.forEach(display => display?.updateTemplateCollections(collections, removeItems));
      this._currentLocationDisplays.next(updatedDisplays);
    });
  }

  public getCompanyDisplays(): Observable<Display[]> {
    return this.displayAPI.GetCompanyDisplays().pipe(
      tap((displays) => this._allCompanyDisplays.next(displays))
    );
  }

}
