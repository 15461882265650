import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { Observable } from 'rxjs';
import { EditMenuFooterViewModel } from './edit-menu-footer-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-menu-footer',
  templateUrl: './edit-menu-footer.component.html',
  styleUrls: [
    './edit-menu-footer.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditMenuFooterViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuFooterComponent extends BaseComponent {

  constructor(
    public viewModel: EditMenuFooterViewModel
  ) {
    super();
  }

  @Input() menu: Menu;
  @Input() allowLiveView: boolean = true;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() canDeleteTemplateMenu: boolean = true;
  @Input() lastSavedTimeText: string = '';
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() showSaveButton: boolean = true;
  @Input() deleteMenu: () => void;
  @Input() openDuplicateMenuModal: () => void;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  @Input() toggleActiveState: (active: boolean) => void;
  // emit true if you want the form to auto submit in the background
  @Output() submitForms = new EventEmitter<boolean>(true);

}
