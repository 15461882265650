import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { StackType } from '../views/menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

export class SmartPrintUtils {

  static showNewProductsCheckbox$(stackType$: Observable<StackType>) {
    return of(true);
  }

  static showInventoryRestockedCheckbox$(stackType$: Observable<StackType>) {
    return of(true);
  }

  static showPriceChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => !printType?.toLowerCase()?.includes('label'))
    );
  }

  static showProductInfoChangeCheckbox$(stackType$: Observable<StackType>) {
    return of(true);
  }

  static showCannabinoidChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => !printType?.toLowerCase()?.includes('label'))
    );
  }

  static showCustomizationChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => !printType?.toLowerCase()?.includes('label'))
    );
  }

  static showMenuChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => !printType?.toLowerCase()?.includes('label'))
    );
  }

}
