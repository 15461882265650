export {};
declare global {
  export interface Number {
    mod(n: number): number;
  }
}

// The % operator in JavaScript is the remainder operator, not the modulo operator
// if I do -2 % 5, I'd expect 3, but in javascript I get -2
Number.prototype.mod = function(n) {
  return ((this % n) + n) % n;
};

export class NumberUtils {

  private static floatComparisonToleranceValue = 0.000001; // not using epsilon because it is not recommended

  static isEven(i: number): boolean {
    return (i % 2) === 0;
  }

  static isOdd(i: number): boolean {
    return (i % 2) !== 0;
  }

  static secondsToMilli(n: number): number {
    return n * 1000;
  }

  static getIntegerSum(total: number, num: number) {
    return total + Math.round(num);
  }

  static floatNumbersEqual(a: number, b: number) {
    return Math.abs(a - b) < this.floatComparisonToleranceValue;
  }

  static floatFirstGreaterThanSecond(a: number, b: number) {
    return a - b - this.floatComparisonToleranceValue > this.floatComparisonToleranceValue;
  }

  /** returns reduced version of input numerator and denominator: [reducedNumerator, reducedDenominator] */
  static reduceFraction(numerator, denominator) {
    const gcd = function greatestCommonDivisor(n, d): number {
      return d ? greatestCommonDivisor(d, n % d) : n;
    };
    const newDenominator = gcd(numerator, denominator);
    const reducedNumerator = numerator / newDenominator;
    const reducedDenominator = denominator / newDenominator;
    return [reducedNumerator, reducedDenominator];
  }

  static parseFloatOrNull(s: string) {
    const p = parseFloat(s);
    return isNaN(p) ? null : p;
  }

  static ifZeroReturnNull(n: number): number {
    if (n === 0) {
      return null;
    } else {
      return n;
    }
  }

  static getDecimalSigFigs(n: number): number {
    if (Math.floor(n) === n) return 0;
    return n?.toString()?.split('.')?.[1]?.length || 0;
  }

  static formatToSigFigDecimals(n: number): string {
    const decSigFigs = NumberUtils.getDecimalSigFigs(n);
    return n.toFixed(decSigFigs);
  }

  static unique(list: number[]): number[] {
    return list?.filter((item, index) => list.indexOf(item) === index) || [];
  }

  static roundToTwoDecimalPlaces(n: number): number {
    return Math.round((n + Number.EPSILON) * 100) / 100;
  }

  static toDollarAmount(n: number): string | null {
    if (!Number.isFinite(n)) return null;
    return Number.isInteger(n) ? n.toFixed(0) : n.toFixed(2);
  }

  static keepUpToNDecimalPlaces(n: number, value: number): string | null {
    if (!Number.isFinite(value)) return null;
    for (let i = 0; i <= n; i++) {
      if (value * Math.pow(10, i) % 1 === 0) {
        return value.toFixed(i);
      }
    }
    return value.toFixed(n);
  }

}
