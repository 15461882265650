<button
  appThrottleClick
  class="bs-button neutral-button smart-filter-sync-button"
  [disabled]="disabled"
  [ngClass]="{ syncing: syncing, disabled: disabled }"
  (throttleClick)="buttonClicked.emit()">
  <img
    src="assets/icons/dark/outline/refresh.svg"
    [style.margin-right.rem]="0.25"
    [ngClass]="{ syncing: syncing }"
    alt="Refresh Img" />
  {{ time$ | async }}
</button>
