import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MenuCreationType } from '../../../../../../models/enum/shared/menu-creation-type.enum';
import { map } from 'rxjs/operators';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';

@Component({
  selector: 'app-menu-creation-method',
  templateUrl: './menu-creation-method.component.html',
  styleUrls: ['./menu-creation-method.component.scss'],
})
export class MenuCreationMethodComponent extends BaseComponent {

  constructor(private userDomainModel: UserDomainModel) {
    super();
  }

  @Input() paddingLeft: string = '1.5rem';
  @Input() paddingRight: string = '1.5rem';
  @Input() titleFontSize: string = '0.75rem';
  @Input() subtitleFontSize: string = '0.75rem';
  @Output() selectedCreationType = new EventEmitter<MenuCreationType>();

  public creationTypes$ = this.userDomainModel.canUseTemplates$.pipe(
    map(canUseTemplates => {
      const types = [];
      const fromTemplate = {
        type: MenuCreationType.FromTemplate,
        title: 'Import from menu template',
        subtitle: 'Use a company menu template'
      };
      const newMenu = {
        type: MenuCreationType.New,
        title: 'Create new menu',
        subtitle: 'Start from scratch to create a new product menu'
      };
      if (canUseTemplates) types.push(fromTemplate);
      types.push(newMenu);
      return types;
    })
  );

}
