import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { EditEmbeddedUrlViewModel } from './edit-embedded-url-view-model';
import { EditUrlPlaylistMenuViewModel } from '../edit-url-playlist-menu-view-model.service';

@Component({
  selector: 'app-edit-embedded-url',
  templateUrl: './edit-embedded-url.component.html',
  styleUrls: ['./edit-embedded-url.component.scss'],
  providers: [EditEmbeddedUrlViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditEmbeddedUrlComponent extends BaseViewModel {

  constructor(public viewModel: EditEmbeddedUrlViewModel) {
    super();
  }

  @Input() mergeKey: string;
  @Input() bindTo: Menu;
  @Input() sharedViewModel: EditUrlPlaylistMenuViewModel;

}
