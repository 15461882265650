import { SectionTemplate } from '../../template/dto/section-template';
import { Deserializable } from '../../protocols/deserializable';

export class DuplicateTemplateSectionRequest implements Deserializable {

  constructor(s: SectionTemplate, addCopyToName: boolean = false) {
    this.baseTemplateId = s?.configurationId;
    this.templateSectionId = s?.id;
    this.targetTemplateId = s?.configurationId;
    this.name = s.title + (addCopyToName ? ' (copy)' : '');
  }

  public baseTemplateId: string;
  public targetTemplateId: string;
  public templateSectionId: string;
  public name: string;
  onDeserialize = () => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-template-service/blob/dev/models/DuplicateTemplateSectionRequest.go
  onSerialize() {
    const dto = Object.create(DuplicateTemplateSectionRequest.prototype);
    dto.baseTemplateId = this.baseTemplateId;
    dto.targetTemplateId = this.targetTemplateId;
    dto.templateSectionId = this.templateSectionId;
    dto.name = this.name;
    return dto;
  }

}
