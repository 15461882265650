<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <div [fxLayout]="inlineLabel ? 'row' : 'column'">
    <div [fxFlex]="inlineLabel ? this.inlinePercentage : null" [style.direction]="'ltr'" fxLayout="row">
      <lib-reactive-form-label
        fxFlex
        [inputId]="id"
        [label]="label"
        [inlineLabel]="inlineLabel"
        [hideLabel]="hideLabel"
        [inputName]="inputName"
        [inputEnabled]="!disabled"
        [required]="required">
      </lib-reactive-form-label>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="center ">
      <input
        hidden
        #input
        (ngModelChange)="handleInputChange($event)"
        [formControlName]="inputName"
        [id]="id"
        [name]="inputName" />

      <app-card-type-selection
        [style.display]="'block'"
        [selectedType]="this.getMyValue()"
        [menuType]="menuType"
        [disabled]="isTemplatedMenu"
        (selected)="cardChanged($event); itemClicked()">
      </app-card-type-selection>

      <lib-reactive-form-general-error
        [parentFormGroupComponent]="reactiveFormGroupComponent"
        [reactiveFormItemComponent]="this"
        [disabled]="disabled">
      </lib-reactive-form-general-error>
    </div>
  </div>
</div>
