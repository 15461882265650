<hr *ngIf="includeTopDivider" class="no-margin" />
<div class="variant-badge-container" fxLayout="row" fxLayoutAlign=" stretch" [ngClass]="{ opaque: !editable }">
  <div class="variant-badge-img">
    <app-asset
      [asset]="badge?.image"
      [scaleFit]="true"
      [cachePolicy]="ClientTypes.CachePolicy.Service"
      [borderRadius]="'0.625rem'">
    </app-asset>
  </div>
  <div class="variant-badge-text" fxFlex fxLayout="column" fxLayoutAlign="center stretch">
    <div class="variant-badge-name">{{ badge?.name }}</div>
    <div class="variant-badge-info">{{ badgeInfo }}</div>
  </div>
  <div
    *ngIf="editable && !showPlusSign"
    class="text-link destructive-link variant-badge-remove"
    (click)="removeClicked.emit(true)">
    Remove
  </div>
  <img
    *ngIf="showPlusSign"
    class="variant-badge-plus"
    [src]="(viewModel.added$ | async) ? 'assets/icons/dark/outline/check.svg' : 'assets/icons/dark/outline/plus.svg'"
    alt="" />
</div>
<hr *ngIf="includeBottomDivider" class="no-margin" />
