<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip">
  <lib-reactive-form-row>
    <lib-reactive-form-number
      [inputName]="'totalNumberOfCards'"
      [label]="'Number of Cards to Display'"
      [placeholder]="'Number of Cards'"
      [inlineLabel]="false"
      [required]="true"
      [minValue]="1"
      [maxValue]="theme?.themeFeatures?.sectionProductMaxCount || -1"
      [disabled]="isTemplatedMenu"
      [integersOnly]="true"
      [customValueParser]="parseNumberAsString"
      [bindingProperty]="'metadata.cardCount'">
    </lib-reactive-form-number>
    <lib-reactive-form-number
      [inputName]="'cardStartAt'"
      [label]="'Start At'"
      [placeholder]="'Start Card Count At'"
      [inlineLabel]="false"
      [required]="true"
      [minValue]="1"
      [maxValue]="99"
      [disabled]="isTemplatedMenu"
      [integersOnly]="true"
      [customValueParser]="parseNumberAsString"
      [bindingProperty]="'metadata.cardStartAt'"
      (valueChanged)="cardStartAtChanged.emit($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-row>

  <lib-reactive-form-row>
    <lib-reactive-form-number
      [inputName]="'totalComboPrice'"
      [label]="'Total Combo Price'"
      [placeholder]="'Enter a price.'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="isTemplatedMenu"
      [integersOnly]="false"
      [customValueParser]="parseDecimalAsString"
      [bindingProperty]="'metadata.price'">
    </lib-reactive-form-number>
  </lib-reactive-form-row>

  <app-reactive-form-card-type
    [inputName]="'cardType'"
    [menuType]="theme?.menuSubType"
    [isTemplatedMenu]="isTemplatedMenu"
    (valueChanged)="cardTypeChanged.emit($event)"
    [bindingProperty]="'metadata.cardType'">
  </app-reactive-form-card-type>
</lib-reactive-form-group>
