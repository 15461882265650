import { Deserializable } from '../../protocols/deserializable';
import { AssetSize } from '../../enum/dto/asset-size.enum';
import { ReplaySubject, timer } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { DateUtils } from '../../../utils/date-utils';
import { MediaType } from '../../enum/dto/media-type.enum';
import { delayWhen, map, retryWhen, take } from 'rxjs/operators';

/**
 * When videos are uploaded they must be processed, resized, and converted to webm format.
 * For large videos this can take a while. The API will return the pre-signed get url for the asset, but the asset may
 * not be available yet. We want to logarithmically retry 10 times with a gradually increasing delay so the client
 * eventually gets the uploaded asset back.
 */
const VIDEO_LOAD_RETRY_COUNT = 10;

export class AssetUrl implements Deserializable {

  public size: AssetSize;
  public url: string;

  // not from API
  public name: string;
  public assetId: string;
  public md5Hash: string;
  public mediaType: MediaType;
  public srcUrl: ReplaySubject<[AssetSize, string | SafeResourceUrl]>;
  public loading: ReplaySubject<boolean>;
  public timeUrlArrivedFromApi: number = -1;
  private alreadyDownloading: boolean = false;
  public retryOverride: number;

  // Cache
  public urlAccessDate: number;

  public buildCacheKey(): string {
    return `Image-${this.assetId}-${this.size}-${this.md5Hash}`;
  }

  public onDeserialize() {
    this.srcUrl = new ReplaySubject<[AssetSize, string | SafeResourceUrl]>(1);
    this.loading = new ReplaySubject<boolean>(1);
    this.alreadyDownloading = false;
    const notSet = this.timeUrlArrivedFromApi < 0
      || this.timeUrlArrivedFromApi === undefined
      || this.timeUrlArrivedFromApi === null;
    if (notSet) {
      this.timeUrlArrivedFromApi = DateUtils.currentTimestamp();
    }
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/) !== null;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/) !== null;
  }

  public isPDF(): boolean {
    return this.mediaType.match(/pdf\/*/) !== null;
  }

  public updateDataFrom(updated: AssetUrl): AssetUrl {
    this.size = updated.size;
    this.url = updated.url;
    this.name = updated.name;
    this.assetId = updated.assetId;
    this.md5Hash = updated.md5Hash;
    this.mediaType = updated.mediaType;
    this.timeUrlArrivedFromApi = updated.timeUrlArrivedFromApi;
    return this;
  }

  urlExpired(): boolean {
    const expiresAt = this.timeUrlArrivedFromApi + this.urlExpiresAfterNSeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  urlExpiresAfterNSeconds(): number {
    return DateUtils.unixOneMinute() * 5;
  }

  forceUrlToExpire() {
    this.timeUrlArrivedFromApi = 0;
  }

  loadAssetIntoSrcUrlSubject(cachePolicy: CachePolicy, cacheForNSeconds: number) {
    if (!this.getCachedAsset(cachePolicy, cacheForNSeconds)) {
      const numberOfRetries = this.retryOverride || (this.isVideo() ? VIDEO_LOAD_RETRY_COUNT : 1);
      if (!window?.injector?.duplicateAssetService?.isDownloading(this)) {
        this.downloadAndCacheBlob(cachePolicy, cacheForNSeconds, numberOfRetries);
      } else {
        this.waitForDownload();
      }
    }
  }

  private waitForDownload() {
    window?.injector?.duplicateAssetService.addToDuplicateQueue(this);
  }

  loadAssetIntoSrcUrlSubjectIfCached(cachePolicy: CachePolicy, cacheForNSeconds: number) {
    this.getCachedAsset(cachePolicy, cacheForNSeconds);
  }

  private downloadAndCacheBlob(
    cachePolicy: CachePolicy = CachePolicy.Service,
    cacheForNSeconds: number,
    numberOfRetries: number
  ) {
    let retries = 0;
    let delayInMilliSeconds = 0;
    this.loading.next(true);
    if (this.handleSafariWebmUrl()) return;
    // Retry mechanism for when asset is not available at url yet
    // (ie: presigned url is valid, but image-sync is still processing)
    window?.injector?.imageApi?.GetBlobFromUrl(this)?.pipe(
      retryWhen(errors$ => {
        return errors$.pipe(
          map((err) => {
            this.forceUrlToExpire();
            delayInMilliSeconds = ++retries * 4000;
          }),
          // delay source observable from retrying for delayInMilliSeconds
          delayWhen(() => timer(delayInMilliSeconds)),
          take(numberOfRetries)
        );
      })
    )?.subscribe({
      next: (blob: Blob) => this.cacheBlob(blob, cachePolicy),
      error: () => this.loading.next(false),
      complete: () => this.loading.next(false)
    });
  }

  /**
   * Safari is unable to render base64 encoded WEBM videos.
   * Therefore, bypass caching operation and use the direct URL instead (these urls have expiration dates).
   *
   * @returns true if this is webm content and the browser is desktop safari
   */
  private handleSafariWebmUrl(): boolean {
    const bypass = this.isSafariAndWebm();
    if (bypass) {
      this.srcUrl.next([this.size, this.url]);
      this.loading.next(false);
    }
    return bypass;
  }

  private cacheBlob(blob: Blob, cachePolicy: CachePolicy): void {
    // Cache the blob
    this.url = '';
    if (blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        const key = this.buildCacheKey();
        const cachedBlob = window?.injector?.cache?.cacheBlob(key, base64data, blob, this.mediaType, cachePolicy);
        // Pass the Blob Url to the srcUrl
        if (!!cachedBlob) {
          this.setUrlSubject(cachedBlob.safeUrl);
          window?.injector?.duplicateAssetService?.finishedDownloading(this, cachedBlob.safeUrl);
          this.loading.next(false);
        }
      };
      reader.onerror = () => this.loading.next(false);
      reader.readAsDataURL(blob);
    }
  }

  private getCachedAsset(cachePolicy: CachePolicy, cacheForNSeconds: number): boolean {
    const key = this.buildCacheKey();
    return this.loadCachedBlob(key, this.mediaType, cachePolicy, cacheForNSeconds);
  }

  public loadCachedBlob(
    key: string,
    mediaType: MediaType,
    cachePolicy: CachePolicy,
    cacheForNSeconds: number
  ): boolean {
    const blobSafeUrl = window?.injector?.cache?.getCachedBlob(key, cachePolicy, cacheForNSeconds);
    if (!!blobSafeUrl) {
      this.setUrlSubject(blobSafeUrl);
      return true;
    } else {
      return false;
    }
  }

  public duplicateEquals(assetUrl: AssetUrl) {
    return (this.size === assetUrl.size) && (this.assetId === assetUrl.assetId) && (this.md5Hash === assetUrl.md5Hash);
  }

  public setUrlSubjectFromDuplicate(url: string|SafeResourceUrl) {
    this.setUrlSubject(url);
  }

  private setUrlSubject(url: string|SafeResourceUrl) {
    this.srcUrl.next([this.size, url]);
    this.urlAccessDate = DateUtils.currentTimestamp();
    this.loading.next(false);
  }

  public isSafariAndWebm(): boolean {
    return !!(window as any)?.safari && this.mediaType?.includes('webm');
  }

}
