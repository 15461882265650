import { TypeDefinition } from './type-definition';

export enum PromotionDiscount {
  Dollar = 'Dollar',
  Percentage = 'Percentage',
  FixedPrice = 'FixedPrice'
}

export class PromotionDiscountType extends TypeDefinition {

  override value: PromotionDiscount;

}
