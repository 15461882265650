import { Component } from '@angular/core';
import { NavItemComponent } from '../nav-item.component';
import { CurrentLocationNavItemViewModel } from './current-location-nav-item-view-model';
import { Router } from '@angular/router';
import { AnimatorService } from '../../../../../../services/animator/animator.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-current-location-nav-item',
  templateUrl: './current-location-nav-item.component.html',
  styleUrls: [
    './current-location-nav-item.component.scss',
    '../nav-item.component.scss'
  ],
  providers: [CurrentLocationNavItemViewModel],
})
export class CurrentLocationNavItemComponent extends NavItemComponent {

  constructor(
    public override viewModel: CurrentLocationNavItemViewModel,
    router: Router,
    protected animatorService: AnimatorService,
  ) {
    super(viewModel, router);
  }

  private animatorSub = this.animatorService.animateLocationPicker$
    .pipe(takeUntil(this.onDestroy))
    .subscribe((dur) => this.animate(dur || 500));

  override navItemSelected() {
    this.viewModel.showLocationPickerModal();
  }

}
