import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../../../models/base/base-component';
import { Variant } from '../../../../../../../../../models/product/dto/variant';

@Component({
  selector: 'app-smart-filter-variants-data-table',
  templateUrl: './smart-filter-variants-data-table.component.html',
  styleUrls: ['./smart-filter-variants-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterVariantsDataTableComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() variants: Variant[];
  @Input() nItemsToDisplay: number;
  @Input() resetPageOnNewData: boolean = false;
  @Input() resetPageOnSortingChange: boolean = true;

}
