export class MapUtils {

  static equals(map1, map2: Map<string, any>): boolean {
    if (!map1 || !map2) {
      return (!map1 && !map2);
    }
    let testVal;
    if (map1.size !== map2.size) {
      return false;
    }
    for (const [key, val] of map1) {
      testVal = map2.get(key);
      if (JSON.stringify(testVal) !== JSON.stringify(val) || (testVal === undefined && !map2.has(key))) {
        return false;
      }
    }
    return true;
  }

  /**
   * If the input map has multiple keys with the same value, then
   * the reversed output map will return with an array of values.
   */
  static reverseMapKeysAndValues<K, L>(inputMap: Map<K, L> | null): Map<L, K|K[]> | null {
    if (!inputMap || !(inputMap instanceof Map)) return null;
    const outputMap = new Map<L, K|K[]>();
    for (const [key, value] of inputMap) {
      if (outputMap?.has(value)) {
        const existingValue = outputMap.get(value);
        Array.isArray(existingValue)
          ? existingValue.push(key)
          : outputMap.set(value, [existingValue, key]);
      } else {
        outputMap.set(value, key);
      }
    }
    return outputMap;
  }

}

