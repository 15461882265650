import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableComponent } from './selectable/selectable.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HasChildIds } from '../../../../models/protocols/has-child-ids';

@Component({
  selector: 'app-group-selection',
  templateUrl: './group-selection.component.html',
  styleUrls: ['./group-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSelectionComponent extends SelectableComponent {

  @Input() override selection: HasChildIds;
  @Output() bulkAdd = new EventEmitter<string[]>(true);
  @Output() bulkRemove = new EventEmitter<string[]>(true);

  protected override _selection = new BehaviorSubject<HasChildIds>(null);
  public groupIds$ = this._selection.pipe(map(selection => selection?.getChildIds()));
  public nChildrenSelected$ = combineLatest([this.groupIds$, this.selectedIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, selectedIds, previouslyAddedIds]) => {
      return (groupIds?.filter(id => selectedIds?.contains(id) || previouslyAddedIds?.contains(id)) || [])?.length ?? 0;
    })
  );
  public percentageChecked$ = combineLatest([this.groupIds$, this.selectedIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, selectedIds, previouslyAddedIds]) => {
      const groupSize = groupIds?.length || 1;
      const selected = groupIds?.filter(id => selectedIds?.contains(id) || previouslyAddedIds?.contains(id)) || [];
      const nSelected = selected?.length ?? 0;
      return nSelected / groupSize;
    })
  );
  public override indeterminate$ = this.percentageChecked$.pipe(map(p => p > 0 && p < 1));
  public override selected$ = this.percentageChecked$.pipe(map(p => p === 1));
  public override previouslyAdded$ = combineLatest([this.groupIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, prevAdded]) => groupIds?.map(id => prevAdded?.contains(id))?.every(contained => contained))
  );
  public newlySelectedGroupIds$ = combineLatest([this.groupIds$, this.selectedIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, selected, prevSelected]) => {
      return (groupIds?.filter(id => selected?.contains(id) && !prevSelected?.contains(id)) || []);
    })
  );
  public groupIdsNotPreviouslyAdded$ = combineLatest([this.groupIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, prevAddedIds]) => groupIds?.filter(id => !prevAddedIds?.contains(id)))
  );

  constructor() {
    super();
  }

  public override clicked(selected: boolean) {
    if (selected) {
      this.groupIdsNotPreviouslyAdded$.pipe(take(1)).subscribe(addUs => this.bulkAdd.emit(addUs));
    } else {
      this.newlySelectedGroupIds$.pipe(take(1)).subscribe(removeUs => this.bulkRemove.emit(removeUs));
    }
  }

}
