<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #dateTimeModalForms
  [mergeKey]="viewModel.mergeKey$ | push"
  (canSubmit$)="viewModel.connectToCanSubmit($event)"
  (unsavedChanges$)="viewModel.connectToUnsavedChanges($event)"
  (formsSubmitted$)="viewModel.saveDateTimeInformation()"></lib-reactive-form-merge-groups>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">Edit Specific Date/Time</div>
      <div>{{ viewModel.menuName$ | push }}</div>
    </div>
  </div>

  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="mt-3 date-time-windows-container">
      <div>
        <div class="bs-bold f18px">Date/Time Windows</div>
        <div class="bs-regular f14px">
          Select date and time windows when the menu should appear on the display. By default the menu will always
          appear based on the recurring details.
        </div>
      </div>
      <div class="my-3">
        <ng-container *rxFor="let dateTimeWindow of viewModel.dateTimeWindows$ | push; let i = index">
          <app-date-time-window-form
            [bindTo]="dateTimeWindow"
            [mergeKey]="viewModel.mergeKey$ | push"
            [index]="i"
            (removeDateTimeWindow)="viewModel.removeDateTimeWindow($event)"></app-date-time-window-form>
        </ng-container>
        <lib-button-neutral
          [iconSrc]="'assets/icons/dark/outline/plus.svg'"
          (buttonClicked)="viewModel.addDateTimeWindow()">
          Add Date / Time Window
        </lib-button-neutral>
      </div>
    </div>

    <div class="mt-4">
      <app-recurring-details-form
        [recurringDetails]="viewModel.recurringDetails$ | push"
        [mergeKey]="viewModel.mergeKey$ | push">
      </app-recurring-details-form>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral (buttonClicked)="cancel()" [style.padding-right.rem]="1">Cancel</lib-button-neutral>
    <lib-button-primary [disabled]="!(viewModel.canSave$ | push)" (buttonClicked)="dateTimeModalForms.submitForms()">
      Done
    </lib-button-primary>
  </div>
</div>
