<div class="edit-page-action-buttons">
  <lib-button-neutral
    [disabled]="disableSave || formHasErrors || !formPristine"
    [disabledTooltip]="(formErrorMessages | getFormErrorDisabledTooltip) || (formPristine | saveChangesTooltip)"
    [iconSrc]="'assets/icons/dark/outline/eye.svg'"
    (buttonClicked)="showLiveView.emit()">
    Live View
  </lib-button-neutral>
  <lib-button-primary
    [infoText]="lastSavedTimeText"
    [loadingOptions]="autoSaveLoadingOpts"
    [disabled]="disableSave || formDisableSubmit"
    [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
    (buttonClicked)="submitForms.emit(false)">
    Save Changes
  </lib-button-primary>
</div>
