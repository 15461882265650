import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getCannabinoidWithUnits'
})
export class GetCannabinoidWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null, cannabinoid: string): string | null {
    return variant?.getCannabinoidWithUnits(cannabinoid) || null;
  }

}
