<div class="d-flex flex-column align-items-center">
  <div class="badge-img">
    <app-loading *ngIf="loadingOptions.isLoading" [options]="loadingOptions"></app-loading>
    <app-asset *ngIf="!loadingOptions.isLoading" [asset]="badge?.image" [scaleFit]="true" [borderRadius]="'0.625rem'">
    </app-asset>
    <app-badge-added-by-smart-filter-indicator
      *ngIf="showSmartFilterIndicator"
      [backgroundColor]="'#2C4058'"
      [iconScale]="1.2"
      [height]="'2rem'"
      [width]="'2rem'">
    </app-badge-added-by-smart-filter-indicator>
  </div>
  <div class="badge-text mt-8px">{{ badge.name }}</div>
</div>
