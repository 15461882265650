import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DuplicateMenuViewModel } from '../../../viewModels/duplicate-menu-view-model';
import { ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';
import { Menu } from '../../../../../models/menu/dto/menu';
import { DefaultPrintStackSize } from '../../../../../models/enum/dto/default-print-stack-size';

@Component({
  selector: 'app-duplicate-menu-modal',
  templateUrl: './duplicate-menu-modal.component.html',
  styleUrls: ['./duplicate-menu-modal.component.scss'],
  providers: [DuplicateMenuViewModel],
})
export class DuplicateMenuModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {

  // View References
  @ViewChild('modalBody') modalBody: HTMLDivElement;
  @ViewChild('duplicateMenuFormGroup') duplicateMenuFormGroup: ReactiveFormGroupComponent;

  constructor(
    public viewModel: DuplicateMenuViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override setupBindings() {
    this.viewModel.dismissModalSubject
      .subscribeWhileAlive({
        owner: this,
        next: close => { if (close) this.cancel(); }
      });
  }

  connectToMenu(menu: Menu) {
    this.viewModel.connectToMenu(menu);
    if (menu?.containsStackedContent()) {
      this.viewModel.connectToSelectedCardStackSize(menu?.metadata?.printCardSize);
    }
  }

  connectToNavigateToDuplicatedMenu(navigateToDuplicatedMenu: boolean) {
    this.viewModel.navigateToDuplicatedMenu = navigateToDuplicatedMenu;
  }

  cardStackSizeSelected(cardStackSize: DefaultPrintStackSize) {
    this.viewModel.connectToSelectedCardStackSize(cardStackSize);
  }

  duplicateMenu() {
    this.viewModel.duplicateMenu();
  }

}
