import { Injectable } from '@angular/core';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { MenuPreview } from '../models/menu/shared/menu-preview';
import { delay, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Menu } from '../models/menu/dto/menu';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { DateUtils } from '../utils/date-utils';
import { ImageAPI } from '../api/image-api';
import { CacheService } from './cache-service';
import { PreviewService } from './preview-service';
import { iiif } from '../utils/observable.extensions';
import { ChangesRequiredForPreviewService } from './changes-required-for-preview.service';
import { MenuDomainModel } from '../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../domainModels/template-domain-model';
import { PreviewOf } from '../models/enum/shared/preview-of';

// Provided by Logged In Scope
@Injectable()
export class MenuPreviewService extends PreviewService {

  constructor(
    private cacheService: CacheService,
    private imageAPI: ImageAPI,
    private templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    changesRequiredForPreviewService: ChangesRequiredForPreviewService
  ) {
    super(locationDomainModel, menuDomainModel, changesRequiredForPreviewService);
  }

  static getMenuPreviewKey(menuId: string, locId: number): string {
    return `MenuPreview-${menuId}-${locId}`;
  }

  /* ******************************* Get Menu Preview ******************************* */

  public getMenuPreview = (
    menu: Menu | MenuTemplate,
    returnLastSaved: boolean,
    cacheImageDelay: number,
    skipCacheWrite: boolean = false,
    forceUpdate: boolean = false,
    previewOnly?: boolean
  ): Observable<MenuPreview> => {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.MenuImage);
        const alreadyFetching$ = defer(() => this.addRequestToQueue(key));
        const waitForFetch$ = defer(() => this.waitForFetch(key));
        const goFetch$ = defer(() => {
          return this.fetchPreview(menu, returnLastSaved, cacheImageDelay, skipCacheWrite, forceUpdate, previewOnly);
        });
        this.setLoadingStateFor(key, true);
        return iiif(alreadyFetching$, waitForFetch$, goFetch$);
      })
    );
  };

  private fetchPreview(
    menu: Menu | MenuTemplate,
    returnLastSaved: boolean,
    cacheImageDelay: number,
    skipCacheWrite: boolean = false,
    forceUpdate: boolean = false,
    previewOnly?: boolean
  ): Observable<MenuPreview> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.MenuImage);

        const cachedPreview = this.cacheService.getCachedObject<MenuPreview>(MenuPreview, key);
        if (cachedPreview && returnLastSaved && !forceUpdate) {
          this.storeActivePreview(key, cachedPreview);
          this.setLoadingStateFor(key, false);
          this.removeRequestFromQueue(key);
          return of(cachedPreview);
        }

        const previewReq$ = menu instanceof MenuTemplate
          ? this.imageAPI.GetMenuTemplatePreview(locId, menu, returnLastSaved, forceUpdate, previewOnly)
          : this.imageAPI.GetMenuPreview(locId, menu, returnLastSaved, forceUpdate, previewOnly);
        return previewReq$.pipe(
          delay(cacheImageDelay),
          map(preview => window.injector.Deserialize.instanceOf(MenuPreview, new MenuPreview(menu.id, preview, locId))),
          tap(preview => {
            if (!skipCacheWrite) this.cacheService.cacheObject<MenuPreview>(key, preview);
            this.storeActivePreview(key, preview);
            this.setLoadingStateFor(key, false);
            this.removeRequestFromQueue(key);
          })
        );
      })
    );
  }

  /* ******************************* Active Preview ******************************* */

  private readonly activeHydratedMenuId$ = this.menuDomainModel.activeHydratedMenuId$;
  private readonly activeMenuTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  private readonly activeId$ = combineLatest([
    this.activeMenuTemplateId$,
    this.activeHydratedMenuId$
  ]).pipe(
    map(([templateId, menuId]) => templateId || menuId),
    distinctUntilChanged()
  );

  public readonly isLoadingActiveMenu$ = combineLatest([
    this.activeId$,
    this.locationId$,
    this.loadingPreviewMap$
  ]).pipe(
    map(([activeId, locId, loadingMap]) => loadingMap?.get(activeId)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public activePreview$ = combineLatest([
    this.activeId$,
    this.locationId$,
    this.previews$
  ]).pipe(
    map(([activeId, locId, previews]) => previews?.get(MenuPreviewService.getMenuPreviewKey(activeId, locId))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public activePreviewUrl$ = this.activePreview$.pipe(
    switchMap(activePreview => activePreview?.preview?.getAssetUrl(AssetSize.Original)?.srcUrl ?? of([null, ''])),
    map(([_, url]) => url),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public activePreviewTimeLabel$ = this.activePreview$.pipe(
    map(p => DateUtils.formatUnixToDateTime(p?.preview?.timestamp))
  );

  public removePreviewFromCache(key: string) {
    this.cacheService.removeCachedObject(key);
  }

}
