import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { StackManualBulkPrintJobViewModel } from '../stack-manual-bulk-print-job-view-model';
import { ReactiveSearchBarComponent } from '@mobilefirstdev/reactive-search-and-pagination';
import { pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-stack-manual-bulk-print-job-right-pane',
  templateUrl: './stack-manual-bulk-print-job-right-pane.component.html',
  styleUrls: ['./stack-manual-bulk-print-job-right-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackManualBulkPrintJobRightPaneComponent extends BaseComponent {

  constructor(
    public viewModel: StackManualBulkPrintJobViewModel
  ) {
    super();
  }

  @ViewChild('searchBar') searchBar: ReactiveSearchBarComponent;

  override setupBindings(): void {
    this.viewModel.selectedStackMenuId$
      .pipe(pairwise())
      .subscribeWhileAlive({
        owner: this,
        next: ([oldLocationId, newLocationId]) => {
          const hasOldAndNew = !!oldLocationId && !!newLocationId;
          if (hasOldAndNew && (oldLocationId !== newLocationId)) this.searchBar?.clear();
        }
      });
  }

}
