<div class="menus-container mt-4">
  <div class="bs-bold f16px">Menus</div>
  <div class="sub-text f12px mt-2">{{ viewModel.subtitle$ | push }}</div>
  <lib-reactive-form-group>
    <lib-reactive-form-switch
      *rxIf="!(viewModel.viewOnly$ | push)"
      [disabled]="!(viewModel.inactiveMenuCount$ | push)"
      class="inactive-menu-switch"
      [inputName]="'inactiveMenuSwitch'"
      [label]="viewModel.inactiveToggleLabel$ | push"
      (valueChanged)="viewModel.connectToShowInactiveMenus($event)">
    </lib-reactive-form-switch>
  </lib-reactive-form-group>
  <app-print-job-menu-form
    [type]="'menu'"
    [job]="viewModel.job$ | push"
    [menuChoices]="viewModel.filteredMenus$ | push"
    [menusAsSelectionItem]="viewModel.menusAsSelectionItem$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [selectedMenuIds]="viewModel.selectedMenuIds$ | push"
    [viewOnly]="viewModel.viewOnly$ | push"
    (add)="viewModel.addMenuIdToJob($event)"
    (remove)="viewModel.removeMenuIdFromJob($event)"
    (bulkAdd)="viewModel.bulkAddMenuIdsToJob($event)"
    (bulkRemove)="viewModel.bulkRemoveMenuIdsFromJob($event)">
  </app-print-job-menu-form>
</div>
