import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayTemplateCollectionSectionViewModel } from './display-template-collection-section-view-model';
import { CompactTemplateCollection } from '../../../../../models/template/dto/compact-template-collection';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-display-template-collection-section',
  templateUrl: './display-template-collection-section.component.html',
  styleUrls: ['./display-template-collection-section.component.scss'],
  providers: [DisplayTemplateCollectionSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayTemplateCollectionSectionComponent extends BaseComponent {

  constructor(
    public viewModel: DisplayTemplateCollectionSectionViewModel,
    public editDisplayViewModel: EditDisplayViewModel
  ) {
    super();
  }

  @Input() collection: CompactTemplateCollection;
  @Input() collectionIsRequired: boolean;
  @Input() isLastItem: boolean;

}
