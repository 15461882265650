<lib-reactive-form-group #form [mergeKey]="mergeKey" [bindTo]="bindTo">
  <lib-reactive-form-column-layout [nColumns]="2">
    <div [style.display]="'flex'">
      <lib-reactive-form-drop-down
        #sectionType
        [style.width]="'-webkit-fill-available'"
        [inputName]="'menuTheme'"
        [label]="'Menu Theme'"
        [placeholder]="'Choose menu theme'"
        [inlineLabel]="false"
        [disabled]="true"
        [required]="true"
        [dropdowns]="themes$ | async"
        [bindingProperty]="'theme'">
      </lib-reactive-form-drop-down>

      <button
        (click)="openThemeDetails()"
        [style.height.rem]="2.5"
        [style.margin-top.rem]="1.6"
        [style.width.rem]="8"
        type="button"
        class="bs-button neutral-button pt-0 pb-0 mt-9px">
        Details
      </button>
    </div>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'menuType'"
      [label]="'Menu Type'"
      [placeholder]="'Choose menu type'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [dropdowns]="menuTypes$ | async"
      [bindingProperty]="'type'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #sectionType
      *ngIf="!(menu | isWebMenu)"
      [inputName]="'displaySize'"
      [label]="'Menu Preview Size'"
      [placeholder]="'Select the size of this menu preview'"
      [inlineLabel]="false"
      [disabled]="false"
      [required]="true"
      [tooltip]="
        'A menu will always respect the size of the display it is assigned to. This menu size is only used for generating menu previews.'
      "
      [dropdowns]="displaySizes$ | async"
      [bindingProperty]="'displaySize.name'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #sectionOrientation
      *ngIf="!(menu | isWebMenu)"
      [inputName]="'orientation'"
      [label]="'Orientation'"
      [placeholder]="'Select the orientation of your display'"
      [inlineLabel]="false"
      [disabled]="false"
      [required]="true"
      [tooltip]="'Portrait is a 90º clockwise rotation. Reverse portrait is a 90º counterclockwise rotation.'"
      [dropdowns]="orientations$ | async"
      [bindingProperty]="'displaySize.orientation'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      #height
      *ngIf="menu | isWebMenu"
      [inputName]="'height'"
      [label]="'Web Embed Height'"
      [placeholder]="'Select the height in pixels.'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="10000"
      [disabled]="false"
      [bindingProperty]="'displaySize.height'"
      [tooltip]="'This is the height for the embedded iframe on your website.'"
      (valueChanged)="heightChanged($event)">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      #width
      *ngIf="menu | isWebMenu"
      [inputName]="'width'"
      [label]="'Web Embed Width'"
      [placeholder]="'Select the width in pixels.'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="5000"
      [disabled]="false"
      [bindingProperty]="'displaySize.width'"
      [tooltip]="'This is the width for the embedded iframe on your website.'"
      (valueChanged)="widthChanged($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
