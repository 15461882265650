<div class="edit-page-action-buttons">
  <lib-button-text
    *ngIf="canDeleteTemplateMenu"
    [textColor]="'#FA5555'"
    [disabled]="autoSaving"
    (buttonClicked)="deleteMenu()">
    Delete Menu
  </lib-button-text>
  <lib-button-text
    [disabled]="autoSaving || !formPristine"
    [disabledTooltip]="formPristine | saveChangesTooltip"
    (buttonClicked)="openDuplicateMenuModal()">
    Duplicate Menu</lib-button-text
  >
  <lib-button-neutral
    [iconSrc]="'assets/icons/dark/outline/eye.svg'"
    [disabled]="!allowLiveView || formHasErrors || !formPristine"
    [disabledTooltip]="
      (formErrorMessages | getFormErrorDisabledTooltip) || (!formPristine ? 'Please save changes before viewing.' : '')
    "
    (buttonClicked)="showLiveView(formPristine, viewModel.formAutoSubmitted$, submitForms)">
    Live View
  </lib-button-neutral>
  <lib-button-primary
    *ngIf="showSaveButton"
    [infoText]="lastAutoSavedTimeText"
    [disabled]="autoSaving || formDisableSubmit"
    [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
    [loading]="autoSaving"
    (buttonClicked)="submitForms.emit(false)">
    Save Changes
  </lib-button-primary>
</div>
