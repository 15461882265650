import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { CompanyDomainModel } from './company-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { Device } from '../models/device/dto/device';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DeviceAPI } from '../api/device-api';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from '../services/toast-service';

// Provided by Logged In Scope
@Injectable()
export class DeviceDomainModel extends BaseDomainModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private deviceAPI: DeviceAPI,
    private locationDomainModel: LocationDomainModel,
    private toastService: ToastService
  ) {
    super();
    this.setupBindings();
  }

  private companyId$ = this.companyDomainModel.companyId$;
  private locationId$ = this.locationDomainModel.locationId$;

  public _locationDevices: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>(null);
  public locationDevices$ = this._locationDevices as Observable<Device[]>;
  public activeLocationIdDevices: number;

  private setupBindings() {
    this.locationId$.subscribeWhileAlive({
      owner: this,
      next: (locationId) => {
        if (locationId && locationId !== this.activeLocationIdDevices) {
          this.activeLocationIdDevices = locationId;
          this.loadLocationDevices();
        }
      }
    });
  }

  public loadLocationDevices(): void {
    this.locationId$.pipe(
      take(1),
      switchMap(locationId => this.deviceAPI.GetCompanyDevices(locationId, false)),
      withLatestFrom(this.locationId$),
    ).subscribe({
      next: ([devices, locationId]) => {
        this.activeLocationIdDevices = locationId;
        this._locationDevices.next(devices);
      },
      error: (error: BsError) => {
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });
  }

  public setupDevice(device: Device): Observable<Device> {
    return combineLatest([this.companyId$, this.locationId$]).pipe(
      take(1),
      switchMap(([companyId, locationId]) => {
        device.companyId = companyId;
        device.locationId = locationId;
        return this.deviceAPI.SetupDevice(device);
      }),
      tap(newDevice => this.replaceDevice(newDevice, false))
    );
  }

  private replaceDevice(device: Device, removeItems: boolean = false): void {
    this.locationDevices$.once(locationDevices => {
      const updatedDevices = locationDevices?.shallowCopy() ?? [];
      const oldDevice = updatedDevices.find(d => d.id === device.id);
      const replacementIndex = updatedDevices.indexOf(oldDevice);
      if (removeItems) {
        if (replacementIndex > -1) updatedDevices.splice(replacementIndex, 1);
      } else if (replacementIndex > -1) {
        updatedDevices[replacementIndex] = device;
      } else {
        updatedDevices.push(device);
      }
      this._locationDevices.next(updatedDevices);
    });
  }

  public updateDevice(device: Device): Observable<Device> {
    return this.deviceAPI.UpdateDevice(device).pipe(
      tap(updatedDevice => this.replaceDevice(updatedDevice, false))
    );
  }

  public resetDevice(device: Device): Observable<Device> {
    return this.deviceAPI.ResetDevice(device).pipe(
      tap(resetDevice => this.replaceDevice(resetDevice, true))
    );
  }

}
