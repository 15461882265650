import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { Injectable } from '@angular/core';
import { ChangePasswordRequest } from '../../../models/account/requests/change-password-request';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { BsError } from '../../../models/shared/bs-error';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast-service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ChangePasswordViewModel extends BaseModalViewModel {

  public user$ = this.userDomainModel.user$;
  public userFullName$ = this.user$.pipe(map(user => user?.getFullName()));

  public req: ChangePasswordRequest = new ChangePasswordRequest('');
  protected override _loadingOpts = new BehaviorSubject(this.getLoadingSpinner());
  public unsavedChanges: boolean;

  public override dismissModalSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private userDomainModel: UserDomainModel,
    protected activeModal: NgbActiveModal,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = 'Changing Password';
    opts.isLoading = false;
    return opts;
  }

  formSubmitted(req: ChangePasswordRequest): void {
    const loadingMess = 'Changing Password';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.userDomainModel.authToken$.once(token => {
        req.accessToken = token;
        this.changePassword(req, loadingMess);
      });
    }
  }

  dismissModal() {
    this.dismissModalSubject.next(true);
  }

  changePassword(cpr: ChangePasswordRequest, loadingMess: string): void {
    this.userDomainModel.changePassword(cpr).subscribe({
      next: (_) =>  {
        // Reset for form object
        this.req = new ChangePasswordRequest('');
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishSuccessMessage('Completed', 'Password Successfully Changed.');
        this.dismissModal();
      },
      error: (error: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });
  }

  connectToUnsavedChanges = (unsavedChanges: boolean) => this.unsavedChanges = unsavedChanges;

}
