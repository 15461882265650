import { Previewable } from '../models/protocols/previewable';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { Menu } from '../models/menu/dto/menu';

export class DownloadUtils {

  static downloadPreview(
    sanitizer: DomSanitizer,
    menu: Menu,
    preview: Previewable,
    url: string|SafeResourceUrl
  ) {
    if (!!preview && !!url) {
      const downloadLink = document.createElement('a');
      downloadLink.href = sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
      const fileExtension = preview?.preview?.fileName.split('.')?.last();
      downloadLink.download = `${menu.name}-${preview.preview.timestamp}.${fileExtension ?? 'jpeg'}`;
      if (fileExtension === 'pdf') {
        downloadLink.target = '_blank';
      }
      downloadLink.click();
    }
  }

}
