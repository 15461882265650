import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../image/dto/asset';
import { Selectable } from '../../protocols/selectable';
import { ThemeFeatures } from './theme-features';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { MenuColumnCountConfig } from './menu-column-count-config';
import { ThemeSectionColumnConfig } from './theme-section-column-config';
import { ThemeId } from '../../enum/dto/theme.enum';
import { PrintConfig } from './print-config';
import { SectionColumnConfigKey } from '../../utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../../utils/dto/section-column-config-default-state-type';
import { MenuType } from '../../utils/dto/menu-type-definition';
import { MenuSubtype } from '../../enum/dto/menu-subtype';

export class Theme implements Deserializable, Selectable, Cachable {

  public id: ThemeId;
  public name: string;
  public description: string;
  public portraitPreviewImages: Asset[];
  public landscapePreviewImages: Asset[];
  public supportedMenuTypes: MenuType[];
  public themeFeatures: ThemeFeatures;
  public companyIds: number[];
  public menuSubType: MenuSubtype;
  public menuColumnCountConfig: MenuColumnCountConfig;
  public sectionColumnConfig: Map<SectionColumnConfigKey, ThemeSectionColumnConfig>;
  public printConfig: PrintConfig;

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.portraitPreviewImages = Deserialize?.arrayOf(Asset, this.portraitPreviewImages);
    this.landscapePreviewImages = Deserialize?.arrayOf(Asset, this.landscapePreviewImages);
    this.themeFeatures = Deserialize?.instanceOf(ThemeFeatures, this.themeFeatures);
    this.printConfig = Deserialize?.instanceOf(PrintConfig, this.printConfig);
    const columnCountConfig = this.menuColumnCountConfig;
    this.menuColumnCountConfig = Deserialize?.instanceOf(MenuColumnCountConfig, columnCountConfig);
    if (!this.sectionColumnConfig) {
      this.sectionColumnConfig = new Map<SectionColumnConfigKey, ThemeSectionColumnConfig>();
    } else if (!(this.sectionColumnConfig instanceof Map)) {
      const config = this.sectionColumnConfig;
      this.sectionColumnConfig = Deserialize?.mapOf(ThemeSectionColumnConfig, config);
    } else {
      this.sectionColumnConfig = new Map<SectionColumnConfigKey, ThemeSectionColumnConfig>(this.sectionColumnConfig);
    }
  }

  public cachedTime: number;

  static buildCacheKey(id: string): string {
    return `Theme-${id}`;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(...params: any): string {
    return Theme.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getPortraitColumnOptionsAsSelectable(): Selectable[] {
    return this.getColumnCountsAsSelectable(this.menuColumnCountConfig?.supportedMenuColumnCountPortrait);
  }

  getLandscapeColumnOptionsAsSelectable(): Selectable[] {
    return this.getColumnCountsAsSelectable(this.menuColumnCountConfig?.supportedMenuColumnCountLandscape);
  }

  private getColumnCountsAsSelectable(numbers: number[]): Selectable[] {
    return numbers?.map(n => {
      return {
        getSelectionTitle: () => n.toString(),
        getSelectionValue: () => n,
        getSelectionUniqueIdentifier: () => n
      };
    });
  }

  public getStackPreviewImages(): Asset[] {
    return this.landscapePreviewImages;
  }

  supportsDynamicColumns(): boolean {
    let supportsDynamicColumns = false;
    this.sectionColumnConfig?.forEach((value, _) => {
      if (value.defaultState !== SectionColumnConfigDefaultState.Disabled) {
        supportsDynamicColumns = true;
      }
    });
    return supportsDynamicColumns;
  }

  companyHasAccess(companyId: number): boolean {
    return this.companyIds?.includes(companyId);
  }

  isPrivate(): boolean {
    return this.companyIds?.length > 0;
  }

}
