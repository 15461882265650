import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-price-preview-widget',
  templateUrl: './price-preview-widget.component.html',
  styleUrls: ['./price-preview-widget.component.scss']
})
export class PricePreviewWidgetComponent {

  @Input() price: any;
  @Input() priceSource: string;

}
