import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { CacheService } from '../../../../../../../services/cache-service';
import { EditPlaylistMenuViewModel } from '../edit-playlist-menu-view-model';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { BudsenseFile } from '../../../../../../../models/shared/budsense-file';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { MediaType } from '../../../../../../../models/enum/dto/media-type.enum';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../../../models/shared/display-options';
import { Asset } from '../../../../../../../models/image/dto/asset';
import { BsError } from '../../../../../../../models/shared/bs-error';
import { OrderableMenuAsset } from '../../../../../../../models/menu/shared/orderable-menu-asset';
import { StringUtils } from '../../../../../../../utils/string-utils';

@Injectable()
export class EditPlaylistViewModel extends BaseViewModel {

  constructor(
    public cacheService: CacheService,
    public sharedViewModel: EditPlaylistMenuViewModel,
    private domainModel: MenuDomainModel,
    private toastService: ToastService
  ) {
    super();
  }

  public setDefaultOptionsForNewFiles(files: BudsenseFile[]): Observable<Menu> {
    return this.sharedViewModel.menu$.pipe(
      take(1),
      switchMap(menu => {
        return this.getNextPriority().pipe(
          switchMap(nextPriority => {
            const changePriority = (f: BudsenseFile, duration: number) => {
              nextPriority++;
              menu.options.rotationOrder.set(f.name, nextPriority);
              menu.options.rotationInterval.set(f.name, duration);
            };
            // Set the next rotation order values for the new files
            const calculate = files.map(f => {
              const topOfPipe$ = f.getMediaType() === MediaType.WEBM
                ? this.setVideoOrderInterval(f)
                : of(DEFAULT_ROTATION_INTERVAL);
              return topOfPipe$.pipe(map(duration => changePriority(f, duration)));
            });
            return forkJoin(calculate).pipe(switchMap(_ => this.sharedViewModel.backgroundSaveMenuWithCompletion()));
          })
        );
      })
    );
  }

  setVideoOrderInterval(f: BudsenseFile): Observable<number> {
    return new Observable((subscriber) => {
      const sendAndComplete = (duration: number) => {
        subscriber.next(duration);
        subscriber.complete();
      };
      if (typeof f.url === 'string') {
        const video = document.createElement('video');
        video.src = f.url;
        video.preload = 'metadata';
        video.load();
        video.onloadedmetadata = () => sendAndComplete(Number(video.duration.toFixed(2)));
      } else {
        sendAndComplete(DEFAULT_ROTATION_INTERVAL);
      }
    });
  }

  public getNextPriority(): Observable<number> {
    // Get the next value from the existing rotation order
    return this.sharedViewModel?.menu$.pipe(
      take(1),
      map(menu => {
        const rotationOrder = menu?.options?.rotationOrder ?? new Map();
        const fileNames = Array.from(rotationOrder.keys());
        let nextPriority = 0;
        fileNames?.forEach(fileName => {
          const p = rotationOrder.get(fileName);
          if (p > nextPriority) nextPriority = p;
        });
        return nextPriority;
      })
    );
  }

  public deleteMedia(f: Asset) {
    this.sharedViewModel.addAssetToRemoveQueue(f);
    return this.domainModel.deleteAsset(f).pipe(delay(5000)).subscribe((_) => {
      this.toastService.publishSuccessMessage('Successful.', 'Remove Media');
      this.sharedViewModel.removeAsset(f);
      this.sharedViewModel.removeAssetFromRemoveQueue(f);
      // Save menu in background to update rotation order
      this.sharedViewModel.saveMenu(true);
    }, (error: BsError) => {
      this.toastService.publishError(error);
      this.sharedViewModel.removeAssetFromRemoveQueue(f);
      throwError(error);
    });
  }

  public addTempUploadedFiles(files: BudsenseFile[]) {
    combineLatest([this.sharedViewModel.menu$, this.sharedViewModel.orderedMedia$]).once(([menu, orderedMedia]) => {
      const spoofedMenuAssets: OrderableMenuAsset[] = [];
      files.forEach((f) => {
        if (!orderedMedia?.find(om => om?.asset?.fileName === f.name)) {
          const spoofedImage = new Asset();
          spoofedImage.fileName = StringUtils.normalizeCharacters(f.name);
          spoofedImage.mediaType = f.getMediaType();
          spoofedImage.id = 'fake';
          spoofedImage.md5Hash = 'fake';
          spoofedImage.urls = [];
          const p = menu?.options?.rotationOrder?.get(f.name) || 0;
          spoofedMenuAssets.push(new OrderableMenuAsset(f.name, spoofedImage, p));
        }
      });
      if (spoofedMenuAssets.length > 0) {
        this.sharedViewModel.updateTempMedia(spoofedMenuAssets);
      }
    });
  }

}
