import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmartFilterRowViewModel } from '../smart-filter-row-view-model';
import { HydratedSmartFilter } from '../../../../../models/automation/hydrated-smart-filter';

@Component({
  selector: 'app-smart-filter-card',
  templateUrl: './smart-filter-card.component.html',
  styleUrls: ['./smart-filter-card.component.scss']
})
export class SmartFilterCardComponent {

  @Input() smartFilter: HydratedSmartFilter;
  @Output() removeClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public viewModel: SmartFilterRowViewModel // global, provided by root
  ) { }

  removeSmartFilter() {
    this.removeClicked.emit(this.smartFilter.id);
  }

}
