import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectableSmartFilter } from '../../../../models/automation/protocols/selectable-smart-filter';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { SmartFilterGrouping } from '../../../../models/automation/smart-filter-grouping';
import { MAX_SF_LIMIT_ON_SMART_DA } from '../../modal/smart-filter-selection-modal/smart-filter-selection-modal-view-model';

@Component({
  selector: 'app-smart-filter-selection',
  templateUrl: './smart-filter-selection.component.html',
  styleUrls: ['./smart-filter-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterSelectionComponent extends BaseComponent implements OnChanges {

  @Input() selection: SelectableSmartFilter;
  @Input() previouslyAddedIds: string[] = [];
  @Input() selectedIds: string[] = [];
  @Input() isChild: boolean = false;
  @Input() includeBottomBorder: boolean = true;
  @Input() forSmartData: boolean = false;
  @Output() add = new EventEmitter<string>(true);
  @Output() remove = new EventEmitter<string>(true);

  protected _selection = new BehaviorSubject<SelectableSmartFilter>(null);
  public selection$ = defer(() => this._selection);
  public name$ = this.selection$.pipe(map(selection => selection?.getSelectionName()));
  public variantHitCount$ = this.selection$.pipe(map(filter => filter?.getVariantHits()));
  public variantHitsString$ = this.variantHitCount$.pipe(map(hits => `${hits} Variant${hits === 0 ? '' : 's'}`));
  public id$ = this.selection$.pipe(map(selection => selection?.getId()));
  protected _previouslyAddedIds = new BehaviorSubject<string[]>([]);
  public previouslyAddedIds$ = defer(() => this._previouslyAddedIds);
  private prevAddedPipe$ = combineLatest([this.id$, this.previouslyAddedIds$]);
  public previouslyAdded$ = this.prevAddedPipe$.pipe(map(([id, prevAdded]) => prevAdded?.contains(id)));
  protected _selectedIds = new BehaviorSubject<string[]>([]);
  public selectedIds$ = defer(() => this._selectedIds);
  public selectedPipe$ = combineLatest([this.id$, this.selectedIds$, this.previouslyAdded$]);
  public selected$ = this.selectedPipe$.pipe(map(([id, ids, prevAdded]) => prevAdded || ids?.contains(id)));
  protected _indeterminate = new BehaviorSubject<boolean>(false);
  public indeterminate$ = defer(() => this._indeterminate);
  protected _forSmartData = new BehaviorSubject<boolean>(false);
  public forSmartData$ = this._forSmartData.asObservable();
  public disableSmartFiltersWithBadgeOrLabelCriteria$ = combineLatest([
    this.selection$, this.forSmartData$
  ]).pipe(
    map(([ssf, openedFromSmartBadges]) => {
      if (!openedFromSmartBadges) return false;
      return (ssf instanceof HydratedSmartFilter && !!ssf?.badgeIds?.length)
        || (ssf instanceof HydratedSmartFilter && !!ssf?.labelIds?.length)
        || (ssf instanceof SmartFilterGrouping && ssf.getGroupedFilters().some((sf) => {
          return !!sf?.badgeIds?.length || !!sf.labelIds?.length;
        }));
    })
  );

  public disabled$ = combineLatest([
    this.disableSmartFiltersWithBadgeOrLabelCriteria$,
    this.previouslyAddedIds$,
    this.selected$,
    this.forSmartData$,
    this.selection$,
    this._selectedIds,
  ]).pipe(
    map(([badgeOrFilter, previouslyAddedIds, isSelected, forSmartData, selection, selectedIds]) => {
      const wasPreviouslyAdded = previouslyAddedIds.contains(selection?.getId());
      const allSelectedIds = [...previouslyAddedIds, ...selectedIds];
      const nSelections = (selectedIds?.length ?? 0) + (previouslyAddedIds?.length ?? 0);
      const nSelectionsFromGroup = selection instanceof SmartFilterGrouping
        ? selection.getIds()?.filter((id) => allSelectedIds?.includes(id))?.length
        : 0;

      const groupingWouldExceedLimit = selection instanceof SmartFilterGrouping
        && (((selection.getIds()?.length ?? 0) + nSelections - nSelectionsFromGroup) > MAX_SF_LIMIT_ON_SMART_DA);

      const entireGroupingSelected = selection instanceof SmartFilterGrouping
        && selection.getIds().every((id) => allSelectedIds?.includes(id));

      const smartFilterGroupingDisabled = selection instanceof SmartFilterGrouping
        && forSmartData
        && (nSelections >= MAX_SF_LIMIT_ON_SMART_DA || groupingWouldExceedLimit)
        && !entireGroupingSelected;

      const selectionIsHydratedSmartFilter = selection instanceof HydratedSmartFilter;
      let hasSmartDataCriteriaInFilter = false;
      if (selectionIsHydratedSmartFilter) {
        const filters = selection.getGroupedFilters();
        filters?.forEach(smartFilter => {
          const hasBadges = !!smartFilter?.badgeIds && smartFilter?.badgeIds.length > 0;
          const hasLabels = !!smartFilter?.labelIds && smartFilter?.labelIds.length > 0;
          hasSmartDataCriteriaInFilter = forSmartData && (hasBadges || hasLabels);
        });
      }

      const hydratedSmartFilterDisabled =
        selectionIsHydratedSmartFilter
        && forSmartData
        && ((nSelections >= MAX_SF_LIMIT_ON_SMART_DA && !isSelected) || hasSmartDataCriteriaInFilter);

      return (wasPreviouslyAdded || badgeOrFilter || hydratedSmartFilterDisabled || smartFilterGroupingDisabled);
    })
  );
  public tooltip = `Smart Filters that use labels and/or badges as advanced filter `
                   + `criteria may not be used as the criteria for smart badges/labels.`;

  constructor() {
    super();
  }

  override setupViews() {
    this._selection.next(this.selection);
    this._previouslyAddedIds.next(this.previouslyAddedIds);
    this._selectedIds.next(this.selectedIds);
    this._forSmartData.next(this.forSmartData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection) this._selection.next(this.selection);
    if (changes.previouslyAddedIds) this._previouslyAddedIds.next(this.previouslyAddedIds);
    if (changes.selectedIds) this._selectedIds.next(this.selectedIds);
    if (changes.forSmartData) this._forSmartData.next(this.forSmartData);
  }

  public clicked(selected: boolean) {
    selected ? this.add.emit(this.selection?.getId()) : this.remove.emit(this.selection?.getId());
  }

}
