import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LabelsPickerViewModel } from './labels-picker-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HydratedSmartFilter } from '../../../models/automation/hydrated-smart-filter';
import { BaseComponent } from '../../../models/base/base-component';
import { ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { Label } from '../../../models/shared/label';

/**
 * Requires dispersedKey to be set and a parent reactive form to be present.
 */
@Component({
  selector: 'app-reactive-form-labels-picker',
  templateUrl: './reactive-form-labels-picker.component.html',
  styleUrls: ['./reactive-form-labels-picker.component.scss'],
  providers: [LabelsPickerViewModel]
})
export class ReactiveFormLabelsPickerComponent extends BaseComponent implements OnChanges {

  @Input() bindTo: HydratedSmartFilter;
  @Input() dispersedKey: string;
  @Input() maxSelectedLabels: number;
  @Input() useAtLeastNItemsFilledInPool: boolean;
  @Output() selectedIds: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('formItem') formItem: ReactiveFormItemComponent;

  private _selectedLabelIds = new BehaviorSubject<string[]>(null);
  public selectedLabelIds$ = this._selectedLabelIds as Observable<string[]>;

  public disableCheckboxes$ = this.selectedLabelIds$.pipe(map(ids => ids?.length >= this.maxSelectedLabels));

  constructor(
    public viewModel: LabelsPickerViewModel,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) {
      this._selectedLabelIds.next(this.bindTo.labelIds);
    }
  }

  parseLabelIds = (newVal: string | string[]) => {
    if (newVal instanceof Array) {
      return newVal.filterFalsies().sort();
    }
  };

  checkboxSelected(labelId: string) {
    this.selectedLabelIds$.pipe(take(1)).subscribe(ids => {
      const i = ids?.indexOf(labelId);
      if (i === -1) {
        ids?.push(labelId);
      } else {
        ids?.splice(i, 1);
      }
      this._selectedLabelIds.next(ids);
      this.selectedIds.emit(ids);
    });
  }

  handleLabelCleared(l: Label) {
    this.selectedLabelIds$.pipe(take(1)).subscribe(ids => {
      const i = ids.indexOf(l?.id || l?.id.toLowerCase());
      ids.splice(i, 1);
      const labelsCopy = ids.deepCopy();
      this._selectedLabelIds.next(labelsCopy);
      this.selectedIds.emit(labelsCopy);
      this.formItem.getSelfAsFormItem().markAsDirty();
    });
  }

}
