import { Injectable } from '@angular/core';
import { CombinedLoadingOptsViewModel } from '../../../shared/components/auto-save/combined-loading-opts-view-model';
import { EditCollectionViewModel } from './edit-collection-view-model';
import { EditDisplayViewModel } from '../../../display/viewModels/edit-display-view-model';

@Injectable()
export class EditCollectionCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    protected editCollectionViewModel: EditCollectionViewModel,
    protected editDisplayViewModel: EditDisplayViewModel
  ) {
    super([editCollectionViewModel, editDisplayViewModel]);
  }

}
