import { Pipe, PipeTransform } from '@angular/core';
import { MediaUtils } from '../../../utils/media-utils';

@Pipe({
  name: 'fileNameWithoutTimestamp'
})
export class FileNameWithoutTimestampPipe implements PipeTransform {

  transform(fileName: string): string {
    return MediaUtils.getFileNameWithoutTimestamp(fileName);
  }

}
