import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Breadcrumb } from '../../../../../../models/shared/stylesheet/breadcrumb';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { TemplateStatus } from '../../../../../../models/template/enum/template-status.enum';
import { EditTemplateMarketingMenuHeaderViewModel } from './edit-template-marketing-menu-header-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-template-marketing-menu-header',
  templateUrl: './edit-template-marketing-menu-header.component.html',
  styleUrls: [
    './edit-template-marketing-menu-header.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditTemplateMarketingMenuHeaderViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingMenuHeaderComponent extends BaseComponent {

  constructor(
    public viewModel: EditTemplateMarketingMenuHeaderViewModel
  ) {
    super();
  }

  @Input() template: MenuTemplate;
  @Input() crumbs: Breadcrumb[] = [];
  @Input() allowLiveView: boolean = true;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() disableSave: boolean = false;
  @Input() headerText: string = '';
  @Input() headerSubtext: string = '';
  @Input() hideLiveViewButton: boolean = false;
  @Input() lastSavedTimeText: string = '';
  @Input() showPublishButton: boolean = false;
  @Input() templateStatus: TemplateStatus;
  @Input() unsavedChanges: boolean = false;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() mergeKey: string;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  @Input() promptToPublishTemplate: () => void;
  // emit true if you want the form to auto submit in the background
  @Output() submitForms = new EventEmitter<boolean>(true);

}
