import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BadgePickerViewModel } from './badge-picker-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-badge-picker',
  templateUrl: './badge-picker.component.html',
  styleUrls: ['./badge-picker.component.scss'],
  providers: [BadgePickerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgePickerComponent extends BaseComponent implements OnChanges {

  @Input() addedBadgeIds: string[];
  @Input() preselectedBadgeIds: string[];
  @Input() asListItems: boolean = false;
  @Input() maximumNumberOfBadges: number;
  @Input() titleText: string = null;
  @Input() @ViewModelConnector('connectToCuratedBadgesOnly') curatedBadgesOnly: boolean = false;
  @Input() @ViewModelConnector('connectToRemoveSmartBadgesFromPicker') removeSmartBadgesFromPicker = true;
  @Output() selected: EventEmitter<HydratedVariantBadge> = new EventEmitter<HydratedVariantBadge>();

  constructor(
    public viewModel: BadgePickerViewModel
  ) {
    super();
  }

  private _titleTextInput = new BehaviorSubject<string>(null);
  public titleTextInput$ = this._titleTextInput as Observable<string>;

  private _maxBadges = new BehaviorSubject<number>(null);
  public maxBadges$ = this._maxBadges as Observable<number>;

  public titleText$ = combineLatest([
    this.titleTextInput$,
    this.maxBadges$
  ]).pipe(
    map(([text, maxNum]) => {
      if (!!text) {
        return text;
      }
      return `Only ${maxNum} badges can be applied to a variant`;
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.titleText) this._titleTextInput.next(this.titleText);
    if (changes.maximumNumberOfBadges) this._maxBadges.next(this.maximumNumberOfBadges);
  }

}
