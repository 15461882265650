import { BaseComponent } from '../../../../models/base/base-component';
import { AutoSaveViewModel } from './auto-save-view-model';
import { Component, ViewChild } from '@angular/core';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { ComponentCanDeactivate } from '../../../../models/protocols/component-can-deactivate';

@Component({ selector: 'app-auto-save', template: '' })
export abstract class AutoSaveComponent extends BaseComponent implements ComponentCanDeactivate {

  protected constructor(
    public viewModel: AutoSaveViewModel,
  ) {
    super();
  }

  @ViewChild(ReactiveFormMergeGroupsComponent) formCapture: ReactiveFormMergeGroupsComponent;

  override setupBindings() {
    // trigger form auto submit
    this.viewModel.triggerAutoFormSubmission
      .subscribeWhileAlive({
        owner: this,
        next: _ => this.formCapture?.submitForms(true)
      });
  }

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

}
