import { Component } from '@angular/core';
import { Tabbable } from '../../../../../models/protocols/tabbable';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-test-tab',
  templateUrl: './test-tab.component.html',
  styleUrls: ['./test-tab.component.scss']
})
export class TestTabComponent extends BaseComponent implements Tabbable {

  iBelongTo: string;
  public tabActivated = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }

}
