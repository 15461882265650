import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OverflowState, OverflowStateInfoType } from '../../../../../../../models/utils/dto/overflow-state-type';
import { MarketingMenuType } from '../../../../../../../models/enum/dto/marketing-menu-type.enum';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';
import { MenuType } from '../../../../../../../models/utils/dto/menu-type-definition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOverflowInformation } from '../../../../../../../modals/modal-overflow-information';
import { MarketingTheme } from '../../../../../../../models/enum/dto/theme.enum';

@Component({
  selector: 'app-menu-titles-form',
  templateUrl: './menu-titles-form.component.html',
  styleUrls: ['./menu-titles-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuTitlesFormComponent extends MenuAdditionalOptionsFormComponent implements OnChanges {

  constructor(
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  @Input() templateMode = false;
  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;

  private _selectedOverflowState = new BehaviorSubject<OverflowState>(null);
  public selectedOverflowState$ = this._selectedOverflowState as Observable<OverflowState>;

  public overflowStates$ = window.types.productMenuOverflowStateTypes$;
  private sectionOverflowStateTypes$ = window.types.sectionOverflowStateTypes$;
  public sectionOverflowDurationRequired$ = combineLatest([
    this.selectedOverflowState$,
    this.sectionOverflowStateTypes$
  ]).pipe(
    map(([selectedOverflowState, sectionOverflowStateTypes]) => {
      return sectionOverflowStateTypes?.map(type => type?.getSelectionValue())?.includes(selectedOverflowState);
    })
  );
  public sectionOverflowDurationDisabled$ = this.sectionOverflowDurationRequired$.pipe(map(required => !required));
  public tooltipModalInfoItems$ = this.overflowStates$.pipe(
    map(types => types?.getInfoItems(OverflowStateInfoType.Default))
  );

  private listenToMenu = this.menu$.notNull().subscribeWhileAlive({
    owner: this,
    next: m => this.setOverflowState(m?.overflowState)
  });

  public hasLoopingContent$ = this.menu$.pipe(
    map(m => {
      const playlist = m?.hydratedTheme?.menuSubType === MarketingMenuType.Playlist;
      const smartPlaylist = m?.hydratedTheme?.menuSubType === MarketingMenuType.SmartPlaylist;
      const featuredProduct = m?.hydratedTheme?.menuSubType === MarketingMenuType.Featured;
      const UrlPlaylist = m?.hydratedTheme?.menuSubType === MarketingMenuType.UrlPlaylist;
      return playlist || smartPlaylist || featuredProduct || UrlPlaylist;
    })
  );

  public showSubtitle$ = combineLatest([
    this.menu$,
    this.hasLoopingContent$
  ]).pipe(
    map(([m, hasLoopingContent]) => {
      const isMarketingMenu = m?.type === MenuType.MarketingMenu;
      return m?.isSpotlightMenu() || (!hasLoopingContent && isMarketingMenu);
    })
  );

  public isSurfsideTheme$ = this.menu$.pipe(
    map(menu => menu?.theme === MarketingTheme.SurfsideUrlPlaylist)
  );

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.bindTo) this._menu.next(this.bindTo);
  }

  openOverflowInfo() {
    ModalOverflowInformation.open(this.ngbModal, this.injector, OverflowStateInfoType.Default);
  }

  setOverflowState = (overflowState: OverflowState) => this._selectedOverflowState.next(overflowState);

}
