/* eslint-disable dot-notation */
import { Variant } from '../models/product/dto/variant';
import { Product } from '../models/product/dto/product';
import { PriceUtils } from './price-utils';
import { VariantGroup } from '../models/product/shared/variant-group';
import { PriceFormat } from '../models/utils/dto/price-format-type';

export class SearchUtils {

  static rangedUnitSearchPropertyList(isVariant: boolean): string[] {
    return [
      'displayAttributesMinTHC', 'displayAttributesMaxTHC',
      'displayAttributesMinCBD', 'displayAttributesMaxCBD',
      'displayAttributesInheritedDisplayAttributeMinTHC', 'displayAttributesInheritedDisplayAttributeMaxTHC',
      'displayAttributesInheritedDisplayAttributeMinCBD', 'displayAttributesInheritedDisplayAttributeMaxCBD'
    ].concat(isVariant ? ['minTHC', 'maxTHC', 'minCBD', 'maxCBD'] : []);
  }

  /* ******************************* Search Products for Table ******************************* */

  static productSearchPropertyList(rangedUnits: boolean): string[] {
    const base = [
      'name', 'displayName', 'brand', 'label', 'overrideGroupName',
      'displayAttributesDisplayName',
      'displayAttributesTHC',
      'displayAttributesCBD',
      'displayAttributesInheritedDisplayAttributeDisplayName',
      'displayAttributesInheritedDisplayAttributeTHC',
      'displayAttributesInheritedDisplayAttributeCBD'
    ];
    return rangedUnits ? base.concat(SearchUtils.rangedUnitSearchPropertyList(false)) : base;
  }

  static getFuseOptionsForProduct(rangedUnits: boolean): any {
    return {
      isCaseSensitive: false,
      shouldSort: false,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: SearchUtils.productSearchPropertyList(rangedUnits)
    };
  }

  static variantSearchPropertyList(rangedUnits: boolean): string[] {
    const base = [
      'barcode', 'name', 'displayName', 'price', 'brand', 'classification', 'size', 'label',
      'cannabisUnitOfMeasure', 'packagedQuantity',
      'productType', 'variantType', 'strain',
      'THC', 'CBD',
      'displayAttributesDisplayName',
      'displayAttributesTHC', 'displayAttributesCBD',
      'displayAttributesInheritedDisplayAttributeDisplayName',
      'displayAttributesInheritedDisplayAttributeTHC',
      'displayAttributesInheritedDisplayAttributeCBD'
    ];
    return rangedUnits ? base.concat(SearchUtils.rangedUnitSearchPropertyList(true)) : base;
  }

  static getFuseOptionsForVariants(rangedUnits: boolean): any {
    return {
      isCaseSensitive: false,
      shouldSort: false,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: SearchUtils.variantSearchPropertyList(rangedUnits)
    };
  }

  // TODO - KFFT - how is this going to work with cannabinoids and terpenes?
  static getSearchableProduct(p: Product, rangedUnits: boolean): any {
    const CUOM = p?.getProductCannabisUnitOfMeasure();
    return {
      id: p?.id,
      name: p?.name.stripWhiteSpaceAndLowerCase(),
      displayName: p?.getDisplayName()?.stripWhiteSpaceAndLowerCase(),
      brand: p?.getBrand()?.stripWhiteSpaceAndLowerCase(),
      label: p?.computedLabelTextForProductSearch?.stripWhiteSpaceAndLowerCase(),
      overrideGroupName: p?.overrideGroupName?.stripWhiteSpaceAndLowerCase(),
      displayAttributesDisplayName: p?.displayAttributes?.displayName?.stripWhiteSpaceAndLowerCase(),
      displayAttributesTHC: (p?.displayAttributes?.['THC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      displayAttributesCBD: (p?.displayAttributes?.['CBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      displayAttributesInheritedDisplayAttributeDisplayName:
        p?.displayAttributes?.inheritedDisplayAttribute?.displayName?.stripWhiteSpaceAndLowerCase(),
      displayAttributesInheritedDisplayAttributeTHC:
        (p?.displayAttributes?.inheritedDisplayAttribute?.['THC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      displayAttributesInheritedDisplayAttributeCBD:
        (p?.displayAttributes?.inheritedDisplayAttribute?.['CBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      ...(rangedUnits && {
        displayAttributesMinTHC: (p?.displayAttributes?.['minTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesMaxTHC: (p?.displayAttributes?.['maxTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesMinCBD: (p?.displayAttributes?.['minCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesMaxCBD: (p?.displayAttributes?.['maxCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMinTHC:
          (p?.displayAttributes?.inheritedDisplayAttribute?.['minTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMaxTHC:
          (p?.displayAttributes?.inheritedDisplayAttribute?.['maxTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMinCBD:
          (p?.displayAttributes?.inheritedDisplayAttribute?.['minCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMaxCBD:
          (p?.displayAttributes?.inheritedDisplayAttribute?.['maxCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase()
      }),
      searchableVariants: p?.variantsFilteredByMedAndRec?.map(v => SearchUtils.getSearchableVariant(v, rangedUnits))
    };
  }

  // TODO - KFFT - how is this going to work with cannabinoids and terpenes?
  static getSearchableVariant(v: Variant, rangedUnits: boolean): any {
    const CUOM = v?.cannabisUnitOfMeasure;
    return {
      id: v?.id,
      barcode: v?.barcode?.stripWhiteSpaceAndLowerCase(),
      name: v?.name.stripWhiteSpaceAndLowerCase(),
      displayName: v?.getDisplayName()?.stripWhiteSpaceAndLowerCase(),
      price: PriceUtils.formatPrice(v?.price),
      brand: v?.brand?.stripWhiteSpaceAndLowerCase(),
      classification: v?.classification?.stripWhiteSpaceAndLowerCase(),
      label: v?.computedLabelTextForVariantSearch?.stripWhiteSpaceAndLowerCase(),
      size: v?.getSize()?.stripWhiteSpaceAndLowerCase(),
      packagedQuantity: v?.packagedQuantity,
      productType: v?.productType?.stripWhiteSpaceAndLowerCase(),
      variantType: v?.variantType?.stripWhiteSpaceAndLowerCase(),
      THC: (v?.getCannabinoidWithUnits('THC'))?.stripWhiteSpaceAndLowerCase(),
      minTHC: (v?.getMinCannabinoidWithUnits('THC'))?.stripWhiteSpaceAndLowerCase(),
      maxTHC: (v?.getMaxCannabinoidWithUnits('THC'))?.stripWhiteSpaceAndLowerCase(),
      CBD: (v?.getCannabinoidWithUnits('CBD'))?.stripWhiteSpaceAndLowerCase(),
      minCBD: (v?.getMinCannabinoidWithUnits('CBD'))?.stripWhiteSpaceAndLowerCase(),
      maxCBD: (v?.getMaxCannabinoidWithUnits('CBD'))?.stripWhiteSpaceAndLowerCase(),
      displayAttributesDisplayName: v?.displayAttributes?.displayName?.stripWhiteSpaceAndLowerCase(),
      displayAttributesTHC: (v?.displayAttributes?.['THC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      displayAttributesCBD: (v?.displayAttributes?.['CBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      displayAttributesInheritedDisplayAttributeDisplayName:
        v?.displayAttributes?.inheritedDisplayAttribute?.displayName?.stripWhiteSpaceAndLowerCase(),
      displayAttributesInheritedDisplayAttributeTHC:
        (v?.displayAttributes?.inheritedDisplayAttribute?.['THC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      displayAttributesInheritedDisplayAttributeCBD:
        (v?.displayAttributes?.inheritedDisplayAttribute?.['CBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
      ...(rangedUnits && {
        displayAttributesMaxTHC: (v?.displayAttributes?.['maxTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesMinTHC: (v?.displayAttributes?.['minTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesMinCBD: (v?.displayAttributes?.['minCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesMaxCBD: (v?.displayAttributes?.['maxCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMaxTHC:
          (v?.displayAttributes?.inheritedDisplayAttribute?.['maxTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMinTHC:
          (v?.displayAttributes?.inheritedDisplayAttribute?.['minTHC'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMinCBD:
          (v?.displayAttributes?.inheritedDisplayAttribute?.['minCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase(),
        displayAttributesInheritedDisplayAttributeMaxCBD:
          (v?.displayAttributes?.inheritedDisplayAttribute?.['maxCBD'] + CUOM)?.stripWhiteSpaceAndLowerCase()
      })
    };
  }

  /* ******************************* Search Products in Edit Section ******************************* */

  static editSectionProductSearchPropertyList(): string[] {
    return ['displayName', 'label'];
  }

  static editSectionVariantSearchPropertyList(rangedUnits: boolean): string[] {
    return [
      'displayName', 'label', 'editSectionLabel'
    ];
  }

  static getFuseOptionsForEditSectionProduct(): any {
    return {
      isCaseSensitive: false,
      shouldSort: false,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: SearchUtils.editSectionProductSearchPropertyList()
    };
  }

  static getFuseOptionsForEditSectionVariants(rangedUnits: boolean): any {
    return {
      isCaseSensitive: false,
      shouldSort: false,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: SearchUtils.editSectionVariantSearchPropertyList(rangedUnits)
    };
  }

  static getSearchableVariantGroup(
    vg: VariantGroup,
    rangedUnits: boolean,
    locId: number,
    compId: number,
    priceFormat: PriceFormat,
    hideSalePricing: boolean
  ): any {
    return SearchUtils.getEditSectionSearchableProduct(
      vg?.product,
      vg?.variants || [],
      rangedUnits,
      locId,
      compId,
      priceFormat,
      hideSalePricing
    );
  }

  static getEditSectionSearchableProduct(
    p: Product,
    searchableVariants: Variant[],
    rangedUnits: boolean,
    locId: number,
    compId: number,
    priceFormat: PriceFormat,
    hideSalePricing: boolean
  ): any {
    const CUOM = p?.getProductCannabisUnitOfMeasure();
    return {
      id: p?.id,
      displayName: p?.getDisplayName()?.stripWhiteSpaceAndLowerCase(),
      label: p?.computedLabelTextForProductSearch?.stripWhiteSpaceAndLowerCase(),
      searchableVariants: searchableVariants?.map(v => {
        return SearchUtils.getEditSectionSearchableVariant(v, rangedUnits, locId, compId, priceFormat, hideSalePricing);
      })
    };
  }

  static getEditSectionSearchableVariant(
    v: Variant,
    rangedUnits: boolean,
    locId: number,
    compId: number,
    priceFormat: PriceFormat,
    hideSalePricing: boolean
  ): any {
    const CUOM = v?.cannabisUnitOfMeasure;
    return {
      id: v?.id,
      displayName: v?.getDisplayName()?.stripWhiteSpaceAndLowerCase(),
      price: PriceUtils.formatPrice(v?.getVisiblePrice(locId, compId, priceFormat, hideSalePricing)),
      classification: v?.classification?.stripWhiteSpaceAndLowerCase(),
      label: v?.computedLabelTextForVariantSearch?.stripWhiteSpaceAndLowerCase(),
      editSectionLabel: v?.computedLabelForMenuSection?.text?.stripWhiteSpaceAndLowerCase(),
      size: v?.getEditSectionVariantSizeText()?.stripWhiteSpaceAndLowerCase(),
      packagedQuantity: v?.packagedQuantity,
      quantityInStock: v?.inventory?.quantityInStock,
      productType: v?.productType?.stripWhiteSpaceAndLowerCase(),
      variantType: v?.variantType?.stripWhiteSpaceAndLowerCase(),
      ...(!rangedUnits && {
        THC: (v?.getCannabinoidWithUnits('THC'))?.stripWhiteSpaceAndLowerCase(),
        CBD: (v?.getCannabinoidWithUnits('CBD'))?.stripWhiteSpaceAndLowerCase(),
      }),
      ...(rangedUnits && {
        minTHC: (v?.getMinCannabinoidWithUnits('THC'))?.stripWhiteSpaceAndLowerCase(),
        maxTHC: (v?.getMaxCannabinoidWithUnits('THC'))?.stripWhiteSpaceAndLowerCase(),
        minCBD: (v?.getMinCannabinoidWithUnits('CBD'))?.stripWhiteSpaceAndLowerCase(),
        maxCBD: (v?.getMaxCannabinoidWithUnits('CBD'))?.stripWhiteSpaceAndLowerCase(),
      })
    };
  }

  /* *********************************** Simple Variant Search *********************************** */

  static getSimpleSearchableProduct(p: Product): any {
    return {
      id: p?.id,
      name: p?.getDisplayName(),
      variantIds: p?.variants?.map(v => v?.id)
    };
  }

  static getSimpleSearchableVariant(v: Variant): any {
    return {
      id: v?.id,
      name: v?.getDisplayName()
    };
  }

  /* ********************************************************************************************* */

}
