import { WeekDay } from '@angular/common';
import { Deserializable } from '../protocols/deserializable';
import { DateUtils } from '../../utils/date-utils';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { LocalizedDateRange } from './localized-date-range';
import { exists } from '../../functions/exists';

export class TimeDuration implements Deserializable, UniquelyIdentifiable {

  public startTime: string;
  public endTime: string;
  public days: WeekDay[];
  public dateTimeWindows: LocalizedDateRange[];

  constructor() {
    this.startTime = '';
    this.endTime = '';
    this.days = [];
    this.dateTimeWindows = [];
  }

  public onDeserialize() {
    this.days = Array.from(this.days || []);
    this.dateTimeWindows = window.injector.Deserialize.arrayOf(LocalizedDateRange, this.dateTimeWindows);
  }

  public onSerialize(): TimeDuration {
    const dto: TimeDuration = Object.create(TimeDuration.prototype);
    dto.startTime = this.startTime;
    dto.endTime = this.endTime;
    dto.days = this.days;
    dto.dateTimeWindows = [];
    this.dateTimeWindows?.forEach(dateTimeWindow => {
      dto.dateTimeWindows?.push(dateTimeWindow?.onSerialize());
    });
    return dto;
  }

  public isActive(): boolean {
    return this.days.contains(DateUtils.currentWeekday()) && this.isTimeIntervalActive();
  }

  public isTimeIntervalActive(): boolean {
    const secondsNow = DateUtils.getSecondsSinceStartOfDay();
    const secondsStart = TimeDuration.getSecondsSinceStartOfDay(this.startTime);
    const secondsEnd = TimeDuration.getSecondsSinceStartOfDay(this.endTime);
    if (secondsStart >= 0 && secondsEnd >= 0) {
      return (secondsNow >= secondsStart) && (secondsNow <= secondsEnd);
    } else {
      return false;
    }
  }

  public isRecurring(): boolean {
    return exists(this.startTime) && exists(this.endTime) && this.days?.length > 0;
  }

  public hasDateTimeWindows(): boolean {
    return this.dateTimeWindows?.length > 0;
  }

  public hasSchedule(): boolean {
    return this.isRecurring() || this.hasDateTimeWindows();
  }

  private static getSecondsSinceStartOfDay(str: string): number {
    if (!!str) {
      const time = str.split(':');
      try {
        const hrsInSecs = (Number.parseInt(time[0], 10) * 3600);
        const minsInSecs = (Number.parseInt(time[1], 10) * 60);
        const secs = ((Number.parseInt(time[2], 10)) || 0);
        return (hrsInSecs + minsInSecs + secs);
      } catch (e) {
        return -1;
      }
    } else {
      return -1;
    }
  }

  getUniqueIdentifier(): string {
    const dateTimeWindowsId = this.dateTimeWindows?.map(dtw => dtw?.getUniqueIdentifier()).sort();
    return `${this.startTime}
      -${this.endTime}
      -${this.days?.sort().toStringArray() ?? ''}
      -${dateTimeWindowsId?.toStringArray() ?? ''}`;
  }

}
