import { OverflowStateInfoType } from '../models/utils/dto/overflow-state-type';
import { TypeDefinition } from '../models/utils/dto/type-definition';
import { OverflowInformationItem } from '../models/shared/overflow-information-item';

export {};
declare global {
  interface Array<T> {
    getInfoItems<T extends TypeDefinition>(o: OverflowStateInfoType): OverflowInformationItem[];
  }
}

Array.prototype.getInfoItems = function(o: OverflowStateInfoType): OverflowInformationItem[] {
  return this.map(t => {
    return new OverflowInformationItem(t.name, t.getSelectionInformationDescription(o), t.getSelectionValue());
  });
};
