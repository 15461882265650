import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { BulkPrintJobsDataTableViewModel } from './bulk-print-jobs-data-table-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-bulk-print-jobs-data-table',
  templateUrl: './bulk-print-jobs-data-table.component.html',
  styleUrls: ['./bulk-print-jobs-data-table.component.scss'],
  providers: [BulkPrintJobsDataTableViewModel]
})
export class BulkPrintJobsDataTableComponent extends BaseComponent {

  @Input() bulkPrintJobs: BulkPrintJob[];
  @Input() nItemsToDisplay: number;
  @Input() noResultsText: string;
  @Input() noResultsTitle: string;
  @Input() resetPageOnNewData: boolean = false;
  @Input() resetPageOnSortingChange: boolean = true;
  @Input() showPagination: boolean = true;
  @Input() @ViewModelConnector('connectToEditPrintCardMode') editPrintCardMode: boolean;
  @Input() @ViewModelConnector('connectToEditPrintLabelMode') editPrintLabelMode: boolean;

  constructor(public viewModel: BulkPrintJobsDataTableViewModel) {
    super();
  }

}
