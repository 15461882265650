import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { StackSmartBulkPrintJobViewModel } from './stack-smart-bulk-print-job-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import type { StackType } from '../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { StackPrintType } from '../../../../../../models/automation/enum/card-stack-print-type.enum';

@Component({
  selector: 'app-stack-smart-bulk-print-job',
  templateUrl: './stack-smart-bulk-print-job.component.html',
  providers: [StackSmartBulkPrintJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartBulkPrintJobComponent {

  constructor(public viewModel: StackSmartBulkPrintJobViewModel) {
  }

  @Input() @ViewModelConnector('connectToStackPrintType') stackPrintType: StackPrintType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToPlaceholder') placeholder: string;
  @Input() @ViewModelConnector('connectToSelectedStackIds') selectedCardStackIds: string[];
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean = false;
  @Input() stackType: StackType;

}
