import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../models/company/dto/location';

@Pipe({
  name: 'locationsOnly'
})
export class LocationsOnlyPipe implements PipeTransform {

  transform(value: any[]): Location[] {
    return value?.filter((v): v is Location => v instanceof Location);
  }

}
