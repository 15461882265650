import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { Observable } from 'rxjs';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import { DisplayLabelInterface } from '../../../modules/product-labels/label/display-label-interface';
import { LabelUtils } from '../../../modules/product-labels/utils/label-utils';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'labelInterfaceForLabelPicker'
})
export class LabelInterfaceForLabelPickerPipe implements PipeTransform {

  transform(
    label: Label,
    configurations$: Observable<[LocationConfiguration, CompanyConfiguration]>,
    isClearable: boolean,
    isOrphanedCompanyLabel: boolean = false
  ): Observable<DisplayLabelInterface> {
    return configurations$?.pipe(
      map(([locationConfig, companyConfig]) => {
        return LabelUtils.getDisplayLabelInterfaceForLabelPicker(
          label,
          locationConfig,
          companyConfig,
          isClearable,
          isOrphanedCompanyLabel
        );
      })
    );
  }

}
