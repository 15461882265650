import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DigitalMarketingMenusViewModel } from './digital-marketing-menus-view-model';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';

@Component({
  selector: 'app-digital-marketing-menus',
  templateUrl: './digital-marketing-menus.component.html',
  styleUrls: ['./digital-marketing-menus.component.scss'],
  providers: [DigitalMarketingMenusViewModel]
})
export class DigitalMarketingMenusComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: DigitalMarketingMenusViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
