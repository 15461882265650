import { Injectable } from '@angular/core';
import { BaseService } from '@mobilefirstdev/base-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActionService extends BaseService {

  constructor() {
    super();
  }

  public createBadgeFlag = new BehaviorSubject<boolean>(null);
  public createDisplayFlag = new BehaviorSubject<boolean>(null);
  public createMenuFlag = new BehaviorSubject<boolean>(null);
  public createPrintMenu = new BehaviorSubject<boolean>(null);
  public pairDeviceFlag = new BehaviorSubject<boolean>(null);

  setOpenNewBadgeModalFlag() {
    this.createBadgeFlag.next(true);
  }

  setCreateDisplayFlag() {
    this.createDisplayFlag.next(true);
  }

  setCreateMenuFlag() {
    this.createMenuFlag.next(true);
  }

  setCreatePrintMenuFlag() {
    this.createPrintMenu.next(true);
  }

  setPairDeviceFlag() {
    this.pairDeviceFlag.next(true);
  }

}
