import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScreenService {

  static MOBILE_WIDTH = 768;
  private isMobile = new BehaviorSubject<boolean>(false);
  public isMobile$ = this.isMobile.pipe(distinctUntilChanged());
  private _width = new BehaviorSubject<number>(0);
  public width$ = this._width.asObservable();

  constructor() { }

  setWidth(w: number) {
    this._width.next(w);
  }

  setIsMobile(x: boolean) {
    this.isMobile.next(x);
  }

}
