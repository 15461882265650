import { Pipe, PipeTransform } from '@angular/core';
import { HydratedSmartFilter } from '../../../models/automation/hydrated-smart-filter';

@Pipe({
  name: 'castAsHydratedSmartFilter'
})
export class CastAsHydratedSmartFilterPipe implements PipeTransform {

  transform(value: any): HydratedSmartFilter|null {
    return (value instanceof HydratedSmartFilter) ? value : null;
  }

}
