import { Component, ViewChild } from '@angular/core';
import { PrintTemplatesViewModel } from './print-templates-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ActivatedRoute } from '@angular/router';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { combineLatest } from 'rxjs';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';

@Component({
  selector: 'app-print-templates',
  templateUrl: './print-templates.component.html',
  styleUrls: ['./print-templates.component.scss'],
  providers: [PrintTemplatesViewModel]
})
export class PrintTemplatesComponent extends BaseComponent {

  constructor(
    public viewModel: PrintTemplatesViewModel,
    private userDomainModel: UserDomainModel,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  @ViewChild('tabController') tabController: TabBarComponent;

  override setupBindings(): void {
    // Capture the fragment if available and navigates to a specific tab
    const fragSub = combineLatest([
      this.activatedRoute.fragment.notNull(),
      this.userDomainModel.canUsePrintCards$,
    ]).subscribe(([fragment, canUsePrintCards]) => {
      switch (fragment?.toLowerCase()) {
        case 'producttemplates': this.tabController.tabSelected(0); break;
        case 'reporttemplates': this.tabController.tabSelected(1); break;
        case 'printcardtemplates': {
          if (canUsePrintCards) {
            this.tabController.tabSelected(2); break;
          }
        }
        case 'printlabeltemplates': this.tabController.tabSelected(canUsePrintCards ? 3 : 2); break;
      }
    });
    this.pushSub(fragSub);
  }

}
