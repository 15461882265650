import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../../models/base/base-component';
import { LabelPickerViewModel } from './label-picker-view-model';
import { Label } from '../../../models/shared/label';

@Component({
  selector: 'app-label-picker',
  templateUrl: './label-picker.component.html',
  styleUrls: ['./label-picker.component.scss'],
  providers: [LabelPickerViewModel]
})
export class LabelPickerComponent extends BaseComponent implements OnChanges {

  public seedNumber = Math.random() * 256;

  @Input() selectedLabel: Label;
  @Input() isCompany: boolean;
  @Input() includePadding: boolean = true;
  @Input() showClearSelectionButton: boolean = true;
  @Input() scrollSelectionIntoView: boolean = false;
  @Output() selectedId: EventEmitter<string> = new EventEmitter<string>();
  @Output() clearSelectionClicked: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>>;

  constructor(
    public viewModel: LabelPickerViewModel
  ) {
    super();
  }

  clearSelection() {
    this.clearSelectionClicked.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCompany) this.viewModel.connectToIsCompany(this.isCompany);
    if (!!changes.selectedLabel && this.scrollSelectionIntoView) {
      const element = this.inputs?.toArray()?.find(input => input?.nativeElement?.id?.includes(this.selectedLabel?.id));
      element?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

}
