import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';
import { EditMenuSectionViewModel } from '../../../viewModels/edit-menu-section-view-model';
import { ModalAddProducts } from '../../../../../modals/modal-add-products';

@Component({
  selector: 'app-edit-menu-section-products',
  templateUrl: './edit-menu-section-products.component.html',
  styleUrls: ['./edit-menu-section-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionProductsComponent extends BaseComponent {

  constructor(
    public viewModel: EditMenuSectionViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;

  openAddProductsModal() {
    const continueOperation = (variantIds: string[]) => this.viewModel.addVariantsToSection(variantIds);
    const resultOperation = () => {};
    combineLatest([
      this.viewModel.menu$,
      this.viewModel.section$
    ]).once(([menu, section]) => {
      ModalAddProducts.open(this.ngbModal, this.injector, menu, section, continueOperation, resultOperation);
    });
  }

  promptForRemoveAllProducts() {
    this.viewModel.section$.once(section => {
      const templatedSection = section?.isTemplatedSection();
      const opts = new ConfirmationOptions();
      opts.title = templatedSection
        ? 'Remove All Additional Products'
        : 'Remove All Products';
      opts.bodyText = templatedSection
        ? `Are you sure you want to remove all additional products from this section?`
        : `Are you sure you want to remove all products from this section?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Remove';
      const confirmation = (cont) => {
        if (cont) {
          this.viewModel.removeAllSectionProducts();
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

}
