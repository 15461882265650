<lib-reactive-form-group #form [mergeKey]="mergeKey" [bindTo]="bindTo">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'displayName'"
      [label]="'Display Name'"
      [placeholder]="'Enter the display name'"
      [inlineLabel]="false"
      [required]="true"
      [maxLength]="128"
      [disabled]="false"
      [bindingProperty]="'name'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      [inputName]="'displaySize'"
      [label]="'Display Size'"
      [placeholder]="'Select the size of this display'"
      [inlineLabel]="false"
      [required]="false"
      [dropdowns]="displaySizeDropDowns$ | async"
      [bindingProperty]="'displaySize.name'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      [inputName]="'orientation'"
      [label]="'Orientation'"
      [placeholder]="'Select the orientation of your display'"
      [inlineLabel]="false"
      [required]="true"
      [dropdowns]="orientationDropDowns$ | async"
      [bindingProperty]="'displaySize.orientation'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
