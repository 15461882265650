import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { AnimatorService } from '../../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { TemplatesContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/templates-content-container-view-model';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { switchMap } from 'rxjs/operators';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';

// Provided by Logged In Scope
@Injectable()
export class DigitalTemplatesContainerViewModel extends TemplatesContentContainerViewModel {

  constructor(
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    userDomainModel: UserDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute
  ) {
    super(
      templateCollectionDomainModel,
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  /**
   * This needs an override, because the digital templates view also contains the "template collections" tab.
   * The collections tab is different from the rest, and doesn't show individual templates. It shows collections
   * of templates. Collections are a group of templates that are meant to be used together.
   *
   * In the future, I think the left side panel should have a separate item for collections, and template collections
   * should be removed as a tab.
   */
  public override readonly tags$ = this.selectedTab$.pipe(
    switchMap(selectedTab => {
      switch (selectedTab) {
        case 0:
        case 1: return this.templateDomainModel.getMenuTemplateTagsFor(this.getMenuTypeFromSelectedTab(selectedTab));
        case 2: return this.templateCollectionDomainModel.existingCollectionTags$;
      }
    })
  );

  getMenuTypeFromSelectedTab(selectedTab: number): MenuType {
    switch (selectedTab) {
      case 0: return MenuType.DisplayMenu;
      case 1: return MenuType.MarketingMenu;
    }
  }

}
