import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class PosProductCategory implements Deserializable, UniquelyIdentifiable {

  public categoryId: string;
  public categoryName: string;
  public parentCategoryId: string;
  public parentCategoryName: string;
  public treeId: string;
  public productType: string;
  public variantType: string;

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `${this.categoryId}
      -${this.categoryName}
      -${this.parentCategoryId}
      -${this.parentCategoryName}
      -${this.treeId}
      -${this.productType}
      -${this.variantType}`;
  }

}
