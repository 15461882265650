import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DropDownItem } from '../../../../../../models/shared/stylesheet/drop-down-item';
import { map } from 'rxjs/operators';
import { EditMediaSectionFormViewModel } from '../edit-media-section-form/edit-media-section-form-view-model';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';

@Injectable()
export class EditTitleSectionFormViewModel extends EditMediaSectionFormViewModel {

  private _objectFit = new BehaviorSubject<string>(null);
  public readonly objectFit$ = this._objectFit as Observable<string>;
  connectToObjectFit = (objectFit: string) => this._objectFit.next(objectFit);

  public titleAlignments$ = of([
    new DropDownItem('Left', 'left'),
    new DropDownItem('Center', 'center'),
    new DropDownItem('Right', 'right')
  ]);

  public supportsImageUpload$ = this.menu$.pipe(
    map(menu => menu?.hydratedTheme?.themeFeatures?.titleSectionBackgroundImage)
  );

  public supportsSubtitle$ = this.menu$.pipe(
    map(menu => menu?.hydratedTheme?.themeFeatures?.titleSectionSubtitle)
  );

  public titleAlignmentToolTip$ = this.supportsSubtitle$.pipe(
    map(supportSubtitle => {
      return `Determines where the title${supportSubtitle ? 's' : ''} should be horizontally aligned.`;
    })
  );

  public readonly disableImageWidth$ = this.objectFit$.pipe(
    map(objectFit => objectFit?.toLowerCase() === 'contain')
  );

  public readonly setBackToFullPercentage$ = this.disableImageWidth$.pipe(
    map(disableImageWidth => (disableImageWidth ? 100 : undefined)),
  );

  override connectToSection(section: HydratedSection) {
    super.connectToSection(section);
    this.connectToObjectFit(section?.metadata?.objectFit);
  }

}
