import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '../../../../../../models/company/dto/location';
import { LocationDisplayGrouping } from '../../../../../../models/display/dto/display';

@Injectable()
export class EditCollectionLocationListItemViewModel extends BaseViewModel {

  private _locationArray = new BehaviorSubject<Location[]>([]);
  public locationArray$ = this._locationArray as Observable<Location[]>;
  connectToLocationArray = (locationDisplayGroupings: LocationDisplayGrouping[]) => {
    const locations = locationDisplayGroupings?.map((ld) => ld?.location);
    this._locationArray.next(locations);
  };

}
