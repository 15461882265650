import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { EditTemplateMarketingMenuFooterViewModel } from './edit-template-marketing-menu-footer-view-model';

@Component({
  selector: 'app-edit-template-marketing-menu-footer',
  templateUrl: './edit-template-marketing-menu-footer.component.html',
  styleUrls: [
    './edit-template-marketing-menu-footer.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditTemplateMarketingMenuFooterViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingMenuFooterComponent extends BaseComponent {

  constructor(
    public viewModel: EditTemplateMarketingMenuFooterViewModel
  ) {
    super();
  }

  @Input() template: MenuTemplate;
  @Input() allowLiveView: boolean = true;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() canDeleteTemplateMenu: boolean = true;
  @Input() lastSavedTimeText: string = '';
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() deleteTemplate: () => void;
  @Input() openDuplicateTemplateModal: () => void;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  // emit true if you want the form to auto submit in the background
  @Output() submitForms = new EventEmitter<boolean>(true);

}
