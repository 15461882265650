<div class="modal-body sticky">
  <app-collection-displays-modal-header
    [title]="'Edit Required Displays'"
    [stickyCollectionKey]="'edit-collection-displays'"
    [stickyZ]="9"
    [stickyOrder]="0"
    [showClearButton]="true"
    [disableClearButton]="!(viewModel.nSelectedDisplayIds$ | async)"
    (clear)="viewModel.connectToSelectedRequiredDisplayIds([])">
  </app-collection-displays-modal-header>

  <div
    class="sticky-body-container bg-white"
    appStickyAutoPosition
    [style.overflow-y]="'unset'"
    [stickyCollectionKey]="'edit-collection-displays'"
    [stickyZ]="9"
    [stickyOrder]="1">
    <div>
      <div class="existing-display-description">
        <p>
          Select which location displays the template collection should be <span class="bs-bold">required</span> at. The
          templated collection will automatically be added to all selected displays and cannot be deleted from that
          display.
        </p>
        <p>
          For displays that are not required, the user can add and remove the template collection at their location.
        </p>
        <hr />
      </div>
      <app-search-with-look-ahead
        [placeHolder]="'Search by display name'"
        [lookAheadItems]="viewModel.allDisplaysWithLocationInfo$ | async"
        [searchTheseProperties]="['name', 'locationName', 'locationState', 'locationAddress']"
        [disablePopper]="true"
        [nLookAheadItems]="10000"
        [outputAllItemsWhenSearchIsEmpty]="true"
        (lookAheadHits)="viewModel.connectToSearchedDisplays($event)">
      </app-search-with-look-ahead>
      <ng-container [ngSwitch]="(viewModel.searchedDisplays$ | async)?.length > 0">
        <div *ngSwitchCase="true" class="select-all-displays-container">
          <div fxLayout="row" fxLayoutAlign="start center">
            <app-group-selection
              #groupSelectionComponent
              [selection]="viewModel.displaysVisibleOnScreen$ | async"
              [selectedIds]="viewModel.selectedRequiredDisplayIds$ | async"
              (bulkAdd)="viewModel.addDisplayIds($event)"
              (bulkRemove)="viewModel.removeDisplayIds($event)">
            </app-group-selection>
            <div class="n-selected">
              <span *ngIf="viewModel.nSelectedDisplayIds$ | async as nSelected" class="checked-count">
                ({{ nSelected }})
              </span>
            </div>
          </div>
          <div class="select-all-locations-text">Select all displays</div>
        </div>
        <div *ngSwitchCase="false" class="pt-16px">No displays found.</div>
      </ng-container>
    </div>
  </div>
  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="modal-search-results-container">
      <mat-accordion multi [displayMode]="'flat'">
        <ng-container
          *ngFor="
            let province of (viewModel.allSearchedDisplaysGroupedByLocationAndProvince$ | async)?.provinces;
            trackBy: viewModel.trackByProvince
          ">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="panel-header">
              <div class="d-flex justify-content-between align-items-center">
                <app-group-selection
                  [selection]="province?.locations | getDisplaysForProvince | getDisplaysAsSelection"
                  [selectedIds]="viewModel.selectedRequiredDisplayIds$ | async"
                  (bulkAdd)="viewModel.addDisplayIds($event)"
                  (bulkRemove)="viewModel.removeDisplayIds($event)">
                </app-group-selection>
                <div class="n-selected">
                  <span
                    *ngIf="
                      viewModel.selectedRequiredDisplayIds$
                        | async
                        | getDisplayGroupSelectedCount : (province.locations | getDisplaysForProvince) as nSelected
                    "
                    class="checked-count ml-4px">
                    ({{ nSelected }})
                  </span>
                </div>
                <div class="province-name">
                  {{ province?.name }}
                </div>
              </div>
            </mat-expansion-panel-header>
            <app-location-display-selection-group
              *ngFor="let locationGrouping of province?.locations; trackBy: viewModel.trackByLocation"
              [selectedIds]="viewModel.selectedRequiredDisplayIds$ | async"
              [locationGrouping]="locationGrouping"
              (addDisplays)="viewModel.addDisplayIds($event)"
              (removeDisplays)="viewModel.removeDisplayIds($event)">
            </app-location-display-selection-group>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </div>
</div>
<app-go-back-modal-footer
  [showPrimaryButton]="true"
  [primaryButtonText]="'Done'"
  [showBackButton]="container.startingAtStepZero$ | async"
  (goBack)="container.goBack()"
  (primaryButtonClicked)="viewModel.updateRequiredDisplays()"
  (cancel)="cancel.emit()">
</app-go-back-modal-footer>
