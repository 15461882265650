import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseProductCardComponent } from '../base-product-card/base-product-card.component';
import { FullImageCardViewModel } from './full-image-card-view-model';

@Component({
  selector: 'app-full-image-card',
  templateUrl: './full-image-card.component.html',
  styleUrls: [
    './full-image-card.component.scss',
    '../base-product-card/base-card.component.scss'
  ],
  providers: [FullImageCardViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullImageCardComponent extends BaseProductCardComponent {

  constructor(
    public override viewModel: FullImageCardViewModel
  ) {
    super(viewModel);
  }

}
