<div class="bs-bold f16px mt-24px">Print {{ stackType | titlecase }}s</div>
<lib-reactive-form-group [mergeKey]="viewModel.mergeKey$ | push" [bindTo]="viewModel.printConfig$ | push">
  <app-list-item-checkbox
    [label]="'Include out of stock products'"
    [inputName]="'includeZeroStockItems'"
    [disabled]="viewModel.viewOnly$ | push"
    [bindingProperty]="'includeZeroStockItems'">
  </app-list-item-checkbox>
  <ng-container *rxIf="!(viewModel.stackHasNoPreviousPrintJobs$ | push); else noPreviousPrintJobs">
    <div class="bs-medium f14px mt-24px mb-12px">Include products in your print job with the following changes:</div>
    <ng-container *rxLet="viewModel.tooltips$ as tooltips">
      <app-list-item-checkboxes
        [inputName]="'printOptionCheckboxes'"
        [hideBulkCheckbox]="true"
        [requireMinimumSelection]="(viewModel.viewOnly$ | push) ? 0 : 1">
        <app-list-item-checkbox
          *rxIf="viewModel.showNewProductsCheckbox$"
          [label]="'New Products'"
          [inputName]="'newProductChange'"
          [disabled]="viewModel.disableNewProductChangeCheckbox$ | push"
          [disabledTooltip]="viewModel.viewOnly$ | push | newProductChangeCheckboxDisabledTooltip"
          [tooltip]="tooltips.newProductChange"
          [programmaticallyChangeValue]="viewModel.selectNewProductChangeCheckbox$ | push"
          [bindingProperty]="'newProductChange'">
        </app-list-item-checkbox>

        <app-list-item-checkbox
          *rxIf="viewModel.showInventoryRestockedCheckbox$"
          [label]="'Inventory Restocked'"
          [inputName]="'inventoryRestock'"
          [disabled]="viewModel.viewOnly$ | push"
          [tooltip]="tooltips.inventoryRestock"
          [bindingProperty]="'inventoryRestock'"
          (valueChanged)="viewModel.handleNewProductStateDeterminantChanged('inventoryRestock', $event)">
        </app-list-item-checkbox>

        <app-list-item-checkbox
          *rxIf="viewModel.showPriceChangeCheckbox$"
          [label]="'Price Changes'"
          [inputName]="'priceChange'"
          [disabled]="viewModel.viewOnly$ | push"
          [tooltip]="tooltips.priceChange"
          [bindingProperty]="'priceChange'"
          (valueChanged)="viewModel.handleNewProductStateDeterminantChanged('priceChange', $event)">
        </app-list-item-checkbox>

        <app-list-item-checkbox
          *rxIf="viewModel.showProductInfoChangeCheckbox$"
          [label]="'Product Info Changes'"
          [inputName]="'productInfoChange'"
          [disabled]="viewModel.viewOnly$ | push"
          [tooltip]="tooltips.productInfoChange"
          [bindingProperty]="'productInfoChange'"
          (valueChanged)="viewModel.handleNewProductStateDeterminantChanged('productInfoChange', $event)">
        </app-list-item-checkbox>

        <app-list-item-checkbox
          *rxIf="viewModel.showCannabinoidChangeCheckbox$"
          [label]="'Cannabinoid Changes'"
          [inputName]="'cannabinoidChange'"
          [disabled]="viewModel.viewOnly$ | push"
          [tooltip]="tooltips.cannabinoidChange"
          [bindingProperty]="'cannabinoidChange'"
          (valueChanged)="viewModel.handleNewProductStateDeterminantChanged('cannabinoidChange', $event)">
        </app-list-item-checkbox>

        <app-list-item-checkbox
          *rxIf="viewModel.showCustomizationChangeCheckbox$"
          [label]="'Badge/Label Changes'"
          [inputName]="'customizationChange'"
          [disabled]="viewModel.viewOnly$ | push"
          [tooltip]="tooltips.customizationChange"
          [bindingProperty]="'customizationChange'"
          (valueChanged)="viewModel.handleNewProductStateDeterminantChanged('customizationChange', $event)">
        </app-list-item-checkbox>

        <app-list-item-checkbox
          *rxIf="viewModel.showMenuChangeCheckbox$"
          [label]="'Menu Changes'"
          [inputName]="'menuChange'"
          [disabled]="viewModel.viewOnly$ | push"
          [tooltip]="tooltips.menuChange"
          [bindingProperty]="'menuChange'"
          (valueChanged)="viewModel.handleNewProductStateDeterminantChanged('menuChange', $event)">
        </app-list-item-checkbox>
      </app-list-item-checkboxes>

      <ng-container *rxIf="!(viewModel.hidePreviousStackPrintJobs$ | push)">
        <div class="bs-bold f16px mt-24px mb-16px">
          Include changes since previous print job
          <img
            *rxIf="!(viewModel.viewOnly$ | push)"
            [ngbTooltip]="viewModel.previousPrintJobsTooltip$ | push"
            class="ml-8px"
            [style.height.rem]="1"
            src="assets/icons/dark/outline/information-circle.svg" />
        </div>
        <lib-reactive-form-drop-down
          [required]="true"
          [inputName]="'includeChangesSince'"
          [hideLabel]="true"
          [disabled]="viewModel.viewOnly$ | push"
          [dropdowns]="viewModel.previousSuccessfulStackPrintJobs$ | push"
          [programmaticallyChangeValue]="viewModel.preSelectedPreviousStackPrintJobId$ | push"
          [bindingProperty]="'includeChangesSince'">
        </lib-reactive-form-drop-down>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noPreviousPrintJobs>
    <div class="pt-32px pb-32px d-flex justify-content-center f14px bs-medium">
      All {{ stackType }}s will be printed if there isn't a previous smart print job.
    </div>
  </ng-template>
</lib-reactive-form-group>
