import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Product } from '../../../../../../../models/product/dto/product';
import { map } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { Section } from '../../../../../../../models/menu/dto/section';
import { Variant } from '../../../../../../../models/product/dto/variant';
import { LabelUtils } from '../../../../../../../modules/product-labels/utils/label-utils';

@Injectable()
export class VariantGroupingHeaderViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public locationConfig$ = this.locationDomainModel.locationConfig$;

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  public connectToMenu = (menu: Menu) => this._menu.next(menu);

  public _section = new BehaviorSubject<Section>(null);
  public section$ = this._section as Observable<Section>;
  public connectToSection = (section: Section) => this._section.next(section);

  private _product = new BehaviorSubject<Product>(null);
  public productTitle$ = this._product.pipe(map(p => p.getProductTitle()));
  public connectToProduct = (product: Product) => this._product.next(product);

  private _variants = new BehaviorSubject<Variant[]>(null);
  public variants$ = this._variants as Observable<Variant[]>;
  public connectToVariants = (variants: Variant[]) => this._variants.next(variants);

  public computeLabelInterface$ = combineLatest([
    this.menu$,
    this.section$,
    this.variants$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
   map(([menu, section, variants, locConfig, companyConfig]) => {
     return LabelUtils.getComputeLabelInterfaceWithinMenuContext(menu, section, variants, locConfig, companyConfig);
   })
  );

}
