import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { EditProductMenuViewModel } from '../../../../../viewModels/edit-product-menu-view-model';

@Component({
  selector: 'app-print-menu-section-details-actions',
  templateUrl: './edit-print-menu-section-details-actions.component.html',
  styleUrls: ['./edit-print-menu-section-details-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPrintMenuSectionDetailsActionsComponent extends BaseComponent {

  constructor(
    public viewModel: EditProductMenuViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;

}
