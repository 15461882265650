import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Input, OnInit, ViewRef } from '@angular/core';
import { ReactiveTableRowBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { DateUtils } from '../../../../../../../../utils/date-utils';
import { BulkPrintJobItemViewModel } from './bulk-print-job-item-view-model';
import { MenuPreviewJobStatus } from '../../../../../../../../models/utils/dto/menu-preview-job-status-type';

@Component({
  selector: 'app-bulk-print-jobs-table-item',
  templateUrl: './bulk-print-jobs-table-item.component.html',
  styleUrls: ['./bulk-print-jobs-table-item.component.scss'],
  providers: [
    {
      provide: BulkPrintJobItemViewModel
    },
    {
      provide: ReactiveTableRowBluePrintComponent,
      useExisting: forwardRef(() => BulkPrintJobsTableItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkPrintJobsTableItemComponent extends ReactiveTableRowBluePrintComponent implements OnInit {

  constructor(
    public viewModel: BulkPrintJobItemViewModel,
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    changeDetector: ChangeDetectorRef
  ) {
    super(viewRef, changeDetector);
  }

  @Input() override rowData: BulkPrintJob;
  public MenuPreviewJobStatus: typeof MenuPreviewJobStatus = MenuPreviewJobStatus;
  public DateUtils: typeof DateUtils = DateUtils;

  ngOnInit(): void {
    this.viewModel.connectToRowData(this.rowData);
  }

  rowDataChanged(job: BulkPrintJob): void {
    this.viewModel.connectToRowData(job);
  }

}
