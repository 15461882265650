import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllDisplaysComponent } from './components/all-displays/all-displays.component';
import { EditDisplayComponent } from './components/edit-display/edit-display.component';
import { CanDeactivateGuard } from '../../services/guards/can-deactivate.guard';
import { AuthGuard } from '../../services/guards/auth.guard';
import { PermissionGuard } from '../../services/guards/permission.guard';

const routes: Routes = [
  {
    path: '',
    component: AllDisplaysComponent,
    data: {
      title: 'Displays'
    },
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: ':displayId',
    component: EditDisplayComponent,
    data: {
      title: 'Edit Display'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisplayRoutingModule {
}
