import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ScheduledEmail } from '../models/automation/scheduled-email';
import { CompanyDomainModel } from './company-domain-model';
import { map, tap } from 'rxjs/operators';
import { AutomationAPI } from '../api/automation-api';
import { Location } from '../models/company/dto/location';
import { Company } from '../models/company/dto/company';
import { UserDomainModel } from './user-domain-model';

// Provided by Logged In Scope
@Injectable()
export class ScheduledMenusDomainModel extends BaseDomainModel {

  constructor(
    private automationAPI: AutomationAPI,
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
  ) {
    super();
  }

  private _scheduleEmail = new BehaviorSubject<ScheduledEmail>(null);
  public scheduledEmail$ = this._scheduleEmail.asObservable();

  public scheduledEmailRecipients$ = combineLatest([
    this.scheduledEmail$.pipe(map(schedule => schedule?.recipientIds || [])),
    this.userDomainModel.user$,
    this.companyDomainModel.employees$
  ]).pipe(
    map(([ids, user, employees]) => {
      return [user, ...(employees || [])]?.uniqueByProperty('userId')?.filter(e => ids?.contains(e.userId));
    })
  );

  getScheduledEmail(company: Company, location: Location): Observable<ScheduledEmail> {
    return this.automationAPI.GetScheduledMenuSending(company, location).pipe(
      map(scheduled => scheduled.firstOrNull() as ScheduledEmail),
      tap(sendSchedule => this._scheduleEmail.next(sendSchedule))
    );
  }

  updateScheduledEmail(scheduledEmail: ScheduledEmail): Observable<ScheduledEmail> {
    return this.automationAPI.UpdateScheduledEmail(scheduledEmail).pipe(
      tap(updated => this._scheduleEmail.next(updated))
    );
  }

}
