import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from '../edit-template-marketing-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingDriveThruMenuViewModel } from './edit-template-marketing-drive-thru-menu-view-model';
import { EditDriveThruMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class EditTemplateMarketingDriveThruMenuCombinedLoadingOptsViewModel
  extends EditTemplateMarketingMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingDriveThruMenuViewModel: EditTemplateMarketingDriveThruMenuViewModel,
    protected editDriveThruMenuViewModel: EditDriveThruMenuViewModel,
  ) {
    super(editTemplateMarketingDriveThruMenuViewModel, editDriveThruMenuViewModel);
  }

}
