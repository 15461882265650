import { LabelViewModel } from '../label/label-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FlagLabelViewModel extends LabelViewModel {

  private _flagHeight = new BehaviorSubject<number>(24.1895);
  public flagHeight$ = this._flagHeight as Observable<number>;
  public connectToFlagHeight = (height: number) => this._flagHeight.next(height);

  public triangleHeight$ = this.flagHeight$.pipe(
    map(height => Math.ceil(height / 2) + 1)
  );
  public flagTriangleHeight$ = combineLatest([
    this.triangleHeight$,
    this.labelBackgroundColor$
  ]).pipe(
    map(([triangleHeight, color]) => {
      return `${Math.ceil(triangleHeight)}px solid ${color}`;
    }),
  );

  public triangleWidth$ = this.flagHeight$.pipe(
    map(height => 0.35 * height)
  );
  public flagTriangleWidth$ = this.triangleWidth$.pipe(
    map(width => `${width}px solid transparent`),
  );

}
