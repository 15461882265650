import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { MaterialModule } from './material/material.module';
import { TestTabComponent } from './components/tab-bar/test-tab/test-tab.component';
import { TabContentComponent } from './components/tab-bar/tab-content/tab-content.component';
import { TabContentContainerDirective } from './components/tab-bar/tab-content/tab-content-container.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PillComponent } from './components/pills/pill/pill.component';
import { PillContainerComponent } from './components/pills/pill-container/pill-container.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { LetterOnlyValidatorDirective } from './components/form-group/validators/letter-only-validator.directive';
import { PasswordValidatorDirective } from './components/form-group/validators/password-validator.directive';
import { CheckboxContainerComponent } from './components/checkboxes/checkbox-container/checkbox-container.component';
import { CheckboxComponent } from './components/checkboxes/checkbox/checkbox.component';
import { DropDownMenuComponent } from './components/drop-down-menu/drop-down-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PhoneValidatorDirective } from './components/form-group/validators/phone-validator.directive';
import { AbandonFormComponent } from './modals/abandon-form/abandon-form.component';
import { LocationPickerComponent } from './modals/location-picker/location-picker.component';
import { CardDeckComponent } from './components/card-deck/card-deck.component';
import { CardComponent } from './components/card-deck/card/card.component';
import { ConfirmationCodeValidatorDirective } from './components/form-group/validators/confirmation-code-validator.directive';
import { UploadItemComponent } from './components/upload-asset/upload-item/upload-item.component';
import { UploadAssetComponent } from './components/upload-asset/upload-asset.component';
import { DndDirective } from './directives/dnd-directive';
import { HeaderComponent } from './components/header/header.component';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IncompleteProductCardComponent } from './components/card-deck/incomplete-product-card/incomplete-product-card.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { CardTableComponent } from './components/card-table/card-table.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CompanyBadgeComponent } from './components/company-badge/company-badge.component';
import { LabelGroupComponent } from './components/label-group/label-group.component';
import { LocationCardComponent } from './components/card-deck/location-card/location-card.component';
import { QuickActionCardComponent } from './components/card-deck/quick-action-card/quick-action-card.component';
import { ReorderModalComponent } from './components/reorder-modal/reorder-modal.component';
import { ReorderListComponent } from './components/reorder-list/reorder-list.component';
import { ActionListComponent } from './components/action-list/action-list.component';
import { ActionComponent } from './components/action-list/action/action.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SingleSelectionListComponent } from './components/single-selection-list/single-selection-list.component';
import { AssetPreviewComponent } from './components/asset-preview/asset-preview.component';
import { SegmentedControlComponent } from './components/segmented-control/segmented-control.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { ThemePreviewCardComponent } from './components/card-deck/theme-preview-card/theme-preview-card.component';
import { ThemeCarouselImageComponent } from './components/card-deck/theme-carousel-image/theme-carousel-image.component';
import { InformationModalComponent } from './modals/information-modal/information-modal.component';
import { AssetComponent } from './components/asset/asset.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { ParseColorPipe } from './components/form-group/pipes/parse-color.pipe';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PlusButtonComponent } from './components/plus-button/plus-button.component';
import { RemoveButtonComponent } from './components/remove-button/remove-button.component';
import { ThrottleClickDirective } from './directives/throttle-click.directive';
import { VariantBadgeComponent } from './components/variant-badge/variant-badge.component';
import { VariantBadgesComponent } from './components/variant-badges/variant-badges.component';
import { BadgePickerComponent } from './components/badge-picker/badge-picker.component';
import { InfoBannerComponent } from './components/banners/info-banner/info-banner.component';
import { WarningBannerComponent } from './components/banners/warning-banner/warning-banner.component';
import { NoteTextComponent } from './components/note-text/note-text.component';
import { TextLineDividerComponent } from './components/text-line-divider/text-line-divider.component';
import { ArrowButtonComponent } from './components/arrow-button/arrow-button.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UserPickerComponent } from './modals/user-picker/user-picker.component';
import { SearchWithLookAheadComponent } from './components/search-with-look-ahead/search-with-look-ahead.component';
import { LookAheadListComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-list.component';
import { EmployeeListItemComponent } from './components/employee-list-item/employee-list-item.component';
import { LookAheadItemComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-item/look-ahead-item.component';
import { MessageBannerComponent } from './components/banners/message-banner/message-banner.component';
import { SelectVariantsModalComponent } from './components/select-variants-modal/select-variants-modal.component';
import { LightboxModalComponent } from './components/lightbox-modal/lightbox-modal.component';
import { CheckboxOnlyComponent } from './components/checkboxes/checkbox-only/checkbox-only.component';
import { DayOfWeekPipe } from './components/form-group/pipes/day-of-week.pipe';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { GroupSelectionComponent } from './components/group-selection/group-selection.component';
import { SelectableComponent } from './components/group-selection/selectable/selectable.component';
import { AddDollarSignToPricePipe } from './pipes/add-dollar-sign-to-price.pipe';
import { ToFixedDecimalPipe } from './pipes/to-fixed-decimal.pipe';
import { VariantPricePipe } from './pipes/variant-price.pipe';
import { VariantFormattedSizingPipe } from './pipes/variant-formatted-sizing.pipe';
import { IsVariantOnSalePipe } from './pipes/is-variant-on-sale.pipe';
import { VariantOriginalPricePipe } from './pipes/variant-original-price.pipe';
import { CreateViewBulkPrintJobComponent } from './modals/create-view-bulk-print-job/create-view-bulk-print-job.component';
import { PrintJobMenuSelectionComponent } from './modals/create-view-bulk-print-job/print-job-menu-selection/print-job-menu-selection.component';
import { LotInfoBannerComponent } from './components/banners/lot-info-banner/lot-info-banner.component';
import { ReferAFriendWidgetComponent } from './components/refer-a-friend-widget/refer-a-friend-widget.component';
import { ReferAFriendModalComponent } from './components/refer-a-friend-widget/refer-a-friend-modal/refer-a-friend-modal.component';
import { ErrorBannerComponent } from './components/banners/error-banner/error-banner.component';
import { ResetColumnOptionsComponent } from './components/reset-column-options/reset-column-options.component';
import { DefaultColumnOptionsPickerComponent } from './components/default-column-options-picker/default-column-options-picker.component';
import { PopperSelectionComponent } from './components/popper-selection/popper-selection.component';
import { ExistsPipe } from './pipes/exists.pipe';
import { LocationComponent } from './components/location/location.component';
import { DateRangeFromNowComponent } from './components/date-range-going-back-from-now/date-range-from-now.component';
import { FilterProductsFormComponent } from './components/filter-products-form/filter-products-form.component';
import { SecondsToMillisecondsPipe } from './pipes/seconds-to-milliseconds.pipe';
import { InlineBadgePickerComponent } from './components/inline-badge-picker/inline-badge-picker.component';
import { InlinePickerActionButtonComponent } from './components/inline-picker-action-button/inline-picker-action-button.component';
import { FakeCheckBoxComponent } from './components/fake-check-box/fake-check-box.component';
import { DebouncePipe } from './pipes/debounce.pipe';
import { LogPipe } from './pipes/log.pipe';
import { BadgeWithinPickerComponent } from './components/badge-picker/badge-within-picker/badge-within-picker.component';
import { DisplayNameComponent } from './components/display-name/display-name.component';
import { PrivateThemeIndicatorComponent } from './components/card-deck/private-theme-indicator/private-theme-indicator.component';
import { OverflowInfoModalComponent } from './modals/overflow-info-modal/overflow-info-modal.component';
import { OverflowVideoComponent } from './modals/overflow-info-modal/overflow-video/overflow-video.component';
import { LabelInterfaceForLabelPickerPipe } from './pipes/label-interface-for-label-picker.pipe';
import { StickyAutoPositionDirective } from './directives/sticky-auto-position.directive';
import { BadgePickerForFormComponent } from './components/badge-picker-for-form/badge-picker-for-form.component';
import { SegmentedControlForFormComponent } from './components/segmented-control-for-form/segmented-control-for-form.component';
import { BadgeAddedBySmartFilterIndicatorComponent } from './components/smart-filters-indicator/badge-added-by-smart-filter-indicator/badge-added-by-smart-filter-indicator.component';
import { SmartFiltersIndicatorComponent } from './components/smart-filters-indicator/smart-filters-indicator.component';
import { InlineBadgePickerCheckIfBadgeIsSmartPipe } from './components/inline-badge-picker/inline-badge-picker-check-if-badge-is-smart.pipe';
import { TitleAndCrumbsComponent } from './components/title-and-crumbs/title-and-crumbs.component';
import { HeaderSubtextComponent } from './components/header-subtext/header-subtext.component';
import { HeaderTitleTextComponent } from './components/header-title-text/header-title-text.component';
import { MenuTypePickerComponent } from './components/menu-type-picker/menu-type-picker.component';
import { SearchableSingleSelectComponent } from './components/searchable-single-select/searchable-single-select.component';
import { ReplaceMenuWithTemplatePipe } from './pipes/replace-menu-with-template.pipe';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { DisplayableContentContainerComponent } from './components/displayable-content/displayable-content-container/displayable-content-container.component';
import { ReactiveTableModule } from '@mobilefirstdev/reactive-table';
import { DisplayableItemContainerComponent } from './components/displayable-content/displayable-item-container/displayable-item-container.component';
import { DisplayableItemPreviewComponent } from './components/displayable-content/displayable-item-container/displayable-item-preview/displayable-item-preview.component';
import { MenuPreviewComponent } from './components/displayable-content/displayable-item-container/menu-preview/menu-preview.component';
import { TemplatePreviewComponent } from './components/displayable-content/displayable-item-container/template-preview/template-preview.component';
import { SmartMenuIndicatorComponent } from './components/displayable-content/displayable-item-container/badges/smart-menu-indicator/smart-menu-indicator.component';
import { TemplateDeployedCountIndicatorComponent } from './components/displayable-content/displayable-item-container/badges/template-deployed-count-indicator/template-deployed-count-indicator.component';
import { ActiveComponent } from './components/displayable-content/displayable-item-container/badges/active/active.component';
import { IsMenuPipe } from './components/displayable-content/pipes/is-menu.pipe';
import { IsTemplatePipe } from './components/displayable-content/pipes/is-template.pipe';
import { IsCollectionPipe } from './components/displayable-content/pipes/is-collection.pipe';
import { ReplaceDisplayWithCollectionPipe } from './pipes/replace-display-with-collection.pipe';
import { CollectionPreviewComponent } from './components/displayable-content/displayable-item-container/collection-preview/collection-preview.component';
import { SingleSelectionGroupedListComponent } from './components/single-selection-grouped-list/single-selection-grouped-list.component';
import { CarouselButtonComponent } from './components/carousel-button/carousel-button.component';
import { ContinueIfCompactMenuPipe } from './pipes/is-compact-menu.pipe';
import { ContinueIfCompactTemplateCollectionPipe } from './pipes/is-compact-template-collection.pipe';
import { ReorderListItemComponent } from './components/reorder-list-item/reorder-list-item.component';
import { DisplayPreviewComponent } from './components/displayable-content/displayable-item-container/display-preview/display-preview.component';
import { TemplateCollectionIndicatorComponent } from './components/displayable-content/displayable-item-container/badges/template-collection-indicator/template-collection-indicator.component';
import { GoBackModalFooterComponent } from './components/go-back-modal-footer/go-back-modal-footer.component';
import { IsDisplayPipe } from './components/displayable-content/pipes/is-display.pipe';
import { EmptyArrayPipe } from './pipes/empty-array.pipe';
import { TemplatedMenuDisabledFormControlTooltipPipe } from './pipes/templated-menu-disabled-form-control-tooltip.pipe';
import { TemplatedMenuDisabledButtonTooltipPipe } from './pipes/templated-menu-disabled-button-tooltip.pipe';
import { TemplatedMenuDisabledOverrideTooltipPipe } from './pipes/templated-menu-disabled-override-tooltip.pipe';
import { CollectionDisabledFormControlTooltipPipe } from './pipes/collection-disabled-form-control-tooltip.pipe';
import { IncludesPipe } from './pipes/includes.pipe';
import { DuplicateButtonDisabledTooltipPipe } from './pipes/duplicate-button-disabled-tooltip.pipe';
import { FileNameWithoutTimestampAndExtensionPipe } from './pipes/file-name-without-timestamp-and-extension.pipe';
import { LoadingShimmerModule } from '@mobilefirstdev/loading-shimmer';
import { FileNameWithoutTimestampPipe } from './pipes/file-name-without-timestamp.pipe';
import { BadgePopoverComponent } from './components/badge-popover/badge-popover.component';
import { IsSmartBadgePipe } from './pipes/is-smart-badge.pipe';
import { CountBadgesPipe } from './pipes/count-badges.pipe';
import { UnixToDateTimePipe } from './pipes/unix-to-date-time.pipe';
import { GetFormErrorDisabledTooltipPipe } from './pipes/get-form-error-disabled-tooltip.pipe';
import { ReactivePaginationComponent, ReactiveSearchAndPaginationModule, ReactiveSearchBarComponent } from '@mobilefirstdev/reactive-search-and-pagination';
import { PageSectionComponent } from './components/page-section/page-section.component';
import { ColumnConfigKeyNamePipe } from './pipes/column-config-key-name.pipe';
import { DisplayableContentFilterFormComponent } from './components/displayable-content/displayable-content-filter-form/displayable-content-filter-form.component';
import { DisplayableContentLayoutComponent } from './components/displayable-content/displayable-content-layout-component/displayable-content-layout.component';
import { AsPipe } from './pipes/cast-to.pipe';
import { AsArrayOfPipe } from './pipes/as-array-of.pipe';
import { ReplaceMenuWithCardOrLabelPipe } from './pipes/replace-menu-with-card.pipe';
import { CardStackSizeCarouselComponent } from './components/card-deck/card-stack-size-carousel/card-stack-size-carousel.component';
import { CardStackSizeSelectionComponent } from './components/card-stack-size-selection/card-stack-size-selection.component';
import { GetStackDimensionsPipe } from './components/card-stack-size-selection/card-size-dimensions.pipe';
import { GetNameFromStackSizePipe } from './components/card-stack-size-selection/card-size-name.pipe';
import { GetImageSrcFromStackSizePipe } from './components/card-stack-size-selection/card-size-image-src.pipe';
import { ReplaceMenuWithStackPipe } from './pipes/replace-menu-with-stack.pipe';
import { ReplaceMenuWithCardStackPipe } from './pipes/replace-menu-with-card-stack.pipe';
import { PrintJobHeaderComponent } from './modals/create-view-bulk-print-job/print-job-header/print-job-header.component';
import { RxPush } from '@rx-angular/template/push';
import { RxLet } from '@rx-angular/template/let';
import { PrintJobNameFormComponent } from './modals/create-view-bulk-print-job/print-job-name-form/print-job-name-form.component';
import { PrintJobEmployeeFormComponent } from './modals/create-view-bulk-print-job/print-job-employee-form/print-job-employee-form.component';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { PrintJobMenuFormComponent } from './modals/create-view-bulk-print-job/print-job-menu-form/print-job-menu-form.component';
import { HiddenArrayFormInputComponent } from './forms/hidden-array-form-input/hidden-array-form-input.component';
import { IsCardStackMenuPipe } from './components/displayable-content/pipes/is-card-stack-menu.pipe';
import { CardStackPreviewComponent } from './components/displayable-content/displayable-item-container/card-stack-preview/card-stack-preview.component';
import { DisplayItemGetLoadingFromBundlePipe } from './components/displayable-content/displayable-item-container/displayable-item-preview/display-item-get-loading-from-bundle.pipe';
import { LoadPreviewPipe } from './components/displayable-content/displayable-item-container/displayable-item-preview/load-preview.pipe';
import { GetVariantTitlePipe } from './pipes/get-variant-title.pipe';
import { GetProductTitlePipe } from './pipes/get-product-title.pipe';
import { CardStackTemplatePreviewComponent } from './components/displayable-content/displayable-item-container/card-stack-template-preview/card-stack-template-preview.component';
import { IsCardStackMenuTemplatePipe } from './components/displayable-content/pipes/is-card-stack-menu-template.pipe';
import { ChooseBulkPrintJobTypeComponent } from './modals/create-view-bulk-print-job/choose-bulk-print-job-type/choose-bulk-print-job-type.component';
import { PrintMenuBulkPrintJobComponent } from './modals/create-view-bulk-print-job/print-menu-bulk-print-job/print-menu-bulk-print-job.component';
import { ChooseStackPrintTypeComponent } from './modals/create-view-bulk-print-job/choose-stack-print-job-option/choose-stack-print-type.component';
import { StackBulkPrintJobComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-bulk-print-job.component';
import { PrintJobSelectStacksComponent } from './modals/create-view-bulk-print-job/print-job-select-stacks/print-job-select-stacks.component';
import { PrintJobSelectMenusComponent } from './modals/create-view-bulk-print-job/print-job-select-menus/print-job-select-menus.component';
import { StackSmartBulkPrintJobComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-job.component';
import { StackSmartBulkPrintJobSelectedStacksComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-job-selected-stacks/stack-smart-bulk-print-job-selected-stacks.component';
import { StackSmartBulkPrintOptionsFormComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-options-form/stack-smart-bulk-print-options-form.component';
import { ListItemCheckboxesComponent } from './forms/list-item-checkboxes/list-item-checkboxes.component';
import { ListItemCheckboxComponent } from './forms/list-item-checkbox/list-item-checkbox.component';
import { StackSmartBulkPrintConfigFormComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-config-form/stack-smart-bulk-print-config-form.component';
import { PrintConfigMapFormItemComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-config-form/print-config-map-form-item/print-config-map-form-item.component';
import { StackSmartBulkPrintPreviousJobsFormComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-previous-jobs-form/stack-smart-bulk-print-previous-jobs-form.component';
import { MenuPrintJobsPipe } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-previous-jobs-form/menu-print-jobs.pipe';
import { PreSelectedPreviousJobIdPipe } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-previous-jobs-form/pre-selected-previous-job.pipe';
import { StackManualBulkPrintJobComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job.component';
import { StackManualBulkPrintJobMenuListComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-menu-list/stack-manual-bulk-print-job-menu-list.component';
import { StackManualBulkPrintJobMenuListItemComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-menu-list/stack-manual-bulk-print-job-menu-list-item/stack-manual-bulk-print-job-menu-list-item.component';
import { FirstSectionNVariantsPipe } from './pipes/first-section-n-variants.pipe';
import { IsSelectedIdPipe } from './pipes/is-selected-id.pipe';
import { StackManualBulkPrintJobLeftPaneComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-left-pane/stack-manual-bulk-print-job-left-pane.component';
import { StackManualBulkPrintJobRightPaneComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-right-pane/stack-manual-bulk-print-job-right-pane.component';
import { CardStackManualBulkPrintJobSelectableVariantComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-right-pane/card-stack-manual-bulk-print-job-selectable-variant/card-stack-manual-bulk-print-job-selectable-variant.component';
import { CardStackManualBulkPrintJobSelectAllVariantsComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-right-pane/card-stack-manual-bulk-print-job-select-all-variants/card-stack-manual-bulk-print-job-select-all-variants.component';
import { GetStackVariantErrorTooltipPipe } from './pipes/get-card-stack-variant-error-tooltip.pipe';
import { BulkCardStackPrintJobAppliedProductsComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/bulk-card-stack-print-job-applied-products/bulk-card-stack-print-job-applied-products.component';
import { BulkCardStackPrintJobAppliedProductsAccordionComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/bulk-card-stack-print-job-applied-products/bulk-card-stack-print-job-applied-products-accordion/bulk-card-stack-print-job-applied-products-accordion.component';
import { MenuFromIdPipe } from './pipes/menu-from-id.pipe';
import { StackAppliedProductListItemComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/bulk-card-stack-print-job-applied-products/stack-applied-product-list-item/stack-applied-product-list-item.component';
import { VariantFromIdPipe } from './pipes/variant-from-id.pipe';
import { NewProductChangeCheckboxDisabledPipe } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-options-form/new-product-change-checkbox-disabled.pipe';
import { NewProductChangeCheckboxDisabledTooltipPipe } from './pipes/new-product-change-checkbox-disabled-tooltip.pipe';
import { LoadingClockComponent } from './components/loading-clock/loading-clock.component';
import { CardSizeImageWidthInRemPipe } from './components/card-stack-size-selection/card-size-image-width.pipe';
import { PrintJobMenuSelectionIconSrcPipe } from './modals/create-view-bulk-print-job/print-job-menu-selection/print-job-menu-selection-icon-src.pipe';
import { IsPrintReportMenuPipe } from './pipes/is-print-report-menu.pipe';
import { StackPrintJobIncludeChangesSincePipe } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-previous-jobs-form/stack-print-job-include-changes-since.pipe';
import { AllStackCardsPrintedComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-smart-bulk-print-job/stack-smart-bulk-print-previous-jobs-form/no-previous-jobs/all-stack-cards-printed.component';
import { DateTimeStringIfUnixStringElseOriginalPipe } from './pipes/date-time-string-if-unix-string-else-original.pipe';
import { ConstructCardStackMenuIfOriginalDeletedPipe } from './modals/create-view-bulk-print-job/stack-bulk-print-job/bulk-card-stack-print-job-applied-products/stack-applied-product-list-item/construct-card-stack-menu-if-original-deleted.pipe';
import { LastRestockedInSecondsPipe } from './pipes/last-restock-time.pipe';
import { GetDisplayNamePipe } from './pipes/get-product-display-name.pipe';
import { ProductFromIdPipe } from './pipes/product-from-id.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { InventoryRoomsUsePurposePipe } from './pipes/inventory-rooms-use-purpose';
import { NotAnAdminTooltipPipe } from './pipes/not-an-admin-tooltip.pipe';
import { UnixToDatePipe } from './pipes/unix-to-date.pipe';
import { DashIfEmptyPipe } from './pipes/dash-if-empty.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { IsLabelStackMenuPipe } from './components/displayable-content/pipes/is-label-stack-menu.pipe';
import { LabelStackPreviewComponent } from './components/displayable-content/displayable-item-container/label-stack-preview/label-stack-preview.component';
import { ReactiveFormIncrementorComponent } from './forms/reactive-form-incrementor/reactive-form-incrementor.component';
import { IsLabelStackMenuTemplatePipe } from './components/displayable-content/pipes/is-label-stack-menu-template.pipe';
import { LabelStackTemplatePreviewComponent } from './components/displayable-content/displayable-item-container/label-stack-template-preview/label-stack-template-preview.component';
import { ContainsStackedContentPipe } from './pipes/contains-stacked-content.pipe';
import { HiddenMapFormInputComponent } from './forms/hidden-map-form-input/hidden-map-form-input.component';
import { ShoppingInfoBannerComponent } from './components/banners/shopping-info-banner/shopping-info-banner.component';
import { NSelectedVariantsPipe } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-menu-list/n-selected-variants.pipe';
import { LabelStackManualBulkPrintJobSelectAllVariantsComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-right-pane/label-stack-manual-bulk-print-job-select-all-variants/label-stack-manual-bulk-print-job-select-all-variants.component';
import { LabelIncludeOrRemoveAllPipe } from './pipes/label-include-or-remove-all.pipe';
import { NLabelsSelectedPipe } from './pipes/n-labels-selected.pipe';
import { GetInitialValueFromMapPipe } from './components/label-stack-print-job-select-variant-count/get-initial-value-from-map.pipe';
import { LabelStackPrintJobSelectVariantCountComponent } from './components/label-stack-print-job-select-variant-count/label-stack-print-job-select-variant-count.component';
import { IncrementorComponent } from './components/incrementor/incrementor.component';
import { LabelStackManualBulkPrintJobSelectableVariantComponent } from './modals/create-view-bulk-print-job/stack-bulk-print-job/stack-manual-bulk-print-job/stack-manual-bulk-print-job-right-pane/label-stack-manual-bulk-print-job-selectable-variant/label-stack-manual-bulk-print-job-selectable-variant.component';
import { GetSingleWordDefiningMenuTypePipe } from './pipes/get-single-word-defining-menu-type.pipe';
import { SaveChangesTooltipPipe } from './pipes/save-changes-tooltip.pipe';

/*
 * ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' })
 * see https://github.com/angular/angular/issues/48350
 */
@NgModule({
  declarations: [
    AssetComponent,
    TabBarComponent,
    TestTabComponent,
    TabContentComponent,
    TabContentContainerDirective,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    LoadingComponent,
    ProgressBarComponent,
    FormGroupComponent,
    LetterOnlyValidatorDirective,
    PasswordValidatorDirective,
    ConfirmationCodeValidatorDirective,
    PhoneValidatorDirective,
    CheckboxContainerComponent,
    CheckboxComponent,
    DropDownMenuComponent,
    AbandonFormComponent,
    LocationPickerComponent,
    CardDeckComponent,
    CardComponent,
    UploadItemComponent,
    UploadAssetComponent,
    DndDirective,
    HeaderComponent,
    IncompleteProductCardComponent,
    EmployeeComponent,
    EmployeeListItemComponent,
    CardTableComponent,
    BreadcrumbsComponent,
    CompanyBadgeComponent,
    LabelGroupComponent,
    LocationCardComponent,
    QuickActionCardComponent,
    ReorderModalComponent,
    ReorderListComponent,
    ActionListComponent,
    ActionComponent,
    ReorderListComponent,
    RangeSliderComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    SingleSelectionGroupedListComponent,
    AssetPreviewComponent,
    SegmentedControlComponent,
    LogoUploadComponent,
    ThemePreviewCardComponent,
    ThemeCarouselImageComponent,
    CardStackSizeCarouselComponent,
    CardStackSizeSelectionComponent,
    GetStackDimensionsPipe,
    GetNameFromStackSizePipe,
    GetImageSrcFromStackSizePipe,
    InformationModalComponent,
    ParseColorPipe,
    DayOfWeekPipe,
    ColorPaletteComponent,
    PlusButtonComponent,
    RemoveButtonComponent,
    ThrottleClickDirective,
    VariantBadgeComponent,
    VariantBadgesComponent,
    BadgePickerComponent,
    WarningBannerComponent,
    InfoBannerComponent,
    ErrorBannerComponent,
    MessageBannerComponent,
    LotInfoBannerComponent,
    ShoppingInfoBannerComponent,
    NoteTextComponent,
    TextLineDividerComponent,
    ArrowButtonComponent,
    ArrowButtonComponent,
    SectionTitleComponent,
    InfoIconComponent,
    ToggleSwitchComponent,
    UserPickerComponent,
    SearchWithLookAheadComponent,
    LookAheadListComponent,
    LookAheadItemComponent,
    DisplayNameComponent,
    SelectVariantsModalComponent,
    LightboxModalComponent,
    CheckboxOnlyComponent,
    GroupSelectionComponent,
    SelectableComponent,
    AddDollarSignToPricePipe,
    ToFixedDecimalPipe,
    VariantPricePipe,
    VariantFormattedSizingPipe,
    IsVariantOnSalePipe,
    VariantOriginalPricePipe,
    CreateViewBulkPrintJobComponent,
    PrintJobMenuSelectionComponent,
    ReferAFriendWidgetComponent,
    ReferAFriendModalComponent,
    ResetColumnOptionsComponent,
    DefaultColumnOptionsPickerComponent,
    PopperSelectionComponent,
    ExistsPipe,
    LocationComponent,
    DateRangeFromNowComponent,
    FilterProductsFormComponent,
    SecondsToMillisecondsPipe,
    InlineBadgePickerComponent,
    InlinePickerActionButtonComponent,
    FakeCheckBoxComponent,
    DebouncePipe,
    LogPipe,
    BadgeWithinPickerComponent,
    PrivateThemeIndicatorComponent,
    OverflowInfoModalComponent,
    OverflowVideoComponent,
    LabelInterfaceForLabelPickerPipe,
    StickyAutoPositionDirective,
    BadgePickerForFormComponent,
    SegmentedControlForFormComponent,
    BadgeAddedBySmartFilterIndicatorComponent,
    SmartFiltersIndicatorComponent,
    InlineBadgePickerCheckIfBadgeIsSmartPipe,
    TitleAndCrumbsComponent,
    HeaderSubtextComponent,
    HeaderTitleTextComponent,
    MenuTypePickerComponent,
    SearchableSingleSelectComponent,
    DisplayableContentContainerComponent,
    DisplayableItemContainerComponent,
    DisplayableItemPreviewComponent,
    MenuPreviewComponent,
    TemplatePreviewComponent,
    CollectionPreviewComponent,
    ReplaceMenuWithTemplatePipe,
    SmartMenuIndicatorComponent,
    TemplateDeployedCountIndicatorComponent,
    IsMenuPipe,
    IsTemplatePipe,
    IsCollectionPipe,
    ReplaceDisplayWithCollectionPipe,
    CarouselButtonComponent,
    ContinueIfCompactMenuPipe,
    ContinueIfCompactTemplateCollectionPipe,
    ReorderListItemComponent,
    CarouselButtonComponent,
    DisplayPreviewComponent,
    TemplateCollectionIndicatorComponent,
    GoBackModalFooterComponent,
    IsDisplayPipe,
    EmptyArrayPipe,
    TemplatedMenuDisabledFormControlTooltipPipe,
    TemplatedMenuDisabledButtonTooltipPipe,
    TemplatedMenuDisabledOverrideTooltipPipe,
    CollectionDisabledFormControlTooltipPipe,
    IncludesPipe,
    CountBadgesPipe,
    DuplicateButtonDisabledTooltipPipe,
    FileNameWithoutTimestampAndExtensionPipe,
    FileNameWithoutTimestampPipe,
    BadgePopoverComponent,
    IsSmartBadgePipe,
    UnixToDateTimePipe,
    UnixToDatePipe,
    GetFormErrorDisabledTooltipPipe,
    PageSectionComponent,
    ColumnConfigKeyNamePipe,
    DisplayableContentFilterFormComponent,
    DisplayableContentLayoutComponent,
    AsPipe,
    AsArrayOfPipe,
    ReplaceMenuWithCardOrLabelPipe,
    ReplaceMenuWithStackPipe,
    ReplaceMenuWithCardStackPipe,
    PrintJobHeaderComponent,
    PrintJobNameFormComponent,
    PrintJobEmployeeFormComponent,
    PrintJobMenuFormComponent,
    HiddenArrayFormInputComponent,
    IsCardStackMenuPipe,
    IsLabelStackMenuPipe,
    CardStackPreviewComponent,
    LabelStackPreviewComponent,
    DisplayItemGetLoadingFromBundlePipe,
    LoadPreviewPipe,
    GetVariantTitlePipe,
    GetProductTitlePipe,
    CardStackTemplatePreviewComponent,
    IsCardStackMenuTemplatePipe,
    ChooseBulkPrintJobTypeComponent,
    PrintMenuBulkPrintJobComponent,
    ChooseStackPrintTypeComponent,
    StackBulkPrintJobComponent,
    PrintJobSelectStacksComponent,
    PrintJobSelectMenusComponent,
    StackSmartBulkPrintJobComponent,
    StackSmartBulkPrintJobSelectedStacksComponent,
    StackSmartBulkPrintOptionsFormComponent,
    ListItemCheckboxesComponent,
    ListItemCheckboxComponent,
    StackSmartBulkPrintConfigFormComponent,
    PrintConfigMapFormItemComponent,
    StackSmartBulkPrintPreviousJobsFormComponent,
    MenuPrintJobsPipe,
    PreSelectedPreviousJobIdPipe,
    StackManualBulkPrintJobComponent,
    StackManualBulkPrintJobMenuListComponent,
    StackManualBulkPrintJobMenuListItemComponent,
    FirstSectionNVariantsPipe,
    IsSelectedIdPipe,
    StackManualBulkPrintJobLeftPaneComponent,
    StackManualBulkPrintJobRightPaneComponent,
    CardStackManualBulkPrintJobSelectableVariantComponent,
    CardStackManualBulkPrintJobSelectAllVariantsComponent,
    GetStackVariantErrorTooltipPipe,
    BulkCardStackPrintJobAppliedProductsComponent,
    BulkCardStackPrintJobAppliedProductsAccordionComponent,
    MenuFromIdPipe,
    StackAppliedProductListItemComponent,
    VariantFromIdPipe,
    NewProductChangeCheckboxDisabledPipe,
    NewProductChangeCheckboxDisabledTooltipPipe,
    LoadingClockComponent,
    CardSizeImageWidthInRemPipe,
    PrintJobMenuSelectionIconSrcPipe,
    IsPrintReportMenuPipe,
    StackPrintJobIncludeChangesSincePipe,
    AllStackCardsPrintedComponent,
    DateTimeStringIfUnixStringElseOriginalPipe,
    ConstructCardStackMenuIfOriginalDeletedPipe,
    LastRestockedInSecondsPipe,
    GetDisplayNamePipe,
    ProductFromIdPipe,
    EllipsisPipe,
    InventoryRoomsUsePurposePipe,
    ReactiveFormIncrementorComponent,
    NotAnAdminTooltipPipe,
    DashIfEmptyPipe,
    ContainsPipe,
    LabelStackTemplatePreviewComponent,
    IsLabelStackMenuTemplatePipe,
    ContainsStackedContentPipe,
    HiddenMapFormInputComponent,
    NSelectedVariantsPipe,
    LabelStackManualBulkPrintJobSelectAllVariantsComponent,
    LabelIncludeOrRemoveAllPipe,
    NLabelsSelectedPipe,
    GetInitialValueFromMapPipe,
    LabelStackPrintJobSelectVariantCountComponent,
    IncrementorComponent,
    LabelStackManualBulkPrintJobSelectableVariantComponent,
    GetSingleWordDefiningMenuTypePipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
    BsDropdownModule,
    NgbTooltipModule,
    ColorSketchModule,
    NgxPopperjsModule,
    ColorChromeModule,
    FlexLayoutModule,
    NgbAccordionModule,
    ReactiveFormModule,
    ReactiveButtonsModule,
    ReactiveTableModule,
    LoadingShimmerModule,
    ReactiveSearchAndPaginationModule,
    SaveChangesTooltipPipe,
    RxPush,
    RxIf,
    RxFor,
    RxLet,
  ],
  exports: [
    // BudSense Shared Components
    AssetComponent,
    TabBarComponent,
    MaterialModule,
    TestTabComponent,
    LoadingComponent,
    NgxSpinnerModule,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    ColorPickerModule,
    ProgressBarComponent,
    FormsModule,
    ReactiveFormsModule,
    FormGroupComponent,
    LetterOnlyValidatorDirective,
    CheckboxComponent,
    CheckboxContainerComponent,
    BsDropdownModule,
    DropDownMenuComponent,
    AbandonFormComponent,
    CardDeckComponent,
    CardComponent,
    UploadItemComponent,
    UploadAssetComponent,
    HeaderComponent,
    CardTableComponent,
    BreadcrumbsComponent,
    EmployeeComponent,
    EmployeeListItemComponent,
    CompanyBadgeComponent,
    LabelGroupComponent,
    ReorderModalComponent,
    RangeSliderComponent,
    ActionListComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    SingleSelectionGroupedListComponent,
    AssetPreviewComponent,
    SegmentedControlComponent,
    LogoUploadComponent,
    ColorPaletteComponent,
    VariantBadgeComponent,
    VariantBadgesComponent,
    BadgePickerComponent,
    InfoBannerComponent,
    MessageBannerComponent,
    WarningBannerComponent,
    ErrorBannerComponent,
    LotInfoBannerComponent,
    ShoppingInfoBannerComponent,
    NoteTextComponent,
    TextLineDividerComponent,
    ThrottleClickDirective,
    ArrowButtonComponent,
    SectionTitleComponent,
    InfoIconComponent,
    ToggleSwitchComponent,
    SearchWithLookAheadComponent,
    CheckboxOnlyComponent,
    DayOfWeekPipe,
    GroupSelectionComponent,
    SelectableComponent,
    AddDollarSignToPricePipe,
    ToFixedDecimalPipe,
    VariantPricePipe,
    VariantFormattedSizingPipe,
    IsVariantOnSalePipe,
    VariantOriginalPricePipe,
    CreateViewBulkPrintJobComponent,
    ReferAFriendWidgetComponent,
    ResetColumnOptionsComponent,
    DefaultColumnOptionsPickerComponent,
    ExistsPipe,
    LocationComponent,
    DateRangeFromNowComponent,
    FilterProductsFormComponent,
    SecondsToMillisecondsPipe,
    PopperSelectionComponent,
    InlineBadgePickerComponent,
    FakeCheckBoxComponent,
    DebouncePipe,
    DisplayNameComponent,
    LogPipe,
    RemoveButtonComponent,
    LabelInterfaceForLabelPickerPipe,
    StickyAutoPositionDirective,
    BadgePickerForFormComponent,
    SegmentedControlForFormComponent,
    SmartFiltersIndicatorComponent,
    InlinePickerActionButtonComponent,
    TitleAndCrumbsComponent,
    HeaderSubtextComponent,
    HeaderTitleTextComponent,
    MenuTypePickerComponent,
    SearchableSingleSelectComponent,
    DisplayableContentContainerComponent,
    DisplayableItemContainerComponent,
    ReplaceMenuWithTemplatePipe,
    ReplaceDisplayWithCollectionPipe,
    ContinueIfCompactMenuPipe,
    ContinueIfCompactTemplateCollectionPipe,
    ReplaceDisplayWithCollectionPipe,
    DisplayableItemPreviewComponent,
    DisplayPreviewComponent,
    GoBackModalFooterComponent,
    EmptyArrayPipe,
    TemplatedMenuDisabledFormControlTooltipPipe,
    TemplatedMenuDisabledButtonTooltipPipe,
    TemplatedMenuDisabledOverrideTooltipPipe,
    CollectionDisabledFormControlTooltipPipe,
    IsTemplatePipe,
    IncludesPipe,
    DuplicateButtonDisabledTooltipPipe,
    BadgePopoverComponent,
    IsSmartBadgePipe,
    UnixToDateTimePipe,
    UnixToDatePipe,
    CountBadgesPipe,
    GetFormErrorDisabledTooltipPipe,
    ReactiveSearchBarComponent,
    ReactivePaginationComponent,
    GetFormErrorDisabledTooltipPipe,
    PageSectionComponent,
    ColumnConfigKeyNamePipe,
    DisplayableContentFilterFormComponent,
    DisplayableContentLayoutComponent,
    AsPipe,
    AsArrayOfPipe,
    IsCollectionPipe,
    ReplaceMenuWithCardOrLabelPipe,
    ReplaceMenuWithStackPipe,
    ReplaceMenuWithCardStackPipe,
    CardStackSizeSelectionComponent,
    PrintJobNameFormComponent,
    PrintJobEmployeeFormComponent,
    PrintJobHeaderComponent,
    HiddenArrayFormInputComponent,
    GetVariantTitlePipe,
    GetProductTitlePipe,
    ChooseStackPrintTypeComponent,
    ListItemCheckboxesComponent,
    ListItemCheckboxComponent,
    IsCardStackMenuPipe,
    IsLabelStackMenuPipe,
    StackManualBulkPrintJobComponent,
    StackAppliedProductListItemComponent,
    NewProductChangeCheckboxDisabledPipe,
    NewProductChangeCheckboxDisabledTooltipPipe,
    LoadingClockComponent,
    IsPrintReportMenuPipe,
    DateTimeStringIfUnixStringElseOriginalPipe,
    LastRestockedInSecondsPipe,
    GetDisplayNamePipe,
    ProductFromIdPipe,
    EllipsisPipe,
    InventoryRoomsUsePurposePipe,
    ReactiveFormIncrementorComponent,
    NotAnAdminTooltipPipe,
    InventoryRoomsUsePurposePipe,
    DashIfEmptyPipe,
    ContainsPipe,
    LabelStackTemplatePreviewComponent,
    IsLabelStackMenuTemplatePipe,
    ContainsStackedContentPipe,
    LabelIncludeOrRemoveAllPipe,
    NLabelsSelectedPipe,
    GetInitialValueFromMapPipe,
    LabelStackPrintJobSelectVariantCountComponent,
    GetSingleWordDefiningMenuTypePipe,
    SaveChangesTooltipPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
