import { Injectable } from '@angular/core';
import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from '../edit-template-marketing-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingUrlPlaylistMenuViewModel } from './edit-template-marketing-url-playlist-menu-view-model';
import { EditUrlPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu-view-model.service';

@Injectable()
export class EditTemplateMarketingUrlPlaylistMenuCombinedLoadingOptsViewModel
  extends EditTemplateMarketingMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingUrlPlaylistMenuViewModel: EditTemplateMarketingUrlPlaylistMenuViewModel,
    protected editUrlPlaylistMenuViewModel: EditUrlPlaylistMenuViewModel
  ) {
    super(editTemplateMarketingUrlPlaylistMenuViewModel, editUrlPlaylistMenuViewModel);
  }

}
