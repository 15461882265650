import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { CanDeactivateGuard } from '../../services/guards/can-deactivate.guard';
import { RefreshSessionGuard } from '../../services/guards/refresh-session.guard';
import { AuthGuard } from '../../services/guards/auth.guard';
import { PermissionGuard } from '../../services/guards/permission.guard';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    data: {
      title: 'Account'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'sign-in',
    component: AuthComponent,
    data: {
      title: 'Sign In'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    data: {
      title: 'Signing Out'
    },
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'forgot-password',
    component: AuthComponent,
    data: {
      title: 'Forgot Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'reset-password',
    component: AuthComponent,
    data: {
      title: 'Reset Password'
    },
    canActivate: [RefreshSessionGuard]
  },
  {
    path: 'new-password',
    component: AuthComponent,
    data: {
      title: 'Set New Password'
    },
    canActivate: [RefreshSessionGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
