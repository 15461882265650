import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderUtils } from '../../utils/provider-utils';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { HydratedUser } from '../../models/account/dto/hydrated-user';

type Activation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({ providedIn: 'root' })
export class PermissionGuard  {

  constructor(
    private userDomainModel: UserDomainModel,
    public router: Router
  ) {
  }

  public findTerm = (href: string, term: string) => {
    if (href.includes(term)) {
      return href;
    }
  };

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activation {
    return this.userDomainModel.user$.firstNotNull().pipe(
      map((user) => {
        return this.routeIsPermitted(state, user) || this.router.parseUrl('');
      }),
    );
  }

  routeIsPermitted(state: RouterStateSnapshot, user: HydratedUser): boolean {
    let isPermitted = true;
    if (user) {
      const isCompanyAdmin = user.isCompanyAdmin;
      const inventoryProvider = user.inventoryProvider;
      const allowIntegrationsAccess = ProviderUtils.supportsLocationSpecificPOSSyncing(inventoryProvider);
      switch (state.url) {
        // note that the order of these cases matters
        case this.findTerm(state.url, '/settings/general'):
          isPermitted = isCompanyAdmin || allowIntegrationsAccess;
          break;
        case this.findTerm(state.url, '/settings'):
          isPermitted = isCompanyAdmin;
          break;
      }
    }
    return isPermitted;
  }

}
