import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-edit-menu-container',
  templateUrl: './edit-menu-container.component.html',
  styleUrls: ['./edit-menu-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuContainerComponent {
}
