import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { Variant } from '../../../../../../models/product/dto/variant';
import { map } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';

@Injectable()
export class FeaturedProductFormViewModel extends BaseViewModel {

  private _menu = new BehaviorSubject<Menu|null>(null);
  public menu$: Observable<Menu|null> = this._menu as Observable<Menu>;

  private _variant = new BehaviorSubject<Variant|null>(null);
  public variant$ = this._variant as Observable<Variant|null>;

  public priceFormat$ = this.locationDomainModel.priceFormat$;

  public pricePlaceHolder$ = combineLatest(([
    this.menu$,
    this.variant$,
    this.priceFormat$
  ])).pipe(
    map(([menu, variant, priceStream]) => {
      const visiblePrice = variant?.getVisiblePrice(menu?.locationId, menu?.companyId, priceStream, false);
      return Number.isFinite(visiblePrice) ? `${visiblePrice}` : '';
    })
  );

  public desc$ = this.variant$.pipe(map(variant => variant?.description));

  public isTemplatedMenu$ = this.menu$.pipe(map(menu => menu?.isTemplatedMenu()));

  constructor(
    private locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  public setMenu = (menu: Menu|null) => this._menu.next(menu);
  public setVariant = (variant: Variant|null) => this._variant.next(variant);

}
