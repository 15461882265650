<app-menu-details-section [templateMode]="templateMode">
  <app-edit-menu-warning-banner
    [warningMessage]="viewModel.menuWarningMessage$ | async"
    [tooltipMessage]="viewModel.menuTooltipWarningMessage$ | async">
  </app-edit-menu-warning-banner>

  <app-menu-additional-options
    #menuAdditionalOptions
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | async"
    [bindTo]="viewModel.menu$ | async"
    (iFrameHeightChanged)="viewModel.iFrameHeightChanged($event)"
    (iFrameWidthChanged)="viewModel.iFrameWidthChanged($event)">
  </app-menu-additional-options>
</app-menu-details-section>

<app-menu-section-background
  [style.display]="'block'"
  [formPristine]="formPristine"
  [templateMode]="templateMode"
  class="mt-24px"
  (backgroundDeleted)="viewModel?.onBackgroundDeleted()">
  Menu Background Asset Section
</app-menu-section-background>

<app-edit-featured-products
  [sharedVM]="viewModel"
  [mergeKey]="mergeKey"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine"
  [isTemplatedMenu]="viewModel?.isTemplatedMenu$ | async">
</app-edit-featured-products>

<hr class="mt-24px" />
