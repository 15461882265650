import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { UniversalVariant } from '../../../../../../../../models/product/dto/universal-variant';

@Component({
  selector: 'app-ubv-info-panel',
  templateUrl: './ubv-info-panel.component.html',
  styleUrls: ['./ubv-info-panel.component.scss']
})
export class UbvInfoPanelComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() variant: UniversalVariant;

}
