import { TypeDefinition } from './type-definition';

export enum SaleLabelFormat {
  NONE = '',
  SALE = 'Sale',
  DOLLAR_OFF = 'DollarOff',
  PERCENT_OFF = 'PercentOff',
}

export class SaleLabelFormatType extends TypeDefinition {

  override value: SaleLabelFormat;

}
