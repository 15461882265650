import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Section } from '../../../../../models/menu/dto/section';
import { Breadcrumb } from '../../../../../models/shared/stylesheet/breadcrumb';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { DropDownMenuSection } from '../../../../../models/shared/stylesheet/drop-down-menu-section';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-edit-menu-section-header',
  templateUrl: './edit-menu-section-header.component.html',
  styleUrls: [
    './edit-menu-section-header.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionHeaderComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() section: Section;
  @Input() headerText: string = 'Edit Menu Section';
  @Input() quickChangeSectionTitle: string = 'Go to Section';
  @Input() crumbs: Breadcrumb[] = [];
  @Input() headerSubtext: string = 'Create unique menu sections for impressive eye-catching menus.';
  @Input() lastAutoSavedText: string;
  @Input() removingVariants: boolean;
  @Input() disableSubmit: boolean;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() hideSectionNavButton: boolean;
  @Input() disableSectionNavOptionIds: string[] = [];
  @Input() sectionNavOptions: DropDownMenuSection[] = [];
  @Input() userIsTemplateAdmin: boolean = false;
  @Input() editTemplateSectionLinkText = 'Edit Template Section';
  @Input() hideLiveViewButton: boolean = false;
  @Input() allowLiveView: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() formHasErrors: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  // emit true if you want the form to auto submit in the background
  @Output() submitForms = new EventEmitter<boolean>(true);
  @Output() handleDropdownSelection = new EventEmitter<any>(true);
  @Output() editTemplateSectionClicked = new EventEmitter<SectionTemplate>(true);

  private readonly _formAutoSubmitted = new Subject<any[]>();
  public readonly formAutoSubmitted$ = this._formAutoSubmitted as Observable<any[]>;
  connectToFormAutoSubmitted = (formAutoSubmitted: any[]) => this._formAutoSubmitted.next(formAutoSubmitted);

}
