import { LiveViewModalComponent } from '../views/display/components/modals/live-view-modal/live-view-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Menu } from '../models/menu/dto/menu';
import { Size } from '../models/shared/size';
import { Injector } from '@angular/core';

export class ModalMenuLiveView {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    locationId: number,
    menu: Menu,
    sizeOverride?: Size
  ): void {
    // BuzzTV always renders the content as 720p, regardless of screen size or operating settings.
    const size = menu?.isWebMenu()
      ? menu?.displaySize
      : Size.sevenTwentyP(sizeOverride?.orientation || menu?.displaySize?.orientation);
    const modalRef = ngbModal.open(
      LiveViewModalComponent,
      ModalUtils.liveViewOptions(injector, sizeOverride?.orientation || size?.orientation)
    );
    const compInstance = modalRef.componentInstance as LiveViewModalComponent;
    compInstance.setupMenuLiveView(menu, sizeOverride || size, locationId);
  }

}
