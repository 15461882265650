import { animate, state, style, transition, trigger } from '@angular/animations';

export function expandCollapseAnimation() {
  return trigger('expandCollapse', [
    state('expanded', style({height: '*', opacity: 1, display: 'block'})),
    state('collapsed', style({height: '0px', opacity: 0, display: 'none'})),
    transition('expanded <=> collapsed', [
      animate('50ms')
    ]),
  ]);
}

