import { Pipe, PipeTransform } from '@angular/core';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';

@Pipe({
  name: 'isLabelStackMenuTemplate'
})
export class IsLabelStackMenuTemplatePipe implements PipeTransform {

  transform(value: any): MenuTemplate | null {
    return (value instanceof MenuTemplate && (value?.isPrintLabelMenu?.() ?? false)) ? value : null;
  }

}
