<lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
  <lib-reactive-form-row>
    <lib-reactive-form-date
      [inputName]="'windowStartDate'"
      [label]="'Start Date'"
      [bindingProperty]="'startDateString'"
      [required]="true"></lib-reactive-form-date>
    <lib-reactive-form-time
      [inputName]="'windowStartTime'"
      [label]="'Start Time'"
      [bindingProperty]="'startTimeString'"
      [inputStep]="'60'"
      [required]="true"></lib-reactive-form-time>
    <lib-reactive-form-date
      [inputName]="'windowEndDate'"
      [label]="'End Date'"
      [bindingProperty]="'endDateString'"
      [required]="true"></lib-reactive-form-date>
    <lib-reactive-form-time
      [inputName]="'windowEndTime'"
      [label]="'End Time'"
      [bindingProperty]="'endTimeString'"
      [inputStep]="'60'"
      [required]="true"></lib-reactive-form-time>
    <div class="remove-window-button" (click)="removeDateTimeWindow.emit(index)">
      <img src="assets/icons/dark/solid/x.svg" alt="" />
    </div>
  </lib-reactive-form-row>
</lib-reactive-form-group>
