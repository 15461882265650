import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionSectionDetailsComponent } from '../../../../../edit-menu-section/edit-menu-section-section-details/edit-menu-section-section-details.component';
import { EditFeaturedCategorySectionViewModel } from '../edit-featured-category-section-view-model';

@Component({
  selector: 'app-edit-featured-category-section-section-details',
  templateUrl: './../../../../../edit-menu-section/edit-menu-section-section-details/edit-menu-section-section-details.component.html',
  styleUrls: [
    './edit-featured-category-section-section-details.component.scss',
    './../../../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategorySectionSectionDetailsComponent extends EditMenuSectionSectionDetailsComponent {

  constructor(
    public override viewModel: EditFeaturedCategorySectionViewModel
  ) {
    super(viewModel);
  }

}
