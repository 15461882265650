import { Injectable } from '@angular/core';
import { combineLatest, fromEvent, merge, timer } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, throttleTime } from 'rxjs/operators';
import { BaseService } from '@mobilefirstdev/base-angular';

@Injectable({
  providedIn: 'root'
})
export class AppIsActiveService extends BaseService {

  constructor(
    private router: Router
  ) {
    super();
  }

  private readonly IS_ACTIVE_INTERVAL = 1200000; // 20 min
  private readonly THROTTLE_EVENTS = 5000; // 5 second

  private readonly appIsVisible$ = fromEvent(document, 'visibilitychange').pipe(
    map(_ => !document?.hidden),
    startWith(true),
    distinctUntilChanged()
  );

  private readonly urlChanged$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map(event => event?.url)
  );

  private readonly mouseMovement$ = fromEvent(document, 'mousemove').pipe(
    throttleTime(this.THROTTLE_EVENTS)
  );

  private readonly keyPressed$ = fromEvent(document, 'keypress').pipe(
    throttleTime(this.THROTTLE_EVENTS)
  );

  private readonly timedOut$ = timer(this.IS_ACTIVE_INTERVAL).pipe(
    map(() => true),
    startWith(false)
  );

  private readonly usingProgram$ = merge(this.urlChanged$, this.mouseMovement$, this.keyPressed$).pipe(
    switchMap(() => this.timedOut$),
    map(timedOut => !timedOut),
    startWith(true),
    distinctUntilChanged()
  );

  public readonly appIsActive$ = combineLatest([
    this.appIsVisible$,
    this.usingProgram$,
  ]).pipe(
    map(([visible, usingProgram]) => visible && usingProgram),
    shareReplay(1)
  );

}
