import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.scss']
})
export class RemoveButtonComponent extends BaseComponent {

  @Input() backgroundColor: string = '#FFFFFF';
  @Input() borderRadius: string = '50%';
  @Input() height: string = '4rem';
  @Input() width: string = '4rem';
  @Input() xDim: string = '1.5rem';
  @Input() xScale: number = 0.5;
  @Input() zIndex: number = 10;

  constructor() {
    super();
  }

}
