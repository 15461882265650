<div class="right-pane">
  <lib-reactive-search-bar
    #searchBar
    class="search-bar"
    [style.top]="0"
    [style.position]="'sticky'"
    [style.z-index]="2"
    [searchTheseProperties]="['name']"
    [placeHolder]="'Search variants by name'"
    [searchThrough]="viewModel.selectedStackSearchableVariants$ | async"
    (searchTextAndHits)="viewModel.connectToSearchTextAndHits($event)">
  </lib-reactive-search-bar>
  <ng-container #cardStackSelection *rxIf="viewModel.isCardStack$; else labelStackSelection">
    <app-card-stack-manual-bulk-print-job-select-all-variants
      *ngIf="!(viewModel.viewOnly$ | async) && (viewModel.hasVisibleVariants$ | async); else emptyState">
    </app-card-stack-manual-bulk-print-job-select-all-variants>
    <ng-template #emptyState>
      <div class="empty-state-container">
        <div class="empty-state-text">No variants found</div>
      </div>
    </ng-template>
    <app-card-stack-manual-bulk-print-job-selectable-variant
      *rxFor="let variant of viewModel.visibleVariantsInList$; trackBy: 'id'"
      [variant]="variant">
    </app-card-stack-manual-bulk-print-job-selectable-variant>
  </ng-container>
  <ng-template #labelStackSelection>
    <app-label-stack-manual-bulk-print-job-select-all-variants
      *ngIf="!(viewModel.viewOnly$ | async) && (viewModel.hasVisibleVariants$ | async); else emptyState"
      [selection]="viewModel.visibleVariantsInListAsSelection$ | push"
      [selectionMap]="viewModel.selectedMenuVariantLabelCountMap$ | push"
      (bulkAddClicked)="viewModel.bulkAddVariantsToVariantLabelCountMap($event)"
      (bulkRemoveClicked)="viewModel.bulkRemoveVariantsFromVariantLabelCountMap($event)">
    </app-label-stack-manual-bulk-print-job-select-all-variants>
    <ng-template #emptyState>
      <div class="empty-state-container">
        <div class="empty-state-text">No variants found</div>
      </div>
    </ng-template>
    <!-- DON'T USE TRACKBY HERE - I NEED THE COMPONENTS TO GET DESTROYED AND RECREATED WHEN THE MENU SWITCHES -->
    <app-label-stack-manual-bulk-print-job-selectable-variant
      *rxFor="let variant of viewModel.visibleVariantsInList$"
      [variant]="variant"
      [variantCountMap]="viewModel.selectedMenuVariantLabelCountMap$ | async"
      [viewOnly]="viewModel.viewOnly$ | async"
      (updateVariantCountMap)="viewModel.updateVariantCountMap([variant?.id, $event])">
    </app-label-stack-manual-bulk-print-job-selectable-variant>
  </ng-template>
  <app-hidden-array-form-input
    [disabled]="viewModel.viewOnly$ | async"
    [dispersedKey]="viewModel.mergeKey$ | async"
    [inputName]="'variantSelections'"
    [required]="true"
    [inputArr]="viewModel.entireJobSelectedVariantIds$ | async">
  </app-hidden-array-form-input>
</div>
