import { defer, EMPTY, iif, NEVER, Observable, PartialObserver, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DistinctUtils } from './distinct-utils';
import { Subscribable } from '@mobilefirstdev/base-angular';

interface LivingObserver<T> {
  owner: Subscribable;
  closed?: boolean;
  next?: (value: T) => void;
  error?: (err: any) => void;
  complete?: () => void;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    distinctUniquelyIdentifiable(): Observable<T>;
    distinctUniquelyIdentifiableArray(): Observable<T>;
    deepCopy(): Observable<T>;
    deepCopyArray(): Observable<T>;
    firstNotNull(): Observable<T>;
    log(): Observable<T>;
    notNull(): Observable<T>;
    once(run?: PartialObserver<T> | ((data: T) => void)): Subscription;
    preventConsecutiveNulls(): Observable<T>;
    shallowCopyArray(): Observable<T>;
    stopWhenInactive(): Observable<T>;
    subscribeWhileAlive(observer: LivingObserver<T>): Subscription;
  }
}

// noinspection SpellCheckingInspection
/**
 * Custom operator. Extends rxjs's iif operator.
 * Allows you to pass in observable pipes for all parameters.
 *
 * @param condition$ - The observable to emit true or false, which determines which observable to emit.
 * @param trueResult$ - The observable to emit if the condition$ observable emits true.
 * @param falseResult$ - The observable to emit if the condition$ observable emits false.
 *
 * Example
 * const condition$ = of(true);
 * const trueResult$ = of('positive');
 * const falseResult$ = of('negative');
 * const result$ = iiif(condition$, trueResult$, falseResult$);
 * result$.subscribe(); // 'positive'
 */
export function iiif<T = never, F = never>(
  condition$: Observable<boolean> = EMPTY,
  trueResult$: Observable<T> = EMPTY,
  falseResult$: Observable<F> = EMPTY
): Observable<T|F> {
  return defer(() => (condition$ || EMPTY).pipe(switchMap(condition => (condition ? trueResult$ : falseResult$))));
}

/**
 * iiif but only checks the condition once.
 */
export function iiifOnce<T = never, F = never>(
  condition$: Observable<boolean> = EMPTY,
  trueResult$: Observable<T> = EMPTY,
  falseResult$: Observable<F> = EMPTY
): Observable<T|F> {
  return defer(() => {
    return (condition$ || EMPTY).pipe(
      take(1),
      switchMap(condition => (condition ? trueResult$ : falseResult$))
    );
  });
}

Observable.prototype.notNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x !== null && x !== undefined));
  };

Observable.prototype.firstNotNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x !== null && x !== undefined), take(1));
  };

Observable.prototype.deepCopy = function<T>(): Observable<T> {
  return this.pipe(
    map(x => {
      if (!x) {
        return x;
      } else if (typeof (x as any)?.onDeserialize === 'function') {
        return window?.injector?.Deserialize?.instanceOf((x as any).constructor, x);
      } else {
        return Object.assign(Object.create((x as any).prototype), x);
      }
    })
  );
};

Observable.prototype.deepCopyArray = function<T>(): Observable<T[]> {
  return this.pipe(
    map(x => {
      if (!x) return x;
      const castedX = x as T[];
      return castedX?.length > 0 ? castedX?.deepCopy() as T[] : [] as T[];
    })
  );
};

Observable.prototype.log = function(): Observable<any> {
  // eslint-disable-next-line no-console
  return this.pipe(tap(console.log));
};

Observable.prototype.subscribeWhileAlive = function<T>(observer: LivingObserver<T>): Subscription {
  const emptyObserver = !observer?.next && !observer?.error && !observer?.complete;
  const typedObserver = emptyObserver ? undefined : observer as PartialObserver<T>;
  return this.pipe(takeUntil(observer.owner.onDestroy)).subscribe(typedObserver);
};

Observable.prototype.stopWhenInactive = function(): Observable<any> {
  return window.injector.appIsActiveService.appIsActive$.pipe(
    switchMap(appIsActive => iif(() => appIsActive, this, NEVER))
  );
};

Observable.prototype.preventConsecutiveNulls = function(): Observable<any> {
  return this.pipe(
    startWith<any, any>(null),
    pairwise(),
    filter(([prev, curr]) => {
      const prevIsNull = prev === null || prev === undefined;
      const currIsNull = curr === null || curr === undefined;
      return !(prevIsNull && currIsNull);
    }),
    map(([_, curr]) => curr)
  );
};

Observable.prototype.once = function<T>(observerOrNext?: PartialObserver<T> | ((value: T) => void)): Subscription {
  return this.pipe(take(1)).subscribe(observerOrNext);
};

Observable.prototype.distinctUniquelyIdentifiable = function<T>(): Observable<T> {
  return this.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));
};

Observable.prototype.distinctUniquelyIdentifiableArray = function<T>(): Observable<T> {
  return this.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray));
};

Observable.prototype.shallowCopyArray = function<T>(): Observable<T[]> {
  return this.pipe(
    map(x => {
      if (!x) return x;
      const castedX = x as T[];
      return [...(castedX || [])] as T[];
    })
  );
};
