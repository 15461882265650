import { SmartFilterAppliedOnPillType } from '../models/enum/shared/smart-filter-applied-on-pill-type';
import { MenuType } from '../models/utils/dto/menu-type-definition';
import type { HydratedVariantBadge } from '../models/product/dto/hydrated-variant-badge';
import type { Menu } from '../models/menu/dto/menu';
import type { Label } from '../models/shared/label';
import type { PillItem, PillItemInterface } from '../models/shared/stylesheet/pill-item';
import type { MenuTemplate } from '../models/template/dto/menu-template';
import { AddEditSmartFilterModalOpenedFrom } from '../models/automation/enum/add-edit-smart-filter-modal-opened-from';

export class SmartFilterUtils {

  static contextRequiresCompanySmartFilter(openedFrom: AddEditSmartFilterModalOpenedFrom): boolean {
    return openedFrom === AddEditSmartFilterModalOpenedFrom.SmartLabel
        || openedFrom === AddEditSmartFilterModalOpenedFrom.SmartBadge
        || openedFrom === AddEditSmartFilterModalOpenedFrom.TemplateSectionCreate
        || openedFrom === AddEditSmartFilterModalOpenedFrom.TemplatedSectionEdit;
  }

  static getAppliedOnPillIcon(type: SmartFilterAppliedOnPillType | MenuType): string {
    switch (type) {
      case MenuType.DisplayMenu:
        return 'assets/icons/dark/solid/desktop-computer-black.svg';
      case MenuType.PrintMenu:
        return 'assets/icons/dark/solid/printer.svg';
      case MenuType.PrintCardMenu:
        return 'assets/icons/dark/solid/print-cards-stack.svg';
      case MenuType.WebMenu:
        return 'assets/icons/dark/outline/web.svg';
      case MenuType.MarketingMenu:
        return 'assets/icons/dark/solid/bookmark-alt.svg';
      case SmartFilterAppliedOnPillType.SmartBadge:
        return 'assets/icons/dark/solid/smart-badge.svg';
      case SmartFilterAppliedOnPillType.SmartLabel:
        return 'assets/icons/dark/solid/smart-label.svg';
      case SmartFilterAppliedOnPillType.Template:
        return 'assets/icons/dark/solid/template.svg';
    }
  }

  static generateAppliedOnPills(
    templates: MenuTemplate[],
    menus: Menu[],
    badges: HydratedVariantBadge[],
    labels: Label[],
    isClickable: boolean
  ): PillItem[] {
    const toBePilled: PillItemInterface[] = [
      ...(templates || []),
      ...(menus || []),
      ...(badges || []),
      ...(labels || [])
    ];
    return toBePilled?.map(item => item?.getAsPillItem(isClickable, false, false))?.filterNulls();
  }

}
