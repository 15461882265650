<div
  appStickyAutoPosition
  class="sticky-header-container"
  [stickyCollectionKey]="stickyCollectionKey"
  [style.overflow-y]="'unset'"
  [stickyOrder]="stickyOrder"
  [stickyZ]="stickyZ">
  <div class="modal-header d-flex align-items-center">
    <div class="modal-title">
      {{ title }}
    </div>
    <div [hidden]="!showClearButton" class="text-link" [class.disabled]="disableClearButton" (click)="clear.emit()">
      Clear
    </div>
  </div>
</div>
