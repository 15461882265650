import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, iif, Observable } from 'rxjs';
import { BaseDisplay } from '../../../../../models/display/dto/base-display';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../domainModels/template-collection-domain-model';
import { Display } from '../../../../../models/display/dto/display';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { TemplateStatus } from '../../../../../models/template/enum/template-status.enum';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { DisplayContentOption } from '../../../../../models/enum/shared/display-content-option.enum';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { SortUtils } from '../../../../../utils/sort-utils';
import { StringUtils } from '../../../../../utils/string-utils';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';

export enum AddContentStep {
  ChooseOption = 0,
  SelectContent = 1,
}

@Injectable()
export class AddMenuOrCollectionModalViewModel extends BaseModalViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private menuDomainModel: MenuDomainModel,
    private collectionDomainModel: TemplateCollectionDomainModel,
    private companyDomainModel: CompanyDomainModel,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
    combineLatest([
      this.collectionMode$,
      this.userDomainModel.canUseTemplates$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([collectionMode, supportsTemplates]) => {
        if (collectionMode || !supportsTemplates) this._addContentStep.next(AddContentStep.SelectContent);
      }
    });
  }

  private menuTemplates$ = this.templateDomainModel.digitalMenuTemplates$.pipe(
    map((templates) => templates?.sort((a, b) => SortUtils.menusByNameAsc(a, b))),
    shareReplay({bufferSize: 1, refCount: true})
  );
  private currentLocationDigitalMenus$ = this.menuDomainModel.currentLocationDigitalMenus$;

  private _addContentStep = new BehaviorSubject<AddContentStep>(AddContentStep.ChooseOption);
  public addContentStep$ = this._addContentStep as Observable<AddContentStep>;

  public contentStepIsChooseOption$ = this.addContentStep$.pipe(
    map(step => step === AddContentStep.ChooseOption)
  );

  public contentStepIsSelectContent$ = this.addContentStep$.pipe(
    map(step => step === AddContentStep.SelectContent)
  );

  private _displayContentOption = new BehaviorSubject<DisplayContentOption>(DisplayContentOption.Menu);
  public displayContentOption$ = this._displayContentOption as Observable<DisplayContentOption>;
  public connectToDisplayContentOption = (opt: DisplayContentOption) => {
    this._displayContentOption.next(opt);
    this._addContentStep.next(AddContentStep.SelectContent);
  };

  private _display = new BehaviorSubject<BaseDisplay>(null);
  public display$ = this._display as Observable<BaseDisplay>;
  public connectToDisplay = (d: BaseDisplay) => this._display.next(d);

  public publishedCollection$ = this.display$.pipe(
    map(d => (d instanceof TemplateCollection && d?.isPublished()))
  );

  private _collectionMode = new BehaviorSubject<boolean>(null);
  public collectionMode$ = this._collectionMode as Observable<boolean>;
  public connectToCollectionMode = (mode: boolean) => this._collectionMode.next(mode);

  public addMenus: (ids: string[], loadingOptions: BehaviorSubject<LoadingOptions>) => void;
  public addCollections: (ids: string[], loadingOptions: BehaviorSubject<LoadingOptions>) => void;

  public title$ = combineLatest([
    this._addContentStep,
    this.displayContentOption$,
    this.display$,
    this.publishedCollection$,
    this.collectionMode$
  ]).pipe(
    map(([contentStep, contentOption, display, publishedCollection, collectionMode]) => {
      if (contentStep === AddContentStep.ChooseOption) return 'Choose Display Content Option';
      if (publishedCollection) return `Add Published ${contentOption} to ${display?.name}`;
      return StringUtils.replaceMenuWithTemplate(`Add ${contentOption} to ${display?.name}`, collectionMode);
    })
  );

  private menuSelectionChoices$ = this.collectionMode$.pipe(
    switchMap((collectionMode) => {
      const activeLocationDigitalMenus$ = this.currentLocationDigitalMenus$.pipe(
        map(menus => menus?.filter(m => m?.active))
      );
      return collectionMode ? this.menuTemplates$ : activeLocationDigitalMenus$;
    })
  );

  public itemsToSearch$ = this.displayContentOption$.pipe(
    switchMap((opt) => {
      return iif(
        () => opt === DisplayContentOption.Menu,
        this.menuSelectionChoices$,
        this.publishedTemplateCollections$
      );
    })
  );

  public publishedTemplateCollections$ = this.collectionDomainModel.templateCollections$.pipe(
    map((collections) => collections?.filter((c) => c.status === TemplateStatus.Published))
  );

  private _itemIdsToAdd = new BehaviorSubject<string[]>([]);
  public itemsIdsToAdd$ = this._itemIdsToAdd as Observable<string[]>;

  public disablePrimaryButton$ = this.itemsIdsToAdd$.pipe(map((items) => !items.length));
  public showBackButton$ = combineLatest([
    this.collectionMode$,
    this.userDomainModel.canUseTemplates$,
    this.contentStepIsChooseOption$
  ]).pipe(
    map(([collectionMode, supportsTemplates, chooseOptionStep]) => {
      return !collectionMode && supportsTemplates && !chooseOptionStep;
    })
  );

  private collectionEmptyStateText$ = this.publishedTemplateCollections$.pipe(
    map((publishedCollections) => {
      return publishedCollections?.length
        ? 'No published collections found.'
        : 'You do not have any published collections.';
    })
  );

  private menuEmptyStateText$ = combineLatest([this.collectionMode$, this.menuSelectionChoices$]).pipe(
    map(([collectionMode, menuSelections]) => {
      return menuSelections?.length
        ? StringUtils.replaceMenuWithTemplate('No menus found.', collectionMode)
        : StringUtils.replaceMenuWithTemplate('You do not have any menus.', collectionMode);
    })
  );

  public emptyStateText$ = this.displayContentOption$.pipe(
    switchMap((opt) => {
      return iif(
        () => opt === DisplayContentOption.Menu,
        this.menuEmptyStateText$,
        this.collectionEmptyStateText$
      );
    })
  );

  public searchPlaceholder$ = combineLatest([this.displayContentOption$, this.collectionMode$]).pipe(
    map(([contentOption, collectionMode]) => {
      if (contentOption === DisplayContentOption.TemplateCollection) {
        return 'Search by collection name';
      }
      return collectionMode
        ? 'Search by template name'
        : 'Search active menus by name';
    })
  );

  public handleItemClick(id: string): void {
    combineLatest([
      this.displayContentOption$,
      this.itemsIdsToAdd$,
      this.display$,
      this.publishedCollection$,
      this.menuTemplates$
    ]).once(([
      contentOption,
      itemsToAdd,
      display,
      publishedCollection,
      allTemplates
    ]) => {
      if (display instanceof TemplateCollection) {
        const template = allTemplates?.find((t) => t?.id === id);
        if (publishedCollection && !template?.isPublished()) return;
      }

      const itemIsIncludedInDisplay = contentOption === DisplayContentOption.Menu
        ? display?.getMenus()?.some((m) => m.id === id)
        : display instanceof Display && display.templateCollectionIds?.includes(id);

      if (itemIsIncludedInDisplay) return;

      const updatedItemsToAdd = itemsToAdd?.shallowCopy() || [];
      if (updatedItemsToAdd?.includes(id)) {
        updatedItemsToAdd.splice(itemsToAdd?.indexOf(id), 1);
      } else {
        updatedItemsToAdd?.push(id);
      }
      this._itemIdsToAdd.next(updatedItemsToAdd);
    });
  }

  public handleAddItems(): void {
    combineLatest([this.displayContentOption$, this.itemsIdsToAdd$]).once(([contentOption, itemsToAdd]) => {
      contentOption === DisplayContentOption.Menu
        ? this.addMenus(itemsToAdd, this._loadingOpts)
        : this.addCollections(itemsToAdd, this._loadingOpts);
    });
  }

  public goBack(): void {
    this._addContentStep.next(AddContentStep.ChooseOption);
    this._itemIdsToAdd.next([]);
  }

}
