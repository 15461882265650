import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DropDownMenuSection } from '../../../../models/shared/stylesheet/drop-down-menu-section';
import { EmployeeAction } from '../../../../models/company/shared/employee-action';
import { User } from '../../../../models/account/dto/user';
import { BaseComponent } from '../../../../models/base/base-component';
import { EmployeeViewModel } from './employee-view-model';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  providers: [EmployeeViewModel]
})
export class EmployeeComponent extends BaseComponent implements OnChanges {

  @Input() employee: User;
  public menuItems: DropDownMenuSection[];
  @Input() showCompanyRole: boolean = false;
  @Input() supportsTemplates: boolean = false;
  @Output() selection: EventEmitter<EmployeeAction> = new EventEmitter();

  constructor(
    public viewModel: EmployeeViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.employee) this.viewModel.connectToEmployee(this.employee);
    if (changes.showCompanyRole) this.viewModel.connectToShowCompanyRole(this.showCompanyRole);
    if (changes.supportsTemplates) this.viewModel.connectToSupportsTemplates(this.supportsTemplates);
  }

}
