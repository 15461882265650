import { Menu } from '../../menu/dto/menu';
import { Size } from '../../shared/size';
import { Display } from '../dto/display';
import { DisplayOptions } from '../../shared/display-options';
import { TemplateCollection } from '../../template/dto/template-collection';

export class NewDisplay {

  public name: string;
  public displaySize: Size;
  public defaultMenu: Menu | TemplateCollection;

  constructor() {
    this.name = '';
    this.displaySize = new Size();
    this.defaultMenu = null;
  }

  public convertToDTO(companyId, locationId: number): Display {
    const d = new Display();
    d.companyId = companyId;
    d.locationId = locationId;
    d.name = this.name;
    d.priority = 0;
    d.displaySize = this.displaySize;
    if (this.defaultMenu instanceof TemplateCollection) {
      this.initDisplayFromTemplateCollection(d);
    } else {
      this.initDisplayFromMenu(d);
    }
    return d;
  }

  private initDisplayFromMenu(display: Display) {
    const menu = this.defaultMenu as Menu;
    display.configurationIds = [menu?.id];
    display.configurations = [menu];
    display.options = DisplayOptions.defaultOptions(menu);
  }

  private initDisplayFromTemplateCollection(display: Display) {
    const tc = this.defaultMenu as TemplateCollection;
    display.templateCollections = [tc];
    display.templateCollectionIds = [tc?.id];
    display.options = DisplayOptions.defaultOptions(tc?.templates[0]);
  }

}
