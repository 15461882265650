<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="live-view-title-container">
        <div class="modal-title live-view-title">Live View: {{ liveViewName }}</div>
        <div class="live-view-button-container">
          <!--  Refresh Button  -->
          <lib-button-outlined class="ml-16px" (buttonClicked)="refreshLiveView()">
            Refresh Live View
          </lib-button-outlined>
          <!--  Download Button  -->
          <lib-button-outlined
            (buttonClicked)="viewModel.downloadClicked.next(null)"
            [disabled]="viewModel.isLoading$ | push"
            [loading]="viewModel.isLoading$ | push"
            [loadingOptions]="viewModel.loadingOpts$ | push">
            Download
          </lib-button-outlined>
        </div>
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Live View iFrame -->
    <div
      #liveViewWrapper
      [ngStyle]="{
        'overflow-x': enableHorizontalScroll ? 'scroll' : 'hidden',
        'overflow-y': enableVerticalScroll ? 'scroll' : 'hidden'
      }"
      class="live-view-wrapper">
      <!--  Loading animation inside frame  -->
      <app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>
      <!-- Add manual scroll tools whens zoomed out of frame -->
      <div class="live-view-iframe-container" [ngStyle]="getIFrameStyle()">
        <iframe allow="autoplay" [frameBorder]="'0'" [name]="iFrameName" [src]="url" id="live-view-frame"> </iframe>
      </div>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <!--  Range slider for zoom property  -->
    <div [hidden]="loadingOpts.isLoading" class="zoom-range">
      <!-- Reset scale button -->
      <button
        (click)="resetScale()"
        [hidden]="scaleIsUnchanged$ | async"
        class="bs-button dark-neutral-button rounded-icon-button reset-scale">
        <img [src]="'assets/icons/light/outline/refresh.svg'" alt="" class="icon-button-icon" />
      </button>
      <!--  Scale Range Slider  -->
      <app-range-slider (valueChanged)="userSetNewScale($event)" [options]="scaleRangeOptions$ | async">
      </app-range-slider>
    </div>
    <button (click)="cancel()" class="bs-button preferred-button">Done</button>
  </div>
</div>
