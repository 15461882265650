import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';

@Pipe({
  name: 'stackPrintJobIncludeChangesSince'
})
export class StackPrintJobIncludeChangesSincePipe implements PipeTransform {

  transform(job: BulkPrintJob, stack: Menu): number {
    return job?.cardStackPrintConfigMap?.get(stack?.id)?.includeChangesSince;
  }

}
