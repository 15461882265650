import { TypeDefinition } from './type-definition';
import { SectionLayoutType } from './section-layout-type';

/** The order of these keys determines the sort order of the columns */
export enum SectionColumnConfigProductInfoKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  Stock = 'Stock',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
}

export enum SectionColumnConfigPrimaryCannabinoidKey {
  THC = 'THC',
  CBD = 'CBD',
}

export enum SectionColumnConfigSecondaryCannabinoidKey {
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
  TAC = 'TAC',
}

export type SectionColumnConfigCannabinoidKey = SectionColumnConfigPrimaryCannabinoidKey
  | SectionColumnConfigSecondaryCannabinoidKey;

export enum SectionColumnConfigCollectiveTerpeneKey {
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
}

export enum SectionColumnConfigIndividualTerpeneKey {
  AlphaBisabolol = 'AlphaBisabolol',
  AlphaPinene = 'AlphaPinene',
  BetaCaryophyllene = 'BetaCaryophyllene',
  BetaEudesmol = 'BetaEudesmol',
  BetaMyrcene = 'BetaMyrcene',
  BetaPinene = 'BetaPinene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Camphene = 'Camphene',
  CaryophylleneOxide = 'CaryophylleneOxide',
  Delta3Carene = 'Delta3Carene',
  Eucalyptol = 'Eucalyptol',
  Geraniol = 'Geraniol',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Ocimene = 'Ocimene',
  ThreeCarene = 'ThreeCarene',
  Terpinene = 'Terpinene',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'TransNerolidol',
  Valencene = 'Valencene',
}

export type SectionColumnConfigTerpeneKey = SectionColumnConfigCollectiveTerpeneKey
  | SectionColumnConfigIndividualTerpeneKey;

export type SectionColumnConfigKey = SectionColumnConfigProductInfoKey
  | SectionColumnConfigCannabinoidKey
  | SectionColumnConfigTerpeneKey;

export enum ColumnOptionName {
  Asset = 'images',
  Badges = 'badges',
  Brand = 'brand',
  Classification = 'classification',
  Quantity = 'quantity',
  Size = 'size',
  QuantityAndSize = 'quantityAndSize',
  Stock = 'stock',
  Cbd = 'cbd',
  Thc = 'thc',
  CBDA = 'cnda',
  CBG = 'cbg',
  CBGA = 'cbga',
  CBL = 'cbl',
  CBLA = 'cbla',
  CBN = 'cbn',
  CBNA = 'cbna',
  CBT = 'cbt',
  CBC = 'cbc',
  CBCA = 'cbca',
  CBCV = 'cbcv',
  CBDV = 'cbdv',
  THC8 = 'thc8',
  THC9 = 'thc9',
  THCA = 'thca',
  THCV = 'thcv',
  TAC = 'tac',
  TopTerpene = 'topTerpene',
  TotalTerpene = 'totalTerpene',
  AlphaBisabolol = 'alphaBisabolol',
  AlphaPinene = 'alphaPinene',
  BetaCaryophyllene = 'betaCaryophyllene',
  BetaEudesmol = 'betaEudesmol',
  BetaMyrcene = 'betaMyrcene',
  BetaPinene = 'betaPinene',
  Bisabolol = 'bisabolol',
  Borneol = 'borneol',
  Camphene = 'camphene',
  CaryophylleneOxide = 'caryophylleneOxide',
  Delta3Carene = 'delta3Carene',
  Eucalyptol = 'eucalyptol',
  Geraniol = 'geraniol',
  Guaiol = 'guaiol',
  Humulene = 'humulene',
  Linalool = 'linalool',
  Limonene = 'limonene',
  Ocimene = 'ocimene',
  ThreeCarene = 'threeCarene',
  Terpinene = 'terpinene',
  Terpinolene = 'terpinolene',
  TransNerolidol = 'transNerolidol',
  Valencene = 'valencene',
  Price = 'price',
  SecondaryPrice = 'secondaryPrice',
}

export class SectionColumnConfigKeyType extends TypeDefinition {

  override value: SectionColumnConfigKey;

  public static isKey(key: string|SectionColumnConfigKey): boolean {
    const keys: SectionColumnConfigKey[] = [
      ...Object.values(SectionColumnConfigProductInfoKey),
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(SectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(SectionColumnConfigIndividualTerpeneKey)
    ];
    return keys.includes(key as SectionColumnConfigKey);
  }

  static getAllKeys(): SectionColumnConfigKey[] {
    return [
      ...Object.values(SectionColumnConfigProductInfoKey),
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(SectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(SectionColumnConfigIndividualTerpeneKey)
    ];
  }

  static getSortOrder(): SectionColumnConfigKey[] {
    return [
      SectionColumnConfigProductInfoKey.Asset,
      SectionColumnConfigProductInfoKey.Badges,
      SectionColumnConfigProductInfoKey.Brand,
      SectionColumnConfigProductInfoKey.StrainType,
      SectionColumnConfigProductInfoKey.Quantity,
      SectionColumnConfigProductInfoKey.Size,
      SectionColumnConfigProductInfoKey.QuantityAndSize,
      SectionColumnConfigProductInfoKey.Stock,
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(SectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(SectionColumnConfigIndividualTerpeneKey),
      SectionColumnConfigProductInfoKey.Price,
      SectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
  }

  public isStockKey(): boolean {
    return this.value === SectionColumnConfigProductInfoKey.Stock;
  }

  public isCannabinoidKey(): boolean {
    return this.isPrimaryCannabinoidKey() || this.isSecondaryCannabinoidKey();
  }

  public isPrimaryCannabinoidKey(): boolean {
    return Object.values(SectionColumnConfigPrimaryCannabinoidKey).includes(
      this.value as SectionColumnConfigPrimaryCannabinoidKey
    );
  }

  public isSecondaryCannabinoidKey(): boolean {
    return Object.values(SectionColumnConfigSecondaryCannabinoidKey).includes(
      this.value as SectionColumnConfigSecondaryCannabinoidKey
    );
  }

  public isTerpeneKey(): boolean {
    return this.isCollectiveTerpeneKey() || this.isIndividualTerpeneKey();
  }

  public isCollectiveTerpeneKey(): boolean {
    return Object.values(SectionColumnConfigCollectiveTerpeneKey).includes(
      this.value as SectionColumnConfigCollectiveTerpeneKey
    );
  }

  public isIndividualTerpeneKey(): boolean {
    return Object.values(SectionColumnConfigIndividualTerpeneKey).includes(
      this.value as SectionColumnConfigIndividualTerpeneKey
    );
  }

  public static disabledForGridLayout(key: string, layoutType: SectionLayoutType): boolean {
    const colsDisabledForGridLayout = [
      SectionColumnConfigProductInfoKey.Quantity,
      SectionColumnConfigProductInfoKey.QuantityAndSize,
      SectionColumnConfigProductInfoKey.Size,
      SectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
    const disableForGridLayout = colsDisabledForGridLayout.includes(key as SectionColumnConfigProductInfoKey);
    return layoutType?.isAnyGrid() && disableForGridLayout;
  }

}
