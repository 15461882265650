<app-display-menu-section-options-container
  [menu]="menu"
  [productOverflowTitle]="productOverflowTitle"
  (openInformationModal)="openInformationModal.emit($event)">
  <app-menu-added-to-display-form
    [mergeKey]="mergeKey"
    [bindTo]="bindTo"
    [menu]="menu"
    [loopDuration]="loopDuration"
    [nMenusOnDisplay]="nMenus"
    [menuIsPartOfCollection]="menuIsPartOfCollection"
    [isLooping]="menu.isLoopingMarketingContent">
  </app-menu-added-to-display-form>
</app-display-menu-section-options-container>
