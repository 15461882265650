import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';

@Component({
  selector: 'app-collection-details-form',
  templateUrl: './collection-details-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionDetailsFormComponent {

  @Input() bindTo: TemplateCollection;
  @Input() mergeKey: string;

  defaultSizes$ = window.types.digitalSizeTypes$;
  orientations$ = window.types.orientations$;

}
