<div [hidden]="!(viewModel.showBackgroundAsset$ | push)" class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Background Asset</p>
    <p class="bs-edit-section-description">
      <ng-container *ngIf="allowVideo">Upload an eye catching background image or video.</ng-container>
      <ng-container *ngIf="!allowVideo">Upload an eye catching background image.</ng-container>
    </p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8 menu-background-image-container position-relative">
    <app-loading
      [hidden]="!(viewModel.backgroundAssetLoading$ | push)"
      [options]="viewModel?.backgroundAssetLoadingOpts$ | push">
    </app-loading>
    <app-asset-preview
      (primaryButtonPressed)="handleDeleteBackgroundImage()"
      [asset]="viewModel.menuBackgroundAsset$ | push"
      (secondaryButtonPressed)="handleChangeBackgroundImage()"
      (refreshButtonPressed)="handleRefreshBackgroundImage()"
      [isDisabledByTemplates]="viewModel.isTemplatedMenu$ | push"
      [formPristine]="formPristine"
      [hidden]="!(viewModel.hasBackgroundAsset$ | push)"
      [options]="viewModel.backgroundPreviewOptions$ | push">
    </app-asset-preview>
    <app-upload-asset
      #backgroundImage
      [hidden]="viewModel.hasBackgroundAsset$ | push"
      [allowImage]="true"
      [allowVideo]="allowVideo"
      [isDisabledByTemplates]="viewModel.isTemplatedMenu$ | push"
      [formPristine]="formPristine"
      [maxAssets]="1"
      [parentHandler]="this">
    </app-upload-asset>
    <div [hidden]="!(viewModel.hasBackgroundAsset$ | push)">
      <lib-reactive-form-group
        [mergeKey]="mergeKey"
        [bindTo]="viewModel.section$ | push"
        [globalDisabledTooltip]="viewModel.isTemplatedMenu$ | push | templatedMenuDisabledFormControlTooltip">
        <lib-reactive-form-column-layout nColumns="3">
          <lib-reactive-form-drop-down
            [inputName]="'objectFit'"
            [label]="'Image Fit'"
            [placeholder]="'Select Image Fit'"
            [inlineLabel]="false"
            [disabled]="viewModel.isTemplatedMenu$ | push"
            [required]="true"
            [tooltip]="'Determines if the image should cover or fit within the background container.'"
            [dropdowns]="viewModel.objectFitTypes$ | push"
            [bindingProperty]="'metadata.objectFit'"
            (valueChanged)="viewModel.connectToImageObjectFit($event)">
          </lib-reactive-form-drop-down>
          <lib-reactive-form-number
            #imageWidth
            [inputName]="'width'"
            [label]="'Image Width'"
            [placeholder]="'Enter image width (%)'"
            [inlineLabel]="false"
            [integersOnly]="true"
            [required]="true"
            [minValue]="1"
            [maxValue]="100"
            [tooltip]="'Determines what percentage the asset width expands within the background container.'"
            [disabled]="(viewModel.imageWidthDisabled$ | push) || viewModel.isTemplatedMenu$ | push"
            [disabledTooltip]="'Image width is disabled when image fit equals cover.'"
            [bindingProperty]="'metadata.width'"
            [customValueParser]="ClientTypes.ParserUtils.parseAsString"
            (valueChanged)="viewModel.connectToImageWidth($event)">
          </lib-reactive-form-number>
          <lib-reactive-form-drop-down
            #imageAlignment
            [inputName]="'imageAlignment'"
            [label]="'Image Alignment'"
            [placeholder]="'Select Image Alignment'"
            [inlineLabel]="false"
            [disabled]="(viewModel.imageAlignmentDisabled$ | push) || (viewModel.isTemplatedMenu$ | push)"
            [required]="true"
            [disabledTooltip]="viewModel.imageAlignmentDisabledTooltip$ | push"
            [tooltip]="'Determines where the image should be horizontally aligned.'"
            [dropdowns]="viewModel.imageAlignments$ | push"
            [bindingProperty]="'metadata.imageAlignment'">
          </lib-reactive-form-drop-down>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
    </div>
  </div>
</div>

<hr [hidden]="!(viewModel.showBackgroundAsset$ | push)" class="mt-24px" />
