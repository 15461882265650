import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { TaxRate } from '../models/product/dto/tax-rate';
import { TaxGroup } from '../models/product/dto/tax-group';

@Injectable({ providedIn: 'root' })
export class TaxesAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Taxes';

  public CreateTaxRate(locationId: number, req: TaxRate): Observable<TaxRate> {
    const url = Endpoints.CreateTaxRate();
    const additionalHeaders = { locationId };
    return this.apiClient.postObj(TaxRate, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateTaxRate', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateTaxRate(locationId: number, req: TaxRate): Observable<TaxRate> {
    const url = Endpoints.UpdateTaxRate();
    const additionalHeaders = { locationId };
    return this.apiClient.postObj(TaxRate, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateTaxRate', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteTaxRate(locationId: number, req: TaxRate): Observable<string> {
    const url = Endpoints.DeleteTaxRate();
    const additionalHeaders = { locationId };
    return this.apiClient.deleteStr(url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteTaxRate', err));
        return throwError(() => err);
      })
    );
  }

  public CreateTaxGroup(req: TaxGroup): Observable<TaxGroup> {
    const url = Endpoints.CreateTaxGroup();
    const locationId = req?.locationId;
    const additionalHeaders = { locationId };
    return this.apiClient.postObj(TaxGroup, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateTaxGroup', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateTaxGroup(req: TaxGroup): Observable<TaxGroup> {
    const url = Endpoints.UpdateTaxGroup();
    const locationId = req?.locationId;
    const additionalHeaders = { locationId };
    return this.apiClient.postObj(TaxGroup, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateTaxGroup', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteTaxGroup(locationId: number, req: TaxGroup): Observable<string> {
    const url = Endpoints.DeleteTaxGroup();
    const additionalHeaders = { locationId };
    return this.apiClient.deleteStr(url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteTaxGroup', err));
        return throwError(() => err);
      })
    );
  }

  public GetTaxRates(locationId: number): Observable<TaxRate[]> {
    const url = Endpoints.GetTaxRates(locationId);
    const additionalHeaders = { locationId };
    return this.apiClient.getArr<TaxRate>(TaxRate, url, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetTaxRates', err));
        return throwError(() => err);
      })
    );
  }

  public GetTaxGroups(locationId: number): Observable<TaxGroup[]> {
    const url = Endpoints.GetTaxGroups(locationId);
    const additionalHeaders = { locationId };
    return this.apiClient.getArr<TaxGroup>(TaxGroup, url, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetTaxGroups', err));
        return throwError(() => err);
      })
    );
  }

}
