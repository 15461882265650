import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CompanyBadgeViewModel } from './company-badge-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmation } from '../../../../modals/modal-confirmation';

@Component({
  selector: 'app-company-badge',
  templateUrl: './company-badge.component.html',
  styleUrls: [
    './company-badge.component.scss',
    './company-badge.responsive.scss'
  ],
  providers: [ CompanyBadgeViewModel ],
})
export class CompanyBadgeComponent extends BaseComponent implements OnChanges {

  @Input() badge: HydratedVariantBadge;
  @Input() editable: boolean = false;
  @Input() deletable: boolean = false;
  @Input() showSmartFilterIndicator: boolean = true;
  @Output() editClicked: EventEmitter<HydratedVariantBadge> = new EventEmitter();
  @Output() deleteClicked: EventEmitter<HydratedVariantBadge> = new EventEmitter();

  loadingOptions: LoadingOptions = LoadingOptions.defaultInBadge();

  constructor(
    public viewModel: CompanyBadgeViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  override setupViews() {
    this.setBadge(this.badge);
  }

  private setBadge(t: HydratedVariantBadge) {
    if (t) this.viewModel.setBadge(t);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setBadge(this.badge);
  }

  deleteBadge() {
    const opts = new ConfirmationOptions();
    opts.title = 'Delete Badge';
    opts.bodyText = `Are you sure you want to delete ${this.badge.name}? `
      + `This will remove the badge from all products and menus it is active on.`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete Badge';
    const confirmation = (cont) => {
      if (cont) {
        this.loadingOptions.isLoading = true;
        this.deleteClicked.emit(this.badge);
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

}
