import { Pipe, PipeTransform } from '@angular/core';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';

@Pipe({
  name: 'isCollection'
})
export class IsCollectionPipe implements PipeTransform {

  transform(value: any): TemplateCollection | null {
    return value instanceof TemplateCollection ? value : null;
  }

}
