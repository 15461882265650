<tr
  class="table-item"
  [class.expanded-parent]="expanded$ | async"
  [class.odd]="(index + 1) % 2 !== 0"
  (click)="viewModel?.productClicked(rowData)">
  <td>
    {{ rowData | getProductTitle | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getDisplayName() | removeDashes)"
      [oldValue]="rowData?.name">
    </app-table-property-changed>
  </td>
  <td>{{ brand$ | async | doubleDashIfEmpty }}</td>
  <td>{{ rowData?.getProductTypeString() | doubleDashIfEmpty }}</td>
  <td>
    {{
      rowData?.getStrainClassification()
        | presentClassificationToUser : viewModel.classificationMap$
        | async
        | doubleDashIfEmpty
    }}
  </td>
  <td>
    {{ rowData | presentProductQuantityInStockToUser : viewModel.inventoryProvider$ | async | doubleDashIfEmpty }}
  </td>
  <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
    {{ rowData?.getMinTHCWithUnits() | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getMinTHC() | removeDashes)"
      [oldValue]="rowData?.getMinTHCWithoutDisplayAttributes()">
    </app-table-property-changed>
  </td>
  <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
    {{ rowData?.getMaxTHCWithUnits() | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getMaxTHC() | removeDashes)"
      [oldValue]="rowData?.getMaxTHCWithoutDisplayAttributes()">
    </app-table-property-changed>
  </td>
  <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
    {{ rowData?.getMinCBDWithUnits() | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getMinCBD() | removeDashes)"
      [oldValue]="rowData?.getMinCBDWithoutDisplayAttributes()">
    </app-table-property-changed>
  </td>
  <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
    {{ rowData?.getMaxCBDWithUnits() | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getMaxCBD() | removeDashes)"
      [oldValue]="rowData?.getMaxCBDWithoutDisplayAttributes()">
    </app-table-property-changed>
  </td>
  <td *ngIf="!(viewModel.displaysUnitsInRanges$ | async)">
    {{ rowData?.getTHCWithUnits() | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getTHC() | removeDashes)"
      [oldValue]="rowData?.getTHCWithoutDisplayAttributes()">
    </app-table-property-changed>
  </td>
  <td *ngIf="!(viewModel.displaysUnitsInRanges$ | async)">
    {{ rowData?.getCBDWithUnits() | doubleDashIfEmpty }}
    <app-table-property-changed
      *ngIf="!!(rowData?.displayAttributes?.getCBD() | removeDashes)"
      [oldValue]="rowData?.getCBDWithoutDisplayAttributes()">
    </app-table-property-changed>
  </td>
  <td>{{ priceNoDollarSigns$ | async | toFixedDecimal : 2 | addDollarSignToPrice | doubleDashIfEmpty }}</td>
  <td>
    <app-label-inflator
      #productLabel
      [zoom]="labelZoom$ | async"
      [displayLabelInterface]="productLabelInterface$ | async">
    </app-label-inflator>
  </td>
  <td>
    <app-table-badges [item]="rowData" [badges]="badges$ | async"></app-table-badges>
  </td>
</tr>

<ng-container *ngIf="expanded$ | async">
  <tr
    *ngFor="let variant of rowDataVariants$ | async; let nestedRowIndex = index"
    class="nested-table-data-cell"
    [class.space-between-product-and-variants]="nestedRowIndex === 0"
    (click)="openEditVariantModal(variant)">
    <td>
      <div fxLayout="row">
        <div fxFlex fxLayout="row">
          <app-display-name
            [displayName]="variant?.getDisplayName()"
            [isMedical]="variant?.isMedical"></app-display-name>
          <app-table-property-changed
            *ngIf="!!(variant?.displayAttributes?.getDisplayName() | removeDashes)"
            [oldValue]="variant?.name">
          </app-table-property-changed>
        </div>
        <div class="variant-sizing">{{ variant | variantFormattedSizing }}</div>
      </div>
    </td>
    <td>{{ variant?.brand | doubleDashIfEmpty }}</td>
    <td>{{ variant?.variantType | doubleDashIfEmpty }}</td>
    <td>
      {{
        variant?.classification | presentClassificationToUser : viewModel.classificationMap$ | async | doubleDashIfEmpty
      }}
    </td>
    <td>
      {{ variant | presentVariantQuantityInStockToUser : viewModel.inventoryProvider$ | async | doubleDashIfEmpty }}
    </td>
    <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
      {{ variant | getMinCannabinoidWithUnits : 'THC' | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!(variant?.displayAttributes?.getMinTHC() | removeDashes)"
        [oldValue]="variant?.getMinCannabinoidWithoutDisplayAttributes('THC')">
      </app-table-property-changed>
    </td>
    <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
      {{ variant | getMaxCannabinoidWithUnits : 'THC' | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!(variant?.displayAttributes?.getMaxTHC() | removeDashes)"
        [oldValue]="variant?.getMaxCannabinoidWithoutDisplayAttributes('THC')">
      </app-table-property-changed>
    </td>
    <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
      {{ variant | getMinCannabinoidWithUnits : 'CBD' | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!(variant?.displayAttributes?.getMinCBD() | removeDashes)"
        [oldValue]="variant?.getMinCannabinoidWithoutDisplayAttributes('CBD')">
      </app-table-property-changed>
    </td>
    <td *ngIf="viewModel.displaysUnitsInRanges$ | async">
      {{ variant | getMaxCannabinoidWithUnits : 'CBD' | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!(variant?.displayAttributes?.getMaxCBD() | removeDashes)"
        [oldValue]="variant?.getMaxCannabinoidWithoutDisplayAttributes('CBD')">
      </app-table-property-changed>
    </td>
    <td *ngIf="!(viewModel.displaysUnitsInRanges$ | async)">
      {{ variant | getCannabinoidWithUnits : 'THC' | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!(variant?.displayAttributes?.getTHC() | removeDashes)"
        [oldValue]="variant?.getCannabinoidWithoutDisplayAttributes('THC')">
      </app-table-property-changed>
    </td>
    <td *ngIf="!(viewModel.displaysUnitsInRanges$ | async)">
      {{ variant | getCannabinoidWithUnits : 'CBD' | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!(variant?.displayAttributes?.getCBD() | removeDashes)"
        [oldValue]="variant?.getCannabinoidWithoutDisplayAttributes('CBD')">
      </app-table-property-changed>
    </td>
    <td>
      <span [class.sale-price]="locationId$ | async | isVariantOnSale : variant : (priceFormat$ | async)">
        {{
          locationId$
            | async
            | variantPrice : variant : (priceFormat$ | async) : false
            | toFixedDecimal : 2
            | addDollarSignToPrice
            | doubleDashIfEmpty
        }}
      </span>
      <span *ngIf="locationId$ | async | isVariantOnSale : variant : (priceFormat$ | async)" class="original-price">
        {{
          locationId$
            | async
            | variantOriginalPrice : variant : (priceFormat$ | async)
            | toFixedDecimal : 2
            | addDollarSignToPrice
            | doubleDashIfEmpty
        }}
      </span>
    </td>
    <td>
      <app-label-inflator
        #variantLabel
        *ngIf="variant?.computedLabelForProductTable | isSaleSystemLabel; else isNotASaleLabel"
        [zoom]="labelZoom$ | async"
        [displaySaleLabelInterface]="variant | variantSaleLabelInterface : dataForLabelInterface$ | async">
      </app-label-inflator>
      <ng-template #isNotASaleLabel>
        <app-label-inflator
          [zoom]="labelZoom$ | async"
          [displayLabelInterface]="variant | variantLabelInterface : dataForLabelInterface$ | async">
        </app-label-inflator>
      </ng-template>
    </td>
    <td>
      <app-table-badges [item]="variant" [badges]="variant | variantBadges : rowData$ | async"> </app-table-badges>
    </td>
  </tr>
</ng-container>
