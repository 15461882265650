import { Variant } from '../../../../../models/product/dto/variant';
import { NumberUtils } from '../../../../../utils/number-utils';

export class EditVariantFormObject {

  public variant: Variant;
  public THC: number;
  public minTHC: number;
  public maxTHC: number;
  public CBD: number;
  public minCBD: number;
  public maxCBD: number;
  public companySecondaryPrice: number;
  public locationSecondaryPrice: number;

  constructor(variant: Variant, companyId, locationId: number) {
    this.variant = variant;
    this.THC = NumberUtils.parseFloatOrNull(variant.THC);
    this.minTHC = NumberUtils.parseFloatOrNull(variant.minTHC);
    this.maxTHC = NumberUtils.parseFloatOrNull(variant.maxTHC);
    this.CBD = NumberUtils.parseFloatOrNull(variant.CBD);
    this.minCBD = NumberUtils.parseFloatOrNull(variant.minCBD);
    this.maxCBD = NumberUtils.parseFloatOrNull(variant.maxCBD);
    // This helper ensures that placeholders are shown if no value is present
    this.companySecondaryPrice = NumberUtils.ifZeroReturnNull(
      variant?.locationPricing?.find(lp => lp.locationId === companyId)?.secondaryPrice
    );
    this.locationSecondaryPrice = NumberUtils.ifZeroReturnNull(
      variant?.locationPricing?.find(lp => lp.locationId === locationId)?.secondaryPrice
    );
  }

  getVariant(companyId: number = 0, locationId: number = 0): Variant {
    if (this.variant.isAccessory()) {
      this.variant.THC = null;
      this.variant.minTHC = null;
      this.variant.maxTHC = null;
      this.variant.CBD = null;
      this.variant.minCBD = null;
      this.variant.maxCBD = null;
      this.variant.strain = null;
      this.variant.classification = null;
    } else {
      this.variant.THC = (this.THC !== null && this.THC !== undefined) ? `${this.THC}` : null;
      this.variant.minTHC = (this.minTHC !== null && this.minTHC !== undefined) ? `${this.minTHC}` : null;
      this.variant.maxTHC = (this.maxTHC !== null && this.maxTHC !== undefined) ? `${this.maxTHC}` : null;
      this.variant.CBD = (this.CBD !== null && this.CBD !== undefined) ? `${this.CBD}` : null;
      this.variant.minCBD = (this.minCBD !== null && this.minCBD !== undefined) ? `${this.minCBD}` : null;
      this.variant.maxCBD = (this.maxCBD !== null && this.maxCBD !== undefined) ? `${this.maxCBD}` : null;
    }
    this.variant?.locationPricing?.forEach(lp => {
      if (companyId > 0 && lp.locationId === companyId) {
        lp.secondaryPrice = this.companySecondaryPrice;
      } else if (locationId > 0 && lp.locationId === locationId) {
        lp.secondaryPrice = this.locationSecondaryPrice;
      }
    });
    return this.variant;
  }

}
