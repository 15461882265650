import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { AnimatorService } from '../../../../../services/animator/animator.service';
import { Location } from '../../../../../models/company/dto/location';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';

@Injectable()
export class LocationCardViewModel extends BaseViewModel {

  constructor(
    private router: Router,
    private userDomainModel: UserDomainModel,
    private locationDomainModel: LocationDomainModel,
    private animatorService: AnimatorService,
  ) {
    super();
  }

  public defaultLocationId$ = this.userDomainModel.userDefaultLocationId$;

  editMenus(l: Location) {
    if (l !== null) this.locationDomainModel.setCurrentLocation(l);
    this.router.navigate(['/menus/digital'], { replaceUrl: true }).then();
    this.animateCurrentLocation();
  }

  editDisplays(l: Location) {
    if (l !== null) this.locationDomainModel.setCurrentLocation(l);
    this.router.navigate(['displays']).then(() => {});
    this.animateCurrentLocation();
  }

  animateCurrentLocation() {
    this.animatorService.animateLocationPicker();
  }

}
