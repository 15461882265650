import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss']
})
export class WarningBannerComponent extends BaseComponent {

  constructor() {
    super();
  }

}
