import { LookAheadItem } from 'src/app/views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { Deserializable } from '../../protocols/deserializable';

export class VariantBadge implements Deserializable, LookAheadItem {

  public companyId: number;
  public id: string;
  public name: string;
  public category: string;
  public subCategory: string;
  public smartFilterIds: string[];
  public removeExistingOnSync: boolean;

  constructor(
    companyId?: number,
    badgeId?: string,
    badgeName?: string,
    smartFilterIds?: string[],
    removeExistingOnSync?: boolean
  ) {
    this.companyId = companyId;
    this.id = badgeId;
    this.name = badgeName;
    this.smartFilterIds = smartFilterIds;
    this.removeExistingOnSync = removeExistingOnSync;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  public onDeserialize() {}

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/VariantBadgeDTO.go
  public onSerialize() {
    const dto = Object.create(VariantBadge.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.name = this.name;
    dto.category = this.category;
    dto.subCategory = this.subCategory;
    dto.smartFilterIds = this.smartFilterIds;
    dto.removeExistingOnSync = this.removeExistingOnSync;
    return dto;
  }

  isCurated(): boolean {
    return this.companyId === -1;
  }

  nonCuratedSmartBadgeWithRemoveExistingOnSyncEnabled(): boolean {
    return !this.isCurated() && !!this.smartFilterIds?.length && this.removeExistingOnSync;
  }

}
