import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-edit-template-locations-section-container',
  templateUrl: './edit-template-locations-section-container.component.html',
  styleUrls: [
    './edit-template-locations-section-container.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLocationsSectionContainerComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() hasActiveLocationIds: boolean;
  @Output() editTemplateLocations = new EventEmitter<void>(true);

}
