<div class="sticky-body-container" [style.overflow-y]="'unset'">
  <div class="modal-search-results-container">
    <div *rxFor="let menu of searchedItems; trackBy: 'id'" class="add-menu-list-item-container">
      <div
        class="add-menu-list-item"
        [class.disabled]="menu | menuIncludedInDisplay : display"
        [class.draft-template-disabled]="display | isPublishedCollectionWithDraftTemplate : menu"
        [ngbTooltip]="tooltip"
        [disableTooltip]="!(display | isPublishedCollectionWithDraftTemplate : menu)"
        [container]="'body'"
        [placement]="'auto'"
        [openDelay]="250"
        (click)="itemClicked.emit(menu.id)">
        <img
          *ngIf="!collectionMode"
          [src]="!!menu.templateId ? ClientTypes.MenuIconSrc.TemplatedMenu : ClientTypes.MenuIconSrc.Menu"
          [style.height.rem]="1"
          alt="" />
        <div class="reorder-value ellipsis-nowrap-text" [style.width]="'fit-content'">
          {{ menu?.name }}
        </div>
        <ng-container *ngIf="(menu | isTemplate)?.status === ClientTypes.TemplateStatus.Draft">
          <div id="template-draft-icon" class="pb-2px">
            <img
              [src]="'assets/icons/dark/outline/pencil-alt.svg'"
              alt=""
              [style.width.rem]="1.15"
              [ngbTooltip]="tooltip"
              [disableTooltip]="(display | isCollection)?.status === ClientTypes.TemplateStatus.Published"
              [container]="'body'"
              [placement]="'auto'" />
          </div>
        </ng-container>
        <div [style.margin-inline-start]="'auto'" class="reorder-icon">
          <img
            [hidden]="
              (menu | menuIncludedInDisplay : display) ||
              (previouslySelectedItemIds | includes : menu?.id) ||
              (display | isPublishedCollectionWithDraftTemplate : menu)
            "
            [src]="'assets/icons/dark/outline/plus.svg'"
            alt="" />
          <img
            [hidden]="!(previouslySelectedItemIds | includes : menu?.id)"
            [src]="'assets/icons/dark/outline/check.svg'"
            alt="" />
          <div [hidden]="!(menu | menuIncludedInDisplay : display)" class="menu-added">Added</div>
        </div>
      </div>
    </div>
    <div *ngIf="searchedItems | emptyArray" class="pt-16px">
      {{ emptyStateText }}
    </div>
  </div>
</div>
<ng-template #tooltip>{{ display | draftTemplateTooltip }}</ng-template>
