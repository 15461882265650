import type { Menu } from '../../menu/dto/menu';
import type { Label } from '../label';
import type { HydratedVariantBadge } from '../../product/dto/hydrated-variant-badge';

export class PillItem {

  public clickable: boolean;
  public selected: boolean;
  public disabled: boolean;
  public text: string;
  public imgSrc: string;
  public linkedItem: Menu | Label | HydratedVariantBadge;

  constructor(
    text: string = '',
    clickable: boolean = true,
    selected: boolean = false,
    disabled: boolean = false,
    imgSrc: string = '',
    linkedItem: Menu | Label | HydratedVariantBadge = null
  ) {
    this.clickable = clickable;
    this.selected = selected;
    this.disabled = disabled;
    this.text = text;
    this.imgSrc = imgSrc;
    this.linkedItem = linkedItem;
  }

}

export interface PillItemInterface {
  getAsPillItem(clickable: boolean, selected: boolean, disabled: boolean): PillItem;
}
