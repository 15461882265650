import { Component, Injector } from '@angular/core';
import { EditDriveThruMenuViewModel } from './edit-drive-thru-menu-view-model';
import { EditMarketingMenuComponent } from '../edit-marketing-menu.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-drive-thru-menu',
  templateUrl: './edit-drive-thru-menu.component.html',
  styleUrls: [
    './edit-drive-thru-menu.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditDriveThruMenuViewModel]
})
export class EditDriveThruMenuComponent extends EditMarketingMenuComponent {

  constructor(
    public override viewModel: EditDriveThruMenuViewModel,
    ngbModal: NgbModal,
    injector: Injector,
    toastService: ToastService,
    router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    super(viewModel, ngbModal, injector, toastService, router, activatedRoute);
  }

}
