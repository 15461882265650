import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DateUtils } from '../../../../utils/date-utils';

@Component({
  selector: 'app-smart-filter-large-sync-button',
  templateUrl: './smart-filter-large-sync-button.component.html',
  styleUrls: ['./smart-filter-large-sync-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterLargeSyncButtonComponent implements OnInit, OnChanges {

  @Input() timestamp: number;
  @Input() syncing: boolean;
  @Input() disabled: boolean = false;
  @Output() buttonClicked = new EventEmitter<void>(true);
  private _timestamp = new BehaviorSubject<number>(null);
  public time$ = this._timestamp.notNull().pipe(
    map(t => {
      return DateUtils.formatUnixToLastSyncTime(t);
    }),
    startWith('Sync Smart Filters')
  );

  constructor() {
  }

  ngOnInit() {
    this._timestamp.next(this.timestamp);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timestamp) this._timestamp.next(this.timestamp);
  }

}
