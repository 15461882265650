import { Pipe, PipeTransform } from '@angular/core';
import { BaseDisplay } from '../../../models/display/dto/base-display';
import { TemplateCollection } from '../../../models/template/dto/template-collection';

@Pipe({
  name: 'draftTemplateTooltip'
})
export class DraftTemplateTooltipPipe implements PipeTransform {

  transform(display: BaseDisplay): string {
    return display instanceof TemplateCollection && display.isPublished()
      ? `This template is in 'Draft' and cannot be added to this collection.`
      : `This template is in 'Draft'`;
  }

}
