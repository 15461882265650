import { Deserializable } from '../../protocols/deserializable';
import { TaxRate } from './tax-rate';
import { exists } from '../../../functions/exists';
import { window } from 'ngx-bootstrap/utils';
import { Selectable } from '@mobilefirstdev/reactive-form/lib/components/reactive-form-item/reactive-form-drop-down/selectable';
import { SortUtils } from '../../../utils/sort-utils';

export class TaxGroup implements Deserializable, Selectable {

  constructor(
    companyId?: number,
    locationId?: number,
    name?: string,
    taxRateLayerMap?: Map<number, string[]>
  ) {
    if (exists(companyId)) this.companyId = companyId;
    if (exists(locationId)) this.locationId = locationId;
    if (exists(name)) this.name = name;
    if (exists(taxRateLayerMap)) {
      this.taxRateIds = [...(taxRateLayerMap?.values() || [])]?.flatMap(x => x)?.unique() ?? [];
      this.taxRateLayerMap = taxRateLayerMap;
    }
  }

  public id: string;
  public companyId: number;
  public locationId: number;
  public name: string;
  public taxRateIds: string[];
  public taxRateLayerMap: Map<number, string[]>;
  public taxRates: TaxRate[];
  public dateCreated: number;
  public lastModified: number;

  onDeserialize() {
    this.taxRateLayerMap = window?.injector?.Deserialize.genericArrayMap(this.taxRateLayerMap);
    this.taxRates = window?.injector?.Deserialize.arrayOf(TaxRate, this.taxRates);
  }

  onSerialize() {
    const dto = Object.create(TaxGroup.prototype);
    dto.id = this.id;
    dto.companyId = this.companyId;
    dto.locationId = this.locationId;
    dto.name = this.name;
    dto.taxRateIds = this.taxRateIds;
    dto.taxRateLayerMap = this.taxRateLayerMap;
    return dto;
  }

  taxRateNamesSeparatedBy(delimiter: string): string {
    return this.taxRates?.filter(taxRate => this.taxRateIds?.includes(taxRate?.id))
      .sort(SortUtils.nameAscending)
      .map(taxRate => taxRate.name)
      .join(delimiter);
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  updateInformation(name: string, taxRateLayerMap: Map<number, string[]>) {
    const filteredEmptyLayers = [...taxRateLayerMap.entries()].filter(([_, value]) => value.length > 0);
    this.name = name;
    this.taxRateLayerMap = new Map<number, string[]>(filteredEmptyLayers);
    this.taxRateIds = [...(this.taxRateLayerMap?.values() || [])]?.flatMap(x => x)?.unique() ?? [];
  }

}
