<div class="modal-body sticky">
  <div
    class="sticky-body-container bg-white pt-16px pb-8px"
    [class.pb-16px]="displayContentOption === ClientTypes.DisplayContentOption.Menu"
    appStickyAutoPosition
    [stickyCollectionKey]="'select-menu-or-collection'"
    [stickyZ]="9"
    [stickyOrder]="0"
    [style.overflow-y]="'unset'">
    <app-search-with-look-ahead
      [placeHolder]="searchPlaceholder"
      [lookAheadItems]="itemsToSearch"
      [searchTheseProperties]="['name']"
      [disablePopper]="true"
      [nLookAheadItems]="10000"
      [outputAllItemsWhenSearchIsEmpty]="true"
      (lookAheadHits)="viewModel.connectToSearchedItems($event)">
    </app-search-with-look-ahead>
  </div>
  <app-collection-results
    *ngIf="displayContentOption === ClientTypes.DisplayContentOption.TemplateCollection"
    [display]="display"
    [emptyStateText]="emptyStateText"
    [previouslySelectedItemIds]="itemIdsToAdd"
    [searchedItems]="viewModel.searchedTemplateCollections$ | push"
    (itemClicked)="itemClicked.emit($event)">
  </app-collection-results>
  <app-menu-results
    *ngIf="displayContentOption === ClientTypes.DisplayContentOption.Menu"
    [collectionMode]="collectionMode"
    [display]="display"
    [emptyStateText]="emptyStateText"
    [previouslySelectedItemIds]="itemIdsToAdd"
    [searchedItems]="viewModel.searchedMenus$ | push"
    (itemClicked)="itemClicked.emit($event)">
  </app-menu-results>
  <br />
</div>
