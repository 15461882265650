import { switchMap } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { MarketingMenuType } from '../../../../../../../models/enum/dto/marketing-menu-type.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MenuSubtype } from '../../../../../../../models/enum/dto/menu-subtype';

@Injectable()
export class CardTypeSelectionViewModel extends BaseViewModel {

  private _menuType = new BehaviorSubject<MenuSubtype>(null);
  public menuType$ = this._menuType as Observable<MenuSubtype>;
  connectToMenuType = (menuType: MenuSubtype) => this._menuType.next(menuType);

  public cardsToDisplay$ = this.menuType$.pipe(
    switchMap(menuType => {
      switch (menuType) {
        case MarketingMenuType.DriveThru:
          return window?.types?.comboMenuCardTypes$;
        case MarketingMenuType.Category:
          return window?.types?.featuredCategoryMenuCardTypes$;
        default:
          // If no menu type is explicitly defined, then do not return any card types
          return of([]);
      }
    })
  );

}
