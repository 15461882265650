<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (formChanged$)="onFormChanges($event)">
  <div class="combo-product-price-container">
    <div [ngClass]="{ 'stroke-through': !!(viewModel.variantSalePrice$ | async) }" class="combo-product-price">
      Regular Price:
      <span class="price-info">({{ viewModel.variantRegularPriceInfo$ | async }})</span>
      <span class="price">{{ viewModel.variantRegularPrice$ | async }}</span>
    </div>
    <div [hidden]="!(viewModel.variantSalePrice$ | async)" class="combo-product-price">
      Sale Price:
      <span class="price-info">({{ viewModel.variantSalePriceInfo$ | async }})</span>
      <span class="price sale">{{ viewModel.variantSalePrice$ | async }}</span>
    </div>
  </div>
  <lib-reactive-form-color-square
    class="color-picker"
    [presetColors]="colorPalette$ | async"
    [inputName]="'colorOverride'"
    [label]="'Card Color'"
    [bindingProperty]="'color'"
    [disabled]="isTemplatedMenu"
    [inlineLabel]="true"
    [inlinePercentage]="80">
  </lib-reactive-form-color-square>
  <lib-reactive-form-number
    class="sale-price-override"
    [inputName]="'priceOverride'"
    [hideLabel]="false"
    [label]="'Sale Price Override'"
    [tooltip]="'Override this products sale price. Setting this to 0 will hide the price.'"
    [inlineLabel]="true"
    [placeholder]="viewModel.pricePlaceHolder$ | async"
    [required]="false"
    [disabled]="isTemplatedMenu"
    [inlinePercentage]="30"
    [bindingProperty]="'price'">
  </lib-reactive-form-number>
  <div class="hide-override-description">
    <lib-reactive-form-column-layout [nColumns]="1">
      <lib-reactive-form-label
        class="combo-product-form-input-label"
        [label]="'Description Override'"
        [tooltip]="'This allows you to override this products description text.'">
      </lib-reactive-form-label>
      <lib-reactive-form-switch
        #enabled
        class="hide-override-description-switch"
        [style.margin-bottom.rem]="0.5"
        [inputName]="'hideDescOverride'"
        [inlineLabel]="true"
        [label]="'Hide'"
        [inlinePercentage]="5"
        [disabled]="isTemplatedMenu"
        (valueChanged)="viewModel.setHideDesc($event)"
        [bindingProperty]="'hideDesc'">
      </lib-reactive-form-switch>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-text-area
      #textArea
      [style.display]="'block'"
      [customValueParser]="viewModel.descValueParser"
      [inputName]="'descOverride'"
      [placeholder]="viewModel.descPlaceHolder$ | async"
      [required]="false"
      [disabled]="(viewModel.hideDesc$ | async) || isTemplatedMenu"
      [maxLength]="125"
      [hideLabel]="true"
      [programmaticallyChangeValue]="viewModel.programmaticallySetDesc$ | async"
      [bindingProperty]="'desc'">
    </lib-reactive-form-text-area>
  </div>
</lib-reactive-form-group>
