import { Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUtils } from '../utils/modal-utils';
import { CollectionDisplaysModalContainerComponent } from '../views/collection/components/modals/collection-displays-modal-container/collection-displays-modal-container.component';
import { RequiredDisplaysEditStep } from '../views/collection/components/modals/collection-displays-modal-container/collection-displays-modal-container-view-model';
import { PendingDisplay } from '../models/template/dto/pending-display';
import { TemplateCollection } from '../models/template/dto/template-collection';

export class ModalCollectionDisplays {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    editStep: RequiredDisplaysEditStep = RequiredDisplaysEditStep.ChooseEditMethod,
    onSave: (result: any) => void,
    collection: TemplateCollection,
    requiredDisplayIds?: string[],
    pendingDisplay?: PendingDisplay,
  ): void {
    const modalRef = ngbModal.open(
      CollectionDisplaysModalContainerComponent,
      ModalUtils.templateCollectionDisplayModalOptions(injector)
    );
    const compInst = modalRef.componentInstance as CollectionDisplaysModalContainerComponent;
    compInst.viewModel.connectToEditStep(editStep);
    compInst.viewModel.connectToCollection(collection);
    if (requiredDisplayIds) compInst.viewModel.connectToRequiredDisplayIds(requiredDisplayIds);
    if (pendingDisplay) compInst.viewModel.connectToPendingDisplay(pendingDisplay);
    if (editStep !== RequiredDisplaysEditStep.ChooseEditMethod) compInst.viewModel.connectToStartingAtStepZero(false);
    modalRef.result
      .then((result) => {
        if (!!result) onSave(result);
      })
      .catch(() => {});
  }

}
