import { Injectable } from '@angular/core';
import { MenuSectionBackgroundViewModel } from '../../shared/menu-section-background/menu-section-background-view-model';
import { iiif } from '../../../../../../utils/observable.extensions';
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from 'rxjs';
import { DropDownItem } from '../../../../../../models/shared/stylesheet/drop-down-item';
import { ImageObjectFit } from '../../../../../../models/enum/shared/image-object-fit';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { ImageAlignment } from '../../../../../../models/enum/shared/image-alignment';
import { exists } from '../../../../../../functions/exists';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';

@Injectable()
export class EditCardStackAssetSectionViewModel extends MenuSectionBackgroundViewModel {

  private _section = new BehaviorSubject<SectionTemplate | HydratedSection>(null);
  public section$ = this._section as Observable<SectionTemplate | HydratedSection>;
  connectToSection = (section: SectionTemplate|HydratedSection) => this._section.next(section);

  public readonly objectFitTypes$ = of([
    new DropDownItem('Cover', ImageObjectFit.Cover),
    new DropDownItem('Contain', ImageObjectFit.Contain)
  ]);

  public readonly imageAlignments$ = of([
    new DropDownItem('Left', ImageAlignment.Left),
    new DropDownItem('Center', ImageAlignment.Center),
    new DropDownItem('Right', ImageAlignment.Right)
  ]);

  private readonly _imageWidth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly imageWidth$ = this.section$.pipe(
    switchMap(section => {
      return iiif(
        this._imageWidth.pipe(map(width => Number.isFinite(width))),
        this._imageWidth,
        of(Number.parseInt(section?.metadata?.width, 10))
      );
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToImageWidth = (imageWidth: string) => this._imageWidth.next(Number.parseInt(imageWidth, 10));

  private readonly _objectFit = new BehaviorSubject<ImageObjectFit>(null);
  public readonly objectFit$ = this.section$.pipe(
    switchMap(section => {
      return iiif(
        this._objectFit.pipe(map(fit => exists(fit))),
        this._objectFit,
        of(section?.metadata?.objectFit)
      );
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToImageObjectFit = (imageObjectFit: ImageObjectFit) => this._objectFit.next(imageObjectFit);

  public readonly imageWidthDisabled$ = this.objectFit$.pipe(
    debounceTime(100),
    map(objectFit => objectFit === ImageObjectFit.Cover)
  );

  public readonly imageAlignmentDisabled$ = combineLatest([
    this.imageWidth$,
    this.objectFit$
  ]).pipe(
    map(([imageWidth, objectFit]) => (imageWidth === 100) || (objectFit === ImageObjectFit.Cover))
  );

  public readonly imageAlignmentDisabledTooltip$ = combineLatest([
    this.imageWidth$,
    this.objectFit$
  ]).pipe(
    map(([imageWidth, objectFit]) => {
      switch (true) {
        case objectFit === ImageObjectFit.Cover:
          return 'Image alignment is disabled when image fit equals cover.';
        case imageWidth === 100:
          return 'Image alignment is disabled when image width equals 100%.';
        default:
          return null;
      }
    })
  );

}
