import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { StackManualBulkPrintJobViewModel } from '../../stack-manual-bulk-print-job-view-model';
import { Variant } from '../../../../../../../../models/product/dto/variant';

@Component({
  selector: 'app-card-stack-manual-bulk-print-job-selectable-variant',
  templateUrl: './card-stack-manual-bulk-print-job-selectable-variant.component.html',
  styleUrls: ['./card-stack-manual-bulk-print-job-selectable-variant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackManualBulkPrintJobSelectableVariantComponent extends BaseComponent {

  constructor(
    public viewModel: StackManualBulkPrintJobViewModel
  ) {
    super();
  }

  @Input() variant: Variant;

}
