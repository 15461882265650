import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DisplayContentOption } from '../../../../../../models/enum/shared/display-content-option.enum';
import { map } from 'rxjs/operators';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';

type DisplayContentOptionSelection = {
  type: DisplayContentOption;
  title: string;
  subtitle: string;
}

@Component({
  selector: 'app-choose-display-content-option',
  templateUrl: './choose-display-content-option.component.html',
  styleUrls: ['./choose-display-content-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseDisplayContentOptionComponent {

  constructor(
    private userDomainModel: UserDomainModel,
  ) {}

  @Output() contentOptionSelected = new EventEmitter<DisplayContentOption>();
  @Output() cancel = new EventEmitter();

  public contentOptions$ = this.userDomainModel.canUseTemplates$.pipe(
    map(canUseTemplates => {
      const options: DisplayContentOptionSelection[] = [];
      const menu = {
        type: DisplayContentOption.Menu,
        title: 'Apply menu',
        subtitle: 'Add an existing active menu to this display'
      };
      const templateCollection = {
        type: DisplayContentOption.TemplateCollection,
        title: 'Apply template collection',
        subtitle: 'Add a collection of menu templates to this display'
      };
      options.push(menu);
      if (canUseTemplates) options.push(templateCollection);
      return options;
    })
  );

}
