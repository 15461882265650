<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<div class="row padding-for-support-button header-padding">
  <div class="col-12">
    <!--  Header  -->
    <div class="bs-headline d-inline-flex">Displays</div>
    <lib-button-primary
      class="new-display-button"
      [iconSrc]="'assets/icons/light/outline/plus.svg'"
      (buttonClicked)="viewModel.openAddDisplayModal()">
      Add New Display
    </lib-button-primary>
    <lib-button-neutral
      class="reorder-display-button"
      (buttonClicked)="viewModel.reorderAllDisplays()"
      [iconSrc]="'assets/icons/dark/outline/collection.svg'"
      [hidden]="!(viewModel.showReorderingForAllDisplays$ | async)">
      Reorder Displays
    </lib-button-neutral>
    <!--   Location header   -->
    <app-location></app-location>
    <hr class="mt-10px mb-10px" />

    <div [hidden]="viewModel?.isLoading$ | async" class="displays-body">
      <!--   No Displays Placeholder   -->
      <div [hidden]="viewModel.hideNoDisplayPlaceholder$ | async" class="no-results-container">
        <div class="no-results-title">No Displays Yet</div>
        <div class="no-results-body">
          It doesn't look like there are displays created at the
          {{ viewModel.currentLocationName$ | async }} location. Set up displays to pair your physical TVs with menus.
        </div>
        <lib-button-primary class="no-results-button" (buttonClicked)="viewModel.openAddDisplayModal()">
          Create a Display
        </lib-button-primary>
      </div>
      <!--   All Displays   -->
      <div *ngIf="viewModel.allDisplaysAreSameOrientation$ | async" class="row displays-container">
        <!-- Display Preview Cards -->
        <app-display-preview
          *ngFor="let disp of viewModel.allDisplays$ | async; trackBy: viewModel.trackById"
          [ngClass]="{
            'col-6 col-lg-4 col-xl-3': disp.displaySize.orientation !== ClientTypes.Orientation.Landscape,
            'col-12 col-sm-12 col-md-6 col-xl-4': disp.displaySize.orientation === ClientTypes.Orientation.Landscape
          }"
          [displayableItem]="disp">
        </app-display-preview>
      </div>
      <!--   Landscape Displays   -->
      <div *ngIf="viewModel.showLandscapeDisplays$ | async" class="landscape-displays-container">
        <div class="displays-container-header">
          <div class="displays-container-title">Landscape Displays</div>
          <div class="displays-container-button">
            <lib-button-neutral
              (buttonClicked)="viewModel.reorderLandscapeDisplays()"
              [iconSrc]="'assets/icons/dark/outline/collection.svg'"
              [hidden]="(viewModel.landscapeDisplays$ | async)?.length < 2">
              Reorder Displays
            </lib-button-neutral>
          </div>
        </div>

        <div class="row displays-container bottom-border">
          <!-- Display Preview Cards -->
          <app-display-preview
            class="col-12 col-sm-12 col-md-6 col-xl-4"
            *ngFor="let disp of viewModel.landscapeDisplays$ | async; trackBy: viewModel.trackById"
            [displayableItem]="disp">
          </app-display-preview>
        </div>
      </div>
      <!--   Portrait Displays   -->
      <div *ngIf="viewModel.showPortraitDisplays$ | async" class="portrait-displays-container">
        <div class="displays-container-header">
          <div class="displays-container-title">Portrait Displays</div>
          <div class="displays-container-button">
            <lib-button-neutral
              (buttonClicked)="viewModel.reorderPortraitDisplays()"
              [iconSrc]="'assets/icons/dark/outline/collection.svg'"
              [hidden]="(viewModel.portraitDisplays$ | async)?.length < 2">
              Reorder Displays
            </lib-button-neutral>
          </div>
        </div>

        <div class="row displays-container">
          <!-- Display Preview Cards -->
          <app-display-preview
            class="col-6 col-lg-4 col-xl-3"
            *ngFor="let disp of viewModel.portraitDisplays$ | async; trackBy: viewModel.trackById"
            [displayableItem]="disp">
          </app-display-preview>
        </div>
      </div>
    </div>
  </div>
</div>
