import { FoundationBaseModal } from '@mobilefirstdev/base-angular';
import { ClientTypeUtils } from '../../utils/client-type-utils';
import { Component } from '@angular/core';

@Component({ selector: 'app-base-modal', template: ''})
export abstract class BaseModalComponent extends FoundationBaseModal {

  ClientTypes = ClientTypeUtils;

}
