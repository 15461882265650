import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMenuOrCollectionModalComponent } from '../views/display/components/modals/add-menu-or-collection-modal/add-menu-or-collection-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';
import { BaseDisplay } from '../models/display/dto/base-display';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../models/shared/loading-options';

export class ModalAddMenuOrCollection {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    display: BaseDisplay,
    collectionMode: boolean,
    addMenus: (ids: string[], loadingOptions: BehaviorSubject<LoadingOptions>) => void,
    addCollections: (ids: string[], loadingOptions: BehaviorSubject<LoadingOptions>) => void
  ): void {
    const modalRef = ngbModal.open(
      AddMenuOrCollectionModalComponent,
      ModalUtils.addMenuOrCollectionModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as AddMenuOrCollectionModalComponent;
    compInstance.display = display;
    compInstance.collectionMode = collectionMode;
    compInstance.viewModel.addMenus = addMenus;
    compInstance.viewModel.addCollections = addCollections;
  }

}
