import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { switchMap } from 'rxjs/operators';

@Pipe({
  name: 'displayItemGetLoadingFromBundle'
})
export class DisplayItemGetLoadingFromBundlePipe implements PipeTransform {

  transform(
    loadingOptions$: Observable<Map<string, BehaviorSubject<LoadingOptions>>>,
    idBundle: (string | string[])
  ): Observable<LoadingOptions | null> {
    const key = Array.isArray(idBundle) ? idBundle?.firstOrNull() : idBundle;
    return loadingOptions$.pipe(
      switchMap(loadingOptions => loadingOptions?.get(key) ?? of(null))
    );
  }

}
