import { Injectable } from '@angular/core';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { DatatableOptions } from '../../../models/shared/stylesheet/datatable-options';
import { DatatableProductFilter } from '../../../models/product/table/datatable-product-filter';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

// Provided by Logged In Scope
@Injectable()
export class ProductPickerViewModel extends BaseModalViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
    public menuDomainModel: MenuDomainModel,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
    this.router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribeWhileAlive({
      owner: this,
      next: () => {
        this.connectToProductSelectionOnly(false);
        this.connectToFlattenAndThenRemoveProductGroupings(false);
        this.connectToHideLabelAndBadgeColumn(false);
        this.connectToShowCurrentOverrideGroupColumn(false);
      }
    });
  }

  // Products table
  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableProductFilter = new DatatableProductFilter();
  public products$ = this.productDomainModel.currentLocationActiveProducts$;

  // Init as Ignore Variants
  public initAsIgnoreVariants = new BehaviorSubject<boolean>(false);

  // Loading
  public loadingOpts: LoadingOptions = LoadingOptions.default();

  private _productSelectionOnly = new BehaviorSubject<boolean>(false);
  public readonly productSelectionOnly$ = this._productSelectionOnly as Observable<boolean>;
  connectToProductSelectionOnly = (x: boolean) => this._productSelectionOnly.next(x);

  private readonly _flattenAndThenRemoveProductGroups = new BehaviorSubject<boolean>(false);
  public readonly flattenAndThenRemoveProductGroups$ = this._flattenAndThenRemoveProductGroups as Observable<boolean>;
  connectToFlattenAndThenRemoveProductGroupings = (x: boolean) => this._flattenAndThenRemoveProductGroups.next(x);

  private readonly _hideLabelAndBadgeColumn = new BehaviorSubject<boolean>(false);
  public readonly hideLabelAndBadgeColumn$ = this._hideLabelAndBadgeColumn as Observable<boolean>;
  connectToHideLabelAndBadgeColumn = (x: boolean) => this._hideLabelAndBadgeColumn.next(x);

  private readonly _showCurrentOverrideGroupColumn = new BehaviorSubject<boolean>(false);
  public readonly showCurrentOverrideGroupColumn$ = this._showCurrentOverrideGroupColumn as Observable<boolean>;
  connectToShowCurrentOverrideGroupColumn = (x: boolean) => this._showCurrentOverrideGroupColumn.next(x);

}
