<div #reorderItem class="reorder-item" cdkDrag>
  <div class="reorder-header">
    <div *ngIf="showIcons">
      <img [src]="item?.orderableIconSrc" [style.height.rem]="1" alt="" />
    </div>
    <div class="reorder-value ellipsis-nowrap-text">
      {{ item.getOrderableTitle() }}
    </div>
    <div class="reorder-icon">
      <img [src]="'assets/icons/dark/outline/menu.svg'" alt="" />
    </div>
  </div>
  <ng-container *ngIf="item | continueIfCompactTemplateCollection as compactTemplateCollection">
    <div *ngFor="let template of compactTemplateCollection?.compactMenus" class="f12px pt-4px pb-2px bs-medium">
      {{ template.name }}
    </div>
  </ng-container>
  <div class="reorder-placeholder" [style.height]="(reorderItemHeight$ | async) + 'px'" *cdkDragPlaceholder></div>
</div>
