import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PrintMenuLiveViewViewModel } from './print-menu-live-view-view-model';
import { BaseComponent } from '../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { Menu } from '../../../models/menu/dto/menu';
import { exists } from '../../../functions/exists';

/**
 * The iFrame makes scaling complicated. It doesn't scale content how you'd expect.
 * That is why the scale value gets sent to the parent container.
 */
@Component({
  selector: 'app-print-menu-live-view',
  templateUrl: './print-menu-live-view.component.html',
  styleUrls: ['./print-menu-live-view.component.scss'],
  providers: [PrintMenuLiveViewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintMenuLiveViewComponent extends BaseComponent implements OnChanges, OnDestroy {

  constructor(
    public viewModel: PrintMenuLiveViewViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToPrintMenu') printMenu: Menu;
  @Input() @ViewModelConnector('connectToUserSetScale') userSetScale: number;
  @Input() fitIntoContainer: HTMLElement;
  @Input() selectedPageIndex: number = 0;
  @Output() menuHeight = new EventEmitter<number>(true);
  @Output() menuWidth = new EventEmitter<number>(true);
  @Output() nPages = new EventEmitter<number>(true);
  @Output() smallestScaleValue = new EventEmitter<number>(true);
  @Output() scale = new EventEmitter<number>(true);
  @ViewChild('iFrame') iFrame: ElementRef<HTMLIFrameElement>;
  private parentContainerResizeObserver: ResizeObserver;

  override setupViews(): void {
    this.sendMenuHeightOutside();
    this.sendMenuWidthOutside();
    this.sendNPagesOutside();
    this.sendScaleToOutside();
    this.sendSmallestScaleValueOutside();
  }

  loaded(iFrame: HTMLIFrameElement) {
    this.viewModel.sendDataToIFrame(iFrame);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (exists(changes.fitIntoContainer)) this.listenToParentContainer();
    if (exists(changes.selectedPageIndex)) {
      this.viewModel.sendPageSelectionToIFrame(this.iFrame?.nativeElement, this.selectedPageIndex);
    }
  }

  private sendMenuHeightOutside(): void {
    this.viewModel.menuHeight$.subscribeWhileAlive({
      owner: this,
      next: (cardHeight) => this.menuHeight.emit(cardHeight)
    });
  }

  private sendMenuWidthOutside(): void {
    this.viewModel.menuWidth$.subscribeWhileAlive({
      owner: this,
      next: (cardWidth) => this.menuWidth.emit(cardWidth)
    });
  }

  private sendNPagesOutside(): void {
    this.viewModel.nPages$.subscribeWhileAlive({
      owner: this,
      next: (nPages) => this.nPages.emit(nPages)
    });
  }

  private sendScaleToOutside(): void {
    this.viewModel.scale$.subscribeWhileAlive({
      owner: this,
      next: (scale) => this.scale.emit(scale)
    });
  }

  private sendSmallestScaleValueOutside(): void {
    this.viewModel.scaleToParentView$.subscribeWhileAlive({
      owner: this,
      next: (smallestScaleValue) => this.smallestScaleValue.emit(smallestScaleValue)
    });
  }

  private listenToParentContainer(): void {
    this.parentContainerResizeObserver?.disconnect();
    if (!this.fitIntoContainer) return;
    this.parentContainerResizeObserver = new ResizeObserver(() => {
      this.viewModel.connectToParentContainerWidth(this.fitIntoContainer?.clientWidth);
      this.viewModel.connectToParentContainerHeight(this.fitIntoContainer?.clientHeight);
    });
    this.parentContainerResizeObserver.observe(this.fitIntoContainer);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.parentContainerResizeObserver?.disconnect();
  }

}
