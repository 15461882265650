import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { EditSpotlightMenuViewModel } from '../edit-spotlight-menu-view-model';
import { EditSpotlightSectionViewModel } from '../edit-spotlight-section-view-model';
import { EditSpotlightMenuCombinedLoadingOptsViewModel } from '../edit-spotlight-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-spotlight-menu-body',
  templateUrl: './edit-spotlight-menu-body.component.html',
  styleUrls: ['./edit-spotlight-menu-body.component.scss'],
  providers: [EditSpotlightMenuCombinedLoadingOptsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSpotlightMenuBodyComponent extends BaseComponent {

  constructor(
    public viewModel: EditSpotlightMenuViewModel,
    public sectionViewModel: EditSpotlightSectionViewModel,
    public sharedLoadingState: EditSpotlightMenuCombinedLoadingOptsViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string = 'editSpotlightSection';
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;

}
