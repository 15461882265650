<div
  #container
  *ngIf="disableSmartFiltersWithBadgeOrLabelCriteria$ | async"
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayoutGap="1rem"
  class="smart-filter-selection-container"
  [ngbTooltip]="tooltip"
  [placement]="'bottom'"
  [container]="'body'"
  [class.disabled]="disabled$ | async"
  [class.child]="isChild">
  <app-checkbox-only
    [checked]="selected$ | async"
    [disabled]="disabled$ | async"
    [indeterminate]="indeterminate$ | async"
    (valueChanged)="clicked($event)"></app-checkbox-only>
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="smart-filter-name">{{ name$ | async | titlecase }}</div>
    <div class="smart-filter-variant-hit-count">{{ variantHitsString$ | async }}</div>
  </div>
</div>

<div
  #container
  *ngIf="!(disableSmartFiltersWithBadgeOrLabelCriteria$ | async)"
  fxLayout="row"
  fxLayoutAlign=" center"
  fxLayoutGap="1rem"
  class="smart-filter-selection-container"
  [ngClass]="{ disabled: disabled$ | async, child: isChild }">
  <app-checkbox-only
    [checked]="selected$ | async"
    [disabled]="disabled$ | async"
    [indeterminate]="indeterminate$ | async"
    (valueChanged)="clicked($event)"></app-checkbox-only>
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="smart-filter-name">{{ name$ | async | titlecase }}</div>
    <div class="smart-filter-variant-hit-count">{{ variantHitsString$ | async }}</div>
  </div>
</div>

<hr *ngIf="includeBottomBorder" class="no-margin" />
