// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { concatMap, distinctUntilChanged, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ToastService } from '../../../../services/toast-service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';

@Injectable()
export class ColorPaletteViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private toast: ToastService
  ) {
    super();
  }

  // Selected Color
  public selectedColor: string = '';

  // Company Configuration Information
  public companyConfiguration$ = this.companyDomainModel.companyConfiguration$;
  public companyColorPaletteClone$ = this.companyConfiguration$.pipe(
    map(config => (config?.colorPalette || []).deepCopy()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Update color palette
  private addThisColor = new BehaviorSubject<string>(null);
  private removeThisColor = new BehaviorSubject<string>(null);
  private updateColorPalette = combineLatest([
    this.addThisColor,
    this.removeThisColor,
    this.companyColorPaletteClone$
  ]).pipe(
    withLatestFrom(this.locationDomainModel.locationId$),
    concatMap(([[add, remove, palette], locationId]) => {
      const colors = (palette || []).deepCopy() as string[];
      if (add) {
        colors.push(add);
        this.addThisColor.next(null); // self resets add mechanism
        return this.companyDomainModel.updateCompanyColorPalette(colors, locationId).pipe(map(config => {
          this.toast.publishSuccessMessage(`Added color to palette`, 'Successful');
          return !!config;
        }));
      }
      if (remove) {
        const i = colors.findIndex(color => color === remove);
        if (i > -1) colors.splice(i, 1);
        this.removeThisColor.next(null); // self resets remove mechanism
        return this.companyDomainModel.updateCompanyColorPalette(colors, locationId).pipe(map(config => {
          this.toast.publishSuccessMessage(`Removed color from palette`, 'Successful');
          return !!config;
        }));
      }
      return of(false);
    }),
    distinctUntilChanged(),
    takeUntil(this.onDestroy)
  ).subscribe({
    error: (err) => this.toast.publishError(err)
  });

  add() {
    this.addThisColor.next(this.selectedColor);
  }

  remove(color: string) {
    this.removeThisColor.next(color);
  }

}
