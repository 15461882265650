import { TypeDefinition } from './type-definition';

export enum SyncJobStatus {
  SyncJobStatus_Queued = 'SyncJobStatus_Queued',
  SyncJobStatus_Processing = 'SyncJobStatus_Processing',
  SyncJobStatus_Success = 'SyncJobStatus_Success',
  SyncJobStatus_Failed = 'SyncJobStatus_Failed',
  SyncJobStatus_Cancelled = 'SyncJobStatus_Cancelled'
}

export class SyncJobStatusType extends TypeDefinition {

  override value: SyncJobStatus;

}
