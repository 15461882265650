import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { PrintJobSelectStacksViewModel } from './print-job-select-stacks-view-model';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';

@Component({
  selector: 'app-print-job-select-stacks',
  templateUrl: './print-job-select-stacks.component.html',
  providers: [PrintJobSelectStacksViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobSelectStacksComponent {

  constructor(public viewModel: PrintJobSelectStacksViewModel) {
  }

  @Input() @ViewModelConnector('connectToStackPrintType') stackPrintType: StackPrintType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToSelectedStackIds') selectedStackIds: string[];
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean = false;

  @Output() add = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();
  @Output() bulkAdd = new EventEmitter<string[]>();
  @Output() bulkRemove = new EventEmitter<string[]>();

}
