import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebProductMenusViewModel } from './web-product-menus-view-model';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';

@Component({
  selector: 'app-web-product-menus',
  templateUrl: './web-product-menus.component.html',
  styleUrls: ['./web-product-menus.component.scss'],
  providers: [WebProductMenusViewModel]
})
export class WebProductMenusComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: WebProductMenusViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
