import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { StackPrintJobProductsFormViewModel } from './stack-print-job-products-form-view-model';
import type { StackType } from '../create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-print-job-products-form',
  templateUrl: './stack-print-job-products-form.component.html',
  styleUrls: ['./stack-print-job-products-form.component.scss'],
  providers: [StackPrintJobProductsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackPrintJobProductsFormComponent {

  constructor(public viewModel: StackPrintJobProductsFormViewModel) {
  }

  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToTemplateMode') templateMode: boolean;
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean;
  @Input() @ViewModelConnector('connectToSearchText') searchText: string;

}
