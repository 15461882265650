import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Menu } from '../../../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-all-stack-cards-printed',
  templateUrl: './all-stack-cards-printed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllStackCardsPrintedComponent {

  @Input() stacks: Menu[];
  @Input() description: string;

}
