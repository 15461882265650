import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BaseComponent } from '../../../../models/base/base-component';
import { Theme } from '../../../../models/menu/dto/theme';
import { PopperUtils } from '../../../../utils/popper-utils';

@Component({
  selector: 'app-variant-badges',
  templateUrl: './variant-badges.component.html',
  styleUrls: ['./variant-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantBadgesComponent extends BaseComponent {

  @Input() inheritedBadges: HydratedVariantBadge[];
  @Input() badges: HydratedVariantBadge[];
  @Input() theme: Theme;
  @Input() title: string;
  @Input() emptyStateText: string;
  @Input() inheritedText: string = 'Set at company wide level. \"Add a Badge\" to override.';
  @Input() showBadgePicker: boolean = true;
  @Input() includeTopDivider: boolean = true;
  @Input() includeBottomDivider: boolean = true;
  @Output() removedClicked: EventEmitter<HydratedVariantBadge> = new EventEmitter<HydratedVariantBadge>();
  @Output() addClicked: EventEmitter<HydratedVariantBadge> = new EventEmitter<HydratedVariantBadge>();

  // Popper
  public popperModifier = [PopperUtils.flipModifier()];
  public popperStyles = { 'background-color': '#FFFFFF' };

  constructor() {
    super();
  }

  getSectionTitle() {
    if (this.theme?.themeFeatures?.badgeCount === 1) {
      return `Badges - ${this.theme?.name ?? 'This'} theme supports 1 badge`;
    } else if (this.theme?.themeFeatures?.badgeCount > 1) {
      return `Badges - ${this.theme?.name ?? 'This'} theme supports ${this.theme?.themeFeatures?.badgeCount} badges`;
    } else {
      return 'Badges';
    }
  }

}
