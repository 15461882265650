import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LabelGroupItem, LabelItemType } from '../../../../models/shared/stylesheet/label-group-item';
import { LabelGroupStyling } from '../../../../models/shared/stylesheet/label-group-styling';

@Component({
  selector: 'app-label-group',
  templateUrl: './label-group.component.html',
  styleUrls: [
    './label-group.component.scss',
    '../form-group/form-group.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class LabelGroupComponent {

  @Input() labelItems: LabelGroupItem[] = [];
  @Input() styling: LabelGroupStyling = new LabelGroupStyling();

  // Variables
  public itemTypes = LabelItemType;

  constructor() {
  }

}
