import { Injectable } from '@angular/core';
import { ColumnOptionsFormViewModel } from '../column-options-form/column-options-form-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { ColumnOptionName, SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Injectable()
export class ColumnOptionsPriceViewModel extends ColumnOptionsFormViewModel {

  constructor(
    public override container: ColumnOptionsViewModel
  ) {
    super(container);
  }

  public override columnSectionKey = SectionColumnConfigKey.Price;
  public override columnSectionName = ColumnOptionName.Price;
  public override tooltip = 'Show Price in menu';

}
