import { Injectable } from '@angular/core';
import { BaseService } from '@mobilefirstdev/base-angular';
import { DisplayableSubItem } from '../../../../../../models/shared/displayable-subitem';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MarketingMenuType } from '../../../../../../models/enum/dto/marketing-menu-type.enum';
import { combineLatest, Observable, of } from 'rxjs';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { map } from 'rxjs/operators';
import { MediaUtils } from '../../../../../../utils/media-utils';
import { SectionType } from '../../../../../../models/utils/dto/section-type-definition';
import { PrintCardMenuType } from '../../../../../../models/enum/dto/print-card-menu-type.enum';
import { ProductMenuType } from '../../../../../../models/enum/dto/product-menu-type.enum';
import { PrintLabelMenuType } from '../../../../../../models/enum/dto/print-label-menu-type.enum';

// Provided by Logged In Scope
@Injectable()
export class MenuDisplayableSubItemService extends BaseService {

  constructor(private productDomainModel: ProductDomainModel) {
    super();
  }

  public displayableSubItemList(menu: Menu): Observable<DisplayableSubItem[]> {
    switch (menu?.getSubType()) {
      case MarketingMenuType.Playlist:
        return this.assetSubItems(menu);
      case MarketingMenuType.DriveThru:
      case MarketingMenuType.Featured:
        return this.productSubItems(menu);
      case ProductMenuType.SpotlightMenu:
      case PrintCardMenuType.PrintCardMenu:
      case PrintLabelMenuType.PrintLabelMenu:
        return this.productNestedSubItems(menu);
      default:
        return this.sectionSubItems(menu);
    }
  }

  private assetSubItems(menu: Menu): Observable<DisplayableSubItem[]> {
    const assetNames = this.sortItemsByRotationOrder(menu?.options?.rotationOrder);
    const nameIconSrcPairs = assetNames?.map((name) => {
      const iconSrc = this.getAssetIconSrc(name);
      const truncatedName = MediaUtils.getFileNameWithoutTimestampAndExtension(name);
      return {name: truncatedName, iconSrc};
    });
    const subItems = this.generateDisplayableSubItemList(nameIconSrcPairs);
    return of(subItems);
  }

  private getAssetIconSrc(name: string): string {
    const mediaType = MediaUtils.getMediaType(name);
    const isVideo = MediaUtils.isVideo(mediaType);
    const isImage = MediaUtils.isImage(mediaType);
    let iconSrc = '';
    if (isImage) {
      iconSrc = 'assets/icons/dark/outline/photograph.svg';
    } else if (isVideo) {
      iconSrc = 'assets/icons/dark/outline/video-camera.svg';
    }
    return iconSrc;
  }

  private productSubItems(menu: Menu): Observable<DisplayableSubItem[]> {
    let variantIds: string[];
    if (menu?.containsStackedContent()) {
      variantIds = menu?.sections?.firstOrNull()?.enabledVariantIds;
    } else {
      variantIds = this.sortItemsByRotationOrder(menu?.options?.rotationOrder);
    }
    return this.productDomainModel.currentLocationVariants$.pipe(
      map(allVariants => {
        const includedVariants = variantIds?.map((id) => allVariants?.find(v => v?.id === id))?.filterNulls();
        const variantNames = includedVariants?.map(v => v?.getDisplayName());
        const nameIconSrcPairs = variantNames?.map((name) => {
          const iconSrc = 'assets/icons/dark/outline/star.svg';
          return {name, iconSrc};
        });
        return this.generateDisplayableSubItemList(nameIconSrcPairs);
      })
    );
  }

  private productNestedSubItems(menu: Menu): Observable<DisplayableSubItem[]> {
    return combineLatest([
      this.productDomainModel.currentLocationVariants$,
      this.productDomainModel.currentLocationProducts$,
    ]).pipe(
      map(([allVariants, allProducts]) => {
        const sortedVariantIds = menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.sortedVariantIds;
        const nameIconSrcPairs: { name: string, iconSrc: string }[] = sortedVariantIds?.map((ids) => {
          const productVariants = allVariants?.filter(v => ids.includes(v?.id));
          let name: string;
          if (productVariants?.length === 1) {
            name = productVariants?.firstOrNull()?.getDisplayName();
          } else {
            const productId = productVariants?.firstOrNull()?.productId;
            const product = allProducts?.find(p => p?.id === productId);
            name = product?.getDisplayName();
          }
          const iconSrc = 'assets/icons/dark/outline/star.svg';
          return {name, iconSrc};
        });
        return this.generateDisplayableSubItemList(nameIconSrcPairs);
      })
    );
  }

  private sectionSubItems(menu: Menu): Observable<DisplayableSubItem[]> {
    const sections = menu?.isTemplatedMenu() ? menu?.template?.templateSections : menu?.getSectionsBasedOnMenuType();
    const sortedSections = sections?.filterNulls()?.sort((a, b) => a?.priority - b?.priority);
    const nameIconSrcPairs = sortedSections?.map((sec) => {
      const name = sec?.sectionType === SectionType.PageBreak ? 'Page Break' : sec?.title;
      return {name, iconSrc: sec?.getIconSrc()};
    });
    const subItems = this.generateDisplayableSubItemList(nameIconSrcPairs);
    return of(subItems);
  }

  private sortItemsByRotationOrder(rotationOrder: Map<string, number>): string[] {
    return Array.from(rotationOrder?.entries() ?? [])
      .sort((a, b) => a[1] - b[1])
      .map(entry => entry[0]);
  }

  private generateDisplayableSubItemList(nameIconSrcPairs: { name: string, iconSrc: string }[]): DisplayableSubItem[] {
    const subItemArray: DisplayableSubItem[] = [];
    if (nameIconSrcPairs?.length > 5) {
      const truncatedNames = nameIconSrcPairs.slice(0, 5);
      truncatedNames.forEach(pair => subItemArray.push(new DisplayableSubItem(pair.name, pair.iconSrc)));
      subItemArray.push(new DisplayableSubItem(`+${nameIconSrcPairs.length - 5} more`, null));
      return subItemArray;
    } else {
      nameIconSrcPairs?.forEach(pair => subItemArray.push(new DisplayableSubItem(pair.name, pair.iconSrc)));
      return subItemArray;
    }
  }

}
