export class InformationItem {

  public name: string;
  public description: string;

  constructor(name, desc: string) {
    this.name = name;
    this.description = desc;
  }

}
