import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'app-abandon-form',
  templateUrl: './abandon-form.component.html',
  styleUrls: ['./abandon-form.component.scss']
})
export class AbandonFormComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    private _location: Location
  ) {
  }

  public goBack() {
    this._location.back();
  }

  keepEditing() {
    this.activeModal.close(false);
  }

  abandonChanges() {
    this.activeModal.close(true);
  }

}
