import { Selectable } from '../protocols/selectable';

export class SelectableItem implements Selectable {

  public title: string;
  public value: any;
  public uniqueIdentifier: any;

  constructor(title: string, value: any, uniqueIdentifier: any) {
    this.title = title;
    this.value = value;
    this.uniqueIdentifier = uniqueIdentifier;
  }

  getSelectionTitle(): string {
    return this.title;
  }

  getSelectionUniqueIdentifier(): any {
    return this.uniqueIdentifier;
  }

  getSelectionValue(): any {
    return this.value;
  }

}
