<lib-reactive-form-column-layout nColumns="2" rowGap="'2rem'">
  <ng-container *rxIf="!(viewModel.useRange$ | push); else rangeTerpenes">
    <lib-reactive-form-group [bindTo]="viewModel.companyDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.companyTerpeneUpdated($event)"
        [inputName]="viewModel.companyTerpeneInputName$ | push"
        [label]="viewModel.companyTerpeneLabel$ | push"
        [placeholder]="viewModel.companyTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneAccessor$ | push"
        [tooltip]="viewModel.companyTerpeneTooltip$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push) || (viewModel.disableTerpeneInputs$ | push)"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>

    <lib-reactive-form-group [bindTo]="viewModel.locationDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.locationTerpeneUpdated($event)"
        [inputName]="viewModel.locationTerpeneInputName$ | push"
        [label]="viewModel.locationTerpeneLabel$ | push"
        [placeholder]="viewModel.locationTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneAccessor$ | push"
        [tooltip]="viewModel.locationTerpeneTooltip$ | push"
        [disabled]="viewModel.disableTerpeneInputs$ | push"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>
  </ng-container>
  <ng-template #rangeTerpenes>
    <lib-reactive-form-group [bindTo]="viewModel.companyDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.companyMinTerpeneUpdated($event)"
        [inputName]="viewModel.companyMinTerpeneInputName$ | push"
        [label]="viewModel.companyMinTerpeneLabel$ | push"
        [placeholder]="viewModel.companyMinTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneMinAccessor$ | push"
        [tooltip]="viewModel.companyMinTerpeneTooltip$ | push"
        [required]="viewModel.hasCompanyMaxTerpene$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push) || (viewModel.disableTerpeneInputs$ | push)"
        [minValue]="0"
        [maxValue]="viewModel.companyMaxTerpeneLowerBoundValue$ | push"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>

      <lib-reactive-form-number
        (valueChanged)="viewModel.companyMaxTerpeneUpdated($event)"
        [inputName]="viewModel.companyMaxTerpeneInputName$ | push"
        [label]="viewModel.companyMaxTerpeneLabel$ | push"
        [placeholder]="viewModel.companyMaxTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneMaxAccessor$ | push"
        [tooltip]="viewModel.companyMaxTerpeneTooltip$ | push"
        [required]="viewModel.hasCompanyMinTerpene$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push) || (viewModel.disableTerpeneInputs$ | push)"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>

    <lib-reactive-form-group [bindTo]="viewModel.locationDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.locationMinTerpeneUpdated($event)"
        [inputName]="viewModel.locationMinTerpeneInputName$ | push"
        [label]="viewModel.locationMinTerpeneLabel$ | push"
        [placeholder]="viewModel.locationMinTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneMinAccessor$ | push"
        [tooltip]="viewModel.locationMinTerpeneTooltip$ | push"
        [required]="viewModel.hasLocationMaxTerpene$ | push"
        [disabled]="viewModel.disableTerpeneInputs$ | push"
        [minValue]="0"
        [maxValue]="viewModel.locationMaxTerpeneLowerBoundValue$ | push"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>

      <lib-reactive-form-number
        (valueChanged)="viewModel.locationMaxTerpeneUpdated($event)"
        [inputName]="viewModel.locationMaxTerpeneInputName$ | push"
        [label]="viewModel.locationMaxTerpeneLabel$ | push"
        [placeholder]="viewModel.locationMaxTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneMaxAccessor$ | push"
        [tooltip]="viewModel.locationMaxTerpeneTooltip$ | push"
        [required]="viewModel.hasLocationMinTerpene$ | push"
        [disabled]="viewModel.disableTerpeneInputs$ | push"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>
  </ng-template>
</lib-reactive-form-column-layout>
