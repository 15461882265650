<div [hidden]="true">
  <ng-content></ng-content>
  <ng-container #headerDuplicator></ng-container>
  <ng-container #rowDuplicator></ng-container>
  <ng-container #detachedItems></ng-container>
  <ng-container #loadingDuplicator></ng-container>
  <ng-container #emptyStateDuplicator></ng-container>
  <ng-container #paginatorDuplicator></ng-container>
</div>
<div class="reactive-table-container">
  <div class="reactive-table">
    <table [style.table-layout]="layoutType">
      <ng-container #headerContainer></ng-container>
      <tbody [hidden]="loadingData$ | async">
        <ng-container #bodyContainer></ng-container>
      </tbody>
    </table>
  </div>

  <div [hidden]="!(loadingData$ | async)"><ng-container #loadingStateContainer></ng-container></div>
  <div [hidden]="thereIsDataToDisplay$ | async"><ng-container #emptyStateContainer></ng-container></div>
  <div [hidden]="(nItemsToDisplay$ | async) <= 0"><ng-container #paginationContainer></ng-container></div>
</div>
