import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../models/menu/dto/section';
import { SectionType } from '../../../models/utils/dto/section-type-definition';

@Pipe({
  name: 'isFeaturedCategorySection'
})
export class IsFeaturedCategorySectionPipe implements PipeTransform {

  transform(value: Section): boolean {
    return value?.sectionType === SectionType.CategoryCard;
  }

}
