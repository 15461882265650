import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fake-check-box',
  templateUrl: './fake-check-box.component.html',
  styleUrls: ['./fake-check-box.component.scss']
})
export class FakeCheckBoxComponent {

  @Input() checked: boolean;
  @Input() label: string;
  @Output() clicked = new EventEmitter(true);

}
