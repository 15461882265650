import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'app-display-name',
  templateUrl: './display-name.component.html',
  styleUrls: ['./display-name.component.scss']
})
export class DisplayNameComponent extends BaseComponent {

  @Input() displayName: string;
  @Input() addedByTemplate: boolean = false;
  @Input() isMedical: boolean = false;
  @Input() truncate: boolean = false;
  @Input() isCultivatorVerified: boolean = false;
  @Input() variantTooltip: string = null;
  @Input() variantTooltipPlacement: PlacementArray = 'right';

}
