import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { MarketingMenuType } from '../../../models/enum/dto/marketing-menu-type.enum';

@Pipe({
  name: 'isFeaturedProductMenu'
})
export class IsFeaturedProductMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return value?.hydratedTheme?.menuSubType === MarketingMenuType.Featured;
  }

}
