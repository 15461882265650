import { Component, Input } from '@angular/core';
import { DisplayableItemPreviewComponent } from '../displayable-item-preview/displayable-item-preview.component';
import { MenuPreviewViewModel } from './menu-preview-view-model';
import { Menu } from '../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-menu-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: ['../displayable-item-preview/displayable-item-preview.component.scss'],
  providers: [MenuPreviewViewModel]
})
export class MenuPreviewComponent extends DisplayableItemPreviewComponent {

  constructor(
    public override viewModel: MenuPreviewViewModel
  ) {
    super(viewModel);
  }

  @Input() override displayableItem: Menu;

}
