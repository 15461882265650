<div class="row edit-page-children">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-bold f18px">
      {{ sectionTitle }}
    </p>
    <p class="f12px">
      {{ sectionDescription }}
    </p>
    <app-edit-display-menu-details-action
      [reorderMenus]="viewModel.reorderMenus"
      [addMenu]="viewModel.addMenuToDisplay"
      [disabled]="formHasErrors || (viewModel.autoSaving$ | async) || !formPristine"
      [formPristine]="formPristine"
      [nMenus]="viewModel.numberOfMenus$ | async"
      [collectionMode]="viewModel.collectionMode$ | async">
    </app-edit-display-menu-details-action>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <ng-container *ngIf="!(viewModel.compactItems$ | async)?.length">
      <div class="f14px bs-medium pb-16px">
        {{ viewModel.emptyDisplayText$ | async }}
      </div>
      <lib-button-primary
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        [disabled]="formHasErrors || (viewModel.autoSaving$ | async)"
        (buttonClicked)="viewModel.addMenuToDisplay()">
        Add {{ 'Menu' | replaceMenuWithTemplate : (viewModel.collectionMode$ | async) }}
      </lib-button-primary>
    </ng-container>
    <!-- List Menus -->
    <app-info-banner
      *ngIf="allDisplaysAreScheduled && !(viewModel.collectionMode$ | async)"
      [style.margin-bottom.rem]="1">
      All menus are scheduled for specific times. If there are gaps between scheduled times, then unaccounted for time
      windows will show a black screen.
    </app-info-banner>

    <ng-container
      *ngFor="
        let item of viewModel.compactItems$ | async as items;
        let i = index;
        let last = last;
        trackBy: viewModel.trackById
      ">
      <app-display-template-collection-section
        *ngIf="item | continueIfCompactTemplateCollection as compactTemplateCollection"
        [collection]="compactTemplateCollection"
        [collectionIsRequired]="compactTemplateCollection | collectionRequiredInDisplay : viewModel.display$ | async"
        [isLastItem]="last">
      </app-display-template-collection-section>
      <app-display-menu-section
        *ngIf="item | continueIfCompactMenu as compactMenu"
        [menuIndex]="i"
        [mergeKey]="mergeKey"
        [allDisplaysAreScheduled]="allDisplaysAreScheduled"
        [nMenusOnDisplay]="items?.length || 1"
        [containerId]="(viewModel.display$ | async)?.id"
        [menuIsPartOfCollection]="viewModel.collectionMode$ | async"
        [menu]="compactMenu"
        [formPristine]="formPristine">
      </app-display-menu-section>
    </ng-container>
  </div>
</div>
<hr class="mt-24px" />
