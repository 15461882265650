import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';
import { MenuAdditionalOptionsViewModel } from './menu-additional-options-view-model';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';
import { MenuPrintOptionsFormComponent } from './additional-option-forms/menu-print-options-form/menu-print-options-form.component';
import { BaseComponent } from '../../../../../models/base/base-component';

/**
 * This is annoying to manage. In the future, extend this component for each EDIT MENU TYPE, so each flow has
 * its own options component. This will make it easier to update/change.
 * An example of a component that does this the right way: EditMarketingMenuComponent
 */
@Component({
  selector: 'app-menu-additional-options',
  templateUrl: './menu-additional-options.component.html',
  styleUrls: ['./menu-additional-options.component.scss'],
  providers: [MenuAdditionalOptionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuAdditionalOptionsComponent extends BaseComponent implements OnChanges {

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() bindTo: Menu;
  @Input() menu: Menu;

  @Output() iFrameWidthChanged = new EventEmitter<number>(true);
  @Output() iFrameHeightChanged = new EventEmitter<number>(true);
  @Output() orientationChanged = new EventEmitter<Orientation>(true);

  @ViewChildren('printOptionsForm') printOptionsForm: QueryList<MenuPrintOptionsFormComponent>;

  constructor(public viewModel: MenuAdditionalOptionsViewModel) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
  }

  override setupBindings() {
    this.viewModel.connectToPrintOptionForm(this.printOptionsForm?.toArray()?.firstOrNull());
    this.printOptionsForm?.changes?.subscribeWhileAlive({
      owner: this,
      next: (elements: QueryList<MenuPrintOptionsFormComponent>) => {
        if (elements?.length) {
          this.viewModel.connectToPrintOptionForm(elements.first);
        }
      }
    });
  }

}
