<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">Apply Changes to Variants</div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <app-checkbox-container [checkBoxes]="variantCheckboxes"> </app-checkbox-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button (click)="saveChanges()" class="bs-button preferred-button">Apply</button>
  </div>
</div>
