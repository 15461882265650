import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Company } from '../models/company/dto/company';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { InventoryProviderConfiguration } from '../models/company/dto/inventory-provider-configuration';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { Location } from '../models/company/dto/location';
import { InventoryRoom } from '../models/company/dto/inventory-room';

@Injectable({ providedIn: 'root' })
export class CompanyAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Company';

  // Company

  public GetCompany(): Observable<Company> {
    const url = Endpoints.GetCompany();
    return this.apiClient.getObj<Company>(Company, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompany', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyConfiguration(companyId: string): Observable<CompanyConfiguration> {
    const url = Endpoints.GetCompanyConfiguration(companyId);
    return this.apiClient.getObj<CompanyConfiguration>(CompanyConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyConfiguration', err));
        return throwError(err);
      })
    );
  }

  public UpdateCompanyConfiguration(
    config: CompanyConfiguration,
    currentLocationId: number
  ): Observable<CompanyConfiguration> {
    const url = Endpoints.UpdateCompanyConfiguration(currentLocationId);
    return this.apiClient.postObj(CompanyConfiguration, url, config).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateCompanyConfiguration', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyInventoryProviderConfigurations(): Observable<InventoryProviderConfiguration[]> {
    const url = Endpoints.CompanyInventoryProviderConfigurations();
    return this.apiClient.getArr<InventoryProviderConfiguration>(InventoryProviderConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        const apiError = new ApiErrorLog(this.serviceName, 'GetCompanyInventoryProviderConfigurations', err);
        this.loggingService.LogAPIError(apiError);
        return throwError(err);
      })
    );
  }

  public UpdateInventoryRooms(rooms: InventoryRoom[]): Observable<InventoryRoom[]> {
    const url = Endpoints.UpdateInventoryRooms();
    return this.apiClient.postArr(InventoryRoom, url, rooms).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateInventoryRooms', err));
        return throwError(err);
      })
    );
  }

  public getLocationConfiguration(locationId: string): Observable<LocationConfiguration> {
    const url = Endpoints.GetLocationConfigurations(locationId);
    return this.apiClient.getObj<LocationConfiguration>(LocationConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationConfiguration', err));
        return throwError(err);
      })
    );
  }

  public updateLocationConfiguration(config: LocationConfiguration): Observable<LocationConfiguration> {
    const url = Endpoints.UpdateLocationConfigurations();
    return this.apiClient.postObj(LocationConfiguration, url, config).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateLocationConfiguration', err));
        return throwError(err);
      })
    );
  }

  public updateCompanyLocation(location: Location): Observable<Location> {
    const url = Endpoints.UpdateLocation();
    return this.apiClient.postObj(Location, url, location).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateCompanyLocation', err));
        return throwError(err);
      })
    );
  }

}
