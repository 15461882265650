import { Component } from '@angular/core';
import { Tabbable } from '../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { BulkPrintJobsListViewModel } from './bulk-print-jobs-list-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { BulkPrintJobPollingService } from '../services/bulk-print-job-polling.service';
import { BulkJobSource } from '../../../../../../models/utils/dto/bulk-job-source-type';

@Component({
  selector: 'app-bulk-print-jobs-list',
  templateUrl: './bulk-print-jobs-list.component.html',
  styleUrls: ['./bulk-print-jobs-list.component.scss'],
  providers: [BulkPrintJobPollingService]
})
export class BulkPrintJobsListComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: BulkPrintJobsListViewModel,
    private bulkPrintJobPollingService: BulkPrintJobPollingService
  ) {
    super();
    this.bulkPrintJobPollingService.startPolling(BulkJobSource.BulkJobSource_BulkPrint);
  }

  tabActivated: BehaviorSubject<boolean>;

}
