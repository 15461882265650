import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import type { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';

@Component({
  selector: 'app-badge-popover',
  templateUrl: './badge-popover.component.html',
  styleUrls: [
    '../company-badge/company-badge.responsive.scss',
    '../company-badge/company-badge.component.scss',
    './badge-popover.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgePopoverComponent {

  @Input() badge: HydratedVariantBadge;
  @Input() showSmartFilterIndicator: boolean = false;

  loadingOptions: LoadingOptions = LoadingOptions.defaultInBadge();

}
