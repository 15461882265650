import { Component, Input } from '@angular/core';
import { LocationCardViewModel } from './location-card-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Location } from '../../../../../models/company/dto/location';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
  providers: [ LocationCardViewModel ],
})
export class LocationCardComponent extends BaseComponent {

  @Input() card: Card;
  @Input() cardClass: string;

  constructor(
    public viewModel: LocationCardViewModel,
    private router: Router,
  ) {
    super();
  }

  editMenus() {
    this.viewModel.editMenus(this.card.data as Location);
  }

  editDisplays() {
    this.viewModel.editDisplays(this.card.data as Location);
  }

  openEditLocation() {
    this.router.navigate([`/locations/${this.card.data.id}`]).then(() => {
    });
  }

}
