import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { FeaturedCategoryMenuCardType } from '../../../../../../models/utils/dto/featured-category-menu-card-type';
import { MenuSectionProductCardColorViewModel } from './menu-section-product-card-color-view-model';

@Component({
  selector: 'app-menu-section-product-card-color',
  templateUrl: './menu-section-product-card-color.component.html',
  styleUrls: ['./menu-section-product-card-color.component.scss'],
  providers: [MenuSectionProductCardColorViewModel]
})
export class MenuSectionProductCardColorComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: MenuSectionProductCardColorViewModel
  ) {
    super();
  }

  @Input() mergeKey: string;
  @Input() section: HydratedSection;
  @Input() cardType: FeaturedCategoryMenuCardType;
  @Input() colorPalette: string[];

  parseNumberOpacityToString = (val: string) => (Number.parseInt(val, 10) / 100).toString();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardType) this.viewModel.connectToCardType(this.cardType);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.colorPalette) this.viewModel.connectToColorPalette(this.colorPalette);
  }

}
