import { Component } from '@angular/core';
import { FilterProductsFormComponent } from '../../../shared/components/filter-products-form/filter-products-form.component';
import { AllProductsDataTableViewModel } from '../all-products-data-table-view-model';

@Component({
  selector: 'app-all-products-filter-form',
  templateUrl: '../../../../views/shared/components/filter-products-form/filter-products-form.component.html',
  styleUrls: ['../../../../views/shared/components/filter-products-form/filter-products-form.component.scss']
})
export class AllProductsFilterFormComponent extends FilterProductsFormComponent {

  constructor(
    public override viewModel: AllProductsDataTableViewModel
  ) {
    super(viewModel);
  }

}
