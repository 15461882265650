<!-- Primary Header -->
<app-title-and-crumbs [headerText]="headerText" [crumbs]="crumbs">
  <lib-button-primary
    *ngIf="showPublishButton"
    [disabled]="autoSaveLoadingOpts?.isLoading || formHasErrors || unsavedChanges"
    [disabledTooltip]="
      (formErrorMessages | getFormErrorDisabledTooltip) || (unsavedChanges | getSaveTemplateBeforePublishingTooltip)
    "
    (buttonClicked)="promptToPublishTemplate()">
    Publish Template
  </lib-button-primary>
</app-title-and-crumbs>

<!-- Secondary Header -->
<div class="template-secondary-header">
  <div class="left-side-of-header">
    <app-template-name>{{ template?.name }}</app-template-name>
    <app-template-status [templateStatus]="templateStatus"></app-template-status>
    <app-template-info-blurb>{{ headerSubtext }}</app-template-info-blurb>
  </div>
  <div class="right-side-of-header">
    <div fxLayout="row" fxLayoutAlign="end start" [style.gap.rem]="1">
      <lib-button-neutral
        [iconSrc]="'assets/icons/dark/outline/eye.svg'"
        [disabled]="!allowLiveView || formHasErrors || !formPristine"
        [disabledTooltip]="(formErrorMessages | getFormErrorDisabledTooltip) || (formPristine | saveChangesTooltip)"
        (buttonClicked)="showLiveView(formPristine, viewModel.formAutoSubmitted$, submitForms)">
        Live View
      </lib-button-neutral>
      <lib-button-primary
        [infoText]="lastSavedTimeText"
        [disabled]="autoSaveLoadingOpts?.isLoading || formDisableSubmit"
        [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
        [loading]="autoSaveLoadingOpts?.isLoading"
        (buttonClicked)="submitForms.emit(false)">
        Save Changes
      </lib-button-primary>
    </div>
  </div>
</div>
