import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, OperatorFunction } from 'rxjs';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class DateRangeFromNowViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private readonly _lengthInSeconds = new BehaviorSubject<number>(0);
  public readonly lengthInSeconds$ = this._lengthInSeconds as Observable<number>;
  private readonly dateFormat = 'MMM D[,] YYYY LT';
  public readonly startDate$ = this.lengthInSeconds$.pipe(this.startDate());
  public readonly hasStartDate$ = this.startDate$.pipe(map(startDate => !!startDate));
  public readonly endDate$ = of(moment().format(this.dateFormat));

  private getFormattedStartDate(lengthInSeconds: number): string {
    let startDate = '';
    if (lengthInSeconds > 0) {
      const start = moment().subtract(lengthInSeconds, 'seconds');
      startDate = start.format(this.dateFormat);
    }
    return startDate;
  }

  // Operators

  private startDate(): OperatorFunction<number, string> {
    return map(this.getFormattedStartDate.bind(this)) as OperatorFunction<number, string>;
  }

  // Connectors

  connectToLengthInSeconds = (lengthInSeconds: number) => this._lengthInSeconds.next(lengthInSeconds);

}
