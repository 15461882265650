import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-section-variant-groups-empty-state',
  templateUrl: './menu-section-variant-groups-empty-search.component.html',
  styleUrls: ['./menu-section-variant-groups-empty-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionVariantGroupsEmptySearchComponent {

  @Input() emptySearch: boolean;
  @Input() noVariantsWithThatVisibility: boolean;

}
