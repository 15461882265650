import { AssetPrintPDF } from './asset-print-pdf';
import { Preview } from '../../shared/preview';

export class PrintPDF extends Preview {

  override preview: AssetPrintPDF;

  override onDeserialize() {
    this.preview = window?.injector?.Deserialize?.instanceOf(AssetPrintPDF, this.preview);
  }

}
