// noinspection JSUnusedLocalSymbols

import { Injectable, Injector } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, throwError } from 'rxjs';
import { Variant } from '../../../../../models/product/dto/variant';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { debounceTime, delay, map, switchMap, take, tap } from 'rxjs/operators';
import type { EditDriveThruMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu-view-model';
import { DriveThruProductData } from './drive-thru-product-data';
import { Asset } from '../../../../../models/image/dto/asset';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { MenuAssets } from '../../../../../models/menu/shared/menu-assets';
import { Menu } from '../../../../../models/menu/dto/menu';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { DriveThruCard } from '../../edit-menu/edit-marketing-menu/edit-drive-thru-menu/models/cards/drive-thru-card';
import { DefaultCardCount } from '../../../../../models/menu/dto/menu-metadata';
import { ColorUtils } from '../../../../../utils/color-utils';
import { DisplayAttributesDomainModel } from '../../../../../domainModels/display-attributes-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { LabelUtils } from '../../../../../modules/product-labels/utils/label-utils';
import { CardType } from '../../../../../models/utils/dto/card-type-definition';
import { ModalEditVariant } from '../../../../../modals/modal-edit-variant';

@Injectable()
export class DriveThruProductViewModel extends BaseViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private productDomainModel: ProductDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private injector: Injector,
    private displayAttributesDomainModel: DisplayAttributesDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  private sharedViewModel: EditDriveThruMenuViewModel;
  private _sharedViewModel = new BehaviorSubject<EditDriveThruMenuViewModel>(null);
  public sharedViewModel$ = this._sharedViewModel.asObservable();

  public loadingOpts: LoadingOptions = LoadingOptions.default();

  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public priceFormat$ = this.locationDomainModel.priceFormat$;

  // Data Transport Layer
  private variantIdSubject = new BehaviorSubject<string>(null);
  public _variant = new ReplaySubject<Variant>(1);
  public variant$ = this._variant.asObservable();
  public variantName$ = this.variant$.pipe(map(v => v?.getDisplayName()));
  public isMedical$ = this.variant$.pipe(map(v => v?.isMedical));
  public variantToolTip$ = this.variant$.pipe(map(v => (v?.displayAttributes?.displayName ? v?.name : null)));
  public variantSize$ = this.variant$.pipe(map(v => `(${v?.getSize()})`));
  public variantOutOfStock$ = this.variant$.pipe(map(v => !v?.inStock()));

  // Forms
  public formData$ = new BehaviorSubject<DriveThruProductData>(new DriveThruProductData());

  // Loading Messages
  public deletingLm = 'Deleting Product';

  // Media
  private _media = new ReplaySubject<MenuAssets>();
  private _menu = new ReplaySubject<Menu>();
  public menu$ = this._menu as Observable<Menu>;
  public theme$ = this.menu$.pipe(map(m => m?.hydratedTheme));
  public asset$ = combineLatest([
    this.variantIdSubject.notNull(),
    this._menu.notNull(),
    this._media.notNull()
  ]).pipe(
    map(([variantId, menu, assets]) => {
      const variantAssetName = menu?.variantFeature?.assetNameMap?.get(variantId);
      return assets?.assets?.filterNulls()?.find(a => a.fileName === variantAssetName);
    })
  );
  public variantFeature$ = this._menu.pipe(map((m) => m?.variantFeature));

  // Index
  public _index = new BehaviorSubject<number>(null);
  public index$ = this._index.asObservable();
  public cardNumber$ = combineLatest([
    this.sharedViewModel$.pipe(
      switchMap(vm => vm.cardStartAt$),
    ),
    this.index$,
  ]).pipe(
    map(([startAt, index]) => {
      if (isNaN(parseInt(startAt, 10)) || parseInt(startAt, 10) < 1) {
        return index + 1;
      } else {
        return parseInt(startAt, 10) + index;
      }
    })
  );

  public cardType$ = this.sharedViewModel$.pipe(switchMap(shared => shared?.cardType$));
  private _comboCardData = new BehaviorSubject<DriveThruProductData>(null);
  public comboCardData$ = this._comboCardData.asObservable();
  public card$ = combineLatest([
    this.comboCardData$,
    this.cardType$,
    this._menu,
    this.variant$,
    this.asset$,
    combineLatest([this.locationConfig$, this.companyConfig$])
  ]).pipe(
    debounceTime(200),
    map(([comboProductData, cardType, menu, variant, asset, [locationConfig, companyConfig]]) => {
      return new DriveThruCard(
        cardType || menu?.metadata?.cardType || CardType.Full,
        menu,
        locationConfig,
        companyConfig,
        variant,
        asset,
        comboProductData
      );
    })
  );
  public cardBadges$ = this.card$.pipe(map(card => card?.getBadges()));
  public cardNumberBackgroundColor$ = this.comboCardData$.pipe(
    map(data => {
      if (!!data?.color) {
        return ColorUtils.validateHexColor(data.color) ?? ColorUtils.BUDSENSE_EXTRA_DARK_GREY_COLOR;
      } else {
        return ColorUtils.BUDSENSE_EXTRA_DARK_GREY_COLOR;
      }
    })
  );

  public cardCount$ = this._menu.pipe(
    map(menu => menu?.metadata?.cardType || DefaultCardCount)
  );

  private _openPreview = new BehaviorSubject<boolean>(false);
  public openPreview$ = this._openPreview.asObservable();

  public computeLabelInterface$ = combineLatest([
    this.menu$,
    this.variant$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    map(([menu, variant, locConfig, compConfig]) => {
      return LabelUtils.getComputeLabelInterfaceWithinMenuContext(menu, null, [variant], locConfig, compConfig);
    })
  );

  init(variantId?: string, sharedVM?: EditDriveThruMenuViewModel) {
    if (sharedVM) {
      this.sharedViewModel = sharedVM;
      this._sharedViewModel.next(sharedVM);
    }
    if (variantId) {
      this.variantIdSubject.next(variantId);
    }
    this.setupLoadingOpts();
    this.setupBindings();
    this.setInitialLoadingState();
  }

  public connectToIndex(i: number) {
    this._index.next(i);
  }

  public connectToVariant(v: Variant) {
    this._variant.next(v);
  }

  public deleteFeaturedVariant(id: string) {
    // perform delete
    this.sharedViewModel.addFeatureVariantToRemoveQueue(id);
    return this.sharedViewModel.deleteFeaturedProduct(id).subscribe(_ => {
      this.toastService.publishSuccessMessage('Product successfully deleted.', 'Product Deleted');
      this.sharedViewModel.removeFeatureVariantFromRemoveQueue(id);
    }, (error: BsError) => {
      this.sharedViewModel.removeFeatureVariantFromRemoveQueue(id);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  private setupLoadingOpts() {
    this.loadingOpts.backgroundColor = 'rgba(255, 255, 255, 0.9)';
  }

  setInitialLoadingState() {
    this.setFeatureVariantDeletingLoadingIndicator(this.sharedViewModel.deleteFeatVariantIdQueue.getValue() || []);
  }

  private setupBindings() {
    this.connectToSharedModel();
    this.assetReloadBinding();
    this.deleteFeaturedVariantBinding();
  }

  private connectToSharedModel() {
    const mediaSub = this._media.bind(this.sharedViewModel.media$);
    this.pushSub(mediaSub);
    const menuSub = this._menu.bind(this.sharedViewModel.menu$);
    this.pushSub(menuSub);
  }

  private assetReloadBinding() {
    const uploadQueueSub = combineLatest([
      this.sharedViewModel.menu$,
      this.sharedViewModel.uploadedAssetQueue.notNull(),
      this.variantIdSubject
    ]).subscribe(([menu, assets, variantId]) => {
      const lm = 'Refreshing Product';
      const fileName = menu?.variantFeature?.assetNameMap?.get(variantId);
      const assetToLoad = assets.find(a => a.name === fileName);
      if (!!assetToLoad && !this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
      } else if (this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.pushSub(uploadQueueSub);
  }

  public comboCardDataChanged(data: DriveThruProductData) {
    const cardData = new DriveThruProductData();
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('price')) {
        cardData.price = value as number;
      }
      if (key.includes('desc')) {
        cardData.desc = value as string;
      }
      if (key.includes('color')) {
        cardData.color = value as string;
      }
      if (key.includes('hideDesc')) {
        cardData.hideDesc = value as boolean;
      }
    }
    this._comboCardData.next(cardData);
  }

  public deleteMedia(f: Asset) {
    this.sharedViewModel.addAssetToRemoveQueue(f);
    const lm = 'Removing Media';
    this._loadingOpts.addRequest(lm);
    this.menuDomainModel.deleteAsset(f).pipe(
      switchMap(_ => this.variantIdSubject.asObservable()),
      tap(vId => {
        this.sharedViewModel.removeAssetFromComboMenu(vId, f);
      }),
      delay(5000),
    ).subscribe(_ => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Successful.', lm);
      this.sharedViewModel.removeAssetFromRemoveQueue(f);
      // Save menu in background to update rotation order
      this.sharedViewModel.saveMenu(true);
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
      this.sharedViewModel.removeAssetFromRemoveQueue(f);
      throwError(error);
    });
  }

  private deleteFeaturedVariantBinding() {
    const deleteSub = combineLatest([
      this.sharedViewModel.deleteFeatVariantIdQueue.notNull(),
      this.variantIdSubject
    ]).pipe(debounceTime(25)).subscribe(([varIds, variantId]) => {
      if (varIds.contains(variantId) && !this.loadingOpts.containsRequest(this.deletingLm)) {
        this.loadingOpts.addRequest(this.deletingLm);
      } else if (!varIds.contains(variantId) && this.loadingOpts.containsRequest(this.deletingLm)) {
        this.loadingOpts.removeRequest(this.deletingLm);
      }
    });
    this.pushSub(deleteSub);
  }

  private setFeatureVariantDeletingLoadingIndicator(ids: string[]) {
    const variantId = this.variantIdSubject.getValue();
    if (ids.contains(variantId) && !this.loadingOpts.containsRequest(this.deletingLm)) {
      this.loadingOpts.addRequest(this.deletingLm);
    } else if (!ids.contains(variantId) && this.loadingOpts.containsRequest(this.deletingLm)) {
      this.loadingOpts.removeRequest(this.deletingLm);
    }
  }

  public openPreviewClicked() {
    const curr = this._openPreview.getValue();
    this._openPreview.next(!curr);
  }

  public closePreview() {
    this._openPreview.next(false);
  }

  openEditVariantModal() {
    this.variant$.pipe(
      debounceTime(1),
      take(1)
    ).subscribe((variant) => {
      ModalEditVariant.open(this.ngbModal, this.injector, variant);
    });
  }

}

