import { Injectable } from '@angular/core';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Size } from '../../../../../../models/shared/size';
import { ModalMenuLiveView } from '../../../../../../modals/modal-menu-live-view';
import { shareReplay } from 'rxjs/operators';
import { iiif } from '../../../../../../utils/observable.extensions';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { Section } from '../../../../../../models/menu/dto/section';

@Injectable()
export abstract class EditStaticSectionMenuViewModel extends EditProductMenuViewModel {

  public showWillNotAppearOnMenuSection$ = new BehaviorSubject<boolean>(false);

  protected readonly sectionStream$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeSectionTemplate$,
    this.menuDomainModel.activeHydratedSection$
  ).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * Must override to replace non-hydrated section on menu object with hydrated section.
   */
  public override openLiveViewModal(sizeOverride?: Size) {
    combineLatest([
      this.locationId$,
      this.menu$,
      this.sectionStream$
    ]).once(([locationId, menu, section]) => {
      menu instanceof MenuTemplate && section instanceof SectionTemplate
        ? menu?.updateSectionTemplate(section, false)
        : menu?.updateSection(section as Section, false);
      ModalMenuLiveView.open(this.ngbModal, this.injector, locationId, menu, sizeOverride);
    });
  }

}
