import { CompanyDomainModel } from '../domainModels/company-domain-model';
import { DisplayAttributesDomainModel } from '../domainModels/display-attributes-domain-model';
import { DisplayDomainModel } from '../domainModels/display-domain-model';
import { HelpDomainModel } from '../domainModels/help-domain-model';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { MenuDomainModel } from '../domainModels/menu-domain-model';
import { PexelsDomainModel } from '../domainModels/pexels-domain-model';
import { ProductDomainModel } from '../domainModels/product-domain-model';
import { ScheduledMenusDomainModel } from '../domainModels/scheduled-menus-domain-model';
import { SmartFiltersDomainModel } from '../domainModels/smart-filters-domain-model';
import { FilterProductsFormViewModel } from '../views/shared/components/filter-products-form/filter-products-form-view-model';
import { AllProductsViewModel } from '../views/product/viewModels/all-products-view-model';
import { AllProductsDataTableViewModel } from '../views/tables/all-products-data-table/all-products-data-table-view-model';
import { DigitalMarketingMenusViewModel } from '../views/menu/components/menus/digital/digital-marketing-menus/digital-marketing-menus-view-model';
import { OrderReceivingFilterFormViewModel } from '../views/product/components/order-receiving/order-receiving-filter-form/order-receiving-filter-form-view-model';
import { SyncSmartFilterService } from '../services/smart-filter-sync.service';
import { ProductPickerDataTableViewModel } from '../views/tables/product-picker-data-table/product-picker-data-table-view-model';
import { ProductPickerViewModel } from '../views/product/viewModels/product-picker-view-model';
import { SmartFilterRowViewModel } from '../views/smart-filters/components/smart-filter-card-row/smart-filter-row-view-model';
import { OrderReceivingViewModel } from '../views/product/components/order-receiving/order-receiving-view-model';
import { ShowInventorySinceViewModel } from '../views/product/components/order-receiving/order-receiving-time-and-property-selection/show-inventory-since/show-inventory-since-view-model';
import { SelectBulkChangePropertiesViewModel } from '../views/product/components/order-receiving/order-receiving-time-and-property-selection/select-bulk-change-properties/select-bulk-change-properties-view-model';
import { WebMenusContainerViewModel } from '../views/menu/components/menus/web/web-menus-container/web-menus-container-view-model';
import { PrintMenusContainerViewModel } from '../views/menu/components/menus/print/print-menus-container/print-menus-container-view-model';
import { DigitalMenusContainerViewModel } from '../views/menu/components/menus/digital/digital-menus-container/digital-menus-container-view-model';
import { AllDisplaysViewModel } from '../views/display/viewModels/all-displays-view-model';
import { TypeService } from '../services/type-service';
import { SyncDomainModel } from '../domainModels/sync-domain-model';
import { TemplateDomainModel } from '../domainModels/template-domain-model';
import { TemplateCollectionDomainModel } from '../domainModels/template-collection-domain-model';
import { DigitalTemplatesContainerViewModel } from '../views/template/components/templates/digital/digital-templates-container/digital-templates-container-view-model';
import { PrintTemplatesContainerViewModel } from '../views/template/components/templates/print/print-templates-container/print-templates-container-view-model';
import { WebTemplatesContainerViewModel } from '../views/template/components/templates/web/web-templates-container/web-templates-container-view-model';
import { SectionBlueprintsDomainModel } from '../domainModels/section-blueprints-domain-model';
import { DomainTunnelFromMenuToTemplateService } from '../services/domain-tunnel-from-menu-to-template.service';
import { MenuPreviewService } from '../services/menu-preview.service';
import { PrintPDFService } from '../services/print-pdf.service';
import { ChangesRequiredForPreviewService } from '../services/changes-required-for-preview.service';
import { MenuDisplayableSubItemService } from '../views/shared/components/displayable-content/displayable-item-container/displayable-item-preview/menu-displayable-sub-item.service';
import { LabelDomainModel } from '../domainModels/label-domain-model';
import { BadgeDomainModel } from '../domainModels/badge-domain-model';
import { PrintCardPreviewService } from '../services/print-card-preview.service';
import { BulkPrintJobDomainModel } from '../domainModels/bulk-print-job-domain-model';
import { DeviceDomainModel } from '../domainModels/device-domain-model';
import { CannabinoidsAndTerpenesDomainModel } from '../domainModels/cannabinoids-and-terpenes-domain-model';

export const domainModelsGloballyScopedToLoginContext = [
  BadgeDomainModel,
  BulkPrintJobDomainModel,
  CompanyDomainModel,
  DeviceDomainModel,
  DisplayAttributesDomainModel,
  DisplayDomainModel,
  HelpDomainModel,
  LabelDomainModel,
  LocationDomainModel,
  MenuDomainModel,
  PexelsDomainModel,
  ProductDomainModel,
  ScheduledMenusDomainModel,
  SectionBlueprintsDomainModel,
  SmartFiltersDomainModel,
  SmartFiltersDomainModel,
  SyncDomainModel,
  TemplateDomainModel,
  TemplateCollectionDomainModel,
  CannabinoidsAndTerpenesDomainModel
];

export const viewModelsGloballyScopedToLoginContext = [
  FilterProductsFormViewModel,
  AllProductsViewModel,
  AllProductsDataTableViewModel,
  DigitalMarketingMenusViewModel,
  OrderReceivingFilterFormViewModel,
  ProductPickerDataTableViewModel,
  ProductPickerViewModel,
  SmartFilterRowViewModel,
  OrderReceivingViewModel,
  ShowInventorySinceViewModel,
  SelectBulkChangePropertiesViewModel,
  AllDisplaysViewModel,
  DigitalMenusContainerViewModel,
  DigitalTemplatesContainerViewModel,
  PrintMenusContainerViewModel,
  PrintTemplatesContainerViewModel,
  WebMenusContainerViewModel,
  WebTemplatesContainerViewModel
];

export const servicesGloballyScopedToLoginContext = [
  SyncSmartFilterService,
  TypeService,
  DomainTunnelFromMenuToTemplateService,
  MenuPreviewService,
  PrintCardPreviewService,
  PrintPDFService,
  ChangesRequiredForPreviewService,
  MenuDisplayableSubItemService
];
