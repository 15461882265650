import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ThemePreviewCardViewModel } from './theme-preview-card-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-theme-preview-card',
  templateUrl: './theme-preview-card.component.html',
  styleUrls: [
    '../../../../../../../src/scss/_print-cards.scss',
    './theme-preview-card.component.scss'
  ],
  providers: [ThemePreviewCardViewModel],
  encapsulation: ViewEncapsulation.None,
})
export class ThemePreviewCardComponent extends BaseComponent {

  constructor(
    public viewModel: ThemePreviewCardViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToCard') card: Card;
  @Input() @ViewModelConnector('connectToCardClass') cardClass: string;
  @Output() cardButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  handleCardButtonClicked(e) {
    e.stopPropagation();
    this.cardButtonClicked.emit(true);
  }

}
