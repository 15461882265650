import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';

@Injectable()
export class MenuLinksViewModel extends BaseViewModel {

  constructor(
    private toastService: ToastService,
    private userDomainModel: UserDomainModel,
    private locationDomainModel: LocationDomainModel,
  ) {
    super();
  }

  private _iFrameHeight = new BehaviorSubject<number>(0);
  public iFrameHeight$ = this._iFrameHeight.pipe(distinctUntilChanged());
  connectToIFrameHeight = (height: number) => this._iFrameHeight.next(height);

  private _iFrameWidth = new BehaviorSubject<number>(0);
  public iFrameWidth$ = this._iFrameWidth.pipe(distinctUntilChanged());
  connectToIFrameWidth = (width: number) => this._iFrameWidth.next(width);

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => {
    this._menu.next(menu);
    this.connectToIFrameHeight(menu?.displaySize?.height);
    this.connectToIFrameWidth(menu?.displaySize?.width);
  };

  private currentLocationId$ = this.locationDomainModel.locationId$;

  public showWebLink$ = this.menu$.pipe(
    map(menu => menu?.isWebMenu()),
    distinctUntilChanged()
  );

  // Menu url
  public menuUrl$ = combineLatest([
    this.menu$,
    this.userDomainModel.validSession$,
    this.currentLocationId$
  ]).pipe(
    map(([menu, validSession, locationId]) => (validSession ? menu?.generateMenuUrl(locationId) : ''))
  );

  public iFrameCode$ = combineLatest([
    this.menu$.notNull(),
    this.iFrameHeight$,
    this.iFrameWidth$,
    this.menuUrl$
  ]).pipe(map(([menu, h, w, url]) => {
    const name = Date.now().toString(10);
    return `<iframe src=\"${url}\" name=\"${name}\" width=\"${w}px\" height=\"${h}px\"></iframe>`;
  }));

  public copyMenuUrl(): void {
    this.menuUrl$.once(url => {
      navigator.clipboard.writeText(url).then((_) => {
        this.toastService.publishInfoMessage('Link copied to clipboard', 'Link Copied!');
      });
    });
  }

  public copyIFrameCode(): void {
    this.iFrameCode$.once(code => {
      navigator.clipboard.writeText(code).then((_) => {
        this.toastService.publishInfoMessage('IFrame code copied to clipboard', 'Code Copied!');
      });
    });
  }

}
