import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent extends BaseComponent {

  @Input() label: string;
  @Input() state: boolean;
  @Input() enabled: boolean = true;
  @Output() toggled = new EventEmitter<boolean>();

  constructor() {
    super();
  }

}
