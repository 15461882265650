import { Injectable } from '@angular/core';
import { TemplateCreationType } from '../../../../../models/enum/shared/template-creation-type.enum';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { ProductMenuType } from '../../../../../models/enum/dto/product-menu-type.enum';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { Theme } from '../../../../../models/menu/dto/theme';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { Tag } from '../../../../../models/menu/dto/tag';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { SingleSelectionItem } from '../../../../../models/shared/stylesheet/single-selection-item';
import { SmartFiltersDomainModel } from '../../../../../domainModels/smart-filters-domain-model';
import { Menu } from '../../../../../models/menu/dto/menu';
import { DistinctUtils } from '../../../../../utils/distinct-utils';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { NewMenuModalViewModel } from '../../../../menu/components/modals/new-menu-modal/new-menu-modal-view-model';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { CreateMenuTemplateReq } from '../../../../../models/template/dto/create-menu-template-req';
import { MenuSubtype } from '../../../../../models/enum/dto/menu-subtype';
import { PrintCardMenuType } from '../../../../../models/enum/dto/print-card-menu-type.enum';
import { ThemeUtils } from '../../../../../utils/theme-utils';
import { PrintReportMenuType } from '../../../../../models/enum/dto/print-report-menu-type.enum';
import { PrintLabelMenuType } from '../../../../../models/enum/dto/print-label-menu-type.enum';
import { DefaultPrintStackSize } from '../../../../../models/enum/dto/default-print-stack-size';

export enum CreateStep {
  HowToCreate = 0,
  ChooseMenuType = 1,
  PickTheme = 2,
  SelectExisting = 3,
  RenameExisting = 4,
}

@Injectable()
export class CreateTemplateContainer extends BaseModalViewModel {

  constructor(
    private templateDomainModel: TemplateDomainModel,
    private menuDomainModel: MenuDomainModel,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public get createStep(): typeof CreateStep {
    return CreateStep;
  }

  private _newTemplate = new BehaviorSubject<MenuTemplate>(new MenuTemplate());
  public newTemplate$ = this._newTemplate as Observable<MenuTemplate>;

  private _creationType = new BehaviorSubject<TemplateCreationType>(null);
  public creationType$ = this._creationType as Observable<TemplateCreationType>;

  private _menuType = new BehaviorSubject<MenuType>(MenuType.DisplayMenu);
  public menuType$ = this._menuType as Observable<MenuType>;
  public connectToMenuType = (mt: MenuType) => {
    if (mt === MenuType.PrintReportMenu) this._readableMenuType.next('Report');
    this._menuType.next(mt);
  };

  private _menuSubtypes = new BehaviorSubject<MenuSubtype[]>([ProductMenuType.ProductMenu]);
  public menuSubtypes$ = this._menuSubtypes as Observable<MenuSubtype[]>;

  private _readableMenuType = new BehaviorSubject<string>('Product Menu');
  public readableMenuType$ = this._readableMenuType as Observable<string>;

  private _creationStep = new BehaviorSubject<number>(0);
  public creationStep$ = this._creationStep.asObservable();

  public _formIsValid = new BehaviorSubject<boolean>(false);
  public formIsValid$ = this._formIsValid as Observable<boolean>;

  private _formHasErrors = new BehaviorSubject<boolean>(false);
  public formHasErrors$ = this._formHasErrors as Observable<boolean>;

  private _themeSelected = new BehaviorSubject<Theme>(null);
  public themeSelected$ = this._themeSelected.asObservable();

  private _orientationSelected = new BehaviorSubject<Orientation>(null);
  public orientationSelected$ = this._orientationSelected.asObservable();

  private _existingMenuSelected = new BehaviorSubject<Menu>(null);
  public existingMenuSelected$ = this._existingMenuSelected.asObservable();

  public dispersedKey$ = of('createTemplate');
  public tags$: Observable<Tag[]> = this.templateDomainModel.existingMenuTemplateTags$;

  private idContainer$ = combineLatest([
    this.locationDomainModel.locationId$,
    this.companyDomainModel.companyId$
  ]);

  private templateDetails$ = combineLatest([
    this.themeSelected$,
    this.menuType$,
    this.orientationSelected$,
    this.existingMenuSelected$
  ]);

  public navMap$ = combineLatest([
    this.creationType$,
    this.menuType$
  ]).notNull().pipe(
    map(([createType, menuType]) => {
      const navMap = new Map<number, CreateStep>();
      navMap.set(0, CreateStep.HowToCreate);
      switch (createType) {
        case TemplateCreationType?.Existing:
          navMap.set(1, CreateStep.SelectExisting);
          navMap.set(2, CreateStep.RenameExisting);
          break;
        case TemplateCreationType?.New:
          const onlyOneSubTypeMenuTypes = [
            MenuType.PrintMenu,
            MenuType.PrintCardMenu,
            MenuType.PrintLabelMenu,
            MenuType.PrintReportMenu
          ];
          if (onlyOneSubTypeMenuTypes.includes(menuType)) {
            navMap.set(1, CreateStep.PickTheme);
          } else {
            navMap.set(1, CreateStep.ChooseMenuType);
            navMap.set(2, CreateStep.PickTheme);
          }
      }
      return navMap;
    })
  );

  public getCreateStepFromNavMap$ = combineLatest([
    this.navMap$,
    this.creationStep$
  ]).notNull().pipe(
    map(([navMap, navStep]) => {
      return navMap.get(navStep);
    })
  );

  public showThemePicker$ = this.getCreateStepFromNavMap$.pipe(
    map(step => step === CreateStep.PickTheme || step === CreateStep.RenameExisting)
  );

  public creatingFromExisting$ = this.getCreateStepFromNavMap$.pipe(
    map(step => step === CreateStep.RenameExisting)
  );

  public showNextButton$ = this.getCreateStepFromNavMap$.pipe(
    map(step => step === CreateStep.SelectExisting)
  );
  public showCreateButton$ = this.getCreateStepFromNavMap$.pipe(
    map(step => step === CreateStep.PickTheme || step === CreateStep.RenameExisting)
  );

  private clearMenuSelection = this.getCreateStepFromNavMap$.subscribeWhileAlive({
    owner: this,
    next: step => {
      if (step !== CreateStep.SelectExisting && step !== CreateStep.RenameExisting) {
        this._existingMenuSelected.next(null);
      }
    }
  });

  public modalTitle$ = combineLatest([
    this.getCreateStepFromNavMap$,
    this.menuType$,
    window.types.menuTypes$,
  ]).pipe(
    map(([creationStep, menuType, menuTypes]) => {
      const readableMenuType = menuTypes?.find(mt => mt.value === menuType).name;
      switch (creationStep) {
        case CreateStep.HowToCreate:
          return 'Choose How to Create Template';
        case CreateStep.ChooseMenuType:
          return `Choose ${readableMenuType} Type`;
        case CreateStep.PickTheme:
          return 'Create Template';
        case CreateStep.SelectExisting:
          return 'Select Existing Menu';
        case CreateStep.RenameExisting:
          return 'Set Name of New Template';
      }
    })
  );

  public modalSubtitle$ = combineLatest([
    this.getCreateStepFromNavMap$,
    this.readableMenuType$,
    this.locationDomainModel.locationName$
  ]).pipe(
    map(([creationStep, readableMenuType, locName]) => {
      switch (creationStep) {
        case CreateStep.HowToCreate:
          return null;
        case CreateStep.ChooseMenuType:
          return null;
        case CreateStep.PickTheme:
          return `${readableMenuType}`;
        case CreateStep.SelectExisting:
          return `You are viewing menus at ${locName}`;
        case CreateStep.RenameExisting:
          return null;
      }
    })
  );

  public canGoNextStep$ = this.existingMenuSelected$.pipe(
    map(existingMenuSelected => !!existingMenuSelected)
  );

  public canSubmitForm$ = combineLatest([
    this.formIsValid$,
    this.formHasErrors$,
    this.themeSelected$,
    this.existingMenuSelected$,
    this.getCreateStepFromNavMap$
  ]).pipe(
    map(([formValid, formHasErrors, themeSelected, existingMenuSelected, step]) => {
      if (step === CreateStep.PickTheme) {
        return formValid && !!themeSelected;
      }
      if (step === CreateStep.RenameExisting) {
        return !formHasErrors && !!existingMenuSelected;
      }
    })
  );

  private locationMenusFilteredByMenuType$ = combineLatest([
    this.menuDomainModel.currentLocationMenus$.notNull().pipe(
      distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
    ),
    this.menuType$
  ]).pipe(
    map(([menus, menuType]) => {
      // We only want to show non-templated menus in the menu picker
      return menus?.filter(m => !m.templateId && m.type === menuType);
    })
  );

  public selectableMenus$ = combineLatest([
    this.locationMenusFilteredByMenuType$,
    this.smartFiltersDomainModel.clientLocationSmartFilterIds$.notNull()
  ]).pipe(
    map(([menus, smartFilterIds]) => {
      const selectableItems = [];
      const tooltip = 'This menu contains smart filters that are location specific. \n'
        + 'By creating a template from this menu, all smart filters will be \n'
        + 'elevated to company smart filters.';
      menus?.forEach(m => {
        const item = new SingleSelectionItem(m?.name, null, m);
        // if menu contains location smart filters
        if (m?.containsAtLeastOneSmartFilterIds(smartFilterIds)) {
          item.iconSrc = 'assets/icons/dark/solid/lightning-bolt.svg';
          item.tooltip = tooltip;
        }
        selectableItems.push(item);
      });
      selectableItems.sort((a, b) => a.title.localeCompare(b.title));
      return selectableItems;
    })
  );

  public incrementCreationStep(): void {
    this.creationStep$.pipe(take(1)).subscribe(step => {
      this.setCreationStep(++step);
    });
  }

  public decrementCreationStep(): void {
    this.creationStep$.pipe(take(1)).subscribe(step => {
      this.setCreationStep(--step);
    });
  }

  public paperSizeTypes$ = combineLatest([
    window.types.printSizeTypes$,
    window.types.printCardPaperSizeTypes$
  ]);

  private _selectedStackSize = new BehaviorSubject<DefaultPrintStackSize>(null);
  public selectedStackSize$ = this._selectedStackSize as Observable<DefaultPrintStackSize>;
  connectToSelectedStackSize = (css: DefaultPrintStackSize) => this._selectedStackSize.next(css);

  public createNewTemplate(): void {
    combineLatest([
      this.idContainer$,
      this.templateDetails$,
      this.newTemplate$,
      this.selectedStackSize$,
      this.paperSizeTypes$,
    ]).once(([
      [locationId, compId],
      [theme, menuType, orientation, existingMenu],
      newTemplate,
      stackSizeSelected,
      [printSizeTypes, cardPaperSizeTypes]
    ]) => {
      const lm = 'Creating Template';
      this._loadingOpts.addRequest(lm);
      const newTemplateReq = new CreateMenuTemplateReq();
      newTemplate.companyId = compId;
      newTemplate.locationId = locationId;
      if (!!existingMenu) { // create from existing menu
        newTemplateReq.existingMenuId = existingMenu?.id;
        newTemplateReq.name = newTemplate?.name;
      } else {
        newTemplate.theme = theme.id;
        newTemplate.hydratedTheme = theme;
        newTemplate.type = menuType;
        newTemplate.tag = newTemplate?.tag?.toLowerCase();
        NewMenuModalViewModel.applyDefaultDisplaySize(newTemplate, printSizeTypes, cardPaperSizeTypes);
        if (newTemplate?.containsStackedContent()) {
          newTemplate.metadata.printCardSize = stackSizeSelected;
        }
        if (newTemplate.type !== MenuType.PrintMenu) {
          newTemplate.displaySize.orientation = orientation;
        }
        if (newTemplate?.isMarketingMenu()) {
          newTemplate.metadata.cardType = ThemeUtils.getStartingCardType(newTemplate.theme);
        }
        newTemplateReq.template = newTemplate;
      }
      const fetchSFs$ = newTemplateReq.existingMenuId
        ? this.smartFiltersDomainModel.getSmartFiltersForLocation()
        : of(null);
      this.templateDomainModel.createMenuTemplate(newTemplateReq).pipe(
        take(1),
        tap(t => this.dismissModalSubject.next(t)),
        switchMap(_ => fetchSFs$)
      ).subscribe(_ => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('New template created successfully.', 'Template Created');
      }, (err: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
        throwError(err);
      });
    });
  }

  public connectToThemeSelected(t: Theme) {
    this._themeSelected.next(t);
  }

  public existingMenuSelected(m: SingleSelectionItem) {
    this._existingMenuSelected.next(m.value);
  }

  public moveToNextStepForExistingSelection() {
    this.existingMenuSelected$.once(existingMenu => {
      const templateBasedOffExistingMenu = window?.injector?.Deserialize?.instanceOf(MenuTemplate, existingMenu);
      this._newTemplate.next(templateBasedOffExistingMenu);
      this._menuSubtypes.next([existingMenu.getSubType()]);
      this.incrementCreationStep();
    });
  }

  public connectToOrientationSelected = (o: Orientation) => this._orientationSelected.next(o);
  public connectToCreationType = (ct: TemplateCreationType) => {
    this._creationType.next(ct);
    this.setMenuSubTypeFromCreationType(ct);
  };
  public connectToMenuSubTypes = (mt: MenuSubtype[]) => this._menuSubtypes.next(mt);
  public connectToReadableMenuType = (t: string) => this._readableMenuType.next(t);
  private setCreationStep = (n: number) => this._creationStep.next(n);
  public connectToFormIsValid = (valid: boolean) => this._formIsValid.next(valid);
  public connectToFormHasErrors = (hasErrors: boolean) => this._formHasErrors.next(hasErrors);

  private setMenuSubTypeFromCreationType(ct: TemplateCreationType) {
    combineLatest([
      this.navMap$,
      this.menuType$
    ]).once(([navMap, mt]) => {
      const userSubTypeSelection = navMap?.containsValue(CreateStep.ChooseMenuType);
      if (ct === TemplateCreationType.New && !userSubTypeSelection) {
        // If user does not select the subtype, we want to set the correct subtype based on menu type
        switch (mt) {
          case MenuType.PrintCardMenu:
            this.connectToMenuSubTypes([PrintCardMenuType.PrintCardMenu]);
            break;
          case MenuType.PrintLabelMenu:
            this.connectToMenuSubTypes([PrintLabelMenuType.PrintLabelMenu]);
            break;
          case MenuType.PrintReportMenu:
            this.connectToMenuSubTypes([PrintReportMenuType.OrderReview]);
            break;
          default:
            // Use PRODUCT_MENU as catch all. All other options should be defined above, or present the subtype picker
            this.connectToMenuSubTypes([ProductMenuType.ProductMenu]);
        }
      }
    });
  }

}

