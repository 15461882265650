<div class="modal-body sticky">
  <app-loading *ngIf="viewModel?.loadingOpts?.isLoading" [options]="viewModel?.loadingOpts"> </app-loading>
  <div class="sticky-header-container">
    <div class="modal-header" style="padding: 0 0 0.5rem 0">
      <div class="modal-title">Edit Product Label</div>
      <div class="modal-subtitle-container">
        <div style="max-width: 75%">
          <span class="modal-subtitle">
            {{ viewModel.variantDisplayName$ | async }}
          </span>
          <!--   Label previews for primary and disabled secondary (if applicable)   -->
          <div class="label-preview-container ml-8px">
            <app-label-preview
              #labelPreviewComponent
              [computeLabelInterface]="viewModel?.computeLabelInterface$ | async">
            </app-label-preview>
          </div>

          <div
            class="product-label-remove text-link destructive-link inline-text-link"
            style="font-size: 0.75rem"
            (click)="viewModel?.removeLabels()"
            *ngIf="!!(viewModel?.existingOverrideLabel$ | async)">
            Remove Label
          </div>
          <div class="inherited-label-info" *ngIf="viewModel?.inheritedDALabelText$ | async as labelText">
            {{ labelText }}
          </div>
        </div>
        <div class="additional-label-info">
          <!--  show reason why system label is automatically applied   -->
          <app-info-banner
            class="full-width"
            *ngIf="(labelPreviewComponent?.appliedSortedSystemLabels$ | async)?.length > 0"
            [buttonText]="(viewModel?.showLabelHierarchy$ | async) ? 'Hide Label Order' : 'Show Label Order'"
            (buttonClicked)="viewModel.explicitlyShowOrHideLabel()">
            {{ viewModel.existingSystemLabelText$ | async }}
          </app-info-banner>
          <!--   show hierarchy of labels   -->
          <app-label-hierarchy-banner
            class="full-width"
            *ngIf="viewModel?.showLabelHierarchy$ | async"
            [message]="
              'A product may only have one label visible. The labels will be displayed based on the following order:'
            "
            [labels]="viewModel?.locationLabels$ | async"
            [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
            [activeSaleLabelFormat]="viewModel?.activeSaleLabelFormat$ | async">
          </app-label-hierarchy-banner>
        </div>
      </div>
    </div>
  </div>
  <div
    class="sticky-body-container mt-16px"
    [style.padding-left]="'0.3rem'"
    [style.padding-right]="'0.6rem'"
    [style.max-height]="'32rem'">
    <app-label-picker
      #labelPickerComponent
      [selectedLabel]="viewModel.selectedOverrideLabel$ | async"
      [isCompany]="viewModel?.templateMode$ | async"
      [includePadding]="false"
      [showClearSelectionButton]="false"
      (selectedId)="viewModel.connectToSelectedOverrideLabelKey($event)"
      (clearSelectionClicked)="viewModel.connectToSelectedOverrideLabelKey(null)">
    </app-label-picker>
  </div>
  <div style="padding-top: 1rem" class="sticky-footer-container modal-footer-flex-end">
    <button class="bs-button neutral-button edit-company-label" (click)="editLabels()">Edit Labels</button>
    <div fxLayout="row" fxLayoutGap="0.5rem" [style.background-color]="'white'">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary
        [loading]="viewModel?.loadingOpts?.isLoading"
        [disabled]="viewModel?.loadingOpts?.isLoading || !(viewModel.newCustomLabelSelected$ | async)"
        (buttonClicked)="promptForSaveLabelOverride()">
        Save
      </lib-button-primary>
    </div>
  </div>
</div>
