import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Menu } from '../../../models/menu/dto/menu';
import { delay, distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { Product } from '../../../models/product/dto/product';
import { Injectable } from '@angular/core';
import { iiif } from '../../../utils/observable.extensions';
import { exists } from '../../../functions/exists';
import { SearchUtils } from '../../../utils/search-utils';
import { Variant } from '../../../models/product/dto/variant';
import { ScalableLiveViewModalViewModel } from '../scalable-live-view-modal-view-model';
import { PrintCardMenuUtils } from '../../../utils/print-card-menu-utils';
import { SortUtils } from '../../../utils/sort-utils';
import { SegmentedControlOption } from '../../../models/shared/stylesheet/segmented-control-option';
import { PrintCardTheme } from '../../../models/enum/dto/theme.enum';
import { PriceFormat } from '../../../models/utils/dto/price-format-type';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';

@Injectable()
export class PrintCardLiveViewModalViewModel extends ScalableLiveViewModalViewModel {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected productDomainModel: ProductDomainModel
  ) {
    super();
  }

  private readonly _printCardMenu = new BehaviorSubject<Menu|null>(null);
  public readonly printCardMenu$ = this._printCardMenu as Observable<Menu|null>;
  connectToPrintCardMenu = (printCardMenu: Menu|null) => this._printCardMenu.next(printCardMenu);
  public printConfig$ = this.printCardMenu$.pipe(
    map(printCardMenu => printCardMenu?.hydratedTheme?.printConfig)
  );

  public printCardSize$ = this.printCardMenu$.pipe(
    map(printCardMenu => printCardMenu?.metadata?.printCardSize)
  );

  public maxVariantsPerCard$ = combineLatest([
    this.printConfig$,
    this.printCardSize$
  ]).pipe(
    map(([printConfig, printCardSize]) => printConfig?.gridCountMap?.get(printCardSize))
  );

  private _sortedVariantIds = new BehaviorSubject<string[]|null>(null);
  public sortedVariantIds$ = this._sortedVariantIds as Observable<string[]|null>;
  connectToSortedVariantIds = (sortedVariantIds: string[]|null) => this._sortedVariantIds.next(sortedVariantIds);

  private readonly _searchTextAndHits = new BehaviorSubject<[string, any[]]>([null, []]);
  public readonly searchTextAndHits$ = this._searchTextAndHits as Observable<[string, any[]]>;
  connectToSearchTextAndHits = (x: [string, any[]]) => this._searchTextAndHits.next(x || [null, []]);

  public readonly shouldGroupProductVariantsTogether$ = this.printCardMenu$.pipe(
    map(menu => menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.shouldGroupProductVariantsTogether() ?? false),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly title$ = this.printCardMenu$.pipe(map(menu => menu?.name));

  private _iFrameLoaded = new BehaviorSubject<boolean>(false);
  public readonly iFrameLoaded$ = this._iFrameLoaded.pipe(delay(1000), startWith(false), distinctUntilChanged());
  connectToIFrameLoaded = (x: boolean) => this._iFrameLoaded.next(x);

  private _selectedSegmentValue = new BehaviorSubject<string|null>(null);
  public readonly selectedSegmentValue$ = this._selectedSegmentValue as Observable<string|null>;
  connectToSelectedSegmentValue = (x: SegmentedControlOption[]) => {
    const value = x?.firstOrNull()?.getSelectionValue();
    this._selectedSegmentValue.next(value);
  };

  /* ***************************** Group Product Variants Together ****************************** */

  public readonly productIds$ = this.printCardMenu$.pipe(
    map(menu => menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.productIds),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly products$: Observable<Product[]> = combineLatest([
    this.productIds$,
    this.productDomainModel.currentLocationProducts$
  ]).pipe(
    map(([productIds, products]) => {
      return productIds
        ?.map(productId => products?.find(product => product?.id === productId))
        ?.filterNulls();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * Sorted variants are passed in externally (most likely from edit section)
   * so we don't have to sort them in here, which increases the loading speed of this modal
   */
  public chunkedVariants$ = combineLatest([
    this.printCardMenu$,
    this.sortedVariantIds$,
    this.products$,
    this.maxVariantsPerCard$
  ]).pipe(
    map(([printCardMenu, sortedVariantIds, products, maxVariantsPerCard]) => {
      const stack = printCardMenu?.getSectionsBasedOnMenuType()?.firstOrNull();
      const sortedProducts = sortedVariantIds?.length
        ? sortedVariantIds?.map(vId => products?.find(p => p?.hasVariantById(vId))).uniqueByProperty('id')
        : products;
      return sortedProducts?.flatMap(p => {
        const getGridVariants = (): Variant[] => stack
          ?.getScopedVisibleVariantsForGridMode(products, p?.variants, printCardMenu, PriceFormat.Default, false)
          ?.flatten();
        const visibleVariants: Variant[] = stack?.isGridMode()
          ? getGridVariants()
          : p?.variants;
        const hasSingleVariant = visibleVariants?.length === 1;
        switch (true) {
          case stack?.isChildVariantList():
          case hasSingleVariant:
            return [visibleVariants];
          default:
            return PrintCardMenuUtils.spreadLargePrintCardGridAcrossMultipleCards(visibleVariants, maxVariantsPerCard);
        }
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * We can't directly pluck products from the section, because the frontend needs to filter out irrelevant
   * variants from the product. Therefore, we need to find which variants are relevant to the product and
   * then rebuild the product with the correct variants.
   */
  public chunkedProducts$ = combineLatest([
    this.chunkedVariants$,
    this.products$
  ]).pipe(
    map(([chunkedVariants, products]) => {
      return chunkedVariants
        ?.map(variantChunk => {
          const vId = variantChunk?.firstOrNull()?.id;
          const product = products?.find(p => p?.hasVariantById(vId));
          return product?.shallowCopyAndReplaceVariants(variantChunk) || null;
        })
        ?.filterNulls();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchableProducts$ = this.chunkedProducts$.pipe(
    map(products => products?.map(p => SearchUtils.getSimpleSearchableProduct(p))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleSortedProducts$: Observable<Product[]|null> = combineLatest([
    this.chunkedProducts$,
    this.searchTextAndHits$,
  ]).pipe(
    map(([products, [searchText, hits]]) => {
      if (searchText?.length >= 2) {
        return hits
          ?.map(hit => {
            return products?.find(p => {
              const sortedVariantIds = p?.variants?.map(v => v?.id)?.sort(SortUtils.numericStringAsc);
              const sortedHitVariantIds = hit?.variantIds?.sort(SortUtils.numericStringAsc);
              return p?.id === hit?.id && sortedVariantIds?.equals(sortedHitVariantIds);
            });
          })
          ?.filterNulls();
      }
      return products;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _selectedProductId = new BehaviorSubject<string|null>(null);
  connectToSelectedProductId = (selectedProductId: string|null) => this._selectedProductId.next(selectedProductId);
  private readonly hasSelectedProductId$ = this._selectedProductId.pipe(map(productId => exists(productId)));
  private readonly firstProductId$ = combineLatest([
    this.sortedVariantIds$,
    this.products$
  ]).pipe(
    map(([sortedVariantIds, products]) => {
      const firstVariantId = sortedVariantIds?.firstOrNull();
      const sortedProductId = products?.find(p => p?.hasVariantById(firstVariantId))?.id;
      return sortedProductId || products?.firstOrNull()?.id;
    })
  );
  public readonly selectedProductId$ = iiif(
    this.hasSelectedProductId$,
    this._selectedProductId,
    this.firstProductId$
  ).pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly noVisibleProducts$ = this.visibleSortedProducts$.pipe(
    map(products => !products?.length)
  );

  public productSelected$(item: Product): Observable<boolean> {
    return combineLatest([
      this.selectedProductId$,
      this.selectedVariantIds$
    ]).pipe(
      map(([selectedProductId, selectedVariantIds]) => {
        const allVariantsSelected = item?.variants?.map(v => v?.id)?.every(id => selectedVariantIds?.includes(id));
        return (selectedProductId === item?.id) && allVariantsSelected;
      })
    );
  }

  /* *************************** Line Item Mode *************************** */

  public readonly variants$ = this.products$.pipe(
    map(products => products?.flatMap(product => product?.variants)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchableVariants$ = this.variants$.pipe(
    map(variants => variants?.map(v => SearchUtils.getSimpleSearchableVariant(v))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleVariants$: Observable<Variant[]|null> = combineLatest([
    this.sortedVariantIds$,
    this.variants$,
    this.searchTextAndHits$
  ]).pipe(
    map(([sortedVariantIds, variants, [searchText, hits]]) => {
      const variantHits = (searchText?.length >= 2)
        ? hits?.map(hit => variants?.find(variant => variant?.id === hit?.id))?.filterNulls()
        : variants;
      return sortedVariantIds?.length
        ? sortedVariantIds?.map(variantId => variantHits?.find(variant => variant?.id === variantId))?.filterNulls()
        : variantHits;
    })
  );

  private readonly _selectedVariantIds = new BehaviorSubject<string[]|null>(null);
  connectToSelectedVariantIds = (ids: string[]|null) => this._selectedVariantIds.next(ids);
  public readonly selectedVariantIds$ = combineLatest([
    this._selectedVariantIds,
    this.products$,
    this.selectedProductId$,
    this.printCardMenu$
  ]).pipe(
    map(([selectedVariantIds, products, selectedProductId, menu]) => {
      if (exists(selectedVariantIds?.length)) {
        return selectedVariantIds;
      }
      const selectedProduct: Product = products?.find(product => product?.id === selectedProductId);
      const enabledVariantIds = menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.enabledVariantIds;
      const productVariantIds = selectedProduct.variants?.map(variant => variant?.id) ?? [];
      return exists(enabledVariantIds) && enabledVariantIds?.length > 0
        ? productVariantIds?.filter(id => enabledVariantIds?.includes(id))
        : productVariantIds;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly noVisibleVariants$ = this.visibleVariants$.pipe(
    map(variants => !variants?.length)
  );

  public variantSelected$(variant: Variant): Observable<boolean> {
    return this.selectedVariantIds$.pipe(
      map(selectedVariantIds => selectedVariantIds?.includes(variant?.id))
    );
  }

  public readonly segmentedControls$: Observable<SegmentedControlOption[] | null> = combineLatest([
    this.printCardMenu$,
    this.iFrameLoaded$
  ]).pipe(
    switchMap(([menu, iFrameLoaded]) => {
      if (!iFrameLoaded || menu?.theme !== PrintCardTheme.FireAndFlower) {
        return of(null);
      }
      return combineLatest([
        of([
          new SegmentedControlOption('Regular', 'regular'),
          new SegmentedControlOption('Member', 'member'),
          new SegmentedControlOption('Sale', 'sale')
        ]).pipe(tap(options => options[0].selected = true)),
        this.variants$,
        this.selectedVariantIds$,
        this.companyDomainModel.companyId$,
        this.locationDomainModel.locationId$ // variant.locationId isn't set
      ]).pipe(
        map(([options, variants, selectedVariantIds, companyId, locationId]) => {
          const variant = selectedVariantIds?.map(id => variants?.find(v => v?.id === id))
            ?.filterNulls()
            ?.firstOrNull();
          const secondaryLocationPrice = variant
            ?.locationPricing
            ?.find(it => it?.locationId === locationId)
            ?.secondaryPrice || null;
          const secondaryCompanyPrice = variant
            ?.locationPricing
            ?.find(it => it?.locationId === companyId)
            ?.secondaryPrice || null;
          const hasMemberPricing = Number.isFinite(secondaryLocationPrice || secondaryCompanyPrice);
          const discounted = variant?.hasDiscount(locationId, variant?.companyId, PriceFormat.Default);
          const hasSale = !menu?.menuOptions?.hideSale && discounted;
          const regularSegment = options?.[0];
          const memberSegment = options?.[1];
          const saleSegment = options?.[2];
          const setSegmentedState = (condition: boolean, segment: SegmentedControlOption) => {
            if (condition) {
              segment.disabled = false;
            } else {
              segment.disabled = true;
              const wasSelected = segment.selected;
              segment.selected = false;
              if (wasSelected) {
                regularSegment.selected = true;
                this.connectToSelectedSegmentValue([regularSegment]);
              }
            }
          };
          setSegmentedState(hasMemberPricing, memberSegment);
          setSegmentedState(hasSale, saleSegment);
          return options;
        })
      );
    })
  );

  /* ******************************* Searching **************************** */

  splitter<T, U>(true$: Observable<T>, false$: Observable<U>): Observable<T|U> {
    return iiif(this.shouldGroupProductVariantsTogether$, true$, false$);
  }
  private readonly productsPlaceHolder = `Search products by name`;
  private readonly variantsPlaceHolder = `Search variants by name`;
  public readonly searchPlaceholder$ = this.splitter(of(this.productsPlaceHolder), of(this.variantsPlaceHolder));
  public readonly searchableItems$ = this.splitter(this.searchableProducts$, this.searchableVariants$);
  public readonly noVisibleItems$ = this.splitter(this.noVisibleProducts$, this.noVisibleVariants$);
  public readonly emptyText$ = this.splitter(of('No products found'), of('No variants found'));
  public readonly sortedVisibleItems$ = this.splitter(this.visibleSortedProducts$, this.visibleVariants$);

  public selected$(item: Product | Variant): Observable<boolean> {
    return this.splitter(this.productSelected$(item as Product), this.variantSelected$(item as Variant));
  }

  itemClicked(item: Product | Variant): void {
    if (item instanceof Variant) {
      this.connectToSelectedProductId(item?.productId);
      this.connectToSelectedVariantIds([item?.id]);
    }
    if (item instanceof Product) {
      this.connectToSelectedProductId(item?.id);
      this.connectToSelectedVariantIds(item?.variants?.map(v => v?.id));
    }
  }

}
