import { Pipe, PipeTransform } from '@angular/core';
import type { Variant } from '../../../models/product/dto/variant';
import type { HydratedVariantBadge } from '../../../models/product/dto/hydrated-variant-badge';
import { SortUtils } from '../../../utils/sort-utils';
import { Observable } from 'rxjs';
import { Product } from '../../../models/product/dto/product';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'variantBadges'
})
export class VariantBadgesPipe implements PipeTransform {

  transform(variant: Variant, rowData$: Observable<Product>): Observable<HydratedVariantBadge[]>  {
    return rowData$.pipe(
      map(() => {
        const locationBadges = variant?.getLocationBadges()?.sort(SortUtils.sortBadgesByNameAscending);
        const companyBadges = () => variant?.getCompanyBadges()?.sort(SortUtils.sortBadgesByNameAscending);
        return locationBadges?.length
          ? locationBadges
          : companyBadges() ?? [];
      })
    );
  }

}
