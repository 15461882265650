<lib-reactive-form-group [bindTo]="collection" [mergeKey]="mergeKey">
  <app-edit-template-locations-form-item
    [inputName]="'requiredDisplayIds'"
    [bindingProperty]="'requiredDisplayIds'"
    [updatedRequiredIds]="viewModel.changeRequiredDisplayIds$ | async"
    (requiredIdsChanged)="requiredDisplaysChanged.emit($event)">
  </app-edit-template-locations-form-item>
  <app-edit-collection-pending-display-form-item
    [inputName]="'pendingDisplay'"
    [bindingProperty]="'pendingDisplay'"
    [pendingDisplay]="viewModel.pendingDisplay$ | async"
    (pendingDisplayChanged)="pendingDisplayChanged.emit($event)">
  </app-edit-collection-pending-display-form-item>
</lib-reactive-form-group>
<div class="row edit-collection-section">
  <div class="col-4">
    <div class="d-flex flex-column align-items-start" [style.gap.rem]="1">
      <div class="section-title mb-16px">Displays</div>
      <div class="section-subtext">
        Assign this template collection to be required at select displays and view displays where this collection is
        active.
      </div>
      <div *ngIf="viewModel.hasRequiredOrActiveDisplayIds$ | async" class="d-flex flex-wrap" [style.gap.rem]="1">
        <lib-button-primary
          (buttonClicked)="viewModel.editCollectionDisplays(viewModel.requiredDisplaysEditStep.ApplyToExisting)">
          Edit Required Displays
        </lib-button-primary>
        <lib-button-neutral
          (buttonClicked)="viewModel.editCollectionDisplays(viewModel.requiredDisplaysEditStep.NewDisplayDetails)">
          {{ viewModel.newDisplaysButtonText$ | async }}
        </lib-button-neutral>
      </div>
    </div>
  </div>
  <div class="col-8 displays-list d-flex flex-column">
    <ng-container [ngSwitch]="viewModel.hasRequiredOrActiveDisplayIds$ | async">
      <ng-container *ngSwitchCase="true">
        <app-edit-collection-location-list-item
          *ngFor="let province of (viewModel.activeDisplaysGroupedByLocationAndProvince$ | async)?.provinces"
          [province]="province.name"
          [locationDisplays]="province.locations"
          [originalRequiredDisplayIds]="viewModel.originalRequiredDisplayIds$ | async"
          [updatedRequiredDisplayIds]="viewModel.changeRequiredDisplayIds$ | async"
          [collectionIsPublished]="viewModel.collectionIsPublished$ | async"
          (provinceClicked)="viewModel.provinceClicked($event)">
        </app-edit-collection-location-list-item>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <div class="empty-state-text">
          Required and active template collection displays will appear in a list by state/province here. You can add,
          edit, and remove required locations from here.
        </div>
        <div class="mt-16px">
          <lib-button-primary
            [disabled]="!formPristine"
            [disabledTooltip]="formPristine | saveChangesTooltip"
            [iconSrc]="'assets/icons/light/outline/plus.svg'"
            (buttonClicked)="viewModel.editCollectionDisplays(viewModel.requiredDisplaysEditStep.ChooseEditMethod)">
            Add Required Displays
          </lib-button-primary>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
