export class FormGroupStyling {

  public includePadding: boolean = true;
  // 2 === two-column, 69 === two-column-expand-last
  public numberColumns: number = 1;
  // Primary button container
  public primaryButtonFloat: string = 'none';
  public resetButtonText: string = '';
  public cancelButtonText: string = '';
  public submitButtonText: string = '';
  // Secondary button container
  public secondaryButtonFloat: string = 'none';
  public secondaryButtonText: string = '';
  public secondaryButtonClass: string = '';
  // Checkbox
  public checkboxLabel: string = '';
  public checkboxClass: string = '';
  // Tooltip
  public tooltipPosition: string = '';

  public getButtonMargin(secondary: boolean = false): string {
    switch (secondary ? this.secondaryButtonFloat : this.primaryButtonFloat) {
      case 'right':
        return '0 0 0 1rem';
      case 'left':
        return '0 1rem 0 0';
      default:
        return '0 0.5rem';
    }
  }

}
