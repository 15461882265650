import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HiddenFormInputComponent } from '../hidden-form-input/hidden-form-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-reactive-form-incrementor',
  templateUrl: './reactive-form-incrementor.component.html',
  styleUrls: ['./reactive-form-incrementor.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => ReactiveFormIncrementorComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReactiveFormIncrementorComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveFormIncrementorComponent extends HiddenFormInputComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() zeroIsNull: boolean = false;
  @Input() minValue: number = 0;
  @Input() initialValueIfNoBindingValue: number = 1;
  @Input() incrementBy: number = 1;
  @Input() maxValue: number = Infinity;

  private readonly _number = new BehaviorSubject<number|null>(1);
  public readonly number$ = this._number as Observable<number|null>;

  protected getBindingValue(fromBinding: number|null): number|null {
    const withinBinding = Number.isFinite(fromBinding) ? fromBinding : this.initialValueIfNoBindingValue;
    this._number.next(withinBinding);
    return this.zeroIsNull && withinBinding === 0 ? null : withinBinding;
  }

  override getBindingProperty(): number {
    return this.getBindingValue(super.getBindingProperty());
  }

  protected increment(): void {
    if (this.disabled) return;
    this.number$.once(n => {
      const updated = (n || 0) + this.incrementBy;
      if (Number.isFinite(this.maxValue)) {
        if (updated <= this.maxValue) this.numberChanged(updated);
      } else {
        this.numberChanged(updated);
      }
    });
  }

  protected decrement(): void {
    if (this.disabled) return;
    this.number$.once(n => {
      const updated = (n || 0) - this.incrementBy;
      if (Number.isFinite(this.minValue)) {
        if (updated >= this.minValue) this.numberChanged(updated);
      } else {
        this.numberChanged(updated);
      }
    });
  }

  protected numberChanged(n: number | null) {
    this._number.next(n);
    this.getSelfAsFormItem().patchValue(this.zeroIsNull && n === 0 ? null : n);
    this.markAsDirty();
    this.handleInputChange(n);
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.initialValueIfNoBindingValue) {
      this.getSelfAsFormItem()?.patchValue(this.getBindingValue(this.getBindingProperty()));
    }
  }

}
