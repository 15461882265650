<div class="bulk-job-table-container">
  <lib-reactive-table
    [data]="bulkPrintJobs"
    [nItemsToDisplay]="nItemsToDisplay"
    [nPagesInsidePaginationIndicator]="7"
    [resetPageOnNewData]="resetPageOnNewData"
    [resetPageOnSortingChange]="resetPageOnSortingChange">
    <app-bulk-print-jobs-table-header [editPrintCardMode]="editPrintCardMode" [editPrintLabelMode]="editPrintLabelMode">
    </app-bulk-print-jobs-table-header>
    <app-bulk-print-jobs-table-item></app-bulk-print-jobs-table-item>
    <app-bulk-print-jobs-table-empty-state [noResultsTitle]="noResultsTitle" [noResultsText]="noResultsText">
    </app-bulk-print-jobs-table-empty-state>
    <app-table-loading></app-table-loading>
    <lib-reactive-table-paginator *ngIf="showPagination"></lib-reactive-table-paginator>
  </lib-reactive-table>
</div>
