import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { map } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';

@Injectable()
export class StackManualBulkPrintJobMenuListViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
  ) {
    super();
  }

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;

  protected readonly _stackMenus = new BehaviorSubject<Menu[] | null>(null);
  public readonly stackMenus$ = this._stackMenus as Observable<Menu[] | null>;
  connectToStackMenus = (cardStackMenus: Menu[]) => this._stackMenus.next(cardStackMenus);

  protected readonly _selectedVariantsMap = new BehaviorSubject<Map<string, string[] | null> | null>(null);
  public readonly selectedVariantsMap$ = this._selectedVariantsMap as Observable<Map<string, string[] | null> | null>;
  connectToSelectedVariantsMap = (selectedVariantsMap: Map<string, string[] | null>) => {
    this._selectedVariantsMap.next(selectedVariantsMap);
  };

  protected readonly _selectedCardStackMenuId = new BehaviorSubject<string | null>(null);
  public readonly selectedCardStackMenuId$ = combineLatest([
    this.stackMenus$,
    this._selectedCardStackMenuId,
  ]).pipe(
    map(([menus, selectedId]) => selectedId || menus?.firstOrNull()?.id)
  );
  connectToSelectedCardStackMenuId = (id: string) => this._selectedCardStackMenuId.next(id);

  nSelectedVariants$(cardStackMenu: Menu): Observable<number> {
    return this.selectedVariantsMap$.pipe(
      map(selectedVariantsMap => selectedVariantsMap?.get(cardStackMenu?.id)?.length || 0)
    );
  }

}
