<div
  #container
  fxLayout="row"
  fxLayoutAlign="center"
  fxLayoutGap="1rem"
  class="menu-selection-container"
  [ngClass]="{ disabled: disabled }">
  <ng-container [ngSwitch]="!!selection?.templateId">
    <ng-container *ngSwitchCase="true">
      <img src="assets/icons/dark/solid/template.svg" alt="Templated Menu" />
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <img [src]="selection | printJobMenuSelectionIconSrc" alt="Menu" />
    </ng-container>
  </ng-container>
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="menu-name">{{ name$ | async | titlecase }}</div>
  </div>
  <app-selectable
    *ngIf="!disabled"
    [selection]="selection"
    [selectedIds]="selectedIds"
    (add)="add.emit($event)"
    (remove)="remove.emit($event)">
  </app-selectable>
</div>
<hr *ngIf="includeBottomBorder" class="no-margin" />
