import { ChangeDetectionStrategy, Component, ContentChildren, forwardRef, Input, QueryList } from '@angular/core';
import { ReactiveFormCheckboxesComponent, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { ListItemCheckboxComponent } from '../list-item-checkbox/list-item-checkbox.component';

@Component({
  selector: 'app-list-item-checkboxes',
  templateUrl: './list-item-checkboxes.component.html',
  styleUrls: ['../list-item-checkbox/list-item-checkbox.component.scss'],
  providers: [
    { provide: ReactiveFormItemComponent, useExisting: forwardRef(() => ListItemCheckboxesComponent) }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemCheckboxesComponent extends ReactiveFormCheckboxesComponent {

  @Input() hideErrorMessage: boolean = true;

  @ContentChildren(
    ListItemCheckboxComponent,
    {descendants: true}
  ) override boxes: QueryList<ListItemCheckboxComponent>;

}
