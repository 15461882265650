import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayMenuSectionBodyComponent } from '../../../display-menu-section/display-menu-section-body/display-menu-section-body.component';
import { DisplayMenuSectionInsideTemplateCollectionBodyViewModel } from './display-menu-section-inside-template-collection-body-view-model';

@Component({
  selector: 'app-display-menu-section-inside-template-collection-body',
  templateUrl: './display-menu-section-inside-template-collection-body.component.html',
  styleUrls: ['./display-menu-section-inside-template-collection-body.component.scss'],
  providers: [DisplayMenuSectionInsideTemplateCollectionBodyViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionInsideTemplateCollectionBodyComponent extends DisplayMenuSectionBodyComponent {

  constructor(
    public override viewModel: DisplayMenuSectionInsideTemplateCollectionBodyViewModel
  ) {
    super(viewModel);
  }

}
