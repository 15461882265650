import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toListFilterFalsies'
})
export class ToListFilterFalsiesPipe implements PipeTransform {

  transform(value: any): any[] {
    return !!value ? [value] : [];
  }

}
