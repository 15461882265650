<div class="info-banner" fxLayout="row">
  <img
    class="info-icon"
    fxFlexAlign="flex-start"
    [src]="'assets/icons/dark/solid/information-circle.svg'"
    alt="information-circle.svg" />

  <div *ngIf="viewModel.showLotInfoBannerText$ | async" class="info-text" fxFlex>
    <!--   If supports POS sync, show info banner  -->
    {{ viewModel.lotInfoBannerText$ | async }}
    <!--   If Lot Info Exists, show Lot Info Attributes  -->
    <ng-container *ngIf="viewModel.hasLotInfo$ | async">
      <hr class="mb-0" />
      <div class="row">
        <div class="col-6 mt-4">
          <div class="bs-medium f12px">Batch ID</div>
          <div class="bs-bold f14px">
            {{ viewModel.batchId$ | async }}
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="bs-medium f12px">Supplier Name</div>
          <div class="bs-bold f14px">
            {{ viewModel.supplierName$ | async }}
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="bs-medium f12px">Harvest Date</div>
          <div class="bs-bold f14px">
            {{ viewModel.harvestDate$ | async | unixToDate | dashIfEmpty }}
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="bs-medium f12px">Packaged Date</div>
          <div class="bs-bold f14px">
            {{ viewModel.packagedDate$ | async | unixToDate | dashIfEmpty }}
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="bs-medium f12px">Receiving Date</div>
          <div class="bs-bold f14px">
            {{ viewModel.receivingDate$ | async | unixToDate | dashIfEmpty }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
