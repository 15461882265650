import { Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';
import { PrintCardTemplatesViewModel } from './print-card-templates-view-model';

@Component({
  selector: 'app-print-card-templates',
  templateUrl: './print-card-templates.component.html',
  styleUrls: ['./print-card-templates.component.scss'],
  providers: [PrintCardTemplatesViewModel],
})
export class PrintCardTemplatesComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintCardTemplatesViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
