<div class="flex-container modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | async">
  <div class="container mt-5">
    <div class="row">
      <div class="form-section-title bs-bold f18px">
        Default Label
        <img
          [ngbTooltip]="
            'The default label will remain assigned to the variant. The location default label will override the\n' +
            'company default label and any system applied labels will follow the respective label order.'
          "
          [style.height.rem]="1"
          [placement]="'right'"
          [container]="'body'"
          [src]="'assets/icons/dark/solid/information-circle.svg'"
          alt="info-circle" />
      </div>
    </div>
    <div #labelPickerParent>
      <div class="row">
        <div class="column pe-4">
          <div [style.display]="'flex'" [style.justify-content]="'space-between'">
            <div class="f14px bs-medium">{{ viewModel.container.companyName$ | async }} (Company)</div>
            <div
              *ngIf="!(viewModel.container.isCompanyAdmin$ | async)"
              [style.align-self]="'flex-end'"
              class="f12px bs-medium">
              Admin Only
            </div>
          </div>
          <hr class="no-margin" />
          <app-inline-label-picker
            class="mt-3"
            [disabled]="!(viewModel.container.isCompanyAdmin$ | async)"
            [isCompany]="true"
            [displayAttribute]="viewModel.container.updatedCompanyDA$ | async"
            [variant]="viewModel.container.variant$ | async"
            (updatedDisplayAttribute)="viewModel.companyLabelsUpdated($event)">
          </app-inline-label-picker>
        </div>

        <div class="column">
          <div class="f14px bs-medium">{{ viewModel.container.currentLocationName$ | async }} (Location)</div>
          <hr class="no-margin" />
          <app-inline-label-picker
            class="mt-3"
            [isCompany]="false"
            [displayAttribute]="viewModel.container.updatedLocationDA$ | async"
            [variant]="viewModel.container.variant$ | async"
            (updatedDisplayAttribute)="viewModel.locationLabelsUpdated($event)">
          </app-inline-label-picker>
        </div>
      </div>
      <div [hidden]="!(viewModel?.systemAppliedLabel$ | async)" class="row mt-4">
        <app-info-banner
          [buttonText]="!(viewModel?.showHierarchyBanner$ | async) ? 'Show Label Order' : 'Hide Label Order'"
          (buttonClicked)="viewModel.toggleShowHierarchyBanner()"
          class="full-width">
          {{ viewModel.existingSystemLabelText$ | async }}
        </app-info-banner>
      </div>

      <div [hidden]="!(viewModel?.showHierarchyBanner$ | async)" class="row">
        <app-label-hierarchy-banner
          class="full-width"
          [message]="
            'A product may only have one label visible. The labels will be displayed based on the following order:'
          "
          [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
          [labels]="viewModel?.labelsForHierarchyBanner$ | async">
        </app-label-hierarchy-banner>
      </div>
    </div>

    <div class="row mt-5">
      <div class="form-section-title bs-bold f18px">
        Default Badges
        <img
          [ngbTooltip]="
            'Badges added here will be associated with the selected variant. Location badges will override any company badges.'
          "
          [style.height.rem]="1"
          [placement]="'right'"
          [container]="'body'"
          [src]="'assets/icons/dark/solid/information-circle.svg'"
          alt="info-circle" />
      </div>
    </div>
    <div class="row">
      <div class="column pe-4">
        <div [style.display]="'flex'" [style.justify-content]="'space-between'">
          <div class="f14px bs-medium">{{ viewModel.container.companyName$ | async }} (Company)</div>
          <div
            *ngIf="!(viewModel.container.isCompanyAdmin$ | async)"
            [style.align-self]="'flex-end'"
            class="f12px bs-medium">
            Admin Only
          </div>
        </div>
        <hr class="no-margin" />
        <app-inline-badge-picker
          class="mt-3"
          [disabled]="!(viewModel.container.isCompanyAdmin$ | async)"
          [maxBadgesAllowed]="9"
          [popperPlacement]="ClientTypes.NgxPopperjsPlacements.RIGHTSTART"
          [displayAttribute]="viewModel.container.updatedCompanyDA$ | async"
          [wrapBadges]="true"
          [contentAliveWhileClosed]="true"
          (updatedDisplayAttribute)="viewModel.companyBadgesUpdated($event)">
        </app-inline-badge-picker>
      </div>

      <div class="column">
        <div class="f14px bs-medium">{{ viewModel.container.currentLocationName$ | async }} (Location)</div>
        <hr class="no-margin" />
        <app-inline-badge-picker
          class="mt-3"
          [maxBadgesAllowed]="9"
          [popperPlacement]="ClientTypes.NgxPopperjsPlacements.RIGHTSTART"
          [displayAttribute]="viewModel.container.updatedLocationDA$ | async"
          [wrapBadges]="true"
          [contentAliveWhileClosed]="true"
          (updatedDisplayAttribute)="viewModel.locationBadgesUpdated($event)">
        </app-inline-badge-picker>
      </div>
    </div>
  </div>
  <app-customization-preview
    [badgesAndSource]="viewModel.badgesOnVariant$ | async"
    [updatedVariant]="viewModel.updatedVariant$ | async"
    [labelOffset]="labelPickerParent.offsetHeight">
  </app-customization-preview>
</div>
<!--Including this allows us to attach to the parent form group and submit using the formSubmit function-->
<lib-reactive-form-group [bindTo]="null" [mergeKey]="'editVariantFormCapture'"> </lib-reactive-form-group>
