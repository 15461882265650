import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { ModalUtils } from '../utils/modal-utils';
import { TemplateAddOrEditRequiredLocationsComponent } from '../views/template/components/shared/template-add-or-edit-locations/template-add-or-edit-required-locations.component';

export class ModalTemplateAddEditRequiredLocations {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    template: MenuTemplate,
    requiredLocationIds: number[],
    requiredLocationIdsFromPicker: (ids: number[]) => void
  ) {
    const modalRef = ngbModal.open(
      TemplateAddOrEditRequiredLocationsComponent,
      ModalUtils.templateAddEditLocationsModalOptions(injector)
    );
    const compInst = modalRef.componentInstance as TemplateAddOrEditRequiredLocationsComponent;
    compInst.viewModel.connectToTemplate(template);
    compInst.viewModel.connectToSelectedRequiredLocationIds(requiredLocationIds);
    modalRef.result
      .then(requiredIds => {
        if (requiredIds instanceof Array) {
          requiredLocationIdsFromPicker?.(requiredIds);
        }
      })
      .catch(() => {});
  }

}
