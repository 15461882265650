<div
  matRipple
  fxLayout="row"
  fxLayoutAlign="center center"
  class="plus-button"
  [style.borderRadius]="borderRadius"
  [style.height]="height"
  [style.width]="width"
  [style.backgroundColor]="backgroundColor">
  <img class="plus-icon" [src]="'assets/icons/dark/solid/plus.svg'" alt="plus icon" />
</div>
