// noinspection JSUnusedLocalSymbols

import { AllProductsDataTableViewModel } from '../all-products-data-table/all-products-data-table-view-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { Product } from '../../../models/product/dto/product';
import { HasChildIds } from '../../../models/protocols/has-child-ids';
import { Event, NavigationEnd, Router } from '@angular/router';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { Variant } from '../../../models/product/dto/variant';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { iiif } from '../../../utils/observable.extensions';

// Provided by Logged In Scope
@Injectable()
export class ProductPickerDataTableViewModel extends AllProductsDataTableViewModel {

  constructor(
    companyDomainModel: CompanyDomainModel,
    locationDomainModel: LocationDomainModel,
    productDomainModel: ProductDomainModel,
    protected router: Router,
  ) {
    super(productDomainModel, companyDomainModel, locationDomainModel);
    this.router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
      takeUntil(this.onDestroy),
    ).subscribe(() => {
      this._searchText.next('');
      this._filterByProductType.next(null);
      this._filterByVariantType.next(null);
      this._filterByStrainType.next(null);
    });
  }

  protected override _numberOfProductsPerPage = new BehaviorSubject<number>(10);

  private readonly _hideLabelAndBadgeColumn = new BehaviorSubject<boolean>(false);
  public readonly hideLabelAndBadgeColumn$ = this._hideLabelAndBadgeColumn as Observable<boolean>;
  connectToHideLabelAndBadgeColumn = (x: boolean) => this._hideLabelAndBadgeColumn.next(x);

  private readonly _showCurrentOverrideGroupColumn = new BehaviorSubject<boolean>(false);
  public readonly showCurrentOverrideGroupColumn$ = this._showCurrentOverrideGroupColumn as Observable<boolean>;
  connectToShowCurrentOverrideGroupColumn = (x: boolean) => this._showCurrentOverrideGroupColumn.next(x);

  private _productSelectionOnly = new BehaviorSubject<boolean>(false);
  public productSelectionOnly$ = this._productSelectionOnly as Observable<boolean>;
  connectToProductSelectionOnly = (x: boolean) => this._productSelectionOnly.next(x);

  private _previouslyAddedProductIds = new BehaviorSubject<string[]>([]);
  public previouslyAddedProductIds$ = this._previouslyAddedProductIds as Observable<string[]>;
  setPreviouslyAddedProductIds = (ids: string[]) => this._previouslyAddedProductIds.next(ids);
  clearPreviouslyAddedProductIds = (): void => this._previouslyAddedProductIds.next([]);

  private _productIdsToBeAdded = new BehaviorSubject<string[]>([]);
  public productIdsToBeAdded$ = this._productIdsToBeAdded.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  clearProductSelections = (): void => this._productIdsToBeAdded.next([]);

  private _previouslyAddedVariantIds = new BehaviorSubject<string[]>([]);
  public previouslyAddedVariantIds$ = this._previouslyAddedVariantIds as Observable<string[]>;
  setPreviouslyAddedVariantIds = (ids: string[]) => this._previouslyAddedVariantIds.next(ids);
  clearPreviouslyAddedVariantIds = (): void => this._previouslyAddedVariantIds.next([]);

  public previouslyAddedIds$ = iiif(
    this.productSelectionOnly$,
    this.previouslyAddedProductIds$,
    this.previouslyAddedVariantIds$
  );

  private _variantIdsToBeAdded = new BehaviorSubject<string[]>([]);
  public variantIdsToBeAdded$ = this._variantIdsToBeAdded.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  private clearVariantSelections = (): void => this._variantIdsToBeAdded.next([]);

  clearSelections(): void {
    this.clearProductSelections();
    this.clearVariantSelections();
  }

  clearPreviouslyAddedIds(): void {
    this.clearPreviouslyAddedProductIds();
    this.clearPreviouslyAddedVariantIds();
  }

  public idsToBeAdded$ = iiif(this.productSelectionOnly$, this.productIdsToBeAdded$, this.variantIdsToBeAdded$);
  public nIdsToBeAdded$ = this.idsToBeAdded$.pipe(map(ids => ids?.length));

  private _productsBeingDisplayed = new BehaviorSubject<Product[]>([]);
  public productsBeingDisplayed$ = this._productsBeingDisplayed as Observable<Product[]>;
  setProductsBeingDisplayed = (products: Product[]) => this._productsBeingDisplayed.next(products);

  public nestedProductIds$ = this.productsBeingDisplayed$.pipe(
    map(products => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => products?.map(product => product?.getId()) ?? [];

      }();
    })
  );

  public nestedVariantIds$ = this.productsBeingDisplayed$.pipe(
    map(products => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => products?.flatMap(product => product?.getChildIds()) ?? [];

     }();
    })
  );

  public nestedIds$ = iiif(
    this.productSelectionOnly$,
    this.nestedProductIds$,
    this.nestedVariantIds$
  );

  private _addProductId = new Subject<string>();
  addProductId = (id: string) => this._addProductId.next(id);
  addProductIds = (ids: string[]) => ids?.forEach(id => this.addProductId(id));
  private listenToAddProductId = this._addProductId.pipe(
    map(id => this._productIdsToBeAdded.getValue()?.concat([id])?.unique())
  ).subscribeWhileAlive({
    owner: this,
    next: ids => this._productIdsToBeAdded.next(ids)
  });

  private _removeProductId = new Subject<string>();
  removeProductId = (id: string) => this._removeProductId.next(id);
  removeProductIds = (ids: string[]) => ids?.forEach(id => this.removeProductId(id));
  private listenToRemoveProductId = this._removeProductId.pipe(
    map(id => this._productIdsToBeAdded.getValue()?.filter(currId => currId !== id))
  ).subscribeWhileAlive({
    owner: this,
    next: ids => this._productIdsToBeAdded.next(ids)
  });

  private _addVariantId = new Subject<string>();
  addVariantId = (id: string) => this._addVariantId.next(id);
  addVariantIds = (ids: string[]) => ids?.forEach(id => this.addVariantId(id));
  private listenToAddAnId = this._addVariantId.pipe(
    map(id => this._variantIdsToBeAdded.getValue()?.concat([id])?.unique())
  ).subscribeWhileAlive({
    owner: this,
    next: ids => this._variantIdsToBeAdded.next(ids)
  });

  private _removeVariantId = new Subject<string>();
  removeVariantId = (id: string) => this._removeVariantId.next(id);
  removeVariantIds = (ids: string[]) => ids?.forEach(id => this.removeVariantId(id));
  private listenToRemoveAnId = this._removeVariantId.pipe(
    map(id => this._variantIdsToBeAdded.getValue()?.filter(currId => currId !== id))
  ).subscribeWhileAlive({
    owner: this,
    next: ids => this._variantIdsToBeAdded.next(ids)
  });

  bulkAdd(ids: string[]): void {
    this.productSelectionOnly$.once(productSelectionOnly => {
      productSelectionOnly
        ? this.addProductIds(ids)
        : this.addVariantIds(ids);
    });
  }

  bulkRemove(ids: string[]): void {
    this.productSelectionOnly$.once(productSelectionOnly => {
      productSelectionOnly
        ? this.removeProductIds(ids)
        : this.removeVariantIds(ids);
    });
  }

  /**
   * We don't use smart filters in the product picker, so revert it to the default behavior
   */
  protected override getVariantsFilteredByMedAndRec(p: Product, filterByUse: (v: Variant) => boolean): Variant[] {
    return filterByUse ? p?.variants?.filter(filterByUse) : p?.variants;
  }

}
