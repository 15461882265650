<div
  class="card bs-card bs-theme-full-preview-card"
  [ngClass]="viewModel.cardClasses$ | async"
  [class.selected]="card.selected">
  <div class="bs-theme-carousel-preview-card-wrapper">
    <!--  Theme Preview  -->
    <div class="bs-theme-preview-image">
      <app-asset [asset]="card.asset" [borderRadius]="'0.25rem'"></app-asset>
      <div *ngIf="!!card?.text" class="card-size-pill">{{ card.text }}</div>
    </div>
  </div>
</div>
