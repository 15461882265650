<app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups #formCapture [mergeKey]="'referAFriend'" (formsSubmitted$)="sendReferral()">
</lib-reactive-form-merge-groups>

<div class="modal-body">
  <div class="modal-title mb-3">Refer a Friend</div>
  <div class="mt-32px">
    Do you love BudSense and know of a friend whose cannabis shop may also benefit? Send them a referral and we'll give
    you $200 if they sign up!
    <div class="block mt-24px">
      <lib-reactive-form-group #referralForm [mergeKey]="'referAFriend'" [bindTo]="referralReq">
        <lib-reactive-form-text
          [inputName]="'name'"
          [label]="'Recipient Name'"
          [placeholder]="'Enter Full Name'"
          [inlineLabel]="false"
          [required]="true"
          [bindingProperty]="'recipientName'">
        </lib-reactive-form-text>
        <lib-reactive-form-text
          [inputName]="'email'"
          [label]="'Recipient Email'"
          [placeholder]="'Enter email address'"
          [inlineLabel]="false"
          [required]="true"
          [bindingProperty]="'recipientEmail'">
        </lib-reactive-form-text>
        <lib-reactive-form-text
          [inputName]="'storeName'"
          [label]="'Recipient Store'"
          [placeholder]="'Enter company or store name'"
          [inlineLabel]="false"
          [required]="true"
          [bindingProperty]="'recipientStoreName'">
        </lib-reactive-form-text>
      </lib-reactive-form-group>
    </div>

    <div class="modal-footer-flex-end">
      <button matRipple (click)="cancel()" class="bs-button neutral-button">Cancel</button>
      <button
        matRipple
        (click)="referralForm.submitForm()"
        class="bs-button preferred-button"
        [matRippleDisabled]="referralForm.disableSubmit()"
        [ngClass]="{ disabled: referralForm.disableSubmit() }"
        [disabled]="referralForm.disableSubmit()">
        Send Referral
      </button>
    </div>
  </div>
</div>
