import { Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { SectionBlueprint } from '../models/template/dto/section-blueprint';
import { SectionBlueprintCategory } from '../models/template/dto/section-blueprint-category';
import { SectionTemplate } from '../models/template/dto/section-template';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { TemplateCollection } from '../models/template/dto/template-collection';
import { CreateMenuTemplateReq } from '../models/template/dto/create-menu-template-req';
import { NewMenuSectionRequest } from '../models/menu/dto/new-menu-section-request';
import { DuplicateMenuRequest } from '../models/menu/shared/duplicate-menu-request';
import { DuplicateTemplateSectionRequest } from '../models/menu/shared/duplicate-template-section-request';
import { ApiPagination } from '../models/shared/api-pagination';

export const DEFAULT_TEMPLATE_PAGINATION_COUNT = 10;
export const DEFAULT_TEMPLATE_COLLECTION_PAGINATION_COUNT = 10;

@Injectable({ providedIn: 'root' })
export class TemplateAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public serviceName = 'Template';

  public GetSectionBlueprints(ids?: string[]): Observable<SectionBlueprint[]> {
    const url = Endpoints.GetSectionBlueprints(ids?.join(','));
    return this.apiClient.getArr<SectionBlueprint>(
      SectionBlueprint,
      url
    ).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSectionBlueprints', err));
        return throwError(err);
      })
    );
  }

  public GetSectionBlueprintCategories(): Observable<SectionBlueprintCategory[]> {
    const url = Endpoints.GetSectionBlueprintCategories();
    return this.apiClient.getArr<SectionBlueprintCategory>(
      SectionBlueprintCategory,
      url
    ).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSectionBlueprintCategories', err));
        return throwError(err);
      })
    );
  }

  public GetMenuTemplateForLocation(locationId: number, menuTemplateId: string): Observable<MenuTemplate> {
    const url = Endpoints.GetMenuTemplateForLocation(locationId, menuTemplateId);
    return this.apiClient.getObj(MenuTemplate, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuTemplateForLocation', err));
        return throwError(err);
      })
    );
  }

  public CreateSectionTemplate(locationId: number, req: NewMenuSectionRequest): Observable<SectionTemplate> {
    const url = Endpoints.CreateSectionTemplate();
    const additionalHeaders = {locationId: `${locationId}`};
    return this.apiClient.postObj(SectionTemplate, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSectionTemplate', err));
        return throwError(err);
      })
    );
  }

  public UpdateMenuTemplate(locationId: number, menu: MenuTemplate): Observable<MenuTemplate> {
    const url = Endpoints.UpdateMenuTemplate();
    const additionalHeaders = {locationId: `${locationId}`};
    return this.apiClient.postObj(MenuTemplate, url, menu, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenuTemplate', err));
        return throwError(err);
      })
    );
  }

  public DeleteMenuTemplate(menu: MenuTemplate): Observable<any> {
    const url = Endpoints.DeleteMenuTemplate();
    return this.apiClient.deleteObjReturnSuccess(url, menu).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteMenuTemplate', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyMenuTemplates(locationId: number, pagination: ApiPagination = null): Observable<MenuTemplate[]> {
    const url = Endpoints.GetCompanyMenuTemplates(locationId);
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_TEMPLATE_PAGINATION_COUNT);
    }
    return this.apiClient.recursiveGet<MenuTemplate>(MenuTemplate, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyTemplates', err));
        return throwError(err);
      })
    );
  }

  public GetSectionTemplate(
    locationId: number,
    menuTemplateId: string,
    sectionTemplateId: string
  ): Observable<SectionTemplate> {
    const url = Endpoints.GetSectionTemplate(locationId, menuTemplateId, sectionTemplateId);
    return this.apiClient.getObj(SectionTemplate, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSectionTemplate', err));
        return throwError(err);
      })
    );
  }

  public UpdateMenuSectionTemplate(locationId: number, sectionTemplate: SectionTemplate): Observable<SectionTemplate> {
    const sectionDTO = window?.injector?.Deserialize?.instanceOf(SectionTemplate, sectionTemplate)?.translateIntoDTO();
    const url = Endpoints.UpdateMenuSectionTemplate();
    const additionalHeaders = {locationId: `${locationId}`};
    return this.apiClient.postObj(SectionTemplate, url, sectionDTO, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenuSectionTemplate', err));
        return throwError(err);
      })
    );
  }

  public UpdateMenuSectionTemplatePriorities(sections: SectionTemplate[]): Observable<SectionTemplate[]> {
    const url = Endpoints.UpdateMenuSectionTemplatePriorities();
    return this.apiClient.postArr(SectionTemplate, url, sections).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenuSectionTemplatePriorities', err));
        return throwError(err);
      })
    );
  }

  public CreateMenuTemplate(menuTemplate: CreateMenuTemplateReq, locationId: number): Observable<MenuTemplate> {
    const url = Endpoints.CreateMenuTemplate();
    const additionalHeaders = {locationId: `${locationId}`};
    return this.apiClient.postObj(MenuTemplate, url, menuTemplate, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateTemplate', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyMenuTemplateCollections(pagination: ApiPagination = null): Observable<TemplateCollection[]> {
    const url = Endpoints.GetCompanyMenuTemplateCollections();
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_TEMPLATE_COLLECTION_PAGINATION_COUNT);
    }
    return this.apiClient.recursiveGet<TemplateCollection>(TemplateCollection, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyTemplateCollections', err));
        return throwError(err);
      })
    );
  }

  public CreateTemplateCollection(collection: TemplateCollection): Observable<TemplateCollection> {
    const url = Endpoints.CreateTemplateCollection();
    return this.apiClient.postObj(TemplateCollection, url, collection).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateTemplateCollection', err));
        return throwError(err);
      })
    );
  }

  public GetTemplateCollection(locationId: number, templateId: string): Observable<TemplateCollection> {
    const url = Endpoints.GetTemplateCollection(locationId, templateId);
    return this.apiClient.getObj<TemplateCollection>(TemplateCollection, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetTemplateCollection', err));
        return throwError(err);
      })
    );
  }

  public UpdateTemplateCollection(locationId: number, collection: TemplateCollection): Observable<TemplateCollection> {
    const url = Endpoints.UpdateTemplateCollection();
    const additionalHeaders = {LocationId: `${locationId}`};
    return this.apiClient.postObj(TemplateCollection, url, collection, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateTemplateCollection', err));
        return throwError(err);
      })
    );
  }

  public DuplicateMenuTemplate(locationId: number, req: DuplicateMenuRequest): Observable<MenuTemplate> {
    const url = Endpoints.DuplicateMenuTemplate();
    const additionalHeaders = {locationId: `${locationId}`};
    return this.apiClient.postObj(MenuTemplate, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DuplicateMenuTemplate', err));
        return throwError(err);
      })
    );
  }

  public DuplicateSectionTemplate(
    locationId: number,
    req: DuplicateTemplateSectionRequest
  ): Observable<SectionTemplate> {
    const url = Endpoints.DuplicateSectionTemplate();
    const additionalHeaders = {locationId: `${locationId}`};
    return this.apiClient.postObj(SectionTemplate, url, req, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DuplicateSectionTemplate', err));
        return throwError(err);
      })
    );
  }

  public DeleteTemplateCollection(collection: TemplateCollection): Observable<string> {
    const url = Endpoints.DeleteTemplateCollection();
    return this.apiClient.deleteObjReturnSuccess(url, collection).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteTemplateCollection', err));
        return throwError(err);
      })
    );
  }

  public DeleteMenuSectionTemplate(section: SectionTemplate): Observable<string> {
    const url = Endpoints.DeleteMenuSectionTemplate();
    return this.apiClient.deleteObjReturnSuccess(url, section).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteMenuSectionTemplate', err));
        return throwError(err);
      })
    );
  }

}
