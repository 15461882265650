import { AfterViewInit, Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { NavigationService } from '../../../../../services/navigation.service';
import { DisplayableContentContainerViewModel } from './displayable-content-container-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionService } from '../../../../../services/action.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../utils/distinct-utils';

@Component({
  selector: 'app-displayable-content-container',
  templateUrl: './displayable-content-container.component.html',
  styleUrls: ['./displayable-container.component.scss']
})
export class DisplayableContentContainerComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @Input() title: string;

  constructor(
    public viewModel: DisplayableContentContainerViewModel, // Provided by Logged In Scope
    protected navigationService: NavigationService,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected actionService: ActionService
  ) {
    super();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.viewModel.selectActiveTag();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) this.viewModel.connectToTitle(this.title);
  }

  override setupBindings() {
    const addNewMenu = () => this.viewModel.toggleAddNewMenu();
    this.pushSub(this.actionService.createPrintMenu.consumeFlag(addNewMenu));

    // This lives in the component and not the view model, because tags$ is an abstract property that won't
    // be initialized in time for the view model to consume it!
    combineLatest([
      this.viewModel.activeTagString$,
      this.viewModel.tags$.pipe(
        map(tags => tags?.map(tag => tag?.value) ?? []),
        distinctUntilChanged(DistinctUtils.distinctSortedStrings)
      )
    ]).subscribeWhileAlive({
      owner: this,
      next: ([activeTagString, tagStringPool]) => {
        if (!!activeTagString && !tagStringPool?.includes(activeTagString)) {
          this.viewModel.connectToActiveTag(null);
        }
      }
    });
  }

  tabSelected(n: number) {
    this.viewModel.tabSelected(n);
  }

}
