import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';
import { StackType } from '../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Injectable()
export class ChooseStackPrintTypeViewModel {

  private _stackType = new BehaviorSubject<string>('card');
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (type: StackType) => this._stackType.next(type);

  public stackPrintTypeOptions$ = this.stackType$.pipe(
    map(stackType => {
      return [
        ...stackType === 'card'
          ? [{
              type: StackPrintType.CardStackPrintType_Smart,
              title: 'Smart Print',
              subtitle: 'Automate a bulk print job for your card stacks'
            },
            {
              type: StackPrintType.CardStackPrintType_Manual,
              title: 'Manual Print',
              subtitle: 'Select products to be included in your card stack bulk print'
            }]
          : [],
        ...stackType === 'label'
          ? [{
              type: StackPrintType.LabelStackPrintType_Smart,
              title: 'Smart Print',
              subtitle: 'Automate a bulk print job for your label stacks'
            },
            {
              type: StackPrintType.LabelStackPrintType_Manual,
              title: 'Manual Print',
              subtitle: 'Select products to be included in your label stack bulk print'
            }]
          : [],
      ];
    })
  );

}
