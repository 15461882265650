import { DisplayOptions } from '../../shared/display-options';
import { Menu } from './menu';
import { MarketingMenuType } from '../../enum/dto/marketing-menu-type.enum';
import { Variant } from '../../product/dto/variant';
import { CardTypeDefinition } from '../../utils/dto/card-type-definition';
import { OverflowState } from '../../utils/dto/overflow-state-type';
import { MenuType } from '../../utils/dto/menu-type-definition';
import { CompactItem } from '../../shared/compact-item';
import { MenuIconSrc } from '../../enum/shared/menu-icon-src.enum';
import { MenuTemplate } from '../../template/dto/menu-template';
import { TemplateStatus } from '../../template/enum/template-status.enum';
import { MenuSubtype } from '../../enum/dto/menu-subtype';
import { LocationConfiguration } from '../../company/dto/location-configuration';
import type { Product } from '../../product/dto/product';

export class CompactMenu extends CompactItem {

  public type: MenuType;
  public menuSubType: MenuSubtype;
  public overflowState: OverflowState;
  public isLoopingMarketingContent: boolean;
  public calculateMarketingLoopDuration: number;
  public options: DisplayOptions;
  public theme: string;
  public subTypeUsesSectionsThatContainProducts: boolean;
  public menuUsesSectionsThatContainProductsAndHasVisibleContent: boolean;
  public cardType: CardTypeDefinition;
  public activeLocationIds: number[];
  public isBasedOffOfTemplate: boolean;
  public isTemplate: boolean;
  public status: TemplateStatus;
  public isTemplateImportedAtMyLocation: boolean;
  public hasAtLeastOneVisibleFeaturedVariantInStock: boolean;
  public sectionIds: string[];
  public sectionIdsWithPrimaryAssets: string[];
  public sectionIdsThatHaveHiddenFlagEnabled: string[];
  public linkedURL: string;

  constructor(
    menu: Menu,
    menuProducts: Product[],
    menuVariants: Variant[],
    locationId: number,
    locationConfig: LocationConfiguration
  ) {
    super();
    this.id = menu.id;
    this.name = menu.name;
    this.type = menu.type;
    this.overflowState = menu.overflowState;
    this.displayPriority = menu.displayPriority;
    this.isLoopingMarketingContent = menu.isLoopingMarketingContent();
    this.calculateMarketingLoopDuration = menu.calculateLongestLoopDuration(
      menuProducts,
      menuVariants,
      locationConfig?.priceFormat
    );
    this.options = menu.options;
    this.theme = menu.theme;
    this.menuSubType = menu.hydratedTheme?.menuSubType;
    this.cardType = window?.types?.initTypeDefinition(CardTypeDefinition, menu.metadata?.cardType);
    this.isBasedOffOfTemplate = menu.isTemplatedMenu();
    this.orderableIconSrc = this.constructOrderableIconSrc(menu);
    this.isTemplate = menu instanceof MenuTemplate;
    if (menu instanceof MenuTemplate) {
      this.status = menu.status;
      this.isTemplateImportedAtMyLocation = menu?.isActiveAtLocation(locationId);
    } else {
      this.isTemplateImportedAtMyLocation = false;
    }
    this.hasAtLeastOneVisibleFeaturedVariantInStock = menu
      ?.getHydratedFeaturedVariants()
      ?.map(v => v.id)
      ?.some(id => (menu?.isFeaturedVariantInStock(id) && menu?.isFeaturedVariantInRotation(id)));
    this.subTypeUsesSectionsThatContainProducts = menu?.subTypeUsesSectionsThatContainProducts();
    this.menuUsesSectionsThatContainProductsAndHasVisibleContent
      = menu?.menuUsesSectionsThatContainProductsOrHasVisibleContent(menuVariants);
    if (menu.isTemplatedMenu()) {
      this.sectionIds = menu?.template?.getSectionsBasedOnMenuType()?.map(section => section?.id)?.filterNulls() ?? [];
      this.sectionIdsWithPrimaryAssets = menu?.template?.sectionIdsWithPrimaryAssets() ?? [];
      this.sectionIdsThatHaveHiddenFlagEnabled = menu?.template?.sectionIdsThatHaveHiddenFlagEnabled() ?? [];
    } else {
      this.sectionIds = menu?.getSectionsBasedOnMenuType()?.map(section => section?.id)?.filterNulls() ?? [];
      this.sectionIdsWithPrimaryAssets = menu?.sectionIdsWithPrimaryAssets() ?? [];
      this.sectionIdsThatHaveHiddenFlagEnabled = menu?.sectionIdsThatHaveHiddenFlagEnabled() ?? [];
    }
    this.linkedURL = menu.linkedURL;
  }

  onDeserialize() {
    this.options = window?.injector?.Deserialize?.instanceOf(DisplayOptions, this.options);
  }

  private constructOrderableIconSrc(menu: Menu) {
    if (menu?.isTemplatedMenu()) return MenuIconSrc.TemplatedMenu;
    return MenuIconSrc.Menu;
  }

  public menuUsesProductsAndDoesntHaveVisibleContent(): boolean {
    return this.subTypeUsesSectionsThatContainProducts && !this.menuUsesSectionsThatContainProductsAndHasVisibleContent;
  }

  public hasNoEnabledMarketingContent(): boolean {
    const hasNoEnabledContent = [...(this.options?.rotationInterval?.values() || [])]?.every(interval => !interval);
    return this.menuNeedsEnabledContent() && hasNoEnabledContent;
  }

  private menuNeedsEnabledContent(): boolean {
    const isDriveThruMenu = this.menuSubType === MarketingMenuType.DriveThru;
    const isFeaturedMenu = this.menuSubType === MarketingMenuType.Featured;
    const isSmartPlaylist = this.menuSubType === MarketingMenuType.SmartPlaylist;
    return isDriveThruMenu || isFeaturedMenu || isSmartPlaylist;
  }

  public isFeaturedMenuWithoutVisibleFeaturedVariants(): boolean {
    const isMarketingMenu = this.type === MenuType.MarketingMenu;
    const isFeaturedMenu = this.menuSubType === MarketingMenuType.Featured;
    return isMarketingMenu && isFeaturedMenu && !this.hasAtLeastOneVisibleFeaturedVariantInStock;
  }

  private menuUsesIntervalSystem(): boolean {
    const playlist = this.menuSubType === MarketingMenuType.Playlist;
    const featured = this.menuSubType === MarketingMenuType.Featured;
    const smartPlaylist = this.menuSubType === MarketingMenuType.SmartPlaylist;
    return playlist || featured || smartPlaylist;
  }

  public usesIntervalSystemAndNoContentEnabled(): boolean {
    const hasAtLeastOneEnabledAsset = (): boolean => {
      if (this.menuSubType === MarketingMenuType.SmartPlaylist) {
        return this.sectionIds?.some(sectionId => {
          const hasInterval = this.options?.rotationInterval?.get(sectionId) > 0;
          const isHidden = this.sectionIdsThatHaveHiddenFlagEnabled?.includes(sectionId) || false;
          const hasPrimaryAsset = this.sectionIdsWithPrimaryAssets?.includes(sectionId) || false;
          return hasInterval && !isHidden && hasPrimaryAsset;
        });
      }
      return [...(this.options?.rotationInterval?.values() || [])].some(interval => interval > 0);
    };
    return this.menuUsesIntervalSystem() && !hasAtLeastOneEnabledAsset();
  }

  public hasNoValidUrl(): boolean {
    return this.menuSubType === MarketingMenuType.UrlPlaylist && !this.linkedURL;
  }

}
