import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { MenuLinksViewModel } from './menu-links-view-model';

@Component({
  selector: 'app-menu-links',
  templateUrl: './menu-links.component.html',
  styleUrls: ['./menu-links.component.scss'],
  providers: [MenuLinksViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuLinksComponent extends BaseComponent {

  constructor(
    public viewModel: MenuLinksViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() @ViewModelConnector('connectToMenu') menu;
  @Input() @ViewModelConnector('connectToIFrameHeight') iFrameHeight;
  @Input() @ViewModelConnector('connectToIFrameWidth') iFrameWidth;

}
