import { Pipe, PipeTransform } from '@angular/core';
import { HasChildIds } from '../../../models/protocols/has-child-ids';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'labelIncludeOrRemoveAll'
})
export class LabelIncludeOrRemoveAllPipe implements PipeTransform {

  transform(selection: HasChildIds, selectionMap: Map<string, number>): string {
    return selection?.getChildIds()?.every(id => exists(selectionMap?.get(id)))
      ? 'Remove All'
      : 'Include All';
  }

}
