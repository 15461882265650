<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <mat-button-toggle-group
    (change)="controlSelected($event)"
    [vertical]="verticalLayout"
    class="bs-segmented-control-group"
    name="{{ controlName }}">
    <mat-button-toggle
      *ngFor="let opt of options"
      [checked]="opt.selected"
      [disabled]="opt.disabled"
      [value]="opt.value"
      [matTooltipClass]="'bs-mat-tooltip'"
      [matTooltip]="opt.tooltipText"
      class="bs-segmented-control">
      {{ opt.title }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
