<div
  matRipple
  [matRippleDisabled]="syncing"
  fxLayout="row"
  fxLayoutAlign=" center"
  class="smart-filter-sync-container"
  [ngbTooltip]="formPristine | saveChangesTooltip"
  [ngClass]="{ disabled: syncing || !formPristine }">
  <img
    src="assets/icons/dark/outline/refresh.svg"
    [style.margin-right.rem]="0.25"
    [ngClass]="{ syncing: syncing }"
    alt="Refresh Img" />
  <div>{{ time$ | async }}</div>
</div>
