import { Deserializable } from '../../protocols/deserializable';
import { Selectable } from '../../protocols/selectable';
import { Asset } from '../../image/dto/asset';
import { LocationStoreHours } from './location-store-hours';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { HasId } from '../../protocols/has-id';
import { SortUtils } from '../../../utils/sort-utils';
import { UsePurpose } from '../../utils/dto/use-purpose-type';

export class Location implements Deserializable, Selectable, LookAheadItem, HasId {

  public companyId: number;
  public id: number;
  public providerId: string;
  public archived: boolean;
  public name: string;
  public address: string;
  public city: string;
  public state: string;
  public stateCode: string;
  public country: string;
  public countryCode: string;
  public timezone: string;
  public lastModified: number;
  public logoId: string;
  public altLogoId: string;
  public logo: Asset;
  public altLogo: Asset;
  public storeHours: LocationStoreHours[];
  public usePurpose: UsePurpose;

  static readonly incompleteLocationGroupName = 'Incomplete Locations';

  /**
   * Builds a JavaScript object of key value pairs.
   * The key being the province name.
   * The value being the locations in that province.
   * I used a JavaScript Object instead of a Map because one has to do key/value pairs
   * to decode the data while they iterate over the data.
   * Why? Because the keys are unknown to the programmer, and one always has to loop through the keys.
   * Therefore, no need for the overhead of a Map, because we'll never use the methods attached to the structure.
   */
  static groupLocationsByProvince(
    [idsToGroup, locations]: [number[], Location[]]
  ): { [provinceName: string]: Location[] } {
    const locationsGroupedByProvince = {};
    idsToGroup?.forEach(locationId => {
      const location = locations?.find(l => l?.id === locationId);
      const province = location?.state || Location.incompleteLocationGroupName;
      if (!locationsGroupedByProvince[province]) locationsGroupedByProvince[province] = [];
      locationsGroupedByProvince[province].push(location);
    });
    Object.keys(locationsGroupedByProvince)?.forEach(province => {
      locationsGroupedByProvince[province].sort(SortUtils.sortLocationByNameAsc);
    });
    return locationsGroupedByProvince;
  }

  static addedOrRemoved(location: Location, originalIds: number[], updatedIds: number[]): string {
    const originalHasLocation = originalIds?.includes(location?.id);
    const updatedHasLocation = updatedIds?.includes(location?.id);
    if (originalHasLocation && !updatedHasLocation) return 'Removed';
    if (!originalHasLocation && updatedHasLocation) return 'Added';
    return undefined;
  }

  public onDeserialize() {
    this.logo = window?.injector?.Deserialize?.instanceOf(Asset, this.logo);
    this.altLogo = window?.injector?.Deserialize?.instanceOf(Asset, this.altLogo);
    this.storeHours = window?.injector?.Deserialize?.arrayOf(LocationStoreHours, this.storeHours);
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/LocationDTO.go
  public onSerialize() {
    const dto = Object.create(Location.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.providerId = this.providerId;
    dto.archived = this.archived;
    dto.name = this.name;
    dto.address = this.address;
    dto.city = this.city;
    dto.state = this.state;
    dto.stateCode = this.stateCode;
    dto.country = this.country;
    dto.countryCode = this.countryCode;
    dto.timezone = this.timezone;
    dto.logoId = this.logoId;
    dto.altLogoId = this.altLogoId;
    dto.storeHours = this.storeHours;
    dto.usePurpose = this.usePurpose;
    return dto;
  }

  getSelectionTitle(): any {
    return this.name;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getFullAddress(): string {
    const addressCityProv: string[] = [];
    if (!!this.address) {
      addressCityProv.push(this.address);
    }
    const cityProv = this.getCityProv();
    if (!!cityProv) {
      addressCityProv.push(cityProv);
    }
    return addressCityProv.join(', ');
  }

  getCityProv(): string {
    const cityProv: string[] = [];
    if (!!this.city) {
      cityProv.push(this.city);
    }
    if (!!this.stateCode) {
      cityProv.push(this.stateCode);
    }
    return cityProv.join(', ');
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  isMissingInformation(): boolean {
    return !this.name || !this.address || !this.city || !this.stateCode || !this.countryCode;
  }

  getId(): string {
    return this.id?.toString();
  }

}
