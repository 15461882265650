<div class="d-flex" [style.gap.rem]="0.625">
  <div
    *ngFor="let badge of badges | slice : 0 : 3"
    [ngbPopover]="badgeTooltip"
    [triggers]="'mouseenter:mouseleave'"
    [style.height.rem]="1"
    [style.width.rem]="1">
    <app-asset [asset]="badge.image" [scaleFit]="true" [size]="ClientTypes.AssetSize.Thumb"> </app-asset>
    <ng-template #badgeTooltip>
      <div [style.width]="'fit-content'" [style.height]="'fit-content'">
        <app-badge-popover [badge]="badge" [showSmartFilterIndicator]="badge | isSmartBadge : item"></app-badge-popover>
      </div>
    </ng-template>
  </div>
  <span *ngIf="badges?.length > 3">...</span>
</div>
