import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of, Subject, switchMap } from 'rxjs';
import { CompactMenu } from '../../../../../models/menu/dto/compact-menu';
import { delay, distinctUntilChanged, map, shareReplay, startWith, takeUntil, tap } from 'rxjs/operators';
import { MarketingMenuType } from '../../../../../models/enum/dto/marketing-menu-type.enum';
import { DisplayMenuOptions } from '../../../../../models/display/shared/display-menu-options';
import { OverflowState } from '../../../../../models/utils/dto/overflow-state-type';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { RangeSliderOptions } from '../../../../../models/shared/stylesheet/range-slider-options';
import { SegmentedControlOption } from '../../../../../models/shared/stylesheet/segmented-control-option';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../utils/menu-interval-utils';

@Injectable()
export class MenuAddedToDisplayFormViewModel extends BaseViewModel {

  private _bindTo: BehaviorSubject<DisplayMenuOptions> = new BehaviorSubject(null);
  public bindTo$ = this._bindTo as Observable<DisplayMenuOptions>;
  connectToBindTo = (bindTo: DisplayMenuOptions) => this._bindTo.next(bindTo);

  private _withinCollectionAssignedToDisplay = new BehaviorSubject<boolean>(false);
  public withinCollectionAssignedToDisplay$ = this._withinCollectionAssignedToDisplay as Observable<boolean>;
  connectToWithinCollectionAssignedToDisplay = (val: boolean) => this._withinCollectionAssignedToDisplay.next(val);

  private _menu = new BehaviorSubject<CompactMenu>(null);
  public menu$ = this._menu as Observable<CompactMenu>;
  public connectToMenu = (menu: CompactMenu) => this._menu.next(menu);

  private _nMenusOnDisplay = new BehaviorSubject<number>(null);
  public nMenusOnDisplay$ = this._nMenusOnDisplay as Observable<number>;
  public connectToNMenusOnDisplay = (nMenusOnDisplay: number) => this._nMenusOnDisplay.next(nMenusOnDisplay);

  private _menuIsPartOfCollection = new BehaviorSubject<boolean>(false);
  public menuIsPartOfCollection$ = this._menuIsPartOfCollection as Observable<boolean>;
  public connectToMenuIsPartOfCollection = (inCol: boolean) => this._menuIsPartOfCollection.next(inCol);

  private _loopDuration = new BehaviorSubject<number>(0);
  public loopDuration$ = this._loopDuration as Observable<number>;
  public connectToLoopDuration = (loopDuration: number) => this._loopDuration.next(Math.floor(loopDuration));

  private _loops = new BehaviorSubject<number>(1);
  public loops$ = this._loops.pipe(distinctUntilChanged());
  public connectToNumberOfLoops = (loops: number) => this._loops.next(loops);

  private _segmentedControlSelection = new BehaviorSubject<SegmentedControlOption>(null);
  public segmentedControlSelectionValue$ = this._segmentedControlSelection.pipe(
    map(selection => selection?.value),
    distinctUntilChanged(),
    tap(selectionValue => {
      switch (true) {
        case selectionValue === 'Manual':
          this.connectToChangeDisplayDuration(this.previouslyKnownDisplayDuration || DEFAULT_ROTATION_INTERVAL);
          break;
        case selectionValue === 'Auto':
          this.connectToChangeSliderValue(this.previouslyKnownScrollSpeed || -5);
          break;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public connectToSegmentedControlSelection = (x: SegmentedControlOption[]) => {
    this._segmentedControlSelection.next(x?.firstOrNull());
  };

  public totalRunTime$ = combineLatest([
    this.loopDuration$,
    this.loops$
  ]).pipe(
    map(([loopDuration, loops]) => loopDuration * loops ?? 0),
    distinctUntilChanged()
  );

  public customErrorMap$ = this.menu$.pipe(
    map(menu => {
      const errorMap = new Map();
      if (menu.overflowState === OverflowState.STEADY_SCROLL) {
        errorMap.set('min', 'Steady Scroll menus must have a duration of 15s or more.');
      }
      return errorMap;
    })
  );

  private isLoopingMarketingContent$ = this.menu$.pipe(
    map(m => m?.isLoopingMarketingContent),
    distinctUntilChanged(),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public moreThanOneMenuAndMarketingContent$ = combineLatest([
    this.isLoopingMarketingContent$,
    this.nMenusOnDisplay$
  ]).pipe(
    map(([isLooping, nMenusOnDisplay]) => isLooping && (nMenusOnDisplay > 1))
  );

  private marketingContentAndContainedInCollection$ = combineLatest([
    this.isLoopingMarketingContent$,
    this.menuIsPartOfCollection$
  ]).pipe(
    map(([isLooping, menuIsPartOfCollection]) => isLooping && menuIsPartOfCollection)
  );

  private productMenuSectionLevelTransitions$ = this.menu$.pipe(
    map(menu => menu?.type === MenuType.DisplayMenu && menu?.overflowState?.includes('SECTION'))
  );

  public moreThanOneMenuAndProductMenuSectionLevelTransitions$ = combineLatest([
    this.productMenuSectionLevelTransitions$,
    this.nMenusOnDisplay$
  ]).pipe(
    map(([productMenuSectionLevelTransitions, nMenusOnDisplay]) => {
      return productMenuSectionLevelTransitions && (nMenusOnDisplay > 1);
    })
  );

  public oneMenuAndProductMenuSectionLevelTransitions$ = combineLatest([
    this.productMenuSectionLevelTransitions$,
    this.nMenusOnDisplay$
  ]).pipe(
    map(([productMenuSectionLevelTransitions, nMenusOnDisplay]) => {
      return productMenuSectionLevelTransitions && (nMenusOnDisplay === 1);
    })
  );

  private productMenuSectionLevelTransitionsAndContainedInCollection$ = combineLatest([
    this.productMenuSectionLevelTransitions$,
    this.menuIsPartOfCollection$
  ]).pipe(
    map(([productMenuSectionLevelTransitions, menuIsPartOfCollection]) => {
      return productMenuSectionLevelTransitions && menuIsPartOfCollection;
    })
  );

  public oneMenuLoopingWithCarouselCards$ = combineLatest([
    this.menu$,
    this.nMenusOnDisplay$
  ]).pipe(
    map(([menu, nMenusOnDisplay]) => {
      return menu?.isLoopingMarketingContent && menu?.cardType?.isCarouselCardType() && (nMenusOnDisplay === 1);
    })
  );

  public oneLoopingContentMenu$ = combineLatest([
    this.menu$,
    this.nMenusOnDisplay$
  ]).pipe(
    map(([menu, nMenusOnDisplay]) => {
      return menu?.isLoopingMarketingContent && (nMenusOnDisplay === 1);
    })
  );

  private loopingInputs$ = combineLatest([
    this.moreThanOneMenuAndMarketingContent$,
    this.marketingContentAndContainedInCollection$,
    this.moreThanOneMenuAndProductMenuSectionLevelTransitions$,
    this.productMenuSectionLevelTransitionsAndContainedInCollection$
  ]);

  public usesLoopSystem$ = this.loopingInputs$.pipe(
    map(([
      moreThanOneMenuAndMarketingContent,
      marketingContentAndContainedInCollection,
      moreThanOneMenuAndProductMenuSectionLevelTransitions,
      productMenuSectionLevelTransitionsContainedInCollection
    ]) => {
      return moreThanOneMenuAndMarketingContent
          || marketingContentAndContainedInCollection
          || moreThanOneMenuAndProductMenuSectionLevelTransitions
          || productMenuSectionLevelTransitionsContainedInCollection;
    })
  );

  public showEnterDisplayDuration$ = combineLatest([
    this.usesLoopSystem$,
    this.oneMenuAndProductMenuSectionLevelTransitions$,
    this.oneMenuLoopingWithCarouselCards$,
    this.oneLoopingContentMenu$
  ]).pipe(
    map(([
      usesLoopSystem,
      singleProdMenuWithSectionLevelTransition,
      singleMenuLoopingWithCarouselCards,
      oneLoopingContentMenu
    ]) => {
      const hide = usesLoopSystem
        || singleProdMenuWithSectionLevelTransition
        || singleMenuLoopingWithCarouselCards
        || oneLoopingContentMenu;
      return !hide;
    })
  );

  public loopingLabel$ = this.loopingInputs$.pipe(
    map(([
      moreThanOneMenuAndMarketingContent,
      marketingContentAndContainedInCollection,
      moreThanOneMenuAndProductMenuSectionLevelTransitions,
      productMenuSectionLevelTransitionsAndContainedInCollection
    ]) => {
      switch (true) {
        case moreThanOneMenuAndMarketingContent:
        case marketingContentAndContainedInCollection:
          return 'Play Count';
        case moreThanOneMenuAndProductMenuSectionLevelTransitions:
        case productMenuSectionLevelTransitionsAndContainedInCollection:
          return 'Overflow iterations';
      }
    })
  );

  public loopingPlaceholder$ = this.loopingInputs$.pipe(
    map(([
      moreThanOneMenuAndMarketingContent,
      marketingContentAndContainedInCollection,
      moreThanOneMenuAndProductMenuSectionLevelTransitions,
      productMenuSectionLevelTransitionsAndContainedInCollection
    ]) => {
      switch (true) {
        case moreThanOneMenuAndMarketingContent:
        case marketingContentAndContainedInCollection:
          return 'How many times to repeat';
        case moreThanOneMenuAndProductMenuSectionLevelTransitions:
        case productMenuSectionLevelTransitionsAndContainedInCollection:
          return 'Iterations';
      }
    })
  );

  public loopingTooltip$ = this.loopingInputs$.pipe(
    map(([
      moreThanOneMenuAndMarketingContent,
      marketingContentAndContainedInCollection,
      moreThanOneMenuAndProductMenuSectionLevelTransitions,
      productMenuSectionLevelTransitionsAndContainedInCollection
    ]) => {
      switch (true) {
        case moreThanOneMenuAndMarketingContent:
        case marketingContentAndContainedInCollection:
          return 'Number of times to loop menu before changing to the next.';
        case moreThanOneMenuAndProductMenuSectionLevelTransitions:
        case productMenuSectionLevelTransitionsAndContainedInCollection:
          return 'Number of times to repeat longest overflow content loop before moving onto the next menu.';
      }
    })
  );

  public displayDurationTooltip$ = this.menu$.pipe(
    map(m => {
      switch (m.menuSubType) {
        case MarketingMenuType.Playlist:
          return 'The total duration required to play each media asset for the duration defined within the menu';
        case MarketingMenuType.Featured:
          return 'The total duration required to display each featured product for the duration defined within '
            + 'the menu.';
        case MarketingMenuType.Category:
          return 'The total duration required to display each of the products within the largest of the featured '
            + 'category cards on the menu.';
        default:
          return 'How long the menu will be displayed for. Display duration affects scroll speed.';
      }
    })
  );

  public readonly rangeSliderOptions$ = of(RangeSliderOptions.scrollSpeed());

  public readonly isSteadyScroll$ = this.menu$.pipe(
    map(menu => menu?.overflowState === OverflowState.STEADY_SCROLL)
  );

  public readonly showScrollSpeedSlider$ = combineLatest([
    this.isSteadyScroll$,
    this.bindTo$,
    this.segmentedControlSelectionValue$
  ]).pipe(
    map(([isSteadyScroll, displayMenuOptions, selection]) => {
      if (isSteadyScroll) {
        return !selection
          ? displayMenuOptions?.interval < 0
          : selection === 'Auto';
      }
      return false;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public minDisplayDuration$ = combineLatest([
    this.menu$,
    this.showScrollSpeedSlider$
  ]).pipe(
    map(([menu, showScrollSpeedSlider]) => {
      if (showScrollSpeedSlider) return -10;
      if (menu?.isLoopingMarketingContent) {
        // Summing the duration of each product on the menu
        return menu.calculateMarketingLoopDuration;
      } else if (menu.overflowState === OverflowState.STEADY_SCROLL) {
        return 15;
      }
      return 5;
    }),
    distinctUntilChanged()
  );

  public readonly segmentedControlOptions$ = this.bindTo$.pipe(
    map(bindTo => {
      // Setup portrait option
      const manual = new SegmentedControlOption('Manual', 'Manual');
      manual.selected = bindTo?.interval >= 1;
      // Setup landscape option
      const auto = new SegmentedControlOption('Auto', 'Auto');
      auto.selected = bindTo?.interval < 0;
      return [manual, auto];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public previouslyKnownScrollSpeed: number;
  private readonly _changeSliderValue = new Subject<number>();
  public readonly changeSliderValue$ = this._changeSliderValue.pipe(
    switchMap(value => of(undefined).pipe(delay(100), startWith(value))),
    startWith(undefined)
  );
  connectToChangeSliderValue = (value: number) => this._changeSliderValue.next(value);

  public previouslyKnownDisplayDuration: number;
  private readonly _changeDisplayDuration = new Subject<number>();
  public readonly changeDisplayDuration$ = this._changeDisplayDuration.pipe(
    switchMap(value => of(undefined).pipe(delay(1000), startWith(value))),
    startWith(undefined)
  );
  connectToChangeDisplayDuration = (value: number) => this._changeDisplayDuration.next(value);

  private listenToLoopChanges = combineLatest([
    this.usesLoopSystem$,
    this.bindTo$
  ]).pipe(takeUntil(this.onDestroy)).subscribe(([usesLoopSystem, displayMenuOptions]) => {
    if (usesLoopSystem) this._loops.next(displayMenuOptions?.interval ?? 1);
  });

}
