import { HttpErrorResponse } from '@angular/common/http';

const GenericAPIErrorTitle = 'An error has occurred.';

export class BsError {

  public code: number;
  public title: string;
  public message: string;
  public suggestion: string;

  constructor(err?: HttpErrorResponse, serviceName?: string) {
    if (err) {
      this.code = err?.status;
      // Parse the error body
      let errorBody = err?.error;
      if (typeof err?.error === 'string') {
        // If the body is a string, convert it to an object
        errorBody = JSON.parse(err?.error);
      }
      if (errorBody?.title === GenericAPIErrorTitle) {
        this.title = `${serviceName} Error`;
      } else if (errorBody) {
        this.title = err?.error?.title;
      }
      this.message = errorBody?.message;
      this.suggestion = errorBody?.suggestion;
    }
  }

  static NewLocalBsError(title, message: string): BsError {
    const err = new BsError();
    err.title = title;
    err.message = message;
    return err;
  }

}
