import { TypeDefinition } from './type-definition';
import { Infoable } from '../../protocols/infoable';

export enum OverflowStateInfoType {
  Default,
  FeatCatCarousel,
  FeatCatNonCarousel
}

export enum OverflowState {
  NONE = 'NONE',
  SCROLL = 'SCROLL',
  STEADY_SCROLL = 'STEADY_SCROLL',
  PAGING = 'PAGING',
  SECTION_SCROLL = 'SECTION_SCROLL', // infinite scroll - 1 line item at a time
  SECTION_PAGING = 'SECTION_PAGING', // paging with fade animation
}

export class OverflowStateType extends TypeDefinition implements Infoable {

  getSelectionInformationDescription(overflowStateInfoType: OverflowStateInfoType): string {
    switch (this.value) {
      case OverflowState.STEADY_SCROLL:
        switch (overflowStateInfoType) {
          case OverflowStateInfoType.Default:
            return `If the menu contains more products than will fit on one page, the menu will dynamically scroll `
              + `to allow for all products to be displayed. The pace at which the page scrolls will be based on the `
              + `‘Display Duration’ setting for that digital display. A fixed duration can be defined, or this value `
              + `can be determined automatically by a speed slider.`;
          case OverflowStateInfoType.FeatCatNonCarousel:
            return 'The menu will display the specified number of cards and continuously scroll '
              + 'the view until the last card is shown, at which point the scrolling animation will reverse.';
          default:
            return '';
        }
      case OverflowState.SCROLL:
        switch (overflowStateInfoType) {
          case OverflowStateInfoType.Default:
            return 'If the menu contains more products than will fit on one page, '
              + 'the menu will dynamically add pages to fit all products. '
              + 'Each page will be displayed for the amount of time set in the '
              + '‘Display Duration’ setting for that digital display. Pages will '
              + 'transition using a quick vertical or horizontal sliding animation.';
          case OverflowStateInfoType.FeatCatCarousel:
            return 'The menu will only display the specified number of cards on the screen. The products'
              + ' within each card will rotate using a snap animation based on the defined carousel duration.';
          case OverflowStateInfoType.FeatCatNonCarousel:
            return 'The menu will display the specified number of cards and after the menu duration '
              + 'the cards will use a snap animation to show the next card. The menu will start at the '
              + 'first card after cycling through all the cards.';
          default:
            return '';
        }
      case OverflowState.PAGING:
        switch (overflowStateInfoType) {
          case OverflowStateInfoType.Default:
            return 'If the menu contains more products than will fit on one page, '
              + 'the menu will dynamically add pages to fit all products. '
              + 'Each page will be displayed for the amount of time set in the '
              + '‘Display Duration’ setting for that digital display. With this '
              + 'option, there will be no transition animation between pages.';
          case OverflowStateInfoType.FeatCatCarousel:
            return 'The menu will only display the specified number of cards on the screen. '
              + 'The products within each card will rotate using a transition animation'
              + ' based on the defined carousel duration.';
          case OverflowStateInfoType.FeatCatNonCarousel:
            return 'The menu will display the specified number of cards and after the menu duration the cards '
              + 'will use a transition animation to show the next card. The menu will start at the first card after'
              + ' cycling through all the cards.';
          default:
            return '';
        }
      case OverflowState.SECTION_PAGING:
        switch (overflowStateInfoType) {
          case OverflowStateInfoType.Default:
            return 'If a section with a product row count limit contains more products than what the limit is set'
              + ' to, the section will dynamically add additional section pages to all products. This differs from'
              + ' the above menu transitions because only the section content will transition,'
              + ' as opposed to the entire menu. Each page within the section will be displayed for the '
              + 'Section Overflow Duration specified in edit section. '
              + 'With this option, sections will transition using a fade animation.';
          default:
            return '';
        }
      case OverflowState.SECTION_SCROLL:
        switch (overflowStateInfoType) {
          case OverflowStateInfoType.Default:
            return 'If a section with a product row count limit contains more products than what the limit is set'
              + ' to, then the section will infinitely tumble the products on and off the screen one at a time.'
              + ' This differs from the above menu transitions as only the section will transition, '
              + 'as opposed to the entire menu.'
              + ' Each tumble will happen after the Section Overflow Duration is specified in edit section.'
              + ' With this option, the items will scroll on and off the screen.';
          default:
            return '';
        }
      case OverflowState.NONE:
        switch (overflowStateInfoType) {
          case OverflowStateInfoType.Default:
            return 'A digital menu only has room for a select amount of products. '
              + 'If the menu is full, this option will stop the menu from '
              + 'automatically transitioning to additional page(s) to display more products.';
          case OverflowStateInfoType.FeatCatCarousel:
            return 'Only the first products will appear within each card and any additional products '
              + 'will remain hidden from view.';
          case OverflowStateInfoType.FeatCatNonCarousel:
            return 'Only the specified number of cards to display will appear on the screen and'
              + ' any additional cards will remain hidden from view.';
        }
    }
    return '';
  }

}

