import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-incrementor',
  templateUrl: './incrementor.component.html',
  styleUrls: ['./incrementor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncrementorComponent implements OnChanges {

  @Input() disabled: boolean = false;
  @Input() incrementBy: number = 1;
  @Input() minValue: number = 0;
  @Input() maxValue: number = Infinity;
  @Input() value: number = 1;
  @Output() valueChanged = new EventEmitter<number>(true);

  private readonly _number = new BehaviorSubject<number|null>(this.value);
  public readonly number$ = this._number.pipe(distinctUntilChanged());

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value) this._number.next(this.value);
  }

  protected increment(): void {
    if (this.disabled) return;
    this.number$.once(n => {
      const updated = (n || 0) + this.incrementBy;
      if (Number.isFinite(this.maxValue)) {
        if (updated <= this.maxValue) this.numberChanged(updated);
      } else {
        this.numberChanged(updated);
      }
    });
  }

  protected decrement(): void {
    if (this.disabled) return;
    this.number$.once(n => {
      const updated = (n || 0) - this.incrementBy;
      if (Number.isFinite(this.minValue)) {
        if (updated >= this.minValue) this.numberChanged(updated);
      } else {
        this.numberChanged(updated);
      }
    });
  }

  protected numberChanged(n: number | null) {
    this.number$.once(current => {
      if (current !== n) {
        this._number.next(n);
        this.valueChanged.emit(n);
      }
    });
  }

}
