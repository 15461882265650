import { Component, Injector } from '@angular/core';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionService } from '../../../../../../services/action.service';
import { PrintMenusContainerViewModel } from './print-menus-container-view-model';
import { DisplayableContentContainerComponent } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component';
import { DisplayableContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container-view-model';
import { BulkPrintJobsListViewModel } from '../bulk-print-jobs-list/bulk-print-jobs-list-view-model';

@Component({
  selector: 'app-print-menus-container',
  templateUrl: './print-menus-container.component.html',
  styleUrls: [
    '../../../../../shared/components/displayable-content/displayable-content-container/displayable-container.component.scss',
    './print-menus-container.component.scss',
  ],
  providers: [
    BulkPrintJobsListViewModel,
    { provide: DisplayableContentContainerViewModel, useExisting: PrintMenusContainerViewModel }
]
})
export class PrintMenusContainerComponent extends DisplayableContentContainerComponent {

  constructor(
    public override viewModel: PrintMenusContainerViewModel, // Provided by Logged In Scope
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector,
    actionService: ActionService
  ) {
    super(viewModel, navigationService, ngbModal, injector, actionService);
  }

  override setupBindings() {
    super.setupBindings();
    const createMenuFlag = this.actionService.createPrintMenu.consumeFlag(_ => this.viewModel.toggleAddNewMenu());
    this.pushSub(createMenuFlag);
  }

}
