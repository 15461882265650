import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';
import { BaseSmartFilterFormComponent } from '../base-smart-filter-form';
import { CannabinoidAddEditSmartFilterFormViewModel } from './cannabinoid-add-edit-smart-filter-form-view-model';

@Component({
  selector: 'app-cannabinoid-add-edit-smart-filter-form',
  templateUrl: 'cannabinoid-add-edit-smart-filter-form.component.html',
  styleUrls: ['./cannabinoid-add-edit-smart-filter-form.component.scss'],
  providers: [CannabinoidAddEditSmartFilterFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CannabinoidAddEditSmartFilterFormComponent extends BaseSmartFilterFormComponent implements OnChanges {

  constructor(
    public viewModel: CannabinoidAddEditSmartFilterFormViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) this.viewModel.connectToSmartFilter(this.bindTo);
    if (changes.isEditing) this.viewModel.connectToIsEditing(this.isEditing);
    if (changes.isViewOnly) this.viewModel.connectToIsViewOnly(this.isViewOnly);
  }

}
