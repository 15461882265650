import { Deserializable } from '../../protocols/deserializable';
import { ColorUtils } from '../../../utils/color-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { FontStyle } from '../../utils/dto/font-style-type';
import { SectionColumnConfigDefaultState } from '../../utils/dto/section-column-config-default-state-type';
import { SectionColumnConfigDataValue, SectionColumnConfigEmptyData } from '../../utils/dto/section-column-config-data-value-type';

export class SectionColumnConfig implements Deserializable, UniquelyIdentifiable {

  public defaultState: SectionColumnConfigDefaultState = SectionColumnConfigDefaultState.Unknown;
  public columnName: string = null;
  public columnWidth: number = null;
  // Currently, only used for Secondary Price
  public dataValue: SectionColumnConfigDataValue = SectionColumnConfigEmptyData.NotSet;
  public fontStyle: string = FontStyle.Normal;
  public fontColor: string = null;
  public columnColor: string = null;
  // ColumnOpacity is saved in the DB as a float 0.0-1.0 but is shown to the user as a % between 0-100
  public columnOpacity: number = null;
  // Not from API
  public hasCustomStyle: boolean = false;

  onDeserialize() {
    this.defaultState = this.defaultState || SectionColumnConfigDefaultState.Unknown;
    if (this.columnWidth === 0) {
      this.columnWidth = null;
    }
    if (this.columnOpacity === 0) {
      this.columnOpacity = null;
    }
    this.dataValue = this.dataValue || SectionColumnConfigEmptyData.NotSet;
    this.hasCustomStyle = this.checkForCustomStyle();
  }

  // DTO === Data Transfer Object
  translateToDTO(): SectionColumnConfig | null {
    if (!this.defaultState || this.defaultState === SectionColumnConfigDefaultState.Disabled) {
      return null;
    }
    return this;
  }

  private checkForCustomStyle(): boolean {
    return this.fontStyle !== FontStyle.Normal
        || this.fontColor !== ''
        || this.columnColor !== ''
        || this.columnOpacity !== null;
  }

  getRGBABackgroundColor(): string {
    if (!!this.columnColor) {
      const rgb = ColorUtils.hexToRgb(this.columnColor);
      return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${this.columnOpacity ?? 1})`;
    } else {
      return null;
    }
  }

  getUniqueIdentifier(): string {
    const defaultState = this.defaultState;
    const columnName = this.columnName ?? '';
    const columnWidth = this.columnWidth?.toString() ?? '';
    const dataValue = this.dataValue;
    const fontStyle = this.fontStyle;
    const fontColor = this.fontColor ?? '';
    const columnColor = this.columnColor ?? '';
    const columnOpacity = this.columnOpacity?.toString() ?? '';
    const hasCustomStyle = this.hasCustomStyle;
    return `${defaultState}
      -${columnName}
      -${columnWidth}
      -${dataValue}
      -${fontStyle}
      -${fontColor}
      -${columnColor}
      -${columnOpacity}
      -${hasCustomStyle}`;
  }

}
