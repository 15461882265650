import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayMenuSectionHeaderComponent } from '../../../display-menu-section/display-menu-section-header/display-menu-section-header.component';

@Component({
  selector: 'app-display-menu-section-inside-template-collection-header',
  templateUrl: './display-menu-section-inside-template-collection-header.component.html',
  styleUrls: [
    './display-menu-section-inside-template-collection-header.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionInsideTemplateCollectionHeaderComponent extends DisplayMenuSectionHeaderComponent {

}
