import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';

@Component({
  selector: 'app-edit-product-menu-details',
  templateUrl: './edit-product-menu-details.component.html',
  styleUrls: ['./edit-product-menu-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProductMenuDetailsComponent extends BaseComponent {

  constructor(
    public viewModel: EditProductMenuViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;

}
