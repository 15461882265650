import { NavSubItemViewModel } from '../nav-sub-item-view-model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';

@Injectable()
export class LocationsSubNavItemViewModel extends NavSubItemViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    router: Router,
  ) {
    super(router);
  }

  public showNavItemAlertIndicator$ = this.locationDomainModel.allLocations$.pipe(
    map((locations) => locations?.some(l => l?.isMissingInformation()))
  );

}
