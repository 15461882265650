import { Asset } from '../../image/dto/asset';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { AssetSize } from '../../enum/dto/asset-size.enum';
import { DateUtils } from '../../../utils/date-utils';

export class AssetPrintPDF extends Asset {

  override onDeserialize() {
    super.onDeserialize();
    // always download the pdf once metadata is available
    this.getAsset(CachePolicy.Session, AssetSize.Original, DateUtils.unixOneHour());
  }

}
