import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DigitalProductMenusViewModel } from './digital-product-menus-view-model';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';

@Component({
  selector: 'app-digital-product-menus',
  templateUrl: './digital-product-menus.component.html',
  styleUrls: ['./digital-product-menus.component.scss'],
  providers: [DigitalProductMenusViewModel]
})
export class DigitalProductMenusComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: DigitalProductMenusViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
