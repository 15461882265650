import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InformationItem } from '../../../../../models/shared/information-item';
import { SegmentedControlOption } from '../../../../../models/shared/stylesheet/segmented-control-option';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';

@Component({
  selector: 'app-overflow-video',
  templateUrl: './overflow-video.component.html',
  styleUrls: ['./overflow-video.component.scss']
})
export class OverflowVideoComponent extends BaseComponent implements OnChanges {

  @Input() selectedInfoItem: {item: InformationItem; videoUrl: string};
  @Input() stickyOffset: string;
  @Output() orientationSelected: EventEmitter<SegmentedControlOption> = new EventEmitter();

  orientationControlOptions: SegmentedControlOption[] = [];
  selectedOrientationControl: SegmentedControlOption;
  private _videoLoaded = new BehaviorSubject<boolean>(false);
  public videoLoaded$ = this._videoLoaded.asObservable().pipe(distinctUntilChanged());

  private setupOrientationSegmentedControl(): void {
    // Setup portrait option
    const p = new SegmentedControlOption('Portrait', Orientation.Portrait);
    p.selected = true;
    this.orientationControlOptions.push(p);
    this.selectedOrientationControl = p;
    this.orientationSelected.emit(this.selectedOrientationControl);
    // Setup landscape option
    const l = new SegmentedControlOption('Landscape', Orientation.Landscape);
    this.orientationControlOptions.push(l);
  }

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedInfoItem) {
      this._videoLoaded.next(false);
    }
  }

  override setupViews(): void {
    this.setupOrientationSegmentedControl();
  }

  orientationSegmentedControlOptionSelected(updatedOpts: SegmentedControlOption[]): void {
    this.selectedOrientationControl = updatedOpts[0];
    this.orientationSelected.emit(this.selectedOrientationControl);
  }

  onCanPlayThrough() {
    this._videoLoaded.next(true);
  }

}
