import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { Event } from '../models/event';
import { delay, map, scan } from 'rxjs/operators';
import { EventQueue } from '../models/event-queue';
import { EventType } from '../models/event-type.enum';

/**
 * Create by: Kevin Baker
 * Date: October 22, 2021
 *
 * This is the interface in which a programmer can send banner events to the screen.
 */
@Injectable({ providedIn: 'root' })
export class BannerEventsService {

  static VISIBLE_SECONDS: number = 12;

  private _dismissEvent = new BehaviorSubject<Event>(null);
  private _events = new Subject<Event>();
  private queue$ = this._events.pipe(scan((queue, event) => queue.addToQueue(event), new EventQueue()));
  public events$ = combineLatest([
    this._dismissEvent.pipe(map(e => e?.killTimerAndDereference()), delay(700)),
    this.queue$
  ]).pipe(
    map(([dismiss, q]) => q.removeFromQueue(dismiss)),
    map(q => q.getDisplayableQueue())
  );

  constructor() { }

  error(message: string) {
    this._events.next(new Event(this, BannerEventsService.VISIBLE_SECONDS, EventType.Error, message));
  }

  success(message: string) {
    this._events.next(new Event(this, BannerEventsService.VISIBLE_SECONDS, EventType.Success, message));
  }

  message(message: string) {
    this._events.next(new Event(this, BannerEventsService.VISIBLE_SECONDS, EventType.Message, message));
  }

  info(message: string) {
    this._events.next(new Event(this, BannerEventsService.VISIBLE_SECONDS, EventType.Info, message));
  }

  warning(message: string) {
    this._events.next(new Event(this, BannerEventsService.VISIBLE_SECONDS, EventType.Warning, message));
  }

  dismiss(event: Event) {
    this._dismissEvent.next(event);
  }

}
