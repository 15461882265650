<label [hidden]="checkBoxContainerOptions.hideBulkCheckbox" class="bs-checkbox-container" for="checkbox-all-{{ cid }}">
  <input
    class="bs-checkbox"
    id="checkbox-all-{{ cid }}"
    name="checkbox-all"
    type="checkbox"
    [ngClass]="{ indeterminate: percentageChecked > 0 && percentageChecked < 1 }"
    [checked]="percentageChecked === 1"
    (change)="selectAllClicked($event)" />
  <span class="bs-checkbox-label">
    {{ checkBoxContainerOptions.checkAllText }}
  </span>
  <span class="bs-checkmark"></span>
</label>

<app-checkbox
  *ngFor="let chbx of checkBoxes"
  [checkbox]="chbx"
  [refresh]="refresh"
  [inline]="checkBoxContainerOptions.inlineOptions"
  [customCheckboxMargin]="checkBoxContainerOptions.customCheckboxMargin"
  (clickEvent)="selectSingleOptionClicked()">
</app-checkbox>
