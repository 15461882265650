/* eslint-disable @typescript-eslint/member-ordering */
import { LocationPromotion } from './location-promotion';

export class Promotion extends LocationPromotion {

  public products: string[];
  public productsExclusionary: boolean;
  public classifications: string[];
  public classificationsExclusionary: boolean;
  public categories: string[];
  public categoriesExclusionary: boolean;
  public vendors: string[];
  public vendorsExclusionary: boolean;
  public brands: string[];
  public brandsExclusionary: boolean;
  public strains: string[];
  public strainsExclusionary: boolean;

  override onDeserialize() {
    super.onDeserialize();
    this.products = Array.from(this.products || []);
    this.classifications = Array.from(this.classifications || []);
    this.categories = Array.from(this.categories || []);
    this.vendors = Array.from(this.vendors || []);
    this.brands = Array.from(this.brands || []);
    this.strains = Array.from(this.strains || []);
  }

}

