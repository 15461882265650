import { BaseViewModel } from '../../../../models/base/base-view-model';
import { AnimatorService } from '../../../../services/animator/animator.service';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationViewModel extends BaseViewModel {

  constructor(
    protected animatorService: AnimatorService,
    protected locationDomainModel: LocationDomainModel
  ) {
    super();
  }
  public locationName$ = this.locationDomainModel?.locationName$;

  private _customText = new BehaviorSubject<string>(null);
  public customText$ = this._customText as Observable<string>;
  public connectToCustomText = (t: string) => this._customText.next(t);

  public subheadingText$ = combineLatest([this.locationName$, this.customText$]).pipe(
    map(([locationName, customText]) => customText ?? locationName)
  );

  animateCurrentLocation = () => this.animatorService.animateLocationPicker();

}
