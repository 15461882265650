export enum UploadFilePath {
  CompanyLogoPath = 'CompanyLogoPath',
  CompanyAltLogoPath = 'CompanyAltLogoPath',
  VariantBadgePath = 'VariantBadgePath',
  SectionImagePath = 'SectionImagePath',
  SectionSecondaryImagePath = 'SectionSecondaryImagePath',
  ConfigurationBackgroundImagePath = 'ConfigurationBackgroundImagePath',
  DisplayPreviewPath = 'DisplayPreviewPath',
  ConfigurationPreviewPath = 'ConfigurationPreviewPath',
  MarketingImagePath = 'MarketingImagePath',
  MarketingVideoPath = 'MarketingVideoPath',
  ProfilePicturePath = 'ProfilePicturePath',
}
