import { Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';
import { WebProductTemplatesViewModel } from './web-product-templates-view-model';

@Component({
  selector: 'app-web-product-templates',
  templateUrl: './web-product-templates.component.html',
  styleUrls: ['./web-product-templates.component.scss'],
  providers: [WebProductTemplatesViewModel],
})
export class WebProductTemplatesComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: WebProductTemplatesViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
