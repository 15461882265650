import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nLabelsSelected'
})
export class NLabelsSelectedPipe implements PipeTransform {

  transform(selectionMap: Map<string, number>): number {
    return [...(selectionMap?.values() ?? [])].reduce((acc, count) => count > 0 ? acc + count : acc, 0);
  }

}
