import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LabelPreviewViewModel } from './label-preview-view-model';
import { BaseComponent } from '../../../models/base/base-component';
import { ComputeLabelInterface } from '../label/compute-label-interface';
import { LabelInflatorComponent } from '../label-inflator/label-inflator.component';
import { Label } from '../../../models/shared/label';
import { BehaviorSubject, Observable } from 'rxjs';
import { SystemLabel } from '../../../models/shared/labels/system-label';

@Component({
  selector: 'app-label-preview',
  templateUrl: './label-preview.component.html',
  styleUrls: ['./label-preview.component.scss'],
  providers: [LabelPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelPreviewComponent extends BaseComponent implements OnChanges {

  @ViewChild('labelCalculator') labelCalculator: LabelInflatorComponent;
  @Input() computeLabelInterface: ComputeLabelInterface;
  @Input() zoom: number = 0.7;
  @Input() stackLabelsVertically: boolean = false;
  @Output() computedPrimaryLabel = new EventEmitter<Label>(true);

  constructor(public viewModel: LabelPreviewViewModel) {
    super();
  }

  private _appliedSortedSystemLabels = new BehaviorSubject<SystemLabel[]>([]);
  public appliedSortedSystemLabels$ = this._appliedSortedSystemLabels as Observable<SystemLabel[]>;

  override setupBindings() {
    this.labelCalculator.computedPrimaryLabel$.subscribeWhileAlive({
      owner: this,
      next: l => this.computedPrimaryLabel.emit(l)
    });
    this.labelCalculator.computedSortedSystemLabels$.subscribeWhileAlive({
      owner: this,
      next: systemLabels => this._appliedSortedSystemLabels.next(systemLabels)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.computeLabelInterface) this.viewModel.connectToComputeLabelInterface(this.computeLabelInterface);
  }

}
