import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  selector: 'app-edit-menu-section-footer',
  templateUrl: './edit-menu-section-footer.component.html',
  styleUrls: [
    './edit-menu-section-footer.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionFooterComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() removingVariants: boolean = false;
  @Input() formDisabledSubmit: boolean = false;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() isTemplatedSection: boolean = false;
  @Input() isPrintReportSection: boolean = false;
  @Input() autoSavingLoadingOpts: LoadingOptions;
  @Input() lastAutoSaveText: string;
  @Output() deleteSection = new EventEmitter<void>(true);
  @Output() duplicateSection = new EventEmitter<void>(true);
  @Output() submitForms = new EventEmitter<boolean>(true);

}
