<div appThrottleClick class="province-location-mapping-container clickable" (throttleClick)="clicked()">
  <div>
    <div class="province">{{ province }}</div>
    <div class="n-locations">
      <span>{{ locations | numberOfLocations }}</span>
      <ng-container *ngIf="templateStatus === ClientTypes.TemplateStatus.Published">
        <span *ngIf="originalRequiredLocations | numberOfNewRequiredLocations : updatedRequiredLocations as updated">
          {{ updated }}</span
        >
        <span
          *ngIf="originalRequiredLocations | numberOfRemovedRequiredLocations : updatedRequiredLocations as removed">
          {{ removed }}</span
        >
      </ng-container>
    </div>
  </div>
  <img [src]="'assets/icons/dark/outline/chevron-right.svg'" alt="" />
</div>
<hr class="no-margin" />
