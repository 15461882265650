import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notAnAdminTooltip'
})
export class NotAnAdminTooltipPipe implements PipeTransform {

  transform(isTemplated: boolean): string {
    if (isTemplated) {
      return 'This property can only be updated by an admin.';
    }
    return null;
  }

}
