import { Pipe, PipeTransform } from '@angular/core';
import { LabelUtils } from '../utils/label-utils';
import { Label } from '../../../models/shared/label';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isCompanyOrphan'
})
export class IsCompanyOrphanPipe implements PipeTransform {

  transform(label: Label, locationCustomLabels$: Observable<Label[]>): Observable<boolean> {
    return locationCustomLabels$.pipe(
      map(locationCustomLabels => LabelUtils.isCompanyOrphan(label, locationCustomLabels))
    );
  }

}
