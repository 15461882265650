import { Injectable, Injector } from '@angular/core';
import { AnimatorService } from '../../../services/animator/animator.service';
import { DisplayDomainModel } from '../../../domainModels/display-domain-model';
import { Display } from '../../../models/display/dto/display';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { NewDisplay } from '../../../models/display/shared/new-display';
import { BsError } from '../../../models/shared/bs-error';
import { ToastService } from '../../../services/toast-service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Orientation } from '../../../models/utils/dto/orientation-type';
import { SizeUnit } from '../../../models/utils/dto/size-unit-type';
import { ActionService } from '../../../services/action.service';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { SortUtils } from '../../../utils/sort-utils';
import { ModalReorderDisplays } from '../../../modals/modal-reorder-displays';
import { ModalAddDisplay } from '../../../modals/modal-add-display';
import { TemplateCollectionDomainModel } from '../../../domainModels/template-collection-domain-model';
import { Selectable } from '../../../models/protocols/selectable';
import { ReactiveFormUtils } from '../../../utils/reactive-form-utils';
import { NavigationService } from '../../../services/navigation.service';

// Provided by Logged In Scope
@Injectable()
export class AllDisplaysViewModel extends BaseModalViewModel {

  constructor(
    private displayDomainModel: DisplayDomainModel,
    private menuDomainModel: MenuDomainModel,
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private animatorService: AnimatorService,
    private toastService: ToastService,
    private actionService: ActionService,
    private navigationService: NavigationService,
    private templateCollectionDomainModel: TemplateCollectionDomainModel,
    private injector: Injector,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
    this.setupBindings();
  }

  public override dismissModalSubject: Subject<Display> = new Subject<Display>();
  private readonly locationId$ = this.locationDomainModel.locationId$;
  private readonly companyId$ = this.companyDomainModel.companyId$;

  public readonly currentLocationName$ = this.locationDomainModel.locationName$.pipe(map((name) => name || '-' ));

  // Displays
  public allDisplays$ = this.displayDomainModel.currentLocationDisplays$.pipe(
    map(displays => displays?.sort(SortUtils.sortDisplaysByPriority)),
  );
  public numberOfDisplays$ = this.allDisplays$.pipe(map(displays => displays?.length || 0));

  public landscapeDisplays$ = this.allDisplays$.pipe(
    map(displays => displays?.filter(display => display?.isLandscape())),
    map(displays => displays?.sort(SortUtils.sortDisplaysByPriority)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public portraitDisplays$ = this.allDisplays$.pipe(
    map(displays => displays?.filter(display => display?.isPortrait())),
    map(displays => displays?.sort(SortUtils.sortDisplaysByPriority)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public allDisplaysAreSameOrientation$ = combineLatest([
    this.landscapeDisplays$,
    this.portraitDisplays$
  ]).pipe(
    map(([landscape, portrait]) => {
      const onlyPortrait = (landscape?.length === 0) && (portrait?.length > 0);
      const onlyLandscape = (portrait?.length === 0) && (landscape?.length > 0);
      return onlyPortrait || onlyLandscape;
    })
  );

  public showReorderingForAllDisplays$ = combineLatest([
    this.allDisplaysAreSameOrientation$,
    this.numberOfDisplays$
  ]).pipe(
    map(([sameOrientation, numberOfDisplays]) => {
      return sameOrientation && (numberOfDisplays > 1);
    })
  );

  public showLandscapeDisplays$ = combineLatest([
    this.allDisplaysAreSameOrientation$,
    this.landscapeDisplays$
  ]).pipe(
    map(([allDisplaysAreSameOrientation, landscapeDisplays]) => {
      return !allDisplaysAreSameOrientation && !!landscapeDisplays?.length;
    })
  );

  public showPortraitDisplays$ = combineLatest([
    this.allDisplaysAreSameOrientation$,
    this.portraitDisplays$
  ]).pipe(
    map(([allDisplaysAreSameOrientation, portraitDisplays]) => {
      return !allDisplaysAreSameOrientation && !!portraitDisplays?.length;
    })
  );

  public hideNoDisplayPlaceholder$ = this.allDisplays$.pipe(
    map(allDisplays => allDisplays?.length > 0)
  );

  // Flags, Signals and Mechanisms
  public addNewDisplay = new BehaviorSubject<boolean>(null);
  private addNewDisplaySignal = new BehaviorSubject<boolean>(null);
  private waitToShowDialogUntilDoneLoadingMechanism$ = combineLatest([
    this.loadingOpts$.pipe(map(opts => opts?.awaitingRequests)).pipe(debounceTime(50)),
    this.addNewDisplaySignal.pipe(debounceTime((50)))
  ]);

  public readonly currentLocationDigitalMenus$ = this.menuDomainModel.currentLocationDigitalMenus$;
  public readonly currentLocationTemplateCollections$ = this.templateCollectionDomainModel.templateCollections$;

  // New Display Modal
  public menuOptions$: Observable<Selectable[]> = combineLatest([
    this.currentLocationDigitalMenus$,
    this.currentLocationTemplateCollections$
  ]).pipe(
    map(([menus, collections]) => {
      const collectionsDivider: Selectable = ReactiveFormUtils.buildDivider('---- Collections ----');
      const templatedMenusDivider: Selectable = ReactiveFormUtils.buildDivider('---- Templated Menus ----');
      const originalMenus = menus?.filter(m => !m.isTemplatedMenu() && m.active);
      const templatedMenus = menus?.filter(m => m.isTemplatedMenu());
      const hasTemplatedMenus = !!templatedMenus?.length;
      const hasCollections = !!collections?.length;
      return []
        .concat(originalMenus ?? [])
        .concat(hasTemplatedMenus ? templatedMenusDivider : [])
        .concat(hasTemplatedMenus ? templatedMenus : [])
        .concat(hasCollections ? collectionsDivider : [])
        .concat(hasCollections ? collections : []);
    })
  );

  setupBindings() {
    // Reload displays sub
    const reloadDisplaysSub = this.displayDomainModel.reloadingDisplays.notNull().subscribe((loading) => {
      const lm = 'Loading Displays';
      if (loading && !this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
      } else if (!loading && this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.pushSub(reloadDisplaysSub);

    // Loading Menus sub
    const loadMenusSub = this.menuDomainModel.loadingMenus$.notNull().subscribe((loading) => {
      const lm = 'Loading Menus';
      if (loading && !this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
      } else if (!loading && this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.pushSub(loadMenusSub);

    const dialogMechanism = this.waitToShowDialogUntilDoneLoadingMechanism$
      .subscribe(([loading, signal]) => {
        if (signal && loading.length === 0) {
          this.addNewDisplay.next(true); // show dialog
          this.addNewDisplaySignal.next(false); // reset mechanism
        }
      });
    this.pushSub(dialogMechanism);

    const addNewDisplaySub = this.addNewDisplay.consumeFlag(flag => {
      if (flag) this.openAddDisplayModal();
    });
    this.pushSub(addNewDisplaySub);

    const createDisplayFlag = this.actionService
      .createDisplayFlag
      .consumeFlag(_ => this.toggleAddNewDisplayMechanism());
    this.pushSub(createDisplayFlag);
  }

  reorderAllDisplays() {
    this.allDisplays$.pipe(take(1)).subscribe(displays => this.showReorderModal(displays));
  }

  reorderLandscapeDisplays() {
    this.landscapeDisplays$.pipe(take(1)).subscribe(displays => this.showReorderModal(displays, 1000));
  }

  reorderPortraitDisplays() {
    this.portraitDisplays$.pipe(take(1)).subscribe(displays => this.showReorderModal(displays, 2000));
  }

  private showReorderModal(displays: Display[], prefix: number = 0) {
    ModalReorderDisplays.open(this.ngbModal, this.injector, displays, prefix, this.updateDisplayPriorities);
  }

  public createNewDisplay(newDisplay: NewDisplay) {
    const lm = 'Creating Display';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      combineLatest([
        combineLatest([this.locationId$, this.companyId$]),
        combineLatest([this.landscapeDisplays$, this.portraitDisplays$])
      ]).pipe(
        take(1),
        switchMap(([[locationId, companyId], [landscape, portrait]]) => {
          const display = newDisplay.convertToDTO(companyId, locationId);
          display.priority = this.getNextDisplayPriority(display.displaySize?.orientation, landscape, portrait);
          display.displaySize.unit = SizeUnit.Digital;
          return this.displayDomainModel.createDisplay(display);
        })
      ).subscribe({
        next: (display) => {
          this._loadingOpts.removeRequest(lm);
          this.dismissModalSubject.next(display);
          this.toastService.publishSuccessMessage('New display created successfully.', 'Display Created');
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(error);
        }
      });
    }
  }

  animateCurrentLocation() {
    this.animatorService.animateLocationPicker();
  }

  private getNextDisplayPriority(
    o: Orientation,
    landscapeDisplays: Display[],
    portraitDisplays: Display[]
  ): number {
    if (o === Orientation.Landscape) {
      return (landscapeDisplays?.map(d => d?.priority)?.filterNulls()?.last() ?? 0) + 1;
    } else {
      return (portraitDisplays?.map(d => d?.priority)?.filterNulls()?.last() ?? 0) + 1;
    }
  }

  public updateDisplayPriorities = (updatedCopies: Display[]): Observable<boolean> => {
    return this.allDisplays$.pipe(
      take(1),
      switchMap(displays => {
        // update priority for all displays if changed from original
        const updateTheseDisplays: Display[] = updatedCopies?.filter(updatedDisplay => {
          const existingDisplay = displays?.find(existing => existing?.id === updatedDisplay?.id);
          return !!existingDisplay && (existingDisplay?.priority !== updatedDisplay?.priority);
        }) || [];
        const updateDisplays$ = this.displayDomainModel
          .updateDisplayPriorities(updateTheseDisplays)
          .pipe(map(updated => updated?.length > 0));
        return updateTheseDisplays?.length ? updateDisplays$ : of(true);
      })
    );
  };

  toggleAddNewDisplayMechanism() {
    this.addNewDisplaySignal.next(true);
  }

  openAddDisplayModal() {
    const onClose = (display: Display) => {
      if (display) this.navigationService.navigateToEditDisplay(display);
    };
    ModalAddDisplay.open(this.ngbModal, this.injector, this, onClose);
  }

  trackById(index: number, item: Display) {
    return item?.id;
  }

}
