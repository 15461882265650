import { Injectable } from '@angular/core';
import { EditSectionFormViewModel } from '../../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { HydratedSection } from '../../../../../../../models/menu/dto/hydrated-section';

@Injectable()
export class SpotlightSectionFormViewModel extends EditSectionFormViewModel {

  constructor(
    locationDomainModel: LocationDomainModel
  ) {
    super(locationDomainModel);
  }

  private _productCount = new BehaviorSubject<number>(15);
  public productCount$ = this._productCount as Observable<number>;
  connectToProductCount = (productCount: number) => this._productCount.next(productCount);

  override connectToSection(section: HydratedSection) {
    super.connectToSection(section);
    this.connectToProductCount(section?.rowCount);
  }

}
