<tr
  class="table-item"
  [class.odd]="(index + 1) % 2 !== 0"
  [class.selected]="(viewModel.highlightedSelectedProductGroupId$ | push) === rowData?.id"
  (click)="viewModel.connectToGroupSelected(rowData)">
  <td class="name-container">{{ rowData?.name | doubleDashIfEmpty }}</td>
  <td>{{ productCount$ | push }}</td>
  <td>{{ childVariantCount$ | push }}</td>
  <td class="chev-container">
    <img src="/assets/icons/dark/solid/chevron-right.svg" class="details-icon" alt="details" />
  </td>
</tr>
