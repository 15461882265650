import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuPreviewJobStatus } from '../../../../../../models/utils/dto/menu-preview-job-status-type';

export abstract class StackPrintJobAppliedProductsViewModel {

  protected constructor(protected productDomainModel: ProductDomainModel) {
  }

  public locationVariants$ = this.productDomainModel.currentLocationVariants$;

  protected _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  protected readonly jobStatus$ = this.job$.pipe(
    map(job => job?.getStatusToDisplay()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public noVariantsPlaceholder$ = this.jobStatus$.pipe(
    map(status => {
      const processing = status === MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
      const queued = status === MenuPreviewJobStatus.MenuPreviewJobStatus_Queued;
      return (processing || queued) ? 'Job is processing' : 'No variants applied';
    })
  );

}
