import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-print-job-name-form',
  templateUrl: './print-job-name-form.component.html',
  styleUrls: ['./print-job-name-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobNameFormComponent {

  @Input() dispersedKey: string;
  @Input() mergeKey: string;
  @Input() job: BulkPrintJob;
  @Input() label: string = 'Bulk Print Name';
  @Input() placeholder: string = formatDate(new Date(), 'MMM d, y h:mm a', 'en');
  @Input() viewOnly: boolean;

}
