<div class="sticky-header-container">
  <div class="modal-header d-flex align-items-center">
    <div class="modal-title">Live View: {{ viewModel.title$ | push }}</div>
  </div>
</div>

<div class="modal-body">
  <div class="content-container">
    <div #mainPane class="main-pane">
      <div
        class="main-pane-scaling"
        [style.bottom]="viewModel.verticalCenteringOffset$ | push"
        [style.left]="viewModel.horizontalCenteringOffset$ | push"
        [style.scale]="liveView.scale | push">
        <app-print-menu-live-view
          #liveView
          [printMenu]="viewModel.printMenu$ | push"
          [fitIntoContainer]="mainPane"
          [userSetScale]="viewModel.userSetScale$ | push"
          [selectedPageIndex]="pagination.selectedPageIndex | push"
          (menuHeight)="viewModel.connectToCardHeight($event)"
          (menuWidth)="viewModel.connectToCardWidth($event)"
          (nPages)="viewModel.connectToNPages($event)"
          (smallestScaleValue)="viewModel.connectToSmallestScaleValue($event)">
        </app-print-menu-live-view>
      </div>
    </div>
  </div>
</div>

<div class="sticky-footer-container">
  <lib-reactive-pagination
    #pagination
    [hidden]="viewModel.oneOrNoPages$ | async"
    [forceNTotalPages]="viewModel.nPages$ | async"
    [nPagesInIndicator]="7"
    [showSkipToBeginningAndEnd]="true">
  </lib-reactive-pagination>
  <lib-button-primary class="done-button" (buttonClicked)="close()">Done</lib-button-primary>
</div>
