import { Label } from '../label';

export class ConfirmationOptions {

  // Text
  public title: string = '';
  public bodyText: string = '';
  // CTAs
  public cancelText: string = 'Cancel';
  public cancelButtonClass: string = 'neutral-button';
  public showContinue: boolean = true;
  public continueText: string = 'Do it';
  public continueButtonClass: string = 'destructive-button';
  // Misc
  public label: Label = null;

}
