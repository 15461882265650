<app-menu-details-section [templateMode]="templateMode">
  <app-menu-additional-options
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | async"
    [bindTo]="viewModel.menu$ | async"
    (iFrameHeightChanged)="viewModel.iFrameHeightChanged($event)"
    (iFrameWidthChanged)="viewModel.iFrameWidthChanged($event)">
  </app-menu-additional-options>
</app-menu-details-section>

<app-edit-embedded-url [mergeKey]="mergeKey" [bindTo]="viewModel.menu$ | async" [sharedViewModel]="sharedViewModel" />

<hr class="mt-24px" />
