import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditLabelStackPrintSectionViewModel } from './edit-label-stack-print-section-view-model';
import { BulkPrintJobPollingService } from '../../../menus/print/services/bulk-print-job-polling.service';
import { EditCardStackPrintSectionComponent } from '../../edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section.component';

@Component({
  selector: 'app-edit-label-stack-print-section',
  templateUrl: '../../edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section.component.html',
  providers: [EditLabelStackPrintSectionViewModel, BulkPrintJobPollingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditLabelStackPrintSectionComponent extends EditCardStackPrintSectionComponent {

  constructor(
    public override viewModel: EditLabelStackPrintSectionViewModel,
    protected override bulkPrintJobPollingService: BulkPrintJobPollingService
  ) {
    super(viewModel, bulkPrintJobPollingService);
  }

  @Input() override sectionDescription: string = 'View and manage the print jobs for your label stack.';
  @Input() override editPrintCardMode: boolean = false;
  @Input() override editPrintLabelMode: boolean = true;

}
