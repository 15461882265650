import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../models/company/dto/location';

@Pipe({
  name: 'getLocationsFromProvinceKey'
})
export class GetLocationsFromProvinceKeyPipe implements PipeTransform {

  transform(value: { [provinceName: string]: Location[] }, provinceKey: string): Location[] {
    return value?.[provinceKey];
  }

}
