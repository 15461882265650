import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getMinTerpeneWithUnits'
})
export class GetMinTerpeneWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null, cannabinoid: string): string | null {
    return variant?.getMinTerpeneWithUnits(cannabinoid) || null;
  }

}
