import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../models/menu/dto/section';

@Pipe({
  name: 'isProductSection'
})
export class IsProductSectionPipe implements PipeTransform {

  transform(value: Section): boolean {
    return value?.isProductSection();
  }

}
