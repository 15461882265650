import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditMenuSectionViewModel } from '../../../viewModels/edit-menu-section-view-model';

@Component({
  selector: 'app-edit-menu-section-section-details',
  templateUrl: './edit-menu-section-section-details.component.html',
  styleUrls: [
    './edit-menu-section-section-details.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionSectionDetailsComponent extends BaseComponent {

  constructor(
    public viewModel: EditMenuSectionViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string = 'editSection';
  @Input() title: string = 'Section Details';
  @Input() description: string = 'View and edit basic information about this section.';
  @Input() formPristine: boolean = true;

}
