import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';

@Pipe({
  name: 'printJobMenuSelectionIconSrc'
})
export class PrintJobMenuSelectionIconSrcPipe implements PipeTransform {

  transform(menu: Menu): string {
    switch (true) {
      case menu?.containsStackedContent():
        return 'assets/icons/dark/outline/category-cards.svg';
      case menu?.isPrintReportMenu():
        return 'assets/icons/dark/solid/clipboard-list.svg';
      default:
        return 'assets/icons/dark/outline/document-text.svg';
    }
  }

}
