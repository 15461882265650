import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../models/company/dto/location';

@Pipe({
  name: 'locationMarkedAsRequired'
})
export class LocationMarkedAsRequiredPipe implements PipeTransform {

  transform(location: Location, requiredLocationIds: number[]): boolean {
    return requiredLocationIds?.includes(location?.id);
  }

}
