import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newProductChangeCheckboxDisabledTooltip'
})
export class NewProductChangeCheckboxDisabledTooltipPipe implements PipeTransform {

  transform(viewOnly: boolean): string {
    return viewOnly ? '' : 'New products are always included with smart prints';
  }

}
