import { ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { Product } from 'src/app/models/product/dto/product';
import { AllProductsDataTableViewModel } from '../../all-products-data-table-view-model';
import { SortUtils } from '../../../../../utils/sort-utils';
import { takeUntil } from 'rxjs/operators';
import { PriceFormat } from '../../../../../models/utils/dto/price-format-type';
import { TableBadgeUtils } from '../../../utils/table-badge-utils';

@Component({
  selector: 'app-all-products-table-header',
  templateUrl: './all-products-table-header.component.html',
  styleUrls: ['./all-products-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => AllProductsTableHeaderComponent)
    }
  ]
})
export class AllProductsTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent implements OnDestroy {

  private locationId: number;
  private priceFormat: PriceFormat;

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: AllProductsDataTableViewModel, // global provided by root
  ) {
    super(viewRef);
    this.viewModel.locationId$.pipe(takeUntil(this.onDestroy)).subscribe(locationId => this.locationId = locationId);
    this.viewModel.priceFormat$.pipe(takeUntil(this.onDestroy)).subscribe(stream => this.priceFormat = stream);
  }

  // Name Sort
  public nameAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(a?.getProductTitle(), b?.getProductTitle());
  public nameDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(a?.getProductTitle(), b?.getProductTitle());

  // Price Sort
  public priceAsc = (a: Product, b: Product) => {
    const locationId = this.locationId;
    const companyId = a?.companyId;
    const asc = (c, d) => SortUtils.variantsPriceAsc([c, d], [locationId, companyId], [this.priceFormat, false]);
    a?.variants?.sort(asc);
    a?.variantsFilteredByTable?.sort(asc);
    b?.variants?.sort(asc);
    b?.variantsFilteredByTable?.sort(asc);
    return SortUtils.numberAscNullsLast(
      a?.getLowestPrice(this.locationId, null, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, null, this.priceFormat, false)
    );
  };
  public priceDesc = (a: Product, b: Product) => {
    const locationId = this.locationId;
    const companyId = a?.companyId;
    const desc = (c, d) => SortUtils.variantsPriceDesc([c, d], [locationId, companyId], [this.priceFormat, false]);
    a?.variants?.sort(desc);
    a?.variantsFilteredByTable?.sort(desc);
    b?.variants?.sort(desc);
    b?.variantsFilteredByTable?.sort(desc);
    return SortUtils.numberDescNullsLast(
      a?.getLowestPrice(this.locationId, null, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, null, this.priceFormat, false)
    );
  };

  // Brand Sort
  public brandAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(a?.getBrand(), b?.getBrand());
  public brandDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(a?.getBrand(), b?.getBrand());

  // Type Sort
  public typeAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getProductTypeString(), b?.getProductTypeString());
  };
  public typeDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getProductTypeString(), b?.getProductTypeString());
  };

  // Strain Sort
  public strainAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getStrainClassification(), b?.getStrainClassification());
  };
  public strainDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getStrainClassification(), b?.getStrainClassification());
  };

  // Quantity Sort
  public qtyAsc = (a: Product, b: Product) => {
    a?.variants?.sort(SortUtils.variantsStockAsc);
    a?.variantsFilteredByTable?.sort(SortUtils.variantsStockAsc);
    b?.variants?.sort(SortUtils.variantsStockAsc);
    b?.variantsFilteredByTable?.sort(SortUtils.variantsStockAsc);
    return SortUtils.numericStringAsc(a?.getQuantityInStockString(), b?.getQuantityInStockString());
  };
  public qtyDesc = (a: Product, b: Product) => {
    a?.variants?.sort(SortUtils.variantsStockDesc);
    a?.variantsFilteredByTable?.sort(SortUtils.variantsStockDesc);
    b?.variants?.sort(SortUtils.variantsStockDesc);
    b?.variantsFilteredByTable?.sort(SortUtils.variantsStockDesc);
    return SortUtils.numericStringDesc(a?.getQuantityInStockString(), b?.getQuantityInStockString());
  };

  // THC Sort
  public thcAsc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'THC'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'THC'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'THC'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'THC'));
    const aTHC = a?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMinCannabinoidOrTerpene('THC')
      : v?.getNumericCannabinoidOrTerpene('THC')) || [];
    const bTHC = b?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMinCannabinoidOrTerpene('THC')
      : v?.getNumericCannabinoidOrTerpene('THC')) || [];
    const aMinTHC = Math.min(...aTHC);
    const bMinTHC = Math.min(...bTHC);
    return SortUtils.numberAscNullsLast(aMinTHC, bMinTHC);
  };
  public thcDesc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'THC'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'THC'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'THC'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'THC'));
    const aTHC = a?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMaxCannabinoidOrTerpene('THC')
      : v?.getNumericCannabinoidOrTerpene('THC')) || [];
    const bTHC = b?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMaxCannabinoidOrTerpene('THC')
      : v?.getNumericCannabinoidOrTerpene('THC')) || [];
    const aMaxTHC = Math.max(...aTHC, 0);
    const bMaxTHC = Math.max(...bTHC, 0);
    return SortUtils.numberDescNullsLast(aMaxTHC, bMaxTHC);
  };

  // CBD Sort
  public cbdAsc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'CBD'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'CBD'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'CBD'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, 'CBD'));
    const aCBD = a?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMinCannabinoidOrTerpene('CBD')
      : v?.getNumericCannabinoidOrTerpene('CBD')) || [];
    const bCBD = b?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMinCannabinoidOrTerpene('CBD')
      : v?.getNumericCannabinoidOrTerpene('CBD')) || [];
    const aMinCBD = Math.min(...aCBD);
    const bMinCBD = Math.min(...bCBD);
    return SortUtils.numberAscNullsLast(aMinCBD, bMinCBD);
  };
  public cbdDesc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'CBD'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'CBD'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'CBD'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, 'CBD'));
    const aCBD = a?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMaxCannabinoidOrTerpene('CBD')
      : v?.getNumericCannabinoidOrTerpene('CBD')) || [];
    const bCBD = b?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMaxCannabinoidOrTerpene('CBD')
      : v?.getNumericCannabinoidOrTerpene('CBD')) || [];
    const aMaxCBD = Math.max(...aCBD, 0);
    const bMaxCBD = Math.max(...bCBD, 0);
    return SortUtils.numberDescNullsLast(aMaxCBD, bMaxCBD);
  };

  // Label Sort
  public labelAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );
  public labelDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );

  // Badge Sort
  public badgeAsc = (a: Product, b: Product) => SortUtils.sortBadgesByNameAscending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );
  public badgeDesc = (a: Product, b: Product) => SortUtils.sortBadgesByNameDescending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );

  // Min THC Sort
  public minTHCAsc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    const aTHC = a?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('THC')) || [];
    const bTHC = b?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('THC')) || [];
    const aMinTHC = Math.min(...aTHC);
    const bMinTHC = Math.min(...bTHC);
    return SortUtils.numberAscNullsLast(aMinTHC, bMinTHC);
  };
  public minTHCDesc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    const aTHC = a?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('THC')) || [];
    const bTHC = b?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('THC')) || [];
    const aMinTHC = Math.min(...aTHC);
    const bMinTHC = Math.min(...bTHC);
    return SortUtils.numberDescNullsLast(aMinTHC, bMinTHC);
  };

  // Max THC Sort
  public maxTHCAsc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'THC'));
    const aTHC = a?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('THC')) || [];
    const bTHC = b?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('THC')) || [];
    const aMaxTHC = Math.max(...aTHC, 0);
    const bMaxTHC = Math.max(...bTHC, 0);
    return SortUtils.numberAscNullsLast(aMaxTHC, bMaxTHC);
  };
  public maxTHCDesc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'THC'));
    const aTHC = a?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('THC')) || [];
    const bTHC = b?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('THC')) || [];
    const aMaxTHC = Math.max(...aTHC, 0);
    const bMaxTHC = Math.max(...bTHC, 0);
    return SortUtils.numberDescNullsLast(aMaxTHC, bMaxTHC);
  };

  // Min CBD Sort
  public minCBDAsc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    const aCBD = a?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('CBD')) || [];
    const bCBD = b?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('CBD')) || [];
    const aMinCBD = Math.min(...aCBD);
    const bMinCBD = Math.min(...bCBD);
    return SortUtils.numberAscNullsLast(aMinCBD, bMinCBD);
  };
  public minCBDesc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    const aCBD = a?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('CBD')) || [];
    const bCBD = b?.variants?.map(v => v?.getNumericMinCannabinoidOrTerpene('CBD')) || [];
    const aMinCBD = Math.min(...aCBD);
    const bMinCBD = Math.min(...bCBD);
    return SortUtils.numberDescNullsLast(aMinCBD, bMinCBD);
  };

  // Max CBD Sort
  public maxCBDAsc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, 'CBD'));
    const aCBD = a?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('CBD')) || [];
    const bCBD = b?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('CBD')) || [];
    const aMaxCBD = Math.max(...aCBD, 0);
    const bMaxCBD = Math.max(...bCBD, 0);
    return SortUtils.numberAscNullsLast(aMaxCBD, bMaxCBD);
  };
  public maxCBDesc = (a: Product, b: Product) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, 'CBD'));
    const aCBD = a?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('CBD')) || [];
    const bCBD = b?.variants?.map(v => v?.getNumericMaxCannabinoidOrTerpene('CBD')) || [];
    const aMaxCBD = Math.max(...aCBD, 0);
    const bMaxCBD = Math.max(...bCBD, 0);
    return SortUtils.numberDescNullsLast(aMaxCBD, bMaxCBD);
  };

  // Override Group Sort
  public overrideGroupAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.overrideGroupName, b?.overrideGroupName);
  };
  public overrideGroupDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.overrideGroupName, b?.overrideGroupName);
  };

  initializeFromBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

}
