import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SmartFiltersSectionViewModel extends BaseViewModel {

  private _smartFilterIds = new BehaviorSubject<string[]>([]);
  public smartFilterIds$ = this._smartFilterIds.asObservable();

  setSmartFilterIds(ids: string[]) {
    this._smartFilterIds.next(ids);
  }

}
