import { Injectable, Injector } from '@angular/core';
import { CardStackPreviewViewModel } from '../card-stack-preview/card-stack-preview-view-model';
import { combineLatest, throwError } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { PrintCardPreviewService } from '../../../../../../services/print-card-preview.service';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { MenuDisplayableSubItemService } from '../displayable-item-preview/menu-displayable-sub-item.service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { BsError } from '../../../../../../models/shared/bs-error';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';

@Injectable()
export class CardStackTemplatePreviewViewModel extends CardStackPreviewViewModel {

  constructor(
    protected templateDomainModel: TemplateDomainModel,
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    printCardPreviewService: PrintCardPreviewService,
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    menuSubItemService: MenuDisplayableSubItemService,
    menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      bulkPrintJobDomainModel,
      printCardPreviewService,
      templateCollectionDomainModel,
      menuSubItemService,
      menuPreviewService,
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngbModal,
      injector
    );
  }

  /* ************************************* Drop Down Selections ************************************ */

  public override dropDownMenuSections$ = combineLatest([
    this.displayableItem$,
    this.locationId$
  ]).pipe(
    map(([cardStackMenu, locationId]) => this.getCardStackTemplateDropDownOptions())
  );

  protected getCardStackTemplateDropDownOptions(): DropDownMenuSection[] {
    const dropDownMenuSections = [];
    const sectionItems = [];
    sectionItems.push(new DropDownMenuItem('Edit', DisplayableAction.Edit));
    sectionItems.push(new DropDownMenuItem('Duplicate', DisplayableAction.Duplicate));
    const deleteOpts = new Set<string>().add('red-text');
    sectionItems.push(new DropDownMenuItem('Delete Template', DisplayableAction.Delete, deleteOpts));
    const section = new DropDownMenuSection(null, sectionItems);
    dropDownMenuSections.push(section);
    return dropDownMenuSections;
  }

  /* ************************************* CTAs ************************************ */

  public override openDeleteItemModal() {
    this.displayableItem$.once(menu => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Template';
      opts.bodyText = `Are you sure you want to delete the following card stack template: \'${menu?.name}\''? `
        + `The templated card stack will be deleted from all locations. `
        + `This action cannot be undone.`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmation = (cont: boolean) => cont && this.deleteItem(menu as MenuTemplate);
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  protected override deleteItem(menu: MenuTemplate) {
    const lm = 'Deleting Template';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.templateDomainModel.deleteMenuTemplate(menu).pipe(delay(250)).subscribe({
        next: () => {
          // Add small delay so updated menus can propagate to menus-view-model
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Template deleted successfully.', 'Template Deleted');
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(() => err);
        }
      });
    }
  }

}
