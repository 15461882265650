import { EditMenuSectionViewModel } from '../../../../viewModels/edit-menu-section-view-model';
import { Injectable, Injector } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { BsError } from '../../../../../../models/shared/bs-error';
import { combineLatest, throwError } from 'rxjs';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { DisplayAttributesDomainModel } from '../../../../../../domainModels/display-attributes-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { LabelDomainModel } from '../../../../../../domainModels/label-domain-model';
import { ModalEditPrintCardStyle } from '../../../../../../modals/modal-edit-print-card-style';
import { SmartFiltersDomainModel } from '../../../../../../domainModels/smart-filters-domain-model';
import { StringUtils } from '../../../../../../utils/string-utils';

@Injectable()
export abstract class EditStaticSectionViewModel extends EditMenuSectionViewModel {

  protected constructor(
    templateDomainModel: TemplateDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    companyDomainModel: CompanyDomainModel,
    labelDomainModel: LabelDomainModel,
    locationDomainModel: LocationDomainModel,
    displayAttributeDomainModel: DisplayAttributesDomainModel,
    userDomainModel: UserDomainModel,
    smartFiltersDomainModel: SmartFiltersDomainModel,
    toastService: ToastService,
    router: Router,
    route: ActivatedRoute,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      templateDomainModel,
      menuDomainModel,
      productDomainModel,
      companyDomainModel,
      labelDomainModel,
      locationDomainModel,
      displayAttributeDomainModel,
      userDomainModel,
      smartFiltersDomainModel,
      toastService,
      router,
      route,
      ngbModal,
      injector
    );
  }

  public containsStackedContent$ = this.menu$.pipe(map(menu => menu?.containsStackedContent()));
  public isCardStackMenu$ = this.menu$.pipe(map(menu => menu?.isPrintCardMenu()));
  public isCardLabelMenu$ = this.menu$.pipe(map(menu => menu?.isPrintLabelMenu()));

  public supportsStackStyling$ = this.menu$.pipe(
    map(menu => {
      const themeFeatures = menu?.hydratedTheme?.themeFeatures;
      const themeSupportsCardOpacity = themeFeatures?.backgroundOpacity;
      const themeSupportsAccentColor = themeFeatures?.sectionHeaderBackgroundColor;
      const themeSupportsCardColor = themeFeatures?.sectionBodyBackgroundColor;
      const themeSupportsHeaderTextColor = themeFeatures?.sectionHeaderTextColor;
      const themeSupportsBodyTextColor = themeFeatures?.sectionBodyTextColor;
      return themeSupportsCardOpacity
          || themeSupportsAccentColor
          || themeSupportsCardColor
          || themeSupportsHeaderTextColor
          || themeSupportsBodyTextColor;
    })
  );

  public readonly disabledStackStylingTooltip$ = this.menu$.pipe(
    map(menu => {
      const noun = menu?.whatTypeOfStackIsThis();
      return `This ${noun} stack theme does not support any ${noun} styling options.`;
    })
  );

  public readonly stackStylingButtonText$ = this.menu$.pipe(
    map(menu => `View ${StringUtils.capitalize(menu?.whatTypeOfStackIsThis())} Styling`)
  );

  public saveStaticSectionMenu(background: boolean = true): void {
    combineLatest([
      this.menu$,
      this.section$
    ]).once(([menu, section]) => {
      let noun: string;
      if (menu.isSpotlightMenu()) {
        noun = menu instanceof MenuTemplate ? 'Spotlight Template' : 'Spotlight Menu';
      } else if (menu.isPrintCardMenu()) {
        noun = menu instanceof MenuTemplate ? 'Card Stack Template' : 'Card Stack';
      } else if (menu.isPrintLabelMenu()) {
        noun = menu instanceof MenuTemplate ? 'Label Stack Template' : 'Label Stack';
      }
      const lm = `Saving ${noun}`;
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const saveMenu$ = (menu instanceof MenuTemplate)
          ? this.templateDomainModel.saveMenuTemplate(menu)
          : this.menuDomainModel.saveMenu(menu);
        saveMenu$.pipe(
          switchMap(() => {
            return (section instanceof SectionTemplate)
              ? this.templateDomainModel.updateMenuSectionTemplate(section)
              : this.menuDomainModel.updateMenuSection(section);
          }),
          take(1),
        ).subscribe({
          complete: () => {
            loadingOpts.removeRequest(lm);
            if (!background) {
              this.toastService.publishSuccessMessage(`${noun} successfully updated.`, 'Menu Updated');
            }
            this.setLastAutoSavedTimestampToNow();
            this.destroyAllTimerSubs();
          },
          error: (error: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  }

  public viewStackStyling(): void {
    this.menu$.once(menu => {
      ModalEditPrintCardStyle.open(this.ngbModal, this.injector, menu);
    });
  }

  /* *************************** Local Threads of Execution *************************** */

  protected firstLoad: boolean = true;
  private listenToMenuToLoadSection = this.fireIfRegularSectionMode$
    .pipe(switchMap(() => this.menu$))
    .notNull()
    .subscribeWhileAlive({
      owner: this,
      next: menu => {
        const menuId = menu?.id;
        const sectionId = menu?.sections?.firstOrNull()?.id;
        const backgroundLoad = !this.firstLoad;
        this.menuDomainModel.selectActiveHydratedSection(sectionId);
        this.firstLoad = false;
      }
    });

  private listenToMenuTemplateToLoadSectionTemplate = this.fireIfTemplateMode$
    .pipe(switchMap(() => this.menu$))
    .notNull()
    .subscribeWhileAlive({
      owner: this,
      next: menu => {
        if (menu instanceof MenuTemplate) {
          const menuTemplateId = menu?.id;
          const sectionTemplateId = menu?.templateSections?.firstOrNull()?.id;
          const backgroundLoad = !this.firstLoad;
          this.templateDomainModel.selectActiveSectionTemplate(sectionTemplateId);
          this.firstLoad = false;
        }
      }
    });

  /* ********************************************************************************** */

  // Overriding this method from edit-menu-section-view-model.ts
  protected override loadHydratedMenuTemplateUponInitialization() {
    // Do nothing - edit-menu-section-view-model will initialize and call this same logic.
    // If implemented in here, the API will be hit twice to fetch the same template.
  }

  // Overriding this method from edit-menu-section-view-model.ts
  protected override navigateAwayFromEditSectionIfSectionDoesntBelongToMenu() {
    // Do nothing - there is only 1 section for spotlight menus, so it can't be specified
    // in the URL, therefore, we don't have to worry about the menu and section being mismatched.
    // If this isn't empty, then when the user duplicates a spotlight menu, it will
    // fire and mess up the navigation of the duplicate action.
  }

}
