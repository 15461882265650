import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HasChildIds } from '../../../../../../../../../models/protocols/has-child-ids';

@Component({
  selector: 'app-card-stack-print-job-select-all-products',
  templateUrl: './card-stack-print-job-select-all-products.component.html',
  styleUrls: ['../stack-print-job-products-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackPrintJobSelectAllProductsComponent {

  @Input() selection: HasChildIds;
  @Input() selectedIds: string[];
  @Output() bulkAddClicked = new EventEmitter<string[]>();
  @Output() bulkRemoveClicked = new EventEmitter<string[]>();

}
