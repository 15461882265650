import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateCreationType } from '../../../../../../../models/enum/shared/template-creation-type.enum';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-template-creation-method',
  templateUrl: './template-creation-method.component.html',
  styleUrls: ['./template-creation-method.component.scss']
})
export class TemplateCreationMethodComponent extends BaseComponent {

  @Input() paddingLeft: string = '1.5rem';
  @Input() paddingRight: string = '1.5rem';
  @Input() titleFontSize: string = '0.75rem';
  @Input() subtitleFontSize: string = '0.75rem';
  @Output() selectedCreationType = new EventEmitter<TemplateCreationType>();

  public creationTypes$ = new BehaviorSubject<any[]>(this.getTemplateCreationTypes());

  constructor() {
    super();
  }

  getTemplateCreationTypes(): any[] {
    const list = [];
    list.push({
      type: TemplateCreationType.Existing,
      title: 'Create from existing menu',
      subtitle: 'Use an existing menu to create a new template'
    });
    list.push({
      type: TemplateCreationType.New,
      title: 'Create new template',
      subtitle: 'Start from scratch to create a new template'
    });
    return list;
  }

}
