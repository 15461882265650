import { Component } from '@angular/core';
import { DigitalProductMenusViewModel } from '../digital-product-menus-view-model';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';

@Component({
  selector: 'app-digital-product-menu-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './digital-product-menu-list.component.scss'
  ]
})
export class DigitalProductMenuListComponent extends DisplayableItemListComponent {

  constructor(
    digitalProductMenusViewModel: DigitalProductMenusViewModel // Provided by parent component
  ) {
    super(digitalProductMenusViewModel);
  }

}
