<div
  class="card-deck"
  [class.horizontal-scroller]="horizontalScroller"
  [ngStyle]="{ 'max-height': getMaxHeightRem() }"
  [style.row-gap]="rowGap"
  [style.column-gap]="columnGap">
  <ng-container *rxFor="let card of cards; let first = first; let last = last; trackBy: 'id'">
    <!--  Default Card  -->
    <app-card *ngIf="card.cardStyle === cardStyles.Default" [card]="card" (click)="handleCardPress(card)"> </app-card>
    <!--  Incomplete Product Card  -->
    <app-incomplete-product-card
      (click)="handleCardPress(card)"
      *ngIf="card.cardStyle === cardStyles.IncompleteProduct"
      [card]="card">
    </app-incomplete-product-card>
    <app-location-card
      class="app-location-card"
      (click)="handleCardPress(card)"
      *ngIf="card.cardStyle === cardStyles.Location"
      [card]="card">
    </app-location-card>
    <app-quick-action-card
      *ngIf="card.cardStyle === cardStyles.QuickAction"
      [ngClass]="{ 'quick-action-card-size': true }"
      [card]="card"
      (click)="handleCardPress(card)">
    </app-quick-action-card>
    <app-theme-preview-card
      *ngIf="card.cardStyle === cardStyles.ThemePreview"
      (cardButtonClicked)="cardButtonPressed.emit(card)"
      (click)="handleCardPress(card)"
      [card]="card"
      [id]="card.id">
    </app-theme-preview-card>
    <app-theme-carousel-image
      *ngIf="card.cardStyle === cardStyles.ThemeCarouselImage"
      [card]="card"
      [id]="card.id"
      (click)="handleCardPress(card)">
    </app-theme-carousel-image>
    <app-card-stack-size-carousel
      *ngIf="card.cardStyle === cardStyles.PrintCardSizeCarouselImage"
      [card]="card"
      [first]="first"
      [id]="card.id"
      [last]="last"
      (click)="handleCardPress(card)">
    </app-card-stack-size-carousel>
  </ng-container>
</div>
