import { TypeDefinition } from './type-definition';

export enum CannabisUnitOfMeasure {
  Percent = '%',
  MilliGram = 'mg',
  MilliGramPerGram = 'mg/g',
  MilliGramPerMilliLitre = 'mg/ml',
  NA = 'N/A',
  UNKNOWN = '',
  MULTIPLE_VALUES = 'MULTIPLE_VALUES'
}

export class CannabisUnitOfMeasureType extends TypeDefinition {

  override value: CannabisUnitOfMeasure;
  hiddenSelectionOption = this.value === CannabisUnitOfMeasure.MULTIPLE_VALUES;

  override getSelectionTitle(): string {
    if (this.value === CannabisUnitOfMeasure.MULTIPLE_VALUES) return 'Multiple Selected';
    return super.getSelectionTitle();
  }

}
