import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LabelHierarchyBannerViewModel } from './label-hierarchy-banner-view-model';
import { Label } from '../../../models/shared/label';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditLabelPriority } from '../../../modals/modal-edit-label-priority';

@Component({
  selector: 'app-label-hierarchy-banner',
  templateUrl: './label-hierarchy-banner.component.html',
  styleUrls: ['./label-hierarchy-banner.component.scss'],
  providers: [LabelHierarchyBannerViewModel]
})
export class LabelHierarchyBannerComponent implements OnChanges {

  // Styling
  @Input() bannerColor: string = '#F2F2F2';
  @Input() textColor: string = '#222222';
  @Input() useLightIcon: boolean = false;
  // allowUnsetPriority: can only ever be true for company tab where location overrides are enabled
  @Input() allowUnsetPriority: boolean = false;

  // Content
  @Input() showEditHierarchyButton: boolean = false;
  @Input() message: string = 'A product may only have one label visible. If multiple labels exist on a \n'
    + 'product, they will be applied based on the following order:';
  @Input() labels: Label[];
  @Input() activeLabelStyle: LabelStyle = LabelStyle.Default;
  @Input() activeSaleLabelFormat: SaleLabelFormat = SaleLabelFormat.SALE;

  constructor(
    public viewModel: LabelHierarchyBannerViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labels) this.viewModel.connectToLabels(this.labels);
    if (changes.activeLabelStyle) this.viewModel.connectToActiveLabelStyle(this.activeLabelStyle);
    if (changes.activeSaleLabelFormat) this.viewModel.connectToActiveSaleLabelFormat(this.activeSaleLabelFormat);
    if (changes.allowUnsetPriority) this.viewModel.connectToAllowUnsetPriority(this.allowUnsetPriority);
  }

  public openEditLabelHierarchyModal() {
    ModalEditLabelPriority.open(
      this.ngbModal,
      this.injector,
      this.labels,
      this.allowUnsetPriority,
      this.activeLabelStyle,
      this.activeSaleLabelFormat,
      (labels: Label[]): void => this.viewModel.updateLabels(labels)
    );
  }

}
