import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-property-changed',
  templateUrl: './table-property-changed.component.html',
  styleUrls: ['./table-property-changed.component.scss']
})
export class TablePropertyChangedComponent {

  @Input() oldValue: string;

}
