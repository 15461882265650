<div class="sticky-body-container">
  <app-arrow-button
    *rxFor="let option of bulkPrintJobTypeOptions$"
    [title]="option.title"
    [subtitle]="option.subtitle"
    [titleFontSize]="'0.75rem'"
    [subtitleFontSize]="'0.75rem'"
    [titleFontWeight]="'500'"
    [subtitleFontWeight]="'400'"
    (clicked)="bulkPrintJobTypeSelected.emit(option.type)">
  </app-arrow-button>
</div>
