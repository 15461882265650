import { Pipe, PipeTransform } from '@angular/core';
import { StackManualBulkPrintJobMenuListViewModel } from './stack-manual-bulk-print-job-menu-list-view-model';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'nSelectedVariants'
})
export class NSelectedVariantsPipe implements PipeTransform {

  transform(viewModel: StackManualBulkPrintJobMenuListViewModel, cardStackMenu: Menu): Observable<number> {
    return viewModel?.nSelectedVariants$(cardStackMenu) || of(0);
  }

}
