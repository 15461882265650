import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { Section } from '../models/menu/dto/section';
import { SectionTemplate } from '../models/template/dto/section-template';
import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from './modal-confirmation';

export class ModalPromptForProductGroupingDelete {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    section: Section,
    confirmation: (cont: boolean) => void
  ): void {
    const isTemplate = section instanceof SectionTemplate;
    const opts = new ConfirmationOptions();
    opts.title = isTemplate ? 'Delete Product Group Template' : 'Delete Product Group';
    opts.bodyText = `You are about to delete this product group: ${section?.getSectionTitle()}? `
      + `This action cannot be undone.`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete';
    ModalConfirmation.open(ngbModal, injector, opts, confirmation);
  }

}
