import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { ProductsNavItem } from '../models/products-nav-item';

@Pipe({
  name: 'isProductsNavItem'
})
export class IsProductsNavItemPipe implements PipeTransform {

  transform(value: NavItem): boolean {
    return value instanceof ProductsNavItem;
  }

}
