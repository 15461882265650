<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<app-add-menu-or-collection-modal-header
  [stickyCollectionKey]="'add-menu-or-collection'"
  [stickyZ]="9"
  [stickyOrder]="0"
  [title]="viewModel.title$ | push">
</app-add-menu-or-collection-modal-header>

<app-choose-display-content-option
  *rxIf="viewModel.contentStepIsChooseOption$"
  (contentOptionSelected)="viewModel.connectToDisplayContentOption($event)"
  (cancel)="cancel()">
</app-choose-display-content-option>

<app-select-menu-or-collection
  *rxIf="viewModel.contentStepIsSelectContent$"
  [collectionMode]="viewModel.collectionMode$ | push"
  [display]="viewModel.display$ | push"
  [displayContentOption]="viewModel.displayContentOption$ | push"
  [emptyStateText]="viewModel.emptyStateText$ | push"
  [itemIdsToAdd]="viewModel.itemsIdsToAdd$ | push"
  [itemsToSearch]="viewModel.itemsToSearch$ | push"
  [searchPlaceholder]="viewModel.searchPlaceholder$ | push"
  (cancel)="cancel()"
  (itemClicked)="viewModel.handleItemClick($event)">
</app-select-menu-or-collection>

<app-go-back-modal-footer
  [primaryButtonText]="'Apply'"
  [showBackButton]="viewModel.showBackButton$ | push"
  [showPrimaryButton]="viewModel.contentStepIsSelectContent$ | push"
  [disablePrimaryButton]="viewModel.disablePrimaryButton$ | push"
  (cancel)="cancel()"
  (primaryButtonClicked)="viewModel.handleAddItems()"
  (goBack)="viewModel.goBack()">
</app-go-back-modal-footer>
