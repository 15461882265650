import { Deserializable } from '../../protocols/deserializable';
import { CannabisUnitOfMeasure } from '../../utils/dto/cannabis-unit-of-measure-type';

export class LotInfo implements Deserializable {

  public packageId: string;
  public batchId: string;
  public supplierName: string;
  public testingFacilityName: string;
  public terpeneProfile: string;
  public receivingDate: number;
  public packagedDate: number;
  public harvestDate: number;
  public lastUpdated: number;
  public catalogItemId: string;
  public THC: string;
  public CBD: string;
  public minTHC: string;
  public maxTHC: string;
  public minCBD: string;
  public maxCBD: string;
  public cannabisUnitOfMeasure: CannabisUnitOfMeasure;
  // TODO - KFFT - terpeneUnitOfMeasure?

  onDeserialize() {
  }

}

