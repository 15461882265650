<app-loading
  [hidden]="!(combinedLoadingOptsViewModel.isLoading$ | async)"
  [options]="combinedLoadingOptsViewModel.loadingOpts$ | async">
</app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="mergeAndDispersedKey"
  (formsAutoSubmitted$)="viewModel.saveTemplate(true)"
  (formsSubmitted$)="viewModel.saveTemplate(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-container>
  <app-edit-template-label-stack-menu-header
    [headerText]="'Edit Template - Label Stack'"
    [crumbs]="viewModel.crumbs$ | async"
    [unsavedChanges]="formCapture.unsavedChanges$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    (submitForms)="viewModel.saveIntercept($event)">
  </app-edit-template-label-stack-menu-header>

  <app-edit-template-locations-section
    [mergeKey]="mergeAndDispersedKey"
    [template]="viewModel.activeTemplate$ | async"
    (changedRequiredTemplateIds)="viewModel.connectToChangedRequiredTemplateIds($event)">
  </app-edit-template-locations-section>

  <app-edit-label-stack-menu-body
    [templateMode]="true"
    [mergeKey]="mergeAndDispersedKey"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [unsavedChanges]="formCapture.unsavedChanges$ | push"
    (sortedVariantIds)="viewModel.connectToSortedVariantIds($event)">
  </app-edit-label-stack-menu-body>

  <app-edit-template-label-stack-menu-footer
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    (submitForms)="viewModel.saveIntercept($event)">
  </app-edit-template-label-stack-menu-footer>
</app-edit-menu-container>
