import { Pipe, PipeTransform } from '@angular/core';
import type { Menu } from '../../../models/menu/dto/menu';

/**
 * Stacked content means something that can be printed, cut out, and stacked together like a deck of cards.
 */
@Pipe({
  name: 'containsStackedContent'
})
export class ContainsStackedContentPipe implements PipeTransform {

  transform(menu: Menu): boolean {
    return menu?.containsStackedContent() ?? false;
  }

}
