import { Deserializable } from '../../protocols/deserializable';

export class MediaForm implements Deserializable {

  public interval: number;
  public mediaLength: number;
  public loop: number;
  public date: string;
  public enabled: boolean;

  constructor(mediaDuration?: number, interval?: number, date?: string) {
    this.interval = interval;
    this.date = date;
    const loopCount = interval / mediaDuration;
    const durationIsRotationLength = Number.isInteger(loopCount);
    if (mediaDuration > 0 && !durationIsRotationLength) {
      this.interval = mediaDuration;
      this.loop = 1;
    } else {
      this.loop = loopCount;
    }
    this.enabled = this.interval > 0;
  }

  onDeserialize() {
  }

}
