import { EditSectionFormViewModel } from '../edit-section-form/edit-section-form-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { DropDownItem } from '../../../../../../models/shared/stylesheet/drop-down-item';
import { ImageObjectFit } from '../../../../../../models/enum/shared/image-object-fit';
import { distinctUntilChanged } from 'rxjs/operators';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';

@Injectable()
export class EditMediaSectionFormViewModel extends EditSectionFormViewModel {

  constructor(
    locationDomainModel: LocationDomainModel
  ) {
    super(locationDomainModel);
  }

  private _showRepeat = new BehaviorSubject<boolean>(false);
  public showRepeat$ = this._showRepeat.pipe(distinctUntilChanged());
  connectToShowRepeat = (percentage: string) => this._showRepeat.next((Number.parseInt(percentage, 10) ?? 100) < 100);

  public readonly mediaAlignments$ = of([
    new DropDownItem('Left', 'left'),
    new DropDownItem('Center', 'center'),
    new DropDownItem('Right', 'right')
  ]);

  public readonly objectFitTypes$ = of([
    new DropDownItem('Cover', ImageObjectFit.Cover),
    new DropDownItem('Contain', ImageObjectFit.Contain)
  ]);

  override connectToSection(section: HydratedSection) {
    super.connectToSection(section);
    this.connectToShowRepeat(section?.metadata?.width);
  }

}
