<div class="tabs-style">
  <!-- dynamicHeight makes tabs nested scrollable -->
  <mat-tab-group
    #tabGroup
    dynamicHeight
    [selectedIndex]="currentSelectedTab"
    (selectedIndexChange)="tabSelected($event)"
    animationDuration="0ms"
    [class.hide-tab-header]="hideTabBarHeader"
    class="h100p">
    <mat-tab
      *ngFor="let tab of tabs; let tabIndex = index"
      class="mat-tab-fill-height"
      [disabled]="tab.disabled || !canChangeTabs">
      <ng-template mat-tab-label>
        <img *ngIf="tab.iconSrc" alt="" class="tab-icon" height="20px" src="{{ tab.iconSrc }}" width="20px" />
        <span [ngbTooltip]="tab.disabled ? tab.disabledTooltip : ''" [container]="'body'">{{ tab.title }}</span>
        <span
          *ngIf="tab?.incompleteProperties > 0 && !tab.disabled"
          [ngbTooltip]="
            tab?.incompleteProperties +
            ' incomplete' +
            (tab.incompleteProperties === 1 ? ' property.' : ' properties.') +
            ' Required properties are denoted with a *'
          "
          [container]="'body'"
          class="alert-indicator">
        </span>
      </ng-template>
      <!-- matTabContent allows for lazy loading of tabs -->
      <ng-template class="tab-item-content" matTabContent>
        <div class="mt-3" *ngIf="!canChangeTabs">
          <app-message-banner [message]="canChangeTabsErrorMsg"> </app-message-banner>
        </div>
        <app-loading
          *ngIf="showLoadingSpinnerOnTabChange"
          [hidden]="!(isLoading$ | async)"
          [options]="loadingOpts$ | async">
        </app-loading>
        <app-tab-content
          [showContent]="!(isLoading$ | async)"
          [tab]="tab"
          [index]="tabIndex"
          [selectedTabIndex]="currentSelectedTab"
          [useDefaultHeight]="useDefaultHeight">
        </app-tab-content>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
