import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, defer, Observable, throwError } from 'rxjs';
import { Asset } from '../../../../../models/image/dto/asset';
import { Menu } from '../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { delay, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { BsError } from '../../../../../models/shared/bs-error';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ColorUtils } from '../../../../../utils/color-utils';
import { LoadingSpinnerSize } from '../../../../../models/enum/shared/loading-spinner-size.enum';
import { EditMarketingMenuViewModel } from '../../../viewModels/edit-marketing-menu-view-model';
import { DistinctUtils } from '../../../../../utils/distinct-utils';

@Injectable()
export class SectionMediaViewModel extends BaseViewModel {

  constructor(
    protected menuDomainModel: MenuDomainModel,
    protected toastService: ToastService,
  ) {
    super();
    this.resetMediaReplacementSwitchAfterFileUpload();
  }

  private sharedViewModel: EditMarketingMenuViewModel;

  private _isDisabledByTemplates = new BehaviorSubject<boolean>(false);
  public isDisabledByTemplates$ = this._isDisabledByTemplates as Observable<boolean>;
  connectToIsDisabledByTemplates = (isDisabledByTemplates: boolean) => {
    this._isDisabledByTemplates.next(isDisabledByTemplates);
  };

  private _file = new BehaviorSubject<Asset>(null);
  public file$ = this._file as Observable<Asset>;
  connectToFile = (file: Asset) => this._file.next(file);
  public fileIsUploaded$ = this.file$.pipe(
    map(file => !!file)
  );

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;
  connectToSection = (section: HydratedSection) => this._section.next(section);
  public sectionId$ = this.section$.pipe(map(section => {
    return section?.isTemplatedSection() ? section?.templateSectionId : section?.id;
  }));

  private _mediaDuration = new BehaviorSubject<number>(0);
  public mediaDuration$ = this._mediaDuration as Observable<number>;

  // Loading
  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(this.getBaseLoadingOpts());
  protected _refreshingMediaLoadingOpts = new BehaviorSubject<LoadingOptions>(this.getRefreshMediaLoadingOpts());
  refreshingMediaLoadingOpts$ = defer(() => this._refreshingMediaLoadingOpts);
  isRefreshingMediaLoading$ = this.refreshingMediaLoadingOpts$.pipe(map(it => it?.isLoading), startWith(false));

  private _replaceMediaSwitch = new BehaviorSubject<boolean>(false);
  public replaceMediaSwitch$ = this._replaceMediaSwitch as Observable<boolean>;

  public groupMediaEnabled$ = this.section$.pipe(
    map(section => !section?.hideSection)
  );

  // noinspection JSMethodCanBeStatic
  private getBaseLoadingOpts(): LoadingOptions {
    const loadingOpts = LoadingOptions.default();
    loadingOpts.backgroundColor = ColorUtils.BUDSENSE_OFF_WHITE_COLOR;
    loadingOpts.color = '#2c4058';
    loadingOpts.spinnerColor = '#2c4058';
    loadingOpts.cornerRadiusRem = 0.25;
    loadingOpts.zIndex = 50;
    return loadingOpts;
  }

  // noinspection JSMethodCanBeStatic
  private getRefreshMediaLoadingOpts(): LoadingOptions {
    const refreshingMediaLoadingOpts = LoadingOptions.default();
    refreshingMediaLoadingOpts.showLoadingText = false;
    refreshingMediaLoadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    refreshingMediaLoadingOpts.backgroundColor = 'rgba(255, 255, 255, 0.5)';
    refreshingMediaLoadingOpts.color = '#2c4058';
    refreshingMediaLoadingOpts.spinnerColor = '#2c4058';
    return refreshingMediaLoadingOpts;
  }

  initSharedViewModel(vm: EditMarketingMenuViewModel) {
    this.sharedViewModel = vm;
  }

  setMediaDuration(dur: number) {
    let mediaDuration: number = 0;
    if (dur % 1 === 0) {
      // subtracting 0.1 in the case the duration is whole
      mediaDuration = dur - 0.1;
    } else if (!!dur) {
      mediaDuration = Math.floor(dur);
    }
    this._mediaDuration.next(mediaDuration);
  }

  public refreshImage() {
    const lm = 'Refreshing Media';
    if (!this._refreshingMediaLoadingOpts.containsRequest(lm)) {
      this._refreshingMediaLoadingOpts.addRequest(lm);
      this.file$.pipe(
        switchMap(file => this.menuDomainModel.refreshImage(file)),
        delay(500)
      ).subscribe(refreshedImg => {
        this._file.next(refreshedImg);
        const refreshDelay = 2500;
        const refreshCount = 3;
        const removeRequest = () => this._refreshingMediaLoadingOpts.removeRequest(lm);
        setTimeout(removeRequest, (refreshDelay * refreshCount));
      }, (error: BsError) => {
        this._refreshingMediaLoadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  toggleReplaceMediaSwitch() {
    this.replaceMediaSwitch$.once(replaceMediaSwitch => {
      this._replaceMediaSwitch.next(!replaceMediaSwitch);
    });
  }

  private resetMediaReplacementSwitchAfterFileUpload() {
    this.file$.notNull().pipe(pairwise()).subscribeWhileAlive({
      owner: this,
      next: ([prev, curr]) => {
        if (!DistinctUtils.distinctUniquelyIdentifiable(prev, curr)) this._replaceMediaSwitch.next(false);
      }
    });
  }

}
