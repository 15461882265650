<app-title-and-crumbs [headerText]="headerText" [crumbs]="crumbs">
  <lib-button-inline-text
    *ngIf="menu?.templateId && userIsTemplateAdmin"
    class="mt-16px"
    (buttonClicked)="editTemplateClicked.emit(menu?.template)">
    Edit Template
  </lib-button-inline-text>
</app-title-and-crumbs>

<div class="edit-page-header">
  <div class="edit-page-header-name-container" fxFlex>
    <app-edit-menu-header-name-and-active
      [menu]="menu"
      [mergeKey]="mergeKey"
      [formPristine]="formPristine"
      [disableActiveToggle]="disableActiveToggle"
      [toggleActiveState]="toggleActiveState">
    </app-edit-menu-header-name-and-active>
    <app-header-subtext>
      {{ headerSubtext }}
    </app-header-subtext>
  </div>

  <app-edit-menu-header-actions
    [allowLiveView]="allowLiveView"
    [autoSaveLoadingOpts]="autoSaveLoadingOpts"
    [disableSave]="disableSave"
    [hideLiveViewButton]="hideLiveViewButton"
    [lastSavedTimeText]="lastSavedTimeText"
    [formHasErrors]="formHasErrors"
    [formDisableSubmit]="formDisableSubmit"
    [formPristine]="formPristine"
    [formAutoSubmitted]="formAutoSubmitted"
    [menu]="menu"
    [showLiveView]="showLiveView"
    [showSaveButton]="showSaveButton"
    [formErrorMessages]="formErrorMessages"
    (submitForms)="submitForms.emit($event)">
    Action Buttons
  </app-edit-menu-header-actions>
</div>
