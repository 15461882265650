<app-arrow-button
  *ngFor="let creationType of creationTypes$ | async"
  (clicked)="selectedCreationType.emit(creationType?.type)"
  [paddingLeft]="paddingLeft"
  [paddingRight]="paddingRight"
  [title]="creationType?.title"
  [titleFontSize]="titleFontSize"
  [subtitle]="creationType?.subtitle"
  [subtitleFontSize]="subtitleFontSize">
</app-arrow-button>
