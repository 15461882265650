import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { Observable } from 'rxjs';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import { DisplayLabelInterface } from '../label/display-label-interface';
import { map } from 'rxjs/operators';
import { LabelUtils } from '../utils/label-utils';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';

@Pipe({
  name: 'getDisplayLabelInterfaceForLabelHierarchy'
})
export class GetDisplayLabelInterfaceForLabelHierarchyPipe implements PipeTransform {

  transform(
    label: Label,
    labelComputationData$: Observable<[
      boolean,
      boolean,
      LocationConfiguration,
      CompanyConfiguration,
      LabelStyle,
      SaleLabelFormat
    ]>
  ): Observable<DisplayLabelInterface> {
    return labelComputationData$.pipe(
      map(([
        companySupportsLocationLabels,
        allowUnsetPriority,
        locationConfig,
        companyConfig,
        labelStyle,
        saleLabelFormat
      ]) => {
        return LabelUtils.getDisplayLabelInterfaceForLabelHierarchy(
          companySupportsLocationLabels,
          allowUnsetPriority,
          locationConfig,
          companyConfig,
          label,
          labelStyle,
          saleLabelFormat
        );
      })
    );
  }

}
