<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ title }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div class="information-item" *ngFor="let item of informationItems">
      <div class="information-item-name">
        {{ item.name }}
      </div>
      <div class="information-item-description">
        {{ item.description }}
      </div>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button preferred-button">Done</button>
  </div>
</div>
