import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { LocationDisplayGrouping } from '../../../../../../models/display/dto/display';
import { EditCollectionLocationListItemViewModel } from './edit-collection-location-list-item-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-collection-location-list-item',
  templateUrl: './edit-collection-location-list-item.component.html',
  styleUrls: ['./edit-collection-location-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EditCollectionLocationListItemViewModel]
})
export class EditCollectionLocationListItemComponent extends BaseComponent {

  constructor(public viewModel: EditCollectionLocationListItemViewModel) {
    super();
  }

  @Input() province: string;
  @Input() @ViewModelConnector('connectToLocationArray') locationDisplays: LocationDisplayGrouping[];
  @Input() originalRequiredDisplayIds: string[];
  @Input() updatedRequiredDisplayIds: string[];
  @Input() collectionIsPublished: boolean;
  @Output() provinceClicked = new EventEmitter<{[key: string]: LocationDisplayGrouping[]}>(true);
  clicked = () => this.provinceClicked.emit({[this.province]: this.locationDisplays});

}
