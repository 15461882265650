import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { User } from '../../../../models/account/dto/user';

@Component({
  selector: 'app-employee-list-item',
  templateUrl: './employee-list-item.component.html',
  styleUrls: ['./employee-list-item.component.scss']
})
export class EmployeeListItemComponent extends BaseComponent {

  @Input() employee: User;
  @Input() hideRemoveButton: boolean = false;
  @Output() removeClicked = new EventEmitter<User>();

  constructor() {
    super();
  }

}
