import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StackManualBulkPrintJobViewModel } from '../stack-manual-bulk-print-job-view-model';

@Component({
  selector: 'app-stack-manual-bulk-print-job-left-pane',
  templateUrl: './stack-manual-bulk-print-job-left-pane.component.html',
  styleUrls: ['./stack-manual-bulk-print-job-left-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackManualBulkPrintJobLeftPaneComponent {

  constructor(
    public viewModel: StackManualBulkPrintJobViewModel
  ) {
  }

}
