import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-smart-menu-indicator',
  templateUrl: './smart-menu-indicator.component.html',
  styleUrls: ['./smart-menu-indicator.component.scss']
})
export class SmartMenuIndicatorComponent {

  @Input() tooltipText: string = 'This menu contains sections that use smart filters.';

}
