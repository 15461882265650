<div class="display-item">
  <div class="d-flex flex-column">
    <div class="display-name">
      {{ display | displayTitle : location }}
      <span *ngIf="required" class="asterisk"> *</span>
    </div>
    <ng-container *ngIf="location">
      <div class="location-address">{{ location.address }}</div>
      <div class="location-city">{{ location.getCityProv() }}</div>
    </ng-container>
  </div>
  <div *ngIf="displayStatusText" class="is-pending" [style.min-width]="'fit-content'">
    {{ displayStatusText }}
  </div>
</div>
