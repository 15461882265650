export class ArrayUtils {

  // laces two lists together - ie, interlace([0,2,4,6,8,10], [1,3,5,7,9]) = [0,1,2,3,4,5,6,7,8,9]
  static interlace = (zs, ys) => {
    if (zs.length === 0) { return ys; }          // base case, are there any Z's to lace? no? then return Y's
    const  [x, ...xs] = zs;                      // break Z's into first item and everything else
    return [x, ...ArrayUtils.interlace(ys, xs)]; // interlace arrays
  };

  static interlaceThreeLists = (xs, ys, zs) => {
    if (xs.length === 0) {
      return ArrayUtils.interlace(ys, zs); // base case, are there any X's to lace? no? then interlace Y's and Z's
    }
    const [x, ...xss] = xs; // break X's into first item and everything else
    return [x, ...ArrayUtils.interlaceThreeLists(ys, zs, xss)]; // interlace arrays
  };

  static getFractionalChunk = (
    list: any[],
    whichPortion: number,
    fractionAltCode: '½'|'⅓'|'¼'|'⅕'
  ): string[] => {
    const getDenominatorFromAltCode = (altCode: string): number => {
      switch (altCode) {
        case '½': return 2;
        case '⅓': return 3;
        case '¼': return 4;
        case '⅕': return 5;
        default:  return 1;
      }
    };
    const length = list?.length || 0;
    const denominator = getDenominatorFromAltCode(fractionAltCode);
    const startingPoint = (whichPortion - 1) * Math.ceil(length / denominator);
    const endingPoint = whichPortion * Math.ceil(length / denominator);
    return list?.slice(startingPoint, endingPoint) ?? [];
  };

}
