import { Deserializable } from '../../protocols/deserializable';
import { VariantInventory } from './variant-inventory';
import { VariantPricing } from './variant-pricing';
import { DisplayAttribute } from '../../display/dto/display-attribute';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { CompanyConfiguration } from '../../company/dto/company-configuration';
import type { Menu } from '../../menu/dto/menu';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import type { Section } from '../../menu/dto/section';
import { VariantBadge } from './variant-badge';
import { HasId } from '../../protocols/has-id';
import { NumberUtils } from '../../../utils/number-utils';
import { HydratedVariantBadge } from './hydrated-variant-badge';
import { SectionLayoutType } from '../../utils/dto/section-layout-type';
import { VariantPricingTier } from './variant-pricing-tier';
import { PriceUtils } from '../../../utils/price-utils';
import { Label } from '../../shared/label';
import { LabelUtils } from '../../../modules/product-labels/utils/label-utils';
import { SystemLabel } from '../../shared/labels/system-label';
import { StrainClassification } from '../../utils/dto/strain-classification-type';
import { VariantType, VariantTypeDefinition } from '../../utils/dto/variant-type-definition';
import { CannabinoidDisplayType } from '../../utils/dto/cannabinoid-display-type-definition';
import { ProductType } from '../../utils/dto/product-type-definition';
import { CannabisUnitOfMeasure } from '../../utils/dto/cannabis-unit-of-measure-type';
import { UnitOfMeasure } from '../../utils/dto/unit-of-measure-type';
import { PriceFormat } from '../../utils/dto/price-format-type';
import { MenuType } from '../../utils/dto/menu-type-definition';
import { ProviderUtils } from '../../../utils/provider-utils';
import { InventoryProvider } from '../../utils/dto/inventory-provider-type';
import { exists } from '../../../functions/exists';
import { StringUtils } from '../../../utils/string-utils';
import { LocationPromotion } from './location-promotion';
import { TerpeneUnitOfMeasure } from '../../utils/dto/terpene-unit-of-measure-type';
import { SectionColumnConfigSecondaryPricingData } from '../../utils/dto/section-column-config-data-value-type';

export class Variant implements Deserializable, Cachable, HasId, UniquelyIdentifiable {

  static readonly invalidParsedCannabinoidOrTerpene = -1;
  // DTO
  public companyId: number;
  public id: string;
  public catalogItemId: string;
  public catalogSKU: string;
  public barcode: string;
  public name: string;
  public price: number;
  public cost: number;
  public lastModified: number;
  public brand: string;
  public manufacturer: string;
  public description: string;
  public richTextDescription: string;
  public shortDescription: string;
  public terpenes: string;
  public classification: StrainClassification;
  public unitSize: number;
  public unitOfMeasure: UnitOfMeasure;
  public cannabisUnitOfMeasure: CannabisUnitOfMeasure;
  public terpeneUnitOfMeasure: TerpeneUnitOfMeasure;
  public packagedQuantity: number;
  public productType: ProductType;
  public variantType: VariantType;
  public strain: string;
  public THC: string;
  public minTHC: string;
  public maxTHC: string;
  public CBD: string;
  public minCBD: string;
  public maxCBD: string;
  public isMedical: boolean;
  public useCannabinoidRange: boolean;
  public useTerpeneRange: boolean;
  public inventory: VariantInventory;
  public locationId: number;
  public locationPricing: VariantPricing[];
  /**
   * Display attributes aren't stored in an array, but rather they are stored like a linked list.
   * Combinations that linked list can be in:
   * 1. null
   * 2. Location DA, points to ->, null
   * 3. Company DA, points to ->, null (Company DA will always point to null)
   * 4. Location DA, points to ->, Company DA, points to ->, null (Company DA will always point to null)
   */
  public displayAttributes: DisplayAttribute;
  public locationPromotion: LocationPromotion;
  public dateCreated: number;
  public posLabelIds: string[]; // Note the API will pull multiple POS labels, but only one is applied based on priority
  public categoryId: string;
  public vendorId: string;
  public brandId: string;
  public strainId: string;
  public isDiscontinued: boolean;
  // Cache
  public cachedTime: number;
  // Parent Product
  public productId: string;
  public productName: string;

  /** Not from API, used for searching smart filter ignored variants */
  public smartFilterIgnoredVariantSubtitle: string;

  /** Not from API, set within variant pipeline - used with lib-reactive-search-bar for searching variant names */
  public variantTitle: string;

  /**
   * Not returned from API. Set by products pipeline inside product domain model.
   * This gets set at the highest level in the waterfall of data, so any pipeline listening
   * to the product domain model will have access to this computed label.
   */
  public computedLabelForProductTable: Label;

  /**
   * Need this property for Fuse.js to include labels in searches.
   */
  public computedLabelTextForVariantSearch: string;

  /**
   * Not returned from API. Computed within edit menu section. Only accessible within edit product section.
   */
  public computedLabelForMenuSection: Label;

  static buildArrayCacheKey(companyId, locationId: number, productId: string): string {
    return `Variants-${companyId}-${locationId}-${productId}`;
  }

  static buildCacheKey(companyId, locationId: number, variantId: string): string {
    return `Variant-${companyId}-${locationId}-${variantId}`;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.inventory = Deserialize?.instanceOf(VariantInventory, this.inventory);
    this.locationPricing = Deserialize?.arrayOf(VariantPricing, this.locationPricing);
    this.displayAttributes = Deserialize?.instanceOf(DisplayAttribute, this.displayAttributes);
    this.locationPromotion = Deserialize?.instanceOf(LocationPromotion, this.locationPromotion);
    this.computedLabelForProductTable = Deserialize?.instanceOf(Label, this.computedLabelForProductTable);
    this.computedLabelForMenuSection = Deserialize?.instanceOf(Label, this.computedLabelForMenuSection);
    if (exists(this.description) && !exists(this.richTextDescription)) {
      this.richTextDescription = `<p>${this.description.replace(/\n/g, '<p>$&</p>')}</p>`;
    }
    if (this.isAccessory()) {
      // Set default values for accessories
      this.packagedQuantity = 1;
    }
    this.terpeneUnitOfMeasure = this.terpeneUnitOfMeasure ?? TerpeneUnitOfMeasure.NA;
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/VariantDTO.go
  public onSerialize() {
    const dto = Object.create(Variant.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.locationId = this.locationId;
    dto.productId = this.productId;
    dto.catalogItemId = this.catalogItemId;
    dto.catalogSKU = this.catalogSKU;
    dto.barcode = this.barcode;
    dto.name = this.name;
    dto.price = this.price;
    dto.cost = this.cost;
    dto.categoryId = this.categoryId;
    dto.vendorId = this.vendorId;
    dto.brandId = this.brandId;
    dto.strainId = this.strainId;
    dto.posLabelIds = this.posLabelIds;
    dto.brand = this.brand;
    dto.manufacturer = this.manufacturer;
    dto.richTextDescription = this.trimRichTextDesc(this.richTextDescription);
    dto.description = this.convertHtmlStringToPlainText(this.richTextDescription);
    dto.shortDescription = this.shortDescription;
    dto.terpenes = this.terpenes;
    dto.classification = this.classification;
    dto.unitSize = this.unitSize;
    dto.unitOfMeasure = this.unitOfMeasure;
    dto.cannabisUnitOfMeasure = this.cannabisUnitOfMeasure;
    dto.terpeneUnitOfMeasure = this.terpeneUnitOfMeasure;
    dto.packagedQuantity = this.packagedQuantity;
    dto.productType = this.productType;
    dto.variantType = this.variantType;
    dto.strain = this.strain;
    dto.THC = this.THC;
    dto.minTHC = this.minTHC;
    dto.maxTHC = this.maxTHC;
    dto.CBD = this.CBD;
    dto.minCBD = this.minCBD;
    dto.maxCBD = this.maxCBD;
    dto.isMedical = this.isMedical;
    dto.useCannabinoidRange = this.useCannabinoidRange;
    dto.useTerpeneRange = this.useTerpeneRange;
    return dto;
  }

  private trimRichTextDesc(richTextDesc: string): string {
    if (!exists(richTextDesc)) return null;
    const temp = document.createElement('div');
    temp.innerHTML = richTextDesc;
    if (!temp.hasChildNodes()) {
      return null;
    }
    const endIndex = temp.childNodes?.length - 1;
    const lastNode = temp?.childNodes?.item(endIndex);
    if (!exists(lastNode?.textContent.trim())) {
      temp.removeChild(lastNode);
      return this.trimRichTextDesc(temp.innerHTML);
    } else {
      return richTextDesc;
    }
  }

  private convertHtmlStringToPlainText(htmlString: string): string {
    if (!exists(htmlString)) return null;
    const temp = document.createElement('div');
    const closingTagsToSpaces = htmlString?.replace(/(?:<li>|<\/li>|<ol>|<\/ol>|<ul>|<\/ul>|<p>|<\/p>)/gm, ' ');
    const openingTagsRemoved = closingTagsToSpaces?.replace(/<[^>]*>?/gm, '');
    temp.innerHTML = openingTagsRemoved?.replace(/\s+/g, ' ').trim();
    return temp.textContent || temp.innerText || '';
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(companyId, locationId: number): string {
    return Variant.buildCacheKey(companyId, locationId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  getId(): string {
    return this.id;
  }

  /* ******************** System Label Checking  *********************** */

  isSaleSystemLabelActive(locationId: number, companyId: number | null, priceFormat: PriceFormat): boolean {
    return this.hasDiscount(locationId, companyId, priceFormat);
  }

  isRestockSystemLabelActive(restockLabel: Label): boolean {
    const currentTimestamp = DateUtils.currentTimestamp();
    const threshold = restockLabel?.timeThreshold * DateUtils.unixOneHour();
    const validRestockUntil = this.inventory?.lastThresholdRestock + threshold;
    const validRestockThreshold = NumberUtils.floatFirstGreaterThanSecond(this.inventory?.lastThresholdRestock, 0);
    const isStocked = NumberUtils.floatFirstGreaterThanSecond(this.inventory?.quantityInStock, 0);
    const withinRestockThreshold = currentTimestamp < validRestockUntil;
    return validRestockThreshold && isStocked && withinRestockThreshold;
  }

  isLowStockSystemLabelActive(lowStockLabel: Label): boolean {
    const quantityInStock = this.inventory?.quantityInStock;
    const threshold = lowStockLabel?.numericThreshold;
    return NumberUtils.floatFirstGreaterThanSecond(quantityInStock, 0)
      && NumberUtils.floatFirstGreaterThanSecond(threshold, quantityInStock);
  }

  isNewSystemLabelActive(newLabel: Label): boolean {
    const threshold = (this.inventory?.firstInventory ?? 0) + ((newLabel?.timeThreshold ?? 0) * DateUtils.unixOneDay());
    return NumberUtils.floatFirstGreaterThanSecond(threshold, DateUtils.currentTimestamp());
  }

  /* ******************************************************** */

  computeLabelPropertyForProductTable(
    locationId: number,
    priceFormat: PriceFormat,
    labels: Label[],
    systemLabels: SystemLabel[]
  ): void {
    this.computedLabelForProductTable = LabelUtils.computeLabelOutsideOfMenuContext(
      [[this], labels, systemLabels],
      [locationId, this.companyId, priceFormat]
    );
    this.computedLabelTextForVariantSearch = this.computedLabelForProductTable?.text;
  }

  computedLabelPropertyForMenuContext(
    [menu, section]: [Menu | null, Section | null],
    [labels, systemLabels]: [Label[], SystemLabel[]],
    [locationId, companyId, priceFormat]: [number, number, PriceFormat]
  ): void {
    this.computedLabelForMenuSection = LabelUtils.computeLabel(
      [menu, section, [this]],
      [labels, systemLabels],
      [locationId, companyId, priceFormat]
    );
  }

  // Incomplete variant

  isIncomplete(useRange: boolean = false, excludeIfOutOfStock: boolean = false): boolean {
    if (!this.inStock() && excludeIfOutOfStock) {
      return false;
    }
    const ipc = this.incompletePropertyCount(undefined, useRange);
    if (this.isAccessory() || this.productType === ProductType.Other) {
      // Never show Accessories or Other as incomplete
      return false;
    } else {
      return ipc > 0;
    }
  }

  incompleteProperties(
    skipShared: boolean = false,
    useRange: boolean = false,
    generalOnly: boolean = false,
    cannabinoidsOnly: boolean = false
  ): string[] {
    const incompleteProperties = [];
    const generalIncompleteProperties = [];
    const cannabinoidIncompleteProperties = [];

    if (this.packagedQuantity === 0 && !this.isNonCannabinoid()) {
      incompleteProperties.push('packagedQuantity');
      generalIncompleteProperties.push('packagedQuantity');
    }
    if (this.brand === '' && !skipShared) {
      incompleteProperties.push('brand');
      generalIncompleteProperties.push('brand');
    }
    if (this.classification?.toString() === '' && !skipShared && !this.isNonCannabinoid()) {
      incompleteProperties.push('classification');
      generalIncompleteProperties.push('classification');
    }
    if (this.unitSize === 0 && !this.isNonCannabinoid()) {
      incompleteProperties.push('unitSize');
      generalIncompleteProperties.push('unitSize');
    }
    if (this.unitOfMeasure?.toString() === '' && !this.isNonCannabinoid()) {
      incompleteProperties.push('unitOfMeasure');
      generalIncompleteProperties.push('unitOfMeasure');
    }
    if (this.cannabisUnitOfMeasure?.toString() === '') {
      incompleteProperties.push('cannabisUnitOfMeasure');
      cannabinoidIncompleteProperties.push('cannabisUnitOfMeasure');
    }
    if (this.productType?.toString() === '' && !skipShared) {
      incompleteProperties.push('productType');
      generalIncompleteProperties.push('productType');
    }
    if (this.variantType?.toString() === '') {
      incompleteProperties.push('variantType');
      generalIncompleteProperties.push('variantType');
    }
    if (!useRange && this.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA) {
      if (this.THC === '') {
        incompleteProperties.push('THC');
        cannabinoidIncompleteProperties.push('THC');
      }
      if (this.CBD === '') {
        incompleteProperties.push('CBD');
        cannabinoidIncompleteProperties.push('CBD');
      }
    } else if (useRange && this.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA) {
      if (this.minCBD?.toString() === '') {
        incompleteProperties.push('minCBD');
        cannabinoidIncompleteProperties.push('minCBD');
      }
      if (this.maxCBD?.toString() === '') {
        incompleteProperties.push('maxCBD');
        cannabinoidIncompleteProperties.push('maxCBD');
      }
      if (this.minTHC?.toString() === '') {
        incompleteProperties.push('minTHC');
        cannabinoidIncompleteProperties.push('minTHC');
      }
      if (this.maxTHC?.toString() === '') {
        incompleteProperties.push('maxTHC');
        cannabinoidIncompleteProperties.push('maxTHC');
      }
    }
    if (generalOnly) {
      return generalIncompleteProperties;
    } else if (cannabinoidsOnly) {
      return cannabinoidIncompleteProperties;
    } else {
      return incompleteProperties;
    }
  }

  hasAutoCompletableProperties(skipShared: boolean = false, useRange: boolean = false): boolean {
    // VariantType, UnitSize and PackagedQuantity are not autocompleted since they will vary for each variant
    const incompleteProperties = this.incompleteProperties(skipShared, useRange);
    const nonAutoCompletableProperties = ['variantType', 'unitSize', 'packagedQuantity'];
    if (incompleteProperties?.length > nonAutoCompletableProperties?.length) {
      return true;
    } else {
      const autoCompletableProperties = incompleteProperties.filter(ip => !nonAutoCompletableProperties.contains(ip));
      return autoCompletableProperties?.length > 0;
    }
  }

  incompletePropertyCount(
    skipShared: boolean = false,
    useRange: boolean = false,
    generalOnly: boolean = false,
    cannabinoidsOnly: boolean = false
  ): number {
    return this.incompleteProperties(skipShared, useRange, generalOnly, cannabinoidsOnly)?.length ?? 0;
  }

  // Update methods

  public applyNonUpdatableProperties(old: Variant) {
    this.id = this.id || old.id;
    this.inventory = this.inventory || old.inventory;
    this.productId = this.productId || old.productId;
    this.productName = this.productName || old.productName;
  }

  /* ************************************* Pricing ************************************* */

  /**
   * Location SALE > Company SALE > Location Price > Company Price
   *
   * If no companyId is provided, this method will use the companyId attached to the variant.
   *
   * @returns the current sticker price of the variant, ie the price that a customer would
   * pay if they were to buy the variant at the store.
   */
  getVisiblePrice(
    locationId: number,
    companyId: number | null,
    priceFormat: PriceFormat,
    hideSale: boolean,
    pricingTierGridName: string = null,
  ): number | null {
    if (!!pricingTierGridName) {
      // Get price for pricing tier that matches provided pricingTierGridName
      const pt = this.getPricingTierForGridName(companyId, locationId, pricingTierGridName);
      return pt.price?.applyPriceFormatRounding(priceFormat) || 0;
    } else {
      // Return pricing logic as normal
      const company = this.locationPricing?.find(p => p.locationId === (companyId || this.companyId));
      const location = this.locationPricing?.find(p => p.locationId === locationId);
      const returnNull = (): null => null;
      const locationBestDiscountedPriceOrNull: (pf: PriceFormat, lp: LocationPromotion) => number = !hideSale
        ? location?.getBestDiscountedPriceOrNull?.bind(location) ?? returnNull
        : returnNull;
      const companyBestDiscountedPriceOrNull: (pf: PriceFormat, lp: LocationPromotion) => number = !hideSale
        ? company?.getBestDiscountedPriceOrNull?.bind(company) ?? returnNull
        : returnNull;
      const calculatePromotionOnOriginalPriceOrNull = (): number => this.locationPromotion
        ?.applyPromotionDiscountOrNull(this.price)
        ?.applyPriceFormatRounding(priceFormat) || null;
      const promotionOnOriginalPriceOrNull: () => number = !hideSale
        ? calculatePromotionOnOriginalPriceOrNull
        : returnNull;
      return locationBestDiscountedPriceOrNull?.(priceFormat, this.locationPromotion)
        || companyBestDiscountedPriceOrNull?.(priceFormat, this.locationPromotion)
        || location?.getPriceWithoutDiscountsOrNull(priceFormat)
        || company?.getPriceWithoutDiscountsOrNull(priceFormat)
        || promotionOnOriginalPriceOrNull()
        || this.price?.applyPriceFormatRounding(priceFormat)
        || null;
    }
  }

  /**
   * If no companyId is provided, this method will use the companyId attached to the variant.
   * Location Price > Company Price > Regular Price > null
   *
   * I did not break this up into separate variables because I want the logic to be calculated
   * via a short-circuit to reduce the amount of computation being done.
   *
   * @returns the price of the variant without promotions or discounts applied.
   */
  getPriceWithoutDiscounts(
    locationId: number,
    companyId: number | null,
    priceFormat: PriceFormat
  ): number {
    const prices = this.locationPricing;
    const compId = companyId || this.companyId;
    return prices?.find(p => p.locationId === locationId)?.getPriceWithoutDiscountsOrNull(priceFormat)
      || prices?.find(p => p.locationId === compId)?.getPriceWithoutDiscountsOrNull(priceFormat)
      || this.price?.applyPriceFormatRounding(priceFormat)
      || null;
  }

  /**
   * Location Best Discount > Company Best Discount > Regular Price with Discount? > null
   * If no companyId is provided, this method will use the companyId attached to the variant.
   *
   * I did not break this up into separate variables because I want the logic to be calculated
   * via a short-circuit to reduce the amount of computation being done.
   *
   * @returns the sale or promotion price of the variant, else null.
   */
  getDiscountedPriceOrNull(
    locationId: number,
    companyId: number | null,
    priceFormat: PriceFormat
  ): number | null {
    const prices = this.locationPricing;
    const promo = this.locationPromotion;
    const compId = companyId || this.companyId;
    return prices?.find(p => p.locationId === locationId)?.getBestDiscountedPriceOrNull(priceFormat, promo)
      || prices?.find(p => p.locationId === compId)?.getBestDiscountedPriceOrNull(priceFormat, promo)
      || promo?.applyPromotionDiscountOrNull(this.price)?.applyPriceFormatRounding(priceFormat)
      || null;
  }

  /**
   * If no companyId is provided, this method will use the companyId attached to the variant.
   *
   * @returns true if the variant is discounted, else false.
   */
  hasDiscount(locationId: number, companyId: number | null, priceFormat: PriceFormat): boolean {
    return this.getDiscountedPriceOrNull(locationId, companyId, priceFormat) !== null;
  }

  getSecondaryPrice(locationId: number, companyId: number): number {
    companyId = Number(companyId ? companyId : this.companyId);
    locationId = Number(locationId);
    const company = this.locationPricing?.find(p => p.locationId === (companyId || this.companyId));
    const location = this.locationPricing?.find(p => p.locationId === locationId);
    let priceVal: number;
    if (location?.secondaryPrice > 0) {
      priceVal = location.secondaryPrice;
    } else if (company?.secondaryPrice > 0) {
      priceVal = company.secondaryPrice;
    }
    return priceVal;
  }

  getPricePerUOM(
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat,
    hideSale: boolean
  ): number {
    companyId = Number(companyId ? companyId : this.companyId);
    locationId = Number(locationId);
    const numerator = this.getVisiblePrice(locationId, companyId, priceFormat, hideSale);
    const denominator = ((this.packagedQuantity ?? 1) * (this.unitSize ?? 1));
    return (isFinite(denominator) && denominator !== 0)
      ? (numerator / denominator)?.applyPriceFormatRounding(priceFormat)
      : 0;
  }

  getTaxesInPrice(locationId: number, companyId: number): number {
    return this.getVisiblePrice(locationId, companyId, PriceFormat.TaxesIn, false);
  }

  getTaxesInRoundedPrice(locationId: number, companyId: number): number {
    return this.getVisiblePrice(locationId, companyId, PriceFormat.TaxesInRounded, false);
  }

  getPreTaxPrice(locationId: number, companyId: number): number {
    return this.getVisiblePrice(locationId, companyId, PriceFormat.Default, false);
  }

  /**
   * Location SALE > Company SALE > Location Price > Company Price
   * Promo and Sale prices are applied on the base variant price. That is, Promos and Sales will never stack.
   * In case they are both present we display which ever price is CHEAPER between the Sale Price and Promo Price.
   * ie) Base Price = $35. Location Sale = $34. Location Promo of 1%: $35 - ($35*0.01) = $34.65.
   * Therefore, we display Location Sale Price which is $34
   *
   * @returns [price, priceText, isSale]
   */
  getFormattedPrice(
    priceFormat: PriceFormat,
    locationId: number,
    locName?,
    compName?: string,
    ignoreSalePrice: boolean = false
  ): [string, string, boolean] {
    const location = this.locationPricing?.find(p => p.locationId === locationId);
    const company = this.locationPricing?.find(p => p.locationId === this.companyId);
    let priceVal: number;
    let priceText: string;
    let isSale = false;
    const buildPrice = (): [string, string, boolean] => [PriceUtils.formatPrice(priceVal), priceText, isSale];
    // location discount
    priceVal = location?.getBestDiscountedPriceOrNull(priceFormat, this.locationPromotion);
    priceText = locName || 'location';
    isSale = true;
    if (priceVal > 0 && !ignoreSalePrice) {
      const isPromo = priceVal === location?.getPromotionPriceOrNull(priceFormat, this.locationPromotion);
      priceText = isPromo ? 'promotion price' : priceText + ' sale price';
      return buildPrice();
    }
    // company discount
    priceVal = company?.getBestDiscountedPriceOrNull(priceFormat, this.locationPromotion);
    priceText = compName || 'company';
    isSale = true;
    if (priceVal > 0 && !ignoreSalePrice) {
      const isPromo = priceVal === company?.getPromotionPriceOrNull(priceFormat, this.locationPromotion);
      priceText = isPromo ? 'promotion price' : priceText + ' sale price';
      return buildPrice();
    }
    // location pricing
    priceVal = location?.getPriceWithoutDiscountsOrNull(priceFormat);
    priceText = locName || 'location price';
    isSale = false;
    if (priceVal > 0) return buildPrice();
    // company pricing
    priceVal = company?.getPriceWithoutDiscountsOrNull(priceFormat);
    priceText = compName || 'company price';
    isSale = false;
    if (priceVal > 0) return buildPrice();
    // default price
    priceVal = this.price?.applyPriceFormatRounding(priceFormat);
    priceText = 'default price';
    isSale = false;
    return buildPrice();
  }

  onSale(locationId: number, ignoreSalePrice: boolean = false, priceFormat: PriceFormat): boolean {
    if (ignoreSalePrice) return false;
    return this.getDiscountedPriceOrNull(locationId, this.companyId, priceFormat) !== null;
  }

  getPriceWithoutDiscountsTooltip(
    locationId: number,
    companyId: number | null,
    priceFormat: PriceFormat
  ): string {
    const price = this.getPriceWithoutDiscounts(locationId, !!companyId ? companyId : this.companyId, priceFormat);
    return !!price ? `Original Price: ${PriceUtils.formatPrice(price)}` : null;
  }

  isLocationPrice(locationId: number, companyId, priceFormat: PriceFormat): boolean {
    const companyPricing = this.locationPricing?.find(p => p.locationId === companyId);
    const locationPricing = this.locationPricing?.find(p => p.locationId === locationId);
    const locationDiscounted = locationPricing?.getBestDiscountedPriceOrNull(priceFormat, this.locationPromotion);
    if (locationDiscounted > 0) return true;
    const companyDiscounted = companyPricing?.getBestDiscountedPriceOrNull(priceFormat, this.locationPromotion);
    if (companyDiscounted > 0) return false;
    return locationPricing?.getPriceWithoutDiscountsOrNull(priceFormat) > 0;
  }

  /**
   * The original price on the variant is conditionally shown if a sale, discount or promotion is
   * applied to the variant. If no sale, discount or promotion is applied, then no value is shown in the column.
   */
  getSaleOriginalPriceOrNull(locationId: number, companyId: number, priceFormat: PriceFormat): number | null {
    return this.onSale(locationId, false, priceFormat)
      ? this.getVisiblePrice(locationId, companyId, priceFormat, true)
      : null;
  }

  getSecondaryPriceColumnFormattedPrice(
    secondaryPriceType: SectionColumnConfigSecondaryPricingData,
    locId: number,
    compId: number,
    priceStream: PriceFormat,
    hideSale: boolean,
    returnDashIfDoesntExist: boolean = false
  ): string | '-' | null {
    let secondaryPrice: number;
    switch (true) {
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.PricePerUOM: {
        secondaryPrice = this.getPricePerUOM(locId, compId, priceStream, hideSale);
        break;
      }
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.OriginalPrice: {
        secondaryPrice = this.getPriceWithoutDiscounts(locId, compId, priceStream);
        break;
      }
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.SaleOriginalPrice: {
        secondaryPrice = this.getDiscountedPriceOrNull(locId, compId, priceStream);
        break;
      }
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice: {
        secondaryPrice = this.getVisiblePrice(locId, compId, priceStream, false);
        break;
      }
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.TaxesInPrice: {
        secondaryPrice = this.getTaxesInPrice(locId, compId);
        break;
      }
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice: {
        secondaryPrice = this.getTaxesInRoundedPrice(locId, compId);
        break;
      }
      case secondaryPriceType === SectionColumnConfigSecondaryPricingData.PreTaxPrice: {
        secondaryPrice = this.getPreTaxPrice(locId, compId);
        break;
      }
      case this.hasLocationOrCompanySecondaryPricing(): {
        secondaryPrice = this.getSecondaryPrice(locId, compId);
        break;
      }
    }
    const emptyValue = returnDashIfDoesntExist ? '-' : null;
    return exists(secondaryPrice) ? PriceUtils.formatPrice(secondaryPrice) : emptyValue;
  }

  /* ************************************************************************* */

  getDisplayName(): string {
    return this.displayAttributes?.displayName
      || this.displayAttributes?.inheritedDisplayAttribute?.displayName
      || this.name;
  }

  getNameTooltip(): string {
    if (this.getDisplayName() !== this.name) {
      // If the display name is different from the name, show the original name in the tooltip
      return this.name;
    } else {
      return null;
    }
  }

  getCannabinoidOrTerpene(cannabinoidOrTerpeneProperty: string): string {
    if (this.isAccessory()) return '';
    return this.displayAttributes?.[cannabinoidOrTerpeneProperty]
      || this.displayAttributes?.inheritedDisplayAttribute?.[cannabinoidOrTerpeneProperty]
      || this?.[cannabinoidOrTerpeneProperty];
  }

  getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneProperty: string): number {
    const strValue = this.getCannabinoidOrTerpene(cannabinoidOrTerpeneProperty);
    return this.getNumericValue(strValue);
  }

  getCannabinoidWithUnits(cannabinoidCamelCased: string): string {
    if (this.isAccessory() || !this.hasCannabisUnitOfMeasure()) return '';
    let cannabinoidWithUnits = '';
    if (this.useCannabinoidRange) {
      const minVal = this.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased);
      const maxVal = this.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased);
      const validMin = minVal !== Variant.invalidParsedCannabinoidOrTerpene;
      const validMax = maxVal !== Variant.invalidParsedCannabinoidOrTerpene;
      if (validMin && validMax) {
        cannabinoidWithUnits = `${minVal} - ${maxVal}${this.cannabisUnitOfMeasure}`;
      }
    } else {
      const cannabinoidValue = this.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
      if (cannabinoidValue !== Variant.invalidParsedCannabinoidOrTerpene) {
        cannabinoidWithUnits = `${cannabinoidValue}${this.cannabisUnitOfMeasure}`;
      }
    }
    return cannabinoidWithUnits;
  }

  getTerpeneWithUnits(terpeneCamelCased: string): string {
    if (this.isAccessory() || !this.hasTerpeneUnitOfMeasure()) return '';
    let terpeneWithUnits = '';
    if (this.useTerpeneRange) {
      const minVal = this.getNumericMinCannabinoidOrTerpene(terpeneCamelCased);
      const maxVal = this.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased);
      const validMin = minVal !== Variant.invalidParsedCannabinoidOrTerpene;
      const validMax = maxVal !== Variant.invalidParsedCannabinoidOrTerpene;
      if (validMin && validMax) {
        terpeneWithUnits = `${minVal} - ${maxVal}${this.terpeneUnitOfMeasure}`;
      }
    } else {
      const terpeneValue = this.getNumericCannabinoidOrTerpene(terpeneCamelCased);
      if (terpeneValue !== Variant.invalidParsedCannabinoidOrTerpene) {
        terpeneWithUnits = `${terpeneValue}${this.terpeneUnitOfMeasure}`;
      }
    }
    return terpeneWithUnits;
  }

  getMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): string {
    if (this.isAccessory()) return '';
    const accessor = StringUtils.capitalize(cannabinoidOrTerpeneCamelCased);
    return this.displayAttributes?.[`min${accessor}`]
      || this.displayAttributes?.inheritedDisplayAttribute?.[`min${accessor}`]
      || this?.[`min${accessor}`];
  }

  getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const strValue = this.getMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
    return this.getNumericValue(strValue);
  }

  getMinCannabinoidWithUnits(cannabinoidCamelCased: string): string {
    if (this.isAccessory()) return '';
    const minVal = this.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased);
    let rangeWithUnits: string = '';
    if (minVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasCannabisUnitOfMeasure()) {
      rangeWithUnits = `${minVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMinTerpeneWithUnits(terpeneCamelCased: string): string {
    if (this.isAccessory()) return '';
    const minVal = this.getNumericMinCannabinoidOrTerpene(terpeneCamelCased);
    let rangeWithUnits: string = '';
    if (minVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasTerpeneUnitOfMeasure()) {
      rangeWithUnits = `${minVal}${this.terpeneUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): string {
    const accessor = StringUtils.capitalize(cannabinoidOrTerpeneCamelCased);
    return this.displayAttributes?.[`max${accessor}`]
      || this.displayAttributes?.inheritedDisplayAttribute?.[`max${accessor}`]
      || this?.[`min${accessor}`];
  }

  getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const strValue = this.getMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
    return this.getNumericValue(strValue);
  }

  getMaxCannabinoidWithUnits(cannabinoidCamelCased: string): string {
    if (this.isAccessory()) return '';
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased);
    let rangeWithUnits: string = '';
    if (maxVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasCannabisUnitOfMeasure()) {
      rangeWithUnits = `${maxVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMaxTerpeneWithUnits(terpeneCamelCased: string): string {
    if (this.isAccessory()) return '';
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased);
    let rangeWithUnits: string = '';
    if (maxVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasTerpeneUnitOfMeasure()) {
      rangeWithUnits = `${maxVal}${this.terpeneUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getTotalTerpenes(): string {
    return this.displayAttributes?.getTotalTerpene();
  }

  getVariantTopTerpene(): string {
    return this.displayAttributes?.getTopTerpene();
  }

  getSize(): string {
    // If variant is non-pre-roll flower
    const isFlowerByGram = VariantTypeDefinition.isFlowerByGramType(this.variantType);
    // If variant is any of the concentrate types
    const isConcentrate = this.productType === ProductType.Concentrate;
    // If variant is any of the liquid oil types (non-capsule)
    const isLiquidOil = this.productType === ProductType.Oil && !VariantTypeDefinition.isCapsuleType(this.variantType);
    // If variant is any Vape option
    const isVape = this.productType === ProductType.Vape;

    switch (true) {
      case isConcentrate:
      case isFlowerByGram:
      case isLiquidOil:
      case isVape:
        return `${this.unitSize} ${this.unitOfMeasure}`;
      default:
        return `${this.packagedQuantity} pk`;
    }
  }

  getGridNames(layoutType: SectionLayoutType, locationId: number): string[] {
    let gns: string[] = [];
    if (layoutType?.isGrid()) {
      // Handle Grid columns
      const gn = this.getGridName();
      gns.push(gn);
    } else if (layoutType?.isPricingTierGrid()) {
      // Handle Pricing Tier Grid columns
      gns = this.getPricingTierGridNames(this.companyId, locationId);
    }
    return gns.filterNulls().map(gn => gn?.trim());
  }

  public getGridName() {
    // If variant is non-pre-roll flower
    const isFlowerByGram = VariantTypeDefinition.isFlowerByGramType(this.variantType);
    // If variant is any of the concentrate types
    const isConcentrate = this.productType === ProductType.Concentrate;
    // If variant is any of the liquid oil types (non-capsule)
    const isLiquidOil = this.productType === ProductType.Oil && !VariantTypeDefinition.isCapsuleType(this.variantType);
    // If variant is any Vape option
    const isVape = this.productType === ProductType.Vape;
    const isCapsule = VariantTypeDefinition.isCapsuleType(this.variantType);

    switch (true) {
      case isFlowerByGram:
        return this.unitSize > 0 ? `${this.unitSize} g` : null;
      case isLiquidOil:
      case isVape:
      case isConcentrate:
        return this.unitSize > 0 ? `${this.unitSize} ${this.unitOfMeasure}` : null;
      case isCapsule:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} caps` : null;
      default:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} pk` : null;
    }
  }

  getGridNameAsColumnComparisonString(): string {
    return StringUtils.gridColumnComparisonString(this.getGridName());
  }

  private getPricingTierGridNames(companyId: number, locationId: number): string[] {
    // Get price to use based on location then company hierarchy
    const locationPrice = this.locationPricing?.find(lp => lp.locationId === locationId);
    const companyPrice = this.locationPricing?.find(lp => lp.locationId === companyId);
    const getPriceTier = (price: VariantPricing): string[] => {
      if (price?.pricingTiers?.length > 0) {
        return this.getPricingTierGridNamesForEntityPricing(price);
      } else {
        return null;
      }
    };
    return getPriceTier(locationPrice) || getPriceTier(companyPrice) || [];
  }

  private getPricingTierGridNamesForEntityPricing(priceToUse: VariantPricing): string[] {
    // Calculate pricing tiers based on priceToUse
    let gridNames: string[] = [];
    priceToUse?.pricingTiers?.forEach(pt => {
      gridNames.push(pt.getGridColumnName(this.shouldUseWeightForPricingTierGridColumn(), this.unitOfMeasure));
    });
    gridNames = gridNames.unique(true);
    return gridNames.length > 0 ? gridNames : null;
  }

  private getPricingTierForGridName(companyId: number, locationId: number, ptGridName: string): VariantPricingTier {
    // Get price to use based on location then company hierarchy
    let priceToUse = this.locationPricing?.find(lp => lp.locationId === locationId);
    if (!priceToUse) {
      priceToUse = this.locationPricing?.find(lp => lp.locationId === companyId);
    }
    const tier = priceToUse?.pricingTiers?.find(pt => {
      const useWeightForPricingTier = this.shouldUseWeightForPricingTierGridColumn();
      return pt.getGridColumnName(useWeightForPricingTier, this.unitOfMeasure) === ptGridName;
    });
    return tier || null;
  }

  hasInheritedBadges(): boolean {
    const hasLocationBadges = (this.displayAttributes?.badgeIds?.length ?? 0) > 0;
    const hasCompanyBadges = (this.displayAttributes?.inheritedDisplayAttribute?.badgeIds?.length ?? 0) > 0;
    return hasLocationBadges || hasCompanyBadges;
  }

  displayNameHasOverride(): boolean {
    return !!this.displayAttributes?.getDisplayName();
  }

  getVariantTitle(): string {
    return this.displayAttributes?.getDisplayName() || this.name;
  }

  hasBadges(map: Map<string, VariantBadge[]>): boolean {
    const badges: VariantBadge[] = [];
    if (map && map.get(this.id)) {
      map.get(this.id).forEach((badge) => {
        badges.push(badge);
      });
    }
    return badges.length > 0 || this.displayAttributes?.getBadges().length > 0;
  }

  getLocationBadges(): HydratedVariantBadge[] {
    return this.getLocationDisplayAttribute()?.badges || [];
  }

  getLocationBadgesShallowCopy(): HydratedVariantBadge[] {
    return this.getLocationBadges()?.shallowCopy() || [];
  }

  getCompanyBadges(): HydratedVariantBadge[] {
    return this.getCompanyDisplayAttribute()?.badges || [];
  }

  getCompanyBadgesShallowCopy(): HydratedVariantBadge[] {
    return this.getCompanyBadges()?.shallowCopy() || [];
  }

  getLocationLabel(): string | null {
    return this.getLocationDisplayAttribute()?.defaultLabel || null;
  }

  getCompanyLabel(): string | null {
    return this.getCompanyDisplayAttribute()?.defaultLabel || null;
  }

  public getAllVariantBadges(
    limit: number = 0,
    variantBadgeMap: Map<string, VariantBadge[]>,
  ): VariantBadge[] {
    let allBadges: VariantBadge[] = [];
    const badges: VariantBadge[] = [];
    if (variantBadgeMap && variantBadgeMap.get(this.id)) {
      variantBadgeMap.get(this.id).forEach((badge) => {
        if (!badges.find(b => b.id === badge.id)) {
          badges.push(badge);
        }
      });
    }
    const displayAttributeBadges = this?.displayAttributes?.badges ?? [];
    const inheritedDisplayAttributeBadges = this?.displayAttributes?.inheritedDisplayAttribute?.badges ?? [];
    if (badges.length > 0) {
      allBadges.push(...badges);
    } else if (displayAttributeBadges.length > 0) {
      allBadges.push(...displayAttributeBadges);
    } else if (inheritedDisplayAttributeBadges.length > 0) {
      allBadges.push(...inheritedDisplayAttributeBadges);
    }
    allBadges = allBadges.uniqueByProperty('id').sort((a, b) => a.name.localeCompare(b.name));
    if (limit > 0 && limit < allBadges.length) {
      return allBadges.slice(0, limit);
    } else if (limit < 0) { // Not supported
      return [];
    } else {
      return allBadges;
    }
  }

  inStock(): boolean {
    return this.inventory?.inStock();
  }

  public getCannabinoidWithoutDisplayAttributes(cannabinoid: string): string {
    if (this.isAccessory()) return '';
    return (this?.[cannabinoid] && this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)
      ? this?.[cannabinoid] + this?.cannabisUnitOfMeasure
      : '';
  }

  public getMinCannabinoidWithoutDisplayAttributes(cannabinoid: string): string {
    if (this.isAccessory()) return '';
    return (this?.['min' + cannabinoid] && this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)
      ? this?.['min' + cannabinoid] + this?.cannabisUnitOfMeasure
      : '';
  }

  public getMaxCannabinoidWithoutDisplayAttributes(cannabinoid: string): string {
    if (this.isAccessory()) return '';
    return (this?.['max' + cannabinoid] && this?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA)
      ? this?.['max' + cannabinoid] + this?.cannabisUnitOfMeasure
      : '';
  }

  public getThc(menu: Menu, companyConfig: CompanyConfiguration): string {
    if (this.isAccessory()) return '-';
    const shouldDisplayTHC = this.shouldDisplayThcValue();
    if (shouldDisplayTHC) {
      const unitOfMeasureString = this.getUnitOfMeasureString(menu);
      const companyUsesRange = companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
      const displayCannabinoidInRanges = companyUsesRange || this.useCannabinoidRange;
      // Get THC value
      if (displayCannabinoidInRanges) {
        const thcRange = this.getFormattedCannabinoidOrTerpeneRange('THC');
        if (thcRange === '') {
          return `${CannabisUnitOfMeasure.NA}`;
        } else {
          return `${thcRange} ${unitOfMeasureString}`.trim();
        }
      } else {
        const parsedThc = this.getNumericCannabinoidOrTerpene('THC');
        if (parsedThc < 1) {
          return (`<1${unitOfMeasureString}`).trim();
        } else {
          return `${(Math.round((parsedThc + Number.EPSILON) * 100) / 100)}${unitOfMeasureString}`.trim();
        }
      }
    } else {
      return '-';
    }
  }

  public getCbd(menu: Menu, companyConfig: CompanyConfiguration): string {
    if (this.isAccessory()) return '-';
    const shouldDisplayCBD = this.shouldDisplayCbdValue();
    if (shouldDisplayCBD) {
      const unitOfMeasureString = this.getUnitOfMeasureString(menu);
      // Get CBD Value
      const companyUsesRange = companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
      const displayCannabinoidInRanges = companyUsesRange || this.useCannabinoidRange;
      if (displayCannabinoidInRanges) {
        const cbdRange = this.getFormattedCannabinoidOrTerpeneRange('CBD');
        if (cbdRange === '') {
          return `${CannabisUnitOfMeasure.NA}`;
        } else {
          return `${cbdRange} ${unitOfMeasureString}`.trim();
        }
      } else {
        const parsedCbd = this.getNumericCannabinoidOrTerpene('CBD');
        if (parsedCbd < 1) {
          return (`<1${unitOfMeasureString}`).trim();
        } else {
          return `${(Math.round((parsedCbd + Number.EPSILON) * 100) / 100)}${unitOfMeasureString}`.trim();
        }
      }
    } else {
      return '-';
    }
  }

  shouldDisplayThcValue(): boolean {
    return !this.isNonCannabinoid();
  }

  shouldDisplayCbdValue(): boolean {
    return !this.isNonCannabinoid();
  }

  getUnitOfMeasureString(menu: Menu): string {
    if (this.isAccessory()) return '-';
    let unitOfMeasure = this.cannabisUnitOfMeasure;
    if (unitOfMeasure === CannabisUnitOfMeasure.NA) {
      return '-';
    } else if (unitOfMeasure === CannabisUnitOfMeasure.UNKNOWN) {
      if (this.productType === ProductType.Flower) {
        unitOfMeasure = CannabisUnitOfMeasure.Percent;
      } else {
        unitOfMeasure = CannabisUnitOfMeasure.MilliGram;
      }
    }
    let unitOfMeasureString = unitOfMeasure.toString();
    const milliGramPer = unitOfMeasure === CannabisUnitOfMeasure.MilliGramPerGram
      || unitOfMeasure === CannabisUnitOfMeasure.MilliGramPerMilliLitre;
    if (milliGramPer && menu.type !== MenuType.PrintMenu) {
      unitOfMeasureString = `${unitOfMeasure}`;
    }
    return unitOfMeasureString;
  }

  getFormattedCannabinoidOrTerpeneRange(cannabinoid: string): string {
    if (this.isAccessory()) return '';
    const minVal = this.getNumericMinCannabinoidOrTerpene(cannabinoid);
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(cannabinoid);
    if (minVal !== Variant.invalidParsedCannabinoidOrTerpene && maxVal !== Variant.invalidParsedCannabinoidOrTerpene) {
      if (minVal === maxVal) {
        return `${minVal}`;
      }
      return `${minVal} - ${maxVal}`;
    }
    return '';
  }

  getNumericValue(v: string): number {
    if (!!v) {
      if (v.includes('-')) {
        v = v.split('-')[0];
      }
      return Number(v.replace(/[^0-9.]+/g, ''));
    } else {
      return Variant.invalidParsedCannabinoidOrTerpene;
    }
  }

  getFormattedUnitSize(ignoreUnsetUOM: boolean = true): string {
    const unsetUOM = this.unitOfMeasure === UnitOfMeasure.NA;
    if (unsetUOM && ignoreUnsetUOM) {
      return '-';
    } else {
      return `${this.unitSize}${unsetUOM ? '' : this.unitOfMeasure}`;
    }
  }

  formattedSizing(wrapInBrackets: boolean = false): string {
    let sizeString = '';
    const moreThanOne = this.packagedQuantity > 0;
    const hasSize = this.unitSize > 0;
    const hasUnits = this.unitOfMeasure !== UnitOfMeasure.NA;
    if (moreThanOne && hasSize && hasUnits) {
      const n = this.packagedQuantity;
      if (VariantTypeDefinition.isCapsuleType(this.variantType)) {
        sizeString = `${n} cap${n > 1 ? 's' : ''}`;
      } else if (this.productType === ProductType.Edible) {
        sizeString = `${n} pack`;
      } else {
        sizeString = this.packagedQuantity + ' x ' + this.unitSize + this.unitOfMeasure;
      }
    }
    if (exists(sizeString) && wrapInBrackets) sizeString = `(${sizeString})`;
    return sizeString;
  }

  isAccessory(): boolean {
    return this.productType === ProductType.Accessories;
  }

  isNonCannabinoidOtherVariant(): boolean {
    return this.productType === ProductType.Other && !VariantTypeDefinition.isOtherCannabis(this.variantType);
  }

  isNonCannabinoid(): boolean {
    return this.isAccessory() || this.isNonCannabinoidOtherVariant();
  }

  getQuantityInStock(): number {
    return this.inventory?.quantityInStock ?? 0;
  }

  getEditSectionVariantSizeText(): string {
    if (this.packagedQuantity > 1) {
      return `(x${this.packagedQuantity}) ${this.unitSize}${this.unitOfMeasure}`;
    } else {
      return `${this.unitSize}${this.unitOfMeasure}`;
    }
  }

  /**
   * This is needed because some of the POS APIs don't return the true value of stock for a variant.
   * Example: The Trees API will cap quantity at 30, even if the stock for the given variant is higher than 30.
   */
  presentQuantityInStockToUser(inventoryProvider: InventoryProvider): string {
    const stock = this.getQuantityInStock();
    if (!stock) {
      return '';
    } else {
      return ProviderUtils.applyVariantInventoryDecorator(inventoryProvider, stock);
    }
  }

  getDisplayAttributes(): DisplayAttribute[] {
    return [this.displayAttributes, this.displayAttributes?.inheritedDisplayAttribute]?.filterNulls();
  }

  getLastBulkUpdateTime(): number {
    const bulkModifiedTimes = this.getDisplayAttributes()?.map(attr => attr?.lastBulkModified) ?? [0];
    return Math.max(...bulkModifiedTimes);
  }

  getLocationDisplayAttribute(): DisplayAttribute | null {
    return this.getDisplayAttributes()?.filter(da => da?.isLocationDA())?.firstOrNull();
  }

  getCompanyDisplayAttribute(): DisplayAttribute | null {
    return this.getDisplayAttributes()?.filter(da => da?.isCompanyDA())?.firstOrNull();
  }

  ignoredVariantSubtitle(): string {
    const props = [
      ... exists(this.brand) ? [this.brand] : [],
      ... exists(this.productType) ? [this.productType] : [],
      ... exists(this.variantType) ? [this.variantType] : [],
    ];
    return `${props.join(' - ')} ${this.formattedSizing(true)}`.trim();
  }

  restockedWithin(timestampInThePast: number): boolean {
    return this.inventory?.restockedWithin(timestampInThePast) || false;
  }

  hasUnitOfMeasure(): boolean {
    return this.unitOfMeasure !== UnitOfMeasure.NA;
  }

  hasCannabisUnitOfMeasure(): boolean {
    return this.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA
      && this.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.UNKNOWN;
  }

  hasTerpeneUnitOfMeasure(): boolean {
    return this.terpeneUnitOfMeasure !== TerpeneUnitOfMeasure.NA
      && this.terpeneUnitOfMeasure !== TerpeneUnitOfMeasure.UNKNOWN;
  }

  /* ******************************* Managing Display Attributes ******************************* */

  removeVariantDisplayAttribute(toRemove: DisplayAttribute): void {
    DisplayAttribute.removeDisplayAttributeFrom(this, toRemove);
  }

  updateVariantDisplayAttribute(toUpdate: DisplayAttribute): void {
    DisplayAttribute.updateDisplayAttributeWithin(this, toUpdate);
  }

  /* ******************************************************************************************* */

  getUniqueIdentifier(): string {
    // generate unique id
    const locationPricingIds: string[] = [];
    this.locationPricing?.forEach((p) => {
      locationPricingIds.push(p.getUniqueIdentifier());
    });
    const locationPricingId = locationPricingIds.sort().join(',');
    const productTableLabel = this.computedLabelForProductTable?.getUniqueIdentifier();
    const menuContextLabel = this.computedLabelForMenuSection?.getUniqueIdentifier();
    // not including terpenes or descriptions as it is rich text and needs to be reduced
    return `${this.companyId}
      -${this.id}
      -${this.name}
      -${this.price}
      -${this.brand}
      -${this.manufacturer}
      -${this.classification}
      -${this.unitSize}
      -${this.unitOfMeasure}
      -${this.cannabisUnitOfMeasure}
      -${this.terpeneUnitOfMeasure}
      -${this.useCannabinoidRange}
      -${this.useTerpeneRange}
      -${this.packagedQuantity}
      -${this.productType}
      -${this.variantType}
      -${this.strain}
      -${this.description}
      -${this.shortDescription}
      -${this.THC}
      -${this.CBD}
      -${this.inventory?.getUniqueIdentifier()}
      -${locationPricingId}
      -${productTableLabel}
      -${menuContextLabel}
      -${this.displayAttributes?.getUniqueIdentifier()}
      -${this.posLabelIds?.sort().join(',')}
      -${this.isDiscontinued}`;
  }

  hasLocationOrCompanyNonSalePricing(): boolean {
    return this.locationPricing?.some(p => p?.price > 0);
  }

  hasLocationOrCompanySalePricing(): boolean {
    return this.locationPricing?.some(p => p?.overridePrice > 0);
  }

  hasLocationOrCompanyPricing(): boolean {
    return this.hasLocationOrCompanyNonSalePricing() || this.hasLocationOrCompanySalePricing();
  }

  hasLocationOrCompanySecondaryPricing(): boolean {
    return this.locationPricing?.some(p => p?.secondaryPrice > 0);
  }

  // This functionality matches that on the API and must remain consistent for grid column validation logic
  shouldUseWeightForPricingTierGridColumn(): boolean {
    return VariantTypeDefinition.isFlowerByGramType(this.variantType);
  }

  getPackagedQuantityAndProductSize(): string {
    // Assuming that packaged quantity should always be 1
    const quantity = this.packagedQuantity === 0 ? 1 : this.packagedQuantity;
    const size = this.getFormattedUnitSize();
    const isPreRollType = VariantTypeDefinition.isPreRollType(this.variantType);
    if (isPreRollType) {
      return `${quantity} x ${size}`;
    } else if (this.isAccessory() || this.productType === ProductType.Other) {
      return `${quantity} ${quantity > 1 ? 'units' : 'unit'}`;
    } else if (this.packagedQuantity > 1) {
      const beverage = VariantTypeDefinition.isReadyToDrinkBeverageType(this.variantType);
      return `${this.packagedQuantity}${beverage ? 'pk' : 'pc'}`;
    } else {
      return this.getFormattedUnitSize();
    }
  }

  getLastModifiedTimes(): number[] {
    return [
      this.lastModified,
      this.getLocationDisplayAttribute()?.lastModified,
      this.getCompanyDisplayAttribute()?.lastModified
    ].filterNulls();
  }

  lastModifiedTimeChanged(prev: number[]): boolean {
    const currentModifiedTimes = this?.getLastModifiedTimes();
    const sameLength = currentModifiedTimes?.length === prev?.length;
    return !sameLength || !currentModifiedTimes.every(t => prev?.includes(t));
  }

  getLocationLevelSmartFilterLabelId(): string | null {
    return this.getLocationDisplayAttribute()?.smartLabelId || null;
  }

  hasSmartBadges(): boolean {
    return !!this.getLocationLevelSmartFilterBadgeIds();
  }

  hasSmartLabel(): boolean {
    return !!this.getLocationLevelSmartFilterLabelId();
  }

  hasPosLabel(): boolean {
    return this.posLabelIds?.length > 0;
  }

  getLocationLevelSmartFilterBadgeIds(): string[] {
    return this.getLocationDisplayAttribute()?.smartBadgeIds ?? [];
  }

  getFormattedClassification(): string {
    switch (this.classification) {
      case StrainClassification.IndicaDominant:
        return 'Indica Dominant';
      case StrainClassification.SativaDominant:
        return 'Sativa Dominant';
      default:
        return this.classification;
    }
  }

  withinGridColumnAndInStock(columnName: string, showOutOfStock: boolean): boolean {
    const unitForComparison = StringUtils.gridColumnComparisonString(this.getGridName());
    const comparingTo = StringUtils.gridColumnComparisonString(columnName);
    return (unitForComparison === comparingTo) && (showOutOfStock || this.inStock());
  }

}
