<div class="text-line-divider" fxLayout="row" fxLayoutAlign="center center" [fxLayoutGap]="barGap">
  <hr class="text-line-hr" [style.borderTop]="'1px solid ' + barColor" fxFlex />
  <div class="line-text-container" fxLayout="row" fxLayoutAlign="center center">
    <div class="{{ textClass }} inline-block"><ng-content></ng-content></div>
    <div class="tooltip-container">
      <button fxFlex (click)="openInformationModal.emit(true)" class="inline-tooltip" [style.height]="'unset'">
        <img [src]="'assets/icons/dark/outline/information-circle.svg'" alt="" style="opacity: 1" />
      </button>
    </div>
  </div>
  <hr class="text-line-hr" [style.borderTop]="'1px solid ' + barColor" fxFlex />
</div>
