<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Locations</p>
    <p class="bs-edit-section-description">
      Assign this template to be required at select locations and view locations where this template is active.
    </p>
    <lib-button-primary *ngIf="hasActiveLocationIds" (buttonClicked)="editTemplateLocations.emit()">
      Edit Required Locations
    </lib-button-primary>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <ng-content></ng-content>
  </div>
</div>

<hr class="mt-16px" />
