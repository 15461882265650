import { TypeDefinition } from './type-definition';

export enum ProductMix {
  All = 'ALL',
  Active = 'ACTIVE',
  Discontinued = 'DISCONTINUED'
}

export class ProductMixType extends TypeDefinition {

  override value: ProductMix;

}
