<lib-reactive-form-group>
  <div class="search-desc-and-tag-container mt-24px">
    <lib-reactive-search-bar
      #search
      class="d-inline-block search-bar"
      [searchThrough]="bulkPrintViewModel.currentLocationBulkPrintJobs$ | async"
      [searchTheseProperties]="['name']"
      (searchText)="bulkPrintViewModel.connectToSearchText($event)"
      (searchHits)="bulkPrintViewModel.connectToSearchedItems($event)">
    </lib-reactive-search-bar>
    <lib-reactive-form-drop-down
      class="drop-down"
      [inputName]="'menuTypeFilter'"
      [label]="'Filter by Menu Type'"
      [placeholder]="'Choose a Menu Type'"
      [dropdowns]="bulkPrintViewModel.menuTypeFilterOptions$ | push"
      [clearable]="true"
      (valueChanged)="bulkPrintViewModel.connectToMenuTypeFilter($event)"></lib-reactive-form-drop-down>
  </div>
</lib-reactive-form-group>
