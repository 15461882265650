import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Label } from '../../../../models/shared/label';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { BsError } from '../../../../models/shared/bs-error';
import { ToastService } from '../../../../services/toast-service';
import { LabelDomainModel } from '../../../../domainModels/label-domain-model';

@Injectable()
export class ManageLabelSmartFiltersViewModel extends BaseModalViewModel {

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private locationConfig$ = this.locationDomainModel.locationConfig$;
  private companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public displayLabelData$ = combineLatest([
    this.locationConfig$,
    this.companyConfig$,
    this.companyConfig$.pipe(map(config => config?.labelStyle))
  ]);

  private readonly _label = new BehaviorSubject<Label>(null);
  public readonly label$ = this._label as Observable<Label>;
  public connectToLabel = (label: Label) => this._label.next(label);

  public stickyZIndex$ = of(2);

  public saveSmartLabelChanges(): void {
    const lm = 'Updating Label';
    this.label$.pipe(
      take(1),
      tap(_ => this._loadingOpts.addRequest(lm)),
      switchMap(label => this.labelDomainModel.updateLabels([label]))
    ).subscribe({
      next: label => this.activeModal.close(label),
      error: (err: BsError) => {
        this.toastService.publishError(err);
        this._loadingOpts.removeRequest(lm);
      },
      complete: () => {
        this.toastService.publishSuccessMessage('Label updated successfully', 'Manage Label Smart Filters');
        this._loadingOpts.removeRequest(lm);
      }
    });
  }

}
