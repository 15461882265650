<iframe
  #iFrame
  loading="eager"
  id="live-view-iframe"
  [style.box-shadow]="viewModel.boxShadow$ | push"
  [style.height.px]="viewModel.cardHeight$ | push"
  [style.width.px]="viewModel.cardWidth$ | push"
  [frameBorder]="'0'"
  [name]="viewModel.iFrameName$ | push"
  [src]="viewModel.url$ | push">
</iframe>
