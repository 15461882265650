import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { ToastService } from '../../../services/toast-service';
import { BsError } from '../../../models/shared/bs-error';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Label } from '../../../models/shared/label';
import { SortUtils } from '../../../utils/sort-utils';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';

@Injectable()
export class LabelHierarchyBannerViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private toastService: ToastService
  ) {
    super();
  }

  public readonly companySupportsLocationLabels$ = this.companyDomainModel.companySupportsLocationLabels$;
  private readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  private readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;

  private _labels = new BehaviorSubject<Label[]>(null);
  public sortedLabels$ = this._labels.pipe(
    map(l => l?.filter(ls => ls.priority !== -1).sort(SortUtils.sortLabelsByPriority))
  );
  public connectToLabels = (ls: Label[]): void => this._labels.next(ls);

  private _activeLabelStyle = new BehaviorSubject<LabelStyle>(null);
  public activeLabelStyle$ = this._activeLabelStyle.asObservable();
  public connectToActiveLabelStyle = (ls: LabelStyle): void => this._activeLabelStyle.next(ls);

  private _activeSaleLabelFormat = new BehaviorSubject<SaleLabelFormat>(null);
  public activeSaleLabelFormat$ = this._activeSaleLabelFormat.asObservable();
  public connectToActiveSaleLabelFormat = (f: SaleLabelFormat): void => this._activeSaleLabelFormat.next(f);

  private _allowUnsetPriority = new BehaviorSubject<boolean>(false);
  public allowUnsetPriority$ = this._allowUnsetPriority as Observable<boolean>;
  public connectToAllowUnsetPriority = (allowUnsetPriority: boolean): void => {
    this._allowUnsetPriority.next(allowUnsetPriority);
  };

  public readonly dataForDisplayLabelInterface$ = combineLatest([
    this.companySupportsLocationLabels$,
    this.allowUnsetPriority$,
    this.locationConfig$,
    this.companyConfig$,
    this.activeLabelStyle$,
    this.activeSaleLabelFormat$
  ]);

  public updateLabels(labels: Label[]): void {
    this.labelDomainModel.updateLabels(labels).subscribe(_ => {
      this.toastService.publishSuccessMessage('Labels updated', 'Success');
    }, (err: BsError) => {
      this.toastService.publishErrorMessage(err.message, 'Unable to update labels');
    });
  }

}
