import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent extends BaseComponent implements OnChanges {

  @Input() options: LoadingOptions = LoadingOptions.default();

  constructor() {
    super();
  }

  override setupViews(): void {
    this.setDefaults();
  }

  private setDefaults() {
    if (!this.options.backgroundColor || this.options.backgroundColor === '') {
      this.options.backgroundColor = 'rgba(222, 222, 222, 0.9)';
    }
    if (!this.options.color || this.options.color === '') {
      this.options.color = '#2C4058';
    }
    if (!this.options.spinnerColor || this.options.spinnerColor === '') {
      this.options.spinnerColor = this.options.color;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) this.setDefaults();
  }

}
