import { Component, ViewChild } from '@angular/core';
import { ReplaceCompanyPhotoViewModel } from './replace-company-photo-view-model';
import { UploadAssetComponent } from '../../../../shared/components/upload-asset/upload-asset.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';

@Component({
  selector: 'app-replace-company-photo',
  templateUrl: './replace-company-photo.component.html',
  styleUrls: ['./replace-company-photo.component.scss'],
  providers: [ReplaceCompanyPhotoViewModel],
})
export class ReplaceCompanyPhotoComponent extends BaseModalComponent implements UploadImageInterface {

  @ViewChild(UploadAssetComponent) dnd: UploadAssetComponent;

  constructor(
    public viewModel: ReplaceCompanyPhotoViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override setupBindings(): void {
    const s = this.viewModel.dismissModalSubject.subscribe((close) => {
      if (close) this.cancel();
    });
    this.pushSub(s);
  }

  override cancel() {
    this.dnd.clear();
    super.cancel();
  }

  uploadImage(): void {
    this.viewModel.uploadLogo();
  }

  fileList(f: BudsenseFile[]) {
    if (f.length > 0) this.viewModel.file = f[0];
    else this.viewModel.file = undefined;
  }

}
