export const PLAYLIST_ROTATION_INTERVAL = 1;
export const DEFAULT_ROTATION_INTERVAL = 30;

export class MenuIntervalUtils {

  static getMenuRotationInterval(isPlaylistContent: boolean) {
    // if the menu is a playlist, we want the menu to play one time (not seconds)
    if (isPlaylistContent) {
      return PLAYLIST_ROTATION_INTERVAL;
    } else {
      return DEFAULT_ROTATION_INTERVAL;
    }
  }

}
