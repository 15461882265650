import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../../models/menu/dto/section';

@Pipe({
  name: 'getSectionPreviewVariantCount'
})
export class GetSectionPreviewVariantCountPipe implements PipeTransform {

  transform(section: Section): number {
    return section?.enabledVariantIds?.length ?? 0;
  }

}
