import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';

@Component({ selector: 'app-base-smart-filter-form', template: '' })
export abstract class BaseSmartFilterFormComponent extends BaseComponent {

  @Input() dispersedKey: string;
  @Input() bindTo: HydratedSmartFilter;
  @Input() isViewOnly: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() modalOpenedFrom: AddEditSmartFilterModalOpenedFrom = null;

}
