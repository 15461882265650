import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { BehaviorSubject, combineLatest, defer, Observable, of } from 'rxjs';
import { ThemeUtils } from '../../../../../../../utils/theme-utils';
import { ThemeSectionColumnConfig } from '../../../../../../../models/menu/dto/theme-section-column-config';
import { map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { InformationItem } from '../../../../../../../models/shared/information-item';
import { ColumnOptionName, SectionColumnConfigKey, SectionColumnConfigKeyType } from '../../../../../../../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState, SectionColumnConfigDefaultStateType } from '../../../../../../../models/utils/dto/section-column-config-default-state-type';
import { SectionColumnConfigDataValueType } from '../../../../../../../models/utils/dto/section-column-config-data-value-type';

// local interface for keeping track of column changes
interface ConfigChanges {
  defaultState: SectionColumnConfigDefaultState;
  columnWidth?: number
}

@Injectable()
export class ColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsViewModel
  ) {
    super();
  }

  private noAutoSectionDefaultStateOptions$ = window.types.noAutoSectionColumnConfigDefaultStates$;
  private autoSectionDefaultStateOptions$ = window.types.autoSectionColumnConfigDefaultStates$;
  private disableSectionDefaultStateOptions$ = window.types.disabledSectionColumnConfigDefaultStates$;

  public columnSectionKey: SectionColumnConfigKey;
  public columnSectionName: ColumnOptionName;
  public tooltip: string;

  public columnSectionKey$ = defer(() => of(this.columnSectionKey));

  public showColumnDataOptions$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigKey.Asset
        || key === SectionColumnConfigKey.SecondaryPrice
        || key === SectionColumnConfigKey.StrainType;
    })
  );

  public hideTextOptions$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigKey.Asset || key === SectionColumnConfigKey.Badges;
    })
  );

  public hideColumnName$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigKey.Asset;
    })
  );

  public columnDataOptionsLabel$ = this.columnSectionKey$.pipe(
    map(key => {
      if (key === SectionColumnConfigKey.Asset) {
        return 'Object Fit';
      } else if (key === SectionColumnConfigKey.SecondaryPrice) {
        return 'Use Value';
      } else if (key === SectionColumnConfigKey.StrainType) {
        return 'Data Layout';
      }
    })
  );

  public columnDataOptionsToolTipModalTitle$ = this.columnSectionKey$.pipe(
    map(key => {
      if (key === SectionColumnConfigKey.Asset) {
        return 'Object Fit Information';
      } else if (key === SectionColumnConfigKey.SecondaryPrice) {
        return 'Secondary Price Information';
      } else if (key === SectionColumnConfigKey.StrainType) {
        return 'Strain Type Layout';
      }
    })
  );

  public columnDataOptionsDropdowns$ = combineLatest([
    this.columnSectionKey$,
    this.container.theme$,
    window.types.assetColumnConfigDataValues$,
    window.types.secondaryPriceColumnConfigDataValues$,
    window.types.strainTypeColumnConfigDataValues$
  ]).pipe(
    map(([
      key,
      theme,
      assetDropdowns,
      secondaryPriceDropdowns,
      strainTypeDropdowns
    ]) => {
      if (key === SectionColumnConfigKey.Asset) {
        return assetDropdowns;
      } else if (key === SectionColumnConfigKey.SecondaryPrice) {
        return ThemeUtils.filterSecondaryPriceUseByForTheme(secondaryPriceDropdowns, theme?.id);
      } else if (key === SectionColumnConfigKey.StrainType) {
        return strainTypeDropdowns;
      }
    })
  );

  public columnDataOptionsToolTipModalItems$ = this.columnDataOptionsDropdowns$.pipe(
    map(dropdowns => {
      return dropdowns.map(m => new InformationItem(m?.name, m?.description));
    })
  );

  public themeColumnConfig: Map<SectionColumnConfigKey, ThemeSectionColumnConfig>;
  private setThemeDefaults = this.container.themeColumnConfig$.subscribe(themeColumnConfig => {
    this.themeColumnConfig = themeColumnConfig;
  });

  private selectableDefaultStateMap =
    new Map<SectionColumnConfigKey, Observable<SectionColumnConfigDefaultStateType[]>>([
      [SectionColumnConfigKey.Asset, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Badges, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Brand, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.StrainType, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Quantity, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Size, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.QuantityAndSize, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Stock, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.CBD, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.THC, this.autoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.Price, this.noAutoSectionDefaultStateOptions$],
      [SectionColumnConfigKey.SecondaryPrice, this.noAutoSectionDefaultStateOptions$]
    ]);

  public formColumnConfig$ = combineLatest([
    this.container.columnConfigMap$,
    this.container.sectionLayoutType$
  ]).pipe(
    map(([columnConfigMap, layoutType]) => {
      const columnConfig = columnConfigMap?.get(this.columnSectionKey);
      if (SectionColumnConfigKeyType.disabledForGridLayout(this.columnSectionKey, layoutType)) {
        columnConfig.defaultState = SectionColumnConfigDefaultState.Disabled;
      } else if (layoutType?.isGrid() && this.columnSectionKey === SectionColumnConfigKey.Price) {
        columnConfig.defaultState = SectionColumnConfigDefaultState.On;
      }
      return columnConfig;
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public showDisabledInGridModeMessage$ = this.container.sectionLayoutType$.pipe(
    map(layoutType => {
      return SectionColumnConfigKeyType.disabledForGridLayout(this.columnSectionKey, layoutType)
          || (this.columnSectionKey === SectionColumnConfigKey.Price && layoutType?.isGrid());
    })
  );

  public disabledInGridModeMessage$ = combineLatest([
    this.container.sectionLayoutType$,
    this.container.gridColumnCount$
  ]).pipe(
    map(([layoutType, gridColumnCount]) => {
      if (SectionColumnConfigKeyType.disabledForGridLayout(this.columnSectionKey, layoutType)) {
        let columnName = `${this.columnSectionKey}`;
        switch (this.columnSectionKey) {
          case SectionColumnConfigKey.SecondaryPrice:
            columnName = 'Secondary Price';
            break;
          case SectionColumnConfigKey.QuantityAndSize:
            columnName = 'Quantity x Size';
            break;
        }
        return `The ${columnName} column is disabled when the section is in Grid Mode.`;
      }
      return `This section has Grid Mode enabled, and there are ${gridColumnCount} `
        + 'active grid columns. Each grid column will be the width that is defined below.';
    })
  );

  private _changes = new BehaviorSubject<ConfigChanges>(null);
  private changes$ = this._changes as Observable<ConfigChanges>;
  private _changedDefaultState = new BehaviorSubject<SectionColumnConfigDefaultState>(null);
  public changedDefaultState$ = this._changedDefaultState as Observable<SectionColumnConfigDefaultState>;

  public minWidth$ = this.container.themeColumnConfig$.pipe(
    map(themeColumnConfig => themeColumnConfig?.get(this.columnSectionKey)?.minColumnWidth || 5)
  );
  public maxWidth$ = this.container?.themeColumnConfig$?.pipe(
    map(themeColumnConfig => themeColumnConfig?.get(this.columnSectionKey)?.maxColumnWidth || 75)
  );

  private themeDefaults$ = this.container.themeColumnConfig$.pipe(
    map(themeColumnConfig => themeColumnConfig?.get(this.columnSectionKey))
  );

  public defaultStatePlaceholder$ = combineLatest([
    this.themeDefaults$,
    this.container.managingDefault$
  ]).pipe(
    map(([themeDefaults, managingDefault]) => {
      if (managingDefault) {
        return 'Select Default State';
      }
      return themeDefaults?.defaultState?.toString() + ' (Theme default)';
    })
  );

  public sectionDefaultStateOptions$ = this.container.section$.pipe(
    switchMap(section => {
      if (SectionColumnConfigKeyType.disabledForGridLayout(this.columnSectionKey, section?.getLayoutType())) {
        return this.disableSectionDefaultStateOptions$;
      }
      return this.selectableDefaultStateMap?.get(this.columnSectionKey);
    }),
    withLatestFrom(this.defaultStatePlaceholder$),
    map(([defaultStateOptions, placeholder]) => {
      const unknownDefaultStateOpt = new SectionColumnConfigDefaultStateType(
        SectionColumnConfigDefaultState.Unknown,
        placeholder
      );
      return [...defaultStateOptions, unknownDefaultStateOpt];
    })
  );

  public columnNamePlaceHolder$ = this.themeDefaults$.pipe(
    map(themeDefaults => {
      const defaultColumnName = themeDefaults?.columnName;
      return !!(defaultColumnName) ? defaultColumnName + ' (Theme default)' : 'Enter a column name';
    })
  );

  public columnWidthPlaceholder$ = combineLatest([
    this.themeDefaults$,
    this.container.managingDefault$
  ]).pipe(
    map(([themeDefaults, managingDefault]) => {
      if (managingDefault) {
        return 'Enter Column Width';
      }
      return themeDefaults?.columnWidth?.toString() + ' (Theme default)';
    })
  );

  public useValuePlaceholder$ = combineLatest([
    this.themeDefaults$,
    this.container.managingDefault$
  ]).pipe(
    map(([themeDefaults, managingDefault]) => {
      if (managingDefault || !themeDefaults?.dataValue) {
        return 'Select Value to Use';
      }
      const name = window?.types?.initTypeDefinition(SectionColumnConfigDataValueType, themeDefaults?.dataValue)?.name;
      return `${name} (Theme default)`;
    })
  );

  public inputDisabled$ = combineLatest([
    this.formColumnConfig$,
    this.changedDefaultState$
  ]).pipe(
    map(([formColumnConfig, changedDefaultState]) => {
      let defaultState = formColumnConfig?.defaultState;
      if (!!changedDefaultState) {
        defaultState = changedDefaultState;
      }
      return !defaultState
          || defaultState === SectionColumnConfigDefaultState.Off
          || defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public widthRequired$ = combineLatest([
    this.inputDisabled$,
    this.container.managingDefault$
  ]).pipe(
    map(([inputDisabled, managingDefault]) => !inputDisabled && managingDefault)
  );

  public defaultStateInputDisabled$ = combineLatest([
    this.container.sectionLayoutType$,
    this.formColumnConfig$
  ]).pipe(
    map(([layoutType, formColumnConfig]) => {
      return (layoutType?.isGrid() && this.columnSectionKey === SectionColumnConfigKey.Price)
          || formColumnConfig?.defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public dropdownRequired$ = combineLatest([
    this.container.managingDefault$,
    this.defaultStateInputDisabled$
  ]).pipe(
    map(([managingDefault, defaultStateInputDisabled]) => {
      return managingDefault ? defaultStateInputDisabled : false;
    })
  );

  public colorPalette$ = this.container.colorPalette$;
  public hideTextColor$ = this.container.theme$.pipe(
    map(theme => {
      const strainTypeColumn = this.columnSectionKey === SectionColumnConfigKey.StrainType
        && ThemeUtils.themeIdsWithClassificationTextColorDisabled().contains(theme?.id);
      return this.columnSectionKey === SectionColumnConfigKey.Badges
          || this.columnSectionKey === SectionColumnConfigKey.Asset
          || strainTypeColumn;
    })
  );

  private changesHandler = this.changes$.subscribe(changes => {
    this.container.connectToColumnChanges({
      key: this.columnSectionKey,
      defaultState: changes?.defaultState,
      columnWidth: changes?.columnWidth
    });
  });

  connectToChanges = (changes: ConfigChanges) => {
    this._changes.next(changes);
  };

  connectToChangedDefaultState = (defaultState: SectionColumnConfigDefaultState) => {
    this._changedDefaultState.next(defaultState);
  };

}
