import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { EditMenuFooterActionsViewModel } from './edit-menu-footer-actions-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-menu-footer-actions',
  templateUrl: './edit-menu-footer-actions.component.html',
  styleUrls: [
    './edit-menu-footer-actions.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditMenuFooterActionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuFooterActionsComponent {

  constructor(
    public viewModel: EditMenuFooterActionsViewModel
  ) {
  }

  @Input() menu: Menu;
  @Input() allowLiveView: boolean = false;
  @Input() autoSaving: boolean = false;
  @Input() canDeleteTemplateMenu: boolean = true;
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() lastAutoSavedTimeText: string;
  @Input() showSaveButton: boolean = true;
  @Input() deleteMenu: () => void;
  @Input() openDuplicateMenuModal: () => void;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  @Output() submitForms = new EventEmitter<boolean>(true);

}
