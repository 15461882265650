import { SmartFilter } from './smart-filter';
import { Deserializable } from '../protocols/deserializable';
import { SelectableSmartFilter } from './protocols/selectable-smart-filter';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import type { Asset } from '../image/dto/asset';
import { SmartFilterCategory } from './smart-filter-category';
import { Pagable } from '../protocols/pagable';

export class HydratedSmartFilter extends SmartFilter
  implements Deserializable, SelectableSmartFilter, UniquelyIdentifiable, Pagable {

  public category: SmartFilterCategory;
  public appliedTemplateIds: string[];
  public appliedMenuIds: string[];
  public appliedVariantIds: string[];
  public appliedLabelIds: string[];
  public appliedBadgeIds: string[];
  public outOfStockVariantIds: string[];

  override onDeserialize() {
    super.onDeserialize();
    this.category = window?.injector?.Deserialize?.instanceOf(SmartFilterCategory, this.category);
    this.appliedMenuIds = Array.from(this.appliedMenuIds || []);
    this.appliedVariantIds = Array.from(this.appliedVariantIds || []);
  }

  getStandardizedCategoryName(): string {
    return this.category?.name?.trim()?.toLowerCase();
  }

  getSelectionName(): string {
    return this?.name;
  }

  getVariantHits(): number {
    return this?.appliedVariantIds?.length ?? 0;
  }

  getId(): string {
    return this.id;
  }

  some(filters: HydratedSmartFilter[]): boolean {
    const filterIds = filters?.map(filter => filter?.id);
    return filterIds?.some(id => id === this?.id);
  }

  override getUniqueIdentifier(): string {
    return super.getUniqueIdentifier().concat(
      `-${this.category?.getUniqueIdentifier()}
       -${this.appliedMenuIds?.sort()?.join(',')}
       -${this.appliedVariantIds?.sort()?.join(',')}
       -${this.appliedLabelIds?.sort()?.join(',')}
       -${this.appliedBadgeIds?.sort()?.join(',')}`
    );
  }

  getNumOfFilters(): number {
    return null;
  }

  getGroupingImage(): Asset {
    return null;
  }

  getGroupedFilters(): HydratedSmartFilter[] {
    return [this];
  }

  getStartKey(): string {
    return this.id;
  }

}
