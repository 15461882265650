import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';

@Pipe({
  name: 'isLabelStackMenu'
})
export class IsLabelStackMenuPipe implements PipeTransform {

  transform(value: any): Menu | null {
    return (value?.isPrintLabelMenu?.() ?? false) ? value : null;
  }

}
