import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditTemplateCardStackMenuViewModel } from './edit-template-card-stack-menu-view-model';
import { EditCardStackMenuViewModel } from '../../../../menu/components/edit-menu/edit-card-stack-menu/edit-card-stack-menu-view-model';
import { EditCardStackSectionViewModel } from '../../../../menu/components/edit-menu/edit-card-stack-menu/edit-card-stack-section-view-model';
import { EditTemplateCardStackMenuCombinedLoadingOptsViewModel } from './edit-template-card-stack-menu-combined-loading-opts-view-model';
import { EditTemplateMenuComponent } from '../edit-template-menu.component';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';

@Component({
  selector: 'app-edit-template-card-stack-menu',
  templateUrl: './edit-template-card-stack-menu.component.html',
  styleUrls: ['./edit-template-card-stack-menu.component.scss'],
  providers: [
    EditTemplateCardStackMenuViewModel,
    EditCardStackMenuViewModel,
    EditCardStackSectionViewModel,
    EditTemplateCardStackMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateCardStackMenuComponent extends EditTemplateMenuComponent implements ComponentCanDeactivate {

  constructor(
    public override viewModel: EditTemplateCardStackMenuViewModel,
    public combinedLoadingOptsViewModel: EditTemplateCardStackMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel);
  }

  @Input() mergeAndDispersedKey: string = 'editTemplateCardStackMenu';

}
