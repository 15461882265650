import { BehaviorSubject, combineLatest, defer, iif, Observable, of, Subject } from 'rxjs';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { Product } from '../../../../models/product/dto/product';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { SearchUtils } from '../../../../utils/search-utils';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import { Variant } from '../../../../models/product/dto/variant';
import { ProviderUtils } from '../../../../utils/provider-utils';
import { StrainClassification, StrainClassificationType } from '../../../../models/utils/dto/strain-classification-type';
import { VariantType, VariantTypeDefinition } from '../../../../models/utils/dto/variant-type-definition';
import { UsePurpose, UsePurposeType } from '../../../../models/utils/dto/use-purpose-type';
import { ProductType, ProductTypeDefinition } from '../../../../models/utils/dto/product-type-definition';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { PaginationUtils } from '../../../../utils/pagination-utils';
import { iiif } from '../../../../utils/observable.extensions';
import { SortUtils } from '../../../../utils/sort-utils';

// Provided by Logged In Scope
@Injectable()
export class FilterProductsFormViewModel extends BaseViewModel {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected productDomainModel: ProductDomainModel,
  ) {
    super();
    this.listenToInternalPipes();
  }

  /**
   * When true: consolidated products get removed from the product pool (products created from override product groups).
   * All sub products within all consolidated products get added back to the product pool.
   * When false: consolidated products (products created from override product groups) are visible in the product pool.
   */
  private _flattenAndThenRemoveProductGroupings = new BehaviorSubject<boolean>(false);
  public flattenAndThenRemoveProductGroupings$ = this._flattenAndThenRemoveProductGroupings as Observable<boolean>;
  connectToFlattenAndThenRemoveProductGroupings = (x: boolean) => this._flattenAndThenRemoveProductGroupings.next(x);

  public readonly overrideProductGroups$ = this.productDomainModel.currentLocationOverrideProductGroups$;

  public inventoryProvider$ = this.companyDomainModel.inventoryProvider$;
  public supportsMedRecProducts$ = this.inventoryProvider$.pipe(
    map(inventoryProvider => ProviderUtils.supportsMedRecProducts(inventoryProvider))
  );
  public inventoryProviderConfigs$ = this.companyDomainModel.inventoryProviderConfigs$;

  public displayAllRecMedControl$ = combineLatest([
    this.supportsMedRecProducts$,
    this.locationDomainModel.location$,
    this.productDomainModel.currentLocationProductsUsePurpose$,
  ]).pipe(
    map(([supportsRecMedAPIKeys, location, currentLocationProductsUsePurpose]) => {
      const posAndLocationSupportsAll = supportsRecMedAPIKeys && location?.usePurpose === UsePurpose.ALL;
      const currentLocationProductsSupportsAll = currentLocationProductsUsePurpose === UsePurpose.ALL;
      return posAndLocationSupportsAll || currentLocationProductsSupportsAll;
    })
  );

  protected _products: BehaviorSubject<Product[]|null> = new BehaviorSubject<Product[]>(null);
  public productPoolFromTableInput$ = iiif(
    this.flattenAndThenRemoveProductGroupings$,
    combineLatest([
      this._products,
      this.productDomainModel.consolidatedProductIds$,
      this.productDomainModel.overridedProducts$
    ]).pipe(
      switchMap(([products, consolidatedProductIds, overridedProducts]) => {
        if (!products) return of(null);
        const isConsolidatedProduct = (p: Product) => consolidatedProductIds?.includes(p?.id);
        const productList = [
          ...(products?.filter(p => !isConsolidatedProduct(p)) || []),
          ...(overridedProducts || [])
        ];
        const productMapping = new Map<string, Product>();
        productList?.forEach(p => productMapping.set(p?.id, p));
        return SortUtils.productNameSortWorker(productMapping, 3).pipe(
          map(sortedIds => sortedIds?.map(id => productMapping?.get(id)))
        );
      })
    ),
    this._products as Observable<Product[]>
  );

  public readonly usePurposeOptions$ = window?.types?.usePurposes$.pipe(
    map((ups) => {
      const upsCopy = window?.injector?.Deserialize.arrayOf(UsePurposeType, ups);
      if (upsCopy?.length) upsCopy[0].selected = true;
      return upsCopy;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected getVariantsFilteredByMedAndRec(p: Product, filterByUse: (v: Variant) => boolean): Variant[] {
    return filterByUse ? p?.variants?.filter(filterByUse) : p?.variants;
  }

  protected _usePurpose: BehaviorSubject<string> = new BehaviorSubject<UsePurpose>(UsePurpose.ALL);
  public selectedUsePurpose$ = this._usePurpose.asObservable();
  public productPoolFilteredByUsePurpose$ = combineLatest([
    this.productPoolFromTableInput$,
    this.selectedUsePurpose$,
  ]).pipe(
    map(([products, usePurpose]) => {
      let filterByUse: (v: Variant) => boolean = null;
      const filterByUsePurpose = {
        [UsePurpose.MEDICAL]: () => filterByUse = (v: Variant) => v?.isMedical,
        [UsePurpose.RECREATION]: () => filterByUse = (v: Variant) => !v?.isMedical,
      };
      filterByUsePurpose?.[usePurpose]?.();
      products?.forEach(p => {
        // This is done on purpose. This saves us from having to do a deep copy of the product data
        // in order to change the variants array on each product. This is an order of magnitude faster.
        // The downside is that it can be a little confusing. The getVariantsFilteredByMedAndRec method
        // is overridden in "All Products Table" and "Product Picker" contexts.
        // The "All Products Table" needs to chain off of variantsThatMeetSmartFilterSearch, while the
        // "Product Picker" needs to chain off of the base products variants.
        p.variantsFilteredByMedAndRec = this.getVariantsFilteredByMedAndRec(p, filterByUse);
      });
      return products;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  protected _searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchText$ = this._searchText.pipe(
    debounceTime(350),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  public programmaticallySetSearchText$ = this.searchText$.pipe(take(1));

  protected _hideOutOfStockProducts = new BehaviorSubject<boolean>(true);
  public hideOutOfStockProducts$ = this._hideOutOfStockProducts as Observable<boolean>;

  protected _hideRecentlyUpdatedProductsMode = new BehaviorSubject<boolean>(false);
  public hideRecentlyUpdatedProductsMode$ = this._hideRecentlyUpdatedProductsMode as Observable<boolean>;

  protected _hideRecentlyUpdatedProducts = new BehaviorSubject<boolean>(true);
  public hideRecentlyUpdatedProducts$ = this._hideRecentlyUpdatedProducts as Observable<boolean>;

  protected _recentlyUpdatedTimeWindowInSeconds = new BehaviorSubject<number>(0);
  public recentlyUpdatedTimeWindowInSeconds$ = this._recentlyUpdatedTimeWindowInSeconds as Observable<number>;

  public _selectedProductId = new BehaviorSubject<string>('');
  public selectedProductId$ = this._selectedProductId as Observable<string>;

  public _tellOutsideComponentsToClearFilters = new Subject<void>();
  public tellOutsideComponentsToClearFilters$ = this._tellOutsideComponentsToClearFilters as Observable<void>;

  public displaysUnitsInRanges$ = this.companyDomainModel?.rangeCannabinoidsAtCompanyLevel$;
  public locationId$ = this.locationDomainModel.locationId$;
  public priceFormat$ = this.locationDomainModel.priceFormat$;

  /**
   * Filters the products using fuse on a background thread. Returns the filtered products as a list of ids.
   */
  private productSearcher(
    [productData, productOptions, variantOptions, searchText]: [Product[], any[], any[], string]
  ): Observable<string[]> {
    return new Observable(subscriber => {
      const myWorker = new Worker(
        new URL('./../../../../worker/product-searcher.worker', import.meta.url),
        { type: 'module', name: 'sorter' }
      );
      myWorker.onmessage = result => {
        subscriber.next(result?.data);
        subscriber.complete();
        myWorker.terminate();
      };
      myWorker.postMessage({ productData, productOptions, variantOptions, searchText });
    });
  }

  private getProductSearchPipeline(
    [products, range, pOpts, vOpts, searchText]: [Product[], boolean, any[], any[], string]
  ): Observable<Product[]> {
    return defer(() => {
      const searchableProducts = products?.map(p => SearchUtils.getSearchableProduct(p, range));
      return this.productSearcher([searchableProducts, pOpts, vOpts, searchText]).pipe(
        map((productIds: string[]) => products?.filter(p => productIds?.includes(p?.id))),
        take(1)
      );
    });
  }

  private productsFilteredBySearchStringAndOutOfStock$ = combineLatest([
    this.productPoolFilteredByUsePurpose$,
    this.searchText$.pipe(map(s => s?.stripWhiteSpaceAndLowerCase()), distinctUntilChanged()),
    this.hideOutOfStockProducts$,
    this.displaysUnitsInRanges$,
    this.displaysUnitsInRanges$.pipe(map(range => SearchUtils.getFuseOptionsForProduct(range))),
    this.displaysUnitsInRanges$.pipe(map(range => SearchUtils.getFuseOptionsForVariants(range))),
  ]).pipe(
    debounceTime(100),
    switchMap(([productPool, text, hideOutOfStockProducts, range, pOpts, vOpts]) => {
      const prods = hideOutOfStockProducts
        ? productPool?.filter(p => p?.variantsFilteredByMedAndRec?.some(v => v?.inStock()))
        : productPool?.filter(p => p?.variantsFilteredByMedAndRec?.length);
      return iif(() => text?.length > 1, this.getProductSearchPipeline([prods, range, pOpts, vOpts, text]), of(prods));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /* ************************* Filter by Product Type ************************* */

  public selectableProductTypes$: Observable<ProductTypeDefinition[]> = combineLatest([
    this.productsFilteredBySearchStringAndOutOfStock$,
    window.types.productTypes$
  ]).pipe(
    map(([productPool, selectableProductTypes]) => {
      const selectedProductTypes = productPool
        ?.flatMap((p) => p.variantsFilteredByMedAndRec)
        ?.map(s => s.productType)
        ?.unique();
      return selectableProductTypes?.filter(selectable => selectedProductTypes?.includes(selectable.value));
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public productTypeDisabled$ = this.selectableProductTypes$.pipe(
    map(selectableProductTypes => !selectableProductTypes || selectableProductTypes?.length === 0)
  );

  protected _filterByProductType = new BehaviorSubject<ProductType|null>(null);
  public filterByProductType$: Observable<ProductType> = this._filterByProductType.pipe(distinctUntilChanged());

  public filterByProductTypeClearable$ = combineLatest([this.filterByProductType$, this.selectableProductTypes$]).pipe(
    map(([filterByProductType, selectableProductTypes]) => !!filterByProductType && selectableProductTypes?.length > 1)
  );

  private _programmaticallySetFilterByProductType = new Subject<ProductType>();
  public programmaticallySetFilterByProductType$ = this.filterByProductType$.pipe(
    take(1),
    debounceTime(250),
    switchMap(initialValue => {
      if (!!initialValue) {
        return this._programmaticallySetFilterByProductType.pipe(startWith(initialValue));
      } else {
        return this._programmaticallySetFilterByProductType;
      }
    })
  );

  /* *************** Filter Products by Product/Variant Type ************** */

  private productsFilteredByProductType$ = combineLatest([
    this.productsFilteredBySearchStringAndOutOfStock$,
    this.filterByProductType$,
  ]).pipe(
    map(([products, filterByProductType]) => {
      let filteredProducts = products;
      if (!!filterByProductType) {
        filteredProducts = filteredProducts?.filter(p => p?.getProductTypes()?.contains(filterByProductType));
      }
      return filteredProducts;
    })
  );

  // Filter by Variant Type

  public selectableVariantTypes$: Observable<VariantTypeDefinition[]> = combineLatest([
    this.productsFilteredByProductType$,
    this.filterByProductType$,
    window?.types?.variantTypes$
  ]).pipe(
    map(([products, productFilter, allVariantTypes]) => {
        const selectedVariantTypes = products
          ?.flatMap((p) => p.variantsFilteredByMedAndRec)
          ?.filter((v) => !v.productType || v.productType === productFilter)
          ?.map((v) => v.variantType)
          ?.unique();
        return allVariantTypes?.filter((vt) => selectedVariantTypes?.includes(vt.value));
      }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public variantTypeDisabled$ = combineLatest([
    this.selectableVariantTypes$,
    this.filterByProductType$
  ]).pipe(
    map(([selectableVariantTypes, productType]) => {
      return !selectableVariantTypes || selectableVariantTypes?.length === 0 || !productType;
    })
  );

  protected _filterByVariantType = new BehaviorSubject<VariantType>(null);
  public filterByVariantType$ = this._filterByVariantType.pipe(distinctUntilChanged());
  public filterByVariantTypeClearable$ = combineLatest([this.filterByVariantType$, this.selectableVariantTypes$]).pipe(
    map(([filterByVariantType, selectableVariantTypes]) => !!filterByVariantType && selectableVariantTypes?.length > 1)
  );

  private _programmaticallySetFilterByVariantType = new Subject<VariantTypeDefinition>();
  public programmaticallySetFilterByVariantType$ = this.filterByVariantType$.pipe(
    take(1),
    debounceTime(400),
    switchMap(initialValue => {
      if (!!initialValue) {
        return this._programmaticallySetFilterByVariantType.pipe(startWith(initialValue));
      } else {
        return this._programmaticallySetFilterByVariantType;
      }
    })
  );

  // Filter Products by Product and Variant Type
  private productsFilteredByProductTypeAndVariantType$ = combineLatest([
    this.productsFilteredByProductType$,
    this.filterByVariantType$
  ]).pipe(
    map(([products, filterByVariantType]) => {
      let filteredProducts = products;
      if (!!filterByVariantType) {
        filteredProducts = filteredProducts?.filter(p => p?.getVariantTypes()?.contains(filterByVariantType));
      }
      return filteredProducts;
    })
  );

  /* *************** Filter Products by Product/Variant/Strain Type ************** */

  public selectableStrainTypes$: Observable<StrainClassificationType[]> = combineLatest([
    this.productsFilteredByProductTypeAndVariantType$,
    this.filterByProductType$,
    this.filterByVariantType$,
    window?.types?.strainTypes$
  ]).pipe(
    map(([products, productFilter, variantFilter, selectableStrainTypes]) => {
      const selectedVariants = products?.flatMap((p) => p.variantsFilteredByMedAndRec)
        ?.filter((v) => !productFilter || v.productType === productFilter)
        ?.filter((v) => !variantFilter || v.variantType === variantFilter);
      const selectedVariantStrainTypes = selectedVariants?.map((v) => v.classification)?.unique();
      return selectableStrainTypes?.filter((s) => selectedVariantStrainTypes?.includes(s.value)) ?? [];
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public strainTypeDisabled$ = this.selectableStrainTypes$.pipe(
    map(selectableStrainTypes => !selectableStrainTypes || selectableStrainTypes?.length === 0)
  );
  protected _filterByStrainType = new BehaviorSubject<StrainClassification|null>(null);
  public filterByStrainType$ = this._filterByStrainType.pipe(distinctUntilChanged());
  public productsFilteredByProductTypeVariantTypeStrainType$ = combineLatest([
    this.productsFilteredByProductTypeAndVariantType$,
    this.filterByProductType$,
    this.filterByVariantType$,
    this.filterByStrainType$
  ]).pipe(
    map(([filteredProducts, productType, variantType, strainType]) => {
      if (!!strainType) {
        filteredProducts = filteredProducts?.filter(p => p?.getStrainClassifications()?.contains(strainType));
      }
      filteredProducts?.forEach((p) => {
        p.variantsFilteredByTable = this.getVariantsFilteredByTable(p, productType, variantType, strainType);
      });
      return filteredProducts;
    })
  );

  protected getVariantsFilteredByTable(
    p: Product,
    productType: ProductType,
    variantType: VariantType,
    strainType: StrainClassification
  ) {
    return p?.variantsFilteredByMedAndRec
      ?.filter(v => !productType || v.productType === productType)
      ?.filter(v => !variantType || v.variantType === variantType)
      ?.filter(v => !strainType || v.classification === strainType);
  }

  public variantsFilteredByProductTypeVariantTypeStrainTypeRecentlyUpdated$ = combineLatest([
    this.productsFilteredByProductTypeVariantTypeStrainType$,
    this.hideRecentlyUpdatedProductsMode$,
    this.hideRecentlyUpdatedProducts$,
    this.recentlyUpdatedTimeWindowInSeconds$
  ]).pipe(
    map(([filteredProducts, hideRecentMode, hideRecentlyUpdatedProducts, timeWindowInSeconds]) => {
      let variants = filteredProducts?.flatMap(p => p?.variantsFilteredByTable);
      if (hideRecentMode && hideRecentlyUpdatedProducts) {
        variants = variants?.filter(v => !v?.displayAttributes?.recentlyBulkUpdated(timeWindowInSeconds));
      }
      return variants;
    })
  );

  public filterByStrainTypeClearable$ = combineLatest([this.filterByStrainType$, this.selectableStrainTypes$]).pipe(
    map(([filterByStrainType, selectableStrainTypes]) => !!filterByStrainType && selectableStrainTypes?.length > 1)
  );

  private _programmaticallySetFilterByStrainType = new Subject<StrainClassification>();
  public programmaticallySetFilterByStrainType$ = this.filterByStrainType$.pipe(
    take(1),
    debounceTime(550),
    switchMap(initialValue => {
      if (!!initialValue) {
        return this._programmaticallySetFilterByStrainType.pipe(startWith(initialValue as StrainClassification));
      } else {
        return this._programmaticallySetFilterByStrainType;
      }
    })
  );

  public readonly hasFilters$ = combineLatest([
    this.filterByProductType$,
    this.filterByVariantType$,
    this.filterByStrainType$
  ]).pipe(
    map(([productType, variantType, strainType]) => !!productType || !!variantType || !!strainType)
  );

  /* ************************************************************************ */

  protected _numberOfProductsPerPage = new BehaviorSubject<number>(10);
  public numberOfProductsPerPage$ = defer(() => this._numberOfProductsPerPage);
  public nProductsPerPageOptions$ = new BehaviorSubject(PaginationUtils.constructResultsPerPageOptions([10, 25]));

  private listenToInternalPipes(): void {
    // listen to selectable product types and then programmatically set filter by product type
    // based on product types
    this.selectableProductTypes$.pipe(
      debounceTime(1)
    ).subscribeWhileAlive({
      owner: this,
      next: productFilters => {
        if (productFilters?.length === 1) {
          this._programmaticallySetFilterByProductType.next(productFilters?.firstOrNull()?.value);
        } else {
          this._filterByProductType.next(null);
          this._programmaticallySetFilterByProductType.next(null);
        }
      }
    });
    // listen to filtered by product type and selectable variant types, and then set filter by variant type
    // based on those values
    combineLatest([
      this.filterByProductType$,
      this.selectableVariantTypes$,
    ]).pipe(debounceTime(1)).subscribeWhileAlive({
      owner: this,
      next: ([filterByProductType, variantFilters]) => {
        if (!!filterByProductType && variantFilters?.length === 1) {
          this._programmaticallySetFilterByVariantType.next(variantFilters?.firstOrNull());
        } else {
          this._filterByVariantType.next(null);
          this._programmaticallySetFilterByVariantType.next(null);
        }
      }
    });
    // listen to variant filters and selectable strain types, then programmatically set filter by strain type
    // based on those values
    combineLatest([
      this.filterByVariantType$,
      this.selectableStrainTypes$
    ]).pipe(debounceTime(1)).subscribeWhileAlive({
      owner: this,
      next: ([filterByVariantType, strainFilters]) => {
        if (!!filterByVariantType && strainFilters?.length === 1) {
          this._programmaticallySetFilterByStrainType.next(
            strainFilters?.firstOrNull()?.getSelectionValue() as StrainClassification
          );
        } else {
          this._filterByStrainType.next(null);
          this._programmaticallySetFilterByStrainType.next(null);
        }
      }
    });
  }

  public productClicked(product: Product): void {
    this.selectedProductId$.pipe(take(1)).subscribe(currentlySelectedId => {
      if (currentlySelectedId === product?.id) this._selectedProductId.next('');
      else this._selectedProductId.next(product.id);
    });
  }

  public toggleRecentlyUpdatedProducts(): void {
    this._hideRecentlyUpdatedProducts.next(!this._hideRecentlyUpdatedProducts.value);
  }

  // RxJS Connectors

  public setProducts = (products: Product[]|null): void => this._products.next(products);
  public setUsePurpose = (usePurposeOption: SegmentedControlOption[]): void => {
    const updatedValue = usePurposeOption?.find(it => it?.selected)?.value;
    if (updatedValue) this._usePurpose.next(usePurposeOption?.find(it => it?.selected)?.value);
  };
  public setSearchText = (searchText: string): void => this._searchText.next(searchText);
  public setHideRecentlyUpdatedProductsMode = (on: boolean): void => this._hideRecentlyUpdatedProductsMode.next(on);
  public setHideRecentlyUpdatedProducts = (value: boolean): void => this._hideRecentlyUpdatedProducts.next(value);
  public setRecentlyUpdatedTimeWindow = (value: number): void => this._recentlyUpdatedTimeWindowInSeconds.next(value);
  public setFilterByProductType = (productType: ProductType): void => this._filterByProductType.next(productType);
  public setFilterByVariantType = (variantType: VariantType): void => this._filterByVariantType.next(variantType);
  public setFilterByStrainType = (strainType: StrainClassification): void => this._filterByStrainType.next(strainType);
  public setNumberOfProductsPerPage = (value: number): void => this._numberOfProductsPerPage.next(value);
  public setHideOutOfStockProducts = (value: boolean): void => this._hideOutOfStockProducts.next(value);
  public clearExternalFilters = (): void => this._tellOutsideComponentsToClearFilters.next();

}
