import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-edit-menu-warning-banner',
  templateUrl: './edit-menu-warning-banner.component.html',
  styleUrls: ['./edit-menu-warning-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuWarningBannerComponent {

  @Input() warningMessage: string;
  @Input() tooltipMessage: string;

}
