import { distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SmartPrintUtils } from '../../../../../../../utils/smart-print-utils';
import { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { Injectable } from '@angular/core';

@Injectable()
export class StackSmartBulkPrintOptionsFormViewModel {

  private _stackType = new BehaviorSubject<StackType>(null);
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (stackType: StackType) => this._stackType.next(stackType);

  public readonly showNewProductsCheckbox$ = SmartPrintUtils.showNewProductsCheckbox$(this.stackType$);
  public readonly showInventoryRestockedCheckbox$ = SmartPrintUtils.showInventoryRestockedCheckbox$(this.stackType$);
  public readonly showPriceChangeCheckbox$ = SmartPrintUtils.showPriceChangeCheckbox$(this.stackType$);
  public readonly showProductInfoChangeCheckbox$ = SmartPrintUtils.showProductInfoChangeCheckbox$(this.stackType$);
  public readonly showCannabinoidChangeCheckbox$ = SmartPrintUtils.showCannabinoidChangeCheckbox$(this.stackType$);
  public readonly showCustomizationChangeCheckbox$ = SmartPrintUtils.showCustomizationChangeCheckbox$(this.stackType$);
  public readonly showMenuChangeCheckbox$ = SmartPrintUtils.showMenuChangeCheckbox$(this.stackType$);

}
