<div class="featured-category-container mt-3">
  <div fxLayout="column" fxLayoutAlign="start" (click)="editSection()">
    <div class="title f20px" fxFlex fxLayout="row" [style.cursor]="'pointer'">
      <div
        class="featured-card-number"
        [style.color]="viewModel.cardNumberTextColor$ | async"
        [style.background-color]="viewModel.cardNumberBackgroundColor$ | async">
        {{ viewModel?.cardNumber$ | async }}.
      </div>
      {{ section.title ? section.title : 'Unnamed' }}
    </div>
    <div class="variant-count f14px" fxFlex fxLayout="row">
      {{ section.enabledVariantIds.length + ' Variants' }}
    </div>
  </div>

  <div *ngIf="viewModel.hasSmartFilters$ | async" class="smart-filter-details-container">
    <app-smart-filter-small-sync-button
      *ngIf="!templateMode"
      appThrottleClick
      [syncing]="viewModel.section$ | isSectionSyncingSmartFilters | async"
      [formPristine]="formPristine"
      [timestamp]="(viewModel.section$ | async)?.lastSmartFilterSync"
      [disabledThrottleClick]="(viewModel.section$ | isSectionSyncingSmartFilters | push) || !formPristine"
      (throttleClick)="sharedViewModel.syncSectionSmartFilters(menu, section)">
    </app-smart-filter-small-sync-button>
    <app-smart-filters-indicator [nSmartFilters]="(viewModel.section$ | async)?.smartFilterIds?.length">
    </app-smart-filters-indicator>
  </div>
  <div class="bs-section-body-buttons mt-5" fxLayout="row" fxLayoutGap="1rem">
    <lib-button-inline-text [disabled]="sharedViewModel?.autoSaving$ | async" (buttonClicked)="editSection()">
      Edit
    </lib-button-inline-text>

    <lib-button-inline-text
      [disabled]="
        (sharedViewModel?.autoSaving$ | async) || (sharedViewModel?.isTemplatedMenu$ | async) || !formPristine
      "
      [disabledTooltip]="
        (sharedViewModel?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="this.duplicateClicked.emit(section)">
      Duplicate
    </lib-button-inline-text>

    <lib-button-inline-text
      [textColor]="'#FA5555'"
      [disabled]="
        (sharedViewModel?.autoSaving$ | async) || (sharedViewModel?.isTemplatedMenu$ | async) || !formPristine
      "
      [disabledTooltip]="
        (sharedViewModel?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="this.deleteClicked.emit(section)">
      Delete
    </lib-button-inline-text>
  </div>
</div>
