<div class="modal-body">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>
  <div class="bs-section-title mt-16px">Change Password</div>
  <div class="change-password-subheader">{{ viewModel.userFullName$ | push }}</div>
  <hr class="mt-28px mb-24px" />
  <!-- Form -->
  <lib-reactive-form-group
    #form
    [bindTo]="viewModel.req"
    (unsavedChanges$)="viewModel.connectToUnsavedChanges($event)"
    (formSubmitted$)="viewModel.formSubmitted(viewModel?.req)">
    <lib-reactive-form-password
      [inputName]="'oldPassword'"
      [required]="true"
      [label]="'Old Password'"
      [placeholder]="'Old Password'"
      [bindingProperty]="'oldPassword'">
    </lib-reactive-form-password>
    <lib-reactive-form-password
      [inputName]="'password'"
      [required]="true"
      [creatingOrUpdatingPassword]="true"
      [confirmPassword]="true"
      [label]="'New Password'"
      [placeholder]="'New Password'"
      [bindingProperty]="'newPassword'">
    </lib-reactive-form-password>
    <lib-reactive-form-password
      [inputName]="'confirmPassword'"
      [required]="true"
      [creatingOrUpdatingPassword]="true"
      [confirmPassword]="true"
      [label]="'Confirm New Password'"
      [placeholder]="'Retype your new password.'">
    </lib-reactive-form-password>
  </lib-reactive-form-group>
  <hr class="mb-32px" />
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1rem">
    <lib-button-neutral (buttonClicked)="cancel()">Cancel</lib-button-neutral>
    <lib-button-primary [disabled]="form.disableSubmit$ | async" (buttonClicked)="form.submitForm()">
      Change Password
    </lib-button-primary>
  </div>
</div>
