import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { SelectMenuOrCollectionResultsComponent } from '../select-menu-or-collection-results.component';

@Component({
  selector: 'app-menu-results',
  templateUrl: './menu-results.component.html',
  styleUrls: ['./menu-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuResultsComponent extends SelectMenuOrCollectionResultsComponent {

  @Input() collectionMode: boolean = false;
  @Input() draftTemplateTooltip: string;
  @Input() override searchedItems: Menu[];

}
