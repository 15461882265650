<div class="loading-container">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
  <div class="media-container">
    <div class="preview-wrapper">
      <!--   Loading spinner for refresh image   -->
      <app-loading
        [hidden]="!(viewModel?.isRefreshingMediaLoading$ | async)"
        [options]="viewModel?.refreshingMediaLoadingOpts$ | async">
      </app-loading>
      <!--   Overlap wrapper for disabled items   -->
      <div [ngClass]="{ disabled: !(viewModel?.groupMediaEnabled$ | async) }" class="image-overlay"></div>
      <app-asset
        class="preview"
        [scaleFit]="true"
        [borderRadius]="'0.625rem'"
        [style.max-width]="'200px'"
        [showControls]="true"
        [asset]="file"
        (duration)="listenForDuration($event)">
      </app-asset>
    </div>

    <div class="media-info-container flex-fill">
      <div *ngIf="(viewModel.file$ | async) && !(viewModel.replaceMediaSwitch$ | async)" class="ms-5">
        <app-section-media-form
          [mergeKey]="mergeKey"
          [bindTo]="viewModel.menu$ | async"
          [file]="viewModel?.file$ | async"
          [sectionId]="viewModel?.sectionId$ | async"
          [mediaDuration]="viewModel?.mediaDuration$ | async"
          [isTemplatedMenu]="viewModel.isDisabledByTemplates$ | async"
          [groupMediaEnabled]="viewModel.groupMediaEnabled$ | async">
        </app-section-media-form>
      </div>

      <div *ngIf="!(viewModel?.file$ | async) || (viewModel?.replaceMediaSwitch$ | async)">
        <app-upload-asset
          [parentHandler]="this"
          [allowImage]="true"
          [allowVideo]="true"
          [isDisabledByTemplates]="isDisabledByTemplates"
          [maxAssets]="1"
          [displayList]="false"
          [id]="index + 5">
        </app-upload-asset>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end" [style.gap.rem]="0.5">
    <lib-button-inline-text
      [hidden]="!(viewModel.fileIsUploaded$ | async)"
      [disabled]="(viewModel?.isRefreshingMediaLoading$ | async) || !formPristine"
      [disabledTooltip]="formPristine | saveChangesTooltip"
      (buttonClicked)="refreshMedia()">
      <img alt="" src="assets/icons/dark/outline/refresh.svg" style="margin-right: 0" />
    </lib-button-inline-text>
    <lib-button-inline-text
      *ngIf="viewModel.fileIsUploaded$ | async"
      [disabled]="(viewModel?.isDisabledByTemplates$ | async) || !formPristine"
      [disabledTooltip]="
        (viewModel?.isDisabledByTemplates$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="viewModel.toggleReplaceMediaSwitch()">
      {{ (viewModel?.replaceMediaSwitch$ | async) ? 'Cancel' : 'Replace Media' }}
    </lib-button-inline-text>
    <lib-button-inline-text
      *ngIf="!!destructiveButtonText"
      [textColor]="'#FA5555'"
      [disabled]="
        !(sharedViewModel?.allowDelete$ | async) || (viewModel?.isDisabledByTemplates$ | async) || !formPristine
      "
      [disabledTooltip]="
        (viewModel?.isDisabledByTemplates$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="openRemoveMediaDialog()">
      {{ destructiveButtonText }}
    </lib-button-inline-text>
  </div>
  <hr *ngIf="bottomDivider" />
</div>
