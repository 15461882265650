<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="dispersedAndMergeKey"
  (formsAutoSubmitted$)="viewModel.saveSection(true)"
  (formsSubmitted$)="viewModel.saveSection(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-section-container>
  <app-edit-menu-section-header
    [crumbs]="viewModel.breadcrumbs$ | async"
    [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
    [headerText]="'Edit Product Grouping'"
    [lastAutoSavedText]="viewModel.lastAutoSavedTimeText$ | async"
    [disableSubmit]="formCapture.disableSubmit$ | async"
    [disableSectionNavOptionIds]="viewModel.disabledSectionNavOptionIds$ | async"
    [removingVariants]="viewModel.removingVariants$ | async"
    [quickChangeSectionTitle]="'Go to Product Group'"
    [section]="viewModel.section$ | async"
    [sectionNavOptions]="viewModel.sectionNavOptions$ | async"
    [userIsTemplateAdmin]="viewModel.userIsTemplateAdmin$ | async"
    [editTemplateSectionLinkText]="'Edit Template Product Group'"
    [allowLiveView]="viewModel.allowLiveView$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [showLiveView]="viewModel.showLiveView"
    (submitForms)="formCapture.submitForms($event)"
    (editTemplateSectionClicked)="viewModel.navigateToEditTemplateSection($event)"
    (handleDropdownSelection)="viewModel.handleDropdownSelection($event)">
  </app-edit-menu-section-header>

  <app-edit-smart-playlist-menu-section-body
    [templateMode]="false"
    [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
    [dispersedAndMergeKey]="dispersedAndMergeKey"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async">
  </app-edit-smart-playlist-menu-section-body>

  <app-edit-menu-section-footer
    [autoSavingLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
    [lastAutoSaveText]="viewModel.lastAutoSavedTimeText$ | async"
    [formDisabledSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [removingVariants]="viewModel.removingVariants$ | async"
    [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
    (deleteSection)="deleteSection()"
    (duplicateSection)="viewModel.openDuplicateSectionModal()"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-menu-section-footer>
</app-edit-menu-section-container>
