<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <input hidden #input [formControlName]="inputName" [id]="id" [name]="inputName" />

  <app-smart-filters-section
    [smartDataType]="types.SmartDataType.Labels"
    [smartFilterIds]="smartFilterIds$ | async"
    (smartFilterRemoved)="smartFilterRemoved($event)"
    (smartFiltersAdded)="smartFiltersAdded($event)">
  </app-smart-filters-section>
</div>
