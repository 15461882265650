import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-all-card-stack-print-jobs-header',
  templateUrl: './all-card-stack-print-jobs-header.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllCardStackPrintJobsHeaderComponent {

  @Input() cardStackName: string = '';

}
