<div class="preview-section">
  <div class="preview-header">Preview</div>
  <div class="preview-terpenes-info-container">
    <ng-container *rxFor="let totalTerpeneInfo of viewModel.totalTerpenePreviewInfo$ | push">
      <div class="preview-terpene">
        <div class="preview-terpene-headers">{{ totalTerpeneInfo?.title }}</div>
        <div class="preview-terpene-info">
          {{ totalTerpeneInfo?.value }}
        </div>
        <div class="terpene-source">
          {{ totalTerpeneInfo?.source }}
        </div>
      </div>
    </ng-container>
    <div *rxIf="viewModel.hasEnabledTerpenes$ | push" class="top-terpene-preview-container">
      <ng-container *rxFor="let topTerpeneInfo of viewModel.topTerpenePreviewInfo$ | push">
        <div class="preview-terpene">
          <div class="preview-terpene-headers">{{ topTerpeneInfo?.title }}</div>
          <div class="preview-terpene-info">
            {{ topTerpeneInfo?.value }}
          </div>
          <div class="terpene-source">
            {{ topTerpeneInfo?.source }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="individual-terpenes-previews-container">
      <ng-container *rxFor="let terpeneInfo of viewModel.individualTerpenesPreviewInfo$ | push">
        <div class="preview-terpene">
          <div class="preview-terpene-headers">{{ terpeneInfo?.title }}</div>
          <div class="preview-terpene-info">
            {{ terpeneInfo?.value }}
          </div>
          <div class="terpene-source">
            {{ terpeneInfo?.source }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
