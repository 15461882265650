<div class="preview-section">
  <div class="preview-header">Preview</div>
  <div class="preview-cannabinoids-info-container">
    <ng-container *rxFor="let primaryCannabinoidInfo of viewModel.primaryCannabinoidPreviewInfo$ | push">
      <div class="preview-cannabinoid">
        <div class="preview-cannabinoid-headers">{{ primaryCannabinoidInfo?.title }}</div>
        <div class="preview-cannabinoid-info">
          {{ primaryCannabinoidInfo?.value }}
        </div>
        <div class="cannabinoid-source">
          {{ primaryCannabinoidInfo?.source }}
        </div>
      </div>
    </ng-container>
    <div class="secondary-cannabinoids-previews-container">
      <ng-container *rxFor="let secondaryCannabinoidInfo of viewModel.secondaryCannabinoidPreviewInfo$ | push">
        <div class="preview-cannabinoid">
          <div class="preview-cannabinoid-headers">{{ secondaryCannabinoidInfo?.title }}</div>
          <div class="preview-cannabinoid-info">
            {{ secondaryCannabinoidInfo?.value }}
          </div>
          <div class="cannabinoid-source">
            {{ secondaryCannabinoidInfo?.source }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
