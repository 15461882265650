<div
  class="card-number-container card-number"
  fxLayout
  fxLayoutAlign="center center"
  #parent
  [ngStyle]="{
    'background-color': changeBackgroundColor ? card?.getCardColor() || '#FFFFFF' : '#FFFFFF'
  }">
  <div
    class="card-number"
    appFitText
    [parentContainer]="parent"
    [percentageOfParent]="0.55"
    [ngStyle]="{
      color: getAutomaticNumberTextColor()
    }">
    {{ index + 1 }}
  </div>
</div>
