import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../models/company/dto/location';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';

@Pipe({
  name: 'getLocationsAsSelection'
})
export class GetLocationsAsSelectionPipe implements PipeTransform {

  transform(locations: Location[]): HasChildIds {
    return new class implements HasChildIds {

      getId = (): string => 'not relevant';
      getChildIds = (): string[] => locations?.map(location => location?.id.toString(10)) ?? [];

    }();
  }

}
