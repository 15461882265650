import { Injectable } from '@angular/core';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { Asset } from '../../../../../models/image/dto/asset';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class UploadProfilePictureViewModel extends BaseModalViewModel {

  existingProfilePicture: Asset;
  file: BudsenseFile;
  protected override _loadingOpts = new BehaviorSubject(this.getLoadingSpinner());

  public override dismissModalSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private userDomainModel: UserDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = 'Uploading';
    opts.isLoading = false;
    opts.fullscreen = false;
    return opts;
  }

  uploadProfilePicture() {
    const lm = 'Uploading Profile Picture';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.userDomainModel.uploadProfilePicture(this.file).notNull().subscribe({
        next: (_) => {
          this._loadingOpts.removeRequest(lm);
          this.dismissModalSubject.next(true);
          this.toastService.publishSuccessMessage('Profile picture successfully uploaded.', 'Upload Profile Picture');
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(error);
        }
      });
    }
  }

  deleteProfilePicture() {
    const lm = 'Deleting Profile Picture';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.userDomainModel.deleteProfilePicture().subscribe({
          next: () => {
            this._loadingOpts.removeRequest(lm);
            this.dismissModalSubject.next(true);
            this.toastService.publishSuccessMessage('Profile picture successfully deleted.', 'Delete Profile Picture');
          },
          error: (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
    }
  }

}
