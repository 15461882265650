<div class="card bs-card" [ngClass]="cardClass" [ngStyle]="{ 'background-image': card.getBackgroundUrl() }">
  <div class="bs-card-label">
    <div class="bs-card-label-title">
      {{ card.label }}
    </div>
    <div class="bs-card-label-text">
      {{ card.text }}
    </div>
  </div>
</div>
