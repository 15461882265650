import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Product } from '../../../../../../../../../models/product/dto/product';
import { expandCollapseAnimation } from '../../../../../../../../shared/animations/expand-collapse.animation';
import { CardStackPrintJobSelectIndividualProductViewModel } from './card-stack-print-job-select-individual-product-view-model';
import { BaseComponent } from '../../../../../../../../../models/base/base-component';

@Component({
  selector: 'app-card-stack-print-job-select-individual-product',
  templateUrl: './card-stack-print-job-select-individual-product.component.html',
  styleUrls: [
    './card-stack-print-job-select-individual-product.component.scss',
    '../stack-print-job-products-form.component.scss'
  ],
  providers: [CardStackPrintJobSelectIndividualProductViewModel],
  animations: [expandCollapseAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardStackPrintJobSelectIndividualProductComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: CardStackPrintJobSelectIndividualProductViewModel
  ) {
    super();
  }

  @Input() cardStackVariantIds: string[];
  @Input() product: Product; // product is a shallow copy with updated variant list
  @Input() productExpanded: boolean;
  @Input() isGridMode: boolean = false;
  @Input() selectedIds: string[];
  @Input() gridColumnNames: string[];
  @Input() viewOnly: boolean = false;

  @Output() addClicked = new EventEmitter<string>();
  @Output() removeClicked = new EventEmitter<string>();
  @Output() bulkAddClicked = new EventEmitter<string[]>();
  @Output() bulkRemoveClicked = new EventEmitter<string[]>();
  @Output() productClicked = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product) this.viewModel.connectToProduct(this.product);
    if (changes.isGridMode) this.viewModel.connectToIsGridMode(this.isGridMode);
    if (changes.gridColumnNames) this.viewModel.connectToGridColumnNames(this.gridColumnNames);
  }

  public variantClicked(id: string): void {
    if (this.viewOnly) return;
    this.selectedIds.includes(id) ? this.removeClicked.emit(id) : this.addClicked.emit(id);
  }

}
