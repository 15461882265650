<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <input hidden #input [formControlName]="inputName" [id]="id" [name]="inputName" />
  <div [fxLayout]="inlineLabel ? 'row' : 'column'">
    <lib-reactive-form-label
      [fxFlex]="inlineLabel ? this.inlinePercentage : null"
      [inputId]="id"
      [inputName]="inputName"
      [inputEnabled]="!disabled"
      [label]="label"
      [inlineLabel]="inlineLabel"
      [hideLabel]="hideLabel"
      [tooltip]="tooltip"
      [required]="required"
      [showRequiredAstrix]="showRequiredAstrix"
      (clickEvent)="$event.preventDefault(); $event.stopPropagation()">
    </lib-reactive-form-label>
    <app-range-slider
      [disabled]="sliderDisabled$ | async"
      [disabledTooltip]="disabledTooltip$ | async"
      [options]="options"
      (valueChanged)="sliderValueChanged($event)">
    </app-range-slider>
  </div>
</div>
