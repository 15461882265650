import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';

@Component({
  selector: 'app-print-menu-bulk-print-job',
  templateUrl: './print-menu-bulk-print-job.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintMenuBulkPrintJobComponent {

  @Input() job: BulkPrintJob;
  @Input() mergeKey: string;
  @Input() placeholder: string;
  @Input() viewOnly: boolean;

}
