import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';
import { TerpeneAddEditSmartFilterFormViewModel } from './terpene-add-edit-smart-filter-form-view-model';
import { BaseSmartFilterFormComponent } from '../base-smart-filter-form';

@Component({
  selector: 'app-terpene-add-edit-smart-filter-form',
  templateUrl: './terpene-add-edit-smart-filter-form.component.html',
  styleUrls: ['./terpene-add-edit-smart-filter-form.component.scss'],
  providers: [TerpeneAddEditSmartFilterFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerpeneAddEditSmartFilterFormComponent extends BaseSmartFilterFormComponent implements OnChanges {

  constructor(
    public viewModel: TerpeneAddEditSmartFilterFormViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) this.viewModel.connectToSmartFilter(this.bindTo);
    if (changes.isEditing) this.viewModel.connectToIsEditing(this.isEditing);
    if (changes.isViewOnly) this.viewModel.connectToIsViewOnly(this.isViewOnly);
  }

}
