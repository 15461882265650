import { Injectable } from '@angular/core';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { DisplayDomainModel } from '../../../../../domainModels/display-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PendingDisplay } from '../../../../../models/template/dto/pending-display';
import { Display } from '../../../../../models/display/dto/display';

@Injectable()
export class EditCollectionSaveConfirmationViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private displayDomainModel: DisplayDomainModel
  ) {
  }

  public allLocations$ = this.locationDomainModel.allLocations$;
  public allCompanyDisplays$ = this.displayDomainModel.allCompanyDisplays$;

  private _originalRequiredDisplayIds = new BehaviorSubject<string[]>(null);
  public originalRequiredDisplayIds$ = this._originalRequiredDisplayIds as Observable<string[]>;
  connectToOriginalRequiredDisplayIds = (ids: string[]) => this._originalRequiredDisplayIds.next(ids);

  private _updatedRequiredDisplayIds = new BehaviorSubject<string[]>(null);
  public updatedRequiredDisplayIds$ = this._updatedRequiredDisplayIds as Observable<string[]>;
  connectToUpdatedRequiredDisplayIds = (ids: string[]) => this._updatedRequiredDisplayIds.next(ids);

  private _pendingDisplay = new BehaviorSubject<PendingDisplay>(null);
  public pendingDisplay$ = this._pendingDisplay as Observable<PendingDisplay>;
  connectToPendingDisplay = (pd: PendingDisplay) => this._pendingDisplay.next(pd);

  public allChangedDisplays$ = combineLatest([
    this.originalRequiredDisplayIds$,
    this.updatedRequiredDisplayIds$,
    this.pendingDisplay$,
    this.allCompanyDisplays$
  ]).pipe(
    map(([originalRequiredDisplayIds, updatedRequiredDisplayIds, pd, allDisplays]) => {
      const addedRequiredDisplayIds = updatedRequiredDisplayIds?.filter((id) => {
        return !originalRequiredDisplayIds?.includes(id);
      });
      const removedRequiredDisplayIds = !!updatedRequiredDisplayIds && originalRequiredDisplayIds?.filter((id) => {
        return !updatedRequiredDisplayIds?.includes(id);
      });
      const changedRequiredDisplayIds = [
        ...(addedRequiredDisplayIds || []),
        ...(removedRequiredDisplayIds || [])
      ].unique();
      const changedRequiredDisplays = Display.getDisplaysFromIds(changedRequiredDisplayIds, allDisplays);
      const pendingDisplays = Display.createDisplaysFromPendingDisplay(pd);
      return [...(changedRequiredDisplays || []), ...(pendingDisplays || [])];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public changedDisplaysGroupedByLocationAndProvince$ = combineLatest([
    this.allLocations$,
    this.allChangedDisplays$
  ]).pipe(
    map(([allLocations, changedDisplays]) => {
      return Display.groupDisplaysByLocationAndProvince(changedDisplays, allLocations);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public numberOfProvinces$ = this.changedDisplaysGroupedByLocationAndProvince$.pipe(
    map((displayLocationGroups) => displayLocationGroups?.provinces?.length)
  );

}
