import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { delay, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';
import { SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../../../../../../../models/utils/dto/section-column-config-default-state-type';

@Component({
  selector: 'app-menu-layout-options-form',
  templateUrl: './menu-layout-options-form.component.html',
  styleUrls: ['./menu-layout-options-form.component.scss']
})
export class MenuLayoutOptionsFormComponent extends MenuAdditionalOptionsFormComponent implements OnChanges {

  @Input() templateMode = false;
  @Input() colorPalette: string[];

  constructor() {
    super();
  }

  private _menu = new BehaviorSubject<Menu>(null);
  private menu$ = this._menu as Observable<Menu>;

  private _bodyBackgroundColor = new BehaviorSubject<string>('');
  public bodyBackgroundColor$ = this._bodyBackgroundColor as Observable<string>;
  connectToBodyBackgroundColor = (bodyBackgroundColor: string) => {
    this._bodyBackgroundColor.next(bodyBackgroundColor);
  };

  public disableCannabisUnitOfMeasureInHeader$ = this.menu$.pipe(
    map(menu => {
      const sectionColumnConfig = menu?.hydratedTheme?.sectionColumnConfig;
      const thcDefaultState = sectionColumnConfig?.get(SectionColumnConfigKey.THC)?.defaultState;
      const cbdDefaultState = sectionColumnConfig?.get(SectionColumnConfigKey.CBD)?.defaultState;
      return thcDefaultState === SectionColumnConfigDefaultState.Disabled
        && cbdDefaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public disableCannabisUOMInHeaderTooltip$ = this.isTemplatedMenu$.pipe(
    map((isTemplatedMenu) => {
      return isTemplatedMenu
        ? 'This property may only be edited on the template'
        : 'This option is disabled when THC and CBD columns are both disabled on the theme';
    })
  );

  public disableBackgroundOpacity$ = combineLatest([
    this.isTemplatedMenu$,
    this.bodyBackgroundColor$
  ]).pipe(
    map(([isTemplatedMenu, bodyBackgroundColor]) => isTemplatedMenu || !bodyBackgroundColor)
  );
  public disableBackgroundOpacityTooltip$: Observable<string|null> = combineLatest([
    this.isTemplatedMenu$,
    this.disableBackgroundOpacity$
  ]).pipe(
    map(([isTemplatedMenu, disableBackgroundOpacity]) => {
      switch (true) {
        case disableBackgroundOpacity && !isTemplatedMenu:
          return 'A background color must be set';
        case isTemplatedMenu:
          return 'This property may only be edited on the template';
      }
      return null;
    })
  );

  public resetOpacityWithBodyBackgroundColor$ = this.bodyBackgroundColor$.pipe(
    switchMap(bodyBackgroundColor => {
      let setValue$: Observable<undefined|number> = of(undefined);
      if (!bodyBackgroundColor) setValue$ = setValue$.pipe(delay(100), startWith(100));
      return setValue$;
    })
  );

  public hydratedTheme$ = this.menu$.pipe(
    map(m => m?.hydratedTheme),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public hideCUOMInHeaderToggle$ = this.menu$.pipe(map(m => m?.isSpotlightMenu()));

  public themeSupportsBlurredBackground$ = this.hydratedTheme$.pipe(
    map(t => !!t?.themeFeatures?.blurredBackground)
  );

  public themeSupportsBackgroundOpacity$ = this.hydratedTheme$.pipe(
    map(t => !!t?.themeFeatures?.backgroundOpacity)
  );

  public themeSupportsTitleTextColor$ = this.hydratedTheme$.pipe(
    map(t => !!t?.themeFeatures?.titleTextColor)
  );

  public themeSupportsBodyTextColor$ = this.hydratedTheme$.pipe(
    map(t => !!t?.themeFeatures?.bodyTextColor)
  );

  public themeSupportsBodyBackgroundColor$ = this.hydratedTheme$.pipe(
    map(t => !!t?.themeFeatures?.bodyBackgroundColor)
  );

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.menu) {
      this._menu.next(this.menu);
      this._bodyBackgroundColor.next(this.menu?.menuOptions?.bodyBackgroundColor);
    }
  }

}
