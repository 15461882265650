import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Router } from '@angular/router';
import { NavSubItemViewModel } from './nav-sub-item-view-model';
import { NavItem } from '../nav-item/models/nav-item';
import { NavSubItem } from './models/nav-sub-item';

@Component({
  selector: 'app-nav-sub-item',
  templateUrl: './nav-sub-item.component.html',
  styleUrls: ['./nav-sub-item.component.scss'],
  providers: [NavSubItemViewModel]
})
export class NavSubItemComponent extends BaseComponent implements OnChanges {

  @Input() navItem: NavItem;
  @Input() subItem: NavSubItem;
  @Input() currentNavUrl: string;

  constructor(
    public viewModel: NavSubItemViewModel,
    protected router: Router,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.connectToNavItem(this.navItem);
    this.viewModel.connectToSubNavItem(this.subItem);
    this.viewModel.connectToCurrentNavUrl(this.currentNavUrl);
  }

  navItemSelected() {
    const extras = { fragment: this.subItem?.savedFragment };
    this.router.navigate([`${this.navItem?.routerPath}/${this.subItem?.relativeRouterPath}`], extras).then(() => {});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navItem) this.viewModel.connectToNavItem(this.navItem);
    if (changes.subItem) this.viewModel.connectToSubNavItem(this.subItem);
    if (changes.currentNavUrl) this.viewModel.connectToCurrentNavUrl(this.currentNavUrl);
  }

}
