import { Injectable } from '@angular/core';
import { CardStackDetailsSectionViewModel } from '../../edit-card-stack-menu/card-stack-details-section/card-stack-details-section-view-model';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LabelStackDetailsSectionViewModel extends CardStackDetailsSectionViewModel {

  public printLabelSize$ = combineLatest([this.menu$, window?.types?.printLabelSizeTypes$]).pipe(
    map(([menu, labelSizes]) => {
      const printCardSize = menu?.metadata?.printCardSize;
      return labelSizes?.find(labelSize => labelSize?.value === printCardSize);
    })
  );

  public override title$ = of('Stack Details');
  public override description$ = of('View and edit basic information about this stack.');

}
