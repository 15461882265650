import { Pipe, PipeTransform } from '@angular/core';
import { ReactiveFormCheckboxComponent } from '@mobilefirstdev/reactive-form';

@Pipe({
  name: 'isReactiveFormCheckboxSelected'
})
export class IsReactiveFormCheckboxSelectedPipe implements PipeTransform {

  transform(value: ReactiveFormCheckboxComponent): boolean {
    return value?.isSelected() || false;
  }

}
