import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { ProductDomainModel } from '../../../../../../../../domainModels/product-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { map, shareReplay } from 'rxjs/operators';
import { SearchUtils } from '../../../../../../../../utils/search-utils';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';

@Injectable()
export class SingleStackManualPrintJobViewModel extends BaseViewModel {

  constructor(private productDomainModel: ProductDomainModel) {
    super();
  }

  private _job = new BehaviorSubject<BulkPrintJob>(null);
  public job$ = this._job as Observable<BulkPrintJob>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  public jobVariants$: Observable<Variant[]> = combineLatest([
    this.job$,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([job, allVariants]) => {
      const previewJob = job?.previewJobs?.firstOrNull();
      const appliedVariantIds = previewJob?.cardStackPrintConfig?.variantIds;
      return allVariants?.filter(v => appliedVariantIds?.includes(v.id));
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchableVariants$ = this.jobVariants$.pipe(
    map(variants => variants?.map(v => SearchUtils.getSimpleSearchableVariant(v))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
