import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { StackPrintType } from '../../../../../../../models/automation/enum/card-stack-print-type.enum';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { PrintJobViewModel } from '../../../../../../shared/modals/create-view-bulk-print-job/print-job-view-model';
import { UserDomainModel } from '../../../../../../../domainModels/user-domain-model';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { CardStackPrintConfig } from '../../../../../../../models/automation/card-stack-print-config';
import { BulkJobSource } from '../../../../../../../models/utils/dto/bulk-job-source-type';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { BulkPrintJobDomainModel } from '../../../../../../../domainModels/bulk-print-job-domain-model';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { BsError } from '../../../../../../../models/shared/bs-error';
import { SmartFiltersDomainModel } from '../../../../../../../domainModels/smart-filters-domain-model';
import type { StackType } from './create-view-stack-print-job.component';

export enum CardStackPrintJobStep {
  ChoosePrintType = 0,
  EditDetails = 1
}

@Injectable()
export class CreateViewStackPrintJobViewModel extends PrintJobViewModel {

  constructor(
    ngbModal: NgbModal,
    router: Router,
    userDomainModel: UserDomainModel,
    activeModal: NgbActiveModal,
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    companyDomainModel: CompanyDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    smartFiltersDomainModel: SmartFiltersDomainModel,
    templateDomainModel: TemplateDomainModel,
    toastService: ToastService,
  ) {
    super(
      ngbModal,
      router,
      userDomainModel,
      activeModal,
      bulkPrintJobDomainModel,
      companyDomainModel,
      locationDomainModel,
      menuDomainModel,
      smartFiltersDomainModel,
      templateDomainModel,
      toastService,
    );
  }

  public mergeKey$ = of('create-stack-print-job');

  override connectToBulkPrintJob(bulkPrintJob: BulkPrintJob) {
    this.menuId$.once((menuId) => {
      const filteredJob = this.filterJobToCurrentCardStackData(bulkPrintJob, menuId);
      const printConfig = filteredJob?.cardStackPrintConfigMap?.get(menuId);
      const printType = printConfig?.printType;
      if (printType) this.connectToSelectedCardStackPrintType(printType);
      super.connectToBulkPrintJob(filteredJob);
    });
  }

  private _stackType = new BehaviorSubject<StackType>('card');
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (type: StackType) => this._stackType.next(type);

  private _step = new BehaviorSubject<CardStackPrintJobStep>(CardStackPrintJobStep.ChoosePrintType);
  private step$ = this._step as Observable<CardStackPrintJobStep>;

  public stepIsChooseOption$ = this.step$.pipe(map(s => s === CardStackPrintJobStep.ChoosePrintType));
  public stepIsEditDetails$ = this.step$.pipe(map(s => s === CardStackPrintJobStep.EditDetails));

  private _reprintMode = new BehaviorSubject<boolean>(false);
  public reprintMode$ = this._reprintMode as Observable<boolean>;
  connectToReprintMode = (reprintMode: boolean) => this._reprintMode.next(reprintMode);

  public showGoBackButton$ = combineLatest([this.stepIsEditDetails$, this.viewOnly$, this.reprintMode$]).pipe(
    map(([isEditDetails, viewOnly, reprintMode]) => isEditDetails && !viewOnly && !reprintMode)
  );

  private _selectedCardStackPrintType = new BehaviorSubject<StackPrintType | null>(null);
  public selectedCardStackPrintType$ = this._selectedCardStackPrintType as Observable<StackPrintType | null>;
  connectToSelectedCardStackPrintType = (pt: StackPrintType) => {
    this._selectedCardStackPrintType.next(pt);
    this._step.next(CardStackPrintJobStep.EditDetails);
  };

  public selectedPrintTypeIsManual$ = this.selectedCardStackPrintType$.pipe(
    map(pt => pt?.toLowerCase()?.includes('manual'))
  );

  public selectedPrintTypeIsSmart$ = this.selectedCardStackPrintType$.pipe(
    map(pt => pt?.toLowerCase()?.includes('smart'))
  );

  public fixedHeightModal$ = combineLatest([this.selectedPrintTypeIsManual$, this.viewOnly$]).pipe(
    map(([isManual, viewOnly]) => isManual && !viewOnly)
  );

  public title$ = combineLatest([this.step$, this.selectedCardStackPrintType$, this.viewOnly$]).pipe(
    map(([step, printType, viewOnly]) => {
      if (step === CardStackPrintJobStep.ChoosePrintType) return 'Create Print Job';
      const prefix = viewOnly ? 'View' : '';
      const printTypeText = printType?.toLowerCase()?.includes('smart') ? 'Smart Print' : 'Manual Print';
      const suffix = viewOnly ? 'Job' : '';
      return `${prefix} ${printTypeText} ${suffix}`.trim();
    })
  );

  private filterJobToCurrentCardStackData(bulkPrintJob: BulkPrintJob, stackId: string): BulkPrintJob {
    bulkPrintJob.menuIds = [stackId];
    const cardStackPrintConfig = bulkPrintJob?.cardStackPrintConfigMap?.get(stackId);
    bulkPrintJob.cardStackPrintConfigMap = new Map([[stackId, cardStackPrintConfig]]);
    const cardStackPreviewJob = bulkPrintJob?.previewJobs?.find(pj => pj.menuId === stackId);
    bulkPrintJob.previewJobs = [cardStackPreviewJob];
    return bulkPrintJob;
  }

  protected override constructNewBulkPrintJob(): void {
    combineLatest([
      this.templateMode$,
      this.companyId$,
      this.locationId$,
      this.menuId$.notNull(),
      this.userId$,
    ]).once(([templateMode, companyId, locationId, menuId, userId])  => {
      const newJob = window?.injector?.Deserialize?.instanceOf(BulkPrintJob, {});
      newJob.locationId = locationId;
      newJob.companyId = companyId;
      newJob.recipientIds = [userId];
      newJob.menuIds = templateMode ? [] : [menuId];
      newJob.templateIds = templateMode ? [menuId] : [];
      newJob.cardStackPrintConfigMap.set(menuId, new CardStackPrintConfig());
      newJob.jobSource = BulkJobSource.BulkJobSource_EditCardStack;
      this.connectToBulkPrintJob(newJob);
    });
  }

  public override createBulkPrintJob() {
    combineLatest([
      this.selectedCardStackPrintType$,
      this.bulkPrintJob$,
      this.menuId$
    ]).once(([printType, bulkPrintJob, cardStackMenuId]) => {
      const printConfig = bulkPrintJob.cardStackPrintConfigMap.get(cardStackMenuId);
      printConfig.printType = printType;
      const lmCreatePrintJob = 'Creating Bulk Print Job';
      const lmSyncSmartFilters = 'Syncing Smart Filters';
      this._loadingOpts.addRequest(lmCreatePrintJob);
      this._loadingOpts.addRequest(lmSyncSmartFilters);
      this.bulkPrintJobDomainModel.bulkPrintJobContainsStackedContentAtMenuLevel(bulkPrintJob).pipe(
        switchMap(containsStackedContent => {
          const async = !containsStackedContent;
          return this.smartFiltersDomainModel.syncSmartFiltersBeforePrintJob(bulkPrintJob?.menuIds, async).pipe(
            tap(() => this._loadingOpts.removeRequest(lmSyncSmartFilters))
          );
        }),
        switchMap(() => this.bulkPrintJobDomainModel.createBulkPrintJob(bulkPrintJob))
      ).subscribe({
        complete: () => {
          this.bulkPrintJobDomainModel.getActiveMenuBulkPrintJobs();
          this.toastService.publishSuccessMessage('Bulk Print Job Created', 'Success');
          this.activeModal.close(null);
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(lmCreatePrintJob);
          this._loadingOpts.removeRequest(lmSyncSmartFilters);
          this.toastService.publishError(err);
        }
      });
    });
  }

  public handlePrimaryButtonClicked(printJobForm: ReactiveFormMergeGroupsComponent): void {
    this.viewOnly$.once((viewOnly) => {
      if (viewOnly) {
        this.activeModal.close();
      } else {
        printJobForm.submitForms();
      }
    });
  }

  public handleGoBack(): void {
    this._step.next(CardStackPrintJobStep.ChoosePrintType);
  }

}
