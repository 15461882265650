// noinspection JSUnusedLocalSymbols

import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { PrintUtils } from '../../../utils/print-utils';
import { EditMenuViewModel } from './edit-menu-view-model';

@Injectable()
export class EditProductMenuViewModel extends EditMenuViewModel implements OnDestroy {

  public readonly breadcrumbs$ = this.menu$.pipe(
    map(menu => [
      menu?.getViewAllBreadcrumb(false),
      menu?.getEditBreadcrumb(true)
    ])
  );

  public readonly headerText$ = this.menu$.pipe(
    map(menu => {
      return menu?.isPrintReportMenu() ? 'Edit Report' : 'Edit Product Menu';
    })
  );

  public readonly headerSubtext$ = this.menu$.pipe(
    map(menu => {
      return menu?.isPrintReportMenu()
        ? 'Keep your team up to date on the latest product info with easy to read reports.'
        : 'Create visually stunning menus built specifically for your in-store displays.';
    })
  );

  public readonly printClicked = new Subject<void>();

  /* *************************** Local Threads of Execution *************************** */

  private listenForPrintClicks = this.printClicked
    .pipe(
      switchMap(() => {
        return combineLatest([
          this.activeUserDownloadableMenuData$,
          this.activeUserDownloadableDataUrl$,
          this.menu$
        ]).pipe(take(1));
      })
    )
    .subscribeWhileAlive({
      owner: this,
      next: ([preview, url, menu]) => PrintUtils.printMenuPDF(this.sanitizer, menu, preview, url)
    });

  private checkIfPrintPreviewNeedsRefresh = this.menu$
    .pipe(filter(menu => menu?.isPrintableMenu()))
    .subscribeWhileAlive({
      owner: this,
      next: activeMenu => {
        this.activeUserDownloadableMenuData$.once(preview => {
          if (preview?.over5MinutesOld()) {
            this.refreshUserDownloadableMenuData();
          }
        });
      }
    });

  private checkForUnSyncedPrintReportSections = this.menu$.notNull().pipe(
    filter(menu => menu?.isPrintReportMenu()),
    take(1),
    switchMap(menu => {
      const sections = menu?.sections;
      const unsyncedSections = sections
        ?.filter(s => s?.lastSmartFilterSync === 0)
        ?.map(s => this.menuDomainModel.syncSectionSmartFilters(menu, s));
      return forkJoin(unsyncedSections);
    })
  ).once();

/* ********************************************************************************* */

  public printPreviewClicked() {
    this.activeUserDownloadableMenuData$.once(preview => {
      if (preview?.over5MinutesOld()) {
        this.toastService.publishWarningMessage('Refreshing…', 'Old PDF');
        this.refreshUserDownloadableMenuData();
      } else {
        this.printClicked.next();
      }
    });
  }

}
