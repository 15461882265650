<div class="modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | push">
  <div *ngIf="viewModel.container.showPOSSyncBanner$ | push" class="terpene-disabled-banner">
    <app-lot-info-banner
      [inventoryProvider]="viewModel.container.inventoryProvider$ | push"
      [displayAttribute]="viewModel.container.displayAttribute$ | push"
      [selectedTabId]="ClientTypes.EditVariantModalTabId.Terpenes"></app-lot-info-banner>
  </div>
  <div class="terpenes-form">
    <div class="terpenes-input">
      <div class="form-section-title">Total Terpenes</div>
      <lib-reactive-form-group [bindTo]="viewModel.container.variant$ | push" [mergeKey]="'editVariantFormCapture'">
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-drop-down
            [inputName]="'terpeneUnitOfMeasure'"
            [bindingProperty]="'terpeneUnitOfMeasure'"
            [label]="'Terpene Unit Of Measure'"
            [required]="true"
            [placeholder]="'Select terpene unit of measure'"
            [tooltip]="viewModel.TUOMTooltip$ | push"
            [dropdowns]="viewModel.terpeneUnitOfMeasureOptions$ | push"
            [disabled]="viewModel.container.syncTerpeneFromPOS$ | push"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.tuomSelectionChanged($event)"></lib-reactive-form-drop-down>
          <lib-reactive-form-switch
            *ngIf="viewModel.container.showVariantUseRangeSwitch$ | push"
            [inputName]="'useTerpeneRange'"
            [bindingProperty]="'useTerpeneRange'"
            [label]="'Use Terpene Range'"
            [tooltip]="
              'Specific products can be enabled to use terpene range. ' +
              'This is useful for multi-pack products with varying terpenes.'
            "
            [disabled]="!(viewModel.container.isCompanyAdmin$ | push)"
            [disabledTooltip]="!(viewModel.container.isCompanyAdmin$ | push) | notAnAdminTooltip"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.container.setVariantUsesRange($event)"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
      <app-terpenes-form
        [terpene]="'Total Terpene'"
        [terpeneCamelCased]="'totalTerpene'"
        [terpenePascalCased]="'TotalTerpene'"
        [pluralizeLabel]="true"
        [mergeKey]="'editVariantFormCapture'"></app-terpenes-form>

      <ng-container *rxIf="viewModel.hasEnabledTerpenes$ | push; else EnableTerpenesMessage">
        <div class="form-section-title">Top Terpene</div>

        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-group
            [bindTo]="viewModel.container.variantCompanyDisplayAttribute$ | push"
            [mergeKey]="'editVariantFormCapture'">
            <lib-reactive-form-drop-down
              (valueChanged)="viewModel.companyDAUpdated($event, 'topTerpene')"
              [inputName]="'companyTopTerpene'"
              [label]="'Company Top Terpene'"
              [placeholder]="'Auto (default)'"
              [bindingProperty]="'topTerpene'"
              [dropdowns]="viewModel.enabledTerpenes$ | push"></lib-reactive-form-drop-down>
          </lib-reactive-form-group>

          <lib-reactive-form-group
            [bindTo]="viewModel.container.variantLocationDisplayAttribute$ | push"
            [mergeKey]="'editVariantFormCapture'">
            <lib-reactive-form-drop-down
              (valueChanged)="viewModel.locationDAUpdated($event, 'topTerpene')"
              [inputName]="'locationTopTerpene'"
              [label]="'Location Top Terpene'"
              [placeholder]="'Auto (default)'"
              [bindingProperty]="'topTerpene'"
              [dropdowns]="viewModel.enabledTerpenes$ | push"></lib-reactive-form-drop-down>
          </lib-reactive-form-group>
        </lib-reactive-form-column-layout>
        <div class="form-section-title">Terpene Values</div>
        <ng-container *rxFor="let terpeneNames of viewModel.enabledTerpeneNames$ | push">
          <app-terpenes-form
            [terpene]="terpeneNames?.terpeneName"
            [terpeneCamelCased]="terpeneNames?.camelCased"
            [terpenePascalCased]="terpeneNames?.pascalCased"
            [pluralizeLabel]="false"
            [mergeKey]="'editVariantFormCapture'"></app-terpenes-form>
        </ng-container>
      </ng-container>

      <ng-template #EnableTerpenesMessage>
        <div class="secondary-container">
          <div class="secondary-message">
            Individual Terpenes can be enabled within Settings > Products > Cannabinoids & Terpenes. Once enabled they
            will appear here (ie: Myrcene, Limonene, Linalool)
          </div>
          <lib-button-neutral
            *rxIf="viewModel.container.isCompanyAdmin$ | push"
            [iconSrc]="'assets/icons/dark/outline/cog.svg'"
            (buttonClicked)="editProductSettings()">
            Product Settings
          </lib-button-neutral>
        </div>
      </ng-template>
    </div>
    <app-terpenes-preview></app-terpenes-preview>
  </div>
</div>
