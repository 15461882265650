import { Pipe, PipeTransform } from '@angular/core';
import { CompactTemplateCollection } from '../../../models/template/dto/compact-template-collection';

@Pipe({
  name: 'continueIfCompactTemplateCollection'
})
export class ContinueIfCompactTemplateCollectionPipe implements PipeTransform {

  transform(value: any): CompactTemplateCollection {
    return (value instanceof CompactTemplateCollection) ? value : null;
  }

}
