import { Component, Input, OnChanges } from '@angular/core';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';

@Component({
  selector: 'app-menu-media-options-form',
  templateUrl: './menu-media-options-form.component.html',
  styleUrls: ['./menu-media-options-form.component.scss']
})
export class MenuMediaOptionsFormComponent extends MenuAdditionalOptionsFormComponent implements OnChanges {

  constructor() {
    super();
  }

  @Input() templateMode = false;

}
