import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { OverrideProductGroup } from '../models/product/dto/override-product-group';
import { CreateProductGroupingModalComponent } from '../views/settings/components/modals/create-product-grouping-modal/create-product-grouping-modal.component';

export class ModalCreateOverrideProductGrouping {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    onClose?: (overrideProductGroup: OverrideProductGroup) => void
  ) {
    const modalRef = ngbModal.open(
      CreateProductGroupingModalComponent,
      ModalUtils.createOverrideProductGroupingModalOptions(injector)
    );
    modalRef.result
      .then((createdGroup) => onClose?.(createdGroup))
      .catch(() => {});
  }

}
