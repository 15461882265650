import { Deserializable } from '../../protocols/deserializable';

export class ResetPasswordRequest implements Deserializable {

  constructor(
    public email: string = '',
    public code: string = ''
  ) {}

  public password: string = '';
  onDeserialize = () => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-authenticator/blob/dev/models/ResetForgottenPasswordRequest.go
  onSerialize() {
    const dto =  Object.create(ResetPasswordRequest.prototype);
    dto.email = this.email;
    dto.code = this.code;
    dto.password = this.password;
    return dto;
  }

}
