import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { MenuDomainModel } from '../../../../domainModels/menu-domain-model';
import { DisplayAttributesDomainModel } from '../../../../domainModels/display-attributes-domain-model';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { SmartFilterUtils } from '../../../../utils/smart-filter-utils';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';
import { LabelDomainModel } from '../../../../domainModels/label-domain-model';
import { BadgeDomainModel } from '../../../../domainModels/badge-domain-model';
import { LoadingOptions } from '../../../../models/shared/loading-options';

@Injectable()
export class SmartFilterAppliedOnPillsViewModel extends BaseViewModel {

  constructor(
    private badgeDomainModel: BadgeDomainModel,
    private menuDomainModel: MenuDomainModel,
    private displayAttributeDomainModel: DisplayAttributesDomainModel,
    private labelDomainModel: LabelDomainModel,
    private templateDomainModel: TemplateDomainModel
  ) {
    super();
    this.initializePillLoadingState();
  }

  private readonly menuTemplates$ = this.templateDomainModel.menuTemplates$;
  private readonly currentLocationMenus$ = this.menuDomainModel.currentLocationMenus$;
  private readonly allBadges$ = this.badgeDomainModel.allBadges$;
  private readonly allCompanyLabels$ = this.labelDomainModel.allCompanyLabels$;

  private _smartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public smartFilter$ = this._smartFilter as Observable<HydratedSmartFilter>;
  connectToSmartFilter = (sf: HydratedSmartFilter) => this._smartFilter.next(sf);

  private _isClickable = new BehaviorSubject<boolean>(null);
  public isClickable$ = this._isClickable as Observable<boolean>;
  connectToIsClickable = (c: boolean) => this._isClickable.next(c);

  public appearsOnPills$ = combineLatest([
    this.smartFilter$.notNull(),
    this.menuTemplates$.notNull(),
    this.currentLocationMenus$.pipe(
      distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
    ).notNull(),
    this.allBadges$.notNull(),
    this.allCompanyLabels$.notNull(),
    this.isClickable$
  ]).pipe(
    map(([sf, templates, locationMenus, allBadges, allLabels, isClickable]) => {
      const appliedTemplates = templates?.filter(t => sf?.appliedTemplateIds?.includes(t.id));
      const appliedMenus = locationMenus?.filter(m => sf?.appliedMenuIds?.includes(m.id));
      const appliedBadges = allBadges?.filter(b => sf?.appliedBadgeIds?.includes(b.id));
      const appliedLabels = allLabels?.filter(l => sf?.appliedLabelIds?.includes(l?.id));
      const generator = SmartFilterUtils.generateAppliedOnPills;
      return generator(appliedTemplates, appliedMenus, appliedBadges, appliedLabels, isClickable);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private initializePillLoadingState() {
    this._loadingOpts.next(LoadingOptions.defaultWhiteBackground());
    const lm = '';
    this._loadingOpts.addRequest(lm);
    this.appearsOnPills$.subscribeWhileAlive({
      owner: this,
      next: (pills) => {
        if (pills !== null) {
          this._loadingOpts.removeRequest(lm);
        }
      },
    });
  }

}
