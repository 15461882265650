import { Injectable } from '@angular/core';
import { EditMarketingMenuViewModel } from '../../../../viewModels/edit-marketing-menu-view-model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarketingTheme } from '../../../../../../models/enum/dto/theme.enum';

@Injectable()
export class EditUrlPlaylistMenuViewModel extends EditMarketingMenuViewModel {

  showSaveButton$ = of(true);

  public readonly headerText$ = this.menu$.pipe(
    map(menu => {
      return menu?.theme === MarketingTheme.SurfsideUrlPlaylist
        ? 'Edit Surfside Playlist Menu'
        : 'Edit URL Playlist Menu';
    }),
  );

}
