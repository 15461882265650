import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CardStackPrintConfig } from '../../../../../../../models/automation/card-stack-print-config';

@Pipe({
  name: 'constructCardStackMenuIfOriginalDeleted'
})
export class ConstructCardStackMenuIfOriginalDeletedPipe implements PipeTransform {

  transform(menu$: Observable<Menu>, printConfig: CardStackPrintConfig): Observable<Menu> {
    return menu$.pipe(
      map(menu => {
        return menu || Object.assign(new Menu(), { name: printConfig.deletedMenuName });
      })
    );
  }

}
