<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Featured Category Cards</p>
    <p class="bs-edit-section-description">
      Use cards to organize small groups of specific products that you are interested in promoting.
    </p>
    <div class="button-container">
      <lib-button-primary
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        (buttonClicked)="addFeaturedCategoryCardClicked()"
        [disabled]="sharedVM?.unsavedChanges || (sharedVM?.isTemplatedMenu$ | async) || !formPristine"
        [disabledTooltip]="
          (sharedVM?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [loading]="sharedVM?.isLoading$ | async">
        Add Category Card
      </lib-button-primary>
      <lib-button-neutral
        *ngIf="isReOrderable$ | async"
        [disabled]="(sharedVM?.isTemplatedMenu$ | async) || !formPristine"
        [disabledTooltip]="
          (sharedVM?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [iconSrc]="'assets/icons/dark/outline/collection.svg'"
        (buttonClicked)="reorderCardsClicked()">
        Reorder Cards
      </lib-button-neutral>
    </div>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8 featured-category-cards-container">
    <div class="col-12 no-padding">
      <app-featured-category-cards-details-form
        [menu]="sharedVM?.menu$ | async"
        [bindTo]="sharedVM?.menu$ | async"
        [orientation]="sharedVM?.selectedOrientation$ | async"
        (cardTypeChanged)="sharedVM.updateCardType($event)"
        (cardStartAtChanged)="sharedVM.updateCardStartAt($event)"
        [mergeKey]="mergeKey">
      </app-featured-category-cards-details-form>
    </div>
    <hr />
    <app-featured-category
      *ngFor="let section of sharedVM.menuSections$ | async; let i = index"
      [templateMode]="templateMode"
      [sharedViewModel]="sharedVM"
      [section]="section"
      [menu]="sharedVM?.menu$ | async"
      [formPristine]="formPristine"
      [index]="i"
      (deleteClicked)="sharedVM.promptForDeleteSection($event)"
      (duplicateClicked)="duplicateCardClicked($event)">
    </app-featured-category>
    <div *ngIf="(sharedVM?.nMenuSections$ | async) === 0">
      Category Cards will appear in a list here. You can add, edit, and remove them from here.
    </div>
    <lib-button-primary
      [class]="'no-margin mt-16px'"
      (buttonClicked)="sharedVM.addFeaturedCategoryCard()"
      [disabled]="sharedVM?.unsavedChanges || (sharedVM?.isTemplatedMenu$ | async) || !formPristine"
      [disabledTooltip]="
        (sharedVM?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
      "
      [loading]="sharedVM?.isLoading$ | async"
      [iconSrc]="'assets/icons/light/solid/plus.svg'">
      Add Category Card
    </lib-button-primary>
  </div>
</div>
