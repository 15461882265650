import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../models/shared/stylesheet/drop-down-menu-section';
import { BadgeAction } from '../../../../models/company/shared/badge-action';
import { Injectable } from '@angular/core';

/**
 * Lifecycle is tied to component lifecycle. Therefore dies when component dies.
 */
@Injectable()
export class CompanyBadgeViewModel extends BaseViewModel {

  public badge: HydratedVariantBadge;
  public menuItems: DropDownMenuSection[];

  constructor() {
    super();
  }

  setBadge(t: HydratedVariantBadge) {
    this.badge = t;
    this.setupDropDownMenu();
  }

  setupDropDownMenu() {
    this.menuItems = [];
    const ddmi = [];
    ddmi.push(new DropDownMenuItem('Edit', new BadgeAction('Edit', this.badge), new Set<string>()),);
    ddmi.push(new DropDownMenuItem('Delete', new BadgeAction('Delete', this.badge), new Set<string>().add('red-text')));
    this.menuItems.push(new DropDownMenuSection(null, ddmi));
  }

}
