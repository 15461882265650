<app-print-job-select-stacks
  *rxIf="viewModel.showSelectStacks$"
  [stackPrintType]="stackPrintType"
  [hidden]="!(viewModel.stepIsSelectStacks$ | push)"
  [job]="viewModel.job$ | push"
  [mergeKey]="viewModel.mergeKey$ | push"
  [selectedStackIds]="viewModel.selectedStackIds$ | push"
  [viewOnly]="viewModel.viewOnly$ | push"
  (add)="viewModel.addStackIdToSelectedIds($event)"
  (remove)="viewModel.removeStackIdFromSelectedIds($event)"
  (bulkAdd)="viewModel.bulkAddStackIdsToSelectedIds($event)"
  (bulkRemove)="viewModel.bulkRemoveStackIdsFromSelectedIds($event)">
</app-print-job-select-stacks>

<app-stack-smart-bulk-print-job
  *rxIf="viewModel.showStackSmartBulkPrintJob$"
  [stackPrintType]="stackPrintType"
  [job]="viewModel.job$ | push"
  [mergeKey]="viewModel.mergeKey$ | push"
  [placeholder]="viewModel.placeholder$ | push"
  [selectedCardStackIds]="viewModel.selectedStackIds$ | push"
  [viewOnly]="viewModel.viewOnly$ | push"
  [stackType]="stackType">
</app-stack-smart-bulk-print-job>

<app-stack-manual-bulk-print-job
  *rxIf="viewModel.showStackManualBulkPrintJob$"
  [stackPrintType]="viewModel.stackPrintType$ | push"
  [job]="viewModel.job$ | push"
  [mergeKey]="viewModel.mergeKey$ | push"
  [placeholder]="viewModel.placeholder$ | push"
  [selectedStackIds]="viewModel.selectedStackIds$ | push"
  [viewOnly]="viewModel.viewOnly$ | push">
</app-stack-manual-bulk-print-job>
