<div class="row mt-24px">
  <div class="col-4">
    <p class="bs-section-subtitle">Drive-Thru Products</p>
    <div class="bs-section-subtext mt-16px">
      <!-- subtext here -->
    </div>
    <div fxLayout="row wrap" class="mt-16px" [style.gap.rem]="0.5">
      <lib-button-primary
        *ngIf="(sharedVM?.hasVariants$ | async) && !(sharedVM.isTemplatedMenu$ | async)"
        [iconSrc]="'assets/icons/light/solid/plus.svg'"
        [disabled]="formHasErrors || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="addFeatureProductClicked()">
        {{ (sharedVM?.lengthOfFeaturedVariantIds$ | async) < 1 ? 'Add Featured Product' : 'Add More' }}
      </lib-button-primary>
      <lib-button-neutral
        *ngIf="(sharedVM?.canReorder$ | async) && !(sharedVM.isTemplatedMenu$ | async)"
        [disabled]="formHasErrors || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="reorderProductsClicked()">
        Reorder Products
      </lib-button-neutral>
    </div>
  </div>
  <div class="col-8">
    <app-edit-drive-thru-products-form
      [mergeKey]="mergeKey"
      [bindTo]="sharedVM?.menu$ | async"
      [menu]="sharedVM?.menu$ | async"
      [theme]="sharedVM?.theme$ | async"
      [isTemplatedMenu]="sharedVM?.isTemplatedMenu$ | async"
      (cardTypeChanged)="updateCardType($event)"
      (cardStartAtChanged)="updateCardStartAt($event)">
    </app-edit-drive-thru-products-form>
    <div class="combo-product-wrapper">
      <hr class="no-margin" />
      <!-- Featured Products -->
      <app-drive-thru-product
        *ngFor="let v of sharedVM?.orderedVariantsOverTime$ | async; trackBy: viewModel.trackById; let i = index"
        [variant]="v"
        [variantId]="v?.id"
        [sharedViewModel]="sharedVM"
        [nestedGroupKey]="mergeKey"
        [menu]="sharedVM?.menu$ | async"
        [isTemplatedMenu]="sharedVM?.isTemplatedMenu$ | async"
        [formPristine]="formPristine"
        (selectedPreviewIndex)="previewClicked($event)"
        [index]="i">
      </app-drive-thru-product>
      <!-- Placeholder -->
      <div [hidden]="sharedVM?.hasVariants$ | async" class="no-combo-variants-placeholder">
        <p class="bs-edit-section-title mt-24px">
          Products will appear in a list here. You can add, edit, and remove variants from here.
        </p>
        <lib-button-primary
          [iconSrc]="'assets/icons/light/outline/plus.svg'"
          [disabled]="(sharedVM?.isTemplatedMenu$ | async) || formHasErrors || !formPristine"
          [disabledTooltip]="formPristine | saveChangesTooltip"
          (buttonClicked)="addFeatureProductClicked()">
          Add Product
        </lib-button-primary>
      </div>
    </div>
  </div>
</div>
