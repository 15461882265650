import { AfterViewInit, Component, Input } from '@angular/core';
import { EditTemplateMarketingMenuViewModel } from './edit-template-marketing-menu-view-model';
import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from './edit-template-marketing-menu-combined-loading-opts-view-model';
import { AutoSaveComponent } from '../../../../shared/components/auto-save/auto-save.component';

@Component({ selector: 'app-edit-template-marketing-menu', template: '' })
export abstract class EditTemplateMarketingMenuComponent extends AutoSaveComponent implements AfterViewInit {

  protected constructor(
    public override viewModel: EditTemplateMarketingMenuViewModel,
    public combinedLoadingOptsViewModel: EditTemplateMarketingMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel);
  }

  @Input() mergeAndDispersedKey: string = 'editTemplateMarketingMenu';

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.viewModel.triggerFormSubmission.subscribeWhileAlive({
      owner: this,
      next: (background: boolean) => this.formCapture?.submitForms(background)
    });
  }

}
