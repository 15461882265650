import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { Menu } from '../../../../../models/menu/dto/menu';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';

@Component({
  selector: 'app-print-job-menu-form',
  templateUrl: './print-job-menu-form.component.html',
  styleUrls: ['./print-job-menu-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobMenuFormComponent {

  @Input() type: 'menu' | 'stack';
  @Input() job: BulkPrintJob;
  @Input() menuChoices: Menu[];
  @Input() menusAsSelectionItem: HasChildIds;
  @Input() mergeKey: string;
  @Input() selectedMenuIds: string[];
  @Input() viewOnly: boolean = false;

  @Output() bulkAdd = new EventEmitter<string[]>();
  @Output() bulkRemove = new EventEmitter<string[]>();
  @Output() add = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();

}
