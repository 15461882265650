import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { SignOutNavItem } from '../models/sign-out-nav-item';

@Pipe({
  name: 'isSignOutNavItem'
})
export class IsSignOutNavItemPipe implements PipeTransform {

  transform(value: NavItem): boolean {
    return value instanceof SignOutNavItem;
  }

}
