import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '../../../../models/shared/stylesheet/card';
import { CardStyle } from '../../../../models/shared/stylesheet/card-style.enum';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.scss'],
})
export class CardDeckComponent extends BaseComponent {

  @Input() cards: Card[] = [];
  @Input() horizontalScroller = true;
  @Input() maxHeightRem: number = 18;
  @Input() columnGap: string = '1rem';
  @Input() rowGap: string = '1rem';
  @Input() scrollToCardId: EventEmitter<string> = new EventEmitter<string>();

  @Output() cardPressed: EventEmitter<Card> = new EventEmitter<Card>();
  @Output() cardButtonPressed: EventEmitter<Card> = new EventEmitter<Card>();

  // View
  public cardStyles = CardStyle;

  constructor() {
    super();
  }

  override setupBindings() {
    // Bind to scroll to selected card
    this.scrollToCardId.subscribe((id) => {
      if (id) {
        document.getElementById(id).scrollIntoView();
        document.getElementById(id).focus();
      }
    });
  }

  handleCardPress(card: Card) {
    this.cardPressed.emit(card);
  }

  getMaxHeightRem(): string {
    return `${this.maxHeightRem}rem`;
  }

}
