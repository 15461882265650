<app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="dispersedAndMergeKey"
  (formsAutoSubmitted$)="viewModel.saveSection(true)"
  (formsSubmitted$)="viewModel.saveSection(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-section-container>
  <app-edit-menu-section-header
    [crumbs]="viewModel.breadcrumbs$ | push"
    [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | push"
    [lastAutoSavedText]="viewModel.lastAutoSavedTimeText$ | push"
    [disableSubmit]="formCapture.disableSubmit$ | push"
    [disableSectionNavOptionIds]="viewModel.disabledSectionNavOptionIds$ | push"
    [removingVariants]="viewModel.removingVariants$ | push"
    [section]="viewModel.section$ | push"
    [sectionNavOptions]="viewModel.sectionNavOptions$ | push"
    [headerText]="viewModel.headerText$ | push"
    [userIsTemplateAdmin]="viewModel.userIsTemplateAdmin$ | push"
    [allowLiveView]="viewModel.allowLiveView$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    [showLiveView]="viewModel.showLiveView"
    (submitForms)="formCapture.submitForms($event)"
    (editTemplateSectionClicked)="viewModel.navigateToEditTemplateSection($event)"
    (handleDropdownSelection)="viewModel.handleDropdownSelection($event)">
  </app-edit-menu-section-header>

  <app-edit-menu-section-body
    [templateMode]="false"
    [isTemplatedSection]="viewModel.isTemplatedSection$ | push"
    [dispersedAndMergeKey]="dispersedAndMergeKey"
    [isPrintMenu]="viewModel.isPrintableMenu$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push">
  </app-edit-menu-section-body>

  <app-edit-menu-section-footer
    [autoSavingLoadingOpts]="viewModel.autoSaveLoadingOpts$ | push"
    [lastAutoSaveText]="viewModel.lastAutoSavedTimeText$ | push"
    [formDisabledSubmit]="formCapture.disableSubmit$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    [removingVariants]="viewModel.removingVariants$ | push"
    [isTemplatedSection]="viewModel.isTemplatedSection$ | push"
    [isPrintReportSection]="viewModel.isPrintReportSection$ | push"
    (deleteSection)="deleteSection()"
    (duplicateSection)="viewModel.openDuplicateSectionModal()"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-menu-section-footer>
</app-edit-menu-section-container>
