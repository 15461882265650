<div
  #subList
  class="nav-sub-list"
  [ngClass]="{
    'sub-list-open': viewModel.isNavListActive$ | async
  }">
  <ng-container *ngFor="let subItem of navItem?.subItems">
    <app-nav-sub-item
      *ngIf="subItem | isDefaultNavSubItem"
      [navItem]="navItem"
      [subItem]="subItem"
      [currentNavUrl]="currentNavUrl"></app-nav-sub-item>
    <app-locations-sub-nav-item
      *ngIf="subItem | isLocationsNavSubItem"
      [navItem]="navItem"
      [subItem]="subItem"
      [currentNavUrl]="currentNavUrl"></app-locations-sub-nav-item>
    <app-incomplete-sub-nav-item
      *ngIf="subItem | isIncompleteNavSubItem"
      [navItem]="navItem"
      [subItem]="subItem"
      [currentNavUrl]="currentNavUrl"></app-incomplete-sub-nav-item>
  </ng-container>
</div>
