import { Component } from '@angular/core';
import { BaseBannerComponent } from '../base-banner.component';

/**
 * Create by: Kevin Baker
 * Date: October 22, 2021
 */

@Component({
  selector: 'app-success-banner',
  templateUrl: './success-banner.component.html',
  styleUrls: ['./success-banner.component.scss']
})
export class SuccessBannerComponent extends BaseBannerComponent {

  constructor() {
    super();
  }

}
