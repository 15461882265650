import { MenuTemplate } from './menu-template';
import { Deserializable } from '../../protocols/deserializable';

export class CreateMenuTemplateReq implements Deserializable {

  public template: MenuTemplate;
  public existingMenuId: string;
  public name: string;

  constructor() {
    this.existingMenuId = null;
    this.name = null;
    this.template = null;
  }

  onDeserialize() {}

  // Expected go model
  // https://github.com/mobilefirstdev/budsense-template-service/blob/dev/models/CreateTemplateReq.go
  onSerialize(): CreateMenuTemplateReq {
    const dto = Object.create(CreateMenuTemplateReq.prototype);
    dto.template = this.template?.onSerialize();
    dto.existingMenuId = this.existingMenuId;
    dto.name = this.name;
    return dto;
  }

}
