import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NewMenuSectionFormViewModel } from './new-menu-section-form-view-model';
import { SelectableItem } from '../../../../../../../models/shared/selectable-item';
import { SectionType, SectionTypeDefinition } from '../../../../../../../models/utils/dto/section-type-definition';
import { Theme } from '../../../../../../../models/menu/dto/theme';

@Component({
  selector: 'app-new-menu-section-form',
  templateUrl: './new-menu-section-form.component.html',
  styleUrls: ['./new-menu-section-form.component.scss'],
  providers: [NewMenuSectionFormViewModel]
})
export class NewMenuSectionFormComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: NewMenuSectionFormViewModel
  ) {
    super();
  }

  @Input() dispersedKey: string;
  @Input() isDuplicating: boolean = false;
  @Input() menuTheme: Theme;
  @Input() sectionTypes: SectionTypeDefinition[];
  @Input() columnOptions: SelectableItem[];
  @Input() selectedSectionType: SectionType;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDuplicating) this.viewModel.connectToIsDuplicating(this.isDuplicating);
    if (changes.menuTheme) this.viewModel.connectToMenuTheme(this.menuTheme);
    if (changes.sectionTypes) this.viewModel.connectToSectionTypes(this.sectionTypes);
    if (changes.columnOptions) this.viewModel.connectToColumnOptions(this.columnOptions);
    if (changes.selectedSectionType) this.viewModel.connectToSelectedSectionType(this.selectedSectionType);
  }

}
