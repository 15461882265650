import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Variant } from '../../../../../../../models/product/dto/variant';
import type { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-applied-product-list-item',
  templateUrl: './stack-applied-product-list-item.component.html',
  styleUrls: ['./stack-applied-product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackAppliedProductListItemComponent {

  @Input() variantCountMap: Map<string, number>; // <variantId, nCopies>
  @Input() variant: Variant;
  @Input() stackType: StackType;

}
