<div class="bs-section-container">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

  <app-display-menu-section-inside-template-collection-header
    [menuWarningTooltip]="viewModel.menuWarningTooltip$ | async"
    [disableButtons]="(editDisplayViewModel.isLoading$ | async) || (editDisplayViewModel.autoSaving$ | async)"
    [menu]="menu"
    [nMenus]="nMenusOnDisplay"
    [allTemplates]="viewModel.allTemplates$ | async"
    [locationId]="viewModel.locationId$ | async"
    [isUserTemplateAdmin]="viewModel.isUserTemplateAdmin$ | async"
    [companySupportsTemplates]="viewModel.companySupportsTemplates$ | async"
    (removeMenu)="viewModel.removeMenuFromDisplay($event)"
    (editMenu)="viewModel.openEditMenu($event)">
  </app-display-menu-section-inside-template-collection-header>

  <app-display-menu-section-inside-template-collection-body
    [menu]="menu"
    [mergeKey]="mergeKey"
    [bindTo]="editDisplayViewModel?.menuOptions$ | getMenuOptionsForDisplay : containerId : menu?.id | async"
    [loopDuration]="
      (editDisplayViewModel?.menuOptions$ | getMenuOptionsForDisplay : containerId : menu?.id | async)?.playlistDuration
    "
    [index]="menuIndex"
    [nMenus]="nMenusOnDisplay"
    [productOverflowTitle]="viewModel.productOverflowTitle$ | async"
    [menuIsPartOfCollection]="menuIsPartOfCollection"
    (openInformationModal)="viewModel.openInformationModal()">
  </app-display-menu-section-inside-template-collection-body>
</div>
