import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { UploadAssetComponent } from '../../../../shared/components/upload-asset/upload-asset.component';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { Observable, throwError } from 'rxjs';
import { BsError } from '../../../../../models/shared/bs-error';
import { ChangeMediaOptions } from '../../../../../models/shared/stylesheet/change-media-options';
import { ToastService } from '../../../../../services/toast-service';
import { ColorUtils } from '../../../../../utils/color-utils';
import { FileUtils } from '../../../../../utils/file-utils';
import { NavigationEnd, Router } from '@angular/router';
import { FormInputItem, FormInputType, FormItemType } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
  selector: 'app-change-media-modal',
  templateUrl: './change-media-modal.component.html',
  styleUrls: ['./change-media-modal.component.scss'],
})
export class ChangeMediaModalComponent extends BaseModalComponent implements AfterViewInit {

  // View References
  @ViewChild('modalBody') modalBody: HTMLDivElement;
  @ViewChild('changeMedia') changeMedia: UploadAssetComponent;

  public canvas = document.createElement('canvas'); // used to generate color image
  public options: ChangeMediaOptions = new ChangeMediaOptions();
  public changeMediaOperation: (files: BudsenseFile[]) => Observable<any>;
  public assets: BudsenseFile[] = [];
  public colorBind = { color: '' };

  // Color Form
  public colorFormItems = this.getColorFormItem();
  public hydrateInputObject: EventEmitter<void> = new EventEmitter<void>();
  public styling = this.getColorFormStyling();

  // Loading
  public loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(
    protected activeModal: NgbActiveModal,
    private toastService: ToastService,
    public router: Router,
    public modalService: NgbModal,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override cancel() {
    this.changeMedia.clear();
    this.activeModal.close();
  }

  override setupBindings() {
    this.bindToBackNav();
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

  fileList(f: BudsenseFile[], _: number) {
    this.assets = f;
  }

  handleChangeMedia() {
    const lm = this.options.loadingMess;
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.changeMediaOperation(this.assets).subscribe((uploadedFileNames) => {
        if (uploadedFileNames?.length > 0) {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(this.options.successMess, this.options.successTitle);
          this.changeMedia.clear();
          this.activeModal.close(uploadedFileNames);
        } else {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishErrorMessage(this.options.failureMess, this.options.failureTitle);
        }
      }, (error: BsError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  formChanges() {
    let canSubmit = true;
    this.colorFormItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateInputObject.next();
    }
  }

  public setColorAsSectionImage() {
    this.changeMedia.clear();
    const colorFile = FileUtils.getPngImageFromHex(this.colorBind.color, this.canvas);
    this.assets.push(colorFile);
  }

  getColorFormItem(): FormInputItem[] {
    const titleTextColorInput = new FormInputItem();
    titleTextColorInput.itemType = FormItemType.Input;
    titleTextColorInput.inputName = 'chooseAColor';
    titleTextColorInput.label = 'Choose a Color';
    titleTextColorInput.bindingProperty = 'color';
    titleTextColorInput.inputType = FormInputType.Color;
    titleTextColorInput.inlineLabel = true;
    titleTextColorInput.inlineLabelWidthPercent = 100;
    titleTextColorInput.colorPopperPlacement = NgxPopperjsPlacements.TOPSTART;
    titleTextColorInput.customValueParser = (val) => {
      return ColorUtils.validateHexColor(val);
    };
    return [titleTextColorInput];
  }

  getColorFormStyling(): FormGroupStyling {
    const s = new FormGroupStyling();
    s.includePadding = false;
    return s;
  }

}
