import { Pipe, PipeTransform } from '@angular/core';
import { MediaUtils } from '../../../utils/media-utils';

@Pipe({
  name: 'fileNameWithoutTimestampAndExtension'
})
export class FileNameWithoutTimestampAndExtensionPipe implements PipeTransform {

  transform(fileName: string): string {
    return MediaUtils.getFileNameWithoutTimestampAndExtension(fileName);
  }

}
