<div class="d-flex flex-wrap-content" [style.gap.rem]="1">
  <lib-button-neutral
    *ngIf="nMenus > 1"
    [iconSrc]="'assets/icons/dark/outline/collection.svg'"
    [disabled]="disabled"
    [disabledTooltip]="formPristine | saveChangesTooltip"
    (buttonClicked)="reorderMenus()">
    Reorder {{ 'Menus' | replaceMenuWithTemplate : collectionMode }}
  </lib-button-neutral>
  <lib-button-primary
    *ngIf="nMenus"
    [iconSrc]="'assets/icons/light/outline/plus.svg'"
    [disabled]="disabled"
    [disabledTooltip]="formPristine | saveChangesTooltip"
    (buttonClicked)="addMenu()">
    Add {{ 'Menu' | replaceMenuWithTemplate : collectionMode }}
  </lib-button-primary>
</div>
