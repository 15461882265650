import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { EditTemplateLocationsSectionViewModel } from './edit-template-locations-section-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { SortUtils } from '../../../../../utils/sort-utils';
import { Location } from '../../../../../models/company/dto/location';

@Component({
  selector: 'app-edit-template-locations-section',
  templateUrl: './edit-template-locations-section.component.html',
  styleUrls: ['./edit-template-locations-section.component.scss'],
  providers: [EditTemplateLocationsSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLocationsSectionComponent extends BaseComponent {

  constructor(
    public viewModel: EditTemplateLocationsSectionViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToTemplate') template: MenuTemplate;
  @Input() mergeKey: string = 'editTemplate';
  @Output() changedRequiredTemplateIds = new EventEmitter<number[]>(true);
  incompleteLocationsLast = SortUtils.sortSpecifiedStringKeyLast(Location.incompleteLocationGroupName);
  override setupBindings = (): void => {
    this.viewModel.changeRequiredTemplateIds$.notNull().subscribeWhileAlive({
      owner: this,
      next: ids => this.changedRequiredTemplateIds.emit(ids)
    });
  };

}
