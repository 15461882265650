import { Injectable } from '@angular/core';
import { EditSectionFormViewModel } from '../edit-section-form/edit-section-form-view-model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';

@Injectable()
export class EditProductSectionFormViewModel extends EditSectionFormViewModel {

  constructor(
    locationDomainModel: LocationDomainModel
  ) {
    super(locationDomainModel);
  }

  private _rowCount = new BehaviorSubject<number>(0);
  public rowCount$ = this._rowCount.pipe(map(rowCount => rowCount || 0));
  connectToRowCount = (rowCount: number) => this._rowCount.next(rowCount);

  public isPrintReportMenu$ = this.menu$.pipe(map(menu => menu?.isPrintReportMenu()));

  override connectToSection(section: HydratedSection) {
    super.connectToSection(section);
    this.connectToRowCount(section?.rowCount);
  }

}
