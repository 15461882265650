import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, throwError, combineLatest } from 'rxjs';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { ToastService } from '../../../../../services/toast-service';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { BsError } from '../../../../../models/shared/bs-error';
import { TemplateCollectionDomainModel } from '../../../../../domainModels/template-collection-domain-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { DisplayOptions } from '../../../../../models/shared/display-options';

export enum CreateTemplateCollectionStep {
  CollectionDetails = 0,
  SelectTemplates = 1
}

@Injectable()
export class CreateTemplateCollectionContainer extends BaseModalViewModel {

  constructor(
    private collectionDomainModel: TemplateCollectionDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public mergeKey$ = of('create-template-collection-modal');

  public templates$ = this.templateDomainModel.digitalMenuTemplates$;

  private _templateIdsToAdd = new BehaviorSubject<string[]>([]);
  public templateIdsToAdd$ = this._templateIdsToAdd as Observable<string[]>;

  private _currentStep = new BehaviorSubject<CreateTemplateCollectionStep>(
    CreateTemplateCollectionStep.CollectionDetails
  );
  public currentStep$ = this._currentStep as Observable<CreateTemplateCollectionStep>;

  public currentStepIsCollectionDetails$ = this.currentStep$.pipe(
    map(step => step === CreateTemplateCollectionStep.CollectionDetails),
  );

  public currentStepIsSelectTemplates$ = this.currentStep$.pipe(
    map(step => step === CreateTemplateCollectionStep.SelectTemplates)
  );

  public title$ = this.currentStep$.pipe(
    map(step => {
      return step === CreateTemplateCollectionStep.CollectionDetails
        ? 'Create Template Collection'
        : 'Select Existing Templates';
    })
  );

  private _canSubmitForm = new BehaviorSubject<boolean>(false);
  public canSubmitForm$ = this._canSubmitForm as Observable<boolean>;
  connectToCanSubmitForm = (canSubmitForm: boolean) => this._canSubmitForm.next(canSubmitForm);

  public primaryButtonText$ = combineLatest([this.currentStep$, this.templates$]).pipe(
    map(([step, templates]) => {
      return step === CreateTemplateCollectionStep.CollectionDetails && templates?.length > 0 ? 'Continue' : 'Create';
    })
  );

  public disablePrimaryButton$ = combineLatest([this.currentStep$, this.canSubmitForm$]).pipe(
    map(([step, canSubmitForm]) => step === CreateTemplateCollectionStep.CollectionDetails && !canSubmitForm)
  );

  private _newCollection = new BehaviorSubject<TemplateCollection>(new TemplateCollection());
  public newCollection$ = this._newCollection as Observable<TemplateCollection>;

  createNewTemplateCollection(): void {
    const lm = 'Creating Template Collection';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.newCollection$.pipe(
        withLatestFrom(this.companyDomainModel.companyId$),
        take(1),
        switchMap(([newCollection, companyId]) => {
          newCollection.companyId = companyId;
          newCollection.options = new DisplayOptions();
          newCollection.templateIds?.forEach((id, i) => newCollection.options.rotationOrder.set(id, i));
          return this.collectionDomainModel.createTemplateCollection(newCollection);
        })
      ).subscribe({
        next: (newCollection) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('New template collection created', 'Collection successfully created');
          this.activeModal.close(newCollection);
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    }
  }

  public handleItemClicked(itemId: string): void {
    this.templateIdsToAdd$.once(templateIdsToAdd => {
      if (templateIdsToAdd?.includes(itemId)) {
        this._templateIdsToAdd.next(templateIdsToAdd.filter(id => id !== itemId));
      } else {
        this._templateIdsToAdd.next([...(templateIdsToAdd || []), itemId]);
      }
    });
  }

  public handlePrimaryButtonClicked(form: ReactiveFormMergeGroupsComponent): void {
    combineLatest([this.currentStep$, this.templates$, this.templateIdsToAdd$, this.newCollection$]).once(([
      step,
      templates,
      templatesToAdd,
      newCollection
    ]) => {
      if (step === CreateTemplateCollectionStep.CollectionDetails && templates?.length > 0) {
        this._currentStep.next(CreateTemplateCollectionStep.SelectTemplates);
      } else {
        newCollection.templateIds = templatesToAdd;
        form.submitForms();
      }
    });
  }

  public goBack(): void {
    this._currentStep.next(0);
    this._templateIdsToAdd.next([]);
  }

}
