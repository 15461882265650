<thead class="header-container">
  <tr>
    <th resizable>
      <div class="header-title" (click)="nameSort.clicked()">Name</div>
      <lib-reactive-table-asc-desc-sort
        #nameSort
        fxFlex
        [ascSort]="nameAsc"
        [descSort]="nameDesc"></lib-reactive-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="brandSort.clicked()">Brand</div>
      <app-table-asc-desc-sort #brandSort fxFlex [ascSort]="brandAsc" [descSort]="brandDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="typeSort.clicked()">Type</div>
      <app-table-asc-desc-sort #typeSort fxFlex [ascSort]="typeAsc" [descSort]="typeDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="strainSort.clicked()">Strain Type</div>
      <app-table-asc-desc-sort
        #strainSort
        fxFlex
        [ascSort]="strainAsc"
        [descSort]="strainDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="qtySort.clicked()">Quantity</div>
      <app-table-asc-desc-sort #qtySort fxFlex [ascSort]="qtyAsc" [descSort]="qtyDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="minThcSort.clicked()">Min THC</div>
      <app-table-asc-desc-sort
        #minThcSort
        fxFlex
        [ascSort]="minTHCAsc"
        [descSort]="minTHCDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="maxThcSort.clicked()">Max THC</div>
      <app-table-asc-desc-sort
        #maxThcSort
        fxFlex
        [ascSort]="maxTHCAsc"
        [descSort]="maxTHCDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="minCbdSort.clicked()">Min CBD</div>
      <app-table-asc-desc-sort
        #minCbdSort
        fxFlex
        [ascSort]="minCBDAsc"
        [descSort]="minCBDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="maxCbdSort.clicked()">Max CBD</div>
      <app-table-asc-desc-sort
        #maxCbdSort
        fxFlex
        [ascSort]="maxCBDAsc"
        [descSort]="maxCBDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="!(viewModel.displaysUnitsInRanges$ | async)" resizable>
      <div class="header-title" (click)="thcSort.clicked()">THC</div>
      <app-table-asc-desc-sort #thcSort fxFlex [ascSort]="thcAsc" [descSort]="thcDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="!(viewModel.displaysUnitsInRanges$ | async)" resizable>
      <div class="header-title" (click)="cbdSort.clicked()">CBD</div>
      <app-table-asc-desc-sort #cbdSort fxFlex [ascSort]="cbdAsc" [descSort]="cbdDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="priceSort.clicked()">Price</div>
      <app-table-asc-desc-sort #priceSort fxFlex [ascSort]="priceAsc" [descSort]="priceDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="labelSort.clicked()">Label</div>
      <app-table-asc-desc-sort #labelSort fxFlex [ascSort]="labelAsc" [descSort]="labelDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="badgeSort.clicked()">Badge</div>
      <app-table-asc-desc-sort #badgeSort fxFlex [ascSort]="badgeAsc" [descSort]="badgeDesc"></app-table-asc-desc-sort>
    </th>
  </tr>
</thead>
