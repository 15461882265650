import { TypeDefinition } from './type-definition';

export enum TerpeneDisplayType {
  Exact = 'exact',
  Range = 'range'
}

export class TerpeneDisplayTypeDefinition extends TypeDefinition {

  override value: TerpeneDisplayType;

}
