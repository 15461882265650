<div class="title-container bs-bold f14px pb-2" *ngIf="!useInlineText">
  Labels
  <img
    [ngbTooltip]="'Any products containing one of the following labels will be pulled in on this smart filter'"
    [src]="'assets/icons/dark/outline/information-circle.svg'"
    [container]="'body'"
    alt="" />
</div>
<div class="inline-picker-container" [ngClass]="{ disabled: disabled }">
  <span [style.display]="'contents'" [style.pointer-events]="disabled ? 'none' : ''">
    <app-simple-label-preview
      *ngIf="(labelIds$ | async)?.length > 0"
      [labelIds]="labelIds$ | async"
      (updatedLabelIds)="picker.handleLabelCleared($event)">
    </app-simple-label-preview>
    <div *ngIf="(labelIds$ | async)?.length === 0 && useInlineText" class="f12px bs-medium">Add Labels</div>
    <div
      [popper]="popperContent"
      [popperShowOnStart]="false"
      [popperTrigger]="ClientTypeUtils.NgxPopperjsTriggers.click"
      [popperModifiers]="popperModifier"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="false"
      [popperPreventOverflow]="false"
      [popperPositionFixed]="true"
      [popperApplyClass]="'label-picker-popper'"
      [popperStyles]="popperStyles"
      [popperPlacement]="popperPlacement"
      [popperDisabled]="disabled">
      <div
        [ngClass]="{ 'ml-2': useInlineText || (labelIds$ | async)?.length > 0 }"
        [ngStyle]="{ 'margin-left': useInlineText ? null : '-0.5rem' }">
        <app-inline-picker-action-button
          *ngIf="(labelIds$ | async)?.length < maxSelectedLabels"
          [disabled]="disabled"
          [canEdit]="!!(labelIds$ | async) && (labelIds$ | async)?.length > 0">
        </app-inline-picker-action-button>
      </div>
    </div>
    <popper-content #popperContent [style.position]="'fixed'" [style.z-index]="1500">
      <app-reactive-form-labels-picker
        #picker
        class="label-picker"
        [bindTo]="bindTo"
        [dispersedKey]="dispersedKey"
        [maxSelectedLabels]="maxSelectedLabels"
        [useAtLeastNItemsFilledInPool]="useAtLeastNItemsFilledInPool"
        (selectedIds)="setUpdatedLabelIds($event)">
      </app-reactive-form-labels-picker>
    </popper-content>
  </span>
</div>
