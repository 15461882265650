import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-shopping-info-banner',
  templateUrl: './shopping-info-banner.component.html',
  styleUrls: ['./shopping-info-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingInfoBannerComponent {

  @Input() message: string;

}
