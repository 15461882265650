import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InformationItem } from '../../../../models/shared/information-item';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent extends BaseModalComponent implements AfterViewInit {

  @ViewChild('modalBody') modalBody: HTMLDivElement;

  public title: string = '';
  public informationItems: InformationItem[] = [];

  constructor(
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

}
