import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StackSmartPrintOptionsFormViewModel } from './stack-smart-print-options-form-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import type { StackType } from '../create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-smart-print-options-form',
  templateUrl: './stack-smart-print-options-form.component.html',
  providers: [StackSmartPrintOptionsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartPrintOptionsFormComponent {

  constructor(public viewModel: StackSmartPrintOptionsFormViewModel) {
  }

  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToTemplateMode') templateMode: boolean;
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean = false;

}
