import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { DateUtils } from '../../../../utils/date-utils';
import { HydratedSection } from '../../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../../domainModels/menu-domain-model';
import { LoadingSpinnerSize } from '../../../../models/enum/shared/loading-spinner-size.enum';
import { Menu } from '../../../../models/menu/dto/menu';
import { SmartFilterAddedToSectionSharedViewModel } from './smart-filter-added-to-section-shared-view-model';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { ToastService } from '../../../../services/toast-service';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { BUDSENSE_CURATED_ID } from '../../../settings/components/settings-automation/smart-filters/manage-smart-filters/add-edit-smart-filter-modal/add-edit-smart-filter-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditSmartFilter } from '../../../../modals/modal-edit-smart-filter';
import { SectionTemplate } from '../../../../models/template/dto/section-template';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';

@Injectable()
export class SmartFilterAddedToSectionViewModel extends BaseViewModel {

  constructor(
    protected smartFiltersDomainModel: SmartFiltersDomainModel,
    protected sharedViewModel: SmartFilterAddedToSectionSharedViewModel,
    protected menuDomainModel: MenuDomainModel,
    protected userDomainModel: UserDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected toastService: ToastService,
  ) {
    super();
  }

  // loading
  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(
    SmartFilterAddedToSectionViewModel.getLoadingOpts()
  );

  // location
  private locationId$ = this.locationDomainModel.locationId$;

  // menu
  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();

  public isPrintReportMenu$ = this.menu$.pipe(map(menu => menu?.isPrintReportMenu()));

  // section
  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section.asObservable();
  public readonly isTemplatedSection$ = this.section$.pipe(map(section => section?.isTemplatedSection()));

  // smart filter
  private _smartFilterId = new BehaviorSubject<string>('');
  public smartFilterId$ = this._smartFilterId.pipe(distinctUntilChanged());
  private clientLocationSmartFiltersIds$ = this.smartFiltersDomainModel.clientLocationSmartFilterIds$;
  private allSmartFilters$ = this.smartFiltersDomainModel.allSmartFilters$;
  public smartFilter$ = combineLatest([
    this.section$,
    this.smartFilterId$,
    this.allSmartFilters$,
    this.locationId$
  ]).pipe(
    switchMap(([section, id, filters, locationId]) => {
      const smartFilter = filters?.find(filter => filter?.id === id);
      if (!smartFilter && section instanceof HydratedSection) {
        return this.smartFiltersDomainModel.getSpecificCuratedSmartFilters(locationId.toString(), [id]).pipe(
          map(curatedSmartFilters => curatedSmartFilters?.firstOrNull())
        );
      }
      return of(smartFilter);
    }),
    startWith(null)
  );
  public smartFilterTitle$ = this.smartFilter$.pipe(
    map(filter => filter?.name || 'Loading...')
  );
  public lastSyncTime$ = this.section$.pipe(
    map(section => {
      return DateUtils.formatUnixToLastSyncTime(section?.lastSmartFilterSync);
    })
  );
  public removingSmartFilters$ = this.sharedViewModel.removingSmartFilters$;
  public isCuratedSmartFilter$ = this.smartFilter$.pipe(map(sf => sf?.locationId === BUDSENSE_CURATED_ID));

  /**
   * If the smart filter is curated, the user is not an admin, or the section is templated
   * the user should be restricted 'View Only'
   */
  public actionButtonText$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.userDomainModel.isCompanyAdmin$,
    this.isTemplatedSection$,
  ]).pipe(
    map(([isCurated, isAdminUser, isTemplated]) => {
      if (isCurated || !isAdminUser || isTemplated) return 'View';
      return 'Edit';
    })
  );

  private static getLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.backgroundColor = '#FFFFFF';
    opts.spinnerSize = LoadingSpinnerSize.Small;
    return opts;
  }

  connectToMenu(menu: Menu) {
    this._menu.next(menu);
  }

  connectToSection(section: HydratedSection) {
    this._section.next(section);
  }

  connectToSmartFilterId(id: string) {
    this._smartFilterId.next(id);
  }

  editSmartFilter() {
    combineLatest([
      this.smartFilter$,
      this.isTemplatedSection$,
    ]).pipe(take(1)).subscribe(([filter, isTemplated]) => {
      ModalEditSmartFilter.open(
        this.ngbModal,
        this.injector,
        isTemplated
          ? AddEditSmartFilterModalOpenedFrom.TemplatedSectionEdit
          : AddEditSmartFilterModalOpenedFrom.SectionEdit,
        filter,
        this.editSmartFilterHelper
      );
    });
  }

  private editSmartFilterHelper = (editedSmartFilter?: HydratedSmartFilter) => {
    if (!!editedSmartFilter) this.fetchSection();
  };

  private fetchSection() {
    combineLatest([this.menu$, this.section$, this.locationId$]).pipe(
      take(1),
      switchMap(([menu, section, locationId]) => iif(
        () => section instanceof SectionTemplate,
        this.templateDomainModel.getHydratedSectionTemplate(locationId, menu?.id, section?.id),
        this.menuDomainModel.getHydratedSection(menu?.id, section?.id)
      )),
    ).once({
      error: err => this.toastService.publishError(err)
    });
  }

  removeSmartFilter() {
    const lm = 'Removing';
    this._loadingOpts.addRequest(lm);
    this.smartFilterId$.notNull().pipe(take(1)).subscribe(id => this.sharedViewModel.removeSmartFilter(id));
  }

}
