import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtils } from '../../../../utils/sort-utils';
import { ClientTypeUtils } from '../../../../utils/client-type-utils';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements } from 'ngx-popperjs';
import { PopperUtils } from '../../../../utils/popper-utils';

export const MAX_BADGES_ON_SF = 10;

@Component({
  selector: 'app-badge-picker-for-form',
  templateUrl: './badge-picker-for-form.component.html',
  styleUrls: ['./badge-picker-for-form.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => BadgePickerForFormComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BadgePickerForFormComponent)
    }
  ],
})
export class BadgePickerForFormComponent extends ReactiveFormItemComponent implements ControlValueAccessor,
  OnChanges, AfterViewInit {

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  @Input() badges: HydratedVariantBadge[];
  @Input() override disabled: boolean = false;
  @Input() maxBadgesAllowed: number = MAX_BADGES_ON_SF;
  @Input() popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.LEFT;
  @Input() inlineText: string = 'Add Badges';
  @Input() titleText: string = `Only ${MAX_BADGES_ON_SF} badges can be applied to the Smart Filter`;
  @Input() useInlineText: boolean = true;

  @ViewChild(NgxPopperjsContentComponent) popperContent: NgxPopperjsContentComponent;

  private _badges = new BehaviorSubject<HydratedVariantBadge[]>([]);
  public badges$ = this._badges.pipe(map(badges => badges?.sort(SortUtils.sortBadgesByNameAscending)));
  public badgeIds$ = this.badges$.pipe(map(badges => badges?.map(b => b.id)));

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['right'])];
  public popperStyles = { 'background-color': '#FFFFFF' };

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (!!changes.badges) this._badges.next(!!this.badges ? [...this.badges] : []);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  getValidators(): ValidatorFn[] {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    return [...validators, ...this.customValidators];
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  writeValue(obj: any): void {
  }

  updateBadges(badge: HydratedVariantBadge) {
    this.badges$.once(badges => {
      const updatedBadges = badges.shallowCopy();
      const i = updatedBadges?.findIndex(b => b.id === badge?.id);
      if (i > -1) {
        updatedBadges.splice(i, 1);
      } else {
        updatedBadges.push(badge);
      }
      const updateFormValue = updatedBadges?.length ? updatedBadges.map(b => b?.id) : null;
      this.handleInputChange(updateFormValue);
      this.getSelfAsFormItem()?.patchValue(updateFormValue);
      this.getSelfAsFormItem()?.markAsDirty();
      this._badges.next(updatedBadges);
      this.valueChanged.emit(this.getMyValue());
    });
  }

  protected readonly ClientTypeUtils = ClientTypeUtils;

}
