import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ColorPaletteViewModel } from './color-palette-view-model';
import { PopperUtils } from '../../../../utils/popper-utils';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
  providers: [ColorPaletteViewModel]
})
export class ColorPaletteComponent extends BaseComponent {

  @Input() rowTitle: string;
  @Input() rowDescription: string;

  // Popper
  public popperModifier = [PopperUtils.flipModifier()];
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };

  constructor(
    public viewModel: ColorPaletteViewModel
  ) {
    super();
  }

}
