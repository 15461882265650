import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDollarSignToPrice'
})
export class AddDollarSignToPricePipe implements PipeTransform {

  transform(value: number|string|null): string {
    let dollarSigned = '';
    if (!!value) {
      if (typeof value === 'number') {
        dollarSigned = `$${value}`;
      } else if (typeof value === 'string') {
        if (value.includes('-')) {
          const [lowest, highest] = (value || '').split('-').map(price => price.trim());
          dollarSigned = `$${lowest} - $${highest}`;
        } else {
          dollarSigned = `$${value}`;
        }
      }
    }
    return dollarSigned;
  }

}
