import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { delay, distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { BaseModalViewModel } from '../../../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HydratedSection } from '../../../../../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { SectionTemplate } from '../../../../../../../models/template/dto/section-template';
import { ToastService } from '../../../../../../../services/toast-service';
import { BsError } from '../../../../../../../models/shared/bs-error';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { StringUtils } from '../../../../../../../utils/string-utils';

@Injectable()
export class EditPrintStackStyleViewModel extends BaseModalViewModel {

  constructor(
    router: Router,
    ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private toastService: ToastService,
  ) {
    super(router, ngbModal);
  }

  private _stackMenu = new BehaviorSubject<Menu | null>(null);
  public stackMenu$ = this._stackMenu as Observable<Menu | null>;
  connectTStackMenu = (stackMenu: Menu) => this._stackMenu.next(stackMenu);

  public readonly whatTypeOfStackIsThis$ = this.stackMenu$.pipe(
    map(stackMenu => stackMenu?.whatTypeOfStackIsThis())
  );

  public isTemplatedMenu$ = this.stackMenu$.notNull().pipe(
    map(stackMenu => stackMenu?.isTemplatedMenu())
  );

  public colorPalette$ = this.companyDomainModel.colorPalette$;

  public templatedMenuDisabledTooltip$ = this.isTemplatedMenu$.pipe(
    map(isTemplatedMenu => {
      return isTemplatedMenu
        ? 'This property may only be edited on the template.'
        : '';
    })
  );

  public section$ = this.stackMenu$.notNull().pipe(
    map(stackMenu => {
      const section = stackMenu?.getSectionsBasedOnMenuType()?.firstOrNull();
      const Deserialize = window?.injector?.Deserialize;
      const sec = Deserialize?.instanceOf(HydratedSection, section);
      this.connectToBackgroundColor(sec?.metadata?.productsContainerBackgroundColor);
      return sec;
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)
  );

  private hydratedTheme$ = this.stackMenu$.notNull().pipe(
    map(stackMenu => stackMenu?.hydratedTheme),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public themeSupportsBackgroundOpacity$ = this.hydratedTheme$.pipe(
    map(t => t?.themeFeatures?.backgroundOpacity)
  );

  public themeSupportsHeaderTextColor$ = this.hydratedTheme$.pipe(
    map(t => t?.themeFeatures?.sectionHeaderTextColor)
  );

  public themeSupportsHeaderBackgroundColor$ = this.hydratedTheme$.pipe(
    map(t => t?.themeFeatures?.sectionHeaderBackgroundColor)
  );

  public themeSupportsBodyTextColor$ = this.hydratedTheme$.pipe(
    map(t => t?.themeFeatures?.sectionBodyTextColor)
  );

  public themeSupportsBodyBackgroundColor$ = this.hydratedTheme$.pipe(
    map(t => t?.themeFeatures?.sectionBodyBackgroundColor)
  );

  private _backgroundColor = new BehaviorSubject<string>('');
  public backgroundColor$ = this._backgroundColor as Observable<string>;
  connectToBackgroundColor = (c: string) => this._backgroundColor.next(c);

  public disableBackgroundOpacity$ = combineLatest([
    this.isTemplatedMenu$,
    this.backgroundColor$
  ]).pipe(
    map(([isTemplatedMenu, backgroundColor]) => isTemplatedMenu || !backgroundColor)
  );

  public disableOpacityTooltip$ = combineLatest([
    this.disableBackgroundOpacity$,
    this.isTemplatedMenu$,
    this.whatTypeOfStackIsThis$
  ]).pipe(
    map(([disableOpacity, isTemplatedMenu, whatTypeOfStackIsThis]) => {
      switch (true) {
        case isTemplatedMenu:
          return 'This property may only be edited on the template.';
        case disableOpacity:
          return `A ${whatTypeOfStackIsThis} color must be set first.`;
        default:
          return null;
      }
    })
  );

  public resetOpacityWithBodyBackgroundColor$ = this.backgroundColor$.pipe(
    switchMap(backgroundColor => {
      let setValue$: Observable<undefined|number> = of(undefined);
      if (!backgroundColor) setValue$ = setValue$.pipe(delay(100), startWith(100));
      return setValue$;
    })
  );

  public saveStyles(section: HydratedSection): void {
    this.whatTypeOfStackIsThis$.once(whatTypeOfStackIsThis => {
      const lm = `Saving ${StringUtils.capitalize(whatTypeOfStackIsThis)} Styles`;
      this._loadingOpts.addRequest(lm);
      const saveSection$ = section instanceof SectionTemplate
        ? this.templateDomainModel.updateMenuSectionTemplate(section)
        : this.menuDomainModel.updateMenuSection(section);
      saveSection$.subscribe({
        complete: () => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(
            `${StringUtils.capitalize(whatTypeOfStackIsThis)} styles have been updated successfully`,
            'Styles Updated'
          );
          this.activeModal.close();
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
        }
      });
    });
  }

}
