import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templatedMenuDisabledFormControlTooltip'
})
export class TemplatedMenuDisabledFormControlTooltipPipe implements PipeTransform {

  transform(isTemplated: boolean): string {
    if (isTemplated) {
      return 'This property may only be edited on the template';
    }
    return null;
  }

}
