import { BaseViewModel } from '../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { ComputeLabelInterface } from '../label/compute-label-interface';
import { map, shareReplay } from 'rxjs/operators';

/**
 * See Label View Model for label diagram
 */
@Injectable()
export class LabelPreviewViewModel extends BaseViewModel {

  constructor(
    public companyDomainModel: CompanyDomainModel,
    public locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  private locationConfig$ = this.locationDomainModel.locationConfig$;
  private companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly configurations$ = combineLatest([this.locationConfig$, this.companyConfig$]);

  private _computeLabelInterface = new BehaviorSubject<ComputeLabelInterface>(null);
  public readonly computeLabelInterface$ = this._computeLabelInterface as Observable<ComputeLabelInterface>;
  public connectToComputeLabelInterface = (computeLabelInterface: ComputeLabelInterface) => {
    this._computeLabelInterface.next(computeLabelInterface);
  };

  public readonly menu$ = this.computeLabelInterface$.pipe(
    map(computeLabelInterface => computeLabelInterface.getMenuForLabelComponent())
  );

  public readonly section$ = this.computeLabelInterface$.pipe(
    map(computeLabelInterface => computeLabelInterface.getSectionForLabelComponent())
  );

  public readonly variants$ = this.computeLabelInterface$.pipe(
    map(computeLabelInterface => computeLabelInterface.getVariantsForLabelComponent())
  );

  public readonly saleLabelData$ = combineLatest([
    this.menu$,
    this.section$,
    this.variants$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
