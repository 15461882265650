import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';

@Pipe({
  name: 'isCardStackMenu'
})
export class IsCardStackMenuPipe implements PipeTransform {

  transform(value: any): Menu | null {
    return (value?.isPrintCardMenu?.() ?? false) ? value : null;
  }

}
