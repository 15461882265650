<app-menu-details-section [templateMode]="templateMode">
  <app-menu-additional-options
    #menuAdditionalOptions
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | async"
    [bindTo]="viewModel.menu$ | async"
    (iFrameHeightChanged)="viewModel.iFrameHeightChanged($event)"
    (iFrameWidthChanged)="viewModel.iFrameWidthChanged($event)">
  </app-menu-additional-options>
</app-menu-details-section>

<app-edit-smart-playlist-menu-content
  [templateMode]="templateMode"
  [sharedVM]="sharedVM"
  [mergeKey]="mergeKey"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine"
  [formAutoSubmitted]="formAutoSubmitted"
  (replacingImage)="replacingImage.emit($event)"
  (submitForms)="submitForms.emit($event)">
</app-edit-smart-playlist-menu-content>

<hr class="mt-24px" />
