import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelStackDetailsSectionViewModel } from './label-stack-details-section-view-model';
import { CardStackDetailsSectionComponent } from '../../edit-card-stack-menu/card-stack-details-section/card-stack-details-section.component';

@Component({
  selector: 'app-label-stack-details-section',
  templateUrl: 'label-stack-details-section.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  providers: [LabelStackDetailsSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackDetailsSectionComponent extends CardStackDetailsSectionComponent {

  constructor(public override viewModel: LabelStackDetailsSectionViewModel) {
    super(viewModel);
  }

}
