import { Deserializable } from '../../protocols/deserializable';

export class SignInNewPasswordRequest implements Deserializable {

  public userId: string = '';
  public password: string = '';
  public session: string = '';
  onDeserialize = ()  => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-authenticator/blob/dev/models/AuthenticateNewPasswordRequest.go
  onSerialize() {
    const dto = Object.create(SignInNewPasswordRequest.prototype);
    dto.userId = this.userId;
    dto.password = this.password;
    dto.session = this.session;
    return dto;
  }

}
