import { Pipe, PipeTransform } from '@angular/core';
import { BaseDisplay } from '../../../models/display/dto/base-display';
import { Menu } from '../../../models/menu/dto/menu';
import { TemplateCollection } from '../../../models/template/dto/template-collection';
import { TemplateStatus } from '../../../models/template/enum/template-status.enum';
import { MenuTemplate } from '../../../models/template/dto/menu-template';

@Pipe({
  name: 'isPublishedCollectionWithDraftTemplate'
})
export class IsPublishedCollectionWithDraftTemplatePipe implements PipeTransform {

  transform(display: BaseDisplay, menu: Menu): boolean {
    const isPublishedCollection = display instanceof TemplateCollection && display?.status === TemplateStatus.Published;
    const isDraftTemplate = menu instanceof MenuTemplate && menu?.status === TemplateStatus.Draft;
    return isPublishedCollection && isDraftTemplate;
  }

}
