import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '../../../../models/account/dto/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent extends BaseComponent implements OnChanges {

  @Input() title: string = 'Add User';
  @Input() infoTitle: string = 'Can\'t Find a User?';
  @Input() infoText: string = 'Make sure the user you\'re looking for has been added to your company.';
  @Input() searchPlaceHolder: string = 'Name or Email';
  @Input() users: User[] = [];
  @Input() alreadyAdded: User[] = [];

  private _usersToAdd: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  public usersToAdd$ = this._usersToAdd.asObservable();
  public hasUsersToAdd$ = this.usersToAdd$.pipe(map(users => users?.length > 0));
  public infoTextToggled: boolean = false;

  // look ahead users
  private _users = new BehaviorSubject<User[]>([]);
  private _alreadyAddedUsers = new BehaviorSubject<User[]>([]);
  public lookAheadUsers$ = combineLatest([
    this._users,
    this._alreadyAddedUsers.pipe(map(users => users.map(user => user.userId))),
    this._usersToAdd.pipe(map(users => users.map(user => user.userId)))
  ]).pipe(
    map(([users, alreadyAddedIds, userIdsToAdd]) => {
      users?.forEach(u => {
        const alreadyAdded = alreadyAddedIds?.contains(u?.userId) || userIdsToAdd?.contains(u?.userId);
        u?.setAlreadyAddedToScheduledMenu(alreadyAdded);
      });
      return users;
    })
  );

  constructor(
    protected activeModal: NgbActiveModal
  ) {
    super();
  }

  override setupViews() {
    this._users.next(this.users);
    this._alreadyAddedUsers.next(this.alreadyAdded);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.users) this._users.next(this.users);
    if (changes.alreadyAdded) this._alreadyAddedUsers.next(this.alreadyAdded);
  }

  cancel() {
    this.activeModal.close();
  }

  addUser(user: any) {
    if (user instanceof User) {
      this.usersToAdd$.pipe(take(1)).subscribe(users => {
        users.push(user);
        const newUsers = users.uniqueByProperty('userId');
        this._usersToAdd.next(newUsers);
      });
    }
  }

  removeUser(user: User) {
    this.usersToAdd$.pipe(take(1)).subscribe(users => {
      users = users.filter(u => u.userId !== user.userId);
      users.uniqueByProperty('userId');
      this._usersToAdd.next(users);
    });
  }

  addUsers() {
    this.usersToAdd$.pipe(take(1)).subscribe(users => this.activeModal.close(users));
  }

}
