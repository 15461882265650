import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-clock',
  templateUrl: './loading-clock.component.html',
  styleUrls: ['./loading-clock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingClockComponent {

  @Input() size: string = '1.25rem';

}
