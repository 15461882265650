export class DropDownMenuSection {

  public header: DropDownMenuHeader;
  public items: DropDownMenuItem[];
  constructor(header: DropDownMenuHeader, items: DropDownMenuItem[]) {
    this.header = header;
    this.items = items;
  }

}

export class DropDownMenuHeader {

  public title: string;
  public titleIconSrc: string;
  constructor(title: string, titleIconSrc?: string) {
    this.title = title;
    this.titleIconSrc = titleIconSrc;
  }

}

export class DropDownMenuItem {

  public title: string;
  public titleIconSrc: string;
  public id: string;
  public value: any;
  public styling: Set<string>;

  constructor(title: string, value: any, styling?: Set<string>, titleIconSrc?: string, id?: string) {
    this.title = title;
    this.titleIconSrc = titleIconSrc;
    this.id = id;
    this.value = value;
    if (styling) {
      this.styling = styling;
    } else {
      this.styling = new Set<string>();
    }
  }

}
