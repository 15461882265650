<lib-reactive-form-group [bindTo]="template" [mergeKey]="mergeKey">
  <app-edit-template-locations-form-item
    [inputName]="'requiredLocationIds'"
    [bindingProperty]="'requiredLocationIds'"
    [updatedRequiredIds]="viewModel.changeRequiredTemplateIds$ | async">
  </app-edit-template-locations-form-item>
</lib-reactive-form-group>

<app-edit-template-locations-section-container
  [hasActiveLocationIds]="viewModel.hasActiveLocationIds$ | async"
  (editTemplateLocations)="viewModel.addOrEditRequiredLocations()">
  <ng-container [ngSwitch]="viewModel.hasActiveLocationIds$ | async">
    <ng-container *ngSwitchCase="true">
      <app-edit-template-location-list-item
        *ngFor="
          let grouping of viewModel.activeLocationsGroupedByProvince$ | async | keyvalue : incompleteLocationsLast
        "
        [templateStatus]="viewModel.templateStatus$ | async"
        (provinceClicked)="viewModel.provinceClicked($event)"
        [province]="grouping?.key"
        [locations]="grouping?.value"
        [updatedRequiredLocations]="
          viewModel.requiredLocationsGroupedByProvince$ | async | getLocationsFromProvinceKey : grouping?.key
        "
        [originalRequiredLocations]="
          viewModel.originalRequiredLocationsGroupedByProvince$ | async | getLocationsFromProvinceKey : grouping?.key
        ">
      </app-edit-template-location-list-item>
    </ng-container>

    <div *ngSwitchCase="false" fxLayout="column" fxLayoutAlign="start start" [style.gap.rem]="1">
      <div class="empty-description">
        You can add, edit, and remove required locations from here. They will be grouped by state/province.
      </div>
      <lib-button-primary (buttonClicked)="viewModel.addOrEditRequiredLocations()">
        Add Required Location
      </lib-button-primary>
    </div>
  </ng-container>
</app-edit-template-locations-section-container>
