import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { BehaviorSubject, combineLatest, iif, Observable, of, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DistinctUtils } from '../utils/distinct-utils';
import { SectionTemplate } from '../models/template/dto/section-template';
import { TemplateAPI } from '../api/template-api';
import { ProductDomainModel } from './product-domain-model';
import { CompanyDomainModel } from './company-domain-model';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from '../services/toast-service';
import { LocationDomainModel } from './location-domain-model';
import { CreateMenuTemplateReq } from '../models/template/dto/create-menu-template-req';
import { NewMenuSectionRequest } from '../models/menu/dto/new-menu-section-request';
import { HydratedVariantFeature } from '../models/product/dto/hydrated-variant-feature';
import { TemplateCollectionDomainModel } from './template-collection-domain-model';
import { MenuDomainModel } from './menu-domain-model';
import { DuplicateMenuRequest } from '../models/menu/shared/duplicate-menu-request';
import { DuplicateTemplateSectionRequest } from '../models/menu/shared/duplicate-template-section-request';
import { MenuStyle } from '../models/menu/dto/menu-style';
import { TemplateStatus } from '../models/template/enum/template-status.enum';
import { DomainTunnelFromMenuToTemplateService } from '../services/domain-tunnel-from-menu-to-template.service';
import { Menu } from '../models/menu/dto/menu';
import { ChangesRequiredForPreviewService } from '../services/changes-required-for-preview.service';
import { UserDomainModel } from './user-domain-model';
import type { Tag } from '../models/menu/dto/tag';
import type { MenuType } from '../models/utils/dto/menu-type-definition';

// Map<locationId, Map<menuTemplateId, MenuTemplate>>
type HydratedMenuTemplateMap = Map<number, Map<string, MenuTemplate>>;
// Map<locationId, Map<sectionTemplateId, SectionTemplate>>
type HydratedSectionTemplateMap = Map<number, Map<string, SectionTemplate>>;

// Provided by Logged In Scope
@Injectable()
export class TemplateDomainModel extends BaseDomainModel {

  constructor(
    private templateAPI: TemplateAPI,
    private userDomainModel: UserDomainModel,
    private productDomainModel: ProductDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private menuDomainModel: MenuDomainModel,
    private templateCollectionDomainModel: TemplateCollectionDomainModel,
    private toastService: ToastService,
    private domainTunnelFromMenuToTemplateService: DomainTunnelFromMenuToTemplateService,
    private changesRequiredForPreviewService: ChangesRequiredForPreviewService,
  ) {
    super();
  }

  public duplicatedMenuTemplatesWithAssets: Map<string, string[]> = new Map<string, string[]>();

  private readonly validSession$ = this.userDomainModel.validSession$;
  private readonly companyId$ = this.companyDomainModel.companyId$;
  private readonly locationId$ = this.locationDomainModel.locationId$;
  private readonly menuThemes$ = this.menuDomainModel.menuThemes$;

  /**
   * Circuit breaker for template feature.
   * If the breaker is enabled, then on$ will flow.
   * If the breaker is disabled, then the observable flow will emit the offDefaultValue.
   *
   * @returns an observable stream
   */
  private templateCircuitBreaker = <T>(on$: Observable<T>, offDefaultValue: T): Observable<T> => {
    return this.userDomainModel.canUseTemplates$.pipe(
      switchMap(canUseTemplates => iif(() => canUseTemplates, on$, of(offDefaultValue)))
    );
  };

  private _loadingMenuTemplates = new BehaviorSubject<boolean>(false);
  public loadingMenuTemplates$ = this._loadingMenuTemplates as Observable<boolean>;
  connectToLoadingMenuTemplates = (loading: boolean) => this._loadingMenuTemplates.next(loading);

  private _menuTemplates = new BehaviorSubject<MenuTemplate[]>(null);
  public menuTemplates$ = this.templateCircuitBreaker(
    combineLatest([
      this._menuTemplates,
      this.menuThemes$
    ]).pipe(
      map(([menuTemplates, themes]) => {
        menuTemplates?.forEach(menu => menu.hydratedTheme = themes?.find(theme => theme?.id === menu?.theme));
        return menuTemplates?.sort((a, b) => a?.name?.localeCompare(b.name));
      }),
    ),
    []
  );

  public existingMenuTemplateTags$ = this.menuTemplates$.pipe(
    map(templates => MenuTemplate.getUniqueTags(templates)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  private listenForDeletedTemplatedMenu = this.domainTunnelFromMenuToTemplateService.deletedTemplatedMenu$
    .subscribeWhileAlive({
      owner: this,
      next: menu => this.templatedMenuDeleted(menu)
    });

  private fetchCompanyMenuTemplates = this.templateCircuitBreaker(
    combineLatest([this.validSession$, this.companyId$.notNull()]).pipe(
      switchMap(([valid, compId]) => {
        const templates$ = this.menuTemplates$.pipe(distinctUntilChanged(DistinctUtils.distinctByMenuIds));
        return templates$.pipe(map(templates => [valid, compId, templates] as [boolean, number, MenuTemplate[]]));
      })
    ),
    [false, 0, []]
  ).subscribeWhileAlive({
    owner: this,
    next: ([validSession, companyId, currentLocationMenus]) => {
      if (validSession && !currentLocationMenus) this.loadCompanyMenuTemplates();
    }
  });

  public printMenuTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isPrint = (template: MenuTemplate) => template?.isPrintMenu();
      return menuTemplates?.filter(isPrint);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public printCardTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isPrintCard = (template: MenuTemplate) => template?.isPrintCardMenu();
      return menuTemplates?.filter(isPrintCard);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public printLabelTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isPrintLabel = (template: MenuTemplate) => template.isPrintLabelMenu();
      return menuTemplates?.filter(isPrintLabel);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public printReportMenuTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isPrintReport = (template: MenuTemplate) => template?.isPrintReportMenu();
      return menuTemplates?.filter(isPrintReport);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public digitalMenuTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isDigitalMenu = (template: MenuTemplate) => template?.isDigitalMenu();
      return menuTemplates?.filter(isDigitalMenu);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public displayMenuTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isDisplayMenu = (template: MenuTemplate) => template?.isDisplayMenu();
      return menuTemplates?.filter(isDisplayMenu);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public webMenuTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isWebMenu = (template: MenuTemplate) => template?.isWebMenu();
      return menuTemplates?.filter(isWebMenu);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public marketingMenuTemplates$ = this.menuTemplates$.pipe(
    map(menuTemplates => {
      const isMarketing = (template: MenuTemplate) => template?.isMarketingMenu();
      return menuTemplates?.filter(isMarketing);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getMenuTemplateTagsFor(menuType: MenuType): Observable<Tag[]> {
    return this.menuTemplates$.pipe(
      map(menus => menus?.filter(menu => menu.type === menuType)),
      map(menus => Menu.getUniqueTags(menus)),
      distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  /* ************************** Hydrated Menu Templates ************************** */

  private _hydratedMenuTemplates: BehaviorSubject<HydratedMenuTemplateMap> = new BehaviorSubject(new Map());
  public hydratedMenuTemplates$ = this._hydratedMenuTemplates as Observable<HydratedMenuTemplateMap>;
  connectToHydratedMenuTemplates = (val: HydratedMenuTemplateMap) => this._hydratedMenuTemplates.next(val);

  private _activeMenuTemplateId = new BehaviorSubject<string>(null);
  public activeMenuTemplateId$ = this._activeMenuTemplateId.pipe(distinctUntilChanged());
  public selectActiveMenuTemplate = (templateId: string) => this._activeMenuTemplateId.next(templateId);
  public clearActiveMenuTemplate = () => this._activeMenuTemplateId.next(null);

  public activeMenuTemplate$ = combineLatest([
    this.locationId$,
    this.activeMenuTemplateId$,
    this.hydratedMenuTemplates$,
  ]).pipe(map(([locId, activeTemplateId, menuTemplates]) => menuTemplates?.get(locId)?.get(activeTemplateId)))
    .deepCopy()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  getHydratedMenuTemplate(locationId: number, menuTemplateId: string): Observable<MenuTemplate> {
    return this.templateAPI.GetMenuTemplateForLocation(locationId, menuTemplateId).pipe(
      tap(menuTemplate => this.updateHydratedMenuTemplateMap(locationId, menuTemplate))
    );
  }

  private updateHydratedMenuTemplateMap = (
    locationId: number,
    template: MenuTemplate,
    removeItem: boolean = false
  ): void => {
    if (!!template) {
      this.hydratedMenuTemplates$.once(menuTemplatesMap => {
        const updatedMap = menuTemplatesMap?.shallowCopy() ?? new Map();
        if (!updatedMap?.get(locationId)) {
          updatedMap.set(locationId, new Map<string, MenuTemplate>());
        }
        if (removeItem) {
          updatedMap?.get(locationId)?.delete(template?.id);
        } else {
          const copy = window?.injector?.Deserialize?.instanceOf(MenuTemplate, template);
          updatedMap?.get(locationId)?.set(template?.id, copy);
        }
        this.connectToHydratedMenuTemplates(updatedMap);
      });
    }
  };

  /* ************************** Hydrated Section Templates ************************** */

  private _hydratedSectionTemplates = new BehaviorSubject(new Map<number, Map<string, SectionTemplate>>());
  private hydratedSectionTemplates$ = this._hydratedSectionTemplates as Observable<HydratedSectionTemplateMap>;
  connectToHydratedSectionTemplates = (val: HydratedSectionTemplateMap) => this._hydratedSectionTemplates.next(val);

  private _activeSectionTemplateId = new BehaviorSubject<string>(null);
  public activeSectionTemplateId$ = this._activeSectionTemplateId.pipe(distinctUntilChanged());
  public selectActiveSectionTemplate = (templateId: string) => this._activeSectionTemplateId.next(templateId);
  public clearActiveSectionTemplate = () => this._activeSectionTemplateId.next(null);

  private applyProductDetailsToHydratedSectionTemplate = this.productDomainModel.applyProductDetailsToHydratedSection;

  public activeSectionTemplate$ = combineLatest([
    this.locationId$,
    this.activeSectionTemplateId$,
    this.hydratedSectionTemplates$,
  ]).pipe(
      map(([locationId, sectionId, sectionTemplates]) => sectionTemplates?.get(locationId)?.get(sectionId)),
      switchMap(sectionTemplate => this.applyProductDetailsToHydratedSectionTemplate(sectionTemplate) ?? null)
    )
    .deepCopy()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  public createSectionTemplate(templateId: string, req: NewMenuSectionRequest): Observable<SectionTemplate> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        req.configurationId = templateId;
        return this.templateAPI.CreateSectionTemplate(locationId, req).pipe(
          tap(templateSection => {
            this.updateHydratedSectionTemplateMap(locationId, templateSection);
            this.replaceSectionTemplatesInAllPipelines(locationId, templateId, [templateSection]);
          })
        );
      })
    );
  }

  public getHydratedSectionTemplate(
    locationId: number,
    menuTemplateId: string,
    sectionTemplateId: string
  ): Observable<SectionTemplate> {
    return this.templateAPI.GetSectionTemplate(locationId, menuTemplateId, sectionTemplateId).pipe(
      // Apply display attributes and inventory to section products
      switchMap(template => this.applyProductDetailsToHydratedSectionTemplate(template) as Observable<SectionTemplate>),
      tap(section => this.updateHydratedSectionTemplateMap(locationId, section)),
      take(1)
    );
  }

  private updateHydratedSectionTemplateMap = (
    locationId: number,
    template: SectionTemplate,
    removeItem: boolean = false
  ): void => {
    if (!!template) {
      this.hydratedSectionTemplates$.once(sectionTemplatesMap => {
        const updatedSectionsMap = sectionTemplatesMap?.shallowCopy() ?? new Map();
        if (!updatedSectionsMap?.get(locationId)) {
          updatedSectionsMap.set(locationId, new Map<string, SectionTemplate>());
        }
        const sectionTemplateMap = updatedSectionsMap?.get(locationId);
        removeItem
          ? sectionTemplateMap?.delete(template.id)
          : sectionTemplateMap?.set(template.id, window?.injector?.Deserialize?.instanceOf(SectionTemplate, template));
        this.connectToHydratedSectionTemplates(updatedSectionsMap);
      });
    }
  };

  /* ************************** Update Menu/Section Template Pipelines ************************** */

  private templatedMenuDeleted(menu: Menu): void {
    this.menuTemplates$.once(templates => {
      const template = templates?.find(t => t?.id === menu?.templateId);
      if (!!template) {
        template?.requiredLocationIds?.remove(menu?.locationId);
        template?.activeLocationIds?.remove(menu?.locationId);
      }
      this.replaceNonHydratedMenuTemplatesInAllPipelines([template], false);
    });
  }

  private replaceNonHydratedMenuTemplatesInAllPipelines(
    updatedTemplates: MenuTemplate[],
    removeItems: boolean = false
  ) {
    this.menuTemplates$.once(existing => {
      const updatedExistingTemplates = MenuTemplate.updateMenuTemplates(existing, updatedTemplates, removeItems);
      this._menuTemplates.next(updatedExistingTemplates);
      if (removeItems) updatedTemplates?.forEach(t => this.menuDomainModel.removeMenusInheritedFromTemplate(t?.id));
      this.menuDomainModel.updateTemplatesAttachedToTemplatedMenus(updatedTemplates, removeItems);
      this.templateCollectionDomainModel.updateMenuTemplatesInCollections(updatedTemplates, removeItems);
    });
  }

  public updateMenuSectionTemplate(sectionTemplate: SectionTemplate): Observable<SectionTemplate> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.templateAPI.UpdateMenuSectionTemplate(locationId, sectionTemplate).pipe(
          switchMap(updatedSection => this.productDomainModel.applyProductDetailsToHydratedSection(updatedSection)),
          tap(updatedSection => {
            this.replaceSectionTemplatesInAllPipelines(locationId, updatedSection?.configurationId, [updatedSection]);
          }),
          take(1),
        );
      })
    );
  }

  /* ************************** Update Section Template Priorities ************************** */

  public updateMenuSectionTemplatePriorities(
    template: MenuTemplate,
    sectionTemplates: SectionTemplate[]
  ): Observable<SectionTemplate[]> {
    return this.templateAPI.UpdateMenuSectionTemplatePriorities(sectionTemplates).pipe(
      tap(updatedSections => this.replaceMenuSectionTemplatePriorities(template?.id, updatedSections)),
      tap(_ => {
        if (template?.isSmartPlaylistMenu()) {
          this.changesRequiredForPreviewService.removePreviewForAllLocations(template);
        }
      }),
      map(_ => sectionTemplates),
      take(1)
    );
  }

  private replaceMenuSectionTemplatePriorities(menuTemplateId: string, sectionTemplates: SectionTemplate[]): void {
    combineLatest([
      this.locationId$,
      this.menuTemplates$,
      this.hydratedMenuTemplates$
    ]).once(([locationId, menuTemplates, hydratedMenuTemplates]) => {
      const menuTemplate = menuTemplates?.find(m => m?.id === menuTemplateId);
      const hydratedMenuTemplate = hydratedMenuTemplates?.get(locationId)?.get(menuTemplateId);
      sectionTemplates?.forEach((updatedSection) => {
        if (menuTemplate) this.replaceMenuSectionTemplatePriorityHelper(menuTemplate, updatedSection);
        if (hydratedMenuTemplate) this.replaceMenuSectionTemplatePriorityHelper(hydratedMenuTemplate, updatedSection);
      });
      if (menuTemplate) this.replaceNonHydratedMenuTemplatesInAllPipelines([menuTemplate]);
      if (hydratedMenuTemplate) this.updateHydratedMenuTemplateMap(locationId, hydratedMenuTemplate);
    });
  }

  private replaceMenuSectionTemplatePriorityHelper(
    menuTemplate: MenuTemplate,
    updatedSectionTemplate: SectionTemplate
  ): void {
    menuTemplate?.templateSections
      ?.find(s => s?.id === updatedSectionTemplate?.id)
      ?.setOrderableValue(updatedSectionTemplate?.getOrderValue());
  }

  /* ************************ Delete Section/Menu Templates ************************ */

  public deleteMenuTemplate(menu: MenuTemplate): Observable<any> {
    return this.templateAPI.DeleteMenuTemplate(menu).pipe(
      tap(_ => this.updateHydratedMenuTemplateInAllPipelines(menu, true)),
      take(1)
    );
  }

  public deleteMenuSectionTemplate(section: SectionTemplate, template: MenuTemplate): Observable<string> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.templateAPI.DeleteMenuSectionTemplate(section).pipe(
          tap(_ => this.replaceSectionTemplatesInAllPipelines(locationId, section?.configurationId, [section], true)),
          tap(_ => {
            if (template?.isSmartPlaylistMenu()) {
              this.changesRequiredForPreviewService.removePreviewForAllLocations(template);
            }
          }),
          take(1)
        );
      })
    );
  }

  /* ************************ Duplicate Section/Menu Templates ************************ */

  public duplicateMenuTemplate(locationId: number, req: DuplicateMenuRequest): Observable<MenuTemplate> {
    return this.templateAPI.DuplicateMenuTemplate(locationId, req).pipe(
      tap(newMenu => this.appendTemplates([newMenu]))
    );
  }

  public duplicateSectionTemplate(req: DuplicateTemplateSectionRequest): Observable<SectionTemplate> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        const menuTemplateId = req.targetTemplateId;
        return this.templateAPI.DuplicateSectionTemplate(locationId, req).pipe(
          switchMap(newSection => this.applyProductDetailsToHydratedSectionTemplate(newSection)),
          tap(updatedSection => {
            this.updateHydratedSectionTemplateMap(locationId, updatedSection);
            this.replaceSectionTemplatesInAllPipelines(locationId, menuTemplateId, [updatedSection]);
          }),
          take(1),
        );
      })
    );
  }

  private replaceSectionTemplatesInAllPipelines(
    locationId: number,
    templateId: string,
    sectionTemplates: SectionTemplate[],
    removeSections: boolean = false
  ) {
    combineLatest([
      this.menuTemplates$,
      this.hydratedMenuTemplates$
    ]).once(([existingTemplates, hydratedMenuTemplates]) => {
      const existingMenuTemplate = existingTemplates?.find(m => m?.id === templateId);
      const hydratedMenuTemplate = hydratedMenuTemplates?.get(locationId)?.get(templateId);
      sectionTemplates?.forEach(updatedSectionTemplate => {
        this.updateHydratedSectionTemplateMap(locationId, updatedSectionTemplate, removeSections);
        existingMenuTemplate?.updateSectionTemplate(updatedSectionTemplate, removeSections);
        hydratedMenuTemplate?.updateSectionTemplate(updatedSectionTemplate, removeSections);
      });
      if (existingMenuTemplate) this.replaceNonHydratedMenuTemplatesInAllPipelines([existingMenuTemplate]);
      if (hydratedMenuTemplate) this.updateHydratedMenuTemplateMap(locationId, hydratedMenuTemplate);
    });
  }

  /* ************************ Update/Remove Template Product Styling ************************ */

  private _updateTemplateProductStyle = new BehaviorSubject<[string, MenuStyle[]]>([null, null]);
  private updateHydratedTemplateStylesMechanism = combineLatest([
    this.locationId$,
    this.hydratedMenuTemplates$,
    this._updateTemplateProductStyle,
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([locationId, menuTemplates, [templateId, styles]]) => {
      const isTemplateStyle = styles?.some(style => style?.isTemplateStyle);
      if (!!locationId && !!templateId && isTemplateStyle) {
        const menuTemplate = menuTemplates?.get(locationId)?.get(templateId);
        if (menuTemplate?.id === templateId) {
          menuTemplate.styling = styles;
        }
        this._updateTemplateProductStyle.next([null, null]);
        this.updateHydratedMenuTemplateMap(locationId, menuTemplate);
      }
    });

  public updateTemplateProductStyling(menuStyleMap: Map<string, MenuStyle[]>): void {
    for (const [menuOrTemplateId, updatedStyles] of menuStyleMap.entries()) {
      if (!!menuOrTemplateId) this._updateTemplateProductStyle.next([menuOrTemplateId, updatedStyles]);
    }
  }

  private _removeTemplateProductStyle = new BehaviorSubject<[string, MenuStyle[]]>([null, null]);
  private removeHydratedTemplateStylesMechanism = combineLatest([
    this.locationId$,
    this.hydratedMenuTemplates$,
    this._removeTemplateProductStyle
  ]).pipe(
    debounceTime(100),
    takeUntil(this.onDestroy)
  ).subscribe(([locationId, menuTemplates, [templateId, styles]]) => {
    const menuTemplate = menuTemplates?.get(locationId)?.get(templateId);
    const isTemplateStyle = styles?.some(style => style?.isTemplateStyle);
    if (!!menuTemplate && !!templateId && (menuTemplate.id === templateId) && isTemplateStyle) {
      styles?.forEach((deleteStyle) => {
        const deleteIndex = menuTemplate?.styling?.findIndex(s => s?.id === deleteStyle?.id);
        if (deleteIndex > -1) menuTemplate.styling.splice(deleteIndex, 1);
      });
    }
    if (!!menuTemplate) {
      this._removeTemplateProductStyle.next([null, null]);
      this.updateHydratedMenuTemplateMap(locationId, menuTemplate);
    }
  });

  public removeTemplateProductStyling(templateId: string, styles: MenuStyle[]): void {
    styles?.forEach(style => {
      if (!!templateId) this._removeTemplateProductStyle.next([templateId, [style]]);
    });
  }

  /* ******************************************************************************** */

  public getCompanyMenuTemplates(): Observable<MenuTemplate[]> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.templateAPI.GetCompanyMenuTemplates(locationId).pipe(
          tap(menuTemplates => this._menuTemplates.next(menuTemplates)),
        );
      }),
      take(1)
    );
  }

  public loadCompanyMenuTemplates() {
    this.connectToLoadingMenuTemplates(true);
    this.getCompanyMenuTemplates().subscribe({
      complete: () => this.connectToLoadingMenuTemplates(false),
      error: (err: BsError) => {
        this.connectToLoadingMenuTemplates(false);
        this.toastService.publishError(err);
        throwError(err);
      }
    });
  }

  public createMenuTemplate(t: CreateMenuTemplateReq): Observable<MenuTemplate> {
    return this.locationDomainModel.locationId$.pipe(
      switchMap(locationId => {
        return this.templateAPI.CreateMenuTemplate(t, locationId).pipe(
          tap((newTemplate) => this.appendTemplates([newTemplate]))
        );
      })
    );
  }

  private appendTemplates(incomingTemplates: MenuTemplate[]) {
    this.menuTemplates$.once(templates => {
      const newTemplates = [...(templates ?? []), ...(incomingTemplates ?? [])];
      this._menuTemplates.next(newTemplates);
    });
  }

  public saveMenuTemplate(menuTemplate: MenuTemplate): Observable<MenuTemplate> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.templateAPI.UpdateMenuTemplate(locationId, menuTemplate).pipe(
          tap(updatedMenuTemplate => this.updateHydratedMenuTemplateInAllPipelines(updatedMenuTemplate)),
          take(1),
        );
      })
    );
  }

  public publishMenuTemplate(menuTemplate: MenuTemplate): Observable<MenuTemplate> {
    menuTemplate.status = TemplateStatus.Published;
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.saveMenuTemplate(menuTemplate).pipe(
          tap(publishedMenuTemplate => {
            const templateBelongsToCurrLocation = publishedMenuTemplate?.requiredLocationIds?.includes(locationId);
            if (templateBelongsToCurrLocation) this.menuDomainModel.loadLocationMenus();
          })
        );
      })
    );
  }

  public updateMenuVariantFeature(currentMenu: MenuTemplate, vf: HydratedVariantFeature): Observable<MenuTemplate> {
    if (vf?.hasChanges(currentMenu.variantFeature)) {
      currentMenu.variantFeature = vf;
      return this.saveMenuTemplate(currentMenu);
    } else {
      return of(currentMenu);
    }
  }

  private updateHydratedMenuTemplateInAllPipelines(
    updatedMenuTemplate: MenuTemplate,
    removeItem: boolean = false
  ): void {
    this.locationId$.once(locationId => {
      this.updateHydratedMenuTemplateMap(locationId, updatedMenuTemplate, removeItem);
      this.replaceNonHydratedMenuTemplatesInAllPipelines([updatedMenuTemplate], removeItem);
      const templateBelongsToCurrLocation = updatedMenuTemplate?.requiredLocationIds?.includes(locationId);
      const templateIsPublished = updatedMenuTemplate?.isPublished();
      if (templateBelongsToCurrLocation && templateIsPublished) this.menuDomainModel.loadLocationMenus();
    });
  }

}
