import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templatedMenuDisabledOverrideTooltip'
})
export class TemplatedMenuDisabledOverrideTooltipPipe implements PipeTransform {

  transform(isTemplated: boolean): string {
    if (isTemplated) {
      return 'This override must be enabled on the template';
    }
    return null;
  }

}
