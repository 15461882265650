export class ReorderOptions {

  // Text
  public title: string;
  public subTitle: string;
  public bodyText: string;
  // Display Icons
  public displayIcons = false;
  // CTAs
  public cancelText: string = 'Cancel';
  public cancelButtonClass: string = 'neutral-button';
  public confirmText: string = 'Do it';
  public confirmButtonClass: string = 'preferred-button';
  // Priority Value
  public orderPrefix: number = 0;
  // Loading
  public loadingMess: string;
  public successTitle: string;
  public successMess: string;
  public failureTitle: string;
  public failureMess: string;

}
