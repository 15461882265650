import { ClientTypeUtils } from '../../utils/client-type-utils';
import { FoundationBaseDirective } from '@mobilefirstdev/base-angular';
import { Directive } from '@angular/core';

@Directive()
export abstract class BaseDirective extends FoundationBaseDirective {

  ClientTypes = ClientTypeUtils;

}
