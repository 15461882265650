import { Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
  name: 'asArrayOf'
})
export class AsArrayOfPipe implements PipeTransform {

  transform<T>(value: any, CastedTo: Type<T>): T[] {
    return value as T[];
  }

}
