import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditStaticSectionMenuFooterComponent } from '../../shared/edit-static-section-menu-footer/edit-static-section-menu-footer.component';
import { EditCardStackMenuViewModel } from '../edit-card-stack-menu-view-model';
import { EditCardStackMenuCombinedLoadingOptsViewModel } from '../edit-card-stack-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-card-stack-menu-footer',
  templateUrl: '../../shared/edit-static-section-menu-footer/edit-static-section-menu-footer.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackMenuFooterComponent extends EditStaticSectionMenuFooterComponent {

  constructor(
    public override viewModel: EditCardStackMenuViewModel,
    public override sharedLoadingState: EditCardStackMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, sharedLoadingState);
  }

}
