import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HasChildIds } from '../../../../../../../../models/protocols/has-child-ids';
import { exists } from '../../../../../../../../functions/exists';
import { StackManualBulkPrintJobViewModel } from '../../stack-manual-bulk-print-job-view-model';

@Component({
  selector: 'app-label-stack-manual-bulk-print-job-select-all-variants',
  templateUrl: './label-stack-manual-bulk-print-job-select-all-variants.component.html',
  styleUrls: ['./label-stack-manual-bulk-print-job-select-all-variants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackManualBulkPrintJobSelectAllVariantsComponent {

  constructor(
    public viewModel: StackManualBulkPrintJobViewModel
  ) {
  }

  @Input() selection: HasChildIds;
  @Input() selectionMap: Map<string, number>;
  @Output() bulkAddClicked = new EventEmitter<string[]>(true);
  @Output() bulkRemoveClicked = new EventEmitter<string[]>(true);

  addOrRemoveClicked(): void {
    this.selection?.getChildIds()?.every(id => exists(this.selectionMap?.get(id)))
      ? this.bulkRemoveClicked.emit(this.selection?.getChildIds())
      : this.bulkAddClicked.emit(this.selection?.getChildIds());
  }

}
