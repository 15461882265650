<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<img (click)="viewModel.navigateToRoot()" [src]="'assets/logo/light/logo-filled.svg'" alt="logo" class="nav-logo" />
<ul class="nav-list bs-menu-nav">
  <li *ngFor="let navItem of viewModel.navItems$ | async">
    <app-nav-item
      *ngIf="navItem | isDefaultNavItem"
      [navItem]="navItem"
      [currentNavUrl]="viewModel.currentNavUrl$ | async"></app-nav-item>
    <app-products-nav-item
      *ngIf="navItem | isProductsNavItem"
      [navItem]="navItem"
      [currentNavUrl]="viewModel.currentNavUrl$ | async"></app-products-nav-item>
    <app-help-nav-item
      *ngIf="navItem | isHelpNavItem"
      [navItem]="navItem"
      [currentNavUrl]="viewModel.currentNavUrl$ | async"></app-help-nav-item>
    <app-profile-nav-item
      *ngIf="navItem | isProfileNavItem"
      [navItem]="navItem"
      [currentNavUrl]="viewModel.currentNavUrl$ | async"></app-profile-nav-item>
    <app-current-location-nav-item
      *ngIf="navItem | isLocationNavItem"
      [navItem]="navItem"
      [currentNavUrl]="viewModel.currentNavUrl$ | async"></app-current-location-nav-item>
    <app-sign-out-nav-item
      *ngIf="navItem | isSignOutNavItem"
      [navItem]="navItem"
      [currentNavUrl]="viewModel.currentNavUrl$ | async"></app-sign-out-nav-item>
  </li>
</ul>
<div class="sidebar-software-version">
  {{ viewModel.versionString$ | async }}
</div>
