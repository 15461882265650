<div class="auth-flow-wrapper">
  <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

  <div class="auth-flow-container">
    <div class="auth-flow-title">
      {{ viewModel.getAuthFlowTitle() }}
    </div>

    <div class="auth-flow-subtext">
      {{ viewModel.getAuthFlowSubtext() }}
    </div>

    <lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
      <lib-reactive-form-email
        #resetCodeEmail
        [inputName]="'email'"
        [label]="'Email'"
        [placeholder]="'Enter your email'"
        [bindingProperty]="'email'"
        [required]="true">
      </lib-reactive-form-email>
    </lib-reactive-form-group>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
      <lib-button-primary (buttonClicked)="form.submitForm()" [disabled]="form.hasErrors$ | async">
        Send Reset Code
      </lib-button-primary>

      <lib-button-text (buttonClicked)="backToSignIn()"> Return to Sign In </lib-button-text>
    </div>
  </div>
</div>
