import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayMenuSectionOptionsComponent } from '../../../../display-menu-section/display-menu-section-body/display-menu-section-options/display-menu-section-options.component';

@Component({
  selector: 'app-display-menu-section-inside-template-collection-options',
  templateUrl: './display-menu-section-inside-template-collection-options.component.html',
  styleUrls: ['./display-menu-section-inside-template-collection-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionInsideTemplateCollectionOptionsComponent extends DisplayMenuSectionOptionsComponent {

  @Input() override menuIsPartOfCollection = true;

}
