<div>
  <div class="d-flex justify-content-between pt-12px">
    <div class="f18px pb-8px d-flex flex-column">
      <div class="bs-bold section-header mb-12px">Smart Filters</div>
      <p *ngIf="!(viewModel.smartFilterIds$ | async)?.length" class="smart-filter-description f12px">
        Smart Filters can be added to {{ smartDataType | lowercase }} to automatically apply the
        {{ smartDataType | lowercase | slice : 0 : -1 }} to any products that meet the smart filter criteria.
      </p>
    </div>
    <div matRipple fxLayout="row" fxLayoutAlign="center center" class="plus-button" (click)="openSmartFilterModal()">
      <img class="plus-icon" [src]="'assets/icons/light/solid/plus.svg'" alt="plus icon" />
    </div>
  </div>
  <app-smart-filter-line-item
    *ngFor="let smartFilterId of viewModel.smartFilterIds$ | async"
    [smartFilterId]="smartFilterId"
    (smartFilterRemoved)="smartFilterRemoved.emit($event)">
  </app-smart-filter-line-item>
</div>
