<lib-reactive-form-group>
  <div class="search-desc-and-tag-container mt-24px">
    <lib-reactive-search-bar
      #search
      class="d-inline-block search-bar"
      [searchThrough]="viewModel.itemsToFilter$ | async"
      [searchTheseProperties]="['name']"
      (searchText)="viewModel.connectToSearchText($event)"
      (searchHits)="viewModel.connectToSearchedItems($event)">
    </lib-reactive-search-bar>
    <lib-reactive-form-drop-down
      #menuTypeFilter
      *ngIf="viewModel.showMenuTypeDropdown$ | async"
      class="drop-down"
      [inputName]="'menuTypeFilter'"
      [label]="viewModel.menuTypeFilterLabel$ | async"
      [placeholder]="viewModel.menuTypeFilterHint$ | async"
      [disabled]="viewModel.filterByMenuTypeDisabled$ | async"
      [dropdowns]="viewModel.menuTypeFilterOptions$ | async"
      [programmaticallyChangeValue]="viewModel.selectFilterByMenuType$ | async"
      (valueChanged)="viewModel.connectToMenuTypeFilter($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      #inactiveStateFilter
      class="drop-down"
      [inputName]="'inactiveStateFilter'"
      [label]="viewModel.inactiveStateFilterLabel$ | async"
      [placeholder]="viewModel.inactiveStateFilterHint$ | async"
      [disabled]="viewModel.filterByInactiveStateDisabled$ | async"
      [dropdowns]="viewModel.inactiveStateFilterOptions$ | async"
      [programmaticallyChangeValue]="viewModel.selectFilterByInactiveState$ | async"
      (valueChanged)="viewModel.connectToUserSelectedInactiveStateFilter($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      [hidden]="!(viewModel.showTagDropdown$ | async)"
      class="drop-down"
      [inputName]="'filterTags'"
      [label]="'Filter by Tags'"
      [placeholder]="viewModel.tagHint$ | async"
      [dropdowns]="viewModel.sortedTags$ | async"
      [clearable]="true"
      [programmaticallyChangeValue]="viewModel.activeTagString$ | async"
      [disabled]="!(viewModel.hasTags$ | async)"
      [disabledTooltip]="'No tags have been added for the content below.'"
      (valueChanged)="viewModel.filterByTag($event)">
    </lib-reactive-form-drop-down>
  </div>
</lib-reactive-form-group>
