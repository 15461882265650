import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProductsTableComponent } from '../../all-products-data-table/products-table/products-table.component';
import { ReactiveTableComponent } from '@mobilefirstdev/reactive-table';
import { Product } from '../../../../models/product/dto/product';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResizeObserver } from '@juggle/resize-observer';

@Component({
  selector: 'app-product-picker-table',
  templateUrl: './product-picker-table.component.html',
  styleUrls: ['./product-picker-table.component.scss'],
  providers: [
    {
      provide: ProductsTableComponent,
      useExisting: forwardRef(() => ProductPickerTableComponent)
    }
  ]
})
export class ProductPickerTableComponent extends ProductsTableComponent implements OnChanges, OnDestroy {

  @ViewChild('hr') hr: ElementRef<HTMLDivElement>;
  @ViewChild(ReactiveTableComponent) reactiveTable: ReactiveTableComponent;
  @Input() override nItemsToDisplay: number;
  @Input() override resetPageOnNewData: boolean = false;
  @Input() override resetPageOnSortingChange: boolean = true;
  @Input() filterHeight: number = 0;
  @Input() productSelectionOnly: boolean = false;
  @Output() dataBeingDisplayed = new EventEmitter<Product[]>(true);

  private hrRO: ResizeObserver;
  private _hrHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public hrHeight$ = this._hrHeight as Observable<number>;
  private _filterHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public filterHeight$ = this._filterHeight as Observable<number>;
  public headerStickyOffset$ = combineLatest([
    this.hrHeight$,
    this.filterHeight$
  ]).pipe(
    map(([hrHeight, filterHeight]) => hrHeight + filterHeight)
  );

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterHeight) this._filterHeight.next(this.filterHeight);
  }

  override setupBindings() {
    super.setupBindings();
    this.reactiveTable?.dataToDisplay$?.subscribeWhileAlive({
      owner: this,
      next: data => this.dataBeingDisplayed.emit(data)
    });
    this.hrRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._hrHeight.next(entry.contentRect.height);
      }
    });
    this.hrRO.observe(this.hr.nativeElement);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.hrRO?.disconnect();
  }

}
