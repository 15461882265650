import { Component, Input } from '@angular/core';
import { Display } from '../../../../models/display/dto/display';
import { SelectableComponent } from '../../../shared/components/group-selection/selectable/selectable.component';
import { TemplateCollectionDomainModel } from '../../../../domainModels/template-collection-domain-model';

@Component({
  selector: 'app-display-selection-item',
  templateUrl: './display-selection-item.component.html',
  styleUrls: ['./display-selection-item.component.scss']
})
export class DisplaySelectionItemComponent extends SelectableComponent {

  constructor(private collectionDomainModel: TemplateCollectionDomainModel) {
    super();
  }

  @Input() override selection: Display;
  public allCollections$ = this.collectionDomainModel.templateCollections$;

}
