import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompactMenu } from '../../../../../../../../models/menu/dto/compact-menu';
import { BaseComponent } from '../../../../../../../../models/base/base-component';

@Component({
  selector: 'app-display-menu-section-options-container',
  templateUrl: './display-menu-section-options-container.component.html',
  styleUrls: ['./display-menu-section-options-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionOptionsContainerComponent extends BaseComponent {

  @Input() menu: CompactMenu;
  @Input() productOverflowTitle: string;
  @Output() openInformationModal = new EventEmitter();

}
