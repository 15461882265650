import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-additional-options-accordion',
  templateUrl: './menu-additional-options-accordion.component.html',
  styleUrls: ['./menu-additional-options-accordion.component.scss']
})
export class MenuAdditionalOptionsAccordionComponent {

  @Input() accordionTitle: string;
  @Input() showBottomHr: boolean = true;
  @Input() showUpperHr: boolean = false;
  @Input() showErrorIndicator: boolean = false;

}
