import { TypeDefinition } from './type-definition';

// The below Enum types do not represent all available options, but rather just those
// that are explicitly defined in the code base. See response from get-types
// endpoint for complete list of available Variant Types
// See https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/Enum/VariantType.go for all options
export enum VariantType {
  // Accessories
  Battery = 'Battery',
  Bong = 'Bong',
  Book = 'Book',
  Cleaning = 'Cleaning',
  Grinder = 'Grinder',
  Kitchenware = 'Kitchenware',
  Lighter = 'Lighter',
  Pipe = 'Pipe',
  Storage = 'Storage',
  Tray = 'Tray',
  // Beverage
  Beverage = 'Beverage',
  Dissolvable = 'Dissolvable',
  Distilled = 'Distilled',
  Tea = 'Tea',
  // Edible
  Edible = 'Edible',
  Gummy = 'Gummy',
  Baking = 'Baking',
  HardCandy = 'Hard Candy',
  // Flower - By Gram
  Flower = 'Flower',
  BulkFlower = 'Bulk Flower',
  MilledFlower = 'Milled Flower',
  InfusedFlower = 'Infused Flower',
  FreeRoll = 'Free Roll', // US Only
  Shake = 'Shake', // US Only
  // Flower - By Unit
  PreRoll = 'Pre-Roll',
  InfusedPreRoll = 'Infused Pre-Roll',
  InfusedBlunt = 'Infused Blunt',
  Blunt = 'Blunt',
  // Oil
  Oil = 'Oil',
  Capsule = 'Capsule',
  // Other
  Apparel = 'Apparel',
  Bundle = 'Bundle',
  GiftCard = 'Gift Card',
  Merch = 'Merch',
  Pet = 'Pet',
  Sample = 'Sample',
  Hemp = 'Hemp',
  // Seed
  Seed = 'Seed',
  // Wellness
  Bath = 'Bath',
  CreamAndLotion = 'Cream & Lotion',
  Lubricant = 'Lubricant',
  RollOn = 'Roll-On',
  SkinCare = 'Skin Care',
  Suppository = 'Suppository',
  Transdermal = 'Transdermal'
}

export class VariantTypeDefinition extends TypeDefinition {

  override value: VariantType;

  static isFlowerByGramType(vt: VariantType): boolean {
    return vt === VariantType.Flower
        || vt === VariantType.BulkFlower
        || vt === VariantType.MilledFlower
        || vt === VariantType.InfusedFlower
        || vt === VariantType.FreeRoll
        || vt === VariantType.Shake;
  }

  static isPreRollType(vt: VariantType): boolean {
    return vt === VariantType.PreRoll
        || vt === VariantType.InfusedPreRoll
        || vt === VariantType.InfusedBlunt
        || vt === VariantType.Blunt;
  }

  static isCapsuleType(vt: VariantType): boolean {
    return vt === VariantType.Capsule;
  }

  static isReadyToDrinkBeverageType(vt: VariantType): boolean {
    return vt === VariantType.Beverage;
  }

  static isOtherCannabis(vt: VariantType): boolean {
    return vt === VariantType.Bundle
        || vt === VariantType.Pet
        || vt === VariantType.Sample;
  }

}
