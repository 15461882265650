import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { EditVariantAvailabilityViewModel } from './edit-variant-availability-view-model';

@Component({
  selector: 'app-edit-variant-availability',
  templateUrl: './edit-variant-availability.component.html',
  styleUrls: ['./edit-variant-availability.component.scss'],
  providers: [EditVariantAvailabilityViewModel]
})
export class EditVariantAvailabilityComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: EditVariantAvailabilityViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

  absolutePercentage(percentage: number): number {
    return Math.abs(percentage);
  }

}
