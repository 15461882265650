import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMarketingSmartPlaylistMenuViewModel } from './edit-template-marketing-smart-playlist-menu-view-model';
import { EditSmartPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-view-model';
import { EditTemplateMarketingSmartPlaylistMenuCombinedLoadingOptsViewModel } from './edit-template-marketing-smart-playlist-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingMenuComponent } from '../edit-template-marketing-menu.component';

@Component({
  selector: 'app-edit-template-marketing-smart-playlist-menu',
  templateUrl: './edit-template-marketing-smart-playlist-menu.component.html',
  styleUrls: ['./edit-template-marketing-smart-playlist-menu.component.scss'],
  providers: [
    EditTemplateMarketingSmartPlaylistMenuViewModel,
    EditSmartPlaylistMenuViewModel,
    EditTemplateMarketingSmartPlaylistMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingSmartPlaylistMenuComponent extends EditTemplateMarketingMenuComponent {

  constructor(
    public override viewModel: EditTemplateMarketingSmartPlaylistMenuViewModel,
    combinedLoadingOptsViewModel: EditTemplateMarketingSmartPlaylistMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
