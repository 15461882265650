import type { BannerEventsService } from '../service/banner-events.service';
import { EventType } from './event-type.enum';

export class Event {

  public type: EventType;
  public message: string;
  public dismissed: boolean;
  private service: BannerEventsService;
  private secondsVisible: number;
  private timer;

  constructor(
    bannerEventsService: BannerEventsService,
    secondsVisible: number,
    type: EventType,
    message: string
  ) {
    this.type = type;
    this.message = message;
    this.dismissed = false;
    this.service = bannerEventsService;
    this.secondsVisible = secondsVisible;
  }

  public startTimer() {
    const kill = function(): void { this.service.dismiss(this); }.bind(this);
    this.timer = setInterval(kill, this.secondsVisible * 1000);
  }

  public killTimerAndDereference(): Event {
    this.dismissed = true;
    if (!!this.timer) clearInterval(this.timer);
    this.service = undefined;
    return this;
  }

}
