import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { SingleStackPrintJobAppliedProductsViewModel } from './single-stack-print-job-applied-products-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import type { StackType } from '../create-view-stack-print-job.component';

@Component({
  selector: 'app-single-stack-print-job-applied-products',
  templateUrl: './single-stack-print-job-applied-products.component.html',
  providers: [SingleStackPrintJobAppliedProductsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleStackPrintJobAppliedProductsComponent {

  constructor(public viewModel: SingleStackPrintJobAppliedProductsViewModel) {
  }

  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToSearchText') searchText: string;
  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;

}
