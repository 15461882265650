<app-loading
  [hidden]="!(combinedLoadingOptsViewModel.isLoading$ | async)"
  [options]="combinedLoadingOptsViewModel.loadingOpts$ | async">
</app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="mergeAndDispersedKey"
  (formsAutoSubmitted$)="viewModel.saveTemplate(true)"
  (formsSubmitted$)="viewModel.saveTemplate(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-container>
  <app-edit-template-marketing-menu-header
    [template]="viewModel.activeTemplate$ | async"
    [crumbs]="viewModel.crumbs$ | async"
    [allowLiveView]="viewModel.allowLiveView$ | async"
    [autoSaveLoadingOpts]="combinedLoadingOptsViewModel.autoSaveLoadingOpts$ | async"
    [lastSavedTimeText]="combinedLoadingOptsViewModel.lastAutoSavedTimeText$ | async"
    [headerText]="'Edit Template - Featured Product Menu'"
    [headerSubtext]="'Create consistent templates that can be deployed to select or all locations.'"
    [showPublishButton]="viewModel.showPublishButton$ | async"
    [templateStatus]="viewModel.templateStatus$ | async"
    [unsavedChanges]="formCapture.unsavedChanges$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [mergeKey]="mergeAndDispersedKey"
    [promptToPublishTemplate]="viewModel.promptToPublishTemplate"
    [showLiveView]="viewModel.showLiveView"
    (submitForms)="viewModel.saveIntercept($event)">
  </app-edit-template-marketing-menu-header>

  <app-edit-template-locations-section
    [mergeKey]="mergeAndDispersedKey"
    [template]="viewModel.activeTemplate$ | async"
    (changedRequiredTemplateIds)="viewModel.connectToChangedRequiredTemplateIds($event)">
  </app-edit-template-locations-section>

  <app-edit-featured-product-menu-body
    [templateMode]="true"
    [mergeKey]="mergeAndDispersedKey"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async">
  </app-edit-featured-product-menu-body>

  <app-edit-template-marketing-menu-footer
    [template]="viewModel.activeTemplate$ | async"
    [allowLiveView]="viewModel.allowLiveView$ | async"
    [autoSaveLoadingOpts]="combinedLoadingOptsViewModel.autoSaveLoadingOpts$ | async"
    [lastSavedTimeText]="combinedLoadingOptsViewModel.lastAutoSavedTimeText$ | async"
    [canDeleteTemplateMenu]="viewModel.canDeleteTemplateMenu$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [deleteTemplate]="viewModel.deleteTemplate"
    [openDuplicateTemplateModal]="viewModel.openDuplicateTemplateModal"
    [showLiveView]="viewModel.showLiveView"
    (submitForms)="viewModel.saveIntercept($event)">
  </app-edit-template-marketing-menu-footer>
</app-edit-menu-container>
