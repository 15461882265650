import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Menu } from '../models/menu/dto/menu';

// Provided by Logged in Scope
@Injectable()
export class DomainTunnelFromMenuToTemplateService {

  private _deletedTemplatedMenu = new Subject<Menu>();
  public deletedTemplatedMenu$ = this._deletedTemplatedMenu as Observable<Menu>;
  templatedMenuDeleted = (menu: Menu) => this._deletedTemplatedMenu.next(menu);

}
