import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { UploadAssetViewModel } from './upload-asset-view-model';
import { UploadImageInterface } from './upload-image-interface';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Animatable } from '../../../../models/protocols/animatable';
import { BehaviorSubject } from 'rxjs';

/**
 * This component allows you to drag and drop files into it, and notify a parent component when files
 * have changes.
 *
 * @param parentHandler: pass your parent component in that implements this interface, so that it
 * knows when the file list has changed.
 * @param maxImages: x <= 0 means infinite, else x
 * @param allowVideo: self explanatory
 * @param allowImage: self explanatory
 */
@Component({
  selector: 'app-upload-asset',
  templateUrl: './upload-asset.component.html',
  styleUrls: ['./upload-asset.component.scss'],
  providers: [UploadAssetViewModel],
  changeDetection: ChangeDetectionStrategy.Default
})
export class UploadAssetComponent extends BaseComponent implements OnChanges, Animatable {

  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;
  @Input() parentHandler: UploadImageInterface;
  @Input() allowImage: boolean = true;
  @Input() allowVideo: boolean = true;
  @Input() maxAssets: number = -1;
  @Input() displayList: boolean = true;
  @Input() isHidden: boolean = false;
  @Input() formPristine: boolean = true;
  @Input() isDisabledByTemplates: boolean = false;
  @Input() loadingOpts: LoadingOptions = LoadingOptions.default();
  @Input() id: number = 0;

  private _animating = new BehaviorSubject<boolean>(false);
  public animating$ = this._animating.asObservable();

  constructor(
    public viewModel: UploadAssetViewModel,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.id = this.id;
    this.viewModel.initMaxAssets(this.maxAssets);
    this.viewModel.initParentHandler(this.parentHandler);
    this.viewModel.initAcceptType(this.allowImage, this.allowVideo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxImages) this.viewModel.initMaxAssets(this.maxAssets);
    if (changes.parentHandler) this.viewModel.initParentHandler(this.parentHandler);
    if (changes.allowImage) this.viewModel.initAcceptType(this.allowImage, this.allowVideo);
    if (changes.allowVideo) this.viewModel.initAcceptType(this.allowImage, this.allowVideo);
    if (changes.id) this.viewModel.id = this.id;
  }

  override setupBindings() {
    const s = this.viewModel.resetInputState.subscribe(() => {
      this.fileDropEl.nativeElement.value = '';
    });
    this.pushSub(s);
  }

  onFileDropped($event) {
    if (!this.isDisabledByTemplates || !this.formPristine) {
      this.viewModel.handleUploadedFiles($event);
    }
  }

  clear() {
    this.viewModel.clear();
  }

  // Animating

  animate(duration: number) {
    this._animating.next(true);
    const updateAnimation = () => this._animating.next(false);
    setTimeout(updateAnimation, duration);
  }

}
