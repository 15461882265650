import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuTemplateRequiredLocationsChangedSaveConfirmationViewModel } from './menu-template-required-locations-changed-save-confirmation-view-model';

@Component({
  selector: 'app-menu-template-required-locations-changed-save-confirmation',
  templateUrl: './menu-template-required-locations-changed-save-confirmation.component.html',
  styleUrls: ['./menu-template-required-locations-changed-save-confirmation.component.scss'],
  providers: [MenuTemplateRequiredLocationsChangedSaveConfirmationViewModel]
})
export class MenuTemplateRequiredLocationsChangedSaveConfirmationComponent extends BaseModalComponent {

  constructor(
    public viewModel: MenuTemplateRequiredLocationsChangedSaveConfirmationViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

}
