import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PrintLabelLiveViewModalViewModel } from './print-label-live-view-modal-view-model';
import { PrintCardLiveViewModalComponent } from '../print-card-live-view-modal/print-card-live-view-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-print-label-card-live-view-modal',
  templateUrl: '../print-card-live-view-modal/print-card-live-view-modal.component.html',
  styleUrls: ['../print-card-live-view-modal/print-card-live-view-modal.component.scss'],
  providers: [PrintLabelLiveViewModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintLabelLiveViewModalComponent extends PrintCardLiveViewModalComponent {

  constructor(
    public override viewModel: PrintLabelLiveViewModalViewModel,
    activeModal: NgbActiveModal,
  ) {
    super(viewModel, activeModal);
  }

}
