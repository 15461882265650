import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditSectionForm } from '../edit-section-form/edit-section-form';
import { EditMediaSectionFormViewModel } from './edit-media-section-form-view-model';

@Component({
  selector: 'app-edit-media-section-form',
  templateUrl: './edit-media-section-form.component.html',
  styleUrls: ['./edit-media-section-form.component.scss'],
  providers: [EditMediaSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMediaSectionFormComponent extends EditSectionForm {

  constructor(public override viewModel: EditMediaSectionFormViewModel) {
    super(viewModel);
  }

}
