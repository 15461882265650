<div class="sticky-footer-container flex-container flex-align-center bg-white" [style.padding-top.rem]="1">
  <div class="text-link" [hidden]="!showBackButton" (click)="goBack.emit()">Go Back</div>
  <div [style.margin-left]="'auto'">
    <lib-button-neutral *ngIf="showCancelButton" (buttonClicked)="cancel.emit()"> Cancel </lib-button-neutral>
    <lib-button-primary
      *ngIf="showPrimaryButton"
      [style.margin-left]="'1rem'"
      [disabled]="disablePrimaryButton"
      (buttonClicked)="primaryButtonClicked.emit()">
      {{ primaryButtonText }}
    </lib-button-primary>
  </div>
</div>
