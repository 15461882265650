import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { User } from '../../../../models/account/dto/user';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../models/shared/stylesheet/drop-down-menu-section';
import { map } from 'rxjs/operators';
import { EmployeeAction } from '../../../../models/company/shared/employee-action';

@Injectable()
export class EmployeeViewModel extends BaseViewModel {

  constructor(
    private userDomainModel: UserDomainModel
  ) {
    super();
  }

  private _employee = new BehaviorSubject<User>(null);
  public employee$ = this._employee as Observable<User>;
  connectToEmployee = (employee: User) => this._employee.next(employee);

  private _showCompanyRole = new BehaviorSubject<boolean>(false);
  public showCompanyRole$ = this._showCompanyRole as Observable<boolean>;
  connectToShowCompanyRole = (showCompanyRole: boolean) => this._showCompanyRole.next(showCompanyRole);

  private _supportsTemplates = new BehaviorSubject<boolean>(false);
  public supportsTemplates$ = this._supportsTemplates as Observable<boolean>;
  connectToSupportsTemplates = (supportsTemplates: boolean) => this._supportsTemplates.next(supportsTemplates);

  private _menuItems = new BehaviorSubject<DropDownMenuSection[]>([]);
  public menuItems$ = this._menuItems as Observable<DropDownMenuSection[]>;

  public employeeFullName$ = this.employee$.pipe(
    map(employee => employee?.getFullName())
  );
  public employeeEmail$ = this.employee$.pipe(
    map(employee => employee?.email)
  );
  public emailConfirmed$ = this.employee$.pipe(
    map(employee => employee?.emailConfirmed)
  );
  public companyRole$ = this.employee$.pipe(
    map(employee => employee?.companyRole)
  );
  public isCompanyAdmin$ = this.employee$.pipe(
    map(employee => employee?.isCompanyAdmin)
  );
  public isTemplateAdmin$ = this.employee$.pipe(
    map(employee => employee?.isTemplateAdmin)
  );

  public canManageTemplatesForEmployees$ = combineLatest([
    this.isTemplateAdmin$,
    this.supportsTemplates$
  ]).pipe(
    map(([isTemplateAdmin, supportsTemplates]) => isTemplateAdmin && supportsTemplates)
  );

  private updateEmployeeActionMenuItems = combineLatest([
    this.employee$,
    this.supportsTemplates$,
    this.userDomainModel.user$
  ]).subscribeWhileAlive({
    owner: this,
    next: (([employee, supportsTemplates, user]) => {
      const menuItems = [];
      const ddmi = [];
      const action = (name: string) => new EmployeeAction(name, employee);
      const item = (name: string, styling = new Set<string>()) => new DropDownMenuItem(name, action(name), styling);
      if (user?.userId !== employee?.userId) {
        if (employee?.accountConfirmed) {
          // Only show admin options for confirmed accounts
          if (employee?.isCompanyAdmin) {
            ddmi.push(item('Revoke Admin'));
          } else {
            ddmi.push(item('Make Admin'));
          }
          if (supportsTemplates) {
            if (employee?.isTemplateAdmin && user?.isCompanyAdmin && user?.isTemplateAdmin) {
              ddmi.push(item('Remove Template Access'));
            } else if (employee?.isCompanyAdmin && user?.isTemplateAdmin) {
              ddmi.push(item('Allow Template Access'));
            }
          }
        } else {
          // Include option to resend invite
          ddmi.push(item('Resend Invite'));
        }
      }
      if (user?.isCompanyAdmin) {
        ddmi.push(item('Edit Employee'));
      }

      if (user?.userId !== employee?.userId && user?.isCompanyAdmin) {
        ddmi.push(item('Remove Employee', new Set<string>().add('red-text')));
      }

      menuItems.push(new DropDownMenuSection(null, ddmi));
      this._menuItems.next(menuItems);
    })
  });

}
