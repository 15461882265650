import { Injectable, Type } from '@angular/core';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SelectMenuOrCollectionViewModel {

  private _searchedItems = new BehaviorSubject<Menu[] | TemplateCollection[]>(null);
  public searchedItems$ = this._searchedItems as Observable<Menu[] | TemplateCollection[]>;
  connectToSearchedItems = (items: Menu[] | TemplateCollection[]) => this._searchedItems.next(items);

  public searchedTemplateCollections$ = this.searchedItems$.pipe(
    filter((items): items is TemplateCollection[] => this.isArrayOfType(items, TemplateCollection))
  );

  public searchedMenus$ = this.searchedItems$.pipe(
    filter((items): items is Menu[] => this.isArrayOfType(items, Menu))
  );

  private isArrayOfType(items: Menu[] | TemplateCollection[], type: Type<Menu | TemplateCollection>): boolean {
    if (!(Array.isArray(items))) return false;
    for (const item of items) {
      if (!(item instanceof type)) return false;
    }
    return true;
  }

}
