import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../models/account/dto/user';
import { UserPickerComponent } from '../views/shared/modals/user-picker/user-picker.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalUserPicker {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    title: string = 'Add Users',
    users: User[] = [],
    alreadyAddedUsers: User[] = [],
    resultOperation: (users: User[]) => void,
  ) {
    const modalRef = ngbModal.open(UserPickerComponent, ModalUtils.userPickerModalOptions(injector));
    const compInstance = modalRef.componentInstance as UserPickerComponent;
    compInstance.title = title;
    compInstance.infoTitle = 'Can\'t Find an Employee?';
    compInstance.infoText = 'Make sure the person you\'re looking for has been added to BudSense. '
      + 'This can be done in Company>General>Employees.';
    compInstance.users = users;
    compInstance.alreadyAdded = alreadyAddedUsers;
    modalRef.result.then((pickedUsers: User[]) => resultOperation(pickedUsers)).catch(() => {});
  }

}
