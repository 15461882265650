import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { FormValidatorInterface } from '@mobilefirstdev/reactive-form/lib/validators/interfaces/form-validator-interface';
import { Directive } from '@angular/core';

export function validUrl(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;

    if (!value) return null;

    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' // protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
      + '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );

    if (!urlPattern.test(value)) {
      return { url: 'Invalid URL format for menu.' };
    }

    return null;
  };
}

@Directive({
  selector: '[appUrlValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UrlValidatorDirective, multi: true }]
})
export class UrlValidatorDirective implements FormValidatorInterface {

  constructor() { }

  errorKey(): string {
    return 'url';
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return validUrl()(control);
  }

}
