import { Pipe, PipeTransform } from '@angular/core';
import { LocationDisplayGrouping } from '../../../models/display/dto/display';

@Pipe({
  name: 'collectionChangesString'
})
export class CollectionChangesStringPipe implements PipeTransform {

  transform(locationDisplays: LocationDisplayGrouping[], originalIds: string[], updatedIds: string[]): string {
    const locationDisplayIds = locationDisplays?.flatMap((ld) => ld?.displays?.map((d) => d?.id));
    const added = updatedIds?.filter((id) => !originalIds?.includes(id) && locationDisplayIds?.includes(id));
    const wasRemoved = (id: string) => locationDisplayIds?.includes(id) && !updatedIds?.includes(id);
    const removed = !!updatedIds && originalIds?.filter(wasRemoved);
    const addedString = added?.length
      ? `(${added?.length} added required display)`
        .pluralizer()
        .addRule({ listConnection: added, useApostrophe: false, word: 'display'})
        .pluralize()
      : '';
    const removedString = removed?.length
      ? `(${removed?.length} removed required display)`
        .pluralizer()
        .addRule({ listConnection: removed, useApostrophe: false, word: 'display'})
        .pluralize()
      : '';
    return [addedString, removedString].filterFalsies().join(' ');
  }

}
