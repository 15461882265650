import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class MenuOptions implements Deserializable, UniquelyIdentifiable {

  // Zoom
  public productZoom: number = 1;
  public titleZoom: number = 1;
  // Hide
  public hideTitle: boolean = false;
  public hideLabel: boolean = false;
  public hidePriceDecimal: boolean = false;
  public hideLogo: boolean = false;
  public hideSale: boolean = false;
  public hideInventoryLabels: boolean = false;
  public hideCannabinoidDecimal: boolean = false;
  public hideDescription: boolean = false;
  // Show
  public showAltLogo: boolean = false;
  public showCUOMInHeader: boolean = false;
  public showFullDescription: boolean = false;
  // Colors
  public blurredBackground: boolean = false;
  public backgroundOpacity: number = 0;
  public titleTextColor: string = '';
  public bodyTextColor: string = '';
  public bodyBackgroundColor: string = '';
  // Formatting
  public sectionMarginTop: number = 0;
  public sectionMarginBottom: number = 0;
  // Print Options
  public sectionPageBreak: boolean = false;
  public titleSectionPageBreak: boolean = false;
  public singlePageMenu: boolean = false;
  public printHeaderLayout: string;
  public printFooterLayout: string;

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `${this.productZoom}
      -${this.titleZoom}
      -${this.hideTitle}
      -${this.hideLabel}
      -${this.hidePriceDecimal}
      -${this.hideLogo}
      -${this.hideSale}
      -${this.hideInventoryLabels}
      -${this.hideCannabinoidDecimal}
      -${this.showAltLogo}
      -${this.sectionMarginTop || ''}
      -${this.sectionMarginBottom || ''}
      -${this.blurredBackground}-${this.backgroundOpacity}
      -${this.titleTextColor}
      -${this.bodyTextColor}
      -${this.bodyBackgroundColor}
      -${this.sectionPageBreak}
      -${this.titleSectionPageBreak}
      -${this.singlePageMenu}`;
  }

}
