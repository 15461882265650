import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { SingleSelectionItem } from './single-selection-item';

export class SingleSelectionItemGrouping implements LookAheadItem {

  public title: string;
  public groupingId: string;
  public items: SingleSelectionItem[];

  constructor(title: string, items: SingleSelectionItem[], groupingId: string) {
    this.title = title;
    this.items = items;
    this.groupingId = groupingId;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  some(items: SingleSelectionItem[]): boolean {
    const groupingIds = items?.map(item => item?.groupingId);
    return groupingIds?.some(id => id === this?.groupingId);
  }

  static createGroupedSearchHits(searchHits: SingleSelectionItem[]): SingleSelectionItemGrouping[] {
    const groupedHits: SingleSelectionItemGrouping[] = [];
    searchHits?.forEach(hit => {
      const groupIndex = groupedHits?.findIndex(g => g?.groupingId === hit?.groupingId);
      if (groupIndex < 0) {
        const group = new SingleSelectionItemGrouping(hit?.groupingName || 'Uncategorized', [hit], hit?.groupingId);
        groupedHits.push(group);
      } else {
        groupedHits[groupIndex].items.push(hit);
      }
    });
    return groupedHits;
  }

}
