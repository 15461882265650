import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, Input, OnDestroy, SimpleChanges, ViewChild, ViewRef } from '@angular/core';
import { AllProductsTableHeaderComponent } from '../../../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import { ProductPickerDataTableViewModel } from '../../product-picker-data-table-view-model';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-product-picker-table-header',
  templateUrl: './product-picker-table-header.component.html',
  styleUrls: ['./product-picker-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => ProductPickerTableHeaderComponent)
    }
  ]
})
export class ProductPickerTableHeaderComponent extends AllProductsTableHeaderComponent
  implements AfterViewInit, OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public override viewModel: ProductPickerDataTableViewModel, // global provided by root
  ) {
    super(viewRef, viewModel);
  }

  @ViewChild('trow') trow: ElementRef<HTMLTableRowElement>;
  @Input() headerStickyOffset: number;
  private _headerStickyOffset: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public headerStickyOffset$ = this._headerStickyOffset as Observable<number>;
  private _headerBottomBorderLeftOffset: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public headerBottomBorderLeftOffset$ = this._headerBottomBorderLeftOffset as Observable<number>;
  private _headerBottomBorderRightOffset: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public headerBottomBorderRightOffset$ = this._headerBottomBorderRightOffset as Observable<number>;
  public loading$ = this.viewModel.variantsFilteredByProductTypeVariantTypeStrainTypeRecentlyUpdated$.pipe(
    map(variants => variants === null),
    startWith(true)
  );
  private ro: ResizeObserver;

  override changesFromBluePrint(changes: SimpleChanges): void {
    super.changesFromBluePrint(changes);
    if (changes.headerStickyOffset) this._headerStickyOffset.next(changes.headerStickyOffset.currentValue);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.ro = new ResizeObserver(_ => {
      // -1 to increase size so that border covers transparent areas
      this._headerBottomBorderLeftOffset.next(this.trow.nativeElement.getBoundingClientRect().left - 1);
      const rightOffset = window.innerWidth - this.trow.nativeElement.getBoundingClientRect().right;
      this._headerBottomBorderRightOffset.next(rightOffset - 1);
    });
    this.ro.observe(this.trow.nativeElement);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.ro?.disconnect();
  }

}
