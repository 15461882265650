import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditCollectionEditRequiredDisplaysViewModel } from './edit-collection-edit-required-displays-view-model';
import { CollectionDisplaysModalContainerViewModel } from '../collection-displays-modal-container/collection-displays-modal-container-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-collection-edit-required-displays',
  templateUrl: './edit-collection-edit-required-displays.component.html',
  styleUrls: ['./edit-collection-edit-required-displays.component.scss'],
  providers: [EditCollectionEditRequiredDisplaysViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionEditRequiredDisplaysComponent extends BaseComponent {

  @Input() @ViewModelConnector('connectToSelectedRequiredDisplayIds') requiredDisplayIds: string[];
  @Output() cancel = new EventEmitter();

  constructor(
    public viewModel: EditCollectionEditRequiredDisplaysViewModel,
    public container: CollectionDisplaysModalContainerViewModel
  ) {
    super();
  }

}
