import { Injectable, Injector } from '@angular/core';
import { EditStaticSectionViewModel } from '../shared/edit-static-section-menu/edit-static-section-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { LabelDomainModel } from '../../../../../domainModels/label-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { DisplayAttributesDomainModel } from '../../../../../domainModels/display-attributes-domain-model';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartFiltersDomainModel } from '../../../../../domainModels/smart-filters-domain-model';
import { of } from 'rxjs';

@Injectable()
export class EditCardStackSectionViewModel extends EditStaticSectionViewModel {

  constructor(
    templateDomainModel: TemplateDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    companyDomainModel: CompanyDomainModel,
    labelDomainModel: LabelDomainModel,
    locationDomainModel: LocationDomainModel,
    displayAttributeDomainModel: DisplayAttributesDomainModel,
    userDomainModel: UserDomainModel,
    smartFiltersDomainModel: SmartFiltersDomainModel,
    toastService: ToastService,
    router: Router,
    route: ActivatedRoute,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      templateDomainModel,
      menuDomainModel,
      productDomainModel,
      companyDomainModel,
      labelDomainModel,
      locationDomainModel,
      displayAttributeDomainModel,
      userDomainModel,
      smartFiltersDomainModel,
      toastService,
      router,
      route,
      ngbModal,
      injector
    );
  }

  public override readonly productsBelowLineWillNotAppearDesc$ = of('Products below line will not appear');

}
