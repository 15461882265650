import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrintJobSelectMenusViewModel } from './print-job-select-menus-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';

@Component({
  selector: 'app-print-job-select-menus',
  templateUrl: './print-job-select-menus.component.html',
  styleUrls: ['./print-job-select-menus.component.scss'],
  providers: [PrintJobSelectMenusViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobSelectMenusComponent {

  constructor(public viewModel: PrintJobSelectMenusViewModel) {
  }

  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean = false;

}
