import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-create-view-stack-print-job-header',
  templateUrl: './create-view-stack-print-job-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateViewStackPrintJobHeaderComponent {

  @Input() title: string;

}
