import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowButtonComponent extends BaseComponent {

  constructor(
    public elementRef: ElementRef<HTMLDivElement>
  ) {
    super();
  }

  @Input() id: string;
  @Input() title: string;
  @Input() subtitle;
  @Input() titleFontSize: string;
  @Input() subtitleFontSize: string;
  @Input() titleFontWeight: string;
  @Input() subtitleFontWeight: string;
  @Input() paddingLeft: string;
  @Input() paddingRight: string;
  @Input() selected: boolean;
  @Output() clicked = new EventEmitter<boolean>();

}
