<app-loading *ngIf="viewModel?.isCombinedLoading$ | async" [options]="viewModel?.combinedLoadingOpts$ | async">
</app-loading>

<div class="modal-body sticky">
  <div
    #modalHeader
    appStickyAutoPosition
    [stickyCollectionKey]="'publish-template'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="modal-title" ngbAutofocus>Publish Template</div>
    </div>
  </div>

  <div
    #contentContainer
    class="sticky-body-container"
    appStickyAutoPosition
    [stickyCollectionKey]="'publish-template'"
    [stickChildClassInstead]="'desc-and-search'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    [style.overflow-y]="'unset'">
    <div class="desc-and-search" fxLayout="column" [style.gap.rem]="1">
      <div class="modal-description">
        <p>Are you sure you want to publish this?</p>
        <ng-container [ngSwitch]="(viewModel.requiredLocationIds$ | async)?.length > 0">
          <p *ngSwitchCase="true">
            A published template can be used by your locations. This templated menu will automatically be created at the
            following required locations:
          </p>
          <p *ngSwitchCase="false">
            A published template can be used by your locations. No required locations for this template have been set.
          </p>
        </ng-container>
      </div>
    </div>
    <ng-container
      *ngFor="
        let grouping of viewModel.requiredLocationsGroupedByProvince$ | async | keyvalue;
        trackBy: viewModel.trackById
      ">
      <ng-container [ngSwitch]="viewModel.singleRequiredProvince$ | async">
        <ng-container *ngSwitchCase="true">
          <div *ngFor="let location of grouping.value | locationsOnly" class="location-container">
            <app-template-location-info-text [location]="location"> </app-template-location-info-text>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <app-template-location-selection-group
            [viewOnlyMode]="true"
            [expanded]="true"
            [province]="grouping?.key"
            [locations]="grouping?.value">
          </app-template-location-selection-group>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div #footerContainer class="sticky-footer-container modal-footer-flex-end" [style.gap.rem]="1">
    <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary (buttonClicked)="viewModel.publishTemplate()"> Publish Template </lib-button-primary>
  </div>
</div>
