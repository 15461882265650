import { Deserializable } from '../../protocols/deserializable';

export class CodeDeliveryDetails implements Deserializable {

  public attributeName: string;
  public deliveryMedium: string;
  public destination: string;

  public onDeserialize() {

  }

}
