import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseComponent } from '../../../../../../models/base/base-component';

@Component({ selector: 'app-menu-additional-options', template: '' })
export abstract class MenuAdditionalOptionsFormComponent extends BaseComponent implements OnChanges {

  @Input() menu: Menu;
  @Input() isTemplatedMenu: boolean = false;
  @Input() mergeKey: string;
  @Input() bindTo: any;

  private _hasError = new BehaviorSubject<boolean>(false);
  public hasError$ = this._hasError as Observable<boolean>;
  public setHasError = (hasError: boolean) => this._hasError.next(hasError);

  private readonly _isTemplatedMenu = new BehaviorSubject<boolean>(this.isTemplatedMenu);
  public readonly isTemplatedMenu$ = this._isTemplatedMenu as Observable<boolean>;
  public setTemplatedMenu = (isTemplatedMenu: boolean) => this._isTemplatedMenu.next(isTemplatedMenu);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isTemplatedMenu) this.setTemplatedMenu(this.isTemplatedMenu);
  }

}
