import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';
import { map, take } from 'rxjs/operators';
import { TypeDefinition } from '../../../../models/utils/dto/type-definition';
import { MenuCreationFlowType } from '../../../../models/utils/dto/menu-creation-flow-type';
import { MenuSubtype } from '../../../../models/enum/dto/menu-subtype';

@Injectable()
export class MenuTypePickerViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _menuType = new BehaviorSubject<MenuType>(null);
  public menuType$ = this._menuType as Observable<MenuType>;
  public connectToMenuType = (mt: MenuType) => this._menuType.next(mt);

  private _readableSubtype = new BehaviorSubject<string>(null);
  public readableSubtype$ = this._readableSubtype as Observable<string>;

  private _selectedSubtypes = new BehaviorSubject<MenuSubtype[]>(null);
  public selectedSubtypes$ = this._selectedSubtypes as Observable<MenuSubtype[]>;

  private menuCreationFlowMap$ = combineLatest([
    this.menuType$,
    window?.types?.menuCreationFlowMap$,
  ]).pipe(
    map(([menuType, createMap]) => createMap?.get(menuType))
  );

  public menuTypes$: Observable<TypeDefinition[]> = combineLatest([
    this.menuCreationFlowMap$,
    window?.types?.menuCreationFlows$
  ]).pipe(
    map(([creationMap, createTypes]) => {
      const list = [];
      creationMap?.forEach((_, k) => {
        list.push(createTypes?.find(t => t.value === k));
      });
      return list;
    })
  );

  public menuTypeSelected(flowType: MenuCreationFlowType): void {
    combineLatest([
      window.types.menuCreationFlows$,
      this.menuCreationFlowMap$
    ]).pipe(take(1)).subscribe(([flows, m]) => {
      const menuSubtypes = (m?.get(flowType)) as MenuSubtype[];
      this._readableSubtype.next(flows.find(f => f.value === flowType).name);
      this._selectedSubtypes.next(menuSubtypes);
    });
  }

}
