import { Component } from '@angular/core';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { ColumnOptionsFormComponent } from '../column-options-form/column-options-form.component';
import { ColumnOptionsQuantityViewModel } from './column-options-quantity-view-model';

@Component({
  selector: 'app-column-options-quantity',
  templateUrl: '../column-options-form/column-options-form.component.html',
  styleUrls: ['../column-options-form/column-options-form.component.scss'],
  providers: [ColumnOptionsQuantityViewModel]
})
export class ColumnOptionsQuantityComponent extends ColumnOptionsFormComponent implements Tabbable {

  constructor(
    public override viewModel: ColumnOptionsQuantityViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
