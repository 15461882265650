import { Pipe, PipeTransform } from '@angular/core';
import { MenuSectionProductPreviewViewModel } from '../components/edit-menu/shared/menu-section-product-preview/menu-section-product-preview-view-model';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getCannabinoidText'
})
export class GetCannabinoidTextPipe implements PipeTransform {

  transform(value: MenuSectionProductPreviewViewModel, cannabinoid: string): Observable<string> {
    return value.getCannabinoidText$(cannabinoid);
  }

}
