import { Pipe, PipeTransform } from '@angular/core';
import { HasChildIds } from '../../../models/protocols/has-child-ids';
import { Display } from '../../../models/display/dto/display';

@Pipe({
  name: 'getDisplaysAsSelection'
})
export class GetDisplaysAsSelectionPipe implements PipeTransform {

  transform(displays: Display[]): HasChildIds {
    return new class implements HasChildIds {

      getId = (): string => 'not relevant';
      getChildIds = (): string[] => displays?.map(location => location?.id) ?? [];

    }();
  }

}
