<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (hasErrors$)="setHasError($event)">
  <div class="layout-switches-container">
    <lib-reactive-form-column-layout [nColumns]="2">
      <div>
        <div [style.max-width.rem]="15">
          <lib-reactive-form-number
            *ngIf="themeSupportsBackgroundOpacity$ | async"
            [inputName]="'backgroundOpacity'"
            [label]="'Background Opacity (%)'"
            [placeholder]="'Enter an opacity value (%)'"
            [tooltip]="'Change the opacity to reveal the background asset behind the menu.'"
            [bindingProperty]="'menuOptions.backgroundOpacity'"
            [integersOnly]="true"
            [percentageMode]="true"
            [required]="true"
            [disabled]="disableBackgroundOpacity$ | push"
            [disabledTooltip]="disableBackgroundOpacityTooltip$ | async"
            [minValue]="1"
            [maxValue]="100"
            [inlinePercentage]="50"
            [programmaticallyChangeValue]="resetOpacityWithBodyBackgroundColor$ | push">
          </lib-reactive-form-number>
        </div>

        <div [hidden]="hideCUOMInHeaderToggle$ | async" class="switch-container">
          <lib-reactive-form-switch
            [label]="'Cannabis unit of measure in header'"
            [tooltip]="'Displays cannabis unit of measure in the header of each section instead of inline.'"
            [bindingProperty]="'menuOptions.showCUOMInHeader'"
            [inputName]="'cuomInHeader'"
            [disabled]="isTemplatedMenu || (disableCannabisUnitOfMeasureInHeader$ | push)"
            [disabledTooltip]="disableCannabisUOMInHeaderTooltip$ | push"
            [inlineLabel]="true"
            [labelOnRight]="true"
            [inlinePercentage]="50">
          </lib-reactive-form-switch>
        </div>

        <div *ngIf="themeSupportsBlurredBackground$ | async" class="switch-container">
          <lib-reactive-form-switch
            [label]="'Blur Background'"
            [tooltip]="'Pixelates the background asset of the menu creating a blurred effect.'"
            [bindingProperty]="'menuOptions.blurredBackground'"
            [inputName]="'blurredBackground'"
            [disabled]="isTemplatedMenu"
            [inlineLabel]="true"
            [labelOnRight]="true"
            [inlinePercentage]="50">
          </lib-reactive-form-switch>
        </div>
      </div>

      <div>
        <lib-reactive-form-color-square
          *ngIf="themeSupportsTitleTextColor$ | async"
          [presetColors]="colorPalette"
          [inputName]="'titleTextColor'"
          [label]="'Title Text Color'"
          [tooltip]="'Set a custom color for the menu title.'"
          [bindingProperty]="'menuOptions.titleTextColor'"
          [disabled]="isTemplatedMenu"
          [inlinePercentage]="100">
        </lib-reactive-form-color-square>

        <lib-reactive-form-color-square
          *ngIf="themeSupportsBodyTextColor$ | async"
          [presetColors]="colorPalette"
          [inputName]="'bodyTextColor'"
          [label]="'Body Text Color'"
          [tooltip]="'Set a custom color for the menu body text, which contains all the menu sections.'"
          [bindingProperty]="'menuOptions.bodyTextColor'"
          [disabled]="isTemplatedMenu"
          [inlinePercentage]="100">
        </lib-reactive-form-color-square>

        <lib-reactive-form-color-square
          *ngIf="themeSupportsBodyBackgroundColor$ | async"
          [presetColors]="colorPalette"
          [inputName]="'bodyBackgroundColor'"
          [label]="'Body Background Color'"
          [tooltip]="'Set a custom color for the menu body background.'"
          [bindingProperty]="'menuOptions.bodyBackgroundColor'"
          [disabled]="isTemplatedMenu"
          [inlinePercentage]="100"
          (valueChanged)="connectToBodyBackgroundColor($event)">
        </lib-reactive-form-color-square>
      </div>
    </lib-reactive-form-column-layout>
  </div>
</lib-reactive-form-group>
