import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { SectionBlueprint } from '../models/template/dto/section-blueprint';
import { SectionBlueprintCategory } from '../models/template/dto/section-blueprint-category';
import { SortUtils } from '../utils/sort-utils';
import { map } from 'rxjs/operators';
import { TemplateAPI } from '../api/template-api';
import { Injectable } from '@angular/core';

// Provided by Logged In Scope
@Injectable()
export class SectionBlueprintsDomainModel extends BaseDomainModel {

  constructor(
    public templateAPI: TemplateAPI
  ) {
    super();
    this.loadSectionBlueprints();
    this.loadSectionBlueprintCategories();
  }

  private _sectionBlueprints = new BehaviorSubject<SectionBlueprint[]>(null);
  public sectionBlueprints$ = this._sectionBlueprints as Observable<SectionBlueprint[]>;

  private _sectionBlueprintCategories = new BehaviorSubject<SectionBlueprintCategory[]>(null);
  public sectionBlueprintCategories$ = this._sectionBlueprintCategories.pipe(
    map(categories => categories?.sort((a, b) => SortUtils.numericStringAsc(a?.name, b?.name)))
  );

  public loadSectionBlueprints() {
    this.templateAPI.GetSectionBlueprints().subscribe(blueprints => {
      this._sectionBlueprints.next(blueprints);
    });
  }

  public getSectionBlueprintsByIds(ids: string[]): Observable<SectionBlueprint[]> {
    return this.templateAPI.GetSectionBlueprints(ids);
  }

  public loadSectionBlueprintCategories() {
    this.templateAPI.GetSectionBlueprintCategories().subscribe(categories => {
      this._sectionBlueprintCategories.next(categories);
    });
  }

}
