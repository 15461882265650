<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (hasErrors$)="setHasError($event)">
  <div class="media-switches-container">
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-switch
        [label]="'Play Audio'"
        [tooltip]="'Allows videos to play audio.'"
        [bindingProperty]="'menuOptions.playAudio'"
        [inputName]="'playAudio'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>
    </lib-reactive-form-column-layout>
  </div>
</lib-reactive-form-group>
