import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayByLocationAndProvince } from '../../../../models/display/dto/display';

@Component({
  selector: 'app-grouped-province-location-display-list-items',
  templateUrl: './grouped-province-location-display-list-items.component.html',
  styleUrls: ['./grouped-province-location-display-list-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedProvinceLocationDisplayListItemsComponent {

  @Input() expanded: boolean = false;
  @Input() groupedRequiredDisplays: DisplayByLocationAndProvince;
  @Input() collectionIsPublished = false;
  @Input() originalRequiredDisplayIds: string[];
  @Input() updatedRequiredDisplayIds: string[];

}
