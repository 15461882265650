import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Asset } from '../../../../../models/image/dto/asset';
import { MarketingMenuType } from '../../../../../models/enum/dto/marketing-menu-type.enum';
import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { MediaUtils } from '../../../../../utils/media-utils';

@Injectable()
export class MenuMediaFormViewModel extends BaseViewModel {

  private _enabled = new BehaviorSubject<boolean>(null);
  public enabled$ = this._enabled.pipe(distinctUntilChanged());

  private _file = new BehaviorSubject<Asset>(null);
  public file$ = this._file as Observable<Asset>;
  public fileNameWithoutTimestampAndExtension$ = this.file$.pipe(
    map(file => MediaUtils.getFileNameWithoutTimestampAndExtension(file?.fileName))
  );

  private _menuType = new BehaviorSubject<MarketingMenuType>(null);
  public menuType$ = this._menuType as Observable<MarketingMenuType>;

  private _changeLoopCount = new BehaviorSubject<number|undefined>(undefined);
  public changeLoopCount$ = this._changeLoopCount as Observable<number|undefined>;

  // change total duration based on loop count
  private _changeDuration = new BehaviorSubject<number|undefined>(undefined);
  public changeDuration$ = combineLatest([
    this.enabled$.pipe(pairwise()),
    this._changeDuration.pipe(distinctUntilChanged()),
    this.file$
  ]).pipe(
    map(([[prevEnabled, currEnabled], duration, file]) => {
      if (!prevEnabled && currEnabled) {
        return duration || 30;
      } else if (prevEnabled && !currEnabled) {
        return 0;
      } else {
        return duration;
      }
    }),
    startWith<undefined, undefined>(undefined)
  );

  // Asset metadata has loaded, can enable
  public assetMetadataNotLoaded$ = combineLatest([
    this._changeDuration,
    this.file$
  ]).pipe(
    map(([cd, file]) => {
      // Videos are only truly ready once they emit a duration. Images are ready almost instantaneously.
      // Enabling media before it is actually ready leads to all sorts of problems, so we need to keep the switch
      // locked down
      if (file.isVideo()) {
        return cd === undefined;
      }
      return false;
    })
  );

  // enabled
  public displayDurationEnabled$ = combineLatest([this.file$, this.enabled$]).pipe(
    map(([file, enabled]) => (file?.isValid() && file?.isImage()) && enabled)
  );

  // show and hide
  private showData$ = combineLatest([this.menuType$, this.file$]);
  public showDisplayDuration$ = this.showData$.pipe(map(([type]) => type !== MarketingMenuType.DriveThru));
  public showLoopContent$ = this.showData$.pipe(
    map(([type, file]) => type !== MarketingMenuType.DriveThru && file?.isValid() && file?.isVideo())
  );
  public showUploadDate$ = this.showData$.pipe(map(() => true));

  public setEnabled = (e: boolean) => this._enabled.next(e);
  public setFile = (file: Asset) => this._file.next(file);
  public setMenuType = (type: MarketingMenuType) => this._menuType.next(type);
  public setChangeDuration = (duration: number) => this._changeDuration.next(duration);
  public setLoopCount = (count: number) => this._changeLoopCount.next(count);

}
