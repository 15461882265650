<div class="menu-section-variant-grouping-container" fxLayout="row">
  <ng-container *rxLet="(viewModel.variants$ | async)?.length > 1 as gridMode">
    <div *rxIf="gridMode" class="grouping-bar"></div>
    <div class="variant-grouping-container" fxLayout="column" fxFlex fxLayoutGap="1.5rem">
      <app-variant-grouping-header
        *rxIf="gridMode"
        [menu]="menu"
        [autoSaving]="autoSaving"
        [removingVariants]="removingVariants"
        [section]="viewModel.section$ | async"
        [product]="viewModel.product$ | async"
        [variants]="viewModel.variants$ | async"
        [badges]="viewModel.badges$ | async"
        [includedOnMenu]="includedOnMenu"
        (showLiveView)="viewModel.showLiveView($event)">
      </app-variant-grouping-header>
      <app-menu-section-product-preview
        *rxFor="let variant of viewModel.variants$"
        [gridMode]="gridMode"
        [menu]="menu"
        [section]="viewModel.section$ | async"
        [sectionStyles]="sectionStyles"
        [theme]="theme"
        [variant]="variant"
        [invalidFormState]="invalidFormState"
        [formPristine]="formPristine"
        [autoSaving]="autoSaving"
        [removingVariants]="removingVariants"
        [templateMode]="templateMode"
        [isTemplatedSection]="isTemplatedSection"
        [allowBadgeOverride]="allowBadgeOverride"
        [allowLabelOverride]="allowLabelOverride"
        [allowStyleOverride]="allowStyleOverride"
        [saveSection]="saveSection"
        [removeVariantFromSection]="removeVariantFromSection"
        [redBorder]="variant | showProductPreviewRedBorder : section : variants : menu : includedOnMenu"
        (showLiveView)="viewModel.showLiveView([$event])">
      </app-menu-section-product-preview>
    </div>
  </ng-container>
</div>
