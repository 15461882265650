<app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"></app-loading>
<div class="modal-body">
  <div class="modal-header no-bottom-border pb-16px">
    <div class="modal-title">{{ viewModel.whatTypeOfStackIsThis$ | push | titlecase }} Styling</div>
  </div>
  <ng-container *rxLet="viewModel.isTemplatedMenu$; let isTemplatedMenu">
    <div class="pt-24px">
      <lib-reactive-form-group
        #cardStyleForm
        [bindTo]="viewModel.section$ | push"
        [globalDisabledTooltip]="viewModel.templatedMenuDisabledTooltip$ | push"
        (formSubmitted$)="viewModel.saveStyles($event)">
        <lib-reactive-form-number
          *rxIf="viewModel.themeSupportsBackgroundOpacity$ | push"
          [label]="(viewModel.whatTypeOfStackIsThis$ | push | titlecase) + ' Opacity (%)'"
          [inputName]="'backgroundOpacity'"
          [disabled]="viewModel?.disableBackgroundOpacity$ | push"
          [disabledTooltip]="viewModel?.disableOpacityTooltip$ | push"
          [placeholder]="'Enter an opacity value (%)'"
          [customValueParser]="parseNumberOpacityToString"
          [percentageMode]="true"
          [integersOnly]="true"
          [required]="true"
          [minValue]="1"
          [maxValue]="100"
          [inlinePercentage]="50"
          [bindOnSubmit]="viewModel.themeSupportsBackgroundOpacity$ | push"
          [bindingProperty]="'metadata.cardOpacity'"
          [programmaticallyChangeValue]="viewModel?.resetOpacityWithBodyBackgroundColor$ | push">
        </lib-reactive-form-number>
        <lib-reactive-form-column-layout [nColumns]="2">
          <lib-reactive-form-color-square
            *rxIf="viewModel.themeSupportsBodyBackgroundColor$ | push"
            [label]="(viewModel.whatTypeOfStackIsThis$ | push | titlecase) + ' Color'"
            [inputName]="'cardColor'"
            [disabled]="isTemplatedMenu"
            [presetColors]="viewModel?.colorPalette$ | push"
            [bindingProperty]="'metadata.productsContainerBackgroundColor'"
            (valueChanged)="viewModel?.connectToBackgroundColor($event)">
          </lib-reactive-form-color-square>
          <lib-reactive-form-color-square
            *rxIf="viewModel.themeSupportsHeaderBackgroundColor$ | push"
            [label]="'Accent Color'"
            [inputName]="'accentColor'"
            [disabled]="isTemplatedMenu"
            [tooltip]="'Set a custom accent color for the card.'"
            [presetColors]="viewModel?.colorPalette$ | push"
            [bindingProperty]="'metadata.productsInfoBackgroundColor'">
          </lib-reactive-form-color-square>
          <lib-reactive-form-color-square
            *rxIf="viewModel.themeSupportsHeaderTextColor$ | push"
            [label]="'Header Text Color'"
            [inputName]="'headerTextColor'"
            [hidden]="!(viewModel.themeSupportsHeaderTextColor$ | push)"
            [disabled]="isTemplatedMenu"
            [tooltip]="'This color will be applied to both card title and subtitle.'"
            [presetColors]="viewModel?.colorPalette$ | push"
            [bindOnSubmit]="viewModel.themeSupportsHeaderTextColor$ | push"
            [bindingProperty]="'metadata.sectionHeaderTextColor'">
          </lib-reactive-form-color-square>
          <lib-reactive-form-color-square
            *rxIf="viewModel.themeSupportsBodyTextColor$ | push"
            [label]="'Body Text Color'"
            [inputName]="'bodyTextColor'"
            [hidden]="!(viewModel.themeSupportsBodyTextColor$ | push)"
            [disabled]="isTemplatedMenu"
            [tooltip]="'This color will be applied to the body text of the card.'"
            [presetColors]="viewModel?.colorPalette$ | push"
            [bindOnSubmit]="viewModel.themeSupportsBodyTextColor$ | push"
            [bindingProperty]="'metadata.sectionBodyTextColor'">
          </lib-reactive-form-color-square>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
    </div>
    <div class="d-flex gap-3 justify-content-end pt-16px">
      <lib-button-neutral [hidden]="isTemplatedMenu" (buttonClicked)="cancel()">Cancel</lib-button-neutral>
      <lib-button-primary
        [hidden]="isTemplatedMenu"
        [disabled]="cardStyleForm.disableSubmit$ | push"
        (buttonClicked)="cardStyleForm.submitForm()">
        Save Changes
      </lib-button-primary>
      <lib-button-primary [hidden]="!isTemplatedMenu" (buttonClicked)="cancel()">Done</lib-button-primary>
    </div>
  </ng-container>
</div>
