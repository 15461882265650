// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, combineLatest, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Menu } from '../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { SectionTemplate } from '../../../../models/template/dto/section-template';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';

@Injectable()
export class SmartFilterAddedToSectionSharedViewModel extends BaseViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private toastService: ToastService
  ) {
    super();
  }

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section.asObservable();

  private _removeSmartFilter = new Subject<string>();
  private _removingSmartFilters = new BehaviorSubject<boolean>(false);
  private removeSmartFilterPool: string[] = [];
  public removingSmartFilters$ = this._removingSmartFilters.asObservable();
  private listenToRemoveSmartFilter = this._removeSmartFilter.notNull().pipe(
    tap(filterId => this.removeSmartFilterPool = [...this.removeSmartFilterPool, filterId]?.unique()),
    debounceTime(2500),
    tap(_ => this._removingSmartFilters.next(true)),
    switchMap(_ => {
      const removeSmartFilters = this.removeSmartFilterPool?.map(filterId => this.removeSmartFilterHelper(filterId));
      return forkJoin(removeSmartFilters ?? [this.section$]);
    }),
    tap(_ => this.removeSmartFilterPool = []),
    map(sections => sections?.last()),
    switchMap(section => this.updateSectionAfterRemovingSmartFilters(section)),
    takeUntil(this.onDestroy)
  ).subscribe((_) => {
    this._removingSmartFilters.next(false);
  }, err => {
    this._removingSmartFilters.next(false);
    throwError(err);
  });

  connectToMenu(menu: Menu) {
    this._menu.next(menu);
  }

  connectToSection(section: HydratedSection) {
    this._section.next(section);
  }

  removeSmartFilter(id: string) {
    this._removeSmartFilter.next(id);
  }

  /**
   * This helper relies on the section staying the same for each call. Therefore, do not make a copy of the section.
   * If you make a copy within, then the section will be updated with the last smart filter in the queue removed, but
   * the remaining smart filters in the queue will not be removed.
   */
  private removeSmartFilterHelper(smartFilterId: string): Observable<HydratedSection> {
    return this.section$.pipe(
      map(section => {
        section.smartFilterIds = section?.smartFilterIds?.filter(id => id !== smartFilterId);
        return section;
      }),
      take(1)
    );
  }

  private updateSectionAfterRemovingSmartFilters(section: HydratedSection): Observable<HydratedSection> {
    if (section instanceof SectionTemplate) {
      return combineLatest([
        this.menu$.notNull(),
        this.menuDomainModel.currentLocationMenus$,
      ]).pipe(
        take(1),
        switchMap(([menu, locationMenus]) => this.templateDomainModel.updateMenuSectionTemplate(section).pipe(
          tap((ts) => {
            // If no smart filters remain, then no delay is necessary as products will be removed once API returns
            const delay = ts?.enabledVariantIds.length > 0 ? 2500 : 0;
            this.menuDomainModel.getTemplatedMenuSortedVariantIdsAfterDelay(menu, locationMenus, delay);
          }),
          tap(() => this.toastService.publishSuccessMessage('Template updated successfully', 'Update Template'))
        ))
      );
    }
    const syncAfterUpdate = (updatedSection$: Observable<HydratedSection>, menu: Menu): Observable<HydratedSection> => {
      return updatedSection$.pipe(
        switchMap(s => this.menuDomainModel.syncSectionSmartFilters(menu, s)),
        map(updated => [section, updated] as [HydratedSection, HydratedSection]),
        tap(([old, updated]) => this.toastService.publishBannerSuccess(old?.whatChangedString(updated))),
        map(([, updated]) => updated),
        catchError((e) => {
          this.toastService.publishBannerFailed(e?.message);
          return throwError(e);
        })
      );
    };
    return this.menu$.notNull().pipe(
      take(1),
      switchMap((menu) => {
        if (section?.smartFilterIds?.length > 0) {
          return syncAfterUpdate(this.menuDomainModel.updateMenuSection(section), menu);
        } else {
          return this.menuDomainModel.updateMenuSection(section);
        }
      })
    );
  }

}
