import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { CreateTemplateModalComponent } from '../views/template/components/modals/create-template-modal/create-template-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { MenuType } from '../models/utils/dto/menu-type-definition';
import { Menu } from '../models/menu/dto/menu';

export class ModalCreateTemplate {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    menuType: MenuType,
    onClose: (menu: Menu) => void
  ): void {
    const modalRef = ngbModal.open(
      CreateTemplateModalComponent,
      ModalUtils.createTemplateModalOptions(injector)
    );
    const compInst = modalRef.componentInstance as CreateTemplateModalComponent;
    compInst.container.connectToMenuType(menuType);
    modalRef.result.then(menu => onClose?.(menu)).catch(() => {});
  }

}
