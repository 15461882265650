import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-display-container',
  templateUrl: './edit-display-container.component.html',
  styleUrls: ['./edit-display-container.component.scss']
})
export class EditDisplayContainerComponent {

  constructor() { }

}
