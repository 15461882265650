import { Pipe, PipeTransform } from '@angular/core';
import { HydratedSmartFilter } from '../../../models/automation/hydrated-smart-filter';

@Pipe({
  name: 'isHydratedSmartFilter'
})
export class IsHydratedSmartFilterPipe implements PipeTransform {

  transform(value: any): boolean {
    return value instanceof HydratedSmartFilter;
  }

}
