<div
  class="featured-product-container mt-24px"
  [style.border]="(viewModel.variantInStock$ | async) && (viewModel.asset$ | async) ? null : '1px solid red'">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

  <div class="featured-product-name">
    <img
      *ngIf="!(viewModel.variantInStock$ | async) || !(viewModel.asset$ | async)"
      class="product-problem"
      [ngbTooltip]="viewModel.generateProblemText$ | async"
      src="assets/icons/red/solid/exclamation-circle.svg"
      alt="" />
    <div class="product-display-name">
      <app-display-name
        [displayName]="viewModel.variantTitle$ | async"
        [isMedical]="viewModel.isMedical$ | async"></app-display-name>
    </div>
    <button
      [hidden]="!(viewModel.variantTooltip$ | async)"
      [ngbTooltip]="viewModel.variantTooltip$ | async"
      class="inline-tooltip">
      <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
    </button>
    <span>{{ viewModel.variantSize$ | async }}</span>
    <!--   Label   -->
    <div class="label-preview-container">
      <app-label-preview [computeLabelInterface]="viewModel.computeLabelInterface$ | async"> </app-label-preview>
    </div>
    <!--   Display Attributes Button   -->
    <lib-button-inline-text
      class="text-link"
      [disabled]="(viewModel?.isLoading$ | async) || !formPristine"
      [disabledTooltip]="formPristine | saveChangesTooltip"
      [textColor]="'#2c4058'"
      (buttonClicked)="openDisplayAttributes()">
      Edit Variant
    </lib-button-inline-text>
  </div>
  <div class="featured-product-price-container">
    <div [ngClass]="{ 'stroke-through': !!(viewModel.variantSalePrice$ | async) }" class="featured-product-price">
      Regular Price:
      <span class="price">{{ viewModel.variantRegularPrice$ | async }}</span>
      <span class="price-info">({{ viewModel.variantRegularPriceInfo$ | async }})</span>
    </div>
    <div [hidden]="!(viewModel.variantSalePrice$ | async)" class="featured-product-price">
      Sale Price:
      <span class="price sale">{{ viewModel.variantSalePrice$ | async }}</span>
      <span class="price-info">({{ viewModel.variantSalePriceInfo$ | async }})</span>
    </div>
  </div>
  <div class="featured-product-form-container mt-16px">
    <app-featured-product-form
      [mergeKey]="mergeKey"
      [bindTo]="viewModel?.formData"
      [menu]="sharedViewModel?.menu$ | async"
      [variant]="variant">
    </app-featured-product-form>
    <div class="mb-16px">
      <hr class="no-margin" />
    </div>
    <app-menu-media
      [isDisabledByTemplates]="sharedViewModel?.isTemplatedMenu$ | async"
      [sharedViewModel]="sharedViewModel"
      [mergeKey]="mergeKey"
      [featuredVariantId]="variantId"
      [index]="index"
      [bottomDivider]="false"
      [menu]="sharedViewModel?.menu$ | async"
      [file]="viewModel.asset$ | async"
      (deleteFeaturedProduct)="deleteFeaturedVariant($event)"
      [theme]="ClientTypes.MarketingTheme.MarketingProductFeature"
      [formPristine]="formPristine"
      [destructiveButtonText]="'Remove Featured Item'"
      [featuredVariantName]="viewModel.variantTitle$ | async">
    </app-menu-media>
  </div>
</div>
