import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectLocationsForNewDisplayViewModel } from './select-locations-for-new-display-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { PendingDisplay } from '../../../../../models/template/dto/pending-display';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { SortUtils } from '../../../../../utils/sort-utils';
import { Location } from '../../../../../models/company/dto/location';

@Component({
  selector: 'app-select-locations-for-new-display',
  templateUrl: './select-locations-for-new-display.component.html',
  styleUrls: ['./select-locations-for-new-display.component.scss'],
  providers: [SelectLocationsForNewDisplayViewModel],
})
export class SelectLocationsForNewDisplayComponent extends BaseComponent {

  @Input() @ViewModelConnector('connectToPendingDisplay') pendingDisplay: PendingDisplay;
  @Output() cancel = new EventEmitter();
  @Output() goBack = new EventEmitter();

  constructor(
    public viewModel: SelectLocationsForNewDisplayViewModel,
  ) {
    super();
  }

  incompleteLocationsLast = SortUtils.sortSpecifiedStringKeyLast(Location.incompleteLocationGroupName);

}
