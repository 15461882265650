<div class="edit-page-footer">
  <div class="edit-page-action-buttons">
    <app-edit-menu-footer-actions
      [menu]="menu"
      [allowLiveView]="allowLiveView"
      [autoSaving]="autoSaveLoadingOpts?.isLoading"
      [canDeleteTemplateMenu]="canDeleteTemplateMenu"
      [formAutoSubmitted]="formAutoSubmitted"
      [formDisableSubmit]="formDisableSubmit"
      [formErrorMessages]="formErrorMessages"
      [formHasErrors]="formHasErrors"
      [formPristine]="formPristine"
      [lastAutoSavedTimeText]="lastSavedTimeText"
      [deleteMenu]="deleteMenu"
      [openDuplicateMenuModal]="openDuplicateMenuModal"
      [showLiveView]="showLiveView"
      [showSaveButton]="showSaveButton"
      (submitForms)="submitForms.emit($event)">
    </app-edit-menu-footer-actions>
  </div>
</div>
