import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { of } from 'rxjs';
import { RequiredDisplayEditMethod } from '../collection-displays-modal-container/collection-displays-modal-container-view-model';

@Injectable()
export class RequiredDisplayEditMethodViewModel extends BaseViewModel {

  public editMethods$ = of([
    {
      type: RequiredDisplayEditMethod.Existing,
      title: 'Apply to existing diplays',
      subtitle: 'Assign this collection to already existing displays'
    },
    {
      type: RequiredDisplayEditMethod.New,
      title: 'Create new display',
      subtitle: 'Start from scratch to create a new display for this collection'
    }
  ]);

}
