import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';

@Component({
  selector: 'app-edit-display-menu-details',
  templateUrl: './edit-display-menu-details.component.html',
  styleUrls: [
    './edit-display-menu-details.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayMenuDetailsComponent extends BaseComponent {

  @Input() sectionTitle: string;
  @Input() sectionDescription: string;
  @Input() mergeKey: string;
  @Input() formHasErrors: boolean;
  @Input() allDisplaysAreScheduled: boolean = false;
  @Input() reorderMenus: () => void;
  @Input() formPristine: boolean = true;

  constructor(public viewModel: EditDisplayViewModel) {
    super();
  }

}
