import { AfterViewInit, Component } from '@angular/core';
import { AutoSaveComponent } from '../../../shared/components/auto-save/auto-save.component';
import { EditTemplateMenuViewModel } from './edit-template-menu-view-model';

@Component({ selector: 'app-edit-template-menu', template: '' })
export abstract class EditTemplateMenuComponent extends AutoSaveComponent implements AfterViewInit {

  constructor(
    public override viewModel: EditTemplateMenuViewModel
  ) {
    super(viewModel);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.viewModel.triggerFormSubmission.subscribeWhileAlive({
      owner: this,
      next: (background) => this.formCapture?.submitForms(background)
    });
  }

}
