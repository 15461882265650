import { HydratedVariantBadge } from './hydrated-variant-badge';
import { Deserializable } from '../../protocols/deserializable';

export class CuratedVariantBadgeSection implements Deserializable {

  public title: string;
  public badges: HydratedVariantBadge[];
  public subSectionBadges: Map<string, HydratedVariantBadge[]> = new Map<string, HydratedVariantBadge[]>();

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.badges = Deserialize?.arrayOf(HydratedVariantBadge, this.badges);
    this.subSectionBadges = Deserialize?.typedArrayMapOf(HydratedVariantBadge, this.subSectionBadges);
  }

  isEmpty(): boolean {
    return !this.badges?.length && [...(this.subSectionBadges?.values() || [])].every(badges => !badges?.length);
  }

  deepCopyAndFilterSectionBySearchHits(badgeIdsWithSearchHits: string[]): CuratedVariantBadgeSection {
    const deepCopy = window?.injector?.Deserialize?.instanceOf(CuratedVariantBadgeSection, this);
    if (deepCopy?.badges?.length) {
      deepCopy.badges = deepCopy?.badges?.filter(badge => badgeIdsWithSearchHits?.includes(badge.id));
    }
    if (deepCopy?.subSectionBadges?.size) {
      for (const [key, val] of deepCopy.subSectionBadges) {
        const hitsForKey = val?.filter(v => badgeIdsWithSearchHits?.includes(v.id));
        hitsForKey?.length ? deepCopy.subSectionBadges.set(key, hitsForKey) : deepCopy.subSectionBadges.delete(key);
      }
    }
    return deepCopy;
  }

  badgeCount(): number {
    return this.badges?.length
        || [...(this.subSectionBadges?.values() || [])].reduce((acc, curr) => acc + (curr?.length || 0), 0)
        || 0;
  }

  /**
   * Directly manipulates the local data of this object. Used within badge picker.
   */
  removeNonCuratedSmartBadgesThatHaveRemoveExistingOnSyncFlag(): void {
    const validBadge = (badge: HydratedVariantBadge) => {
      const smartBadge = !!badge?.smartFilterIds?.length;
      const smartBadgeAndDoesNotRemoveExisting = smartBadge && !badge?.removeExistingOnSync;
      return badge?.isCurated() || !smartBadge || smartBadgeAndDoesNotRemoveExisting;
    };
    this.badges = this.badges.filter(validBadge);
    this.subSectionBadges.forEach((subBadges, key) => {
      subBadges?.forEach(subBadge => {
        if (!validBadge(subBadge)) {
          const badgeIndex = subBadges.indexOf(subBadge);
          subBadges.splice(badgeIndex, 1);
        }
      });
    });
  }

}
