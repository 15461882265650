import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';

@Component({
  selector: 'app-display-menu-section-body-container',
  templateUrl: './display-menu-section-body-container.component.html',
  styleUrls: ['./display-menu-section-body-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionBodyContainerComponent extends BaseComponent {

  @Input() nMenus: number;
  @Input() index: number;

}
