import { Component } from '@angular/core';
import { NavItemComponent } from '../nav-item.component';
import { Router } from '@angular/router';
import { NavItemViewModel } from '../nav-item-view-model';

@Component({
  selector: 'app-sign-out-nav-item',
  templateUrl: './sign-out-nav-item.component.html',
  styleUrls: [
    './sign-out-nav-item.component.scss',
    '../nav-item.component.scss'
  ],
  providers: [NavItemViewModel]
})
export class SignOutNavItemComponent extends NavItemComponent {

  constructor(
    public override viewModel: NavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

  override navItemSelected() {
    // navigate to the sign-out page instead of signing out here so that the CanDeactivateGuard will work properly
    this.router.navigate([`/auth/sign-out`]).then();
  }

}
