import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MenuSectionColorsViewModel } from './menu-section-colors-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Section } from '../../../../../../models/menu/dto/section';
import { ReactiveFormColorSquareComponent } from '@mobilefirstdev/reactive-form';
import { ReactiveFormUtils } from '../../../../../../utils/reactive-form-utils';

@Component({
  selector: 'app-menu-section-colors',
  templateUrl: './menu-section-colors.component.html',
  styleUrls: ['./menu-section-colors.component.scss'],
  providers: [MenuSectionColorsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionColorsComponent extends BaseComponent {

  constructor(
    public viewModel: MenuSectionColorsViewModel,
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() section: Section;
  @Input() isTemplatedSection: boolean = false;
  @Input() sectionSupportsTitleSectionBackgroundColor: boolean;
  @Input() sectionSupportsHeaderBackgroundColor: boolean;
  @Input() sectionSupportsHeaderTextColor: boolean;
  @Input() sectionSupportsProductContainerBackgroundColor: boolean;
  @Input() sectionSupportsBodyTextColor: boolean;
  @ViewChildren(ReactiveFormColorSquareComponent) squares: QueryList<ReactiveFormColorSquareComponent>;
  private mutationObservers: MutationObserver[];

  override setupBindings(): void {
    this.deleteErrorBlocks(this.squares?.toArray() || []);
    this.squares.changes.subscribeWhileAlive({
      owner: this,
      next: squares => this.deleteErrorBlocks(squares)
    });
  }

  private deleteErrorBlocks(components: ReactiveFormColorSquareComponent[]): void {
    this.disconnect();
    this.mutationObservers = components
      ?.map(component => component?.getNativeElement() as HTMLDivElement)
      ?.filterNulls()
      ?.map(div => ReactiveFormUtils.getDeleteErrorMutationObserver(div));
  }

  private disconnect(): void {
    this.mutationObservers?.forEach(observer => observer?.disconnect());
  }

  override destroy() {
    super.destroy();
    this.disconnect();
  }

}
