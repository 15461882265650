<div
  [ngbTooltip]="viewModel.badgeTooltip$ | async"
  [placement]="'top'"
  [container]="'body'"
  [style.cursor]="(viewModel.disableBadge$ | async) ? 'not-allowed' : 'pointer'">
  <app-asset
    matRipple
    appThrottleClick
    [ngbTooltip]="badge?.name"
    triggers="hover"
    class="badge-img"
    [class.disabled]="viewModel.disableBadge$ | async"
    (throttleClick)="selected.emit(badge)"
    [asset]="badge?.image"
    [scaleFit]="true"
    [cachePolicy]="ClientTypes.CachePolicy.Service"
    [borderRadius]="'0.625rem'">
  </app-asset>
</div>
