<div #hr [style.top.px]="filterHeight$ | async" class="product-picker-header-hr">
  <div class="hr-cover-see-through-space-top"></div>
  <div class="hr-bar"></div>
  <div class="hr-cover-see-through-space-bottom"></div>
</div>
<lib-reactive-table
  [data]="products"
  [nItemsToDisplay]="nItemsToDisplay"
  [nPagesInsidePaginationIndicator]="7"
  [resetPageOnNewData]="resetPageOnNewData"
  [resetPageOnSortingChange]="resetPageOnSortingChange">
  <app-product-picker-table-header [headerStickyOffset]="headerStickyOffset$ | async"></app-product-picker-table-header>
  <app-product-picker-table-item></app-product-picker-table-item>
  <app-product-picker-table-empty-state></app-product-picker-table-empty-state>
  <app-table-loading></app-table-loading>
  <lib-reactive-table-paginator></lib-reactive-table-paginator>
</lib-reactive-table>
