import { BudsenseFile } from '../models/shared/budsense-file';
import { ColorUtils } from './color-utils';
import { MediaType } from '../models/enum/dto/media-type.enum';

export class FileUtils {

  static getPngImageFromHex(hex: string, canvas: HTMLCanvasElement): BudsenseFile {
    hex = ColorUtils.validateHexColor(hex);
    const ctx = canvas.getContext('2d');
    canvas.width = 500;
    canvas.height = 500;
    ctx.fillStyle = hex;
    ctx.fillRect(0, 0, 500, 500);
    const encodedData = 'data:image/png;base64,' + canvas.toDataURL('image/png', '').substring(22);
    // Init File for color
    const file = new BudsenseFile();
    file.name = `${hex}.png`.toLowerCase();
    file.type = MediaType.PNG;
    file.url = encodedData;
    return file;
  }

  /**
   * These file signatures were sourced from: https://en.wikipedia.org/wiki/List_of_file_signatures
   * More about file signatures: https://www.file-recovery.com/signatures.htm
   */
  static mimeTypeMatchesDataSignature(file: BudsenseFile): boolean {
    const b64Url = String(file.url);
    const replacementString = 'data:' + file.type + ';base64,';
    const encodedB64 = b64Url.replace(replacementString, '');
    const hex = this.base64ToHex(encodedB64, 256);
    switch (file.type) {
      case MediaType.PNG:
        return hex.substring(0, 16) === '89504E470D0A1A0A';
      case MediaType.JPEG:
      case MediaType.JPG:
        return hex.substring(0, 6) === 'FFD8FF';
      case MediaType.WEBP:
        return hex.substring(0, 8) === '52494646';
      case MediaType.BMP:
        return  hex.substring(0, 4) === '424D';
      case MediaType.GIF:
        return hex.substring(0, 8) === '47494638';
      case MediaType.MP4: {
        const isQuickTimeContainerFile = (): boolean => hex.substring(8, 16) === '66747970';
        const validMP4SubType = (): boolean => {
          const subType = FileUtils.hexToCharacters(hex.substring(16, 24));
          return subType === 'avc1'
              || subType === 'iso2'
              || subType === 'isom'
              || subType === 'mmp4'
              || subType === 'mp41'
              || subType === 'mp42'
              || subType === 'mp71'
              || subType === 'msnv'
              || subType === 'ndas'
              || subType === 'ndsc'
              || subType === 'ndsh'
              || subType === 'ndsm'
              || subType === 'ndsp'
              || subType === 'ndss'
              || subType === 'ndxc'
              || subType === 'ndxh'
              || subType === 'ndxm'
              || subType === 'ndxp'
              || subType === 'ndxs';
        };
        return isQuickTimeContainerFile() && validMP4SubType();
      }
      case MediaType.MOV: {
        const isQuickTimeContainerFile = (): boolean => hex.substring(8, 16) === '66747970';
        const validMOVSubType = (): boolean => {
          const subType = FileUtils.hexToCharacters(hex.substring(16, 24));
          return subType === 'qt  ';
        };
        return isQuickTimeContainerFile() && validMOVSubType();
      }
      case MediaType.WEBM:
        return hex.substring(0, 8) === '1A45DFA3';
      case MediaType.OGV:
        return hex.substring(0, 8) === '4F676753';
      case MediaType.AVI:
        return hex.substring(0, 8) === '52494646';
      case MediaType.MPG:
      case MediaType.MPEG:
        return hex.substring(0, 8) === '000001BA';
      default: return false;
    }
  }

  // b64 must be encoded properly, cannot include 'data:image/png;base64' etc
  static base64ToHex(b64: string, nHexDigitsReturned?: number) {
    const raw = atob(b64);
    let result = '';
    for (let i = 0; i < (nHexDigitsReturned ?? raw.length); i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result.toUpperCase();
  }

  static hexToCharacters(hex: string) {
    let result = '';
    for (let i = 0; i < hex.length; i += 2) {
      result += String.fromCharCode(parseInt(hex.substring(i, i + 2), 16));
    }
    return result;
  }

}
