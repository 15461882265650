import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { SectionColumnConfigKey, SectionColumnConfigKeyType } from '../../utils/dto/section-column-config-key-type';
import { SectionColumnConfig } from './section-column-config';
import { SectionMetadata } from './section-metadata';
import type { SectionSortOption } from '../../utils/dto/section-sort-type';
import { SectionType } from '../../utils/dto/section-type-definition';
import { SectionLayout, SectionLayoutType } from '../../utils/dto/section-layout-type';
import { Orderable } from '../../protocols/orderable';
import { MenuSectionTypeIcon } from '../enum/menu-section-type-icon.enum';
import { StringUtils } from '../../../utils/string-utils';

export abstract class BaseSection implements Deserializable, Orderable, UniquelyIdentifiable {

  public id: string;
  public priority: number;
  public title: string;
  public subTitle: string;
  public showZeroStockItems: boolean;
  public sorting: SectionSortOption;
  public secondarySorting: SectionSortOption;
  public sectionType: SectionType;
  public layoutType: string;
  public metadata: SectionMetadata;
  public columnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>;
  public rowCount: number;
  public smartFilterIds: string[];
  public autoUpdateGridColumns: boolean = false;
  public hideSection: boolean = false;
  public dateCreated: number;
  public lastUpdated: number;

  onDeserialize() {
    this.smartFilterIds = Array.from(this.smartFilterIds || []);
    this.deserializeMetadata();
    this.deserializeColumnConfig();
    this.transformFalsyRowCountToNull();
    this.setDataOnNewProductAndRestockedProductSections();
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/BaseSectionDTO.go
  onSerialize() {
    const dto = Object.create(BaseSection.prototype);
    dto.id = this.id;
    dto.autoUpdateGridColumns = this.autoUpdateGridColumns;
    dto.columnConfig = this.columnConfig;
    dto.layoutType = this.layoutType;
    dto.metadata = this.metadata;
    dto.priority = this.priority;
    dto.rowCount = this.rowCount;
    dto.secondarySorting = this.secondarySorting;
    dto.sectionType = this.sectionType;
    dto.showZeroStockItems = this.showZeroStockItems;
    dto.smartFilterIds = this.smartFilterIds;
    dto.sorting = this.sorting;
    dto.subTitle = this.subTitle;
    dto.title = this.title;
    dto.hideSection = this.hideSection;
    return dto;
  }

  protected deserializeMetadata() {
    this.metadata = this.metadata
      ? window?.injector?.Deserialize?.instanceOf(SectionMetadata, this.metadata)
      : new SectionMetadata();
  }

  private deserializeColumnConfig() {
    if (!this.columnConfig) {
      this.columnConfig = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    } else if (!(this.columnConfig instanceof Map)) {
      this.columnConfig = window?.injector?.Deserialize?.mapOf(SectionColumnConfig, this.columnConfig);
    } else {
      this.columnConfig = new Map<SectionColumnConfigKey, SectionColumnConfig>(this.columnConfig);
    }
    SectionColumnConfigKeyType.getAllKeys()?.forEach(key => {
      if (!this.columnConfig?.get(key)) {
        this.columnConfig.set(key, new SectionColumnConfig());
      }
    });
  }

  private transformFalsyRowCountToNull() {
    if (!this.rowCount) this.rowCount = null;
  }

  private setDataOnNewProductAndRestockedProductSections() {
    if (this.sectionType === SectionType.NewProducts || this.sectionType === SectionType.RestockedProducts) {
      this.metadata.hideNewLabels = 'true';
      this.metadata.hideRestockedLabels = 'true';
    }
  }

  getUniqueIdentifier(): string {
    const titleId = this.title ?? '';
    const subTitleId = this.subTitle ?? '';
    const zeroStockId = `${this.showZeroStockItems}`;
    const sortingId = this.sorting ?? '';
    const secondarySortingId = this.secondarySorting ?? '';
    const sectionTypeId = this.sectionType ?? '';
    const layoutTypeId = this.layoutType ?? '';
    const hideSection = this.hideSection;
    const columnConfigMap: string[] = [];
    this.columnConfig?.forEach((val, key) => {
      const columnConfig = val?.getUniqueIdentifier();
      columnConfigMap.push(`${key}-${columnConfig}`);
    });
    const metadataId = this.metadata?.getUniqueIdentifier() ?? '';
    const rowCount = `${this.rowCount}`;
    const smartFilterIds = this.smartFilterIds.sort().join(',') ?? '';
    return `${titleId}
      -${subTitleId}
      -${zeroStockId}
      -${sortingId}
      -${secondarySortingId}
      -${sectionTypeId}
      -${hideSection}
      -${columnConfigMap.sort().join(',')}
      -${layoutTypeId}
      -${metadataId}
      -${rowCount}
      -${smartFilterIds}`;
  }

  /* ******************************* Orderable Interface ******************************* */

  getOrderValue(): number {
    return this.priority;
  }

  getOrderableTitle(): string {
    return this.getSectionTitle();
  }

  getOrderableUniqueId(): string {
    return this.id;
  }

  setOrderableValue(val: number) {
    this.priority = val;
  }

  /* ********************************************************************************** */

  /** returns self */
  translateIntoDTO(): this {
    SectionColumnConfigKeyType.getAllKeys()?.forEach(key => {
      const columnConfig = this.columnConfig?.get(key) as SectionColumnConfig | null;
      this.columnConfig.set(key, columnConfig?.translateToDTO());
    });
    return this;
  }

  public hasSmartFilters(): boolean {
    return this.smartFilterIds?.length > 0;
  }

  public getSectionTitle(): string {
    switch (this.sectionType) {
      case SectionType.PageBreak:
        return 'Page Break';
      default:
        return this.title;
    }
  }

  public getFormattedSectionType(): string {
    switch (this.sectionType) {
      case SectionType.Media:
        return 'Media';
      case SectionType.Title:
        return 'Title';
      case SectionType.PageBreak:
        return 'Page Break';
      case SectionType.NewProducts:
      case SectionType.Product:
      case SectionType.RestockedProducts:
        return 'Product';
    }
  }

  public getIconSrc(): string {
    switch (this.sectionType) {
      case SectionType.NewProducts:
      case SectionType.Product:
      case SectionType.ProductGroup:
      case SectionType.RestockedProducts:
      case SectionType.Spotlight:
        return MenuSectionTypeIcon.ProductSection;
      case SectionType.Title:
        return MenuSectionTypeIcon.TitleSection;
      case SectionType.Media:
        return MenuSectionTypeIcon.MediaSection;
      case SectionType.PageBreak:
        return MenuSectionTypeIcon.PageBreak;
      case SectionType.CategoryCard:
        return MenuSectionTypeIcon.CategoryCard;
      default:
        return '';
    }
  }

  isInLineMode(): boolean {
    // For purposes of determining visible/scoped variants, we need to know if the section references grid columns
    return this.layoutType === SectionLayout.List;
  }

  isGridMode(): boolean {
    return this.layoutType === SectionLayout.Grid;
  }

  isChildVariantList(): boolean {
    return this.layoutType === SectionLayout.ChildVariantList;
  }

  getGridModeColumnNamesOrNull(): string | null {
    return this.isGridMode() ? this.metadata?.gridColumnNames : null;
  }

  isPricingTierGridMode(): boolean {
    return this.layoutType === SectionLayout.PricingTierGrid;
  }

  getPricingTierGridColumnNamesOrNull(): string | null {
    return this.isPricingTierGridMode() ? this.metadata?.gridColumnNames : null;
  }

  hasMultipleVariantsPerLineItem(): boolean {
    return this.isGridMode() || this.isPricingTierGridMode();
  }

  getLayoutType(): SectionLayoutType {
    return window?.types?.initTypeDefinition(SectionLayoutType, this.layoutType);
  }

  addSmartFilters(ids: string[]) {
    this.smartFilterIds = (this.smartFilterIds || [])?.concat(ids)?.unique();
  }

  isProductSection(): boolean {
    const productSectionTypes = [SectionType.NewProducts, SectionType.Product, SectionType.RestockedProducts];
    return productSectionTypes.includes(this.sectionType);
  }

  isNewProductsSection(): boolean {
    return this.sectionType === SectionType.NewProducts;
  }

  isPrintReportMenuSection(): boolean {
    const printReportSections = [SectionType.RestockedProducts, SectionType.NewProducts];
    return printReportSections.includes(this.sectionType);
  }

  supportsStockColumn(): boolean {
    return this.sectionType === SectionType.NewProducts || this.sectionType === SectionType.RestockedProducts;
  }

  private getActiveGridColumns(): string[] {
    return this.metadata?.gridColumnNames?.split(',')?.unique() ?? [];
  }

  public getActiveColumnsAsGridColumnComparisonStrings(): string[] {
    return this.getActiveGridColumns().map(StringUtils.gridColumnComparisonString);
  }

}
