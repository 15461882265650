import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EditMarketingMenuBodyComponent } from '../../edit-marketing-menu-body/edit-marketing-menu-body.component';
import { EditFeaturedCategoryMenuViewModel } from '../edit-featured-category-menu-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-featured-category-menu-body',
  templateUrl: './edit-featured-category-menu-body.component.html',
  styleUrls: ['./edit-featured-category-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategoryMenuBodyComponent extends EditMarketingMenuBodyComponent {

  constructor(
    public override viewModel: EditFeaturedCategoryMenuViewModel, // provided by parent component
  ) {
    super(viewModel);
  }

  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

}
