<app-loading *ngIf="viewModel.loadingOpts?.isLoading" [options]="viewModel.loadingOpts"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">Override Badges</div>
      <div class="modal-subtitle inline-block">
        {{ viewModel.variantName$ | async }}
        <!-- Add inline tooltip -->
        <button
          [hidden]="!(viewModel.variantToolTip$ | async)"
          [ngbTooltip]="viewModel.variantToolTip$ | async"
          [disableTooltip]="disableToolTip$ | async"
          #t="ngbTooltip"
          placement="right"
          class="inline-tooltip">
          <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
        </button>
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->

    <div class="variant-badge-container">
      <div *ngIf="viewModel.descText$ | async" class="bs-edit-section-description mb-16px show-new-lines">
        {{ viewModel.descText$ | async }}
      </div>

      <app-variant-badges
        [title]="'Selected'"
        [includeTopDivider]="false"
        [badges]="viewModel.liveBadgeDisplayAttributeUpdates$ | async"
        [inheritedBadges]="viewModel.inheritedBadges$ | async"
        [theme]="viewModel.theme$ | async"
        [showBadgePicker]="false"
        (removedClicked)="viewModel.setBadgeForRemoval($event)"
        [emptyStateText]="'Badges that you’ve added for this product will appear here.'"
        [inheritedText]="'Badge inherited from display attribute'">
      </app-variant-badges>

      <div class="bs-edit-section-title mt-32px mb-16px">Available</div>

      <app-badge-picker
        class="badge-picker"
        [addedBadgeIds]="viewModel.addedBadgeIds$ | async"
        [asListItems]="true"
        (selected)="viewModel.setBadgeToAdditions($event)">
      </app-badge-picker>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="promptForSaveBadgeOverride()"
      [disabled]="!viewModel.canSave()"
      [ngClass]="{ disabled: !viewModel.canSave() }"
      class="bs-button preferred-button">
      Save
    </button>
  </div>
</div>
