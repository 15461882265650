import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { SaleSystemLabel } from '../../../models/shared/labels/sale-system-label';

@Pipe({
  name: 'isSaleSystemLabel'
})
export class IsSaleSystemLabelPipe implements PipeTransform {

  transform(value: Label): boolean {
    return value instanceof SaleSystemLabel;
  }

}
