<app-print-job-name-form
  [job]="job"
  [label]="'Stack Print Name'"
  [mergeKey]="mergeKey"
  [placeholder]="placeholder"
  [viewOnly]="viewOnly">
</app-print-job-name-form>

<app-print-job-employee-form [job]="job" [mergeKey]="mergeKey" [viewOnly]="viewOnly"></app-print-job-employee-form>

<lib-reactive-search-bar
  #searchBar
  class="search-bar"
  [style.top]="0"
  [style.position]="'sticky'"
  [style.z-index]="2"
  [searchTheseProperties]="['name']"
  [placeHolder]="'Search by name'">
</lib-reactive-search-bar>

<app-single-stack-print-job-applied-products
  *rxIf="viewOnly; else productsForm"
  [job]="job"
  [stackType]="stackType"
  [searchText]="searchBar.searchText | push">
</app-single-stack-print-job-applied-products>

<ng-template #productsForm>
  <app-stack-print-job-products-form
    [job]="job"
    [mergeKey]="mergeKey"
    [templateMode]="templateMode"
    [viewOnly]="viewOnly"
    [searchText]="searchBar.searchText | push"
    [stackType]="stackType">
  </app-stack-print-job-products-form>
</ng-template>
