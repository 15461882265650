<lib-reactive-form-group #form [mergeKey]="mergeKey" [bindTo]="bindTo">
  <lib-reactive-form-row>
    <lib-reactive-form-number
      [inputName]="'priceOverride'"
      [label]="'Price Override'"
      [placeholder]="viewModel.pricePlaceHolder$ | async"
      [inlineLabel]="true"
      [required]="false"
      [disabled]="viewModel?.isTemplatedMenu$ | async"
      [disabledTooltip]="viewModel?.isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip"
      [integersOnly]="false"
      [inlinePercentage]="20"
      [customValueParser]="scopedParsePriceOverride"
      [tooltip]="
        'This allows you to override the variant price on this menu. By default the sale price or regular price is selected.'
      "
      [bindingProperty]="'price'">
    </lib-reactive-form-number>
  </lib-reactive-form-row>
  <lib-reactive-form-row>
    <lib-reactive-form-text-area
      [inputName]="'descOverride'"
      [label]="'Description Override'"
      [placeholder]="viewModel.desc$ | async"
      [inlineLabel]="true"
      [required]="false"
      [disabled]="viewModel?.isTemplatedMenu$ | async"
      [disabledTooltip]="viewModel?.isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip"
      [inlinePercentage]="20"
      [maxLength]="600"
      [customValueParser]="scopedParseDescOverride"
      [tooltip]="'This allows you to override the variant description text.'"
      [bindingProperty]="'desc'">
    </lib-reactive-form-text-area>
  </lib-reactive-form-row>
</lib-reactive-form-group>
