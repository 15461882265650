<div
  class="section-product-preview-container"
  fxLayout="column"
  [style]="{ border: redBorder ? '1px solid red' : null }">
  <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

  <div class="section-product-preview-header" fxFlex fxLayout="row">
    <!--  Variant Title  -->
    <div class="section-product-preview-title" fxFlex fxLayout="row">
      <div [style.margin-right.rem]="0.5">
        <app-display-name
          [displayName]="viewModel.variantTitle$ | async"
          [addedByTemplate]="viewModel.variantAddedFromTemplate$ | async"
          [isMedical]="viewModel.isMedical$ | async"
          [variantTooltip]="viewModel.variantTooltip$ | async"
          [variantTooltipPlacement]="'top'"></app-display-name>
      </div>
      <!--   Label previews for primary and disabled secondary (if applicable)   -->
      <app-label-preview [zoom]="0.7" [computeLabelInterface]="viewModel.computeLabelInterface$ | async">
      </app-label-preview>
      <app-asset
        *ngFor="let badge of viewModel.badges$ | async; trackBy: trackByBadgeId"
        class="variant-badge-preview"
        [asset]="badge?.image"
        [scaleFit]="true"
        [borderRadius]="'0.625rem'">
      </app-asset>
    </div>

    <!--  Primary Sort Property -->
    <div [hidden]="viewModel.hidePrimarySortProperty$ | async" class="section-product-preview-data sort-by-container">
      <div class="sort-by-value">
        <!-- Show sort value - stroke-through only happens when showing price  -->
        <div [ngClass]="{ 'stroke-through': viewModel.primarySortStrokePrice$ | async }">
          {{ viewModel.primarySortOriginalPriceOrSortByValue$ | async }}
        </div>
        <!-- Show sort value as price - if sort value is by price, then show sale price  -->
        <div [hidden]="!(viewModel.primarySortHasSalePrice$ | async)" class="sale-price-value">
          {{ viewModel.salePrice$ | async }}
        </div>
      </div>
      <div class="sort-by-subtext ellipsis-nowrap-text">
        {{ viewModel.primarySortBySubtext$ | async }}
      </div>
    </div>

    <!--  Secondary Sort Property -->
    <div [hidden]="viewModel.hideSecondarySortProperty$ | async" class="section-product-preview-data">
      <div class="sort-by-value">
        <!-- Show sort value - stroke-through only happens when showing price  -->
        <div [ngClass]="{ 'stroke-through': viewModel.secondarySortStrokePrice$ | async }">
          {{ viewModel.secondarySortOriginalPriceOrSortByValue$ | async }}
        </div>
        <!-- Show sort value as price - if sort value is by price, then show sale price  -->
        <div [hidden]="!(viewModel.secondarySortHasSalePrice$ | async)" class="sale-price-value">
          {{ viewModel.salePrice$ | async }}
        </div>
      </div>
      <div class="sort-by-subtext ellipsis-nowrap-text">
        {{ viewModel.secondarySortBySubtext$ | async }}
      </div>
    </div>

    <!--  Variant Quantity  -->
    <div
      [hidden]="viewModel.hideQuantity$ | async"
      [ngClass]="{ 'out-of-stock': viewModel.outOfStock$ | async }"
      class="section-product-preview-data">
      <div class="quantity-value">
        {{ viewModel.variantQtyValue$ | async }}
      </div>
      <div class="quantity-subtext ellipsis-nowrap-text">
        {{ viewModel.quantityText$ | async }}
      </div>
    </div>

    <!--  Variant Price  -->
    <div [hidden]="viewModel.hidePrice$ | async" class="section-product-preview-price">
      <div [ngClass]="{ 'stroke-through': viewModel.salePrice$ | async }" class="price-value">
        {{ viewModel.calculatedPrice$ | async }}
      </div>
      <div [hidden]="!(viewModel.salePrice$ | async)" class="sale-price-value">
        {{ viewModel.salePrice$ | async }}
      </div>
      <div class="price-subtext ellipsis-nowrap-text">
        {{ viewModel.priceSubText$ | async }}
      </div>
    </div>
  </div>

  <div class="section-product-preview-body" fxFlex fxLayout="row grid" fxLayoutGap="1.5rem grid">
    <!--  Product/Variant Type  -->
    <div class="section-product-preview-detail" style="padding-left: 0">
      <img [src]="'assets/icons/dark/outline/archive.svg'" alt="" />
      <span>{{ viewModel.typeText$ | async }}</span>
    </div>
    <!--  Unit Size  -->
    <div class="section-product-preview-detail">
      <img [src]="'assets/icons/dark/outline/arrows-expand.svg'" alt="" />
      <span>{{ viewModel.unitSizeText$ | async }}</span>
    </div>
    <!--  Cannabis Content  -->
    <div
      class="section-product-preview-detail"
      [ngStyle]="{ width: (viewModel.cannabinoidRange$ | async) ? '10rem' : '7rem' }">
      <img [src]="'assets/icons/dark/outline/beaker.svg'" alt="" />
      <!-- Add inline tooltip -->
      <button
        [hidden]="!(viewModel.cannabisContentTooltip$ | async)"
        [ngbTooltip]="viewModel.cannabisContentTooltip$ | async"
        class="inline-tooltip">
        <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" style="padding-bottom: 0" />
      </button>
      <span>{{ viewModel | getCannabinoidText : 'THC' | async }} THC</span>
      <span>{{ viewModel | getCannabinoidText : 'CBD' | async }} CBD</span>
    </div>
    <!--  Variant Style  -->
    <div class="section-product-preview-detail">
      <img [hidden]="!(viewModel.variantStyle$ | async)" [src]="'assets/icons/dark/outline/eye.svg'" alt="" />
      <img [hidden]="viewModel.variantStyle$ | async" [src]="'assets/icons/dark/outline/eye-off.svg'" alt="" />
      <span>{{ viewModel.styleText$ | async }}</span>
    </div>
    <!--  Variant Badge  -->
    <div class="section-product-preview-detail">
      <img [src]="'assets/icons/dark/outline/tag.svg'" [ngbTooltip]="viewModel.badgeTooltip$ | async" alt="" />
      <span>{{ viewModel.badgeCountText$ | async }}</span>
    </div>
  </div>

  <div class="section-product-preview-footer" fxFlex>
    <!--  Featured Variant Toggle  -->
    <div class="section-product-preview-featured-variant"></div>
    <!--  Buttons  -->
    <div class="bs-section-body-buttons">
      <lib-button-inline-text
        [disabled]="autoSaving || removingVariants || invalidFormState || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="viewModel.openEditVariantModal()">
        Edit Variant
      </lib-button-inline-text>

      <lib-button-inline-text
        *ngIf="!(viewModel.isProductGroupSection$ | async)"
        [disabled]="
          autoSaving ||
          removingVariants ||
          invalidFormState ||
          !formPristine ||
          (viewModel?.disableLabelOverride$ | async)
        "
        [disabledTooltip]="(viewModel.disableLabelOverrideTooltip$ | async) || (formPristine | saveChangesTooltip)"
        (buttonClicked)="viewModel.openEditLabelModal()">
        Override Labels
      </lib-button-inline-text>

      <lib-button-inline-text
        *ngIf="!(viewModel.isProductGroupSection$ | async)"
        [disabled]="
          autoSaving ||
          removingVariants ||
          invalidFormState ||
          !formPristine ||
          (viewModel?.disableBadgeOverride$ | async)
        "
        [disabledTooltip]="(viewModel.disableBadgeOverrideTooltip$ | async) || (formPristine | saveChangesTooltip)"
        (buttonClicked)="viewModel.openBadgeModal(this.saveSection)">
        Override Badges
      </lib-button-inline-text>

      <lib-button-inline-text
        *ngIf="viewModel.showManageStyleCTA$ | async"
        [disabled]="
          autoSaving ||
          removingVariants ||
          invalidFormState ||
          !formPristine ||
          (viewModel.disableManageStyling$ | async)
        "
        [disabledTooltip]="(viewModel.disableManageStylingTooltip$ | async) || (formPristine | saveChangesTooltip)"
        (buttonClicked)="viewModel.handleEditStyle()">
        {{ viewModel.manageStyleText$ | async }}
      </lib-button-inline-text>

      <lib-button-inline-text
        *ngIf="(menu | containsStackedContent) && !gridMode"
        [disabled]="autoSaving || removingVariants || redBorder || invalidFormState || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="showLiveView.emit(variant)">
        Live View
      </lib-button-inline-text>

      <lib-button-inline-text
        [disabled]="autoSaving || invalidFormState || !formPristine || !(viewModel.canRemove$ | async)"
        [disabledTooltip]="(viewModel?.disabledRemoveTooltip$ | async) || (formPristine | saveChangesTooltip)"
        (buttonClicked)="handleRemoveVariant()"
        [textColor]="'#FA5555'">
        Remove
      </lib-button-inline-text>
    </div>
  </div>
</div>
