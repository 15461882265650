import { Deserializable } from '../../protocols/deserializable';
import { Orientation } from '../../utils/dto/orientation-type';
import { DefaultPrintStackSize } from '../../enum/dto/default-print-stack-size';

export class PrintConfig implements Deserializable {

  public orientations: string[];
  public paperSizes: string[];
  public printCardSizes: string[];
  public marginTop: number = 0;
  public marginRight: number = 0;
  public marginBottom: number = 0;
  public marginLeft: number = 0;
  public previewCardMap: Map<DefaultPrintStackSize, string[]> = new Map();
  public cardOrientationMap: Map<DefaultPrintStackSize, Orientation> = new Map();
  public gridCountMap: Map<DefaultPrintStackSize, number> = new Map();
  public visiblePropertyMap: Map<DefaultPrintStackSize, string[]>;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.previewCardMap = Deserialize?.genericArrayMap(this.previewCardMap);
    this.cardOrientationMap = Deserialize?.genericMap(this.cardOrientationMap);
    this.gridCountMap = Deserialize?.genericMap(this.gridCountMap);
    this.visiblePropertyMap = !!this.visiblePropertyMap
      ? window.injector.Deserialize.genericArrayMap(this.visiblePropertyMap)
      : new Map<DefaultPrintStackSize, string[]>();
  }

}
