import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { PrintJobEmployeeFormViewModel } from './print-job-employee-form-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-print-job-employee-form',
  templateUrl: './print-job-employee-form.component.html',
  providers: [PrintJobEmployeeFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobEmployeeFormComponent {

  constructor(public viewModel: PrintJobEmployeeFormViewModel) {
  }

  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean;

}
