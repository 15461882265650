import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishCollectionViewModel } from './publish-collection-view-model';

@Component({
  selector: 'app-publish-collection',
  templateUrl: './publish-collection.component.html',
  styleUrls: ['./publish-collection.component.scss'],
  providers: [PublishCollectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishCollectionComponent extends BaseModalComponent {

  constructor(
    public activeModal: NgbActiveModal,
    public viewModel: PublishCollectionViewModel
  ) {
    super(activeModal);
  }

}
