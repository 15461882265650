import { Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { Company } from '../models/company/dto/company';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError, map } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Location } from '../models/company/dto/location';
import { ScheduledEmail } from '../models/automation/scheduled-email';
import { SmartFilter } from '../models/automation/smart-filter';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { ApiPagination } from '../models/shared/api-pagination';

export const DEFAULT_SMART_FILTER_PAGINATION_COUNT = 75;

@Injectable({ providedIn: 'root' })
export class AutomationAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public serviceName = 'Automation';

  // Scheduled Menus

  public GetScheduledMenuSending(company: Company, location: Location): Observable<ScheduledEmail[]> {
    const url = Endpoints.GetMenuSchedules(location);
    return this.apiClient.getArr<ScheduledEmail>(ScheduledEmail, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuSchedules', err));
        return throwError(err);
      })
    );
  }

  public UpdateScheduledEmail(scheduledEmail: ScheduledEmail) {
    const url = Endpoints.UpdateMenuSchedule();
    return this.apiClient.postObj(ScheduledEmail, url, scheduledEmail).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenuSchedule', err));
        return throwError(err);
      })
    );
  }

  // Smart Filters

  public createSmartFilter(smartFilter: SmartFilter, locationId: number): Observable<HydratedSmartFilter> {
    const url = Endpoints.createSmartFilter();
    // noinspection SpellCheckingInspection
    const additionalHeaders = { locationid: `${locationId}` };
    return this.apiClient.postObj(HydratedSmartFilter, url, smartFilter, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSmartFilter', err));
        return throwError(err);
      })
    );
  }

  public updateSmartFilter(smartFilter: SmartFilter, locationId: number): Observable<HydratedSmartFilter[]> {
    const url = Endpoints.updateSmartFilter();
    // noinspection SpellCheckingInspection
    const additionalHeaders = { locationid: `${locationId}` };
    return this.apiClient.postObj(HydratedSmartFilter, url, smartFilter, additionalHeaders).pipe(
      map(updated => [updated]),
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateSmartFilter', err));
        return throwError(err);
      })
    );
  }

  public deleteSmartFilter(smartFilter: SmartFilter, locationId: number): Observable<string> {
    const url = Endpoints.deleteSmartFilter();
    // noinspection SpellCheckingInspection
    const additionalHeaders = { locationid: `${locationId}` };
    return this.apiClient.deleteStr(url, smartFilter, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteSmartFilter', err));
        return throwError(err);
      })
    );
  }

  public getSmartFilters(locationId: string, ids?: string[], pagination: ApiPagination = null):
    Observable<HydratedSmartFilter[]> {
    const url = Endpoints.getSmartFilters(locationId, ids);
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_SMART_FILTER_PAGINATION_COUNT);
    }
    return this.apiClient.recursiveGet<HydratedSmartFilter>(HydratedSmartFilter, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSmartFilters', err));
        return throwError(err);
      })
    );
  }

  public getCuratedSmartFilters(locationId: string, ids?: string[], pagination: ApiPagination = null):
    Observable<HydratedSmartFilter[]> {
    const url = Endpoints.getCuratedSmartFilters(locationId, ids);
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_SMART_FILTER_PAGINATION_COUNT);
    }
    return this.apiClient.recursiveGet<HydratedSmartFilter>(HydratedSmartFilter, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCuratedSmartFilters', err));
        return throwError(err);
      })
    );
  }

  public syncSectionSmartFilters(menuId: string, sectionId: string): Observable<HydratedSection> {
    const url = Endpoints.syncSectionSmartFilter(menuId, sectionId);
    return this.apiClient.getObj<HydratedSection>(HydratedSection, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'syncSectionSmartFilters', err));
        return throwError(err);
      })
    );
  }

  public syncLocationSmartFilters(locationId: string, forceSync?: boolean): Observable<LocationConfiguration> {
    const url = Endpoints.syncLocationSmartFilters(locationId, forceSync);
    return this.apiClient.getObj<LocationConfiguration>(LocationConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'syncLocationSmartFilters', err));
        return throwError(err);
      })
    );
  }

  /**
   * Sync smart filters before creating a print job so smart filter data is up-to-date before rendering.
   * This is offloaded onto the frontend because our AWS lambda functions keep timing out for large print jobs.
   *
   * @param locationId
   * @param menuIds are the ids of the menus associated with an upcoming print job request
   * @param async should be false if the job contains stacked content (cards/labels/etc.)
   */
  public syncSmartFiltersBeforePrintJob(
    locationId: string,
    menuIds: string[],
    async: boolean
  ): Observable<LocationConfiguration> {
    const url = Endpoints.syncSmartFiltersBeforePrintJob(locationId, menuIds, async);
    return this.apiClient.getObj<LocationConfiguration>(LocationConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'syncSmartFiltersBeforePrintJob', err));
        return throwError(err);
      })
    );
  }

  public syncSmartDisplayAttributes(locationIds: string, forceSync?: boolean): Observable<LocationConfiguration[]> {
    const url = Endpoints.syncSmartDisplayAttributes(locationIds, forceSync);
    return this.apiClient.getArr<LocationConfiguration>(LocationConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'syncSmartDisplayAttributes', err));
        return throwError(err);
      })
    );
  }

}
