import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EditSmartPlaylistMenuContentViewModel extends BaseViewModel {

  private readonly _formAutoSubmitted = new Subject<any[]>();
  public readonly formAutoSubmitted$ = this._formAutoSubmitted as Observable<any[]>;
  connectToFormAutoSubmitted = (formAutoSubmitted: any[]) => this._formAutoSubmitted.next(formAutoSubmitted);

}
