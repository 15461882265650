import { Pipe, PipeTransform } from '@angular/core';
import { CardStackPrintConfig } from '../../../../../../../models/automation/card-stack-print-config';

@Pipe({
  name: 'newProductChangeCheckboxDisabled'
})
export class NewProductChangeCheckboxDisabledPipe implements PipeTransform {

  transform(printConfig: CardStackPrintConfig): boolean {
    return printConfig?.newProductChangeShouldBeForcedToTrue();
  }

}
