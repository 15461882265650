<div class="bs-bold f16px mt-24px">Print {{ stackType | titlecase }}s</div>
<lib-reactive-form-group [mergeKey]="mergeKey">
  <app-list-item-checkbox
    [label]="'Include out of stock products'"
    [inputName]="'includeZeroStockItems'"
    [disabled]="viewOnly"
    [programmaticallyChangeValue]="blueprint?.includeZeroStockItems"
    (clicked)="smartPrintOptionChanged.emit('includeZeroStockItems')">
  </app-list-item-checkbox>
  <div class="bs-medium f14px mt-24px mb-12px">Include products in your print job with the following changes:</div>
  <app-list-item-checkboxes
    [inputName]="'printOptionCheckboxes'"
    [hideBulkCheckbox]="true"
    [disabled]="viewOnly"
    [requireMinimumSelection]="1">
    <app-list-item-checkbox
      *rxIf="viewModel.showNewProductsCheckbox$"
      [label]="'New Products'"
      [inputName]="'newProductChange'"
      [disabled]="viewOnly || (blueprint | newProductChangeCheckboxDisabled)"
      [disabledTooltip]="viewOnly | newProductChangeCheckboxDisabledTooltip"
      [tooltip]="tooltips.newProductChange"
      [programmaticallyChangeValue]="blueprint?.newProductChange"
      (clicked)="smartPrintOptionChanged.emit('newProductChange')">
    </app-list-item-checkbox>
    <app-list-item-checkbox
      *rxIf="viewModel.showInventoryRestockedCheckbox$"
      [label]="'Inventory Restocked'"
      [inputName]="'inventoryRestock'"
      [disabled]="viewOnly"
      [tooltip]="tooltips.inventoryRestock"
      [programmaticallyChangeValue]="blueprint?.inventoryRestock"
      (clicked)="smartPrintOptionChanged.emit('inventoryRestock')">
    </app-list-item-checkbox>

    <app-list-item-checkbox
      *rxIf="viewModel.showPriceChangeCheckbox$"
      [label]="'Price Changes'"
      [inputName]="'priceChange'"
      [disabled]="viewOnly"
      [tooltip]="tooltips.priceChange"
      [programmaticallyChangeValue]="blueprint?.priceChange"
      (clicked)="smartPrintOptionChanged.emit('priceChange')">
    </app-list-item-checkbox>

    <app-list-item-checkbox
      *rxIf="viewModel.showProductInfoChangeCheckbox$"
      [label]="'Product Info Changes'"
      [inputName]="'productInfoChange'"
      [disabled]="viewOnly"
      [tooltip]="tooltips.productInfoChange"
      [programmaticallyChangeValue]="blueprint?.productInfoChange"
      (clicked)="smartPrintOptionChanged.emit('productInfoChange')">
    </app-list-item-checkbox>

    <app-list-item-checkbox
      *rxIf="viewModel.showCannabinoidChangeCheckbox$"
      [label]="'Cannabinoid Changes'"
      [inputName]="'cannabinoidChange'"
      [disabled]="viewOnly"
      [tooltip]="tooltips.cannabinoidChange"
      [programmaticallyChangeValue]="blueprint?.cannabinoidChange"
      (clicked)="smartPrintOptionChanged.emit('cannabinoidChange')">
    </app-list-item-checkbox>

    <app-list-item-checkbox
      *rxIf="viewModel.showCustomizationChangeCheckbox$"
      [label]="'Badge/Label Changes'"
      [inputName]="'customizationChange'"
      [disabled]="viewOnly"
      [tooltip]="tooltips.customizationChange"
      [programmaticallyChangeValue]="blueprint?.customizationChange"
      (clicked)="smartPrintOptionChanged.emit('customizationChange')">
    </app-list-item-checkbox>

    <app-list-item-checkbox
      *rxIf="viewModel.showMenuChangeCheckbox$"
      [label]="'Menu Changes'"
      [inputName]="'menuChange'"
      [disabled]="viewOnly"
      [tooltip]="tooltips.menuChange"
      [programmaticallyChangeValue]="blueprint?.menuChange"
      (clicked)="smartPrintOptionChanged.emit('menuChange')">
    </app-list-item-checkbox>
  </app-list-item-checkboxes>
</lib-reactive-form-group>
