import { Component, Input } from '@angular/core';
import { DisplayPreviewViewModel } from './display-preview-view-model';
import { DisplayableItemPreviewComponent } from '../displayable-item-preview/displayable-item-preview.component';
import { Display } from '../../../../../../models/display/dto/display';

@Component({
  selector: 'app-display-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: ['../displayable-item-preview/displayable-item-preview.component.scss'],
  providers: [DisplayPreviewViewModel]
})
export class DisplayPreviewComponent extends DisplayableItemPreviewComponent {

  @Input() override displayableItem: Display;

  constructor(
    public override viewModel: DisplayPreviewViewModel
  ) {
    super(viewModel);
  }

}
