import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMediaSectionFormComponent } from '../edit-media-section-form/edit-media-section-form.component';
import { EditTitleSectionFormViewModel } from './edit-title-section-form-view-model';

@Component({
  selector: 'app-edit-title-section-form',
  templateUrl: './edit-title-section-form.component.html',
  styleUrls: ['./edit-title-section-form.component.scss'],
  providers: [EditTitleSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTitleSectionFormComponent extends EditMediaSectionFormComponent {

  constructor(
    public override viewModel: EditTitleSectionFormViewModel
  ) {
    super(viewModel);
  }

}
