<!-- Company Logo -->
<div class="row">
  <div class="col-4">
    <p class="bs-semi-bold f16px">{{ rowTitle }}</p>
    <p class="bs-regular f14px">{{ rowDescription }}</p>
  </div>
  <div class="col-4 position-relative">
    <!-- Form -->
    <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>
    <ng-container *ngIf="!(isLoading$ | async)">
      <app-upload-asset
        #uploadContainer
        *ngIf="!logo && !logoToUpload"
        [allowImage]="true"
        [allowVideo]="false"
        [id]="id"
        [maxAssets]="1"
        [parentHandler]="this">
      </app-upload-asset>
      <app-asset
        *ngIf="!!logo"
        class="logo"
        [scaleFit]="true"
        [cachePolicy]="ClientTypes.CachePolicy.Persistent"
        [styleOverrides]="{
          'background-color':
            logoType === ClientTypes.LogoType.Alternative ? ClientTypes.ColorUtils.BUDSENSE_BLACK_COLOR : ''
        }"
        [asset]="logo">
      </app-asset>
      <img
        *ngIf="!!logoToUpload && !logo"
        [ngClass]="{ 'black-background': logoType === ClientTypes.LogoType.Alternative }"
        [src]="logoToUpload?.url"
        alt="logo"
        class="logo" />
      <div fxLayout="row" [style.gap.rem]="0.5">
        <lib-button-neutral *ngIf="logo || logoToUpload" (buttonClicked)="openReplaceLogoModal()">
          Replace Image
        </lib-button-neutral>
        <lib-button-destructive *ngIf="logo" (buttonClicked)="openDeleteLogoModal()">
          Delete Image
        </lib-button-destructive>
      </div>
    </ng-container>
  </div>
  <div class="col-4"></div>
</div>
