import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationPickerViewModel } from './location-picker-view-model';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [LocationPickerViewModel]
})
export class LocationPickerComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: LocationPickerViewModel
  ) {
  }

  cancel() {
    this.activeModal.close([null]);
  }

}
