import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionFooterComponent } from '../../../../menu/components/edit-menu-section/edit-menu-section-footer/edit-menu-section-footer.component';

@Component({
  selector: 'app-edit-template-menu-section-footer',
  templateUrl: './edit-template-menu-section-footer.component.html',
  styleUrls: [
    './edit-template-menu-section-footer.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMenuSectionFooterComponent extends EditMenuSectionFooterComponent {
}
