import { ClientTypeUtils } from '../../utils/client-type-utils';
import { FoundationBaseComponent } from '@mobilefirstdev/base-angular';
import { Component } from '@angular/core';

@Component({ selector: 'app-base-component', template: ''})
export abstract class BaseComponent extends FoundationBaseComponent {

  ClientTypes = ClientTypeUtils;

}
