import { Injectable } from '@angular/core';
import { BaseService } from '@mobilefirstdev/base-angular';
import { Subject } from 'rxjs';
import { Menu } from '../models/menu/dto/menu';
import { MenuTemplate } from '../models/template/dto/menu-template';

// Provided by Logged in Scope
@Injectable()
export class ChangesRequiredForPreviewService extends BaseService {

  constructor() {
    super();
  }

  private readonly _removePreviewForAllLocations = new Subject<Menu|MenuTemplate>();
  public readonly removePreviewForAllLocations$ = this._removePreviewForAllLocations as Subject<Menu|MenuTemplate>;
  removePreviewForAllLocations = (menu: Menu|MenuTemplate) => this._removePreviewForAllLocations.next(menu);

  private readonly _removeAllLocationPreviews = new Subject<number>();
  public readonly removeAllLocationPreviews$ = this._removeAllLocationPreviews as Subject<number>;
  removeAllLocationPreviews = (locId: number) => this._removeAllLocationPreviews.next(locId);

}
