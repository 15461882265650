<div class="row edit-collection-section">
  <div class="col-4">
    <div class="section-title">Template Collection Details</div>
  </div>
  <div class="col-8">
    <app-message-banner [message]="viewModel.bannerMessage$ | async"></app-message-banner>
    <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="bindTo">
      <div class="collection-details-form">
        <div>
          <lib-reactive-form-text
            fxFlex
            [inputName]="'collectionName'"
            [required]="true"
            [bindingProperty]="'name'"
            [placeholder]="'Enter the template collection name'"
            [maxLength]="128"
            [label]="'Template Collection Name'">
          </lib-reactive-form-text>
        </div>
        <div class="d-flex flex-align-center" [style.gap.rem]="0.5">
          <lib-reactive-form-drop-down
            fxFlex
            [hidden]="viewModel.creatingNewTag$ | async"
            [bindOnSubmit]="!(viewModel.creatingNewTag$ | async)"
            [inputName]="'tags'"
            [label]="'Tag'"
            [clearable]="true"
            [placeholder]="'Select a tag for this collection.'"
            [inlineLabel]="false"
            [disabled]="viewModel.creatingNewTag$ | async"
            [required]="false"
            [dropdowns]="viewModel.tags$ | async"
            [bindingProperty]="'tag'">
          </lib-reactive-form-drop-down>

          <lib-reactive-form-text
            fxFlex
            [hidden]="!(viewModel.creatingNewTag$ | async)"
            [bindOnSubmit]="viewModel.creatingNewTag$ | async"
            [inputName]="'newTag'"
            [label]="'New Tag'"
            [placeholder]="'Enter tag name.'"
            [inlineLabel]="false"
            [required]="false"
            [minLength]="1"
            [maxLength]="128"
            [disabled]="!(viewModel.creatingNewTag$ | async)"
            [bindingProperty]="'tag'">
          </lib-reactive-form-text>

          <lib-button-primary [style.margin-left.rem]="0.5" (buttonClicked)="viewModel.toggleNewTagInput()">
            {{ (viewModel.creatingNewTag$ | async) ? 'Existing' : 'New' }}
          </lib-button-primary>
        </div>
        <div>
          <lib-reactive-form-drop-down
            fxFlex
            [inputName]="'previewSize'"
            [required]="true"
            [dropdowns]="viewModel.displaySizeDropDowns$ | async"
            [bindingProperty]="'displaySize.name'"
            [label]="'Preview Size'">
          </lib-reactive-form-drop-down>
        </div>
        <div>
          <lib-reactive-form-drop-down
            fxFlex
            [inputName]="'previewOrientation'"
            [required]="true"
            [dropdowns]="viewModel.orientationDropDowns$ | async"
            [bindingProperty]="'displaySize.orientation'"
            [label]="'Preview Orientation'">
          </lib-reactive-form-drop-down>
        </div>
      </div>
    </lib-reactive-form-group>
  </div>
</div>
