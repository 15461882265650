import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { CannabinoidsPreviewViewModel } from './cannabinoids-preview-view-model';

@Component({
  selector: 'app-cannabinoids-preview',
  templateUrl: './cannabinoids-preview.component.html',
  styleUrls: ['./cannabinoids-preview.component.scss'],
  providers: [CannabinoidsPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CannabinoidsPreviewComponent extends BaseComponent {

  constructor(
    public viewModel: CannabinoidsPreviewViewModel
  ) {
    super();
  }

}
