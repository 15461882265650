import { Injectable, Injector } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { ModalTemplateViewProvinceLocations } from '../../../../../modals/modal-template-view-province-locations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '../../../../../models/company/dto/location';
import { ModalTemplateAddEditRequiredLocations } from '../../../../../modals/modal-template-add-edit-required-locations';

@Injectable()
export class EditTemplateLocationsSectionViewModel extends BaseViewModel {

  constructor(
    protected locationDomainModel: LocationDomainModel,
    protected ngbModal: NgbModal,
    protected injector: Injector
  ) {
    super();
  }

  public readonly allLocations$ = this.locationDomainModel.allLocations$;

  private readonly _template = new BehaviorSubject<MenuTemplate>(null);
  public readonly template$ = this._template as Observable<MenuTemplate>;
  connectToTemplate = (template: MenuTemplate) => this._template.next(template);

  private readonly _changeRequiredTemplateIds = new BehaviorSubject<number[]>(undefined);
  public readonly changeRequiredTemplateIds$ = this._changeRequiredTemplateIds.pipe(distinctUntilChanged());
  public changeRequiredTemplateIds = (updatedRequiredTemplateIds: number[]) => {
    this._changeRequiredTemplateIds.next(updatedRequiredTemplateIds);
  };

  templateStatus$ = this.template$.pipe(
    map(template => template?.status),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly originalRequiredLocationIds$ = this.template$.pipe(
    map(template => template?.requiredLocationIds ?? []),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly originalRequiredLocationsGroupedByProvince$ = combineLatest([
    this.originalRequiredLocationIds$,
    this.allLocations$,
  ]).pipe(
    map(Location.groupLocationsByProvince),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly requiredLocationIds$ = combineLatest([
    this.changeRequiredTemplateIds$,
    this.originalRequiredLocationIds$
  ]).pipe(
    map(([updated, original]) => (!!updated ? updated : original)),
  );
  public readonly hasRequiredLocationIds$ = this.requiredLocationIds$.pipe(map(ids => ids?.length > 0));
  public readonly requiredLocationsGroupedByProvince$ = combineLatest([
    this.requiredLocationIds$,
    this.allLocations$,
  ]).pipe(map(Location.groupLocationsByProvince));

  public readonly activeLocationIds$ = combineLatest([
    this.template$.pipe(map(template => template?.activeLocationIds ?? [])),
    this.requiredLocationIds$,
  ]).pipe(
    map(([active, required]) => (active ?? []).concat(required ?? []).unique())
  );
  public readonly hasActiveLocationIds$ = this.activeLocationIds$.pipe(map(ids => ids?.length > 0));
  public readonly activeLocationsGroupedByProvince$ = combineLatest([
    this.activeLocationIds$,
    this.allLocations$,
  ]).pipe(map(Location.groupLocationsByProvince));

  provinceClicked(clickEvent: { [provinceName: string]: Location[] }) {
    combineLatest([
      this.template$,
      this.requiredLocationIds$
    ]).once(([template, requiredLocationIds]) => {
      const [province, locations] = Object.entries(clickEvent)?.firstOrNull() || [null, null];
      ModalTemplateViewProvinceLocations.open(
        this.ngbModal,
        this.injector,
        template,
        requiredLocationIds,
        province,
        locations
      );
    });
  }

  addOrEditRequiredLocations() {
    combineLatest([
      this.template$,
      this.requiredLocationIds$
    ]).once(([template, requiredLocationIds]) => {
      ModalTemplateAddEditRequiredLocations.open(
        this.ngbModal,
        this.injector,
        template,
        requiredLocationIds,
        this.changeRequiredTemplateIds
      );
    });
  }

}
