<div class="look-ahead-list" fxLayout="column">
  <app-look-ahead-item
    *ngFor="let item of lookAheadItems; let index = index"
    [item]="item"
    [selected]="this.selectedPosition === index"
    [disabled]="item.lookAheadDisabled()"
    [lookAheadProperties]="lookAheadProperties"
    [lookAheadSeparator]="lookAheadSeparator"
    (lookAheadItemClicked)="lookAheadItemClicked.emit($event)">
  </app-look-ahead-item>
</div>
