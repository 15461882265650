import { TypeDefinition } from './type-definition';

export enum SectionColumnConfigEmptyData {
  NotSet = ''
}

export enum SectionColumnConfigAssetData {
  Contain = 'Contain',
  Cover = 'Cover',
}

export enum SectionColumnConfigSecondaryPricingData {
  SecondaryPrice = 'SecondaryPrice',
  PricePerUOM = 'PricePerUOM',
  OriginalPrice = 'OriginalPrice',
  SaleOriginalPrice = 'SaleOriginalPrice',
  OriginalAndSalePrice = 'OriginalAndSalePrice',
  TaxesInPrice = 'TaxesInPrice',
  TaxesInRoundedPrice = 'TaxesInRoundedPrice',
  PreTaxPrice = 'PreTaxPrice',
}

export enum SectionColumnConfigStrainTypeData {
  StrainTypeSymbol = 'StrainTypeSymbol',
  StrainTypeWord = 'StrainTypeWord',
}

export type SectionColumnConfigDataValue = SectionColumnConfigEmptyData
  | SectionColumnConfigAssetData
  | SectionColumnConfigSecondaryPricingData
  | SectionColumnConfigStrainTypeData;

export class SectionColumnConfigDataValueType extends TypeDefinition {

  override value: SectionColumnConfigDataValue;

  public getSelectionInformationDescription(): string {
    return this.description;
  }

}
