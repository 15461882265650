import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StackSizePickerViewModel } from './stack-size-picker-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Menu } from '../../../../../models/menu/dto/menu';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { DefaultPrintCardSizeType } from '../../../../../models/utils/dto/default-print-card-size-type';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { DefaultPrintStackSize } from '../../../../../models/enum/dto/default-print-stack-size';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';

@Component({
  selector: 'app-card-stack-size-picker',
  templateUrl: './stack-size-picker.component.html',
  styleUrls: ['./stack-size-picker.component.scss'],
  providers: [StackSizePickerViewModel]
})
export class StackSizePickerComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: StackSizePickerViewModel
  ) {
    super();
  }

  @Input() menu: Menu|MenuTemplate;
  @Input() menuType: MenuType;
  @Input() cardStackSizesToShow: DefaultPrintStackSize[];
  @Output() selectedStackSize = new EventEmitter<DefaultPrintStackSize>();

  override setupBindings() {
    this.listenToSelectedSize();
  }

  private listenToSelectedSize(): void {
    this.viewModel.selectedSize$.subscribeWhileAlive({
      owner: this,
      next: selectedSize => this.selectedStackSize.emit(selectedSize)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) {
      this.viewModel.connectToMenu(this.menu);
      this.viewModel.connectToSelectedSize(this.menu?.metadata?.printCardSize);
    }
    if (changes.cardStackSizesToShow) this.viewModel.connectToCardStackSizesToShow(this.cardStackSizesToShow);
  }

  stackSizeSelected(card: Card) {
    const selectedCardStackSizeType = card?.data as DefaultPrintCardSizeType;
    this.viewModel.connectToSelectedSize(selectedCardStackSizeType?.getSelectionValue());
  }

}
