import { Component, Injector } from '@angular/core';
import { ActionService } from '../../../../../../services/action.service';
import { DigitalMenusContainerViewModel } from './digital-menus-container-view-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayableContentContainerComponent } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component';
import { DisplayableContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container-view-model';

@Component({
  selector: 'app-digital-menus-container',
  templateUrl: '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component.html',
  styleUrls: [
    '../../../../../shared/components/displayable-content/displayable-content-container/displayable-container.component.scss',
    './digital-menus-container.component.scss'
  ],
  providers: [{ provide: DisplayableContentContainerViewModel, useExisting: DigitalMenusContainerViewModel }]
})
export class DigitalMenusContainerComponent extends DisplayableContentContainerComponent {

  constructor(
    public override viewModel: DigitalMenusContainerViewModel, // Provided by Logged In Scope
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector,
    actionService: ActionService
  ) {
    super(viewModel, navigationService, ngbModal, injector, actionService);
  }

  override setupBindings() {
    super.setupBindings();
    const createMenuFlag = this.actionService.createMenuFlag.consumeFlag(_ => this.viewModel.toggleAddNewMenu());
    this.pushSub(createMenuFlag);
  }

}
