import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-displayable-content-layout',
  templateUrl: './displayable-content-layout.component.html',
  styleUrls: ['./displayable-content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayableContentLayoutComponent {

  @Input() title: string;
  @Input() locationText: string;

}
