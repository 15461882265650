<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">Add New Display</div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div class="modal-divider mb-4">
      <h5 class="mt-4 mb-4"><b>Display Info</b></h5>
      <lib-reactive-form-group
        #newDisplayInput
        class="mb-4"
        [bindTo]="newDisplay"
        (canSubmit$)="canSubmitForm$.next($event)"
        (formSubmitted$)="viewModel.createNewDisplay($event)">
        <lib-reactive-form-column-layout [nColumns]="2">
          <lib-reactive-form-text
            [inputName]="'name'"
            [label]="'Display Name'"
            [placeholder]="'Enter the display name'"
            [bindingProperty]="'name'"
            [required]="true"
            [minLength]="1"
            [maxLength]="128">
          </lib-reactive-form-text>

          <lib-reactive-form-drop-down
            [inputName]="'displaySize'"
            [label]="'Display Size'"
            [placeholder]="'Select the size of this display'"
            [bindingProperty]="'displaySize.name'"
            [required]="true"
            [dropdowns]="defaultSizes$ | async"></lib-reactive-form-drop-down>

          <lib-reactive-form-drop-down
            [inputName]="'orientation'"
            [label]="'Orientation'"
            [placeholder]="'Orientation'"
            [bindingProperty]="'displaySize.orientation'"
            [required]="true"
            [tooltip]="'Portrait is a 90º clockwise rotation. Reverse portrait is a 90º counterclockwise rotation.'"
            [dropdowns]="orientations$ | async"></lib-reactive-form-drop-down>

          <lib-reactive-form-drop-down
            [inputName]="'defaultMenu'"
            [label]="'Default Menu'"
            [placeholder]="'Select an active menu for this display'"
            [bindingProperty]="'defaultMenu'"
            [required]="true"
            [dropdowns]="viewModel.menuOptions$ | async"></lib-reactive-form-drop-down>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="newDisplayInput.submitForm()"
      [disabled]="!(canSubmitForm$ | async)"
      [ngClass]="{ disabled: !(canSubmitForm$ | async) }"
      class="bs-button preferred-button">
      Save Display
    </button>
  </div>
</div>
