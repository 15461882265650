import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { HelpNavItem } from '../models/help-nav-item';

@Pipe({
  name: 'isHelpNavItem'
})
export class IsHelpNavItemPipe implements PipeTransform {

  transform(value: NavItem): boolean {
    return value instanceof HelpNavItem;
  }

}
