import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { PrintTheme } from '../../../models/enum/dto/theme.enum';

@Pipe({
  name: 'isPlantlifeMenu'
})
export class IsPlantlifeMenuPipe implements PipeTransform {

  transform(menu: Menu): boolean {
    return menu?.theme === PrintTheme.Plantlife;
  }

}
