import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseReactiveFormComponent } from '@mobilefirstdev/reactive-form';
import { Theme } from '../../../../../../../../models/menu/dto/theme';
import { Menu } from '../../../../../../../../models/menu/dto/menu';
import { CardType } from '../../../../../../../../models/utils/dto/card-type-definition';

@Component({
  selector: 'app-edit-drive-thru-products-form',
  templateUrl: './edit-drive-thru-products-form.component.html',
  styleUrls: ['./edit-drive-thru-products-form.component.scss']
})
export class EditDriveThruProductsFormComponent extends BaseReactiveFormComponent {

  @Input() menu: Menu;
  @Input() theme: Theme;
  @Input() isTemplatedMenu: boolean = false;
  @Output() cardTypeChanged = new EventEmitter<CardType>(true);
  @Output() cardStartAtChanged = new EventEmitter<string>(true);

  constructor() {
    super();
  }

  parseNumberAsString = (val: string) => Number.parseInt(val, 10).toString();
  parseDecimalAsString = (val: string) => Number.parseFloat(val).toString();

}
