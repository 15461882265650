import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { CardType } from '../../../../../models/utils/dto/card-type-definition';
import { MenuSubtype } from '../../../../../models/enum/dto/menu-subtype';

@Component({
  selector: 'app-reactive-form-card-type',
  templateUrl: './reactive-form-card-type.component.html',
  styleUrls: ['./reactive-form-card-type.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => ReactiveFormCardTypeComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReactiveFormCardTypeComponent)
    }
  ],
})
export class ReactiveFormCardTypeComponent extends ReactiveFormItemComponent implements ControlValueAccessor {

  @Input() menuType: MenuSubtype;
  @Input() isTemplatedMenu: boolean = false;

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  getValidators(): ValidatorFn[] {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    return [...validators, ...this.customValidators];
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  writeValue(value: any): void {
  }

  cardChanged(cardType: CardType) {
    this.getSelfAsFormItem().patchValue(cardType);
    this.markAsDirty();
    this.valueChanged.emit(this.getMyValue());
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
