<div appThrottleClick class="province-location-mapping-container clickable" (throttleClick)="clicked()">
  <div>
    <div class="province">
      {{ province }}
      <ng-container *ngIf="collectionIsPublished">
        <span
          *ngIf="
            locationDisplays
              | collectionChangesString : originalRequiredDisplayIds : updatedRequiredDisplayIds as changesString
          ">
          {{ changesString }}</span
        >
      </ng-container>
      <span *ngIf="locationDisplays | numberOfNewDisplaysString as newDisplayString"> {{ newDisplayString }}</span>
    </div>
    <div class="n-locations">{{ viewModel.locationArray$ | async | numberOfLocations }}</div>
  </div>
  <img [src]="'assets/icons/dark/outline/chevron-right.svg'" alt="" />
</div>
