import { Orderable } from '../protocols/orderable';

export abstract class CompactItem implements Orderable {

  public id: string;
  public name: string;
  public displayPriority: number;
  public orderableIconSrc: string;

  getOrderValue(): number {
    return this.displayPriority;
  }

  getOrderableTitle(): string {
    return this.name;
  }

  getOrderableUniqueId(): string {
    return this.id;
  }

  setOrderableValue(val: number) {
    this.displayPriority = val;
  }

  abstract onDeserialize();

}
