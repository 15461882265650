import { Injectable, Injector } from '@angular/core';
import { EditTemplateMarketingMenuViewModel } from '../edit-template-marketing-menu-view-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditSmartPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-view-model';

@Injectable()
export class EditTemplateMarketingSmartPlaylistMenuViewModel extends EditTemplateMarketingMenuViewModel {

  constructor(
    protected override editMenuViewModel: EditSmartPlaylistMenuViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editMenuViewModel, templateDomainModel, locationDomainModel, ngbModal, injector);
  }

  getEditMenuViewModel(): EditSmartPlaylistMenuViewModel {
    return this.editMenuViewModel;
  }

}
