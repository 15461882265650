<div
  #container
  matRipple
  appThrottleClick
  class="look-ahead-item"
  [matRippleDisabled]="disabled"
  [ngClass]="{ selected: selected && !disabled, disabled: disabled }"
  [ngbTooltip]="disabled ? item.lookAheadDisabledMessage() : null"
  (throttleClick)="!disabled ? lookAheadItemClicked.emit(item) : null">
  {{ getLookAheadString() }}
</div>
