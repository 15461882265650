import { Pipe, PipeTransform } from '@angular/core';
import { NavSubItem } from '../models/nav-sub-item';
import { LocationsSubNavItem } from '../models/locations-sub-nav-item';

@Pipe({
  name: 'isLocationsNavSubItem'
})
export class IsLocationsNavSubItemPipe implements PipeTransform {

  transform(value: NavSubItem): boolean {
    return value instanceof LocationsSubNavItem;
  }

}
