import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-locked',
  templateUrl: './label-locked.component.html',
  styleUrls: ['./label-locked.component.scss']
})
export class LabelLockedComponent {

  @Input() heightAndWidth: string = '1rem';

}
