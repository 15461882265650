import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { SectionCreationType } from '../../../../../../../models/enum/shared/section-creation-type.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-section-creation-method',
  templateUrl: './section-creation-method.component.html',
  styleUrls: ['./section-creation-method.component.scss']
})
export class SectionCreationMethodComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() paddingLeft: string = '1.5rem';
  @Input() paddingRight: string = '1.5rem';
  @Input() titleFontSize: string = '0.75rem';
  @Input() subtitleFontSize: string = '0.75rem';
  @Output() selectedCreationType = new EventEmitter<SectionCreationType>();

  public creationTypes$ = new BehaviorSubject<any[]>(this.getSectionCreationTypes());

  getSectionCreationTypes(): any[] {
    const list = [];
    list.push({
      type: SectionCreationType.FromBlueprint,
      title: 'Create from blueprint',
      subtitle: 'Use a BudSense Blueprint section to create a new section'
    });
    list.push({
      type: SectionCreationType.New,
      title: 'Create new section',
      subtitle: 'Start from scratch to create a new section'
    });
    return list;
  }

}
