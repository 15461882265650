import { CompactMenu } from '../../menu/dto/compact-menu';
import { TemplateCollection } from './template-collection';
import { CompactItem } from '../../shared/compact-item';

export class CompactTemplateCollection extends CompactItem {

  public compactMenus: CompactMenu[];
  public requiredDisplayIds: string[];
  public override orderableIconSrc = 'assets/icons/dark/outline/template-collection.svg';

  constructor(collection: TemplateCollection, compactMenus: CompactMenu[]) {
    super();
    this.id = collection.id;
    this.name = collection.name;
    this.compactMenus = compactMenus;
    this.requiredDisplayIds = collection.requiredDisplayIds;
  }

  onDeserialize() {
    this.compactMenus = window?.injector?.Deserialize?.arrayOf(CompactMenu, this.compactMenus);
    this.requiredDisplayIds = Array.from(this.requiredDisplayIds) ?? [];
  }

}
