<app-collection-displays-modal-header [title]="'Template Collection Displays'" [showClearButton]="false">
</app-collection-displays-modal-header>

<div class="sticky-body-container" [style.padding-inline]="'unset'">
  <app-arrow-button
    *ngFor="let editMethod of viewModel.editMethods$ | async"
    [title]="editMethod?.title"
    [subtitle]="editMethod?.subtitle"
    [titleFontSize]="'0.75rem'"
    [subtitleFontSize]="'0.75rem'"
    [titleFontWeight]="'500'"
    [subtitleFontWeight]="'400'"
    (clicked)="editMethodSelected.emit(editMethod.type)">
  </app-arrow-button>
</div>

<app-go-back-modal-footer (cancel)="cancel.emit()" [showBackButton]="false" [showPrimaryButton]="false">
</app-go-back-modal-footer>
