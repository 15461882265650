<span *ngIf="viewModel.isPrintableMenu$ | async" class="mat-content">
  <mat-panel-title
    class="mat-expansion-panel-header-title bs-medium f14px mt-13px mb-13px"
    [style.margin-bottom.rem]="1">
    Printable PDF
  </mat-panel-title>
</span>

<div *ngIf="viewModel.isPrintableMenu$ | async" class="pdf-buttons">
  <lib-button-neutral
    [hidden]="!(viewModel.showUserDownloadableMenuDataButton$ | async)"
    [iconSrc]="'assets/icons/dark/outline/printer.svg'"
    [disabled]="!(viewModel.allowLiveView$ | async)"
    (buttonClicked)="viewModel.printPreviewClicked()">
    Preview
  </lib-button-neutral>

  <lib-button-neutral
    [hidden]="!(viewModel.showUserDownloadableMenuDataButton$ | async)"
    [iconSrc]="'assets/icons/dark/outline/download.svg'"
    (buttonClicked)="viewModel.userDownloadableDataClicked.next()">
    Download ({{ viewModel.activeUserDownloadableMenuDataTimeLabel$ | async }})
  </lib-button-neutral>

  <lib-button-neutral
    [loadingOptions]="viewModel.userDownloadableDataLoadingOpts$ | async"
    (buttonClicked)="viewModel.refreshUserDownloadableMenuData()">
    {{ viewModel.generatePdfText$ | push }}
  </lib-button-neutral>
</div>
