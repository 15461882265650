import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popper-selection',
  templateUrl: './popper-selection.component.html',
  styleUrls: ['./popper-selection.component.scss']
})
export class PopperSelectionComponent {

  @Input() data: any;
  @Input() disabled: boolean = false;
  @Output() selected = new EventEmitter<any>(true);

}
