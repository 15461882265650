import { Pipe, PipeTransform } from '@angular/core';
import { Display } from '../../../models/display/dto/display';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'displayMarkedAsRequired'
})
export class DisplayMarkedAsRequiredPipe implements PipeTransform {

  transform(display: Display, requiredDisplayIds$: Observable<string[]>): Observable<boolean> {
    return requiredDisplayIds$.pipe(
      map((requiredIds) => requiredIds?.includes(display?.id))
    );
  }

}
