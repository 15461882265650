import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'replaceMenuWithTemplate'
})
export class ReplaceMenuWithTemplatePipe implements PipeTransform {

  transform(input: string, templateMode: boolean): string {
    if (templateMode) {
      input = StringUtils.replaceMenuWithTemplate(input);
    }
    return input;
  }

}
