import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { Variant } from '../../../../../../models/product/dto/variant';
import { map, shareReplay } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { PriceUtils } from '../../../../../../utils/price-utils';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { PriceFormat } from '../../../../../../models/utils/dto/price-format-type';

@Injectable()
export class DriveThruProductFormViewModel extends BaseViewModel {

  private _hideDesc = new BehaviorSubject<boolean>(false);
  public hideDesc$ = this._hideDesc as Observable<boolean>;

  private _descOverride = new BehaviorSubject<string>(null);
  public descOverride$ = this._descOverride as Observable<string>;

  private _menu = new BehaviorSubject<Menu|null>(null);
  public menu$: Observable<Menu|null> = this._menu as Observable<Menu>;

  private _variant = new BehaviorSubject<Variant|null>(null);
  public variant$ = this._variant as Observable<Variant|null>;

  public priceFormat$ = this.locationDomainModel.priceFormat$;
  public locationId$ = this.locationDomainModel.locationId$;
  public locationName$ = this.locationDomainModel.locationName$;
  public companyName$ = this.companyDomainModel.companyName$;

  public pricePlaceHolder$ = combineLatest(([
    this.menu$,
    this.variant$,
    this.priceFormat$
  ])).pipe(
    map(([menu, variant, priceStream]) => {
      const visiblePrice = variant?.getVisiblePrice(menu?.locationId, menu?.companyId, priceStream, false);
      const formattedPrice = PriceUtils.formatPrice(visiblePrice);
      return !formattedPrice ? 'Sale Price Override' : formattedPrice;
    })
  );
  private priceCalculationData$ = combineLatest([
    this.variant$.notNull(),
    this.priceFormat$,
    this.locationId$,
    this.locationName$,
    this.companyName$
  ]).pipe(shareReplay({bufferSize: 1, refCount: true}));

  public variantSalePrice$ = this.priceCalculationData$.pipe(
    map(([v, priceStream, locId, locationName, companyName]) => {
      const [price, _, isSale] = this.variantPriceTuple(v, undefined, priceStream, locId, locationName, companyName);
      return isSale ? price : null;
    })
  );
  public variantRegularPrice$ = this.priceCalculationData$.pipe(
    map(([v, priceStream, locationId, locationName, companyName]) => {
      const [price] = this.variantPriceTuple(v, true, priceStream, locationId, locationName, companyName);
      return price || null;
    })
  );
  public variantRegularPriceInfo$ = this.priceCalculationData$.pipe(
    map(([v, priceStream, locationId, locationName, companyName]) => {
      const [_, priceText] = this.variantPriceTuple(v, true, priceStream, locationId, locationName, companyName);
      return priceText || null;
    })
  );
  public variantSalePriceInfo$ = this.priceCalculationData$.pipe(
    map(([v, priceStream, locId, locName, companyName]) => {
      const [_, priceText, isSale] = this.variantPriceTuple(v, undefined, priceStream, locId, locName, companyName);
      return isSale ? priceText : null;
    })
  );
  public desc$ = this.variant$.pipe(map(variant => variant.description));
  public programmaticallySetDesc$ = combineLatest([
    this.desc$,
    this.descOverride$,
    this.hideDesc$,
  ]).pipe(
    map(([desc, descOverride, hideDesc]) => {
      if (hideDesc) {
        return '--';
      } else if (!hideDesc) {
        if (!!descOverride && descOverride !== ' ') {
          return descOverride;
        } else {
          return '';
        }
      }
    })
  );
  public descPlaceHolder$ = this.variant$.pipe(map(variant => {
    return !variant?.description ? 'Description Override' : variant?.description;
  }));

  constructor(
    private locationDomainModel: LocationDomainModel,
    public companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  public setHideDesc = (hide: boolean) => this._hideDesc.next(hide);
  public setDescOverride = (desc: string) => this._descOverride.next(desc);
  public setMenu = (menu: Menu|null) => this._menu.next(menu);
  public setVariant = (variant: Variant|null) => this._variant.next(variant);
  public descValueParser = (val: any) => {
    if ( val === '--') {
      return ' ';
    } else if (val === ' ') {
      return '--';
    } else {
      return val;
    }
  };

  /**
   * returns price, priceText, isSale
   */
  variantPriceTuple(
    variant: Variant,
    ignoreSalePrice: boolean = false,
    priceFormat: PriceFormat = PriceFormat.Default,
    locationId: number,
    locName: string,
    compName: string
  ): [string, string, boolean] {
    return variant?.getFormattedPrice(priceFormat, locationId, locName, compName, ignoreSalePrice);
  }

}
