import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelectedId'
})
export class IsSelectedIdPipe implements PipeTransform {

  transform(value: { id: string }, selectedId: string): boolean {
    return value?.id === selectedId;
  }

}
