import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appLabelInflatorContainer]'
})
export class LabelInflatorContainerDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
