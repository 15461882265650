<app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">Duplicate Smart Filter</div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container mt-16px">
    <lib-reactive-form-group #formGroup [bindTo]="smartFilter$ | async" (formSubmitted$)="duplicateSmartFilter($event)">
      <lib-reactive-form-drop-down
        [inputName]="'locationId'"
        [label]="'Location'"
        [placeholder]="'Select a Location'"
        [inlineLabel]="false"
        [required]="true"
        [dropdowns]="locations$ | async"
        [bindingProperty]="'locationId'">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [inputName]="'name'"
        [label]="'Smart Filter Name'"
        [placeholder]="'Enter your Smart Filter name'"
        [inlineLabel]="false"
        [required]="true"
        [maxLength]="128"
        [disabled]="false"
        [bindingProperty]="'name'">
      </lib-reactive-form-text>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="formGroup.submitForm()"
      [disabled]="formGroup.hasErrors$ | async"
      [ngClass]="{ disabled: formGroup.hasErrors$ | async }"
      class="bs-button preferred-button">
      Duplicate Smart Filter
    </button>
  </div>
</div>
