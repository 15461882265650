import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../../models/company/dto/location';

@Pipe({
  name: 'numberOfRemovedRequiredLocations'
})
export class NumberOfRemovedRequiredLocationsPipe implements PipeTransform {

  transform(original: Location[], updated: Location[]): string {
    const originalIds = original?.map(location => location?.id)?.filterNulls();
    const updatedIds = updated?.map(location => location?.id)?.filterNulls();
    const removedLocations = originalIds?.filter(id => !updatedIds?.includes(id));
    return removedLocations?.length > 0
      ? `(${removedLocations?.length} removed required location)`
        .pluralizer()
        .addRule({ listConnection: removedLocations, useApostrophe: false, word: 'location' })
        .pluralize()
      : '';
  }

}
