import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duplicateButtonDisabledTooltip'
})
export class DuplicateButtonDisabledTooltipPipe implements PipeTransform {

  transform(formPristine: boolean): string {
    if (!formPristine) {
      return 'Changes must be saved before duplication.';
    }
    return null;
  }

}
