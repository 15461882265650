import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMarketingMenuBodyComponent } from '../../edit-marketing-menu-body/edit-marketing-menu-body.component';
import { EditFeaturedProductMenuViewModel } from '../edit-featured-product-menu-view-model';

@Component({
  selector: 'app-edit-featured-product-menu-body',
  templateUrl: './edit-featured-product-menu-body.component.html',
  styleUrls: ['./edit-featured-product-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedProductMenuBodyComponent extends EditMarketingMenuBodyComponent {

  constructor(
    public override viewModel: EditFeaturedProductMenuViewModel, // provided by parent component
  ) {
    super(viewModel);
  }

}
