import { Pipe, PipeTransform } from '@angular/core';
import { MenuType, MenuTypeDefinition } from '../../../models/utils/dto/menu-type-definition';

@Pipe({
  name: 'getSingleWordDefiningMenuType'
})
export class GetSingleWordDefiningMenuTypePipe implements PipeTransform {

  transform(menuType: MenuType): string {
    return MenuTypeDefinition.getSingleWordDefiningMenuType(menuType);
  }

}
