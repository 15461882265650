import { Pipe, PipeTransform } from '@angular/core';
import { VariantBadge } from '../../../../models/product/dto/variant-badge';
import { Observable } from 'rxjs';
import { DisplayAttribute } from '../../../../models/display/dto/display-attribute';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'inlineBadgePickerCheckIfBadgeIsSmart'
})
export class InlineBadgePickerCheckIfBadgeIsSmartPipe implements PipeTransform {

  transform(badge: VariantBadge, displayAttribute$: Observable<DisplayAttribute>): Observable<boolean> {
    return displayAttribute$.pipe(
      map(displayAttribute => displayAttribute?.smartBadgeIds?.includes(badge?.id))
    );
  }

}
