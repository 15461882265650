import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { EditMenuSectionProductsComponent } from '../../../../../edit-menu-section/edit-menu-section-products/edit-menu-section-products.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditFeaturedCategorySectionViewModel } from '../edit-featured-category-section-view-model';

@Component({
  selector: 'app-edit-featured-category-section-products',
  templateUrl: './../../../../../edit-menu-section/edit-menu-section-products/edit-menu-section-products.component.html',
  styleUrls: ['./edit-featured-category-section-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategorySectionProductsComponent extends EditMenuSectionProductsComponent {

  constructor(
    public override viewModel: EditFeaturedCategorySectionViewModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(viewModel, ngbModal, injector);
  }

}
