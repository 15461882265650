<div class="auth-wrapper">
  <div class="auth-brand-container">
    <div class="brand-wrapper">
      <div class="video-container">
        <video [muted]="true" [autoplay]="true" playsInline loop id="splash-video">
          <source [src]="'assets/img/account/bs-splash.mp4'" type="video/mp4" />
          <img [src]="'assets/img/account/auth-flow-background.jpg'" alt="fallback-image" />
        </video>
      </div>
      <div class="brand-logo-container">
        <img
          (click)="logoClicked()"
          alt="bs"
          class="brand-logo"
          src="../../../../../assets/logo/light/logo-filled.svg" />
      </div>
    </div>
    <div class="software-version">
      {{ viewModel.versionString }}
    </div>
  </div>
  <div class="auth-action-container">
    <!--  Sign In Flow  -->
    <app-sign-in *ngIf="viewModel.authFlow === ClientTypes.AuthFlow.SignIn"> </app-sign-in>
    <!--  Forgot Password Flow  -->
    <app-forgot-password *ngIf="viewModel.authFlow === ClientTypes.AuthFlow.ForgotPassword"> </app-forgot-password>
    <!--  Reset Password Flow  -->
    <app-reset-password *ngIf="viewModel.authFlow === ClientTypes.AuthFlow.ResetPassword"> </app-reset-password>
    <!--  New Password Flow  -->
    <app-new-password *ngIf="viewModel.authFlow === ClientTypes.AuthFlow.SetNewPassword"> </app-new-password>
  </div>
</div>
