export class OverflowInformationItem {

  public name: string;
  public description: string;
  public overflowStateType: string;

  constructor(name, desc: string, overflowStateType: string ) {
    this.name = name;
    this.description = desc;
    this.overflowStateType = overflowStateType;
  }

}
