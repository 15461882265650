import { Pipe, PipeTransform } from '@angular/core';
import { Display } from '../../../models/display/dto/display';

@Pipe({
  name: 'displayChangesString'
})
export class DisplayChangesStringPipe implements PipeTransform {

  transform(
    display: Display,
    originalRequiredIds: string[],
    updatedRequiredIds: string[],
    collectionIsPublished: boolean
  ): string {
    const isPendingDisplay = !display?.id;
    if (!collectionIsPublished && isPendingDisplay) return 'New on Publish';
    if (!collectionIsPublished) return '';
    if (collectionIsPublished && isPendingDisplay) return 'New';

    const displayWasAdded = updatedRequiredIds?.includes(display?.id) && !originalRequiredIds?.includes(display?.id);
    if (displayWasAdded) return 'Added';
    const displayWasRemoved = originalRequiredIds?.includes(display?.id) && !updatedRequiredIds?.includes(display?.id);
    if (displayWasRemoved) return 'Removed';
  }

}
