import { Injectable, Injector } from '@angular/core';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { ToastService } from '../../../services/toast-service';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { SectionColumnConfig } from '../../../models/menu/dto/section-column-config';
import { DefaultSectionColumnConfig } from '../../../models/menu/dto/default-section-column-config';
import { HydratedSection } from '../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { Theme } from '../../../models/menu/dto/theme';
import { ResetColumnConfigMode } from '../../../models/enum/dto/reset-column-config-mode.enum';
import { ConfirmationOptions } from '../../../models/shared/stylesheet/confirmation-options';
import { BsError } from '../../../models/shared/bs-error';
import { SectionColumnConfigKey } from '../../../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../../../models/utils/dto/section-column-config-default-state-type';
import { ModalConfirmation } from '../../../modals/modal-confirmation';
import { SectionTemplate } from '../../../models/template/dto/section-template';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';

interface ColumnChanges {
  key: SectionColumnConfigKey;
  defaultState: SectionColumnConfigDefaultState;
  columnWidth?: number;
}

interface ResetColumnConfig {
  resetMode: ResetColumnConfigMode;
  defaultColumnConfig: DefaultSectionColumnConfig;
}

export const NAME_COLUMN_DEFAULT_WIDTH = 25;

@Injectable()
export class ColumnOptionsViewModel extends BaseModalViewModel {

  constructor(
    protected toastService: ToastService,
    protected injector: Injector,
    protected menuDomainModel: MenuDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public mergeKey = 'column-options-form';

  private _bottomButtonPosition = new BehaviorSubject<string>('absolute');
  public bottomButtonPosition$ = this._bottomButtonPosition.pipe(distinctUntilChanged());
  connectToBottomButtonPosition = (position: string) => this._bottomButtonPosition.next(position);

  private _section: BehaviorSubject<HydratedSection> = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;
  connectToSection = (section: HydratedSection) => this._section.next(section);

  public sectionLayoutType$ = this.section$.pipe(
    map(section => section?.getLayoutType())
  );
  public sectionColumnConfig$ = this.section$.pipe(map(section => section?.columnConfig));
  public gridColumnCount$ = this.section$.pipe(
    map(section => section?.metadata?.gridColumnNames.split(',').length)
  );

  private _theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme as Observable<Theme>;
  public themeColumnConfig$ = this.theme$.pipe(map(theme => theme?.sectionColumnConfig));

  private _managingDefault: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public managingDefault$ = this._managingDefault as Observable<boolean>;

  private _defaultSectionConfig = new BehaviorSubject<DefaultSectionColumnConfig>(new DefaultSectionColumnConfig());
  public defaultSectionConfig$ = this._defaultSectionConfig as Observable<DefaultSectionColumnConfig>;

  private _columnChanges: BehaviorSubject<ColumnChanges> = new BehaviorSubject<ColumnChanges>(null);
  private columnChanges$ = this._columnChanges as Observable<ColumnChanges>;

  public message: string = 'By default, the product name column must be at least 25% and will expand '
    + 'to take up any additional available width.';

  private _columnConfigMap = new BehaviorSubject<Map<SectionColumnConfigKey, SectionColumnConfig>>(null);
  public columnConfigMap$ = this._columnConfigMap as Observable<Map<SectionColumnConfigKey, SectionColumnConfig>>;

  private _currentlySelectedTabIndex = new BehaviorSubject<number>(0);
  public currentlySelectedTabIndex$ = this._currentlySelectedTabIndex as Observable<number>;
  private _previouslySelectedTabId = new BehaviorSubject<number>(0);
  public previouslySelectedTabId$ = this._previouslySelectedTabId as Observable<number>;

  public listenToColumnConfigBeingManaged = combineLatest([
    this.managingDefault$,
    this.defaultSectionConfig$,
    this.sectionColumnConfig$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([managingDefault, defaultSectionConfig, sectionColumnConfig]) => {
      const configToManage = managingDefault ? defaultSectionConfig?.columnConfig : sectionColumnConfig;
      const newConfigMap = configToManage?.deepCopy() || new Map();
      this._columnConfigMap.next(newConfigMap);
    }
  });

  private _resetSubject = new Subject<ResetColumnConfig>();

  private columnConfigResetHandler = combineLatest([
    this.themeColumnConfig$,
    this._resetSubject.pipe(startWith<ResetColumnConfig>(null)),
  ]).subscribeWhileAlive({
    owner: this,
    next: ([themeColumnConfig, resetSubject]) => {
      const columnConfigMap = this._columnConfigMap.getValue();
      const resetColumnConfigMode = resetSubject?.resetMode;
      const defaultColumnConfigToSet = resetSubject?.defaultColumnConfig;
      if (!!resetColumnConfigMode) {
        switch (resetColumnConfigMode) {
          case ResetColumnConfigMode.ThemeDefault:
            themeColumnConfig?.forEach((_, key) => columnConfigMap?.set(key, null));
            break;
          case ResetColumnConfigMode.PreDefined:
            const defaultColumnConfigCopy = defaultColumnConfigToSet?.columnConfig?.deepCopy();
            defaultColumnConfigCopy?.forEach((columnConfig, key) => {
              const themeDefaults = themeColumnConfig.get(key);
              const themeMax = themeDefaults.maxColumnWidth;
              const themeMin = themeDefaults.minColumnWidth;
              if (columnConfig.columnWidth > themeMax) columnConfig.columnWidth = themeMax;
              if (columnConfig.columnWidth < themeMin) columnConfig.columnWidth = themeMin;
              columnConfigMap?.set(key, columnConfig);
            });
            break;
        }
        this.saveColumnChanges(columnConfigMap, resetColumnConfigMode, defaultColumnConfigToSet);
      }
    }
  });

  public autoSubmitForm$ = new Subject<void>();
  public formIsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public unsavedChangesInTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public unsavedChangesInModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private trackModalChanges = this.unsavedChangesInTab$.subscribeWhileAlive({
    owner: this,
    next: unsavedChanges => {
      if (unsavedChanges) {
        this.unsavedChangesInModal$.next(true);
      }
    }
  });

  public canChangeTabs$ = combineLatest([this.unsavedChangesInTab$, this.formIsValid$]).pipe(
    map(([hasChanges, formIsValid]) => {
      if (!hasChanges) {
        return true;
      } else {
        return formIsValid;
      }
    })
  );

  public columnsWidthTotal$ = combineLatest([
    this.columnConfigMap$,
    this.themeColumnConfig$,
    this.sectionLayoutType$,
    this.gridColumnCount$,
    this.columnChanges$
  ]).pipe(
    map(([
      columnConfigMap,
      themeColumnConfig,
      sectionLayoutType,
      gridColumnCount,
      columnChanges
    ]) => {
      let columnWidthTotal = NAME_COLUMN_DEFAULT_WIDTH;
      columnConfigMap?.forEach((columnConfig, key) => {
        const themeDefaults = themeColumnConfig?.get(key);
        let columnState = columnConfig?.defaultState || themeDefaults?.defaultState;
        if (!!columnChanges && key === columnChanges.key) {
          columnState = columnChanges.defaultState;
        }
        const canBeOn = !!columnState
          && columnState !== SectionColumnConfigDefaultState.Disabled
          && columnState !== SectionColumnConfigDefaultState.Off;
        if (canBeOn) {
          let widthToBeAdded = columnConfig?.columnWidth || themeDefaults?.columnWidth;
          if (!!columnChanges && key === columnChanges.key) {
            widthToBeAdded = columnChanges.columnWidth;
          }
          if (key === SectionColumnConfigKey.Price && sectionLayoutType?.isGrid()) {
            widthToBeAdded = widthToBeAdded * gridColumnCount;
          }
          columnWidthTotal += widthToBeAdded;
        }
      });
      return columnWidthTotal;
    })
  );

  public columnsWidthTotalError$ = this.columnsWidthTotal$.pipe(map(columnsWidthTotal => columnsWidthTotal > 100));
  public columnsWidthTotalErrorMessage$ = this.columnsWidthTotal$.pipe(
    map(columnsWidthTotal => {
      return `All enabled columns, including the name column (25%), add up to ${columnsWidthTotal}% `
        + `of the available space. When all columns are present, the menu will not format properly.`;
    })
  );

  public canSubmitForm$ = combineLatest([
    this.unsavedChangesInModal$,
    this.canChangeTabs$,
    this.columnsWidthTotalError$,
  ]).pipe(
    debounceTime(100),
    map(([unsavedChanges, canChangeTabs, columnWidthTotalError]) => {
      return unsavedChanges && canChangeTabs && !columnWidthTotalError;
    })
  );

  public colorPalette$ = this.menuDomainModel.colorPalette$;

  connectToCurrentlySelectedTabIndex = (id: number) => this._currentlySelectedTabIndex.next(id);
  connectToPreviouslySelectedTabId = (id: number) => this._previouslySelectedTabId.next(id);
  connectToColumnChanges = (changes: ColumnChanges) => this._columnChanges.next(changes);

  public initData(section: HydratedSection, theme: Theme, defaultOptions: DefaultSectionColumnConfig) {
    if (!!defaultOptions) {
      this._managingDefault.next(true);
      const defaultOptionsDeepCopy = window.injector.Deserialize.instanceOf(DefaultSectionColumnConfig, defaultOptions);
      if (!defaultOptions?.columnConfig) {
        defaultOptionsDeepCopy.columnConfig = ColumnOptionsViewModel.createNewColumnConfig();
      }
      this._defaultSectionConfig.next(defaultOptionsDeepCopy);
    } else {
      this._managingDefault.next(false);
      this._defaultSectionConfig.next(null);
    }
    this.connectToSection(section);
    this._theme.next(theme);
  }

  private static createNewColumnConfig(): Map<SectionColumnConfigKey, SectionColumnConfig> {
    return new Map<SectionColumnConfigKey, SectionColumnConfig>([
      [SectionColumnConfigKey.Asset, new SectionColumnConfig()],
      [SectionColumnConfigKey.Badges, new SectionColumnConfig()],
      [SectionColumnConfigKey.Brand, new SectionColumnConfig()],
      [SectionColumnConfigKey.StrainType, new SectionColumnConfig()],
      [SectionColumnConfigKey.QuantityAndSize, new SectionColumnConfig()],
      [SectionColumnConfigKey.Quantity, new SectionColumnConfig()],
      [SectionColumnConfigKey.Size, new SectionColumnConfig()],
      [SectionColumnConfigKey.CBD, new SectionColumnConfig()],
      [SectionColumnConfigKey.THC, new SectionColumnConfig()],
      [SectionColumnConfigKey.Price, new SectionColumnConfig()],
      [SectionColumnConfigKey.SecondaryPrice, new SectionColumnConfig()]
    ]);
  }

  resetToDefaultColumnConfig(defaultColumnConfig: DefaultSectionColumnConfig) {
    this.section$.once(section => {
      const opts = new ConfirmationOptions();
      opts.title = 'Reset Column Options';
      const sectionName = section?.title;
      opts.bodyText = `Are you sure you want to reset the Column Options for section '${sectionName}' `
        + `to '${defaultColumnConfig.name}'?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Reset';
      const confirmed = (cont: boolean) => {
        if (cont) {
          // when defaultColumnConfig.id set explicitly to -1, then reset to theme default
          if (defaultColumnConfig.id === '-1') {
            this.resetToThemeDefault();
          } else {
            this.resetToDefault(defaultColumnConfig);
          }
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmed);
    });
  }

  private saveColumnChanges(
    columnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>,
    resetColumnConfigMode: ResetColumnConfigMode,
    defaultToSet: DefaultSectionColumnConfig
  ): void {
    this.section$.once(s => {
      const loadingOpts = this._loadingOpts;
      const lm = 'Updating Column Options';
      const Deserialize = window?.injector?.Deserialize;
      const section = Deserialize?.instanceOf(HydratedSection, s);
      section.columnConfig = Deserialize?.mapOf(SectionColumnConfig, columnConfig);
      loadingOpts.addRequest(lm);
      const update$ = section instanceof SectionTemplate
        ? this.templateDomainModel.updateMenuSectionTemplate(section)
        : this.menuDomainModel.updateMenuSection(section);
      update$.subscribe({
        next: updatedSection => {
          if (resetColumnConfigMode === ResetColumnConfigMode.PreDefined) {
            this.identifyDiscrepanciesInAppliedDefault(defaultToSet, updatedSection.columnConfig);
          }
          this.connectToSection(updatedSection);
          this.unsavedChangesInModal$.next(false);
          loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Column options successfully updated.', 'Reset Column Options');
        },
        error: (error: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    });
  }

  saveChangesWithinModal() {
    const columnConfigMap = this._columnConfigMap.getValue();
    columnConfigMap?.forEach((columnConfig) => {
      if (columnConfig?.columnOpacity === 0) columnConfig.columnOpacity = null;
    });
    this._columnConfigMap.next(columnConfigMap);
  }

  submitForms() {
    const managingDefault = this._managingDefault.getValue();
    const sectionColumnConfig = this._columnConfigMap.getValue();
    if (managingDefault) {
      const defaultColumnConfig = this._defaultSectionConfig.getValue();
      defaultColumnConfig.columnConfig = this.setUnassignedColumnsToOff(sectionColumnConfig);
      this.activeModal.close(defaultColumnConfig);
    } else {
      this.activeModal.close(sectionColumnConfig);
    }
  }

  private setUnassignedColumnsToOff(
    sectionColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>
  ): Map<SectionColumnConfigKey, SectionColumnConfig> {
    const newDefaultColumnConfigMap = new Map<SectionColumnConfigKey, SectionColumnConfig>;
    sectionColumnConfig?.forEach((columnConfig, key) => {
      const columnConfigCopy = window.injector.Deserialize.instanceOf(SectionColumnConfig, columnConfig);
      if (!columnConfig?.defaultState) {
        columnConfigCopy.defaultState = SectionColumnConfigDefaultState.Off;
      }
      newDefaultColumnConfigMap.set(key, columnConfigCopy);
    });
    return newDefaultColumnConfigMap;
  }

  private resetToThemeDefault(): void {
    this._resetSubject.next({resetMode: ResetColumnConfigMode.ThemeDefault, defaultColumnConfig: null});
  }

  private resetToDefault(defaultColumnConfig: DefaultSectionColumnConfig) {
    this._resetSubject.next({resetMode: ResetColumnConfigMode.PreDefined, defaultColumnConfig});
  }

  private identifyDiscrepanciesInAppliedDefault(
    config: DefaultSectionColumnConfig,
    incomingConfig: Map<SectionColumnConfigKey, SectionColumnConfig>
  ) {
    if (!config) return;
    const outgoingConfig = window?.injector?.Deserialize?.instanceOf(DefaultSectionColumnConfig, config);
    const columnsSuccessfullyChanged = [];
    for (const [k, v] of outgoingConfig.columnConfig) {
      const testVal = window?.injector?.Deserialize?.instanceOf(SectionColumnConfig, incomingConfig.get(k));
      if (JSON.stringify(testVal) === JSON.stringify(v) && incomingConfig.has(k)) {
        columnsSuccessfullyChanged.push(k);
      }
    }
    if (columnsSuccessfullyChanged.length < incomingConfig.size) {
      this.toastService.publishBannerSuccess(
        `The section columns have been reset to ${outgoingConfig.name}, however some of the options are not `
        + `supported in the current theme. We've automatically updated the following columns to match what this `
        + `theme supports: ${columnsSuccessfullyChanged.join(', ')}`
      );
    }
  }

}
