import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Menu } from '../../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-edit-menu-header-name-and-active',
  templateUrl: './edit-menu-header-name-and-active.component.html',
  styleUrls: [
    './edit-menu-header-name-and-active.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuHeaderNameAndActiveComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() menu: Menu;
  @Input() mergeKey: string;
  @Input() disableActiveToggle: boolean = false;
  @Input() formPristine: boolean = true;
  @Input() toggleActiveState: (active: boolean) => void;

}
