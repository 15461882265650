import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Component({
  selector: 'app-edit-display-actions',
  templateUrl: './edit-display-actions.component.html',
  styleUrls: [
    './edit-display-actions.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayActionsComponent extends BaseComponent {

  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() disableSave: boolean = false;
  @Input() lastSavedTimeText: string = '';
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Output() showLiveView = new EventEmitter();
  @Output() submitForms = new EventEmitter();

  constructor() {
    super();
  }

}
