import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSaveTemplateBeforePublishingTooltip'
})
export class GetSaveTemplateBeforePublishingTooltipPipe implements PipeTransform {

  transform(unsavedChanges: boolean): string | null {
    return unsavedChanges
      ? 'Save changes before publishing the template.'
      : null;
  }

}
