<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'editDisplay'"
  (formsAutoSubmitted$)="viewModel.saveDisplay(true)"
  (formsSubmitted$)="viewModel.saveDisplay(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-display-container>
  <app-edit-display-header
    [headerText]="'Edit Display'"
    [headerSubtext]="'Assign menus to displays and set the timing and order that they are displayed.'"
    [disableSave]="viewModel.autoSaving$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    (submitForms)="formCapture.submitForms()">
  </app-edit-display-header>

  <app-edit-display-details
    [display]="viewModel.display$ | async"
    [mergeKey]="'editDisplay'"
    (copyDisplayUrl)="viewModel.copyDisplayUrl()">
  </app-edit-display-details>

  <app-edit-display-menu-details
    [sectionTitle]="'Menu Details'"
    [mergeKey]="'editDisplay'"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [allDisplaysAreScheduled]="allDisplaysAreScheduled$ | async"
    [sectionDescription]="'Assign one or many menus to a display and configure the rotation order and duration.'">
  </app-edit-display-menu-details>

  <app-edit-display-actions
    [disableSave]="viewModel.autoSaving$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
    (showLiveView)="viewModel.showLiveView()"
    (submitForms)="formCapture.submitForms()">
  </app-edit-display-actions>
</app-edit-display-container>
