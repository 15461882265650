import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { EditSectionForm } from '../../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form';
import { ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';
import { SpotlightSectionFormViewModel } from './spotlight-section-form-view-model';

@Component({
  selector: 'app-spotlight-section-form',
  templateUrl: './spotlight-section-form.component.html',
  styleUrls: ['./spotlight-section-form.component.scss'],
  providers: [SpotlightSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpotlightSectionFormComponent extends EditSectionForm {

  constructor(
    public override viewModel: SpotlightSectionFormViewModel
  ) {
    super(viewModel);
  }

  @ViewChild('form') form: ReactiveFormGroupComponent;

}
