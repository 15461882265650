import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { StackSmartBulkPrintPreviousJobsFormViewModel } from './stack-smart-bulk-print-previous-jobs-form-view-model';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-smart-bulk-print-previous-jobs-form',
  templateUrl: './stack-smart-bulk-print-previous-jobs-form.component.html',
  styleUrls: ['./stack-smart-bulk-print-previous-jobs-form.component.scss'],
  providers: [StackSmartBulkPrintPreviousJobsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartBulkPrintPreviousJobsFormComponent {

  constructor(public viewModel: StackSmartBulkPrintPreviousJobsFormViewModel) {
  }

  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToSelectedCardStacks') selectedCardStacks: Menu[];
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean;

  @Output() includeChangesSinceChanged = new EventEmitter<Map<string, number>>();

  public handleDropdownValueChange(id: string, prevJobDate: number): void {
    this.includeChangesSinceChanged.emit(new Map([[id, prevJobDate]]));
  }

}
