import { Component, ElementRef } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, ValidatorFn } from '@angular/forms';

@Component({template: ''})
export abstract class HiddenFormInputComponent extends ReactiveFormItemComponent implements ControlValueAccessor {

  protected constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  getValidators(): ValidatorFn[] {
    return [];
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(obj: any): void {
  }

}
