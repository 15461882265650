import { TypeDefinition } from './type-definition';

export enum DefaultDigitalSize {
  Digital720p = 'DefaultSize_720p',
  Digital1080p = 'DefaultSize_1080p',
  Digital4k = 'DefaultSize_4k',
  Digital8k = 'DefaultSize_8k',
}

export class DefaultDigitalSizeType extends TypeDefinition {

  override value: DefaultDigitalSize;

  static defaultDigitalSizes(): DefaultDigitalSize[] {
    return [
      DefaultDigitalSize.Digital4k,
      DefaultDigitalSize.Digital8k,
      DefaultDigitalSize.Digital720p,
      DefaultDigitalSize.Digital1080p
    ];
  }

}
