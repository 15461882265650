import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMarketingMenuComponent } from '../edit-template-marketing-menu.component';
import { EditTemplateMarketingDriveThruMenuViewModel } from './edit-template-marketing-drive-thru-menu-view-model';
import { EditDriveThruMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu-view-model';
import { EditTemplateMarketingDriveThruMenuCombinedLoadingOptsViewModel } from './edit-template-marketing-drive-thru-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-template-marketing-drive-thru-menu',
  templateUrl: './edit-template-marketing-drive-thru-menu.component.html',
  styleUrls: ['./edit-template-marketing-drive-thru-menu.component.scss'],
  providers: [
    EditTemplateMarketingDriveThruMenuViewModel,
    EditDriveThruMenuViewModel,
    EditTemplateMarketingDriveThruMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingDriveThruMenuComponent extends EditTemplateMarketingMenuComponent {

  constructor(
    public override viewModel: EditTemplateMarketingDriveThruMenuViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateMarketingDriveThruMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
