<div class="modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | push">
  <div *ngIf="viewModel.container.showPOSSyncBanner$ | push" class="cannabinoid-disabled-banner">
    <app-lot-info-banner
      [inventoryProvider]="viewModel.container.inventoryProvider$ | push"
      [displayAttribute]="viewModel.container.displayAttribute$ | push"
      [selectedTabId]="ClientTypes.EditVariantModalTabId.Cannabinoids"></app-lot-info-banner>
  </div>
  <div class="cannabinoids-form">
    <div class="cannabinoids-input">
      <div class="form-section-title">Primary</div>
      <lib-reactive-form-group [bindTo]="viewModel.container.variant$ | push" [mergeKey]="'editVariantFormCapture'">
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-drop-down
            [inputName]="'cannabisUnitOfMeasure'"
            [bindingProperty]="'cannabisUnitOfMeasure'"
            [label]="'Cannabis Unit Of Measure'"
            [required]="true"
            [placeholder]="'Select cannabis unit of measure'"
            [tooltip]="viewModel.CUOMTooltip$ | push"
            [dropdowns]="viewModel.cannabisUnitOfMeasureOptions$ | push"
            [disabled]="viewModel.container.syncCannabinoidsFromPOS$ | push"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.cuomSelectionChanged($event)"></lib-reactive-form-drop-down>
          <lib-reactive-form-switch
            *ngIf="viewModel.container.showVariantUseRangeSwitch$ | push"
            [inputName]="'useCannabinoidRange'"
            [bindingProperty]="'useCannabinoidRange'"
            [label]="'Use Cannabinoid Range'"
            [tooltip]="
              'Specific products can be enabled to use cannabinoid range. ' +
              'This is useful for multi-pack products with varying cannabinoids.'
            "
            [disabled]="!(viewModel.container.isCompanyAdmin$ | push)"
            [disabledTooltip]="!(viewModel.container.isCompanyAdmin$ | push) | notAnAdminTooltip"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.container.setVariantUsesRange($event)"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
      <app-cannabinoids-form [cannabinoid]="'THC'" [mergeKey]="'editVariantFormCapture'"></app-cannabinoids-form>
      <app-cannabinoids-form [cannabinoid]="'CBD'" [mergeKey]="'editVariantFormCapture'"></app-cannabinoids-form>

      <div class="form-section-title">Secondary</div>
      <ng-container *rxIf="viewModel.hasEnabledSecondaryCannabinoids$ | push; else SecondaryCannabinoidsEnableMessage">
        <ng-container *rxFor="let secondaryCannabinoid of viewModel.enabledSecondaryCannabinoidNames$ | push">
          <app-cannabinoids-form
            [cannabinoid]="secondaryCannabinoid"
            [mergeKey]="'editVariantFormCapture'"></app-cannabinoids-form>
        </ng-container>
      </ng-container>

      <ng-template #SecondaryCannabinoidsEnableMessage>
        <div class="secondary-container">
          <div class="secondary-message">
            Secondary cannabinoids can be enabled within Settings > Products. Once enabled they will appear here.
            (eg:CBG,CBN,THCA)
          </div>
          <lib-button-neutral
            *rxIf="viewModel.container.isCompanyAdmin$ | push"
            [iconSrc]="'assets/icons/dark/outline/cog.svg'"
            (buttonClicked)="editProductSettings()">
            Product Settings
          </lib-button-neutral>
        </div>
      </ng-template>
    </div>
    <app-cannabinoids-preview></app-cannabinoids-preview>
  </div>
</div>
