<div class="product-picker-datatable-container">
  <!-- Filter Options -->
  <div #filterContainer class="datatable-filters-container sticky-modal-header" [style.top.px]="headerHeight$ | async">
    <!-- do not delete #filterFormComponent -->
    <app-product-picker-filter-form
      #filterFormComponent
      [includeBottomBorder]="false"
      (resetPageNumber)="productsTableComponent?.resetPageNumber()">
    </app-product-picker-filter-form>
  </div>

  <div class="datatable-content sticky-modal-header">
    <!-- do not delete #productsTableComponent -->
    <app-product-picker-table
      #productsTableComponent
      [products]="viewModel.productsFilteredByProductTypeVariantTypeStrainType$ | async"
      [nItemsToDisplay]="viewModel.numberOfProductsPerPage$ | async"
      [filterHeight]="(filterDivHeight$ | async) + (headerHeight$ | async)"
      (dataBeingDisplayed)="viewModel.setProductsBeingDisplayed($event)">
    </app-product-picker-table>
  </div>
</div>
