<ng-container *rxIf="viewModel.isViewOnlyWithRecipients$ | push">
  <div class="d-flex justify-content-between align-items-center">
    <span class="bs-bold f16px">Sharing</span>
    <div *rxIf="!(viewModel.viewOnly$ | push)" (click)="viewModel.openEmployeeSelectionModal()" class="text-link">
      Add Employee
    </div>
  </div>
  <div class="f12px mt-3">
    {{ viewModel.description$ | push }}
  </div>
  <lib-reactive-form-group [bindTo]="viewModel.job$ | push" [mergeKey]="viewModel.mergeKey$ | push">
    <app-hidden-array-form-input
      [inputName]="'recipientSelections'"
      [bindingProperty]="'recipientIds'"
      [inputArr]="viewModel.recipientIds$ | push">
    </app-hidden-array-form-input>
  </lib-reactive-form-group>
  <app-employee-list-item
    *rxFor="let employee of viewModel.recipients$ | push; trackBy: 'userId'"
    [employee]="employee"
    [hideRemoveButton]="viewModel.viewOnly$ | push"
    (removeClicked)="viewModel.removeRecipient($event)">
  </app-employee-list-item>
</ng-container>
