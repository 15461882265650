export enum AuthFlow {
  SignIn,
  ForgotPassword,
  ResetPassword,
  SetNewPassword
}

export function AuthFlowTitle(af: AuthFlow): string {
  switch (af) {
    case AuthFlow.SignIn:
      return 'Sign In';
    case AuthFlow.ForgotPassword:
      return 'Forgot Password';
    case AuthFlow.ResetPassword:
      return 'Reset Password';
    case AuthFlow.SetNewPassword:
      return 'Set New Password';
  }
}

export function AuthFlowSubtext(af: AuthFlow): string {
  switch (af) {
    case AuthFlow.SignIn:
      return '';
    case AuthFlow.ForgotPassword:
      return 'Enter the email that you use to sign in with your account. '
        + 'We will send you a code that can be used to reset your password.';
    case AuthFlow.ResetPassword:
      return '';
    case AuthFlow.SetNewPassword:
      return 'You are required to enter a new password for your account.';
  }
}
