<app-label-inflator #labelCalculator hidden [computeLabelInterface]="computeLabelInterface"> </app-label-inflator>

<div
  *ngIf="labelCalculator.computedPrimaryLabel$ | async as primaryLabel"
  [ngClass]="{ 'vertical-stack-labels': stackLabelsVertically, 'horizontal-list-labels': !stackLabelsVertically }">
  <!-- Primary Label -->
  <app-label-inflator
    *ngIf="primaryLabel | isSaleSystemLabel; else primaryIsNotSaleLabel"
    [zoom]="zoom"
    [displaySaleLabelInterface]="
      primaryLabel | connectLabelPreviewToDisplaySaleLabelInterface : viewModel.saleLabelData$ : false | async
    ">
  </app-label-inflator>
  <ng-template #primaryIsNotSaleLabel>
    <app-label-inflator
      [zoom]="zoom"
      [displayLabelInterface]="
        primaryLabel | connectLabelPreviewToDisplayLabelInterface : viewModel.configurations$ : false | async
      ">
    </app-label-inflator>
  </ng-template>

  <!-- Secondary Label(s) -->
  <ng-container *ngFor="let secondaryLabel of labelCalculator.computedSecondaryLabels$ | async">
    <app-label-inflator
      *ngIf="secondaryLabel | isSaleSystemLabel; else secondaryNotSaleLabel"
      [zoom]="zoom"
      [displaySaleLabelInterface]="
        secondaryLabel | connectLabelPreviewToDisplaySaleLabelInterface : viewModel.saleLabelData$ : true | async
      ">
    </app-label-inflator>
    <ng-template #secondaryNotSaleLabel>
      <app-label-inflator
        [zoom]="zoom"
        [displayLabelInterface]="
          secondaryLabel | connectLabelPreviewToDisplayLabelInterface : viewModel.configurations$ : true | async
        ">
      </app-label-inflator>
    </ng-template>
  </ng-container>
</div>
