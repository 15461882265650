import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Section } from '../models/menu/dto/section';
import { Menu } from '../models/menu/dto/menu';
import { MenuStyle } from '../models/menu/dto/menu-style';
import { Asset } from '../models/image/dto/asset';
import { Theme } from '../models/menu/dto/theme';
import { DisplayAttribute } from '../models/display/dto/display-attribute';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { DuplicateMenuRequest } from '../models/menu/shared/duplicate-menu-request';
import { BulkPrintJob } from '../models/automation/bulk-print-job';
import { DuplicateMenuSectionRequest } from '../models/menu/shared/duplicate-menu-section-request';
import { NewMenuSectionRequest } from '../models/menu/dto/new-menu-section-request';
import { ApiPagination } from '../models/shared/api-pagination';
import { Injectable } from '@angular/core';
import { Label } from '../models/shared/label';

export const DEFAULT_MENU_PAGINATION_COUNT = 10;
export const DEFAULT_BULK_JOB_COUNT = 100;

@Injectable({providedIn: 'root'})
export class MenuAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Menu';

  // Menu

  public AddMenuSection(section: NewMenuSectionRequest): Observable<Menu> {
    const url = Endpoints.AddMenuSection();
    return this.apiClient.postObj(Menu, url, section).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AddMenuSection', err));
        return throwError(err);
      })
    );
  }

  public GetMenuSection(menuId, sectionId: string): Observable<HydratedSection> {
    const url = Endpoints.GetMenuSection(menuId, sectionId);
    return this.apiClient.getObj<HydratedSection>(HydratedSection, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuSection', err));
        return throwError(err);
      })
    );
  }

  public CreateMenu(menu: Menu): Observable<Menu> {
    const url = Endpoints.CreateMenu();
    return this.apiClient.postObj(Menu, url, menu).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateMenu', err));
        return throwError(err);
      })
    );
  }

  public DeleteMenu(menu: Menu): Observable<any> {
    const url = Endpoints.DeleteMenu();
    return this.apiClient.deleteObjReturnSuccess(url, menu).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteMenu', err));
        return throwError(err);
      })
    );
  }

  public DuplicateMenu(req: DuplicateMenuRequest): Observable<Menu> {
    const url = Endpoints.DuplicateMenu();
    return this.apiClient.postObj(Menu, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DuplicateMenu', err));
        return throwError(err);
      })
    );
  }

  public DeleteMenuSection(section: Section): Observable<Menu> {
    const url = Endpoints.DeleteMenuSection();
    return this.apiClient.deleteObj(Menu, url, section).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteMenuSection', err));
        return throwError(err);
      })
    );
  }

  public DeleteMenuStyles(styles: MenuStyle[]): Observable<string> {
    const url = Endpoints.DeleteMenuStyles();
    return this.apiClient.deleteStr(url, styles).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteMenuStyles', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyBasicMenus(locationId: number, pagination: ApiPagination = null): Observable<Menu[]> {
    const url = Endpoints.GetCompanyBasicMenus(locationId);
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_MENU_PAGINATION_COUNT);
    }
    return this.apiClient.recursiveGet<Menu>(Menu, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyMenus', err));
        return throwError(err);
      })
    );
  }

  public GetMenu(locationId: number, menuId: string): Observable<Menu> {
    const url = Endpoints.GetMenu(locationId, menuId);
    return this.apiClient.getObj<Menu>(Menu, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenu', err));
        return throwError(err);
      })
    );
  }

  public GetMenuAssets(isTemplate: boolean, id: string): Observable<Asset[]> {
    const url = Endpoints.GetMenuAssets(isTemplate, id);
    return this.apiClient.getArr<Asset>(Asset, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuAssets', err));
        return throwError(err);
      })
    );
  }

  public GetMenuThemes(): Observable<Theme[]> {
    const url = Endpoints.GetMenuThemes();
    return this.apiClient.getArr<Theme>(Theme, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetMenuThemes', err));
        return throwError(err);
      })
    );
  }

  public UpdateMenu(menu: Menu): Observable<Menu> {
    const menuDTO = window?.injector?.Deserialize?.instanceOf(Menu, menu)?.translateIntoDTO();
    const url = Endpoints.UpdateMenu();
    return this.apiClient.postObj(Menu, url, menuDTO).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenu', err));
        return throwError(err);
      })
    );
  }

  public UpdateSectionPriorities(sections: Section[]): Observable<Section[]> {
    const url = Endpoints.UpdateSectionPriorities();
    return this.apiClient.postArr(Section, url, sections).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateSectionPriorities', err));
        return throwError(err);
      })
    );
  }

  public UpdateMenuSection(section: Section): Observable<HydratedSection> {
    const sectionDTO = window?.injector?.Deserialize?.instanceOf(Section, section)?.translateIntoDTO();
    const url = Endpoints.UpdateMenuSection();
    return this.apiClient.postObj(HydratedSection, url, sectionDTO).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenuSection', err));
        return throwError(err);
      })
    );
  }

  public UpdateMenuStyles(styles: MenuStyle[]): Observable<Map<string, MenuStyle[]>> {
    const url = Endpoints.UpdateMenuStyles();
    return this.apiClient.postArrayReturnMapArr(MenuStyle, url, styles).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateMenuStyles', err));
        return throwError(err);
      })
    );
  }

  public DuplicateMenuSection(req: DuplicateMenuSectionRequest): Observable<HydratedSection> {
    const url = Endpoints.DuplicateMenuSection();
    return this.apiClient.postObj(HydratedSection, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DuplicateMenuSection', err));
        return throwError(err);
      })
    );
  }

  // Display Attributes

  public WriteDisplayAttributes(
    attrs: DisplayAttribute[],
    menuId: string = '',
    isBulkEdit: boolean = false
  ): Observable<DisplayAttribute[]> {
    const url = Endpoints.WriteDisplayAttributes(menuId, isBulkEdit);
    return this.apiClient.postArr(DisplayAttribute, url, attrs).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'WriteDisplayAttributes', err));
        return throwError(err);
      })
    );
  }

  public GetDisplayAttributes(locationId: number, pagination: ApiPagination = null): Observable<DisplayAttribute[]> {
    const url = Endpoints.GetDisplayAttributes(locationId);
    if (!pagination) {
      pagination = new ApiPagination(2000);
    }
    return this.apiClient.recursiveGet<DisplayAttribute>(DisplayAttribute, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplayAttributes', err));
        return throwError(err);
      })
    );
  }

  public DeleteDisplayAttributes(ids: string[]): Observable<string> {
    const url = Endpoints.DeleteDisplayAttributes(ids);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteDisplayAttributes', err));
        return throwError(err);
      })
    );
  }

  public GetLabels(locationId: number): Observable<Label[]> {
    const url = Endpoints.GetLabels(locationId);
    return this.apiClient.getArr<Label>(Label, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLabels', err));
        return throwError(err);
      })
    );
  }

  public DeleteLabel(l: Label): Observable<string> {
    const url = Endpoints.DeleteLabel();
    return this.apiClient.deleteStr(url, l).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteLabel', err));
        return throwError(err);
      })
    );
  }

  public CreateLabels(labels: Label[]): Observable<Label[]> {
    const url = Endpoints.CreateLabel();
    return this.apiClient.postArr(Label, url, labels).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateLabels', err));
        return throwError(err);
      })
    );
  }

  public UpdateLabels(labels: Label[]): Observable<Label[]> {
    const url = Endpoints.UpdateLabels();
    return this.apiClient.postArr(Label, url, labels).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateLabels', err));
        return throwError(err);
      })
    );
  }

  // Bulk Print

  public CreateBulkPrintJob(req: BulkPrintJob): Observable<BulkPrintJob> {
    const url = Endpoints.createBulkPrintJob();
    return this.apiClient.postObj(BulkPrintJob, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateBulkPrint', err));
        return throwError(err);
      })
    );
  }

  public CancelBulkPrintJob(req: BulkPrintJob): Observable<BulkPrintJob> {
    const url = Endpoints.cancelBulkPrintJob();
    return this.apiClient.postObj(BulkPrintJob, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CancelBulkPrint', err));
        return throwError(err);
      })
    );
  }

  public GetBulkPrintJobs(
    locationId: string,
    ids?: string[],
    pagination: ApiPagination = null,
  ): Observable<BulkPrintJob[]> {
    const url = Endpoints.getBulkPrintJobs(locationId, ids);
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_BULK_JOB_COUNT);
    }
    return this.apiClient.recursiveGet<BulkPrintJob>(BulkPrintJob, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBulkPrintJobs', err));
        return throwError(err);
      })
    );
  }

  public GetMenuBulkPrintJobs(menuId?: string, locationId?: string): Observable<BulkPrintJob[]> {
    const url = Endpoints.getMenuBulkPrintJobs(menuId, locationId);
    return this.apiClient.getArr<BulkPrintJob>(BulkPrintJob, url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(
          new ApiErrorLog(this.serviceName, 'GetMenuBulkPrintJobs', err)
        );
        return throwError(() => err);
      })
    );
  }

  public GetMenuTemplateBulkPrintJobs(templateId?: string, locationId?: string): Observable<BulkPrintJob[]> {
    const url = Endpoints.getMenuTemplateBulkPrintJobs(templateId, locationId);
    return this.apiClient.getArr<BulkPrintJob>(BulkPrintJob, url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(
          new ApiErrorLog(this.serviceName, 'GetMenuTemplateBulkPrintJobs', err)
        );
        return throwError(() => err);
      })
    );
  }

}
