<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">{{ title }}</p>
    <p class="bs-edit-section-description">{{ description }}</p>
    <lib-button-neutral
      *ngIf="viewModel.showColumnOptionsButton$ | async"
      [disabled]="(viewModel.isTemplatedSection$ | async) || !formPristine"
      [disabledTooltip]="
        (viewModel.isTemplatedSection$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="viewModel.openEditColumnOptionsModal()">
      Column Options
    </lib-button-neutral>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <!-- Edit section details form -->
    <app-edit-menu-section-form
      [mergeKey]="mergeKey"
      [menu]="viewModel.menu$ | async"
      [section]="viewModel.section$ | async"
      [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
      [companyConfig]="viewModel.companyConfig$ | async"
      [locationConfig]="viewModel.locationConfig$ | async"
      [bindTo]="viewModel.section$ | async"
      (showZeroStockChanged)="viewModel.showZeroStockChanged($event)"
      (formAutoSubmitted)="viewModel.saveSection(true)"
      (disableSubmit)="viewModel.setAutoSaveTimer($event)"
      (primarySortChanged)="viewModel.primarySortChanged($event)"
      (secondarySortChanged)="viewModel.secondarySortChanged($event)">
    </app-edit-menu-section-form>
  </div>
</div>

<hr class="mt-24px" />
