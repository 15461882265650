<app-loading *ngIf="container?.isLoading$ | async" [options]="container.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #editVariantFormCapture
  [mergeKey]="'editVariantFormCapture'"
  [removeLabelFromErrorMessages]="true"
  (formsSubmitted$)="container.saveVariant($event)"
  (formsAutoSubmitted$)="autoSaveVariant($event)"
  (canSubmit$)="container.canSubmitForm$.next($event)"
  (unsavedChanges$)="container.unsavedChanges$.next($event)">
</lib-reactive-form-merge-groups>

<div
  #modalBody
  [style.height.px]="container.modalBodyHeight$ | async"
  [style.max-height.px]="container.modalBodyHeight$ | async"
  class="modal-body sticky variant-modal">
  <div #header class="sticky-header-container" [style.height.rem]="7">
    <div class="modal-header" [style.border-bottom]="'none'">
      <div class="modal-title-container">
        <div class="modal-title me-3">
          <app-display-name
            [displayName]="container.variantName$ | async"
            [isMedical]="(container.variant$ | async)?.isMedical"
            [isCultivatorVerified]="(container.universalVariant$ | async)?.cultivatorVerified"
            [variantTooltip]="container.shouldShowOriginalName$ | async"
            [truncate]="true"></app-display-name>
        </div>
        <app-label-preview [zoom]="0.9" [computeLabelInterface]="container.computeLabelInterface$ | async">
        </app-label-preview>
        <app-edit-variant-variant-picker
          class="variant-picker"
          *ngIf="(container.product$ | async)?.variants.length > 1"
          [currentVariantId]="(container.variant$ | async).id"
          [relatedVariants]="(container.product$ | async)?.variants"
          (variantClicked)="setVariant($event, true)">
        </app-edit-variant-variant-picker>
      </div>
      <div class="modal-subtitle-container">
        <div class="modal-subtitle">
          {{ container.variantSubtitle$ | async }}
        </div>
        <app-asset
          *ngFor="let badge of container.variantBadges$ | async"
          class="variant-badge-preview"
          [asset]="badge?.image"
          [scaleFit]="true">
        </app-asset>
      </div>
    </div>
  </div>

  <div #tabContainer class="sticky-body-container variant-tab-container">
    <app-tab-bar
      #tabController
      [tabs]="tabs$ | async"
      [skipFragmentAppending]="true"
      [showLoadingSpinnerOnTabChange]="true"
      [loadingTimeMs]="500"
      [delayTabClick]="100"
      [canChangeTabs]="container.canChangeTabs$ | async"
      [canChangeTabsErrorMsg]="canChangeTabsErrorMsg | async"
      (previousTab)="setPreviousTab($event); checkForAutosave($event)"
      (selectedTab)="setCurrentTab($event)"
      class="flex-fill">
    </app-tab-bar>
  </div>

  <div
    #footer
    [style.position]="container.bottomButtonPosition$ | async"
    class="sticky-footer-container edit-variant-footer">
    <ng-container
      *ngIf="
        (container?.product$ | async)?.variants.length > 1 &&
        (((tabController.currentSelectedTab === ClientTypes.EditVariantModalTabId.Cannabinoids ||
          tabController.currentSelectedTab === ClientTypes.EditVariantModalTabId.Terpenes) &&
          !(this.container.companyConfig$ | async)?.syncPOSCannabinoid) ||
          tabController.currentSelectedTab === ClientTypes.EditVariantModalTabId.Customization)
      ">
      <app-fake-check-box
        [label]="'Apply to Multiple Variants'"
        [checked]="(this.container.selectedSiblingVariants$ | async).length > 0"
        (clicked)="container.openSelectVariantModal()">
      </app-fake-check-box>
    </ng-container>
    <div [style.margin-left]="'auto'">
      <lib-button-neutral (buttonClicked)="checkForUnsavedChangesAndCancel()" [style.margin-right.rem]="1">
        Cancel
      </lib-button-neutral>
      <lib-button-primary
        (buttonClicked)="editVariantFormCapture.submitForms()"
        [disabled]="!(container.canSubmitForm$ | async)"
        [loading]="container.autoSaving$ | push"
        [loadingOptions]="container.autoSaveLoadingOpts$ | push">
        Save Changes
      </lib-button-primary>
      <div [hidden]="!(container.lastAutoSaveText$ | async)" class="auto-save-text bs-medium f12px">
        {{ container.lastAutoSaveText$ | async }}
      </div>
    </div>
  </div>
</div>
