<div class="select-all-container bg-white">
  <div class="d-flex pt-16px pb-16px" *rxIf="!viewOnly">
    <label>Select all {{ type }}s</label>
    <span class="menu-count">({{ selectedMenuIds?.length }})</span>
    <app-group-selection
      [selection]="menusAsSelectionItem"
      [selectedIds]="selectedMenuIds"
      (bulkAdd)="bulkAdd.emit($event)"
      (bulkRemove)="bulkRemove.emit($event)">
    </app-group-selection>
  </div>
</div>
<app-print-job-menu-selection
  *rxFor="let menu of menuChoices; let last = last; trackBy: 'id'"
  [selection]="menu"
  [selectedIds]="selectedMenuIds"
  [includeBottomBorder]="!last"
  [disabled]="viewOnly"
  (add)="add.emit($event)"
  (remove)="remove.emit($event)">
</app-print-job-menu-selection>
<lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="job">
  <app-hidden-array-form-input
    [inputName]="'printMenuSelections'"
    [bindingProperty]="'menuIds'"
    [required]="true"
    [disabled]="viewOnly"
    [inputArr]="selectedMenuIds">
  </app-hidden-array-form-input>
</lib-reactive-form-group>
