import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../../../../../models/product/dto/hydrated-variant-badge';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { CustomizationPreviewViewModel } from './customization-preview-view-model';

@Component({
  selector: 'app-customization-preview',
  templateUrl: './customization-preview.component.html',
  styleUrls: ['./customization-preview.component.scss'],
  providers: [CustomizationPreviewViewModel]
})
export class CustomizationPreviewComponent extends BaseComponent implements OnChanges {

  @Input() badgesAndSource: {badges: HydratedVariantBadge[]; source: string};
  @Input() labelOffset: number;
  @Input() updatedVariant: Variant;

  constructor(
    public viewModel: CustomizationPreviewViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updatedVariant) this.viewModel.connectToVariant(this.updatedVariant);
  }

}
