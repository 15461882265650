import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SortUtils } from '../../../../utils/sort-utils';
import { Selectable } from '@mobilefirstdev/reactive-form/lib/components/reactive-form-item/reactive-form-drop-down/selectable';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';

@Injectable()
export class LocationPickerViewModel extends BaseViewModel {

  constructor(
    protected activeModal: NgbActiveModal,
    protected locationDomainModel: LocationDomainModel,
    protected userDomainModal: UserDomainModel
  ) {
    super();
    this.locationDomainModel.locationId$.subscribeWhileAlive({
      owner: this,
      next: (locId) => this.connectToActiveLocationId(locId)
    });
  }

  public readonly locations$ = this.locationDomainModel.allLocations$;

  private readonly _remember = new BehaviorSubject<boolean>(false);
  public readonly remember$ = this._remember as Observable<boolean>;
  connectToRemember = (remember: boolean) => this._remember.next(remember);

  private readonly _activeLocationId = new BehaviorSubject<number | null>(null);
  public readonly activeLocationId$ = this._activeLocationId as Observable<number | null>;
  connectToActiveLocationId = (activeLocationId: number) => this._activeLocationId.next(activeLocationId);

  public readonly activeLocation$ = combineLatest([
    this.activeLocationId$,
    this.locations$
  ]).pipe(
    map(([activeLocationId, locations]) => {
      return Number.isFinite(activeLocationId)
        ? locations?.find(l => l?.id === activeLocationId)
        : null;
    })
  );

  public sortedLocations$ = combineLatest([
    this.userDomainModal.userDefaultLocationId$,
    this.locations$,
    this.activeLocation$
  ]).pipe(
    map(([defaultLocId, locations, activeLocation]) => {
      return locations
        ?.sort((a, b) => SortUtils.sortLocationPicker(a, b, activeLocation?.id))
        ?.map(l => {
          return new class implements Selectable {

            getSelectionTitle = (): string => l.getSelectionTitle() + `${defaultLocId === l.id ? ' (default)' : ''}`;
            getSelectionValue = (): any => l.getSelectionValue();
            getSelectionUniqueIdentifier = (): any => l.getSelectionUniqueIdentifier();

          }();
        });
    })
  );

  save() {
    combineLatest([
      this.activeLocation$,
      this.remember$
    ]).pipe(
      take(1)
    ).subscribe(([activeLocation, remember]) => {
      this.activeModal.close([activeLocation, remember]);
    });
  }

}
