<div class="bs-section-container">
  <!-- Loading Options on section preview -->
  <app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"> </app-loading>

  <div class="min-section-height" fxLayout="row">
    <div fxFlex fxLayout="column">
      <div
        #sectionTitle
        class="bs-section-list-title clickable"
        [class.non-clickable-section]="!(viewModel.isEditableSection$ | push)"
        (click)="viewModel.editSection()">
        <img
          [hidden]="viewModel.hideCUOMHeaderWarning$ | push"
          [ngbTooltip]="viewModel.headerWarningForCUOM$ | push"
          [src]="'assets/icons/dark/outline/exclamation.svg'"
          alt="" />
        {{ section | getSectionPreviewTitle }}
      </div>
      <div #sectionTypeAndVariantCount fxFlex [style.margin-top.rem]="1">
        <div class="bs-section-body-detail">
          <span>Section Type:</span>
          {{ section | getSectionPreviewFormattedSectionType }}
        </div>
        <div class="bs-section-body-detail" [hidden]="!(viewModel.showVariantCount$ | push)">
          <span>Variant Count:</span>
          {{ section | getSectionPreviewVariantCount }}
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="end end">
      <div *rxIf="viewModel.hasSmartFilters$" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="0.5rem">
        <app-smart-filter-small-sync-button
          *rxIf="!(viewModel.templateMode$ | push)"
          appThrottleClick
          [syncing]="viewModel.section$ | isSectionSyncingSmartFilters | push"
          [formPristine]="formPristine"
          [timestamp]="viewModel.lastSmartFilterSync$ | push"
          [disabledThrottleClick]="(viewModel.section$ | isSectionSyncingSmartFilters | push) || !formPristine"
          (throttleClick)="viewModel.syncSectionSmartFilters(menu, section)">
        </app-smart-filter-small-sync-button>
        <app-smart-filters-indicator
          [appliedSmartFiltersTooltip]="viewModel?.appliedSmartFilters$ | push"
          [nSmartFilters]="section?.smartFilterIds?.length">
        </app-smart-filters-indicator>
      </div>
      <div class="bs-section-body-buttons" fxLayout="row" fxLayoutAlign="end center">
        <lib-button-inline-text
          *rxIf="viewModel.isEditableSection$"
          [disabled]="(viewModel.autoSaving$ | push) || hasErrors"
          (buttonClicked)="viewModel.editSection()">
          Edit
        </lib-button-inline-text>

        <lib-button-inline-text
          *ngIf="!(viewModel.isPrintReportMenu$ | push)"
          [disabled]="(viewModel.templatedMenu$ | push) || (viewModel.autoSaving$ | push) || hasErrors || !formPristine"
          [disabledTooltip]="
            (viewModel.templatedMenu$ | push | templatedMenuDisabledButtonTooltip) ||
            (formPristine | saveChangesTooltip)
          "
          (buttonClicked)="duplicateSection()">
          Duplicate
        </lib-button-inline-text>

        <lib-button-inline-text
          *ngIf="!(viewModel.isPrintReportMenu$ | push)"
          [textColor]="'#FA5555'"
          [disabled]="(viewModel.templatedMenu$ | push) || (viewModel.autoSaving$ | push) || hasErrors || !formPristine"
          [disabledTooltip]="
            (viewModel.templatedMenu$ | push | templatedMenuDisabledButtonTooltip) ||
            (formPristine | saveChangesTooltip)
          "
          (buttonClicked)="viewModel.promptForDeleteSection()">
          Delete
        </lib-button-inline-text>
      </div>
    </div>
  </div>

  <hr *rxIf="sectionIndex < (viewModel.menuSectionsLastIndex$ | push)" class="no-margin" />
</div>
