<lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
  <div class="flex-container mb-16px">
    <div class="name">{{ viewModel?.fileName$ | async }}</div>
  </div>

  <lib-reactive-form-number
    #displayDuration
    [inputName]="'interval'"
    [label]="'Display Duration (seconds)'"
    [placeholder]="'Enter the display duration'"
    [integersOnly]="true"
    [inlineLabel]="true"
    [required]="true"
    [minValue]="1"
    [maxValue]="999999"
    [disabled]="!(viewModel.displayDurationEnabled$ | async) || (viewModel?.isTemplatedMenu$ | async)"
    [disabledTooltip]="viewModel?.isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip"
    [programmaticallyChangeValue]="viewModel.newInterval$ | async"
    [bindingProperty]="'options.rotationInterval'"
    [bindingPropertyMapKey]="sectionId">
  </lib-reactive-form-number>

  <lib-reactive-form-number
    #loopContent
    *ngIf="viewModel.fileIsVideo$ | async"
    [inputName]="'loop'"
    [label]="'Play Count'"
    [placeholder]="'Play count'"
    [tooltip]="'Number of times to loop before changing to new content.'"
    [integersOnly]="true"
    [inlineLabel]="true"
    [required]="true"
    [minValue]="1"
    [maxValue]="999999"
    [disabled]="!(viewModel.groupMediaEnabled$ | async) || (viewModel?.isTemplatedMenu$ | async)"
    [disabledTooltip]="viewModel?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip"
    [programmaticallyChangeValue]="viewModel.loopCount$ | async"
    (valueChanged)="viewModel.connectToLoopCount($event)">
  </lib-reactive-form-number>
</lib-reactive-form-group>
