<div class="bs-section-container">
  <!-- Loading Options on section preview -->
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

  <div class="min-section-height" fxLayout="row">
    <div fxFlex fxLayout="column">
      <div #sectionTitle class="bs-section-list-title smart-filter-title" (click)="viewModel.editSmartFilter()">
        {{ viewModel.smartFilterTitle$ | async }}
      </div>
      <div
        #sectionTypeAndVariantCount
        *ngIf="!templateMode && (viewModel.smartFilter$ | push)"
        fxFlex
        [style.margin-top.rem]="1">
        <div class="bs-section-body-detail">
          <span>Last Sync:</span>
          {{ viewModel.lastSyncTime$ | async }}
        </div>
      </div>
    </div>

    <div *ngIf="viewModel.smartFilter$ | push" fxLayout="column" fxLayoutAlign="end end">
      <div class="bs-section-body-buttons" fxLayout="row" fxLayoutAlign="end center">
        <lib-button-inline-text
          [disabled]="autoSaving || syncing || (viewModel.removingSmartFilters$ | async) || !formPristine"
          [disabledTooltip]="formPristine | saveChangesTooltip"
          (buttonClicked)="viewModel.editSmartFilter()">
          {{ viewModel.actionButtonText$ | async }}
        </lib-button-inline-text>

        <lib-button-inline-text
          *rxIf="!(viewModel.isPrintReportMenu$ | push)"
          [disabled]="
            autoSaving ||
            syncing ||
            !formPristine ||
            (viewModel.isTemplatedSection$ | async) ||
            (viewModel.removingSmartFilters$ | async)
          "
          [disabledTooltip]="
            (viewModel.isTemplatedSection$ | async | templatedMenuDisabledButtonTooltip) ||
            (formPristine | saveChangesTooltip)
          "
          [textColor]="'#FA5555'"
          (buttonClicked)="viewModel.removeSmartFilter()">
          Remove
        </lib-button-inline-text>
      </div>
    </div>
  </div>
</div>
<hr *ngIf="!last" class="no-margin" />
