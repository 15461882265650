import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../functions/exists';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { ThemeUtils } from '../../../../../utils/theme-utils';

@Injectable()
export class ThemePreviewCardViewModel extends BaseViewModel {

  private _card = new BehaviorSubject<Card|null>(null);
  public readonly card$ = this._card as Observable<Card|null>;
  connectToCard = (card: Card) => this._card.next(card);

  private _cardClass = new BehaviorSubject<string>('');
  public readonly cardClass$ = this._cardClass as Observable<string>;
  connectToCardClass = (cardClass: string) => this._cardClass.next(cardClass);

  public readonly isThemePrivate$ = this.card$.pipe(map(card => exists(card.data?.companyId)));

  public readonly themeHasNonStandardDimensions$ = this.card$.pipe(
    map(card => card?.data?.id),
    map(id => ThemeUtils.themeIdsWithNonStandardDimensions().includes(id))
  );

  public readonly cardClasses$: Observable<string> = combineLatest([
    this.card$,
    this.cardClass$
  ]).pipe(
    map(([card, cardClass]) => {
      return exists(card?.customClass)
        ? `${card?.customClass}` + (exists(cardClass) ? ` ${cardClass}` : '')
        : cardClass;
    })
  );

}
