import { Pipe, PipeTransform } from '@angular/core';
import type { VariantBadge } from '../../../models/product/dto/variant-badge';
import type { Product } from '../../../models/product/dto/product';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'isSmartBadge'
})
export class IsSmartBadgePipe implements PipeTransform {

  transform(badge: VariantBadge, item: Product | Variant): boolean {
    if (item instanceof Variant) return item?.getLocationLevelSmartFilterBadgeIds()?.includes(badge?.id);

    const allVariantSmartFilterBadges = item?.variants?.flatMap(v => v?.getLocationLevelSmartFilterBadgeIds());
    return allVariantSmartFilterBadges?.includes(badge?.id);
  }

}
