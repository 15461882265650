import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HiddenFormInputComponent } from '../../../../../../forms/hidden-form-input/hidden-form-input.component';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CardStackPrintConfig } from '../../../../../../../../models/automation/card-stack-print-config';

@Component({
  selector: 'app-print-config-map-form-input',
  templateUrl: '../../../../../../../shared/forms/hidden-form-input/hidden-form-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => PrintConfigMapFormItemComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PrintConfigMapFormItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintConfigMapFormItemComponent extends HiddenFormInputComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() printConfigMap: Map<string, CardStackPrintConfig>;
  @Output() printConfigMapChanged = new EventEmitter<Map<string, CardStackPrintConfig>>();

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.printConfigMap && !!this.printConfigMap) {
      this.configMapChanged(this.printConfigMap);
    }
  }

  configMapChanged(configMap: Map<string, CardStackPrintConfig>) {
    this.handleInputChange(configMap);
    this.getSelfAsFormItem()?.patchValue(configMap);
    this.markAsDirty();
    this.printConfigMapChanged.emit(configMap);
  }

}
