import { Deserializable } from '../../protocols/deserializable';

export class SignOutRequest implements Deserializable {

  constructor(userId, accessToken: string) {
    this.userId = userId;
    this.accessToken = accessToken;
  }

  public userId: string = '';
  public accessToken: string = '';
  onDeserialize = ()  => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-authenticator/blob/dev/models/SignOutRequest.go
  onSerialize() {
    const dto = Object.create(SignOutRequest.prototype);
    dto.userId = this.userId;
    dto.accessToken = this.accessToken;
    return dto;
  }

}
