import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseColor'
})
export class ParseColorPipe implements PipeTransform {

  transform(input: [string, any]): string {
    if (input?.length === 2) {
      return input[0];
    }
    return '';
  }

}
