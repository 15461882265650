import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseReactiveFormComponent } from '@mobilefirstdev/reactive-form';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { Variant } from '../../../../../../models/product/dto/variant';
import { DriveThruProductFormViewModel } from './drive-thru-product-form-view-model';
import { DriveThruProductData } from '../drive-thru-product-data';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-drive-thru-product-form',
  templateUrl: './drive-thru-product-form.component.html',
  styleUrls: ['./drive-thru-product-form.component.scss'],
  providers: [DriveThruProductFormViewModel]
})
export class DriveThruProductFormComponent extends BaseReactiveFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() override bindTo: DriveThruProductData;
  @Input() menu: Menu;
  @Input() variant: Variant;
  @Input() isTemplatedMenu: boolean = false;
  @Output() formChanged: EventEmitter<any> = new EventEmitter(true);

  public companyConfiguration$ = this.companyDomainModel.companyConfiguration$;
  public colorPalette$ = this.companyConfiguration$.pipe(map(cc => cc?.colorPalette));
  public debouncer = new Subject<string>();
  private _destroy = new Subject<void>();

  constructor(
    public viewModel: DriveThruProductFormViewModel,
    private companyDomainModel: CompanyDomainModel,
  ) {
    super();
    this.debouncer
      .pipe(takeUntil(this._destroy))
      .pipe(debounceTime(250))
      .subscribe((value: string) => this.formChanged.emit(value));
  }

  ngOnInit() {
    this.viewModel.setHideDesc(this.bindTo?.hideDesc);
    this.viewModel.setDescOverride(this.bindTo?.desc);
    this.viewModel.setMenu(this.menu);
    this.viewModel.setVariant(this.variant);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bindTo) {
      this.viewModel.setHideDesc(this.bindTo?.hideDesc);
      this.viewModel.setDescOverride(this.bindTo?.desc);
    }
    if (changes.menu) this.viewModel.setMenu(this.menu);
    if (changes.variant) this.viewModel.setVariant(this.variant);
  }

  onFormChanges(event: any) {
    this.debouncer.next(event);
  }

  ngOnDestroy(): void {
    this._destroy.next();
  }

}
