import { HydratedSmartFilter } from './hydrated-smart-filter';
import { SortUtils } from '../../utils/sort-utils';
import { SelectableSmartFilter } from './protocols/selectable-smart-filter';
import { Asset } from '../image/dto/asset';

export class SmartFilterGrouping implements SelectableSmartFilter {

  public id = Math.random().toString(24);
  public category: string;
  public grouping: HydratedSmartFilter[];

  constructor(category: string) {
    this.category = category;
    this.grouping = [];
  }

  addToGroup(filter: HydratedSmartFilter[]): void {
    this.grouping?.push(...filter);
    this.grouping?.sort(SortUtils.sortHydratedSmartFiltersByName);
  }

  getSelectionName(): string {
    return this.category.charAt(0).toUpperCase() + this.category.slice(1);
  }

  getVariantHits(): number {
    return this.grouping?.reduce((accum, filter) => accum + filter.getVariantHits(), 0);
  }

  getIds(): string[] {
    return this.grouping?.map(filter => filter?.id) || [];
  }

  getId(): string {
    return this.id;
  }

  some(filters: HydratedSmartFilter[]): boolean {
    const filterIds = filters?.map(filter => filter?.id) ?? [];
    const groupingIds = this.grouping?.map(filter => filter?.id) ?? [];
    return filterIds?.some(id => groupingIds?.includes(id));
  }

  getNumOfFilters(): number {
    if (this.grouping?.length > 0) {
      return this.grouping.length;
    } else {
      return null;
    }
  }

  getGroupingImage(): Asset {
    if (this.grouping?.length > 0) {
      return this.grouping[0].category.image as Asset;
    } else {
      return null;
    }
  }

  getGroupedFilters(): HydratedSmartFilter[] {
    if (this.grouping?.length > 0) {
      return this.grouping;
    } else {
      return null;
    }
  }

  getPriority(): number {
    return this.grouping?.firstOrNull()?.category?.priority;
  }

}
