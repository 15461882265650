import { TypeDefinition } from './type-definition';

export enum UnitOfMeasure {
  Gram = 'g',
  MilliGram = 'mg',
  MilliLitre = 'ml',
  NA = 'N/A'
}

export class UnitOfMeasureType extends TypeDefinition {

  override value: UnitOfMeasure;

}
