import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { PrintTheme } from '../../../models/enum/dto/theme.enum';

@Pipe({
  name: 'isPlantlifeNonSmokableMenu'
})
export class IsPlantlifeNonSmokableMenuPipe implements PipeTransform {

  transform(menu: Menu): boolean {
    return menu?.theme === PrintTheme.PlantlifeNonSmokable;
  }

}
