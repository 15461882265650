import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LabelViewModel } from './label-view-model';
import { ComputeLabelInterface } from './compute-label-interface';
import { BaseComponent } from '../../../models/base/base-component';
import { Label } from '../../../models/shared/label';
import { DisplayLabelInterface } from './display-label-interface';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DisplaySaleLabelInterface } from './display-sale-label-interface';

@Component({ selector: 'app-label', template: '' })
export abstract class LabelComponent extends BaseComponent implements OnChanges {

  protected constructor(
    public viewModel: LabelViewModel
  ) {
    super();
  }

  @Input() computeLabelInterface: ComputeLabelInterface;
  @Input() displayLabelInterface: DisplayLabelInterface;
  @Input() displaySaleLabelInterface: DisplaySaleLabelInterface;
  @Input() zoom: number;

  protected _clearClicked = new Subject<Label>();
  public clearClicked$ = this._clearClicked as Observable<Label>;

  override setupViews() {
    this.viewModel.connectToDisplayLabelInterface(this.displayLabelInterface);
    this.viewModel.connectToComputeLabelInterface(this.computeLabelInterface);
    this.viewModel.connectToDisplaySaleLabelInterface(this.displaySaleLabelInterface);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.displayLabelInterface) {
      this.viewModel.connectToDisplayLabelInterface(this.displayLabelInterface);
    }
    if (!!changes?.computeLabelInterface) {
      this.viewModel.connectToComputeLabelInterface(this.computeLabelInterface);
    }
    if (!!changes?.displaySaleLabelInterface) {
      this.viewModel.connectToDisplaySaleLabelInterface(this.displaySaleLabelInterface);
    }
  }

  clearClicked() {
    if (!!this.displayLabelInterface) {
      this._clearClicked.next(this.displayLabelInterface?.getLabelsForLabelComponent()?.firstOrNull());
    } else if (!!this.computeLabelInterface) {
      this.viewModel.label$.pipe(take(1)).subscribe(label => this._clearClicked.next(label));
    }
  }

}
