import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { Observable } from 'rxjs';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import { DisplayLabelInterface } from '../label/display-label-interface';
import { map } from 'rxjs/operators';
import { LabelUtils } from '../utils/label-utils';

@Pipe({
  name: 'connectLabelPreviewToDisplayLabelInterface'
})
export class ConnectLabelPreviewToDisplayLabelInterfacePipe implements PipeTransform {

  transform(
    label: Label,
    configurations$: Observable<[LocationConfiguration, CompanyConfiguration]>,
    secondary: boolean
  ): Observable<DisplayLabelInterface> {
    return configurations$.pipe(
      map(([locConfig, compConfig]) => {
        return LabelUtils.getDisplayLabelInterfaceForLabelPreview(label, locConfig, compConfig, secondary, secondary);
      })
    );
  }

}
