import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardStackSectionFormViewModel } from './card-stack-section-form-view-model';
import { EditSectionForm } from '../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form';

@Component({
  selector: 'app-card-stack-section-form',
  templateUrl: './card-stack-section-form.component.html',
  providers: [CardStackSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackSectionFormComponent extends EditSectionForm {

  @Input() isTemplatedMenu: boolean = false;

  constructor(public override viewModel: CardStackSectionFormViewModel) {
    super(viewModel);
  }

}
