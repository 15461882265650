import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Menu } from '../../../../../models/menu/dto/menu';
import { NewMenuModalViewModel } from './new-menu-modal-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';

@Component({
  selector: 'app-new-menu-modal',
  templateUrl: './new-menu-modal.component.html',
  styleUrls: ['./new-menu-modal.component.scss'],
  providers: [NewMenuModalViewModel]
})
export class NewMenuModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: NewMenuModalViewModel
  ) {
    super(activeModal);
  }

  private listenToClose = this.viewModel.dismissModalSubject
    .subscribeWhileAlive({
      owner: this,
      next: close => this.cancel(close)
    });

  override cancel(menu?: Menu) {
    this.activeModal.close(menu);
  }

}
