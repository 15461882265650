import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionBodyComponent } from '../../../../../edit-menu-section/edit-menu-section-body/edit-menu-section-body.component';
import { EditFeaturedCategorySectionViewModel } from '../edit-featured-category-section-view-model';

@Component({
  selector: 'app-edit-featured-category-section-body',
  templateUrl: './edit-featured-category-section-body.component.html',
  styleUrls: ['./edit-featured-category-section-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategorySectionBodyComponent extends EditMenuSectionBodyComponent {

  constructor(
    public override viewModel: EditFeaturedCategorySectionViewModel
  ) {
    super(viewModel);
  }

}
