import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, Observable, of, throwError } from 'rxjs';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { MenuStyle } from '../../../../../models/menu/dto/menu-style';
import { Variant } from '../../../../../models/product/dto/variant';
import { Section } from '../../../../../models/menu/dto/section';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuStyleObject } from '../../../../../models/utils/dto/menu-style-object-type';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';

@Injectable()
export class EditMenuStyleViewModel extends BaseModalViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private toastService: ToastService,
    protected injector: Injector,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private readonly _colorPalette = new BehaviorSubject<string[]>(null);
  public readonly colorPalette$ = this._colorPalette as Observable<string[]>;
  connectToColorPalette = (colors: string[]) => this._colorPalette.next(colors);

  private readonly _variant = new BehaviorSubject<Variant>(null);
  public readonly variant$ = this._variant as Observable<Variant>;
  connectToVariant = (variant: Variant) => this._variant.next(variant);

  private readonly _siblingIds = new BehaviorSubject<string[]>(null);
  public readonly siblingIds$ = this._siblingIds as Observable<string[]>;
  connectToSiblingIds = (siblingIds: string[]) => this._siblingIds.next(siblingIds);

  private readonly _section = new BehaviorSubject<Section>(null);
  public readonly section$ = this._section as Observable<Section>;
  connectToSection = (section: Section) => this._section.next(section);

  public readonly menu$ = this.section$.pipe(
    switchMap(section => {
      return (section instanceof SectionTemplate)
        ? this.templateDomainModel.activeMenuTemplate$
        : this.menuDomainModel.activeHydratedMenu$;
    })
  );

  private readonly _originalMenuStyle = new BehaviorSubject<MenuStyle>(null);
  public readonly originalMenuStyle$ = this._originalMenuStyle.deepCopy().pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToOriginalMenuStyle = (menuStyle: MenuStyle) => this._originalMenuStyle.next(menuStyle);

  public readonly viewOnly$ = combineLatest([
    this.originalMenuStyle$,
    this.section$
  ]).pipe(
    map(([menuStyle, section]) => menuStyle?.viewOnly(section))
  );

  /**
   * Use this menu style for editing. This is a copy of the original menu style.
   * Original menu style is used for comparison on whether it can be removed.
   * If the menu style is for a templated menu, and is a template styling, then this
   * gets transformed into a non-template styling that can be used for overriding.
   */
  public readonly editableMenuStyle$ = combineLatest([
    this.menu$,
    this.section$,
    this._originalMenuStyle
  ]).pipe(
    map(([menu, section, menuStyle]) => {
      if (menuStyle?.isTemplatedMenuWithTemplateStyle(menu)) {
        menuStyle.isTemplateStyle = false;
        menuStyle.configurationId = menu?.id;
        menuStyle.sectionId = section?.id;
        menuStyle.id = null;
      }
      return menuStyle;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly removeButtonText$ = combineLatest([
    this.menu$,
    this.section$,
    this.variant$,
    this.originalMenuStyle$
  ]).pipe(
    map(([menu, section, variant, menuStyle]) => {
      switch (true) {
        case !!menuStyle && !menuStyle?.isTemplateStyle && menu?.hasTemplateStyling(section, variant):
          return 'Remove Override';
        default:
          return 'Remove Style';
      }
    })
  );

  public readonly cancelButtonText$ = this.viewOnly$.pipe(
    map(viewOnly => (viewOnly ? 'Close' : 'Cancel'))
  );

  public readonly modalTitle$ = combineLatest([
    this.menu$,
    this.section$,
    this.variant$,
    this.originalMenuStyle$,
    this.viewOnly$
  ]).pipe(
    map(([menu, section, variant, menuStyle, viewOnly]) => {
      switch (true) {
        case viewOnly:
          return 'View Menu Style';
        case !!menuStyle && !menuStyle?.isTemplateStyle && menu?.hasTemplateStyling(section, variant):
          return 'Edit Override Styling';
        case !!menuStyle?.id:
          return 'Edit Menu Style';
        default:
          return 'Add Menu Style';
      }
    })
  );

  public readonly variantName$ = this.variant$.pipe(map(variant => variant?.getVariantTitle()));
  public readonly hasSiblings$ = this.siblingIds$.pipe(map(siblingIds => siblingIds?.length > 1));

  public readonly canRemoveStyle$ = combineLatest([
    this.menu$,
    this.originalMenuStyle$,
    this.viewOnly$
  ]).pipe(
    map(([menu, menuStyle, viewOnly]) => menuStyle?.isRemovable(menu) && !viewOnly)
  );

  // Only show font size if not carousel card type
  public readonly showFontSize$ = combineLatest([
    this.menu$,
    this.section$
  ]).pipe(
    map(([menu, section]) => {
      const carouselCard = menu?.getFeaturedCategoryCardType()?.isCarouselCardType();
      const newProductsSection = section?.isNewProductsSection();
      return !carouselCard && !newProductsSection;
    })
  );

  public setBold(bold: boolean) {
    this.editableMenuStyle$.once(style => style.fontDecoration[0] = bold);
  }

  public setItalics(italics: boolean) {
    this.editableMenuStyle$.once(style => style.fontDecoration[1] = italics);
  }

  public removeStyle() {
    this.editableMenuStyle$.once(style => {
      if (style.applyToProductVariants) {
        this.promptForDeleteStyles();
      } else {
        this.removeMenuStyle();
      }
    });
  }

  public promptForDeleteStyles() {
    this.variant$.once(variant => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete section styles?';
      opts.bodyText = `You are about to delete the menu styles for all variants under `
        + `the product: '${variant?.productName}'?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete Styles';
      const confirmation = (cont) => {
        if (cont) this.removeMenuStyle();
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  public saveMenuStyle() {
    const lm = 'Updating Styles';
    combineLatest([this.editableMenuStyle$, this.menu$, this.variant$]).pipe(
      take(1),
      switchMap(([menuStyle, menu, variant]) => {
        this._loadingOpts.addRequest(lm);
        menuStyle?.transformIntoDTO(menu, variant);
        return menuStyle.applyToProductVariants
          ? this.deleteVariantStylesForProduct().pipe(map(_ => menuStyle))
          : of(menuStyle);
      }),
      switchMap(menuStyle => {
        return this.menuDomainModel.updateMenuStyles([menuStyle]).pipe(
          tap(menuStyles => this.templateDomainModel.updateTemplateProductStyling(menuStyles))
        );
      }),
      take(1)
    ).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Menu Styles updated successfully', 'Styles Updated');
        this.dismissModalSubject.next(true);
      },
      error: (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      }
    });
  }

  public removeMenuStyle() {
    const lm = 'Removing Styles';
    combineLatest([this.editableMenuStyle$, this.menu$, this.variant$]).pipe(
      take(1),
      switchMap(([menuStyle, menu, variant]) => {
        this._loadingOpts.addRequest(lm);
        menuStyle?.transformIntoDTO(menu, variant);
        const id = menuStyle.configurationId;
        return this.menuDomainModel.deleteMenuStyles(id, [menuStyle]).pipe(
          tap(menuStyles => this.templateDomainModel.removeTemplateProductStyling(id, [menuStyle]))
        );
      }),
      take(1)
    ).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Menu Styles deleted successfully', 'Styles Deleted');
        this.dismissModalSubject.next(true);
      },
      error: (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      }
    });
  }

  private deleteVariantStylesForProduct(): Observable<string> {
    return combineLatest([this.menu$, this.section$, this.siblingIds$]).pipe(
      take(1),
      switchMap(([menu, section, siblingIds]) => {
        const stylesToDelete: MenuStyle[] = [];
        menu?.styling?.forEach((style) => {
          const isVariantStyling = style.objectType === MenuStyleObject.Variant;
          const isSibling = siblingIds?.contains(style.objectId);
          if (isVariantStyling && isSibling) stylesToDelete.push(style);
        });
        const menuId = section?.configurationId;
        const deleteStyles$ = defer(() => {
          return this.menuDomainModel.deleteMenuStyles(menuId, stylesToDelete).pipe(
            tap(menuStyles => this.templateDomainModel.removeTemplateProductStyling(menuId, stylesToDelete))
          );
        });
        return (stylesToDelete?.length > 0) ? deleteStyles$ : of('');
      })
    );
  }

}
