import { Checkbox } from '../../shared/stylesheet/checkbox';
import type { DatatableFilterOption } from '../../shared/stylesheet/datatable-filter-option';
import type { ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';
import type { DatatableDataProvider } from '../../protocols/datatable-data-provider';
import { DatatableFilterFormResult } from '../../../views/product/components/data-table/data-table-filter-form/datatable-filter-form.result';

export class DatatableProductFilter {

  // Search Input
  public enableSearch: boolean = true;
  public searchLabelText: string = 'Search';
  public searchPlaceholderText: string = 'Search to filter results';
  // Checkbox
  public enableFilterCheckbox: boolean = false;
  public checkbox: Checkbox = new Checkbox('');
  public clearCheckboxButtonText = 'Show Out Of Stock Products';
  // Other
  public enableResultsDisplayed: boolean = true;
  public hideOutOfStockProducts: boolean = true;
  // Custom Filters
  public clearButtonHidden: boolean = false;
  public customFilters: DatatableFilterOption[] = [];
  public searchQueryString: string = '';

  public datatableFilterFormResult = new DatatableFilterFormResult();
  public onFilterableDataChanged?: (tableData: DatatableDataProvider, filterForm: ReactiveFormGroupComponent) => void;

  public filtersAreActive(): boolean {
    return this.customFilters
      ?.map(cf => cf.selectedOption && cf.selectedOption.getSelectionValue() !== null)
      ?.filter(cf => cf).length > 0;
  }

  public clearFilters(): void {
    this.customFilters.map(cf => cf.selectedOption = null);
  }

}
