import { ChangeDetectorRef, Component, forwardRef, Inject, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableEmptyStateBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AllProductsDataTableViewModel } from '../../all-products-data-table-view-model';

@Component({
  selector: 'app-all-products-table-empty-state',
  templateUrl: './all-products-table-empty-state.component.html',
  styleUrls: ['./all-products-table-empty-state.component.scss'],
  providers: [
    {
      provide: ReactiveTableEmptyStateBluePrintComponent,
      useExisting: forwardRef(() => AllProductsTableEmptyStateComponent)
    }
  ]
})
export class AllProductsTableEmptyStateComponent extends ReactiveTableEmptyStateBluePrintComponent {

  public title$ = this.getCombinedPipe(this.getTitleText.bind(this));
  public desc$ = this.getCombinedPipe(this.getDescText.bind(this));
  public clearOutOfStockOption$ = this.title$.pipe(map(desc => desc?.includes('No in stock results')));

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: AllProductsDataTableViewModel // global - provided by root
  ) {
    super(viewRef);
  }

  private getCombinedPipe(mapData: (filterOutOfStock: boolean, searchString: string) => string): Observable<string> {
    return combineLatest([this.viewModel.hideOutOfStockProducts$, this.viewModel.searchText$]).pipe(
      map(([filterOutOfStock, searchString]) => mapData(filterOutOfStock, searchString))
    );
  }

  public getTitleText(filterOutOfStock: boolean, searchString: string): string {
    if (filterOutOfStock) {
      return `No in stock results`;
    } else if (!!searchString && searchString?.length > 0) {
      return `No results for "${searchString}"`;
    } else {
      return `No results matching the active filters.`;
    }
  }

  public getDescText(filterOutOfStock: boolean, searchString: string): string {
    if (filterOutOfStock) {
      return `No products in stock matched your search query.`;
    } else if (!!searchString && searchString?.length > 0) {
      return `It doesn't look like this product exists in your inventory. Try a different search term.`;
    } else {
      return `It doesn't look like this product exists in your inventory. Try modifying your filters.`;
    }
  }

  initializeFromBluePrint(bluePrint: AllProductsTableEmptyStateComponent): void {
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  sendOutputsToBluePrint(bluePrint: AllProductsTableEmptyStateComponent): void {
  }

}
