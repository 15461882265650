import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset } from '../../../../../../models/image/dto/asset';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { Preview } from '../../../../../../models/shared/preview';
import { PrintCardPreview } from '../../../../../../models/menu/shared/print-card-preview';

@Pipe({
  name: 'loadPreview'
})
export class LoadPreviewPipe implements PipeTransform {

  transform(
    itemPreviews$: Observable<Preview[]|null>,
    contentId: (string | string[])
  ): Observable<Asset> {
    return itemPreviews$.pipe(
      map(itemPreviews => {
        const contentKey = Array.isArray(contentId) ? contentId?.firstOrNull() : contentId;
        if (Array.isArray(contentId)) {
          const preview = itemPreviews?.find(itemPreview => {
            return itemPreview instanceof PrintCardPreview
              ? itemPreview?.variantIds?.includes(contentKey)
              : itemPreview?.id?.includes(contentKey);
          });
          return preview?.preview;
        }
        return itemPreviews?.find(itemPreview => itemPreview?.id === contentKey)?.preview;
      }),
      distinctUntilChanged(DistinctUtils.distinctAssetByMD5Hash)
    );
  }

}
