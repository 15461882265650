import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Variant } from '../../../../../../../models/product/dto/variant';
import { PopperUtils } from '../../../../../../../utils/popper-utils';

@Component({
  selector: 'app-edit-variant-variant-picker',
  templateUrl: './edit-variant-variant-picker.component.html',
  styleUrls: ['./edit-variant-variant-picker.component.scss']
})
export class EditVariantVariantPickerComponent extends BaseComponent {

  @Input() relatedVariants: Variant[];
  @Input() currentVariantId: string;
  @Output() variantClicked: EventEmitter<Variant> = new EventEmitter<Variant>();

  // Popper
  public popperModifier = [PopperUtils.flipModifier()];
  public popperStyles = { 'background-color': '#FFFFFF' };

}
