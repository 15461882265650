import { Menu } from '../../menu/dto/menu';
import { TemplateStatus } from '../enum/template-status.enum';
import { SectionTemplate } from './section-template';
import { environment } from '../../../../environments/environment';
import { StringUtils } from '../../../utils/string-utils';
import { Section } from '../../menu/dto/section';
import { PillItem } from '../../shared/stylesheet/pill-item';
import { SmartFilterUtils } from '../../../utils/smart-filter-utils';
import { SmartFilterAppliedOnPillType } from '../../enum/shared/smart-filter-applied-on-pill-type';
import { exists } from '../../../functions/exists';
import { MenuType } from '../../utils/dto/menu-type-definition';

export class MenuTemplate extends Menu {

  public override id: string; // the id associated with the template
  public activeLocationIds: number[];
  public requiredLocationIds: number[];
  public status: TemplateStatus;
  public templateSections: SectionTemplate[];

  /**
   * Will return a shallow copy of the existing array with any changes.
   */
  static updateMenuTemplates(
    existingTemplates: MenuTemplate[],
    updateTemplates: MenuTemplate[],
    removeItems: boolean = false
  ): MenuTemplate[] {
    const updatedTemplates = existingTemplates?.shallowCopy() || [];
    updateTemplates?.forEach(updatedTemplate => {
      const oldMenu = updatedTemplates?.find(t => t?.id === updatedTemplate?.id);
      const replacementIndex = updatedTemplates?.indexOf(oldMenu);
      if (removeItems) {
        if (replacementIndex > -1) updatedTemplates?.splice(replacementIndex, 1);
      } else if (replacementIndex > -1) {
        updatedTemplates[replacementIndex] = updatedTemplate;
      } else {
        updatedTemplates.push(updatedTemplate);
      }
    });
    return updatedTemplates;
  }

  override onDeserialize() {
    super.onDeserialize();
    this.templateSections = window?.injector?.Deserialize?.arrayOf(SectionTemplate, this.templateSections);
  }

  // Expected go model
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/TemplateDTO.go
  override onSerialize(): MenuTemplate {
    const dto = Object.assign(new MenuTemplate(), super.onSerialize());
    dto.requiredLocationIds = this.requiredLocationIds;
    dto.status = this.status;
    return dto;
  }

  public updateSectionTemplate(updatedSection: SectionTemplate, removeItem: boolean): void {
    const updatedTemplateSections = this.templateSections?.shallowCopy() || [];
    const replacementIndex = updatedTemplateSections?.findIndex(s => s?.id === updatedSection?.id);
    if (removeItem) {
      if (replacementIndex > -1) updatedTemplateSections.splice(replacementIndex, 1);
    } else if (replacementIndex > -1) {
      updatedTemplateSections[replacementIndex] = updatedSection;
    } else {
      updatedTemplateSections.push(updatedSection);
    }
    this.templateSections = updatedTemplateSections;
  }

  override translateIntoDTO(): this {
    return this;
  }

  public override getEditMarketingMenuTitleFromSubtype(): string {
    return StringUtils.replaceMenuWithTemplate(super.getEditMarketingMenuTitleFromSubtype());
  }

  override getViewAllNavigationUrl(): string {
    return StringUtils.replaceMenuWithTemplate(super.getViewAllNavigationUrl());
  }

  override getViewAllNavigationName(): string {
    return StringUtils.replaceMenuWithTemplate(super.getViewAllNavigationName());
  }

  override getViewAllNavigationFragment(): string {
    const parent = super.getViewAllNavigationFragment();
    if (parent?.toLowerCase()?.includes('menu')) {
      return StringUtils.replaceMenuWithTemplate(super.getViewAllNavigationFragment());
    }
    switch (this.type) {
      case MenuType.PrintLabelMenu:
        return `printLabelTemplates`;
      case MenuType.PrintCardMenu:
        return `printCardTemplates`;
      case MenuType.PrintReportMenu:
        return `reportTemplates`;
      default:
        return null;
    }
  }

  override getEditNavigationName(): string {
    return StringUtils.replaceMenuWithTemplate(super.getEditNavigationName());
  }

  override getEditNavigationUrl(): string {
    return StringUtils.replaceMenuWithTemplate(super.getEditNavigationUrl());
  }

  public override generateMenuUrl(locationId: number): string {
    const env = (environment.production && environment.environmentName !== 'rc')
      ? ''
      : `${environment.environmentName}.`;
    return `https://${env}display.mybudsense.com/#/template/${locationId.toString()}/${this.id}`;
  }

  override getSectionsBasedOnMenuType(): Section[] {
    return this.templateSections;
  }

  override getAsPillItem(clickable: boolean, selected: boolean, disabled: boolean): PillItem {
    const imgSrc = SmartFilterUtils.getAppliedOnPillIcon(SmartFilterAppliedOnPillType.Template);
    return new PillItem(this.name, clickable, selected, disabled, imgSrc, this);
  }

  override whatTypeOfMenuIsThis(): string | null {
    switch (true) {
      case this.isPrintCardMenu():  return 'card stack template';
      case this.isPrintLabelMenu(): return 'label stack template';
      default:                      return 'menu template';
    }
  }

  /* *************************** Displayable Item Interface *************************** */

  public override displayableItemHasSmartFilters(): boolean {
    return this.templateSections?.some(s => s?.hasSmartFilters());
  }

  override displayableItemIsActive(): boolean {
    return this.status === TemplateStatus.Published;
  }

  override displayableItemActiveText(): string {
    return 'Published';
  }

  override displayableItemInactiveText(): string {
    return 'Draft';
  }

  override displayableItemSmartFilterIndicatorTooltip(): string {
    if (this.containsStackedContent()) return 'This template contains smart filters';
    return 'This template contains sections that use smart filters.';
  }

  override displayableItemShowDeployedCountIndicator(): boolean {
    return true;
  }

  override displayableItemDeployedCountTooltipText(): string {
    return 'Number of locations this template is imported to.';
  }

  override displayableItemDeployedCount(): number {
    return this.activeLocationIds?.length;
  }

  override displayableItemDeployedCountIcon(): string {
    return 'assets/icons/dark/solid/location-marker.svg';
  }

  override displayableItemIsTemplatedMenu(): boolean {
    return false;
  }

  /* ********************************************************************************** */

  override sectionIdsWithPrimaryAssets(): string[] {
    const isSectionTemplate = (sectionTemplate: SectionTemplate): sectionTemplate is SectionTemplate => {
      return sectionTemplate instanceof SectionTemplate;
    };
    return this.templateSections
      ?.filter(isSectionTemplate)
      ?.map(s => exists(s?.image) ? s?.id : null)
      ?.filterNulls() ?? [];
  }

  isPublished(): boolean {
    return this.status === TemplateStatus.Published;
  }

  isActiveAtLocation(locationId: number): boolean {
    return this.activeLocationIds?.includes(locationId);
  }

  override getUniqueIdentifier(): string {
    const parentIdentifier = super.getUniqueIdentifier();
    return `
      -${parentIdentifier}
      -${this.activeLocationIds?.sort()?.join()}
      -${this.requiredLocationIds?.sort()?.join()}
      -${this.status}
      -${this.templateSections?.map(s => s?.getUniqueIdentifier())?.join()}
    `;
  }

}
