<div class="modal-padding">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-string-number
      [inputName]="'packagedQuantity'"
      [label]="'Packaged Quantity'"
      [placeholder]="'The number of units in a package. (ie: 1, 3, 5)'"
      [inlineLabel]="false"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="
        'The smart filter will include products that exactly match one of the specified packaged ' +
        'quantities (comma separated)'
      "
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'packagedQuantities'"
      [dispersedKey]="dispersedKey"
      [forceMinValueZeroOrLower]="0"
      [forceMaxValue]="1000"
      [allowCommas]="true"
      [customValueParser]="cleanString">
    </lib-reactive-form-string-number>

    <lib-reactive-form-string-number
      [inputName]="'unitSize'"
      [label]="'Unit Size'"
      [placeholder]="'The size of each unit (ie: 1, 3.5 ,7)'"
      [inlineLabel]="false"
      [disabled]="viewModel.isViewOnly$ | async"
      [customValueParser]="cleanString"
      [tooltip]="
        'The smart filter will include products that exactly match one of the specified unit ' +
        'sizes (comma separated)'
      "
      [bindingProperty]="'unitSizes'"
      [dispersedKey]="dispersedKey"
      [forceMinValueZeroOrLower]="0"
      [forceMaxValue]="1000"
      [addToAtLeastNItemsFilledInPool]="true"
      [allowCommas]="true">
    </lib-reactive-form-string-number>

    <lib-reactive-form-text
      [inputName]="'productName'"
      [label]="'Product Name'"
      [placeholder]="'Terms within product name (ie: OG, Kush)'"
      [inlineLabel]="false"
      [maxLength]="1023"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="
        'The smart filter will include products that contain any of the specified search terms in ' +
        'the product name (comma separated)'
      "
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'productName'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-text>

    <lib-reactive-form-text
      [inputName]="'brandName'"
      [label]="'Brand Name'"
      [placeholder]="'Brand Names (Wana, Wana Quick)'"
      [inlineLabel]="false"
      [maxLength]="1023"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="
        'The smart filter will include products that exactly match one of the specified brand names ' +
        '(comma separated)'
      "
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'brandName'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-text>

    <lib-reactive-form-text
      [inputName]="'strain'"
      [label]="'Strain'"
      [placeholder]="'Strain Names (White Widow, Pink Kush)'"
      [inlineLabel]="false"
      [maxLength]="1023"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="
        'The smart filter will include products that exactly match one of the specified strains ' + '(comma separated)'
      "
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'strain'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-text>

    <lib-reactive-form-text
      [inputName]="'search'"
      [label]="'General Search Term'"
      [placeholder]="'Search Terms (ie: Full-Spectrum, Pinene)'"
      [inlineLabel]="false"
      [maxLength]="1023"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="
        'The smart filter will include products that contain any of the specified search terms in ' +
        'the products manufacturer, description or terpenes fields (comma separated)'
      "
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'searchTerm'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-text>

    <lib-reactive-form-number
      [inputName]="'minInventory'"
      [label]="'Minimum Inventory'"
      [placeholder]="'Minimum inventory of product'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [disabled]="viewModel.isViewOnly$ | async"
      [minValue]="1"
      [maxValue]="viewModel?.maxInventory$ | async"
      [tooltip]="'The smart filter will include products with inventory values greater than or equal to this value'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'minInventory'"
      [dispersedKey]="dispersedKey"
      (valueChanged)="viewModel.setMinInventory($event)">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      [inputName]="'maxInventory'"
      [label]="'Maximum Inventory'"
      [placeholder]="'Maximum inventory of product'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [disabled]="viewModel.isViewOnly$ | async"
      [maxValue]="viewModel?.inventoryFilterUpperBound$ | async"
      [minValue]="viewModel?.minInventory$ | async"
      [tooltip]="'The smart filter will include products with inventory values less than or equal to this value'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'maxInventory'"
      [dispersedKey]="dispersedKey"
      (valueChanged)="viewModel.setMaxInventory($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-string-number
    [inputName]="'pricingCode'"
    [label]="'Pricing Code'"
    [placeholder]="'Pricing code of product active price (ie: 0.98, 0.99)'"
    [inlineLabel]="false"
    [disabled]="viewModel.isViewOnly$ | async"
    [tooltip]="'The smart filter will include products that exactly match one of the pricing tiers (comma separated)'"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'pricingCodes'"
    [dispersedKey]="dispersedKey"
    [forceMinValueZeroOrLower]="0"
    [allowCommas]="true"
    [customValueParser]="cleanString">
  </lib-reactive-form-string-number>

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-switch
      style="align-self: center"
      class="mt-2"
      [label]="'Active Sale'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'activeSale'"
      [dispersedKey]="dispersedKey"
      [inputName]="'activeSale'"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="'The smart filter will only include products on sale.'">
    </lib-reactive-form-switch>

    <lib-reactive-form-switch
      style="align-self: center"
      class="mt-2"
      [label]="'Require Pricing Tier'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'requirePricingTier'"
      [dispersedKey]="dispersedKey"
      [inputName]="'pricingTier'"
      [disabled]="viewModel.isViewOnly$ | async"
      [tooltip]="'The smart filter will only include products with pricing tiers.'">
    </lib-reactive-form-switch>
  </lib-reactive-form-column-layout>

  <div class="label-badge-container">
    <app-message-banner
      class="mt-3"
      *ngIf="viewModel.disableLabelAndBadgePickers$ | async"
      [message]="
        'Smart Filters that are assigned to labels and/or badges may not use labels and/or badges as ' +
        'advanced filter criteria.'
      ">
    </app-message-banner>

    <app-reactive-form-inline-labels-picker
      class="mt-3"
      [bindTo]="bindTo"
      [dispersedKey]="dispersedKey"
      [disabled]="(viewModel.isViewOnly$ | async) || (viewModel.disableLabelAndBadgePickers$ | async)"
      [useAtLeastNItemsFilledInPool]="true"
      [useInlineText]="false">
    </app-reactive-form-inline-labels-picker>

    <div class="mt-3">
      <app-badge-picker-for-form
        [inputName]="'badgePicker'"
        [bindingProperty]="'badgeIds'"
        [dispersedKey]="dispersedKey"
        [badges]="viewModel?.hydratedBadgesOnSmartFilter$ | async"
        [popperPlacement]="NgxPopperjsPlacements.BOTTOM"
        [addToAtLeastNItemsFilledInPool]="true"
        [disabled]="(viewModel.isViewOnly$ | async) || (viewModel.disableLabelAndBadgePickers$ | async)"
        [useInlineText]="false">
      </app-badge-picker-for-form>
    </div>
  </div>
</div>
