import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AllCardStackPrintJobsModalViewModel } from './all-card-stack-print-jobs-modal-view-model';
import { BaseModalComponent } from '../../../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-all-card-stack-print-jobs-modal',
  templateUrl: './all-card-stack-print-jobs-modal.component.html',
  providers: [AllCardStackPrintJobsModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllCardStackPrintJobsModalComponent extends BaseModalComponent {

  constructor(
    activeModal: NgbActiveModal,
    public viewModel: AllCardStackPrintJobsModalViewModel
  ) {
    super(activeModal);
  }

}
