<div
  [class.smart-filter-indicator-container-no-number]="!(nSmartFilters$ | async)"
  [class.smart-filter-indicator-container]="(nSmartFilters$ | async) > 0"
  [ngbTooltip]="appliedSmartFiltersTooltip">
  <img
    src="assets/icons/dark/solid/lightning-bolt.svg"
    [style.margin-right.rem]="(nSmartFilters$ | async) > 0 ? 0.25 : null"
    alt="Smart Filter Indicator" />
  <div *ngIf="showNumber$ | async">{{ nSmartFilters$ | async }}</div>
</div>
