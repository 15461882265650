import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { MarketingMenuType } from '../../../models/enum/dto/marketing-menu-type.enum';

@Pipe({
  name: 'marketingMenuHasAdditionalOptions'
})
export class MarketingMenuHasAdditionalOptionsPipe implements PipeTransform {

  transform(menu: Menu): boolean {
    return menu?.hydratedTheme?.menuSubType === MarketingMenuType.DriveThru
      || menu?.hydratedTheme?.menuSubType === MarketingMenuType.Featured
      || menu?.hydratedTheme?.menuSubType === MarketingMenuType.Category;
  }

}
