<div class="modal-body">
  <div class="modal-title mb-3">{{ title }}</div>

  <div class="mt-32px mb-32px">
    <app-search-with-look-ahead
      [placeHolder]="searchPlaceHolder"
      [lookAheadItems]="lookAheadUsers$ | async"
      [searchTheseProperties]="['fullName', 'email']"
      (lookAheadSelected)="addUser($event)">
    </app-search-with-look-ahead>

    <div class="block mt-24px">
      <app-employee-list-item
        *ngFor="let employee of usersToAdd$ | async"
        [employee]="employee"
        (removeClicked)="removeUser($event)">
      </app-employee-list-item>
    </div>

    <div appThrottleClick class="text-link mt-24px" (throttleClick)="infoTextToggled = !infoTextToggled">
      {{ infoTitle }}
    </div>
    <div *ngIf="infoTextToggled" class="info-text mt-24px mb-16px">{{ infoText }}</div>
  </div>

  <div class="modal-footer-flex-end">
    <button matRipple (click)="cancel()" class="bs-button outlined-button">Cancel</button>
    <button
      matRipple
      (click)="addUsers()"
      class="bs-button preferred-button"
      [matRippleDisabled]="!(hasUsersToAdd$ | async)"
      [ngClass]="{ disabled: !(hasUsersToAdd$ | async) }"
      [disabled]="!(hasUsersToAdd$ | async)">
      Add
    </button>
  </div>
</div>
