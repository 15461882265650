import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HasChildIds } from '../../../../../../../../../models/protocols/has-child-ids';
import { exists } from '../../../../../../../../../functions/exists';

@Component({
  selector: 'app-label-stack-print-job-select-all-products',
  templateUrl: './label-stack-print-job-select-all-products.component.html',
  styleUrls: ['./label-stack-print-job-select-all-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackPrintJobSelectAllProductsComponent {

  @Input() selection: HasChildIds;
  @Input() selectionMap: Map<string, number>;
  @Output() bulkAddClicked = new EventEmitter<string[]>(true);
  @Output() bulkRemoveClicked = new EventEmitter<string[]>(true);

  addOrRemoveClicked(): void {
    this.selection?.getChildIds()?.every(id => exists(this.selectionMap?.get(id)))
      ? this.bulkRemoveClicked.emit(this.selection?.getChildIds())
      : this.bulkAddClicked.emit(this.selection?.getChildIds());
  }

}
