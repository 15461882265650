import { TimeDuration } from '../../shared/time-duration';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { MenuIntervalUtils } from '../../../utils/menu-interval-utils';

export class DisplayMenuOptions implements UniquelyIdentifiable {

  public priority: number;
  public interval: number; // if marketing playlist, then == n times to loop content.
  public playlistDuration: number; // time duration in seconds to loop once through content
  public marketingTotalTime: number; // loop count * duration in seconds of one loop
  public override: TimeDuration;

  static default(
    usesLoopingSystem: boolean,
    existingOptions: Map<string, DisplayMenuOptions>,
  ): DisplayMenuOptions {
    let nextPriority = 0;
    existingOptions?.forEach((option) => {
      if (option.priority >= nextPriority) {
        nextPriority = option.priority + 1;
      }
    });
    // Setup new options
    const opt = new DisplayMenuOptions();
    opt.priority = nextPriority;
    opt.interval = MenuIntervalUtils.getMenuRotationInterval(usesLoopingSystem);
    opt.override = new TimeDuration();
    return opt;
  }

  getUniqueIdentifier(): string {
    return `${this.priority}
      -${this.interval}
      -${this.playlistDuration}
      -${this.marketingTotalTime}
      -${this.override?.getUniqueIdentifier()}`;
  }

}
