import { Component } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Tabbable } from '../../../../models/protocols/tabbable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Label } from '../../../../models/shared/label';
import { map } from 'rxjs/operators';
import { AddNewLabelViewModel } from '../add-new-label-view-model';
import { LabelDomainModel } from '../../../../domainModels/label-domain-model';

@Component({
  selector: 'app-add-company-label',
  templateUrl: './add-company-label.component.html',
  styleUrls: ['./add-company-label.component.scss']
})
export class AddCompanyLabelComponent extends BaseComponent implements Tabbable {

  constructor(
    private labelDomainModel: LabelDomainModel,
    public viewModel: AddNewLabelViewModel
  ) {
    super();
  }

  public companyLabels$: Observable<Label[]> = this.labelDomainModel.companyCustomLabels$;
  public locationLabels$: Observable<Label[]> = this.labelDomainModel.locationCustomLabels$;

  public addableCompanyLabels$ = combineLatest([
    this.companyLabels$,
    this.locationLabels$
  ]).pipe(
    map(([
      companyLabels,
      locationLabels
    ]) => {
      return companyLabels.filter(cl => !locationLabels.find(ll => ll?.id === cl?.id)?.isCompanyManaged);
    })
  );

  public nonAddableCompanyLabels$ = combineLatest([
    this.companyLabels$,
    this.locationLabels$
  ]).pipe(
    map(([
      companyLabels,
      locationLabels
    ]) => {
      return companyLabels.filter(cl => locationLabels.find(ll => ll?.id === cl?.id)?.isCompanyManaged);
    })
  );

  public tabActivated: BehaviorSubject<boolean>;

  override setupViews(): void {
    this.viewModel.companyLabelsToAddToLocation$.next([]);
  }

  public companyLabelToggled(l: Label): void {
    this.viewModel.addOrRemoveLabelFromPool(l);
  }

}
