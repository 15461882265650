import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Observable } from 'rxjs';
import { LoadingOptions } from '../../../../../../../models/shared/loading-options';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { EditMenuHeaderActionsViewModel } from './edit-menu-header-actions-view-model';
import { Menu } from '../../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-edit-menu-header-actions',
  templateUrl: './edit-menu-header-actions.component.html',
  styleUrls: [
    './edit-menu-header-actions.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditMenuHeaderActionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuHeaderActionsComponent extends BaseComponent {

  constructor(
    public viewModel: EditMenuHeaderActionsViewModel
  ) {
    super();
  }

  @Input() allowLiveView: boolean = true;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() disableSave: boolean = false;
  @Input() hideLiveViewButton: boolean = false;
  @Input() lastSavedTimeText: string = '';
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() menu: Menu;
  @Input() showSaveButton: boolean = true;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  // emit true if you want the form to auto submit in the background
  @Output() submitForms = new EventEmitter<boolean>(true);

}
