import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { RequiredDisplayEditMethod } from '../collection-displays-modal-container/collection-displays-modal-container-view-model';
import { RequiredDisplayEditMethodViewModel } from './required-display-edit-method-view-model';

@Component({
  selector: 'app-required-display-edit-method',
  templateUrl: './required-display-edit-method.component.html',
  styleUrls: ['./required-display-edit-method.component.scss'],
  providers: [RequiredDisplayEditMethodViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequiredDisplayEditMethodComponent extends BaseComponent {

  constructor(public viewModel: RequiredDisplayEditMethodViewModel) {
    super();
  }

  @Output() editMethodSelected = new EventEmitter<RequiredDisplayEditMethod>();
  @Output() cancel = new EventEmitter();

}
