import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { EditMarketingMenuComponent } from '../edit-marketing-menu.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { EditUrlPlaylistMenuViewModel } from './edit-url-playlist-menu-view-model.service';

@Component({
  selector: 'app-edit-url-playlist-menu',
  templateUrl: './edit-url-playlist-menu.component.html',
  styleUrls: ['./edit-url-playlist-menu.component.scss'],
  providers: [EditUrlPlaylistMenuViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditUrlPlaylistMenuComponent extends EditMarketingMenuComponent  {

  constructor(
    public override viewModel: EditUrlPlaylistMenuViewModel,
    ngbModal: NgbModal,
    injector: Injector,
    toastService: ToastService,
    router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    super(viewModel, ngbModal, injector, toastService, router, activatedRoute);
  }

}
