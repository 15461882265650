<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (hasErrors$)="setHasError($event)">
  <div class="show-hide-switches-container">
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-switch
        *ngIf="!(menu | containsStackedContent) && !(menu | isFeaturedProductMenu)"
        [label]="'Hide Title'"
        [tooltip]="'Hides the title from the menu.'"
        [bindingProperty]="'menuOptions.hideTitle'"
        [inputName]="'hideTitle'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Label'"
        [tooltip]="'Hides all labels (ie: Low Stock, Sale, etc).'"
        [bindingProperty]="'menuOptions.hideLabel'"
        [inputName]="'hideLabel'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | containsStackedContent) && !(menu | isFeaturedProductMenu)"
        [label]="'Hide Logo'"
        [tooltip]="'Hides the company logo from the menu.'"
        [bindingProperty]="'menuOptions.hideLogo'"
        [inputName]="'hideLogo'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Sale'"
        [tooltip]="'Hides sale prices/labels.'"
        [bindingProperty]="'menuOptions.hideSale'"
        [inputName]="'hideSale'"
        [disabled]="isTemplatedMenu || hideSaleDisabled"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Inventory Labels'"
        [tooltip]="'Hides restock and low stock labels.'"
        [bindingProperty]="'menuOptions.hideInventoryLabels'"
        [inputName]="'hideInventoryLabels'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Price Decimal'"
        [tooltip]="'By default the price decimal values are shown, but they can be hidden (without rounding).'"
        [bindingProperty]="'menuOptions.hidePriceDecimal'"
        [inputName]="'hidePriceDecimal'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Cannabinoid Decimal'"
        [tooltip]="'Remove cannabinoid decimals by rounding values to the nearest integer.'"
        [bindingProperty]="'menuOptions.hideCannabinoidDecimal'"
        [inputName]="'hideCannabinoidDecimal'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Show Alternative Logo'"
        [tooltip]="'Swaps orignal logo with alternative logo. Alt logo must be setup in \'Company\' tab first.'"
        [bindingProperty]="'menuOptions.showAltLogo'"
        [inputName]="'showAltLogo'"
        [disabled]="!hasAltLogo || isTemplatedMenu || showAlternativeLogoDisabled"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="menu | supportsDescriptionOptions"
        (valueChanged)="setHideDescription($event)"
        [label]="'Hide Description'"
        [tooltip]="'Hide the description for the product.'"
        [bindingProperty]="'menuOptions.hideDescription'"
        [inputName]="'hideDescription'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true"></lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="menu | supportsDescriptionOptions"
        (valueChanged)="setShowFullDescription($event)"
        [label]="'Show Full Description'"
        [tooltip]="'Shows a product\'s full description instead of the short description.'"
        [bindingProperty]="'menuOptions.showFullDescription'"
        [inputName]="'showFullDescription'"
        [disabled]="isTemplatedMenu || (hideDescription$ | push)"
        [disabledTooltip]="showFullDescriptionDisabledTooltip$ | push"
        [programmaticallyChangeValue]="programmaticallyChangedShowFullDescription$ | push"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true"></lib-reactive-form-switch>
    </lib-reactive-form-column-layout>
  </div>
</lib-reactive-form-group>
