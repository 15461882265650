import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RangeSliderOptions } from '../../../../models/shared/stylesheet/range-slider-options';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: [
    './range-slider.component.scss',
    '../form-group/form-group.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class RangeSliderComponent {

  @Input() disabled: boolean = false;
  @Input() disabledTooltip: string;
  @Input() options: RangeSliderOptions = RangeSliderOptions.default();
  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  private debouncer: Subject<number> = new Subject<number>();

  constructor() {
    this.debouncer
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(val => {
        this.valueChanged.next(val);
      });
  }

  updateRangeValue(val: number) {
    this.options.value = val;
    this.debouncer.next(this.options.value);
  }

}
