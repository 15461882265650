import { Deserializable } from '../protocols/deserializable';
import { TimeDuration } from './time-duration';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import type { Menu } from '../menu/dto/menu';
import { MenuIntervalUtils } from '../../utils/menu-interval-utils';
import { OptionScale } from '../utils/dto/option-scale-type';

export const DEFAULT_ROTATION_INTERVAL = 30;

export class DisplayOptions implements Deserializable, UniquelyIdentifiable {

  public scale: OptionScale = OptionScale.CenterCrop;
  public rotationInterval: Map<string, number> = new Map();
  public rotationOrder: Map<string, number> = new Map();
  public overrides: Map<string, TimeDuration> = new Map();

  static defaultOptions(menu: Menu): DisplayOptions {
    const opts = new DisplayOptions();
    opts.rotationOrder = new Map<string, number>();
    opts.rotationOrder.set(menu?.id, 0);
    opts.rotationInterval = new Map<string, number>();
    const usesLoopingSystem = menu?.usesLoopingSystem();
    opts.rotationInterval.set(menu?.id, MenuIntervalUtils.getMenuRotationInterval(usesLoopingSystem));
    return opts;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    // If initializing new instance from properly mapped object, we can ignore the map deserialization
    if (!(this.rotationInterval instanceof Map)) {
      this.rotationInterval = Deserialize?.genericMap<string, number>(this.rotationInterval);
    } else {
      this.rotationInterval = new Map<string, number>(this.rotationInterval);
    }
    if (!(this.rotationOrder instanceof Map)) {
      this.rotationOrder = Deserialize?.genericMap<string, number>(this.rotationOrder);
    } else {
      this.rotationOrder = new Map<string, number>(this.rotationOrder);
    }
    if (!(this.overrides instanceof Map)) {
      this.overrides = Deserialize?.mapOf(TimeDuration, this.overrides);
    } else {
      this.overrides = new Map<string, TimeDuration>(this.overrides);
    }
  }

  public onSerialize(): DisplayOptions {
    const dto: DisplayOptions = Object.create(DisplayOptions.prototype);
    dto.scale = this.scale;
    dto.rotationOrder = this.rotationOrder;
    dto.rotationInterval = this.rotationInterval;
    dto.overrides = new Map<string, TimeDuration>();
    this.overrides?.forEach((override, key) => {
      dto.overrides.set(key, override?.onSerialize());
    });
    return dto;
  }

  isActiveInRotation(variantId: string): boolean {
    const interval = this.rotationInterval?.get(variantId);
    return Number.isFinite(interval) && (interval > 0);
  }

  public removeId(id: string) {
    this.rotationInterval?.delete(id);
    this.overrides?.delete(id);
    this.rotationOrder?.delete(id);
  }

  getUniqueIdentifier(): string {
    const scaleId = this.scale;
    const intervalIds: string[] = [];
    this.rotationInterval?.forEach((val, key) => {
      intervalIds.push(`${key}-${val}`);
    });
    const intervalId = intervalIds.sort().join(',') ?? '';
    const orderIds: string[] = [];
    this.rotationOrder?.forEach((val, key) => {
      orderIds.push(`${key}-${val}`);
    });
    const orderId = orderIds.sort().join(',') ?? '';
    const overridesIds: string[] = [];
    this.overrides?.forEach((val, key) => {
      orderIds.push(`${key}-${val.getUniqueIdentifier() ?? ''}`);
    });
    const overridesId = overridesIds.sort().join(',') ?? '';
    return `${scaleId}-${intervalId}-${orderId}-${overridesId}`;
  }

}
