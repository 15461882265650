import { Deserializable } from '../../protocols/deserializable';
import { InventoryProvider } from '../../utils/dto/inventory-provider-type';
import { InventoryRoom } from './inventory-room';
import { SortUtils } from '../../../utils/sort-utils';

export class InventoryProviderConfiguration implements Deserializable {

  public companyId: number;
  public provider: InventoryProvider;
  public providerCompanyId: string;
  public username: string;
  public password: string;
  public clientId: string;
  public clientSecret: string;
  public isLocationInventoryProvider: boolean;
  public inventoryRooms: InventoryRoom[];

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.inventoryRooms = Deserialize
      ?.arrayOf(InventoryRoom, this.inventoryRooms)
      ?.sort(SortUtils.nameAscending) ?? [];
  }

}
