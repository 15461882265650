import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collectionDisabledFormControlTooltip'
})
export class CollectionDisabledFormControlTooltipPipe implements PipeTransform {

  transform(isTemplated: boolean): string {
    if (isTemplated) {
      return 'This property may only be edited on the collection';
    }
    return null;
  }

}
