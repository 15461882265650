import { Component, Injector } from '@angular/core';
import { DisplayableContentContainerComponent } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionService } from '../../../../../../services/action.service';
import { PrintTemplatesContainerViewModel } from './print-templates-container-view-model';
import { DisplayableContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container-view-model';

@Component({
  selector: 'app-print-templates-container',
  templateUrl: '../../../../../shared/components/displayable-content/displayable-content-container/displayable-content-container.component.html',
  styleUrls: ['../../../../../shared/components/displayable-content/displayable-content-container/displayable-container.component.scss',
    './print-templates-container.component.scss'],
  providers: [{ provide: DisplayableContentContainerViewModel, useExisting: PrintTemplatesContainerViewModel}]
})
export class PrintTemplatesContainerComponent extends DisplayableContentContainerComponent {

  constructor(
    public override viewModel: PrintTemplatesContainerViewModel, // Provided by Logged In Scope
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector,
    actionService: ActionService
  ) {
    super(viewModel, navigationService, ngbModal, injector, actionService);
  }

}
