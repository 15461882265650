<div class="row mt-24px">
  <div class="col-4">
    <p class="bs-section-subtitle">Embedded URL</p>
    <div class="bs-section-subtext mt-16px">
      Paste in the URL that you would like to be loaded onto this display while this menu is shown.
    </div>
  </div>
  <div class="col-8">
    <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="bindTo">
      <lib-reactive-form-text-area
        fxFlex
        [inputName]="'linkedURL'"
        [label]="'URL'"
        [required]="true"
        [bindingProperty]="'linkedURL'"
        [placeholder]="'Enter the URL for the menu.'"
        [disabled]="sharedViewModel?.isTemplatedMenu$ | async"
        [disabledTooltip]="sharedViewModel?.isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip"
        [customValidators]="viewModel.validators">
      </lib-reactive-form-text-area>
    </lib-reactive-form-group>
  </div>
</div>
