import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductLabelsModule } from '../product-labels/product-labels.module';
import { GetDisplayLabelInterfaceForConfirmationModalPipe } from './confirmation-modal/get-display-label-interface-for-condirmation-modal.pipe';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ProductLabelsModule
  ],
  declarations: [
    ConfirmationModalComponent,
    GetDisplayLabelInterfaceForConfirmationModalPipe
  ],
  exports: [],
})
export class ConfirmationModule { }
