import { DisplayDomainModel } from '../../../domainModels/display-domain-model';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { Display } from '../../../models/display/dto/display';
import { Injectable, Injector } from '@angular/core';
import { DisplayMenuOptions } from '../../../models/display/shared/display-menu-options';
import { TimeDuration } from '../../../models/shared/time-duration';
import { BsError } from '../../../models/shared/bs-error';
import { ToastService } from '../../../services/toast-service';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, throwError } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { CompactMenu } from '../../../models/menu/dto/compact-menu';
import { NavigationService } from '../../../services/navigation.service';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { Variant } from '../../../models/product/dto/variant';
import { DistinctUtils } from '../../../utils/distinct-utils';
import { Orientation } from '../../../models/utils/dto/orientation-type';
import { Breadcrumb } from '../../../models/shared/stylesheet/breadcrumb';
import { ModalDisplayLiveView } from '../../../modals/modal-display-live-view';
import { ModalReorderMenus } from '../../../modals/modal-reorder-menus';
import { ModalAddMenuOrCollection } from '../../../modals/modal-add-menu-or-collection';
import { TemplateCollectionDomainModel } from '../../../domainModels/template-collection-domain-model';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { AutoSaveViewModel } from '../../shared/components/auto-save/auto-save-view-model';
import { BaseDisplay } from '../../../models/display/dto/base-display';
import { TemplateCollection } from '../../../models/template/dto/template-collection';
import { MenuTemplate } from '../../../models/template/dto/menu-template';
import { CompactTemplateCollection } from '../../../models/template/dto/compact-template-collection';
import { CompactItem } from '../../../models/shared/compact-item';
import { StringUtils } from '../../../utils/string-utils';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { TemplateStatus } from '../../../models/template/enum/template-status.enum';
import { Section } from '../../../models/menu/dto/section';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { CacheService } from '../../../services/cache-service';
import { PriceFormat } from '../../../models/utils/dto/price-format-type';
import type { Product } from '../../../models/product/dto/product';
import { exists } from '../../../functions/exists';

@Injectable()
export class EditDisplayViewModel extends AutoSaveViewModel {

  constructor(
    private cacheService: CacheService,
    private userDomainModel: UserDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private collectionDomainModel: TemplateCollectionDomainModel,
    private menuDomainModel: MenuDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private productDomainModel: ProductDomainModel,
    private locationDomainModel: LocationDomainModel,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private injector: Injector,
    private ngbModal: NgbModal,
  ) {
    super();
    this.init();
  }

  public readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;

  public dismissModalSubject: Subject<boolean> = new Subject<boolean>();

  init() {
    this.leaveIfDisplayNotFound();
  }

  public showLiveView = () => {
    combineLatest([
      this.display$,
      this.locationDomainModel.locationId$
    ]).once(([display, locationId]) => ModalDisplayLiveView.open(this.ngbModal, this.injector, display, locationId));
  };

  private _collectionMode = new BehaviorSubject<boolean>(false);
  public collectionMode$ = this._collectionMode as Observable<boolean>;
  connectToCollectionMode = (collectionMode) => this._collectionMode.next(collectionMode);

  public displayMode$ =  this.collectionMode$.pipe(map(collectionMode => !collectionMode));
  public collections$ = this.collectionDomainModel.templateCollections$;
  private currentLocationDisplays$ = this.displayDomainModel.currentLocationDisplays$;
  private hydratedDisplays$ = this.displayDomainModel.hydratedDisplays$;

  private fireIfDisplayMode$ = this.displayMode$.pipe(debounceTime(99), filter(displayMode => displayMode));
  private fireIfCollectionMode$ = this.collectionMode$.pipe(debounceTime(99), filter(collectionMode => collectionMode));

  private _originalOrientation = new BehaviorSubject<Orientation>(null);
  public originalOrientation$ = this._originalOrientation as Observable<Orientation>;

  public emptyDisplayText$ = this.collectionMode$.pipe(
    map((collectionMode) => {
      const text = 'Menus will appear in a list here. You can add, edit, and remove menus from here.';
      return StringUtils.replaceMenuWithTemplate(text, collectionMode);
    })
  );

  private selectActiveCollectionFromRouteParam = this.fireIfCollectionMode$
    .pipe(switchMap(_ => this.collections$))
    .notNull() // wait for collections to load in before trying to select one
    .pipe(switchMap(_ => this.activatedRoute.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const collectionId = params?.collectionId;
        if (!!collectionId) this.collectionDomainModel.selectActiveCollection(collectionId);
      }
    });

  // Display
  public displayId$ = combineLatest([this.collectionMode$, this.activatedRoute.params]).pipe(
    map(([collectionMode, params]) => (collectionMode ? params.collectionId : params.displayId)),
    distinctUntilChanged(),
    tap((id) => this.lastKnownDisplayModifiedTime = this.cacheService.getCachedGeneric(`last-modified-${id}`)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private fetchHydratedDisplay = this.fireIfDisplayMode$.pipe(
    switchMap(() => this.displayId$.notNull()),
    debounceTime(1),
    takeUntil(this.onDestroy)
  ).subscribe({
    next: (displayId) => this.getHydratedDisplay(displayId, this.lastKnownDisplayModifiedTime),
    error: (err) => console.error('getDisplay', err)
  });

  private fetchHydratedCollection = this.fireIfCollectionMode$.pipe(
    switchMap(() => this.displayId$.notNull()),
    debounceTime(1),
    takeUntil(this.onDestroy)
  ).subscribe({
    next: (displayId) => this.getHydratedCollection(displayId),
    error: (err) => console.error('getCollection', err)
  });

  private displayWithoutMenuOptions$ = this.fireIfDisplayMode$.pipe(
    switchMap(() => combineLatest([
      this.displayId$,
      this.hydratedDisplays$,
      this.menuDomainModel.menuThemes$.pipe(filter(themes => themes?.length > 0))
    ])),
    debounceTime(250),
    map(([displayId, displays, themes]) => {
      const display = displays?.get(displayId);
      display?.getMenus()?.forEach(menu => menu.hydratedTheme = themes?.find(t => t?.id === menu?.theme));
      return display;
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  private collectionWithoutMenuOptions$ = this.fireIfCollectionMode$.pipe(
    switchMap(() => combineLatest([
      this.collectionDomainModel.activeCollection$,
      this.menuDomainModel.menuThemes$.pipe(filter(themes => themes?.length > 0))
    ])),
    debounceTime(250),
    map(([collection, themes]) => {
      if (!!collection) {
        collection?.getMenus()?.forEach(menu => menu.hydratedTheme = themes?.find(t => t?.id === menu?.theme));
        return collection;
      } else {
        return null;
      }
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public constructDisplay$ = combineLatest([
    this.displayWithoutMenuOptions$,
    this.productDomainModel.currentLocationProducts$,
    this.productDomainModel.currentLocationVariants$,
    this.locationConfig$
  ]).pipe(
    tap(([display]) => this._originalOrientation.next(display?.displaySize?.orientation)),
    map(([display, locProducts, locVariants, locationConfig]) => {
      return this.createMenuOptions(display, locProducts, locVariants, locationConfig?.priceFormat);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public constructCollection$ = combineLatest([
    this.collectionWithoutMenuOptions$,
    this.productDomainModel.currentLocationProducts$,
    this.productDomainModel.currentLocationVariants$,
    this.locationConfig$
  ]).pipe(
    map(([collection, locProducts, locVariants, locationConfig]) => {
      return this.createMenuOptions(collection, locProducts, locVariants, locationConfig?.priceFormat);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  private lastKnownDisplayModifiedTime: number = null;

  public display$ = this.collectionMode$.pipe(
    switchMap((collectionMode) => {
      return iif(() => collectionMode, this.constructCollection$, this.constructDisplay$);
    }),
    tap(display => {
      this.cacheService.cacheGeneric(`last-modified-${display?.id}`, display?.lastModified);
      this.lastKnownDisplayModifiedTime = display?.lastModified;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

   public compactMenus$ = combineLatest([
     this.display$,
     this.productDomainModel.currentLocationProducts$,
     this.productDomainModel.currentLocationVariants$,
     this.locationId$,
     this.locationConfig$
    ]).pipe(
     map(([display, locProducts, locVariants, locationId, locationConfig]) => {
       const compactMenus: CompactMenu[] = [];
       // eslint-disable-next-line @typescript-eslint/prefer-for-of
       // For loop is faster here as map makes a copy of every object
       const menus = display?.getMenus() ?? [];
       for (let i = 0; i < menus?.length; i++) {
         compactMenus.push(new CompactMenu(menus[i], locProducts, locVariants, locationId, locationConfig));
       }
       // Sort menus based on rotation order
       return compactMenus.sort((a, b) => {
         return display?.options?.rotationOrder?.get(a.id) - display?.options?.rotationOrder?.get(b.id);
       });
     }),
     shareReplay({bufferSize: 1, refCount: true})
   );

  public numberOfMenus$ = this.display$.pipe(
    map((d) => {
      let nMenus = d?.getMenus()?.length;
      if (d instanceof Display) nMenus += d?.templateCollectionIds?.length;
      return nMenus;
    })
  );

  public compactTemplateCollections$ = combineLatest([
    this.display$,
    this.productDomainModel.currentLocationProducts$,
    this.productDomainModel.currentLocationVariants$,
    this.locationId$,
    this.locationConfig$
  ]).pipe(
    map(([display, locProducts, locVariants, locationId, locationConfig]) => {
      if (!(display instanceof Display)) return [];
      return display.templateCollections?.map((collection) => {
        this.setPriorityForCollectionOnDisplay(display, collection);
        const collectionWithOptions = this.createMenuOptions(
          collection,
          locProducts,
          locVariants,
          locationConfig?.priceFormat
        );
        const compactMenus = (collectionWithOptions?.getMenus() ?? [])
          .map((m) => new CompactMenu(m, locProducts, locVariants, locationId, locationConfig))
          .sort((a, b) => {
            return collection?.options?.rotationOrder?.get(a.id) - collection.options?.rotationOrder?.get(b.id);
          });
        return new CompactTemplateCollection(collection, compactMenus);
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public compactItems$: Observable<CompactItem[]> = combineLatest([
    this.display$,
    this.compactMenus$,
    this.compactTemplateCollections$
  ]).pipe(
    map(([display, compactMenus, compactCollections]) => {
      const items = [...(compactMenus || []), ...(compactCollections || [])];
      return items.sort((a, b) => {
        return display?.options?.rotationOrder?.get(a.id) - display?.options?.rotationOrder?.get(b.id);
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _menuOptions = new BehaviorSubject<Map<string, Map<string, DisplayMenuOptions>>>(new Map());
  public menuOptions$ = this._menuOptions as Observable<Map<string, Map<string, DisplayMenuOptions>>>;

  private _enabledMenuOverrides = new BehaviorSubject<Map<string, Map<string, boolean>>>(new Map());
  public enabledMenuOverrides$ = this._enabledMenuOverrides as Observable<Map<string, Map<string, boolean>>>;
  public setEnabledMenuOverrideState = (containerId: string, menuId: string, enabled: boolean) => {
    this.enabledMenuOverrides$.once((menuOverrideMap) => {
      if (!menuOverrideMap?.has(containerId)) menuOverrideMap?.set(containerId, new Map<string, boolean>());
      menuOverrideMap?.get(containerId)?.set(menuId, enabled);
      this._enabledMenuOverrides.next(menuOverrideMap);
    });
  };

  // Breadcrumbs
  public breadcrumbs$ = this.display$.pipe(
    map((display) => {
      const breadcrumbs = [];
      const dispBc = new Breadcrumb(
        'Displays',
        'displays',
      );
      breadcrumbs.push(dispBc);
      const editDispBc = new Breadcrumb(
        'Edit Display',
        `displays/${display?.id}`,
      );
      editDispBc.active = true;
      breadcrumbs.push(editDispBc);
      return breadcrumbs;
    })
  );

  private leaveIfDisplayNotFound(): void {
    this.fireIfDisplayMode$.pipe(
      switchMap(_ => {
        return combineLatest([
          this.userDomainModel.validSession$,
          this.displayId$,
          this.currentLocationDisplays$,
        ]);
      }),
      debounceTime(1)
    ).subscribeWhileAlive({
      owner: this,
      next: ([validSession, displayId, locationDisplays]) => {
        const validDisplayId = !!displayId;
        const validLocationDisplays = !!locationDisplays;
        const invalidDisplay = !(validDisplayId && this.displayExists(locationDisplays, displayId)) && validSession;
        if (invalidDisplay && validLocationDisplays) this.navigationService.displays();
      }
    });
  }

  public displayExists(displays: BaseDisplay[], id: string): boolean {
    const activeDisplay = displays?.find(d => d.id === id);
    return !!displays && !!activeDisplay;
  }

  private setPriorityForCollectionOnDisplay(display: Display, collection: TemplateCollection) {
    const opts = new DisplayMenuOptions();
    opts.priority = display?.options?.rotationOrder?.get(collection?.id) ?? 0;
    this.updateDisplayOptionsIfTheyAreDifferentFromCurrent(display?.id, collection?.id, opts);
  }

  private createMenuOptions<T extends BaseDisplay>(
    d: T,
    menuProducts: Product[],
    locVariants: Variant[],
    priceFormat: PriceFormat
  ): T {
    d?.getMenus()?.forEach((config) => {
      const opts = new DisplayMenuOptions();
      opts.priority = d?.options?.rotationOrder?.get(config.id) ?? 0;
      const usesLoopingSystem = config?.usesLoopingSystem();
      const defaultInterval = usesLoopingSystem ? 1 : 60;
      opts.interval = d?.options?.rotationInterval?.get(config.id) ?? defaultInterval;
      const menuVariantIds = config.getSectionsBasedOnMenuType()?.flatMap(s => {
        return this.getEnabledVariantIds(s);
      }).unique(true);
      const menuVariants = config?.getSectionsBasedOnMenuType()?.length
        ? locVariants.filter(v => menuVariantIds?.contains(v.id))
        : config?.hydratedVariantFeature?.variants;
      if (usesLoopingSystem) {
        opts.playlistDuration = config?.calculateLongestLoopDuration(menuProducts, menuVariants, priceFormat);
      }
      opts.marketingTotalTime = opts?.interval * opts?.playlistDuration;
      opts.override = d?.options?.overrides?.get(config?.id);
      if (!opts?.override) {
        // Set empty override options
        opts.override = new TimeDuration();
      }
      const hasOverrideState = this.checkForOverrideDateTime(d, config?.id, opts?.override);
      this.setEnabledMenuOverrideState(d?.id, config?.id, hasOverrideState);
      this.updateDisplayOptionsIfTheyAreDifferentFromCurrent(d?.id, config?.id, opts);
    });
    return d;
  }

  private checkForOverrideDateTime<T extends BaseDisplay>(display: T, menuId: string, override: TimeDuration): boolean {
    const containerId = display?.id;
    let overrides = display?.options?.overrides;
    if (containerId !== display?.id && display instanceof Display) {
      const templateCollection = display?.templateCollections?.find(tc => tc?.id === containerId);
      overrides = templateCollection?.options?.overrides;
    }
    const timeDuration = overrides?.get(menuId);
    return timeDuration?.isRecurring() || timeDuration?.hasDateTimeWindows();
  }

  private getEnabledVariantIds(s: Section) {
    const sectionEnabledVariantIds = s?.enabledVariantIds ?? [];
    const templateSectionEnabledVariantIds = s?.templateSection?.enabledVariantIds ?? [];
    return [...sectionEnabledVariantIds, ...templateSectionEnabledVariantIds];
  }

  public getHydratedDisplay(displayId: string, lastModified?: number) {
    const loadingOpts = this._loadingOpts;
    const lm = 'Loading Display';
    if (!loadingOpts.containsRequest(lm)) {
      loadingOpts.addRequest(lm);
      this.displayDomainModel.getDisplay(displayId, lastModified).pipe(delay(2000)).subscribe({
        complete: () => loadingOpts.removeRequest(lm),
        error: (error: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    }
  }

  public getHydratedCollection(displayId: string) {
    const loadingOpts = this._loadingOpts;
    const lm = 'Loading Template Collection';
    if (!loadingOpts.containsRequest(lm)) {
      loadingOpts.addRequest(lm);
      this.collectionDomainModel.getTemplateCollection(displayId).pipe(delay(2000)).subscribe((_) => {
        loadingOpts.removeRequest(lm);
      }, (error: BsError) => {
        loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  public saveDisplay(background: boolean = false) {
    this.display$.once(display => {
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      const isCollection = display instanceof TemplateCollection;
      const stringReplacer = StringUtils.replaceDisplayWithTemplateCollection;
      const lm = stringReplacer('Updating Display', isCollection);
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        this.applyMenuOptionsToDisplay(display).pipe(
          switchMap((d) => {
            return d instanceof Display
              ? this.displayDomainModel.updateDisplays([d])
              : this.updateTemplateCollectionAndReloadDisplays(d);
          })
        ).subscribe({
          complete: () => {
            const successMessage = stringReplacer('Display successfully updated.', isCollection);
            const successTitle = stringReplacer('Update Display', isCollection);
            if (!background) this.toastService.publishSuccessMessage(successMessage, successTitle);
            this.setLastAutoSavedTimestampToNow();
            this.destroyAllTimerSubs();
            loadingOpts.removeRequest(lm);
          },
          error: (err: BsError) => {
            this.toastService.publishError(err);
            loadingOpts.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

  public updateTemplateCollectionAndReloadDisplays = (c: TemplateCollection): Observable<Display[]> => {
    const published = c?.status === TemplateStatus.Published;
    return this.collectionDomainModel.updateTemplateCollection(c, true).pipe(
      tap(() => published && this.displayDomainModel.loadDisplaysForCurrentLocation()),
      switchMap(() => {
        return published && exists(c?.pendingDisplay)
          ? this.displayDomainModel.getCompanyDisplays()
          : of(null);
      })
    );
  };

  public updateDisplay = (d: Display): Observable<Display[]> => this.displayDomainModel.updateDisplays([d]);

  public saveDisplayMenuOrder(): Observable<boolean> {
    // Apply menu options to display metadata
    return this.display$.pipe(
      take(1),
      tap(_ => this.destroyAllTimerSubs()),
      switchMap(display => combineLatest([
        this.applyMenuOptionsToDisplay(display),
        this.collectionDomainModel.hydratedTemplateCollections$,
        this.locationDomainModel.locationId$
      ])),
      take(1),
      switchMap(([updatedDisplay, hydratedCollections, locationId]) => {
        if (updatedDisplay instanceof Display) {
          return this.displayDomainModel.updateDisplays([updatedDisplay]);
        }
        hydratedCollections?.get(locationId)?.set(updatedDisplay.id, updatedDisplay);
        this.collectionDomainModel.connectToHydratedTemplateCollections(hydratedCollections);
        return this.collectionDomainModel.updateTemplateCollection(updatedDisplay);
      }),
      map(updatedData => (updatedData instanceof Array ? updatedData?.length > 0 : !!updatedData))
    );
  }

  public removeMenu(removingLoadingOpts: BehaviorSubject<LoadingOptions>, m: CompactMenu): void {
    const removingMessage = 'Removing';
    let lm: string;
    let successMessage: string;
    let successTitle: string;
    this.display$.pipe(
      tap((display) => {
        if (display instanceof Display) {
          lm = `Deleting Menu ${m.id}`;
          successMessage = 'Display successfully updated';
          successTitle = 'Update Display';
        }
        if (display instanceof TemplateCollection) {
          lm = `Deleting Template ${m.id}`;
          successMessage = 'Template Collection successfully updated';
          successTitle = 'Update Template Collection';
        }
      }),
      take(1),
      switchMap((display) => {
        if (!this._autoSaveLoadingOpts.containsRequest(lm)) {
          removingLoadingOpts.addRequest(removingMessage);
          this._autoSaveLoadingOpts.addRequest(lm);
          let displayUpdater$: Observable<Display[] | TemplateCollection>;
          if (display instanceof Display) {
            displayUpdater$ = this.displayDomainModel.removeMenuFromDisplay(m.id, display);
          } else if (display instanceof TemplateCollection) {
            displayUpdater$ = this.collectionDomainModel.removeTemplateFromCollection(m.id, display);
          } else {
            displayUpdater$ = of(null);
          }
          return displayUpdater$;
        }
      }),
      take(1),
    ).subscribe({
      complete: () => {
        removingLoadingOpts.removeRequest(removingMessage);
        this._autoSaveLoadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage(successMessage, successTitle);
      },
      error: (error: BsError) => {
        removingLoadingOpts.removeRequest(removingMessage);
        this._autoSaveLoadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      }
    });
  }

  public removeCollection(removingLoadingOpts: BehaviorSubject<LoadingOptions>, c: CompactTemplateCollection): void {
    const lm = `Deleting Template Collection ${c.id}`;
    const removingMessage = 'Removing';
    this.display$.pipe(
      switchMap((display) => {
        if (!this._autoSaveLoadingOpts.containsRequest(lm)) {
          removingLoadingOpts.addRequest(removingMessage);
          this._autoSaveLoadingOpts.addRequest(lm);
          return this.displayDomainModel.removeCollectionFromDisplay(c.id, display as Display);
        }
      }),
      take(1)
    ).subscribe({
      complete: () => {
        removingLoadingOpts.removeRequest(removingMessage);
        this._autoSaveLoadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Display successfully updated', 'Update Display');
      },
      error: (error: BsError) => {
        removingLoadingOpts.removeRequest(removingMessage);
        this._autoSaveLoadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      }
    });
  }

  public navigateToTemplateCollection(c: CompactTemplateCollection) {
    this.display$.once(d => {
      const display = d as Display;
      const collection = display?.templateCollections?.find(tc => tc?.id === c?.id);
      if (!!collection) {
        this.navigationService.navigateToTemplateCollection(collection);
      } else {
        this.navigationService.displays();
      }
    });
  }

  public addMenusToDisplay = (menuIds: string[], loadingOpts: BehaviorSubject<LoadingOptions>) => {
    combineLatest([this.menuOptions$, this.display$]).once(([menuOptions, display]) => {
      const isCollection = display instanceof TemplateCollection;
      const allMenus$ = isCollection
        ? this.templateDomainModel.menuTemplates$
        : this.menuDomainModel.currentLocationMenus$;
      const lm = StringUtils.replaceMenuWithTemplate('Updating Menus', isCollection);
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        allMenus$.pipe(
          take(1),
          switchMap(allMenus => {
            menuIds?.forEach((mid) => {
              const menu = allMenus?.find(m => m?.id === mid);
              const displayOptions = DisplayMenuOptions.default(
                menu?.usesLoopingSystem(),
                menuOptions?.get(display?.id)
              );
              this.updateDisplayOptionsIfTheyAreDifferentFromCurrent(display?.id, mid, displayOptions);
              this.setEnabledMenuOverrideState(display?.id, mid, false);
            });
            return this.applyMenuOptionsToDisplay(display).pipe(withLatestFrom(of(allMenus)));
          }),
          switchMap(([updatedData, allMenus]) => {
            return (updatedData instanceof TemplateCollection)
              ? this.collectionDomainModel.addTemplatesToCollection(menuIds, updatedData, allMenus as MenuTemplate[])
              : this.displayDomainModel.addMenusToDisplay(menuIds, updatedData, allMenus);
          }),
          take(1)
        ).subscribe({
          complete: () => {
            loadingOpts.removeRequest(lm);
            const stringReplacer = StringUtils.replaceDisplayWithTemplateCollection;
            const successMessage = stringReplacer('Display successfully updated.', isCollection);
            const successTitle =  stringReplacer('Update Display', isCollection);
            this.toastService.publishSuccessMessage(successMessage, successTitle);
            this.ngbModal.dismissAll();
          },
          error: (err) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
          }
        });
      }
    });
  };

  public addCollectionsToDisplay = (collectionIds: string[], loadingOpts: BehaviorSubject<LoadingOptions>) => {
    const lm = 'Adding Template Collections';
    if (!loadingOpts.containsRequest(lm)) {
      loadingOpts.addRequest(lm);
      combineLatest([this.menuOptions$, this.display$]).pipe(
        take(1),
        switchMap(([menuOptions, display]) => {
          collectionIds?.forEach((cid) => {
            const opts = menuOptions?.get(display?.id);
            const collectionDisplayOptions = new DisplayMenuOptions();
            collectionDisplayOptions.priority = this.getNextPriority(opts);
            this.updateDisplayOptionsIfTheyAreDifferentFromCurrent(display?.id, cid, collectionDisplayOptions);
          });
          return this.applyMenuOptionsToDisplay(display as Display);
        }),
        switchMap(updatedData => this.displayDomainModel.addCollectionsToDisplay(collectionIds, updatedData)),
        take(1)
      ).subscribe({
        complete: () => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Display successfully updated.', 'Update Display');
          this.ngbModal.dismissAll();
        },
        error: (err) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
        }
      });
    }
  };

  private getNextPriority(menuOptions: Map<string, DisplayMenuOptions>) {
    let nextPriority = 0;
    menuOptions?.forEach((option) => {
      if (option?.priority >= nextPriority) {
        nextPriority = option.priority + 1;
      }
    });
    return nextPriority;
  }

  public applyMenuOptionsToDisplay<T extends BaseDisplay>(display: T): Observable<T> {
    const rotationInterval = new Map<string, number>();
    const rotationOrder = new Map<string, number>();
    const rotationOverrides = new Map<string, TimeDuration>();
    let defaultMenuId: string = '';
    const defaultMenuOrder: number = 999;
    return this.menuOptions$.pipe(
      take(1),
      map(menuOptions => {
        menuOptions?.get(display?.id)?.forEach((opts, menuId) => {
          rotationInterval.set(menuId, opts.interval);
          rotationOrder.set(menuId, opts.priority);
          if (opts.priority < defaultMenuOrder) {
            defaultMenuId = menuId;
          }
          rotationOverrides.set(menuId, opts.override);
        });
        display.options.rotationInterval = rotationInterval;
        display.options.rotationOrder = rotationOrder;
        display.options.overrides = rotationOverrides;
        return display;
      })
    );
  }

  private updateDisplayOptionsIfTheyAreDifferentFromCurrent(
    containerId: string,
    id: string,
    displayOptions: DisplayMenuOptions
  ) {
    this.menuOptions$.once((menuOptions) => {
      if (!menuOptions?.has(containerId)) menuOptions?.set(containerId, new Map<string, DisplayMenuOptions>());
      const currentOpts = menuOptions?.get(containerId)?.get(id);
      // don't switch the options out if they are the same. If you switch the options out when they are the same,
      // it will cause the forms to flicker as they reinitialize with the same values.
      if (!DistinctUtils.distinctUniquelyIdentifiable(currentOpts, displayOptions)) {
        menuOptions.get(containerId).set(id, displayOptions);
        this._menuOptions.next(menuOptions);
      }
    });
  }

  public trackById(index: number, item: CompactItem): string {
    return item.id;
  }

  public copyDisplayUrl() {
    this.display$.once((display) => {
      if (display instanceof Display) {
        navigator.clipboard.writeText(display?.shortUrl).then((_) => {
          return this.toastService.publishInfoMessage('Link copied to clipboard', 'Link Copied!');
        });
      }
    });
  }

  public reorderMenus = () => {
    combineLatest([
      this.display$,
      this.compactItems$,
      this.menuOptions$,
      this.collectionMode$,
      this.userDomainModel.canUseTemplates$
    ]).once(([
      display,
      compactItems,
      menuOptions,
      collectionMode,
      companySupportsTemplates
    ]) => {
      const reorderOperation = (items: CompactItem[]): Observable<boolean> => {
        // Apply new menu priority
        items.forEach((item) => {
          const opts = menuOptions?.get(display.id)?.get(item.id);
          opts.priority = item.displayPriority;
          menuOptions.get(display.id)?.set(item.id, opts);
        });
        return this.saveDisplayMenuOrder();
      };
      const displayIcons = companySupportsTemplates && !collectionMode;
      ModalReorderMenus.open(
        this.ngbModal,
        this.injector,
        display,
        compactItems,
        menuOptions?.get(display.id),
        reorderOperation,
        collectionMode,
        displayIcons
      );
    });
  };

  public addMenuToDisplay = () => {
    combineLatest([this.display$, this.collectionMode$]).once(([display, collectionMode]) => {
      ModalAddMenuOrCollection.open(
        this.ngbModal,
        this.injector,
        display,
        collectionMode,
        this.addMenusToDisplay,
        this.addCollectionsToDisplay
      );
    });
  };

}
