import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SmartFilterSelectionComponent } from '../smart-filter-selection.component';
import { SmartFilterGrouping } from '../../../../../models/automation/smart-filter-grouping';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-smart-filter-group-selection',
  templateUrl: '../smart-filter-selection.component.html',
  styleUrls: [
    '../smart-filter-selection.component.scss',
    './smart-filter-group-selection.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterGroupSelectionComponent extends SmartFilterSelectionComponent {

  @Input() override selection: SmartFilterGrouping;
  @Output() bulkAdd = new EventEmitter<string[]>(true);
  @Output() bulkRemove = new EventEmitter<string[]>(true);

  protected override _selection = new BehaviorSubject<SmartFilterGrouping>(null);
  public groupIds$ = this._selection.pipe(map(selection => selection?.getIds()));
  public percentageChecked$ = combineLatest([this.groupIds$, this.selectedIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, selectedIds, previouslyAddedIds]) => {
      const groupSize = groupIds?.length || 1;
      const selected = groupIds?.filter(id => selectedIds.contains(id) || previouslyAddedIds.contains(id)) || [];
      const nSelected = selected?.length ?? 0;
      return nSelected / groupSize;
    })
  );
  public override indeterminate$ = this.percentageChecked$.pipe(map(p => p > 0 && p < 1));
  public override selected$ = this.percentageChecked$.pipe(map(p => p === 1));
  public override previouslyAdded$ = combineLatest([this.groupIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, prevAdded]) => groupIds?.map(id => prevAdded?.contains(id))?.every(contained => contained))
  );
  public newlySelectedGroupIds$ = combineLatest([this.groupIds$, this.selectedIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, selected, prevSelected]) => {
      return (groupIds?.filter(id => selected.contains(id) && !prevSelected.contains(id)) || []);
    })
  );
  public groupIdsNotPreviouslyAdded$ = combineLatest([this.groupIds$, this.previouslyAddedIds$]).pipe(
    map(([groupIds, prevAddedIds]) => groupIds?.filter(id => !prevAddedIds.contains(id)))
  );

  constructor() {
    super();
  }

  public override clicked(selected: boolean) {
    if (selected) {
      this.groupIdsNotPreviouslyAdded$.pipe(take(1)).subscribe(addUs => this.bulkAdd.emit(addUs));
    } else {
      this.newlySelectedGroupIds$.pipe(take(1)).subscribe(removeUs => this.bulkRemove.emit(removeUs));
    }
  }

}
