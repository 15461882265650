<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ confirmationOptions?.title }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <!-- Keep this one line because of white space attribute set to pre-wrap -->
    <div class="confirmation-text">{{ confirmationOptions?.bodyText }}</div>
    <ng-container *ngIf="!!confirmationOptions?.label">
      <div class="label-container">
        <app-label-inflator
          [zoom]="0.9"
          [displayLabelInterface]="
            confirmationOptions?.label
              | getDisplayLabelInterfaceForConfirmationModal : viewModel.configurations$
              | async
          ">
        </app-label-inflator>
      </div>
    </ng-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <button (click)="cancel()" [ngClass]="confirmationOptions?.cancelButtonClass" class="bs-button">
      {{ confirmationOptions?.cancelText }}
    </button>
    <button
      *ngIf="confirmationOptions?.showContinue"
      (click)="continue()"
      [ngClass]="confirmationOptions?.continueButtonClass"
      class="bs-button">
      {{ confirmationOptions?.continueText }}
    </button>
  </div>
</div>
