import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { Injectable } from '@angular/core';
import { ProductDomainModel } from '../../../../../../../../domainModels/product-domain-model';
import { HasChildIds } from '../../../../../../../../models/protocols/has-child-ids';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { iiif } from '../../../../../../../../utils/observable.extensions';
import type { Product } from '../../../../../../../../models/product/dto/product';
import type { StackType } from '../create-view-stack-print-job.component';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { PriceFormat } from '../../../../../../../../models/utils/dto/price-format-type';
import { VariantGroup } from '../../../../../../../../models/product/shared/variant-group';
import { SortUtils } from '../../../../../../../../utils/sort-utils';
import { LocationDomainModel } from '../../../../../../../../domainModels/location-domain-model';

@Injectable()
export class StackPrintJobProductsFormViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private menuDomainModel: MenuDomainModel,
    private productDomainModel: ProductDomainModel,
    private templateDomainModel: TemplateDomainModel
  ) {
  }

  public readonly priceFormat$ = this.locationDomainModel.priceFormat$.pipe(
    map(priceFormat => priceFormat || PriceFormat.Default),
  );

  private _stackType = new BehaviorSubject<StackType>('card');
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (type: StackType) => this._stackType.next(type);

  public readonly isCardStack$ = this.stackType$.pipe(map(type => type === 'card'));
  public readonly isLabelStack$ = this.stackType$.pipe(map(type => type === 'label'));

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private readonly _searchText = new BehaviorSubject<string>('');
  public readonly searchText$ = this._searchText as Observable<string>;
  connectToSearchText = (searchText: string) => this._searchText.next(searchText);

  private _expandedProductId = new BehaviorSubject<string>('');
  public expandedProductId$ = this._expandedProductId as Observable<string>;

  public description$ = this.viewOnly$.pipe(
    map(viewOnly => {
      return viewOnly
        ? 'View which stack variants were included in this print job.'
        : 'Choose which stack variants you want included in this print job.';
    })
  );

  private cardStackMenu$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplate$,
    this.menuDomainModel.activeHydratedMenu$
  );
  private cardStackMenuId$ = this.cardStackMenu$.pipe(map(cardStack => cardStack?.id));
  private cardStackSection$ = this.cardStackMenu$.pipe(
    map(cardStack => cardStack?.getSectionsBasedOnMenuType()?.firstOrNull())
  );

  public cardStackPrintConfig$ = combineLatest([
    this.job$,
    this.cardStackMenuId$
  ]).pipe(
    map(([job, cardStackId]) => job?.cardStackPrintConfigMap?.get(cardStackId)),
    tap(cardStackPrintConfig => {
      if (cardStackPrintConfig) {
        this.connectToVariantIdsToBeAdded(cardStackPrintConfig.variantIds);
        this.connectToVariantLabelCountMap(cardStackPrintConfig?.variantCardCountMap);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public cardStackProductIds$ = this.cardStackSection$.pipe(
    map(section => section?.productIds ?? [])
  );

  public cardStackIsGridMode$ = this.cardStackSection$.pipe(
    map(section => section?.isGridMode())
  );

  public cardStackGridModeColumnNames$ = this.cardStackSection$.pipe(
    map(section => section?.getGridModeColumnNamesOrNull()?.split(','))
  );

  private search = (haystack: string, needle: string) => {
    return haystack?.toLowerCase()?.trim()?.includes(needle?.toLowerCase()?.trim());
  };

  public readonly productIds$ = this.cardStackMenu$.pipe(
    map(menu => menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.productIds),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly productsWithinStack$: Observable<Product[]> = combineLatest([
    this.productIds$,
    this.productDomainModel.currentLocationProducts$
  ]).pipe(
    map(([productIds, products]) => {
      return productIds
        ?.map(productId => products?.find(product => product?.id === productId))
        ?.filterNulls();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * This can be a little confusing in the context of stacked content (cards/labels/etc). We don't call
   * spreadLargePrintCardGridAcrossMultipleCards in here, because we are letting them decide which variants
   * are relevant, so pre-splitting the variants into chunks would be confusing for variant selection.
   */
  public readonly chunkedVariants$: Observable<Variant[][]> = combineLatest([
    this.cardStackMenu$,
    this.cardStackSection$,
    this.productsWithinStack$,
    this.priceFormat$
  ]).pipe(
    map(([stackMenu, stack, productsWithinStack, priceFormat]) => {
      const scoped = productsWithinStack?.map(p => {
        return stack?.getScopedVisibleVariants(productsWithinStack, p?.variants, stackMenu, priceFormat, false);
      });
      const variantGroups = scoped?.map(variantsInGroup => {
        const product = productsWithinStack?.find(p => p?.id === variantsInGroup?.firstOrNull()?.productId);
        return new VariantGroup(product, variantsInGroup, true);
      });
      return SortUtils
        ?.variantGroupsBySortOptions(variantGroups, stackMenu, stack, priceFormat)
        ?.map(it => it?.variants);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleVariantIds$ = this.chunkedVariants$.pipe(
    map(chunkedVariants => chunkedVariants?.flatten<Variant[]>()?.map(v => v?.id)?.unique()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * We can't directly pluck products from the section, because the frontend needs to filter out irrelevant
   * variants from the product. Therefore, we need to find which variants are relevant to the product and
   * then rebuild the product with the correct variants.
   */
  public readonly productsWithCorrectVariants$ = combineLatest([
    this.chunkedVariants$,
    this.productsWithinStack$
  ]).pipe(
    map(([chunkedVariants, productsWithinStack]) => {
      return chunkedVariants
        ?.map(variantChunk => {
          const vId = variantChunk?.firstOrNull()?.id;
          const product = productsWithinStack?.find(p => p?.hasVariantById(vId));
          return product?.shallowCopyAndReplaceVariants(variantChunk) || null;
        })
        ?.filterNulls();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchedProductsWithCorrectVariants$: Observable<Product[]> = combineLatest([
    this.searchText$,
    this.productsWithCorrectVariants$,
  ]).pipe(
    map(([searchText, products]) => {
      let searchedProducts = products;
      if (searchText?.length >= 2) {
        searchedProducts = searchedProducts?.filter(product => {
          const productNameHit = this.search(product?.getProductTitle(), searchText);
          const hasVariantHits = product?.variants?.some(v => this.search(v?.getVariantTitle(), searchText));
          return productNameHit || hasVariantHits;
        });
      }
      return searchedProducts;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly emptySearch$ = combineLatest([
    this.searchText$,
    this.searchedProductsWithCorrectVariants$
  ]).pipe(
    map(([searchText, hits]) => searchText?.length >= 2 && !hits?.length),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly noVariantsForSearchPlaceholder$ = this.searchText$.pipe(
    map(searchText => `No products/variants found for "${searchText}"`),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public searchedStackVariantIds$: Observable<string[]> = combineLatest([
    this.searchText$,
    this.visibleVariantIds$,
    this.searchedProductsWithCorrectVariants$,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([searchText, visibleVariantIds, searchedProductsWithCorrectVariants, locationVariants]) => {
      if (searchText?.length >= 2) {
        const variantIdHits: string[] = [];
        searchedProductsWithCorrectVariants?.forEach(product => {
          if (this.search(product?.getProductTitle(), searchText)) {
            variantIdHits.push(...(product?.variants?.map(v => v?.id) || []));
          } else {
            const variantHits = product?.variants?.filter(v => this.search(v?.getVariantTitle(), searchText));
            variantIdHits.push(...(variantHits?.map(v => v?.id) || []));
          }
        });
        return visibleVariantIds
          ?.unique()
          ?.filter(id => variantIdHits?.includes(id));
      }
      return visibleVariantIds;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly allVisibleVariantsInStack$ = this.chunkedVariants$.pipe(
    map(chunkedVariants => chunkedVariants?.flatten<Variant[]>()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _variantIdsToBeAdded = new BehaviorSubject<string[]>([]);
  public variantIdsToBeAdded$ = this._variantIdsToBeAdded as Observable<string[]>;
  connectToVariantIdsToBeAdded = (ids: string[]) => this._variantIdsToBeAdded.next(ids);

  public allCardStackVariantIdsAsSelectionItem$ = this.searchedStackVariantIds$.pipe(
    map((variantIds) => {
      return new class implements HasChildIds {

        public getId(): string {
          return '';
        }
        public getChildIds(): string[] {
          return variantIds;
        }

      }();
    })
  );

  private _variantLabelCountMap = new BehaviorSubject(new Map<string, number>());
  public variantLabelCountMap$ = this._variantLabelCountMap.pipe(
    tap(mapping => this.connectToVariantIdsToBeAdded([...mapping?.keys() || []]?.unique())),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToVariantLabelCountMap = (x: Map<string, number>) => this._variantLabelCountMap.next(x);

  public updateVariantCountMap = ([variantId, count]: [string, number]): void => {
    this.variantLabelCountMap$.once(prevMap => {
      const updatedMap = new Map(prevMap);
      !count ? updatedMap.delete(variantId) : updatedMap.set(variantId, count);
      this.connectToVariantLabelCountMap(updatedMap);
    });
  };

  public bulkAddVariantsToVariantLabelCountMap = (variantIds: string[]): void => {
    this.variantLabelCountMap$.once(prevMap => {
      const updatedMap = new Map(prevMap);
      variantIds.forEach(id => {
        if (!updatedMap?.get(id)) updatedMap.set(id, 1);
      });
      this.connectToVariantLabelCountMap(updatedMap);
    });
  };

  public bulkRemoveVariantsFromVariantLabelCountMap = (variantIds: string[]): void => {
    this.variantLabelCountMap$.once(prevMap => {
      const updatedMap = new Map(prevMap);
      variantIds.forEach(id => updatedMap.delete(id));
      this.connectToVariantLabelCountMap(updatedMap);
    });
  };

  public addVariantClicked(id: string): void {
    this.variantIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.concat(id)?.unique() ?? [id];
      this.connectToVariantIdsToBeAdded(updatedIds);
    });
  }

  public removeVariantClicked(id: string): void {
    this.variantIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.filter(prevId => prevId !== id) ?? [];
      this.connectToVariantIdsToBeAdded(updatedIds);
    });
  }

  public bulkAddVariantsClicked(ids: string[]): void {
    this.variantIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.concat(ids)?.unique() ?? ids;
      this.connectToVariantIdsToBeAdded(updatedIds);
    });
  }

  public bulkRemoveVariantsClicked(ids: string[]): void {
    this.variantIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.filter(prevId => !ids.includes(prevId)) ?? [];
      this.connectToVariantIdsToBeAdded(updatedIds);
    });
  }

  public handleProductClicked(productId: string): void {
    this.expandedProductId$.once(prevProductId => {
      const updatedProductId = prevProductId === productId ? '' : productId;
      this._expandedProductId.next(updatedProductId);
    });
  }

}
