import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UploadProfilePictureViewModel } from './upload-profile-picture-view-model';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadAssetComponent } from '../../../../shared/components/upload-asset/upload-asset.component';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';

@Component({
  selector: 'app-upload-profile-picture',
  templateUrl: './upload-profile-picture.component.html',
  styleUrls: ['./upload-profile-picture.component.scss'],
  providers: [UploadProfilePictureViewModel]
})
export class UploadProfilePictureComponent extends BaseModalComponent implements UploadImageInterface {

  @ViewChild(UploadAssetComponent) dnd: UploadAssetComponent;

  constructor(
    public viewModel: UploadProfilePictureViewModel,
    private cdr: ChangeDetectorRef,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override setupBindings() {
    const s = this.viewModel.dismissModalSubject.subscribe((close) => {
      if (close) this.cancel();
    });
    this.pushSub(s);
  }

  uploadImage(): void {
    this.viewModel.uploadProfilePicture();
  }

  deleteProfilePicture(): void {
    this.viewModel.deleteProfilePicture();
  }

  override cancel() {
    this.dnd.clear();
    this.activeModal.close(null);
  }

  setFile(f: BudsenseFile) {
    this.viewModel.file = f;
    this.cdr.detectChanges();
  }

  fileList(f: BudsenseFile[]): void {
    if (f.length > 0) {
      this.setFile(f[0]);
    } else {
      this.setFile(undefined);
    }
  }

}
