<div class="inline-picker-container" [ngClass]="{ disabled: disabled }">
  <span [style.display]="'contents'" [style.pointer-events]="disabled ? 'none' : ''">
    <app-label-inflator
      #labelInflatorComponent
      *ngIf="!!(label$ | async)"
      [zoom]="0.8"
      [displayLabelInterface]="displayLabelInterface$ | async"
      (clearClicked)="updateDisplayAttribute($event.id)">
    </app-label-inflator>
    <div *ngIf="!(label$ | async)" class="f12px bs-medium">Add</div>
    <div
      [popper]="popperContent"
      [popperShowOnStart]="false"
      [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
      [popperModifiers]="popperModifier"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="false"
      [popperPreventOverflow]="false"
      [popperPositionFixed]="true"
      [popperApplyClass]="'label-picker-popper'"
      [popperStyles]="popperStyles"
      [popperPlacement]="popperPlacement"
      [popperDisabled]="(addedBySmartFilter$ | async) || (disableActionButton$ | async)">
      <div class="ms-2">
        <app-inline-picker-action-button
          [disabled]="(addedBySmartFilter$ | async) || (disableActionButton$ | async)"
          [canEdit]="showEditButton$ | async">
        </app-inline-picker-action-button>
      </div>
    </div>
    <popper-content #popperContent [style.position]="'fixed'" [style.z-index]="1500">
      <app-label-picker
        *ngIf="started && popperContent.state"
        class="label-picker"
        [selectedLabel]="label$ | async"
        [isCompany]="isCompany$ | async"
        (selectedId)="updateDisplayAttribute($event)"
        (clearSelectionClicked)="updateDisplayAttribute($event)">
      </app-label-picker>
    </popper-content>
  </span>
</div>
