<div class="pt-24px">
  <lib-reactive-form-text
    #menuName
    [inputName]="'menuName'"
    [dispersedKey]="dispersedKey"
    [label]="'Menu Name (Internal)' | replaceMenuWithTemplate : (viewModel.templateMode$ | push)"
    [placeholder]="'Enter an internal name for the menu.' | replaceMenuWithTemplate : (viewModel.templateMode$ | push)"
    [bindingProperty]="'name'"
    [maxLength]="128"
    [required]="true">
  </lib-reactive-form-text>

  <div class="tag-container" *rxIf="!creatingFromExisting; strategy: 'immediate'">
    <lib-reactive-form-drop-down
      class="tag-form-dropdown"
      [inputName]="'Tag'"
      [dispersedKey]="dispersedKey"
      [label]="'Tag'"
      [placeholder]="'Select a tag to sort your menus by.'"
      [bindingProperty]="'tag'"
      [required]="false"
      [editable]="true"
      [dropdowns]="viewModel?.tags$ | push"
      [clearable]="true"
      [bindOnSubmit]="!(viewModel.showNewTagInput$ | push)"
      [disabled]="viewModel.showNewTagInput$ | push">
    </lib-reactive-form-drop-down>

    <lib-button-primary
      class="tag-button"
      [disabled]="viewModel.showNewTagInput$ | push"
      (buttonClicked)="toggleUseExistingTags(true)">
      New
    </lib-button-primary>
  </div>

  <div class="tag-container" *rxIf="!creatingFromExisting; strategy: 'immediate'">
    <lib-reactive-form-text
      class="tag-form-new-input"
      [dispersedKey]="dispersedKey"
      [bindOnSubmit]="viewModel.showNewTagInput$ | push"
      [hidden]="!(viewModel.showNewTagInput$ | push)"
      [inputName]="'newTag'"
      [label]="'New Tag Name'"
      [placeholder]="'Enter new tag name'"
      [bindingProperty]="'tag'"
      [required]="false">
    </lib-reactive-form-text>

    <lib-button-neutral
      class="tag-button"
      *rxIf="viewModel.showNewTagInput$; strategy: 'immediate'"
      (buttonClicked)="toggleUseExistingTags(false)">
      Use Existing
    </lib-button-neutral>
  </div>

  <hr />
  <app-card-stack-size-picker
    *rxIf="viewModel.containsStackedContent$; strategy: 'immediate'"
    [menuType]="viewModel.productMenuType$ | push"
    [cardStackSizesToShow]="viewModel.stackSizesSupportedByThemes$ | push"
    (selectedStackSize)="viewModel.stackSizeSelected($event)">
  </app-card-stack-size-picker>
  <!--  Theme Previews  -->
  <app-theme-preview
    *rxIf="!creatingFromExisting; strategy: 'immediate'"
    [companyId]="viewModel.companyId$ | push"
    [menuType]="viewModel.productMenuType$ | push"
    [selectedCardStackSize]="viewModel.selectedStackSize$ | push"
    [themes]="viewModel.themeSelectionOptions$ | push"
    (orientationSelected)="viewModel.orientationSelected($event)"
    (themeSelected)="viewModel.themeSelected($event)">
  </app-theme-preview>
</div>
