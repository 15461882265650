import { Deserializable } from './deserializable';
import { Type } from '@angular/core';
import { SectionTemplate } from '../template/dto/section-template';
import { HydratedSection } from '../menu/dto/hydrated-section';
import { Section } from '../menu/dto/section';
import { SectionBlueprint } from '../template/dto/section-blueprint';

export class DeserializeSectionHelper {

  static getPolymorphicSectionObject<T extends Deserializable>(section: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), section);
    };
    const isSectionTemplate = section?.hasOwnProperty('allowAddProducts')
      || section.hasOwnProperty('allowBadgeOverride')
      || section.hasOwnProperty('allowLabelOverride')
      || section.hasOwnProperty('allowStyleOverride');
    const isHydratedSection = section?.hasOwnProperty('products');
    const isSectionBlueprint = section?.hasOwnProperty('blueprintCategory');
    switch (true) {
      case isSectionTemplate:  return createPolymorphicObject(SectionTemplate);
      case isHydratedSection:  return createPolymorphicObject(HydratedSection);
      case isSectionBlueprint: return createPolymorphicObject(SectionBlueprint);
      default:                 return createPolymorphicObject(Section);
    }
  }

}
