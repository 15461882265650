<lib-reactive-table
  [data]="overrideProductGroupings"
  [nItemsToDisplay]="nItemsToDisplay"
  [nPagesInsidePaginationIndicator]="7"
  [resetPageOnNewData]="resetPageOnNewData"
  [resetPageOnSortingChange]="resetPageOnSortingChange">
  <app-product-grouping-table-header></app-product-grouping-table-header>
  <app-product-grouping-table-item></app-product-grouping-table-item>
  <app-product-grouping-table-empty-state [searchTextAndHits]="searchTextAndHits" (clearSearch)="clearSearch.emit()">
  </app-product-grouping-table-empty-state>
  <app-table-loading></app-table-loading>
  <lib-reactive-table-paginator></lib-reactive-table-paginator>
</lib-reactive-table>
