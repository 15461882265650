<app-edit-menu-section-section-details
  [templateMode]="templateMode"
  [mergeKey]="dispersedAndMergeKey"
  [title]="'Product Group Details'"
  [description]="'View and edit basic information about this product group.'">
</app-edit-menu-section-section-details>

<app-edit-menu-section-primary-asset
  [primaryAssetTitle]="'Product Group Media'"
  [primaryAssetDesc]="'Upload an eye catching background image or video to make this menu really stand out.'"
  [hideLoading]="viewModel.hideSectionAssetLoading$ | async"
  [loadingOpts]="viewModel.sectionAssetLoadingOpts$ | async"
  [sectionAsset]="viewModel.sectionMedia$ | async"
  [templateMode]="templateMode"
  [formPristine]="formPristine"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
  [sectionAssetPreview]="viewModel.sectionMediaPreview$ | async"
  [allowVideo]="true"
  [parentHandler]="this"
  (changeSectionAssetClicked)="viewModel.handleChangeSectionAsset()"
  (deleteSectionAssetClicked)="viewModel.handleDeleteSectionAsset()"
  (refreshSectionAssetClicked)="viewModel.handleMenuSectionAssetRefresh($event)">
</app-edit-menu-section-primary-asset>

<app-menu-section-smart-filters
  *ngIf="viewModel.isProductSection$ | async"
  [templateMode]="templateMode"
  [menu]="viewModel.menu$ | async"
  [section]="viewModel.section$ | async"
  [formPristine]="formPristine"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
  [autoSaving]="viewModel.autoSaving$ | async"
  [mergeKey]="dispersedAndMergeKey">
</app-menu-section-smart-filters>

<app-edit-menu-section-products
  [templateMode]="templateMode"
  [formPristine]="formPristine"
  [formHasErrors]="formHasErrors">
</app-edit-menu-section-products>
