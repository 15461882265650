// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { VariantType, VariantTypeDefinition } from '../../../../../../../models/utils/dto/variant-type-definition';
import { ProductType } from '../../../../../../../models/utils/dto/product-type-definition';

@Injectable()
export class EditVariantGeneralViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
    this.container.variant$.notNull().once((variant) => {
      this.connectToSelectedProductType(variant?.productType);
      this.connectToSelectedVariantType(variant?.variantType);
    });
  }

  // static dropdown selection options
  public productTypeOptions$ = window.types.productTypes$;
  public strainTypeOptions$ = window?.types?.strainTypes$;
  public unitOfMeasureOptions$ = window?.types?.unitOfMeasures$;

  private _selectedProductType = new BehaviorSubject<ProductType | null>(null);
  public selectedProductType$ = this._selectedProductType.pipe(debounceTime(1));
  connectToSelectedProductType = (productType: ProductType) => this._selectedProductType.next(productType);

  private _selectedVariantType = new BehaviorSubject<VariantType | null>(null);
  public selectedVariantType$ = this._selectedVariantType.pipe(debounceTime(1));
  connectToSelectedVariantType = (variantType: VariantType) => this._selectedVariantType.next(variantType);

  public variantTypeOptions$ = this.selectedProductType$.pipe(
    switchMap((productType) => window?.types?.getVariantTypesForProductType(productType)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public variantTypeDisabled$ = this.selectedProductType$.pipe(map((productType) => !productType));
  public isAccessoryProduct$ = this.selectedProductType$.pipe(
    map((productType) => productType === ProductType.Accessories)
  );
  public isOtherProduct$ = this.selectedProductType$.pipe(map((productType) => productType === ProductType.Other));
  public isNonCannabinoidOtherVariant$ = combineLatest([this.isOtherProduct$, this.selectedVariantType$]).pipe(
    map(([isOtherProduct, variantType]) => isOtherProduct && !VariantTypeDefinition.isOtherCannabis(variantType))
  );
  public isNonCannabinoid$ = combineLatest([this.isAccessoryProduct$, this.isNonCannabinoidOtherVariant$]).pipe(
    debounceTime(25),
    map((isNonCannabinoid) => isNonCannabinoid?.includes(true)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _queuedCompanyVariantDisplayName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public queuedCompanyVariantDisplayName$ = this._queuedCompanyVariantDisplayName as Observable<string>;
  private _queuedCompanyVariantDisplayNameChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public queuedCompanyVariantDisplayNameChanged$ = this._queuedCompanyVariantDisplayNameChanged as Observable<boolean>;

  private _queuedCompanyProductDisplayName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public queuedCompanyProductDisplayName$ = this._queuedCompanyProductDisplayName as Observable<string>;
  private _queuedCompanyProductDisplayNameChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public queuedCompanyProductDisplayNameChanged$ = this._queuedCompanyProductDisplayNameChanged as Observable<boolean>;

  public unitSizeTooltip$ = of(
    'This value is the weight of a single unit. This value multiplied by the '
    + 'packaged quantity will equal the total weight of the product.'
  );

  public variantDisplayNamePlaceholder$ = combineLatest([
    this.container.variant$,
    this.container.variantCompanyDisplayAttribute$,
    this.queuedCompanyVariantDisplayName$,
    this.queuedCompanyVariantDisplayNameChanged$
  ]).pipe(
    map(([
      variant,
      variantCompanyDA,
      queuedCompanyVariantDisplayName,
      queuedCompanyVariantDisplayNameChanged
    ]) => {
      const useVariantDisplayName = variantCompanyDA?.displayName && !queuedCompanyVariantDisplayNameChanged;
      if (queuedCompanyVariantDisplayName || useVariantDisplayName) {
        const displayName = queuedCompanyVariantDisplayName || variantCompanyDA.displayName;
        return displayName + ' (Company Display Name)';
      } else {
        return variant?.name;
      }
    })
  );

  public productDisplayNamePlaceholder$ = combineLatest([
    this.container.product$,
    this.container.productCompanyDisplayAttribute$,
    this.queuedCompanyProductDisplayName$,
    this.queuedCompanyProductDisplayNameChanged$
  ]).pipe(
    map(([
      product,
      productCompanyDA,
      queuedCompanyProductDisplayName,
      queuedCompanyProductDisplayNameChanged
    ]) => {
      const useProductDisplayName = productCompanyDA?.displayName && !queuedCompanyProductDisplayNameChanged;
      if (queuedCompanyProductDisplayName || useProductDisplayName) {
        const displayName = queuedCompanyProductDisplayName || productCompanyDA.displayName;
        return displayName + ' (Company Display Name)';
      } else {
        return product?.name;
      }
    })
  );

  // Location Display Name Title
  public locationDisplayNamesTitle$ = this.container.currentLocationName$.pipe(
    map(l => `Display Name - ${l} (Location)`)
  );

  connectToQueuedCompanyVariantDisplayName = (newName: string) => {
    this._queuedCompanyVariantDisplayName.next(newName);
    this._queuedCompanyVariantDisplayNameChanged.next(true);
  };
  connectToQueuedCompanyProductDisplayName = (newName: string) => {
    this._queuedCompanyProductDisplayName.next(newName);
    this._queuedCompanyProductDisplayNameChanged.next(true);
  };

}
