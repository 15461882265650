<div class="theme-preview-container">
  <!-- Title and orientation selection -->
  <div class="theme-preview-header">
    <div class="theme-preview-title float-left">Theme</div>
    <div
      *ngIf="menuType !== ClientTypes.MenuType.PrintCardMenu && menuType !== ClientTypes.MenuType.PrintLabelMenu"
      class="theme-orientation float-right">
      <app-segmented-control
        (optionSelected)="orientationSegmentedControlOptionSelected($event)"
        [hidden]="hideOrientationSegmentedControl()"
        [options]="orientationControlOptions">
      </app-segmented-control>
    </div>
  </div>
  <!-- Themes Preview Cards -->
  <app-card-deck
    (cardButtonPressed)="openCardDetails($event)"
    (cardPressed)="themeCardSelected($event)"
    [cards]="previewCards"
    [horizontalScroller]="true"
    [rowGap]="'0'"
    [columnGap]="'0'"
    [maxHeightRem]="maxHeightRem"
    [scrollToCardId]="scrollToCardId">
  </app-card-deck>
</div>
