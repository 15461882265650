<div *ngIf="viewModel.isLoading$ | async" class="loading-container">
  <span class="smart-filter-subtext mt-8px mb-8px">{{ titleText }}</span>
  <div class="loading-pills">
    <lib-loading-shimmer
      *ngFor="let number of [0, 1, 2]"
      [width]="'8rem'"
      [height]="'1.825rem'"
      [shape]="'rect'"
      [borderRadius]="'1.3125rem'">
    </lib-loading-shimmer>
  </div>
</div>
<div>
  <ng-container *ngIf="(viewModel.appearsOnPills$ | async)?.length > 0">
    <span class="smart-filter-subtext mt-8px mb-8px">{{ titleText }}</span>
    <ng-container *ngFor="let pill of viewModel.appearsOnPills$ | async">
      <app-pill
        class="mr-8px mb-16px"
        [pillItem]="pill"
        [clickable]="isClickable"
        [fontSize]="'0.75rem'"
        (click)="clicked.emit(pill.linkedItem)">
      </app-pill>
    </ng-container>
  </ng-container>
</div>
