<app-info-banner *ngIf="viewModel.selectedSortOptionIsStrainClassification$ | async" class="mb-16px">
  {{ viewModel.strainClassificationSortExplanation$ | async }}
</app-info-banner>

<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  [customErrorMessageMap]="viewModel.customErrorMessageMap$ | async"
  (disableSubmit$)="disableSubmit.emit($event)"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      [inputName]="'layoutType'"
      [label]="'Layout'"
      [placeholder]="'Select how the section products should be laid out'"
      [inlineLabel]="false"
      [required]="true"
      [disabled]="isTemplatedSection"
      [dropdowns]="viewModel.layoutTypes$ | async"
      (valueChanged)="viewModel.connectToSectionLayoutType($event)"
      [bindingProperty]="'layoutType'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      [inputName]="'productRows'"
      [label]="'Product Rows'"
      [placeholder]="'Number of products to display.'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="menu?.hydratedTheme?.themeFeatures?.sectionProductMaxCount"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'rowCount'"
      (valueChanged)="viewModel.connectToProductCount($event)">
    </lib-reactive-form-number>

    <lib-reactive-form-drop-down
      [inputName]="'sorting'"
      [label]="'Primary Sort'"
      [placeholder]="'Select how the section products should be laid out'"
      [inlineLabel]="false"
      [required]="true"
      [disabled]="isTemplatedSection"
      [dropdowns]="viewModel.primarySortingTypes$ | async"
      [bindingProperty]="'sorting'"
      [reinitializeWhenListChanges]="false"
      (valueChanged)="viewModel.connectToSelectedPrimarySortOption($event)">
    </lib-reactive-form-drop-down>

    <!-- not required, because old menu sections will not have a secondary set -->
    <lib-reactive-form-drop-down
      [inputName]="'secondarySorting'"
      [label]="'Secondary Sort'"
      [disabled]="(viewModel.secondarySortDisabled$ | async) || isTemplatedSection"
      [placeholder]="viewModel.secondarySortPlaceholder$ | async"
      [tooltip]="viewModel.secondarySortTooltip$ | async"
      [inlineLabel]="false"
      [required]="false"
      [dropdowns]="viewModel.secondarySortingTypes$ | async"
      [bindingProperty]="'secondarySorting'"
      [reinitializeWhenListChanges]="false"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeSecondarySort$ | async"
      (valueChanged)="viewModel.connectToSelectedSecondarySortOption($event)">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-row>
    <!--  Lay out Grid Column Options and hide when not selected to avoid re-rendering of form  -->
    <lib-reactive-form-checkboxes
      [hidden]="!((viewModel.sectionGridColumns$ | async)?.length > 0 && (viewModel.isGrid$ | async))"
      [customValueParser]="viewModel.parseSectionGridColumns"
      (valueChanged)="viewModel.connectToGridColumns($event)"
      [disabled]="(viewModel.disableGridColumnCheckboxes$ | async) || isTemplatedSection"
      [disabledTooltip]="viewModel.selectAllGridColumnsDisabledTooltip$ | async"
      [disabledTooltipDelayMs]="75"
      [performValidationWhenDisabled]="viewModel.performGridColumnValidationWhenDisabled$ | async"
      [showErrorMessagesOnInitialization]="true"
      [bindOnSubmit]="viewModel.bindGridCheckboxesOnSubmit$ | async"
      [bindingProperty]="'metadata.gridColumnNames'"
      [requireMinimumSelection]="1"
      [maximumSelections]="viewModel.maximumGridColumnSelections$ | async"
      [inputName]="'gridColumnNames'"
      [label]="'Grid Columns'"
      [checkAllText]="'Select All Grid Columns'"
      [inlineOptions]="true">
      <lib-reactive-form-checkbox
        *ngFor="let colName of viewModel.sectionGridColumns$ | async"
        [inputName]="colName | replaceDotWithDash"
        [disabled]="
          (viewModel.section$ | async).autoUpdateGridColumns ||
          isTemplatedSection ||
          (colName
            | disableGridColumnCheckbox : viewModel.gridColumnsArray$ : viewModel.maximumGridColumnsReached$
            | async)
        "
        [disabledTooltip]="
          colName
            | disableGridColumnCheckbox : viewModel.gridColumnsArray$ : viewModel.maximumGridColumnsReached$
            | disableGridColumnCheckboxTooltip
            | async
        "
        [disabledTooltipDelayMs]="75"
        [selectedValue]="colName"
        [programmaticallyChangeValue]="viewModel.clearGridBulkSelection$ | async"
        [notSelectedValue]="null">
        {{ colName }}
      </lib-reactive-form-checkbox>
    </lib-reactive-form-checkboxes>
    <!--  Lay out Pricing Tier Grid Column Options and hide when not selected to avoid re-rendering of form  -->
    <lib-reactive-form-checkboxes
      [hidden]="
        !((viewModel.sectionPricingTierGridColumns$ | async)?.length > 0 && (viewModel.isPricingTierGrid$ | async))
      "
      [customValueParser]="viewModel.parseSectionGridColumns"
      (valueChanged)="viewModel.connectToPricingTierGridColumns($event)"
      [disabled]="(viewModel.disablePricingTierGridColumnCheckboxes$ | async) || isTemplatedSection"
      [disabledTooltip]="viewModel.selectAllGridColumnsDisabledTooltip$ | async"
      [disabledTooltipDelayMs]="75"
      [performValidationWhenDisabled]="viewModel.performPricingTierGridValidationWhenDisabled$ | async"
      [showErrorMessagesOnInitialization]="true"
      [bindOnSubmit]="viewModel.bindPricingTierGridCheckboxesOnSubmit$ | async"
      [bindingProperty]="'metadata.gridColumnNames'"
      [requireMinimumSelection]="1"
      [maximumSelections]="viewModel.maximumGridColumnSelections$ | async"
      [inputName]="'pricingTierGridColumnNames'"
      [label]="'Grid Columns'"
      [checkAllText]="'Select All Grid Columns'"
      [inlineOptions]="true">
      <lib-reactive-form-checkbox
        *ngFor="let colName of viewModel.sectionPricingTierGridColumns$ | async"
        [inputName]="colName | replaceDotWithDash"
        [disabled]="
          (viewModel.section$ | async).autoUpdateGridColumns ||
          isTemplatedSection ||
          (colName
            | disableGridColumnCheckbox : viewModel.gridColumnsArray$ : viewModel.maximumGridColumnsReached$
            | async)
        "
        [disabledTooltip]="
          colName
            | disableGridColumnCheckbox : viewModel.gridColumnsArray$ : viewModel.maximumGridColumnsReached$
            | disableGridColumnCheckboxTooltip
            | async
        "
        [disabledTooltipDelayMs]="75"
        [selectedValue]="colName"
        [programmaticallyChangeValue]="viewModel.clearPricingTierGridBulkSelection$ | async"
        [notSelectedValue]="null">
        {{ colName }}
      </lib-reactive-form-checkbox>
    </lib-reactive-form-checkboxes>
  </lib-reactive-form-row>

  <lib-reactive-form-row>
    <lib-reactive-form-checkbox
      #showZeroStock
      [inputName]="'showOutOfStockProducts'"
      [disabled]="(viewModel.sectionHasSmartFilters$ | async) || isTemplatedSection"
      [selectedValue]="true"
      [notSelectedValue]="false"
      (valueChanged)="viewModel.connectToShowZeroStock($event)"
      [bindingProperty]="'showZeroStockItems'">
      Show Out of Stock Products
    </lib-reactive-form-checkbox>
  </lib-reactive-form-row>

  <lib-reactive-form-row *ngIf="(viewModel.showZeroStockNote$ | async) || (viewModel.sectionHasSmartFilters$ | async)">
    <app-note-text [fontSize]="'0.875rem'" [margin]="'1rem 0 0 0'">
      {{ viewModel.zeroStockNote$ | async }}
    </app-note-text>
  </lib-reactive-form-row>
</lib-reactive-form-group>

<app-edit-menu-warning-banner
  class="mt-16px"
  [warningMessage]="viewModel.sectionBannerWarningMessage$ | async"
  [tooltipMessage]="viewModel.sectionWarningMessageToolTip$ | async">
</app-edit-menu-warning-banner>
