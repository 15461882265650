import { Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewMenuSectionModalComponent } from '../views/menu/components/modals/new-menu-section-modal/new-menu-section-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Section } from '../models/menu/dto/section';
import { Menu } from '../models/menu/dto/menu';

export class ModalNewMenuSection {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    menu: Menu,
    section: Section,
    onClose: (section: Section) => void
  ): void {
    const modalRef = ngbModal.open(
      NewMenuSectionModalComponent,
      ModalUtils.newMenuSectionModalOptions(injector)
    );
    const modalInstance = modalRef.componentInstance as NewMenuSectionModalComponent;
    modalInstance.setMenuToAddSectionTo(menu);
    modalInstance.setExistingSectionToDuplicate(section);
    modalRef.result.then(onClose).catch(() => {});
  }

}
