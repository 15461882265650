import { Selectable } from '../models/protocols/selectable';

export class PaginationUtils {

  static constructResultsPerPageOptions(options: number[]): Selectable[] {
    return options?.map(v => {
      return new class implements Selectable {

        getSelectionTitle = () => `${v}`;
        getSelectionUniqueIdentifier = () => v;
        getSelectionValue = () => v;

      }();
    }) ?? [];
  }

}

