import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { Endpoints } from './endpoints';
import { SignInRequest } from '../models/account/requests/sign-in-request';
import { Injectable } from '@angular/core';
import { SignInNewPasswordRequest } from '../models/account/requests/sign-in-new-password-request';
import { SignOutRequest } from '../models/account/requests/sign-out-request';
import { RefreshSessionRequest } from '../models/account/requests/refresh-session-request';
import { CreateUserRequest } from '../models/account/requests/create-user-request';
import { User } from '../models/account/dto/user';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { ConfirmCodeRequest } from '../models/account/requests/confirm-code-request';
import { CodeDeliveryDetails } from '../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { catchError } from 'rxjs/operators';
import { LoggingService } from '../services/logging-service';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { BsError } from '../models/shared/bs-error';
import { SendCompanyReferral } from '../models/account/dto/send-company-referral';

@Injectable({ providedIn: 'root' })
export class AccountAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Account';

  // Session

  public SignIn(req: SignInRequest): Observable<HydratedUser> {
    const url = Endpoints.SignIn();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignIn', err));
        return throwError(err);
      })
    );
  }

  public SignInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedUser> {
    const url = Endpoints.SignInNewPassword();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignInNewPassword', err));
        return throwError(err);
      })
    );
  }

  public SignOut(req: SignOutRequest): Observable<any> {
    const url = Endpoints.SignOut();
    return this.apiClient.postObj(User, url, req, null, 'text').pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignOut', err));
        return throwError(err);
      })
    );
  }

  public RefreshSession(req: RefreshSessionRequest): Observable<HydratedUser> {
    const url = Endpoints.RefreshSession();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'RefreshSession', err));
        return throwError(err);
      })
    );
  }

  // Admin Account

  public AdminCreateUser(req: CreateUserRequest): Observable<HydratedUser> {
    const url = Endpoints.AdminCreateUser();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminCreateUser', err));
        return throwError(err);
      })
    );
  }

  public AdminDeleteUser(user: User): Observable<string> {
    const url = Endpoints.AdminDeleteUser();
    return this.apiClient.deleteStr(url, user).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminDeleteUser', err));
        return throwError(err);
      })
    );
  }

  public AdminUpdateUser(employee: User): Observable<User> {
    const url = Endpoints.AdminUpdateUser();
    return this.apiClient.postObj(User, url, employee).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminUpdateUser', err));
        return throwError(err);
      })
    );
  }

  public AdminGetUsers(): Observable<HydratedUser[]> {
    const url = Endpoints.AdminGetUsers();
    return this.apiClient.getArr<HydratedUser>(HydratedUser, url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminGetUsers', err));
        return throwError(err);
      })
    );
  }

  // User Account

  public ChangePassword(req: ChangePasswordRequest): Observable<HydratedUser> {
    const url = Endpoints.ChangePassword();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ChangePassword', err));
        return throwError(err);
      })
    );
  }

  public ConfirmCode(req: ConfirmCodeRequest): Observable<HydratedUser> {
    const url = Endpoints.ConfirmCode();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ConfirmCode', err));
        return throwError(err);
      })
    );
  }

  public GetForgotPasswordCode(email: string): Observable<CodeDeliveryDetails> {
    const url = Endpoints.ForgotPasswordCode(email);
    return this.apiClient.getObj<CodeDeliveryDetails>(CodeDeliveryDetails, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetForgotPasswordCode', err));
        return throwError(err);
      })
    );
  }

  public ResendCode(email, token: string): Observable<CodeDeliveryDetails> {
    const url = Endpoints.ResendCode(email, token);
    return this.apiClient.getObj<CodeDeliveryDetails>(CodeDeliveryDetails, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResendCode', err));
        return throwError(err);
      })
    );
  }

  public ResetForgottenPassword(req: ResetPasswordRequest): Observable<HydratedUser> {
    const url = Endpoints.ResetForgottenPassword();
    return this.apiClient.postObj(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResetForgottenPassword', err));
        return throwError(err);
      })
    );
  }

  public UpdateUser(user: HydratedUser): Observable<HydratedUser> {
    const url = Endpoints.UpdateUser();
    return this.apiClient.postObj(HydratedUser, url, user).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateUser', err));
        return throwError(err);
      })
    );
  }

  public GetUser(): Observable<HydratedUser> {
    const url = Endpoints.GetUser();
    return this.apiClient.getObj<HydratedUser>(HydratedUser, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetUser', err));
        return throwError(err);
      })
    );
  }

  public SendCompanyReferral(req: SendCompanyReferral): Observable<SendCompanyReferral> {
    const url = Endpoints.SendCompanyReferral();
    return this.apiClient.postObj(SendCompanyReferral, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SendCompanyReferral', err));
        return throwError(err);
      })
    );
  }

}
