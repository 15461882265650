import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SingleSelectionItem } from '../../../../models/shared/stylesheet/single-selection-item';

@Component({
  selector: 'app-single-selection-list',
  templateUrl: './single-selection-list.component.html',
  styleUrls: ['./single-selection-list.component.scss']
})
export class SingleSelectionListComponent implements OnChanges {

  @Input() items: SingleSelectionItem[] = [];
  @Input() selectedItem: SingleSelectionItem;
  @Output() itemSelected: EventEmitter<SingleSelectionItem> = new EventEmitter<SingleSelectionItem>();

  public sid = Math.random().toString(24);

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem) this.handleSelection(this.selectedItem, false);
  }

  handleSelection(item: SingleSelectionItem, emitSelection: boolean = true) {
    if (!!item && !item?.disabled) {
      this.items.forEach(i => i.selected = false);
      item.selected = true;
      if (emitSelection) this.itemSelected.emit(item);
    }
  }

}
