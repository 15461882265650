import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { AdvancedAddEditSmartFilterFormViewModel } from './advanced-add-edit-smart-filter-form-view-model';
import { BaseSmartFilterFormComponent } from '../base-smart-filter-form';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

@Component({
  selector: 'app-advanced-add-edit-smart-filter-form',
  templateUrl: './advanced-add-edit-smart-filter-form.component.html',
  styleUrls: ['./advanced-add-edit-smart-filter-form.component.scss'],
  providers: [AdvancedAddEditSmartFilterFormViewModel]
})
export class AdvancedAddEditSmartFilterFormComponent extends BaseSmartFilterFormComponent implements OnChanges {

  constructor(
    public viewModel: AdvancedAddEditSmartFilterFormViewModel
  ) {
    super();
  }

  protected readonly NgxPopperjsPlacements = NgxPopperjsPlacements;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) this.viewModel.connectToSmartFilter(this.bindTo);
    if (changes.isEditing) this.viewModel.connectToIsEditing(this.isEditing);
    if (changes.isViewOnly) this.viewModel.connectToIsViewOnly(this.isViewOnly);
    if (changes.modalOpenedFrom) this.viewModel.connectToModalOpenedFrom(this.modalOpenedFrom);
  }

  cleanString(val: string) {
    const splitString = val?.split(',');
    const cleanedNumbers = [];
    splitString.forEach(s => {
      const parsed = parseFloat(s);
      if (!isNaN(parsed)) {
        cleanedNumbers.push(parsed);
      }
    });
    return cleanedNumbers.join(',');
  }

}
