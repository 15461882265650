import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-segmented-control-for-form',
  templateUrl: './segmented-control-for-form.component.html',
  styleUrls: ['./segmented-control-for-form.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => SegmentedControlForFormComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SegmentedControlForFormComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SegmentedControlForFormComponent extends ReactiveFormItemComponent implements ControlValueAccessor,
  OnChanges, AfterViewInit {

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  @Input() options: SegmentedControlOption[] = [];
  @Input() verticalLayout: boolean = false;

  public controlName = Math.random().toString(24);

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  getValidators(): ValidatorFn[] {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    return [...validators, ...this.customValidators];
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  writeValue(obj: any): void {
  }

  controlSelected(e: MatButtonToggleChange) {
    this.options?.forEach(option => option.selected = option.value === e.value);
    const selVal = this.options.find(o => o.selected === true);
    this.getSelfAsFormItem()?.patchValue(selVal.value);
    this.getSelfAsFormItem()?.markAsDirty();
    this.valueChanged.emit(this.getMyValue());
  }

}

