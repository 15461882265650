import { Injectable } from '@angular/core';
import { CombinedLoadingOptsViewModel } from '../../../../shared/components/auto-save/combined-loading-opts-view-model';
import { EditCardStackMenuViewModel } from './edit-card-stack-menu-view-model';
import { EditCardStackSectionViewModel } from './edit-card-stack-section-view-model';

@Injectable()
export class EditCardStackMenuCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    protected editCardStackMenuViewModel: EditCardStackMenuViewModel,
    protected editCardStackSectionViewModel: EditCardStackSectionViewModel,
  ) {
    super([editCardStackMenuViewModel, editCardStackSectionViewModel]);
  }

}
