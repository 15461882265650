import { Component, EventEmitter, Injector, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MenuSectionVariantGroupsViewModel } from './menu-section-variant-groups-view-model';
import { Variant } from '../../../../../../models/product/dto/variant';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInformation } from '../../../../../../modals/modal-information';
import { RemovedFromMenuInfo } from '../../../../../../models/shared/information/removed-from-menu-info';
import type { SectionSortOption } from '../../../../../../models/utils/dto/section-sort-type';
import { VariantGroup } from '../../../../../../models/product/shared/variant-group';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { ReactiveFormUtils } from '../../../../../../utils/reactive-form-utils';

@Component({
  selector: 'app-menu-section-variant-groups',
  templateUrl: './menu-section-variant-groups.component.html',
  styleUrls: ['./menu-section-variant-groups.component.scss'],
  // styles won't work with decodeSectionSortingIntoReadableString if set to emulated
  encapsulation: ViewEncapsulation.None,
  providers: [MenuSectionVariantGroupsViewModel]
})
export class MenuSectionVariantGroupsComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: MenuSectionVariantGroupsViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() showWillNotAppearOnMenuSection: boolean = false;
  @Input() showZeroStockItems: boolean = false;
  @Input() primarySorting: SectionSortOption;
  @Input() secondarySorting: SectionSortOption;
  @Input() autoSaving: boolean = false;
  @Input() removingVariants: boolean = false;
  @Input() templateMode: boolean = false;
  @Input() isTemplatedSection: boolean = false;
  @Input() allowAddingProducts: boolean = true;
  @Input() allowBadgeOverride: boolean = true;
  @Input() allowLabelOverride: boolean = true;
  @Input() allowStyleOverride: boolean = true;
  @Input() saveSection: (background: boolean, badgeMap?: Map<string, string[]>) => void;
  @Input() removeVariantFromSection: (v: Variant) => Observable<string[]>;
  @Input() hideBottomDivider: boolean = true;
  @Input() invalidFormState: boolean = false;
  @Input() productBelowLineDesc: string = 'Products below line will not appear on menu';
  @Input() formPristine: boolean = true;
  @Output() addProductsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removeAllProductsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sortedVariantIds = new EventEmitter<string[]>(true);
  @ViewChildren(ReactiveFormDropDownComponent) dropdowns: QueryList<ReactiveFormDropDownComponent>;
  private mutationObservers: MutationObserver[];

  override setupBindings(): void {
    this.deleteErrorBlocks(this.dropdowns?.toArray() || []);
    this.dropdowns.changes.subscribeWhileAlive({
      owner: this,
      next: dropdowns => this.deleteErrorBlocks(dropdowns)
    });
    this.viewModel.sortedVariantIds$.subscribeWhileAlive({
      owner: this,
      next: ids => this.sortedVariantIds.emit(ids)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.showZeroStockItems) this.viewModel.connectToShowZeroStockItems(this.showZeroStockItems);
    if (changes.primarySorting) this.viewModel.connectToPrimarySortOption(this.primarySorting);
    if (changes.secondarySorting) this.viewModel.connectToSecondarySortOption(this.secondarySorting);
    if (changes.showWillNotAppearOnMenuSection) {
      this.viewModel.connectToShowWillNotAppearOnMenuSection(this.showWillNotAppearOnMenuSection);
    }
    if (changes.removingVariants) this.viewModel.connectToRemovingVariants(this.removingVariants);
    if (changes.allowAddingProducts) this.viewModel.connectToAllowAddingProducts(this.allowAddingProducts);
  }

  openInformationModal() {
    ModalInformation.open(
      this.ngbModal,
      this.injector,
      'Excluded Products Information',
      RemovedFromMenuInfo.getInformationItems(this.menu)
    );
  }

  trackByShortGroupingId(index: number, val: VariantGroup) {
    return `
      -${val?.product?.id}
      -${val?.variants?.map(v => v?.id)?.sort()?.join('-')}
    `;
  }

  private deleteErrorBlocks(components: ReactiveFormDropDownComponent[]): void {
    this.disconnect();
    this.mutationObservers = components
      ?.map(component => component?.getNativeElement() as HTMLDivElement)
      ?.filterNulls()
      ?.map(div => ReactiveFormUtils.getDeleteErrorMutationObserver(div));
  }

  private disconnect(): void {
    this.mutationObservers?.forEach(observer => observer?.disconnect());
  }

  override destroy() {
    super.destroy();
    this.disconnect();
  }

}
