import { NavItemViewModel } from '../nav-item-view-model';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';

@Injectable()
export class AccountNavItemViewModel extends NavItemViewModel {

  constructor(
    protected userDomainModel: UserDomainModel,
    router: Router
  ) {
    super(router);
  }

  public incompleteAccount$ = this.userDomainModel.user$.pipe(
    map(user => !user?.emailConfirmed)
  );

}
