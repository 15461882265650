<div class="add-company-label-container mb-3">
  <app-label-action-card
    *ngFor="let l of addableCompanyLabels$ | async"
    [label]="l"
    [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
    (cardClicked)="companyLabelToggled($event)">
  </app-label-action-card>
  <app-label-action-card
    *ngFor="let l of nonAddableCompanyLabels$ | async"
    [label]="l"
    [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
    [disabled]="true">
  </app-label-action-card>
</div>
