import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { debounceTime, distinctUntilChanged, map, pairwise, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject, throwError } from 'rxjs';
import { DisplayAttribute } from '../models/display/dto/display-attribute';
import { MenuAPI } from '../api/menu-api';
import { DistinctUtils } from '../utils/distinct-utils';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from '../services/toast-service';
import { CompanyDomainModel } from './company-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { MenuStyleObject } from '../models/utils/dto/menu-style-object-type';
import { UserDomainModel } from './user-domain-model';
import { exists } from '../functions/exists';

export type ProductPipeDisplayAttrsChanges = { attrsToUpdate: DisplayAttribute[], attrsToRemove: DisplayAttribute[] };

// Provided by Logged In Scope
@Injectable()
export class DisplayAttributesDomainModel extends BaseDomainModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private menuAPI: MenuAPI,
    private toastService: ToastService,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  private _isDALoading = new BehaviorSubject<boolean>(false);
  public isDALoading$ = this._isDALoading as Observable<boolean>;

  private readonly currentLocationId$ = this.locationDomainModel.locationId$;
  private readonly currentCompanyId$ = this.companyDomainModel.companyId$;

  private readonly _companyDisplayAttributes = new BehaviorSubject<DisplayAttribute[]>(null);
  public peekIntoCompanyDisplayAttrPipe = (): DisplayAttribute[] => this._companyDisplayAttributes.getValue();
  public readonly companyDisplayAttributes$ = this._companyDisplayAttributes.pipe(
    debounceTime(100),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _locationDisplayAttributes = new BehaviorSubject<DisplayAttribute[]>(null);
  public peekIntoLocationDisplayAttrPipe = (): DisplayAttribute[] => this._locationDisplayAttributes.getValue();
  public locationDisplayAttributes$ = this._locationDisplayAttributes.pipe(
    debounceTime(100),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public allDisplayAttributes$ = combineLatest([
    this.companyDisplayAttributes$,
    this.locationDisplayAttributes$
  ]).pipe(
    map(([company, location]) => [...company, ...location])
  );

  /* *************************** Product Pipeline Updates ***************************
   * These pipelines exists to bypass certain expensive calculations within the
   * products pipeline. Therefore, users will NOT have to wait for expensive
   * computations to complete before they can see the results of their changes in
   * the UI.
   */

  private readonly _fillProductPipelineHopperWithInitialCompanyDisplayAttrs
    = new BehaviorSubject<DisplayAttribute[]>(null);
  public readonly fillProductPipelineHopperWithInitialCompanyDisplayAttrs$
    = this._fillProductPipelineHopperWithInitialCompanyDisplayAttrs as Observable<DisplayAttribute[]>;

  private readonly _fillProductPipelineHopperWithInitialLocationDisplayAttrs
    = new BehaviorSubject<DisplayAttribute[]>(null);
  public readonly fillProductPipelineHopperWithInitialLocationDisplayAttrs$
    = this._fillProductPipelineHopperWithInitialLocationDisplayAttrs as Observable<DisplayAttribute[]>;

  /**
   * [DisplayAttrsToUpdate[], DisplayAttrsToRemove[]]
   */
  private readonly _productPipelineDisplayAttrsAdjustments = new Subject<ProductPipeDisplayAttrsChanges>();
  public readonly productPipelineDisplayAttrsAdjustments$
    = this._productPipelineDisplayAttrsAdjustments as Observable<ProductPipeDisplayAttrsChanges>;

  /* *************************** Local Threads of Execution *************************** */

  private fetchLocationDisplayAttributes = this.currentLocationId$.pipe(
    distinctUntilChanged()
  ).subscribeWhileAlive({
    owner: this,
    next: (locationId) => {
      if (exists(locationId)) {
        this.clearLocationDisplayAttributePipelines();
        this.loadLocationDisplayAttributes();
      }
    }
  });

  private fetchCompanyDisplayAttributes = combineLatest([
    this.currentCompanyId$.pipe(distinctUntilChanged()),
    this.currentLocationId$.pipe(distinctUntilChanged())
  ]).subscribeWhileAlive({
    owner: this,
    next: ([companyId, locationId]) => {
      if (exists(companyId) && exists(locationId)) {
        this.clearCompanyDisplayAttributesPipelines();
        this.loadCompanyDisplayAttributes();
      }
    }
  });

  private fetchDisplayAttributesOnLocationSyncChanges = this.locationDomainModel.locationConfig$.pipe(
    pairwise(),
    takeUntil(this.onDestroy)
  ).subscribe(([prev, curr]) => {
    const smartDASyncChanged = prev?.lastSmartDisplayAttributeSync !== curr?.lastSmartDisplayAttributeSync;
    const sameLocationIds = prev?.locationId === curr?.locationId;
    if (sameLocationIds && smartDASyncChanged) {
      this.loadLocationDisplayAttributes();
    }
  });

  /* ********************************************************************************* */

  private clearCompanyDisplayAttributesPipelines(): void {
    this._companyDisplayAttributes.next(null);
    this._fillProductPipelineHopperWithInitialCompanyDisplayAttrs.next(null);
  }

  private clearLocationDisplayAttributePipelines(): void {
    this._locationDisplayAttributes.next(null);
    this._fillProductPipelineHopperWithInitialLocationDisplayAttrs.next(null);
  }

  /**
   * @param attrs display attributes to be updated
   * @param menuId If included, will update the lastUpdated timestamp on the menu
   * @param isBulkEdit If true, will update the lastBulkModified timestamp on the displayAttributes
   * @return updated display attributes
   *
   * if an empty DA is sent in the payload, it will be deleted and not returned from the backend
   */
  public updateDisplayAttributes(
    attrs: DisplayAttribute[],
    menuId: string = '',
    isBulkEdit: boolean = false
  ): Observable<DisplayAttribute[]> {
    return this.menuAPI.WriteDisplayAttributes(attrs, menuId, isBulkEdit).pipe(
      tap(updatedAttrs => {
        const updatedIds = updatedAttrs?.map(a => a?.getSimpleKey());
        const removedAttrs = attrs?.filter(a => !updatedIds?.includes(a?.getSimpleKey()));
        const updateAndRemove$ = updatedAttrs?.length > 0 || removedAttrs?.length > 0
          ? this.replaceDisplayAttributes(updatedAttrs, removedAttrs)
          : of(null);
        updateAndRemove$.subscribe({
          complete: () => this.sendMicroUpdatesToProductPipeline(updatedAttrs, removedAttrs)
        });
      })
    );
  }

  private sendMicroUpdatesToProductPipeline(
    attrsToUpdate: DisplayAttribute[],
    attrsToRemove: DisplayAttribute[]
  ): void {
    this._productPipelineDisplayAttrsAdjustments.next({ attrsToUpdate, attrsToRemove });
  }

  /**
   * Do not deep copy the display attributes, because there is a lot of them and it is expensive.
   * Use pointer manipulation instead, because it is 100x faster.
   */
  private replaceDisplayAttributes(
    attrsToUpdate: DisplayAttribute[] | null,
    attrsToRemove?: DisplayAttribute[] | null
  ): Observable<void> {
    return combineLatest([
      this.currentLocationId$,
      this.locationDisplayAttributes$,
      this.companyDisplayAttributes$
    ]).pipe(
      take(1),
      map(([locationId, locAttrs, compAttrs]) => {
        const updatedLocAttrs = locAttrs?.shallowCopy() || [];
        const updatedCompAttrs = compAttrs?.shallowCopy() || [];
        attrsToUpdate?.forEach((newAttr) => {
          const isCompanyAttr = newAttr.companyId === newAttr.locationId;
          const attrPoolToUpdate = (isCompanyAttr ? updatedCompAttrs : updatedLocAttrs);
          const oldAttr = attrPoolToUpdate?.find(a => a.companyId === newAttr.companyId && a.id === newAttr.id);
          const replacementIndex = attrPoolToUpdate?.indexOf(oldAttr);
          if (replacementIndex > -1) {
            attrPoolToUpdate[replacementIndex] = newAttr;
          } else if (isCompanyAttr || (newAttr.locationId === locationId)) {
            attrPoolToUpdate.push(newAttr);
          }
        });
        attrsToRemove?.forEach(removeAttr => {
          const isCompanyAttr = removeAttr.companyId === removeAttr.locationId;
          const attrPoolToUpdate = (isCompanyAttr ? updatedCompAttrs : updatedLocAttrs);
          const oldAttr = attrPoolToUpdate?.find(a => a.companyId === removeAttr.companyId && a.id === removeAttr.id);
          const replacementIndex = attrPoolToUpdate?.indexOf(oldAttr);
          if (replacementIndex) attrPoolToUpdate?.splice(replacementIndex, 1);
        });
        this._locationDisplayAttributes.next(updatedLocAttrs);
        this._companyDisplayAttributes.next(updatedCompAttrs);
      })
    );
  }

  private updateTopOfLocationDisplayAttributePipelines(locationAttrs: DisplayAttribute[]): void {
    this._locationDisplayAttributes.next(locationAttrs);
    this._fillProductPipelineHopperWithInitialLocationDisplayAttrs.next(locationAttrs);
  }

  loadLocationDisplayAttributes() {
    this.currentLocationId$.once(locationId => {
      this._isDALoading.next(true);
      this.menuAPI.GetDisplayAttributes(locationId).subscribe({
        next: (displayAttributes) => {
          this._isDALoading.next(false);
          this.updateTopOfLocationDisplayAttributePipelines(displayAttributes || []);
        },
        error: (error: BsError) => {
          this._isDALoading.next(false);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    });
  }

  private updateTopOfCompanyDisplayAttributePipelines(companyAttrs: DisplayAttribute[]): void {
    this._companyDisplayAttributes.next(companyAttrs);
    this._fillProductPipelineHopperWithInitialCompanyDisplayAttrs.next(companyAttrs);
  }

  loadCompanyDisplayAttributes() {
    this.currentCompanyId$.once(companyId => {
      this._isDALoading.next(true);
      this.menuAPI.GetDisplayAttributes(companyId).subscribe({
        next: (displayAttributes) => {
          this._isDALoading.next(false);
          this.updateTopOfCompanyDisplayAttributePipelines(displayAttributes || []);
        },
        error: (error: BsError) => {
          this._isDALoading.next(false);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    });
  }

  private newLocationDA = (variantId: string): Observable<DisplayAttribute> => {
    return combineLatest([
      this.currentLocationId$,
      this.currentCompanyId$,
    ]).pipe(
      take(1),
      map(([locationId, companyId]) => new DisplayAttribute(companyId, locationId, variantId, MenuStyleObject.Variant))
    );
  };

  private newCompanyDA = (variantId: string): Observable<DisplayAttribute> => {
    return this.currentCompanyId$.pipe(
      take(1),
      map(companyId => new DisplayAttribute(companyId, companyId, variantId, MenuStyleObject.Variant))
    );
  };

  public getSingleValueStreamForLocationDA(variantId: string): Observable<DisplayAttribute> {
    return this.locationDisplayAttributes$.pipe(
      take(1),
      map(attrs => attrs?.find(a => a?.objectId === variantId)),
      switchMap(attr => iif(() => !!attr, of(attr), this.newLocationDA(variantId)))
    );
  }

  public getSingleValueStreamForCompanyDA(variantId: string): Observable<DisplayAttribute> {
    return this.companyDisplayAttributes$.pipe(
      take(1),
      map(attrs => attrs?.find(a => a?.objectId === variantId)),
      switchMap(attr => iif(() => !!attr, of(attr), this.newCompanyDA(variantId)))
    );
  }

}
