import { TypeDefinition } from './type-definition';

export enum MenuPreviewJobStatus {
  MenuPreviewJobStatus_Queued = 'Pending', // Job is in the 'Pending Status'
  MenuPreviewJobStatus_Processing = 'Processing',
  MenuPreviewJobStatus_Success = 'Completed', // Job has finished processing and results are saved to S3
  MenuPreviewJobStatus_Failed = 'Failed',
  MenuPreviewJobStatus_Sent = 'Sent', // Job is done and has been emailed out to user
  MenuPreviewJobStatus_Cancelled = 'Cancelled' // User cancelled job
}

export class MenuPreviewJobStatusType extends TypeDefinition {

  override value: MenuPreviewJobStatus;

  static jobIsSuccessfulOrSent(jobStatus: MenuPreviewJobStatus): boolean {
    return jobStatus === MenuPreviewJobStatus.MenuPreviewJobStatus_Success
      || jobStatus === MenuPreviewJobStatus.MenuPreviewJobStatus_Sent;
  }

}
