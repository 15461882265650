<app-loading *rxIf="container.isLoading$" [options]="container.loadingOpts$ | push"></app-loading>

<lib-reactive-form-merge-groups
  #newCollectionFormGroup
  [mergeKey]="container.mergeKey$ | push"
  (canSubmit$)="container.connectToCanSubmitForm($event)"
  (formsSubmitted$)="container.createNewTemplateCollection()">
</lib-reactive-form-merge-groups>

<div class="sticky-header-container">
  <div class="modal-header">
    <div class="modal-title">{{ container.title$ | push }}</div>
  </div>
</div>

<app-collection-details-form
  [bindTo]="container.newCollection$ | push"
  [hidden]="!(container.currentStepIsCollectionDetails$ | push)"
  [mergeKey]="container.mergeKey$ | push">
</app-collection-details-form>

<app-select-menu-or-collection
  *rxIf="container.currentStepIsSelectTemplates$; strategy: 'native'"
  [display]="container.newCollection$ | push"
  [emptyStateText]="'No templates found.'"
  [searchPlaceholder]="'Search by template name'"
  [itemIdsToAdd]="container.templateIdsToAdd$ | push"
  [itemsToSearch]="container.templates$ | push"
  (itemClicked)="container.handleItemClicked($event)">
</app-select-menu-or-collection>

<app-go-back-modal-footer
  [primaryButtonText]="container.primaryButtonText$ | push"
  [showBackButton]="container.currentStepIsSelectTemplates$ | push"
  [showPrimaryButton]="true"
  [disablePrimaryButton]="container.disablePrimaryButton$ | push"
  (cancel)="cancel()"
  (primaryButtonClicked)="container.handlePrimaryButtonClicked(newCollectionFormGroup)"
  (goBack)="container.goBack()">
</app-go-back-modal-footer>
