import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { HydratedVariantBadge } from '../../../../../../../models/product/dto/hydrated-variant-badge';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { SortUtils } from '../../../../../../../utils/sort-utils';
import { DisplayAttributesDomainModel } from '../../../../../../../domainModels/display-attributes-domain-model';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { LabelUtils } from '../../../../../../../modules/product-labels/utils/label-utils';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { Variant } from '../../../../../../../models/product/dto/variant';
import { LabelDomainModel } from '../../../../../../../domainModels/label-domain-model';

@Injectable()
export class EditVariantCustomizationViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer,
    private displayAttributesDomainModal: DisplayAttributesDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel,
  ) {
    super();
    this.init();
  }

  private readonly allLabels$ = this.labelDomainModel.allLabels$;
  private readonly systemLabels$ = this.labelDomainModel.systemLabels$;
  private readonly locationLabels$ = this.labelDomainModel.allLocationLabels$;
  private readonly companyPOSLabels$ = this.labelDomainModel.companyPOSLabels$;

  public labelsForHierarchyBanner$ = combineLatest([
    this.locationLabels$,
    this.companyPOSLabels$,
  ]).pipe(
    map(([locationLabels, posLabels]) => {
      // Only include posLabel with valid priority
      // When location overrides are not supported, all POS labels will have a valid priority
      // When location overrides are supported, we only want to show POS labels with a valid priority
      return locationLabels?.concat(posLabels) || [];
    })
  );

  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public locationName$ = this.locationDomainModel.locationName$;
  public companyName$ = this.companyDomainModel.companyName$;
  public readonly priceFormat$ = this.locationDomainModel.priceFormat$;

  public systemAppliedLabel$ = combineLatest([
    this.container.variant$,
    this.container.companyConfig$,
    this.container.locationConfig$,
    this.allLabels$,
    this.systemLabels$,
    this.priceFormat$
  ]).pipe(
    map(([variant, companyConfig, locationConfig, allLabels, systemLabels, priceStream]) => {
      return LabelUtils.computeSystemLabel(
        [variant],
        [allLabels, systemLabels],
        [locationConfig.locationId, companyConfig.companyId, priceStream]
      );
    })
  );

  public existingSystemLabelText$ = combineLatest([
    this.systemAppliedLabel$,
    this.container.variant$,
    combineLatest([this.locationName$, this.companyName$]),
    combineLatest([this.locationConfig$, this.companyConfig$]),
    this.container.priceFormat$
  ]).pipe(
    map(([systemLabel, variant, names, configs, locationPriceStream]) => {
      return LabelUtils.getSystemLabelInfo(systemLabel, variant, names, configs, locationPriceStream);
    })
  );

  public showHierarchyBanner$ = new BehaviorSubject<boolean>(false);

  public activeLabelStyle$ = combineLatest([
    this.container.companyConfig$,
    this.container.locationConfig$,
  ]).pipe(
    map(([companyConfig, locationConfig]) => {
      return locationConfig?.saleLabelFormat || companyConfig?.saleLabelFormat;
    })
  );

  public badgesOnVariant$ = combineLatest([
    this.container.updatedLocationDA$,
    this.container.updatedCompanyDA$,
  ]).pipe(
    debounceTime(100),
    map(([locationDa, companyDa]) => {
      let badges: HydratedVariantBadge[];
      let source: string;
      if (locationDa?.badges?.length > 0) {
        badges = locationDa.badges;
        source = 'Location';
      } else if (companyDa?.badges?.length > 0) {
        badges = companyDa.badges;
        source = 'Company';
      } else {
        badges = null;
        source = null;
      }
      badges?.sort(SortUtils.sortBadgesByNameAscending);
      return {badges, source};
    })
  );

  public updatedVariant$ = combineLatest([
    this.container.updatedLocationDA$,
    this.container.updatedCompanyDA$,
    this.container.variant$
  ]).pipe(
    debounceTime(100),
    map(([locationDa, companyDa, variant]) => {
      const copy = window?.injector?.Deserialize?.instanceOf(Variant, variant);
      copy.displayAttributes = locationDa || new DisplayAttribute();
      copy.displayAttributes.inheritedDisplayAttribute = companyDa || new DisplayAttribute();
      return copy;
    })
  );

  public init() {
    const distinctByUniqueId = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
    combineLatest([
      this.container.variantLocationDisplayAttribute$.notNull().pipe(distinctByUniqueId),
      this.container.variantCompanyDisplayAttribute$.notNull().pipe(distinctByUniqueId),
    ]).pipe(
      debounceTime(100),
      tap(([locationDa, companyDa]) => {
        const Deserialize = window?.injector?.Deserialize;
        this.container.updatedLocationDA$.next(Deserialize?.instanceOf(DisplayAttribute, locationDa));
        this.container.updatedCompanyDA$.next(Deserialize?.instanceOf(DisplayAttribute, companyDa));
      })
    ).subscribeWhileAlive({ owner: this });
  }

  public isKeyInDa(da: DisplayAttribute, key: string): boolean {
    return (da?.defaultLabel === key);
  }

  public locationBadgesUpdated(da: BehaviorSubject<DisplayAttribute>) {
    const daVal = da.getValue();
    this.container.updatedLocationBadges = daVal.badgeIds;
    const tempDA = this.container.updatedLocationDA$.getValue();
    tempDA.badges = daVal.badges;
    this.container.updatedLocationDA$.next(tempDA);
    this.container.canSubmitForm$.next(true);
    this.container.unsavedChanges$.next(true);
  }

  public locationLabelsUpdated(da: BehaviorSubject<DisplayAttribute>) {
    const daVal = da.getValue();
    const tempDA = this.container.updatedLocationDA$.getValue();
    tempDA.defaultLabel = daVal.defaultLabel ?? '';
    this.container.updatedLocationDA$.next(tempDA);
    this.container.updatedLocationLabel = daVal.defaultLabel ?? '';
    this.container.canSubmitForm$.next(true);
    this.container.unsavedChanges$.next(true);
  }

  public companyBadgesUpdated(da: BehaviorSubject<DisplayAttribute>) {
    const daVal = da.getValue();
    this.container.updatedCompanyBadges = daVal.badgeIds;
    const tempDA = this.container.updatedCompanyDA$.getValue();
    tempDA.badges = daVal.badges;
    this.container.updatedCompanyDA$.next(tempDA);
    this.container.canSubmitForm$.next(true);
    this.container.unsavedChanges$.next(true);
  }

  public companyLabelsUpdated(da: BehaviorSubject<DisplayAttribute>) {
    const daVal = da.getValue();
    const tempDA = this.container.updatedCompanyDA$.getValue();
    tempDA.defaultLabel = daVal.defaultLabel ?? '';
    this.container.updatedCompanyDA$.next(tempDA);
    this.container.updatedCompanyLabel = daVal.defaultLabel ?? '';
    this.container.canSubmitForm$.next(true);
    this.container.unsavedChanges$.next(true);
  }

  public toggleShowHierarchyBanner() {
    const val = this.showHierarchyBanner$.getValue();
    this.showHierarchyBanner$.next(!val);
  }

}
