<div class="d-flex justify-content-between align-items-center mt-24px">
  <span class="bs-bold f16px">Products</span>
</div>

<div class="f12px mt-16px mb-8px">
  {{ viewModel.description$ | push }}
</div>

<lib-reactive-form-group
  [mergeKey]="viewModel.mergeKey$ | push"
  [dispersedKey]="(viewModel.mergeKey$ | push) + 'dispersed'"
  [bindTo]="viewModel.cardStackPrintConfig$ | push">
  <app-hidden-array-form-input
    [disabled]="viewModel.viewOnly$ | push"
    [inputName]="'variantSelections'"
    [required]="true"
    [bindingProperty]="'variantIds'"
    [inputArr]="viewModel.variantIdsToBeAdded$ | push">
  </app-hidden-array-form-input>

  <ng-container *rxIf="viewModel.isLabelStack$; else checkboxFlow">
    <app-label-stack-print-job-select-all-products
      *rxIf="!(viewModel.emptySearch$ | push)"
      [selection]="viewModel.allCardStackVariantIdsAsSelectionItem$ | push"
      [selectionMap]="viewModel.variantLabelCountMap$ | push"
      (bulkAddClicked)="viewModel.bulkAddVariantsToVariantLabelCountMap($event)"
      (bulkRemoveClicked)="viewModel.bulkRemoveVariantsFromVariantLabelCountMap($event)">
    </app-label-stack-print-job-select-all-products>
    <app-label-stack-print-job-select-variant-counts
      [inputName]="'variantCardCountMap'"
      [dispersedKey]="(viewModel.mergeKey$ | push) + 'dispersed'"
      [stackVariants]="viewModel.allVisibleVariantsInStack$ | push"
      [searchedVariantIds]="viewModel.searchedStackVariantIds$ | push"
      [inputMap]="viewModel.variantLabelCountMap$ | push"
      [atLeastOneItemInMap]="true"
      [viewOnly]="viewOnly"
      [bindingProperty]="'variantCardCountMap'"
      (updateVariantCountMap)="viewModel.updateVariantCountMap($event)">
    </app-label-stack-print-job-select-variant-counts>
  </ng-container>

  <ng-template #checkboxFlow>
    <app-card-stack-print-job-select-all-products
      *rxIf="!(viewModel.emptySearch$ | push)"
      [selectedIds]="viewModel.variantIdsToBeAdded$ | push"
      [selection]="viewModel.allCardStackVariantIdsAsSelectionItem$ | push"
      (bulkAddClicked)="viewModel.bulkAddVariantsClicked($event)"
      (bulkRemoveClicked)="viewModel.bulkRemoveVariantsClicked($event)">
    </app-card-stack-print-job-select-all-products>
    <app-card-stack-print-job-select-individual-product
      *rxFor="let product of viewModel.searchedProductsWithCorrectVariants$; trackBy: 'id'"
      [cardStackVariantIds]="viewModel.searchedStackVariantIds$ | push"
      [product]="product"
      [productExpanded]="(viewModel.expandedProductId$ | push) === product.id"
      [selectedIds]="viewModel.variantIdsToBeAdded$ | push"
      [isGridMode]="viewModel.cardStackIsGridMode$ | push"
      [gridColumnNames]="viewModel.cardStackGridModeColumnNames$ | push"
      [viewOnly]="viewModel.viewOnly$ | push"
      (addClicked)="viewModel.addVariantClicked($event)"
      (removeClicked)="viewModel.removeVariantClicked($event)"
      (bulkAddClicked)="viewModel.bulkAddVariantsClicked($event)"
      (bulkRemoveClicked)="viewModel.bulkRemoveVariantsClicked($event)"
      (productClicked)="viewModel.handleProductClicked($event)">
    </app-card-stack-print-job-select-individual-product>
  </ng-template>
</lib-reactive-form-group>

<div *rxIf="viewModel.emptySearch$" class="d-flex justify-content-start mt-16px f14px bs-medium">
  <div>{{ viewModel.noVariantsForSearchPlaceholder$ | push }}</div>
</div>
