import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'replaceMenuWithStack'
})
export class ReplaceMenuWithStackPipe implements PipeTransform {

  transform(input: string, isCard: boolean): string {
    if (isCard) {
      input = StringUtils.replaceMenuWithStack(input);
    }
    return input;
  }

}
