import { TypeDefinition } from './type-definition';
import { WeekDay } from '@angular/common';

export class DayOfWeekType extends TypeDefinition {

  override getSelectionValue(): WeekDay {
    return parseInt(this.value, 10);
  }

  override getSelectionUniqueIdentifier(): WeekDay {
    return parseInt(this.value, 10);
  }

}
