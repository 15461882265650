import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Variant } from '../../../../../../../../../models/product/dto/variant';
import { HiddenMapFormInputComponent } from '../../../../../../../../shared/forms/hidden-map-form-input/hidden-map-form-input.component';

@Component({
  selector: 'app-label-stack-print-job-select-variant-counts',
  templateUrl: './label-stack-print-job-select-variant-counts.component.html',
  styleUrls: ['./label-stack-print-job-select-variant-counts.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => LabelStackPrintJobSelectVariantCountsComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LabelStackPrintJobSelectVariantCountsComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackPrintJobSelectVariantCountsComponent extends HiddenMapFormInputComponent {

  @Input() stackVariants: Variant[];
  @Input() searchedVariantIds: string[];
  @Input() viewOnly: boolean;
  @Output() updateVariantCountMap = new EventEmitter<[string, number]>(true);

}
