import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class SingleSelectionItem implements LookAheadItem, UniquelyIdentifiable {

  public title: string;
  public body: string;
  public iconSrc: string;
  public tooltip: string;
  public value: any = null;
  public selected: boolean = false;
  public disabled: boolean = false;
  public groupingId: string;
  public groupingName: string;

  constructor(title: string, body: string, value: any, groupingId?: string, groupingName?: string) {
    this.title = title;
    this.body = body;
    this.value = value;
    this.groupingId = groupingId;
    this.groupingName = groupingName;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  contains(criteria: string): boolean {
    const title = this.title === criteria;
    const body = this.body === criteria;
    const groupingId = this.groupingId === criteria;
    return title || body || groupingId;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.title}-
      ${this.body}-
      ${this.iconSrc}-
      ${this.tooltip}-
      ${this.value}-
      ${this.selected}-
      ${this.disabled}-
      ${this.groupingId}-
    `;
  }

}
