<hr class="no-margin" />
<div class="widget-container mt-4">
  <img class="widget-icon" [src]="'assets/icons/light/outline/chat-alt-2.svg'" alt="chat bubbles" />
  <div class="widget-title f20px mt-4">Refer a Friend</div>
  <div class="subtext-container mt-4">
    <div class="widget-subtext f14px">
      Do you love BudSense and know of a friend whose cannabis shop may also benefit?
    </div>
    <div class="widget-subtext f14px mt-4">Send them a referral and we'll give you $200 if they sign up!</div>
  </div>
  <button class="bs-button inverse-preferred-button" (click)="openReferFriendModal()" [style.max-width]="'8rem'">
    Start Now
  </button>
</div>
