<hr *ngIf="includeTopDivider" />

<div class="variant-badges" fxLayout="column" fxLayoutAlign="center stretch">
  <div class="header" fxLayout="row" fxLayoutAlign=" stretch">
    <div class="bs-edit-section-title" fxFlex>{{ title || getSectionTitle() }}</div>
    <div
      *ngIf="showBadgePicker"
      class="text-link"
      [popper]="popperContent"
      [popperShowOnStart]="false"
      [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
      [popperModifiers]="popperModifier"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="false"
      [popperPreventOverflow]="true"
      [popperPositionFixed]="true"
      [popperApplyClass]="'badge-picker-popper'"
      [popperStyles]="popperStyles"
      [popperPlacement]="ClientTypes.NgxPopperjsPlacements.TOPEND">
      Add a Badge
    </div>
    <popper-content #popperContent [style.z-index]="1500">
      <app-badge-picker class="badge-picker" (selected)="addClicked.emit($event)"></app-badge-picker>
    </popper-content>
  </div>

  <!-- inherited badges that are not editable -->
  <ng-container *ngIf="!badges || badges?.length < 1">
    <app-variant-badge
      *ngFor="let badge of inheritedBadges; let last = last"
      [badge]="badge"
      [editable]="false"
      [includeTopDivider]="false"
      [includeBottomDivider]="!last"
      [badgeInfo]="inheritedText"></app-variant-badge>
  </ng-container>

  <!-- badges added to this product variant -->
  <app-variant-badge
    *ngFor="let badge of badges; let last = last"
    [badge]="badge"
    [includeTopDivider]="false"
    [includeBottomDivider]="!last"
    (removeClicked)="removedClicked.emit(badge)"
    [editable]="true">
  </app-variant-badge>

  <!-- empty state -->
  <div
    *ngIf="(!badges || badges?.length < 1) && (!inheritedBadges || inheritedBadges?.length < 1)"
    class="bs-edit-section-description pt-16px pb-16px">
    {{ emptyStateText }}
  </div>
</div>

<hr *ngIf="includeBottomDivider" class="mt-0" />
