import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DigitalTemplatesComponent } from './components/templates/digital/digital-templates.component';
import { PrintTemplatesComponent } from './components/templates/print/print-templates.component';
import { WebTemplatesComponent } from './components/templates/web/web-templates.component';
import { AuthGuard } from '../../services/guards/auth.guard';
import { PermissionGuard } from '../../services/guards/permission.guard';
import { CanDeactivateGuard } from '../../services/guards/can-deactivate.guard';
import { EditTemplateProductMenuComponent } from './components/edit-template-menu/edit-template-product-menu/edit-template-product-menu.component';
import { EditTemplateSpotlightMenuComponent } from './components/edit-template-menu/edit-template-spotlight-menu/edit-template-spotlight-menu.component';
import { EditTemplateMenuSectionComponent } from './components/edit-template-menu-section/edit-template-menu-section.component';
import { EditTemplateMarketingPlaylistMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-playlist-menu/edit-template-marketing-playlist-menu.component';
import { EditTemplateMarketingFeaturedProductMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-featured-product-menu/edit-template-marketing-featured-product-menu.component';
import { EditTemplateMarketingDriveThruMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-drive-thru-menu/edit-template-marketing-drive-thru-menu.component';
import { EditTemplateMarketingFeaturedCategoryMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-featured-category-menu/edit-template-marketing-featured-category-menu.component';
import { EditTemplateFeaturedCategorySectionComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-featured-category-menu/edit-template-featured-category-section/edit-template-featured-category-section.component';
import { TemplateAdminGuard } from './guards/template-admin.guard';
import { EditTemplateMarketingSmartPlaylistMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-smart-playlist-menu/edit-template-marketing-smart-playlist-menu.component';
import { EditTemplateSmartPlaylistMenuSectionComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-smart-playlist-menu/edit-template-smart-playlist-menu-section/edit-template-smart-playlist-menu-section.component';
import { EditCollectionComponent } from '../collection/components/edit-collection/edit-collection.component';
import { EditTemplateCardStackMenuComponent } from './components/edit-template-menu/edit-template-card-stack-menu/edit-template-card-stack-menu.component';
import firebase from 'firebase';
import Auth = firebase.auth.Auth;
import { EditTemplateLabelStackMenuComponent } from './components/edit-template-menu/edit-template-label-stack-menu/edit-template-label-stack-menu.component';
import { EditTemplateMarketingUrlPlaylistMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-url-playlist-menu/edit-template-marketing-url-playlist-menu.component';

const routes: Routes = [
  {
    path: 'digital',
    component: DigitalTemplatesComponent,
    data: {
      title: 'Digital Templates'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
  },
  {
    path: 'digital/product/:menuTemplateId',
    component: EditTemplateProductMenuComponent,
    data: {
      title: 'Edit Template - Product Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'digital/product/:menuTemplateId/section/:sectionTemplateId',
    component: EditTemplateMenuSectionComponent,
    data: {
      title: 'Edit Template - Product Menu Section'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/spotlight/:menuTemplateId',
    component: EditTemplateSpotlightMenuComponent,
    data: {
      title: 'Edit Template - Spotlight Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'digital/marketing/playlist/:menuTemplateId',
    component: EditTemplateMarketingPlaylistMenuComponent,
    data: {
      title: 'Edit Template - Looping Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/smart-playlist/:menuTemplateId',
    component: EditTemplateMarketingSmartPlaylistMenuComponent,
    data: {
      title: 'Edit Template - Product Linked Looping Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/url-playlist/:menuTemplateId',
    component: EditTemplateMarketingUrlPlaylistMenuComponent,
    data: {
      title: 'Edit Template - Url Playlist Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/smart-playlist/:menuTemplateId/section/:sectionTemplateId',
    component: EditTemplateSmartPlaylistMenuSectionComponent,
    data: {
      title: 'Edit Template - Product Grouping'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/featured/:menuTemplateId',
    component: EditTemplateMarketingFeaturedProductMenuComponent,
    data: {
      title: 'Edit Template - Featured Product Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/drive-thru/:menuTemplateId',
    component: EditTemplateMarketingDriveThruMenuComponent,
    data: {
      title: 'Edit Template - Drive-Thru Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/category/:menuTemplateId',
    component: EditTemplateMarketingFeaturedCategoryMenuComponent,
    data: {
      title: 'Edit Template - Featured Category Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/category/:menuTemplateId/section/:sectionTemplateId',
    component: EditTemplateFeaturedCategorySectionComponent,
    data: {
      title: 'Edit Template - Featured Category Section'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print',
    component: PrintTemplatesComponent,
    data: {
      title: 'Print Templates'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard]
  },
  {
    path: 'print/product/:menuTemplateId',
    component: EditTemplateProductMenuComponent,
    data: {
      title: 'Edit Template - Print Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/product/:menuTemplateId/section/:sectionTemplateId',
    component: EditTemplateMenuSectionComponent,
    data: {
      title: 'Edit Template - Print Menu Section'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/report/:menuTemplateId',
    component: EditTemplateProductMenuComponent,
    data: {
      title: 'Edit Template - Report'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/report/:menuTemplateId/section/:sectionTemplateId',
    component: EditTemplateMenuSectionComponent,
    data: {
      title: 'Edit Template - Report Section'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/stack/:menuTemplateId',
    component: EditTemplateCardStackMenuComponent,
    data: {
      title: 'Edit Template - Card Stack'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/labels/:menuTemplateId',
    component: EditTemplateLabelStackMenuComponent,
    data: {
      title: 'Edit Template - Label Stack'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'web',
    component: WebTemplatesComponent,
    data: {
      title: 'Web Templates'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard]
  },
  {
    path: 'web/product/:menuTemplateId',
    component: EditTemplateProductMenuComponent,
    data: {
      title: 'Edit Template - Product Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'web/product/:menuTemplateId/section/:sectionTemplateId',
    component: EditTemplateMenuSectionComponent,
    data: {
      title: 'Edit Template - Product Menu Section'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'web/spotlight/:menuTemplateId',
    component: EditTemplateSpotlightMenuComponent,
    data: {
      title: 'Edit Template - Spotlight Menu'
    },
    canActivate: [AuthGuard, PermissionGuard, TemplateAdminGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'digital/collections',
    children: [
      {
        path: ':collectionId',
        component: EditCollectionComponent,
        canActivate: [AuthGuard, PermissionGuard],
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
  {
    path: '',
    redirectTo: 'digital',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRoutingModule {
}
