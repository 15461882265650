<div class="d-flex align-items-center ml-32px pt-21px pb-21px" [style.gap]="'1rem'">
  <app-checkbox-only class="mr-8px" [checked]="selected$ | async" (valueChanged)="clicked($event)"> </app-checkbox-only>
  <div class="d-flex" [style.gap]="'0.5rem'">
    <div class="display-name mr-8px">
      {{ selection | displayTitle }}
    </div>
    <img
      *ngIf="selection?.templateCollectionIds?.length"
      [ngbTooltip]="tooltip"
      src="assets/icons/dark/outline/template-collection.svg"
      alt="" />
  </div>
  <ng-template #tooltip>
    <div *ngFor="let id of selection?.templateCollectionIds">
      {{ id | getCollectionNameFromId : allCollections$ | async }}
    </div>
  </ng-template>
</div>
