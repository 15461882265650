import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '../../../../../models/company/dto/location';

@Component({
  selector: 'app-template-location-selection-group',
  templateUrl: './template-location-selection-group.component.html',
  styleUrls: ['./template-location-selection-group.component.scss']
})
export class TemplateLocationSelectionGroupComponent {

  @Input() viewOnlyMode: boolean = false;
  @Input() showAddedOrRemovedStatus: boolean = false;
  @Input() expanded: boolean = false;
  @Input() province: string;
  @Input() locations: Location[];
  @Input() originalRequiredLocationIds: number[];
  @Input() updatedRequiredLocationIds: number[];
  @Input() selectedIds: string[] = [];
  @Output() addLocationIds = new EventEmitter<string[]>(true);
  @Output() removeLocationIds = new EventEmitter<string[]>(true);

}
