import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { TerpenesPreviewViewModel } from './terpenes-preview-view-model';

@Component({
  selector: 'app-terpenes-preview',
  templateUrl: './terpenes-preview.component.html',
  styleUrls: ['./terpenes-preview.component.scss'],
  providers: [TerpenesPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerpenesPreviewComponent extends BaseComponent {

  constructor(
    public viewModel: TerpenesPreviewViewModel
  ) {
    super();
  }

}
