import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplatePublishViewModel } from './template-publish-view-model';

@Component({
  selector: 'app-template-publish',
  templateUrl: './template-publish.component.html',
  styleUrls: ['./template-publish.component.scss'],
  providers: [TemplatePublishViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePublishComponent extends BaseModalComponent {

  constructor(
    public viewModel: TemplatePublishViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

  override setupBindings = () => this.viewModel.dismissModalSubject.subscribeWhileAlive({
    owner: this,
    next: c => this.close(c)
  });
  publish = () => this.close(true);

}
