import { Component } from '@angular/core';
import { ColumnOptionsFormComponent } from '../column-options-form/column-options-form.component';
import { ColumnOptionsStockViewModel } from './column-options-stock-view-model';

@Component({
  selector: 'app-column-options-stock',
  templateUrl: '../column-options-form/column-options-form.component.html',
  styleUrls: ['../column-options-form/column-options-form.component.scss'],
  providers: [ColumnOptionsStockViewModel],
})
export class ColumnOptionsStockComponent extends ColumnOptionsFormComponent {

  constructor(
    public override viewModel: ColumnOptionsStockViewModel
  ) {
    super(viewModel);
  }

}
