import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Menu } from '../models/menu/dto/menu';
import { ActionService } from './action.service';
import { TemplateCollection } from '../models/template/dto/template-collection';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { Section } from '../models/menu/dto/section';
import { Display } from '../models/display/dto/display';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class NavigationService {

  constructor(
    private router: Router,
    private actionService: ActionService,
    private ngbModal: NgbModal
  ) {
  }

  createBadge() {
    const navigationExtras: NavigationExtras = { fragment: 'badges' };
    this.actionService.setOpenNewBadgeModalFlag();
    this.router.navigate(['/settings/customization'], navigationExtras).then(_ => {});
  }

  displays() {
    this.router.navigate(['/displays']).then(_ => {});
  }

  manageProducts() {
    const navigationExtras: NavigationExtras = { fragment: 'cannabinoids&Terpenes' };
    this.router.navigate(['/settings/products'], navigationExtras).then(_ => {});
  }

  manageLabels() {
    const navigationExtras: NavigationExtras = { fragment: 'labels' };
    this.router.navigate(['/settings/customization'], navigationExtras).then(_ => {});
  }

  manageEmployees() {
    const navigationExtras: NavigationExtras = { fragment: 'employees' };
    this.router.navigate(['/settings/general'], navigationExtras).then(_ => {});
  }

  manageLogos() {
    const navigationExtras: NavigationExtras = { fragment: 'general' };
    this.router.navigate(['/settings/general'], navigationExtras).then(_ => {});
  }

  manageBadges() {
    const navigationExtras: NavigationExtras = {fragment: 'badges'};
    this.router.navigate(['/settings/customization'], navigationExtras).then(_ => {
    });
  }

  pairDevice() {
    const navigationExtras: NavigationExtras = { fragment: 'devices' };
    this.actionService.setPairDeviceFlag();
    this.router.navigate(['/settings/general'], navigationExtras).then(_ => {});
  }

  createDisplay() {
    const navigationExtras: NavigationExtras = {};
    this.actionService.setCreateDisplayFlag();
    this.router.navigate(['/displays'], navigationExtras).then(_ => {
    });
  }

  createMenu() {
    const navigationExtras: NavigationExtras = {};
    this.actionService.setCreateMenuFlag();
    this.router.navigate(['/menus/digital'], navigationExtras).then();
  }

  createPrintMenu() {
    const navigationExtras: NavigationExtras = { fragment: 'print' };
    this.actionService.setCreatePrintMenuFlag();
    this.router.navigate(['/menus/print'], navigationExtras).then();
  }

  navigateToEditDisplay(display: Display) {
    this.router.navigate([`/displays/${display.id}`]).then();
  }

  navigateToEditMenuOrTemplate(item: Menu | MenuTemplate) {
    const url = item.getEditNavigationUrl();
    if (!!url) this.router.navigate([url]).then();
  }

  navigateToEditSectionOrTemplateSection(menu: Menu, section: Section) {
    const url = section?.getEditSectionUrl(menu);
    if (url) this.router.navigate([url]).then();
  }

  navigateToTemplateCollection(collection: TemplateCollection) {
    this.router.navigate([`/templates/digital/collections/${collection.id}`]).then();
  }

  collections() {
    const navigationExtras: NavigationExtras = { fragment: 'templateCollections' };
    this.router.navigate(['/templates/digital'], navigationExtras).then(_ => {});
  }

  newPassword(extras?: NavigationExtras): void {
    this.router.navigate(['auth/new-password'], extras).then();
  }

  resetPassword(extras?: NavigationExtras): void {
    this.router.navigate(['auth/reset-password'], extras).then();
  }

  signIn(extras?: NavigationExtras): void {
    this.router.navigate(['/auth/sign-in'], extras).then();
  }

}
