import { Selectable } from '../../protocols/selectable';

export class DropDownItem implements Selectable {

  public title: string;
  public value: any;

  constructor(title: string, value: any) {
    this.title = title;
    this.value = value;
  }

  getSelectionTitle(): string {
    return this.title;
  }

  getSelectionValue(): any {
    return this.value;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }

}
