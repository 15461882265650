import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, throwError } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BaseViewModel } from 'src/app/models/base/base-view-model';
import { EditFeaturedProductMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu-view-model';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { Variant } from '../../../../../models/product/dto/variant';
import { FeaturedProductFormData } from '../../../../../models/menu/shared/featured-product-form-data';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { MenuAssets } from '../../../../../models/menu/shared/menu-assets';
import { Menu } from '../../../../../models/menu/dto/menu';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { LabelUtils } from '../../../../../modules/product-labels/utils/label-utils';

@Injectable()
export class FeaturedProductViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  private sharedViewModel: EditFeaturedProductMenuViewModel;

  // Data Transport Layer
  public locationId$ = this.locationDomainModel.locationId$;
  public locationName$ = this.locationDomainModel.locationName$;
  public priceFormat$ = this.locationDomainModel.priceFormat$;
  public companyName$ = this.companyDomainModel.companyName$;
  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  private _variantId = new BehaviorSubject<string|null>(null);
  public variantId$ = this._variantId as Observable<string|null>;
  private _variant = new BehaviorSubject<Variant|null>(null);
  public variant$ = this._variant as Observable<Variant|null>;
  public variantInStock$ = this.variant$.pipe(map(variant => variant?.inStock()));
  public variantSize$ = this.variant$.pipe(map(variant => `(${variant?.getSize()})`));
  public variantTitle$ = this.variant$.pipe(map(variant => variant?.getVariantTitle()));
  public isMedical$ = this.variant$.pipe(map(variant => variant?.isMedical));
  public variantTooltip$ = this.variant$.pipe(map(variant => {
      return variant?.displayNameHasOverride() ? variant?.name : null;
  }));
  public variantPriceTuple$ = combineLatest([
    this.locationId$,
    this.locationName$,
    this.companyName$,
    this.variant$,
    this.priceFormat$
  ]).pipe(
    map(([locId, locName, compName, variant, priceStream]) => {
      return variant?.getFormattedPrice(priceStream, locId, locName, compName, false) || [null, null, null];
    })
  );
  public variantPriceTupleIgnoreSalePrice$ = combineLatest([
    this.locationId$,
    this.locationName$,
    this.companyName$,
    this.variant$,
    this.priceFormat$
  ]).pipe(
    map(([locId, locName, compName, variant, priceStream]) => {
      return variant?.getFormattedPrice(priceStream, locId, locName, compName, true) || [null, null, null];
    })
  );
  public variantRegularPrice$ = this.variantPriceTupleIgnoreSalePrice$.pipe(map(([price]) => price));
  public variantRegularPriceInfo$ = this.variantPriceTupleIgnoreSalePrice$.pipe(map(([, priceText]) => priceText));
  public variantSalePrice$ = this.variantPriceTuple$.pipe(
    map(([price, _, isSale]) => ((!!price && isSale) ? price : null))
  );
  public variantSalePriceInfo$ = this.variantPriceTuple$.pipe(
    map(([price, priceText, isSale]) => ((!!price && isSale) ? priceText : null))
  );

  // Forms
  public formData: FeaturedProductFormData = new FeaturedProductFormData();

  // Loading
  public deletingLm = 'Deleting Feature Variant';
  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(FeaturedProductViewModel.getBaseLoadingOpts());

  // Media
  private _media = new ReplaySubject<MenuAssets>();
  private _menu = new ReplaySubject<Menu>();
  public menu$ = this._menu as Observable<Menu>;
  public theme$ = this.menu$.pipe(map(menu => menu?.hydratedTheme));
  public asset$ = combineLatest([
    this.variantId$.notNull(),
    this._menu.notNull(),
    this._media.notNull()
  ]).pipe(
    map(([variantId, menu, assets]) => {
      const variantAssetName = menu?.variantFeature?.assetNameMap?.get(variantId);
      return assets?.assets?.filterNulls()?.find(a => a.fileName === variantAssetName);
    })
  );

  public computeLabelInterface$ = combineLatest([
    this.menu$,
    this.variant$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    map(([menu, variant, locConfig, compConfig]) => {
      return LabelUtils.getComputeLabelInterfaceWithinMenuContext(menu, null, [variant], locConfig, compConfig);
    })
  );

  public generateProblemText$ = combineLatest([this.variantInStock$, this.asset$]).pipe(
    map(([inStock, asset]) => {
      switch (true) {
        case (asset && !inStock):
          return 'Product is out of stock';
        case (!asset && inStock):
          return 'Does not have media';
        default:
          return 'Does not have media and is out of stock';
      }
    })
  );

  private static getBaseLoadingOpts(): LoadingOptions {
    const loadingOpts = LoadingOptions.default();
    loadingOpts.backgroundColor = 'rgba(255, 255, 255, 0.9)';
    loadingOpts.cornerRadiusRem = 1;
    loadingOpts.absolutePosition = -0.125;
    return loadingOpts;
  }

  init(variantId?: string, sharedVM?: EditFeaturedProductMenuViewModel) {
    if (sharedVM) {
      this.sharedViewModel = sharedVM;
    }
    if (variantId) {
      this._variantId.next(variantId);
    }
    this.setupBindings();
    this.setInitialLoadingState();
  }

  public deleteFeaturedVariant(id: string) {
    // perform delete
    this.sharedViewModel.addFeatureVariantToRemoveQueue(id);
    return this.sharedViewModel.deleteFeaturedProduct(id).subscribe(_ => {
      this.toastService.publishSuccessMessage('Featured variant successfully deleted.', 'Variant Deleted');
      this.sharedViewModel.removeFeatureVariantFromRemoveQueue(id);
    }, (error: BsError) => {
      this.sharedViewModel.removeFeatureVariantFromRemoveQueue(id);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  setInitialLoadingState() {
    this.setFeatureVariantDeletingLoadingIndicator(this.sharedViewModel.deleteFeatVariantIdQueue.getValue() || []);
  }

  private setupBindings() {
    this.connectToSharedModel();
    this.assetReloadBinding();
    this.deleteFeaturedVariantBinding();
  }

  private connectToSharedModel() {
    const mediaSub = this._media.bind(this.sharedViewModel.media$);
    this.pushSub(mediaSub);
    const menuSub = this._menu.bind(this.sharedViewModel.menu$);
    this.pushSub(menuSub);
  }

  public connectToVariant(v: Variant) {
    this._variant.next(v);
  }

  private assetReloadBinding() {
    const uploadQueueSub = combineLatest([
      this.sharedViewModel.menu$,
      this.sharedViewModel.uploadedAssetQueue.notNull(),
      this.variantId$
    ]).subscribe(([menu, assets, variantId]) => {
      const lm = 'Refreshing Feature Variant';
      const fileName = menu?.variantFeature?.assetNameMap?.get(variantId);
      const assetToLoad = assets.find(a => a.name === fileName);
      if (!!assetToLoad && !this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
      } else if (this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.pushSub(uploadQueueSub);
  }

  private deleteFeaturedVariantBinding() {
    const deleteSub = combineLatest([
      this.sharedViewModel.deleteFeatVariantIdQueue.notNull(),
      this.variantId$
    ]).pipe(debounceTime(25)).subscribe(([varIds, variantId]) => {
      if (varIds.contains(variantId) && !this._loadingOpts.containsRequest(this.deletingLm)) {
        this._loadingOpts.addRequest(this.deletingLm);
      } else if (!varIds.contains(variantId) && this._loadingOpts.containsRequest(this.deletingLm)) {
        this._loadingOpts.removeRequest(this.deletingLm);
      }
    });
    this.pushSub(deleteSub);
  }

  private setFeatureVariantDeletingLoadingIndicator(ids: string[]) {
    const variantId = this._variantId.getValue();
    if (ids.contains(variantId) && !this._loadingOpts.containsRequest(this.deletingLm)) {
      this._loadingOpts.addRequest(this.deletingLm);
    } else if (!ids.contains(variantId) && this._loadingOpts.containsRequest(this.deletingLm)) {
      this._loadingOpts.removeRequest(this.deletingLm);
    }
  }

}
