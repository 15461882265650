import { Deserializable } from '../protocols/deserializable';
import { StackPrintType } from './enum/card-stack-print-type.enum';

export class CardStackPrintConfig implements Deserializable {

  printType: StackPrintType = null;
  variantIds: string[] = [];
  variantCardCountMap: Map<string, number>;
  deletedMenuName: string = '';
  inventoryRestock: boolean = false;
  priceChange: boolean = false;
  productInfoChange: boolean = false;
  cannabinoidChange: boolean = false;
  customizationChange: boolean = false;
  newProductChange: boolean = false;
  menuChange: boolean = false;
  includeChangesSince: number = 0;
  includeZeroStockItems: boolean = false;

  onDeserialize() {
    this.variantIds = Array.from(this.variantIds || []);
    this.variantCardCountMap = window?.injector?.Deserialize?.genericMap(this.variantCardCountMap) ?? new Map();
  }

  newProductChangeShouldBeForcedToTrue(): boolean {
    return this.inventoryRestock
        || this.priceChange
        || this.productInfoChange
        || this.cannabinoidChange
        || this.customizationChange
        || this.menuChange;
  }

  static readonly includeChangesSinceTooltips = {
    inventoryRestock: 'Products that have been restocked since the defined time will be reprinted',
    priceChange: 'Products that have price changes since the defined time will be reprinted',
    productInfoChange: 'Products that have updates to the general or packaging info since the defined '
      + 'time will be reprinted',
    cannabinoidChange: 'Products where the active cannabinoid was updated since the defined time will be reprinted ',
    customizationChange: 'Products where the active badge/label was updated since the defined time will be reprinted',
    newProductChange: 'Products that have been added since the defined time will be printed',
    menuChange: 'Products with card style changes since the defined time will be reprinted'
  };

}
