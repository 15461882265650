import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { OverflowStateInfoType, OverflowState, OverflowStateType } from '../../../../models/utils/dto/overflow-state-type';
import { map, switchMap, tap } from 'rxjs/operators';
import { OverflowInformationItem } from '../../../../models/shared/overflow-information-item';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import OverflowVideoList from './overflow-video-list';

@Injectable()
export class OverflowInfoModalViewModel {

  private _videos = new BehaviorSubject(OverflowVideoList);

  private _selectedOrientation = new BehaviorSubject<SegmentedControlOption>(null);
  private _overflowStateInfoType = new BehaviorSubject<OverflowStateInfoType>(null);

  private overflowStateTypes$ = this._overflowStateInfoType.pipe(
    switchMap((osit) => {
      let overflowStateType$: Observable<OverflowStateType[]>;
      switch (osit) {
        case OverflowStateInfoType.FeatCatCarousel:
          overflowStateType$ = window.types.featuredCategoryOverflowStateTypes$.pipe(
            map((types) => types.filter(
              ost => ost.value !== OverflowState.STEADY_SCROLL
            ))
          );
          break;
        case OverflowStateInfoType.FeatCatNonCarousel:
          overflowStateType$ = window.types.featuredCategoryOverflowStateTypes$;
          break;
        case OverflowStateInfoType.Default:
          overflowStateType$ = window.types.allMenuOverflowStateTypes$;
          break;
      }
      return overflowStateType$;
    })
  );

  public overflowInfoItems$ = this.overflowStateTypes$.pipe(
    switchMap((types) => this._overflowStateInfoType.pipe(
      map((osit) => {
        return types.getInfoItems(osit);
      }),
      tap((items) => this._selectedInfoItem.next(items[0]))
    ))
  );

  private _selectedInfoItem = new BehaviorSubject<OverflowInformationItem>(null);
  public selectedInfoItem$ = this._selectedInfoItem.asObservable().pipe(
    switchMap(item => this.videoUrl$.pipe(
      map((videoUrl) => ({ item, videoUrl})),
    ))
  );

  private videoUrl$: Observable<string> = combineLatest([
    this._selectedInfoItem,
    this._videos,
    this._selectedOrientation,
    this._overflowStateInfoType,
  ]).pipe(
    map(([item, videos, orient, osit]) => {
      const nameOfOverflowStateInfoType = OverflowStateInfoType[osit];
      if (!!item.overflowStateType && !!orient) {
        return videos[orient.value][nameOfOverflowStateInfoType][item.overflowStateType
          .replace('_', '').toLowerCase()];
      }
      return '';
    })
  );

  constructor() {
  }

  public updateOverflowStateInfoType(osit: OverflowStateInfoType): void {
    this._overflowStateInfoType.next(osit);
  }

  public updateSelectedInfoItem(infoItem: OverflowInformationItem): void {
    this._selectedInfoItem.next(infoItem);
  }

  public updateSelectedOrientation(orientation: SegmentedControlOption): void {
    this._selectedOrientation.next(orientation);
  }

}
