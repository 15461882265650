import { Injectable, Injector } from '@angular/core';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { PrintTemplatesContainerViewModel } from '../print-templates-container/print-templates-container-view-model';
import { DisplayableItemFilterByActive } from '../../../../../../models/enum/shared/displayable-item-filter-by.active';
import { PublishableDisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/publishable-displayable-items-view-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';

@Injectable()
export class PrintProductTemplatesViewModel extends PublishableDisplayableItemsViewModel {

  constructor(
    protected templateDomainModel: TemplateDomainModel,
    // printTemplatesContainerViewModel provided by the Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected printTemplatesContainerViewModel: PrintTemplatesContainerViewModel,
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(printTemplatesContainerViewModel, navigationService, ngbModal, injector);
    this.setupFilter();
  }

  protected itemsToFilter$ = this.templateDomainModel.printMenuTemplates$;

  protected setEmptyState(
    searchText: string,
    tag: string,
    filterDisplayableItemBy: DisplayableItemFilterByActive
  ) {
    const allTitle = 'No Print Product Templates';
    const activeTitle = 'No Published Print Product Templates';
    const inactiveTitle = 'No Draft Print Product Templates';
    this.updateEmptyStateHelper(
      [searchText, tag, filterDisplayableItemBy],
      {
        noSearchTextNoTag: () => {
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => {
              this._emptyStateTitle.next(allTitle);
              this._emptyStateDesc.next('Create a template by using the buttons above.');
            },
            active: () => {
              this._emptyStateTitle.next(activeTitle);
              const desc = 'Try showing draft templates to see if you have any print product templates created.';
              this._emptyStateDesc.next(desc);
            },
            inactive: () => {
              this._emptyStateTitle.next(inactiveTitle);
              const desc = 'Try showing published templates to see if you have any print product templates created.';
              this._emptyStateDesc.next(desc);
            }
          });
        },
        noSearchTextWithTag: () => {
          const withThatTag = ` with the tag \"${tag}\"`;
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => this._emptyStateTitle.next(allTitle + withThatTag),
            active: () => this._emptyStateTitle.next(activeTitle + withThatTag),
            inactive: () => this._emptyStateTitle.next(inactiveTitle + withThatTag)
          });
          this._emptyStateDesc.next('Try a different tag to find the template that you\'re looking for.');
        },
        searchTextNoTag: () => {
          const couldNotBeFound = ` could be found that includes \"${searchText}\"`;
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => this._emptyStateTitle.next(allTitle + couldNotBeFound),
            active: () => this._emptyStateTitle.next(activeTitle + couldNotBeFound),
            inactive: () => this._emptyStateTitle.next(inactiveTitle + couldNotBeFound)
          });
          this._emptyStateDesc.next('Try a different search query to find the template that you\'re looking for.');
        },
        searchTextWithTag: () => {
          const withThatTag = ` with the tag \"${tag}\"`;
          const withThatText = ` could be found that includes \"${searchText}\"`;
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => this._emptyStateTitle.next(allTitle + withThatTag + withThatText),
            active: () => this._emptyStateTitle.next(activeTitle + withThatTag + withThatText),
            inactive: () => this._emptyStateTitle.next(inactiveTitle + withThatTag + withThatText)
          });
          const desc = 'Try a different tag or search query to find the template that you\'re looking for.';
          this._emptyStateDesc.next(desc);
        }
      }
    );
  }

  openCreatePrintTemplateModal() {
    this.openCreateTemplateModal(MenuType.PrintMenu);
  }

}
