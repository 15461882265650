<div class="variant-grouping-header" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="1rem">
  <div class="variant-product-title">{{ viewModel.productTitle$ | async }}</div>

  <app-label-preview [zoom]="0.8" [computeLabelInterface]="viewModel.computeLabelInterface$ | async">
  </app-label-preview>

  <div fxFlex fxLayout="row" fxLayoutAlign="end" fxLayoutGap="0.5rem">
    <app-asset
      *ngFor="let badge of badges"
      class="variant-grouping-badge"
      [asset]="badge?.image"
      [scaleFit]="true"
      [borderRadius]="'0.625rem'">
    </app-asset>
  </div>

  <lib-button-inline-text
    *ngIf="menu | containsStackedContent"
    [disabled]="autoSaving || removingVariants || !includedOnMenu"
    (buttonClicked)="showLiveView.emit(variants)">
    Live View
  </lib-button-inline-text>
</div>
