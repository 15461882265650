<mat-accordion multi [displayMode]="'flat'" class="applied-products-accordion">
  <mat-expansion-panel class="mat-elevation-z0 applied-products-container">
    <mat-expansion-panel-header class="applied-products-accordion-header" [expandedHeight]="'3rem'">
      <mat-panel-title class="bs-medium f13px">
        <div class="d-flex gap-3">
          <div>
            <ng-container *rxIf="!!cardStackMenu?.templateId; else cardStackIcon">
              <img src="assets/icons/dark/solid/template.svg" [style.height.rem]="1" />
            </ng-container>
            <ng-template #cardStackIcon>
              <img src="assets/icons/dark/outline/category-cards.svg" [style.height.rem]="1" />
            </ng-template>
          </div>
          <div>{{ cardStackMenu?.name }} ({{ variantCount }})</div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
  <hr class="no-margin" />
</mat-accordion>
