import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LabelStackSectionFormViewModel } from './label-stack-section-form-view-model';
import { CardStackSectionFormComponent } from '../../edit-card-stack-menu/card-stack-details-form/card-stack-section-form.component';

@Component({
  selector: 'app-label-stack-section-form',
  templateUrl: '../../edit-card-stack-menu/card-stack-details-form/card-stack-section-form.component.html',
  providers: [LabelStackSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackSectionFormComponent extends CardStackSectionFormComponent {

  constructor(public override viewModel: LabelStackSectionFormViewModel) {
    super(viewModel);
  }

}
