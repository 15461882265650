import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-smart-filters-indicator',
  templateUrl: './smart-filters-indicator.component.html',
  styleUrls: ['./smart-filters-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFiltersIndicatorComponent implements OnChanges {

  @Input() nSmartFilters: number;
  @Input() appliedSmartFiltersTooltip: string;
  @Input() hideNumberIfOneSmartFilter = false;

  private _nSmartFilters = new BehaviorSubject<number>(0);
  public nSmartFilters$ = this._nSmartFilters.asObservable();

  private _hideNumberIfOneSmartFilter = new BehaviorSubject<boolean>(false);
  public hideNumberIfOneSmartFilter$ = this._hideNumberIfOneSmartFilter.asObservable();

  public showNumber$ = combineLatest([this._nSmartFilters, this.hideNumberIfOneSmartFilter$]).pipe(
    map(([nSmartFilters, hideNumberIfOne]) => {
      return (nSmartFilters > 0 && !hideNumberIfOne) || (nSmartFilters > 1 && hideNumberIfOne);
    })
  );

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nSmartFilters) this._nSmartFilters.next(this.nSmartFilters);
    if (changes.hideNumberIfOneSmartFilter) this._hideNumberIfOneSmartFilter.next(this.hideNumberIfOneSmartFilter);
  }

}
