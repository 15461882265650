import { AfterViewInit, ChangeDetectionStrategy, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EditDisplayViewModel } from '../../viewModels/edit-display-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { ComponentCanDeactivate } from '../../../../models/protocols/component-can-deactivate';
import { DisplayMenuSectionComponent } from '../display-section/display-menu-section/display-menu-section.component';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-display',
  templateUrl: './edit-display.component.html',
  styleUrls: [
    './edit-display.component.scss',
    '../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditDisplayViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayComponent extends BaseComponent implements AfterViewInit, ComponentCanDeactivate {

  constructor(
    public viewModel: EditDisplayViewModel,
  ) {
    super();
  }

  @ViewChild(ReactiveFormMergeGroupsComponent) formCapture: ReactiveFormMergeGroupsComponent;
  @ViewChildren(DisplayMenuSectionComponent) addedDisplays: QueryList<DisplayMenuSectionComponent>;
  private displaysAreScheduledSub: Subscription;
  private _allDisplaysAreScheduled = new BehaviorSubject<boolean>(false);
  public allDisplaysAreScheduled$ = this._allDisplaysAreScheduled.pipe(debounceTime(1));

  override setupBindings() {
    // trigger form auto submit
    this.viewModel.triggerAutoFormSubmission
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => this.formCapture?.submitForms(true));
    // are all displays scheduled?
    this.setupAreAllDisplaysScheduled(this.addedDisplays.toArray());
    this.addedDisplays.changes
      .pipe(takeUntil(this.onDestroy))
      .subscribe(displays => this.setupAreAllDisplaysScheduled(displays));
  }

  private setupAreAllDisplaysScheduled(displays: DisplayMenuSectionComponent[]) {
    this.displaysAreScheduledSub?.unsubscribe();
    this.displaysAreScheduledSub = combineLatest(displays.map(display => display.viewModel.showDateTimeOverride$))
      .pipe(
        map(isScheduledTimes => isScheduledTimes?.every(isScheduled => isScheduled)),
        takeUntil(this.onDestroy)
      )
      .subscribe(allAreScheduled => this._allDisplaysAreScheduled.next(allAreScheduled));
  }

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

}
