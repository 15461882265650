import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbandonFormComponent } from '../views/shared/modals/abandon-form/abandon-form.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalAbandonForm {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    onClose: (abandon: boolean) => void
  ) {
    const modalRef = ngbModal.open(
      AbandonFormComponent,
      ModalUtils.defaultModalOptions(injector)
    );
    return modalRef.result.then(onClose).catch((_) => onClose(false));
  }

}
