import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'saveChangesTooltip',
  standalone: true
})

export class SaveChangesTooltipPipe implements PipeTransform {

  transform(formPristine: boolean, customMessage?: string): string {
    if (formPristine) return;
    return customMessage || 'Please save changes before proceeding.';
  }

}
