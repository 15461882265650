import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Asset } from '../../../../../models/image/dto/asset';
import { Variant } from '../../../../../models/product/dto/variant';
import { FeaturedProductFormData } from '../../../../../models/menu/shared/featured-product-form-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditFeaturedProductMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu-view-model';
import { FeaturedProductViewModel } from './featured-product-view-model';
import { Menu } from '../../../../../models/menu/dto/menu';
import { combineLatest } from 'rxjs';
import { ModalEditVariant } from '../../../../../modals/modal-edit-variant';

@Component({
  selector: 'app-featured-product',
  templateUrl: './featured-product.component.html',
  styleUrls: ['./featured-product.component.scss'],
  providers: [FeaturedProductViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedProductComponent extends BaseComponent implements OnChanges {

  public media: Asset;

  @Input() mergeKey: string;
  @Input() variantId: string;
  @Input() variant: Variant;
  @Input() sharedViewModel: EditFeaturedProductMenuViewModel;
  @Input() index: number;
  @Input() last: boolean = false;
  @Input() formPristine: boolean = true;

  constructor(
    public viewModel: FeaturedProductViewModel,
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variant) {
      this.viewModel.connectToVariant(this.variant);
    }
  }

  override setupViews() {
    this.viewModel.init(this.variantId, this.sharedViewModel);
  }

  override setupBindings() {
    const variantSub = combineLatest([
      this.sharedViewModel.menu$,
      this.viewModel.variant$
    ]).notNull().subscribe(([menu, variant]) => {
      this.variant = variant;
      this.setupForm(variant, menu);
    });
    this.pushSub(variantSub);
  }

  deleteFeaturedVariant(id: string) {
    this.viewModel.deleteFeaturedVariant(id);
  }

  getData(): [string, FeaturedProductFormData] {
    return [this.variantId, this.viewModel.formData];
  }

  private setupForm(v: Variant, menu: Menu) {
    const req = new FeaturedProductFormData();
    req.desc = menu?.variantFeature?.descriptionOverrideMap?.get(v.id) || '';
    req.price = menu?.variantFeature?.priceOverrideMap?.get(v.id) || null;
    this.viewModel.formData = req;
  }

  openDisplayAttributes() {
    ModalEditVariant.open(this.ngbModal, this.injector, this.variant);
  }

}
