import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { Location } from '../../../../../models/company/dto/location';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { map } from 'rxjs/operators';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';

@Injectable()
export class TemplateAddOrEditLocationsViewModel extends BaseModalViewModel {

  constructor(
    protected locationDomainModel: LocationDomainModel,
    ngbModal: NgbModal,
    router: Router
  ) {
    super(router, ngbModal);
  }

  public stickyZIndex$ = of(2);

  public readonly allLocations$ = this.locationDomainModel.allLocations$;
  public readonly locationsGroupedByProvince$ = this.allLocations$.pipe(
    map(locations => Location.groupLocationsByProvince([locations?.map(loc => loc?.id), locations]))
  );

  private _template = new BehaviorSubject<MenuTemplate>(null);
  public template$ = this._template as Observable<MenuTemplate>;
  connectToTemplate = (template: MenuTemplate) => this._template.next(template);

  private _selectedRequiredLocationIds = new BehaviorSubject<number[]>(null);
  public selectedRequiredLocationIds$ = this._selectedRequiredLocationIds as Observable<number[]>;
  public selectedRequiredLocationIdsAsStringList$ = this.selectedRequiredLocationIds$
    .pipe(map(ids => ids?.map(id => id.toString()) ?? []));
  connectToSelectedRequiredLocationIds = (selectedRequiredLocationIds: number[]) => {
    this._selectedRequiredLocationIds.next(selectedRequiredLocationIds);
  };
  public nSelectedLocationIds$ = this.selectedRequiredLocationIds$.pipe(map(ids => ids?.length ?? 0));

  private _searchString = new BehaviorSubject<string>(null);
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _searchedLocations = new BehaviorSubject<Location[]>(null);
  public searchedLocations$ = this._searchedLocations as Observable<Location[]>;
  connectToSearchedLocations = (locations: Location[]) => this._searchedLocations.next(locations);

  public readonly searchedLocationsGroupedByProvince$ = this.searchedLocations$.pipe(
    map(locations => Location.groupLocationsByProvince([locations?.map(loc => loc?.id), locations]))
  );

  public readonly locationsVisibleOnScreen$ = this.searchedLocations$.pipe(
    map(locations => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => locations?.map(location => location?.getId()) ?? [];

      }();
    })
  );

  private addToRequiredLocationIds = (id: number) => this.selectedRequiredLocationIds$
    .once(ids => this._selectedRequiredLocationIds.next(ids?.concat([id])?.unique()));

  public addLocationIds(ids: string[]) {
    ids?.forEach(id => this.addToRequiredLocationIds(Number.parseInt(id, 10)));
  }

  private removeFromRequiredLocationIds = (id: number) => this.selectedRequiredLocationIds$
    .once(ids => this._selectedRequiredLocationIds.next(ids?.filter(currId => currId !== id)));

  public removeLocationIds(ids: string[]) {
    ids?.forEach(id => this.removeFromRequiredLocationIds(Number.parseInt(id, 10)));
  }

  trackByProvince(index, item: { key: string; value: Location[] }) {
    return item?.key;
  }

}
