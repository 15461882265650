import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { BaseService } from '@mobilefirstdev/base-angular';
import { MenuPreview } from '../models/menu/shared/menu-preview';
import { Preview } from '../models/shared/preview';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { DateUtils } from '../utils/date-utils';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { PreviewOf } from '../models/enum/shared/preview-of';
import { ChangesRequiredForPreviewService } from './changes-required-for-preview.service';
import { Menu } from '../models/menu/dto/menu';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { MenuDomainModel } from '../domainModels/menu-domain-model';
import { LocationDomainModel } from '../domainModels/location-domain-model';

export abstract class PreviewService extends BaseService {

  constructor(
    protected locationDomainModel: LocationDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected changesRequiredForPreviewService: ChangesRequiredForPreviewService
  ) {
    super();
    changesRequiredForPreviewService
      .removePreviewForAllLocations$
      .subscribeWhileAlive({
        owner: this,
        next: menu => this.removePreviewForAllLocationsAndLetBackendStartNewJobs(menu)
      });
    changesRequiredForPreviewService
      .removeAllLocationPreviews$
      .subscribeWhileAlive({
        owner: this,
        next: locId => this.removeAllLocationPreviewsOfType(locId, PreviewOf.MenuImage)
      });
  }

  static getLocationPreviewPartialKey(locId: number, previewOf: PreviewOf): string {
    return `${locId}-${previewOf}`;
  }

  static getPreviewKey(id: string, locId: number, previewOf: PreviewOf): string {
    const locAndPreviewType = PreviewService.getLocationPreviewPartialKey(locId, previewOf);
    return `${id}-${locAndPreviewType}`;
  }

  public readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly currentLocationMenus$ = this.menuDomainModel.currentLocationMenus$;

  /* **************************** API Request Queue ******************************* */

  /**
   * PreviewService.getPreviewKey's that should not return the last saved preview, but wait
   * for the new job created on the backend to finish.
   */
  protected doNotReturnLastSavedPreviewQueue: string[] = [];

  protected _requestQueue = new BehaviorSubject<string[]>([]);

  /**
   * @returns alreadyAddedToQueue: boolean
   */
  protected addRequestToQueue(key: string): Observable<boolean> {
    const currentValue = this._requestQueue.getValue() ?? [];
    if (!currentValue.contains(key)) {
      const updatedValue = [...currentValue, key];
      this._requestQueue.next(updatedValue);
      return of(false);
    }
    return of(true);
  }

  protected removeRequestFromQueue(key: string) {
    const deleteQueue = this._requestQueue.getValue()?.shallowCopy() ?? [];
    const removeIndex = deleteQueue?.indexOf(key);
    if (removeIndex > -1) deleteQueue?.splice(removeIndex, 1);
    this._requestQueue.next(deleteQueue);
  }

  public isPreviewInProgress(key: string): boolean {
    const currentValues = this._requestQueue.getValue() || [];
    const inRequestQueue = currentValues?.contains(key);
    const inDoNotReturnLastSavedQueue = this.doNotReturnLastSavedPreviewQueue?.contains(key);
    if (inDoNotReturnLastSavedQueue) {
      this.doNotReturnLastSavedPreviewQueue?.remove(key);
      return true;
    }
    return inRequestQueue;
  }

  protected _loadingPreviewMap = new BehaviorSubject<Map<string, boolean>>(new Map());
  public loadingPreviewMap$ = this._loadingPreviewMap as Observable<Map<string, boolean>>;

  protected setLoadingStateFor(key: string, state: boolean) {
    this._loadingPreviewMap.once(loadingMap => {
      const updated = loadingMap?.shallowCopy() || new Map();
      updated?.set(key, state);
      this._loadingPreviewMap.next(updated);
    });
  }

  /* ******************************* Active Preview ******************************* */

  protected _previews = new BehaviorSubject<Map<string, Preview>>(new Map());
  public previews$ = this._previews as Observable<Map<string, Preview>>;

  public removePreview(key: string) {
    this.previews$.once(previews => {
      const updatedPreviews = previews?.shallowCopy() || new Map();
      updatedPreviews?.delete(key);
      this._previews.next(updatedPreviews);
    });
  }

  public removeAllLocationPreviewsOfType(locId: number, previewOf: PreviewOf) {
    this.previews$.once(previews => {
      const updatedPreviews = previews?.shallowCopy() || new Map();
      const previewKeys = [];
      const partialKey = PreviewService.getLocationPreviewPartialKey(locId, previewOf);
      previews?.forEach((_, k: string) => {
        if (k.includes(partialKey)) {
          updatedPreviews?.delete(k);
          previewKeys?.push(k);
        }
      });
      this.doNotReturnLastSavedPreviewQueue?.push(...(previewKeys || []));
      this._previews.next(updatedPreviews);
    });
  }

  public removePreviewForAllLocationsAndLetBackendStartNewJobs(menu: Menu|MenuTemplate) {
    combineLatest([this.previews$, this.currentLocationMenus$]).once(([previews, locMenus]) => {
      const updatedPreviews = previews?.shallowCopy() || new Map();
      const keyPool = [...(updatedPreviews?.keys() || [])];
      const menuIds = [menu?.id];
      if (menu instanceof MenuTemplate) {
        locMenus?.filter(m => m?.templateId === menu?.id)?.forEach(templatedMenu => menuIds?.push(templatedMenu?.id));
      }
      const previewKeys = keyPool?.filter(key => menuIds?.some(id => key?.startsWith(id)));
      previewKeys?.forEach(previewKey => updatedPreviews?.delete(previewKey));
      this.doNotReturnLastSavedPreviewQueue?.push(...(previewKeys || []));
      this._previews.next(updatedPreviews);
    });
  }

  protected storeActivePreview(key: string, p: MenuPreview) {
    if (!!p) {
      this.previews$.once(previews => {
        const updatedPreviews = previews?.shallowCopy() || new Map();
        const hours = DateUtils.unixOneHour() * 12;
        p?.preview?.getAssetUrl(AssetSize.Original)?.loadAssetIntoSrcUrlSubjectIfCached(CachePolicy.Service, hours);
        updatedPreviews?.set(key, p);
        this._previews.next(updatedPreviews);
      });
    }
  }

  protected waitForFetch(key: string): Observable<Preview> {
    return this.loadingPreviewMap$.pipe(
      map(loadingMap => loadingMap?.get(key)),
      filter(loading => !loading),
      switchMap(_ => this.previews$),
      map(m => m?.get(key)),
      take(1)
    );
  }

}
