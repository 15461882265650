import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Variant } from '../../../../../models/product/dto/variant';
import { Asset } from '../../../../../models/image/dto/asset';
import { Menu } from '../../../../../models/menu/dto/menu';
import { BaseComponent } from '../../../../../models/base/base-component';
import { DriveThruProductViewModel } from './drive-thru-product-view-model';
import type { EditDriveThruMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu-view-model';
import { DriveThruProductData } from './drive-thru-product-data';
import { ColorUtils } from '../../../../../utils/color-utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-drive-thru-product',
  templateUrl: './drive-thru-product.component.html',
  styleUrls: ['./drive-thru-product.component.scss'],
  providers: [DriveThruProductViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriveThruProductComponent extends BaseComponent implements OnChanges {

  public media: Asset;

  @Input() variantId: string;
  @Input() variant: Variant;
  @Input() sharedViewModel: EditDriveThruMenuViewModel;
  @Input() nestedGroupKey: string;
  @Input() index: number;
  @Input() closePreview: boolean;
  @Input() menu: Menu;
  @Input() isTemplatedMenu: boolean = false;
  @Input() formPristine: boolean = true;
  @Output() selectedPreviewIndex: EventEmitter<number> = new EventEmitter<number>();

  public cardNumberTextColor$ = new BehaviorSubject<string>(null);
  public cardNumberBackgroundColor$ = new BehaviorSubject<string>(null);

  constructor(
    public viewModel: DriveThruProductViewModel,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.init(this.variantId, this.sharedViewModel);
    this.viewModel.connectToIndex(this.index);
  }

  override setupBindings() {
    const variantAndMenuSub = combineLatest([
      this.viewModel.variant$.notNull(),
      this.sharedViewModel.menu$.notNull(),
    ]).pipe(takeUntil(this.onDestroy)).subscribe(([variant, menu]) => {
        this.initData(variant, menu);
    });
    this.pushSub(variantAndMenuSub);

    const formDataSub = this.viewModel.comboCardData$.notNull().subscribe(fd => {
      if (!!fd.color) {
        const color = ColorUtils.validateHexColor(fd.color) ?? ColorUtils.BUDSENSE_EXTRA_DARK_GREY_COLOR;
        this.cardNumberBackgroundColor$.next(color);
        if (!ColorUtils.isDarkColor(fd.color)) {
          this.cardNumberTextColor$.next(ColorUtils.BUDSENSE_BLACK_COLOR);
        } else {
          this.cardNumberTextColor$.next(ColorUtils.BUDSENSE_OFF_WHITE_COLOR);
        }
      } else {
        this.cardNumberBackgroundColor$.next(ColorUtils.BUDSENSE_WHITE_COLOR);
        this.cardNumberTextColor$.next(ColorUtils.BUDSENSE_BLACK_COLOR);
      }
    });
    this.pushSub(formDataSub);
  }

  deleteFeaturedVariant(id: string) {
    this.viewModel.deleteFeaturedVariant(id);
  }

  getData(): [string, DriveThruProductData] {
    return [this.variantId, this.viewModel.formData$.getValue()];
  }

  private setFormInParentViewModel(v: Variant) {
    // Set components in sharedViewModel
    const compIndex = this.sharedViewModel.menuComboComponents.findIndex(
      comp => comp.variantId.toLowerCase() === v.id.toLowerCase()
    );
    if (compIndex < 0) {
      this.sharedViewModel.menuComboComponents.push(this);
    } else {
      this.sharedViewModel.menuComboComponents.splice(compIndex, 1);
      this.sharedViewModel.menuComboComponents.push(this);
    }
  }

  private initData(v: Variant, menu: Menu) {
    const req = new DriveThruProductData();
    req.desc = menu?.variantFeature?.descriptionOverrideMap?.get(v?.id) ?? '';
    req.price = menu?.variantFeature?.priceOverrideMap?.get(v?.id) ?? null;
    req.color = menu?.variantFeature?.getVariantColor(v?.id) ?? '';
    if (req.desc === ' ') {
      req.hideDesc = true;
    }
    this.viewModel.formData$.next(req);
    this.viewModel.comboCardDataChanged(req);
    this.setFormInParentViewModel(v);
  }

  openDisplayAttributes() {
    this.viewModel.openEditVariantModal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.index) {
      this.viewModel.connectToIndex(this.index);
    }
    if (changes.variant) {
      this.viewModel.connectToVariant(this.variant);
    }
  }

  previewSelectionChanged(selectedIndex: number) {
    if (this.index !== selectedIndex) {
      this.viewModel.closePreview();
    }
  }

}
