import { Subscribable } from '@mobilefirstdev/base-angular';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, pairwise, switchMap, takeUntil } from 'rxjs/operators';

export class LocationChangedUtils {

  static onLocationChange(
    owner: Subscribable,
    locationId$: Observable<number>,
    changed: (locationId?: number) => void
  ): Subscription {
    return locationId$
      .pipe(pairwise(), takeUntil(owner.onDestroy))
      .subscribe(([oldLocationId, newLocationId]) => {
        const hasOldAndNew = !!oldLocationId && !!newLocationId;
        if (hasOldAndNew && (oldLocationId !== newLocationId)) changed(newLocationId);
      });
  }

  static loadMenuTemplate(
    owner: Subscribable,
    locationId$: Observable<number>,
    activeMenuTemplateId$: Observable<string>,
    loadHydratedMenuTemplate: () => void
  ): Subscription {
    const locationIdsDiffer = ([prev, curr]: [number, number]) => !!prev && !!curr && (prev !== curr);
    return activeMenuTemplateId$
      .pipe(
        switchMap(menuTemplateId => locationId$.pipe(pairwise(), filter(locationIdsDiffer))),
        takeUntil(owner.onDestroy)
      )
      .subscribe(_ => loadHydratedMenuTemplate());
  }

  static loadSectionTemplate(
    owner: Subscribable,
    locationId$: Observable<number>,
    activeMenuTemplateId$: Observable<string>,
    activeSectionTemplateId$: Observable<string>,
    loadHydratedSectionTemplate: (locId: number, menuTemplateId: string, sectionTemplateId: string) => void
  ): Subscription {
    const locationIdsDiffer = ([prev, curr]: [number, number]) => !!prev && !!curr && (prev !== curr);
    return combineLatest([activeMenuTemplateId$, activeSectionTemplateId$]).pipe(
      switchMap(([menuTemplateId, sectionTemplateId]) => {
        const toOutputData = ([prev, curr]) => [curr, menuTemplateId, sectionTemplateId] as [number, string, string];
        return locationId$.pipe(pairwise(), filter(locationIdsDiffer), map(toOutputData));
      }),
      takeUntil(owner.onDestroy)
    )
    .subscribe(([locId, menuTemplateId, sectionTemplateId]) => {
      loadHydratedSectionTemplate(locId, menuTemplateId, sectionTemplateId);
    });
  }

}
