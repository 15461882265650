import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CardStackPrintConfig } from '../../../../../../../models/automation/card-stack-print-config';
import type { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { StackSmartBulkPrintOptionsFormViewModel } from './stack-smart-bulk-print-options-form-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-stack-smart-bulk-print-options-form',
  templateUrl: './stack-smart-bulk-print-options-form.component.html',
  providers: [StackSmartBulkPrintOptionsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartBulkPrintOptionsFormComponent {

  constructor(
    public viewModel: StackSmartBulkPrintOptionsFormViewModel
  ) {
  }

  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Input() blueprint: CardStackPrintConfig;
  @Input() mergeKey: string;
  @Input() viewOnly: boolean;

  @Output() smartPrintOptionChanged = new EventEmitter<string>();

  public tooltips = CardStackPrintConfig.includeChangesSinceTooltips;

}
