import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { exists } from '../../../../functions/exists';

@Injectable()
export class ProductGroupingTableEmptyStateViewModel extends BaseViewModel {

  private readonly _searchTextAndHits = new BehaviorSubject<[string, any[]]>([null, null]);
  public readonly searchTextAndHits$ = this._searchTextAndHits as Observable<[string, any[]]>;
  connectToSearchTextAndHits = (searchTextAndHits: [string, any[]]) => {
    if (!searchTextAndHits) return;
    this._searchTextAndHits.next(searchTextAndHits);
  };

  public readonly searching$ = this.searchTextAndHits$.pipe(
    map(([searchText]) => searchText?.length >= 2)
  );

  public title$ = this.searchTextAndHits$.pipe(
    map(([searchText, hits]) => {
      return exists(searchText?.length) && !hits?.length
        ? 'No Results Found'
        : 'No Product Groupings';
    })
  );

  public desc$ = this.searchTextAndHits$.pipe(
    map(([searchText, hits]) => {
      return exists(searchText?.length) && !hits?.length
        ? `No Product Groupings were found matching \"${searchText}\". Please try a different search.`
        : 'No Product Groupings have been created. To create a product grouping, select the Create Product Grouping '
          + 'button and begin adding products to your new product grouping.';
    })
  );

}
