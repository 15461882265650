import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { combineLatest } from 'rxjs';
import { exists } from '../../../../../functions/exists';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';

@Injectable()
export class TerpeneAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    public companyDomainModel: CompanyDomainModel,
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  public enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$;

  public terpeneDropdowns$ = combineLatest([
    this.enabledTerpenes$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      return terpenes?.filter(t => enabledTerpenes?.includes(t?.getSelectionValue()));
    })
  );

  public terpenes$ = combineLatest([
    this.smartFilter$,
    this.isViewOnly$,
    this.terpeneDropdowns$
  ]).pipe(
    map(([smartFilter, isViewOnly, terpeneTypes]) => terpeneTypes?.map(t => {
      const typeText = t?.getSelectionValue();
      const typeKey = typeText.toString()?.stripWhiteSpace();
      const selectionValueKey = 'min' + typeKey;
      return {
        key: typeKey,
        text: typeText,
        selectedValue: isViewOnly && exists(smartFilter[selectionValueKey]) ? smartFilter[selectionValueKey] : -1,
      };
    })),
  );

  public useToggle$ = combineLatest([
    this.companyDomainModel.inventoryProviderSupportsIndividualTerpeneValues$,
    this.companyDomainModel.syncPOSTerpene$
  ]).pipe(
    map(([supportsIndividualTerpeneValues, syncPOSTerpene]) => !supportsIndividualTerpeneValues && syncPOSTerpene)
  );

}
