import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, OnInit, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { SmartFilterVariantsTableViewModel } from '../../smart-filter-variants-table-view-model';

@Component({
  selector: 'app-smart-filter-variants-table-header',
  templateUrl: './smart-filter-variants-table-header.component.html',
  styleUrls: ['./smart-filter-variants-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => SmartFilterVariantsTableHeaderComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterVariantsTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent
  implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: SmartFilterVariantsTableViewModel
  ) {
    super(viewRef);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  initializeFromBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

}
