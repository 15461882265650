import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { CardStackPrintConfig } from '../../../../../../models/automation/card-stack-print-config';
import { StackPrintType } from '../../../../../../models/automation/enum/card-stack-print-type.enum';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { exists } from '../../../../../../functions/exists';

@Injectable()
export class StackSmartBulkPrintJobViewModel {

  constructor(private menuDomainModel: MenuDomainModel) {
    this.constructPrintConfigBlueprint();
  }

  private currentLocationStackedMenus$ = this.menuDomainModel.currentLocationStackedMenus$;

  private _stackPrintType = new BehaviorSubject<StackPrintType | null>(null);
  public stackPrintType$ = this._stackPrintType as Observable<StackPrintType | null>;
  connectToStackPrintType = (pt: StackPrintType) => this._stackPrintType.next(pt);

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    const printConfig = [...(job?.cardStackPrintConfigMap?.values() ?? [])]?.firstOrNull();
    if (printConfig) this._printConfigBlueprint.next(printConfig);
    this._job.next(job);
  };

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private _placeholder = new BehaviorSubject<string>('');
  public placeholder$ = this._placeholder as Observable<string>;
  connectToPlaceholder = (placeholder: string) => this._placeholder.next(placeholder);

  private _selectedCardStackIds = new BehaviorSubject<string[]>([]);
  public selectedCardStackIds$ = this._selectedCardStackIds as Observable<string[]>;
  connectToSelectedStackIds = (ids: string[]) => this._selectedCardStackIds.next(ids);

  private _printConfigBlueprint = new BehaviorSubject<CardStackPrintConfig | null>(null);
  public printConfigBlueprint$ = this._printConfigBlueprint.pipe(
    tap(bp => bp.newProductChange = bp?.newProductChange || bp?.newProductChangeShouldBeForcedToTrue()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _includeChangesSinceMap = new BehaviorSubject<Map<string, number>>(new Map());
  public includeChangesSinceMap$ = this._includeChangesSinceMap as Observable<Map<string, number>>;

  public updatedPrintConfigMap$ = combineLatest([
    this.selectedCardStackIds$,
    this.printConfigBlueprint$,
    this.includeChangesSinceMap$
  ]).pipe(
    map(([ids, blueprint, changesSinceMap]) => {
      const printConfigMap = new Map<string, CardStackPrintConfig>();
      ids?.forEach((id) => printConfigMap.set(id, blueprint));
      this.setIndividualChangesSinceDates(printConfigMap, changesSinceMap);
      return printConfigMap;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public selectedCardStacks$ = combineLatest([
    this.currentLocationStackedMenus$,
    this.selectedCardStackIds$,
    this.job$
  ]).pipe(
    map(([cardStacks, ids, job]) => {
      const selectedCardStacks = ids?.map(id => cardStacks?.find(cs => cs?.id === id));
      const deletedCardStacks = this.constructDeletedCardStacks(job);
      return [...(selectedCardStacks ?? []), ...(deletedCardStacks ?? [])]?.sort((SortUtils.menusByNameAsc));
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private constructDeletedCardStacks(job: BulkPrintJob): Menu[] {
    if (!job?.deletedMenuNames?.length) return [];
    const previewJobs = job?.previewJobs;
    const deletedCardStacks = previewJobs?.map(previewJob => {
      const deletedMenuName = previewJob?.cardStackPrintConfig?.deletedMenuName;
      if (deletedMenuName) {
        return Object.assign(new Menu(), { name: deletedMenuName, id: previewJob.menuId });
      }
    });
    return deletedCardStacks?.filterNulls();
  }

  public handleSmartPrintOptionChanged(prop: string): void {
    this.printConfigBlueprint$.once(blueprint => {
      const blueprintCopy = window?.injector?.Deserialize?.instanceOf(CardStackPrintConfig, blueprint);
      blueprintCopy[prop] = !blueprintCopy[prop];
      this._printConfigBlueprint.next(blueprintCopy);
    });
  }

  public handleIncludeChangesSinceChanged(updatedChangesSince: Map<string, number>): void {
    this.includeChangesSinceMap$.once(changesSinceMap => {
      const updatedMap = new Map([...changesSinceMap, ...updatedChangesSince]);
      this._includeChangesSinceMap.next(updatedMap);
    });
  }

  private constructPrintConfigBlueprint() {
    this.stackPrintType$.pipe(filter(pt => exists(pt))).once(stackPrintType => {
      const config = new CardStackPrintConfig();
      config.printType = stackPrintType;
      this._printConfigBlueprint.next(config);
    });
  }

  private setIndividualChangesSinceDates(
    printConfigMap: Map<string, CardStackPrintConfig>,
    changesSinceMap: Map<string, number>
  ): void {
    changesSinceMap?.forEach((previousPrintDate, id) => {
      if (printConfigMap?.has(id)) {
        const originalConfig = printConfigMap.get(id);
        const configCopy = window?.injector?.Deserialize?.instanceOf(CardStackPrintConfig, originalConfig);
        configCopy.includeChangesSince = previousPrintDate;
        printConfigMap.set(id, configCopy);
      }
    });
  }

}
