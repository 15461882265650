import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../functions/exists';

@Injectable()
export class ThemeCarouselImageViewModel extends BaseViewModel {

  private _card = new BehaviorSubject<Card|null>(null);
  public readonly card$ = this._card as Observable<Card|null>;
  connectToCard = (card: Card|null): void => this._card.next(card);

  private readonly _cardClass = new BehaviorSubject<string|null>(null);
  public readonly cardClass$ = this._cardClass as Observable<string|null>;
  connectToCardClass = (cardClass: string|null): void => this._cardClass.next(cardClass);

  public readonly cardClasses$: Observable<string> = combineLatest([
    this.card$,
    this.cardClass$
  ]).pipe(
    map(([card, cardClass]) => {
      return exists(card?.customClass)
        ? `${card?.customClass}` + (exists(cardClass) ? ` ${cardClass}` : '')
        : cardClass;
    })
  );

}
