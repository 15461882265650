import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { OverrideProductGroup } from '../../../models/product/dto/override-product-group';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { exists } from '../../../functions/exists';
import { ProductGroupingsViewModel } from '../../settings/components/settings-products/product-groupings/product-groupings-view-model';

@Injectable()
export class ProductGroupingTableViewModel extends BaseViewModel {

  constructor(
    private productGroupingsViewModel: ProductGroupingsViewModel
  ) {
    super();
  }

  private readonly _groupSelected = new Subject<OverrideProductGroup>();
  public readonly groupSelected$ = this._groupSelected as Observable<OverrideProductGroup>;
  connectToGroupSelected = (group: OverrideProductGroup) => this._groupSelected.next(group);

  public overrideProductGroupSelected$ = this.productGroupingsViewModel.selectedProductGroup$;

  private _sidePanelIsOpen = new BehaviorSubject(false);
  public sidePanelIsOpen$ = this._sidePanelIsOpen.pipe(distinctUntilChanged());
  connectToSidePanelIsOpen = (isOpen: boolean) => this._sidePanelIsOpen.next(isOpen);

  public readonly highlightedSelectedProductGroupId$ = combineLatest([
    this.sidePanelIsOpen$,
    this.overrideProductGroupSelected$
  ]).pipe(
    map(([sidePanelIsOpen, overrideProductGroupSelected]) => {
      return sidePanelIsOpen && exists(overrideProductGroupSelected)
        ? overrideProductGroupSelected.id
        : null;
    })
  );

}
