<app-edit-product-menu-details [templateMode]="templateMode" [mergeKey]="mergeKey">
  Menu Details Section
</app-edit-product-menu-details>

<app-menu-section-background
  [templateMode]="templateMode"
  [allowVideo]="!(viewModel.isPrintableMenu$ | push)"
  [formPristine]="formPristine"
  (backgroundDeleted)="viewModel.onSectionBackgroundDeleted()">
  Menu Background Asset Section
</app-menu-section-background>

<app-edit-product-menu-sections
  [templateMode]="templateMode"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine"
  [formAutoSubmitted]="formAutoSubmitted"
  (submitForms)="submitForms.emit($event)">
  Display Sections Added To Product Menu
</app-edit-product-menu-sections>
