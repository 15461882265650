import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Section } from '../models/menu/dto/section';
import { Orderable } from '../models/protocols/orderable';
import { Observable } from 'rxjs';
import { ReorderModalComponent } from '../views/shared/components/reorder-modal/reorder-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { ReorderOptions } from '../models/shared/stylesheet/reorder-options';
import { Injector } from '@angular/core';
import { SectionTemplate } from '../models/template/dto/section-template';
import { StringUtils } from '../utils/string-utils';

export class ModalReorderSections {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    sections: Section[],
    reorderOperation: (items: Orderable[]) => Observable<any>
  ): void {
    const modalRef = ngbModal.open(
      ReorderModalComponent,
      ModalUtils.reorderModalOptions(injector)
    );
    const inst = modalRef.componentInstance as ReorderModalComponent;
    inst.setReorderOptions(ModalReorderSections.getReorderOptions(sections));
    inst.setReorderItems(sections);
    inst.reorderOperation = reorderOperation;
  }

  static getReorderOptions(sections: Section[]): ReorderOptions {
    const isTemplate = sections?.firstOrNull() instanceof SectionTemplate;
    const opts = new ReorderOptions();
    opts.title = 'Reorder Sections';
    opts.subTitle = 'Sections';
    const bodyText = 'Drag and drop sections into the desired order that they should appear on the menu.';
    opts.bodyText = StringUtils.replaceMenuWithTemplate(bodyText, isTemplate);
    opts.confirmText = 'Confirm Order';
    opts.loadingMess = 'Reordering Sections';
    opts.successTitle = StringUtils.replaceMenuWithTemplate('Menu Updated', isTemplate);
    opts.successMess = 'Section order successfully updated.';
    opts.failureTitle = StringUtils.replaceMenuWithTemplate('Menu Error', isTemplate);
    opts.failureMess = 'Error updating section order.';
    const minPriority = Math.min(...sections.map(s => s.priority));
    const maxPriority = Math.max(...sections.map(s => s.priority));
    if (minPriority > sections.length) {
      // will be able to reorder all sections without duplicates
      opts.orderPrefix = 0;
    } else {
      // Will have duplicates so use new priority values starting at current max
      opts.orderPrefix = maxPriority + 1;
    }
    return opts;
  }

}
