import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-inline-picker-action-button',
  templateUrl: './inline-picker-action-button.component.html',
  styleUrls: ['./inline-picker-action-button.component.scss']
})
export class InlinePickerActionButtonComponent extends BaseComponent implements OnChanges {

  constructor() {
    super();
  }

  private _disabled = new BehaviorSubject<boolean>(false);
  public disabled$ = this._disabled.asObservable();

  private _canEdit = new BehaviorSubject<boolean>(false);
  public iconSrc$ = combineLatest([
    this._canEdit,
    this.disabled$,
  ]).pipe(
    map(([canEdit, disabled]) => {
      if (disabled) {
        return 'assets/icons/dark/solid/minus-sm.svg';
      } else {
        return canEdit ? 'assets/icons/dark/solid/pencil.svg' : 'assets/icons/dark/outline/plus.svg';
      }
    })
  );

  @Input() canEdit: boolean;
  @Input() disabled: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.canEdit) this._canEdit.next(this.canEdit);
    if (changes.disabled) this._disabled.next(this.disabled);
  }

}
