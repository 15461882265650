<div class="label-hierarchy-banner" fxLayout="row" [ngStyle]="{ 'background-color': bannerColor ? bannerColor : null }">
  <img
    class="warning-icon"
    fxFlexAlign="center"
    [src]="
      useLightIcon
        ? 'assets/icons/light/solid/exclamation-circle.svg'
        : 'assets/icons/dark/solid/exclamation-circle.svg'
    "
    alt="exclamation-circle.svg" />
  <div class="label-hierarchy-container">
    <div
      class="label-hierarchy-text"
      [ngStyle]="{
        color: textColor ? textColor : null,
        width: showEditHierarchyButton ? '50%' : null,
        display: showEditHierarchyButton ? 'inline-block' : null
      }">
      {{ (viewModel?.sortedLabels$ | async)?.length > 0 ? message : 'No labels have an assigned priority.' }}
    </div>
    <!-- Edit Label Hierarchy -->
    <div class="edit-label-hierarchy-buttons" [hidden]="!showEditHierarchyButton">
      <button class="bs-button preferred-button mt-4px mb-4px" (click)="openEditLabelHierarchyModal()">
        <img alt="" class="mr-6px" src="assets/icons/light/outline/collection.svg" />
        Edit Label Order
      </button>
    </div>
    <div *ngIf="(viewModel.sortedLabels$ | async)?.length" class="label-hierarchy">
      <div
        *ngFor="let l of viewModel.sortedLabels$ | async; let i = index; let last = last"
        class="sorted-label-container">
        <app-label-inflator
          [zoom]="0.7"
          [displayLabelInterface]="
            l | getDisplayLabelInterfaceForLabelHierarchy : viewModel.dataForDisplayLabelInterface$ | async
          ">
        </app-label-inflator>
        <!-- Chevron -->
        <div *ngIf="!last" class="sorted-label-divider">
          <img [src]="'assets/icons/dark/outline/chevron-right.svg'" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
