<mat-accordion multi [displayMode]="'flat'">
  <ng-container *ngFor="let province of groupedRequiredDisplays?.provinces">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="expanded">
      <mat-expansion-panel-header class="panel-header">
        <div class="province-name">
          {{ province?.name }}
        </div>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let locationGrouping of province?.locations">
        <app-location-display-list-item
          *ngFor="let display of locationGrouping?.displays"
          [display]="display"
          [displayStatusText]="
            display
              | displayChangesString : originalRequiredDisplayIds : updatedRequiredDisplayIds : collectionIsPublished
          "
          [location]="locationGrouping?.location">
        </app-location-display-list-item>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
