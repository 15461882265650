import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'lastRestockedInSeconds'
})
export class LastRestockedInSecondsPipe implements PipeTransform {

  transform(variant: Variant): number | null {
    return variant?.inventory?.lastRestockedTimeInSeconds() || null;
  }

}
