import { Variant } from '../dto/variant';

export class PrioritySortableVariant {

  public priority: number;
  public variant: Variant;

  constructor(p: number, v: Variant) {
    this.priority = p;
    this.variant = v;
  }

}
