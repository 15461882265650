import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Location } from '../../../../../../models/company/dto/location';
import { TemplateStatus } from '../../../../../../models/template/enum/template-status.enum';

@Component({
  selector: 'app-edit-template-location-list-item',
  templateUrl: './edit-template-location-list-item.component.html',
  styleUrls: ['./edit-template-location-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLocationListItemComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() templateStatus: TemplateStatus;
  @Input() province: string;
  @Input() locations: Location[];
  @Input() updatedRequiredLocations: Location[];
  @Input() originalRequiredLocations: Location[];
  @Output() provinceClicked = new EventEmitter<{ [provinceName: string]: Location[] }>(true);
  clicked = () => this.provinceClicked.emit({ [this.province]: this.locations });

}
