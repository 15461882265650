import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { ProviderUtils } from '../../../../../utils/provider-utils';
import { BadgeDomainModel } from '../../../../../domainModels/badge-domain-model';

@Injectable()
export class AdvancedAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    private badgeDomainModel: BadgeDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  // Min and Maxes
  private _minInventory = new BehaviorSubject<number>(1);
  public minInventory$ = this._minInventory as Observable<number>;
  public setMinInventory = (i: number) => this._minInventory.next(i);

  private _maxInventory = new BehaviorSubject<number>(undefined);
  public maxInventory$ = this._maxInventory as Observable<number>;
  public setMaxInventory = (i: number) => this._maxInventory.next(i);

  private _inventoryFilterUpperBound = new BehaviorSubject<number>(undefined);
  public inventoryFilterUpperBound$ = this._inventoryFilterUpperBound as Observable<number>;

  private setMinAndMaxes = combineLatest([
    this.smartFilter$,
    this.companyDomainModel.inventoryProvider$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([sf, ip]) => {
      const providerMax = ProviderUtils.getVariantInventoryUpperBound(ip);
      if (!!providerMax) {
        this.setMaxInventory(providerMax);
        this._inventoryFilterUpperBound.next(providerMax);
      } else if (!!sf?.maxInventory) {
        this.setMaxInventory(sf?.maxInventory);
      }
      if (!!sf?.minInventory) this.setMinInventory(sf?.minInventory);
    }
  });

  private allBadges$ = this.badgeDomainModel.allBadges$;

  public hydratedBadgesOnSmartFilter$ = combineLatest([
    this.smartFilter$,
    this.allBadges$
  ]).pipe(
    map(([sf, allBadges]) => {
      return allBadges.filter(b => sf?.badgeIds.indexOf(b?.id) !== -1);
    })
  );

  public smartFilterAppliedToBadgesOrLabels$ = this.smartFilter$.pipe(
    map(sf => {
      return (sf?.appliedLabelIds?.length > 0) || (sf?.appliedBadgeIds?.length > 0);
    })
  );

  public modalOpenedFromSmartLabelOrBadge$ = this.modalOpenedFrom$.pipe(
    map(of => {
      return of === AddEditSmartFilterModalOpenedFrom.SmartBadge
          || of === AddEditSmartFilterModalOpenedFrom.SmartLabel;
    })
  );

  public disableLabelAndBadgePickers$ = combineLatest([
    this.smartFilterAppliedToBadgesOrLabels$,
    this.modalOpenedFromSmartLabelOrBadge$
  ]).pipe(
    map(([appliedTo, openedFrom]) => appliedTo || openedFrom)
  );

}
