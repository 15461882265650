<div class="sticky-body-container" [style.padding-inline]="'unset'">
  <app-arrow-button
    *rxFor="let contentOption of contentOptions$"
    [title]="contentOption?.title"
    [subtitle]="contentOption?.subtitle"
    [titleFontSize]="'0.75rem'"
    [subtitleFontSize]="'0.75rem'"
    [titleFontWeight]="'500'"
    [subtitleFontWeight]="'400'"
    (clicked)="contentOptionSelected.emit(contentOption.type)">
  </app-arrow-button>
</div>
