import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SingleSelectionItem } from '../../../../models/shared/stylesheet/single-selection-item';
import { SingleSelectionItemGrouping } from '../../../../models/shared/stylesheet/single-selection-item-grouping';
import { SearchableSingleSelectViewModel } from './searchable-single-select-view-model';

@Component({
  selector: 'app-searchable-single-select',
  templateUrl: './searchable-single-select.component.html',
  providers: [SearchableSingleSelectViewModel]
})
export class SearchableSingleSelectComponent extends BaseComponent implements OnChanges {

  @Input() searchBarPlaceholder: string = 'Search by name';
  @Input() searchLookAheadItems: number = 10;
  @Input() searchableItems: SingleSelectionItem[] = null;
  @Input() searchableGroupedItems: SingleSelectionItemGrouping[] = null;
  @Input() noItemsTitle: string = 'No Items';
  @Input() noItemsBody: string = 'There are no selectable items at this time';
  @Input() stickyOrder: number;
  @Input() stickyCollectionKey: string;
  @Input() stickyZ: number;
  @Output() selectedItem = new EventEmitter<SingleSelectionItem>(true);

  constructor(
    public viewModel: SearchableSingleSelectViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchableItems) {
      this.viewModel.connectToSearchableItems(this.searchableItems);
    }
    if (changes.searchableGroupedItems) this.viewModel.connectToSearchableGroupItems(this.searchableGroupedItems);
  }

}
