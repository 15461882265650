import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HiddenFormInputComponent } from '../../../../../shared/forms/hidden-form-input/hidden-form-input.component';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PendingDisplay } from '../../../../../../models/template/dto/pending-display';

@Component({
  selector: 'app-edit-collection-pending-display-form-item',
  templateUrl: '../../../../../shared/forms/hidden-form-input/hidden-form-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => EditCollectionPendingDisplayFormItemComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditCollectionPendingDisplayFormItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionPendingDisplayFormItemComponent extends HiddenFormInputComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() pendingDisplay: PendingDisplay;
  @Output() pendingDisplayChanged = new EventEmitter<PendingDisplay>();

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.pendingDisplay && !!this.pendingDisplay) {
      this.handlePendingDisplayChange(this.pendingDisplay);
    }
  }

  handlePendingDisplayChange(pd: PendingDisplay) {
    this.handleInputChange(pd);
    this.getSelfAsFormItem()?.patchValue(pd);
    this.markAsDirty();
    this.pendingDisplayChanged.emit(pd);
  }

}
