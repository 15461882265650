import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from '../edit-template-marketing-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingFeaturedProductMenuViewModel } from './edit-template-marketing-featured-product-menu-view-model';
import { EditFeaturedProductMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class EditTemplateMarketingFeaturedProductMenuCombinedLoadingOptsViewModel
  extends EditTemplateMarketingMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingFeaturedProductMenuViewModel: EditTemplateMarketingFeaturedProductMenuViewModel,
    protected editFeaturedProductMenuViewModel: EditFeaturedProductMenuViewModel,
  ) {
    super(editTemplateMarketingFeaturedProductMenuViewModel, editFeaturedProductMenuViewModel);
  }

}
