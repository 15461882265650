import { AssetSize } from '../../enum/dto/asset-size.enum';

export class AssetPreviewOptions {

  public previewSize: AssetSize = AssetSize.Medium;
  public primaryButtonText: string;
  public primaryButtonDestructive: boolean = false;
  public secondaryButtonText: string;
  public tertiaryButtonText: string;

}
