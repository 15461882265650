<app-edit-spotlight-menu-details [templateMode]="templateMode" [mergeKey]="mergeKey"> </app-edit-spotlight-menu-details>

<app-edit-spotlight-menu-section-details
  [templateMode]="templateMode"
  [mergeKey]="mergeKey"
  [formPristine]="formPristine">
</app-edit-spotlight-menu-section-details>

<app-menu-section-location-permissions
  *ngIf="
    (sectionViewModel.section$ | async | isSectionTemplate) && (sectionViewModel.section$ | async | isSpotlightSection)
  "
  [mergeKey]="mergeKey"
  [sectionTemplate]="sectionViewModel.section$ | async | castToSectionTemplate">
</app-menu-section-location-permissions>

<app-menu-section-background
  [templateMode]="templateMode"
  [formPristine]="formPristine"
  (backgroundDeleted)="viewModel.onSectionBackgroundDeleted()">
  Menu Background Asset Section
</app-menu-section-background>

<app-menu-section-smart-filters
  [templateMode]="templateMode"
  [menu]="viewModel.menu$ | async"
  [section]="sectionViewModel.section$ | async"
  [autoSaving]="viewModel.autoSaving$ | async"
  [isTemplatedSection]="viewModel.isTemplatedMenu$ | async"
  [mergeKey]="mergeKey"
  [formPristine]="formPristine">
</app-menu-section-smart-filters>

<app-edit-spotlight-menu-products
  [templateMode]="templateMode"
  [combinedAutoSaving]="sharedLoadingState.autoSaving$ | async"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine">
</app-edit-spotlight-menu-products>
