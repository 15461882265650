import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { Observable } from 'rxjs';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import { map } from 'rxjs/operators';
import { LabelUtils } from '../utils/label-utils';
import { Section } from '../../../models/menu/dto/section';
import { Variant } from '../../../models/product/dto/variant';
import { Menu } from '../../../models/menu/dto/menu';
import { DisplaySaleLabelInterface } from '../label/display-sale-label-interface';

@Pipe({
  name: 'connectLabelPreviewToDisplaySaleLabelInterface'
})
export class ConnectLabelPreviewToDisplaySaleLabelInterfacePipe implements PipeTransform {

  transform(
    label: Label,
    displayLabelData$: Observable<[Menu, Section, Variant[], LocationConfiguration, CompanyConfiguration]>,
    secondary: boolean
  ): Observable<DisplaySaleLabelInterface> {
    return displayLabelData$.pipe(
      map(([menu, section, variants, locationConfig, companyConfig]) => {
        return LabelUtils.getDisplaySaleLabelInterfaceForLabelPreview(
          [label, secondary, secondary],
          [menu, section, variants],
          [locationConfig, companyConfig]
        );
      })
    );
  }

}
