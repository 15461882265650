<div class="edit-page-footer">
  <!--   Action buttons   -->
  <div class="edit-page-action-buttons" [style.gap.rem]="1">
    <lib-button-text
      [textColor]="'#FA5555'"
      [disabled]="viewModel?.autoSaving$ | async"
      (buttonClicked)="viewModel.deleteTemplate()">
      Delete Template
    </lib-button-text>
    <lib-button-text
      [disabled]="(viewModel?.autoSaving$ | async) || !formPristine"
      [disabledTooltip]="formPristine | duplicateButtonDisabledTooltip"
      (buttonClicked)="viewModel?.openDuplicateTemplateModal()">
      Duplicate Template
    </lib-button-text>
    <lib-button-primary
      [infoText]="viewModel.lastAutoSavedTimeText$ | async"
      [disabled]="(viewModel.autoSaving$ | async) || formDisableSubmit"
      [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
      [loading]="viewModel.autoSaving$ | async"
      (buttonClicked)="submitForms.emit(false)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
