<div class="label-stack-product-selection-item">
  <div class="flex-container bs-medium gap-8px">
    <span class="f12px">{{ variant | getVariantTitle }}</span>
    <span class="f11px variant-size">{{ variant | variantFormattedSizing : true }}</span>
  </div>
  <app-incrementor
    [disabled]="viewOnly"
    [minValue]="0"
    [maxValue]="100"
    [value]="variantCountMap | getInitialValueFromMap : variant?.id"
    (valueChanged)="updateVariantCountMap.emit($event)">
  </app-incrementor>
</div>
