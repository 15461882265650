import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../../models/company/dto/location';

@Pipe({
  name: 'numberOfLocations'
})
export class NumberOfLocationsPipe implements PipeTransform {

  transform(locations: Location[]): string {
    return locations?.length > 0
      ? `${locations?.length} Location${locations?.length > 1 ? 's' : ''}`
      : 'No locations';
  }

}
