<div class="preview-section">
  <div class="preview-header bs-bold f20px">Preview</div>
  <div class="preview-customization-info-container">
    <div class="preview-customization-label-container">
      <div [hidden]="!(viewModel.computedPrimaryLabel$ | async)">
        <app-label-preview
          [zoom]="0.65"
          [stackLabelsVertically]="true"
          [computeLabelInterface]="viewModel.computeLabelInterface$ | async"
          (computedPrimaryLabel)="viewModel.setPrimaryComputedLabel($event)">
        </app-label-preview>
        <div *ngIf="!!(viewModel.labelSource$ | async)" class="label-source">
          {{ viewModel.labelSource$ | async }}
        </div>
      </div>
      <div [hidden]="!!(viewModel.computedPrimaryLabel$ | async)">
        <div class="f24px bs-bold">--</div>
      </div>
    </div>
    <div class="preview-customization-badge-container" [style.margin-top.px]="labelOffset">
      <ng-container *ngIf="!!badgesAndSource?.badges; else nullText">
        <div class="badge-list">
          <ng-container *ngFor="let badge of badgesAndSource.badges">
            <div class="badge-img" [ngClass]="{ 'badge-padding': badgesAndSource.badges.length > 1 }">
              <app-asset [asset]="badge?.image" [scaleFit]="true"> </app-asset>
            </div>
          </ng-container>
        </div>
        <div class="badge-source">({{ badgesAndSource.source }})</div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #nullText>
  <div class="f24px bs-bold">--</div>
</ng-template>
