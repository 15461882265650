import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { map } from 'rxjs/operators';

@Injectable()
export class FormGroupViewModel extends BaseViewModel {

  public companyConfiguration$ = this.companyDomainModel.companyConfiguration$;
  public companyColorPalette$ = this.companyConfiguration$.pipe(map(cc => cc?.colorPalette));

  constructor(
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

}
