import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge-added-by-smart-filter-indicator',
  templateUrl: './badge-added-by-smart-filter-indicator.component.html',
  styleUrls: ['./badge-added-by-smart-filter-indicator.component.scss']
})
export class BadgeAddedBySmartFilterIndicatorComponent {

  @Input() backgroundColor: string = '#000000';
  @Input() borderRadius: string = '50%';
  @Input() height: string = '1rem';
  @Input() width: string = '1rem';
  @Input() iconScale: number = 0.7;

}
