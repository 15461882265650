<lib-reactive-form-group #filterForm [bindTo]="bindTo" [mergeKey]="mergeKey">
  <div *ngIf="includeNameAndEnableSwitch" class="flex-container mb-16px">
    <div class="name">{{ viewModel.fileNameWithoutTimestampAndExtension$ | async }}</div>
    <lib-reactive-form-switch
      [inputName]="'enabled'"
      [selectedValue]="true"
      [notSelectedValue]="false"
      [bindingProperty]="'enabled'"
      [disabled]="(viewModel.assetMetadataNotLoaded$ | async) || isDisabledByTemplates"
      [disabledTooltip]="isDisabledByTemplates | templatedMenuDisabledButtonTooltip"
      (valueChanged)="enableMediaClicked($event)">
      Enable Media
    </lib-reactive-form-switch>
  </div>

  <lib-reactive-form-column-layout [nColumns]="1">
    <lib-reactive-form-number
      #displayDuration
      *ngIf="viewModel.showDisplayDuration$ | async"
      [inputName]="'interval'"
      [label]="'Display Duration (seconds)'"
      [placeholder]="'Enter the display duration'"
      [integersOnly]="true"
      [inlineLabel]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="999999"
      [disabled]="!(viewModel.displayDurationEnabled$ | async) || isDisabledByTemplates"
      [disabledTooltip]="isDisabledByTemplates | templatedMenuDisabledFormControlTooltip"
      [bindingProperty]="'interval'">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      #loopContent
      *ngIf="viewModel.showLoopContent$ | async"
      [inputName]="'loop'"
      [label]="'Play Count'"
      [placeholder]="'Play count'"
      [tooltip]="'Number of times to loop before changing to new content.'"
      [integersOnly]="true"
      [inlineLabel]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="999999"
      [disabled]="!(viewModel.enabled$ | async) || isDisabledByTemplates"
      [disabledTooltip]="isDisabledByTemplates | templatedMenuDisabledButtonTooltip"
      [customValueParser]="scopedParseLoopContent"
      [programmaticallyChangeValue]="viewModel.changeLoopCount$ | async"
      [bindingProperty]="'loop'">
    </lib-reactive-form-number>

    <lib-reactive-form-text
      #uploadDate
      *ngIf="viewModel.showUploadDate$ | async"
      [inputName]="'uploaded-date'"
      [label]="'Uploaded Date'"
      [placeholder]="'Date'"
      [inlineLabel]="true"
      [required]="false"
      [disabled]="true"
      [bindingProperty]="'date'">
    </lib-reactive-form-text>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
