import { EditSectionFormViewModel } from '../edit-section-form/edit-section-form-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CardType } from '../../../../../../models/utils/dto/card-type-definition';
import { combineLatest } from 'rxjs';
import { StringUtils } from '../../../../../../utils/string-utils';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';

@Injectable()
export class EditFeaturedCategorySectionFormViewModel extends EditSectionFormViewModel {

  constructor(
    locationDomainModel: LocationDomainModel
  ) {
    super(locationDomainModel);
  }

  public sectionTitleLabel$ = this.menu$.pipe(
    map(menu => {
      const cardType = menu?.getFeaturedCategoryCardType();
      switch (cardType?.value) {
        case CardType.ProductDealCarousel:
          return 'Section Title (Internal only)';
        default:
          return 'Section Title';
      }
    })
  );

  public sectionSubtitlePlaceholder$ = combineLatest([
    this.menu$,
    this.section$,
  ]).pipe(
    map(([menu, section]) => {
      const defaultPlaceholder = 'Category Card Subtitle';
      const cardType = menu?.getFeaturedCategoryCardType();
      switch (cardType?.value) {
        case CardType.ProductDealCarousel:
          const variantsBrand = StringUtils.getStringMode(section?.variants?.filter(v => !!v.brand)?.map(v => v.brand));
          return !!variantsBrand ? variantsBrand : defaultPlaceholder;
        default:
          return defaultPlaceholder;
      }
    })
  );

}
