<div class="loading-container">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
  <div class="media-container">
    <div class="preview-wrapper">
      <!--   Loading spinner for refresh image   -->
      <app-loading
        [hidden]="!(viewModel?.isRefreshingMediaLoading$ | async)"
        [options]="viewModel?.refreshingMediaLoadingOpts$ | async">
      </app-loading>
      <!--   Overlap wrapper for disabled items   -->
      <div [ngClass]="{ disabled: !enabled }" class="image-overlay"></div>
      <app-asset
        class="preview"
        [scaleFit]="true"
        [borderRadius]="'0.625rem'"
        [style.max-width]="'200px'"
        [showControls]="true"
        [asset]="file"
        (duration)="listenForDuration($event)">
      </app-asset>
    </div>
    <div class="media-info-container flex-fill">
      <div *ngIf="file && !replaceMediaSwitch">
        <app-menu-media-form
          [mergeKey]="mergeKey"
          [isDisabledByTemplates]="isDisabledByTemplates"
          [includeNameAndEnableSwitch]="true"
          [mediaDuration]="mediaDuration"
          [file]="file"
          (enabledChanged)="enabled = $event"
          [bindTo]="viewModel.req">
        </app-menu-media-form>
      </div>
      <div *ngIf="!file || replaceMediaSwitch">
        <app-upload-asset
          [parentHandler]="this"
          [allowImage]="true"
          [allowVideo]="true"
          [formPristine]="formPristine"
          [isDisabledByTemplates]="isDisabledByTemplates"
          [maxAssets]="1"
          [displayList]="false"
          [id]="index + 5">
        </app-upload-asset>
      </div>
      <div fxLayout="row wrap" [style.gap.rem]="0.5">
        <lib-button-neutral
          (buttonClicked)="refreshMedia()"
          [hidden]="!file"
          [disabled]="(viewModel?.isRefreshingMediaLoading$ | async) || !formPristine"
          [disabledTooltip]="formPristine | saveChangesTooltip">
          <img alt="" src="assets/icons/dark/outline/refresh.svg" style="margin-right: 0" />
        </lib-button-neutral>
        <lib-button-neutral
          *ngIf="file"
          [disabled]="isDisabledByTemplates || !formPristine"
          [disabledTooltip]="
            (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
          "
          (buttonClicked)="toggleReplaceMediaSwitch()">
          {{ replaceMediaSwitch ? 'Cancel' : 'Replace Media' }}
        </lib-button-neutral>
        <lib-button-destructive
          *ngIf="destructiveButtonText"
          [disabled]="!(sharedViewModel?.allowDelete$ | async) || isDisabledByTemplates || !formPristine"
          [disabledTooltip]="
            (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
          "
          (buttonClicked)="toggleRemoveMediaDialog()">
          {{ destructiveButtonText }}
        </lib-button-destructive>
      </div>
    </div>
  </div>
  <hr *ngIf="bottomDivider" />
</div>
