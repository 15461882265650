import { exists } from '../functions/exists';

export class ParserUtils {

  static parseAsString(s: any): string {
    return exists(s) ? `${s}` : null;
  }

  static parseFloatAsString = (val: string) => {
    const parsedNumber = Number.parseFloat(val);
    return Number.isFinite(parsedNumber) ? parsedNumber.toString() : '';
  };

  static parseIntAsString = (val: string) => {
    const parsedNumber = Number.parseInt(val, 10);
    return Number.isFinite(parsedNumber) ? parsedNumber.toString() : '';
  };

  static parseIntAsPaddedString = (val: string) => {
    const expectedLength = val?.length;
    const parsedNumber = Number.parseInt(val, 10);
    const parsedString = Number.isFinite(parsedNumber) ? parsedNumber.toString() : '';
    if (parsedString.length !== expectedLength) {
      // Pad the string with leading zeros
      return parsedString.padStart(expectedLength, '0');
    } else {
      return parsedString;
    }
  };

}
