import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Tabbable } from '../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { DisplayableItemsViewModel } from '../displayable-item-list/displayable-items-view-model';

@Component({ selector: 'app-displayable-items', template: ''})
export abstract class DisplayableItemsComponent extends BaseComponent implements Tabbable {

  protected constructor(
    public viewModel: DisplayableItemsViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

}
