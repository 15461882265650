import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FeaturedCategoryViewModel } from './featured-category-view-model';
import { EditFeaturedCategoryMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu-view-model';
import { Menu } from '../../../../../models/menu/dto/menu';
import { Section } from '../../../../../models/menu/dto/section';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-featured-category',
  templateUrl: './featured-category.component.html',
  styleUrls: ['./featured-category.component.scss'],
  providers: [FeaturedCategoryViewModel]
})
export class FeaturedCategoryComponent extends BaseComponent implements OnChanges {

  @Input() templateMode: boolean;
  @Input() sharedViewModel: EditFeaturedCategoryMenuViewModel;
  @Input() section: Section;
  @Input() menu: Menu;
  @Input() index: number;
  @Input() formPristine: boolean = true;

  @Output() deleteClicked = new EventEmitter<Section>();
  @Output() duplicateClicked = new EventEmitter();

  constructor(
    public viewModel: FeaturedCategoryViewModel,
  ) {
    super();
  }

  editSection() {
    this.sharedViewModel.navigateToEditSectionView(this.section);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharedViewModel) this.viewModel.init(this.sharedViewModel);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.index) this.viewModel.connectToIndex(this.index);
  }

}
