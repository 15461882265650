import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BulkPrintJobsListViewModel } from '../bulk-print-jobs-list/bulk-print-jobs-list-view-model';

@Component({
  selector: 'app-bulk-print-filter-form',
  templateUrl: './bulk-print-filter-form.component.html',
  styleUrls: ['../../../../../shared/components/displayable-content/displayable-content-filter-form/displayable-content-filter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkPrintFilterFormComponent {

  constructor(public bulkPrintViewModel: BulkPrintJobsListViewModel) {
  }

}
