export class StringifyUtils {

  static mapDataType = 'Map';

  static replacer(key: string|number|symbol, value: any): any {
    const ignoredKeys: string[] = ['inheritedDisplayAttribute', 'srcUrl', 'loading', 'sizePriorityUrl$'];
    const originalObject = this[key];
    if (typeof key === 'string' && ignoredKeys?.contains(key)) {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj: { [key: string|number|symbol]: any } = {};
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }

  static displayAppReplacer(key: string|number|symbol, value: any): any {
    const ignoredKeys: string[] = ['srcUrl', 'loading', 'sizePriorityUrl$'];
    const originalObject = this[key];
    if (typeof key === 'string' && ignoredKeys?.contains(key)) {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj: { [key: string|number|symbol]: any } = {};
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }

  static webWorkerReplacer(key: string|number|symbol, value: any): any {
    return StringifyUtils.displayAppReplacer(key, value);
  }

  static cachedBlobReplacer(key: string|number|symbol, value: any) {
    const originalObject = this[key];
    if (key === 'url' || key === 'safeUrl' || key === 'blob') {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj: { [key: string|number|symbol]: any } = {};
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }

}
