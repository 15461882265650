import { Injectable } from '@angular/core';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NavigationService } from '../navigation.service';

type Activation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(
    public userDomainModel: UserDomainModel,
    public router: Router,
    private navigationService: NavigationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activation {
    return this.userDomainModel.isAuthenticated().pipe(
      map(r => r !== null),
      tap(sess => {
        if (!sess) {
          this.navigationService.signIn();
        }
      })
    );
  }

}
