<div class="employee-list-item-container">
  <div class="employee-flex-container" fxLayout="row" fxLayoutAlign=" center">
    <div class="employee-info" fxFlex fxLayout="column">
      <div class="employee-name">{{ employee?.getFullName() }}</div>
      <div class="employee-email-container" fxLayout="row" fxLayoutAlign="flex-start center">
        <div class="employee-email" [style.color]="!employee?.emailConfirmed ? '#FA5555' : '#9FA2AD'">
          {{ employee?.email }}
        </div>
        <app-info-icon
          *ngIf="!employee?.emailConfirmed"
          [information]="employee?.getFullName() + '\'s email is not verified. They will not receive this email!'">
        </app-info-icon>
      </div>
    </div>
    <div
      class="text-link destructive-link"
      appThrottleClick
      (throttleClick)="removeClicked.emit(employee)"
      *ngIf="!hideRemoveButton">
      Remove
    </div>
  </div>
  <hr class="no-margin" />
</div>
