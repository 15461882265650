import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent {

  // Toggle Switch
  @Input() includeToggleSwitch: boolean = false;
  @Input() toggleLabel: string;
  @Input() toggleState: boolean;
  @Input() toggledEnabled: boolean;
  @Output() switchToggled = new EventEmitter<boolean>();

  // Save Button
  @Input() includeSaveButton: boolean = false;
  @Input() buttonLabel: string = 'Save Changes';
  @Input() canSubmit: boolean = false;
  @Output() buttonClicked = new EventEmitter<any>();

  constructor() {}

}
