import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { EditCollectionSaveConfirmationViewModel } from './edit-collection-save-confirmation-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-collection-save-confirmation',
  templateUrl: './edit-collection-save-confirmation.component.html',
  styleUrls: ['./edit-collection-save-confirmation.component.scss'],
  providers: [EditCollectionSaveConfirmationViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionSaveConfirmationComponent extends BaseModalComponent {

  constructor(protected activeModal: NgbActiveModal, public viewModel: EditCollectionSaveConfirmationViewModel) {
    super(activeModal);
  }

}
