import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnOptionsViewModel } from '../../../viewModels/column-options-view-model';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { Theme } from '../../../../../models/menu/dto/theme';
import { DefaultSectionColumnConfig } from '../../../../../models/menu/dto/default-section-column-config';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { ColumnOptionsBadgesComponent } from './components/column-options-badges/column-options-badges.component';
import { ColumnOptionsBrandComponent } from './components/column-options-brand/column-options-brand.component';
import { ColumnOptionsClassificationComponent } from './components/column-options-classification/column-options-classification.component';
import { ColumnOptionsQuantityComponent } from './components/column-options-quantity/column-options-quantity.component';
import { ColumnOptionsSizeComponent } from './components/column-options-size/column-options-size.component';
import { ColumnOptionsCbdComponent } from './components/column-options-cbd/column-options-cbd.component';
import { ColumnOptionsThcComponent } from './components/column-options-thc/column-options-thc.component';
import { ColumnOptionsPriceComponent } from './components/column-options-price/column-options-price.component';
import { ColumnOptionsSecondaryPriceComponent } from './components/column-options-secondary-price/column-options-secondary-price.component';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ColumnOptionsAssetComponent } from './components/column-options-asset/column-options-asset.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { SectionColumnConfigKey } from '../../../../../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../../../../../models/utils/dto/section-column-config-default-state-type';
import { ColumnOptionsQuantityAndSizeComponent } from './components/column-options-quantity-and-size/column-options-quantity-and-size.component';
import { SectionType } from '../../../../../models/utils/dto/section-type-definition';
import { ColumnOptionsStockComponent } from './components/column-options-stock/column-options-stock.component';

@Component({
  selector: 'app-column-options-modal',
  templateUrl: './column-options-modal.component.html',
  styleUrls: ['./column-options-modal.component.scss'],
  providers: [ColumnOptionsViewModel],
})
export class ColumnOptionsModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: ColumnOptionsViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  @ViewChild('contentContainer') tabContainer: ElementRef<HTMLDivElement>;
  @ViewChild('tabController') tabController: TabBarComponent;
  @ViewChild('columnOptionsFormGroup') columnOptionsFormGroup: ReactiveFormMergeGroupsComponent;

  public canChangeTabsErrorMsg = new BehaviorSubject<string>('Please correct the invalid data before changing tabs.');

  private dialogRO: ResizeObserver;
  private tabContainerRO: ResizeObserver;
  private _modalBodyHeight = new BehaviorSubject<number>(0);
  public modalBodyHeight$ = this._modalBodyHeight.pipe(distinctUntilChanged());

  public initModal(section: HydratedSection, theme: Theme, defaultOptions: DefaultSectionColumnConfig) {
    this.viewModel.initData(section, theme, defaultOptions);
  }

  override setupBindings(): void {
    this.observeDialog();
    this.observeTabContainer();
    this.viewModel.autoSubmitForm$.subscribeWhileAlive({
      owner: this,
      next: () => this.columnOptionsFormGroup?.submitForms(true)
    });
  }

  private observeDialog(): void {
    this.dialogRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._modalBodyHeight.next(entry.contentRect.height);
      }
    });
    this.dialogRO.observe(document.getElementsByClassName('modal-dialog').item(0));
  }

  private observeTabContainer(): void {
    this.tabContainerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.calculateBottomButtonContainerPosition(entry.contentRect.height);
      }
    });
    this.tabContainerRO.observe(this.tabContainer.nativeElement);
  }

  /*
   * This lives in here and not the view model because the imports required for it to live in the view model
   * cause circular dependency issues.
   */
  public tabs$ = combineLatest([
    this.viewModel.section$,
    this.viewModel.themeColumnConfig$
  ]).pipe(
    map(([section, themeColumnConfig]) => {
      const restockedSectionAssetColDisabled = (key: SectionColumnConfigKey): boolean => {
        const restockedSection = section?.sectionType === SectionType.RestockedProducts;
        return restockedSection && key === SectionColumnConfigKey.Asset;
      };
      const isDisabled = (key: SectionColumnConfigKey): boolean => {
        const configDisabled = themeColumnConfig?.get(key)?.defaultState === SectionColumnConfigDefaultState.Disabled;
        return configDisabled || restockedSectionAssetColDisabled(key);
      };
      const orderedSectionColumnConfigKeys = [
        SectionColumnConfigKey.Asset,
        SectionColumnConfigKey.Badges,
        SectionColumnConfigKey.Brand,
        SectionColumnConfigKey.StrainType,
        SectionColumnConfigKey.Quantity,
        SectionColumnConfigKey.Size,
        SectionColumnConfigKey.QuantityAndSize,
        ...(section?.supportsStockColumn() ? [SectionColumnConfigKey.Stock] : []),
        SectionColumnConfigKey.CBD,
        SectionColumnConfigKey.THC,
        SectionColumnConfigKey.Price,
        SectionColumnConfigKey.SecondaryPrice
      ];
      const tabPosition = new Map<SectionColumnConfigKey, number>();
      orderedSectionColumnConfigKeys.forEach((key, index) => tabPosition.set(key, index));

      const tabDisabled = new Map<SectionColumnConfigKey, boolean>();
      orderedSectionColumnConfigKeys.forEach((key) => tabDisabled.set(key, isDisabled(key)));

      let selectedIndexFound: boolean = false;
      let currentTabId = 0;
      const checkForInitialSelectedIndex = (key: SectionColumnConfigKey): void => {
        if (!selectedIndexFound && !tabDisabled.get(key)) {
          currentTabId = tabPosition.get(key);
          selectedIndexFound = true;
        }
      };
      // Asset
      const assetComponent = ColumnOptionsAssetComponent;
      const assetTitle = 'Image';
      const assetDisabled = isDisabled(SectionColumnConfigKey.Asset);
      const assetTooltip = restockedSectionAssetColDisabled(SectionColumnConfigKey.Asset)
        ? assetTitle + `s are disabled for restocked product sections.`
        : assetTitle + `s are disabled for this theme.`;
      const asset = new TabBarItem(assetComponent, assetTitle, '', false, assetDisabled, null, assetTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Asset);
      // Badge
      const badgeComponent = ColumnOptionsBadgesComponent;
      const badgesTitle = SectionColumnConfigKey.Badges;
      const badgesDisabled = isDisabled(SectionColumnConfigKey.Badges);
      const badgeTooltip = badgesTitle + ' are disabled for this theme.';
      const badges = new TabBarItem(badgeComponent, badgesTitle, '', false, badgesDisabled, null, badgeTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Badges);
      // Brand
      const brandComponent = ColumnOptionsBrandComponent;
      const brandTitle = SectionColumnConfigKey.Brand;
      const brandDisabled = isDisabled(SectionColumnConfigKey.Brand);
      const brandTooltip = brandTitle + ' is disabled for this theme.';
      const brand =  new TabBarItem(brandComponent, brandTitle, '', false, brandDisabled, null, brandTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Brand);
      // Strain Type
      const classComponent = ColumnOptionsClassificationComponent;
      const classTitle = 'Strain Type';
      const classDisabled = isDisabled(SectionColumnConfigKey.StrainType);
      const classTooltip = classTitle + ' is disabled for this theme.';
      const classification = new TabBarItem(classComponent, classTitle, '', false, classDisabled, null, classTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.StrainType);
      // Quantity
      const qtyComponent = ColumnOptionsQuantityComponent;
      const qtyTitle = SectionColumnConfigKey.Quantity;
      const qtyDisabled = isDisabled(SectionColumnConfigKey.Quantity);
      const qtyTooltip = qtyTitle + ' is disabled for this theme.';
      const quantity = new TabBarItem(qtyComponent, qtyTitle, '', false, qtyDisabled, null, qtyTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Quantity);
      // Size
      const sizeComponent = ColumnOptionsSizeComponent;
      const sizeTitle = SectionColumnConfigKey.Size;
      const sizeDisabled = isDisabled(SectionColumnConfigKey.Size);
      const sizeTooltip = sizeTitle + ' is disabled for this theme.';
      const size = new TabBarItem(sizeComponent, sizeTitle, '', false, sizeDisabled, null, sizeTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Size);
      // Quantity and Size
      const qtySizeComp = ColumnOptionsQuantityAndSizeComponent;
      const qtySizeTitle = 'Quantity x Size';
      const qtySizeDisabled = isDisabled(SectionColumnConfigKey.QuantityAndSize);
      const qtySizeTooltip = qtySizeTitle + ' is disabled for this theme.';
      const qtyAndSize = new TabBarItem(qtySizeComp, qtySizeTitle, '', false, qtySizeDisabled, null, qtySizeTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.QuantityAndSize);
      // Quantity In Stock
      const stockComp = ColumnOptionsStockComponent;
      const stockTitle = 'Quantity In Stock';
      const stockDisabled = isDisabled(SectionColumnConfigKey.QuantityAndSize);
      const stockTooltip = stockTitle + ' is disabled for this theme.';
      const stock = new TabBarItem(stockComp, stockTitle, '', false, stockDisabled, null, stockTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Stock);
      // CBD
      const cbdComponent = ColumnOptionsCbdComponent;
      const cbdTitle = SectionColumnConfigKey.CBD;
      const cbdDisabled = isDisabled(SectionColumnConfigKey.CBD);
      const cbdTooltip = cbdTitle + ' is disabled for this theme.';
      const cbd = new TabBarItem(cbdComponent, cbdTitle, '', false, cbdDisabled, null, cbdTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.CBD);
      // THC
      const thcComponent = ColumnOptionsThcComponent;
      const thcTitle = SectionColumnConfigKey.THC;
      const thcDisabled = isDisabled(SectionColumnConfigKey.THC);
      const thcTooltip = thcTitle + ' is disabled for this theme.';
      const thc = new TabBarItem(thcComponent, thcTitle, '', false, thcDisabled, null, thcTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.THC);
      // Price
      const priceComponent = ColumnOptionsPriceComponent;
      const priceTitle = SectionColumnConfigKey.Price;
      const priceDisabled = isDisabled(SectionColumnConfigKey.Price);
      const priceTooltip = priceTitle + ' is disabled for this theme.';
      const price = new TabBarItem(priceComponent, priceTitle, '', false, priceDisabled, null, priceTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.Price);
      // Secondary Price
      const secPriceComp = ColumnOptionsSecondaryPriceComponent;
      const secTitle = 'Secondary Price';
      const disableSecPrice = isDisabled(SectionColumnConfigKey.SecondaryPrice);
      const secPriceTooltip = secTitle + ' is disabled for this theme.';
      const secondaryPrice = new TabBarItem(secPriceComp, secTitle, '', false, disableSecPrice, null, secPriceTooltip);
      checkForInitialSelectedIndex(SectionColumnConfigKey.SecondaryPrice);
      const tabs = [
        asset, badges, brand, classification,
        quantity, size, qtyAndSize,
        ...(section?.supportsStockColumn() ? [stock] : []),
        cbd, thc, price, secondaryPrice
      ];
      TabBarItem.setSelectedTab(tabs, currentTabId);
      return tabs;
    })
  );

  checkForChanges() {
    combineLatest([this.viewModel?.unsavedChangesInTab$, this.viewModel?.formIsValid$]).pipe(
      take(1),
      map(([unsavedChanges, formIsValid]) => {
        if (unsavedChanges && formIsValid) {
          this.columnOptionsFormGroup.submitForms(true);
        }
      })
    ).subscribe();
  }

  setPreviousTab(id: number) {
    this.viewModel.connectToPreviouslySelectedTabId(id);
  }

  setCurrentTab(id: number) {
    this.viewModel.connectToCurrentlySelectedTabIndex(id);
  }

  private calculateBottomButtonContainerPosition(contentHeight: number): void {
    const dialogRect = document.getElementsByClassName('modal-dialog').item(0)?.getBoundingClientRect();
    const headerRect = document.getElementsByClassName('sticky-header-container').item(0)?.getBoundingClientRect();
    const footerRect = document.getElementsByClassName('sticky-footer-container').item(0)?.getBoundingClientRect();
    const heightUntilScrollableContent = (dialogRect?.height - headerRect?.height) - footerRect?.height;
    const position = contentHeight > heightUntilScrollableContent ? 'sticky' : 'absolute';
    this.viewModel.connectToBottomButtonPosition(position);
  }

}
