import { Injectable } from '@angular/core';
import { ColumnOptionsFormViewModel } from '../column-options-form/column-options-form-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { ColumnOptionName, SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Injectable()
export class ColumnOptionsSecondaryPriceViewModel extends ColumnOptionsFormViewModel {

  constructor(
    public override container: ColumnOptionsViewModel
  ) {
    super(container);
  }

  public override columnSectionKey = SectionColumnConfigKey.SecondaryPrice;
  public override columnSectionName = ColumnOptionName.SecondaryPrice;
  public override tooltip = `This column allows you to display a comparative price or display the price per unit of `
                          + `measure. Will be disabled if menu is set to grid.`;

}
