<app-collection-displays-modal-header [title]="modalTitle$ | async" [showClearButton]="false">
</app-collection-displays-modal-header>

<div class="sticky-body-container">
  <div class="mb-24px mt-16px bs-bold f14px">Display Info</div>

  <lib-reactive-form-group
    #newDisplayDetails
    [bindTo]="container.pendingDisplay$ | async"
    (formSubmitted$)="container.connectToPendingDisplay($event); container.incrementEditStep()">
    <lib-reactive-form-text
      [label]="'Display Name'"
      [inputName]="'displayName'"
      [placeholder]="'Enter the display name'"
      [required]="true"
      [bindingProperty]="'pendingDisplay.name'"></lib-reactive-form-text>

    <lib-reactive-form-drop-down
      [label]="'Display Size'"
      [inputName]="'displaySize'"
      [placeholder]="'Select the size of this display'"
      [dropdowns]="container.displaySizeOptions$ | async"
      [required]="true"
      [programmaticallyChangeValue]="container.displaySize$ | async"
      [bindingProperty]="'pendingDisplay.displaySize.name'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      [label]="'Orientation'"
      [tooltip]="'Portrait is a 90º clockwise rotation. Reverse portrait is a 90º counterclockwise rotation.'"
      [inputName]="'orientation'"
      [placeholder]="'Select the orientation of this display'"
      [dropdowns]="container.orientationOptions$ | async"
      [required]="true"
      [programmaticallyChangeValue]="container.orientation$ | async"
      [bindingProperty]="'pendingDisplay.displaySize.orientation'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-group>
</div>

<app-go-back-modal-footer
  [showPrimaryButton]="true"
  [primaryButtonText]="'Next'"
  [showBackButton]="container.startingAtStepZero$ | async"
  [disablePrimaryButton]="newDisplayDetails.hasErrors$ | async"
  (goBack)="container.goBack()"
  (primaryButtonClicked)="newDisplayDetails.submitForm(false)"
  (cancel)="cancel.emit()">
</app-go-back-modal-footer>
