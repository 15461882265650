import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LookAheadItem } from './protocol/look-ahead-item';

@Component({
  selector: 'app-look-ahead-item',
  templateUrl: './look-ahead-item.component.html',
  styleUrls: ['./look-ahead-item.component.scss']
})
export class LookAheadItemComponent {

  @Input() item: LookAheadItem;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() lookAheadProperties: string[];
  @Input() lookAheadSeparator: string = ' - ';
  @Output() lookAheadItemClicked = new EventEmitter<any>(true);

  @ViewChild('container') container: ElementRef;

  constructor() { }

  getLookAheadString(): string {
    return this.lookAheadProperties.map(property => this.item[property]).filterNulls().join(this.lookAheadSeparator);
  }

  click() {
    this.container.nativeElement.click();
  }

}
