import { Injectable, Injector } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { CompactTemplateCollection } from '../../../../../models/template/dto/compact-template-collection';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';

@Injectable()
export class DisplayTemplateCollectionSectionViewModel extends BaseViewModel {

  constructor(
    private editDisplayViewModel: EditDisplayViewModel,
    private userDomainModel: UserDomainModel,
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
  }

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());
  public isTemplateAdmin$ = this.userDomainModel.isTemplateAdmin$;

  removeCollectionFromDisplay(c: CompactTemplateCollection) {
    const options = this.setConfirmationOptions(c);
    const confirmation = (cont) => {
      if (cont) {
        this.editDisplayViewModel.autoSaveLoadingOpts$.pipe(
          take(1),
          map((opts) => opts?.isLoading)
        ).subscribe((isLoading) => {
          if (!isLoading) this.editDisplayViewModel.removeCollection(this._loadingOpts, c);
        });
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, options, confirmation);
  }

  navigateToEditTemplateCollectionPage(c: CompactTemplateCollection) {
    this.editDisplayViewModel.navigateToTemplateCollection(c);
  }

  private setConfirmationOptions(c: CompactTemplateCollection): ConfirmationOptions {
    const options = new ConfirmationOptions();
    options.cancelText = 'Cancel';
    options.continueText = 'Remove';
    options.title = 'Remove Template Collection';
    options.bodyText = `Are you sure you want to delete the following template collection `
      + `from this display: '${c?.name}'?`;
    return options;
  }

}
