import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Theme } from '../../../../../../../models/menu/dto/theme';
import { Variant } from '../../../../../../../models/product/dto/variant';

@Injectable()
export class EditDriveThruProductsViewModel extends BaseViewModel {

  public _theme = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme.asObservable();

  constructor() {
    super();
  }

  connectToTheme(t: Theme) {
    this._theme.next(t);
  }

  trackById(index: number, v: Variant) {
    return v?.id;
  }

}
