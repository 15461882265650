import { Pipe, PipeTransform } from '@angular/core';
import { HydratedSection } from '../../../models/menu/dto/hydrated-section';
import { SectionTemplate } from '../../../models/template/dto/section-template';

@Pipe({
  name: 'isSectionTemplate'
})
export class IsSectionTemplatePipe implements PipeTransform {

  transform(value: HydratedSection): boolean {
    return value instanceof SectionTemplate;
  }

}
