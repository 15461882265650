import { Injectable, Injector } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { OverrideProductGroup } from '../../../../../models/product/dto/override-product-group';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { ModalAddProductsToProductGrouping } from '../../../../../modals/modal-add-products-to-product-grouping';
import { DistinctUtils } from '../../../../../utils/distinct-utils';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { ToastService } from '../../../../../services/toast-service';

@Injectable()
export class CreateProductGroupingModalViewModel extends BaseModalViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private productDomainModel: ProductDomainModel,
    private injector: Injector,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private readonly _name = new BehaviorSubject<string>('');
  public readonly name$ = this._name as BehaviorSubject<string>;
  connectToName = (name: string) => this._name.next(name);

  private _selectedProductIds = new BehaviorSubject<string[]>([]);
  public selectedProductIds$ = this._selectedProductIds.pipe(distinctUntilChanged(DistinctUtils.distinctSortedStrings));
  connectToSelectedProductIds = (productIds: string[]) => this._selectedProductIds.next(productIds);

  public hasProductIds$ = this.selectedProductIds$.pipe(map(productIds => productIds?.length > 0));

  public readonly disabled$ = combineLatest([
    this.name$,
    this.hasProductIds$
  ]).pipe(
    map(([name, hasProductIds]) => !name || !hasProductIds),
  );

  public creationRequest$ = combineLatest([
    this.companyDomainModel.companyId$,
    this.name$,
    this.selectedProductIds$
  ]).pipe(
    filter(([companyId]) => Number.isFinite(companyId)),
    map(([companyId, name, productIds]) => new OverrideProductGroup(companyId, name, productIds)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  addProducts(): void {
    this.creationRequest$.once(grouping => {
      const cont = (productIds: string[]) => {
        return of(true).pipe(
          tap(() => {
            const updatedIds = [...(grouping?.productIds ?? []), ...(productIds ?? [])].unique();
            this.connectToSelectedProductIds(updatedIds);
          })
        );
      };
      ModalAddProductsToProductGrouping.open(this.ngbModal, this.injector, grouping, false, cont);
    });
  }

  createOverrideProductGroup(): void {
    this.creationRequest$.once(grouping => {
      const loadingMsg = 'Creating Product Group';
      this._loadingOpts.addRequest(loadingMsg);
      this.productDomainModel.createOverrideProductGroup(grouping).subscribe({
        next: (createdGrouping) => {
          const msg = `"${createdGrouping.name}" successfully created.`;
          const title = 'Create Product Grouping';
          this.toastService.publishSuccessMessage(msg, title);
          this._loadingOpts.removeRequest(loadingMsg);
          this.dismissModalSubject.next(createdGrouping);
        },
        error: (err) => {
          this.toastService.publishError(err);
          this._loadingOpts.removeRequest(loadingMsg);
        }
      });
    });
  }

}
