import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DisplayableItem } from '../../displayable-item-preview/displayable-item';
import { map } from 'rxjs/operators';

@Injectable()
export class ActiveViewModel extends BaseViewModel {

  protected _displayableItem = new BehaviorSubject<DisplayableItem>(null);
  public displayableItem$ = this._displayableItem as Observable<DisplayableItem>;
  public connectToDisplayableItem = (item: DisplayableItem) => this._displayableItem.next(item);

  public active$ = this.displayableItem$.pipe(map(item => item?.displayableItemIsActive()));
  public activeText$ = this.displayableItem$.pipe(map(item => item?.displayableItemActiveText()));
  public inactiveText$ = this.displayableItem$.pipe(map(item => item?.displayableItemInactiveText()));

}
