import { PreviewRequest } from './preview-request';

export class RequestPDF extends PreviewRequest {

  constructor(
    public locationId: number,
    public menuId: string
  ) {
    super();
    this.returnLastSaved = false;
    this.previewOnly = false;
  }

  returnLastSaved: boolean;
  previewOnly: boolean = false;

}
