import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { PrintReportMenusViewModel } from './print-report-menus-view-model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-print-report-menus',
  templateUrl: './print-report-menus.component.html',
  styleUrls: ['./print-report-menus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PrintReportMenusViewModel]
})
export class PrintReportMenusComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintReportMenusViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
