<div class="row mt-24px">
  <div class="col-4">
    <p class="bs-section-subtitle f16px">Product Groupings</p>
    <div class="bs-section-subtext f12px mt-16px">
      Use product groupings to associate multiple products to a single marketing asset.
    </div>
    <div *ngIf="sharedVM?.menuHasSections$ | async" fxLayout="row wrap" class="mt-16px" [style.gap.rem]="0.5">
      <lib-button-primary
        *ngIf="!(sharedVM?.isTemplatedMenu$ | async)"
        (buttonClicked)="addProductGroupingClicked()"
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        [disabled]="!formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip">
        Add Product Grouping
      </lib-button-primary>
      <lib-button-neutral
        *ngIf="!(sharedVM?.isTemplatedMenu$ | async)"
        (buttonClicked)="reorderGroupingsClicked()"
        [disabled]="(sharedVM.isTemplatedMenu$ | async) || (sharedVM.nMenuSections$ | async) < 2 || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        [iconSrc]="'assets/icons/dark/outline/collection.svg'">
        Reorder Product Groupings
      </lib-button-neutral>
    </div>
  </div>
  <div class="col-8">
    <div *ngIf="!(sharedVM?.menuHasSections$ | async)">
      <p class="bs-medium f14px mt-24px">
        Product Groupings will appear in a list here. You can add, edit, and remove groupings from here.
      </p>
      <lib-button-primary
        *ngIf="!(sharedVM?.isTemplatedMenu$ | async)"
        [disabled]="!formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="sharedVM.addProductGroupingClicked()"
        [iconSrc]="'assets/icons/light/outline/plus.svg'">
        Add Product Grouping
      </lib-button-primary>
    </div>

    <div *ngIf="sharedVM?.menuHasSections$ | async" class="product-groupings-container">
      <app-product-grouping
        *ngFor="let section of sharedVM?.menuSections$ | async; trackBy: sharedVM?.trackBySectionId; let i = index"
        [menu]="sharedVM?.menu$ | async"
        [mergeKey]="mergeKey"
        [templateMode]="templateMode"
        [sharedViewModel]="sharedVM"
        [section]="section"
        [index]="i"
        [formHasErrors]="formHasErrors"
        [formPristine]="formPristine"
        (deleteClicked)="sharedVM.promptForDeleteSection($event)"
        (duplicateClicked)="duplicateGrouping($event)">
      </app-product-grouping>
    </div>
  </div>
</div>
