<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div appStickyAutoPosition class="sticky-header-container" [stickyCollectionKey]="'publish-collection'" [stickyZ]="9">
    <div class="modal-header no-bottom-border">
      <div class="modal-title mb-28px">Publish Template Collection</div>
    </div>
  </div>

  <div
    class="sticky-body-container"
    appStickyAutoPosition
    [stickyCollectionKey]="'publish-collection'"
    [stickChildClassInstead]="'info-text'"
    [stickyZ]="9"
    [style.overflow-y]="'unset'">
    <ng-container [ngSwitch]="viewModel.collectionHasRequiredDisplays$ | async">
      <div *ngSwitchCase="false" class="info-text">
        <p>Are you sure you want to publish this?</p>
        <p>
          A published template collection can be used by any location. No required locations for this template
          collection have been set.
        </p>
      </div>

      <div *ngSwitchCase="true" class="info-text">
        Displays shown are set as Required Displays and will automatically deploy this template collection.
        <hr />
      </div>
    </ng-container>

    <ng-container *ngIf="viewModel.numberOfProvinces$ | async as numberOfProvinces">
      <app-single-province-location-display-list-items
        *ngIf="numberOfProvinces === 1"
        [groupedRequiredDisplays]="viewModel.requiredDisplaysGroupedByLocationAndProvince$ | async">
      </app-single-province-location-display-list-items>

      <app-grouped-province-location-display-list-items
        *ngIf="numberOfProvinces > 1"
        [expanded]="true"
        [groupedRequiredDisplays]="viewModel.requiredDisplaysGroupedByLocationAndProvince$ | async">
      </app-grouped-province-location-display-list-items>
    </ng-container>
  </div>

  <div class="sticky-footer-container flex-container" style="padding-top: 1rem">
    <div [style.margin-left]="'auto'">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary ngbAutofocus [style.margin-left]="'0.5rem'" (buttonClicked)="viewModel.publishCollection()">
        Publish Collection
      </lib-button-primary>
    </div>
  </div>
</div>
