import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Output } from '@angular/core';
import { HiddenFormInputComponent } from '../../../../../shared/forms/hidden-form-input/hidden-form-input.component';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-selected-override-products-form-component',
  templateUrl: './selected-override-products-form-component.component.html',
  styleUrls: ['./selected-override-products-form-component.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => SelectedOverrideProductsFormComponentComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectedOverrideProductsFormComponentComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedOverrideProductsFormComponentComponent extends HiddenFormInputComponent {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  private readonly _productIds = new BehaviorSubject<string[]|null>(null);
  public readonly productIds$ = this._productIds as Observable<string[]|null>;
  @Output() updatedProductIds = new EventEmitter<string[]|null>(true);

  override getBindingProperty(): string[] {
    const val = super.getBindingProperty();
    this._productIds.next(val);
    return val;
  }

  deleteFromGroup(productId: string): void {
    const currIds: string[] = this.getSelfAsFormItem()?.value || [];
    const updated = currIds?.shallowCopy()?.filter(id => id !== productId) || [];
    this.idsChanged(updated);
  }

  idsChanged(ids: string[] | null) {
    this.handleInputChange(ids);
    this.getSelfAsFormItem().patchValue(ids);
    this.markAsDirty();
    this._productIds.next(ids);
    this.updatedProductIds.emit(ids);
  }

  trackByProductId = (index: number, productId: string): string => productId;

}
