<div
  class="overflow-info-video-container"
  [style.top]="stickyOffset"
  [ngClass]="selectedOrientationControl.value === 'portrait' ? 'overflow-info-video-p' : 'overflow-info-video-ls'">
  <div class="overflow-info-video-header">
    <div class="info-video-title">{{ selectedInfoItem?.item?.name }}</div>
    <app-segmented-control
      [options]="orientationControlOptions"
      (optionSelected)="orientationSegmentedControlOptionSelected($event)">
    </app-segmented-control>
  </div>
  <div class="overflow-info-video">
    <div
      class="shimmer-container"
      *ngIf="!(videoLoaded$ | async)"
      [ngClass]="selectedOrientationControl.value === 'portrait' ? 'shimmer-p' : 'shimmer-ls'">
      <lib-loading-shimmer [width]="'100%'" [height]="'100%'" [borderRadius]="'0'"> </lib-loading-shimmer>
    </div>
    <video
      [style.display]="(videoLoaded$ | async) ? 'initial' : 'none'"
      [src]="selectedInfoItem?.videoUrl"
      [ngClass]="selectedOrientationControl.value === 'landscape' ? 'info-video-ls' : 'info-video-p'"
      [autoplay]="true"
      [loop]="true"
      [muted]="true"
      (canplaythrough)="onCanPlayThrough()"></video>
  </div>
</div>
