import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Label } from '../../../models/shared/label';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { map, shareReplay } from 'rxjs/operators';
import { SortUtils } from '../../../utils/sort-utils';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';

@Injectable()
export class LabelsPickerViewModel extends BaseViewModel {

  constructor(
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  public readonly locationCustomLabels$ = this.labelDomainModel.locationCustomLabels$;

  public readonly companySystemLabels$ = this.labelDomainModel
    .companySystemLabels$
    .shallowCopyArray()
    .pipe(
      map(ls => ls.sort(SortUtils.sortLabelsForLabelPicker)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  public companyCustomLabels$: Observable<Label[]> = combineLatest([
    this.labelDomainModel.companyCustomLabels$,
    this.locationCustomLabels$
  ]).pipe(
    map(([companyCustomLabels, locationCustomLabels]) => {
      const companyLabelPoolWithLocationLabelsReplaced = [];
      companyCustomLabels.forEach(l => {
        const i = locationCustomLabels.findIndex(locL => locL.id === l.id);
        if (i > -1) {
          companyLabelPoolWithLocationLabelsReplaced.push(locationCustomLabels[i]);
        } else {
          companyLabelPoolWithLocationLabelsReplaced.push(l);
        }
      });
      return companyLabelPoolWithLocationLabelsReplaced.sort(SortUtils.sortLabelsForLabelPicker);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyPOSLabels$: Observable<Label[]> = this.labelDomainModel
    .companyPOSLabels$
    .shallowCopyArray()
    .pipe(
      map(ls => ls.sort(SortUtils.sortLabelsForLabelPicker)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  public showPOSLabels$ = this.companyPOSLabels$.pipe(
    map(posLabelPool => {
      return posLabelPool?.length > 0;
    })
  );

  public showCustomLabels$ = this.companyCustomLabels$.pipe(
    map(customLabelPool => {
      return customLabelPool?.length > 0;
    })
  );

  public configurations$ = combineLatest([
    this.locationDomainModel.locationConfig$,
    this.companyDomainModel.companyConfiguration$
  ]);

}
