export class Breadcrumb {

  public title: string;
  public targetUrl: string;
  public fragment: string;
  public active: boolean = false;
  public disabled: boolean = false;

  constructor(title: string, targetUrl: string, fragment?: string, active?: boolean) {
    this.title = title;
    this.targetUrl = targetUrl;
    if (!!fragment) this.fragment = fragment;
    this.active = !!active;
  }

}
