import { TypeDefinition } from './type-definition';

export enum MenuStyleObject {
  Product = 'product',
  Variant = 'variant'
}

export class MenuStyleObjectType extends TypeDefinition {

  override value: MenuStyleObject;

}
