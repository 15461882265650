<app-loading *ngIf="sharedLoadingState.isLoading$ | async" [options]="sharedLoadingState.loadingOpts$ | async">
</app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'editSpotlightSection'"
  (formsAutoSubmitted$)="sectionViewModel?.saveStaticSectionMenu(true)"
  (formsSubmitted$)="sectionViewModel?.saveStaticSectionMenu(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-container>
  <app-edit-menu-header
    [menu]="viewModel.menu$ | async"
    [crumbs]="viewModel.breadcrumbs$ | async"
    [allowLiveView]="viewModel.allowLiveView$ | async"
    [autoSaveLoadingOpts]="sharedLoadingState.autoSaveLoadingOpts$ | async"
    [headerText]="'Edit Spotlight Menu'"
    [headerSubtext]="'Create visually stunning menus built specifically for your in-store displays.'"
    [lastSavedTimeText]="sharedLoadingState.lastAutoSavedTimeText$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [mergeKey]="'editSpotlightSection'"
    [showLiveView]="viewModel.showLiveView"
    [toggleActiveState]="viewModel.toggleActiveState"
    [userIsTemplateAdmin]="viewModel.userIsTemplateAdmin$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    (editTemplateClicked)="viewModel.navigateToEditTemplate($event)"
    (submitForms)="formCapture.submitForms($event)">
    Edit Spotlight Menu Header
  </app-edit-menu-header>

  <app-edit-spotlight-menu-body
    [mergeKey]="'editSpotlightSection'"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async">
    Edit Spotlight Menu Body
  </app-edit-spotlight-menu-body>

  <app-edit-spotlight-menu-footer
    [canDeleteTemplateMenu]="viewModel.canDeleteTemplateMenu$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    (submitForms)="formCapture.submitForms($event)">
    Edit Product Menu Footer
  </app-edit-spotlight-menu-footer>
</app-edit-menu-container>
