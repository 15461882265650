import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { debounceTime, map } from 'rxjs/operators';
import { BulkPrintJobDomainModel } from '../../../../../../../domainModels/bulk-print-job-domain-model';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { iiif } from '../../../../../../../utils/observable.extensions';

@Injectable()
export class AllCardStackPrintJobsModalViewModel {

  constructor(
    private bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel
  ) {
  }

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  public readonly cardStackPrintJobs$ = this.bulkPrintJobDomainModel.activeMenuBulkPrintJobs$.pipe(
    debounceTime(100)
  );

  public readonly activeMenu$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplate$,
    this.menuDomainModel.activeHydratedMenu$
  );

  public readonly isCardStack$ = this.activeMenu$.pipe(map(menu => menu?.isPrintCardMenu()));
  public readonly isLabelStack$ = this.activeMenu$.pipe(map(menu => menu?.isPrintLabelMenu()));
  public readonly cardStackName$ = this.activeMenu$.pipe(map(cardStack => cardStack?.name ?? ''));

}
