<!-- Sections -->
<div class="row edit-page-children">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Sections</p>
    <p class="bs-edit-section-description">
      Use sections to help visually break up different parts of the menu for customers.
    </p>
    <div [hidden]="viewModel.hideSections$ | async" class="bs-edit-section-button-container">
      <lib-button-primary
        [disabled]="formHasErrors || (viewModel.isTemplatedMenu$ | async) || !formPristine"
        [disabledTooltip]="
          (viewModel.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        (buttonClicked)="openAddSectionModal()">
        Add Section
      </lib-button-primary>
      <lib-button-neutral
        [disabled]="formHasErrors || (viewModel.isTemplatedMenu$ | async) || !formPristine"
        [disabledTooltip]="
          (viewModel.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [iconSrc]="'assets/icons/dark/outline/collection.svg'"
        [hidden]="viewModel.hideReorderSections$ | async"
        (buttonClicked)="reorderSections()">
        Reorder Sections
      </lib-button-neutral>
    </div>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <!-- List Sections -->
    <ng-container *ngFor="let section of viewModel.menuSections$ | async; let i = index">
      <app-menu-section-preview
        [templateMode]="templateMode"
        [menu]="viewModel.menu$ | async"
        [hasErrors]="formHasErrors"
        [formPristine]="formPristine"
        [formAutoSubmitted]="formAutoSubmitted"
        [sectionIndex]="i"
        [section]="section"
        (submitForms)="submitForms.emit($event)">
      </app-menu-section-preview>
    </ng-container>

    <div [hidden]="viewModel.menuHasSections$ | async" class="no-section-placeholder">
      <p class="bs-edit-section-title">
        Sections will appear in a list here. You can add, edit, and remove sections from here.
      </p>
      <lib-button-primary
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        [disabled]="(viewModel.isTemplatedMenu$ | async) || formHasErrors || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="openAddSectionModal()">
        Add Section
      </lib-button-primary>
    </div>
  </div>
</div>

<hr class="mt-24px" />
