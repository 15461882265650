import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getPrintCardOptionTitle'
})
export class GetPrintCardOptionTitlePipe implements PipeTransform {

  transform(item: Product | Variant): string | null {
    if (item instanceof Product) {
      return item?.variants?.length === 1 ? item?.variants?.firstOrNull()?.getVariantTitle() : item?.getProductTitle();
    }
    if (item instanceof Variant) return item?.getVariantTitle();
    return null;
  }

}
