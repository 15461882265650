import { TypeDefinition } from './type-definition';

export enum PromotionPeriod {
  Recurrent = 'Recurrent',
  Definite = 'Definite'
}

export class PromotionPeriodType extends TypeDefinition {

  override value: PromotionPeriod;

}
