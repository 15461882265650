import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditCollectionViewModel } from './edit-collection-view-model';
import { EditDisplayViewModel } from '../../../display/viewModels/edit-display-view-model';
import { EditCollectionCombinedLoadingOptsViewModel } from './edit-collection-combined-loading-opts-view-model';
import { AutoSaveComponent } from '../../../shared/components/auto-save/auto-save.component';

@Component({
  selector: 'app-edit-collection',
  templateUrl: './edit-collection.component.html',
  styleUrls: ['./edit-collection.component.scss'],
  providers: [EditCollectionViewModel, EditDisplayViewModel, EditCollectionCombinedLoadingOptsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionComponent extends AutoSaveComponent {

  constructor(
    public override viewModel: EditCollectionViewModel,
    public editDisplayViewModel: EditDisplayViewModel,
    public combinedLoadingOptsViewModel: EditCollectionCombinedLoadingOptsViewModel
  ) {
    super(viewModel);
  }

}
