<div class="sticky-body-container" [style.overflow-y]="'unset'">
  <div class="modal-search-results-container">
    <div
      *rxFor="let collection of searchedItems; trackBy: 'id'; let select = select"
      class="add-collection-list-item-container">
      <div
        class="add-collection-list-item"
        [class.disabled]="collection | collectionIncludedInDisplay : display"
        (click)="itemClicked.emit(collection.id)">
        <div
          class="d-flex align-items-center"
          [style.gap.rem]="0.25"
          [class.disabled]="collection | collectionIncludedInDisplay : display">
          <img
            [src]="'assets/icons/dark/outline/template-collection.svg'"
            alt=""
            [style.display]="'block'"
            [style.height.rem]="1" />
          <div class="reorder-value ellipsis-nowrap-text">
            {{ collection?.name }}
          </div>
          <div class="reorder-icon">
            <img
              [hidden]="
                (collection | collectionIncludedInDisplay : display) ||
                (previouslySelectedItemIds | includes : collection?.id)
              "
              [src]="'assets/icons/dark/outline/plus.svg'"
              alt="" />
            <img
              [hidden]="!(previouslySelectedItemIds | includes : collection?.id)"
              [src]="'assets/icons/dark/outline/check.svg'"
              alt="" />
            <div class="bs-semi-bold" [hidden]="!(collection | collectionIncludedInDisplay : display)">Added</div>
          </div>
        </div>
        <div *rxFor="let template of select(['templates'])" class="f12px bs-medium">
          {{ template.name }}
        </div>
      </div>
    </div>
    <div *ngIf="searchedItems | emptyArray" class="pt-16px">
      {{ emptyStateText }}
    </div>
  </div>
</div>
