<app-display-menu-section-header-container [menuWarningTooltip]="menuWarningTooltip">
  <p class="bs-section-list-title">
    <ng-container [ngSwitch]="menu?.isBasedOffOfTemplate || menu?.isTemplate">
      <img
        *ngSwitchCase="true"
        class="pr-8px"
        [style.height.rem]="1"
        [src]="'assets/icons/dark/solid/template.svg'"
        alt="" />
      <img
        *ngSwitchCase="false"
        class="pr-8px"
        [style.height.rem]="1"
        [src]="'assets/icons/dark/outline/document-text.svg'"
        alt="" />
    </ng-container>
    {{ menu?.name }}
  </p>
  <ng-container *ngIf="menu?.isTemplate">
    <img
      *ngIf="menu.status === ClientTypes.TemplateStatus.Draft"
      class="ml-8px pb-2px"
      src="../../../../../../../assets/icons/dark/outline/pencil-alt.svg"
      alt=""
      [ngbTooltip]="draftTemplateTooltip$ | async"
      [style.width.rem]="1.2" />
  </ng-container>
  <div class="bs-section-header-button-container">
    <!--   Delete Menu   -->
    <lib-button-inline-text
      [hidden]="nMenus < 2"
      [textColor]="'#FA5555'"
      [disabled]="disableButtons || !formPristine"
      [disabledTooltip]="formPristine | saveChangesTooltip"
      (buttonClicked)="removeMenu.emit(menu)">
      {{ 'Remove Menu' | replaceMenuWithTemplate : menu?.isTemplate }}
    </lib-button-inline-text>
    <!--   Edit Menu   -->
    <lib-button-inline-text
      [disabled]="disableButtons || menuIsTemplateAndMissingPermission"
      [disabledTooltip]="menuIsTemplateAndMissingPermission ? 'You do not have access to edit this menu.' : null"
      (buttonClicked)="editMenu.emit(menu)">
      {{ 'Edit Menu' | replaceMenuWithTemplate : menu?.isTemplate }}
    </lib-button-inline-text>
  </div>
</app-display-menu-section-header-container>
