import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ThemeCarouselImageViewModel } from './theme-carousel-image-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-theme-carousel-image',
  templateUrl: './theme-carousel-image.component.html',
  styleUrls: [
    '../../../../../../../src/scss/_print-cards.scss',
    './theme-carousel-image.component.scss',
  ],
  providers: [ThemeCarouselImageViewModel],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeCarouselImageComponent extends BaseComponent {

  constructor(
    public viewModel: ThemeCarouselImageViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToCard') card: Card;
  @Input() @ViewModelConnector('connectToCardClass') cardClass: string;
  @Output() cardButtonClicked: EventEmitter<any> = new EventEmitter<any>();

}
