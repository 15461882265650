import { Injectable } from '@angular/core';
import { EditCardStackMenuViewModel } from '../edit-card-stack-menu/edit-card-stack-menu-view-model';
import { of } from 'rxjs';

@Injectable()
export class EditLabelStackMenuViewModel extends EditCardStackMenuViewModel {

  public override mergeKey$ = of('editLabelStack');

}
