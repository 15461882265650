import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu } from '../../../../../models/menu/dto/menu';
import { SelectableComponent } from '../../../components/group-selection/selectable/selectable.component';

@Component({
  selector: 'app-print-job-menu-selection',
  templateUrl: './print-job-menu-selection.component.html',
  styleUrls: ['./print-job-menu-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintJobMenuSelectionComponent extends SelectableComponent implements OnChanges {

  @Input() override selection: Menu;
  @Input() includeBottomBorder: boolean = true;
  @Input() disabled: boolean = false;

  protected override _selection = new BehaviorSubject<Menu>(null);
  public override selection$ = defer(() => this._selection) as Observable<Menu>;
  public name$ = this.selection$.pipe(map(selection => selection?.name));

}
