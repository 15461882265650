import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReorderModalComponent } from '../views/shared/components/reorder-modal/reorder-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Observable } from 'rxjs';
import { Display } from '../models/display/dto/display';
import { Orderable } from '../models/protocols/orderable';
import { ReorderOptions } from '../models/shared/stylesheet/reorder-options';
import { Injector } from '@angular/core';

export class ModalReorderDisplays {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    displays: Display[],
    prefix: number = 0,
    reorderOperation: (ordered: Orderable[]) => Observable<any>
  ): void {
    const modalRef = ngbModal.open(
      ReorderModalComponent,
      ModalUtils.reorderModalOptions(injector)
    );
    const inst = modalRef.componentInstance as ReorderModalComponent;
    inst.setReorderOptions(ModalReorderDisplays.getReorderOptions(prefix));
    inst.setReorderItems(displays);
    inst.reorderOperation = reorderOperation;
  }

  static getReorderOptions(prefix: number): ReorderOptions {
    const opts = new ReorderOptions();
    opts.title = 'Reorder Displays';
    opts.subTitle = 'Displays';
    opts.bodyText = 'Drag and drop displays into the desired order.';
    opts.confirmText = 'Confirm Order';
    opts.orderPrefix = prefix;
    opts.loadingMess = 'Reordering Displays';
    opts.successTitle = 'Displays Updated';
    opts.successMess = 'Display order successfully updated.';
    opts.failureTitle = 'Displays Error';
    opts.failureMess = 'Error updating display order.';
    return opts;
  }

}
