import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayMenuSectionComponent } from '../../display-menu-section/display-menu-section.component';
import { DisplayMenuSectionTemplateCollectionViewModel } from './display-menu-section-template-collection-view-model';
import { EditDisplayViewModel } from '../../../../viewModels/edit-display-view-model';

@Component({
  selector: 'app-display-menu-section-inside-template-collection',
  templateUrl: './display-menu-section-inside-template-collection.component.html',
  styleUrls: ['./display-menu-section-inside-template-collection.component.scss'],
  providers: [DisplayMenuSectionTemplateCollectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionInsideTemplateCollectionComponent extends DisplayMenuSectionComponent {

  constructor(
    public override viewModel: DisplayMenuSectionTemplateCollectionViewModel,
    editDisplayViewModel: EditDisplayViewModel
  ) {
    super(viewModel, editDisplayViewModel);
  }

  @Input() override menuIsPartOfCollection = true;

}
