// noinspection JSUnusedLocalSymbols

import { Injectable, Type } from '@angular/core';
import { UtilsAPI } from '../api/utils-api';
import { CacheService } from './cache-service';
import { EnumTypes } from '../models/utils/dto/enum-types';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from './toast-service';
import { BaseService } from '@mobilefirstdev/base-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TypeDefinition } from '../models/utils/dto/type-definition';
import { OverflowStateType } from '../models/utils/dto/overflow-state-type';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { VariantTypeDefinition } from '../models/utils/dto/variant-type-definition';
import { Menu } from '../models/menu/dto/menu';
import { MarketingMenuType } from '../models/enum/dto/marketing-menu-type.enum';
import { ThemeUtils } from '../utils/theme-utils';
import { SectionColumnConfigDefaultState } from '../models/utils/dto/section-column-config-default-state-type';
import { SectionType, SectionTypeDefinition } from '../models/utils/dto/section-type-definition';
import { MenuType } from '../models/utils/dto/menu-type-definition';
import { SortUtils } from '../utils/sort-utils';
import { ProductType } from '../models/utils/dto/product-type-definition';
import * as uuid from 'uuid';
import { exists } from '../functions/exists';
import { Selectable } from '../models/protocols/selectable';

declare global {
  interface Window {
    types: TypeService | undefined;
  }
}

// Provided by Logged In Scope
@Injectable()
export class TypeService extends BaseService {

  constructor(
    private cacheService: CacheService,
    private utilsAPI: UtilsAPI,
    private toastService: ToastService,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
    window.types = this;
  }

  public _rootTypes: BehaviorSubject<EnumTypes> = new BehaviorSubject<EnumTypes>(null);
  public rootTypes$ = this._rootTypes as Observable<EnumTypes>;
  connectToRootTypes = (rootTypes: EnumTypes) => exists(rootTypes) && this._rootTypes.next(rootTypes);

  public assetLibraryTypes$ = this.rootTypes$.pipe(map(types => types?.assetLibraryType));
  public bulkJobSourceTypes$ = this.rootTypes$.pipe(map(types => types?.bulkJobSourceType));
  public cannabinoidDisplayTypes$ = this.rootTypes$.pipe(map(types => types?.cannabinoidDisplayType));
  public terpeneDisplayTypes$ = this.rootTypes$.pipe(map(types => types?.terpeneDisplayType));
  public cannabisUnitOfMeasures$ = this.rootTypes$.pipe(map(types => types?.cannabisUnitOfMeasure));
  public terpeneUnitOfMeasures$ = this.rootTypes$.pipe(map(types => types?.terpeneUnitOfMeasure));
  public comboMenuCardTypes$ = this.rootTypes$.pipe(map(types => types?.comboMenuCardType));
  public companyRoles$ = this.rootTypes$.pipe(map(types => types?.companyRole));
  public countries$ = this.rootTypes$.pipe(map(types => types?.country));
  public daysOfWeek$ = this.rootTypes$.pipe(map(types => types?.daysOfWeek));
  public digitalSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultDigitalSizes));
  public fontStyles$ = this.rootTypes$.pipe(map(types => types?.fontStyle));
  public inventoryProviders$ = this.rootTypes$.pipe(map(types => types?.inventoryProvider));
  public menuCreationFlowMap$ = this.rootTypes$.pipe(map(types => types?.menuCreationFlowMap));
  public menuCreationFlows$ = this.rootTypes$.pipe(map(types => types?.menuCreationFlows));
  public menuLabels$ = this.rootTypes$.pipe(map(types => types?.menuLabel));
  public menuPreviewJobStatuses$ = this.rootTypes$.pipe(map(types => types?.menuPreviewJobStatus));
  public menuStyleObjects$ = this.rootTypes$.pipe(map(types => types?.menuStyleObject));
  public menuTypes$ = this.rootTypes$.pipe(map(types => types?.menuType));
  public optionScales$ = this.rootTypes$.pipe(map(types => types?.optionScale));
  public orientations$ = this.rootTypes$.pipe(map(types => types?.orientation));
  public provincesMap$ = this.rootTypes$.pipe(map(types => types?.province));
  public provinces$ = this.provincesMap$.pipe(
    map(typesMap => [...(typesMap?.values() || [])]?.flatMap(typeDefinitions => typeDefinitions))
  );

  // Overflow State Types
  public allMenuOverflowStateTypes$ = this.rootTypes$.pipe(map(types => types?.overflowState));
  public productMenuOverflowStateTypes$ = this.allMenuOverflowStateTypes$;
  public sectionOverflowStateTypes$ = this.allMenuOverflowStateTypes$.pipe(
    map(types => types?.filter(type => type?.value?.includes('SECTION')))
  );
  public overflowStateTypesWithoutSectionOverflow$ = this.allMenuOverflowStateTypes$.pipe(
    map(types => types?.filter(type => !type?.value?.includes('SECTION')))
  );
  public marketingCategoryOverflowStateTypes$ = this.overflowStateTypesWithoutSectionOverflow$;
  public featuredCategoryOverflowStateTypes$ = this.overflowStateTypesWithoutSectionOverflow$.pipe(
    map(types => {
      const copy = window?.injector?.Deserialize?.arrayOf(OverflowStateType, types);
      copy.forEach(t => t.name = t?.name?.replace('(Menu)', ''));
      return copy;
    }),
  );

  public priceFormats$ = this.rootTypes$.pipe(map(types => types?.priceFormat));
  public printFooterLayouts$ = this.rootTypes$.pipe(map(types => types?.printFooterLayout));
  public printHeaderLayouts$ = this.rootTypes$.pipe(map(types => types?.printHeaderLayout));
  public productMix$ = this.rootTypes$.pipe(map(types => types?.productMix));

  // Print Card Paper Size Type
  public printCardPaperSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintCardPaperSizes));

  // Print Card Size Type
  public printCardSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintCardSizes));

  // Print Label Paper Size Type
  public printLabelPaperSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintLabelPaperSizes));

  // Print Label Size Type
  public printLabelSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintLabelSizes));

  public readonly printStackSizeTypes$ = combineLatest([
    this.printCardSizeTypes$,
    this.printLabelSizeTypes$
  ]).pipe(
    map(([printCardSizes, printLabelSizes]) => {
      if (!printCardSizes && !printLabelSizes) return null;
      return [...(printCardSizes || []), ...(printLabelSizes || [])];
    })
  );

  // Print Size Type
  public printSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintSizes));

  public productTypes$ = this.rootTypes$.pipe(map(types => types?.productType));
  public cannabisProductTypes$ = this.productTypes$.pipe(
    map(types => types?.filter((t) => {
      return t.value !== 'Accessories';
    }))
  );
  public promotionConditions$ = this.rootTypes$.pipe(map(types => types?.promotionCondition));
  public promotionDiscounts$ = this.rootTypes$.pipe(map(types => types?.promotionDiscount));
  public promotionPeriods$ = this.rootTypes$.pipe(map(types => types?.promotionPeriod));
  public saleLabelFormats$ = this.rootTypes$.pipe(map(types => types?.saleLabelFormat));
  public secondaryCannabinoids$ = this.rootTypes$.pipe(map(types => types?.secondaryCannabinoids));

  // Section Column Config Data Values
  public sectionColumnConfigDataValues$ = this.rootTypes$.pipe(map(types => types?.sectionColumnConfigDataValue));

  public secondaryPriceColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return type?.value?.includes('Price');
    }))
  );

  public assetColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return !type?.value?.includes('Price') && !type?.value?.includes('StrainType');
    }))
  );

  public strainTypeColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return type?.value?.includes('StrainType');
    }))
  );

  public sectionColumnConfigDefaultStates$ = this.rootTypes$.pipe(map(types => types?.sectionColumnConfigDefaultState));
  public autoSectionColumnConfigDefaultStates$ = this.sectionColumnConfigDefaultStates$.pipe(
    map(defaultStates => defaultStates?.filter(ds => ds?.value !== SectionColumnConfigDefaultState.Disabled))
  );
  public noAutoSectionColumnConfigDefaultStates$ = this.autoSectionColumnConfigDefaultStates$.pipe(
    map(defaultStates => defaultStates?.filter(ds => ds.value !== SectionColumnConfigDefaultState.Auto))
  );
  public disabledSectionColumnConfigDefaultStates$ = this.sectionColumnConfigDefaultStates$.pipe(
    map(defaultStates => defaultStates?.filter(ds => ds?.value === SectionColumnConfigDefaultState.Disabled))
  );
  public sectionColumnConfigKeys$ = this.rootTypes$.pipe(map(types => types?.sectionColumnConfigKey));

  public cannabinoidSectionColumnConfigKeys$ = this.sectionColumnConfigKeys$.pipe(
    map(keys => keys?.filter(k => k?.isCannabinoidKey()))
  );
  public terpeneSectionColumnConfigKeys$ = this.sectionColumnConfigKeys$.pipe(
    map(keys => keys?.filter(k => k?.isTerpeneKey()))
  );

  public sectionSortOptions$ = this.rootTypes$.pipe(
    map(types => types?.sectionSortOption),
    map(options => options?.sort(SortUtils.sortSectionSortTypesByName))
  );
  public featuredCategoryMenuCardTypes$ = this.rootTypes$.pipe(map(types => types?.featuredCategoryMenuCardType));
  public sectionLayoutTypes$ = this.rootTypes$.pipe(map(types => types?.sectionLayoutType));
  public sectionTypes$ = this.rootTypes$.pipe(map(types => types?.sectionType));
  public sizeUnits$ = this.rootTypes$.pipe(map(types => types?.sizeUnit));
  public strainTypes$ = this.rootTypes$.pipe(map(types => types?.strainType));
  public syncJobStatus$ = this.rootTypes$.pipe(map(types => types?.syncJobStatus));
  public syncTypes$ = this.rootTypes$.pipe(map(types => types?.syncType));
  public terpenes$ = this.rootTypes$.pipe(map(types => types?.terpenes));
  public terpeneUnitOfMeasure$ = this.rootTypes$.pipe(map(types => types?.terpeneUnitOfMeasure));
  public themeSubTypes$ = this.rootTypes$.pipe(map(types => types?.themeSubType));
  public timezoneMap$ = this.rootTypes$.pipe(map(types => types?.timezone));
  public unitOfMeasures$ = this.rootTypes$.pipe(map(types => types?.unitOfMeasure));
  public usePurposes$ = this.rootTypes$.pipe((map(types => types?.usePurpose)));
  public variantLookupTypes$ = this.rootTypes$.pipe(map(types => types?.variantLookupType));
  public variantProperties$ = this.rootTypes$.pipe(map(types => types?.variantProperty));

  // Variant Types
  public variantTypesMap$ = this.rootTypes$.pipe(map(types => types?.variantType));
  public variantTypes$ = this.variantTypesMap$.pipe(
    map(typesMap => [...(typesMap?.values() || [])]?.flatMap(typeDefinitions => typeDefinitions))
  );

  public groupedSectionSortOptions$ = combineLatest([
    this.sectionSortOptions$,
    this.secondaryCannabinoids$,
    this.terpenes$
  ]).pipe(
    map(([sectionSortOptions, secondaryCannabinoidsTypes, terpenesTypes]) => {
      const secondaryCannabinoids = secondaryCannabinoidsTypes?.map(sc => sc?.getSelectionValue());
      const terpenes = terpenesTypes?.map(t => t?.getSelectionValue());
      const productSortOptions = sectionSortOptions?.filter(opt => {
        return !exists(opt?.metadata);
      });
      const secondaryCannabinoidSortOptions = sectionSortOptions?.filter(opt => {
        return secondaryCannabinoids?.includes(opt?.metadata);
      });
      const terpeneSortOptions = sectionSortOptions?.filter(opt => {
        return terpenes?.includes(opt?.metadata);
      });
      return [
        this.buildDropDownHeader('Product Info'),
        ...(productSortOptions ?? []),
        this.buildDropDownHeader('Secondary Cannabinoids'),
        ...(secondaryCannabinoidSortOptions ?? []),
        this.buildDropDownHeader('Terpenes'),
        ...(terpeneSortOptions ?? [])
      ];
    })
  );

  /* Load Types
   * This needs to be below _rootTypes. If it's above, then it has a chance of firing before _rootTypes is initialized.
   */
  private listenToLocationCountryCode = this.locationDomainModel.countryCode$
    .notNull()
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: cc => this.loadTypes(cc)
    });

  /**
   * Iterate over all properties on the rootTypes object and find a matching
   * TypeDefinition of the type T with associated key
   */
  public initTypeDefinition<T extends TypeDefinition>(type: Type<T>, key: string): T {
    if (!!key) {
      const enumTypes = this._rootTypes.getValue();
      for (const enumTypesKey in enumTypes) {
        if (enumTypes.hasOwnProperty(enumTypesKey)) {
          const enumType = enumTypes[enumTypesKey] as Map<string, TypeDefinition[]> | TypeDefinition[];
          let typeDefinitions: TypeDefinition[] = [];
          if (enumType instanceof Map) {
            // EnumType is Map<string, TypeDefinition[]>
            typeDefinitions = [...(enumType?.values() || [])]?.flatMap(definitions => definitions);
          } else if (enumType instanceof Array) {
            typeDefinitions = enumType as TypeDefinition[];
          }
          // EnumType is TypeDefinition[]
          const typeDefinitionResult = typeDefinitions.find(td => td instanceof type && td.value === key);
          if (!!typeDefinitionResult) {
            return typeDefinitionResult as T;
          }
        }
      }
    }
    return null;
  }

  public getProvincesForCountry(countryCode: string): Observable<TypeDefinition[]> {
    return this.provincesMap$.pipe(
      map(provinceMap => {
        const provinces = provinceMap?.get(countryCode) ?? [];
        return provinces?.sort(SortUtils.nameAscending);
      })
    );
  }

  public getTimeZonesForCountry(countryCode: string): Observable<TypeDefinition[]> {
    return this.timezoneMap$.pipe(map(timezoneMap => timezoneMap?.get(countryCode) ?? []));
  }

  public getVariantTypesForProductType(
    productType: string,
    cannabisOnly: boolean = false
  ): Observable<VariantTypeDefinition[]> {
    return this.variantTypesMap$.pipe(
      map(variantTypesMap => {
        const vts = variantTypesMap?.get(productType) ?? [];
        if (productType === ProductType.Other && cannabisOnly) {
          return vts.filter(vt => VariantTypeDefinition.isOtherCannabis(vt.value));
        } else {
          return vts;
        }
      })
    );
  }

  public getSectionTypes(menu?: Menu): Observable<SectionTypeDefinition[]> {
    return this.sectionTypes$.pipe(
      map(sectionTypes => {
        let sectionTypeList = [
          SectionType.Product,
          SectionType.Title,
          SectionType.Media
        ];
        if (menu?.type === MenuType.PrintMenu) {
          sectionTypeList.push(SectionType.PageBreak);
        }
        if (menu?.getSubType() === MarketingMenuType.Category) {
          sectionTypeList.push(SectionType.CategoryCard);
        }
        if (menu?.getSubType() === MarketingMenuType.SmartPlaylist) {
          sectionTypeList.push(SectionType.ProductGroup);
        }
        if (menu?.isPrintCardMenu()) {
          sectionTypeList.push(SectionType.CardStack);
        }
        if (menu?.isPrintLabelMenu()) {
          sectionTypeList.push(SectionType.LabelStack);
        }
        if (menu?.isPrintReportMenu()) {
          sectionTypeList.push(SectionType.NewProducts, SectionType.RestockedProducts);
        }
        if (ThemeUtils.themeIdsWithMediaAndTitleSectionsDisabled().contains(menu?.theme)) {
          sectionTypeList = sectionTypeList.filter(
            item => item !== SectionType.Title && item !== SectionType.Media
          );
        }
        return sectionTypes?.filter(sectionType => sectionTypeList.contains(sectionType?.value));
      })
    );
  }

  private loadTypes(countryCode: string = 'CA') {
    // Want to use cached value as fallback since the app wont work without these enum values
    const cachedTypes = this.cacheService.getCachedObject<EnumTypes>(EnumTypes, EnumTypes.getCacheKey());
    if (!!cachedTypes) {
      // Set the types right away in case API fails or is delayed
      this.connectToRootTypes(cachedTypes);
    }
    // Get the types from the API and update the cached value
    this.utilsAPI.GetTypes(countryCode).subscribe({
      next: (types) => {
        this.connectToRootTypes(types);
        this.cacheService.cacheObject<EnumTypes>(EnumTypes.getCacheKey(), types);
      },
      error: (error: BsError) => {
        this.toastService.publishError(error);
      }
    });
  }

  private buildDropDownHeader = (title: string): Selectable => new class implements Selectable {

    getSelectionTitle = (): string => `---- ${title} ----`;
    getSelectionUniqueIdentifier = (): string => uuid?.v4();
    getSelectionValue = (): any => undefined;
    getSelectionIsDisabled = (): boolean => true;

  }();

  override destroy() {
    super.destroy();
    window.types = undefined;
  }

}
