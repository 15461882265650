import { TypeDefinition } from './type-definition';

export enum FontStyle {
  Normal = 'Normal',
  Bold = 'Bold',
  Italics = 'Italics',
  Underline = 'Underline',
  Strikethrough = 'Strikethrough',
}

export class FontStyleType extends TypeDefinition {

  override value: FontStyle;

  public isNormal(): boolean {
    return this.value === FontStyle.Normal;
  }

  public isBold(): boolean {
    return this.value === FontStyle.Bold;
  }

  public isItalics(): boolean {
    return this.value === FontStyle.Italics;
  }

  public isUnderline(): boolean {
    return this.value === FontStyle.Underline;
  }

  public isStrikethrough(): boolean {
    return this.value === FontStyle.Strikethrough;
  }

}
