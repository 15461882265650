import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-collection-displays-modal-header',
  templateUrl: './collection-displays-modal-header.component.html',
  styleUrls: ['./collection-displays-modal-header.component.scss']
})
export class CollectionDisplaysModalHeaderComponent {

  @Input() title: string;
  @Input() showClearButton: boolean;
  @Input() disableClearButton: boolean = false;
  @Input() stickyCollectionKey: string;
  @Input() stickyOrder: number;
  @Input() stickyZ: number;
  @Output() clear = new EventEmitter();

}
