import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DayOfWeek'
})
export class DayOfWeekPipe implements PipeTransform {

  transform(input: number): string {
    return moment.weekdays(input);
  }

}
