import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { MenuType } from '../../../models/utils/dto/menu-type-definition';

@Pipe({
  name: 'isProductMenu'
})
export class IsProductMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return value?.type === MenuType?.DisplayMenu || value?.type === MenuType?.WebMenu;
  }

}
