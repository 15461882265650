import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { Label } from '../../../models/shared/label';
import { map, shareReplay } from 'rxjs/operators';
import { SortUtils } from '../../../utils/sort-utils';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';

@Injectable()
export class SimpleLabelPreviewViewModel extends BaseViewModel {

  constructor(
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private _labelIds = new BehaviorSubject<string[]>(null);
  public labelIds$ = this._labelIds as Observable<string[]>;

  private _labelsToDisplay = new BehaviorSubject<Label[]>(null);
  public labelsToDisplay$ = this._labelsToDisplay as Observable<Label[]>;

  private allLocationLabels$ = this.labelDomainModel.allLocationLabels$;
  private allCompanyLabels$ = this.labelDomainModel.allCompanyLabels$;

  private locationConfig$ = this.locationDomainModel.locationConfig$;
  private companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly configurations$ = combineLatest([this.locationConfig$, this.companyConfig$]);

  private listenToLabels = combineLatest([
    this.labelIds$.notNull(),
    this.allCompanyLabels$,
    this.allLocationLabels$
  ]).pipe(
    map(([labelIds, allCompanyLabels, allLocationLabels]) => {
      const labelsToDisplay = [];
      labelIds?.forEach(id => {
        const locationIndex = allLocationLabels?.findIndex(ll => {
          return ll?.id === id || ll?.id.toLowerCase() === id.toLowerCase();
        });
        if (locationIndex > -1) {
          labelsToDisplay.push(allLocationLabels[locationIndex]);
        } else {
          const companyIndex = allCompanyLabels?.findIndex(cl => {
            return cl?.id === id || cl?.id.toLowerCase() === id.toLowerCase();
          });
          labelsToDisplay.push(allCompanyLabels[companyIndex]);
        }
      });
      this._labelsToDisplay.next(labelsToDisplay?.sort(SortUtils.sortLabelsAlphabetically));
    }),
    shareReplay({bufferSize: 1, refCount: true})
  ).subscribeWhileAlive({ owner: this });

  connectToLabelIds = (ids: string[]) => this._labelIds.next(ids);

}
