import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditCollectionDisplaysViewModel } from './edit-collection-displays-view-model';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { PendingDisplay } from '../../../../../models/template/dto/pending-display';

@Component({
  selector: 'app-edit-collection-displays',
  templateUrl: './edit-collection-displays.component.html',
  styleUrls: ['./edit-collection-displays.component.scss'],
  providers: [EditCollectionDisplaysViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionDisplaysComponent extends BaseComponent {

  @Input() @ViewModelConnector('connectToCollection') collection: TemplateCollection;
  @Input() mergeKey: string;
  @Input() formPristine: boolean = true;
  @Output() pendingDisplayChanged = new EventEmitter<PendingDisplay>();
  @Output() requiredDisplaysChanged = new EventEmitter<string[]>();

  constructor(public viewModel: EditCollectionDisplaysViewModel) {
    super();
  }

}
