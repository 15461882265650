<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <app-header [header]="viewModel.headerTitle"></app-header>
  <app-upload-asset [parentHandler]="this" [allowImage]="true" [allowVideo]="false" [maxAssets]="1"> </app-upload-asset>
  <button *ngIf="viewModel.file" (click)="uploadImage()" class="bs-button preferred-button float-right mt-20px mb-20px">
    Save New Image
  </button>
  <button (click)="cancel()" class="bs-button neutral-button float-right mt-20px mb-20px">Cancel</button>
</div>
