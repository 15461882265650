import { Deserializable } from '../../protocols/deserializable';
import { WeekDay } from '@angular/common';
import { DateUtils } from '../../../utils/date-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class InventoryReceivingWindow implements Deserializable, UniquelyIdentifiable {

  public startDayOfWeek: WeekDay = null;
  public startTime: string = ''; // HH:MM - 00:00 - 23:59
  public endDayOfWeek: WeekDay = null;
  public endTime: string = ''; // HH:MM - 00:00 - 23:59

  onDeserialize() {
  }

  public isEmpty(): boolean {
    const hasStartDay = this.startDayOfWeek >= 0;
    const hasEndDay = this.endDayOfWeek >= 0;
    return !hasStartDay || !hasEndDay || !this.startTime || !this.endTime;
  }

  public withinTimeWindow(): boolean {
    const currDate = new Date();
    const currDayOfTheWeek = currDate.getDay();
    const currHourMinArray = [currDate.getHours(), currDate.getMinutes(), currDate.getSeconds()];
    const currTimeString = currHourMinArray.join(':');
    // Appending seconds to start and end time
    let startTime = this.startTime;
    let endTime = this.endTime;
    startTime += ':00';
    endTime += ':00';
    return DateUtils.stringDateAndTimeBetween(
      this.startDayOfWeek,
      this.endDayOfWeek,
      currDayOfTheWeek,
      startTime,
      endTime,
      currTimeString
    );
  }

  getUniqueIdentifier(): string {
    return `
      -${this.startDayOfWeek}
      -${this.startTime}
      -${this.endDayOfWeek}
      -${this.endTime}
    `;
  }

}
