import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditMenuSectionHeaderComponent } from '../../../../menu/components/edit-menu-section/edit-menu-section-header/edit-menu-section-header.component';

@Component({
  selector: 'app-edit-template-menu-section-header',
  templateUrl: './edit-template-menu-section-header.component.html',
  styleUrls: [
    './edit-template-menu-section-header.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMenuSectionHeaderComponent extends EditMenuSectionHeaderComponent {

  constructor() {
    super();
  }

  @Input() override headerText: string = 'Edit Template - Section';
  @Input() override headerSubtext: string = 'Create unique template sections for impressive eye-catching templates.';

}
