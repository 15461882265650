import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { DigitalProductMenusComponent } from './digital-product-menus/digital-product-menus.component';
import { DigitalMarketingMenusComponent } from './digital-marketing-menus/digital-marketing-menus.component';
import { BaseViewModel } from '../../../../../models/base/base-view-model';

@Injectable()
export class DigitalViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  public tabs$: Observable<TabBarItem[]> = new BehaviorSubject<TabBarItem[]>([
    new TabBarItem(DigitalProductMenusComponent,  'Product Menus', '', false),
    new TabBarItem(DigitalMarketingMenusComponent, 'Marketing Menus', '', false),
  ]);

}
