import { AfterViewInit, Component, ViewChild } from '@angular/core';
import type { AllDisplaysViewModel } from '../../../viewModels/all-displays-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NewDisplay } from '../../../../../models/display/shared/new-display';
import { ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-new-display-modal',
  templateUrl: './new-display-modal.component.html',
  styleUrls: ['./new-display-modal.component.scss']
})
export class NewDisplayModalComponent extends BaseModalComponent implements AfterViewInit {

  // View References
  @ViewChild('modalBody') modalBody: HTMLDivElement;
  @ViewChild('newDisplayInput') newDisplayInput: ReactiveFormGroupComponent;

  // Display
  public viewModel: AllDisplaysViewModel;
  public newDisplay: NewDisplay = new NewDisplay();

  defaultSizes$ = window.types.digitalSizeTypes$;
  orientations$ = window.types.orientations$;
  // Form
  public canSubmitForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override setupBindings() {
    const s = this.viewModel.dismissModalSubject.subscribe((display) => {
      this.close(display);
    });
    this.pushSub(s);
  }

}
