import { SystemLabel } from './system-label';
import type { Variant } from '../../product/dto/variant';
import { PriceUtils } from '../../../utils/price-utils';
import { SaleLabelFormat } from '../../utils/dto/sale-label-format-type';
import { PriceFormat } from '../../utils/dto/price-format-type';

export class SaleSystemLabel extends SystemLabel {

  getSortOrderTitle(): string {
    return 'Sale';
  }

  public getSaleText(
    variant: Variant,
    saleLabelFormat: SaleLabelFormat,
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat
  ): string {
    switch (saleLabelFormat) {
      case SaleLabelFormat.SALE:        return this.text || 'SALE';
      case SaleLabelFormat.DOLLAR_OFF:  return this.getDollarOff(variant, locationId, companyId, priceFormat);
      case SaleLabelFormat.PERCENT_OFF: return this.getPercentOff(variant, locationId, companyId, priceFormat);
    }
  }

  private getDollarOff(
    variant: Variant,
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat
  ): string {
    const originalPrice = variant?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    const salePrice = variant?.getDiscountedPriceOrNull(locationId, companyId, priceFormat);
    const saved = originalPrice - salePrice;
    return Number.isFinite(saved) ? (PriceUtils.formatPrice(saved) + ' OFF') : '$X OFF';
  }

  private getPercentOff(
    variant: Variant,
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat
  ): string {
    const originalPrice = variant?.getPriceWithoutDiscounts(locationId, companyId, priceFormat);
    const salePrice = variant?.getDiscountedPriceOrNull(locationId, companyId, priceFormat);
    const saved = 1 - (salePrice / originalPrice);
    return Number.isFinite(saved) ? (PriceUtils.formatDecimalIntoPercentage(saved) + ' OFF') : 'X% OFF';
  }

}
