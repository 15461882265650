import { BaseSection } from '../../menu/dto/base-section';
import { SectionBlueprintCategory } from './section-blueprint-category';
import { Asset } from '../../image/dto/asset';

export class SectionBlueprint extends BaseSection {

  public companyId: string;
  public categoryId: string;
  public category: SectionBlueprintCategory;
  public asset: Asset;

  override onDeserialize() {
    super.onDeserialize();
    this.category = window?.injector?.Deserialize?.instanceOf(SectionBlueprintCategory, this.category);
    this.asset = window?.injector?.Deserialize?.instanceOf(Asset, this.asset);
  }

}
