<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ viewModel.themeName$ | push }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <div>
      <app-label-group [labelItems]="viewModel.labelGroupItems$ | push" [styling]="viewModel.labelGroupStyling$ | push">
      </app-label-group>

      <div class="theme-previews-header">
        <div class="theme-preview-title float-left">Examples</div>
        <div class="float-right">
          <app-segmented-control
            (optionSelected)="orientationSegmentedControlOptionSelected($event)"
            [hidden]="viewModel.hideOrientationSegmentedControl$ | push"
            [options]="viewModel.orientationControlOptions$ | push">
          </app-segmented-control>
        </div>
      </div>

      <div class="theme-preview-carousel">
        <app-card-deck
          [cards]="viewModel.previewCards$ | push"
          [horizontalScroller]="true"
          [maxHeightRem]="viewModel.maxHeightRem$ | push"
          [scrollToCardId]="scrollToCardId"
          (cardPressed)="viewModel.showHighResPreview($event)">
        </app-card-deck>
      </div>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="background: none">
    <lib-button-primary (buttonClicked)="cancel()">Done</lib-button-primary>
  </div>
</div>
