import { Menu } from '../dto/menu';
import { MenuTemplate } from '../../template/dto/menu-template';
import { Deserializable } from '../../protocols/deserializable';
import { DefaultPrintStackSize } from '../../enum/dto/default-print-stack-size';

export class DuplicateMenuRequest implements Deserializable {

  public configurationId?: string;
  public templateId?: string;
  public name: string;
  public theme: string;
  public companyId: number;
  public locationId: number;
  public printCardSize?: DefaultPrintStackSize;

  constructor(locationId: number, menu: Menu) {
    if (menu instanceof MenuTemplate) {
      this.templateId = menu?.id;
      delete this.configurationId;
    } else {
      this.configurationId = menu?.id;
      delete this.templateId;
    }
    this.name = `${menu?.name} (Copy)`;
    this.theme = menu?.theme;
    this.locationId = locationId;
    if (menu?.containsStackedContent()) {
      this.printCardSize = menu?.metadata?.printCardSize;
    }
  }

  onDeserialize = () => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-menu-service/blob/dev/models/DuplicateConfigurationRequest.go
  onSerialize() {
    const dto = Object.create(DuplicateMenuRequest.prototype);
    dto.configurationId = this.configurationId;
    dto.templateId = this.templateId;
    dto.name = this.name;
    dto.theme = this.theme;
    dto.companyId = this.companyId;
    dto.locationId = this.locationId;
    dto.printCardSize = this.printCardSize;
    return dto;
  }

}
