import { CardType, CardTypeDefinition } from './card-type-definition';

export class FeaturedCategoryMenuCardType extends CardTypeDefinition {

  isCarouselCard() {
    return this.value === CardType.HeaderImageCarousel
        || this.value === CardType.ProductDealCarousel;
  }

  supportsSecondaryAsset() {
    return this.value === CardType.ProductDealCarousel;
  }

  supportsThirdColor() {
    return this.value === CardType.HeaderImageCarousel;
  }

  isProductDealCarousel() {
    return this.value === CardType.ProductDealCarousel;
  }

}
