import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { DisplayableItem } from '../../displayable-item-preview/displayable-item';
import { map } from 'rxjs/operators';

export class TemplateDeployedCountIndicatorViewModel {

  protected _displayableItem = new BehaviorSubject<DisplayableItem>(null);
  public displayableItem$ = this._displayableItem as Observable<DisplayableItem>;
  public connectToDisplayableItem = (item: DisplayableItem) => this._displayableItem.next(item);

  protected dPipe = <T>(op: OperatorFunction<DisplayableItem, T>) => this.displayableItem$.pipe(op);
  public templateDeployedTooltipText$ = this.dPipe(map(i => i?.displayableItemDeployedCountTooltipText()));
  public templateDeployedCount$ = this.dPipe(map(i => i?.displayableItemDeployedCount()));
  public templateDeployedCountIcon$ = this.dPipe(map(i => i?.displayableItemDeployedCountIcon()));

}
