// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, forkJoin, iif, Observable, of, Subject, throwError } from 'rxjs';
import { Product } from '../../../../../models/product/dto/product';
import { Variant } from '../../../../../models/product/dto/variant';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { DisplayAttribute } from '../../../../../models/display/dto/display-attribute';
import { Injectable, Injector } from '@angular/core';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { BsError } from '../../../../../models/shared/bs-error';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { EditVariantFormObject } from '../../forms/edit-variant-form/edit-variant-form-object';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbandonFormComponent } from '../../../../shared/modals/abandon-form/abandon-form.component';
import { DistinctUtils } from '../../../../../utils/distinct-utils';
import { SortUtils } from '../../../../../utils/sort-utils';
import { DateUtils } from '../../../../../utils/date-utils';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ProviderUtils } from '../../../../../utils/provider-utils';
import { EditVariantModalTabId } from '../../../../../models/enum/shared/edit-variant-modal-tab-id.enum';
import { LabelUtils } from '../../../../../modules/product-labels/utils/label-utils';
import { MenuStyleObject } from '../../../../../models/utils/dto/menu-style-object-type';
import { ModalSelectVariants } from '../../../../../modals/modal-select-variants';
import { ModalAbandonForm } from '../../../../../modals/modal-abandon-form';
import { LabelDomainModel } from '../../../../../domainModels/label-domain-model';
import { DisplayAttributesDomainModel } from '../../../../../domainModels/display-attributes-domain-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { CannabisUnitOfMeasure } from '../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { exists } from '../../../../../functions/exists';
import { CannabinoidDisplayType } from '../../../../../models/utils/dto/cannabinoid-display-type-definition';

@Injectable()
export class EditVariantContainer extends BaseViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private cannabinoidAndTerpeneDomainModel: CannabinoidsAndTerpenesDomainModel,
    private displayAttributeDomainModel: DisplayAttributesDomainModel,
    private injector: Injector,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private ngbModal: NgbModal,
    private productDomainModel: ProductDomainModel,
    private toastService: ToastService,
  ) {
    super();
    this.setupBindings();
  }

  private _unsavedChangesModalOpened = new BehaviorSubject<boolean>(false);
  public saveAttempted$: Subject<void> = new Subject<void>();

  private readonly companyDisplayAttributes$ = this.displayAttributeDomainModel.companyDisplayAttributes$;
  private readonly locationDisplayAttributes$ = this.displayAttributeDomainModel.locationDisplayAttributes$;
  private readonly currentLocationProducts$ = this.productDomainModel.currentLocationProducts$;
  public readonly currentLocation$ = this.locationDomainModel.location$;
  public readonly currentLocationId$ = this.locationDomainModel.locationId$;
  public readonly currentLocationName$ = this.locationDomainModel.locationName$;
  public readonly companyLocations$ = this.locationDomainModel.allLocations$;
  public readonly priceFormat$ = this.locationDomainModel.priceFormat$;
  public readonly companyUsesRange$ = this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$;
  public readonly locationSystemLabels$ = this.labelDomainModel.locationSystemLabels$;

  // Auto Saving
  protected _autoSaveLoadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultInButton());
  public autoSaveLoadingOpts$ = this._autoSaveLoadingOpts.asObservable();
  public autoSaving$ = this.autoSaveLoadingOpts$.pipe(map(it => it.isLoading));

  private _lastAutoSaveTimestamp = new BehaviorSubject<number | null | undefined>(null);
  public lastAutoSaveText$ = this._lastAutoSaveTimestamp.pipe(map(time => {
    if (!!time && DateUtils.unixAfterMinutesAgo(time, 15)) {
      return `Last Saved ${DateUtils.formatUnixToTime(time)}`;
    }
    return null;
  }));

  private _modalBodyHeight = new BehaviorSubject<number>(0);
  public modalBodyHeight$ = this._modalBodyHeight.pipe(distinctUntilChanged());

  private _tabComponentHeight = new BehaviorSubject<number>(0);
  public tabComponentHeight$ = this._tabComponentHeight.pipe(distinctUntilChanged());

  private _bottomButtonPosition = new BehaviorSubject<string>('absolute');
  public bottomButtonPosition$ = this._bottomButtonPosition.pipe(distinctUntilChanged());

  public dismissModalSubject: Subject<boolean> = new Subject<boolean>();
  public unsavedChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canSubmitForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public canChangeTabs$ = combineLatest([this.unsavedChanges$, this.canSubmitForm$]).pipe(
    map(([hasChanges, canSubmit]) => {
      if (!hasChanges) {
        return true;
      } else {
        return canSubmit;
      }
    })
  );

  public canSaveForm$ = combineLatest([this.unsavedChanges$, this.canSubmitForm$]).pipe(
    map(([hasChanges, canSubmit]) => {
      return hasChanges && canSubmit;
    })
  );

  private _currentlySelectedTabIndex = new BehaviorSubject<number>(0);
  public currentlySelectedTabIndex$ = this._currentlySelectedTabIndex as Observable<number>;
  private _previouslySelectedTabId = new BehaviorSubject<number>(0);
  public previouslySelectedTabId$ = this._previouslySelectedTabId as Observable<number>;

  private _variantId = new BehaviorSubject<string>(null);
  public variantId$ = this._variantId as Observable<string>;
  public variant$ = combineLatest([
    this.variantId$,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([variantId, variants]) => variants?.find(it => it?.id === variantId)),
    tap((variant) => this.setVariantUsesRange(!!variant?.useCannabinoidRange)),
  )
    .deepCopy()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  public universalVariant$ = this.variant$.notNull().pipe(
    switchMap(v => iif(() => !!v?.id, this.productDomainModel.getUniversalVariant(v), of(null))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public product$ = combineLatest(([
    this.variant$,
    this.currentLocationProducts$
  ])).pipe(
    map(([v, products]) => {
      const product = products?.find(p => p?.id === v?.productId);
      return window?.injector?.Deserialize?.instanceOf(Product, product);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public variantCompanyDisplayAttribute$ = combineLatest([
    this.variant$,
    this.companyDisplayAttributes$
  ]).pipe(
    map(([variant, companyDAs]) => {
      const variantCompanyDA = companyDAs?.find(da => {
        return da.objectId === variant?.id && da.objectType === MenuStyleObject.Variant;
      });
      return variantCompanyDA || EditVariantContainer.createNewCompanyDA(false, variant);
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public variantLocationDisplayAttribute$ = combineLatest([
    this.currentLocationId$,
    this.variant$,
    this.locationDisplayAttributes$,
  ]).pipe(
    map(([locationId, variant, locationDAs]) => {
      const variantLocationDA = locationDAs?.find(da => {
        return da.objectId === variant?.id && da.objectType === MenuStyleObject.Variant;
      });
      return variantLocationDA || EditVariantContainer.createNewLocationDA(false, variant, locationId);
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public productCompanyDisplayAttribute$ = combineLatest([
    this.product$,
    this.companyDisplayAttributes$
  ]).pipe(
    map(([product, companyDAs]) => {
      const productCompanyDA = companyDAs?.find(da => {
        return da.objectId === product?.id && da.objectType === MenuStyleObject.Product;
      });
      return productCompanyDA || EditVariantContainer.createNewCompanyDA(true, product);
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public productLocationDisplayAttribute$ = combineLatest([
    this.currentLocationId$,
    this.product$,
    this.locationDisplayAttributes$,
  ]).pipe(
    map(([locationId, product, locationDAs]) => {
      const productLocationDA = locationDAs?.find(da => {
        return da.objectId === product?.id && da.objectType === MenuStyleObject.Product;
      });
      return productLocationDA || EditVariantContainer.createNewLocationDA(true, product, locationId);
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public displayAttributesHaveLotInfo$ = combineLatest([
    this.variantLocationDisplayAttribute$,
    this.variantCompanyDisplayAttribute$,
    this.productLocationDisplayAttribute$,
    this.productCompanyDisplayAttribute$
  ]).pipe(map(([varLocDA, varCompDA, prodLocDA, prodCompDA]) => {
    return !!varLocDA?.lotInfo || !!varCompDA?.lotInfo || !!prodLocDA?.lotInfo || !!prodCompDA?.lotInfo;
  }));

  public inventoryProvider$ = this.companyDomainModel.inventoryProvider$;
  public inventoryProviderDetails$ = combineLatest([
    this.companyDomainModel.inventoryProviderSupportsPOSSync$,
    this.companyDomainModel.inventoryProviderSupportsLotInfo$
  ]).pipe(
    map(([supportsSync, supportsLotInfo]) => {
      return [supportsSync, supportsLotInfo];
    })
  );

  private _variantUsesRange = new BehaviorSubject<boolean>(false);
  public variantUsesRange$ = this._variantUsesRange as Observable<boolean>;
  public setVariantUsesRange = (usesRange: boolean) => this._variantUsesRange.next(usesRange);

  public useRange$ = combineLatest([this.companyUsesRange$, this.variantUsesRange$]).pipe(
    map(([companyUsesRange, variantUsesRange]) => companyUsesRange || variantUsesRange)
  );

  public isCompanyAdmin$ = this.userDomainModel.isCompanyAdmin$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public companyId$ = this.companyConfig$.pipe(map(cc => cc.companyId));
  public companyName$ = this.companyDomainModel.companyName$;
  public syncCannabinoidsFromPOS$ = this.companyConfig$.pipe(map(cc => cc.syncPOSCannabinoid));
  public syncTerpeneFromPOS$ = this.companyConfig$.pipe(map(cc => cc.syncPOSTerpene));
  public enabledSecondaryCannabinoidNames$ = this.cannabinoidAndTerpeneDomainModel.enabledSecondaryCannabinoidNames$;
  public hasEnabledSecondaryCannabinoids$ = this.cannabinoidAndTerpeneDomainModel.hasEnabledSecondaryCannabinoids$;
  public enabledTerpenes$ = this.cannabinoidAndTerpeneDomainModel.enabledTerpenes$;
  public enabledTerpenesNames$ = combineLatest([
    this.cannabinoidAndTerpeneDomainModel.enabledTerpeneNames$,
    this.cannabinoidAndTerpeneDomainModel.enabledTerpeneNamesPascalCased$,
    this.cannabinoidAndTerpeneDomainModel.enabledTerpeneNamesCamelCased$
  ]).pipe(
    map(([names, namesPascalCased, namesCamelCased]) => {
      return names?.map((terpene, index) => {
        return {
          terpeneName: terpene,
          pascalCased: namesPascalCased?.[index],
          camelCased: namesCamelCased?.[index]
        };
      });
    })
  );
  public hasEnabledTerpenes$ = this.cannabinoidAndTerpeneDomainModel.hasEnabledTerpenes$;

  public showPOSSyncBanner$: Observable<boolean> = combineLatest([
    this.companyConfig$,
    this.inventoryProviderDetails$,
    this.displayAttributesHaveLotInfo$
  ]).pipe(
    map(([cc, [supportsPOSSync, supportsLotInfo], hasLotInfo]) => {
      let showPOSSyncBanner = (cc.syncPOSCannabinoid || cc.syncPOSTerpene) && supportsPOSSync;
      if (supportsLotInfo) {
        // If lot info is supported, only show the sync banner if lot info is present
        showPOSSyncBanner = showPOSSyncBanner && hasLotInfo;
      }
      return showPOSSyncBanner;
    })
  );

  public displayAttribute$ = combineLatest([
    this.variantLocationDisplayAttribute$,
    this.variantCompanyDisplayAttribute$,
    this.productLocationDisplayAttribute$,
    this.productCompanyDisplayAttribute$
  ]).pipe(
    map(([varLocDA, varCompDA, prodLocDA, prodCompDA]) => {
      if (exists(varLocDA)) return varLocDA;
      else if (exists(prodLocDA)) return prodLocDA;
      else if (exists(varCompDA)) return varCompDA;
      else return prodCompDA;
    })
  );

  public showVariantUseRangeSwitch$ = this.companyConfig$.pipe(
    map(cc => cc?.cannabinoidDisplayType === CannabinoidDisplayType.Exact)
  );

  public supportsCompanySecondaryPrice$ = this.inventoryProvider$.pipe(
    map(inventoryProvider => ProviderUtils.supportsCompanySecondaryPrice(inventoryProvider))
  );
  public secondaryPriceDisabled$ = this.locationDomainModel.secondaryPriceSyncedToPricingGroup$;
  public secondaryPriceDisabledTooltip$ = combineLatest([
    this.secondaryPriceDisabled$,
    this.isCompanyAdmin$,
    this.locationDomainModel.locationConfig$,
  ]).pipe(
    map(([isSynced, isCompanyAdmin, _]) => {
      if (isSynced) {
        return 'Secondary price is disabled because it is synced to a pricing group in the POS.';
        // Stubbed for future further implementation
        // Reference the locConfig?.secondaryPriceGroupId and find Pricing Group name from list of all available
        // Pricing Groups. API does not currently pull and store pricing groups - will be exposed when user can
        // select which pricing group maps to the secondary price field (rather than hard-coding in db)
      } else if (!isCompanyAdmin) {
        return 'Company Secondary price can only be set by Admins .';
      }
      return null;
    })
  );
  public companySecondaryPriceDisabled$ = combineLatest([
    this.secondaryPriceDisabled$,
    this.isCompanyAdmin$,
  ]).pipe(
    map(([secondaryPriceDisabled, isAdmin]) => {
      return secondaryPriceDisabled || !isAdmin;
    })
  );

  // The customization tab writes to these Display Attributes, and feeds them into the customization preview
  public updatedLocationDA$ = new BehaviorSubject<DisplayAttribute>(new DisplayAttribute());
  public updatedCompanyDA$ = new BehaviorSubject<DisplayAttribute>(new DisplayAttribute());

  public updatedLocationBadges: string[] = null;
  public updatedLocationLabel: string = null;
  public updatedCompanyBadges: string[] = null;
  public updatedCompanyLabel: string = null;

  // Customization tab options
  private _selectedSiblingVariants = new BehaviorSubject<Variant[]>([]);
  public selectedSiblingVariants$ = this._selectedSiblingVariants as Observable<Variant[]>;

  public readonly selectedSiblingCompanyDisplayAttributes$ = combineLatest([
    this.companyDisplayAttributes$,
    this.locationDisplayAttributes$,
    this.selectedSiblingVariants$
  ]).pipe(
    map(([companyDAs, locationDAs, selectedSiblings]) => {
      const toSiblingDA = (sibling: Variant) => {
        return companyDAs?.find(da => da.objectId === sibling?.id)
            || EditVariantContainer.createNewCompanyDA(false, sibling);
      };
      return selectedSiblings?.map(toSiblingDA) || [];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly selectedSiblingLocationDisplayAttributes$ = combineLatest([
    this.currentLocationId$,
    this.locationDisplayAttributes$,
    this.selectedSiblingVariants$
  ]).pipe(
    map(([locationId, locationDAs, selectedSiblings]) => {
      const toSiblingDA = (sibling: Variant) => {
        return locationDAs.find(da => da.objectId === sibling?.id)
            || EditVariantContainer.createNewLocationDA(false, sibling, locationId);
      };
      return selectedSiblings?.map(toSiblingDA) || [];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly dataForLabelInterface$ = combineLatest([
    this.product$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Modal Header and Company level placeholders
  public variantName$ = combineLatest([
    this.variant$,
    this.variantCompanyDisplayAttribute$,
    this.variantLocationDisplayAttribute$
  ]).pipe(
    map(([v, cda, lda]) => {
      if (!!lda?.displayName) {
        return lda.displayName;
      } else if (!!cda?.displayName) {
        return cda.displayName;
      } else {
        return v?.name;
      }
    })
  );

  public companyVariantNamePlaceholder$ = this.variant$.pipe(
    map(v => v?.name)
  );

  public companyProductNamePlaceholder$ = this.product$.pipe(
    map(p => p?.name)
  );

  public variantSubtitle$ = this.variant$.pipe(
    map(v => {
      if (!!v?.variantType) {
        return `${v?.variantType} - ${v?.getPackagedQuantityAndProductSize()}`;
      } else {
        return `${v?.getPackagedQuantityAndProductSize()}`;
      }
    })
  );

  public shouldShowOriginalName$ = combineLatest([
    this.companyVariantNamePlaceholder$,
    this.variant$
  ]).pipe(
    map(([name, variant]) => {
      if (name === variant?.name) {
        return null;
      } else {
        return variant?.name;
      }
    })
  );

  public variantBadges$ = combineLatest(([
    this.variantCompanyDisplayAttribute$.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
    this.variantLocationDisplayAttribute$.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable))
  ])).pipe(
    debounceTime(50),
    map(([cda, lda]) => {
      if (!!lda?.getBadges()) {
        return lda.getBadges()?.sort(SortUtils.sortBadgesByNameAscending);
      } else if (!!cda?.getBadges()) {
        return cda.getBadges()?.sort(SortUtils.sortBadgesByNameAscending);
      }
      return null;
    })
  );

  public variantHasCannabinoidsOrTerpenes$ = this.variant$.pipe(
    map(v => !v?.isNonCannabinoid())
  );

  public variantHasIncompleteGeneralProperties$ = combineLatest([
    this.useRange$,
    this.variant$
  ]).pipe(
    map(([useRange, v]) => {
      return v?.incompletePropertyCount(undefined, useRange, true);
    })
  );

  public variantHasIncompleteCannabinoidProperties$ = combineLatest([
    this.useRange$,
    this.variant$
  ]).pipe(
    map(([useRange, v]) => {
      return v?.incompletePropertyCount(undefined, useRange, false, true);
    })
  );

  public computeLabelInterface$ = combineLatest([
    this.variant$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    map(([variant, locConfig, compConfig]) => {
      return LabelUtils.getComputeLabelInterfaceFromVariantOutsideOfMenuContext(variant, locConfig, compConfig);
    })
  );

  private static createNewCompanyDA(
    isProduct: boolean,
    item: Variant | Product
  ): DisplayAttribute {
    return new DisplayAttribute(
      item?.companyId,
      item?.companyId,
      item?.id,
      isProduct ? MenuStyleObject.Product : MenuStyleObject.Variant
    );
  }

  private static createNewLocationDA(
    isProduct: boolean,
    item: Variant | Product,
    locationId: number
  ): DisplayAttribute {
    return new DisplayAttribute(
      item?.companyId,
      locationId,
      item?.id,
      isProduct ? MenuStyleObject.Product : MenuStyleObject.Variant
    );
  }

  public setupBindings() {
    this.ngbModal.activeInstances.subscribeWhileAlive({
      owner: this,
      next: (instances) => {
        instances?.some((i) => i.componentInstance instanceof AbandonFormComponent)
          ? this._unsavedChangesModalOpened.next(true)
          : this._unsavedChangesModalOpened.next(false);
      }
    });
  }

  public openSelectVariantModal() {
    combineLatest([
      this.variant$,
      this.product$,
      this.selectedSiblingVariants$
    ]).once(([variant, product, siblings]) => {
      const disabledVariantIds = [variant.id];
      const preselectedVariants = [variant];
      if (siblings?.length > 0) preselectedVariants.push(...siblings);
      const onClose = (selectedVariants) => {
        if (!!selectedVariants) {
          this.canSubmitForm$.next(true);
          this.addVariants(selectedVariants);
        }
      };
      ModalSelectVariants.open(
        this.ngbModal,
        this.injector,
        product?.variants,
        disabledVariantIds,
        preselectedVariants,
        onClose
      );
    });
  }

  public addVariants(selectedVariants: Variant[]) {
    const currVariantId = this._variantId.getValue();
    // The currently selected variant will always be output, so we need to filter it out
    const otherSelectedVariants = selectedVariants?.filter(v => v.id !== currVariantId);
    this.connectToSelectedSiblingVariants(otherSelectedVariants.length > 0 ? otherSelectedVariants : []);
  }

  saveVariant(submissions: any[], isAutoSave: boolean = false) {
    let selectedTab: number;
    if (isAutoSave) {
      selectedTab = this._previouslySelectedTabId.getValue();
    } else {
      selectedTab = this._currentlySelectedTabIndex.getValue();
    }
    switch (selectedTab) {
      case EditVariantModalTabId.General: // General
        this.saveVariantAndDisplayAttributes('Updating General Variant Information', submissions, isAutoSave);
        break;
      case EditVariantModalTabId.Cannabinoids: // Cannabinoids
      case EditVariantModalTabId.Terpenes: // Terpenes
        this.saveMultipleVariantsAndDisplayAttributes(isAutoSave);
        break;
      case EditVariantModalTabId.Pricing: // Pricing
        this.savePricing(submissions, isAutoSave);
        break;
      case EditVariantModalTabId.Availability: // Avail.
        break;
      case EditVariantModalTabId.Customization: // Customization
        this.saveDisplayAttributes(isAutoSave);
        break;
      case 5:
        break;
      case 6:
        break;
    }
  }

  public saveVariantAndDisplayAttributes(loadingMessage: string, submissions: any[], isAutosave: boolean) {
    const variants: Variant[] = submissions?.filter(s => s instanceof Variant) || [];
    const displayAttrs: DisplayAttribute[] = submissions?.filter(s => s instanceof DisplayAttribute) || [];
    const lm = loadingMessage;
    const sources = [];
    if (variants?.length) sources.push(this.productDomainModel.updateVariants(variants));
    if (displayAttrs?.length) sources.push(this.displayAttributeDomainModel.updateDisplayAttributes(displayAttrs));
    const autoSaveOpts = this._autoSaveLoadingOpts;
    const loading = () => isAutosave ? autoSaveOpts.addRequest('') : this._loadingOpts.addRequest(lm);
    const removeLoading = () => isAutosave ? autoSaveOpts.removeRequest('') : this._loadingOpts.removeRequest(lm);
    loading();
    forkJoin(sources).subscribe({
      next: () => {
        removeLoading();
        this.toastService.publishSuccessMessage('Variant update successful.', 'Successful Update');
        this._lastAutoSaveTimestamp.next(DateUtils.currentTimestamp());
        this.saveAttempted$.next();
      },
      error: (error: BsError) => {
        removeLoading();
        this.toastService.publishError(error);
        this.saveAttempted$.next();
        throwError(() => error);
      }
    });
  }

  public saveMultipleVariantsAndDisplayAttributes(isAutosave: boolean = false) {
    combineLatest([
      this.variant$,
      this.variantLocationDisplayAttribute$,
      this.variantCompanyDisplayAttribute$,
      this.selectedSiblingVariants$,
      this.selectedSiblingCompanyDisplayAttributes$,
      this.selectedSiblingLocationDisplayAttributes$,
      this.cannabinoidAndTerpeneDomainModel.cannabinoidAndTerpeneSavingSignals$
    ]).once(([
      variant,
      variantLocationDA,
      variantCompanyDA,
      siblingVariants,
      siblingCompanyDAs,
      siblingLocationDAs,
      [cannabinoids, terpenesCamelCased, terpenesPascalCased]
    ]) => {
      const allVariants = siblingVariants.concat(variant);
      const allLocationDAs = siblingLocationDAs.concat(variantLocationDA);
      const allCompanyDAs = siblingCompanyDAs.concat(variantCompanyDA);

      const toUpdateArray = [];
      allVariants.forEach(v => {
        if (variant?.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.UNKNOWN) {
          v.cannabisUnitOfMeasure = variant.cannabisUnitOfMeasure;
        }
        v.useCannabinoidRange = variant?.useCannabinoidRange;
        v.useTerpeneRange = variant?.useTerpeneRange;
        toUpdateArray.push(v);
      });
      allLocationDAs.forEach(da => {
        this.assignCannabinoidsAndTerpenesToDisplayAttributes(
          da,
          variantLocationDA,
          cannabinoids,
          terpenesCamelCased,
          terpenesPascalCased
        );
        toUpdateArray.push(da);
      });
      allCompanyDAs.forEach(da => {
        this.assignCannabinoidsAndTerpenesToDisplayAttributes(
          da,
          variantCompanyDA,
          cannabinoids,
          terpenesCamelCased,
          terpenesPascalCased
        );
        toUpdateArray.push(da);
      });
      this.saveVariantAndDisplayAttributes('Updating Cannabinoids', toUpdateArray, isAutosave);
    });
  }

  private assignCannabinoidsAndTerpenesToDisplayAttributes(
    da: DisplayAttribute,
    variantDA: DisplayAttribute,
    cannabinoids: string[],
    terpenesCamelCased: string[],
    terpenesPascalCased: string[]
  ): void {
    cannabinoids.forEach(c => {
      da[c] = variantDA?.[c] ?? '';
      da['min' + c] = variantDA?.['min' + c] ?? '';
      da['max' + c] = variantDA?.['max' + c] ?? '';
    });
    da.totalTerpene = variantDA?.totalTerpene ?? '';
    da.minTotalTerpene = variantDA?.minTotalTerpene ?? '';
    da.maxTotalTerpene = variantDA?.maxTotalTerpene ?? '';
    da.topTerpene = variantDA?.topTerpene ?? '';
    terpenesCamelCased?.forEach((t, index) => {
      const tpc = terpenesPascalCased?.[index];
      da[t] = variantDA?.[t] ?? '';
      da['min' + tpc] = variantDA?.['min' + tpc] ?? '';
      da['max' + tpc] = variantDA?.['max' + tpc] ?? '';
    });
  }

  public savePricing(submission: EditVariantFormObject[], isAutosave: boolean = false) {
    combineLatest([this.currentLocation$, this.companyId$]).pipe(take(1)).subscribe(([currentLocation, companyId]) => {
      const populatedVariant = submission[0].getVariant(companyId, currentLocation.id);
      this.saveVariantAndDisplayAttributes('Updating Variant Pricing', [populatedVariant], isAutosave);
    });
  }

  public saveDisplayAttributes(isAutosave: boolean) {
    combineLatest([
      this.variantLocationDisplayAttribute$,
      this.variantCompanyDisplayAttribute$,
      this.selectedSiblingCompanyDisplayAttributes$,
      this.selectedSiblingLocationDisplayAttributes$
    ]).once(([variantLocationDA, variantCompanyDA, siblingCompanyDAs, siblingLocationDAs]) => {
      const allLocationDAs = siblingLocationDAs.concat(variantLocationDA);
      const allCompanyDAs = siblingCompanyDAs.concat(variantCompanyDA);

      const toUpdateArray = [];
      allLocationDAs.forEach(da => {
        if (this.updatedLocationLabel !== null) da.defaultLabel = this.updatedLocationLabel;
        if (this.updatedLocationBadges !== null) da.badgeIds = this.updatedLocationBadges;
        if (this.updatedLocationLabel !== null || this.updatedLocationBadges !== null) toUpdateArray.push(da);
      });
      allCompanyDAs.forEach(da => {
        if (this.updatedCompanyBadges !== null) da.badgeIds = this.updatedCompanyBadges;
        if (this.updatedCompanyLabel !== null) da.defaultLabel = this.updatedCompanyLabel;
        if (this.updatedCompanyLabel !== null || this.updatedCompanyBadges !== null) toUpdateArray.push(da);
      });
      this.saveVariantAndDisplayAttributes('Updating Variant', toUpdateArray, isAutosave);
    });
  }

  unsavedChangesPrompt(cancel: () => void) {
    this._unsavedChangesModalOpened.pipe(take(1)).subscribe((modalOpened) => {
      if (!modalOpened) {
        const onClose = (abandon) => {
          if (abandon) {
            cancel();
          }
        };
        ModalAbandonForm.open(this.ngbModal, this.injector, onClose);
      }
    });
  }

  connectToModalBodyHeight = (height: number) => this._modalBodyHeight.next(height);
  connectToTabComponentHeight = (height: number) => this._tabComponentHeight.next(height);
  connectToBottomButtonPosition = (position: string) => this._bottomButtonPosition.next(position);
  connectToCurrentlySelectedTabIndex = (id: number) => this._currentlySelectedTabIndex.next(id);
  connectToPreviouslySelectedTabId = (id: number) => this._previouslySelectedTabId.next(id);
  connectToVariantId = (id: string) => this._variantId.next(id);
  connectToSelectedSiblingVariants = (variantArray: Variant[]) => this._selectedSiblingVariants.next(variantArray);
  connectToLastAutoSaveTimestamp = (timeStamp: number) => this._lastAutoSaveTimestamp.next(timeStamp);

}
