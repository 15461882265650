import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { Session } from '../../models/account/dto/session';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '../navigation.service';
import { exists } from '../../functions/exists';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

  private refreshObservable: ReplaySubject<Session>;
  private ignoredRequests = [
    'budboard-image',
    '/sign-in',
    '/sign-in-new-password',
    '/forgot-password-code',
    '/resend-code',
    '/reset-forgotten-password'
  ];

  constructor(
    private userDomainModel: UserDomainModel,
    private ngbModal: NgbModal,
    private navigationService: NavigationService
  ) {
  }

  /**
   * TODO - Figure out how to untangle this.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.ignoredRequests.map(r => request.url.includes(r)).filter(v => v).length > 0) {
      return this.addAuthenticationToken(request).pipe(
        take(1),
        switchMap(req => next.handle(req))
      );
    }
    return this.addAuthenticationToken(request).pipe(
      take(1),
      switchMap(req => next.handle(req)),
      catchError(err => {
        if (err?.status !== 403) {
          return throwError(() => err);
        }
        return this.userDomainModel.getRefreshSessionReq().pipe(
          switchMap((req) => {
            if (!req) {
              // Kill session and navigate to logout
              this.userDomainModel.destroySession.next(true);
              return throwError(() => err);
            }
            // Stall all incoming requests that wont have a valid token
            if (!this.refreshObservable) {
              this.refreshObservable = new ReplaySubject<Session>(1);
              this.refreshObservable.bind(this.userDomainModel.isAuthenticated(true));
            }
            // Refresh Session
            return new Observable<HttpEvent<any>>(subscriber => {
              this.refreshObservable.subscribe({
                next: (sess) => {
                  if (!sess) {
                    this.navigationService.signIn();
                  }
                  this.refreshObservable = null;
                  return this.addAuthenticationToken(request, sess?.accessToken).pipe(
                    take(1),
                    switchMap(r => next.handle(r))
                    // eslint-disable-next-line rxjs/no-nested-subscribe
                  ).subscribe({
                    next: (response) => {
                      subscriber.next(response);
                    },
                    error: (e) => {
                      subscriber.error(e);
                      this.ngbModal.dismissAll();
                      this.navigationService.signIn();
                    }
                  });
                },
                error: (error) => {
                  this.refreshObservable = null;
                  this.userDomainModel.destroySession.next(true);
                  subscriber.error(error);
                }
              });
            });
          })
        );
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>, newToken?: string): Observable<HttpRequest<any>> {
    return this.userDomainModel.user$.pipe(
      take(1),
      map((user) => user?.session?.accessToken || ''),
      switchMap((token) => this.createHeaders(newToken || token, request.headers)),
      take(1),
      map((headers) => request.clone({headers}))
    );
  }

  createHeaders(token: string, headers: HttpHeaders): Observable<HttpHeaders> {
    return this.userDomainModel.user$.pipe(
      take(1),
      map((user) => {
        const append = (n: string, val: string|string[]) => { if (exists(val)) { headers = headers.append(n, val); } };
        // noinspection FallThroughInSwitchStatementJS - we want to check all cases
        switch (true) {
          case !headers.get('Content-Type'): append('Content-Type', 'application/json');
          case !headers.get('ClientSecret'): append('ClientSecret', environment?.cognitoClientSecret);
          case !headers.get('CompanyId'):    append('CompanyId', user?.companyId?.toString());
          case !headers.get('Accept'):       append('Accept', 'application/json');
          case !headers.get('UserId'):       append('UserId', user?.userId);
          case !headers.get('Token'):        append('Token', token);
        }
        return headers;
      })
    );
  }

}

