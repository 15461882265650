<div>
  <ng-container *ngFor="let group of groupedItems">
    <mat-accordion multi [displayMode]="'flat'">
      <mat-expansion-panel #panel class="mat-elevation-z0">
        <mat-expansion-panel-header [collapsedHeight]="'4.625rem'" [expandedHeight]="'4.625rem'">
          <div class="bs-medium f12px">{{ group?.title }}</div>
        </mat-expansion-panel-header>
        <app-single-selection-list
          [items]="group?.items"
          [selectedItem]="selectedItem$ | async"
          (itemSelected)="handleItemSelection($event)"></app-single-selection-list>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</div>
