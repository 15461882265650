import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-remove-button',
  templateUrl: './label-remove-button.component.html',
  styleUrls: ['./label-remove-button.component.scss']
})
export class LabelRemoveButtonComponent {

  @Input() backgroundColor: string = '#FFFFFF';
  @Input() borderRadius: string = '50%';
  @Input() height: string = '4rem';
  @Input() width: string = '4rem';
  @Input() xDim: string = '1.5rem';
  @Input() xScale: number = 0.5;
  @Input() zIndex: number = 10;

}
