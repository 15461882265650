import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { LoadingOptions } from '../../../../../../../../models/shared/loading-options';
import { AssetPreviewOptions } from '../../../../../../../../models/shared/stylesheet/asset-preview-options';
import { Asset } from '../../../../../../../../models/image/dto/asset';
import { UploadAssetComponent } from '../../../../../../../shared/components/upload-asset/upload-asset.component';
import { BudsenseFile } from '../../../../../../../../models/shared/budsense-file';
import { HydratedSection } from '../../../../../../../../models/menu/dto/hydrated-section';
import { EditFeaturedCategoryEditAssetViewModel } from './edit-featured-category-edit-asset-view-model';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { ImageAlignment } from '../../../../../../../../models/enum/shared/image-alignment';

@Component({
  selector: 'app-edit-featured-category-edit-asset',
  templateUrl: './edit-featured-category-edit-asset.component.html',
  styleUrls: ['./edit-featured-category-edit-asset.component.scss'],
  providers: [EditFeaturedCategoryEditAssetViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategoryEditAssetComponent extends BaseComponent implements OnChanges {

  @ViewChild('uploadAsset') assetUploadComponent: UploadAssetComponent;
  @ViewChild('imageAlignment') imageAlignmentDropdown: ReactiveFormDropDownComponent;

  @Input() hideLoading: boolean;
  @Input() loadingOptions: LoadingOptions;
  @Input() asset: Asset;
  @Input() hideAsset: boolean;
  @Input() assetPreviewOptions: AssetPreviewOptions;
  @Input() hideUploadAsset: boolean;
  @Input() hideImageOptions: boolean;
  @Input() uploadAssetId: number = 0;
  @Input() bindTo: HydratedSection;
  @Input() isSecondaryImage: boolean = false;
  @Input() isTemplatedSection: boolean = false;
  @Input() formPristine: boolean = true;
  @Output() primaryButtonPressed = new EventEmitter<any>();
  @Output() secondaryButtonPressed = new EventEmitter<any>();
  @Output() refreshButtonPressed = new EventEmitter<string>();
  @Output() fileListChanged = new EventEmitter<BudsenseFile[]>();

  constructor(
    public viewModel: EditFeaturedCategoryEditAssetViewModel
  ) {
    super();
  }

  override setupBindings() {
    this.viewModel.imageAlignmentDisabled$.subscribeWhileAlive({
      owner: this,
      next: imageAlignmentDisabled => {
        if (imageAlignmentDisabled) this.imageAlignmentDropdown?.getSelfAsFormItem()?.setValue(ImageAlignment.Center);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.bindTo || !!changes.isSecondaryImage) {
      const metadata = this.bindTo?.metadata;
      const objectFit = this.isSecondaryImage ? metadata?.secondaryObjectFit : metadata?.objectFit;
      if (!!objectFit) this.viewModel.connectToImageObjectFit(objectFit);
      const imageWidth = this.isSecondaryImage ? metadata?.secondaryWidth : metadata?.width;
      const widthIsInteger = Number.isInteger(Number.parseInt(imageWidth, 10));
      if (widthIsInteger) this.viewModel.connectToImageWidth(imageWidth);
    }
  }

  public fileList(files: BudsenseFile[], _: number) {
    if (files.length > 0) {
      this.assetUploadComponent?.clear();
      this.fileListChanged.emit(files);
    }
  }

  public parseAsString(s: any): string {
    return `${s}`;
  }

}
