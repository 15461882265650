import { Injectable, Type } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { CompanyDomainModel } from './company-domain-model';
import { ToastService } from '../services/toast-service';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { DistinctUtils } from '../utils/distinct-utils';
import { Label } from '../models/shared/label';
import { SystemLabel } from '../models/shared/labels/system-label';
import { LowStockSystemLabel } from '../models/shared/labels/low-stock-system-label';
import { RestockSystemLabel } from '../models/shared/labels/restock-system-label';
import { SaleSystemLabel } from '../models/shared/labels/sale-system-label';
import { NewSystemLabel } from '../models/shared/labels/new-system-label';
import { FeaturedSystemLabel } from '../models/shared/labels/featured-system-label';
import { BsError } from '../models/shared/bs-error';
import { MenuAPI } from '../api/menu-api';
import { LabelUtils } from '../modules/product-labels/utils/label-utils';
import { ChangesRequiredForPreviewService } from '../services/changes-required-for-preview.service';
import { exists } from '../functions/exists';

// Provided by Logged In Scope
@Injectable()
export class LabelDomainModel extends BaseDomainModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private menuAPI: MenuAPI,
    private toastService: ToastService,
    private changesRequiredForPreviewService: ChangesRequiredForPreviewService
  ) {
    super();
  }

  private readonly currentLocationId$ = this.locationDomainModel.locationId$;
  private readonly currentCompanyId$ = this.companyDomainModel.companyId$;

  // Labels
  private readonly _allLabels = new BehaviorSubject<Label[]>(null);
  public readonly allLabels$ = this._allLabels.pipe(
    debounceTime(100),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _companyCustomLabels = new BehaviorSubject<Label[]>(null);
  public readonly companyCustomLabels$ = this._companyCustomLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _locationCustomLabels = new BehaviorSubject<Label[]>(null);
  public locationCustomLabels$ = this._locationCustomLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _companySystemLabels = new BehaviorSubject<SystemLabel[]>(null);
  public readonly companySystemLabels$ = this._companySystemLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _companyPOSLabels = new BehaviorSubject<Label[]>(null);
  public readonly companyPOSLabels$ = this._companyPOSLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _locationSystemLabels = new BehaviorSubject<SystemLabel[]>(null);
  public locationSystemLabels$ = this._locationSystemLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _allCompanyLabels = new BehaviorSubject<Label[]>(null);
  public readonly allCompanyLabels$ = this._allCompanyLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _allLocationLabels = new BehaviorSubject<Label[]>(null);
  public allLocationLabels$ = this._allLocationLabels.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly systemLabels$ = combineLatest([
    this.companySystemLabels$,
    this.locationSystemLabels$
  ]).pipe(
    map(([companySystemLabels, locationSystemLabels]) => {
      return [...(companySystemLabels || []), ...(locationSystemLabels || [])];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly dataForFindingSystemLabels$ = combineLatest([
    this.systemLabels$,
    this.locationDomainModel.locationId$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private getSystemLabel<T extends SystemLabel>(labelType: Type<T>): Observable<T> {
    return this.dataForFindingSystemLabels$.pipe(
      map(([systemLabels, locationId]) => {
        const isTargetSystemLabel = (l: SystemLabel): l is T => l instanceof labelType;
        const targetedSystemLabels = systemLabels?.filter(isTargetSystemLabel);
        return targetedSystemLabels?.find(l => l?.locationId === locationId) || targetedSystemLabels?.firstOrNull();
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public readonly featuredLabel$ = this.getSystemLabel(FeaturedSystemLabel);
  public readonly lowStockSystemLabel$ = this.getSystemLabel(LowStockSystemLabel);
  public readonly restockSystemLabel$ = this.getSystemLabel(RestockSystemLabel);
  public readonly saleSystemLabel$ = this.getSystemLabel(SaleSystemLabel);
  public readonly newSystemLabel$ = this.getSystemLabel(NewSystemLabel);

  /* *************************** Local Threads of Execution *************************** */

  private fetchAllLabels = this.currentLocationId$.pipe(
    distinctUntilChanged()
  ).subscribeWhileAlive({
    owner: this,
    next: locationId => {
      if (exists(locationId)) {
        this._allLabels.next(null);
        this.loadLabels(locationId);
      }
    }
  });

  private filterLabelsMechanism = combineLatest([
    this.currentLocationId$,
    this.currentCompanyId$,
    this.allLabels$,
  ]).subscribeWhileAlive({
    owner: this,
    next: ([locationId, companyId, labels]) => {
      // location
      const locationLabels = labels?.filter(l => l.locationId === locationId);
      const locationSystemLabels = locationLabels?.filter((l): l is SystemLabel => l instanceof SystemLabel) ?? [];
      const locationCustomLabels = locationLabels?.filter(l => !l.isSystemLabel) ?? [];
      this._locationCustomLabels.next(labels ? locationCustomLabels : null);
      this._locationSystemLabels.next(labels ? locationSystemLabels : null);
      this._allLocationLabels.next(labels ? [...locationCustomLabels, ...locationSystemLabels] : null);
      // company
      const companyLabels = labels?.filter(l => l.locationId === companyId);
      const companySystemLabels = companyLabels?.filter((l): l is SystemLabel => l instanceof SystemLabel) ?? [];
      const companyCustomLabels = companyLabels?.filter(l => !l.isSystemLabel && !l.isPOSManaged) ?? [];
      const compPOSLabels = companyLabels?.filter(l => l.isPOSManaged) ?? [];
      this._companyCustomLabels.next(labels ? companyCustomLabels : null);
      this._companySystemLabels.next(labels ? companySystemLabels : null);
      this._companyPOSLabels.next(labels ? compPOSLabels : null);
      this._allCompanyLabels.next(labels ? [...companyCustomLabels, ...companySystemLabels, ...compPOSLabels] : null);
    }
  });

  /* ********************************************************************************** */

  public loadLabels(locationId: number): void {
    this.menuAPI.GetLabels(locationId).subscribe({
      next: (labels: Label[]) => this._allLabels.next(labels || []),
      error: (err: BsError) => {
        this.toastService.publishError(err);
        throwError(() => err);
      }
    });
  }

  public createLabels(labels: Label[]): Observable<Label[]> {
    labels?.forEach(label => label?.clearRemoveExistingOnSyncIfNecessary());
    return this.menuAPI.CreateLabels(labels).pipe(
      tap(createdLabels => {
        const existingLabels = this._allLabels.getValue().shallowCopy();
        createdLabels.forEach(cl => {
          const i = existingLabels.findIndex(l => (l.id === cl.id) && (l.locationId === cl.locationId));
          if (i > -1) {
            existingLabels.splice(i, 1);
          }
        });
        existingLabels.push(...createdLabels);
        this._allLabels.next(existingLabels);
      }),
      tap(createdLabels => {
        const smartLabelCreated = createdLabels?.some(label => !!label?.smartFilterIds?.length);
        if (smartLabelCreated) this.forceSmartLabelSync();
      }),
    );
  }

  public deleteLabel(label: Label, isCompanyLabel: boolean): Observable<void | string> {
    return combineLatest([
      this.menuAPI.DeleteLabel(label),
      this.currentLocationId$
    ]).pipe(
      map(([res, locationId]) => {
        const existingLabels = this._allLabels.getValue().shallowCopy();
        // If a company label is deleted, and other locations have labels that are currently linked to
        // said label, they should still exist, but no longer be managed or linked. Therefore, if a company label is
        // deleted, we want to re-fetch all labels to include the newly orphaned location label
        const i = existingLabels.findIndex(l => (l.id === label.id) && (l.locationId === label.locationId));
        if (i > -1) {
          existingLabels.splice(i, 1);
        }
        this._allLabels.next(existingLabels);
        return isCompanyLabel ? this.loadLabels(locationId) : res;
      })
    );
  }

  public updateLabels(readyForUpdate: Label[]): Observable<Label[]> {
    readyForUpdate?.forEach(label => label?.clearRemoveExistingOnSyncIfNecessary());
    const updateLabels$ = this.menuAPI.UpdateLabels(readyForUpdate).pipe(
      tap(updatedLabels => {
        const existingLabels = this._allLabels.getValue().shallowCopy();
        updatedLabels.forEach(label => {
          const i = existingLabels.findIndex(l => (l.id === label.id) && (l.locationId === label.locationId));
          if (i > -1) {
            // Must push in new instance of label to trigger distinctUntilChanged
            existingLabels.splice(i, 1, window?.injector?.Deserialize?.instanceOf(Label, label));
          }
        });
        this._allLabels.next(existingLabels);
      })
    );
    return this.updateLabelsWrapper(updateLabels$, readyForUpdate);
  }

  private updateLabelsWrapper(updateLabels$: Observable<Label[]>, readyForUpdate: Label[]): Observable<Label[]> {
    return this.allLabels$.pipe(
      take(1),
      switchMap(currentPoolOfLabels => {
        const existingLabels = currentPoolOfLabels?.filter(existingLabel => {
          const matchingLabelIds = updatedLabel => LabelUtils.labelsHaveTheSameIds(existingLabel, updatedLabel);
          return readyForUpdate?.find(matchingLabelIds);
        });
        return updateLabels$.pipe(
          tap(updatedLabels => {
            const smartLabelSyncRequired = LabelUtils.updatedLabelsRequireSmartLabelSync(existingLabels, updatedLabels);
            if (smartLabelSyncRequired) this.forceSmartLabelSync();
          })
        );
      })
    );
  }

  private forceSmartLabelSync(): void {
    this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(locationId => this.locationDomainModel.syncSmartDisplayAttributes([locationId.toString()], true)),
    ).subscribe({
      error: (err: BsError) => this.toastService.publishError(err),
      complete: () => this.toastService.publishSuccessMessage(`Smart Labels Synced`, `Success`)
    });
  }

  public removeAllLocationPreviews() {
    this.currentLocationId$.once(locId => {
      this.changesRequiredForPreviewService.removeAllLocationPreviews(locId);
    });
  }

}
