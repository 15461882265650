import { Injectable, Injector } from '@angular/core';
import { DigitalTemplatesContainerViewModel } from '../digital-templates-container/digital-templates-container-view-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { DisplayableItemFilterByActive } from '../../../../../../models/enum/shared/displayable-item-filter-by.active';
import { PublishableDisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/publishable-displayable-items-view-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { ModalCreateTemplateCollection } from '../../../../../../modals/modal-create-template-collection';

@Injectable()
export class DigitalTemplateCollectionsViewModel extends PublishableDisplayableItemsViewModel {

  constructor(
    protected templatesDomainModel: TemplateDomainModel,
    protected templateCollectionsDomainModel: TemplateCollectionDomainModel,
    // digitalTemplatesContainerViewModel provided by the Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected digitalTemplatesContainerViewModel: DigitalTemplatesContainerViewModel,
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(digitalTemplatesContainerViewModel, navigationService, ngbModal, injector);
    this.setupFilter();
  }

  protected itemsToFilter$ = this.templateCollectionsDomainModel.templateCollections$;

  protected setEmptyState(
    searchText: string,
    tag: string,
    filterDisplayableItemBy: DisplayableItemFilterByActive
  ) {
    const tc = 'template collection';
    const allTitle = 'No Template Collections';
    const activeTitle = 'No Published Template Collections';
    const inactiveTitle = 'No Draft Template Collections';
    this.updateEmptyStateHelper(
      [searchText, tag, filterDisplayableItemBy],
      {
        noSearchTextNoTag: () => {
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => {
              this._emptyStateTitle.next(allTitle);
              this._emptyStateDesc.next('Create a ' + tc + ' by using the buttons above.');
            },
            active: () => {
              this._emptyStateTitle.next(activeTitle);
              const desc = 'Try showing draft ' + tc + 's to see if you have any ' + tc + 's created.';
              this._emptyStateDesc.next(desc);
            },
            inactive: () => {
              this._emptyStateTitle.next(inactiveTitle);
              const desc = 'Try showing published ' + tc + 's to see if you have any ' + tc + 's created.';
              this._emptyStateDesc.next(desc);
            }
          });
        },
        noSearchTextWithTag: () => {
          const withThatTag = ` with the tag \"${tag}\"`;
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => this._emptyStateTitle.next(allTitle + withThatTag),
            active: () => this._emptyStateTitle.next(activeTitle + withThatTag),
            inactive: () => this._emptyStateTitle.next(inactiveTitle + withThatTag)
          });
          this._emptyStateDesc.next('Try a different tag to find the ' + tc + ' that you\'re looking for.');
        },
        searchTextNoTag: () => {
          const couldNotBeFound = ` could be found that includes \"${searchText}\"`;
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => this._emptyStateTitle.next(allTitle + couldNotBeFound),
            active: () => this._emptyStateTitle.next(activeTitle + couldNotBeFound),
            inactive: () => this._emptyStateTitle.next(inactiveTitle + couldNotBeFound)
          });
          this._emptyStateDesc.next('Try a different search query to find the ' + tc + ' that you\'re looking for.');
        },
        searchTextWithTag: () => {
          const withThatTag = ` with the tag \"${tag}\"`;
          const withThatText = ` could be found that includes \"${searchText}\"`;
          this.displayableItemFilterBySwitchCase({
            filterDisplayableItemBy,
            all: () => this._emptyStateTitle.next(allTitle + withThatTag + withThatText),
            active: () => this._emptyStateTitle.next(activeTitle + withThatTag + withThatText),
            inactive: () => this._emptyStateTitle.next(inactiveTitle + withThatTag + withThatText)
          });
          const desc = 'Try a different tag or search query to find the ' + tc + ' that you\'re looking for.';
          this._emptyStateDesc.next(desc);
        }
      }
    );
  }

  public openCreateTemplateCollectionModal(): void {
    const onClose = (newCollection: TemplateCollection) => {
      if (newCollection) {
        this.navigationService.navigateToTemplateCollection(newCollection);
      }
    };
    ModalCreateTemplateCollection.open(this.ngbModal, this.injector, onClose);
  }

}
