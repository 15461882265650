import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';

@Injectable()
export class LabelActionCardViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private _activeLabelStyle = new BehaviorSubject<LabelStyle>(null);
  public activeLabelStyle$ = this._activeLabelStyle.asObservable();
  public connectToActiveLabelStyle = (ls: LabelStyle): void => this._activeLabelStyle.next(ls);

  private _activeSaleLabelFormat = new BehaviorSubject<SaleLabelFormat>(null);
  public activeSaleLabelFormat$ = this._activeSaleLabelFormat as Observable<SaleLabelFormat>;
  public connectToActiveSaleLabelFormat = (slf: SaleLabelFormat) => this._activeSaleLabelFormat.next(slf);

  private readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  private readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly configs$ = combineLatest([this.locationConfig$, this.companyConfig$]);
  public readonly labelStyling$ = combineLatest([this.activeLabelStyle$, this.activeSaleLabelFormat$]);

}
