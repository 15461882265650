import { Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { EnumTypes } from '../models/utils/dto/enum-types';

@Injectable({ providedIn: 'root' })
export class UtilsAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Utils';

  // Types

  public GetTypes(countryCode: string): Observable<EnumTypes> {
    const url = Endpoints.GetTypes(countryCode);
    return this.apiClient.getObj<EnumTypes>(EnumTypes, url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetTypes', err));
        return throwError(err);
      })
    );
  }

}
