import { EventEmitter, Injectable, Injector } from '@angular/core';
import { EditMenuSectionViewModel } from '../../../menu/viewModels/edit-menu-section-view-model';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';
import { AutoSaveViewModel } from '../../../shared/components/auto-save/auto-save-view-model';
import { map } from 'rxjs/operators';
import { Menu } from '../../../../models/menu/dto/menu';
import { Section } from '../../../../models/menu/dto/section';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';
import { combineLatest, Observable } from 'rxjs';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { LocationChangedUtils } from '../../../../utils/location-changed-utils';
import { LiveViewUtils } from '../../../../utils/live-view-utils';
import { Size } from '../../../../models/shared/size';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class EditTemplateMenuSectionViewModel extends AutoSaveViewModel {

  /*
   * Need editMenuSectionViewModel injected here so that it's scoped to this template.
   * This allows child components that require these view models to share the same instance.
   */
  constructor(
    public editMenuSectionViewModel: EditMenuSectionViewModel,
    protected templateDomainModel: TemplateDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected ngbModal: NgbModal,
    protected injector: Injector
  ) {
    super();
    this.connectToAllowAutoSaving(false);
    editMenuSectionViewModel.connectToTemplateMode(true);
    editMenuSectionViewModel.connectToAllowAutoSaving(false);
    editMenuSectionViewModel.connectToAddRequest('Loading Section Template');
  }

  public readonly locId$ = this.locationDomainModel.locationId$;
  public readonly menuTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  public readonly activeMenuTemplate$ = this.templateDomainModel.activeMenuTemplate$;
  public readonly sectionTemplateId$ = this.templateDomainModel.activeSectionTemplateId$;
  public readonly activeSectionTemplate$ = this.templateDomainModel.activeSectionTemplate$;
  public readonly removingVariants$ = this.editMenuSectionViewModel.removingVariants$;
  public readonly hideSectionNavButton$ = this.editMenuSectionViewModel.hideSectionNavButton$;
  public readonly disabledSectionNavOptionIds$ = this.editMenuSectionViewModel.disabledSectionNavOptionIds$;
  public readonly sectionNavOptions$ = this.editMenuSectionViewModel.sectionNavOptions$;
  public readonly isPrintReportSection$ = this.editMenuSectionViewModel.isPrintReportSection$;
  public readonly handleDropdownSelection = this.editMenuSectionViewModel.handleDropdownSelection;

  public readonly breadcrumbs$ = combineLatest([
    this.activeMenuTemplate$,
    this.activeSectionTemplate$,
  ]).pipe(
    map(([menu, section]) => this.getCrumbs(menu, section))
  );

  loadMenuTemplate = () => this.editMenuSectionViewModel.loadHydratedMenuTemplate();
  loadSectionTemplate = (locationId: number, menuTemplateId: string, sectionTemplateId: string,) => {
    this.editMenuSectionViewModel.loadSectionTemplate(locationId, menuTemplateId, sectionTemplateId);
  };

  /* *********************** Local Threads of Execution *********************** */

  private fetchMenuTemplateWhenLocationChanges = LocationChangedUtils
    .loadMenuTemplate(this, this.locId$, this.menuTemplateId$, this.loadMenuTemplate);

  private fetchSectionTemplateWhenLocationChanges = LocationChangedUtils
    .loadSectionTemplate(this, this.locId$, this.menuTemplateId$, this.sectionTemplateId$, this.loadSectionTemplate);

  /* ************************************************************************** */

  protected getCrumbs(menu: Menu, section: Section): Breadcrumb[] {
    return this.editMenuSectionViewModel.getCrumbs(menu, section) ?? [];
  }

  saveTemplateSection(background: boolean) {
    this.editMenuSectionViewModel.saveSection(background);
  }

  deleteSection() {
    this.editMenuSectionViewModel.promptForSectionDelete();
  }

  showLiveView = (
    allPristine: boolean,
    formsAutoSubmitted$: Observable<any[]>,
    submitForms: EventEmitter<boolean>
  ): void => {
    LiveViewUtils.openLiveView(allPristine, formsAutoSubmitted$, submitForms, this.openLiveViewModal.bind(this));
  };

  public openLiveViewModal(sizeOverride?: Size) {
    combineLatest([this.locId$, this.activeMenuTemplate$]).once(([locationId, menu]) => {
      LiveViewUtils.openLiveViewModal(this.ngbModal, this.injector, locationId, menu, sizeOverride);
    });
  }

  public duplicateSection() {
    this.editMenuSectionViewModel.openDuplicateSectionModal();
  }

  override destroy() {
    super.destroy();
    this.templateDomainModel.clearActiveSectionTemplate();
  }

}
