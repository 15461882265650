import { Injectable } from '@angular/core';
import { MenuAddedToDisplayFormViewModel } from '../menu-added-to-display-form/menu-added-to-display-form-view-model';

@Injectable()
export class MenuWithinCollectionAddedToDisplayFormViewModel extends MenuAddedToDisplayFormViewModel {

  constructor() {
    super();
    this.connectToMenuIsPartOfCollection(true);
    this.connectToWithinCollectionAssignedToDisplay(true);
  }

}
