import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '../../../../../models/company/dto/location';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';

@Injectable()
export class MenuTemplateRequiredLocationsChangedSaveConfirmationViewModel extends BaseModalViewModel {

  constructor(
    protected locationDomainModel: LocationDomainModel,
    ngbModal: NgbModal,
    router: Router
  ) {
    super(router, ngbModal);
  }

  public stickyZIndex$ = of(2);

  private _template = new BehaviorSubject<MenuTemplate>(null);
  public template$ = this._template as Observable<MenuTemplate>;
  connectToTemplate = (template: MenuTemplate) => this._template.next(template);

  private _updatedRequiredLocationIds = new BehaviorSubject<number[]>(null);
  public updatedRequiredLocationIds$ = this._updatedRequiredLocationIds as Observable<number[]>;
  connectToUpdatedRequiredLocationIds = (updated: number[]) => this._updatedRequiredLocationIds.next(updated);

  private _searchString = new BehaviorSubject<string>(null);
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _searchedLocations = new BehaviorSubject<Location[]>(null);
  public searchedLocations$ = this._searchedLocations as Observable<Location[]>;
  connectToSearchedLocations = (locations: Location[]) => this._searchedLocations.next(locations);

  private readonly allLocations$ = this.locationDomainModel.allLocations$;

  public readonly originalRequiredLocationIds$ = this.template$.pipe(
    map(template => template?.requiredLocationIds ?? [])
  );

  public readonly addedOrRemovedLocations$ = combineLatest([
    this.allLocations$,
    this.originalRequiredLocationIds$,
    this.updatedRequiredLocationIds$,
  ]).pipe(
    map(([locations, original, updated]) => {
      const addedOrRemoved = (location: Location): boolean => !!Location.addedOrRemoved(location, original, updated);
      const locationIds = [...(original ?? []), ...(updated ?? [])]?.unique();
      return locations?.filter(addedOrRemoved);
    })
  );

  public readonly searchedLocationsGroupedByProvince$ = this.searchedLocations$.pipe(
    map(locations => Location.groupLocationsByProvince([locations?.map(loc => loc?.id), locations]))
  );

  trackById(index, item: { key: string; value: Location[] }) {
    return `${item?.key}-${item?.value?.map(loc => loc?.id).join('-')}`;
  }

}
