import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-checkbox-only',
  templateUrl: './checkbox-only.component.html',
  styleUrls: ['./checkbox-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxOnlyComponent extends BaseComponent {

  public cid = Math.random().toString(24);

  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() zIndex: number = 1;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>(true);
  @ViewChild('input') input: ElementRef;

  constructor() {
    super();
  }

  public itemClicked(): void {
    this.handleInputChange();
    this.clicked.emit(true);
  }

  public handleInputChange(): void {
    this.checked = !this.checked;
    (this.input.nativeElement as HTMLInputElement).checked = this.checked;
    this.valueChanged.emit(this.checked);
  }

}
