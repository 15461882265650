import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';

@Pipe({
  name: 'isMenu'
})
export class IsMenuPipe implements PipeTransform {

  transform(value: any): Menu | null {
    return value instanceof Menu ? value : null;
  }

}
