import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Theme } from '../../../../../models/menu/dto/theme';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { MenuType, MenuTypeDefinition } from '../../../../../models/utils/dto/menu-type-definition';
import { SegmentedControlOption } from '../../../../../models/shared/stylesheet/segmented-control-option';
import { LabelGroupItem } from '../../../../../models/shared/stylesheet/label-group-item';
import { LabelGroupStyling } from '../../../../../models/shared/stylesheet/label-group-styling';
import { map, take } from 'rxjs/operators';
import { ThemeDetailsForm } from '../../forms/theme-details-form';
import { CardStyle } from '../../../../../models/shared/stylesheet/card-style.enum';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { Asset } from '../../../../../models/image/dto/asset';
import { DefaultPrintCardSize } from '../../../../../models/utils/dto/default-print-card-size-type';
import { LightboxModalComponent } from '../../../../shared/components/lightbox-modal/lightbox-modal.component';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { SortUtils } from '../../../../../utils/sort-utils';
import { DefaultPrintStackSize } from '../../../../../models/enum/dto/default-print-stack-size';
import { exists } from '../../../../../functions/exists';
import { DefaultStackedSizeType } from '../../../../../models/utils/dto/default-stacked-size-type';
import { DefaultPrintLabelSize } from '../../../../../models/utils/dto/default-print-label-size-type';

@Injectable()
export class ThemeDetailsModalViewModel extends BaseModalViewModel {

  constructor(
    router: Router,
    public modalService: NgbModal,
    private menuDomainModel: MenuDomainModel
  ) {
    super(router, modalService);
  }

  // Theme
  private _theme = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme as Observable<Theme>;
  connectToTheme = (theme: Theme) => this._theme.next(theme);

  private _initialOrientation = new BehaviorSubject<Orientation>(Orientation.Portrait);
  public initialOrientation$ = this._initialOrientation as Observable<Orientation>;
  connectToInitialOrientation = (initialOrientation: Orientation) => this._initialOrientation.next(initialOrientation);

  private _menuType = new BehaviorSubject<MenuType>(MenuType.DisplayMenu);
  public menuType$ = this._menuType as Observable<MenuType>;
  connectToMenuType = (menuType: MenuType) => this._menuType.next(menuType);

  private _orientationControlOptions = new BehaviorSubject<SegmentedControlOption[]>([]);
  public orientationControlOptions$ = this._orientationControlOptions as Observable<SegmentedControlOption[]>;
  connectToOrientationControlOptions = (opts: SegmentedControlOption[]) => this._orientationControlOptions.next(opts);

  private _selectedOrientationControl = new BehaviorSubject<SegmentedControlOption>(null);
  public selectedOrientationControl$ = this._selectedOrientationControl as Observable<SegmentedControlOption>;
  connectToSelectedOrientationControl = (selectedOrientationControl: SegmentedControlOption) => {
    this._selectedOrientationControl.next(selectedOrientationControl);
  };

  private _previewCards = new BehaviorSubject<Card[]>([]);
  public previewCards$ = this._previewCards as Observable<Card[]>;

  private _maxHeightRem = new BehaviorSubject<number>(24);
  public maxHeightRem$ = this._maxHeightRem as Observable<number>;

  // Label Group
  private _labelGroupItems = new BehaviorSubject<LabelGroupItem[]>([]);
  public labelGroupItems$ = this._labelGroupItems as Observable<LabelGroupItem[]>;

  private _labelGroupStyling = new BehaviorSubject<LabelGroupStyling>(new LabelGroupStyling());
  public labelGroupStyling$ = this._labelGroupStyling as Observable<LabelGroupStyling>;

  public themeName$ = this.theme$.pipe(
    map(theme => theme?.name)
  );

  public hideOrientationSegmentedControl$ = this.menuType$.pipe(
    map(menuType => {
      return menuType === MenuType.WebMenu
        || menuType === MenuType.PrintMenu
        || MenuTypeDefinition.containsStackedContent(menuType)
        || menuType === MenuType.PrintReportMenu;
    })
  );

  setupTheme(): void {
    this.theme$.pipe(
      take(1),
      switchMap(theme => this.menuDomainModel.getThemeById(theme?.id)),
    ).once(theme => this._theme.next(theme));
  }

  setupLabelGroup() {
    combineLatest([
      this.theme$,
      this.labelGroupStyling$
    ]).once(([theme, labelGroupStyling]) => {
      const labelGroupItems = ThemeDetailsForm.getItems(theme);
      this._labelGroupItems.next(labelGroupItems);
      labelGroupStyling.numberColumns = 2;
      this._labelGroupStyling.next(labelGroupStyling);
    });
  }

  setupOrientationSegmentedControl() {
    // Setup portrait option
    combineLatest([
      this.initialOrientation$,
      this.theme$,
    ]).subscribeWhileAlive({
      owner: this,
      next: ([initialOrientation, theme]) => {
        const p = new SegmentedControlOption('Portrait', Orientation.Portrait);
        const l = new SegmentedControlOption('Landscape', Orientation.Landscape);
        const hasPortraitImages = exists(theme?.portraitPreviewImages?.length);
        const hasLandscapeImages = exists(theme?.landscapePreviewImages?.length);
        const initializeFromInitialOrientation = () => {
          p.selected = initialOrientation === Orientation.Portrait;
          l.selected = initialOrientation === Orientation.Landscape;
          if (p.selected) this.connectToSelectedOrientationControl(p);
          if (l.selected) this.connectToSelectedOrientationControl(l);
        };
        const orientationControlOptions: SegmentedControlOption[] = [
          ...hasPortraitImages ? [p] : [],
          ...hasLandscapeImages ? [l] : []
        ];
        switch (true) {
          case !hasPortraitImages && hasLandscapeImages:
            l.selected = true;
            this.connectToSelectedOrientationControl(l);
            break;
          case hasPortraitImages && !hasLandscapeImages:
            p.selected = true;
            this.connectToSelectedOrientationControl(p);
            break;
          default:
            initializeFromInitialOrientation();
        }
        this.connectToOrientationControlOptions(orientationControlOptions);
      }
    });
  }

  setupThemeCards() {
    combineLatest([
      this.theme$,
      this.menuType$,
      this.selectedOrientationControl$,
      window.types.printStackSizeTypes$
    ]).once(([theme, menuType, selectedOrientationControl, stackedContentSizeTypes]) => {
      // Set max height for card container
      const selectedOrientation = selectedOrientationControl.value;

      this._maxHeightRem.next(this.setMaxHeightRem(theme, menuType, selectedOrientation));
      // Setup theme preview cards
      const containsStackedContent = MenuTypeDefinition.containsStackedContent(menuType);
      let previewImages = selectedOrientation === Orientation.Portrait
        ? theme.portraitPreviewImages
        : theme.landscapePreviewImages;
      previewImages = previewImages || [];
      const previewImageToCardSizeMap = new Map<string, DefaultPrintStackSize>();
      theme?.printConfig?.previewCardMap?.forEach((hashes, cardSize) => {
        hashes?.forEach(hash => previewImageToCardSizeMap.set(hash, cardSize));
      });
      this.sortPreviewImages(previewImages, previewImageToCardSizeMap, containsStackedContent);
      const previewCards = previewImages.map((previewImage) => {
        const previewCardText = containsStackedContent
          ? this.getStackDimensionsText(previewImageToCardSizeMap, previewImage, stackedContentSizeTypes)
          : '';
        const card = new Card('', previewCardText);
        card.id = previewImage.id;
        card.cardStyle = CardStyle.ThemeCarouselImage;
        card.customClass = containsStackedContent
          ? this.getPrintStackCustomClass(theme, previewImage)
          : this.getCardCustomClass(menuType, selectedOrientation);
        card.asset = previewImage;
        return card;
      });
      this._previewCards.next(previewCards);
    });
  }

  private sortPreviewImages(
    previewImages: Asset[],
    previewImageToCardSizeMap: Map<string, DefaultPrintStackSize>,
    isStackMenu: boolean
  ): void {
    isStackMenu
      ? previewImages?.sort((a, b) => SortUtils.sortPrintStackThemePreviewImages(a, b, previewImageToCardSizeMap))
      : previewImages?.sort((a, b) => b.timestamp - a.timestamp);
  }

  private setMaxHeightRem(theme: Theme, menuType: MenuType, selectedOrientation: Orientation): number {
    if (MenuTypeDefinition.containsStackedContent(menuType)) return this.findMaxHeightRemForPrintCard(theme);
    return selectedOrientation === Orientation.Portrait ? 26 : 16;
  }

  private findMaxHeightRemForPrintCard(theme: Theme): number {
    const printConfig = theme?.printConfig;
    const orientations = [...printConfig.cardOrientationMap?.values() ?? []];
    if (orientations?.includes(Orientation.Portrait)) return 20;
    const sizes = [...(printConfig.cardOrientationMap?.keys() ?? [])];
    switch (true) {
      case sizes.includes(DefaultPrintLabelSize.DefaultSize_CustomLabel2x4):
      case sizes.includes(DefaultPrintCardSize.DefaultSize_Custom2x2):
      case sizes.includes(DefaultPrintCardSize.DefaultSize_Custom5x5):
        return 20;
      case sizes.includes(DefaultPrintCardSize.DefaultSize_PostCard):
        return 15;
      case sizes.includes(DefaultPrintCardSize.DefaultSize_BusinessCard):
        return 13.33;
      case sizes.includes(DefaultPrintCardSize.DefaultSize_IndexCard):
        return 12;
      case sizes.includes(DefaultPrintCardSize.DefaultSize_AddressCard):
        return 6.66;
    }
  }

  private getStackDimensionsText(
    previewImageToSizeMap: Map<string, DefaultPrintStackSize>,
    previewImage: Asset,
    stackedContentSizeTypes: DefaultStackedSizeType[]
  ): string {
    const sizeForPreviewImage = previewImageToSizeMap.get(previewImage.md5Hash);
    const cardSizeType = stackedContentSizeTypes?.find(stackedSize => {
      return stackedSize?.getSelectionValue() === sizeForPreviewImage;
    });
    return cardSizeType?.getNameFromSize() + ' ' + cardSizeType?.getDimensionString() || '';
  }

  private getCardCustomClass(menuType: MenuType, selectedOrientation: Orientation): string {
    switch (menuType) {
      case MenuType.DisplayMenu:
      case MenuType.MarketingMenu:
        return selectedOrientation === Orientation.Portrait
          ? 'portrait-full-preview'
          : 'landscape-full-preview';
      case MenuType.WebMenu:
        return 'web-full-preview';
      case MenuType.PrintMenu:
      case MenuType.PrintReportMenu:
        return 'portrait-print-full-preview';
    }
  }

  private getPrintStackCustomClass(theme: Theme, previewImage: Asset): string {
    const hash = previewImage.md5Hash;
    const printConfig = theme?.printConfig;
    const cardSize = printConfig?.previewCardMap?.getFirstKeyFromValue(hash);
    const orientation = printConfig?.cardOrientationMap?.get(cardSize);
    return `${orientation}-${cardSize?.toLowerCase()}`;
  }

  showHighResPreview(card: Card) {
    this.selectedOrientationControl$.once(selectedOrientationControl => {
      const modalRef = this.modalService.open(LightboxModalComponent);
      modalRef.componentInstance.img = card.asset;
      modalRef.componentInstance.cardSizeText = card.text;
      modalRef.componentInstance.orientation = selectedOrientationControl.value;
      modalRef.result.then(() => {});
    });
  }

}
