import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smartLabelDisabledTooltip'
})
export class SmartLabelDisabledTooltipPipe implements PipeTransform {

  transform(disabled: boolean): string {
    if (disabled) {
      return `This label is added and removed using smart filters and can not be manually applied.`;
    }
    return null;
  }

}
