<div class="bulk-print-jobs-container">
  <div class="mt-24px">
    <app-bulk-print-jobs-data-table
      [bulkPrintJobs]="viewModel.filteredSearchedItem$ | async"
      [nItemsToDisplay]="10"
      [editPrintCardMode]="false"
      [editPrintLabelMode]="false"
      [noResultsText]="viewModel.noResultsText$ | async"
      [noResultsTitle]="viewModel.noResultsTitle$ | async">
    </app-bulk-print-jobs-data-table>
  </div>
</div>

<lib-button-input-portal [portalKey]="'displayableContentKey'">
  <lib-button-primary (buttonClicked)="viewModel.createBulkPrintJob()"> New Bulk Print Job </lib-button-primary>
</lib-button-input-portal>
