import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { MarketingMenuType } from '../../../models/enum/dto/marketing-menu-type.enum';

@Pipe({
  name: 'isDriveThruMenu'
})
export class IsDriveThruMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return value?.hydratedTheme?.menuSubType === MarketingMenuType.DriveThru;
  }

}
