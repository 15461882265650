<div class="row mt-28px">
  <div class="col-12" fxLayout="row" fxLayoutAlign=" end">
    <div class="bs-section-title" fxFlex><ng-content></ng-content></div>
    <app-toggle-switch
      *ngIf="includeToggleSwitch"
      [label]="toggleLabel"
      [state]="toggleState"
      [enabled]="toggledEnabled"
      (toggled)="switchToggled.emit($event)">
    </app-toggle-switch>
    <button
      class="bs-button preferred-button"
      *ngIf="includeSaveButton"
      (click)="buttonClicked.emit()"
      [disabled]="!canSubmit"
      [ngClass]="{ disabled: !canSubmit }">
      {{ buttonLabel }}
    </button>
  </div>
</div>
<hr class="mb-0" />
