<app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

<div class="app-body mt-0 mb-0">
  <!-- Nav Bar -->
  <app-sidebar-navigation
    [hidden]="sidebarMinimized"
    [class.sidebar-show]="!sidebarMinimized"
    [class.sidebar-hide]="sidebarMinimized"
    class="sidebar">
  </app-sidebar-navigation>
  <!-- Main content -->
  <main class="main">
    <main class="main-content">
      <router-outlet></router-outlet>
      <app-banner-events></app-banner-events>
    </main>
  </main>
</div>
