import { TypeDefinition } from './type-definition';

export enum PriceFormat {
  Default = 'Default',
  TaxesIn = 'TaxesIn',
  TaxesInRounded = 'TaxesInRounded'
}

export class PriceFormatType extends TypeDefinition {

  override value: PriceFormat;

}
