import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMilliseconds'
})
export class SecondsToMillisecondsPipe implements PipeTransform {

  transform(seconds: number): number {
    return seconds >= 0 ? seconds * 1000 : seconds;
  }

}
