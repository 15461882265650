<mat-accordion multi [displayMode]="'flat'">
  <mat-expansion-panel #panel class="mat-elevation-z0">
    <mat-expansion-panel-header [collapsedHeight]="'4.625rem'" [expandedHeight]="'4.625rem'">
      <app-smart-filter-group-selection
        [selection]="grouping$ | async"
        [selectedIds]="selectedIds"
        [previouslyAddedIds]="previouslyAddedIds"
        [includeBottomBorder]="false"
        [forSmartData]="forSmartData"
        (add)="add.emit($event)"
        (bulkAdd)="bulkAdd.emit($event)"
        (remove)="remove.emit($event)"
        (bulkRemove)="bulkRemove.emit($event)">
      </app-smart-filter-group-selection>
    </mat-expansion-panel-header>
    <app-smart-filter-selection
      *ngFor="let child of children$ | async"
      [isChild]="true"
      [selection]="child"
      [previouslyAddedIds]="previouslyAddedIds"
      [selectedIds]="selectedIds"
      [forSmartData]="forSmartData"
      (add)="add.emit($event)"
      (remove)="remove.emit($event)">
    </app-smart-filter-selection>
  </mat-expansion-panel>
</mat-accordion>
