import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { TemplateAddOrEditLocationsViewModel } from './template-add-or-edit-locations-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortUtils } from '../../../../../utils/sort-utils';
import { Location } from '../../../../../models/company/dto/location';

@Component({
  selector: 'app-template-add-or-edit-required-locations',
  templateUrl: './template-add-or-edit-required-locations.component.html',
  styleUrls: ['./template-add-or-edit-required-locations.component.scss'],
  providers: [TemplateAddOrEditLocationsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateAddOrEditRequiredLocationsComponent extends BaseModalComponent {

  constructor(
    public viewModel: TemplateAddOrEditLocationsViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

  incompleteLocationsLast = SortUtils.sortSpecifiedStringKeyLast(Location.incompleteLocationGroupName);
  update() {
    this.viewModel.selectedRequiredLocationIds$.once(ids => this.close(ids));
  }

}
