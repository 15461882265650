import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, take } from 'rxjs/operators';
import { Section } from '../../../../../models/menu/dto/section';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { Menu } from '../../../../../models/menu/dto/menu';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ToastService } from '../../../../../services/toast-service';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { EditSmartPlaylistMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-view-model';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { BsError } from '../../../../../models/shared/bs-error';
import { MediaType } from '../../../../../models/enum/dto/media-type.enum';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../models/shared/display-options';
import { Asset } from '../../../../../models/image/dto/asset';
import { ASSET_RETRY_DELAY, MediaUtils } from '../../../../../utils/media-utils';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { exists } from '../../../../../functions/exists';

@Injectable()
export class ProductGroupingViewModel extends BaseViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private locationDomainModel: LocationDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  public readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;

  private sharedViewModel: EditSmartPlaylistMenuViewModel;

  private _templateMode = new BehaviorSubject<boolean>(false);
  public templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;
  connectToSection = (section: Section) => this._section.next(section as HydratedSection);

  public sectionVariantCountString$ = this.section$.pipe(
    map((section) => {
      return `${section?.enabledVariantIds?.length ?? 0}  Variant`
        .pluralizer()
        .addRule({
          listConnection: section?.enabledVariantIds,
          useApostrophe: false,
          word: 'Variant'
        })
        .pluralize();
    })
  );

  public inStockVariantCountString$ = combineLatest([
    this.menu$,
    this.section$,
    this.locationConfig$
  ]).pipe(
    map(([menu, section, locationConfig]) => {
      const inStockVariantCount = section?.getScopedVisibleLineItemCount(
        section?.products,
        section?.variants,
        menu,
        locationConfig?.priceFormat,
        menu?.isProductMenuWithSectionLevelOverflow()
      ) ?? 0;
      return (section?.showZeroStockItems)
        ? 'Stock Not Required'
        : `${inStockVariantCount} In Stock`;
    })
  );

  public subTitle$ = combineLatest([this.sectionVariantCountString$, this.inStockVariantCountString$]).pipe(
    map(([variantCount, inStockVariantCount]) => `${variantCount} / ${inStockVariantCount}`)
  );

  public hasSmartFilters$ = this.section$.pipe(map(s => s?.hasSmartFilters()));
  public sectionIsHidden$ = this.section$.pipe(map(s => s?.hideSection));
  public sectionAsset$ = this.section$.pipe(
    map(section => section?.image)
  );

  public isTemplatedMenu$ = this.menu$.pipe(
    map(menu => menu?.isTemplatedMenu())
  );

  public isTemplatedSection$ = this.section$.pipe(
    map(section => section?.isTemplatedSection())
  );

  public sectionAssetLoadingOpts$ = new BehaviorSubject<LoadingOptions>(
    ProductGroupingViewModel.getSectionAssetLoadingOpts()
  );

  public sectionIsVisible$ = combineLatest([
    this.menu$,
    this.section$,
    this.locationConfig$
  ]).pipe(
    map(([menu, section, locationConfig]) => {
      const atLeastOneVariantInStock = section?.getScopedVisibleLineItemCount(
        section?.products,
        section?.variants,
        menu,
        locationConfig?.priceFormat,
        menu?.isProductMenuWithSectionLevelOverflow()
      ) > 0;
      return !section?.hideSection
          && (section?.showZeroStockItems || atLeastOneVariantInStock)
          && exists(section?.image);
    })
  );

  init(sharedVM?: EditSmartPlaylistMenuViewModel) {
    if (sharedVM) {
      this.sharedViewModel = sharedVM;
    }
  }

  private static getSectionAssetLoadingOpts(): LoadingOptions {
    // Setup Background Asset loading opts
    const loadingOpts = LoadingOptions.getAssetLoadingOpts();
    loadingOpts.backgroundColor = '#FFF';
    return loadingOpts;
  }
  public hideSectionAssetLoading$ = this.sectionAssetLoadingOpts$.pipe(map(it => !it.isLoading));

  uploadSectionAsset(f: BudsenseFile): Observable<BudsenseFile> {
    return combineLatest([this.menu$, this.section$]).pipe(
      take(1),
      switchMap(([menu, section]) => {
        const loadingOpts = this.sectionAssetLoadingOpts$;
        const lm = 'Uploading Product Grouping Asset';
        if (!loadingOpts.containsRequest(lm)) {
          loadingOpts.addRequest(lm);
          return this.sharedViewModel.uploadSectionAsset(f, menu, section).pipe(
            map((_) => {
              loadingOpts.removeRequest(lm);
              if (f?.isVideo()) {
                f?.replaceWithWebm();
              }
              return f;
            }),
            catchError((err: BsError) => {
              loadingOpts.removeRequest(lm);
              return throwError(err);
            })
          );
        } else {
          const err = BsError.NewLocalBsError(
            'Asset Upload Error',
            'Cannot upload 2 assets at the same time'
          );
          this.toastService.publishError(err);
          return throwError(err);
        }
      })
    );
  }

  public getRefreshedSectionAsset(newFile: BudsenseFile, remainingRetries: number) {
    combineLatest([this.section$, this.templateMode$, this.locationId$]).once(([section, templateMode, locationId]) => {
      const lm = MediaUtils.getRefreshAssetLoadingMessage(remainingRetries);
      const newFileName = newFile?.name;
      if (!this.sectionAssetLoadingOpts$.containsRequest(lm)) {
        this.sectionAssetLoadingOpts$.addRequest(lm);
        const getSection$ = templateMode
          ? this.templateDomainModel.getHydratedSectionTemplate(locationId, section.configurationId, section.id)
          : this.menuDomainModel.getHydratedSection(section.configurationId, section.id);
        getSection$
          .pipe(delay(ASSET_RETRY_DELAY * 1000))
          .subscribe(updatedSection => {
            this.sectionAssetLoadingOpts$.removeRequest(lm);
            const image = updatedSection.image;
            const noImageOrUpdatedFileName = !image || (newFileName !== image?.fileName);
            const authorizedToFetch = remainingRetries > 0;
            if (noImageOrUpdatedFileName && authorizedToFetch) {
              this.getRefreshedSectionAsset(newFile, remainingRetries - 1);
            } else {
              this.setDefaultOptionsForNewFiles(newFile);
            }
          });
      }
    });
  }

  public setDefaultOptionsForNewFiles(file: BudsenseFile) {
    const lm = 'Waiting For Media Update';
    if (!this.sectionAssetLoadingOpts$.containsRequest(lm)) {
      this.sectionAssetLoadingOpts$.addRequest(lm);
      combineLatest([
        this.section$,
        this.sharedViewModel.menu$
      ]).pipe(
        take(1),
        switchMap(([section, menu]) => {
          const setDefaults = (s: Section, duration: number) => {
            menu.options.rotationOrder.set(s.id, s.priority);
            menu.options.rotationInterval.set(s.id, duration);
          };
          const filePipe$ = !!file && file?.getMediaType() === MediaType.WEBM
            ? this.setVideoOrderInterval(file)
            : of (DEFAULT_ROTATION_INTERVAL);
          return filePipe$.pipe(
            map(duration => setDefaults(section, duration)),
            switchMap(_ => this.sharedViewModel.backgroundSaveMenuWithCompletion())
          );
        })
      ).subscribe(_ => {
        this.sectionAssetLoadingOpts$.removeRequest(lm);
      }, (error: BsError) => {
        this.sectionAssetLoadingOpts$.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  setVideoOrderInterval(f: BudsenseFile): Observable<number> {
    return new Observable((subscriber) => {
      const sendAndComplete = (duration: number) => {
        subscriber.next(duration);
        subscriber.complete();
      };
      if (typeof f.url === 'string') {
        const video = document.createElement('video');
        video.src = f.url;
        video.preload = 'metadata';
        video.load();
        video.onloadedmetadata = () => sendAndComplete(Number(video.duration.toFixed(2)));
      } else {
        sendAndComplete(DEFAULT_ROTATION_INTERVAL);
      }
    });
  }

  public deleteMedia(f: Asset) {
    const lm = 'Removing Media';
    this.sharedViewModel.addAssetToRemoveQueue(f);
    const loadingOpts = this.sectionAssetLoadingOpts$;
    loadingOpts.addRequest(lm);
    this.menu$.once(menu => {
      this.menuDomainModel.deleteAsset(f, menu).pipe(delay(5000)).subscribe((_) => {
        loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Successful.', 'Remove Media');
        this.sharedViewModel.removeAsset(f);
        this.sharedViewModel.removeAssetFromRemoveQueue(f);
        // Save menu in background to update rotation order
        this.updateMenuIntervalAfterMediaDeletion();
      }, (error: BsError) => {
        loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        this.sharedViewModel.removeAssetFromRemoveQueue(f);
        throwError(error);
      });
    });
  }

  private updateMenuIntervalAfterMediaDeletion() {
    combineLatest([
      this.section$,
      this.sharedViewModel.menu$
    ]).pipe(
      take(1),
      switchMap(([section, menu]) => {
        menu.options?.rotationInterval?.set(section?.id, 0);
        return this.sharedViewModel.backgroundSaveMenuWithCompletion();
      })
    ).subscribe({
      next: _ => this.toastService.publishSuccessMessage('Successful removal of media.', 'Success'),
      error: (error: BsError) => this.toastService.publishError(error)
    });
  }

  toggleHideSection() {
    combineLatest([
      this.section$,
      this.isTemplatedSection$
    ]).pipe(
      switchMap(([section, isTemplatedSection]) => {
        if (isTemplatedSection) return of(null);
        section.hideSection = !section.hideSection;
        if (section instanceof SectionTemplate) {
          return this.templateDomainModel.updateMenuSectionTemplate(section);
        } else {
          return this.menuDomainModel.updateMenuSection(section);
        }
      }),
      take(1)
    ).subscribe(updatedSection => {
      if (!!updatedSection) {
        this.sharedViewModel.refreshMenu();
        const mess = updatedSection?.hideSection ? 'Section is now hidden' : 'Section set to visible';
        this.toastService.publishSuccessMessage(mess, 'Hide Section Changed');
      }
    }, (error: BsError) => {
      this.toastService.publishError(error);
    });
  }

}
