import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '../../../../../models/company/dto/location';
import { TemplateLocationInfoTextViewModel } from './template-location-info-text-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-template-location-info-text',
  templateUrl: './template-location-info-text.component.html',
  styleUrls: ['./template-location-info-text.component.scss'],
  providers: [TemplateLocationInfoTextViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateLocationInfoTextComponent extends BaseComponent {

  constructor(public viewModel: TemplateLocationInfoTextViewModel) {
    super();
  }

  @Input() requiredLocationIds: number[];
  @Input() location: Location;

}
