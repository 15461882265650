import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../../../../models/base/base-component';
import { Tabbable } from '../../../../models/protocols/tabbable';
import { AddNewLabelViewModel } from '../../add-new-label-modal/add-new-label-view-model';

@Component({
  selector: 'app-create-custom-label-modal',
  templateUrl: './create-custom-label.component.html',
  styleUrls: ['./create-custom-label.component.scss']
})
export class CreateCustomLabelComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: AddNewLabelViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

}
