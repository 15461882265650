import { TypeDefinition } from './type-definition';

export enum SectionColumnConfigDefaultState {
  On = 'On',
  Off = 'Off',
  Auto = 'Auto',
  Disabled = 'Disabled',
  Unknown = '',
}

export class SectionColumnConfigDefaultStateType extends TypeDefinition {

  override value: SectionColumnConfigDefaultState;
  hiddenSelectionOption = this.value === SectionColumnConfigDefaultState.Unknown;

}
