import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-plus-button',
  templateUrl: './plus-button.component.html',
  styleUrls: ['./plus-button.component.scss']
})
export class PlusButtonComponent extends BaseComponent {

  @Input() backgroundColor: string = '#F2F2F2';
  @Input() borderRadius: string = '0.5rem';
  @Input() height: string = '4rem';
  @Input() width: string = '4rem';
  @Input() plusDim: string = '1.5rem';

  constructor() {
    super();
  }

}
