import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { DisplayableProductCard } from '../displayable-product-card';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { ColorUtils } from '../../../../../../../../utils/color-utils';

@Component({
  selector: 'app-card-number',
  templateUrl: './card-number.component.html',
  styleUrls: ['./card-number.component.scss']
})
export class CardNumberComponent extends BaseComponent implements AfterViewInit {

  @Input() card: DisplayableProductCard;
  @Input() index: number;
  @Input() changeBackgroundColor: boolean = true;

  public _width = new BehaviorSubject<string>('0px');
  public width$ = this._width.asObservable();

  constructor(
      public elementRef: ElementRef
  ) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.emitHeight();
  }

  getAutomaticNumberTextColor(): string {
    if (this.changeBackgroundColor && !!this.card?.getCardColor()) {
      return ColorUtils.isDarkColor(this.card?.getCardColor()) ? '#FFFFFF' : ColorUtils.BUDSENSE_BLACK_COLOR;
    } else {
      return ColorUtils.BUDSENSE_BLACK_COLOR;
    }
  }

  override setupBindings() {
    const s = fromEvent(window, 'resize').pipe(
      startWith(false),
      debounceTime(1),
      takeUntil(this.onDestroy)
    ).subscribe(it => { if (it) { this.emitHeight(); } });
    this.pushSub(s);
  }

  emitHeight() {
    this._width.next(`${this.elementRef.nativeElement.scrollHeight}px`);
  }

}
