<div class="budsense-switch-container">
  <div>
    {{ label }}
  </div>

  <label class="grassland-switch">
    <input
      class="grassland-switch-input"
      (change)="toggled.emit(!state)"
      [checked]="state"
      [attr.disabled]="!enabled ? true : null"
      type="checkbox" />
    <span class="grassland-slider" [ngClass]="{ disabled: !enabled }"></span>
  </label>
</div>
