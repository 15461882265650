import { Type } from '@angular/core';
import { Deserializable } from './deserializable';
import { Menu } from '../menu/dto/menu';
import { MenuTemplate } from '../template/dto/menu-template';

export class DeserializeMenuHelper {

    static getPolymorphicMenuObject<T extends Deserializable>(menu: any): T {
        const createPolymorphicObject = (ObjectType: Type<any>): T => {
            return Object.assign(Object.create(ObjectType.prototype), menu);
        };
        if (!!menu?.status) {
          return createPolymorphicObject(MenuTemplate);
        }
        return createPolymorphicObject(Menu);
    }

}
