import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CardTableOptions } from '../../../../models/shared/stylesheet/card-table-options';
import { Subject } from 'rxjs';
import { Card } from '../../../../models/shared/stylesheet/card';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: [
    './card-table.component.scss',
    '../form-group/form-group.component.scss',
    '../drop-down/drop-down.component.scss',
    '../../../product/components/data-table/data-table.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CardTableComponent extends BaseComponent implements OnChanges {

  @Input() public tableOptions: CardTableOptions = new CardTableOptions();
  @Input() public tableData: Card[] = [];
  @Input() public resetTable = new EventEmitter();
  @Input() public updateTableData: EventEmitter<Card[]> = new EventEmitter<Card[]>();

  @Output() checkBoxChanged = new EventEmitter<any>();
  @Output() public cardSelected: EventEmitter<Card> = new EventEmitter<Card>();

  // Data
  public filteredData: Card[];
  public displayedData: Card[];

  // Table Filtering
  public searchQueryString: string = '';
  public searchQueryStringChanged: Subject<string> = new Subject<string>();

  // Pagination
  private page: number = 0;
  private numberOfEntries: number = 20;
  private maxNumberOfPages: number = 5;

  constructor() {
    super();
  }

  override setupViews() {
    this.tableReset();
  }

  override setupBindings() {
    // Bind reset event emitter
    const resetSub = this.resetTable.subscribe((_) => {
      this.tableReset();
    });
    this.pushSub(resetSub);
    // Bind new data
    const updateSub = this.updateTableData.subscribe((td: Card[]) => {
      this.tableData = td;
      this.tableReset();
    });
    this.pushSub(updateSub);
    // Debounce the search input
    const debounceSearch = this.searchQueryStringChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(val => {
        this.searchQueryString = val;
        this.filterChanged();
      });
    this.pushSub(debounceSearch);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardFilters) this.filterChanged();
  }

  // Action

  public cardPressed(card: Card) {
    this.cardSelected.emit(card);
  }

  public getCardClass(index: number): string {
    let cardClass = 'table-incomplete-product-card';
    if ((index + 1) % 4 === 0) {
      cardClass = cardClass + ' mr-0';
    } else if (index % 4 === 0) {
      cardClass = cardClass + ' ml-0';
    }
    return cardClass;
  }

  public clearSearch(): void {
    this.searchQueryString = '';
    this.filterChanged();
  }

  public onQueryStringChange(s: string): void {
    if (s === '') {
      this.searchQueryString = s;
      this.filterChanged();
    } else {
      this.searchQueryStringChanged.next(s);
    }
  }

  public filterChanged(toggle?: boolean): void {
    if (toggle === true || toggle === false) {
      this.tableOptions.checkBox.checked = toggle;
    }
    if (!this.searchQueryString) {
      this.searchQueryString = '';
    }
    // Filter based on query string
    const searchVal = this.searchQueryString.stripWhiteSpaceAndLowerCase();
    this.resetPagination(true);

    // Reset to full data set
    this.filteredData = this.tableData;
    this.filteredData = this.filteredData.filter((d) => {
      return d.label.toString().stripWhiteSpaceAndLowerCase().indexOf(searchVal) !== -1
          || d.text.toString().stripWhiteSpaceAndLowerCase().indexOf(searchVal) !== -1;
    });
    this.checkBoxChanged.emit(this.tableOptions.checkBox.checked);
    if (!!toggle) {
      if (this.tableOptions.checkBox.checked) {
        this.filteredData = this.filteredData.filter(card => {
          return this.tableOptions.checkBoxFilter(card);
        });
      }
    }

    this.paginate();
  }

  public beginAmount(): number {
    return this.page * this.numberOfEntries;
  }

  public endAmount(): number {
    return this.page * this.numberOfEntries + this.numberOfEntries;
  }

  // Pagination

  public nextDisabled(): boolean {
    return this.page + 1 >= this.numberOfPages();
  }

  public previousDisabled(): boolean {
    return this.page === 0;
  }

  public startDisabled(): boolean {
    return this.previousDisabled();
  }

  public endDisabled(): boolean {
    return this.nextDisabled();
  }

  public previousClicked(): void {
    if (this.page > 0) {
      this.page--;
      this.paginate();
    }
  }

  public nextClicked(): void {
    if (this.page + 1 < this.numberOfPages()) {
      this.page++;
      this.paginate();
    }
  }

  public pageClicked(page): void {
    const selected = parseInt(page, 10) - 1;
    this.page = isNaN(selected) ? this.numberOfPages() - 1 : selected;
    this.paginate();
  }

  public endClicked(): void {
    this.page = this.numberOfPages() - 1;
    this.paginate();
  }

  public startClicked(): void {
    this.page = 0;
    this.paginate();
  }

  public isActivePage(page): boolean {
    return this.page === parseInt(page, 10) - 1;
  }

  public pages(): string[] {
    const total = this.numberOfPages();
    const arr = [];
    const sidePadding = 2;
    const maxRun = 3;
    let start;
    let end;

    if (total <= this.maxNumberOfPages) {
      start = 0;
      end = total;
    } else if (this.page >= total - maxRun) {
      start = total - this.maxNumberOfPages;
      end = total;
    } else if (this.page >= maxRun) {
      start = this.page - sidePadding;
      end = start + this.maxNumberOfPages;
    } else if (this.page < maxRun) {
      start = 0;
      end = this.maxNumberOfPages;
    }

    for (let i = start + 1; i < end + 1; i++) {
      arr.push(i.toString());
    }
    return arr;
  }

  private tableReset(): void {
    if (this.tableData) {
      this.resetData();
      this.filterChanged();
      this.paginate();
    }
  }

  private resetData() {
    this.filteredData = JSON.parse(JSON.stringify(this.tableData));
  }

  private resetPagination(persistPage: boolean = false): void {
    if (!persistPage || this.numberOfPages() <= this.page) {
      if (this.numberOfPages() === 0 || !persistPage) {
        this.page = 0;
      } else {
        this.page = this.numberOfPages() - 1;
      }
      this.paginate();
    }
  }

  private numberOfPages(): number {
    if (this.filteredData && this.numberOfEntries) {
      return Math.ceil(this.filteredData.length / this.numberOfEntries);
    } else {
      return 0;
    }
  }

  private paginate(): void {
    const begin = this.beginAmount();
    const end = this.endAmount();
    this.displayedData = this.filteredData.slice(begin, end);
  }

  //  No Results

  getNoResultsTitle() {
    if (this.tableData.length === 0) {
      return `You're all done`;
    } else if (this.searchQueryString !== '') {
      return `No results for "${this.searchQueryString}"`;
    } else {
      return `No results matching the active filters.`;
    }
  }

  getNoResultsBody() {
    if (this.tableData.length === 0) {
      return `There are no incomplete products in your inventory. Good Job!`;
    } else if (this.searchQueryString !== '') {
      return `It doesn't look like this product exists in your inventory. Try a different search term.`;
    } else {
      return `The product you're looking for does not exist in your inventory or it's already completed!`;
    }
  }

}
