import { TypeDefinition } from './type-definition';

export enum PromotionCondition {
  All = 'All',
  Specified = 'Specified',
}

export class PromotionConditionType extends TypeDefinition {

  override value: PromotionCondition;

}
