import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';

@Component({
  selector: 'app-menu-zoom-spacing-options-form',
  templateUrl: './menu-zoom-spacing-options-form.component.html',
  styleUrls: ['./menu-zoom-spacing-options-form.component.scss']
})
export class MenuZoomSpacingOptionsFormComponent extends MenuAdditionalOptionsFormComponent implements OnChanges {

  constructor() {
    super();
  }

  @Input() templateMode = false;
  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;

  menuShouldShowMarginOptions$ = this.menu$.pipe(map(m => m?.isDisplayMenu() || m?.isWebMenu()));

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.menu) this._menu.next(this.menu);
  }

}
