import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CreateViewStackPrintJobViewModel } from './create-view-stack-print-job-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../../../../models/base/base-modal.component';

export type StackType = 'card' | 'label';

@Component({
  selector: 'app-create-view-stack-print-job',
  templateUrl: './create-view-stack-print-job.component.html',
  providers: [CreateViewStackPrintJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateViewStackPrintJobComponent extends BaseModalComponent {

  constructor(
    activeModal: NgbActiveModal,
    public viewModel: CreateViewStackPrintJobViewModel
  ) {
    super(activeModal);
  }

}
