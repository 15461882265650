import { Component } from '@angular/core';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';
import { WebProductTemplatesViewModel } from '../web-product-templates-view-model';

@Component({
  selector: 'app-web-product-template-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: ['../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './web-product-template-list.component.scss']
})
export class WebProductTemplateListComponent extends DisplayableItemListComponent {

  constructor(
    webProductTemplatesViewModel: WebProductTemplatesViewModel // Provided by parent component
  ) {
    super(webProductTemplatesViewModel);
  }

}
