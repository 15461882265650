<div [ngClass]="cardClass" class="card bs-card bs-incomplete-product-card">
  <div class="bs-incomplete-product-card-wrapper">
    <div class="bs-card-icon">
      <img [src]="'assets/icons/red/outline/exclamation-circle.svg'" alt="" />
    </div>

    <div class="bs-card-label">
      <div class="bs-card-label-title">
        {{ card.label }}
      </div>
      <div class="bs-card-label-text">
        {{ card.text }}
      </div>
      <div class="bs-card-label-subtext">
        {{ card.subtext }}
      </div>
    </div>
  </div>
</div>
