import { TypeDefinition } from './type-definition';

export enum SectionLayout {
  Grid = 'SectionLayoutType_Grid',
  List = 'SectionLayoutType_List',
  PricingTierGrid = 'SectionLayoutType_PricingTierGrid',
  ChildVariantList = 'SectionLayoutType_ChildVariantList'
}

export class SectionLayoutType extends TypeDefinition {

  override value: SectionLayout;

  public isList(): boolean {
    return this.value === SectionLayout.List;
  }

  public isGrid(): boolean {
    return this.value === SectionLayout.Grid;
  }

  public isPricingTierGrid(): boolean {
    return this.value === SectionLayout.PricingTierGrid;
  }

  public isChildVariantList(): boolean {
    return this.value === SectionLayout.ChildVariantList;
  }

  public isAnyGrid(): boolean {
    return this.isGrid() || this.isPricingTierGrid();
  }

}
