import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { CardType } from '../../utils/dto/card-type-definition';
import { DefaultPrintStackSize } from '../../enum/dto/default-print-stack-size';

export const DefaultCardCount = '4';
export const DefaultCardStartAt = '1';
export const DefaultCarouselDuration = '10';

export class MenuMetadata implements Deserializable, UniquelyIdentifiable {

  public backgroundOpacity: string = '';
  public cardCount: string = DefaultCardCount;
  public cardType: CardType = CardType.Full;
  public price: string = '';
  public printCardSize: DefaultPrintStackSize;
  public cardStartAt: string = DefaultCardStartAt;
  public hideCardNumber: string = 'false';
  public carouselDuration: string = DefaultCarouselDuration;
  // Section Overflow
  static defaultSectionOverflowDuration: string = '20';
  public sectionOverflowDuration: string = '';

  static defaultCardCount(): string {
    return DefaultCardCount;
  }

  onDeserialize() {
    this.backgroundOpacity = this.backgroundOpacity || '1';
    this.cardType = this.cardType || CardType.Full;
    this.cardCount = this.cardCount || MenuMetadata.defaultCardCount();
    this.price = this.price || '';
    this.cardStartAt = this.cardStartAt || DefaultCardStartAt;
    this.hideCardNumber = this.hideCardNumber || 'false';
    this.carouselDuration = this.carouselDuration || DefaultCarouselDuration;
    this.sectionOverflowDuration = !this.sectionOverflowDuration
      ? MenuMetadata.defaultSectionOverflowDuration
      : this.sectionOverflowDuration;
  }

  getUniqueIdentifier(): string {
    return `${this.backgroundOpacity}
      -${this.cardCount}
      -${this.cardType}
      -${this.price}
      -${this.cardStartAt}
      -${this.hideCardNumber}
      -${this.carouselDuration}`;
  }

}
