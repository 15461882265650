<div
  #container
  fxLayout="row"
  fxLayoutAlign="start center"
  [style.gap.rem]="1"
  class="location-selection-container"
  [class.child]="isChild"
  [class.disabled]="disabled">
  <app-checkbox-only
    *ngIf="!viewOnlyMode"
    [checked]="selected$ | async"
    [disabled]="disabled"
    (valueChanged)="clicked($event)">
  </app-checkbox-only>
  <app-template-location-info-text
    *ngIf="isChild"
    [requiredLocationIds]="updatedRequiredLocationIds"
    [location]="selection">
  </app-template-location-info-text>
  <div *ngIf="showAddedOrRemovedStatus" class="status">
    {{ selection | getAddedOrRemovedStatus : originalRequiredLocationIds : updatedRequiredLocationIds }}
  </div>
</div>
