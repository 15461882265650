<app-loading *rxIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<div class="modal-body sticky max-out" fxLayout="column">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border" fxLayout="row" fxLayoutAlign=" center">
      <div fxFlex class="modal-title">Select Smart Filters</div>
      <div (click)="viewModel.clearSelections()" class="text-link">Clear</div>
    </div>
    <app-search-with-look-ahead
      [disablePopper]="true"
      [placeHolder]="'Search by name'"
      [nLookAheadItems]="1000"
      [lookAheadItems]="viewModel.searchableSmartFilters$ | push"
      [searchTheseProperties]="['name', 'category']"
      (searchText)="viewModel.searchText($event)"
      (lookAheadHits)="viewModel.searchHits($event)">
    </app-search-with-look-ahead>

    <ng-container *rxIf="viewModel.forSmartData$ | push as smartDataType">
      <app-warning-banner *rxIf="viewModel.displaySmartFilterLimitBanner$ | push" class="block mt-16px">
        Smart {{ smartDataType }} are limited to {{ viewModel.maxSFCountOnSmartDA }} Smart Filters.
      </app-warning-banner>

      <div class="smart-data-notice">Only Company Smart Filters can be applied to Smart {{ smartDataType }}.</div>
    </ng-container>

    <mat-tab-group disableRipple animationDuration="0ms" (selectedIndexChange)="viewModel.tabSelected($event)">
      <mat-tab><ng-template mat-tab-label>Custom</ng-template></mat-tab>
      <mat-tab><ng-template mat-tab-label>Default</ng-template></mat-tab>
      <mat-tab><ng-template mat-tab-label>Advanced</ng-template></mat-tab>
    </mat-tab-group>
  </div>

  <div class="sticky-body-container position-relative" fxFlex>
    <div [hidden]="viewModel.loadingSmartFilters$ | push">
      <ng-container *rxFor="let selectable of viewModel?.groupedHits$ | push">
        <app-smart-filter-selection-group
          *rxIf="selectable | isSmartFilterGrouping"
          [grouping]="selectable | castAsSmartFilterGrouping"
          [previouslyAddedIds]="viewModel.previouslyAddedSmartFilterIds$ | push"
          [selectedIds]="viewModel.selectedSmartFilterIds$ | push"
          [forSmartData]="!!(viewModel.forSmartData$ | push)"
          (add)="viewModel.addSelectedId($event)"
          (remove)="viewModel.removeSelectedId($event)"
          (bulkAdd)="viewModel.addSelectedIds($event)"
          (bulkRemove)="viewModel.removeSelectedIds($event)">
        </app-smart-filter-selection-group>
        <app-smart-filter-selection
          *rxIf="selectable | isHydratedSmartFilter"
          [selection]="selectable | castAsHydratedSmartFilter"
          [previouslyAddedIds]="viewModel.previouslyAddedSmartFilterIds$ | push"
          [selectedIds]="viewModel.selectedSmartFilterIds$ | push"
          [forSmartData]="!!(viewModel.forSmartData$ | push)"
          (add)="viewModel.addSelectedId($event)"
          (remove)="viewModel.removeSelectedId($event)">
        </app-smart-filter-selection>
      </ng-container>
    </div>
    <div
      *rxIf="viewModel.loadingSmartFilters$ | push"
      class="loading-dots-container"
      fxLayout="column"
      fxLayoutAlign="center center">
      <app-loading-dots [style.display]="'block'" fxFlex="50"></app-loading-dots>
    </div>
    <div
      *rxIf="viewModel.showNoCustomFilters$ | push"
      class="no-search-results-container"
      fxLayout="column"
      fxLayoutGap="0.5rem">
      <div class="bs-section-list-title">No Custom Smart Filters</div>
      <div class="bs-edit-section-description" *rxIf="(viewModel?.searchableSmartFilters$ | push)?.length === 0">
        You can create a custom smart filter by clicking on the text link below.
      </div>
    </div>
    <div
      *rxIf="viewModel.showNoResults$ | push"
      class="no-search-results-container"
      fxLayout="column"
      fxLayoutGap="0.5rem">
      <div class="bs-section-list-title">No Results</div>
      <div class="bs-edit-section-description">We couldn't find any smart filters matching your search criteria.</div>
    </div>
  </div>

  <div class="sticky-footer-container mt-3" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0.5rem">
    <div fxFlex>
      <div
        [style.width]="'fit-content'"
        [ngbTooltip]="viewModel?.smartFilterCreationTooltip$ | push"
        [container]="'body'">
        <div
          [ngClass]="{ disabled: !(viewModel?.allowSmartFilterCreation$ | push) }"
          [style.cursor]="(viewModel?.allowSmartFilterCreation$ | push) ? 'pointer' : 'not-allowed'"
          (click)="viewModel.openCreateSmartFilterModal()"
          class="text-link">
          Create Smart Filter
        </div>
      </div>
    </div>
    <lib-button-neutral (buttonClicked)="cancel()">Cancel</lib-button-neutral>
    <lib-button-primary
      [disabled]="viewModel.disableAddButton$ | push"
      (buttonClicked)="viewModel.spitOutNewlySelectedIds()">
      Add
    </lib-button-primary>
  </div>
</div>
