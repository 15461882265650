import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayableItem } from '../../displayable-item-preview/displayable-item';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { TemplateDeployedCountIndicatorViewModel } from './template-deployed-count-indicator-view-model';

@Component({
  selector: 'app-template-deployed-count-indicator',
  templateUrl: './template-deployed-count-indicator.component.html',
  styleUrls: ['./template-deployed-count-indicator.component.scss'],
  providers: [TemplateDeployedCountIndicatorViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateDeployedCountIndicatorComponent {

  constructor(public viewModel: TemplateDeployedCountIndicatorViewModel) { }
  @Input() @ViewModelConnector('connectToDisplayableItem') displayableItem: DisplayableItem;

}
