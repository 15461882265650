<!-- Sections -->
<div class="row edit-page-children">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Products</p>
    <p class="bs-edit-section-description">
      {{ sectionViewModel.productSectionDesc$ | push }}
    </p>
    <div [hidden]="sectionViewModel.hideAddProductsButton$ | push" class="bs-edit-section-button-container">
      <lib-button-primary
        (buttonClicked)="openAddProductsModal()"
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        [disabled]="(sectionViewModel.disableAddProductsButton$ | push) || !formPristine"
        [disabledTooltip]="
          (sectionViewModel.showDisabledTooltip$ | push | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        ">
        Add Products
      </lib-button-primary>
      <lib-button-neutral
        *rxIf="sectionViewModel.containsStackedContent$ | push"
        [iconSrc]="'assets/icons/dark/solid/pencil.svg'"
        [disabled]="!(sectionViewModel.supportsStackStyling$ | push) || !formPristine"
        [disabledTooltip]="
          (formPristine | saveChangesTooltip) || (sectionViewModel.disabledStackStylingTooltip$ | push)
        "
        (buttonClicked)="sectionViewModel.viewStackStyling()">
        {{ sectionViewModel.stackStylingButtonText$ | push }}
      </lib-button-neutral>
      <lib-button-destructive
        *ngIf="!(viewModel.sectionHasSmartFilters$ | push) && (viewModel.sectionHasProductsThatCanBeRemoved$ | push)"
        [disabled]="(viewModel.autoSaving$ | push) || (viewModel.removingVariants$ | push) || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        [iconSrc]="'assets/icons/red/outline/trash.svg'"
        [ngClass]="{
          disabled: (viewModel.autoSaving$ | push) || (viewModel.removingVariants$ | push) || !formPristine,
        }"
        (buttonClicked)="promptForRemoveAllProducts()">
        {{ viewModel.removeAllProductButtonText$ | push }}
      </lib-button-destructive>
    </div>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <app-message-banner *ngIf="viewModel.templateMode$ | push" class="mb-16px">
      All variants shown are based on the current location's pricing, inventory, and product attributes. This
      information can differ between locations.
    </app-message-banner>
    <app-menu-section-variant-groups
      [menu]="menuViewModel.menu$ | push"
      [section]="sectionViewModel.section$ | push"
      [primarySorting]="viewModel.primarySortOption$ | push"
      [secondarySorting]="viewModel.secondarySortOption$ | push"
      [autoSaving]="combinedAutoSaving"
      [showZeroStockItems]="sectionViewModel.showZeroStockProducts$ | push"
      [showWillNotAppearOnMenuSection]="menuViewModel.showWillNotAppearOnMenuSection$ | push"
      [productBelowLineDesc]="viewModel.productsBelowLineWillNotAppearDesc$ | push"
      [invalidFormState]="formHasErrors"
      [formPristine]="formPristine"
      [saveSection]="sectionViewModel.saveSection"
      [removeVariantFromSection]="sectionViewModel.removeVariantFromSection"
      [templateMode]="viewModel.templateMode$ | push"
      [isTemplatedSection]="sectionViewModel.isTemplatedSection$ | push"
      [allowAddingProducts]="sectionViewModel.allowAddingProducts$ | push"
      [allowBadgeOverride]="sectionViewModel.allowBadgeOverride$ | push"
      [allowLabelOverride]="sectionViewModel.allowLabelOverride$ | push"
      [allowStyleOverride]="sectionViewModel.allowStyleOverride$ | push"
      (addProductsClicked)="openAddProductsModal()"
      (removeAllProductsClicked)="promptForRemoveAllProducts()"
      (sortedVariantIds)="sortedVariantIds.emit($event)">
    </app-menu-section-variant-groups>
  </div>
</div>

<hr />
