import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast-service';

// Provided by Logged In Scope
@Injectable()
export class PexelsDomainModel extends BaseDomainModel {

  constructor(
    private toastService: ToastService,
  ) {
    super();
  }

}
