<div class="modal-body">
  <div class="modal-title mb-3">You Have Unsaved Changes</div>
  <p class="mb-4">
    You have made changes to this page that haven't been saved. You can either abandon these changes or keep editing
    this page.
  </p>
  <div class="footer-button">
    <button (click)="abandonChanges()" class="bs-button destructive-button">Abandon changes</button>
    <button (click)="keepEditing()" class="bs-button preferred-button">Keep editing</button>
  </div>
</div>
