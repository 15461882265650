import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditSmartPlaylistMenuSectionViewModel } from '../../../../../../menu/components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-section/edit-smart-playlist-menu-section-view-model';
import { EditTemplateMenuSectionViewModel } from '../../../../edit-template-menu-section/edit-template-menu-section-view-model';
import { EditTemplateMenuSectionComponent } from '../../../../edit-template-menu-section/edit-template-menu-section.component';
import { EditTemplateSmartPlaylistMenuSectionCombinedLoadingOptsViewModel } from './edit-template-smart-playlist-menu-section-combined-loading-opts-view-model';
import { EditMenuSectionViewModel } from '../../../../../../menu/viewModels/edit-menu-section-view-model';

@Component({
  selector: 'app-edit-template-smart-playlist-menu-section',
  templateUrl: './edit-template-smart-playlist-menu-section.component.html',
  providers: [
    EditSmartPlaylistMenuSectionViewModel,
    EditTemplateMenuSectionViewModel,
    EditTemplateSmartPlaylistMenuSectionCombinedLoadingOptsViewModel,
    {
      provide: EditMenuSectionViewModel,
      useExisting: EditSmartPlaylistMenuSectionViewModel
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateSmartPlaylistMenuSectionComponent extends EditTemplateMenuSectionComponent {

  constructor(
    public override viewModel: EditTemplateMenuSectionViewModel,
    combinedLoadingOptsViewModel: EditTemplateSmartPlaylistMenuSectionCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
