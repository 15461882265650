import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Checkbox } from '../../../../../models/shared/stylesheet/checkbox';
import { CheckboxContainerOptions } from '../../../../../models/shared/stylesheet/checkbox-container-options';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-checkbox-container',
  templateUrl: './checkbox-container.component.html',
  styleUrls: ['./checkbox-container.component.scss'],
})
export class CheckboxContainerComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @Input() checkBoxes: Checkbox[] = [];
  @Input() checkBoxContainerOptions: CheckboxContainerOptions = new CheckboxContainerOptions();
  @Output() changed = new EventEmitter<Checkbox[]>();

  public cid = Math.random().toString(24);
  public refresh = new EventEmitter<any>();
  public percentageChecked: number;

  constructor() {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setCheckAllState();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setCheckAllState();
  }

  public selectAllClicked(event) {
    this.checkBoxContainerOptions.touched = true;
    const checked = event.target.checked;
    this.percentageChecked = checked ? 1 : 0;
    this.checkBoxes.forEach(item => {
      if (!item.disabled) {
        item.checked = checked;
      }
    });
    this.refresh.emit(true);
    this.emitToOutput();
  }

  public selectSingleOptionClicked() {
    this.checkBoxContainerOptions.touched = true;
    this.setCheckAllState();
    this.emitToOutput();
  }

  public setCheckAllState() {
    let selectedCount = 0;
    this.checkBoxes.forEach((ch) => {
      if (ch.checked) {
        selectedCount++;
      }
    });
    this.percentageChecked = selectedCount / this.checkBoxes.length;
  }

  emitToOutput() {
    this.changed.emit(this.checkBoxes);
  }

}
