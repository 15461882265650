import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { CardTypeSelectionViewModel } from './card-type-selection-view-model';
import { CardType } from '../../../../../../../models/utils/dto/card-type-definition';
import { MenuSubtype } from '../../../../../../../models/enum/dto/menu-subtype';

@Component({
  selector: 'app-card-type-selection',
  templateUrl: './card-type-selection.component.html',
  styleUrls: ['./card-type-selection.component.scss'],
  providers: [CardTypeSelectionViewModel]
})
export class CardTypeSelectionComponent extends BaseComponent implements OnChanges {

  @Input() selectedType: CardType = CardType.Full;
  @Input() menuType: MenuSubtype;
  @Input() disabled: boolean;
  @Output() selected = new EventEmitter<CardType>(true);

  constructor(
    public viewModel: CardTypeSelectionViewModel
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.connectToMenuType(this.menuType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuType) this.viewModel.connectToMenuType(this.menuType);
  }

  handleCardClick(cardValue: CardType) {
    if (!this.disabled) this.selected.emit(cardValue);
  }

}
