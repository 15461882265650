import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { Variant } from '../../../../../../../../models/product/dto/variant';

@Component({
  selector: 'app-label-stack-manual-bulk-print-job-selectable-variant',
  templateUrl: './label-stack-manual-bulk-print-job-selectable-variant.component.html',
  styleUrls: ['./label-stack-manual-bulk-print-job-selectable-variant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackManualBulkPrintJobSelectableVariantComponent {

  @Input() variant: Variant;
  @Input() variantCountMap: Map<string, number>;
  @Input() viewOnly: boolean;
  @Output() updateVariantCountMap = new EventEmitter<number>(true);

}
