import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '@mobilefirstdev/base-angular';

@Injectable({ providedIn: 'root' })
export class ScrollLinkService extends BaseService {

  constructor() {
    super();
  }

  private _scrollPosition = new BehaviorSubject<[number, string]>([0, '']);
  public readonly scrollPosition$ = this._scrollPosition as Observable<[number, string]>;

  resetScrollPosition = () => this._scrollPosition.next([0, '']);
  setScrollPosition = (scrollPosition: number, id: string) => this._scrollPosition.next([scrollPosition, id]);

}
