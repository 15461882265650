import { Component } from '@angular/core';
import { NavItemComponent } from '../nav-item.component';
import { Router } from '@angular/router';
import { AccountNavItemViewModel } from './profile-nav-item-view-model';

@Component({
  selector: 'app-profile-nav-item',
  templateUrl: './profile-nav-item.component.html',
  styleUrls: [
    './profile-nav-item.component.scss',
    '../nav-item.component.scss'
  ],
  providers: [AccountNavItemViewModel]
})
export class ProfileNavItemComponent extends NavItemComponent {

  constructor(
    public override viewModel: AccountNavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

}
