import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Display } from '../../../../models/display/dto/display';
import { Location } from '../../../../models/company/dto/location';

@Component({
  selector: 'app-location-display-list-item',
  templateUrl: './location-display-list-item.component.html',
  styleUrls: ['./location-display-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationDisplayListItemComponent {

  @Input() display: Display;
  @Input() required: boolean;
  @Input() location: Location;
  @Input() displayStatusText: string;
  @Input() forSaveConfirmation = false;

}
