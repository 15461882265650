import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Action } from '../../../../models/shared/stylesheet/action';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent extends BaseComponent {

  @Input() header: string = 'Recent Activity';
  @Input() includeTopDivider: boolean = true;
  @Input() actions: Action[] = [];

  constructor() {
    super();
  }

  actionClicked(a: Action) {
    a.action();
  }

}
