import { Injectable } from '@angular/core';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { DisplayDomainModel } from '../../../../../domainModels/display-domain-model';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Display } from '../../../../../models/display/dto/display';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { TemplateStatus } from '../../../../../models/template/enum/template-status.enum';
import { TemplateCollectionDomainModel } from '../../../../../domainModels/template-collection-domain-model';
import { ToastService } from '../../../../../services/toast-service';

@Injectable()
export class PublishCollectionViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private collectionDomainModel: TemplateCollectionDomainModel,
    private activeModal: NgbActiveModal,
    private toastService: ToastService
  ) {
    super();
  }

  private _collection = new BehaviorSubject<TemplateCollection>(null);
  public collection$ = this._collection as Observable<TemplateCollection>;
  public connectToCollection = ((collection) => this._collection.next(collection));

  public allLocations$ = this.locationDomainModel.allLocations$;
  public allCompanyDisplays$ = this.displayDomainModel.allCompanyDisplays$;

  public requiredDisplays$ = combineLatest([
    this.collection$,
    this.allCompanyDisplays$
  ]).pipe(
    map(([collection, allDisplays ]) => {
      const pendingDisplays = Display.createDisplaysFromPendingDisplay(collection?.pendingDisplay);
      const requiredDisplays = Display.getDisplaysFromIds(collection?.requiredDisplayIds, allDisplays);
      return [...(pendingDisplays || []), ...(requiredDisplays || [])];
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public collectionHasRequiredDisplays$ = this.requiredDisplays$.pipe(
    map((displays) => !!displays?.length)
  );

  public requiredDisplaysGroupedByLocationAndProvince$ = combineLatest([
    this.allLocations$,
    this.requiredDisplays$,
  ]).pipe(
    map(([allLocations, requiredDisplays]) => {
      return Display.groupDisplaysByLocationAndProvince(requiredDisplays, allLocations);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public numberOfProvinces$ = this.requiredDisplaysGroupedByLocationAndProvince$.pipe(
    map((displayLocationGroups) => displayLocationGroups?.provinces?.length)
  );

  public publishCollection() {
    const lm = 'Publishing Template Collection';
    this._loadingOpts.addRequest(lm);
    this.collection$.pipe(
      map((collection) => {
        const updatedCollection = window?.injector?.Deserialize?.instanceOf(TemplateCollection, collection);
        updatedCollection.status = TemplateStatus.Published;
        return updatedCollection;
      }),
      switchMap((c) => this.collectionDomainModel.updateTemplateCollection(c, true)),
      take(1),
      switchMap(() => this.displayDomainModel.getCompanyDisplays()),
      tap(() => this.displayDomainModel.loadDisplaysForCurrentLocation())
    ).subscribe({
      complete: () => {
        this.activeModal.close();
        this.toastService.publishSuccessMessage(
          'Template Collection successfully published.',
          'Publish Template Collection'
        );
      },
      error: (err) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

}
