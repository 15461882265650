import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BulkPrintJobDomainModel } from '../../../../../../../../domainModels/bulk-print-job-domain-model';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { iiif } from '../../../../../../../../utils/observable.extensions';
import { CardStackPrintConfig } from '../../../../../../../../models/automation/card-stack-print-config';
import { StackType } from '../create-view-stack-print-job.component';
import { SmartPrintUtils } from '../../../../../../../../utils/smart-print-utils';

@Injectable()
export class StackSmartPrintOptionsFormViewModel {

  constructor(
    private bulkPrintDomainModel: BulkPrintJobDomainModel,
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel
  ) {
  }

  private PREVIOUS_PRINT_JOBS_LIMIT = 25;

  private _stackType = new BehaviorSubject<StackType>(null);
  public stackType$ = this._stackType as Observable<StackType>;
  connectToStackType = (stackType: StackType) => this._stackType.next(stackType);

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    this.setNewProductStateDeterminantMapFromJob(job);
    this._job.next(job);
  };

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  private _newProductStateDeterminantMap = new BehaviorSubject<Map<string, boolean>>(new Map());
  public newProductStateDeterminantMap$ = this._newProductStateDeterminantMap as Observable<Map<string, boolean>>;

  public previousSuccessfulStackPrintJobs$ = this.bulkPrintDomainModel.activeSuccessfulMenuBulkPrintJobs$.pipe(
    map(jobs => jobs?.slice(0, this.PREVIOUS_PRINT_JOBS_LIMIT))
  );

  public previousPrintJobsTooltip$ = of(
    `Select from the previous ${this.PREVIOUS_PRINT_JOBS_LIMIT} card stack print jobs`
  );

  private cardStackMenuId$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplateId$,
    this.menuDomainModel.activeHydratedMenuId$
  );

  public printConfig$ = combineLatest([this.cardStackMenuId$, this.job$]).pipe(
    map(([cardStackId, job]) => job?.cardStackPrintConfigMap?.get(cardStackId)),
  );

  public stackHasNoPreviousPrintJobs$ = this.previousSuccessfulStackPrintJobs$.pipe(map(jobs => !jobs?.length));

  public preSelectedPreviousStackPrintJobId$ = combineLatest([
    this.viewOnly$,
    this.previousSuccessfulStackPrintJobs$,
    this.printConfig$,
  ]).pipe(
    map(([viewOnly, prevJobs, printConfig]) => {
      const jobThatMatchesConfig = prevJobs?.find(j => j.dateCreated === printConfig?.includeChangesSince);
      const mostRecentJob = prevJobs?.firstOrNull();
      return viewOnly
        ? jobThatMatchesConfig?.id
        : mostRecentJob?.id;
    })
  );

  public hidePreviousStackPrintJobs$ = combineLatest([
    this.viewOnly$,
    this.stackHasNoPreviousPrintJobs$,
    this.printConfig$
  ]).pipe(
    map(([viewOnly, noPreviousJobs, printConfig]) => {
      const wasFirstJob = (viewOnly && printConfig?.includeChangesSince === 0);
      return wasFirstJob || noPreviousJobs;
    })
  );

  public tooltips$ = of(CardStackPrintConfig.includeChangesSinceTooltips);

  public selectNewProductChangeCheckbox$ = this.newProductStateDeterminantMap$.pipe(
    map(determinantMap => determinantMap?.containsValue(true) || undefined)
  );

  public disableNewProductChangeCheckbox$ = combineLatest([this.viewOnly$, this.selectNewProductChangeCheckbox$]).pipe(
    map(([viewOnly, selectNewProductChangeCheckbox]) => {
      return viewOnly || selectNewProductChangeCheckbox;
    })
  );

  public readonly showNewProductsCheckbox$ = SmartPrintUtils.showNewProductsCheckbox$(this.stackType$);
  public readonly showInventoryRestockedCheckbox$ = SmartPrintUtils.showInventoryRestockedCheckbox$(this.stackType$);
  public readonly showPriceChangeCheckbox$ = SmartPrintUtils.showPriceChangeCheckbox$(this.stackType$);
  public readonly showProductInfoChangeCheckbox$ = SmartPrintUtils.showProductInfoChangeCheckbox$(this.stackType$);
  public readonly showCannabinoidChangeCheckbox$ = SmartPrintUtils.showCannabinoidChangeCheckbox$(this.stackType$);
  public readonly showCustomizationChangeCheckbox$ = SmartPrintUtils.showCustomizationChangeCheckbox$(this.stackType$);
  public readonly showMenuChangeCheckbox$ = SmartPrintUtils.showMenuChangeCheckbox$(this.stackType$);

  private setNewProductStateDeterminantMapFromJob(job: BulkPrintJob): void {
    combineLatest([this.newProductStateDeterminantMap$, this.cardStackMenuId$]).once(([
      determinantMap,
      cardStackMenuId
    ]) => {
      const printConfig = job?.cardStackPrintConfigMap?.get(cardStackMenuId);
      if (!printConfig) return;
      const updatedMap = new Map(determinantMap);
      updatedMap.set('inventoryRestock', printConfig.inventoryRestock);
      updatedMap.set('priceChange', printConfig.priceChange);
      updatedMap.set('productInfoChange', printConfig.productInfoChange);
      updatedMap.set('cannabinoidChange', printConfig.cannabinoidChange);
      updatedMap.set('customizationChange', printConfig.customizationChange);
      updatedMap.set('menuChange', printConfig.menuChange);
      this._newProductStateDeterminantMap.next(updatedMap);
    });
  }

  handleNewProductStateDeterminantChanged(inputName: string, value: any): void {
    this.newProductStateDeterminantMap$.once(determinantMap => {
      const updatedMap = new Map(determinantMap);
      updatedMap.set(inputName, value);
      this._newProductStateDeterminantMap.next(updatedMap);
    });
  }

}
