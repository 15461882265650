<app-loading *ngIf="!(viewModel.smartFilter$ | async)" [options]="viewModel?.loadingOpts"> </app-loading>
<div *ngIf="viewModel.smartFilter$ | async" class="added-smart-filter d-flex justify-content-between">
  <div class="smart-filter-info d-flex flex-column">
    <div class="smart-filter-title">{{ viewModel.smartFilterTitle$ | async }}</div>
    <div class="smart-filter-variant-count">{{ viewModel.smartFilterVariantCount$ | async }}</div>
  </div>
  <div class="smart-filter-buttons d-flex flex-row align-items-center">
    <div class="text-link" (click)="editSmartFilter()">
      {{ viewModel.editViewButtonText$ | async }}
    </div>
    <div class="text-link destructive-link" (click)="removeSmartFilter()">Remove</div>
  </div>
</div>
