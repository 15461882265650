import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { EditSpotlightMenuViewModel } from '../edit-spotlight-menu-view-model';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditStaticSectionMenuProductsComponent } from '../../shared/edit-static-section-menu-products/edit-static-section-menu-products.component';
import { EditSpotlightSectionViewModel } from '../edit-spotlight-section-view-model';

@Component({
  selector: 'app-edit-spotlight-menu-products',
  templateUrl: '../../shared/edit-static-section-menu-products/edit-static-section-menu-products.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSpotlightMenuProductsComponent extends EditStaticSectionMenuProductsComponent {

  constructor(
    public override menuViewModel: EditSpotlightMenuViewModel,
    public override sectionViewModel: EditSpotlightSectionViewModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(menuViewModel, sectionViewModel, ngbModal, injector);
  }

  override promptForRemoveAllProducts() {
    const opts = new ConfirmationOptions();
    opts.title = 'Remove All Spotlight Products';
    opts.bodyText = `Are you sure you want to remove all products from this spotlight menu?`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Remove';
    const confirmation = (cont) => {
      if (cont) {
        this.sectionViewModel.removeAllSectionProducts();
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

}
