<lib-reactive-form-group
  #form
  [bindTo]="bindTo"
  [mergeKey]="mergeKey"
  [globalDisabledTooltip]="viewModel.withinCollectionAssignedToDisplay$ | async | collectionDisabledFormControlTooltip"
  [customErrorMessageMap]="viewModel.customErrorMap$ | async">
  <lib-reactive-form-row #loopAndDuration>
    <lib-reactive-form-number
      [hidden]="!(viewModel.usesLoopSystem$ | async)"
      [inputName]="'loop'"
      [label]="viewModel.loopingLabel$ | async"
      [placeholder]="viewModel.loopingPlaceholder$ | async"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="viewModel.usesLoopSystem$ | async"
      [bindOnSubmit]="viewModel.usesLoopSystem$ | async"
      [minValue]="1"
      [disabled]="viewModel.withinCollectionAssignedToDisplay$ | async"
      [tooltip]="viewModel.loopingTooltip$ | async"
      [bindingProperty]="(viewModel.usesLoopSystem$ | async) ? 'interval' : null"
      (valueChanged)="viewModel.connectToNumberOfLoops($event)">
    </lib-reactive-form-number>

    <div
      [style.display]="
        (viewModel.showEnterDisplayDuration$ | async) || (viewModel.showScrollSpeedSlider$ | async) ? null : 'none'
      "
      class="scroll-speed-container">
      <app-segmented-control
        *ngIf="viewModel.isSteadyScroll$ | async"
        [disableAll]="viewModel.withinCollectionAssignedToDisplay$ | async"
        [disableAllTooltip]="
          viewModel.withinCollectionAssignedToDisplay$ | async | collectionDisabledFormControlTooltip
        "
        [options]="viewModel.segmentedControlOptions$ | async"
        (optionSelected)="connectToSegmentedControlSelection($event)">
      </app-segmented-control>
      <app-reactive-form-range-slider
        #rangeSlider
        [hidden]="!(viewModel.showScrollSpeedSlider$ | async)"
        [bindOnSubmit]="viewModel.showScrollSpeedSlider$ | async"
        [inputName]="'rangeSlider'"
        [label]="'Display Duration (scroll speed)'"
        [inlineLabel]="false"
        [options]="viewModel.rangeSliderOptions$ | async"
        [disabled]="
          (viewModel.withinCollectionAssignedToDisplay$ | async) || !(viewModel.showScrollSpeedSlider$ | async)
        "
        [bindingProperty]="'interval'"
        [programmaticallyChangeValue]="viewModel.changeSliderValue$ | async">
      </app-reactive-form-range-slider>
      <lib-reactive-form-number
        #displayDuration
        class="flex-fill"
        [hidden]="!(viewModel.showEnterDisplayDuration$ | async) || (viewModel.showScrollSpeedSlider$ | async)"
        [inputName]="'displayDuration'"
        [label]="'Display Duration (seconds)'"
        [placeholder]="'Enter the display duration'"
        [inlineLabel]="false"
        [integersOnly]="true"
        [required]="viewModel.showEnterDisplayDuration$ | async"
        [bindOnSubmit]="(viewModel.showEnterDisplayDuration$ | async) && !(viewModel.showScrollSpeedSlider$ | async)"
        [minValue]="viewModel.minDisplayDuration$ | async"
        [maxValue]="999999"
        [disabled]="
          (viewModel.withinCollectionAssignedToDisplay$ | async) || (viewModel.showScrollSpeedSlider$ | async)
        "
        [showErrorMessagesOnInitialization]="true"
        [tooltip]="viewModel.displayDurationTooltip$ | async"
        [bindingProperty]="(viewModel.showEnterDisplayDuration$ | async) ? 'interval' : null"
        [programmaticallyChangeValue]="viewModel.changeDisplayDuration$ | async">
      </lib-reactive-form-number>
    </div>

    <lib-reactive-form-number
      [hidden]="!(viewModel.usesLoopSystem$ | async)"
      [inputName]="'loopedInterval'"
      [label]="'Display Duration (seconds)'"
      [placeholder]="'Loading duration...'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="false"
      [bindOnSubmit]="false"
      [disabled]="viewModel.withinCollectionAssignedToDisplay$ | async"
      [editable]="false"
      [tooltip]="viewModel.displayDurationTooltip$ | async"
      [programmaticallyChangeValue]="viewModel.totalRunTime$ | async">
    </lib-reactive-form-number>
  </lib-reactive-form-row>
</lib-reactive-form-group>
