import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationDisplayGrouping } from '../../../../models/display/dto/display';

@Component({
  selector: 'app-location-display-selection-group',
  templateUrl: './location-display-selection-group.component.html',
  styleUrls: ['./location-display-selection-group.component.scss']
})
export class LocationDisplaySelectionGroupComponent {

  @Input() locationGrouping: LocationDisplayGrouping;
  @Input() selectedIds: string[];

  @Output() addDisplays = new EventEmitter<string[]>();
  @Output() removeDisplays = new EventEmitter<string[]>();

}
