import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, } from 'rxjs/operators';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { RouteUtils } from '../../../../../utils/route-utils';
import { NavItem } from '../nav-item/models/nav-item';
import { NavSubItem } from './models/nav-sub-item';

@Injectable()
export class NavSubItemViewModel extends BaseViewModel {

  protected _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());

  protected navItem = new BehaviorSubject<NavItem>(null);
  protected subItem = new BehaviorSubject<NavSubItem>(null);
  public isNavItemActive$ = combineLatest([
    this.navItem,
    this.subItem,
    this.currentNavUrl$
  ]).pipe(
    debounceTime(1),
    map(([nav, sub, url]) => {
      const isCurrentPath = url.indexOf(`${nav?.routerPath}/${sub?.relativeRouterPath}`) > -1;
      const fragment = RouteUtils.getFragmentFromUrl(url);
      if (!!nav && !!sub && isCurrentPath) {
        nav?.saveSubPosition(sub, fragment);
        this.checkToSaveSubFragment(nav, sub, url);
      }
      return isCurrentPath;
    }),
  );

  constructor(
    protected router: Router
  ) {
    super();
  }

  connectToNavItem(item: NavItem) {
    this.navItem.next(item);
  }

  connectToSubNavItem(item: NavSubItem) {
    this.subItem.next(item);
  }

  connectToCurrentNavUrl(url: string) {
    this._currentNavUrl.next(url);
  }

  private checkToSaveSubFragment(nav: NavItem, sub: NavSubItem, url: string): void {
    const segments = this.router.parseUrl(url)?.root?.children[PRIMARY_OUTLET]?.segments;
    const strippedUrl = `/${segments?.join('/')}`; // url without fragments or parameters
    const shouldSaveSubFragment = strippedUrl === `${nav?.routerPath}/${sub?.relativeRouterPath}`;
    if (shouldSaveSubFragment) {
      const fragment = RouteUtils.getFragmentFromUrl(url);
      sub?.saveSubFragment(fragment);
    }
  }

}
