<app-menu-titles-form
  *ngIf="!(viewModel.containsStackedContent$ | push)"
  [templateMode]="templateMode"
  [mergeKey]="mergeKey"
  [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
  [bindTo]="viewModel.menu$ | push">
</app-menu-titles-form>

<app-menu-additional-options-accordion
  [accordionTitle]="'Additional Menu Information' | replaceMenuWithTemplate : templateMode"
  [showUpperHr]="true"
  [showBottomHr]="!(viewModel.isPlaylist$ | push)"
  [showErrorIndicator]="detailsForm.hasError$ | push">
  <app-menu-details-form
    #detailsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [bindTo]="viewModel.menu$ | push"
    (width)="iFrameWidthChanged.emit($event)"
    (height)="iFrameHeightChanged.emit($event)"
    (printCardPaperSizeChanged)="viewModel.connectToPrintCardPaperSize($event)"
    (orientationChanged)="orientationChanged.emit($event)">
  </app-menu-details-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  *rxIf="viewModel.showPrintOptions$ | push"
  [accordionTitle]="'Print Options'"
  [showBottomHr]="!(viewModel.isPrintLabelMenu$ | push)"
  [showErrorIndicator]="printOptionsForm.hasError$ | push">
  <app-menu-print-options-form
    #printOptionsForm
    [templateMode]="templateMode"
    [isTemplatedMenu]="viewModel?.isTemplatedMenu$ | push"
    [singlePageMenuSwitchDisabled]="viewModel?.singlePageMenuSwitchDisabled$ | push"
    [needsToBeSinglePage]="viewModel.needsToBeSinglePage$ | push"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [bindTo]="viewModel.menu$ | push">
  </app-menu-print-options-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  *ngIf="viewModel.showHideOptions$ | push"
  [accordionTitle]="'Hide/Show Options'"
  [showBottomHr]="!(viewModel.containsStackedContent$ | push)"
  [showErrorIndicator]="showHideOptionsForm.hasError$ | push">
  <app-menu-hide-show-options-form
    #showHideOptionsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [hideSaleDisabled]="viewModel.hideSaleDisabled$ | push"
    [showAlternativeLogoDisabled]="viewModel.showAlternativeLogoDisabled$ | push"
    [bindTo]="viewModel.menu$ | push"
    [hasAltLogo]="viewModel.hasAltLogo$ | push">
  </app-menu-hide-show-options-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  *ngIf="viewModel.showLayoutOptions$ | push"
  [showBottomHr]="viewModel.showZoomOptions$ | push"
  [accordionTitle]="'Layout Options'"
  [showErrorIndicator]="layoutOptionsForm.hasError$ | push">
  <app-menu-layout-options-form
    #layoutOptionsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [bindTo]="viewModel.menu$ | push"
    [colorPalette]="viewModel.colorPalette$ | push">
  </app-menu-layout-options-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  *ngIf="viewModel.showZoomOptions$ | push"
  [accordionTitle]="'Zoom & Spacing Options'"
  [showBottomHr]="viewModel.isPrintMenu$ | push"
  [showErrorIndicator]="spacingOptionsForm.hasError$ | push">
  <app-menu-zoom-spacing-options-form
    #spacingOptionsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [bindTo]="viewModel.menu$ | push">
  </app-menu-zoom-spacing-options-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  *ngIf="viewModel.showMediaOptions$ | push"
  [accordionTitle]="'Media Options'"
  [showUpperHr]="true"
  [showErrorIndicator]="mediaOptionsForm.hasError$ | push">
  <app-menu-media-options-form
    #mediaOptionsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [bindTo]="viewModel.menu$ | push">
  </app-menu-media-options-form>
</app-menu-additional-options-accordion>
