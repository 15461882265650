import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { map } from 'rxjs/operators';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { ToastService } from 'src/app/services/toast-service';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';

@Component({
  selector: 'app-duplicate-smart-filter-modal',
  templateUrl: './duplicate-smart-filter-modal.component.html',
  styleUrls: ['./duplicate-smart-filter-modal.component.scss']
})
export class DuplicateSmartFilterModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    private domainModel: SmartFiltersDomainModel,
    private toastService: ToastService,
    private locationDomainModel: LocationDomainModel
  ) {
    super(activeModal);
  }

  public locations$ = this.locationDomainModel.allLocations$;
  public currentLocationId$ = this.locationDomainModel.locationId$;

  // Loading
  public loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public loadingOpts$ = this.loadingOpts.asObservable();
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));

  private _smartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public currentLocationId: number = null;
  public smartFilter$ = combineLatest([
    this._smartFilter.notNull(),
    this.currentLocationId$
  ]).pipe(
    map(([smartFilter, currentLocationId]) => {
      smartFilter.locationId = currentLocationId;
      this.currentLocationId = currentLocationId;
      return smartFilter;
    })
  );

  connectToSmartFilter = (smartFilter: HydratedSmartFilter) => this._smartFilter.next(smartFilter);

  duplicateSmartFilter(duplicatedSmartFilter: HydratedSmartFilter) {
    if (duplicatedSmartFilter.locationId === this.currentLocationId) {
      this.createForCurrentLocation(duplicatedSmartFilter);
    } else {
      this.createForDifferentLocation(duplicatedSmartFilter);
    }
  }

  createForCurrentLocation(smartFilter: HydratedSmartFilter) {
    const lm = 'Duplicating your smart filter';
    this.loadingOpts.addRequest(lm);
    this.domainModel.createSmartFilter(smartFilter).subscribe(_ => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Your smart filter was duplicated', 'Success!');
      this.activeModal.close();
    }, err => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishErrorMessage(err, 'Error');
    });
  }

  createForDifferentLocation(smartFilter: HydratedSmartFilter) {
    const lm = 'Duplicating your smart filter';
    this.loadingOpts.addRequest(lm);
    this.domainModel.createSmartFilterForDifferentLocation(smartFilter).subscribe(_ => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Your smart filter was duplicated', 'Success!');
      this.activeModal.close();
    }, err => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishErrorMessage(err, 'Error');
    });
  }

}
