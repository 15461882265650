<div
  class="card bs-card bs-theme-preview-card"
  [class.selected]="card?.selected"
  [ngClass]="viewModel.cardClasses$ | async">
  <div class="bs-theme-preview-card-wrapper">
    <!--  Theme Preview  -->
    <div class="bs-theme-preview-image">
      <app-asset
        [asset]="card.asset"
        [borderRadius]="'0.25rem'"
        [loadingTechnique]="ClientTypes.LoadingTechnique.SHIMMER"
        [scaleFit]="viewModel.themeHasNonStandardDimensions$ | push"
        [objectPosition]="'top'"></app-asset>
      <app-private-theme-indicator
        *ngIf="viewModel.isThemePrivate$ | push"
        class="private-theme-indicator"></app-private-theme-indicator>
    </div>
    <!--  Theme information  -->
    <div class="bs-card-label">
      <div class="bs-card-label-text">
        {{ card.text }}
      </div>
      <div class="bs-card-label-subtext">
        <div (click)="handleCardButtonClicked($event)" class="text-link">See Details</div>
      </div>
    </div>
    <div class="selected-icon">
      <img [src]="'assets/icons/green/solid/check-circle.svg'" alt="" />
    </div>
  </div>
</div>
