import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';

// Provided by Logged In Scope
@Injectable()
export class HelpDomainModel extends BaseDomainModel {

  constructor() {
    super();
  }

}
