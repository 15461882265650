<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)"
  (disableSubmit$)="disableSubmit.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'title'"
      [label]="'Section Title'"
      [placeholder]="'Enter a title for this section.'"
      [inlineLabel]="false"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'title'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'sectionType'"
      [label]="'Section Type'"
      [placeholder]="'Select the type of section to create'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [disabledTooltip]="''"
      [dropdowns]="viewModel.sectionTypes$ | async"
      [bindingProperty]="'sectionType'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      #widthPercentage
      [inputName]="'width'"
      [label]="'Media Width'"
      [placeholder]="'Enter media width (%)'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="20"
      [maxValue]="100"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'metadata.width'"
      [customValueParser]="viewModel.parseAsString"
      (valueChanged)="viewModel.connectToShowRepeat($event)">
    </lib-reactive-form-number>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'alignment'"
      [label]="'Media Alignment'"
      [placeholder]="'Select Media Alignment'"
      [inlineLabel]="false"
      [disabled]="isTemplatedSection"
      [required]="true"
      [tooltip]="'Determines where the media should be horizontally aligned.'"
      [dropdowns]="viewModel.mediaAlignments$ | async"
      [bindingProperty]="'metadata.alignment'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-switch
      #repeat
      [hidden]="!(viewModel.showRepeat$ | async)"
      [inputName]="'repeat'"
      [selectedValue]="'true'"
      [notSelectedValue]="'false'"
      [disabled]="isTemplatedSection"
      [tooltip]="'If enabled, the media will repeat. The width must be less than 100% for this to appear on the menu.'"
      [bindingProperty]="'metadata.repeat'">
      Repeat Media
    </lib-reactive-form-switch>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
