import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMarketingPlaylistMenuViewModel } from './edit-template-marketing-playlist-menu-view-model';
import { EditPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu-view-model';
import { EditTemplateMarketingPlaylistMenuCombinedLoadingOptsViewModel } from './edit-template-marketing-playlist-menu-combined-loading-opts-view-model';
import { EditTemplateMarketingMenuComponent } from '../edit-template-marketing-menu.component';

@Component({
  selector: 'app-edit-template-marketing-playlist-menu',
  templateUrl: './edit-template-marketing-playlist-menu.component.html',
  styleUrls: ['./edit-template-marketing-playlist-menu.component.scss'],
  providers: [
    EditTemplateMarketingPlaylistMenuViewModel,
    EditPlaylistMenuViewModel,
    EditTemplateMarketingPlaylistMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingPlaylistMenuComponent extends EditTemplateMarketingMenuComponent {

  constructor(
    public override viewModel: EditTemplateMarketingPlaylistMenuViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateMarketingPlaylistMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
