<div>
  <ng-container *ngFor="let defaults of viewModel.defaultColumnOptions$ | async">
    <app-popper-selection [data]="defaults" (selected)="selected.emit(defaults)">
      {{ defaults.name }}
    </app-popper-selection>
  </ng-container>

  <hr *ngIf="(viewModel.defaultColumnOptions$ | async).length > 0" class="mt-0 mr-16px mb-0 ml-16px" />

  <app-popper-selection class="theme-defaults" (selected)="emitThemeDefault()"> Theme defaults </app-popper-selection>
</div>
