<app-display-menu-section-body-container [nMenus]="nMenus" [index]="index">
  <div class="section-body-container">
    <app-display-menu-section-options
      [menu]="menu"
      [productOverflowTitle]="productOverflowTitle"
      [mergeKey]="mergeKey"
      [bindTo]="bindTo"
      [loopDuration]="loopDuration"
      [nMenus]="nMenus"
      [menuIsPartOfCollection]="menuIsPartOfCollection"
      (openInformationModal)="openInformationModal.emit($event)">
    </app-display-menu-section-options>
    <div class="date-times-container">
      <div *rxIf="viewModel.menuHasSpecificDateTimesConfigured$ | push" class="is-scheduled-message-banner">
        <img
          [style.width.rem]="2"
          [style.height.rem]="2"
          [src]="'assets/icons/dark/solid/information-circle.svg'"
          [ngbTooltip]="'This menu will only appear on the display for the specific dates/times set'"
          alt="" />
        <div class="is-scheduled-message">Menu has specific dates/times configured</div>
      </div>
      <lib-button-neutral
        class="edit-dates-times-button"
        [disabled]="!formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="viewModel.openEditSpecificDateTimeModal()">
        {{ (viewModel.menuHasSpecificDateTimesConfigured$ | push) ? 'Edit' : 'Add' }} Dates/Times
      </lib-button-neutral>
    </div>
  </div>
</app-display-menu-section-body-container>
