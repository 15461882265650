import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../../../../models/product/dto/variant';
import { Section } from '../../../../../../models/menu/dto/section';
import { Menu } from '../../../../../../models/menu/dto/menu';

@Pipe({
  name: 'showProductPreviewRedBorder'
})
export class ShowProductPreviewRedBorderPipe implements PipeTransform {

  transform(
    variant: Variant,
    section: Section,
    productVariants: Variant[],
    menu: Menu,
    includedOnMenu: boolean
  ): boolean {
    if (menu?.containsStackedContent()) {
      return !includedOnMenu;
    }
    const variantOutOfStock = !variant?.inStock();
    const showOutOfStockItems = section?.showZeroStockItems;
    const productHasMoreThanOneVariant = productVariants?.length > 1;
    return !includedOnMenu || (!showOutOfStockItems && variantOutOfStock && productHasMoreThanOneVariant);
  }

}
