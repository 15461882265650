import { Pipe, PipeTransform } from '@angular/core';
import { Display } from '../../../models/display/dto/display';

@Pipe({
  name: 'getDisplayGroupSelectedCount'
})
export class GetDisplayGroupSelectedCountPipe implements PipeTransform {

  transform(selectedIds: string[], displaysInList: Display[]): number {
    return displaysInList?.filter(display => selectedIds?.includes(display?.id))?.length;
  }

}
