import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { map, shareReplay } from 'rxjs/operators';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';
import { Menu } from '../../../../../models/menu/dto/menu';

@Injectable()
export class PrintJobSelectMenusViewModel {

  constructor(private menuDomainModel: MenuDomainModel) {
  }

  private locationPrintAndPrintReportMenus$ = this.menuDomainModel.currentLocationPrintMenusAndPrintReportMenus$;

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    this._job.next(job);
    this._selectedMenuIds.next(job?.menuIds ?? null);
  };

  private _selectedMenuIds = new BehaviorSubject<string[] | null>(null);
  public selectedMenuIds$ = this._selectedMenuIds as Observable<string[] | null>;

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public inactiveMenuCount$ = this.locationPrintAndPrintReportMenus$.pipe(
    map(menus => menus?.filter(menu => !menu.active)?.length)
  );

  public inactiveToggleLabel$ = this.inactiveMenuCount$.pipe(map(count => `Show Inactive Menus (${count})`));

  private _showInactiveMenus = new BehaviorSubject<boolean>(false);
  public showInactiveMenus$ = this._showInactiveMenus as Observable<boolean>;
  connectToShowInactiveMenus = (showInactiveMenus: boolean) => this._showInactiveMenus.next(showInactiveMenus);

  public filteredMenus$ = combineLatest([
    this.locationPrintAndPrintReportMenus$,
    this.showInactiveMenus$,
    this.viewOnly$,
    this.job$
  ]).pipe(
    map(([allMenus, showInactiveMenus, viewOnly, printJob]) => {
      if (!viewOnly) {
        return allMenus?.filter(menu => showInactiveMenus || menu.active);
      }
      const printMenusOnJob = allMenus?.filter(menu => printJob?.menuIds?.includes(menu?.id)) ?? [];
      const deletedMenus = this.constructDeletedMenus(printJob?.deletedMenuNames);
      return deletedMenus.concat(printMenusOnJob);
    }),
    shareReplay({ bufferSize: 1, refCount: true } )
  );

  public menusAsSelectionItem$ = this.filteredMenus$.pipe(
    map(menus => {
      return new (class implements HasChildIds {

        getId = (): string => '';
        getChildIds = (): string[] => menus?.map(menu => menu?.getId()) ?? [];

      })();
    })
  );

  public subtitle$ = this.viewOnly$.pipe(
    map(viewOnly => {
      return viewOnly
        ? 'See which menus were included in the print job.'
        : 'Choose which print menus you want included in this batch.';
    })
  );

  private constructDeletedMenus(deletedMenuNames: string[]): Menu[]  {
    return deletedMenuNames?.map(name => {
      const deletedMenu = new Menu();
      deletedMenu.name = name;
      deletedMenu.id = '-1';
      return deletedMenu;
    }) ?? [];
  }

  public bulkAddMenuIdsToJob(ids: string[]): void {
    this.selectedMenuIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.concat(ids)?.unique() ?? ids;
      this._selectedMenuIds.next(newIds);
    });
  }

  public bulkRemoveMenuIdsFromJob(ids: string[]): void {
    this.selectedMenuIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.filter(prevId => !ids.includes(prevId)) ?? [];
      this._selectedMenuIds.next(newIds);
    });
  }

  public addMenuIdToJob(id: string): void {
    this.selectedMenuIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.concat(id) ?? [id];
      this._selectedMenuIds.next(newIds);
    });
  }

  public removeMenuIdFromJob(id: string): void {
    this.selectedMenuIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.filter(prevId => prevId !== id) ?? [];
      this._selectedMenuIds.next(newIds);
    });
  }

}
