import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Display } from '../models/display/dto/display';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { ApiPagination } from '../models/shared/api-pagination';

export const DEFAULT_DISPLAY_PAGINATION_COUNT = 2;

@Injectable({ providedIn: 'root' })
export class DisplayAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Display';

  // Display

  public WriteDisplay(display: Display): Observable<Display> {
    const url = Endpoints.WriteDisplay();
    return this.apiClient.postObj(Display, url, display).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'WriteDisplay', err));
        return throwError(err);
      })
    );
  }

  public UpdateDisplay(display: Display): Observable<Display> {
    const updateDataUrl = Endpoints.UpdateDisplay();
    const getDataUrl = Endpoints.GetDisplay(display.id, false);
    return this.apiClient.postRecursiveObj<Display, Display>(Display, updateDataUrl, getDataUrl, display).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateDisplay', err));
        return throwError(err);
      })
    );
  }

  public DeleteDisplay(display: Display): Observable<string> {
    const url = Endpoints.DeleteDisplay();
    return this.apiClient.deleteStr(url, display).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteDisplay', err));
        return throwError(err);
      })
    );
  }

  public GetDisplay(id: string, ignoreLastSession: boolean, lastModified?: number): Observable<Display> {
    const url = Endpoints.GetDisplay(id, ignoreLastSession, lastModified);
    return this.apiClient.recursiveGetObject<Display>(Display, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplay', err));
        return throwError(err);
      })
    );
  }

  public GetLocationDisplays(
    locId: number,
    pagination: ApiPagination = null
  ): Observable<Display[]> {
    const url = Endpoints.GetLocationDisplays(locId);
    if (!pagination) {
      pagination = new ApiPagination(DEFAULT_DISPLAY_PAGINATION_COUNT);
    }
    return this.apiClient.recursiveGet<Display>(Display, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationDisplays', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyDisplays(): Observable<Display[]> {
    const url = Endpoints.GetCompanyDisplays();
    return this.apiClient.getArr(Display, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyDisplays', err));
        return throwError(err);
      })
    );
  }

  public UpdateDisplayPriorities(displays: Display[]): Observable<Display[]> {
    const url = Endpoints.UpdateDisplayPriorities();
    return this.apiClient.postArr(Display, url, displays).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateDisplayPriorities', err));
        return throwError(err);
      })
    );
  }

}
