import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Theme } from '../../../../../models/menu/dto/theme';
import { SegmentedControlOption } from '../../../../../models/shared/stylesheet/segmented-control-option';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { ThemeDetailsModalViewModel } from './theme-details-modal-view-model';

@Component({
  selector: 'app-theme-details-modal',
  templateUrl: './theme-details-modal.component.html',
  styleUrls: ['./theme-details-modal.component.scss'],
  providers: [ThemeDetailsModalViewModel]
})
export class ThemeDetailsModalComponent extends BaseModalComponent implements AfterViewInit {

  // View References
  @ViewChild('modalBody') modalBody: HTMLDivElement;

  public scrollToCardId: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public viewModel: ThemeDetailsModalViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override setupViews() {
    this.viewModel.setupTheme();
    this.viewModel.setupLabelGroup();
    this.viewModel.setupOrientationSegmentedControl();
    this.viewModel.setupThemeCards();
  }

  setTheme(t: Theme, menuType: MenuType) {
    this.viewModel.connectToTheme(t);
    this.viewModel.connectToMenuType(menuType);
  }

  setOrientation(o: Orientation) {
    this.viewModel.connectToInitialOrientation(o);
  }

  orientationSegmentedControlOptionSelected(updatedOpts: SegmentedControlOption[]) {
    this.viewModel.connectToSelectedOrientationControl(updatedOpts[0]);
    this.viewModel.setupThemeCards();
    setTimeout(() => {
      // Let new view layout before scrolling to card
      this.viewModel.previewCards$.once(previewCards => {
        this.scrollToCardId.emit(previewCards.find(c => c.selected)?.id);
      });
    }, 50);
  }

}
