import { Injectable } from '@angular/core';
import { ImageAPI } from '../api/image-api';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { MenuDomainModel } from '../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../domainModels/template-domain-model';
import { Menu } from '../models/menu/dto/menu';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { combineLatest, defer, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { DateUtils } from '../utils/date-utils';
import { PrintPDF } from '../models/menu/shared/print-pdf';
import { RequestPDF } from '../models/image/requests/request-pdf';
import { iiif } from '../utils/observable.extensions';
import { PreviewService } from './preview-service';
import { MenuPreviewService } from './menu-preview.service';
import { PreviewOf } from '../models/enum/shared/preview-of';
import { ChangesRequiredForPreviewService } from './changes-required-for-preview.service';

// Provided by Logged In Scope
@Injectable()
export class PrintPDFService extends PreviewService {

  constructor(
    private imageAPI: ImageAPI,
    private templateDomainModel: TemplateDomainModel,
    private menuPreviewService: MenuPreviewService,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    changesRequiredForPreviewService: ChangesRequiredForPreviewService
  ) {
    super(locationDomainModel, menuDomainModel, changesRequiredForPreviewService);
  }

  public readonly menuTemplates$ = this.templateDomainModel.menuTemplates$;

  private readonly activeHydratedMenuId$ = this.menuDomainModel.activeHydratedMenuId$;
  private readonly activeMenuTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  private readonly activeId$ = combineLatest([
    this.activeMenuTemplateId$,
    this.activeHydratedMenuId$
  ]).pipe(
    map(([templateId, menuId]) => templateId || menuId),
    distinctUntilChanged(),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public readonly isLoadingActivePrintMenuPDF$ = combineLatest([
    this.activeId$,
    this.locationId$,
    this.loadingPreviewMap$
  ]).pipe(
    map(([activeId, locId, loadingMap]) => {
      return loadingMap?.get(PreviewService.getPreviewKey(activeId, locId, PreviewOf.PrintMenuPDF));
    }),
    distinctUntilChanged(),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public activePrintMenuPDF$ = combineLatest([
    this.activeId$,
    this.locationId$,
    this.previews$
  ]).pipe(
    map(([activeId, locId, pdfs]) => {
      return pdfs?.get(PreviewService.getPreviewKey(activeId, locId, PreviewOf.PrintMenuPDF));
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  activePrintMenuPDFUrl$ = this.activePrintMenuPDF$.pipe(
    switchMap(activePreview => activePreview?.preview?.getAssetUrl(AssetSize.Original)?.srcUrl ?? of([null, ''])),
    map(([_, url]) => url),
    shareReplay({bufferSize: 1, refCount: true})
  );

  activePrintMenuPDFTimeLabel$ = this.activePrintMenuPDF$.pipe(
    map(p => DateUtils.formatCreatedAt(p?.preview?.timestamp)),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public fetchPrintMenuPDF(menu: Menu | MenuTemplate, forceUpdate: boolean): Observable<PrintPDF> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.PrintMenuPDF);
        const reqPDF = new RequestPDF(locId, menu?.id);
        const alreadyFetching$ = defer(() => this.addRequestToQueue(key));
        const waitForFetch$ = defer(() => this.waitForFetch(key));
        const goFetch$ = defer(() => this.fetchPrintPDF(menu, locId, forceUpdate, reqPDF));
        this.setLoadingStateFor(key, true);
        return iiif(alreadyFetching$, waitForFetch$, goFetch$);
      })
    );
  }

  /**
   * Whenever a print pdf is generated for a menu using the forceUpdate flag, then the backend will also generate
   * a jpg screenshot as well. When this happens, replace the menu preview with the new jpg screenshot.
   */
  private fetchPrintPDF(menu: Menu, locId: number, forceUpdate: boolean, reqPDF: RequestPDF): Observable<PrintPDF> {
    this.deletePDF(menu, locId);
    const fetchTemplatePDF$ = defer(() => this.imageAPI.GetPrintPDF(locId, forceUpdate, undefined, menu?.id));
    const fetchMenuPDF$ = defer(() => this.imageAPI.GetPrintPDF(locId, forceUpdate, menu?.id));
    return iif(() => menu instanceof MenuTemplate, fetchTemplatePDF$, fetchMenuPDF$).pipe(
      map(rawMetadata => {
        const pdfKey = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.PrintMenuPDF);
        const Deserialize = window?.injector?.Deserialize;
        const printPDF = Deserialize?.instanceOf(PrintPDF, new PrintPDF(menu?.id, rawMetadata, locId));
        this.storePDF(pdfKey, printPDF);
        this.setLoadingStateFor(pdfKey, false);
        this.removeRequestFromQueue(pdfKey);
        return printPDF;
      }),
      tap(_ => {
        if (forceUpdate) {
          // Remove existing menu preview snapshot from cache and memory
          // Grab updated snapshot from API - no compute since PDF job above generates updated snapshot
          const snapshotKey = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.MenuImage);
          this.menuPreviewService.removePreview(snapshotKey);
          this.menuPreviewService.removePreviewFromCache(snapshotKey);
          this.menuPreviewService.getMenuPreview(menu, true, null, null, false, true).pipe(take(1)).subscribe();
        }
      })
    );
  }

  private deletePDF(menu: Menu, locId: number) {
    if (!!menu) {
      this.previews$.once(pdfs => {
        const updatedPrintPDFs = pdfs?.shallowCopy() || new Map();
        const key = PreviewService.getPreviewKey(menu?.id, locId, PreviewOf.PrintMenuPDF);
        updatedPrintPDFs?.delete(key);
        this._previews.next(updatedPrintPDFs);
      });
    }
  }

  private storePDF(key: string, printPDF: PrintPDF) {
    if (!!key && !!printPDF) {
      this.previews$.once(pdfs => {
        const updatedPrintPDFs = pdfs?.shallowCopy() || new Map();
        updatedPrintPDFs?.set(key, printPDF);
        this._previews.next(updatedPrintPDFs);
      });
    }
  }

}
