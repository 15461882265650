import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { map } from 'rxjs/operators';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { StackPrintJobAppliedProductsViewModel } from './stack-print-job-applied-products-view-model';
import { CardStackPrintConfig } from '../../../../../../models/automation/card-stack-print-config';

@Injectable()
export class BulkCardStackPrintJobAppliedProductsViewModel extends StackPrintJobAppliedProductsViewModel {

  constructor(
    productDomainModel: ProductDomainModel,
    private menuDomainModel: MenuDomainModel,
  ) {
    super(productDomainModel);
  }

  public locationMenus$ = this.menuDomainModel.currentLocationMenus$;

  public cardStackPrintConfigs$ = this.job$.pipe(
    map(job => job?.getStackPrintConfigMap() || new Map<string, CardStackPrintConfig>())
  );

}
