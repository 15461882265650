import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { EditSpecificDateTimeModalComponent } from '../views/display/components/modals/edit-specific-date-time-modal/edit-specific-date-time-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { CompactMenu } from '../models/menu/dto/compact-menu';
import { DisplayMenuOptions } from '../models/display/shared/display-menu-options';
import { TemplateCollection } from '../models/template/dto/template-collection';
import { Display } from '../models/display/dto/display';
import { Observable } from 'rxjs';

export class ModalEditSpecificDateTime {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    display: Display|TemplateCollection,
    compactMenu: CompactMenu,
    displayOptions: DisplayMenuOptions,
    saveFunc: (display: TemplateCollection|Display) => Observable<Display[]>,
    onClose?: () => void
  ): void {
    const modalRef = ngbModal.open(
      EditSpecificDateTimeModalComponent,
      ModalUtils.displayModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as EditSpecificDateTimeModalComponent;
    compInstance.setDisplay(display);
    compInstance.setCompactMenu(compactMenu);
    compInstance.setDisplayOptions(displayOptions);
    compInstance.setSaveFunc(saveFunc);
    modalRef.result.then(_ => onClose?.()).catch();
  }

}
