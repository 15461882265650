import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BulkPrintJobType } from '../../../../../models/automation/enum/bulk-print-job-type';
import { of } from 'rxjs';

@Component({
  selector: 'app-choose-bulk-print-job-type',
  templateUrl: './choose-bulk-print-job-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseBulkPrintJobTypeComponent {

  @Output() bulkPrintJobTypeSelected = new EventEmitter<BulkPrintJobType>();

  public bulkPrintJobTypeOptions$ = of([
    {
      type: BulkPrintJobType.BulkPrintJobType_Menu,
      title: 'Print Menus',
      subtitle: 'Select existing print menus'
    },
    {
      type: BulkPrintJobType.BulkPrintJobType_Card,
      title: 'Print Cards',
      subtitle: 'Select existing print card stacks'
    },
    {
      type: BulkPrintJobType.BulkPrintJobType_Label,
      title: 'Print Labels',
      subtitle: 'Select existing print label stacks'
    }
  ]);

}
