import { ProductCard } from './product-card';
import { Asset } from '../../../../../../../../models/image/dto/asset';
import { DriveThruProductData } from '../../../../../shared/drive-thru-product/drive-thru-product-data';
import { Menu } from '../../../../../../../../models/menu/dto/menu';
import { CompanyConfiguration } from '../../../../../../../../models/company/dto/company-configuration';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';
import { PriceUtils } from '../../../../../../../../utils/price-utils';
import { CardType } from '../../../../../../../../models/utils/dto/card-type-definition';

export class DriveThruCard extends ProductCard {

  type: CardType;
  formData: DriveThruProductData;

  constructor(
    type: CardType,
    menu: Menu,
    locationConfiguration: LocationConfiguration,
    companyConfiguration: CompanyConfiguration,
    variant: Variant,
    asset: Asset,
    comboProductDate: DriveThruProductData
  ) {
    super();
    this.type = type;
    this.menu = menu;
    this.companyConfig = companyConfiguration;
    this.locationConfig = locationConfiguration;
    this.variant = variant;
    this.asset = asset;
    this.formData = comboProductDate;
  }

  override getAsset(): Asset {
    return this.asset;
  }

  override getCardColor(): string {
    return this.formData?.color || this.menu?.variantFeature?.getVariantColor(this.variant?.id);
  }

  override getProductDesc(): string {
    // get description override if applicable
    if (!!this.formData?.desc) {
      return this.formData.desc;
    }
    const descriptionOverride = this.menu.variantFeature.descriptionOverrideMap.get(this.variant.id);
    if (descriptionOverride) {
      return descriptionOverride;
    } else {
      return this.variant.description;
    }
  }

  override getSalePrice(): string {
    const formPrice = this.formData?.price;
    if ((formPrice !== null) && (formPrice >= 0)) {
      return PriceUtils.formatPrice(this.formData?.price);
    }
    const override = this.menu.variantFeature.priceOverrideMap.get(this.variant.id);
    const overridePrice = override ? PriceUtils.formatPrice(override) : null;
    const salePrice = super.getSalePrice();
    return overridePrice ?? salePrice;
  }

}
