import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';
import { Display } from '../../../models/display/dto/display';
import { Location } from '../../../models/company/dto/location';

@Pipe({
  name: 'displayTitle'
})
export class DisplayTitlePipe implements PipeTransform {

  transform(display: Display, location: Location = null): string {
    const orientation = display?.displaySize?.orientation;
    const upperCaseOrientation = StringUtils.capitalize(orientation);
    return location
      ? `${location?.name} - ${display?.name} (${upperCaseOrientation})`
      : `${display?.name} (${upperCaseOrientation})`;
  }

}
