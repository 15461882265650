import { Pipe, PipeTransform } from '@angular/core';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';

@Pipe({
  name: 'isTemplate'
})
export class IsTemplatePipe implements PipeTransform {

  transform(value: any): MenuTemplate | null {
    return value instanceof MenuTemplate ? value : null;
  }

}
