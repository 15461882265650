<!-- Primary Header -->
<app-title-and-crumbs [headerText]="headerText" [crumbs]="crumbs">
  <app-drop-down-menu
    [buttonClass]="'bs-regular'"
    [hidden]="hideSectionNavButton"
    [buttonText]="quickChangeSectionTitle"
    [openLeft]="true"
    [dropdownLeftMargin]="'6.9rem'"
    [dropdownTopMargin]="'-0.05rem'"
    [disabledSectionNavOptionIds]="disableSectionNavOptionIds"
    [useDivInsteadOfButton]="true"
    [sections]="sectionNavOptions"
    (output)="handleDropdownSelection.emit($event)">
  </app-drop-down-menu>
</app-title-and-crumbs>

<!-- Secondary Header -->
<div class="section-template-secondary-header">
  <div class="section-template-left-side-of-header">
    <app-template-name>{{ section?.title }}</app-template-name>
    <app-template-info-blurb>{{ headerSubtext }}</app-template-info-blurb>
  </div>
  <div class="section-template-right-side-of-header">
    <lib-button-neutral
      [disabled]="!allowLiveView || formHasErrors || !formPristine"
      [disabledTooltip]="
        (formErrorMessages | getFormErrorDisabledTooltip) ||
        (!formPristine ? 'Please save changes before viewing.' : '')
      "
      [iconSrc]="'assets/icons/dark/outline/eye.svg'"
      [hidden]="hideLiveViewButton"
      (buttonClicked)="showLiveView(formPristine, formAutoSubmitted$, submitForms)">
      Live View
    </lib-button-neutral>

    <lib-button-primary
      [infoText]="lastAutoSavedText"
      [disabled]="removingVariants || disableSubmit"
      [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
      [loadingOptions]="autoSaveLoadingOpts"
      (buttonClicked)="submitForms.emit(false)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
