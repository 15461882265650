<mat-accordion multi [displayMode]="'flat'">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="panel-header" [expandedHeight]="'4rem'" [collapsedHeight]="'4rem'">
      <div class="d-flex align-items-center ml-16px">
        <app-group-selection
          [selection]="locationGrouping?.displays | getDisplaysAsSelection"
          [selectedIds]="selectedIds"
          (bulkAdd)="addDisplays.emit($event)"
          (bulkRemove)="removeDisplays.emit($event)">
        </app-group-selection>
        <div class="n-selected">
          <span
            *ngIf="selectedIds | getDisplayGroupSelectedCount : locationGrouping?.displays as nSelected"
            class="checked-count">
            ({{ nSelected }})
          </span>
        </div>
        <div class="d-flex flex-column ml-8px">
          <div class="province-name">
            {{ locationGrouping?.location?.name }}
          </div>
          <div class="location-address">
            <div>{{ locationGrouping?.location?.address }}</div>
            <div>{{ locationGrouping?.location?.getCityProv() }}</div>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <app-display-selection-item
      *ngFor="let display of locationGrouping?.displays"
      [selection]="display"
      [selectedIds]="selectedIds"
      (add)="addDisplays.emit([$event])"
      (remove)="removeDisplays.emit([$event])">
    </app-display-selection-item>
  </mat-expansion-panel>
</mat-accordion>
