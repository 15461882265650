import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsError } from '../models/shared/bs-error';
import { BannerEventsService } from '../modules/banner-events/service/banner-events.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(
    private toastr: ToastrService,
    private bannerEvents: BannerEventsService,
  ) {
  }

  publishError(err: BsError) {
    if (!err?.title && !err?.message) {
      console.error('Empty error message received');
      if (!environment.production) {
        this.toastr.error('Empty error message received', 'Empty Error Message');
      }
    } else if (err.message !== 'Invalid Token!') {
      this.toastr.error(err.message, err.title);
    }
  }

  publishErrorMessage(mess, title: string) {
    if (!mess && !title) {
      console.error('Empty error message received');
      if (!environment.production) {
        this.toastr.error('Empty error message received', 'Empty Error Message');
      }
    } else {
      this.toastr.error(mess, title);
    }
  }

  publishSuccessMessage(mess, title: string) {
    this.toastr.success(mess, title);
  }

  publishInfoMessage(mess, title: string) {
    this.toastr.info(mess, title);
  }

  publishWarningMessage(mess, title: string) {
    this.toastr.warning(mess, title);
  }

  publishBannerSuccess(mess: string) {
    this.bannerEvents.success(mess);
  }

  publishBannerFailed(mess: string) {
    this.bannerEvents.error(mess);
  }

}
