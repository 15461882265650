<app-all-card-stack-print-jobs-header [cardStackName]="viewModel.cardStackName$ | push">
</app-all-card-stack-print-jobs-header>
<div class="modal-body">
  <app-bulk-print-jobs-data-table
    [bulkPrintJobs]="viewModel.cardStackPrintJobs$ | push"
    [editPrintCardMode]="viewModel.isCardStack$ | push"
    [editPrintLabelMode]="viewModel.isLabelStack$ | push"
    [nItemsToDisplay]="5">
  </app-bulk-print-jobs-data-table>
</div>
<app-go-back-modal-footer
  [primaryButtonText]="'Done'"
  [showCancelButton]="false"
  [showPrimaryButton]="true"
  (primaryButtonClicked)="cancel()">
</app-go-back-modal-footer>
