import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Asset } from '../../../../../models/image/dto/asset';
import { BaseComponent } from '../../../../../models/base/base-component';
import { MenuMediaViewModel } from '../../../viewModels/menu-media-view-model';
import { Menu } from '../../../../../models/menu/dto/menu';
import type { EditMarketingMenuViewModel } from '../../../viewModels/edit-marketing-menu-view-model';
import { MediaForm } from '../../../../../models/menu/shared/media-form';
import { DateUtils } from '../../../../../utils/date-utils';
import { FormGroupComponent } from '../../../../shared/components/form-group/form-group.component';
import { FormInputItem } from '../../../../../models/shared/stylesheet/form-input-item';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarketingTheme } from '../../../../../models/enum/dto/theme.enum';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { ToastService } from '../../../../../services/toast-service';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../models/shared/display-options';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';

@Component({
  selector: 'app-menu-media',
  templateUrl: './menu-media.component.html',
  styleUrls: ['./menu-media.component.scss'],
  providers: [MenuMediaViewModel]
})
export class MenuMediaComponent extends BaseComponent implements AfterViewInit, OnChanges, UploadImageInterface {

  @ViewChild(FormGroupComponent) form: FormGroupComponent;
  @Input() sharedViewModel: EditMarketingMenuViewModel;
  @Input() mergeKey: string = 'editMarketingMenu';
  @Input() isDisabledByTemplates: boolean = false;
  @Input() file: Asset;
  @Input() menu: Menu;
  @Input() index: number;
  @Input() bottomDivider: boolean = true;
  @Input() destructiveButtonText: string;
  @Input() featuredVariantId: string;
  @Input() featuredVariantName: string;
  @Input() theme: MarketingTheme = MarketingTheme.MarketingPlaylist;
  @Input() formPristine: boolean = true;
  @Output() deleteMedia: EventEmitter<Asset> = new EventEmitter<Asset>();
  @Output() deleteFeaturedProduct: EventEmitter<string> = new EventEmitter<string>();

  public replaceMediaSwitch: boolean = false;
  public enabled: boolean = false;
  public mediaDuration: number;

  constructor(
    public viewModel: MenuMediaViewModel,
    private ngbModal: NgbModal,
    private injector: Injector,
    private toastService: ToastService,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.initSharedModel(this.sharedViewModel);
    this.setInitialToggledState();
    this.bindToFileAsset();
    this.setupFile();
  }

  override setupBindings() {
    this.bindToFileAsset();
  }

  setupFile() {
    if (this.file?.isImage()) this.setupImageForm();
    if (this.file?.isVideo()) this.setupVideoForm();
  }

  listenForDuration(dur: number) {
    let mediaDuration: number = 0;
    if (dur % 1 === 0) {
      // subtracting 0.1 in the case the duration is whole
      mediaDuration = dur - 0.1;
    } else if (!!dur) {
      mediaDuration = Math.floor(dur);
    }

    const intervalLength = this.menu?.options?.rotationInterval?.get(this.getMediaIdentifier()) || 0;
    const date = this.file ? DateUtils.formatUnixToDateTime(this.file.timestamp) : '';
    this.viewModel.req = new MediaForm(mediaDuration, intervalLength, date);
    this.mediaDuration = mediaDuration;
    this.viewModel.req.mediaLength = mediaDuration;
    const loopCount = intervalLength / mediaDuration;
    const durationIsRotationLength = Number.isInteger(loopCount);
    if (mediaDuration > 0 && !durationIsRotationLength) {
      this.viewModel.req.interval = mediaDuration;
      this.viewModel.req.loop = 1;
    } else {
      this.viewModel.req.loop = loopCount;
    }
    setTimeout(() => { this.setupVideoForm(); }, 100);
  }

  bindToFileAsset() {
    this.bindToFile();
  }

  bindToFile() {
    const fileSub = this.viewModel.file$.notNull().subscribe((file) => {
      this.file = file;
    });
    this.pushSub(fileSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharedViewModel) this.viewModel.initSharedModel(this.sharedViewModel);
    if (!!changes.file || !!changes.menu || !!changes.featuredVariantId) this.setupFile();
    if (changes.menu) this.setInitialToggledState();
  }

  getMediaIdentifier(): string {
    return this.featuredVariantId || this.file?.fileName;
  }

  replaceMedia(f: BudsenseFile) {
    const marketingProductFeature = this.theme === MarketingTheme.MarketingProductFeature;
    this.viewModel.replaceMedia(this.file, f, this.featuredVariantId, marketingProductFeature);
    this.replaceMediaSwitch = false;
  }

  toggleReplaceMediaSwitch() {
    this.replaceMediaSwitch = !this.replaceMediaSwitch;
  }

  getInterval(): [string, number] {
    this.form?.getPopulatedObject();
    return [this.getMediaIdentifier(), this.viewModel.req?.interval];
  }

  toggleRemoveMediaDialog() {
    const opts = new ConfirmationOptions();
    let confirmation: (cont: boolean) => void;
    if (this.theme === MarketingTheme.MarketingPlaylist) {
      opts.title = 'Delete Media';
      opts.bodyText = `Are you sure you want to delete ${this?.file?.fileName}?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      confirmation = (cont) => {
        if (cont) {
          this.deleteMedia.next(this.file);
        }
      };
    } else if (this.theme === MarketingTheme.MarketingProductFeature) {
      opts.title = 'Delete Featured Product';
      opts.bodyText = `Are you sure you want to delete ${this?.featuredVariantName}?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      confirmation = (cont) => {
        if (cont) {
          this.deleteFeaturedProduct.next(this.featuredVariantId);
        }
      };
    }
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

  setInitialToggledState() {
    if (this.menu) {
      const rotInterval = this.menu.options?.rotationInterval?.get(this.getMediaIdentifier()) || 0;
      this.enabled = rotInterval > 0;
    }
  }

  fileList(f: BudsenseFile[], id?: number) {
    if (f && f.length === 1) {
      this.replaceMedia(f[0]);
    } else if (f.length > 1) {
      this.toastService.publishErrorMessage('Must provide 1 file in order to replace.', 'Too many files!');
    }
  }

  /**
   * Toggles Twice, once on create, and once after view init.
   */
  private setFormInParentViewModel(
    file: Asset,
    mediaIdentifier: string,
    items: FormInputItem[],
    afterInit: boolean = false
  ) {
    if (!afterInit) {
      this.viewModel.connectToFile(file);
      if (items && this.menu) {
        this.sharedViewModel.menuAssetItems.set(mediaIdentifier, items);
      }
      const compIndex = this.sharedViewModel.menuAssetComponents.findIndex(it => {
        return it.getMediaIdentifier() === mediaIdentifier;
      });
      if (compIndex < 0) {
        this.sharedViewModel.menuAssetComponents.push(this);
      } else {
        this.sharedViewModel.menuAssetComponents.splice(compIndex, 1);
        this.sharedViewModel.menuAssetComponents.push(this);
      }
    }
  }

  refreshMedia() {
    this.viewModel.refreshImage();
  }

  private setupVideoForm() {
    this.setFormInParentViewModel(this.file, this.getMediaIdentifier(), this.viewModel.formItems);
  }

  private setupImageForm() {
    this.sharedViewModel.menu$.once(menu => {
      const interval = menu?.options?.rotationInterval?.get(this.getMediaIdentifier())
        || (this.enabled ? (this.viewModel.req?.interval || DEFAULT_ROTATION_INTERVAL) : 0);
      const date = this.file ? DateUtils.formatUnixToDateTime(this.file.timestamp) : '';
      this.viewModel.req = new MediaForm(this.mediaDuration, interval, date);
      this.setFormInParentViewModel(this.file, this.getMediaIdentifier(), this.viewModel.formItems);
    });
  }

}
