import { Pipe, PipeTransform } from '@angular/core';
import { HasChildIds } from '../../../../../../../../../models/protocols/has-child-ids';
import { Product } from '../../../../../../../../../models/product/dto/product';

@Pipe({
  name: 'cardStackProductAsSelectionItem'
})
export class CardStackProductAsSelectionItemPipe implements PipeTransform {

  transform(product: Product, cardStackVariantIds: string[]): HasChildIds {
    return (new class implements HasChildIds {

      getId() {
        return '';
      }
      getChildIds() {
        return product?.variants?.map(v => v?.id)?.filter(id => cardStackVariantIds.includes(id)) ?? [];
      }

    });
  }

}
