import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { map } from 'rxjs/operators';
import { Location } from '../../../../../models/company/dto/location';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';
import { PendingDisplay } from '../../../../../models/template/dto/pending-display';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class SelectLocationsForNewDisplayViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  public readonly allLocations$ = this.locationDomainModel.allLocations$;

  private _pendingDisplay = new BehaviorSubject<PendingDisplay>(new PendingDisplay());
  public pendingDisplay$ = this._pendingDisplay as Observable<PendingDisplay>;
  connectToPendingDisplay = (pd: PendingDisplay) => {
    this._pendingDisplay.next(pd);
    this.connectToSelectedRequiredLocationIds(pd?.locationIds || []);
  };

  private _selectedRequiredLocationIds = new BehaviorSubject<number[]>(null);
  public selectedRequiredLocationIds$ = this._selectedRequiredLocationIds as Observable<number[]>;
  public selectedRequiredLocationIdsAsStringList$ = this.selectedRequiredLocationIds$
    .pipe(map(ids => ids?.map(id => id.toString()) ?? []));

  connectToSelectedRequiredLocationIds = (selectedRequiredLocationIds: number[]) => {
    this._selectedRequiredLocationIds.next(selectedRequiredLocationIds);
  };
  public nSelectedLocationIds$ = this.selectedRequiredLocationIds$.pipe(map(ids => ids?.length ?? 0));

  private _searchString = new BehaviorSubject<string>(null);
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _searchedLocations = new BehaviorSubject<Location[]>(null);
  public searchedLocations$ = this._searchedLocations as Observable<Location[]>;
  connectToSearchedLocations = (locations: Location[]) => this._searchedLocations.next(locations);

  public readonly searchedLocationsGroupedByProvince$ = this.searchedLocations$.pipe(
    map(locations => Location.groupLocationsByProvince([locations?.map(loc => loc?.id), locations]))
  );

  public readonly locationsVisibleOnScreen$ = this.searchedLocations$.pipe(
    map(locations => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => locations?.map(location => location?.getId()) ?? [];

      }();
    })
  );

  public disableSubmit$ = combineLatest([this.pendingDisplay$, this.selectedRequiredLocationIds$]).pipe(
    map(([pendingDisplay, selectedLocationIds]) => {
      const isEditFlow = !!pendingDisplay?.locationIds?.length;
      if (isEditFlow) return false;
      return !selectedLocationIds?.length;
    })
  );

  private addToRequiredLocationIds = (id: number) => this.selectedRequiredLocationIds$
    .once(ids => this._selectedRequiredLocationIds.next(ids?.concat([id])?.unique()));

  public addLocationIds(ids: string[]) {
    ids?.forEach(id => this.addToRequiredLocationIds(Number.parseInt(id, 10)));
  }

  private removeFromRequiredLocationIds = (id: number) => this.selectedRequiredLocationIds$
    .once(ids => this._selectedRequiredLocationIds.next(ids?.filter(currId => currId !== id)));

  public removeLocationIds(ids: string[]) {
    ids?.forEach(id => this.removeFromRequiredLocationIds(Number.parseInt(id, 10)));
  }

  public updatePendingDisplay(): void {
    combineLatest([this.selectedRequiredLocationIds$, this.pendingDisplay$]).once(([ids, pendingDisplay]) => {
      const updatedPendingDisplay = window?.injector?.Deserialize?.instanceOf(PendingDisplay, pendingDisplay);
      updatedPendingDisplay.locationIds = ids;
      this.activeModal.close(updatedPendingDisplay);
    });
  }

  trackById(index, item: { key: string; value: Location[] }) {
    return `new-display-${item.key}`;
  }

}
