import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '../../../../../models/company/dto/location';
import { BsError } from '../../../../../models/shared/bs-error';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { LoadingOptions } from '../../../../../models/shared/loading-options';

@Injectable()
export class TemplatePublishViewModel extends BaseModalViewModel {

  constructor(
    private templateDomainModel: TemplateDomainModel,
    private locationDomainModel: LocationDomainModel,
    protected toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public readonly allLocations$ = this.locationDomainModel.allLocations$;
  public readonly stickyZIndex$ = of(2);

  public readonly _menuTemplate = new BehaviorSubject<Observable<MenuTemplate>>(null);
  public readonly menuTemplate$ = this._menuTemplate.pipe(switchMap(m$ => m$ || of<MenuTemplate>(null)));
  connectToMenuTemplate = (menuTemplate$: Observable<MenuTemplate>) => this._menuTemplate.next(menuTemplate$);

  private readonly _parentLoadingOpts = new BehaviorSubject<Observable<LoadingOptions>>(null);
  private readonly parentLoadingOpts$ = this._parentLoadingOpts.pipe(
    switchMap(opt$ => opt$ || of<LoadingOptions>(null))
  );
  connectToParentLoadingOpts = (loadingOpts$: Observable<LoadingOptions>) => this._parentLoadingOpts.next(loadingOpts$);

  public readonly combinedLoadingOpts$ = combineLatest([
    this.parentLoadingOpts$,
    this.loadingOpts$
  ]).pipe(
    map(([parentOpts, opts]) => (!!parentOpts ? opts.combineWith(parentOpts) : opts)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly isCombinedLoading$ = this.combinedLoadingOpts$.pipe(map(opts => opts?.isLoading));

  public readonly requiredLocationIds$ = this.menuTemplate$.pipe(
    map(menuTemplate => menuTemplate?.requiredLocationIds)
  );

  public readonly requiredLocationsGroupedByProvince$ = combineLatest([
    this.requiredLocationIds$,
    this.allLocations$,
  ]).pipe(
    map(Location.groupLocationsByProvince),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly nRequiredProvinces$ = this.requiredLocationsGroupedByProvince$.pipe(
    map(requiredLocationsGroupedByProvince => Object.keys(requiredLocationsGroupedByProvince)?.length)
  );

  public readonly singleRequiredProvince$ = this.nRequiredProvinces$.pipe(map(nRequiredProv => nRequiredProv === 1));

  trackById = (index, item) => item.id;

  publishTemplate() {
    const lm = 'Publishing Template';
    this.menuTemplate$.once(mt => {
      const menuTemplate = window.injector.Deserialize.instanceOf(MenuTemplate, mt);
      this._loadingOpts.addRequest(lm);
      if (menuTemplate instanceof MenuTemplate) {
        this.templateDomainModel.publishMenuTemplate(menuTemplate).subscribe({
          complete: () => {
            this._loadingOpts.removeRequest(lm);
            const msg = `${menuTemplate?.name} has been successfully published`;
            this.toastService.publishSuccessMessage(msg, 'Template Published');
            this.closeWithReturnValue(menuTemplate);
          },
          error: (err: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(err);
          }
        });
      }
    });
  }

}
