<div class="availability-container" [style.height.px]="viewModel.container.tabComponentHeight$ | async">
  <div class="availability-location-section">
    <div class="availability-section-title">Location Availability-{{ viewModel.locationName$ | async }}</div>
    <div class="availability-row">
      <div class="availability-row-label">
        <div [style.display]="'flex'" [style.align-items]="'center'">Quantity in Stock</div>
        <div
          *ngIf="viewModel.shouldShowInventoryCountPercentage$ | async"
          class="availability-percentage"
          [ngClass]="{
            'positive-percentage': (viewModel.locationPercentageDifference$ | async) > 0,
            'negative-percentage': (viewModel.locationPercentageDifference$ | async) < 0
          }">
          <div>
            <img
              *ngIf="(viewModel.locationPercentageDifference$ | async) > 0"
              [src]="'assets/icons/green/outline/chevron-up.svg'"
              alt=""
              class="percentage-chevron" />
            <img
              *ngIf="(viewModel.locationPercentageDifference$ | async) < 0"
              [src]="'assets/icons/red/outline/chevron-down.svg'"
              alt=""
              class="percentage-chevron" />
          </div>
          {{ absolutePercentage(viewModel.locationPercentageDifference$ | async) }}%
        </div>
      </div>
      <div class="availability-row-value">{{ viewModel.quantityInStockToDisplay$ | async }}</div>
    </div>
    <div class="availability-row">
      <div class="availability-row-label">Last Restock</div>
      <div class="availability-row-value">{{ viewModel.lastRestock$ | async }}</div>
    </div>
    <div class="availability-row" *ngIf="viewModel?.showThresholdRestock$ | async">
      <div class="availability-row-label">
        Last Restock (Below quantity {{ viewModel?.locationRestockLabelQuantityThreshold$ | async }})
      </div>
      <div class="availability-row-value">{{ viewModel.lastThresholdRestock$ | async }}</div>
    </div>
    <ng-container *ngIf="viewModel.shouldShowInventoryCountPercentage$ | async">
      <div class="availability-location-notice">
        * Inventory count relative to average across {{ viewModel.container.companyName$ | async }} in stock locations
      </div>
    </ng-container>
  </div>

  <div class="availability-company-section" [style.height.px]="viewModel.container.tabComponentHeight$ | async">
    <ng-container *ngIf="viewModel.hasMultipleLocations$ | async">
      <div class="availability-section-title">Availability At {{ viewModel.container.companyName$ | async }}</div>
      <div [style.margin-top.rem]="9" *ngIf="viewModel?.isLoading$ | async">
        <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
      </div>
      <ng-container *ngFor="let provinceLocations of viewModel.inventoryForVariantByLocation$ | async | keyvalue">
        <div class="availability-province-label">{{ provinceLocations.key }}</div>
        <ng-container *ngFor="let locationWithInventory of provinceLocations.value">
          <div
            class="availability-row"
            [ngClass]="{ 'availability-row-no-stock': !locationWithInventory.inventoryForVariant }">
            <div class="availability-row-label">{{ locationWithInventory?.location?.name }}</div>
            <div class="availability-row-value">
              {{ viewModel | formatInventoryAtOtherLocations : locationWithInventory }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
