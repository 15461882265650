<div class="py-2 ps-5 ubv-info-container">
  <div class="row mt-4 mb-3">
    <div class="col-4">
      <div class="bs-medium f12px">Variant Name</div>
      <div class="bs-bold f16px">{{ variant?.name | doubleDashIfEmpty }}</div>
    </div>
    <div class="col-4">
      <div class="bs-medium f12px">Brand</div>
      <div class="bs-bold f16px">{{ variant?.brand | doubleDashIfEmpty }}</div>
    </div>
    <div class="col-4">
      <div class="bs-medium f12px">Barcode</div>
      <div class="bs-bold f16px">{{ variant?.barcode | doubleDashIfEmpty }}</div>
    </div>
  </div>
  <div class="row mt-4 mb-3">
    <div class="col-4">
      <div class="bs-medium f12px">Manufacturer</div>
      <div class="bs-bold f16px">{{ variant?.manufacturer | doubleDashIfEmpty }}</div>
    </div>
    <div class="col-4" *ngIf="variant?.productType !== ClientTypes.ProductType.Accessories">
      <div class="bs-medium f12px">Strain</div>
      <div class="bs-bold f16px">{{ variant?.strain | doubleDashIfEmpty }}</div>
    </div>
    <div class="col-4" *ngIf="variant?.productType !== ClientTypes.ProductType.Accessories">
      <div class="bs-medium f12px">Unit Size</div>
      <div class="bs-bold f16px">{{ variant?.unitSize }}</div>
    </div>
  </div>
  <ng-container *ngIf="variant?.productType !== ClientTypes.ProductType.Accessories">
    <div class="row mt-4 mb-3">
      <div class="col-4">
        <div class="bs-medium f12px">Unit of Measure</div>
        <div class="bs-bold f16px">{{ variant?.unitOfMeasure | doubleDashIfEmpty }}</div>
      </div>
      <div class="col-4">
        <div class="bs-medium f12px">Cannabis Unit of Measure</div>
        <div class="bs-bold f16px">{{ variant?.cannabisUnitOfMeasure | doubleDashIfEmpty }}</div>
      </div>
      <div class="col-4">
        <div class="bs-medium f12px">Packaged Quantity</div>
        <div class="bs-bold f16px">{{ variant?.packagedQuantity }}</div>
      </div>
    </div>
    <div class="row mt-4 mb-3">
      <div class="col-4">
        <div class="bs-medium f12px">THC</div>
        <div class="bs-bold f16px">{{ variant?.THC | doubleDashIfEmpty }}</div>
      </div>
      <div class="col-4">
        <div class="bs-medium f12px">Minimum THC</div>
        <div class="bs-bold f16px">{{ variant?.minTHC | doubleDashIfEmpty }}</div>
      </div>
      <div class="col-4">
        <div class="bs-medium f12px">Maximum THC</div>
        <div class="bs-bold f16px">{{ variant?.maxTHC | doubleDashIfEmpty }}</div>
      </div>
    </div>
    <div class="row mt-4 mb-3">
      <div class="col-4">
        <div class="bs-medium f12px">CBD</div>
        <div class="bs-bold f16px">{{ variant?.CBD | doubleDashIfEmpty }}</div>
      </div>
      <div class="col-4">
        <div class="bs-medium f12px">Minimum CBD</div>
        <div class="bs-bold f16px">{{ variant?.minCBD | doubleDashIfEmpty }}</div>
      </div>
      <div class="col-4">
        <div class="bs-medium f12px">Maximum CBD</div>
        <div class="bs-bold f16px">{{ variant?.maxCBD | doubleDashIfEmpty }}</div>
      </div>
    </div>
  </ng-container>
  <div class="row mt-4 mb-3">
    <div class="col-12">
      <div class="bs-medium f12px">Description</div>
      <div class="bs-bold f16px">{{ variant?.description | doubleDashIfEmpty }}</div>
    </div>
  </div>
</div>
