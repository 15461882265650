import { Pipe, PipeTransform } from '@angular/core';
import { StackPrintType } from '../../../../../../../../models/automation/enum/card-stack-print-type.enum';
import { BulkPrintJobType } from '../../../../../../../../models/automation/enum/bulk-print-job-type';

@Pipe({
  name: 'printJobTypeString'
})
export class PrintJobTypeStringPipe implements PipeTransform {

  transform(printType: StackPrintType | BulkPrintJobType): string {
    const parts = printType?.split('_');
    return parts?.length > 1 ? parts.pop() : '';
  }

}
