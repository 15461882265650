import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { CreateTemplateCollectionModalComponent } from '../views/template/components/modals/create-template-collection-modal/create-template-collection-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { TemplateCollection } from '../models/template/dto/template-collection';

export class ModalCreateTemplateCollection {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    onClose?: (newCollection: TemplateCollection) => void
  ): void {
    const modalRef = ngbModal.open(
      CreateTemplateCollectionModalComponent,
      ModalUtils.defaultModalOptions(injector)
    );
    modalRef.result
      .then((collection) => {
        if (!!collection && !!onClose) {
          onClose(collection);
        }
      })
      .catch(() => {});
  }

}
