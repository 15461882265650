import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditProductMenuViewModel } from '../../../../menu/viewModels/edit-product-menu-view-model';
import { EditTemplateProductMenuViewModel } from './edit-template-product-menu-view-model';
import { EditTemplateMenuComponent } from '../edit-template-menu.component';
import { EditTemplateProductMenuCombinedLoadingOptsViewModel } from './edit-template-product-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-template-product-menu',
  templateUrl: './edit-template-product-menu.component.html',
  styleUrls: ['./edit-template-product-menu.component.scss'],
  providers: [
    EditTemplateProductMenuViewModel,
    EditProductMenuViewModel,
    EditTemplateProductMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateProductMenuComponent extends EditTemplateMenuComponent {

  constructor(
    public override viewModel: EditTemplateProductMenuViewModel,
    public combinedLoadingOptsViewModel: EditTemplateProductMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel);
  }

  @Input() mergeAndDispersedKey: string = 'editTemplateProductMenu';

}
