import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMarketingMenuComponent } from '../edit-template-marketing-menu.component';
import { EditTemplateMarketingFeaturedProductMenuViewModel } from './edit-template-marketing-featured-product-menu-view-model';
import { EditTemplateMarketingFeaturedProductMenuCombinedLoadingOptsViewModel } from './edit-template-marketing-featured-product-menu-combined-loading-opts-view-model';
import { EditFeaturedProductMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu-view-model';

@Component({
  selector: 'app-edit-template-marketing-featured-product-menu',
  templateUrl: './edit-template-marketing-featured-product-menu.component.html',
  styleUrls: ['./edit-template-marketing-featured-product-menu.component.scss'],
  providers: [
    EditTemplateMarketingFeaturedProductMenuViewModel,
    EditFeaturedProductMenuViewModel,
    EditTemplateMarketingFeaturedProductMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingFeaturedProductMenuComponent extends EditTemplateMarketingMenuComponent {

  constructor(
    public override viewModel: EditTemplateMarketingFeaturedProductMenuViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateMarketingFeaturedProductMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
