import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateProductGroupingModalViewModel } from './create-product-grouping-modal-view-model';

@Component({
  selector: 'app-create-product-grouping-modal',
  templateUrl: './create-product-grouping-modal.component.html',
  styleUrls: ['./create-product-grouping-modal.component.scss'],
  providers: [CreateProductGroupingModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProductGroupingModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: CreateProductGroupingModalViewModel,
    activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override setupBindings(): void {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: (createdGroup) => this.close(createdGroup)
    });
  }

}
