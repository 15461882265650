import { Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
  name: 'as'
})
export class AsPipe implements PipeTransform {

  transform<T>(value: any, CastedTo: Type<T>): T {
    return value as T;
  }

}
