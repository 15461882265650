import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmartFilterRowViewModel } from '../smart-filter-row-view-model';

@Component({
  selector: 'app-smart-filters-row-misc-button',
  templateUrl: './smart-filters-row-misc-button.component.html',
  styleUrls: ['./smart-filters-row-misc-button.component.scss']
})
export class SmartFiltersRowMiscButtonComponent {

  @Input() text: string;
  @Input() iconSrc: string;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public viewModel: SmartFilterRowViewModel // global, provided by root
  ) { }

  buttonClicked() {
    this.clicked.emit();
  }

}
