import { Pipe, PipeTransform } from '@angular/core';
import { Card } from '../edit-drive-thru-menu/models/cards/card';
import { DriveThruCard } from '../edit-drive-thru-menu/models/cards/drive-thru-card';
import { CardType } from '../../../../../../models/utils/dto/card-type-definition';

@Pipe({
  name: 'isEmbeddedImageCard'
})
export class IsEmbeddedImageCardPipe implements PipeTransform {

  transform(value: Card): boolean {
    return value instanceof DriveThruCard ? value?.type === CardType.Embedded : false;
  }

}
