import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SimpleLabelPreviewViewModel } from './simple-label-preview-view-model';
import { BaseComponent } from '../../../models/base/base-component';
import { Label } from '../../../models/shared/label';

@Component({
  selector: 'app-simple-label-preview',
  templateUrl: './simple-label-preview.component.html',
  styleUrls: ['./simple-label-preview.component.scss'],
  providers: [SimpleLabelPreviewViewModel]
})
export class SimpleLabelPreviewComponent extends BaseComponent implements OnChanges {

  @Input() labelIds: string[];
  @Input() zoom: number = 0.7;
  @Input() stackLabelsVertically: boolean = false;
  @Input() isClearable: boolean = true;
  @Output() updatedLabelIds = new EventEmitter<Label>(true);

  constructor(
    public viewModel: SimpleLabelPreviewViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelIds) {
      this.viewModel.connectToLabelIds(this.labelIds);
    }
  }

  handleClearedLabel(l: Label) {
    this.updatedLabelIds.emit(l);
  }

}
