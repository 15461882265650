import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { map } from 'rxjs/operators';

@Injectable()
export class VariantBadgeViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _addedBadgeIds: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _badge: BehaviorSubject<HydratedVariantBadge> = new BehaviorSubject<HydratedVariantBadge>(null);
  public readonly added$ = combineLatest([
    this._addedBadgeIds,
    this._badge
  ]).pipe(
    map(([addedBadgeIds, badge]) => {
      return badge ? addedBadgeIds.includes(badge.id) : false;
    })
  );

  connectToAddedBadgeIds = (addedBadgeIds: string[]) => {
    this._addedBadgeIds.next(!!addedBadgeIds ? [...addedBadgeIds] : []);
  };

  connectToBadge = (badge: HydratedVariantBadge) => this._badge.next(badge);

}
