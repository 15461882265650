import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { LocalizedDateRange } from '../../../../../../models/shared/localized-date-range';

@Component({
  selector: 'app-date-time-window-form',
  templateUrl: './date-time-window-form.component.html',
  styleUrls: ['./date-time-window-form.component.scss']
})
export class DateTimeWindowFormComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() bindTo: LocalizedDateRange;
  @Input() mergeKey: string;
  @Input() index: number;
  @Output() removeDateTimeWindow: EventEmitter<number> = new EventEmitter<number>();

}
