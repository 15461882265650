import { EditTemplateMenuSectionCombinedLoadingOptsViewModel } from '../../../../edit-template-menu-section/edit-template-combined-loading-opts-view-model';
import { EditTemplateFeaturedCategorySectionViewModel } from './edit-template-featured-category-section-view-model';
import { EditFeaturedCategorySectionViewModel } from '../../../../../../menu/components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class EditTemplateFeaturedCategorySectionCombinedLoadingOptsViewModel
  extends EditTemplateMenuSectionCombinedLoadingOptsViewModel {

  constructor(
    public editTemplateFeaturedCategorySectionViewModel: EditTemplateFeaturedCategorySectionViewModel,
    public editFeaturedCategorySectionViewModel: EditFeaturedCategorySectionViewModel,
  ) {
    super(editTemplateFeaturedCategorySectionViewModel, editFeaturedCategorySectionViewModel);
  }

}
