import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SelectMenuOrCollectionResultsComponent } from '../select-menu-or-collection-results.component';
import { TemplateCollection } from '../../../../../../../models/template/dto/template-collection';

@Component({
  selector: 'app-collection-results',
  templateUrl: './collection-results.component.html',
  styleUrls: ['./collection-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionResultsComponent extends SelectMenuOrCollectionResultsComponent {

  @Input() override searchedItems: TemplateCollection[];

}
