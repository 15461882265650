import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMarketingMenuComponent } from '../edit-template-marketing-menu.component';

import { EditTemplateMarketingUrlPlaylistMenuViewModel } from './edit-template-marketing-url-playlist-menu-view-model';
import { EditUrlPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu-view-model.service';
import { EditTemplateMarketingUrlPlaylistMenuCombinedLoadingOptsViewModel } from './edit-template-marketing-url-playlist-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-template-marketing-url-playlist-menu',
  templateUrl: './edit-template-marketing-url-playlist-menu.component.html',
  styleUrls: ['./edit-template-marketing-url-playlist-menu.component.scss'],
  providers: [
    EditTemplateMarketingUrlPlaylistMenuViewModel,
    EditUrlPlaylistMenuViewModel,
    EditTemplateMarketingUrlPlaylistMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateMarketingUrlPlaylistMenuComponent extends EditTemplateMarketingMenuComponent {

  constructor(
    public override viewModel: EditTemplateMarketingUrlPlaylistMenuViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateMarketingUrlPlaylistMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
