import { Pipe, PipeTransform } from '@angular/core';
import { LocationDisplayGrouping } from '../../../models/display/dto/display';

@Pipe({
  name: 'numberOfNewDisplaysString'
})
export class NumberOfNewDisplaysStringPipe implements PipeTransform {

  transform(locationGroupings: LocationDisplayGrouping[]): string {
    const newDisplayCount = locationGroupings.reduce((count, group) => {
      return count + group.displays.filter((display) => !display.id).length;
    }, 0);
    return newDisplayCount
      ? `(${newDisplayCount} new display${newDisplayCount > 1 ? 's' : ''})`
      : null;
  }

}
