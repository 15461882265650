import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavigationService } from '../../../../../services/navigation.service';

@Injectable()
export class SignOutViewModel extends BaseViewModel {

  constructor(
    protected router: Router,
    protected userDomainModel: UserDomainModel,
    protected toastService: ToastService,
    protected navigationService: NavigationService
  ) {
    super();
  }

  public override loadingOpts$ = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  public override isLoading$ = this.loadingOpts$.pipe(map(it => it?.isLoading));

  signOut() {
    const loadingMess = 'Signing Out';
    this.loadingOpts$.addRequest(loadingMess);
    return this.userDomainModel.signOut().subscribe({
      complete: () => this.loadingOpts$.removeRequest(loadingMess),
      error: (error: BsError) => {
        this.loadingOpts$.removeRequest(loadingMess);
        this.toastService.publishError(error);
        this.navigationService.signIn();
      }
    });
  }

}
