import { ChangeDetectorRef, Component, forwardRef, Inject, Injector, Input, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableEmptyStateBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateBulkPrintJob } from '../../../../../../../../modals/modal-create-bulk-print-job';

@Component({
  selector: 'app-bulk-print-jobs-table-empty-state',
  templateUrl: './bulk-print-jobs-table-empty-state.component.html',
  styleUrls: ['./bulk-print-jobs-table-empty-state.component.scss'],
  providers: [
    {
      provide: ReactiveTableEmptyStateBluePrintComponent,
      useExisting: forwardRef(() => BulkPrintJobsTableEmptyStateComponent)
    }
  ]
})
export class BulkPrintJobsTableEmptyStateComponent extends ReactiveTableEmptyStateBluePrintComponent {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super(viewRef);
  }

  @Input() noResultsText: string;
  @Input() noResultsTitle: string;

  changesFromBluePrint(changes: SimpleChanges): void {
    if (!!changes.noResultsText) this.noResultsText = changes.noResultsText.currentValue;
    if (!!changes.noResultsTitle) this.noResultsTitle = changes.noResultsTitle.currentValue;
  }

  initializeFromBluePrint(bluePrint: BulkPrintJobsTableEmptyStateComponent): void {
    this.noResultsText = bluePrint.noResultsText;
    this.noResultsTitle = bluePrint.noResultsTitle;
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableEmptyStateBluePrintComponent): void {
  }

  createBulkPrintJob() {
    ModalCreateBulkPrintJob.open(this.ngbModal, this.injector);
  }

}
