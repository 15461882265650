import { Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReferAFriend } from '../../../../modals/modal-refer-a-friend';

@Component({
  selector: 'app-refer-a-friend-widget',
  templateUrl: './refer-a-friend-widget.component.html',
  styleUrls: ['./refer-a-friend-widget.component.scss']
})
export class ReferAFriendWidgetComponent {

  constructor(
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) { }

  openReferFriendModal() {
    ModalReferAFriend.open(this.ngbModal, this.injector);
  }

}
