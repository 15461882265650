import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrintProductMenusViewModel } from './print-product-menus-view-model';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';

@Component({
  selector: 'app-print-product-menus',
  templateUrl: './print-product-menus.component.html',
  styleUrls: ['./print-product-menus.component.scss'],
  providers: [PrintProductMenusViewModel]
})
export class PrintProductMenusComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintProductMenusViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
