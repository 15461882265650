<lib-reactive-form-group
  [bindTo]="bindTo"
  [mergeKey]="mergeKey"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (hasErrors$)="setHasError($event)">
  <div class="print-options-switches-container">
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-drop-down
        #headerLayoutType
        *rxIf="!(menu | containsStackedContent) && !(menu | isPrintReportMenu)"
        [inputName]="'headerLayoutType'"
        [label]="'Header Layout Type'"
        [inlinePercentage]="58"
        [placeholder]="'Change how your header is displayed'"
        [bindingProperty]="'menuOptions.printHeaderLayout'"
        [required]="false"
        [disabled]="isTemplatedMenu"
        [tooltipModalInfoItems]="headerTooltips$ | push"
        [dropdowns]="printHeaderTypes$ | push">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-drop-down
        #footerLayoutType
        *rxIf="!(menu | containsStackedContent) && !(menu | isPrintReportMenu)"
        [inputName]="'footerLayoutType'"
        [label]="'Footer Layout Type'"
        [inlinePercentage]="58"
        [placeholder]="'Change how your footer is displayed'"
        [bindingProperty]="'menuOptions.printFooterLayout'"
        [required]="false"
        [disabled]="isTemplatedMenu"
        [tooltipModalInfoItems]="footerTooltips$ | push"
        [dropdowns]="printFooterTypes$ | push">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-switch
        *ngIf="!(menu | containsStackedContent)"
        [label]="'Section Page Break'"
        [tooltip]="'Every section will begin on a new page'"
        [bindingProperty]="'menuOptions.sectionPageBreak'"
        [inputName]="'sectionPageBreak'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [labelOnRight]="true"
        [inlinePercentage]="50">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="menu | showTitleSectionPageBreak"
        [label]="'Title Section Page Break'"
        [tooltip]="'Every title section will begin on a new page'"
        [bindingProperty]="'menuOptions.titleSectionPageBreak'"
        [inputName]="'titleSectionPageBreak'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [labelOnRight]="true"
        [inlinePercentage]="50">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        #singlePageMenuSwitch
        [label]="
          (menu | containsStackedContent)
            ? ('Single Menu Per Page'
              | replaceMenuWithCardOrLabel : (menu | isPrintCardMenu) : (menu | isPrintLabelMenu))
            : 'Single Page Menu'
        "
        [tooltip]="
          (menu | containsStackedContent)
            ? ('When enabled, the print menus will each appear centered on separate pages'
              | replaceMenuWithCardOrLabel : (menu | isPrintCardMenu) : (menu | isPrintLabelMenu))
            : 'This print menu is only one page'
        "
        [bindingProperty]="'menuOptions.singlePageMenu'"
        [inputName]="'singlePageMenu'"
        [disabled]="isTemplatedMenu || singlePageMenuSwitchDisabled || needsToBeSinglePage"
        [inlineLabel]="true"
        [labelOnRight]="true"
        [inlinePercentage]="50">
      </lib-reactive-form-switch>
    </lib-reactive-form-column-layout>
  </div>
</lib-reactive-form-group>
