import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../models/base/base-component';
import { Label } from '../../../models/shared/label';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import { SystemLabel } from '../../../models/shared/labels/system-label';
import { LabelActionCardViewModel } from './label-action-card-view-model';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';

@Component({
  selector: 'app-label-action-card',
  templateUrl: './label-action-card.component.html',
  styleUrls: ['./label-action-card.component.scss'],
  providers: [LabelActionCardViewModel]
})
export class LabelActionCardComponent extends BaseComponent implements OnChanges {

  @Input() label: Label;
  @Input() disabled: boolean = false;
  @Input() reordering: boolean = false;
  @Input() locked: boolean = false;
  @Input() clearable: boolean = false;
  @Input() activeLabelStyle: LabelStyle = LabelStyle.Unset;
  @Input() activeSaleLabelFormat: SaleLabelFormat = SaleLabelFormat.SALE;

  @Output() cardClicked = new EventEmitter<Label>();
  @Output() clearClicked = new EventEmitter<Label>();

  constructor(
    public viewModel: LabelActionCardViewModel
  ) {
    super();
  }

  private _isDisabled = new BehaviorSubject<boolean>(false);
  private _isSelected = new BehaviorSubject<boolean>(false);
  private _isReordering = new BehaviorSubject<boolean>(false);
  private _isLocked = new BehaviorSubject<boolean>(false);
  private _isClearable = new BehaviorSubject<boolean>(false);
  private _label = new BehaviorSubject<Label>(null);
  public label$ = this._label as Observable<Label>;
  public isClearable$ = this._isClearable as Observable<boolean>;

  public getCardIcon$ = combineLatest([
    this._isDisabled,
    this._isSelected,
    this._isReordering,
    this._isLocked
  ]).pipe(
    map(([isDisabled, isSelected, isReordering, isLocked]) => {
      if (isLocked) {
        return 'assets/icons/dark/solid/lock-closed.svg';
      } else if (isReordering) {
        return 'assets/icons/dark/outline/menu.svg';
      } else if (!isDisabled && !isSelected) {
        return 'assets/icons/dark/solid/plus.svg';
      } else {
        return 'assets/icons/dark/solid/check.svg';
      }
    })
  );

  public cardTitle$ = this.label$.pipe(
    map(l => {
      if (l instanceof SystemLabel) {
        return l.getSortOrderTitle()?.toUpperCase();
      } else {
        return l?.text.toUpperCase();
      }
    })
  );

  emitCardClicked(): void {
    this._isSelected.pipe(take(1)).subscribe(s => this._isSelected.next(!s));
    this.cardClicked.emit(this.label);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) this._isDisabled.next(this.disabled);
    if (changes.reordering) this._isReordering.next(this.reordering);
    if (changes.locked) this._isLocked.next(this.locked);
    if (changes.clearable) this._isClearable.next(this.clearable);
    if (changes.label) this._label.next(this.label);
    if (changes.activeLabelStyle) this.viewModel.connectToActiveLabelStyle(this.activeLabelStyle);
    if (changes.activeSaleLabelFormat) this.viewModel.connectToActiveSaleLabelFormat(this.activeSaleLabelFormat);
  }

}
