<div class="edit-page-action-buttons">
  <lib-button-neutral
    [disabled]="!allowLiveView || formHasErrors || !formPristine"
    [disabledTooltip]="
      (formErrorMessages | getFormErrorDisabledTooltip) || (!formPristine ? 'Please save changes before viewing.' : '')
    "
    [iconSrc]="'assets/icons/dark/outline/eye.svg'"
    [hidden]="hideLiveViewButton"
    (buttonClicked)="showLiveView(formPristine, viewModel.formAutoSubmitted$, submitForms)">
    Live View
  </lib-button-neutral>
  <lib-button-primary
    *ngIf="showSaveButton"
    [infoText]="lastSavedTimeText"
    [loadingOptions]="autoSaveLoadingOpts"
    [disabled]="disableSave || formDisableSubmit"
    [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
    (buttonClicked)="submitForms.emit(false)">
    Save Changes
  </lib-button-primary>
</div>
