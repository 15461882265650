import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { ClientTypeUtils } from '../../../utils/client-type-utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { RangeSliderOptions } from '../../../models/shared/stylesheet/range-slider-options';

@Component({
  selector: 'app-reactive-form-range-slider',
  templateUrl: './reactive-form-range-slider.component.html',
  styleUrls: ['./reactive-form-range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => ReactiveFormRangeSliderComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReactiveFormRangeSliderComponent)
    }
  ],
})
export class ReactiveFormRangeSliderComponent extends ReactiveFormItemComponent
  implements ControlValueAccessor, OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() options: RangeSliderOptions = RangeSliderOptions.default();
  @Input() tooltip: string;

  private readonly _options = new BehaviorSubject<RangeSliderOptions>(this.options);
  public readonly options$ = this._options as Observable<RangeSliderOptions>;

  private readonly _sliderValue = new BehaviorSubject<number>(this.options.value);
  public readonly sliderValue$ = this._sliderValue.pipe(distinctUntilChanged());

  public readonly sliderDisabled$ = combineLatest([
    this.options$,
    this.disabled$,
  ]).pipe(
    map(([options, disabled]) => options?.disabled || disabled)
  );

  public types = ClientTypeUtils;

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.options) this._options.next(this.options);
  }

  protected override programmaticallyChangeValueHelper(newValue: any): void {
    this.sliderValueChanged(newValue);
  }

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  getValidators(): ValidatorFn[] {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    return [...validators, ...this.customValidators];
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  protected override getBindingProperty(): any {
    const bindingValue = super.getBindingProperty();
    const currentOptions = this._options.getValue();
    currentOptions.value = bindingValue;
    this._options.next(currentOptions);
    this._sliderValue.next(bindingValue);
    return bindingValue;
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  sliderValueChanged(sliderValue: number) {
    this.handleInputChange(sliderValue);
    this.getSelfAsFormItem().patchValue(sliderValue);
    this.markAsDirty();
    const currentOptions = this._options.getValue();
    currentOptions.value = sliderValue;
    this._options.next(currentOptions);
    this._sliderValue.next(sliderValue);
  }

  markAsDirty(): void {
    this.getSelfAsFormItem()?.markAsDirty();
    this.getSelfAsFormItem()?.updateValueAndValidity();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(obj: any): void {
  }

}
