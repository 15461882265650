import { Pipe, PipeTransform } from '@angular/core';
import { CompactMenu } from '../../../models/menu/dto/compact-menu';

@Pipe({
  name: 'continueIfCompactMenu'
})
export class ContinueIfCompactMenuPipe implements PipeTransform {

  transform(value: any): CompactMenu {
    return (value instanceof CompactMenu) ? value : null;
  }

}
