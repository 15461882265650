import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditTemplateMenuViewModel } from './edit-template-menu-view-model';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';
import { BaseComponent } from '../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({ selector: 'app-edit-template-menu-header', template: '' })
export class EditTemplateMenuHeaderComponent extends BaseComponent {

  constructor(
    public viewModel: EditTemplateMenuViewModel,
  ) {
    super();
  }

  @Input() headerText: string;
  @Input() crumbs: Breadcrumb[] = [];
  @Input() unsavedChanges: boolean = false;
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

}
