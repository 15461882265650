import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { DisplayDomainModel } from '../../../../../domainModels/display-domain-model';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';
import { Display, LocationDisplayGrouping } from '../../../../../models/display/dto/display';
import { Location } from '../../../../../models/company/dto/location';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class EditCollectionEditRequiredDisplaysViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  public readonly allDisplays$ = this.displayDomainModel.allCompanyDisplays$;
  public readonly allLocations$ = this.locationDomainModel.allLocations$;

  public allDisplaysWithLocationInfo$ = combineLatest([this.allDisplays$, this.allLocations$]).pipe(
    map(([displays, locations]) => {
      return displays?.map(display => {
        const location = locations?.find(loc => loc?.id === display?.locationId);
        return this.constructDisplayWithLocationInfo(display, location);
      });
    }),
  );

  private constructDisplayWithLocationInfo(display: Display, location: Location): Display {
    const displayWithLocationInfo = new class extends Display {

      locationName = location?.name;
      locationState = location?.state;
      locationAddress = location?.getFullAddress();

    };
    Object.assign(displayWithLocationInfo, display);
    return displayWithLocationInfo;
  }

  private _selectedRequiredDisplayIds = new BehaviorSubject<string[]>(null);
  public selectedRequiredDisplayIds$ = this._selectedRequiredDisplayIds as BehaviorSubject<string[]>;
  connectToSelectedRequiredDisplayIds = (selectedRequiredDisplayIds: string[]) => {
    this._selectedRequiredDisplayIds.next(selectedRequiredDisplayIds);
  };
  public nSelectedDisplayIds$ = this.selectedRequiredDisplayIds$.pipe(map(ids => ids?.length ?? 0));

  private _searchedDisplays = new BehaviorSubject<Display[]>(null);
  public searchedDisplays$ = this._searchedDisplays as BehaviorSubject<Display[]>;
  connectToSearchedDisplays = (displays: Display[]) => this._searchedDisplays.next(displays);

  public allSearchedDisplaysGroupedByLocationAndProvince$ = combineLatest([
    this.searchedDisplays$,
    this.allLocations$,
  ]).pipe(
    map(([allDisplays, allLocations]) => {
      return Display.groupDisplaysByLocationAndProvince(allDisplays, allLocations);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public readonly displaysVisibleOnScreen$ = this.searchedDisplays$.pipe(
    map(displays => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => displays?.map(display => display?.getId()) ?? [];

      }();
    })
  );

  private addToRequiredDisplayIds = (id: string) => this.selectedRequiredDisplayIds$
    .once(ids => {
      const updatedIds = [...(ids ?? []), id].unique();
      this._selectedRequiredDisplayIds.next(updatedIds);
    });

  public addDisplayIds(ids: string[]) {
    ids?.forEach(id => this.addToRequiredDisplayIds(id));
  }

  private removeFromRequiredDisplayIds = (id: string) => this.selectedRequiredDisplayIds$
    .once(ids => this._selectedRequiredDisplayIds.next(ids?.filter(currId => currId !== id)));

  public removeDisplayIds(ids: string[]) {
    ids?.forEach(id => this.removeFromRequiredDisplayIds(id));
  }

  trackByProvince(index: number, item: { name: string; locations: LocationDisplayGrouping[]}) {
    return item?.name;
  }

  trackByLocation(index: number, item: { displays: Display[]; location: Location}) {
    return item?.location?.id;
  }

  trackById(index: number, item: { key: string; value: Display[] }) {
    return `${item?.key}-${item?.value?.map(loc => loc?.id).join('-')}`;
  }

  updateRequiredDisplays() {
    this.selectedRequiredDisplayIds$.once((requiredDisplayIds) => {
      this.activeModal.close(requiredDisplayIds);
    });
  }

}
