import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'replaceDisplayWithCollection'
})
export class ReplaceDisplayWithCollectionPipe implements PipeTransform {

  transform(input: string, templateMode: boolean): string {
    if (templateMode) {
      input = StringUtils.replaceDisplayWithCollection(input);
    }
    return input;
  }

}
