import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-carousel-button',
  templateUrl: './carousel-button.component.html',
  styleUrls: ['./carousel-button.component.scss']
})
export class CarouselButtonComponent extends BaseComponent {

  @Input() disabled: boolean = false;
  @Input() icon: string;
  @Output() clicked = new EventEmitter(true);

  constructor() {
    super();
  }

}
