import { Injectable, Injector } from '@angular/core';
import { EditTemplateCardStackMenuViewModel } from '../edit-template-card-stack-menu/edit-template-card-stack-menu-view-model';
import { EditLabelStackSectionViewModel } from '../../../../menu/components/edit-menu/edit-label-stack-menu/edit-label-stack-section-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditLabelStackMenuViewModel } from '../../../../menu/components/edit-menu/edit-label-stack-menu/edit-label-stack-menu-view-model';

@Injectable()
export class EditTemplateLabelStackMenuViewModel extends EditTemplateCardStackMenuViewModel {

  /*
   * Need editCardStackMenuViewModel and editCardStackSectionViewModel injected here so that it's
   * scoped to this template. This allows child components that require these view models
   * to share the same instance.
   */
  constructor(
    protected editLabelStackMenuViewModel: EditLabelStackMenuViewModel,
    protected editLabelStackSectionViewModel: EditLabelStackSectionViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      editLabelStackMenuViewModel,
      editLabelStackSectionViewModel,
      templateDomainModel,
      locationDomainModel,
      ngbModal,
      injector
    );
  }

}
