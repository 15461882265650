import { Component } from '@angular/core';
import { LabelPriorityEditorModalViewModel } from './label-priority-editor-modal-view-model';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';

const DragConfig = {
  zIndex: 1051 // More than a modal z-index
};

@Component({
  selector: 'app-label-priority-editor-modal',
  templateUrl: './label-priority-editor-modal.component.html',
  styleUrls: ['./label-priority-editor-modal.component.scss'],
  providers: [LabelPriorityEditorModalViewModel, {provide: CDK_DRAG_CONFIG, useValue: DragConfig}],
})
export class LabelPriorityEditorModalComponent extends BaseComponent {

  constructor(
    public viewModel: LabelPriorityEditorModalViewModel
  ) {
    super();
  }

}
