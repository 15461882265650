import { EditMenuStyleModalComponent } from '../views/menu/components/modals/edit-menu-style-modal/edit-menu-style-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from '../models/product/dto/variant';
import { MenuStyle } from '../models/menu/dto/menu-style';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { Injector } from '@angular/core';

export class ModalEditMenuStyle {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    section: HydratedSection,
    variant: Variant,
    style: MenuStyle,
    palette: string[]
  ): void {
    const modalRef = ngbModal.open(
      EditMenuStyleModalComponent,
      ModalUtils.editMenuStyleModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as EditMenuStyleModalComponent;
    const siblingIds = section?.getVariantSiblingIdsInSection(variant);
    compInstance.setupModal(variant, section, style, siblingIds, palette);
  }

}
