import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { NavItem } from '../nav-item/models/nav-item';

@Injectable()
export class SidebarSubItemListViewModel extends BaseViewModel {

  private _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());

  private navItem = new BehaviorSubject<NavItem>(null);
  public isNavListActive$ = combineLatest([
    this.navItem,
    this.currentNavUrl$
  ]).pipe(
    map(([nav, url]) => url.indexOf(`${nav?.routerPath}`) === 0)
  );

  constructor(
    private router: Router,
  ) {
    super();
  }

  connectToNavItem(item: NavItem) {
    this.navItem.next(item);
  }

  connectToCurrentNavUrl(url: string) {
    this._currentNavUrl.next(url);
  }

}
