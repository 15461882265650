import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToListFilterFalsiesPipe } from './to-list-filter-falsies.pipe';

@NgModule({
  declarations: [
    ToListFilterFalsiesPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToListFilterFalsiesPipe
  ]
})
export class BasePipesModule { }
