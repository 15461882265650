<app-title-and-crumbs [headerText]="headerText" [crumbs]="crumbs">
  <div class="d-flex mt-16px" [style.gap.rem]="1">
    <lib-button-inline-text
      *ngIf="section?.templateSectionId && userIsTemplateAdmin"
      (buttonClicked)="editTemplateSectionClicked.emit(section?.templateSection)">
      {{ editTemplateSectionLinkText }}
    </lib-button-inline-text>
    <app-drop-down-menu
      [buttonClass]="'bs-regular'"
      [hidden]="hideSectionNavButton"
      [buttonText]="quickChangeSectionTitle"
      [openLeft]="true"
      [dropdownLeftMargin]="'6.9rem'"
      [dropdownTopMargin]="'-0.05rem'"
      [disabledSectionNavOptionIds]="disableSectionNavOptionIds"
      [useDivInsteadOfButton]="true"
      [sections]="sectionNavOptions"
      (output)="handleDropdownSelection.emit($event)">
    </app-drop-down-menu>
  </div>
</app-title-and-crumbs>

<div class="edit-page-header">
  <div class="edit-page-header-name-container" fxFlex>
    <app-header-title-text>{{ section?.title }}</app-header-title-text>
    <app-header-subtext>{{ headerSubtext }}</app-header-subtext>
  </div>

  <div class="edit-page-action-buttons" fxFlexAlign="flex-end">
    <lib-button-neutral
      [disabled]="!allowLiveView || formHasErrors || !formPristine"
      [disabledTooltip]="
        (formErrorMessages | getFormErrorDisabledTooltip) ||
        (!formPristine ? 'Please save changes before viewing.' : '')
      "
      [iconSrc]="'assets/icons/dark/outline/eye.svg'"
      [hidden]="hideLiveViewButton"
      (buttonClicked)="showLiveView(formPristine, formAutoSubmitted$, submitForms)">
      Live View
    </lib-button-neutral>

    <lib-button-primary
      [infoText]="lastAutoSavedText"
      [disabled]="removingVariants || disableSubmit"
      [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
      [loadingOptions]="autoSaveLoadingOpts"
      (buttonClicked)="submitForms.emit(false)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
