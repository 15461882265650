import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../../../../models/menu/dto/section';

@Pipe({
  name: 'getSectionPreviewFormattedSectionType'
})
export class GetSectionPreviewFormattedSectionTypePipe implements PipeTransform {

  transform(section: Section): string {
    return section?.getFormattedSectionType();
  }

}
