import { Component, Input } from '@angular/core';
import { TemplateStatus } from '../../../../../models/template/enum/template-status.enum';

@Component({
  selector: 'app-template-status',
  templateUrl: './template-status.component.html',
  styleUrls: ['./template-status.component.scss']
})
export class TemplateStatusComponent {

  @Input() templateStatus: TemplateStatus = null;

}
