<div class="hover-div">
  <div *ngIf="isClearable$ | async" class="clear-container">
    <div (click)="clearClicked.emit(label)" class="white-circle">
      <img class="x-icon" [src]="'assets/icons/dark/solid/x.svg'" alt="x" />
    </div>
  </div>
  <div class="label-action-card-container" [ngClass]="{ disabled: disabled || locked, reorderable: reordering }">
    <div (click)="!disabled ? emitCardClicked() : null" class="label-action-row">
      <span class="ms-2 f14px bs-bold">
        {{ cardTitle$ | async }}
      </span>
      <div class="action-container">
        <app-label-inflator
          [zoom]="0.7"
          [displayLabelInterface]="
            label | getDisplayLabelInterfaceForLabelActionCard : viewModel.configs$ : viewModel.labelStyling$ | async
          ">
        </app-label-inflator>
        <div class="action-button">
          <img class="action-icon ms-2" alt="action icon" src="{{ getCardIcon$ | async }}" />
        </div>
      </div>
    </div>
  </div>
</div>
