import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantTitle'
})
export class GetVariantTitlePipe implements PipeTransform {

  transform(variant: Variant): string {
    return variant?.getVariantTitle() ?? '';
  }

}
