import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditCollectionDetailsViewModel } from './edit-collection-details-view-model';
import { BaseDisplay } from '../../../../../models/display/dto/base-display';

@Component({
  selector: 'app-edit-collection-details',
  templateUrl: './edit-collection-details.component.html',
  styleUrls: ['./edit-collection-details.component.scss'],
  providers: [EditCollectionDetailsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionDetailsComponent extends BaseComponent {

  constructor(public viewModel: EditCollectionDetailsViewModel) {
    super();
  }

  @Input() mergeKey: string;
  @Input() bindTo: BaseDisplay;

}
