import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';
import { BaseComponent } from '../../../../../models/base/base-component';
import { StackBulkPrintJobViewModel } from './stack-bulk-print-job-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BulkPrintJobType } from '../../../../../models/automation/enum/bulk-print-job-type';
import { BulkPrintJobStep } from '../create-view-bulk-print-job-view-model';
import type { StackType } from '../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-bulk-print-job',
  templateUrl: './stack-bulk-print-job.component.html',
  providers: [StackBulkPrintJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackBulkPrintJobComponent extends BaseComponent {

  constructor(public viewModel: StackBulkPrintJobViewModel) {
    super();
  }

  @Input() @ViewModelConnector('connectToStackPrintType') stackPrintType: StackPrintType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToPlaceholder') placeholder: string;
  @Input() @ViewModelConnector('connectToSelectedBulkPrintType') selectedBulkPrintType: BulkPrintJobType;
  @Input() @ViewModelConnector('connectToStep') step: BulkPrintJobStep;
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean;
  @Input() stackType: StackType;

}
