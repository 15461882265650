<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #columnOptionsFormGroup
  [mergeKey]="viewModel?.mergeKey"
  (canSubmit$)="viewModel?.formIsValid$.next($event)"
  (unsavedChanges$)="viewModel.unsavedChangesInTab$.next($event)"
  (formsAutoSubmitted$)="viewModel.saveChangesWithinModal()"
  (formsSubmitted$)="viewModel.submitForms()">
</lib-reactive-form-merge-groups>

<div
  class="modal-body sticky column-options-modal-body"
  [style.height.px]="modalBodyHeight$ | async"
  [style.max-height.px]="modalBodyHeight$ | async">
  <div
    #modalHeader
    appStickyAutoPosition
    [stickyCollectionKey]="'column-options-modal'"
    [stickyZ]="20"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border column-options-modal-header-container">
      <div class="modal-title">
        {{ (viewModel?.managingDefault$ | async) ? 'Default Column Options' : 'Column Options' }}
        <img
          [ngbTooltip]="viewModel?.message"
          [container]="'body'"
          [src]="'assets/icons/dark/outline/information-circle.svg'"
          alt="" />
      </div>
      <div *ngIf="!(viewModel?.managingDefault$ | async)">
        <app-reset-column-options
          [themeDefault]="viewModel?.themeColumnConfig$ | async"
          (resetClicked)="viewModel.resetToDefaultColumnConfig($event)"></app-reset-column-options>
      </div>
    </div>
    <div *ngIf="viewModel?.managingDefault$ | async">
      <lib-reactive-form-group [bindTo]="viewModel?.defaultSectionConfig$ | async" [mergeKey]="'column-options-form'">
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-text
            [inputName]="'defaultOptionsName'"
            [label]="'Default Options Name'"
            [placeholder]="'Enter a name for the default options'"
            [bindingProperty]="'name'"
            [required]="viewModel?.managingDefault$ | async"
            [tooltip]="'The name of the Default Column Options'">
          </lib-reactive-form-text>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
    </div>
  </div>

  <div
    #contentContainer
    class="sticky-body-container column-option-modal-body-container"
    appStickyAutoPosition
    [stickyCollectionKey]="'column-options-modal'"
    [stickChildClassInstead]="'mat-tab-header'"
    [stickyZ]="10">
    <app-tab-bar
      #tabController
      [tabs]="tabs$ | async"
      [skipFragmentAppending]="true"
      [useDefaultHeight]="false"
      [showLoadingSpinnerOnTabChange]="true"
      [loadingTimeMs]="500"
      [delayTabClick]="100"
      [canChangeTabs]="viewModel.canChangeTabs$ | async"
      [canChangeTabsErrorMsg]="canChangeTabsErrorMsg | async"
      (previousTab)="setPreviousTab($event); checkForChanges()"
      (selectedTab)="setCurrentTab($event)"
      class="flex-fill"></app-tab-bar>
  </div>

  <div
    #footerContainer
    class="sticky-footer-container column-option-modal-footer-container"
    [style.position]="viewModel.bottomButtonPosition$ | async">
    <div *ngIf="this.viewModel?.columnsWidthTotalError$ | async" class="error-banner-container">
      <app-error-banner [message]="viewModel?.columnsWidthTotalErrorMessage$ | async"></app-error-banner>
    </div>
    <div class="button-holder">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary
        [disabled]="!(viewModel.canSubmitForm$ | async)"
        (buttonClicked)="columnOptionsFormGroup.submitForms()">
        Done
      </lib-button-primary>
    </div>
  </div>
</div>
