import { TypeDefinition } from './type-definition';

/**
 * Do not delete this enum. The enum is used to BUILD properties on display-attributes and smart-filters.
 * Order matters here. It is used to sort the terpenes in the UI.
 * This list is accessed via Object.values(Terpene).
 */
export enum Terpene {
  AlphaBisabolol = 'Alpha Bisabolol',
  AlphaCaryophyllene = 'Alpha Caryophyllene',
  AlphaCedrene = 'Alpha Cedrene',
  AlphaHumulene = 'Alpha Humulene',
  AlphaMyrcene = 'Alpha Myrcene',
  AlphaPinene = 'Alpha Pinene',
  AlphaPhellandrene = 'Alpha Phellandrene',
  AlphaSantalene = 'Alpha Santalene',
  AlphaTerpinene = 'Alpha Terpinene',
  AlphaTerpineol = 'Alpha Terpineol',
  BetaCaryophyllene = 'Beta Caryophyllene',
  BetaEudesmol = 'Beta Eudesmol',
  BetaMyrcene = 'Beta Myrcene',
  BetaOcimene = 'Beta Ocimene',
  BetaPinene = 'Beta Pinene',
  Bergamotene = 'Bergamotene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Cadinene = 'Cadinene',
  Camphene = 'Camphene',
  Camphor = 'Camphor',
  Carene = 'Carene',
  CaryophylleneOxide = 'Caryophyllene Oxide',
  Carvacrol = 'Carvacrol',
  Carvone = 'Carvone',
  Caryophyllene = 'Caryophyllene',
  Cedrene = 'Cedrene',
  Cedrol = 'Cedrol',
  CisNerolidol = 'Cis Nerolidol',
  CisOcimene = 'Cis Ocimene',
  Citral = 'Citral',
  Citronellol = 'Citronellol',
  Cymene = 'Cymene',
  Cymenene = 'Cymenene',
  Delta3Carene = 'Delta 3 Carene',
  DeltaLimonene = 'Delta Limonene',
  EndoFenchyl = 'Endo Fenchyl',
  Eucalyptol = 'Eucalyptol',
  Eudesmols = 'Eudesmols',
  Eugenol = 'Eugenol',
  Farnesene = 'Farnesene',
  Farnesol = 'Farnesol',
  Fenchol = 'Fenchol',
  Fenchone = 'Fenchone',
  Fenchyl = 'Fenchyl',
  Geraniol = 'Geraniol',
  GeranylAcetate = 'Geranyl Acetate',
  Germacrene = 'Germacrene',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Isopulegol = 'Isopulegol',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Myrcene = 'Myrcene',
  Nerol = 'Nerol',
  Nerolidol = 'Nerolidol',
  Ocimene = 'Ocimene',
  ParaCymenene = 'Para Cymenene',
  Phellandrene = 'Phellandrene',
  Phytol = 'Phytol',
  Pinene = 'Pinene',
  Pulegone = 'Pulegone',
  Sabinene = 'Sabinene',
  Santalene = 'Santalene',
  ThreeCarene = 'Three Carene',
  Terpinene = 'Terpinene',
  Terpineol = 'Terpineol',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'Trans Nerolidol',
  TransCaryophyllene = 'Trans Caryophyllene',
  Valencene = 'Valencene',
}

export class TerpeneTypeDefinition extends TypeDefinition {

  override value: Terpene;

}
