import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditTemplateMenuSectionComponent } from '../../../../edit-template-menu-section/edit-template-menu-section.component';
import { EditTemplateFeaturedCategorySectionViewModel } from './edit-template-featured-category-section-view-model';
import { EditFeaturedCategorySectionViewModel } from '../../../../../../menu/components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section-view-model';
import { EditTemplateFeaturedCategorySectionCombinedLoadingOptsViewModel } from './edit-template-featured-category-section-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-template-featured-category-section',
  templateUrl: './edit-template-featured-category-section.component.html',
  styleUrls: [
    './edit-template-featured-category-section.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [
    EditTemplateFeaturedCategorySectionViewModel,
    EditFeaturedCategorySectionViewModel,
    EditTemplateFeaturedCategorySectionCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateFeaturedCategorySectionComponent extends EditTemplateMenuSectionComponent {

  constructor(
    public override viewModel: EditTemplateFeaturedCategorySectionViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateFeaturedCategorySectionCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

  @Input() override dispersedAndMergeKey = 'editTemplatedFeaturedCategorySection';

}
