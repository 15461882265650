import { Component, Input, ViewChild } from '@angular/core';
import { InformationItem } from '../../../../../../../models/shared/information-item';
import { map } from 'rxjs/operators';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';
import { ReactiveFormSwitchComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-menu-print-options-form',
  templateUrl: './menu-print-options-form.component.html',
  styleUrls: ['./menu-print-options-form.component.scss']
})
export class MenuPrintOptionsFormComponent extends MenuAdditionalOptionsFormComponent {

  constructor() {
    super();
  }

  @ViewChild('singlePageMenuSwitch') singlePageMenuSwitch: ReactiveFormSwitchComponent;

  @Input() singlePageMenuSwitchDisabled: boolean = false;
  @Input() templateMode: boolean = false;
  @Input() needsToBeSinglePage: boolean = false;

  public printHeaderTypes$ = window?.types?.printHeaderLayouts$;
  public printFooterTypes$ = window?.types?.printFooterLayouts$;
  public headerTooltips$ = this.printHeaderTypes$.pipe(
    map(types => {
      return types.map(t => new InformationItem(t?.name, t?.getSelectionInformationDescription()));
    })
  );
  public footerTooltips$ = this.printFooterTypes$.pipe(
    map(types => {
      return types.map(t => new InformationItem(t?.name, t?.getSelectionInformationDescription()));
    })
  );

}
