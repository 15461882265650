import { Component } from '@angular/core';
import { PrintLabelsViewModel } from './print-labels-view-model';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-print-labels',
  templateUrl: './print-labels.component.html',
  styleUrls: ['./print-labels.component.scss'],
  providers: [PrintLabelsViewModel]
})
export class PrintLabelsComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintLabelsViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

  override setupBindings(): void {
  }

  override setupViews(): void {
  }

}
