import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProductGroupingTableViewModel } from './product-grouping-table-view-model';
import { BaseComponent } from '../../../models/base/base-component';
import { ReactiveTableComponent } from '@mobilefirstdev/reactive-table';
import { OverrideProductGroup } from '../../../models/product/dto/override-product-group';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-product-grouping-table',
  templateUrl: './product-grouping-table.component.html',
  styleUrls: ['./product-grouping-table.component.scss'],
  providers: [ProductGroupingTableViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupingTableComponent extends BaseComponent {

  constructor(
    public viewModel: ProductGroupingTableViewModel
  ) {
    super();
  }

  @ViewChild(ReactiveTableComponent) table: ReactiveTableComponent;
  @Input() @ViewModelConnector('connectToSidePanelIsOpen') sidePanelIsOpen: boolean = false;
  @Input() overrideProductGroupings: OverrideProductGroup[];
  @Input() nItemsToDisplay: number = 25;
  @Input() resetPageOnNewData: boolean = false;
  @Input() resetPageOnSortingChange: boolean = true;
  @Input() searchTextAndHits: [string, any[]];
  @Output() clearSearch = new EventEmitter<void>(true);
  @Output() overrideProductGroupSelected = new EventEmitter<OverrideProductGroup>(true);

  override setupBindings(): void {
    this.viewModel.groupSelected$.subscribeWhileAlive({
      owner: this,
      next: (group: OverrideProductGroup) => this.overrideProductGroupSelected.emit(group)
    });
  }

  toggleChangeDetection(): void {
    this.table?.fireChangeDetectionForEntireTable();
  }

  resetPageNumber(): void {
    this.table?.resetPageNumber();
  }

}
