import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Product } from '../../../../models/product/dto/product';
import type { Variant } from '../../../../models/product/dto/variant';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-table-badges',
  templateUrl: './table-badges.component.html',
  styleUrls: ['./table-badges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableBadgesComponent extends BaseComponent {

  @Input() item: Product | Variant;
  @Input() badges: HydratedVariantBadge[];

}
