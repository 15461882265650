<app-menu-details-section [templateMode]="templateMode">
  <app-edit-menu-warning-banner
    [warningMessage]="viewModel.menuWarningMessage$ | async"
    [tooltipMessage]="viewModel.menuTooltipWarningMessage$ | async">
  </app-edit-menu-warning-banner>

  <app-menu-additional-options
    #menuAdditionalOptions
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | async"
    [bindTo]="viewModel.menu$ | async"
    (iFrameHeightChanged)="viewModel.iFrameHeightChanged($event)"
    (iFrameWidthChanged)="viewModel.iFrameWidthChanged($event)">
  </app-menu-additional-options>

  <app-print-menu-section-details-actions [templateMode]="templateMode"> </app-print-menu-section-details-actions>

  <app-menu-links
    *ngIf="!templateMode"
    [templateMode]="templateMode"
    [iFrameHeight]="menuAdditionalOptions.iFrameHeightChanged | async"
    [iFrameWidth]="menuAdditionalOptions.iFrameWidthChanged | async"
    [menu]="viewModel.menu$ | async">
  </app-menu-links>
</app-menu-details-section>
