import { Component, ViewChild } from '@angular/core';
import { WebTemplatesViewModel } from './web-templates-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-web-templates',
  templateUrl: './web-templates.component.html',
  styleUrls: ['./web-templates.component.scss'],
  providers: [WebTemplatesViewModel]
})
export class WebTemplatesComponent extends BaseComponent {

  constructor(
    public viewModel: WebTemplatesViewModel,
    private activatedRoute: ActivatedRoute,

  ) {
    super();
  }

  @ViewChild('tabController') tabController: TabBarComponent;

  override setupBindings(): void {
    // Capture the fragment if available and navigates to a specific tab
    const fragSub = this.activatedRoute.fragment.notNull().subscribe(fragment => {
      switch (fragment?.toLowerCase()) {
        case 'producttemplates': this.tabController.tabSelected(0); break;
      }
    });
    this.pushSub(fragSub);
  }

}
