import { Injectable } from '@angular/core';
import { EditTemplateMarketingPlaylistMenuViewModel } from './edit-template-marketing-playlist-menu-view-model';
import { EditPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu-view-model';
import { EditTemplateMarketingMenuCombinedLoadingOptsViewModel } from '../edit-template-marketing-menu-combined-loading-opts-view-model';

@Injectable()
export class EditTemplateMarketingPlaylistMenuCombinedLoadingOptsViewModel
  extends EditTemplateMarketingMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingPlaylistMenuViewModel: EditTemplateMarketingPlaylistMenuViewModel,
    protected editPlaylistMenuViewModel: EditPlaylistMenuViewModel,
  ) {
    super(editTemplateMarketingPlaylistMenuViewModel, editPlaylistMenuViewModel);
  }

}
