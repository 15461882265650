export class CheckboxContainerOptions {

  public hideBulkCheckbox: boolean = false;
  public checkAllText: string = 'Check All';
  public inlineOptions: boolean = false;
  public requireMinimumSelection: number = 0;
  public touched: boolean = false;
  public customCheckboxMargin: string;

}

