import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';

@Component({
  selector: 'app-edit-display-header',
  templateUrl: './edit-display-header.component.html',
  styleUrls: [
    './edit-display-header.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayHeaderComponent extends BaseComponent {

  @Input() headerText: string = '';
  @Input() headerSubtext: string = '';
  @Input() disableSave: boolean = false;
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() lastSavedTimeText: string = '';
  @Input() mergeKey: string;
  @Input() formPristine: boolean = true;
  @Output() submitForms = new EventEmitter<boolean>(true);

  constructor(public viewModel: EditDisplayViewModel) {
    super();
  }

}
