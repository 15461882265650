import { TypeDefinition } from './type-definition';
import { Infoable } from '../../protocols/infoable';

export enum PrintHeaderLayout {
  None = 'None',
  FirstPage = 'FirstPage',
  AllPages = 'AllPages',
}

export class PrintHeaderLayoutTypeDefinition extends TypeDefinition implements Infoable {

  override value: PrintHeaderLayout;

  getSelectionInformationDescription(...args: any): string {
    switch (this.value) {
      case PrintHeaderLayout.None:
        return 'No header will be added to your print menu.';
      case PrintHeaderLayout.FirstPage:
        return 'The header will be added to the top for only the first page.';
      case PrintHeaderLayout.AllPages:
        return 'The header will be added to the top of every page.';
    }
  }

}
