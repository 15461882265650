import { Pipe, PipeTransform } from '@angular/core';
import { DisplayMenuOptions } from '../../../models/display/shared/display-menu-options';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getMenuOptionsForDisplay'
})
export class GetMenuOptionsForDisplayPipe implements PipeTransform {

  transform(
    menuOptions$: Observable<Map<string, Map<string, DisplayMenuOptions>>>,
    containerId: string,
    menuId: string
  ): Observable<DisplayMenuOptions> {
    return menuOptions$.pipe(
      map((opts) => opts?.get(containerId)?.get(menuId))
    );
  }

}
