import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ReactiveFormDropDownComponent, ReactiveFormTextComponent } from '@mobilefirstdev/reactive-form';
import { Product } from '../../../../models/product/dto/product';
import { FilterProductsFormViewModel } from './filter-products-form-view-model';
import { Variant } from '../../../../models/product/dto/variant';

@Component({
  selector: 'app-filter-products-form',
  templateUrl: './filter-products-form.component.html',
  styleUrls: ['./filter-products-form.component.scss'],
  providers: [FilterProductsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterProductsFormComponent extends BaseComponent implements OnChanges {

  @ViewChild('searchBar') searchBar!: ReactiveFormTextComponent;
  @ViewChild('filterByProductType') filterByProductType!: ReactiveFormDropDownComponent;
  @ViewChild('filterByVariantType') filterByVariantType!: ReactiveFormDropDownComponent;
  @ViewChild('filterByStrainType') filterByStrainType!: ReactiveFormDropDownComponent;
  @Input() hideRecentlyUpdatedProductsMode: boolean = false;
  @Input() includeBottomBorder: boolean = true;
  @Input() products: Product[];
  @Input() recentlyUpdatedTimeWindowInSeconds: number = 0;
  @Output() hasFilters: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() numberOfProductsPerPage = new EventEmitter<number>(true);
  @Output() productsFilteredByProductTypeVariantTypeStrainType = new EventEmitter<Product[]>(true);
  @Output() variantsFilteredByProductTypeVariantTypeStrainTypeRecentlyUpdated = new EventEmitter<Variant[]>(true);
  @Output() resetPageNumber: EventEmitter<void> = new EventEmitter<void>(true);
  @Output() searchText: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() hideRecentlyUpdated: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() hideOutOfStock: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    public viewModel: FilterProductsFormViewModel,
  ) {
    super();
  }

  override setupViews(): void {
    this.viewModel.setHideRecentlyUpdatedProductsMode(this.hideRecentlyUpdatedProductsMode);
    this.viewModel.setRecentlyUpdatedTimeWindow(this.recentlyUpdatedTimeWindowInSeconds);
    if (!!this.products) this.viewModel.setProducts(this.products);
  }

  override setupBindings(): void {
    // set outputs
    this.viewModel.hasFilters$.subscribeWhileAlive({
      owner: this,
      next: hasFilters => this.hasFilters.emit(hasFilters)
    });
    this.viewModel.productsFilteredByProductTypeVariantTypeStrainType$.subscribeWhileAlive({
      owner: this,
      next: products => this.productsFilteredByProductTypeVariantTypeStrainType.emit(products)
    });
    this.viewModel.numberOfProductsPerPage$.subscribeWhileAlive({
      owner: this,
      next: numberOfProductsPerPage => this.numberOfProductsPerPage.emit(numberOfProductsPerPage)
    });
    this.viewModel.variantsFilteredByProductTypeVariantTypeStrainTypeRecentlyUpdated$.subscribeWhileAlive({
      owner: this,
      next: variants => this.variantsFilteredByProductTypeVariantTypeStrainTypeRecentlyUpdated.emit(variants)
    });
    this.viewModel.searchText$.subscribeWhileAlive({
      owner: this,
      next: searchText => this.searchText.emit(searchText)
    });
    this.viewModel.hideRecentlyUpdatedProducts$.subscribeWhileAlive({
      owner: this,
      next: hideRecentlyUpdated => this.hideRecentlyUpdated.emit(hideRecentlyUpdated)
    });
    this.viewModel.hideOutOfStockProducts$.subscribeWhileAlive({
      owner: this,
      next: hideOutOfStock => this.hideOutOfStock.emit(hideOutOfStock)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.hideRecentlyUpdatedProductsMode) {
      this.viewModel.setHideRecentlyUpdatedProductsMode(this.hideRecentlyUpdatedProductsMode);
    }
    if (!!changes?.recentlyUpdatedTimeWindowInSeconds) {
      this.viewModel.setRecentlyUpdatedTimeWindow(this.recentlyUpdatedTimeWindowInSeconds);
    }
    if (!!changes?.products) {
      this.viewModel.setProducts(this.products);
    }
  }

  public clearSearchAndFilters(): void {
    this.clearSearch();
    this.clearFilters();
  }

  public clearFilters(): void {
    this.filterByProductType?.clear(true);
    this.filterByVariantType?.clear(true);
    this.filterByStrainType?.clear(true);
  }

  public clearSearch(): void {
    this.searchBar?.getSelfAsFormItem()?.setValue('');
  }

  public toggleRecentlyUpdated(): void {
    this.viewModel.toggleRecentlyUpdatedProducts();
  }

  public setHideOutOfStockProducts(hideOutOfStockProducts: boolean) {
    this.viewModel.setHideOutOfStockProducts(hideOutOfStockProducts);
  }

}
