import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { Observable } from 'rxjs';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { map } from 'rxjs/operators';
import { HydratedSection } from '../../../models/menu/dto/hydrated-section';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';

@Pipe({
  name: 'firstSectionNVariants'
})
export class FirstSectionNVariantsPipe implements PipeTransform {

  constructor(
    private productDomainModel: ProductDomainModel,
  ) {
  }

  transform(menu: Menu, locationConfig: LocationConfiguration, nSelected: number): Observable<string> {
    return this.productDomainModel.currentLocationVariants$.pipe(
      map(currentLocationVariants => {
        const section =  menu?.getSectionsBasedOnMenuType()?.firstOrNull() as HydratedSection;
        const products = section?.products;
        const sectionVariants = section?.enabledVariantIds?.map(id => currentLocationVariants?.find(v => v?.id === id));
        const priceFormat = locationConfig?.priceFormat;
        const sectionLevelOverflow = menu?.isProductMenuWithSectionLevelOverflow();
        const vIds = section
          ?.getScopedVisibleVariants(products, sectionVariants, menu, priceFormat, sectionLevelOverflow)
          ?.map(v => v?.id)
          ?.filterNulls();
        const variants = vIds
          ?.map(id => currentLocationVariants?.find(v => v?.id === id))
          ?.filterNulls();
        const n = variants?.length ?? 0;
        let nVariantsString = '';
        if (nSelected > 0) nVariantsString += `${nSelected} of `;
        nVariantsString += `${n} variant`;
        return nVariantsString
          .pluralizer()
          .addRule({ isPlural: n > 1, listConnection: null, useApostrophe: false, word: 'variant' })
          .pluralize();
      })
    );
  }

}
