import { Injectable } from '@angular/core';
import { EditMenuSectionViewModel } from '../../../../../viewModels/edit-menu-section-view-model';
import { BehaviorSubject, combineLatest, defer, Observable, of } from 'rxjs';
import { AssetPreviewOptions } from '../../../../../../../models/shared/stylesheet/asset-preview-options';
import { ChangeMediaOptions } from '../../../../../../../models/shared/stylesheet/change-media-options';
import { take, tap } from 'rxjs/operators';
import { BudsenseFile } from '../../../../../../../models/shared/budsense-file';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../../../utils/menu-interval-utils';
import { SectionTemplate } from '../../../../../../../models/template/dto/section-template';
import { MenuTemplate } from '../../../../../../../models/template/dto/menu-template';

@Injectable()
export class EditSmartPlaylistMenuSectionViewModel extends EditMenuSectionViewModel {

  public override allowPrimarySectionMedia$ = of(true);
  public override sectionMediaPreview$ = of(this.createAssetPreviewOptions());

  public override isProductSection$ = of(true);
  public override hideProductSection$ = of(false);

  public override handleChangeSectionAsset(isSecondaryImage: boolean = false) {
    super.handleChangeSectionAsset(isSecondaryImage);
  }

  protected override getChangeSectionAssetOptions(): ChangeMediaOptions {
    const opts = super.getChangeSectionAssetOptions();
    opts.allowVideo$ = new BehaviorSubject(true);
    return opts;
  }

  private createAssetPreviewOptions(): AssetPreviewOptions {
    const sectionImagePreviewOptions = new AssetPreviewOptions();
    sectionImagePreviewOptions.primaryButtonText = 'Remove Media';
    sectionImagePreviewOptions.primaryButtonDestructive = true;
    sectionImagePreviewOptions.secondaryButtonText = 'Change Media';
    return sectionImagePreviewOptions;
  }

  public override uploadSectionAsset(f: BudsenseFile, isSecondaryImage: boolean = false): Observable<string[]> {
    return super.uploadSectionAsset(f, isSecondaryImage).pipe(
      tap(() => this.updateMenuOptionsAfterAssetChange(f)),
    );
  }

  private updateMenuOptionsAfterAssetChange(file: BudsenseFile) {
    combineLatest([this.menu$, this.section$]).once(([menu, section]) => {
      const updateMenu$ = defer(() => {
        return section instanceof SectionTemplate
          ? this.templateDomainModel.saveMenuTemplate(menu as MenuTemplate)
          : this.menuDomainModel.saveMenu(menu);
      });
      if (file.isImage()) {
        menu?.options?.rotationInterval.set(section.id, DEFAULT_ROTATION_INTERVAL);
        updateMenu$.pipe(take(1)).subscribe();
        return;
      }
      const video = document.createElement('video');
      video.src = file.url as string;
      video.preload = 'metadata';
      video.load();
      video.onloadedmetadata = () => {
        menu?.options?.rotationInterval.set(section.id, Number(video.duration.toFixed(2)));
        updateMenu$.pipe(take(1)).subscribe();
      };
    });
  }

}

