<div
  class="range-container"
  [class.disabled]="disabled || options?.disabled"
  [ngbTooltip]="disabledTooltip"
  [class.start-or-end-label]="options?.labelStart || options?.labelEnd">
  <label [hidden]="options.sliderOnly" class="control-label bs-textfield-label range-inline-label" for="range-input">
    {{ options.label }}
  </label>
  <output [hidden]="options.sliderOnly" class="bs-range-output">
    {{ options.value }}
  </output>
  <div *ngIf="options?.labelStart">{{ options?.labelStart }}</div>
  <mat-slider
    [disabled]="options.disabled || disabled"
    [max]="options.max"
    [min]="options.min"
    [step]="options.step"
    [showTickMarks]="options.showTickMarks"
    [style.width]="options.displayWidth"
    [discrete]="options.showThumbLabel"
    class="bs-range">
    <input matSliderThumb id="range-input" [value]="options.value" (valueChange)="updateRangeValue($event)" />
  </mat-slider>
  <div *ngIf="options?.labelEnd">{{ options?.labelEnd }}</div>
</div>
