import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { CompactMenu } from '../../../../../models/menu/dto/compact-menu';
import { MenuAddedToDisplayFormViewModel } from './menu-added-to-display-form-view-model';
import { DisplayMenuOptions } from '../../../../../models/display/shared/display-menu-options';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { ReactiveFormRangeSliderComponent } from '../../../../../modules/reactive-form-extensions/reactive-form-range-slider/reactive-form-range-slider.component';
import { ReactiveFormNumberComponent } from '@mobilefirstdev/reactive-form';
import { SegmentedControlOption } from '../../../../../models/shared/stylesheet/segmented-control-option';
import { delay } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-menu-added-to-display-form',
  templateUrl: './menu-added-to-display-form.component.html',
  styleUrls: ['./menu-added-to-display-form.component.scss'],
  providers: [MenuAddedToDisplayFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuAddedToDisplayFormComponent extends BaseComponent {

  @Input() mergeKey: string;
  @Input() isLooping: boolean = null;
  @Input() @ViewModelConnector('connectToBindTo') bindTo: DisplayMenuOptions;
  @Input() @ViewModelConnector('connectToMenu') menu: CompactMenu;
  @Input() @ViewModelConnector('connectToNMenusOnDisplay') nMenusOnDisplay: number = null;
  @Input() @ViewModelConnector('connectToLoopDuration') loopDuration: number;
  @Input() @ViewModelConnector('connectToMenuIsPartOfCollection') menuIsPartOfCollection = false;
  @ViewChild('rangeSlider') rangeSlider: ReactiveFormRangeSliderComponent;
  @ViewChild('displayDuration') displayDuration: ReactiveFormNumberComponent;

  constructor(public viewModel: MenuAddedToDisplayFormViewModel) {
    super();
  }

  /**
   * We need to mark the form items as dirty when the user switches segments, so that "save changes" enables.
   */
  override setupBindings() {
    this.viewModel.segmentedControlSelectionValue$
      .pipe(delay(200))
      .subscribeWhileAlive({
        owner: this,
        next: (value) => {
          let formItem: UntypedFormControl;
          switch (true) {
            case value === 'Manual':
              formItem = this.displayDuration?.getSelfAsFormItem();
              break;
            case value === 'Auto':
              formItem = this.rangeSlider?.getSelfAsFormItem();
              break;
          }
          formItem?.markAsDirty();
          formItem?.updateValueAndValidity();
        }
      });
  }

  connectToSegmentedControlSelection(selections: SegmentedControlOption[]): void {
    const selection = selections?.firstOrNull();
    switch (true) {
      case selection?.value === 'Manual':
        this.viewModel.previouslyKnownScrollSpeed = this.rangeSlider?.getMyValue();
        break;
      case selection?.value === 'Auto':
        this.viewModel.previouslyKnownDisplayDuration = this.displayDuration?.getMyValue();
        break;
    }
    this.viewModel.connectToSegmentedControlSelection(selections);
  }

}
