<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>
<div [hidden]="isHidden">
  <div
    (fileDropped)="onFileDropped($event)"
    [hidden]="!allowImage && !allowVideo"
    [class.animating]="animating$ | async"
    appDnd
    class="bs-upload-area dark-animation">
    <form
      class="bs-upload-form"
      [ngClass]="{ disabled: isDisabledByTemplates || !formPristine }"
      [ngbTooltip]="
        (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
      ">
      <img src="/assets/icons/dark/solid/document-duplicate.svg" class="bs-upload-icon" alt="manage" />
      <span class="bs-upload-text">
        <label
          [ngClass]="{
            'bs-upload-label': !isDisabledByTemplates || formPristine,
            'bs-upload-disabled': isDisabledByTemplates || !formPristine
          }"
          for="fileDropRef{{ id }}">
          Upload a File
        </label>
        or Drag and Drop
      </span>
      <span *ngIf="allowImage && allowVideo" class="bs-upload-text secondary-upload-text">
        JPG, PNG, WEBP & BMP up to 10MB - MOV, MP4 & WEBM up to 75MB
      </span>
      <span *ngIf="allowImage && !allowVideo" class="bs-upload-text secondary-upload-text">
        JPG, PNG, WEBP & BMP up to 10MB
      </span>
      <span *ngIf="!allowImage && allowVideo" class="bs-upload-text secondary-upload-text">
        MOV, MP4 & WEBM up to 75MB
      </span>
      <input
        type="file"
        #fileDropRef
        class="bs-file-input"
        id="fileDropRef{{ id }}"
        name="fileDropRefName{{ id }}"
        multiple
        [accept]="viewModel.accept"
        [disabled]="isDisabledByTemplates || !formPristine"
        (change)="viewModel.fileBrowseHandler($event.target)" />
    </form>
  </div>
  <div [ngStyle]="{ padding: displayList ? '1.25rem 0' : '1rem 0' }" class="bs-upload-list-container">
    <ul *ngIf="viewModel.files.length > 0 && displayList" class="bs-upload-list">
      <app-upload-item *ngFor="let item of viewModel.files" [uploadItemInterface]="viewModel" [file]="item">
      </app-upload-item>
    </ul>
  </div>
</div>
