<div class="auth-flow-wrapper">
  <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

  <div class="auth-flow-container">
    <div class="auth-flow-title mb-8px">
      {{ viewModel.getAuthFlowTitle() }}
    </div>

    <lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
      <lib-reactive-form-email
        [inputName]="'email'"
        [label]="'Email'"
        [bindingProperty]="'email'"
        [required]="true"
        [showRequiredAstrix]="false"
        [placeholder]="'Enter your email'">
      </lib-reactive-form-email>

      <lib-reactive-form-password
        [inputName]="'password'"
        [required]="true"
        [label]="'Password'"
        [placeholder]="'Enter your password'"
        (keydown.enter)="form.submitForm()"
        [bindingProperty]="'password'">
      </lib-reactive-form-password>

      <lib-reactive-form-checkbox
        [inputName]="'rememberMe'"
        [disabled]="false"
        [selectedValue]="true"
        [notSelectedValue]="false"
        [bindingProperty]="'rememberMe'">
        Remember me
      </lib-reactive-form-checkbox>
    </lib-reactive-form-group>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-24px">
      <lib-button-primary [disabled]="form.hasErrors$ | async" (buttonClicked)="form.submitForm()">
        Continue
      </lib-button-primary>

      <lib-button-text (buttonClicked)="secondaryButtonPressed()"> Forgot Password? </lib-button-text>
    </div>
  </div>
</div>
