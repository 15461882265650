import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from '../../../../../../../../../models/product/dto/product';
import { map } from 'rxjs/operators';

@Injectable()
export class CardStackPrintJobSelectIndividualProductViewModel extends BaseViewModel {

  private _product = new BehaviorSubject<Product>(null);
  public product$ = this._product as Observable<Product>;
  connectToProduct = (product: Product) => this._product.next(product);

  private _isGridMode = new BehaviorSubject<boolean>(false);
  public isGridMode$ = this._isGridMode as Observable<boolean>;
  connectToIsGridMode = (isGridMode: boolean) => this._isGridMode.next(isGridMode);

  private _gridColumnNames = new BehaviorSubject<string[]>([]);
  public gridColumnNames$ = this._gridColumnNames as Observable<string[]>;
  connectToGridColumnNames = (gridColumnNames: string[]) => this._gridColumnNames.next(gridColumnNames);

  /**
   * product$ contains a shallow copy of the product with an updated visible variant list,
   * so we can just directly map to variants.
   */
  public variants$ = this.product$.pipe(
    map(product => product?.variants)
  );

}
