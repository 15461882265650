// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { delay, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { ProductDomainModel } from '../../../../../../../domainModels/product-domain-model';
import { HydratedSmartFilter } from '../../../../../../../models/automation/hydrated-smart-filter';
import { SmartFiltersDomainModel } from '../../../../../../../domainModels/smart-filters-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { SmartFilterUtils } from '../../../../../../../utils/smart-filter-utils';
import { ConfirmationOptions } from '../../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../../modals/modal-confirmation';
import { UserDomainModel } from '../../../../../../../domainModels/user-domain-model';

export const BUDSENSE_CURATED_ID = -1;

@Injectable()
export class AddEditSmartFilterViewModel extends BaseViewModel {

  constructor(
    protected activeModal: NgbActiveModal,
    private userDomainModel: UserDomainModel,
    private locationDomainModel: LocationDomainModel,
    private productDomainModel: ProductDomainModel,
    private smartFilterDomainModel: SmartFiltersDomainModel,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private injector: Injector,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private isCompanyAdmin$ = this.userDomainModel.isCompanyAdmin$;

  // Edit Form
  public priceFormat$ = this.locationDomainModel.priceFormat$;
  private _existingSmartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public existingSmartFilter$ = this._existingSmartFilter as Observable<HydratedSmartFilter>;
  connectToExistingSmartFilter = (existingSmartFilter: HydratedSmartFilter) => {
    this._existingSmartFilter.next(existingSmartFilter);
    this.connectToIgnoredVariantIds(existingSmartFilter?.ignoredVariantIds);
  };
  private _changesMade = new BehaviorSubject<boolean>(false);
  public changesMade$ = this._changesMade as Observable<boolean>;

  private _previewGenerated = new BehaviorSubject<boolean>(false);
  public previewGenerated$ = this._previewGenerated as Observable<boolean>;

  private _variantVisibilityToggled = new BehaviorSubject<boolean>(false);
  public variantVisibilityToggled$ = this._variantVisibilityToggled as Observable<boolean>;
  setVariantVisibilityToggled = (variantVisibilityToggled: boolean) => {
    this._variantVisibilityToggled.next(variantVisibilityToggled);
    if (variantVisibilityToggled) {
      this._previewGenerated.next(false);
      this._canSubmitForm.next(true);
    }
  };

  private _canSubmitForm = new BehaviorSubject<boolean>(false);
  public canSubmitForm$ = this._canSubmitForm as Observable<boolean>;
  setCanSubmit = (canSubmit: boolean) => {
    this._canSubmitForm.next(canSubmit);
  };

  private _formHasBeenSubmitted = new BehaviorSubject<boolean>(false);
  public formHasBeenSubmitted$ = this._formHasBeenSubmitted as Observable<boolean>;

  private _generatedInModal = new BehaviorSubject<boolean>(false);
  public generatedInModal$ = this._generatedInModal as Observable<boolean>;

  private _hydrateObject = new BehaviorSubject<boolean>(false);
  public hydrateObject$ = this._hydrateObject as Observable<boolean>;

  // Opened From
  public _openedFrom = new BehaviorSubject<AddEditSmartFilterModalOpenedFrom>(null);
  public openedFrom$ = this._openedFrom as Observable<AddEditSmartFilterModalOpenedFrom>;
  connectToOpenedFrom = (openedFrom: AddEditSmartFilterModalOpenedFrom) => this._openedFrom.next(openedFrom);

  public contextRequiresCompanySmartFilter$ = this.openedFrom$.pipe(
    map(openedFrom => SmartFilterUtils.contextRequiresCompanySmartFilter(openedFrom))
  );

  public isOpenedFromSectionEdit$ = this.openedFrom$.pipe(
    map(openedFrom => openedFrom === AddEditSmartFilterModalOpenedFrom.SectionEdit)
  );

  public isOpenedFromTemplatedSectionFlow$ = this.openedFrom$.pipe(
    map(openedFrom => {
      return openedFrom === AddEditSmartFilterModalOpenedFrom.TemplatedSectionEdit;
    })
  );

  public canGeneratePreview$ = this.openedFrom$.pipe(
    // Preview can be generated if the modal was opened from the settings page or the labels/badges page
    map(openedFrom => {
      return openedFrom === AddEditSmartFilterModalOpenedFrom.Settings
          || openedFrom === AddEditSmartFilterModalOpenedFrom.SmartLabel
          || openedFrom === AddEditSmartFilterModalOpenedFrom.SmartBadge;
    })
  );

  private _smartFilterReq = new BehaviorSubject<HydratedSmartFilter>(new HydratedSmartFilter());
  public smartFilterReq$ = combineLatest([
    this._smartFilterReq,
    this.companyDomainModel.companyId$,
    this.openedFrom$
  ]).pipe(
    map(([smartFilterReq, companyId, openedFrom]) => {
      if (SmartFilterUtils.contextRequiresCompanySmartFilter(openedFrom)) {
        smartFilterReq.locationId = companyId;
      }
      return smartFilterReq;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _isEditing = new BehaviorSubject<boolean>(false);
  public isEditing$ = this._isEditing as Observable<boolean>;
  connectToIsEditing = (isEditing: boolean) => this._isEditing.next(isEditing);

  public isCuratedSmartFilter$ = this.existingSmartFilter$.pipe(
    map(sf => sf?.locationId === BUDSENSE_CURATED_ID)
  );

  /**
   * If the smart filter is curated, the user is not an admin, or the section is templated
   * the user should be restricted 'View Only'
   */
  public isViewOnly$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.isCompanyAdmin$,
    this.isOpenedFromTemplatedSectionFlow$
  ]).pipe(
    map(([curatedSF, isAdminUser, openedFromTemplatedSection]) => {
      return curatedSF || !isAdminUser || openedFromTemplatedSection;
    })
  );

  public viewOnlyBanner$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.isCompanyAdmin$,
    this.isOpenedFromTemplatedSectionFlow$,
  ]).pipe(
    map(([curatedSF, isAdminUser, openedFromTemplatedSection]) => {
      if (openedFromTemplatedSection) {
        return 'The current Smart Filter is in View Only mode because it is applied to a templated section. To '
          + 'modify the smart filter, do so from Edit Template or Settings.';
      } else if (curatedSF) {
        return 'The current Smart Filter is in View Only mode as it is a BudSense curated smart filter. In order '
          + 'to modify the smart filter, create a new one with the same criteria.';
      } else if (!isAdminUser) {
        return 'The current Smart Filter is in View Only mode as only company admins can create/edit Smart Filters.';
      } else {
        return null;
      }
    })
  );

  public viewOnlyTooltip$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.isCompanyAdmin$,
    this.isOpenedFromTemplatedSectionFlow$,
  ]).pipe(
    map(([curatedSF, isAdminUser, openedFromTemplatedSection]) => {
      if (openedFromTemplatedSection) {
        return 'Smart Filters applied to templated menus most be edited on the template.';
      } else if (curatedSF) {
        return 'Curated Smart Filters can not be edited. Create a new smart filter to edit.';
      } else if (!isAdminUser) {
        return 'Smart Filters can only be updated by Admin users';
      } else {
        return null;
      }
    })
  );

  public modalTitle$ = combineLatest([
    this.isEditing$,
    this.isViewOnly$
  ]).pipe(
    map(([isEditing, isViewOnly]) => {
      if (isEditing && !isViewOnly) {
        return 'Edit Smart Filter';
      } else if (isViewOnly) {
        return 'View Smart Filter';
      } else {
        return 'Add Smart Filter';
      }
    })
  );

  // Variants
  public smartFilterVariants$ = combineLatest([
    this.existingSmartFilter$,
    this.productDomainModel.currentLocationVariants$,
  ]).pipe(
    map(([smartFilter, locationVariants]) => {
      if (!smartFilter || !locationVariants || locationVariants?.length <= 0) {
        return null;
      }
      // Only include variants with IDs that are on the Smart Filter option
      const smartFilterVariants = locationVariants.filter(v => smartFilter?.appliedVariantIds?.indexOf(v.id) !== -1);
      const sortedVariantIds = smartFilter?.appliedVariantIds;
      // Ensuring that list of hydrated variants matches the same order as smart filter applied variant Ids
      smartFilterVariants.sort((a, b) => {
        return sortedVariantIds?.indexOf(a?.id) - sortedVariantIds?.indexOf(b?.id);
      });
      return smartFilterVariants;
    })
  );

  public hideSmartFilterVariantTable$ = combineLatest([
    this.smartFilterVariants$,
    this.changesMade$,
    this.isEditing$
  ]).pipe(
    map(([smartFilterVariants, changesMade, isEditing]) => {
      const smartFilterHasNoVariants = !smartFilterVariants || smartFilterVariants?.length <= 0;
      const changesMadeWhileEditing = changesMade && isEditing;
      return smartFilterHasNoVariants || changesMadeWhileEditing;
    })
  );

  private _ignoredVariantIds = new BehaviorSubject<string[]>([]);
  public ignoredVariantIds$ = this._ignoredVariantIds as Observable<string[]>;
  connectToIgnoredVariantIds = (variantIds: string[]) => this._ignoredVariantIds.next(variantIds);

  // Accordion

  private _openProductAccordion = new BehaviorSubject<boolean>(false);
  public openProductAccordion$ = this._openProductAccordion as Observable<boolean>;

  private _openCannabinoidAccordion = new BehaviorSubject<boolean>(false);
  public openCannabinoidAccordion$ = this._openCannabinoidAccordion as Observable<boolean>;

  private _openTerpeneAccordion = new BehaviorSubject<boolean>(false);
  public openTerpeneAccordion$ = this._openTerpeneAccordion as Observable<boolean>;

  private _openAdvancedAccordion = new BehaviorSubject<boolean>(false);
  public openAdvancedAccordion$ = this._openAdvancedAccordion as Observable<boolean>;

  public ctaText$ = combineLatest([
    this.previewGenerated$,
    this.openedFrom$
  ]).pipe(
    map(([previewGenerated, openedFrom]) => {
      switch (openedFrom) {
        case AddEditSmartFilterModalOpenedFrom.Products:
          return 'Add Filter';
        case AddEditSmartFilterModalOpenedFrom.SectionCreate:
          return 'Add Smart Filter';
        case AddEditSmartFilterModalOpenedFrom.SectionEdit:
          if (previewGenerated) {
            return 'Done';
          } else {
            return 'Save Changes';
          }
        case AddEditSmartFilterModalOpenedFrom.Settings:
          if (previewGenerated) {
            return 'Done';
          } else {
            return 'Save Changes';
          }
        default:
          return 'Save Changes';
      }
    })
  );

  public disableSubmitButton$ = combineLatest([
    this.canSubmitForm$,
    this.previewGenerated$,
    this.changesMade$,
    this.formHasBeenSubmitted$,
    this.generatedInModal$,
    this.variantVisibilityToggled$
  ]).pipe(
    map(([
      canSubmit,
      previewGenerated,
      changesMade,
      formHasBeenSubmitted,
      generatedInModal,
      variantVisibilityToggled
    ]) => {
      return (!canSubmit && !previewGenerated)
        || (!variantVisibilityToggled && !changesMade && !formHasBeenSubmitted && !generatedInModal);
    })
  );

  public showDeleteSmartFilterCTA$ = combineLatest([
    this.isEditing$,
    this.isViewOnly$,
    this.isOpenedFromSectionEdit$
  ]).pipe(
    map(([isEditing, isViewOnly, isOpenedFromSectionEdit]) => {
      return isEditing && !isViewOnly && !isOpenedFromSectionEdit;
    })
  );

  listenForProperties = this.existingSmartFilter$.notNull().subscribeWhileAlive({
    owner: this,
    next: sf => {
      if (sf?.hasProductPropertySet()) this._openProductAccordion.next(true);
      if (sf?.hasCannabinoidPropertySet()) this._openCannabinoidAccordion.next(true);
      if (sf?.hasTerpenePropertySet()) this._openTerpeneAccordion.next(true);
      if (sf?.hasAdvancedPropertySet()) this._openAdvancedAccordion.next(true);
    }
  });

  saveChanges() {
    combineLatest([
      this.previewGenerated$,
      this.existingSmartFilter$
    ]).once(([previewGenerated, existingSmartFilter]) => {
      if (previewGenerated) {
        this.activeModal.close(existingSmartFilter);
      } else {
        this._formHasBeenSubmitted.next(true);
        this.createOrUpdateSmartFilter(existingSmartFilter);
      }
    });
  }

  private createOrUpdateSmartFilter(existingSmartFilter: HydratedSmartFilter) {
    combineLatest([
      this.smartFilterReq$,
      this.ignoredVariantIds$,
      this.isEditing$
    ]).pipe(
      take(1),
      switchMap(([smartFilterReq, updatedSmartFilterIgnoredVariantIds, isEditing]) => {
        this.addLoadingReq();
        if (isEditing) {
          existingSmartFilter.ignoredVariantIds = updatedSmartFilterIgnoredVariantIds?.shallowCopy();
        }
        const updatePipe$ = defer(() => this.smartFilterDomainModel.updateSmartFilter(existingSmartFilter));
        const createPipe$ = defer(() => this.smartFilterDomainModel.createSmartFilter(smartFilterReq));
        return isEditing ? updatePipe$ : createPipe$;
      }),
      take(1)
    ).subscribe({
      next: (returnedSmartFilter) => {
        this.removeLoadingReq();
        this.publishToastMessage();
        this.activeModal.close(returnedSmartFilter);
      },
      error: (err) => {
        this.removeLoadingReq();
        this.toastService.publishErrorMessage(err, 'Error');
      }
    });
  }

  showDeleteModal() {
    this.existingSmartFilter$.once(sf => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Smart Filter';
      opts.bodyText = `Deleting a Smart Filter will remove it from any menus that it's been applied to `
        + `across all company locations.\n\n`
        + `This action cannot be undone.\n\n`
        + `Are you sure you want to delete '${sf.name}'?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmation = (cont: boolean) => {
        if (cont) {
          this.deleteSmartFilter(sf);
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  deleteSmartFilter(sf: HydratedSmartFilter) {
    const lm = 'Deleting your Smart Filter';
    this._loadingOpts.addRequest(lm);
    this.smartFilterDomainModel.deleteSmartFilter(sf).subscribe({
      next: _ => {
        this.toastService.publishBannerSuccess('Your Smart Filter was deleted');
        this._loadingOpts.removeRequest(lm);
        this.activeModal.close();
      },
      error: err => {
        this.toastService.publishBannerFailed('There was an error deleting your Smart Filter');
        this._loadingOpts.removeRequest(lm);
      }
    });
  }

  formChanges(isPristine: boolean) {
    this._changesMade.next(false);
    this._previewGenerated.next(true);
    if (isPristine) {
      this._previewGenerated.next(false);
      this._changesMade.next(true);
    }
  }

  regenerateSmartFilter() {
    this._hydrateObject.next(true);
    const lm = 'Regenerating your Smart Filter';
    this._loadingOpts.addRequest(lm);
    this.existingSmartFilter$.pipe(
      tap((_) => this._loadingOpts.addRequest(lm)),
      take(1),
      delay(300),
      switchMap((req) => this.smartFilterDomainModel.updateSmartFilter(req)),
      take(1)
    ).subscribe({
      next: response => {
        this._existingSmartFilter.next(response);
        this._previewGenerated.next(true);
        this._changesMade.next(false);
        this._generatedInModal.next(true);
        this._formHasBeenSubmitted.next(true);
        this._loadingOpts.removeRequest(lm);
        this._hydrateObject.next(false);
      },
      error: err => {
        this.toastService.publishBannerFailed('There was an error regenerating your Smart Filter');
        this._loadingOpts.removeRequest(lm);
        this._hydrateObject.next(false);
      }
    });
  }

  previewSmartFilter() {
    this._hydrateObject.next(true);
    const lm = 'Generating your Smart Filter';
    combineLatest([
      this._smartFilterReq.notNull(),
      this.locationDomainModel.locationId$,
    ]).pipe(
      take(1),
      map(([req, locationId]) => {
        req.locationId = locationId;
        this._loadingOpts.addRequest(lm);
        return req;
      }),
      delay(300),
      switchMap(req => {
        return this.smartFilterDomainModel.createSmartFilter(req).pipe(
          withLatestFrom(this.canGeneratePreview$)
        );
      }),
      take(1)
    ).subscribe({
      next: ([response, canGeneratePreview]) => {
        this._isEditing.next(true);
        this._existingSmartFilter.next(response);
        this._generatedInModal.next(true);
        this._changesMade.next(false);
        this._formHasBeenSubmitted.next(true);
        if (canGeneratePreview) {
          this._previewGenerated.next(true);
        }
        this.toastService.publishSuccessMessage('Your smart filter was created', 'Success!');
        this._loadingOpts.removeRequest(lm);
        this._hydrateObject.next(false);
      },
      error: err => {
        this.toastService.publishBannerFailed('There was an error generating your Smart Filter');
        this._loadingOpts.removeRequest(lm);
        this._hydrateObject.next(false);
      }
    });
  }

  setFormObject(hydratedFilters: HydratedSmartFilter[]) {
    this.isEditing$.once(isEditing => {
      if (isEditing) {
        this._existingSmartFilter.next(hydratedFilters?.firstOrNull());
      } else {
        this._smartFilterReq.next(hydratedFilters?.firstOrNull());
      }
    });
  }

  addLoadingReq() {
    this.isEditing$.once(isEditing => {
      const lm = isEditing ? 'Saving Changes' : 'Creating Smart Filter';
      this._loadingOpts.addRequest(lm);
    });
  }

  removeLoadingReq() {
    this.isEditing$.once(isEditing => {
      const lm = isEditing ? 'Saving Changes' : 'Creating Smart Filter';
      this._loadingOpts.removeRequest(lm);
    });
  }

  publishToastMessage() {
    this.isEditing$.once(isEditing => {
      const successMsg = isEditing ? 'Your smart filter was updated' : 'Your smart filter was created';
      this.toastService.publishSuccessMessage(successMsg, 'Success!');
    });
  }

}
