import { Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, fromEvent, Observable } from 'rxjs';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { AddNewLabelViewModel } from './add-new-label-view-model';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { ResizeObserver } from '@juggle/resize-observer';
import { TabBarItem } from '../../../models/shared/stylesheet/tab-bar-item';
import { DistinctUtils } from '../../../utils/distinct-utils';
import { LabelTab } from '../../../models/enum/shared/label-tab.enum';
import { CreateCustomLabelComponent } from '../smart-labels/create-custom-label/create-custom-label.component';
import { AddCompanyLabelComponent } from './add-company-label/add-company-label.component';

@Component({
  selector: 'app-add-new-label-modal',
  templateUrl: './add-new-label-modal.component.html',
  styleUrls: ['./add-new-label-modal.component.scss'],
  providers: [AddNewLabelViewModel]
})
export class AddNewLabelModalComponent extends BaseModalComponent implements OnDestroy {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: AddNewLabelViewModel,
    public renderer: Renderer2,
  ) {
    super(activeModal);
  }

  @ViewChild('contentContainer') contentContainer: ElementRef<HTMLDivElement>;
  @ViewChild('addNewLabelFormCapture') formCapture: ReactiveFormMergeGroupsComponent;

  public tabs$: Observable<TabBarItem[]> = combineLatest([
    this.viewModel.companySupportsLocationLabels$,
    this.viewModel.sourceLabelTab$,
  ]).pipe(
    map(([supportsLocationLabels, sourceLabelTab]) => this.getTabs(supportsLocationLabels, sourceLabelTab)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  public disableSubmitForm$ = new BehaviorSubject<boolean>(true);

  private _bottomButtonPosition = new BehaviorSubject<string>('absolute');
  public bottomButtonPosition$ = combineLatest([
    this._bottomButtonPosition,
    this.viewModel.allowedToCreateSmartLabel$,
    this.viewModel.currentlySelectedTabIndex$
  ]).pipe(
    map(([position, allowedToCreateSmartLabel, tabIndex]) => {
      return !allowedToCreateSmartLabel && tabIndex === 0 ? 'sticky' : position;
    }),
    // If we decide to make this modal have a fixed height, then delete this override
    // and this will become a plug and play solution.
    map(([position]) => 'sticky'),
    distinctUntilChanged()
  );

  private contentContainerRO: ResizeObserver;
  private _contentContainerHeight = new BehaviorSubject<number>(null);
  public contentContainerHeight$ = this._contentContainerHeight.pipe(
    filter(height => height > 0),
    distinctUntilChanged()
  );

  private modalHeightRO: ResizeObserver;

  override setupBindings(): void {
    // These methods are commented out on and left here on purpose. If we change this modal to have a fixed height,
    // then uncommenting these methods will make it plug and play (saving us dev time).
    // this.observeContentContainer();
    // this.observeModalHeight();
    this.observeFormDisabledState();
    this.observeTabBodyPointerEvents();
  }

  private getTabs(supportsLocationLabels: boolean, sourceLabelTab: LabelTab): TabBarItem[] {
    const createCustom = new TabBarItem(CreateCustomLabelComponent, 'Create Custom Label', '', true);
    const addCompany = new TabBarItem(AddCompanyLabelComponent, 'Add Company Label', '', false);
    const tabs = [];
    tabs.push(createCustom);
    if (sourceLabelTab === LabelTab.LOCATION_TAB && supportsLocationLabels) {
      tabs.push(addCompany);
    }
    TabBarItem.setSelectedTab(tabs, 0);
    return tabs;
  }

  private observeFormDisabledState(): void {
    combineLatest([
      this.formCapture.disableSubmit$,
      this.viewModel.companyLabelsToAddToLocation$,
      this.viewModel.currentlySelectedTabIndex$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([disableSubmit, labelsToAdd, index]) => {
        if (index === 0) {
          this.disableSubmitForm$.next(disableSubmit);
        } else if (index === 1) {
          this.disableSubmitForm$.next(labelsToAdd?.length === 0);
        }
      }
    });
  }

  /**
   * The z-indices within modals are a real pain in the you know what when dealing with sticky content. I had to do some
   * wizardry in order to get the z-axis flattened. Without this wizardry, all popper content
   * (in this case the color pickers) will go under the sticky header and footer.
   *
   * The flattened z-axis causes pointer events to fail within the tab component. The code below is a workaround to get
   * the pointer events working again.
   */
  private observeTabBodyPointerEvents(): void {
    combineLatest([
      this.viewModel.currentlySelectedTabIndex$,
      this.tabs$.pipe(map(tabs => tabs?.length ?? 0))
    ]).subscribeWhileAlive({
      owner: this,
      next: ([selectedIndex, numberOfTabs]) => {
        const parent = 'add-new-label-dialog';
        const tabBodies = 'mat-tab-body';
        const tabs = document.getElementsByClassName(parent)?.item(0)?.getElementsByClassName(tabBodies);
        for (let i = 0; i < numberOfTabs; i++) {
          const tab = tabs?.item(i);
          if (tab) this.renderer.setStyle(tab, 'pointer-events', (i === selectedIndex) ? 'auto' : 'none');
        }
      }
    });
  }

  private observeContentContainer(): void {
    this.contentContainerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._contentContainerHeight.next(entry.contentRect.height);
      }
    });
    this.contentContainerRO.observe(this.contentContainer.nativeElement);
    fromEvent(window, 'resize').pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: () => {
        this._contentContainerHeight.next(this.contentContainer.nativeElement?.getBoundingClientRect()?.height);
      }
    });
    this.contentContainerHeight$.subscribeWhileAlive({
      owner: this,
      next: height => this.calculateBottomButtonContainerPosition(height)
    });
  }

  private observeModalHeight(): void {
    this.modalHeightRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._contentContainerHeight.next(this.contentContainer.nativeElement?.getBoundingClientRect()?.height);
      }
    });
    const modalContent = document.getElementsByClassName('add-new-label-dialog')
      ?.item(0)?.getElementsByClassName('modal-content')?.item(0);
    this.modalHeightRO.observe(modalContent);
  }

  /**
   * This is infrastructure to get the bottom button container positioned correctly. Currently, it is a dead pipe,
   * but will be extremely useful if we decide to make the modal a fixed height.
   */
  private calculateBottomButtonContainerPosition(contentHeight: number): void {
    const modalContentRect = document.getElementsByClassName('add-new-label-dialog')
      ?.item(0)?.getElementsByClassName('modal-content')
      ?.item(0)?.getBoundingClientRect();
    const headerRect = document.getElementsByClassName('add-new-label-header')?.item(0)?.getBoundingClientRect();
    const footerRect = document.getElementsByClassName('add-new-label-footer')?.item(0)?.getBoundingClientRect();
    const heightUntilScrollableContent = (modalContentRect.height - headerRect.height) - footerRect.height;
    const position = contentHeight >= heightUntilScrollableContent ? 'sticky' : 'absolute';
    this._bottomButtonPosition.next(position);
  }

  formSubmitted() {
    this.viewModel.currentlySelectedTabIndex$.pipe(take(1)).subscribe(i => {
      if (i === 0) {
        // Create new label
        this.formCapture.submitForms();
      } else {
        // Import company label into location
        this.importLocationLabelsFromCompany();
      }
    });
  }

  submitForms(submissions: any[]) {
    this.activeModal.close([submissions, false]);
  }

  importLocationLabelsFromCompany() {
    this.viewModel.companyLabelsToAddToLocation$.pipe(take(1)).subscribe(l => {
      this.activeModal.close([l, true]);
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.modalHeightRO?.disconnect();
    this.contentContainerRO?.disconnect();
  }

}
