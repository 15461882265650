import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';

@Injectable()
export class DashboardViewModel extends BaseViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
  ) {
    super();
    // Listen to employees data
    const eSub = combineLatest([
      this.userDomainModel.user$,
      this.companyDomainModel.employees$
    ]).pipe(
      debounceTime(100),
      switchMap(([user, employees]) => {
        const validUser = user?.accountConfirmed;
        const validSession = user?.session?.validSession();
        const confirmedUserAndValidSession = validUser && validSession;
        if (!employees && confirmedUserAndValidSession) return this.companyDomainModel.adminGetEmployees();
        else return of(employees);
      })
    ).subscribe();
    this.pushSub(eSub);
  }

}
