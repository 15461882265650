import { Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';
import { DigitalMarketingTemplatesViewModel } from './digital-marketing-templates-view-model';

@Component({
  selector: 'app-digital-marketing-templates',
  templateUrl: './digital-marketing-templates.component.html',
  styleUrls: ['./digital-marketing-templates.component.scss'],
  providers: [DigitalMarketingTemplatesViewModel]
})
export class DigitalMarketingTemplatesComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: DigitalMarketingTemplatesViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
