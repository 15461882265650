import { BehaviorSubject, Observable } from 'rxjs';

export class ChangeMediaOptions {

  // Media
  public modalTitle: string = 'Change Media';
  public allowImage$: Observable<boolean> = new BehaviorSubject<boolean>(true).asObservable();
  public allowVideo$: Observable<boolean> = new BehaviorSubject<boolean>(true).asObservable();
  public maxAssets: number = 1;
  public inputId: number = 99;
  // Loading
  public loadingMess: string;
  public successTitle: string;
  public successMess: string;
  public failureTitle: string;
  public failureMess: string;

}
