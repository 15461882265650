import { TypeDefinition } from './type-definition';

export enum StrainClassification {
  Blend = 'Blend',
  CBD = 'CBD',
  Hybrid = 'Hybrid',
  Indica = 'Indica',
  IndicaDominant = 'IndicaDominant',
  Sativa = 'Sativa',
  SativaDominant = 'SativaDominant',
  Unknown = ''
}

export class StrainClassificationType extends TypeDefinition {

  override value: StrainClassification;

}
