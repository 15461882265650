import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditSpecificDateTimeModalViewModel } from './edit-specific-date-time-modal-view-model';
import { CompactMenu } from '../../../../../models/menu/dto/compact-menu';
import { DisplayMenuOptions } from '../../../../../models/display/shared/display-menu-options';
import { Display } from '../../../../../models/display/dto/display';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-specific-date-time-modal',
  templateUrl: './edit-specific-date-time-modal.component.html',
  styleUrls: ['./edit-specific-date-time-modal.component.scss'],
  providers: [EditSpecificDateTimeModalViewModel]
})
export class EditSpecificDateTimeModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: EditSpecificDateTimeModalViewModel
  ) {
    super(activeModal);
  }

  override setupBindings(): void {
    this.viewModel.closeModalSubject.subscribeWhileAlive({
      owner: this,
      next: _ => this.activeModal.close()
    });
  }

  setDisplay(display: Display|TemplateCollection): void {
    this.viewModel.connectToDisplay(display);
  }

  setCompactMenu(compactMenu: CompactMenu): void {
    this.viewModel.connectToCompactMenu(compactMenu);
  }

  setDisplayOptions(displayOptions: DisplayMenuOptions): void {
    this.viewModel.connectToDisplayOptions(displayOptions);
    this.viewModel.connectToDateTimeWindows(displayOptions?.override?.dateTimeWindows ?? []);
  }

  setSaveFunc(saveFunc: (display: Display|TemplateCollection) => Observable<Display[]>): void {
    this.viewModel.saveDisplay = saveFunc;
  }

}
