import { Injectable } from '@angular/core';
import { EditSectionFormViewModel } from '../../../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form-view-model';
import { of } from 'rxjs';
import { DropDownItem } from '../../../../../../../../models/shared/stylesheet/drop-down-item';
import { ImageObjectFit } from '../../../../../../../../models/enum/shared/image-object-fit';

@Injectable()
export class EditSmartPlaylistMenuSectionFormViewModel extends EditSectionFormViewModel {

  public readonly objectFitTypes$ = of([
    new DropDownItem('Cover', ImageObjectFit.Cover),
    new DropDownItem('Contain', ImageObjectFit.Contain)
  ]);

  protected override zeroStockNote = 'Note: This media will not appear on the menu when all products are out of stock.';

}
