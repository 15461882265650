import { Injectable } from '@angular/core';
import { CardStackSectionFormViewModel } from '../../edit-card-stack-menu/card-stack-details-form/card-stack-section-form-view-model';
import { SectionLayout, SectionLayoutType } from '../../../../../../models/utils/dto/section-layout-type';
import { ThemeUtils } from '../../../../../../utils/theme-utils';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';

@Injectable()
export class LabelStackSectionFormViewModel extends CardStackSectionFormViewModel {

  protected override layoutTypeFilter(menu: Menu, section: HydratedSection, layoutType: SectionLayoutType): boolean {
    return layoutType?.value === SectionLayout.List;
  }

  protected override layoutTypeSelectionConversion(
    menu: Menu,
    section: HydratedSection,
    layoutType: SectionLayoutType
  ): SectionLayoutType {
    switch (layoutType.value) {
      case SectionLayout.List:
        return ThemeUtils.getSelectionOptionForLabelStackLayoutList(layoutType);
      default:
        return layoutType;
    }
  }

}
