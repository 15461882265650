import { Injectable } from '@angular/core';
import { BaseViewModel } from 'src/app/models/base/base-view-model';
import { EditFeaturedProductMenuViewModel } from '../edit-featured-product-menu-view-model';
import { Variant } from '../../../../../../../models/product/dto/variant';

@Injectable()
export class EditFeaturedProductsViewModel extends BaseViewModel {

  public sharedViewModel: EditFeaturedProductMenuViewModel;

  public init(sharedVM?: EditFeaturedProductMenuViewModel) {
    if (sharedVM) this.sharedViewModel = sharedVM;
  }

  public trackById(index: number, v: Variant): string {
    return v.id;
  }

}
