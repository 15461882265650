import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OverflowInfoModalViewModel } from './overflow-info-modal-view-model';
import { OverflowStateInfoType } from '../../../../models/utils/dto/overflow-state-type';
import { OverflowInformationItem } from '../../../../models/shared/overflow-information-item';

@Component({
  selector: 'app-overflow-info-modal',
  templateUrl: './overflow-info-modal.component.html',
  styleUrls: ['./overflow-info-modal.component.scss'],
  providers: [OverflowInfoModalViewModel]
})
export class OverflowInfoModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {

  modalHeaderHeight: string;
  title: string;
  overflowStateInfoType: OverflowStateInfoType;

  @ViewChild('modalHeader') modalHeader: ElementRef;

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: OverflowInfoModalViewModel
  ) {
    super(activeModal);
  }

  override ngOnInit() {
    this.viewModel.updateOverflowStateInfoType(this.overflowStateInfoType);
  }

  override  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalHeaderHeight = `${this.modalHeader.nativeElement.offsetHeight}px`;
  }

  onClick(item: OverflowInformationItem): void {
    this.viewModel.updateSelectedInfoItem(item);
  }

}
