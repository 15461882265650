import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { SmartFilterVariantsTableViewModel } from './smart-filter-variants-table-view-model';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-smart-filter-variants-table',
  templateUrl: './smart-filter-variants-table.component.html',
  styleUrls: ['./smart-filter-variants-table.component.scss'],
  providers: [SmartFilterVariantsTableViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterVariantsTableComponent extends BaseComponent {

  constructor(
    public viewModel: SmartFilterVariantsTableViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToVariants') variants: Variant[];
  @Input() @ViewModelConnector('connectToIgnoredVariants') ignoredVariantIds: string[];
  @Input() @ViewModelConnector('connectToIsViewOnly') isViewOnly: boolean;
  @Input() @ViewModelConnector('connectToViewOnlyTooltip') viewOnlyTooltip: string;
  @Output() ignoredListUpdated = new EventEmitter<string[]>;
  @Output() variantVisibilityToggled = new EventEmitter<boolean>();

  override setupBindings(): void {
    this.viewModel.ignoredVariantIds$.subscribeWhileAlive({
      owner: this,
      next: ignoredVariantIds => this.ignoredListUpdated.emit(ignoredVariantIds)
    });
    this.viewModel.variantVisibilityToggled$.subscribeWhileAlive({
      owner: this,
      next: variantVisibilityToggled => this.variantVisibilityToggled.emit(variantVisibilityToggled)
    });
  }

}
