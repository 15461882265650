<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <input hidden #input [formControlName]="inputName" [id]="id" [name]="inputName" />
</div>

<div class="incrementor">
  <div class="square left decrement" [class.disabled]="minValue === (number$ | async)" (click)="decrement()">
    <img class="decrement-icon" src="/assets/icons/dark/solid/minus.svg" alt="-" />
  </div>
  <div class="square number">{{ number$ | async }}</div>
  <div class="square right increment" [class.disabled]="maxValue === (number$ | async)" (click)="increment()">
    <img class="increment-icon" src="/assets/icons/dark/solid/plus.svg" alt="+" />
  </div>
</div>
