import { Injectable } from '@angular/core';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';

@Injectable()
 export abstract class BaseSmartFilterFormViewModel extends BaseViewModel {

  private _isEditing = new BehaviorSubject<boolean>(false);
  public isEditing$ = this._isEditing as Observable<boolean>;
  public connectToIsEditing = (isEditing: boolean) => this._isEditing.next(isEditing);

  private _smartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public smartFilter$ = this._smartFilter as Observable<HydratedSmartFilter>;
  public connectToSmartFilter = (sf: HydratedSmartFilter) => this._smartFilter.next(sf);

  private _isViewOnly = new BehaviorSubject<boolean>(false);
  public isViewOnly$ = this._isViewOnly as Observable<boolean>;
  public connectToIsViewOnly = (isViewOnly: boolean) => this._isViewOnly.next(isViewOnly);

  private _modalOpenedFrom = new BehaviorSubject<AddEditSmartFilterModalOpenedFrom>(null);
  public modalOpenedFrom$ = this._modalOpenedFrom as Observable<AddEditSmartFilterModalOpenedFrom>;
  public connectToModalOpenedFrom = (of: AddEditSmartFilterModalOpenedFrom) => this._modalOpenedFrom.next(of);

}
