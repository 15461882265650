import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CompactTemplateCollection } from '../../../models/template/dto/compact-template-collection';
import { map } from 'rxjs/operators';
import { BaseDisplay } from '../../../models/display/dto/base-display';

@Pipe({
  name: 'collectionRequiredInDisplay'
})
export class CollectionRequiredInDisplayPipe implements PipeTransform {

  transform(collection: CompactTemplateCollection, display$: Observable<BaseDisplay>): Observable<boolean> {
    return display$.pipe(
      map((d) => {
        return collection?.requiredDisplayIds?.includes(d?.id);
      })
    );
  }

}
