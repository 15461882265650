import type { ComputeLabelInterface } from '../label/compute-label-interface';
import type { Product } from '../../../models/product/dto/product';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import type { Variant } from '../../../models/product/dto/variant';
import type { Section } from '../../../models/menu/dto/section';
import type { Menu } from '../../../models/menu/dto/menu';
import { MarketingMenuType } from '../../../models/enum/dto/marketing-menu-type.enum';
import type { DisplayAttribute } from '../../../models/display/dto/display-attribute';
import type { Label } from '../../../models/shared/label';
import { LowStockSystemLabel } from '../../../models/shared/labels/low-stock-system-label';
import { RestockSystemLabel } from '../../../models/shared/labels/restock-system-label';
import { SaleSystemLabel } from '../../../models/shared/labels/sale-system-label';
import { NewSystemLabel } from '../../../models/shared/labels/new-system-label';
import type { SystemLabel } from '../../../models/shared/labels/system-label';
import { SortUtils } from '../../../utils/sort-utils';
import { Type } from '@angular/core';
import type { DisplayLabelInterface } from '../label/display-label-interface';
import { DateUtils } from '../../../utils/date-utils';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';
import { LabelStyle } from '../../../models/enum/shared/label-style.enum';
import type { DisplaySaleLabelInterface } from '../label/display-sale-label-interface';
import { MenuLabel } from '../../../models/utils/dto/menu-label-type';
import { PriceFormat } from '../../../models/utils/dto/price-format-type';
import { exists } from '../../../functions/exists';

export class LabelUtils {

  static getComputeLabelInterfaceWithinMenuContext(
    menu: Menu | null,
    section: Section | null,
    variants: Variant[],
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
  ): ComputeLabelInterface {
    return new class implements ComputeLabelInterface {

      getMenuForLabelComponent = () => menu;
      getSectionForLabelComponent = () => section;
      getVariantsForLabelComponent = () => variants;
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getClearableForLabelComponent = () => false;
      getShutOffLabelForLabelComponent = () => false;

    }();
  }

  static getComputeLabelInterfaceFromProductOutsideOfMenuContext(
    product: Product,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
  ): ComputeLabelInterface {
    return new class implements ComputeLabelInterface {

      getVariantsForLabelComponent = () => product?.variants;
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getMenuForLabelComponent = () => undefined;
      getSectionForLabelComponent = () => undefined;
      getClearableForLabelComponent = () => false;
      getShutOffLabelForLabelComponent = () => false;

    }();
  }

  static getComputeLabelInterfaceFromVariantOutsideOfMenuContext(
    variant: Variant,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
  ): ComputeLabelInterface {
    return new class implements ComputeLabelInterface {

      getVariantsForLabelComponent = () => [variant];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getMenuForLabelComponent = () => undefined;
      getSectionForLabelComponent = () => undefined;
      getClearableForLabelComponent = () => false;
      getShutOffLabelForLabelComponent = () => false;

    }();
  }

  static getDisplayLabelInterfaceForProductInProductTable(
    product: Product,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getLabelsForLabelComponent = () => product?.computedLabelsForProductTable;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => baseLabelText?.ellipsis(16);
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplaySaleLabelInterfaceForVariantInProductTable(
    variant: Variant,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
  ): DisplaySaleLabelInterface {
    return new class implements DisplaySaleLabelInterface {

      getMenuForLabelComponent = () => null;
      getSectionForLabelComponent = () => null;
      getShutOffLabelForLabelComponent = () => false;
      getVariantsForLabelComponent = () => [variant];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getLabelsForLabelComponent = () => [variant?.computedLabelForProductTable];
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForVariantInProductTable(
    variant: Variant,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getLabelsForLabelComponent = () => [variant?.computedLabelForProductTable];
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => baseLabelText?.ellipsis(16);
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForLabelPreview(
    label: Label,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
    strokeThrough: boolean,
    disabled: boolean
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getLabelsForLabelComponent = () => [label];
      getStrokeThroughForLabelComponent = () => strokeThrough;
      getDisabledForLabelComponent = () => disabled;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplaySaleLabelInterfaceForLabelPreview(
    [label, strokeThrough, disabled]: [Label, boolean, boolean],
    [menu, section, variants]: [Menu | null, Section | null, Variant[]],
    [locationConfig, companyConfig]: [LocationConfiguration, CompanyConfiguration],
  ): DisplaySaleLabelInterface {
    return new class implements DisplaySaleLabelInterface {

      getMenuForLabelComponent = () => menu;
      getSectionForLabelComponent = () => section;
      getVariantsForLabelComponent = () => variants;
      getShutOffLabelForLabelComponent = () => false;
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getLabelsForLabelComponent = () => [label];
      getStrokeThroughForLabelComponent = () => strokeThrough;
      getDisabledForLabelComponent = () => disabled;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForLabelPicker(
    label: Label,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
    isClearable: boolean,
    isOrphanedCompanyLabel: boolean = false,
    variant: Variant = null,
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getLabelsForLabelComponent = () => [label];
      getStrokeThroughForLabelComponent = () => isOrphanedCompanyLabel;
      getDisabledForLabelComponent = () => isOrphanedCompanyLabel;
      getClearableForLabelComponent = () => isClearable;
      getFallbackTooltipForLabelComponent = () => {
        if (label?.isPOSManaged && variant?.posLabelIds?.contains(label?.id)) {
          return `${label.text.toUpperCase()} is a POS managed label. To remove this label from this product, remove `
               + `the associated tag in the POS.`;
        }
        return null;
      };
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => true;
      getSmartFilterLabelId = () => variant?.getLocationLevelSmartFilterLabelId() || null;
      getSmartFilterBadgeIds = () => variant?.getLocationLevelSmartFilterBadgeIds() || [];

    }();
  }

  static getDisplayLabelInterfaceForOldDataTable(
    label: Label,
    [locationConfig, companyConfig]: [LocationConfiguration, CompanyConfiguration]
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForLabelEditor(
    label: Label,
    [saleLabelFormat, locationConfig, companyConfig, labelStyle]:
      [SaleLabelFormat, LocationConfiguration, CompanyConfiguration, LabelStyle]
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => saleLabelFormat;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => labelStyle;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForLabelActionCard(
    label: Label,
    [locationConfig, companyConfig]: [LocationConfiguration, CompanyConfiguration],
    [labelStyle, saleLabelFormat]: [LabelStyle, SaleLabelFormat],
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => saleLabelFormat;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => labelStyle;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForConfirmationModal(
    label: Label,
    [locationConfig, companyConfig]: [LocationConfiguration, CompanyConfiguration]
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => undefined;
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => undefined;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForCreateCustomLabel(
    label: Label,
    [locationConfig, companyConfig, labelStyle]: [LocationConfiguration, CompanyConfiguration, LabelStyle]
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => undefined;
      overrideLabelTextIfDefined = (baseLabelText: string) => baseLabelText || '--';
      getLockedForLabelInterface = (): boolean => false;
      overrideLabelStyleIfDefined = (): LabelStyle => labelStyle;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getDisplayLabelInterfaceForManageLabelSmartFilters(
    label: Label,
    [locationConfig, companyConfig, labelStyle]: [LocationConfiguration, CompanyConfiguration, LabelStyle]
  ): DisplayLabelInterface {
    return LabelUtils.getDisplayLabelInterfaceForCreateCustomLabel(label, [locationConfig, companyConfig, labelStyle]);
  }

  static getDisplayLabelInterfaceForLabelHierarchy(
    companySupportsLocationLabels: boolean,
    allowUnsetPriority: boolean,
    locationConfig: LocationConfiguration,
    companyConfig: CompanyConfiguration,
    label: Label,
    labelStyle: LabelStyle,
    saleLabelFormat: SaleLabelFormat,
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getLocationConfigForLabelComponent = () => locationConfig;
      getCompanyConfigForLabelComponent = () => companyConfig;
      getStrokeThroughForLabelComponent = () => false;
      getDisabledForLabelComponent = () => false;
      getClearableForLabelComponent = () => false;
      getFallbackTooltipForLabelComponent = () => null;
      overrideSaleLabelFormatIfDefined = () => saleLabelFormat;
      overrideLabelTextIfDefined = (baseLabelText: string) => baseLabelText || '--';
      getLockedForLabelInterface = (): boolean => {
        return companySupportsLocationLabels && (label?.labelPriorityIsCompanyDefault() || allowUnsetPriority);
      };
      overrideLabelStyleIfDefined = (): LabelStyle => labelStyle;
      showAsDisabledIfAddedBySmartFilter = () => false;
      getSmartFilterLabelId = () => null;
      getSmartFilterBadgeIds = () => [];

    }();
  }

  static getSortedOverrideLabelIds(
    menu: Menu,
    section: Section,
    variants: Variant[],
    locationLabels: Label[]
  ): string[] {
    const overrideLabelKeys = variants?.map(variant => section?.customLabelMap?.get(variant.id))?.filterNulls();
    // The exclusion logic can be removed when the excluded menu types are updated
    // to the "Section" data model structure on the backend
    const isFeaturedProductMenu = menu?.getSubType() === MarketingMenuType.Featured;
    const isDriveThruMenu = menu?.getSubType() === MarketingMenuType.DriveThru;
    const excludeTheseMarketingMenus = isFeaturedProductMenu || isDriveThruMenu;
    const isFeatured = variants?.some(v => {
      return !excludeTheseMarketingMenus && menu?.variantFeature?.variantIds?.contains(v.id);
    });
    if (isFeatured) overrideLabelKeys.push(MenuLabel.Featured);
    return locationLabels
      ?.filter(label => overrideLabelKeys?.contains(label?.id))
      ?.sort(SortUtils.sortLabelsByPriority)
      ?.map(label => label?.id);
  }

  static getSortedLocationLabelIds(variants: Variant[], locationLabels: Label[]): string[] {
    const locationLabelIds = variants
      ?.map(variant => variant?.getDisplayAttributes()?.filter(attr => attr?.isLocationDA()) ?? [])
      ?.flatten<DisplayAttribute[]>()
      ?.map(locationDisplayAttributes => locationDisplayAttributes?.defaultLabel)
      ?.filterNulls()
      ?.unique();
    return locationLabels
      ?.filter(label => locationLabelIds?.contains(label?.id))
      ?.sort(SortUtils.sortLabelsByPriority)
      ?.map(label => label?.id);
  }

  static getSortedCompanyLabelIds(variants: Variant[], locationLabels: Label[]): string[] {
    const companyLabelIds = variants
      ?.map(variant => variant?.getDisplayAttributes()?.filter(attr => attr?.isCompanyDA()) ?? [])
      ?.flatten<DisplayAttribute[]>()
      ?.map(companyDisplayAttribute => companyDisplayAttribute?.defaultLabel)
      ?.filterNulls()
      ?.unique();
    return locationLabels
      ?.filter(label => companyLabelIds?.contains(label?.id))
      ?.sort(SortUtils.sortLabelsByPriority)
      ?.map(label => label?.id);
  }

  static priorityBasedLabelPool<T>(overrideLabelIds: T[], locationLabelIds: T[], companyLabelIds: T[]): T[] {
    switch (true) {
      case overrideLabelIds?.length > 0:
        return overrideLabelIds;
      case locationLabelIds?.length > 0:
        return locationLabelIds;
      case companyLabelIds?.length > 0:
        return companyLabelIds;
      default:
        return [];
    }
  }

  static sortedPriorityBasedLabelPool(labelIdPool: string[], labels: Label[]): Label[] {
    return labels?.filter(label => labelIdPool?.contains(label?.id));
  }

  static labelToCompareWithSystemLabel(labels: Label[]): Label {
    return labels?.firstOrNull();
  }

  static getSystemLabel<T extends SystemLabel>(locationId: number, systemLabels: SystemLabel[], labelType: Type<T>): T {
    const isTargetSystemLabel = (l: SystemLabel): l is T => l instanceof labelType;
    const targetedSystemLabels = systemLabels?.filter(isTargetSystemLabel);
    return targetedSystemLabels?.find(l => l?.locationId === locationId) || targetedSystemLabels?.firstOrNull();
  }

  static getLowStockSystemLabel(locationId: number, systemLabels: SystemLabel[]): LowStockSystemLabel {
    return LabelUtils.getSystemLabel(locationId, systemLabels, LowStockSystemLabel);
  }

  static getRestockSystemLabel(locationId: number, systemLabels: SystemLabel[]): RestockSystemLabel {
    return LabelUtils.getSystemLabel(locationId, systemLabels, RestockSystemLabel);
  }

  static getSaleSystemLabel(locationId: number, systemLabels: SystemLabel[]): SaleSystemLabel {
    return LabelUtils.getSystemLabel(locationId, systemLabels, SaleSystemLabel);
  }

  static getNewSystemLabel(locationId: number, systemLabels: SystemLabel[]): NewSystemLabel {
    return LabelUtils.getSystemLabel(locationId, systemLabels, NewSystemLabel);
  }

  static variantLowStockSystemLabelOrNull(v: Variant, label: LowStockSystemLabel): LowStockSystemLabel | null {
    return v?.isLowStockSystemLabelActive(label) ? label : null;
  }

  static variantRestockSystemLabelOrNull(v: Variant, label: RestockSystemLabel): RestockSystemLabel | null {
    return v?.isRestockSystemLabelActive(label) ? label : null;
  }

  static variantSaleLabelOrNull(
    menu: Menu | null,
    variant: Variant,
    saleLabel: SaleSystemLabel,
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat
  ): SaleSystemLabel | null {
    const showSale = !menu?.menuOptions?.hideSale;
    return (variant?.isSaleSystemLabelActive(locationId, companyId, priceFormat) && showSale) ? saleLabel : null;
  }

  static variantNewLabelOrNull(v: Variant, label: NewSystemLabel): NewSystemLabel | null {
    return v?.isNewSystemLabelActive(label) ? label : null;
  }

  /**
   * This method is not the same as the asynchronous calculation of systemLabelPool$
   */
  static systemLabelPool(
    [menu, variants]: [Menu | null, Variant[]],
    [locId, compId, priceStream]: [number, number, PriceFormat],
    [lowStockSystemLabel, restockSystemLabel]: [LowStockSystemLabel, RestockSystemLabel],
    [saleSystemLabel, newSystemLabel]: [SaleSystemLabel, NewSystemLabel]
  ): SystemLabel[] {
    return variants
      ?.map(v => {
        const lowStockOrNull = LabelUtils.variantLowStockSystemLabelOrNull(v, lowStockSystemLabel);
        const restockOrNull = LabelUtils.variantRestockSystemLabelOrNull(v, restockSystemLabel);
        const saleOrNull = LabelUtils.variantSaleLabelOrNull(menu, v, saleSystemLabel, locId, compId, priceStream);
        const newOrNull = LabelUtils.variantNewLabelOrNull(v, newSystemLabel);
        return [lowStockOrNull, restockOrNull, saleOrNull, newOrNull] as SystemLabel[];
      })
      ?.flatten<SystemLabel[]>()
      ?.uniqueByProperty('id', true) || [];
  }

  static sortedSystemLabelPool(systemLabelPool: SystemLabel[]): SystemLabel[] {
    return systemLabelPool?.sort(SortUtils.sortLabelsByPriority);
  }

  static systemLabelToCompareWithCustomLabel(systemLabels: SystemLabel[]): SystemLabel {
    return systemLabels?.firstOrNull();
  }

  /* ****************************** Synchronously Compute a Label ****************************** */

  static computeLabel(
    [menu, section, variants]: [Menu | null, Section | null, Variant[]],
    [labels, systemLabels]: [Label[], SystemLabel[]],
    [locationId, companyId, priceStream]: [number, number, PriceFormat]
  ): Label {
    if (menu?.menuOptions?.hideLabel) return null;
    // Custom Label
    const posLabels = labels?.filter(label => label?.isPOSManaged);
    const locationLabels = labels?.filter(label => label?.locationId === locationId);
    const locationAndPOSLabels = [...(locationLabels || []), ...(posLabels || [])];
    const overrideLabelIds = LabelUtils.getSortedOverrideLabelIds(menu, section, variants, locationAndPOSLabels);
    const locationLabelIds = LabelUtils.getSortedLocationLabelIds(variants, locationAndPOSLabels);
    // Passing in location labels is correct. Search all for sortedAssociatedCompanyLabels$ to see explanation
    const companyLabelIds = LabelUtils.getSortedCompanyLabelIds(variants, locationAndPOSLabels);
    const labelIdPool = LabelUtils.priorityBasedLabelPool(overrideLabelIds, locationLabelIds, companyLabelIds);
    const sortedLabelPool = LabelUtils.sortedPriorityBasedLabelPool(labelIdPool, locationAndPOSLabels);
    const labelToCompareWithSystemLabel = LabelUtils.labelToCompareWithSystemLabel(sortedLabelPool);
    // System Label
    const lowStockSystemLabel = LabelUtils.getLowStockSystemLabel(locationId, systemLabels);
    const restockSystemLabel = LabelUtils.getRestockSystemLabel(locationId, systemLabels);
    const saleSystemLabel = LabelUtils.getSaleSystemLabel(locationId, systemLabels);
    const newSystemLabel = LabelUtils.getNewSystemLabel(locationId, systemLabels);
    const systemLabelPool = LabelUtils.systemLabelPool(
      [menu, variants],
      [locationId, companyId, priceStream],
      [lowStockSystemLabel, restockSystemLabel],
      [saleSystemLabel, newSystemLabel]
    );
    const sortedSystemLabelPool = LabelUtils.sortedSystemLabelPool(systemLabelPool);
    const systemLabelToCompareWithCustomLabel = LabelUtils.systemLabelToCompareWithCustomLabel(sortedSystemLabelPool);
    const topLabels: Label[] = [labelToCompareWithSystemLabel, systemLabelToCompareWithCustomLabel];
    return topLabels?.filterNulls()?.sort(SortUtils.sortLabelsByPriority)?.firstOrNull();
  }

  static computeLabelOutsideOfMenuContext(
    [variants, labels, systemLabels]: [Variant[], Label[], SystemLabel[]],
    [locationId, companyId, priceStream]: [number, number, PriceFormat]
  ): Label {
    return LabelUtils.computeLabel(
      [null, null, variants],
      [labels, systemLabels],
      [locationId, companyId, priceStream]
    );
  }

  // Get the system label from a variant. Will return null if there is no system label applied
  static computeSystemLabel(
    [variant]: [Variant],
    [labels, systemLabels]: [Label[], SystemLabel[]],
    [locationId, companyId, priceStream]: [number, number, PriceFormat]
  ): SystemLabel {
    const lowStockSystemLabel = LabelUtils.getLowStockSystemLabel(locationId, systemLabels);
    const restockSystemLabel = LabelUtils.getRestockSystemLabel(locationId, systemLabels);
    const saleSystemLabel = LabelUtils.getSaleSystemLabel(locationId, systemLabels);
    const newSystemLabel = LabelUtils.getNewSystemLabel(locationId, systemLabels);
    const systemLabelPool = LabelUtils.systemLabelPool(
      [null, [variant]],
      [locationId, companyId, priceStream],
      [lowStockSystemLabel, restockSystemLabel],
      [saleSystemLabel, newSystemLabel]
    );
    const sortedSystemLabelPool = LabelUtils.sortedSystemLabelPool(systemLabelPool);
    return sortedSystemLabelPool?.firstOrNull();
  }

  static IsAutogeneratedSaleText(t: string): boolean {
    return t === 'X% Off' || t === '$X Off';
  }

  static getSystemLabelInfo(
    systemAppliedLabel: SystemLabel,
    variant: Variant,
    [locName, companyName]: [string, string],
    [locationConfig, companyConfig]: [LocationConfiguration, CompanyConfiguration],
    priceFormat: PriceFormat
  ): string {
    if (!!systemAppliedLabel) {
      const locId = locationConfig?.locationId;
      const autoAppliedText =
        `The '${systemAppliedLabel.getSortOrderTitle().toUpperCase()}' label is being automatically applied`;
      switch (systemAppliedLabel?.id) {
        case MenuLabel.Sale:
          const salePriceTuple = variant.getFormattedPrice(priceFormat, locId, locName, companyName, false);
          return `${autoAppliedText}. The variant '${variant.getDisplayName()}' is on sale `
            + `for ${salePriceTuple[0]} (${salePriceTuple[1]}).`;
        case MenuLabel.LowStock:
          return `${autoAppliedText}. The variant '${variant.getDisplayName()}' has an inventory `
            + `level of ${variant.inventory.quantityInStock}, which is below the low stock threshold `
            + `of ${systemAppliedLabel.numericThreshold}`;
        case MenuLabel.Restocked:
          const timeSinceLastRestock = DateUtils.hoursMinutesSince(variant?.inventory?.lastRestockedTimeInSeconds());
          return `${autoAppliedText}. The variant '${variant.getDisplayName()}' inventory level fell below the `
            + `quantity threshold of ${systemAppliedLabel.numericThreshold} and was restocked `
            + `${timeSinceLastRestock} ago, which is sooner than the threshold of `
            + `${systemAppliedLabel.timeThreshold} hours.`;
        case MenuLabel.New:
          if (!exists(variant?.inventory?.firstInventory) || variant?.inventory?.firstInventory === 0) return '';
          const firstInventory = DateUtils.formatUnixToDate(variant?.inventory?.firstInventory);
          return `${autoAppliedText}. The variant '${variant.getDisplayName()}' first received inventory at `
            + `${locName} on ${firstInventory}, which is within the threshold of  ${systemAppliedLabel.timeThreshold} `
            + `days.`;
      }
    }
    return '';
  }

  static labelsHaveTheSameIds(label1: Label, label2: Label): boolean {
    const sameLocationId = label1?.locationId === label2?.locationId;
    const sameId = label1?.id === label2?.id;
    return sameLocationId && sameId;
  }

  static updatedLabelsRequireSmartLabelSync(existingLabels: Label[], updatedLabels: Label[]): boolean {
    return existingLabels
      ?.map(prevLabel => {
        const matchingLabelIds = updated => LabelUtils.labelsHaveTheSameIds(prevLabel, updated);
        const updatedLabel = updatedLabels?.find(matchingLabelIds);
        return [prevLabel, updatedLabel];
      })
      ?.some(([prev, updated]) => prev?.changesRequireSmartLabelSync(updated));
  }

  static isCompanyOrphan(label: Label, locationCustomLabels: Label[]): boolean {
    return !label?.isSystemLabel
        && !label?.isPOSManaged
        && !locationCustomLabels?.find(locationLabel => locationLabel?.id === label?.id);
  }

}
