import { Component, ElementRef, forwardRef } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ClientTypeUtils } from '../../../utils/client-type-utils';

@Component({
  selector: 'app-reactive-form-smart-filter-list',
  templateUrl: './reactive-form-smart-filter-list.component.html',
  styleUrls: ['./reactive-form-smart-filter-list.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => ReactiveFormSmartFilterListComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReactiveFormSmartFilterListComponent)
    }
  ],
})
export class ReactiveFormSmartFilterListComponent extends ReactiveFormItemComponent implements ControlValueAccessor {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  public types = ClientTypeUtils;

  private _smartFilterIds = new BehaviorSubject<string[]>([]);
  public smartFilterIds$ = this._smartFilterIds.pipe(distinctUntilChanged());

  smartFiltersAdded(smartFilterIds: string[]): void {
    const ids = this._smartFilterIds.getValue()?.shallowCopy() || [];
    this.smartFiltersChanged([...ids, ...(smartFilterIds || [])]);
  }

  smartFilterRemoved(smartFilterId: string): void {
    const ids = this._smartFilterIds.getValue()?.shallowCopy() || [];
    const updated = ids.filter((id) => id !== smartFilterId);
    this.smartFiltersChanged(updated);
  }

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  getValidators(): ValidatorFn[] {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    return [...validators, ...this.customValidators];
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  protected override getBindingProperty(): any {
    const bindingValue = super.getBindingProperty();
    this._smartFilterIds.next(bindingValue || []);
    return bindingValue;
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  smartFiltersChanged(smartFilterIds: string[]) {
    this.handleInputChange(smartFilterIds);
    this.getSelfAsFormItem().patchValue(smartFilterIds);
    this.markAsDirty();
    this._smartFilterIds.next(smartFilterIds);
  }

  markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(obj: any): void {
  }

}
