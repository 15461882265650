import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDotsComponent } from './loading-dots/loading-dots.component';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  declarations: [
    LoadingDotsComponent
  ],
  exports: [
    LoadingDotsComponent
  ],
  imports: [
    CommonModule,
    LottieModule
  ]
})
export class BudsenseLottieModule { }
