<lib-reactive-form-drop-down
  #sectionType
  [inputName]="'sectionType'"
  [label]="'Section Type'"
  [placeholder]="'Select the type of section to create'"
  [bindingProperty]="'sectionType'"
  [required]="true"
  [disabled]="viewModel.disableSectionType$ | async"
  [dropdowns]="viewModel.sectionTypes$ | async"
  [hidden]="viewModel.hideSectionType$ | async"
  [dispersedKey]="dispersedKey"
  (valueChanged)="viewModel.setSectionType($event)">
</lib-reactive-form-drop-down>

<lib-reactive-form-text
  #sectionTitle
  [inputName]="'title'"
  [label]="'Section Title'"
  [placeholder]="'Enter a title for this section.'"
  [bindingProperty]="'title'"
  [disabled]="viewModel.hideTitle$ | async"
  [minLength]="1"
  [maxLength]="128"
  [required]="!(viewModel.hideTitle$ | async)"
  [hidden]="viewModel.hideTitle$ | async"
  [dispersedKey]="dispersedKey">
</lib-reactive-form-text>

<lib-reactive-form-drop-down
  [inputName]="'columnOptions'"
  [label]="'Column Options'"
  [placeholder]="'Theme Default'"
  [bindingProperty]="'defaultColumnConfigId'"
  [required]="false"
  [clearable]="true"
  [disabled]="viewModel.hideAndDisableColumnOptionsDropdown$ | async"
  [hidden]="viewModel.hideAndDisableColumnOptionsDropdown$ | async"
  [tooltip]="'Default Column Options can be created in company settings to save time when creating new menu sections.'"
  [dropdowns]="viewModel.columnOptions$ | async"
  [dispersedKey]="dispersedKey">
</lib-reactive-form-drop-down>
