import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { ImageObjectFit } from '../../enum/shared/image-object-fit';
import { ImageAlignment } from '../../enum/shared/image-alignment';

export class SectionMetadata implements Deserializable, UniquelyIdentifiable {

  public width: string = '100';
  public secondaryWidth: string = '100';
  public alignment: string = ImageAlignment.Left;
  public secondaryAlignment: string = ImageAlignment.Left;
  public imageAlignment: string = ImageAlignment.Center;
  public objectFit: ImageObjectFit = ImageObjectFit.Contain;
  public secondaryObjectFit: ImageObjectFit = ImageObjectFit.Contain;
  public repeat: string = 'false';
  public gridColumnNames: string = '';
  public hidePrices: string = 'false';
  public autoBalanceThreshold: string;
  public hideLowStockLabels: string = 'false'; // NOT RETURNED FROM API, USED LOCALLY
  public hideNewLabels: string = 'false'; // NOT RETURNED FROM API, USED LOCALLY
  public hideRestockedLabels: string = 'false'; // NOT RETURNED FROM API, USED LOCALLY
  // background color
  public titleSectionBackgroundColor: string = '';
  public productsHeaderBackgroundColor: string = '';
  public productsContainerBackgroundColor: string = '';
  public productsInfoBackgroundColor: string = '';
  // font color
  public sectionHeaderTextColor: string = '';
  public sectionBodyTextColor: string = '';
  // featured category cards
  public cardOpacity: string = '100';

  onDeserialize() {
    this.width = this.width || '100';
    this.secondaryWidth = this.secondaryWidth || '100';
    this.alignment = this.alignment || ImageAlignment.Left;
    this.secondaryAlignment = this.secondaryAlignment || ImageAlignment.Left;
    this.imageAlignment = this.imageAlignment || ImageAlignment.Center;
    this.objectFit = this.objectFit || ImageObjectFit.Contain;
    this.secondaryObjectFit = this.secondaryObjectFit || ImageObjectFit.Contain;
    this.repeat = this.repeat || 'false';
    this.gridColumnNames = this.gridColumnNames || '';
    this.hidePrices = this.hidePrices || 'false';
    this.titleSectionBackgroundColor = this.titleSectionBackgroundColor || '';
    this.productsHeaderBackgroundColor = this.productsHeaderBackgroundColor || '';
    this.productsContainerBackgroundColor = this.productsContainerBackgroundColor || '';
    this.productsInfoBackgroundColor = this.productsInfoBackgroundColor || '';
    this.sectionHeaderTextColor = this.sectionHeaderTextColor || '';
    this.sectionBodyTextColor = this.sectionBodyTextColor || '';
    if (!this.autoBalanceThreshold || parseInt(this.autoBalanceThreshold, 10) <= 0) {
      this.autoBalanceThreshold = null;
    }
    this.cardOpacity = this.cardOpacity || '100';
  }

  getUniqueIdentifier(): string {
    return `${this.width}
      -${this.secondaryWidth}
      -${this.alignment}
      -${this.secondaryAlignment}
      -${this.objectFit}
      -${this.secondaryObjectFit}
      -${this.imageAlignment}
      -${this.repeat}
      -${this.gridColumnNames}
      -${this.hidePrices}
      -${this.titleSectionBackgroundColor}
      -${this.productsHeaderBackgroundColor}
      -${this.productsContainerBackgroundColor}
      -${this.sectionHeaderTextColor}
      -${this.sectionBodyTextColor}`;
  }

}
