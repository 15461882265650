import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmartFilterRowViewModel } from './smart-filter-row-view-model';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';

@Component({
  selector: 'app-smart-filter-card-row',
  templateUrl: './smart-filter-card-row.component.html',
  styleUrls: ['./smart-filter-card-row.component.scss'],
})
export class SmartFilterCardRowComponent {

  @Input() showTopLine: boolean = true;
  @Output() cardClicked = new EventEmitter<HydratedSmartFilter[]>(true);

  constructor(
    public viewModel: SmartFilterRowViewModel // global, provided by root
  ) {
  }

  clearAppliedSmartFilters() {
    this.viewModel.clearAllSmartFilters();
  }

  openSelectSmartFilterModal() {
    this.viewModel.openSelectSmartFilterModal();
  }

}
