import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';

@Pipe({
  name: 'isWebMenu'
})
export class IsWebMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return value?.isWebMenu();
  }

}
