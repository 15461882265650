import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { EditFeaturedCategoryCardsViewModel } from './edit-featured-category-cards-view-model';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditFeaturedCategoryMenuViewModel } from '../edit-featured-category-menu-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Section } from '../../../../../../../models/menu/dto/section';
import { ModalReorderSections } from '../../../../../../../modals/modal-reorder-sections';
import { MenuTemplate } from '../../../../../../../models/template/dto/menu-template';
import { HydratedSection } from '../../../../../../../models/menu/dto/hydrated-section';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-featured-category-cards',
  templateUrl: './edit-featured-category-cards.component.html',
  styleUrls: ['./edit-featured-category-cards.component.scss'],
  providers: [EditFeaturedCategoryCardsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategoryCardsComponent extends BaseComponent {

  @Input() templateMode: boolean;
  @Input() sharedVM: EditFeaturedCategoryMenuViewModel;
  @Input() mergeKey: string;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

  public isReOrderable$ = new BehaviorSubject<boolean>(null);

  constructor(
    public viewModel: EditFeaturedCategoryCardsViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  override setupBindings() {
    this.sharedVM?.theme$.notNull()?.subscribeWhileAlive({
      owner: this,
      next: theme => this.viewModel.connectToTheme(theme)
    });
    this.sharedVM?.menu$.notNull()?.subscribeWhileAlive({
      owner: this,
      next: menu => {
        this.viewModel.connectToMenu(menu);
        const sections = menu instanceof MenuTemplate ? menu?.templateSections : menu?.sections;
        this.isReOrderable$.next(sections?.length > 1);
      }
    });
  }

  reorderCardsClicked() {
    const reorderOp = (ordered: Section[]): Observable<boolean> => this.sharedVM.saveMenuSectionOrder(ordered);
    const open = (sections: Section[]) => ModalReorderSections.open(this.ngbModal, this.injector, sections, reorderOp);
    this.sharedVM?.menuSections$.once(open);
  }

  addFeaturedCategoryCardClicked() {
    if (this.formPristine) {
      this.sharedVM.addFeaturedCategoryCard();
    } else {
      this.viewModel.formAutoSubmitted$.once(_ => this.sharedVM?.addFeaturedCategoryCard());
      this.submitForms.emit(true);
    }
  }

  duplicateCardClicked(section: HydratedSection) {
    if (this.formPristine) {
      this.sharedVM.duplicateSection(section);
    } else {
      this.viewModel.formAutoSubmitted$.once(_ => this.sharedVM?.duplicateSection(section));
      this.submitForms.emit(true);
    }
  }

}
