import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EditStaticSectionViewModel } from '../edit-static-section-menu/edit-static-section-view-model';
import { EditStaticSectionMenuViewModel } from '../edit-static-section-menu/edit-static-section-menu-view-model';
import { EditMenuSectionProductsComponent } from '../../../edit-menu-section/edit-menu-section-products/edit-menu-section-products.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({template: ''})
export abstract class EditStaticSectionMenuProductsComponent
  extends EditMenuSectionProductsComponent
  implements OnChanges {

  protected constructor(
    public menuViewModel: EditStaticSectionMenuViewModel,
    public sectionViewModel: EditStaticSectionViewModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(sectionViewModel, ngbModal, injector);
  }

  @Input() combinedAutoSaving: boolean = false;
  @Output() sortedVariantIds = new EventEmitter<string[]>(true);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateMode) {
      this.menuViewModel.connectToTemplateMode(this.templateMode);
      this.sectionViewModel.connectToTemplateMode(this.templateMode);
    }
  }

}
