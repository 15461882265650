import { TypeDefinition } from './type-definition';

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  ReversePortrait = 'reverseportrait',
  NA = ''
}

export class OrientationType extends TypeDefinition {

  override value: Orientation;

}
