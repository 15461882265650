import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SingleSelectionItem } from '../../../../models/shared/stylesheet/single-selection-item';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { SingleSelectionItemGrouping } from '../../../../models/shared/stylesheet/single-selection-item-grouping';
import { debounceTime, map } from 'rxjs/operators';

@Injectable()
export class SearchableSingleSelectViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _searchString = new BehaviorSubject<string>('');
  public searchString$ = this._searchString.asObservable();

  private _searchHits = new BehaviorSubject<SingleSelectionItem[]>([]);
  public searchHits$ = this._searchHits as Observable<SingleSelectionItem[]>;

  private _searchableItems = new BehaviorSubject<SingleSelectionItem[]>(null);
  public searchableItems$ = this._searchableItems as Observable<SingleSelectionItem[]>;

  private _searchableGroupedItems = new BehaviorSubject<SingleSelectionItemGrouping[]>(null);
  public searchableGroupedItems$ = this._searchableGroupedItems as Observable<SingleSelectionItemGrouping[]>;

  public itemsToSearch$ = combineLatest([
    this.searchableItems$,
    this.searchableGroupedItems$
  ]).pipe(
    map(([items, groups]) => (!!groups ? groups?.flatMap(g => g?.items) : items))
  );

  public groupedHits$ = combineLatest([
    this.searchString$,
    this.searchHits$,
    this.searchableGroupedItems$,
  ]).pipe(
    debounceTime(100),
    map(([searched, hits, groups]) => {
      return !!searched && !!groups ? SingleSelectionItemGrouping.createGroupedSearchHits(hits) : groups;
    })
  );

  public individualHits$ = combineLatest([
    this.searchString$,
    this.searchHits$,
    this.searchableItems$,
  ]).pipe(
    debounceTime(100),
    map(([searched, hits, items]) => {
      return !!searched && !!items ? hits : items;
    })
  );

  public showNoItems$ = combineLatest([
    this.searchableItems$,
    this.searchableGroupedItems$
  ]).pipe(
    map(([i, g]) => i?.length === 0 && (g?.length === 0 || !g?.some(group => group?.items?.length > 0)))
  );

  public showNoResults$ = combineLatest([
    this.searchableItems$,
    this.individualHits$,
    this.isLoading$
  ]).pipe(
    map(([items, hits, isLoading]) => {
      const noHits = hits?.length < 1;
      const hasItems = items?.length > 0;
      return !isLoading && noHits && hasItems;
    })
  );

  public showNoResultGroups$ = combineLatest([
    this.searchableGroupedItems$,
    this.groupedHits$,
    this.isLoading$
  ]).pipe(
    map(([groups, hits, isLoading]) => {
      const noHits = hits?.length < 1;
      const hasGroups = groups?.length > 0;
      return !isLoading && noHits && hasGroups;
    })
  );

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());

  private showLoadingSpinner = this.itemsToSearch$.subscribeWhileAlive({
    owner: this,
    next: i => {
      const lm = 'Loading';
      if (!i) {
        this._loadingOpts.addRequest(lm);
      } else {
        this._loadingOpts.removeRequest(lm);
      }
    }
  });

  public connectToSearchString = (s: string) => this._searchString.next(s);
  public connectToSearchHits = (hits: SingleSelectionItem[]) => this._searchHits.next(hits);
  public connectToSearchableItems = (items: SingleSelectionItem[]) => this._searchableItems.next(items);
  public connectToSearchableGroupItems = (items: SingleSelectionItemGrouping[]) =>
    this._searchableGroupedItems.next(items);

}
