import { Injectable, Injector } from '@angular/core';
import { EditMarketingMenuViewModel } from '../../../../viewModels/edit-marketing-menu-view-model';
import { Observable, of, throwError } from 'rxjs';
import { ModalAddSmartPlaylistProductGrouping } from '../../../../../../modals/modal-add-smart-playlist-product-grouping';
import { BsError } from '../../../../../../models/shared/bs-error';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { Section } from '../../../../../../models/menu/dto/section';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { SyncSmartFilterService } from '../../../../../../services/smart-filter-sync.service';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { ModalPromptForProductGroupingDelete } from '../../../../../../modals/modal-prompt-for-product-grouping-delete';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { PrintPDFService } from '../../../../../../services/print-pdf.service';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { DisplayDomainModel } from '../../../../../../domainModels/display-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';

@Injectable()
export class EditSmartPlaylistMenuViewModel extends EditMarketingMenuViewModel {

  constructor(
    protected syncSmartFilterService: SyncSmartFilterService,
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    displayDomainModel: DisplayDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    templateDomainModel: TemplateDomainModel,
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    userDomainModel: UserDomainModel,
    menuPreviewService: MenuPreviewService,
    printPDFService: PrintPDFService,
    navigationService: NavigationService,
    toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
    sanitizer: DomSanitizer,
    activatedRoute: ActivatedRoute,
    injector: Injector
  ) {
    super(
      bulkPrintJobDomainModel,
      displayDomainModel,
      locationDomainModel,
      menuDomainModel,
      productDomainModel,
      templateDomainModel,
      templateCollectionDomainModel,
      userDomainModel,
      menuPreviewService,
      printPDFService,
      navigationService,
      toastService,
      router,
      ngbModal,
      sanitizer,
      activatedRoute,
      injector
    );
  }

  showSaveButton$ = of(true);

  addProductGroupingClicked() {
    this.menu$.once(menu => {
      const onClose = (section: Section) => {
        const url = section?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalAddSmartPlaylistProductGrouping.open(this.ngbModal, this.injector, menu, null, onClose);
    });
  }

  syncSectionSmartFilters(menu: Menu, section: Section) {
    this.syncSmartFilterService.syncSectionSmartFilters(menu, section).subscribe(syncedSection => {
      if (!!syncedSection) {
        this.toastService.publishBannerSuccess(section.whatChangedString(syncedSection));
      }
    }, (error: BsError) => {
      this.toastService.publishBannerFailed(error?.message);
      throwError(error);
    });
  }

  override duplicateSection(section: HydratedSection) {
    this.menu$.once(menu => {
      const onClose = (newSection: Section) => {
        const url = newSection?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalAddSmartPlaylistProductGrouping.open(this.ngbModal, this.injector, menu, section, onClose);
    });
  }

  promptForDeleteSection(section: Section) {
    const confirmation = (cont) => { if (cont) { this.deleteSection(section); } };
    ModalPromptForProductGroupingDelete.open(this.ngbModal, this.injector, section, confirmation);
  }

  uploadSectionAsset(file: BudsenseFile, menu: Menu, section: Section): Observable<any> {
    return this.menuDomainModel.uploadSectionAsset(file, menu, section);
  }

  refreshMenu() {
    this.menu$.once(menu => {
      menu instanceof MenuTemplate ? this.loadHydratedMenuTemplate(true) : this.loadHydratedMenu(true);
    });
  }

  trackBySectionId(index: number, section: Section) {
    return section.id;
  }

}
