import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditMenuStyleViewModel } from './edit-menu-style-view-model';
import { MenuStyle } from '../../../../../models/menu/dto/menu-style';
import { Variant } from '../../../../../models/product/dto/variant';
import { Section } from '../../../../../models/menu/dto/section';
import { MenuStyleObject } from '../../../../../models/utils/dto/menu-style-object-type';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';

@Component({
  selector: 'app-edit-menu-style-modal',
  templateUrl: './edit-menu-style-modal.component.html',
  styleUrls: ['./edit-menu-style-modal.component.scss'],
  providers: [EditMenuStyleViewModel]
})
export class EditMenuStyleModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: EditMenuStyleViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override setupBindings() {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: (close) => {
        if (close) this.cancel();
      }
    });
  }

  setupModal(variant: Variant, section: Section, style: MenuStyle, siblingIds: string[], palette: string[]) {
    this.viewModel.connectToSiblingIds(siblingIds);
    this.viewModel.connectToVariant(variant);
    this.viewModel.connectToSection(section);
    this.viewModel.connectToColorPalette(palette);
    if (style) {
      // duplicate style so that changes are killed when modal is cancelled
      const styleCopy = window?.injector?.Deserialize?.instanceOf(MenuStyle, style);
      if (!styleCopy.fontDecoration) {
        styleCopy.fontDecoration = [false, false];
        styleCopy.applyToProductVariants = styleCopy.objectType === MenuStyleObject.Product;
      }
      styleCopy.setupFontDecorationFormData();
      this.viewModel.connectToOriginalMenuStyle(styleCopy);
    } else {
      const newStyle = MenuStyle.newStyle(section instanceof SectionTemplate);
      newStyle.sectionId = section.id;
      newStyle.objectId = variant.id;
      newStyle.objectType = MenuStyleObject.Variant;
      newStyle.setupFontDecorationFormData();
      this.viewModel.connectToOriginalMenuStyle(newStyle);
    }
  }

}
