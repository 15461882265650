import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'disableGridColumnCheckboxTooltip'
})
export class DisableGridColumnCheckboxTooltipPipe implements PipeTransform {

  transform(isDisabled$: Observable<boolean>): Observable<string> {
    return isDisabled$.pipe(
      map((isDisabled) => {
        return isDisabled
          ? 'Selecting additional grid columns would exceed the maximum width.'
          : null;
      })
    );
  }

}
