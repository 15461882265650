export class HtmlUtils {

  static getElementTotalHeight(nativeElement: any): number {
    if (!!nativeElement) {
      const computedStyle = window.getComputedStyle(nativeElement);
      const marginTop = parseFloat(computedStyle?.marginTop?.replace('px', ''));
      const marginBottom = parseFloat(computedStyle?.marginBottom?.replace('px', ''));
      const paddingTop = parseFloat(computedStyle?.paddingTop?.replace('px', ''));
      const paddingBottom = parseFloat(computedStyle?.paddingBottom?.replace('px', ''));
      const borderTop = parseFloat(computedStyle?.borderTopWidth?.replace('px', ''));
      const borderBottom = parseFloat(computedStyle?.borderBottomWidth?.replace('px', ''));
      const zoom = parseFloat(nativeElement?.style?.zoom || '1');
      // Don't use nativeElement.offsetHeight because it doesn't give you the decimal value.
      // Window.getComputedStyle(nativeElement).getPropertyValue('height') gives you the decimal value.
      // Without the decimal your heights will be off.
      const height = parseFloat(computedStyle.getPropertyValue('height')) || 0;
      const padding = paddingTop + paddingBottom;
      const border = borderTop + borderBottom;
      const margin = marginTop + marginBottom;
      const totalHeight = height + padding + border + margin;
      return zoom * totalHeight;
    } else {
      return 0;
    }
  }

  static getElementPaddingAndBorderHeight(nativeElement: any): number {
    if (!!nativeElement) {
      const computedStyle = window.getComputedStyle(nativeElement);
      const paddingTop = parseFloat(computedStyle?.paddingTop?.replace('px', ''));
      const paddingBottom = parseFloat(computedStyle?.paddingBottom?.replace('px', ''));
      const borderTop = parseFloat(computedStyle?.borderTopWidth?.replace('px', ''));
      const borderBottom = parseFloat(computedStyle?.borderBottomWidth?.replace('px', ''));
      const zoom = parseFloat(nativeElement?.style?.zoom || '1');
      const padding = paddingTop + paddingBottom;
      const border = borderTop + borderBottom;
      return zoom * (padding + border);
    } else {
      return 0;
    }
  }

  static getElementTopMargin(nativeElement: any): number {
    if (!!nativeElement) {
      const marginTop = parseFloat(window.getComputedStyle(nativeElement)?.marginTop?.replace('px', ''));
      const zoom = parseFloat(nativeElement?.style?.zoom || '1');
      return zoom * marginTop;
    } else {
      return 0;
    }
  }

  static getElementBottomMargin(nativeElement: any): number {
    if (!!nativeElement) {
      const marginBottom = parseFloat(window.getComputedStyle(nativeElement)?.marginBottom?.replace('px', ''));
      const zoom = parseFloat(nativeElement?.style?.zoom || '1');
      return zoom * marginBottom;
    } else {
      return 0;
    }
  }

  static getElementHeightWithoutMargins(nativeElement: any): number {
    return HtmlUtils.getElementTotalHeight(nativeElement)
      - HtmlUtils.getElementTopMargin(nativeElement)
      - HtmlUtils.getElementBottomMargin(nativeElement);
  }

}
