import { Deserializable } from '../../protocols/deserializable';

export class RefreshSessionRequest implements Deserializable {

  constructor(userId, refreshToken: string) {
    this.userId = userId;
    this.refreshToken = refreshToken;
  }

  public userId: string = '';
  public refreshToken: string = '';
  onDeserialize = ()  => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-authenticator/blob/dev/models/RefreshSessionRequest.go
  onSerialize() {
    const dto = Object.create(RefreshSessionRequest.prototype);
    dto.userId = this.userId;
    dto.refreshToken = this.refreshToken;
    return dto;
  }

}
