import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';

@Component({
  selector: 'app-edit-display-menu-details-action',
  templateUrl: './edit-display-menu-details-action.component.html',
  styleUrls: ['./edit-display-menu-details-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayMenuDetailsActionComponent extends BaseComponent {

  @Input() nMenus: number;
  @Input() disabled: boolean;
  @Input() formPristine: boolean = true;
  @Input() reorderMenus: () => void;
  @Input() addMenu: () => void;
  @Input() collectionMode: boolean;

  constructor() {
    super();
  }

}
