<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <label
    [hidden]="hideBulkCheckbox"
    class="reactive-form-checkbox-container"
    [style.margin-bottom.rem]="3"
    [style.bottom.rem]="0.25"
    [class.disabled]="disabled"
    [htmlFor]="id">
    <input
      class="reactive-form-checkbox"
      [id]="id"
      [name]="inputName"
      type="checkbox"
      [attr.disabled]="disabled ? true : null"
      [checked]="checked$ | async"
      (change)="_selectAllClicked.next()"
      (focus)="inputFocus.emit($event)"
      (blur)="inputBlur.emit($event)" />
    <span class="reactive-form-checkbox-label">{{ checkAllText }}</span>
    <span
      class="reactive-form-checkmark"
      [ngbTooltip]="disabledTooltip$ | async"
      [openDelay]="disabledTooltipDelayMs"
      [disableTooltip]="disableTooltip$ | async"
      [container]="'body'">
    </span>
  </label>
  <ng-content></ng-content>
  <lib-reactive-form-general-error
    [hidden]="hideErrorMessage"
    [parentFormGroupComponent]="reactiveFormGroupComponent"
    [reactiveFormItemComponent]="this"
    [required]="required"
    [requireMinimumSelection]="requireMinimumSelection"
    [maximumSelections]="maximumSelections"
    [disabled]="disabled"
    [performValidationWhenDisabled]="performValidationWhenDisabled"
    [marginLeft]="'0'"
    [marginTop]="'0.5rem'">
  </lib-reactive-form-general-error>
</div>
