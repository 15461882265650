export class ProductPickerModalOptions {

  // CTAs
  public cancelText: string = 'Cancel';
  public cancelButtonClass: string = 'neutral-button';
  public confirmText: string = 'Do it';
  public confirmButtonClass: string = 'preferred-button';
  // Loading
  public loadingMess: string;
  public successTitle: string;
  public successMess: string;
  public failureTitle: string;
  public failureMess: string;

  static productGroupingPickerModalOptions(showSuccessToast: boolean): ProductPickerModalOptions {
    const opts = new ProductPickerModalOptions();
    opts.confirmText = 'Add Products';
    opts.loadingMess = 'Adding products to product grouping';
    if (showSuccessToast) opts.successTitle = 'Products Added';
    opts.successMess = 'Products successfully added to product grouping.';
    opts.failureTitle = 'Add Products Failed';
    opts.failureMess = 'Unable to add products to product grouping';
    return opts;
  }

  static defaultProductPickerModalOptions(): ProductPickerModalOptions {
    const opts = new ProductPickerModalOptions();
    opts.confirmText = 'Add Products';
    opts.loadingMess = 'Adding products to section';
    opts.successTitle = 'Products Added';
    opts.successMess = 'Products successfully added to section.';
    opts.failureTitle = 'Add Products Failed';
    opts.failureMess = 'Unable to add products to section';
    return opts;
  }

  static ignoreVariantsModalOptions(): ProductPickerModalOptions {
    const opts = new ProductPickerModalOptions();
    opts.confirmText = 'Save Selected Variants';
    opts.loadingMess = 'Ignoring your selected variants';
    opts.successTitle = 'Variants added.';
    opts.successMess = 'The selected variants will be ignored from smart filters.';
    opts.failureTitle = 'Adding Variants Failed';
    opts.failureMess = 'Unable to ignore variants';
    return opts;
  }

}

