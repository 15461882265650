import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { debounceTime, map } from 'rxjs/operators';
import { LabelUtils } from '../../../../../../../../modules/product-labels/utils/label-utils';
import { LocationDomainModel } from '../../../../../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { Label } from '../../../../../../../../models/shared/label';
import { MenuLabel } from '../../../../../../../../models/utils/dto/menu-label-type';

@Injectable()
export class CustomizationPreviewViewModel extends BaseViewModel {

  private _variant = new BehaviorSubject<Variant>(null);
  public variant$ = this._variant as Observable<Variant>;
  connectToVariant = (v: Variant) => this._variant.next(v);

  private _computedPrimaryLabel = new BehaviorSubject<Label>(null);
  public computedPrimaryLabel$ = this._computedPrimaryLabel as Observable<Label>;

  public labelSource$ = new BehaviorSubject<string>(null);

  constructor(
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private setLabelSource = combineLatest([
    this.computedPrimaryLabel$.notNull(),
    this.variant$
  ]).pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: ([label, variant]) => {
        let source = null;
        if (label?.isSystemLabel && label?.id !== MenuLabel.Featured) {
          source = '(System)';
        } else {
          const locationDA = variant?.displayAttributes;
          const companyDA = locationDA?.inheritedDisplayAttribute;
          if (companyDA?.defaultLabel === label?.id) {
            source = '(Company)';
          }
          if (locationDA?.defaultLabel === label?.id) {
            source = '(Location)';
          }
        }
        this.labelSource$.next(source);
      }
    });

  public computeLabelInterface$ = combineLatest([
    this.variant$,
    this.locationDomainModel.locationConfig$,
    this.companyDomainModel.companyConfiguration$
  ]).pipe(
    map(([variant, locConfig, compConfig]) => {
      return LabelUtils.getComputeLabelInterfaceFromVariantOutsideOfMenuContext(variant, locConfig, compConfig);
    })
  );

  public setPrimaryComputedLabel(l: Label) {
    this._computedPrimaryLabel.next(l);
  }

}

