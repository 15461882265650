import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Menu } from '../../../../../models/menu/dto/menu';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { MarketingMenuType } from '../../../../../models/enum/dto/marketing-menu-type.enum';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { DefaultPrintCardPaperSize } from '../../../../../models/utils/dto/default-print-card-paper-size-type';
import type { MenuPrintOptionsFormComponent } from './additional-option-forms/menu-print-options-form/menu-print-options-form.component';
import { DefaultPrintSize } from '../../../../../models/utils/dto/default-print-size-type';
import { ThemeUtils } from '../../../../../utils/theme-utils';

@Injectable()
export class MenuAdditionalOptionsViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private _menu = new BehaviorSubject<Menu>(null);
  public  menu$ = this._menu as Observable<Menu>;
  public isTemplatedMenu$ = this.menu$.pipe(
    map(menu => menu?.isTemplatedMenu())
  );

  private _printOptionsForm = new BehaviorSubject<MenuPrintOptionsFormComponent | null>(null);
  private printOptionsForm$ = this._printOptionsForm as Observable<MenuPrintOptionsFormComponent | null>;
  connectToPrintOptionForm = (form: MenuPrintOptionsFormComponent) => this._printOptionsForm.next(form);

  private _printCardPaperSize = new BehaviorSubject<DefaultPrintCardPaperSize | null>(null);
  public printCardPaperSize$ = this._printCardPaperSize as Observable<DefaultPrintCardPaperSize | null>;
  connectToPrintCardPaperSize(paperSize: DefaultPrintCardPaperSize) {
    if (paperSize?.toLowerCase()?.includes('perforated')) {
      this.printOptionsForm$.once(form => {
        form?.singlePageMenuSwitch?.getSelfAsFormItem()?.patchValue(false);
      });
    }
    this._printCardPaperSize.next(paperSize);
  }

  public singlePageMenuSwitchDisabled$ = this.printCardPaperSize$.pipe(
    map(p => p?.toLowerCase()?.includes('perforated'))
  );

  public hasAltLogo$ = this.companyDomainModel.company$.pipe(map(c => !!c?.altLogo));
  public colorPalette$ = this.companyDomainModel.colorPalette$;
  public isPrintMenu$ = this.menu$.pipe(map(m => m?.type === MenuType.PrintMenu), distinctUntilChanged());

  public hasLoopingContent$ = this.menu$.pipe(
    map(m => {
      const playlistMenu = m?.hydratedTheme?.menuSubType === MarketingMenuType.Playlist;
      const smartPlaylistMenu = m?.hydratedTheme?.menuSubType === MarketingMenuType.SmartPlaylist;
      const featuredProduct = m?.hydratedTheme?.menuSubType === MarketingMenuType.Featured;
      return playlistMenu || smartPlaylistMenu || featuredProduct;
    })
  );

  public isPlaylist$ = this.menu$.pipe(
    map(m => {
      return m?.hydratedTheme?.menuSubType === MarketingMenuType.Playlist
          || m?.hydratedTheme?.menuSubType === MarketingMenuType.SmartPlaylist
          || m?.hydratedTheme?.menuSubType === MarketingMenuType.UrlPlaylist;
    })
  );

  public readonly isUrlPlaylistMenu$ = this.menu$.pipe(
    map(m => m?.isUrlPlaylistMenu())
  );

  public isDriveThruMenu$ = this.menu$.pipe(
    map(m => {
      return m?.hydratedTheme?.menuSubType === MarketingMenuType.DriveThru;
    })
  );

  public isFeatProductMenu$ = this.menu$.pipe(
    map(m => {
      return m?.hydratedTheme?.menuSubType === MarketingMenuType.Featured;
    })
  );

  public isPrintCardMenu$ = this.menu$.pipe(
    map(m => {
      return m?.type === MenuType.PrintCardMenu;
    })
  );

  public isPrintLabelMenu$ = this.menu$.pipe(
    map(m => {
      return m?.type === MenuType.PrintLabelMenu;
    })
  );

  public readonly containsStackedContent$ = this.menu$.pipe(
    map(m => m?.containsStackedContent())
  );

  public isPrintableMenu$ = this.menu$.pipe(
    map(m => m?.isPrintableMenu())
  );

  public isFeatCatMenu$ = this.menu$.pipe(
    map(m => {
      return m?.hydratedTheme?.menuSubType === MarketingMenuType.Category;
    })
  );

  public readonly showPrintOptions$ = this.isPrintableMenu$;

  public readonly showHideOptions$ = combineLatest([
    this.hasLoopingContent$,
    this.isFeatProductMenu$,
    this.isPrintLabelMenu$,
    this.isUrlPlaylistMenu$,
  ]).pipe(
    map(([hasLoopingContent, isFeatProductMenu, isPrintLabelMenu, isUrlPlaylistMenu]) => {
      const shouldShow = !hasLoopingContent || isFeatProductMenu;
      const invalidMenu = isPrintLabelMenu || isUrlPlaylistMenu;
      return shouldShow && !invalidMenu;
    })
  );

  public readonly showZoomOptions$ = combineLatest([
    this.hasLoopingContent$,
    this.isDriveThruMenu$,
    this.isFeatCatMenu$,
    this.isUrlPlaylistMenu$,
    this.containsStackedContent$
  ]).pipe(
    map(([hasLooping, isDriveThru, isFeatCat, isUrlPlaylist, containsStackedContent]) => {
      return !hasLooping
          && !isDriveThru
          && !isFeatCat
          && !isUrlPlaylist
          && !containsStackedContent;
    })
  );

  public readonly showLayoutOptions$ = combineLatest([
    this.isPlaylist$,
    this.containsStackedContent$
  ]).pipe(
    map(([isPlaylist, containsStackedContent]) => {
      return !isPlaylist && !containsStackedContent;
    })
  );

  public readonly showMediaOptions$ = combineLatest([
    this.menu$,
    this.isPlaylist$,
    this.isFeatProductMenu$
  ]).pipe(
    map(([menu, isPlaylist, isFeatProductMenu])  => {
      return (!menu?.isUrlPlaylistMenu() && isPlaylist) || isFeatProductMenu;
    })
  );

  public needsToBeSinglePage$ = this.menu$.pipe(
    map(menu => menu?.displaySize?.name === DefaultPrintSize.CustomLargeFormat24x60)
  );

  public hideSaleDisabled$ = this.menu$.pipe(
    map(menu => ThemeUtils.themeIdsWithHideSaleDisabled().contains((menu?.theme)))
  );

  public showAlternativeLogoDisabled$ = this.menu$.pipe(
    map(menu => ThemeUtils.themeIdsWithShowAlternativeLogoDisabled().contains(menu?.theme))
  );

  public connectToMenu = (m: Menu) => this._menu.next(m);

}
