import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { TemplateCollection } from '../models/template/dto/template-collection';
import { TemplateAPI } from '../api/template-api';
import { CompanyDomainModel } from './company-domain-model';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { DisplayDomainModel } from './display-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { DistinctUtils } from '../utils/distinct-utils';
import { UserDomainModel } from './user-domain-model';
import { exists } from '../functions/exists';

// Map<locationId, Map<templateCollectionId, TemplateCollection>>
type HydratedTemplateCollectionMap = Map<number, Map<string, TemplateCollection>>;

// Provided by Logged In Scope
@Injectable()
export class TemplateCollectionDomainModel extends BaseDomainModel {

  constructor(
    private templateAPI: TemplateAPI,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private userDomainModel: UserDomainModel,
  ) {
    super();
  }

  /**
   * Circuit breaker for template collection feature.
   * If the breaker is enabled, then on$ will flow.
   * If the breaker is disabled, then the observable flow will emit the offDefaultValue.
   *
   * @returns an observable stream
   */
  private templateCollectionCircuitBreaker = <T>(on$: Observable<T>, offDefaultValue: T): Observable<T> => {
    return this.userDomainModel.canUseTemplates$.pipe(
      switchMap(canUseTemplates => iif(() => canUseTemplates, on$, of(offDefaultValue)))
    );
  };

  private readonly companyId$ = this.companyDomainModel.companyId$;
  private readonly locationId$ = this.locationDomainModel.locationId$;

  private _templateCollections = new BehaviorSubject<TemplateCollection[]>(null);
  public templateCollections$ = this.templateCollectionCircuitBreaker(
    this._templateCollections.pipe(
      map((collections) => collections?.sort((a, b) => a?.name?.localeCompare(b?.name))),
      shareReplay({ bufferSize: 1, refCount: true })
    ),
    []
  );
  connectToTemplateCollections = (collections: TemplateCollection[]) => this._templateCollections.next(collections);

  private _hydratedTemplateCollections = new BehaviorSubject<HydratedTemplateCollectionMap>(new Map());
  public hydratedTemplateCollections$ = this._hydratedTemplateCollections as Observable<HydratedTemplateCollectionMap>;
  connectToHydratedTemplateCollections = (collectionMap: HydratedTemplateCollectionMap) => {
    this._hydratedTemplateCollections.next(collectionMap);
  };

  private _activeCollectionId = new BehaviorSubject<string>(null);
  public activeCollectionId$ = this._activeCollectionId.asObservable();

  public selectActiveCollection = (collectionId: string) => this._activeCollectionId.next(collectionId);
  public clearActiveCollection = () => this._activeCollectionId.next(null);

  public activeCollection$ = combineLatest([
    this.locationId$,
    this.activeCollectionId$,
    this.hydratedTemplateCollections$,
  ]).pipe(
    map(([locationId, activeCollectionId, collections]) => {
      return collections?.get(locationId)?.get(activeCollectionId);
    })
  );

  public existingCollectionTags$ = this.templateCollections$.pipe(
    map(templates => TemplateCollection.getUniqueTags(templates)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  /* *************************** Local Threads of Execution *************************** */

  private fetchCompanyTemplateCollections = this.templateCollectionCircuitBreaker(this.companyId$, null)
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: companyId => {
        if (exists(companyId)) this.getCompanyTemplateCollections();
      }
    });

  /* ********************************************************************************* */

  createTemplateCollection(newCollection: TemplateCollection): Observable<TemplateCollection> {
    return this.templateAPI.CreateTemplateCollection(newCollection).pipe(
      tap(newTemplateCollection => {
        this.replaceTemplateCollection(newTemplateCollection);
      })
    );
  }

  getTemplateCollection(id: string): Observable<TemplateCollection> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap((locationId) => this.templateAPI.GetTemplateCollection(locationId, id)),
      tap((collection) => {
        this.replaceTemplateCollection(collection);
        this.replaceHydratedTemplateCollection(collection);
      })
    );
  }

  private replaceHydratedTemplateCollection(collection: TemplateCollection) {
    combineLatest([this.locationId$, this.hydratedTemplateCollections$]).once(([locId, hcs]) => {
      const hydratedCollections = hcs?.shallowCopy();
      if (!hydratedCollections?.get(locId)) hydratedCollections?.set(locId, new Map());
      hydratedCollections?.get(locId)?.set(collection.id, collection);
      this.connectToHydratedTemplateCollections(hydratedCollections);
    });
  }

  private replaceTemplateCollection(collection: TemplateCollection) {
    this.templateCollections$.once(tcs => {
      const templateCollections = tcs?.shallowCopy();
      const collectionIndex = templateCollections?.findIndex(tc => tc?.id === collection?.id);
      if (collectionIndex >= 0) {
        templateCollections?.splice(collectionIndex, 1, collection);
      } else {
        templateCollections?.push(collection);
      }
      this.connectToTemplateCollections(templateCollections);
    });
  }

  public updateMenuTemplatesInCollections(updatedTemplates: MenuTemplate[], removeItems: boolean = false) {
    this.templateCollections$.once(existingCollections => {
      const updatedCollections = existingCollections?.deepCopy();
      updatedCollections?.forEach(collection => {
        const collectionIncludes = (updated: MenuTemplate) => collection.templateIds?.includes(updated?.id);
        if (removeItems) {
          collection.templates = MenuTemplate.updateMenuTemplates(collection?.templates, updatedTemplates, true);
          collection.templateIds = collection.templates?.map(template => template?.id);
        } else {  // only update the templates that are in the collection
          updatedTemplates?.forEach(updatedTemplate => {
            if (collectionIncludes(updatedTemplate)) {
              collection.templates = MenuTemplate.updateMenuTemplates(collection?.templates, [updatedTemplate], false);
            }
          });
        }
      });
      this.displayDomainModel.updateCollectionsAttachedToDisplay(updatedCollections);
      this.connectToTemplateCollections(updatedCollections);
    });
  }

  deleteTemplateCollection(collection: TemplateCollection): Observable<any> {
    return this.templateAPI.DeleteTemplateCollection(collection).pipe(
      tap(() => {
        this.removeTemplateCollection(collection);
        this.removeHydratedTemplateCollection(collection);
      })
    );
  }

  private removeHydratedTemplateCollection(collection: TemplateCollection) {
    combineLatest([this.locationId$, this.hydratedTemplateCollections$]).once(([locId, hcs]) => {
      const hydratedCollections = hcs?.shallowCopy();
      hydratedCollections?.get(locId)?.delete(collection.id);
      this.connectToHydratedTemplateCollections(hydratedCollections);
    });
  }

  private removeTemplateCollection(collection: TemplateCollection) {
    this.templateCollections$.once(tcs => {
      const templateCollections = tcs?.shallowCopy() || [];
      const collectionIndex = templateCollections?.findIndex(tc => tc?.id === collection?.id);
      if (collectionIndex >= 0) {
        templateCollections?.splice(collectionIndex, 1);
      }
      this.displayDomainModel.updateCollectionsAttachedToDisplay(templateCollections);
      this.connectToTemplateCollections(templateCollections);
    });
  }

  private appendCollections(incomingCollections: TemplateCollection[]) {
    this.templateCollections$.once(collections => {
      const newCollections = [...(collections ?? []), ...(incomingCollections ?? [])];
      this.connectToTemplateCollections(newCollections);
    });
  }

  private getCompanyTemplateCollections() {
    this.templateAPI.GetCompanyMenuTemplateCollections().once(collections => this.appendCollections(collections));
  }

  updateTemplateCollection(
    collection: TemplateCollection,
    refreshHydratedCollection = false
  ): Observable<TemplateCollection> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap((locationId) => this.templateAPI.UpdateTemplateCollection(locationId, collection)),
      tap((updatedCollection) => this.replaceTemplateCollection(updatedCollection)),
      switchMap((updatedCollection) => {
        return refreshHydratedCollection
          ? this.getTemplateCollection(updatedCollection.id)
          : of(updatedCollection);
      })
    );
  }

  removeTemplateFromCollection(templateId: string, collection: TemplateCollection): Observable<TemplateCollection> {
    // remove the template from the collection immediately to update the UI
    return combineLatest([this.companyId$, this.locationId$, this.hydratedTemplateCollections$]).pipe(
      take(1),
      tap(([companyId, locationId, hydratedCollections]) => {
        collection.templates = collection.templates.filter((t) => t.id !== templateId);
        collection.companyId = companyId;
        collection.templateIds = collection.templates.map((t) => t.id);
        collection.options?.removeId(templateId);
        hydratedCollections?.get(locationId)?.set(collection.id, collection);
        this.connectToHydratedTemplateCollections(hydratedCollections);
      }),
      switchMap(() => this.updateTemplateCollection(collection))
    );
  }

  addTemplatesToCollection(
    templateIds: string[],
    collection: TemplateCollection,
    allTemplates: MenuTemplate[]
  ): Observable<TemplateCollection> {
    return this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        // add the templates to the collection immediately to update the UI
        const newTemplates = allTemplates?.filter(t => templateIds?.includes(t.id));
        collection.templates = collection.templates.concat(newTemplates).sort((a, b) => {
          return collection.options.rotationOrder.get(a.id) - collection.options.rotationOrder.get(b.id);
        });
        collection.companyId = companyId;
        collection.templateIds = collection.templates.map((t) => t.id);
        return this.updateTemplateCollection(collection, true);
      })
    );
  }

}
