import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../../../../../../../models/product/dto/variant';

@Pipe({
  name: 'variantsInCardStack'
})
export class VariantsInCardStackPipe implements PipeTransform {

  transform(variants: Variant[], cardStackVariantIds: string[]): Variant[] {
    return variants?.filter(v => cardStackVariantIds?.includes(v?.id)) ?? [];
  }

}
