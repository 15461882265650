import { Pipe, PipeTransform } from '@angular/core';
import { CompactMenu } from '../../../models/menu/dto/compact-menu';
import { MenuTemplate } from '../../../models/template/dto/menu-template';

@Pipe({
  name: 'templateImportedAtMyLocation'
})
export class TemplateImportedAtMyLocationPipe implements PipeTransform {

  transform(compactMenu: CompactMenu, allTemplates: MenuTemplate[], locationId: number): boolean {
    const template = allTemplates?.find((t) => t.id === compactMenu.id);
    return template?.activeLocationIds?.includes(locationId);
  }

}
