import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templatedMenuDisabledButtonTooltip'
})
export class TemplatedMenuDisabledButtonTooltipPipe implements PipeTransform {

  transform(isTemplated: boolean): string {
    if (isTemplated) {
      return 'This action may only be performed on the template';
    }
    return null;
  }

}
