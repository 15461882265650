import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateCardStackMenuHeaderComponent } from '../../edit-template-card-stack-menu/edit-template-card-stack-menu-header/edit-template-card-stack-menu-header.component';
import { EditTemplateLabelStackMenuViewModel } from '../edit-template-label-stack-menu-view-model';

@Component({
  selector: 'app-edit-template-label-stack-menu-header',
  templateUrl: '../../edit-template-card-stack-menu/edit-template-card-stack-menu-header/edit-template-card-stack-menu-header.component.html',
  styleUrls: [
    '../../edit-template-card-stack-menu/edit-template-card-stack-menu-header/edit-template-card-stack-menu-header.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLabelStackMenuHeaderComponent extends EditTemplateCardStackMenuHeaderComponent {

  constructor(
    public override viewModel: EditTemplateLabelStackMenuViewModel
  ) {
    super(viewModel);
  }

}
