import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';
import { Observable } from 'rxjs';
import { Product } from '../../../models/product/dto/product';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../models/company/dto/company-configuration';
import { map } from 'rxjs/operators';
import { LabelUtils } from '../../../modules/product-labels/utils/label-utils';
import { DisplaySaleLabelInterface } from '../../../modules/product-labels/label/display-sale-label-interface';

@Pipe({
  name: 'variantSaleLabelInterface'
})
export class VariantSaleLabelInterfacePipe implements PipeTransform {

  transform(
    variant: Variant,
    labelInterfaceData$: Observable<[Product, LocationConfiguration, CompanyConfiguration]>
  ): Observable<DisplaySaleLabelInterface> {
    return labelInterfaceData$?.pipe(
      map(([_, locationConfig, companyConfig]) => {
        return LabelUtils.getDisplaySaleLabelInterfaceForVariantInProductTable(variant, locationConfig, companyConfig);
      })
    );
  }

}
