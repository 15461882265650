import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseReactiveFormComponent } from '@mobilefirstdev/reactive-form';
import { FeaturedProductFormData } from '../../../../../../models/menu/shared/featured-product-form-data';
import { Variant } from '../../../../../../models/product/dto/variant';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { FeaturedProductFormViewModel } from './featured-product-form-view-model';

@Component({
  selector: 'app-featured-product-form',
  templateUrl: './featured-product-form.component.html',
  styleUrls: ['./featured-product-form.component.scss'],
  providers: [FeaturedProductFormViewModel]
})
export class FeaturedProductFormComponent extends BaseReactiveFormComponent implements OnInit, OnChanges {

  @Input() override bindTo: FeaturedProductFormData;
  @Input() menu: Menu;
  @Input() variant: Variant;

  public scopedParsePriceOverride = this.parsePriceOverride.bind(this);
  public scopedParseDescOverride = this.parseDescOverride.bind(this);

  constructor(
    public viewModel: FeaturedProductFormViewModel
  ) {
    super();
  }

  ngOnInit() {
    this.viewModel.setMenu(this.menu);
    this.viewModel.setVariant(this.variant);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) this.viewModel.setMenu(this.menu);
    if (changes.variant) this.viewModel.setVariant(this.variant);
  }

  public parsePriceOverride(value: string): number {
    const priceOverrides = this.menu?.variantFeature?.priceOverrideMap;
    if (!!priceOverrides && !!this.variant) {
      if (!value) {
        priceOverrides.delete(this.variant.id);
      } else {
        priceOverrides.set(this.variant.id, parseFloat(value));
      }
    }
    return !!value ? parseFloat(value) : null;
  }

  public parseDescOverride(value: string): string {
    const descOverrides = this.menu?.variantFeature?.descriptionOverrideMap || new Map<string, string>();
    if (!!this.variant) {
      if (!value) {
        descOverrides.delete(this.variant.id);
      } else {
        descOverrides.set(this.variant.id, value);
      }
    }
    return value;
  }

}
