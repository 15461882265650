import { Selectable } from '@mobilefirstdev/reactive-form/lib/components/reactive-form-item/reactive-form-drop-down/selectable';

export class SegmentedControlOption implements Selectable {

  public title: string;
  public value: any;
  public selected: boolean = false;
  public disabled: boolean = false;
  public tooltipText: string;

  constructor(title?: string, value?: any) {
    this.title = title;
    this.value = value;
  }

  getSelectionTitle(): string {
    return this.title;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }

  getSelectionValue(): any {
    return this.value;
  }

}
