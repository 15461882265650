import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProductPickerViewModel } from '../../../viewModels/product-picker-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Section } from '../../../../../models/menu/dto/section';
import { ProductPickerModalOptions } from '../../../../../models/product/shared/product-picker-modal-options';
import { BehaviorSubject, combineLatest, fromEvent, Observable, throwError } from 'rxjs';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { Menu } from '../../../../../models/menu/dto/menu';
import { DatatableProductFilter } from '../../../../../models/product/table/datatable-product-filter';
import { distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { ProductPickerDataTableComponent } from '../../../../tables/product-picker-data-table/product-picker-data-table.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { OverrideProductGroup } from '../../../../../models/product/dto/override-product-group';
import { exists } from '../../../../../functions/exists';

@Component({
  selector: 'app-product-picker-modal',
  templateUrl: './product-picker-modal.component.html',
  styleUrls: ['./product-picker-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductPickerModalComponent extends BaseModalComponent implements AfterViewInit, OnDestroy {

  constructor(
    public viewModel: ProductPickerViewModel,
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastService: ToastService,
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) {
    super(activeModal);
  }

  @ViewChild('header') header: ElementRef<HTMLDivElement>;
  @ViewChild('modalBody') modalBody: ElementRef<HTMLDivElement>;
  @ViewChild('bottomButtons') bottomButtons: ElementRef<HTMLDivElement>;
  @ViewChild('tableContainer') tableContainer: ElementRef<HTMLDivElement>;
  @ViewChild('productPickerDataTableComponent') productPickerDataTableComponent: ProductPickerDataTableComponent;

  private dialogRO: ResizeObserver;
  private tableContainerRO: ResizeObserver;
  private headerRO: ResizeObserver;
  private datatableRO: ResizeObserver;

  private _loadingVariants = new BehaviorSubject<boolean>(true);
  public loadingVariants$ = this._loadingVariants.pipe(distinctUntilChanged());

  private _headerHeight = new BehaviorSubject<number>(0);
  public headerHeight$ = this._headerHeight as Observable<number>;

  private _modalBodyHeight = new BehaviorSubject<number>(0);
  public modalBodyHeight$ = this._modalBodyHeight.pipe(distinctUntilChanged());

  private _tableContainerHeight = new BehaviorSubject<number>(null);
  public tableContainerHeight$ = this._tableContainerHeight.pipe(
    filter(height => height > 0),
    distinctUntilChanged()
  );

  private _datatableLeftOffset = new BehaviorSubject<number>(0);
  public datatableLeftOffset$ = this._datatableLeftOffset.pipe(distinctUntilChanged());

  public _datatableRightOffset = new BehaviorSubject<number>(0);
  public datatableRightOffset$ = this._datatableRightOffset.pipe(distinctUntilChanged());

  private _bottomButtonPosition = new BehaviorSubject<string>('absolute');
  public bottomButtonPosition$ = this._bottomButtonPosition.pipe(
    tap(position => setTimeout(_ => { this.calculatePaginationBottomOffset(); }, 500)),
    distinctUntilChanged()
  );

  public options: ProductPickerModalOptions = new ProductPickerModalOptions();
  public continueOperation: (items: string[]) => Observable<any>;
  public subtitle: [string, string];
  public title: string = 'Add Product(s)';

  override cancel() {
    this.activeModal.close(Object.assign(new DatatableProductFilter(), this.viewModel.datatableFilter));
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.viewModel.productSelectionOnly$.once(productSelectionOnly => {
      const ids = this.viewModel.datatableOptions.preselectedIds;
      productSelectionOnly
        ? this.productPickerDataTableComponent?.setPreviouslyAddedProductIds(ids)
        : this.productPickerDataTableComponent?.setPreviouslyAddedVariantIds(ids);
    });
    const hideOutOfStockProducts = this.viewModel.datatableFilter.hideOutOfStockProducts;
    this.productPickerDataTableComponent?.setHideOutOfStockProducts(hideOutOfStockProducts);
  }

  override setupBindings() {
    this.observeHeader();
    this.observeDialog();
    this.observeDatatable();
    this.observeTableContainer();
    this.placePaginationInBottom();
  }

  private observeDialog(): void {
    this.dialogRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._modalBodyHeight.next(entry.contentRect.height);
      }
    });
    const dialogWindow = document.getElementsByClassName('product-picker-modal').item(0);
    this.dialogRO.observe(dialogWindow.getElementsByClassName('modal-dialog').item(0));
  }

  private observeHeader(): void {
    this.headerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._headerHeight.next(entry.contentRect.bottom);
      }
    });
    this.headerRO.observe(this.header.nativeElement);
  }

  private observeDatatable(): void {
    this.datatableRO = new ResizeObserver((entries) => {
      const table = document.getElementsByClassName('product-picker-datatable-container')?.item(0);
      this._datatableLeftOffset.next(table.getBoundingClientRect()?.left || 0);
      this._datatableRightOffset.next(table.getBoundingClientRect()?.right || 0);
    });
    this.datatableRO.observe(document.getElementsByClassName('product-picker-datatable-container')?.item(0));
  }

  private observeTableContainer(): void {
    this.tableContainerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._tableContainerHeight.next(entry.contentRect.height);
      }
    });
    this.tableContainerRO.observe(this.tableContainer.nativeElement);
    fromEvent(window, 'resize').subscribeWhileAlive({
      owner: this,
      next: () => {
        this._tableContainerHeight.next(this.tableContainer.nativeElement?.getBoundingClientRect()?.height);
      }
    });
    this.tableContainerHeight$.subscribeWhileAlive({
      owner: this,
      next: height => this.calculateBottomButtonContainerPosition(height)
    });
  }

  private placePaginationInBottom(): void {
    combineLatest([
      this.datatableLeftOffset$,
      this.datatableRightOffset$,
      this.loadingVariants$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([left, right, loading]) => {
        // second item, not the blueprint
        const dialogWindow = document.getElementsByClassName('product-picker-modal').item(0);
        const results = dialogWindow.getElementsByClassName('pagination-container')?.item(1);
        // second item, not the blueprint
        const pages = dialogWindow.getElementsByClassName('pages')?.item(1);
        const neutralButtonRect = dialogWindow
          ?.getElementsByClassName('add-product-button')
          ?.item(0)
          ?.getBoundingClientRect();
        const pagesHeight = neutralButtonRect.bottom - neutralButtonRect.top;
        // Results Indicator
        this.renderer.setStyle(results, 'position', 'fixed');
        this.renderer.setStyle(results, 'justify-content', 'center');
        this.renderer.setStyle(results, 'margin-bottom', '0');
        this.renderer.setStyle(results, 'z-index', '20');
        this.renderer.setStyle(results, 'height', `${pagesHeight}px`);
        this.renderer.setStyle(results, 'left', `${left}px`);
        this.renderer.setStyle(results, 'visibility', loading ? 'hidden' : null);
        // Pagination
        this.renderer.setStyle(pages, 'position', 'fixed');
        this.renderer.setStyle(pages, 'justify-content', 'center');
        this.renderer.setStyle(pages, 'margin-bottom', '0');
        this.renderer.setStyle(pages, 'z-index', '20');
        this.renderer.setStyle(pages, 'height', `${pagesHeight}px`);
        this.renderer.setStyle(pages, 'padding', '0');
        this.renderer.setStyle(pages, 'left', `50%`);
        this.renderer.setStyle(pages, 'right', `50%`);
        this.renderer.setStyle(pages, 'visibility', loading ? 'hidden' : null);
      }
    });
    this.modalBodyHeight$.subscribeWhileAlive({
      owner: this,
      next: () => this.calculatePaginationBottomOffset()
    });
  }

  private calculatePaginationBottomOffset(): void {
    const dialogWindow = document.getElementsByClassName('product-picker-modal').item(0);
    const dialogRect = dialogWindow.getElementsByClassName('modal-dialog')?.item(0)?.getBoundingClientRect();
    const footerStyle = window.getComputedStyle(dialogWindow.getElementsByClassName('product-picker-footer')?.item(0));
    const bottomOffset = window.innerHeight - dialogRect.bottom + Number.parseFloat(footerStyle.paddingBottom);
    // second item, not the blueprint
    const results = dialogWindow.getElementsByClassName('pagination-container')?.item(1);
    this.renderer.setStyle(results, 'bottom', `${bottomOffset}px`);
    // second item, not the blueprint
    const pages = dialogWindow.getElementsByClassName('pages')?.item(1);
    this.renderer.setStyle(pages, 'bottom', `${bottomOffset}px`);
  }

  private calculateBottomButtonContainerPosition(contentHeight: number): void {
    const dialogWindow = document.getElementsByClassName('product-picker-modal').item(0);
    const dialogRect = dialogWindow.getElementsByClassName('modal-dialog')?.item(0)?.getBoundingClientRect();
    const headerRect = dialogWindow.getElementsByClassName('sticky-header-container')?.item(0)?.getBoundingClientRect();
    const footerRect = dialogWindow.getElementsByClassName('product-picker-footer')?.item(0)?.getBoundingClientRect();
    const heightUntilScrollableContent = (dialogRect.height - headerRect.height) - footerRect.height;
    const position = contentHeight > heightUntilScrollableContent ? 'sticky' : 'absolute';
    this._bottomButtonPosition.next(position);
  }

  addProducts() {
    const lm = this.options.loadingMess;
    if (!this.viewModel.loadingOpts.containsRequest(lm)) {
      this.viewModel.loadingOpts.addRequest(lm);
      this.productPickerDataTableComponent.idsToBeAdded$.pipe(
        take(1),
        switchMap(selectedIds => this.continueOperation(selectedIds)),
        take(1)
      ).subscribe((success) => {
        if (success) {
          this.viewModel.loadingOpts.removeRequest(lm);
          if (exists(this.options.successTitle)) {
            this.toastService.publishSuccessMessage(this.options.successMess, this.options.successTitle);
          }
          this.activeModal.close();
        } else {
          this.viewModel.loadingOpts.removeRequest(lm);
          if (exists(this.options.failureTitle)) {
            this.toastService.publishErrorMessage(this.options.failureMess, this.options.failureTitle);
          }
        }
      }, (error: BsError) => {
        this.viewModel.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  setupModalForSection(s: Section) {
    this.subtitle = [`To Section:`, `${s.title}`];
    this.viewModel.datatableOptions.preselectedIds = s.enabledVariantIds;
  }

  setupModalForSpotlightMenu(menu: Menu, s: Section) {
    this.subtitle = [`To Menu:`, `${menu.name}`];
    this.viewModel.datatableOptions.preselectedIds = s.enabledVariantIds;
  }

  setupModalForFeaturedProductMenu(menu: Menu) {
    this.subtitle = [`Add Featured Products to`, `${menu?.name}`];
    this.viewModel.datatableOptions.preselectedIds = menu?.variantFeature?.variantIds;
  }

  setupModalForIgnoredVariants(preselectedVariantIds: string[]) {
    this.title = 'Select Ignored Variants';
    this.subtitle = [
      `Select variants that you want smart filters to ignore across all menus at this location.`,
      null
    ];
    if (preselectedVariantIds) {
      this.viewModel.datatableOptions.preselectedIds = preselectedVariantIds;
    }
  }

  setupModalForHideOutOfStockProducts(hideOutOfStockProducts: boolean) {
    this.viewModel.datatableFilter.hideOutOfStockProducts = hideOutOfStockProducts;
  }

  setupModalForProductGrouping(productGrouping: OverrideProductGroup) {
    this.viewModel.connectToProductSelectionOnly(true);
    this.viewModel.connectToFlattenAndThenRemoveProductGroupings(true);
    this.viewModel.connectToHideLabelAndBadgeColumn(true);
    this.viewModel.connectToShowCurrentOverrideGroupColumn(true);
    this.title = `Add Product(s)`;
    this.subtitle = null;
    this.viewModel.datatableOptions.preselectedIds = productGrouping?.productIds;
  }

  loadingVariants(loadingVariants: boolean): void {
    this._loadingVariants.next(loadingVariants);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.productPickerDataTableComponent?.clearSelections();
    this.productPickerDataTableComponent?.clearPreviouslyAddedIds();
    this.dialogRO?.disconnect();
    this.tableContainerRO?.disconnect();
    this.datatableRO?.disconnect();
    this.headerRO?.disconnect();
  }

}
