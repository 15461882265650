<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <div class="bs-headline" style="line-height: 1">{{ headerText }}</div>
    <app-breadcrumbs [breadcrumbs]="crumbs"></app-breadcrumbs>
  </div>
  <div>
    <lib-button-primary
      *ngIf="collection?.status === ClientTypes.TemplateStatus.Draft"
      [disabled]="disablePublish"
      [disabledTooltip]="publishButtonDisabledText || (formErrorMessages | getFormErrorDisabledTooltip)"
      (buttonClicked)="publishCollection.emit()">
      Publish Template Collection
    </lib-button-primary>
  </div>
</div>

<div class="edit-page-header justify-content-between" [style.margin-top.rem]="1.5">
  <div class="edit-page-header-name-container align-items-start" fxFlex>
    <div class="edit-page-name">
      {{ collection?.name }}
    </div>
    <div class="d-flex">
      <app-template-status [templateStatus]="collection?.status"></app-template-status>
    </div>
    <app-header-subtext>
      {{ headerSubtext }}
    </app-header-subtext>
  </div>
  <app-edit-display-actions
    [disableSave]="disableSave"
    [formPristine]="formPristine"
    [formDisableSubmit]="formDisableSubmit"
    [formErrorMessages]="formErrorMessages"
    [formHasErrors]="formHasErrors"
    [lastSavedTimeText]="lastSavedTimeText"
    [autoSaveLoadingOpts]="autoSaveLoadingOpts"
    (showLiveView)="showLiveView.emit()"
    (submitForms)="submitForms.emit()">
  </app-edit-display-actions>
</div>
