import { Variant } from './variant';
import { VariantFeature } from './variant-feature';
import { Deserializable } from '../../protocols/deserializable';

export class HydratedVariantFeature extends VariantFeature implements Deserializable {

  public variants: Variant[];

  public override onDeserialize() {
    super.onDeserialize();
    this.variants = window?.injector?.Deserialize?.arrayOf(Variant, this.variants);
  }

  isFeaturedVariantInStock(id: string): boolean {
    return this.variants?.find(v => v.id === id)?.inStock() ?? false;
  }

  removeVariantById(id: string) {
    const i = this.variants?.findIndex(v => v.id === id);
    if (i > -1) {
      this.variants?.splice(i, 1);
    }
  }

}
