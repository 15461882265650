import { Injectable, Injector } from '@angular/core';
import { EditTemplateMenuViewModel } from '../edit-template-menu-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { EditProductMenuViewModel } from '../../../../menu/viewModels/edit-product-menu-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { map } from 'rxjs/operators';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';

@Injectable()
export class EditTemplateProductMenuViewModel extends EditTemplateMenuViewModel {

  /*
   * Need editProductMenuViewModel injected here so that it's scoped to this template.
   * This allows child components that require editProductMenuViewModel to share the same instance.
   */
  constructor(
    protected editProductMenuViewModel: EditProductMenuViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editProductMenuViewModel, templateDomainModel, locationDomainModel, ngbModal, injector);
  }

  showLiveView = this.editProductMenuViewModel.showLiveView;

  public headerText$ = this.activeTemplate$.pipe(
    map(template => {
      let menuType: string;
      switch (template?.type) {
        case MenuType.PrintMenu:
          menuType = 'Print Menu';
          break;
        case MenuType.PrintReportMenu:
          menuType = 'Report';
          break;
        default:
          menuType = 'Product Menu';
      }
      return `Edit Template - ${menuType}`;
    })
  );

}
