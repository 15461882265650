import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Selectable } from '../../../../models/protocols/selectable';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnInit {

  @Input() labelText;
  @Input() disabled: boolean = false;
  @Input() hint: Selectable;
  @Input() dropdowns: Selectable[] = [];
  @Input() currentSelection: Selectable;

  @Output() selection: EventEmitter<Selectable> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    if (!this.currentSelection && this.hint) {
      this.currentSelection = this.hint;
    }
  }

  changed() {
    this.selection.emit(this.currentSelection);
  }

}
