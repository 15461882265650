import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SmartFilterAppliedOnPillsViewModel } from './smart-filter-applied-on-pills-view-model';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { Label } from '../../../../models/shared/label';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { Menu } from '../../../../models/menu/dto/menu';

@Component({
  selector: 'app-smart-filter-applied-on-pills',
  templateUrl: './smart-filter-applied-on-pills.component.html',
  styleUrls: ['./smart-filter-applied-on-pills.component.scss'],
  providers: [SmartFilterAppliedOnPillsViewModel]
})
export class SmartFilterAppliedOnPillsComponent implements OnChanges {

  @Input() smartFilter: HydratedSmartFilter;
  @Input() titleText: string = 'Applied On:';
  @Input() isClickable: boolean;

  @Output() clicked = new EventEmitter<Menu | Label | HydratedVariantBadge>(true);

  constructor(
    public viewModel: SmartFilterAppliedOnPillsViewModel
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.smartFilter) this.viewModel.connectToSmartFilter(this.smartFilter);
  }

}
