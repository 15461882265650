import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuType } from '../models/utils/dto/menu-type-definition';
import { Menu } from '../models/menu/dto/menu';
import { NewMenuModalComponent } from '../views/menu/components/modals/new-menu-modal/new-menu-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalCreateMenu {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    productMenuType: MenuType,
    onClose: (menu: Menu) => void
  ): void {
    const modalRef = ngbModal.open(
      NewMenuModalComponent,
      ModalUtils.newMenuModalOptions(injector)
    );
    const compInst = modalRef.componentInstance as NewMenuModalComponent;
    compInst.viewModel.setMenuType(productMenuType);
    modalRef.result.then(menu => onClose?.(menu)).catch(() => {});
  }

}
