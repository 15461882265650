import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompactMenu } from '../../../../../../../models/menu/dto/compact-menu';
import { BaseComponent } from '../../../../../../../models/base/base-component';

@Component({
  selector: 'app-display-menu-section-options',
  templateUrl: './display-menu-section-options.component.html',
  styleUrls: ['./display-menu-section-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayMenuSectionOptionsComponent extends BaseComponent {

  @Input() menu: CompactMenu;
  @Input() productOverflowTitle: string;
  @Input() mergeKey: string;
  @Input() bindTo: any;
  @Input() loopDuration: number;
  @Input() nMenus: number;
  @Input() menuIsPartOfCollection = false;
  @Output() openInformationModal = new EventEmitter();

}
