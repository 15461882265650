import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllProductsViewModel } from '../product/viewModels/all-products-view-model';
import { NgbPopover, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { ReactiveTableModule, ResizableModule } from '@mobilefirstdev/reactive-table';
import { SharedModule } from '../shared/shared.module';
import { TableLoadingComponent } from './generic-components/table-loading/table-loading.component';
import { TableAscDescSortComponent } from './generic-components/table-asc-desc-sort/table-asc-desc-sort.component';
import { ProductsTableActionButtonComponent } from './generic-components/all-products-table-action-button/products-table-action-button.component';
import { ProductsTableComponent } from './all-products-data-table/products-table/products-table.component';
import { AllProductsTableItemComponent } from './all-products-data-table/products-table/all-products-table-item/all-products-table-item.component';
import { AllProductsDataTableComponent } from './all-products-data-table/all-products-data-table.component';
import { AllProductsTableEmptyStateComponent } from './all-products-data-table/products-table/all-products-table-empty-state/all-products-table-empty-state.component';
import { AllProductsTableHeaderComponent } from './all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import { TablePropertyChangedComponent } from './generic-components/table-property-changed/table-property-changed.component';
import { DoubleDashIfEmptyPipe } from './pipes/double-dash-if-empty.pipe';
import { ProductPickerDataTableComponent } from './product-picker-data-table/product-picker-data-table.component';
import { ProductPickerTableItemComponent } from './product-picker-data-table/products-table/product-picker-table-item/product-picker-table-item.component';
import { ProductPickerFilterFormComponent } from './product-picker-data-table/product-picker-filter-form/product-picker-filter-form.component';
import { ProductPickerTableEmptyStateComponent } from './product-picker-data-table/products-table/product-picker-table-empty-state/product-picker-table-empty-state.component';
import { ProductPickerTableHeaderComponent } from './product-picker-data-table/products-table/product-picker-table-header/product-picker-table-header.component';
import { ProductPickerTableComponent } from './product-picker-data-table/products-table/product-picker-table.component';
import { RemoveDashesPipe } from './pipes/remove-dashes.pipe';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { AllProductsFilterFormComponent } from './all-products-data-table/all-products-filter-form/all-products-filter-form.component';
import { ProductLabelsModule } from '../../modules/product-labels/product-labels.module';
import { VariantLabelInterfacePipe } from './pipes/variant-label-interface.pipe';
import { VariantSaleLabelInterfacePipe } from './pipes/variant-sale-label-interface.pipe';
import { PresentProductQuantityInStockToUserPipe } from './pipes/present-product-quantity-in-stock-to-user.pipe';
import { PresentVariantQuantityInStockToUserPipe } from './pipes/present-variant-quantity-in-stock-to-user.pipe';
import { PresentClassificationToUserPipe } from './pipes/present-classification-to-user.pipe';
import { VariantBadgesPipe } from './pipes/variant-badges.pipe';
import { TableBadgesComponent } from './components/table-badges/table-badges.component';
import { ProductGroupingTableComponent } from './product-grouping-table/product-grouping-table.component';
import { ProductGroupingTableHeaderComponent } from './product-grouping-table/product-grouping-table-header/product-grouping-table-header.component';
import { ProductGroupingTableItemComponent } from './product-grouping-table/product-grouping-table-item/product-grouping-table-item.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RxPush } from '@rx-angular/template/push';
import { ProductGroupingTableEmptyStateComponent } from './product-grouping-table/product-grouping-table-empty-state/product-grouping-table-empty-state.component';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { GetCannabinoidWithUnitsPipe } from './pipes/get-cannabinoid-with-units.pipe';
import { GetMinCannabinoidWithUnitsPipe } from './pipes/get-min-cannabinoid-with-units.pipe';
import { GetMaxCannabinoidWithUnitsPipe } from './pipes/get-max-cannabinoid-with-units.pipe';
import { GetTerpeneWithUnitsPipe } from './pipes/get-terpene-with-units.pipe';
import { GetMinTerpeneWithUnitsPipe } from './pipes/get-min-terpene-with-units.pipe';
import { GetMaxTerpeneWithUnitsPipe } from './pipes/get-max-terpene-with-units.pipe';

@NgModule({
  declarations: [
    AllProductsDataTableComponent,
    ProductsTableComponent,
    TableLoadingComponent,
    ProductsTableActionButtonComponent,
    AllProductsTableHeaderComponent,
    AllProductsTableEmptyStateComponent,
    TableAscDescSortComponent,
    AllProductsTableItemComponent,
    TablePropertyChangedComponent,
    DoubleDashIfEmptyPipe,
    ProductPickerDataTableComponent,
    ProductPickerTableItemComponent,
    ProductPickerFilterFormComponent,
    ProductPickerTableEmptyStateComponent,
    ProductPickerTableHeaderComponent,
    ProductPickerTableComponent,
    RemoveDashesPipe,
    AllProductsFilterFormComponent,
    VariantLabelInterfacePipe,
    VariantSaleLabelInterfacePipe,
    PresentProductQuantityInStockToUserPipe,
    PresentVariantQuantityInStockToUserPipe,
    PresentClassificationToUserPipe,
    VariantBadgesPipe,
    TableBadgesComponent,
    ProductGroupingTableComponent,
    ProductGroupingTableHeaderComponent,
    ProductGroupingTableItemComponent,
    ProductGroupingTableEmptyStateComponent,
    GetCannabinoidWithUnitsPipe,
    GetMinCannabinoidWithUnitsPipe,
    GetMaxCannabinoidWithUnitsPipe,
    GetTerpeneWithUnitsPipe,
    GetMinTerpeneWithUnitsPipe,
    GetMaxTerpeneWithUnitsPipe
  ],
  exports: [
    AllProductsDataTableComponent,
    ProductPickerDataTableComponent,
    TableLoadingComponent,
    DoubleDashIfEmptyPipe,
    ProductsTableActionButtonComponent,
    TableAscDescSortComponent,
    TablePropertyChangedComponent,
    VariantLabelInterfacePipe,
    PresentVariantQuantityInStockToUserPipe,
    ProductGroupingTableComponent
  ],
  providers: [
    AllProductsViewModel
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    ReactiveFormModule,
    ReactiveTableModule,
    SharedModule,
    FlexLayoutModule,
    ResizableModule,
    NgxPopperjsModule,
    ProductLabelsModule,
    NgbPopover,
    RxPush,
    ReactiveButtonsModule
  ]
})
export class TablesModule { }
