import { Deserializable } from '../../protocols/deserializable';
import { MapUtils } from '../../../utils/map.utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class VariantFeature implements Deserializable, UniquelyIdentifiable {

  public title: string;
  public variantIds: string[];
  public descriptionOverrideMap: Map<string, string>;
  public priceOverrideMap: Map<string, number>;
  public assetNameMap: Map<string, string>;
  public colorMap: Map<string, string>; // variantId, color

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.variantIds = Array.from(this.variantIds || []);
    if (!(this.descriptionOverrideMap instanceof Map)) {
      this.descriptionOverrideMap = Deserialize?.genericMap(this.descriptionOverrideMap);
    } else {
      this.descriptionOverrideMap = new Map<string, string>(this.descriptionOverrideMap);
    }
    if (!(this.priceOverrideMap instanceof Map)) {
      this.priceOverrideMap = Deserialize?.genericMap(this.priceOverrideMap);
    } else {
      this.priceOverrideMap = new Map<string, number>(this.priceOverrideMap);
    }
    if (!(this.assetNameMap instanceof Map)) {
      this.assetNameMap = Deserialize?.genericMap(this.assetNameMap);
    } else {
      this.assetNameMap = new Map<string, string>(this.assetNameMap);
    }
    if (!(this.colorMap instanceof Map)) {
      this.colorMap = Deserialize?.genericMap(this.colorMap);
    }
  }

  public removeId(id: string) {
    const i = this.variantIds.findIndex(variantId => variantId.toUpperCase() === id.toUpperCase());
    if (i > -1) this.variantIds.splice(i, 1);
    this.priceOverrideMap?.delete(id);
    this.descriptionOverrideMap?.delete(id);
    this.assetNameMap?.delete(id);
  }

  public hasChanges(v: VariantFeature): boolean {
    return !v
        || this.title !== v.title
        || !this.variantIds.equals(v.variantIds)
        || !MapUtils.equals(this.descriptionOverrideMap, v.descriptionOverrideMap)
        || !MapUtils.equals(this.assetNameMap, v.assetNameMap)
        || !MapUtils.equals(this.priceOverrideMap, v.priceOverrideMap);
  }

  getVariantColor(vId: string): string {
    return this.colorMap?.get(vId);
  }

  public getKeyFromAssetName(n: string): string {
    let assetNameKey: string;
    this.assetNameMap.forEach((name, key) => {
      if (name === n) {
        assetNameKey = key;
      }
    });
    return assetNameKey;
  }

  getUniqueIdentifier(): string {
    const titleId = this.title ?? '';
    const variantId = this.variantIds?.sort()?.join(',') ?? '';
    const descIds: string[] = [];
    this.descriptionOverrideMap?.forEach((val, key) => descIds.push(`${key}-${val}`));
    const descId = descIds.sort().join(',') ?? '';
    const assetIds: string[] = [];
    this.assetNameMap?.forEach((val, key) => descIds.push(`${key}-${val}`));
    const assetId = assetIds.sort().join(',') ?? '';
    const priceIds: string[] = [];
    this.priceOverrideMap?.forEach((val, key) => priceIds.push(`${key}-${val}`));
    const priceId = priceIds.sort().join(',') ?? '';
    const assetNames: string[] = [];
    this.assetNameMap?.forEach((val, key) => assetNames.push(`${key}-${val}`));
    const assetName = assetNames.sort().join(',') ?? '';
    return `${titleId}-${variantId}-${descId}-${assetId}-${priceId}-${assetName}`;
  }

}
