import { ChangeDetectionStrategy, Component, Input, OnDestroy, Renderer2 } from '@angular/core';
import { StackManualBulkPrintJobViewModel } from './stack-manual-bulk-print-job-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { StackPrintType } from '../../../../../../models/automation/enum/card-stack-print-type.enum';

@Component({
  selector: 'app-stack-manual-bulk-print-job',
  templateUrl: './stack-manual-bulk-print-job.component.html',
  styleUrls: ['./stack-manual-bulk-print-job.component.scss'],
  providers: [StackManualBulkPrintJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackManualBulkPrintJobComponent extends BaseComponent implements OnDestroy {

  constructor(
    public viewModel: StackManualBulkPrintJobViewModel,
    private renderer: Renderer2
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToStackPrintType') stackPrintType: StackPrintType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToMergeKey') mergeKey: string;
  @Input() @ViewModelConnector('connectToPlaceholder') placeholder: string;
  @Input() @ViewModelConnector('connectToSelectedStackMenuIds') selectedStackIds: string[];
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean;

  override setupViews(): void {
    if (!this.viewOnly) {
      this.renderer.setStyle(this.getModalDiv(), 'height', '90vh');
    }
  }

  private getModalDiv(): HTMLDivElement {
    return document?.getElementById('create-view-bulk-print-job') as HTMLDivElement;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    const modalDiv = this.getModalDiv();
    if (modalDiv) this.renderer.removeStyle(modalDiv, 'height');
  }

}
