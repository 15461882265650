import { TypeDefinition } from './type-definition';

export enum SyncType {
  FullProductInfo = 'SyncType_FullProductInfo',
  Product = 'SyncType_Product',
  ProductAudit = 'SyncType_ProductAudit',
  Inventory = 'SyncType_Inventory',
  LotInfo = 'SyncType_LotInfo',
  Pricing = 'SyncType_Pricing',
  DisplayNames = 'SyncType_DisplayNames',
  SmartFilters = 'SyncType_SmartFilters',
  SmartDisplayAttributes = 'SyncType_SmartDisplayAttributes',
  Promotions = 'SyncType_Promotion',
  Labels = 'SyncType_Labels',
  Location = 'SyncType_Location'
}

export class SyncTypeType extends TypeDefinition {

  override value: SyncType;

}
