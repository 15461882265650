<div class="label-stack-product-selection-item">
  <div class="flex-container bs-medium gap-8px">
    <span class="f12px">{{ variant | getVariantTitle }}</span>
    <span class="f11px variant-size">{{ variant | variantFormattedSizing : true }}</span>
  </div>
  <app-reactive-form-incrementor
    [dispersedKey]="dispersedKey"
    [disabled]="viewOnly"
    [inputName]="variant?.id"
    [initialValueIfNoBindingValue]="variantCountMap | getInitialValueFromMap : variant?.id"
    [zeroIsNull]="true"
    [minValue]="0"
    [maxValue]="100"
    (valueChanged)="updateVariantCountMap.emit($event)">
  </app-reactive-form-incrementor>
</div>
