import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../image/dto/asset';
import { DateUtils } from '../../../utils/date-utils';
import { Cachable } from '../../protocols/cachable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class MenuAssets implements Deserializable, Cachable, UniquelyIdentifiable {

  public menuId: string;
  public assets: Asset[];
  // Cache
  public cachedTime: number;

  constructor(id?: string, assets?: Asset[]) {
    this.menuId = id;
    this.assets = assets;
  }

  static buildCacheKey(id: string): string {
    return `MenuAssets-${id}`;
  }

  removeAsset(f: Asset) {
    const meIndex = this.assets.findIndex(find => find.id === f.id);
    if (meIndex > -1) {
      this.assets.splice(meIndex, 1);
    }
  }

  public onDeserialize() {
    this.assets = window?.injector?.Deserialize?.arrayOf(Asset, this.assets);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return MenuAssets.buildCacheKey(this.menuId);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  getUniqueIdentifier(): string {
    const assetsId = this.assets?.map(a => a?.getUniqueIdentifier()).sort().join(',') ?? '';
    return `${this.menuId}
      -${assetsId}`;
  }

}
