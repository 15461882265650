import { TypeDefinition } from './type-definition';
import { Infoable } from '../../protocols/infoable';

export enum PrintFooterLayout {
  None = 'None',
  AllPagesFixed = 'AllPagesFixed',
  AllPagesHugging = 'AllPagesHugging',
}

export class PrintFooterLayoutTypeDefinition extends TypeDefinition implements Infoable {

  override value: PrintFooterLayout;

  getSelectionInformationDescription(...args: any): string {
    switch (this.value) {
      case PrintFooterLayout.None:
        return 'No footer will be added to your print menu.';
      case PrintFooterLayout.AllPagesFixed:
        return 'The footer will be added to the bottom of every page.';
      case PrintFooterLayout.AllPagesHugging:
        return 'The footer will appear on every page and it will hug the bottom of the last section on the page.';
    }
  }

}
