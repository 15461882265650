import { CombinedLoadingOptsViewModel } from '../../../../shared/components/auto-save/combined-loading-opts-view-model';
import { EditTemplateMarketingMenuViewModel } from './edit-template-marketing-menu-view-model';
import { EditMarketingMenuViewModel } from '../../../../menu/viewModels/edit-marketing-menu-view-model';

export class EditTemplateMarketingMenuCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateMarketingMenuViewModel: EditTemplateMarketingMenuViewModel,
    protected editMarketingMenuViewModel: EditMarketingMenuViewModel,
  ) {
    super([editTemplateMarketingMenuViewModel, editMarketingMenuViewModel]);
  }

}
