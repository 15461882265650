import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableLoadingStateBluePrintComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-table-loading',
  templateUrl: './table-loading.component.html',
  styleUrls: ['./table-loading.component.scss'],
  providers: [
    {
      provide: ReactiveTableLoadingStateBluePrintComponent,
      useExisting: forwardRef(() => TableLoadingComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableLoadingComponent extends ReactiveTableLoadingStateBluePrintComponent {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
  ) {
    super(viewRef);
  }

  initializeFromBluePrint(bluePrint: ReactiveTableLoadingStateBluePrintComponent): void {
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableLoadingStateBluePrintComponent): void {
  }

}
