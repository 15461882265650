import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditSectionForm } from '../edit-section-form/edit-section-form';
import { EditProductSectionFormViewModel } from './edit-product-section-form-view-model';

@Component({
  selector: 'app-edit-product-section-form',
  templateUrl: './edit-product-section-form.component.html',
  styleUrls: ['./edit-product-section-form.component.scss'],
  providers: [EditProductSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProductSectionFormComponent extends EditSectionForm {

  constructor(public override viewModel: EditProductSectionFormViewModel) {
    super(viewModel);
  }

}
