import { ColumnOptionsFormViewModel } from '../column-options-form/column-options-form-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { Injectable } from '@angular/core';
import { ColumnOptionName, SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Injectable()
export class ColumnOptionsAssetViewModel extends ColumnOptionsFormViewModel {

  constructor(
    container: ColumnOptionsViewModel
  ) {
    super(container);
  }

  public override columnSectionKey = SectionColumnConfigKey.Asset;
  public override columnSectionName = ColumnOptionName.Asset;
  public override tooltip = 'Show product images in menu';

}
