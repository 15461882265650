import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateCollection } from '../../../models/template/dto/template-collection';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getCollectionNameFromId'
})
export class GetCollectionNameFromIdPipe implements PipeTransform {

  transform(targetId: string, allCollections$: Observable<TemplateCollection[]>): Observable<string> {
    return allCollections$.pipe(
      map((collections) => collections?.find((c) => c?.id === targetId)?.name)
    );
  }

}
