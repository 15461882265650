import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Pipe({
  name: 'debounce'
})
export class DebouncePipe implements PipeTransform {

  transform<T>(value$: Observable<T>, debounceTimeInMilliseconds: number): Observable<T> {
    let output$: Observable<T> | null = null;
    if (!!value$) output$ = value$.pipe(debounceTime(debounceTimeInMilliseconds));
    return output$;
  }

}
