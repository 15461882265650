import { Pipe, PipeTransform } from '@angular/core';
import { NavSubItem } from '../models/nav-sub-item';
import { IncompleteNavSubItem } from '../models/incomplete-nav-sub-item';

@Pipe({
  name: 'isIncompleteNavSubItem'
})
export class IsIncompleteNavSubItemPipe implements PipeTransform {

  transform(value: NavSubItem): boolean {
    return value instanceof IncompleteNavSubItem;
  }

}
