import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { SignInRequest } from '../models/account/requests/sign-in-request';
import { AccountAPI } from '../api/account-api';
import { catchError, concatMap, delay, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { ForgotPasswordRequest } from '../models/account/requests/forgot-password-request';
import { CodeDeliveryDetails } from '../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { SignInNewPasswordRequest } from '../models/account/requests/sign-in-new-password-request';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { CacheService } from '../services/cache-service';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { Session } from '../models/account/dto/session';
import { ConfirmCodeRequest } from '../models/account/requests/confirm-code-request';
import { ImageAPI } from '../api/image-api';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { BudsenseFile } from '../models/shared/budsense-file';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { StringUtils } from '../utils/string-utils';
import { ToastService } from '../services/toast-service';
import { SendCompanyReferral } from '../models/account/dto/send-company-referral';
import { NavigationService } from '../services/navigation.service';
import { RefreshSessionRequest } from '../models/account/requests/refresh-session-request';
import { SignOutRequest } from '../models/account/requests/sign-out-request';
import { exists } from '../functions/exists';
import { InventoryProvider } from '../models/utils/dto/inventory-provider-type';
import { Asset } from '../models/image/dto/asset';

@Injectable({ providedIn: 'root' })
export class UserDomainModel extends BaseDomainModel {

  constructor(
    private accountAPI: AccountAPI,
    private imageAPI: ImageAPI,
    private cacheService: CacheService,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
    super();
    this.setupBindings();
  }

  private _refreshingSessionUser = new BehaviorSubject<boolean>(false);
  public refreshingSessionUser$ = this._refreshingSessionUser as Observable<boolean>;
  connectToRefreshingSessionUser = (refreshing: boolean) => this._refreshingSessionUser.next(refreshing);

  public destroySession: Subject<boolean> = new Subject<boolean>();

  private _deadSession = new BehaviorSubject<boolean>(true);
  public deadSession$ = this._deadSession as Observable<boolean>;

  private _user = new BehaviorSubject<HydratedUser | null>(null);
  public user$ = this._user as Observable<HydratedUser | null>;
  public setUser(u: HydratedUser, rememberMe?: boolean): void {
    this.user$.once(user => {
      u.rememberMe = (rememberMe !== undefined ? rememberMe : user?.rememberMe) ?? false;
      if (u.rememberMe) {
        this.cacheService.cacheObject(DefaultCacheKey.SessionUser, u, true);
      }
      this.cacheService.cacheObject(DefaultCacheKey.SessionUser, u);
      this._user.next(u);
    });
  }

  public userSession$ = this.user$.pipe(map(user => user?.session));
  public validSession$ = this.userSession$.pipe(map(session => session?.validSession()), distinctUntilChanged());
  public userId$ = this.user$.pipe(map(user => user?.userId), distinctUntilChanged());
  public userEmail$ = this.user$.pipe(map(user => user?.email), distinctUntilChanged());
  public authToken$ = this.user$.pipe(map(user => user?.session?.accessToken), distinctUntilChanged());
  public refreshToken$ = this.user$.pipe(map(user => user?.session?.refreshToken), distinctUntilChanged());
  public challengeToken$ = this.user$.pipe(map(user => user?.session?.challenge?.authSession), distinctUntilChanged());
  public isCompanyAdmin$ = this.user$.pipe(map(u => u?.isCompanyAdmin), distinctUntilChanged());
  public readonly isTemplateAdmin$ = this.user$.pipe(map(u => u?.isTemplateAdmin ?? false), distinctUntilChanged());
  public userDefaultLocationId$ = this.user$.pipe(map(user => user?.defaultLocationId || null), distinctUntilChanged());
  public companyFeatures$ = this.user$.notNull().pipe(map(user => user?.companyFeatures), distinctUntilChanged());
  public canUsePrintCards$ = this.companyFeatures$.pipe(map(cf => cf?.supportsPrintCards), distinctUntilChanged());
  public canUseTemplates$ = this.companyFeatures$.pipe(map(cf => cf?.supportsTemplates), distinctUntilChanged());

  public isAuthenticated(forceRefresh: boolean = false): Observable<Session | null> {
    return combineLatest([
      this.user$,
      this.refreshingSessionUser$,
    ]).pipe(
      filter(([, refreshingUser]) => !refreshingUser),
      concatMap(([user, refreshingUser]) => {
        if (!user) {
          user = this.getCachedUser();
        }
        if (user?.session?.validSession() && !refreshingUser && !forceRefresh) {
          return of(user.session);
        } else {
          return this.getRefreshSessionReq(user).pipe(
            take(1),
            switchMap(req => {
              if (!req) {
                // No refreshSession req in cache
                return of(null);
              }
              this.connectToRefreshingSessionUser(true);
              return this.accountAPI.RefreshSession(req).pipe(
                tap(apiReturnedUser => {
                  this.setUser(apiReturnedUser,  user.rememberMe);
                }),
                delay(1000),
                switchMap(u => {
                  this.connectToRefreshingSessionUser(false);
                  return of(u.session);
                }),
                catchError(() => {
                  this.destroySession.next(true);
                  this.connectToRefreshingSessionUser(false);
                  this.toastService.publishErrorMessage(
                    'For security reasons, your token has expired. Please log in again.',
                    'You have been signed out.'
                  );
                  return this.signOut().pipe(
                    map(() => null)
                  );
                })
              );
            })
          );
        }
      }),
      take(1),
      takeUntil(this.destroySession)
    );
  }

  public signOut(): Observable<any> {
    return this.getSignOutReq().pipe(
      take(1),
      switchMap(req => this.accountAPI.SignOut(req)),
      map((r) => {
        // Clear the user session from the persistent cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.cacheService.clearAllCaches();
        this.destroySession.next(true);
        return r;
      }),
      catchError(err => {
        // If sign out request fails, we want to kill all caches any destroy session
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.cacheService.clearAllCaches();
        this.destroySession.next(true);
        return throwError(() => err);
      })
    );
  }

  // Auth Methods

  public signIn(req: SignInRequest): Observable<HydratedUser> {
    return this.accountAPI.SignIn(req).pipe(
      map((user) => {
        // Clear the existing user from the cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.setUser(user, req.rememberMe);
        return user;
      })
    );
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    return this.accountAPI.GetForgotPasswordCode(req.email).pipe(
      map((deliveryDetails) => {
        this.setDeliveryDetails(deliveryDetails);
        return deliveryDetails;
      })
    );
  }

  public resetPassword(req: ResetPasswordRequest): Observable<HydratedUser> {
    return this.accountAPI.ResetForgottenPassword(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public signInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedUser> {
    // Populate request from current session
    return combineLatest([this.userId$, this.challengeToken$]).pipe(
      take(1),
      switchMap(([userId, challengeToken]) => {
        req.userId = userId;
        req.session = challengeToken;
        return this.accountAPI.SignInNewPassword(req);
      }),
      tap((user) => this.setUser(user))
    );
  }

  private setupBindings() {
    this.destroySession.notNull().subscribeWhileAlive({
      owner: this,
      next: () => {
        this.connectToRefreshingSessionUser(false);
        this._deadSession.next(true);
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this._user.next(null);
        this.navigationService.signIn();
      }
    });
  }

  public changePassword(req: ChangePasswordRequest): Observable<HydratedUser> {
    return this.accountAPI.ChangePassword(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public updateUser(req: HydratedUser): Observable<HydratedUser> {
    return this.accountAPI.UpdateUser(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public getUser(): Observable<HydratedUser> {
    return this.accountAPI.GetUser();
  }

  public confirmEmail(req: ConfirmCodeRequest): Observable<HydratedUser> {
    return this.accountAPI.ConfirmCode(req).pipe(
      map((user) => {
        this.setUser(user, true);
        return user;
      })
    );
  }

  public resendEmailConfirmationCode(email: string): Observable<CodeDeliveryDetails> {
    return this.authToken$.pipe(
      take(1),
      switchMap((token) => this.accountAPI.ResendCode(email, token))
    );
  }

  public uploadProfilePicture(f: BudsenseFile): Observable<Asset> {
    return this.user$.pipe(
      take(1),
      switchMap((user) => {
        this.cacheService.removeCachedAsset(user?.profilePicture);
        const req = new GenerateUploadUrlRequest();
        req.fileName = StringUtils.normalizeCharacters(f.name);
        req.mediaClass = UploadFilePath.ProfilePicturePath;
        req.mediaType = f.getMediaType();
        req.metadata = new Map<string, string>();
        req.metadata.set('CompanyId', user?.companyId?.toString());
        req.metadata.set('UserId', user?.userId);
        return this.uploadProfilePictureHelper(req, f);
      })
    );
  }

  public deleteProfilePicture(): Observable<Session | never> {
    return this.user$.pipe(
      take(1),
      switchMap(user => {
        const existingProfilePicture = user?.profilePicture;
        if (!existingProfilePicture) {
          return EMPTY;
        }
        this.cacheService.removeCachedAsset(user?.profilePicture);
        return this.imageAPI.DeleteAsset(existingProfilePicture.id, existingProfilePicture.md5Hash).pipe(
          delay(2000),
          switchMap(() => this.isAuthenticated(true))
        );
      }),
    );
  }

  private uploadProfilePictureHelper(req: GenerateUploadUrlRequest, f: BudsenseFile): Observable<Asset> {
    return this.imageAPI.GenerateUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName).pipe(
          // provide delay based on file size
          delay(f.getUploadDelay()),
          switchMap((_) => {
            return this.pollForUploadedAssets(f, 10, 0);
          })
        );
      })
    );
  }

  private pollForUploadedAssets(file: BudsenseFile, numOfRetries: number, delayTime: number): Observable<Asset> {
    return this.getUser().pipe(
      delay(delayTime),
      switchMap(u => {
        if (u?.profilePicture?.fileName?.toLowerCase() === file.name.toLowerCase()) {
          numOfRetries = 0;
        }
        if (numOfRetries > 0) {
          return this.pollForUploadedAssets(file, numOfRetries - 1, 3500);
        } else {
          numOfRetries = 0;
          const profilePicture = u?.profilePicture;
          this.setUserProfilePicture(profilePicture);
          return of(profilePicture);
        }
      })
    );
  }

  public sendCompanyReferral(req: SendCompanyReferral): Observable<SendCompanyReferral> {
    return this.accountAPI.SendCompanyReferral(req);
  }

  public getRefreshSessionReq(user?: HydratedUser): Observable<RefreshSessionRequest | null> {
    return combineLatest([this.userId$, this.refreshToken$]).pipe(
      take(1),
      map(([userId, refreshToken]) => {
        if (user) {
          userId = user?.userId;
          refreshToken = user?.session?.refreshToken;
        }
        const req = new RefreshSessionRequest(userId, refreshToken);
        return (req?.refreshToken && req?.userId) ? req : null;
      })
    );
  }

  public getSignOutReq(): Observable<SignOutRequest | null> {
    return combineLatest([this.userId$, this.authToken$]).pipe(
      take(1),
      map(([userId, authToken]) => {
        const req = new SignOutRequest(userId, authToken);
        return (req?.accessToken && req?.userId) ? req : null;
      })
    );
  }

  public getCachedUser(): HydratedUser {
    const user = this.cacheService.getCachedObject(HydratedUser, DefaultCacheKey.SessionUser)
      ?? this.cacheService.getCachedObject(HydratedUser, DefaultCacheKey.SessionUser, true);
    this._deadSession.next(false);
    if (exists(user)) {
      this._user.next(user);
    }
    return user;
  }

  public setInventoryProvider(provider: InventoryProvider): void {
    this.user$.once(user => {
      const userCopy = window?.injector?.Deserialize?.instanceOf(HydratedUser, user);
      userCopy.inventoryProvider = provider;
      this.setUser(userCopy);
    });
  }

  public setUserProfilePicture(profilePicture: Asset): void {
    this.user$.once(user => {
      const userCopy = window?.injector?.Deserialize?.instanceOf(HydratedUser, user);
      userCopy.profilePicture = profilePicture;
      this.setUser(userCopy);
    });
  }

  private setDeliveryDetails(deliveryDetails: CodeDeliveryDetails): void {
    const spoofedUser = new HydratedUser();
    spoofedUser.codeDeliveryDetails = [deliveryDetails];
    this.setUser(spoofedUser);
  }

}
