<div class="mt-4">
  <lib-reactive-form-group [bindTo]="viewModel?.formColumnConfig$ | async" [mergeKey]="viewModel?.container?.mergeKey">
    <div class="my-3" *ngIf="viewModel?.showDisabledInGridModeMessage$ | async">
      <app-message-banner [message]="viewModel?.disabledInGridModeMessage$ | async"> </app-message-banner>
    </div>

    <lib-reactive-form-column-layout nColumns="2">
      <lib-reactive-form-drop-down
        #columnDefaultState
        [inputName]="viewModel?.columnSectionName + 'DefaultState'"
        [label]="'Default State'"
        [placeholder]="viewModel?.defaultStatePlaceholder$ | async"
        [bindingProperty]="'defaultState'"
        [dropdowns]="viewModel?.sectionDefaultStateOptions$ | async"
        [tooltip]="viewModel?.tooltip"
        [disabled]="viewModel?.defaultStateInputDisabled$ | async"
        [required]="viewModel?.dropdownRequired$ | async"
        [programmaticallyChangeValue]="(viewModel?.formColumnConfig$ | async)?.defaultState"
        (valueChanged)="changeToDefaultState($event)"></lib-reactive-form-drop-down>

      <div [hidden]="viewModel?.hideColumnName$ | async">
        <lib-reactive-form-text
          #columnName
          [inputName]="viewModel?.columnSectionName + 'ColumnName'"
          [label]="'Column Name'"
          [placeholder]="viewModel?.columnNamePlaceHolder$ | async"
          [bindingProperty]="'columnName'"
          [disabled]="viewModel?.inputDisabled$ | async"></lib-reactive-form-text>
      </div>

      <lib-reactive-form-drop-down
        #columnDataValue
        *ngIf="viewModel?.showColumnDataOptions$ | async"
        [inputName]="viewModel?.columnSectionName + 'DataValue'"
        [label]="viewModel?.columnDataOptionsLabel$ | async"
        [placeholder]="viewModel?.useValuePlaceholder$ | async"
        [bindingProperty]="'dataValue'"
        [dropdowns]="viewModel?.columnDataOptionsDropdowns$ | async"
        [disabled]="viewModel?.inputDisabled$ | async"
        [required]="viewModel?.dropdownRequired$ | async"
        [tooltipModalTitle]="viewModel?.columnDataOptionsToolTipModalTitle$ | async"
        [tooltipModalInfoItems]="viewModel?.columnDataOptionsToolTipModalItems$ | async"></lib-reactive-form-drop-down>

      <lib-reactive-form-number
        #columnWidth
        [inputName]="viewModel?.columnSectionName + 'ColumnWidth'"
        [label]="'Column Width'"
        [placeholder]="viewModel?.columnWidthPlaceholder$ | async"
        [bindingProperty]="'columnWidth'"
        [integersOnly]="true"
        [minValue]="viewModel?.minWidth$ | async"
        [maxValue]="viewModel?.maxWidth$ | async"
        [tooltip]="'Will be validated by the lower and upper bounds set on the theme config'"
        [disabled]="viewModel?.inputDisabled$ | async"
        [required]="viewModel?.widthRequired$ | async"
        (valueChanged)="changeToColumnWidth($event)"></lib-reactive-form-number>
    </lib-reactive-form-column-layout>

    <app-column-style-options-form
      [formBindTo]="viewModel?.formColumnConfig$ | async"
      [formMergeKey]="viewModel?.container?.mergeKey"
      [columnOptionName]="viewModel?.columnSectionName"
      [enabled]="!(viewModel?.inputDisabled$ | async)"
      [hideTextColor]="viewModel?.hideTextColor$ | async"
      [hideTextOptions]="viewModel?.hideTextOptions$ | async"
      [colorPalette]="viewModel?.colorPalette$ | async">
    </app-column-style-options-form>
  </lib-reactive-form-group>
</div>
