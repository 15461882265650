import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import FuzzySearch from 'fuzzy-search';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { LookAheadItem } from './look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { PopperUtils } from '../../../../utils/popper-utils';

@Injectable()
export class SearchWithLookAheadViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _searchText = new BehaviorSubject<string>(null);
  public readonly searchText$ = this._searchText as Observable<string>;
  private _searchProperties = new BehaviorSubject<string[]>([]);
  private _nLookAheadItems = new BehaviorSubject<number>(5);
  private _searchItems = new BehaviorSubject<LookAheadItem[]>([]);
  private _outputAllItemsWhenSearchIsEmpty = new BehaviorSubject<boolean>(false);
  public searchTextAndFuzzyHits$: Observable<[string, LookAheadItem[]]> = combineLatest([
    this._searchText,
    this._searchProperties,
    this._nLookAheadItems,
    this._searchItems,
    this._outputAllItemsWhenSearchIsEmpty
  ]).pipe(
    debounceTime(50),
    map(([searchText, searchProperties, returnNItems, items, outputAllItemsWhenSearchIsEmpty]) => {
      const hasSearchProperties = !!searchProperties && searchProperties?.length > 0;
      const hasSearchableItems = items?.length > 0;
      let matches = outputAllItemsWhenSearchIsEmpty ? items : [];
      if (!!searchText && hasSearchProperties && hasSearchableItems) {
        const searcher = new FuzzySearch(items, searchProperties, {caseSensitive: false});
        matches = searcher.search(searchText);
        return [searchText, matches.take<any>(returnNItems)] as [string, LookAheadItem[]];
      }
      return [searchText, matches] as [string, LookAheadItem[]];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly fuzzyHits$ = this.searchTextAndFuzzyHits$.pipe(
    map(([searchText, fuzzyHits]) => fuzzyHits)
  );

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['bottom', 'top'])];
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };

  public connectToSearchText(search: string) {
    this._searchText.next(search);
  }

  public connectToSearchProperties(properties: string[]) {
    this._searchProperties.next(properties);
  }

  public connectToNLookAheadItems(n: number) {
    this._nLookAheadItems.next(n);
  }

  public connectToSearchItems(items: LookAheadItem[]) {
    this._searchItems.next(items);
  }

  public connectToOutputAllItemsWhenSearchIsEmpty(outputAllItemsWhenSearchIsEmpty: boolean) {
    this._outputAllItemsWhenSearchIsEmpty.next(outputAllItemsWhenSearchIsEmpty);
  }

}
