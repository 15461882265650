<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip">
  <lib-reactive-form-row>
    <app-reactive-form-card-type
      [inputName]="'cardType'"
      [menuType]="menu?.hydratedTheme?.menuSubType"
      [isTemplatedMenu]="isTemplatedMenu$ | async"
      (valueChanged)="cardTypeSelected($event)"
      [bindingProperty]="'metadata.cardType'">
    </app-reactive-form-card-type>
  </lib-reactive-form-row>
  <lib-reactive-form-column-layout [nColumns]="3">
    <lib-reactive-form-drop-down
      [inputName]="'cardCount'"
      [label]="'Number of Cards to Display'"
      [placeholder]="'Number of Cards to Display'"
      [inlineLabel]="false"
      [required]="true"
      [dropdowns]="cardCountOptions$ | async"
      [bindingProperty]="'metadata.cardCount'"
      [programmaticallyChangeValue]="cardsToDisplay$ | async"
      [disabled]="isTemplatedMenu$ | async"
      (valueChanged)="cardsToDisplaySelected($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-number
      [inputName]="'cardStartAt'"
      [label]="'Start At'"
      [placeholder]="'Start Card Count At'"
      [inlineLabel]="false"
      [required]="true"
      [minValue]="1"
      [maxValue]="99"
      [disabled]="(hideCardNumber$ | async) || (isTemplatedMenu$ | async)"
      [integersOnly]="true"
      [customValueParser]="parseNumberAsString"
      [bindingProperty]="'metadata.cardStartAt'"
      (valueChanged)="cardStartAtChanged.emit($event)">
    </lib-reactive-form-number>
    <lib-reactive-form-switch
      class="switch-padding"
      [inputName]="'hideCardNumber'"
      [label]="'Hide Card Number'"
      [labelOnRight]="true"
      [bindingProperty]="'metadata.hideCardNumber'"
      [selectedValue]="'true'"
      [notSelectedValue]="'false'"
      [disabled]="isTemplatedMenu$ | async"
      (valueChanged)="hideCardNumberToggled($event)">
    </lib-reactive-form-switch>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      [inputName]="'overflowState'"
      [label]="'Product Overflow'"
      [placeholder]="'How should overflow products be handled'"
      [inlineLabel]="false"
      [disabled]="isTemplatedMenu$ | async"
      [required]="true"
      [customTooltipModal]="true"
      [tooltipModalTitle]="'Product Overflow Information'"
      [tooltipModalInfoItems]="tooltipModalInfoItems$ | async"
      [dropdowns]="overflowStates$ | async"
      [bindingProperty]="'overflowState'"
      [programmaticallyChangeValue]="selectedOverflowTypeValue$ | async"
      (tooltipClicked)="openOverflowInfo()"
      (valueChanged)="overflowTypeSelected($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-number
      [inputName]="'carouselDuration'"
      [label]="'Carousel Duration'"
      [placeholder]="carouselDurationPlaceholder$ | async"
      [inlineLabel]="false"
      [required]="isCarouselCard$ | async"
      [minValue]="1"
      [maxValue]="600"
      [disabled]="!(isCarouselCard$ | async) || (isTemplatedMenu$ | async)"
      [integersOnly]="true"
      [tooltip]="'The duration that a product appears before transitioning to the next.'"
      [customValueParser]="parseNumberAsString"
      [programmaticallyChangeValue]="carouselDuration$ | async"
      [bindingProperty]="'metadata.carouselDuration'">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>

<app-message-banner *ngIf="(carouselInfoMessage$ | async)?.length > 0" [message]="carouselInfoMessage$ | async">
</app-message-banner>
