import { TypeDefinition } from './type-definition';

export enum MenuLabel {
  Featured = 'Featured',
  LowStock = 'LowStock',
  Restocked = 'Restocked',
  Sale = 'Sale',
  New = 'New'
}

export class MenuLabelType extends TypeDefinition {

  override value: MenuLabel;

}
