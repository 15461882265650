import { Component, Input } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-quick-action-card',
  templateUrl: './quick-action-card.component.html',
  styleUrls: ['./quick-action-card.component.scss']
})
export class QuickActionCardComponent {

  @Input() card: Card;
  @Input() cardClass: string;

  constructor() {
  }

}
