import { TypeDefinition } from './type-definition';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum OptionScale {
  Fit = 'OptionScale_Fit',
  CenterCrop = 'OptionScale_CenterCrop'
}

export class OptionScaleType extends TypeDefinition {

  public override value: OptionScale;

  static getScaleFit(): Observable<OptionScaleType[]> {
    return window.types.optionScales$.pipe(
      map(optionScales => optionScales?.filter(os => os.value = OptionScale.Fit))
    );
  }

  static getScaleCenterCrop(): Observable<OptionScaleType[]> {
    return window.types.optionScales$.pipe(
      map(optionScales => optionScales?.filter(os => os.value = OptionScale.CenterCrop))
    );
  }

}
