<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ options.modalTitle }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container mt-24px">
    <app-upload-asset
      #changeMedia
      [allowImage]="options.allowImage$ | async"
      [allowVideo]="options.allowVideo$ | async"
      [id]="options.inputId"
      [maxAssets]="options.maxAssets"
      [parentHandler]="this">
    </app-upload-asset>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="handleChangeMedia()"
      [disabled]="assets.length === 0"
      [ngClass]="{ disabled: assets.length === 0 }"
      class="bs-button preferred-button"
      style="margin-right: 0">
      Done
    </button>
  </div>
</div>
