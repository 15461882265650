import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BaseDirective } from '../../models/base/base-directive';
import { ResizeObserver } from '@juggle/resize-observer';
import { fromEvent } from 'rxjs';
import { debounceTime, startWith, tap } from 'rxjs/operators';

@Directive({
    selector: '[appFitText]'
})
export class FitTextDirective extends BaseDirective implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef,
  ) {
    super();
    this.element = elementRef.nativeElement;
  }

  @Input() parentContainer: HTMLElement;
  @Input() fitTextDisabled: boolean = false;
  @Input() percentageOfParent: number = 1;
  @Input() lineHeightMultiplier = 1.25;

  private readonly element: HTMLElement;
  public fontSize: number = 16;
  public ro: ResizeObserver;
  private windowResize$ = fromEvent(window, 'resize').pipe(
    startWith(false),
    debounceTime(200),
    tap(it => {
      if (it) {
        this.setRelativeSizeToContainer();
      }
    }),
  );

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setRelativeSizeToContainer();
  }

  override setupBindings() {
    this.observeResize();
  }

  private observeResize() {
    const s = this.windowResize$.pipe(debounceTime(1)).subscribe(() => this.setRelativeSizeToContainer());
    this.pushSub(s);
  }

  private setRelativeSizeToContainer() {
    if (this.parentContainer) {
      // Use parent container as reference
      this.fontSize = this.parentContainer.offsetHeight * this.percentageOfParent;
    } else {
      // Use self as reference
      this.fontSize = this.element.offsetHeight * this.percentageOfParent;
    }
    this.fit();
  }

  private fit() {
    if (!this.fitTextDisabled) {
      this.element.style.fontSize = `${this.fontSize}px`;
      this.element.style.lineHeight = `${this.fontSize * this.lineHeightMultiplier}px`;
    }
  }

  override ngOnDestroy(): void {
        super.destroy();
        this.ro?.disconnect();
    }

}
