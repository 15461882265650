<div class="employee-container">
  <div class="employee-name">
    {{ viewModel.employeeFullName$ | async }}
    <div *ngIf="viewModel.canManageTemplatesForEmployees$ | async" class="flex-wrap-content flex-inline">
      <img class="template-access-icon" src="assets/icons/dark/solid/template.svg" alt="" />
    </div>
    <div
      *ngIf="viewModel.isCompanyAdmin$ | async"
      class="bs-admin-pill ml-8px flex-wrap-content flex-inline employee-admin">
      Admin
    </div>
    <app-drop-down-menu
      class="float-right mr-20px"
      [iconSrc]="'assets/icons/dark/outline/dots-horizontal.svg'"
      [useDivInsteadOfButton]="true"
      [dropdownTopMargin]="'-0.75rem'"
      [dropdownLeftMargin]="'2.65rem'"
      [sections]="viewModel.menuItems$ | async"
      [openLeft]="true"
      (output)="selection.emit($event)">
    </app-drop-down-menu>
  </div>
  <div class="employee-email mt-2px">
    <img
      *ngIf="viewModel.emailConfirmed$ | async"
      class="employee-verified"
      src="assets/icons/green/solid/check-circle.svg"
      alt="" />
    <img
      *ngIf="!(viewModel.emailConfirmed$ | async)"
      [ngbTooltip]="'The user has not confirmed their account yet. Certain actions are disabled for unconfirmed users.'"
      class="employee-verified"
      src="assets/icons/red/solid/exclamation-circle.svg"
      alt="" />
    {{ viewModel.employeeEmail$ | async }}
  </div>
  <div *ngIf="viewModel.showCompanyRole$ | async" class="employee-role-container mt-2px" fxLayout="row">
    <img class="employee-role-icon" src="assets/icons/dark/solid/user.svg" alt="" />
    <div class="employee-company-role" fxFlex>{{ viewModel.companyRole$ | async }}</div>
  </div>
  <hr class="mr-25px" />
</div>
