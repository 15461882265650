import { Pipe, PipeTransform } from '@angular/core';
import { DisplayAttribute } from '../../../models/display/dto/display-attribute';

@Pipe({
  name: 'getDisplayName'
})
export class GetDisplayNamePipe implements PipeTransform {

  transform(displayAttribute: DisplayAttribute): string {
    return displayAttribute?.getDisplayName() ?? '';
  }

}
