import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { EditMarketingMenuComponent } from '../edit-marketing-menu.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { EditSmartPlaylistMenuViewModel } from './edit-smart-playlist-menu-view-model';

@Component({
  selector: 'app-edit-smart-playlist-menu',
  templateUrl: './edit-smart-playlist-menu.component.html',
  styleUrls: [
    './edit-smart-playlist-menu.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditSmartPlaylistMenuViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSmartPlaylistMenuComponent extends EditMarketingMenuComponent {

  constructor(
    public override viewModel: EditSmartPlaylistMenuViewModel,
    ngbModal: NgbModal,
    injector: Injector,
    toastService: ToastService,
    router: Router,
    activatedRoute: ActivatedRoute
  ) {
    super(viewModel, ngbModal, injector, toastService, router, activatedRoute);
  }

}
