import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReorderModalComponent } from '../views/shared/components/reorder-modal/reorder-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Observable } from 'rxjs';
import { DisplayMenuOptions } from '../models/display/shared/display-menu-options';
import { ReorderOptions } from '../models/shared/stylesheet/reorder-options';
import { Orderable } from '../models/protocols/orderable';
import { Injector } from '@angular/core';
import { BaseDisplay } from '../models/display/dto/base-display';
import { CompactItem } from '../models/shared/compact-item';
import { CompactTemplateCollection } from '../models/template/dto/compact-template-collection';

export class ModalReorderMenus {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    display: BaseDisplay,
    compactItems: CompactItem[],
    menuOptions: Map<string, DisplayMenuOptions>,
    reorderOperation: (ordered: Orderable[]) => Observable<any>,
    collectionMode: boolean = false,
    displayIcons: boolean = false
  ): void {
    const modalRef = ngbModal.open(
      ReorderModalComponent,
      ModalUtils.reorderModalOptions(injector)
    );
    // Set display priority on all Menus
    display?.getMenus()?.forEach(m => m.displayPriority = menuOptions?.get(m?.id)?.priority);
    compactItems?.forEach((item) => {
      if (item instanceof CompactTemplateCollection) {
        item.compactMenus?.forEach(m => m.displayPriority = menuOptions?.get(m?.id)?.priority);
      }
      item.displayPriority = menuOptions?.get(item?.id)?.priority;
    });
    // Open modal
    const inst = modalRef.componentInstance as ReorderModalComponent;
    const reorderOptions = collectionMode
      ? ModalReorderMenus.getTemplateReorderOptions()
      : ModalReorderMenus.getMenuReorderOptions();
    reorderOptions.displayIcons = displayIcons;
    inst.setReorderOptions(reorderOptions);
    inst.setReorderItems(compactItems);
    inst.reorderOperation = reorderOperation;
  }

  static getMenuReorderOptions(): ReorderOptions {
    const opts = new ReorderOptions();
    opts.title = 'Reorder Menus';
    opts.subTitle = 'Menus';
    opts.bodyText = 'Drag and drop menus into the desired order that they should appear on the display.';
    opts.confirmText = 'Confirm Order';
    opts.loadingMess = 'Reordering Menus';
    opts.successTitle = 'Display Updated';
    opts.successMess = 'Menu order successfully updated.';
    opts.failureTitle = 'Display Error';
    opts.failureMess = 'Error updating menu order.';
    return opts;
  }

  static getTemplateReorderOptions(): ReorderOptions {
    const opts = new ReorderOptions();
    opts.title = 'Reorder Templates';
    opts.subTitle = 'Templates';
    opts.bodyText = 'Drag and drop templates into the desired order that they should appear on the collection.';
    opts.confirmText = 'Confirm Order';
    opts.loadingMess = 'Reordering Templates';
    opts.successTitle = 'Collection Updated';
    opts.successMess = 'Template order successfully updated.';
    opts.failureTitle = 'Collection Error';
    opts.failureMess = 'Error updating template order.';
    return opts;
  }

}
