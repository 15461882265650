<div class="banner-events-container" fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="0.5rem">
  <ng-container *ngFor="let event of bannerEvents$ | async">
    <app-error-banner
      *ngIf="event.type === EventType.Error"
      [ngClass]="{ 'banner-event-dismissed': event.dismissed }"
      (click)="eventClicked(event)">
      {{ event?.message }}
    </app-error-banner>
    <app-info-banner
      *ngIf="event.type === EventType.Info"
      [ngClass]="{ 'banner-event-dismissed': event.dismissed }"
      (click)="eventClicked(event)">
      {{ event?.message }}
    </app-info-banner>
    <app-message-banner
      *ngIf="event.type === EventType.Message"
      [ngClass]="{ 'banner-event-dismissed': event.dismissed }"
      (click)="eventClicked(event)">
      {{ event?.message }}
    </app-message-banner>
    <app-success-banner
      *ngIf="event.type === EventType.Success"
      [ngClass]="{ 'banner-event-dismissed': event.dismissed }"
      (click)="eventClicked(event)">
      {{ event?.message }}
    </app-success-banner>
    <app-warning-banner
      *ngIf="event.type === EventType.Warning"
      [ngClass]="{ 'banner-event-dismissed': event.dismissed }"
      (click)="eventClicked(event)">
      {{ event?.message }}
    </app-warning-banner>
  </ng-container>
</div>
