<app-print-job-name-form
  [job]="viewModel.job$ | push"
  [label]="'Stack Print Name'"
  [mergeKey]="viewModel.mergeKey$ | push"
  [placeholder]="viewModel.placeholder$ | push"
  [viewOnly]="viewModel.viewOnly$ | push">
</app-print-job-name-form>

<app-print-job-employee-form
  [job]="viewModel.job$ | push"
  [mergeKey]="viewModel.mergeKey$ | push"
  [viewOnly]="viewModel.viewOnly$ | push">
</app-print-job-employee-form>

<app-stack-smart-bulk-print-job-selected-stacks
  [stackMenus]="viewModel.selectedCardStacks$ | push"
  [stackType]="stackType">
</app-stack-smart-bulk-print-job-selected-stacks>

<app-stack-smart-bulk-print-config-form
  [configMap]="viewModel.updatedPrintConfigMap$ | push"
  [job]="viewModel.job$ | push"
  [mergeKey]="viewModel.mergeKey$ | push">
</app-stack-smart-bulk-print-config-form>

<app-stack-smart-bulk-print-options-form
  [stackType]="stackType"
  [blueprint]="viewModel.printConfigBlueprint$ | push"
  [mergeKey]="viewModel.mergeKey$ | push"
  [viewOnly]="viewModel.viewOnly$ | push"
  (smartPrintOptionChanged)="viewModel.handleSmartPrintOptionChanged($event)">
</app-stack-smart-bulk-print-options-form>

<app-stack-smart-bulk-print-previous-jobs-form
  [stackType]="stackType"
  [job]="viewModel.job$ | push"
  [selectedCardStacks]="viewModel.selectedCardStacks$ | push"
  [viewOnly]="viewModel.viewOnly$ | push"
  (includeChangesSinceChanged)="viewModel.handleIncludeChangesSinceChanged($event)">
</app-stack-smart-bulk-print-previous-jobs-form>

<app-bulk-card-stack-print-job-applied-products *rxIf="viewModel.viewOnly$ | push" [job]="viewModel.job$ | push">
</app-bulk-card-stack-print-job-applied-products>
