import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MenuDetailsSectionViewModel } from './menu-details-section-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { Menu } from '../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-menu-details-section',
  templateUrl: './menu-details-section.component.html',
  styleUrls: [
    './menu-details-section.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [MenuDetailsSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDetailsSectionComponent extends BaseComponent {

  constructor(public viewModel: MenuDetailsSectionViewModel) {
    super();
  }

  @Input() @ViewModelConnector('connectToTemplateMode') templateMode = false;
  @Input() @ViewModelConnector('connectToMenu') menu: Menu;

}
