<div
  class="product-card-price-container"
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap="1rem"
  #parent
  [style.color]="card.getCardTextColor()">
  <div
    class="product-card-price"
    appFitText
    [hidden]="card?.getHidePrice()"
    [parentContainer]="parent"
    [percentageOfParent]="0.5"
    [style.textDecoration]="card?.getSalePrice() ? 'line-through' : null"
    [style.opacity]="card?.getSalePrice() ? '0.8' : '1.0'"
    [style.fontWeight]="card?.getSalePrice() ? '500' : '700'">
    {{ card?.getOriginalPrice() }}
  </div>
  <div
    *ngIf="card?.getSalePrice() && !card?.getHidePrice()"
    class="product-card-sale-price"
    appFitText
    [parentContainer]="parent"
    [percentageOfParent]="0.5">
    {{ card?.getSalePrice() }}
  </div>
</div>
