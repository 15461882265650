import { HydratedVariantBadge } from '../../product/dto/hydrated-variant-badge';

export class BadgeAction {

  action: string;
  badge: HydratedVariantBadge;

  constructor(a: string, b: HydratedVariantBadge) {
    this.action = a;
    this.badge = b;
  }

}
