<div class="row menu-section-smart-filters-container">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Location Permissions</p>
    <p class="bs-edit-section-description">
      Define what functionality is available for a templated menu at each location.
    </p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8">
    <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="sectionTemplate">
      <lib-reactive-form-column-layout [nColumns]="2" [rowGap]="'1.5rem'" [columnGap]="'1rem'">
        <lib-reactive-form-checkbox
          [inputName]="'allowAddingProducts'"
          [selectedValue]="true"
          [notSelectedValue]="false"
          [tooltip]="allowAddingProductsTooltip$ | async"
          [disabled]="disableProductOverrides$ | push"
          [disabledTooltip]="disableProductOverridesTooltip$ | push"
          [bindingProperty]="'allowAddProducts'">
          Allow Adding Products
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          *ngIf="showAllowStyleEditingOption$ | async"
          [inputName]="'allowStyleOverride'"
          [selectedValue]="true"
          [notSelectedValue]="false"
          [tooltip]="allowStyleEditingTooltip$ | async"
          [bindingProperty]="'allowStyleOverride'">
          Allow Style Editing
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'allowLabelOverride'"
          [selectedValue]="true"
          [notSelectedValue]="false"
          [tooltip]="allowLabelOverrideTooltip$ | async"
          [bindingProperty]="'allowLabelOverride'">
          Allow Label Override
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'allowBadgeOverride'"
          [selectedValue]="true"
          [notSelectedValue]="false"
          [tooltip]="allowBadgeOverrideTooltip$ | async"
          [bindingProperty]="'allowBadgeOverride'">
          Allow Badge Override
        </lib-reactive-form-checkbox>
      </lib-reactive-form-column-layout>
    </lib-reactive-form-group>
  </div>
</div>

<hr class="mt-24px" />
