export class RangeSliderOptions {

  public label: string;
  public min: number;
  public max: number;
  public value: number;
  public step: number;
  public showThumbLabel: boolean = true;
  public disabled: boolean = false;
  public sliderOnly: boolean = false;
  public displayWidth: string;
  public labelStart: string;
  public labelEnd: string;
  public showTickMarks: boolean = false;

  static default(min?: number, max?: number, step?: number): RangeSliderOptions {
    const opt = new RangeSliderOptions();
    opt.min = min || 0;
    opt.max = max || 100;
    opt.step = step || 1;
    return opt;
  }

  static scrollSpeed(): RangeSliderOptions {
    const opt = new RangeSliderOptions();
    opt.min = -11;
    opt.max = -1;
    opt.step = 1;
    opt.value = -6;
    opt.showThumbLabel = false;
    opt.sliderOnly = true;
    opt.displayWidth = '200px';
    opt.labelStart = 'Slow';
    opt.labelEnd = 'Fast';
    return opt;
  }

}
