import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { SignOutViewModel } from './sign-out-view-model';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
  providers: [SignOutViewModel]
})
export class SignOutComponent extends BaseComponent {

  // A full sign-out page is needed to maintain the functionality of the abandon changes selection modal to allow the
  // user to decide if they want to abandon their changes or not
  constructor(
    public viewModel: SignOutViewModel
  ) {
    super();
  }

  override setupBindings(): void {
    this.viewModel.signOut();
  }

}
