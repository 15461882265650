<div class="label-picker-container" [style.padding]="includePadding ? '0.5rem 1rem' : null">
  <div class="label-picker-title">Change Default Label</div>
  <hr class="radio-divider" />
  <div class="label-picker-subtitle" *ngIf="viewModel.showPOSLabels$ | async">Your Labels</div>
  <form class="bs-radio-form">
    <div
      *ngFor="let label of viewModel.labelPool$ | async"
      class="bs-radio-option"
      [class.disabled]="
        label | isSmartLabelWithRemoveExistingOnSyncEnabled : viewModel.removeFromExistingOnSyncData$ | async
      "
      (click)="input.click()">
      <input
        #input
        [disabled]="
          label | isSmartLabelWithRemoveExistingOnSyncEnabled : viewModel.removeFromExistingOnSyncData$ | async
        "
        class="bs-radio"
        [style.position]="'inherit'"
        type="radio"
        id="{{ label?.id + seedNumber }}"
        name="{{ label?.id + seedNumber }}"
        [value]="label?.id"
        [style.margin-left.rem]="-0.5"
        [ngModel]="selectedLabel?.id"
        (ngModelChange)="selectedId.emit($event)" />
      <span
        class="bs-custom-radio"
        [style.margin-left.rem]="-1"
        [style.zIndex]="
          (label | isSmartLabelWithRemoveExistingOnSyncEnabled : viewModel.removeFromExistingOnSyncData$ | async)
            ? 2
            : null
        "
        [ngbTooltip]="
          label
            | isSmartLabelWithRemoveExistingOnSyncEnabled : viewModel.removeFromExistingOnSyncData$
            | async
            | smartLabelDisabledTooltip
        "
        [placement]="'top-left'"
        [container]="'body'">
      </span>
      <label class="bs-radio-label radio-product-label" for="{{ label?.id + seedNumber }}">{{
        label?.getLabelPickerTitle()?.toUpperCase()
      }}</label>
      <app-label-inflator
        [zoom]="0.8"
        [displayLabelInterface]="
          label
            | labelInterfaceForLabelPicker
              : viewModel.configurations$
              : false
              : (label | isCompanyOrphan : viewModel.locationCustomLabels$ | async)
            | async
        ">
      </app-label-inflator>
    </div>
  </form>

  <ng-container *ngIf="viewModel.showPOSLabels$ | async">
    <hr class="radio-divider" />
    <div class="label-picker-subtitle">POS Labels</div>
    <form class="bs-radio-form">
      <div *ngFor="let l of viewModel.posLabelPool$ | async" class="bs-radio-option" (click)="input.click()">
        <input
          #input
          class="bs-radio"
          [style.position]="'inherit'"
          type="radio"
          id="{{ l?.id + seedNumber }}"
          name="{{ l?.id + seedNumber }}"
          [value]="l?.id"
          [style.margin-left.rem]="-0.5"
          [ngModel]="selectedLabel?.id"
          (ngModelChange)="selectedId.emit($event)" />
        <span class="bs-custom-radio" [style.margin-left.rem]="-1"></span>
        <label class="bs-radio-label radio-product-label" for="{{ l?.id + seedNumber }}">{{
          l?.text?.toUpperCase()
        }}</label>
        <app-label-inflator
          [zoom]="0.8"
          [displayLabelInterface]="
            l | labelInterfaceForLabelPicker : viewModel.configurations$ : false : false | async
          ">
        </app-label-inflator>
      </div>
    </form>
  </ng-container>

  <hr class="radio-divider" />

  <div class="float-right">
    <button
      *ngIf="showClearSelectionButton"
      class="bs-button no-margin no-padding text-button"
      [disabled]="!selectedLabel?.id"
      [class.disabled]="!selectedLabel?.id"
      (click)="clearSelection()">
      Clear Selection
    </button>
  </div>
</div>
