import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Section } from '../../../../../../models/menu/dto/section';
import { MenuSectionPreviewViewModel } from './menu-section-preview-view-model';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-menu-section-preview',
  templateUrl: './menu-section-preview.component.html',
  styleUrls: ['./menu-section-preview.component.scss'],
  providers: [MenuSectionPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionPreviewComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: MenuSectionPreviewViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() hasErrors: boolean = false;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() menu: Menu;
  @Input() section: Section;
  @Input() sectionIndex: number;
  @Output() submitForms = new EventEmitter<boolean>(true);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateMode) this.viewModel.connectToTemplateMode(this.templateMode);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
  }

  duplicateSection() {
      if (this.formPristine) {
        this.viewModel.openDuplicateSectionModal();
      } else {
        this.viewModel.formAutoSubmitted$.once(_ => this.viewModel.openDuplicateSectionModal());
        this.submitForms.emit(true);
      }
    }

}
