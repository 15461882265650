import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { MenuStyleObject } from '../../utils/dto/menu-style-object-type';
import type { Menu } from './menu';
import { Variant } from '../../product/dto/variant';
import { Section } from './section';

export class MenuStyle implements Deserializable, UniquelyIdentifiable {

  public configurationId: string;
  public id: string;
  public sectionId: string;
  public objectId: string;
  public objectType: MenuStyleObject;
  public fontSize: number;
  public fontColor: string;
  public backgroundColor: string;
  public bold: boolean;
  public italics: boolean;
  public isTemplateStyle: boolean;
  // Form Group
  public fontDecoration: [boolean, boolean] = [false, false]; // [bold, italics]
  public applyToProductVariants: boolean = false;

  // For default label colors, we want to choose something uncommon to ensure the user does not choose it as
  // their initial selection
  static DEFAULT_COLOR_PICKER_COLOR = '#F1F2F3';

  static newStyle(isTemplateStyle: boolean = false): MenuStyle {
    const s = new MenuStyle();
    s.isTemplateStyle = isTemplateStyle;
    s.fontSize = 100;
    s.bold = false;
    s.italics = false;
    s.fontColor = MenuStyle.DEFAULT_COLOR_PICKER_COLOR;
    s.backgroundColor = MenuStyle.DEFAULT_COLOR_PICKER_COLOR;
    return s;
  }

  public onDeserialize() {
    this.fontColor = this.fontColor || MenuStyle.DEFAULT_COLOR_PICKER_COLOR;
    this.backgroundColor = this.backgroundColor || MenuStyle.DEFAULT_COLOR_PICKER_COLOR;
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/MenuStyleDTO.go
  public onSerialize() {
    const dto = Object.create(MenuStyle.prototype);
    dto.configurationId = this.configurationId;
    dto.id = this.id;
    dto.backgroundColor = this.backgroundColor;
    dto.bold = this.bold;
    dto.fontColor = this.fontColor;
    dto.fontSize = this.fontSize;
    dto.italics = this.italics;
    dto.objectId = this.objectId;
    dto.objectType = this.objectType;
    dto.sectionId = this.sectionId;
    dto.isTemplateStyle = this.isTemplateStyle;
    return dto;
  }

  public setupFontDecorationFormData(): void {
    this.fontDecoration = [this.bold, this.italics];
  }

  public placeFontDecorationIntoProperties(): void {
    this.bold = this.fontDecoration[0];
    this.italics = this.fontDecoration[1];
  }

  public transformIntoDTO(menu: Menu, variant: Variant): void {
    this.configurationId = menu?.id;
    this.placeFontDecorationIntoProperties();
    if (this.applyToProductVariants) {
      this.objectId = variant.productId;
      this.objectType = MenuStyleObject.Product;
    } else {
      this.objectId = variant.id;
      this.objectType = MenuStyleObject.Variant;
    }
    if (this.fontColor?.toLowerCase() === MenuStyle.DEFAULT_COLOR_PICKER_COLOR.toLowerCase()) {
      this.fontColor = '';
    }
    if (this.backgroundColor?.toLowerCase() === MenuStyle.DEFAULT_COLOR_PICKER_COLOR.toLowerCase()) {
      this.backgroundColor = '';
    }
  }

  public isRemovable(menu: Menu): boolean {
    return !!this.id && !this.isTemplatedMenuWithTemplateStyle(menu);
  }

  public isTemplatedMenuWithTemplateStyle(menu: Menu): boolean {
    return this.isTemplateStyle && menu?.isTemplatedMenu();
  }

  public viewOnly(section: Section): boolean  {
    return section?.isTemplatedSection()
      ? !section?.allowedToOverrideTemplateStyling()
      : false;
  }

  public templateStyleTargetsWhichTemplatedSectionId(templatedSections: Section[]): string {
    return templatedSections
      ?.find(s => s?.templateSectionId === this.sectionId)
      ?.id;
  }

  getUniqueIdentifier(): string {
    return `${this.id}
      -${this.sectionId}
      -${this.objectId}
      -${this.objectType}
      -${this.fontSize}
      -${this.fontColor}
      -${this.backgroundColor}
      -${this.bold}
      -${this.italics}`;
  }

}
