<div class="mt-16px mb-16px f16px bs-bold">{{ title }}</div>

<app-bulk-card-stack-print-job-applied-products-accordion
  *rxFor="let data of viewModel.cardStackPrintConfigs$ | push | keyvalue"
  [cardStackMenu]="
    data?.key | menuFromId : viewModel.locationMenus$ | constructCardStackMenuIfOriginalDeleted : data?.value | push
  "
  [variantCount]="data?.value?.variantIds?.length">
  <ng-container *rxIf="data?.value?.variantIds?.length; else noAppliedVariants">
    <app-stack-applied-product-list-item
      *rxFor="let id of data?.value?.variantIds"
      [variantCountMap]="data?.value?.variantCardCountMap"
      [variant]="id | variantFromId : viewModel.locationVariants$ | push">
    </app-stack-applied-product-list-item>
  </ng-container>
  <ng-template #noAppliedVariants>
    <div class="d-flex f12px pb-16px bs-medium">
      <div>{{ viewModel?.noVariantsPlaceholder$ | push }}</div>
    </div>
  </ng-template>
</app-bulk-card-stack-print-job-applied-products-accordion>
