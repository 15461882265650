import { Pipe, PipeTransform } from '@angular/core';
import { SmartFilter } from '../../../models/automation/smart-filter';

@Pipe({
  name: 'isSmartFilter'
})
export class IsSmartFilterPipe implements PipeTransform {

  transform(value: any): boolean {
    return value instanceof SmartFilter;
  }

}
