import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DisplayableProductCard } from '../displayable-product-card';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { ColorUtils } from '../../../../../../../../utils/color-utils';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BaseProductCardViewModel } from './base-product-card-view-model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
  providers: [BaseProductCardViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseProductCardComponent extends BaseComponent {

  constructor(
    public viewModel: BaseProductCardViewModel,
  ) {
    super();
  }
  @Input() @ViewModelConnector('connectToCard') card: DisplayableProductCard;
  @Input() @ViewModelConnector('connectToIndex') index: number;
  @Input() @ViewModelConnector('connectToCardCount') cardCount: number;

  @ViewChild('productCard') productCard: ElementRef;
  public ro: ResizeObserver;
  protected cardContainerDomRec = new BehaviorSubject<DOMRectReadOnly | null>(null);

  public cardContainerBottom$ = this.cardContainerDomRec.pipe(
    map((cardContainerDomRec) => {
      return cardContainerDomRec?.bottom;
    })
  );
  public cardContainerClientHeight$ = this.cardContainerDomRec.pipe(map(rect => rect?.height));

  override setupBindings() {
    this.observeProductCardContainer();
  }

  observeProductCardContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const productCardRec = this.productCard?.nativeElement?.getBoundingClientRect();
        this.cardContainerDomRec.next(productCardRec as DOMRectReadOnly);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.productCard.nativeElement);
  }

  isDarkTextColor(): boolean {
    return ColorUtils.isDarkColor(this.card.getCardTextColor());
  }

  getLabelPercentage(): number {
    return 0.045;
  }

  getProductNameTextPercentage(): number {
    return 0.08;
  }

  getProductDescTextPercentage(): number {
    return this.getProductNameTextPercentage() * 0.6;
  }

  getProductInfoTextPercentage(): number {
    return this.getProductNameTextPercentage() * 0.66;
  }

  getProductCannabinoidTextPercentage(): number {
    return this.card.getIsCannabinoidRange()
      ? this.getProductNameTextPercentage() * 0.45
      : this.getProductNameTextPercentage() * 0.66;
  }

}
