import { TypeDefinition } from './type-definition';
import { SectionLayoutType } from './section-layout-type';

export enum SectionColumnConfigKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  THC = 'THC',
  CBD = 'CBD',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
  Stock = 'Stock'
}

export enum ColumnOptionName {
  Asset = 'images',
  Badges = 'badges',
  Brand = 'brand',
  Classification = 'classification',
  Quantity = 'quantity',
  Size = 'size',
  QuantityAndSize = 'quantityAndSize',
  Cbd = 'cbd',
  Thc = 'thc',
  Price = 'price',
  SecondaryPrice = 'secondaryPrice',
  Stock = 'stock'
}

export class SectionColumnConfigKeyType extends TypeDefinition {

  override value: SectionColumnConfigKey;

  public static disabledForGridLayout(key: string, layoutType: SectionLayoutType): boolean {
    const disableForGridLayout = key === SectionColumnConfigKey.Quantity
      || key === SectionColumnConfigKey.QuantityAndSize
      || key === SectionColumnConfigKey.SecondaryPrice
      || key === SectionColumnConfigKey.Size;
    return layoutType?.isAnyGrid() && disableForGridLayout;
  }

}
