import { PropertyLevel } from '../../../models/enum/shared/property-level.enum';
import { CustomizableData } from '../../../models/enum/shared/customizable-data.enum';

export class CustomizationFilter {

  constructor(
    public level: PropertyLevel,
    public type: CustomizableData
  ) {
    this.name = `${this.level} ${this.type}`;
  }

  public name: string;

}
