import { FormValidator } from '../../protocols/form-validator';
import { UntypedFormGroup } from '@angular/forms';
import { Validatable } from '../../protocols/validatable';
import { Selectable } from '../../protocols/selectable';
import { Searchable } from '../../protocols/searchable';
import { EventEmitter } from '@angular/core';
import { Checkbox } from './checkbox';
import { CheckboxContainerOptions } from './checkbox-container-options';
import { InformationItem } from '../information-item';
import { Subject } from 'rxjs';
import { NgxPopperjsPlacements } from 'ngx-popperjs';

export enum FormInputType {
  Date = 'date',
  Email = 'email',
  Color = 'color',
  Number = 'number',
  Password = 'password',
  ConfirmPassword = 'password',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
}

export enum FormItemType {
  Input = 'input',
  Dropdown = 'dropdown',
  Textarea = 'textarea',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Checkbox = 'checkbox',
  Switch = 'switch',
  Divider = 'divider',
  Hidden = 'hidden',
  Spacer = 'spacer',
  Title = 'title',
  CheckboxGroup = 'checkboxgroup'
}

export class FormInputItem implements Validatable {

  // Properties
  public itemType: FormItemType = FormItemType.Input;
  public inputName: string;
  public label: string;
  public placeholder: string;
  public editable: boolean = true;
  public bindingProperty: string;
  public inputType: FormInputType;
  public mustMatchInputName: string;
  public dropdownOptions: Selectable[] = [];
  public dropdownIsObject: boolean = false;
  public tooltipText: string;
  public tooltipModalTitle: string;
  public tooltipModalInfoItems: InformationItem[];
  public valueChanged: Subject<any> = new Subject<any>();
  public enabled: boolean = true;
  public customValueParser: (s: any) => any;
  public boundInputs: FormInputItem[];
  public customClass: string;
  public inputStep: string = '1';
  // Grouped Checkbox
  public groupCheckboxes: Checkbox[] = [];
  public groupCheckboxOptions: CheckboxContainerOptions = new CheckboxContainerOptions();
  public groupCheckboxesChanged: (checkboxes: Checkbox[]) => void;
  // Input Button
  public inputHasButton: boolean = false;
  public inputButtonText: string;
  public inputButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  // Layout Properties
  public showRequiredAsterisk: boolean = false;
  public inlineLabel: boolean = false;
  public inlineLabelWidthPercent: number = 50;
  public hideLabel: boolean = false;
  public overrideFullWidth: boolean = false;
  public autoCapitalize = 'on';
  public autoComplete: boolean = false;
  public showDropdownClearButton: boolean = false;
  // Validation Properties
  public required: boolean = false;
  public minLength: number;
  public maxLength: number;
  public minValue: number = -1;
  public maxValue: number = -1;
  public customValidator: FormValidator;
  // Custom error messages
  // Options: required, minlength, maxlength, and custom FormValidator.errorName()
  public customError: Map<string, string>;
  // Form Delegate
  public formDelegate: UntypedFormGroup;
  // Searchable
  public searchable: Searchable[] = [];
  // Popper
  public colorPopperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.BOTTOMEND;

  static getItemValue(items: FormInputItem[], inputName: string): any {
    let val: any = null;
    items.forEach((i) => {
      if (i.inputName === inputName) {
        val = i.getValue();
      }
    });
    return val;
  }

  getCustomErrorMessage(key: string): string {
    let customErr;
    if (this.customError) {
      this.customError.forEach((val, k) => {
        if (key === k) {
          customErr = val;
        }
      });
    }
    return customErr ? customErr : null;
  }

  clearValue() {
    if (this.isIgnoredItemType()) {
      return;
    }
    if (this.isDropDown() && this.formDelegate?.get(this.inputName)) {
      return this.formDelegate.get(this.inputName).patchValue(this.dropdownIsObject ? 'null' : '');
    } else if (this.formDelegate?.get(this.inputName)) {
      return this.formDelegate.get(this.inputName).reset('');
    }
    return;
  }

  selectFirstDropdown() {
    if (this.itemType === FormItemType.Dropdown) {
      this.formDelegate.get(this.inputName).setValue(this.dropdownOptions[0].getSelectionValue());
    }
  }

  selectDropDown(s: Selectable) {
    if (s && this.itemType === FormItemType.Dropdown) {
      if (this.formDelegate.get(this.inputName).value !== s.getSelectionValue()) {
        this.formDelegate.get(this.inputName).setValue(s.getSelectionValue());
      }
    }
  }

  getValue(): any {
    if (this.isIgnoredItemType()) {
      return null;
    }

    if (this.formDelegate?.get(this.inputName)) {
      const val = this.formDelegate?.get(this.inputName).value;
      if (this.itemType === FormItemType.Dropdown) {
        return this.dropdownOptions
          ?.find(o => o.getSelectionValue() === val)
          ?.getSelectionValue() || (this.dropdownIsObject ? null : '');
      }
      return val;
    }
    return null;
  }

  /**
   * Can programmatically update an input if parameters passed in.
   * Emits changed value to valueChanged.
   */
  handleValueChanged(input?: HTMLInputElement, value?: string) {
    if (!!input) {
      input.value = value;
      input.dispatchEvent(new Event('input'));
    }
    this.valueChanged.next([this.getValue(), this.boundInputs]);
  }

  // Validatable Methods

  hasErrors(): boolean {
    if (!this.enabled) {
      // dont validate disabled fields
      return false;
    } else if (!this.formDelegate || this.isIgnoredItemType()) {
      return false;
    } else if (this.itemType === FormItemType.CheckboxGroup) {
      if (this.groupCheckboxOptions.requireMinimumSelection > 0) {
        return this.groupCheckboxes
          ?.map(ch => ch.checked)
          ?.filter(ch => ch).length < this.groupCheckboxOptions.requireMinimumSelection;
      } else {
        return false;
      }
    } else if (this.formDelegate.get(this.inputName).touched) {
      // check for error on touched field
      if (this.mustMatchInputName) {
        const formItem = this.formDelegate.get(this.inputName);
        const checkingAgainst = this.formDelegate.get(this.mustMatchInputName);
        const emptyString = formItem.value !== '';
        const differentValues = formItem.value !== checkingAgainst.value;
        if (emptyString && differentValues) {
          return true;
        }
      }
      return (this.formDelegate.get(this.inputName).invalid && this.formDelegate.get(this.inputName).errors !== null);
    } else {
      return false;
    }
  }

  getErrorMessage(): string {
    if (!this.enabled) {
      // dont validate disabled fields
      return '';
    } else if (!this.formDelegate || this.isIgnoredItemType()) {
      return '';
    } else if (this.itemType === FormItemType.CheckboxGroup) {
      const requiredMinSelection = this.groupCheckboxOptions?.requireMinimumSelection > 0;
      const touched = this.groupCheckboxOptions?.touched;
      const failsRequirements = this.groupCheckboxes
        ?.map(ch => ch.checked)
        ?.filter(ch => ch).length < this.groupCheckboxOptions.requireMinimumSelection;
      if (requiredMinSelection && touched && failsRequirements) {
        return `At least ${this.groupCheckboxOptions.requireMinimumSelection} option must be selected.`;
      } else {
        return '';
      }
    } else {
      const errors = this.formDelegate?.get(this.inputName)?.errors;
      if (errors === null) {
        const valOne = this.formDelegate?.get(this.inputName)?.value;
        const valTwo = this.formDelegate?.get(this.mustMatchInputName)?.value;
        if (this.mustMatchInputName && (valOne !== valTwo)) {
          return `Does not match ${this.mustMatchInputName}`;
        } else {
          return null;
        }
      } else {
        let returnError: string = '';
        Object.keys(errors).forEach(keyError => {
          if (keyError === 'required') {
            returnError = this.getCustomErrorMessage(keyError) ?? `${this.label} is required.`;
          } else if (keyError === 'minlength') {
            const msg = `${this.label} must be more than ${this.minLength - 1} characters.`;
            returnError = this.getCustomErrorMessage(keyError) || msg;
          } else if (keyError === 'maxlength') {
            const msg = `${this.label} must be less than ${this.maxLength + 1} characters.`;
            returnError = this.getCustomErrorMessage(keyError) || msg;
          } else if (keyError === 'min') {
            // If min value is 0.01 then show error message as 0 since it is inclusive
            const greaterThan = `${this.label} must be greater than 0.`;
            const greaterThanOrEqual = `${this.label} must be greater than or equal to ${this.minValue}.`;
            const msg = (this.minValue === 0.01) ? greaterThan : greaterThanOrEqual;
            returnError = this.getCustomErrorMessage(keyError) || msg;
          } else if (keyError === 'max') {
            const msg = `${this.label} must be less than or equal to ${this.maxValue}.`;
            returnError = this.getCustomErrorMessage(keyError) || msg;
          } else if (keyError === 'email') {
            returnError = this.getCustomErrorMessage(keyError) || `Must be a valid email address.`;
          } else if (keyError === 'searchFor') {
            returnError = this.getCustomErrorMessage(keyError) || `Search list must contain selection.`;
          } else if (keyError === 'phoneNumber') {
            returnError = this.getCustomErrorMessage(keyError) || `Invalid phone number.`;
          } else if (keyError === this.customValidator?.errorName()) {
            returnError = this.getCustomErrorMessage(keyError) || errors[keyError];
          }
        });
        return returnError;
      }
    }
  }

  canSubmit(): boolean {
    if (!this.formDelegate) {
      return false;
    }
    if (this.isIgnoredItemType()) {
      return true;
    }
    if (this.required) {
      // can submit if has no errors and the items has either been touched/dirty or is disabled
      const touched = this.formDelegate?.get(this.inputName)?.touched;
      const dirty = this.formDelegate?.get(this.inputName)?.dirty;
      return !this.hasErrors() && (touched || dirty || !this.enabled);
    } else {
      return !this.hasErrors();
    }
  }

  private isIgnoredItemType(): boolean {
    return this.itemType === FormItemType.Divider
        || this.itemType === FormItemType.Title
        || this.itemType === FormItemType.Hidden
        || this.itemType === FormItemType.Spacer;
  }

  private isDropDown(): boolean {
    return this.itemType === FormItemType.Dropdown;
  }

}
