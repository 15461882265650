import { HttpClient } from '@angular/common/http';
import { EMPTY, iif, Observable } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { Deserializable } from '../models/protocols/deserializable';
import { APIRequestType } from '../models/enum/shared/api-request-type.enum';
import { StringifyUtils } from '../utils/stringify-utils';
import { expand, map, reduce } from 'rxjs/operators';
import { ApiPagination } from '../models/shared/api-pagination';
import { Pagable } from '../models/protocols/pagable';
import { PagableObject } from '../models/protocols/pagableObject';

export const DEFAULT_PAGINATION_COUNT = 1000;

/**
 * Some api endpoints send JSON objects that are too large for a single payload.
 * When this happens, we use a custom "Paging" system that breaks up the object into
 * multiple payloads. The json object will contain the property "pagingKey" if it is
 * a paged object. If there isn't a pagingKey, then the object sent was complete.
 */
type PagedData = { pagingKey?: string };

@Injectable({ providedIn: 'root' })
export class ApiClient {

  constructor(
    private http: HttpClient,
  ) {
  }

  /* ************************** GET ************************** */

  public getUntypedObj(url: string, additionalHeaders: any = null): any {
    return this.http.get(url, {headers: additionalHeaders});
  }

  public getObj<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T> {
    return this.http.get<T>(url, {headers: additionalHeaders}).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(ObjectType, r))
    );
  }

  public getArr<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T[]> {
    return this.http.get<T[]>(url, {headers: additionalHeaders}).pipe(
      map(r => r?.map(rr => window?.injector?.Deserialize?.instanceOf(ObjectType, rr)) as T[])
    );
  }

  public recursiveGet<T extends Pagable>(
    respObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null,
    pagination: ApiPagination
  ): Observable<T[]> {
    let paginationUrl: string;
    const params = url.includes('?');
    if (pagination) {
      const c = pagination.count || DEFAULT_PAGINATION_COUNT;
      const sk = pagination.startKey || '';
      paginationUrl = `${url}${params ? '&' : '?'}Count=${c}&StartKey=${sk}`;
    } else {
      pagination = new ApiPagination(DEFAULT_PAGINATION_COUNT);
      paginationUrl = `${url}${params ? '&' : '?'}Count=${DEFAULT_PAGINATION_COUNT}`;
    }
    return this.getArr<T>(respObjectType, paginationUrl || url, additionalHeaders).pipe(
      expand((res) => {
        if (res && res.length === pagination.count) {
          pagination.startKey = (res.last() as Pagable).getStartKey();
          const parameters = url.includes('?');
          paginationUrl = `${url}${parameters ? '&' : '?'}Count=${pagination.count}&StartKey=${pagination.startKey}`;
          return this.getArr<T>(respObjectType, paginationUrl || url, additionalHeaders);
        } else {
          return EMPTY;
        }
      }),
      reduce((acc, val) => {
        acc.push(...val);
        return acc;
      })
    );
  }

  /**
   * Get the raw JSON object from the API for paginated data.
   */
  public getPagedObj(url: string, additionalHeaders: any = null): Observable<PagedData> {
    return this.http.get<PagedData>(url, {headers: additionalHeaders});
  }

  public recursiveGetObject<T extends PagableObject>(
    RespObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null
  ): Observable<T> {
    const hasParams = url?.includes('?');
    const getPaginatedUrl = (pagingKey: string) => `${url}${hasParams ? '&' : '?'}StartKey=${pagingKey || ''}`;
    const getResponse = (getObjUrl) => this.getPagedObj(getObjUrl, additionalHeaders);
    const hasPageResponse = (result) => !!result && !!result?.pagingKey;
    return getResponse(url).pipe(
      expand(result => iif(() => hasPageResponse(result), getResponse(getPaginatedUrl(result?.pagingKey)), EMPTY)),
      reduce((acc: PagedData[], val: PagedData) => [...acc, val], []),
      map((pageFragments: any[]) => {
        const builder = new RespObjectType();
        return builder.consolidatePagedData(pageFragments) as T;
      })
    );
  }

  public getTypedStringMap<T extends Deserializable>(
    ObjectType: Type<T>,
    url: string,
    additionalHeaders: any = null,
  ): Observable<Map<string, T>> {
    return this.http.get<Map<string, T>>(url, {headers: additionalHeaders}).pipe(
      map(r => window?.injector?.Deserialize?.mapOf(ObjectType, r))
    );
  }

  public getBlob<Blob>(url: string): Observable<Blob> {
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json'
    });
  }

  /* ************************* POST ************************** */

  public postRecursiveObj<T extends PagableObject, U extends Deserializable>(
    ReturnType: Type<T>,
    updateDataUrl,
    getDataUrl,
    payload: U,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    const hasParams = getDataUrl?.includes('?');
    const getPaginatedUrl = (pagingKey: string) => `${getDataUrl}${hasParams ? '&' : '?'}StartKey=${pagingKey || ''}`;
    const getResponse = (getObjUrl) => this.getPagedObj(getObjUrl, additionalHeaders);
    const hasPageResponse = (result) => !!result && !!result?.pagingKey;
    const stringPayload = JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer);
    return this.http.post<T>(updateDataUrl, stringPayload, {headers: additionalHeaders}).pipe(
      expand(result => iif(() => hasPageResponse(result), getResponse(getPaginatedUrl(result?.pagingKey)), EMPTY)),
      reduce((acc: PagedData[], val: PagedData) => [...acc, val], []),
      map(pageFragments => {
        const builder = new ReturnType();
        return builder.consolidatePagedData(pageFragments) as T;
      })
    );
  }

  public postObj<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: U,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(ReturnType, r))
    );
  }

  public postArr<T extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: T[],
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    const serializedPayload = payload?.map(obj => obj?.onSerialize?.() ?? obj);
    return this.http.post<T[]>(url, JSON.stringify(serializedPayload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(ReturnType, rr)) as T[])
    );
  }

  public postArrayReturnMapArr<T extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: T[],
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<Map<string, T[]>> {
    const serializedPayload = payload?.map(obj => obj?.onSerialize?.() ?? obj);
    return this.http.post<Map<string, T[]>>(url, JSON.stringify(serializedPayload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.typedArrayMapOf(ReturnType, r))
    );
  }

  /* ************************ DELETE ************************* */

  public deleteStr<T extends Deserializable>(
    url,
    payload: T | T[],
    additionalHeaders: any = null,
    responseType: string = 'text'
  ): Observable<string> {
    const serializedPayload = payload instanceof Array
      ? payload?.map(obj => obj?.onSerialize?.() ?? obj)
      : payload?.onSerialize?.() ?? payload;
    return this.http.request<string>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(serializedPayload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    });
  }

  public deleteObj<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: U,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    return this.http.request<T>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(ReturnType, r))
    );
  }

  public deleteObjReturnSuccess<U extends Deserializable>(
    url,
    payload: U,
    additionalHeaders: any = null,
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'text'
  ): Observable<any> {
    return this.http.request(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer),
      responseType
    });
  }

  public deleteReturnArr<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: U,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    return this.http.request<T[]>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(ReturnType, rr)) as T[])
    );
  }

}

