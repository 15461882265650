import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { BaseDisplay } from '../../../../../../models/display/dto/base-display';

@Component({template:''})
export abstract class SelectMenuOrCollectionResultsComponent extends BaseComponent {

  @Input() display: BaseDisplay;
  @Input() emptyStateText: string;
  @Input() previouslySelectedItemIds: string[];
  @Input() searchedItems: any[];

  @Output() itemClicked = new EventEmitter<string>();

}
