import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'productFromId'
})
export class ProductFromIdPipe implements PipeTransform {

  constructor(
    private productDomainModel: ProductDomainModel,
  ) {
  }

  transform(productId: string): Observable<Product|null> {
    return combineLatest([
      this.productDomainModel.currentLocationProducts$,
      this.productDomainModel.overridedProducts$
    ]).pipe(
      map(([products, overridedProducts]) => {
        return products?.find(p => p?.id === productId) || overridedProducts?.find(p => p?.id === productId) || null;
      })
    );
  }

}
