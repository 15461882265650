import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferAFriendModalComponent } from '../views/shared/components/refer-a-friend-widget/refer-a-friend-modal/refer-a-friend-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalReferAFriend {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
  ): void {
    ngbModal.open(
      ReferAFriendModalComponent,
      ModalUtils.defaultModalOptions(injector)
    );
  }

}
