<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<app-required-display-edit-method
  [hidden]="(viewModel.editStep$ | async) !== viewModel.requiredDisplaysStep.ChooseEditMethod"
  (cancel)="cancel()"
  (editMethodSelected)="viewModel.connectToEditStep($event)">
</app-required-display-edit-method>

<app-edit-collection-edit-required-displays
  [hidden]="(viewModel.editStep$ | async) !== viewModel.requiredDisplaysStep.ApplyToExisting"
  [requiredDisplayIds]="viewModel.requiredDisplayIds$ | async"
  (cancel)="cancel()">
</app-edit-collection-edit-required-displays>

<app-edit-collection-create-new-display
  [hidden]="(viewModel.editStep$ | async) !== viewModel.requiredDisplaysStep.NewDisplayDetails"
  (cancel)="cancel()">
</app-edit-collection-create-new-display>

<app-select-locations-for-new-display
  [hidden]="(viewModel.editStep$ | async) !== viewModel.requiredDisplaysStep.SelectLocations"
  [pendingDisplay]="viewModel.pendingDisplay$ | async"
  (goBack)="viewModel.goBack()"
  (cancel)="cancel()">
</app-select-locations-for-new-display>
