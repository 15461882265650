import { PropertyLevel } from '../../../models/enum/shared/property-level.enum';
import { StringUtils } from '../../../utils/string-utils';

export class TerpeneFilter {

  constructor(
    public level: PropertyLevel,
    public terpene: string
  ) {
    this.name = `${this.level} ${this.terpene}`;
  }

  public name: string;

  public getNameWithCamelizedTerpene(): string {
    return `${this.level} ${StringUtils.toCamelCase(this.terpene)}`;
  }

}
