import { Deserializable } from '../protocols/deserializable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { ProductType } from '../utils/dto/product-type-definition';
import { Terpene } from '../utils/dto/terpene-type-definition';
import { SecondaryCannabinoid } from '../utils/dto/secondary-cannabinoids-type-definition';
import { exists } from '../../functions/exists';
import { PrimaryCannabinoid } from '../enum/shared/primary-cannabinoid.enum';
import { StringUtils } from '../../utils/string-utils';

export class SmartFilter implements Deserializable, LookAheadItem, UniquelyIdentifiable {

  public locationId: number = null;
  public id: string = '';
  public ignoredVariantIds: string[] = [];
  public dateCreated: number = null;
  public lastUpdated: number = null;
  public categoryId: string = '';
  public advancedFilter: boolean = null;
  public hidden: boolean = null;
  // General Info
  public name: string = '';
  public itemCount: number = null;
  public primarySort: string = '';
  public secondarySort: string = '';
  // Filter Attributes
  public usePurpose: string = '';
  public productType: ProductType = null;
  public variantType: string = '';
  public classification: string = '';
  public minPrice: number = null;
  public maxPrice: number = null;
  public minSecondaryPrice: number = null;
  public maxSecondaryPrice: number = null;
  // Advanced Filter Attributes
  public packagedQuantities: string = '';
  public unitSizes: string = '';
  public productName: string = '';
  public brandName: string = '';
  public strain: string = '';
  public searchTerm: string = ''; // General Search Term
  public minInventory: number = null;
  public maxInventory: number = null;
  public pricingCodes: string = '';
  public activeSale: boolean = null;
  public requirePricingTier: boolean = null;
  public labelIds: string[] = [];
  public badgeIds: string[] = [];
  public isCompanySmartFilter: boolean = null;
  public isCuratedSmartFilter: boolean = null;
  // Terpenes
  public topTerpene: string = '';
  public minTotalTerpene: number = null;
  public maxTotalTerpene: number = null;

  // Cannabinoid and Terpene Properties are added via initializeCannabinoidsAndTerpeneProperties()
  // These properties are of type number.
  // cannabinoid example properties: minTHC, maxTHC, minCBLA, maxCBLA, minTAC, maxTAC, etc.
  // terpene example properties: minBisabolol, maxBisabolol, minLimonene, maxLimonene, etc.
  // NOTE: terpene enums are capitalized, but terpene properties are camelCase.

  /**
   * Adds [minCannabinoid] and [maxCannabinoid] properties to the SmartFilter object.
   * Adds [minTerpene], and [maxTerpene] properties to the DisplayAttribute object.
   * NOTE: terpene enums are capitalized, but terpene properties are camelCase.
   */
  protected initializeCannabinoidsAndTerpeneProperties(): void {
    Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
      this[`min${cannabinoid}`] = this[`min${cannabinoid}`] || null; // This will make 0 become null
      this[`max${cannabinoid}`] = this[`max${cannabinoid}`] || null; // This will make 0 become null
    });
    Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
      this[`min${secondaryCannabinoid}`] = this[`min${secondaryCannabinoid}`] || null; // This will make 0 become null
      this[`max${secondaryCannabinoid}`] = this[`max${secondaryCannabinoid}`] || null; // This will make 0 become null
    });
    Object.values(Terpene)?.forEach(terpene => {
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      this[`min${pascalCaseTerpene}`] = this[`min${pascalCaseTerpene}`] || null; // This will make 0 become null
      this[`max${pascalCaseTerpene}`] = this[`max${pascalCaseTerpene}`] || null; // This will make 0 become null
    });
  }

  onDeserialize() {
    this.initializeCannabinoidsAndTerpeneProperties();
    if (this.minTotalTerpene === 0) this.minTotalTerpene = null;
    if (this.maxTotalTerpene === 0) this.maxTotalTerpene = null;
    if (this.minInventory === 0) this.minInventory = null;
    if (this.maxInventory === 0) this.maxInventory = null;
    if (this.minPrice === 0) this.minPrice = null;
    if (this.maxPrice === 0) this.maxPrice = null;
    if (this.minSecondaryPrice === 0) this.minSecondaryPrice = null;
    if (this.maxSecondaryPrice === 0) this.maxSecondaryPrice = null;
    if (this.itemCount === 0) this.itemCount = null;
    this.ignoredVariantIds = Array.from(this.ignoredVariantIds || []);
    this.labelIds = Array.from(this.labelIds || []);
    this.badgeIds = Array.from(this.badgeIds || []);
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/SmartFilterDTO.go
  onSerialize() {
    const dto = Object.create(SmartFilter.prototype);
    dto.locationId = this.locationId;
    dto.id = this.id;
    dto.ignoredVariantIds = this.ignoredVariantIds;
    dto.categoryId = this.categoryId;
    dto.advancedFilter = this.advancedFilter;
    dto.name = this.name;
    dto.itemCount = this.itemCount;
    dto.primarySort = this.primarySort;
    dto.secondarySort = this.secondarySort;
    dto.usePurpose = this.usePurpose;
    dto.productType = this.productType;
    dto.variantType = this.variantType;
    dto.classification = this.classification;
    dto.minPrice = this.minPrice;
    dto.maxPrice = this.maxPrice;
    dto.minSecondaryPrice = this.minSecondaryPrice;
    dto.maxSecondaryPrice = this.maxSecondaryPrice;
    dto.packagedQuantities = this.packagedQuantities;
    dto.unitSizes = this.unitSizes;
    dto.productName = this.productName;
    dto.brandName = this.brandName;
    dto.strain = this.strain;
    dto.searchTerm = this.searchTerm;
    dto.minInventory = this.minInventory;
    dto.maxInventory = this.maxInventory;
    dto.pricingCodes = this.pricingCodes;
    dto.activeSale = this.activeSale;
    dto.requirePricingTier = this.requirePricingTier;
    dto.labelIds = this.labelIds;
    dto.badgeIds = this.badgeIds;
    dto.topTerpene = this.topTerpene;
    dto.minTotalTerpene = this.minTotalTerpene;
    dto.maxTotalTerpene = this.maxTotalTerpene;
    Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
      dto[`min${cannabinoid}`] = this[`min${cannabinoid}`];
      dto[`max${cannabinoid}`] = this[`max${cannabinoid}`];
    });
    Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
      dto[`min${secondaryCannabinoid}`] = this[`min${secondaryCannabinoid}`];
      dto[`max${secondaryCannabinoid}`] = this[`max${secondaryCannabinoid}`];
    });
    Object.values(Terpene)?.forEach(terpene => {
      const terperneKey = StringUtils.toPascalCase(terpene);
      this[`min${terperneKey}`] = this[`min${terperneKey}`];
      this[`max${terperneKey}`] = this[`max${terperneKey}`];
    });
    return dto;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  isCurated(): boolean {
    return this.locationId === -1;
  }

  hasProductPropertySet(): boolean {
    const productProperty = this.productType
      || this.variantType
      || this.classification
      || this.minPrice
      || this.maxPrice;
    return exists(productProperty);
  }

  hasCannabinoidPropertySet(): boolean {
    return [
      ...Object.values(PrimaryCannabinoid),
      ...Object.values(SecondaryCannabinoid)
    ].some(cannabinoid => {
      return exists(this[`min${cannabinoid}`] || this[`max${cannabinoid}`]);
    });
  }

  hasTerpenePropertySet(): boolean {
    const topTerpeneExists = exists(this.topTerpene);
    const totalTerpeneExists = exists(this.minTotalTerpene || this.maxTotalTerpene);
    const terpeneExists = Object.values(Terpene).some(terpene => {
      const terpeneKey = StringUtils.toPascalCase(terpene);
      return exists(this[`min${terpeneKey}`] || this[`max${terpeneKey}`]);
    });
    return topTerpeneExists || totalTerpeneExists || terpeneExists;
  }

  hasAdvancedPropertySet(): boolean {
    const advancedProperty = this.packagedQuantities
      || this.unitSizes
      || this.strain
      || this.searchTerm
      || this.minInventory
      || this.maxInventory
      || this.pricingCodes
      || this.activeSale
      || this.badgeIds?.length > 0
      || this.labelIds?.length > 0;
    return exists(advancedProperty);
  }

  getUniqueIdentifier(): string {
    let uniqueId = `${this.id}
      -${this.activeSale}
      -${this.classification}
      -${this.ignoredVariantIds?.sort()?.join(',')}
      -${this.locationId}
      -${this.topTerpene}
      -${this.minTotalTerpene}
      -${this.maxTotalTerpene}
      -${this.minInventory}
      -${this.maxInventory}
      -${this.minPrice}
      -${this.maxPrice}
      -${this.minSecondaryPrice}
      -${this.maxSecondaryPrice}
      -${this.name}
      -${this.productType}
      -${this.searchTerm}
      -${this.strain}
      -${this.variantType}
      -${this.advancedFilter}
      -${this.hidden}
      -${this.lastUpdated}
      -${this.itemCount}
      -${this.primarySort}
      -${this.secondarySort}
      -${this.usePurpose}
      -${this.packagedQuantities}
      -${this.unitSizes}
      -${this.productName}
      -${this.brandName}
      -${this.pricingCodes}
      -${this.requirePricingTier}
      -${this.isCompanySmartFilter}
      -${this.isCuratedSmartFilter}
      -${this.labelIds?.sort()?.join(',')}
      -${this.badgeIds?.sort()?.join(',')}`;
    Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
      uniqueId += `-${this[`min${cannabinoid}`]}`;
      uniqueId += `-${this[`max${cannabinoid}`]}`;
    });
    Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
      uniqueId += `-${this[`min${secondaryCannabinoid}`]}`;
      uniqueId += `-${this[`max${secondaryCannabinoid}`]}`;
    });
    Object.values(Terpene)?.forEach(terpene => {
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      uniqueId += `-${this[`min${pascalCaseTerpene}`]}`;
      uniqueId += `-${this[`max${pascalCaseTerpene}`]}`;
    });
    return uniqueId;
  }

}
