import { Injectable } from '@angular/core';
import { EditTemplateMenuSectionCombinedLoadingOptsViewModel } from '../../../../edit-template-menu-section/edit-template-combined-loading-opts-view-model';
import { EditTemplateMenuSectionViewModel } from '../../../../edit-template-menu-section/edit-template-menu-section-view-model';
import { EditSmartPlaylistMenuSectionViewModel } from '../../../../../../menu/components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-section/edit-smart-playlist-menu-section-view-model';

@Injectable()
export class EditTemplateSmartPlaylistMenuSectionCombinedLoadingOptsViewModel
  extends EditTemplateMenuSectionCombinedLoadingOptsViewModel {

  constructor(
    public override editTemplateMenuSectionViewModel: EditTemplateMenuSectionViewModel,
    public editProductLinkedLoopingMenuSectionViewModel: EditSmartPlaylistMenuSectionViewModel,
  ) {
    super(editTemplateMenuSectionViewModel, editProductLinkedLoopingMenuSectionViewModel);
  }

}
