import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EditCardStackMenuViewModel } from '../edit-card-stack-menu-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { EditCardStackMenuCombinedLoadingOptsViewModel } from '../edit-card-stack-menu-combined-loading-opts-view-model';
import { EditCardStackSectionViewModel } from '../edit-card-stack-section-view-model';

@Component({
  selector: 'app-edit-card-stack-menu-body',
  templateUrl: './edit-card-stack-menu-body.component.html',
  providers: [EditCardStackMenuCombinedLoadingOptsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackMenuBodyComponent extends BaseComponent {

  constructor(
    public viewModel: EditCardStackMenuViewModel,
    public sectionViewModel: EditCardStackSectionViewModel,
    public sharedLoadingState: EditCardStackMenuCombinedLoadingOptsViewModel
  ) {
    super();
  }

  @Input() mergeKey: string;
  @Input() templateMode: boolean = false;
  @Input() formHasErrors: boolean = true;
  @Input() unsavedChanges: boolean = false;
  @Input() formPristine: boolean = true;
  @Output() sortedVariantIds = new EventEmitter<string[]>(true);

}
