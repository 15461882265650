import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Menu } from '../../../models/menu/dto/menu';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'menuFromId'
})
export class MenuFromIdPipe implements PipeTransform {

  transform(id: string, menus$: Observable<Menu[]>): Observable<Menu> {
    return menus$.pipe(
      map(menus => menus?.find(menu => menu?.id === id))
    );
  }

}
