import { TypeDefinition } from './type-definition';

export enum CannabinoidDisplayType {
  Exact = 'exact',
  Range = 'range'
}

export class CannabinoidDisplayTypeDefinition extends TypeDefinition {

  override value: CannabinoidDisplayType;

}
