import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditStaticSectionMenuFooterComponent } from '../../shared/edit-static-section-menu-footer/edit-static-section-menu-footer.component';
import { EditSpotlightMenuViewModel } from '../edit-spotlight-menu-view-model';
import { EditSpotlightMenuCombinedLoadingOptsViewModel } from '../edit-spotlight-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-spotlight-menu-footer',
  templateUrl: '../../shared/edit-static-section-menu-footer/edit-static-section-menu-footer.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSpotlightMenuFooterComponent extends EditStaticSectionMenuFooterComponent {

  constructor(
    public override viewModel: EditSpotlightMenuViewModel,
    public override sharedLoadingState: EditSpotlightMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, sharedLoadingState);
  }

}
