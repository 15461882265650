<button
  class="bs-pill"
  [ngClass]="{ active: selected, hoverable: clickable && !disabled }"
  [ngStyle]="{ 'font-size': fontSize }"
  [disabled]="disabled"
  (click)="clicked()">
  <ng-content *ngIf="!imageSrc"></ng-content>
  <ng-container *ngIf="imageSrc"><img class="pill-image me-2" [src]="imageSrc" alt="pill-icon" /></ng-container>
  <ng-content *ngIf="!text"></ng-content>
  <ng-container *ngIf="text">{{ text }}</ng-container>
</button>
