import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';
import { PriceFormat } from '../../../models/utils/dto/price-format-type';

@Pipe({
  name: 'variantOriginalPrice'
})
export class VariantOriginalPricePipe implements PipeTransform {

  transform(locationId: number, variant: Variant, priceFormat: PriceFormat): number | null {
    if (locationId > -1) {
      return variant?.getPriceWithoutDiscounts(locationId, null, priceFormat);
    } else {
      return null;
    }
  }

}
