import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class AnimatorService {

  private _animateLocationPicker: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public animateLocationPicker$ = this._animateLocationPicker.asObservable();

  public animateLocationPicker(durationInMilliSeconds: number = 750) {
    this._animateLocationPicker.next(durationInMilliSeconds);
  }

}
