import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-note-text',
  templateUrl: './note-text.component.html',
  styleUrls: ['./note-text.component.scss']
})
export class NoteTextComponent {

  @Input() fontSize: string;
  @Input() margin: string;
  @Input() padding: string;

}
