<div [hidden]="!(viewModel.showBackgroundAsset$ | async)" class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Background Asset</p>
    <p class="bs-edit-section-description">
      <ng-container *ngIf="allowVideo">Upload an eye catching background image or video.</ng-container>
      <ng-container *ngIf="!allowVideo">Upload an eye catching background image.</ng-container>
    </p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8 menu-background-image-container">
    <app-loading
      [hidden]="!(viewModel.backgroundAssetLoading$ | async)"
      [options]="viewModel?.backgroundAssetLoadingOpts$ | async">
    </app-loading>

    <!-- Asset Preview -->
    <app-asset-preview
      (primaryButtonPressed)="handleDeleteBackgroundImage()"
      [asset]="viewModel.menuBackgroundAsset$ | async"
      [formPristine]="formPristine"
      (secondaryButtonPressed)="handleChangeBackgroundImage()"
      (refreshButtonPressed)="handleRefreshBackgroundImage()"
      [isDisabledByTemplates]="viewModel.isTemplatedMenu$ | async"
      [hidden]="!(viewModel.hasBackgroundAsset$ | async)"
      [options]="viewModel.backgroundPreviewOptions$ | async">
    </app-asset-preview>
    <!-- Upload Asset -->
    <app-upload-asset
      #backgroundImage
      [allowImage]="true"
      [allowVideo]="allowVideo"
      [formPristine]="formPristine"
      [isDisabledByTemplates]="viewModel.isTemplatedMenu$ | async"
      [hidden]="viewModel.hasBackgroundAsset$ | async"
      [maxAssets]="1"
      [parentHandler]="this">
    </app-upload-asset>
  </div>
</div>
<hr [hidden]="!(viewModel.showBackgroundAsset$ | async)" class="mt-24px" />
