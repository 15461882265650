import { Pipe, PipeTransform } from '@angular/core';
import { DisplayableProductCard } from '../edit-drive-thru-menu/menu-cards/displayable-product-card';
import { ProductCard } from '../edit-drive-thru-menu/models/cards/product-card';
import { Card } from '../edit-drive-thru-menu/models/cards/card';

@Pipe({
  name: 'castToProductCard'
})
export class CastToProductCardPipe implements PipeTransform {

  transform(value: Card): DisplayableProductCard {
    if (value instanceof ProductCard) {
      return value;
    }
    return null;
  }

}
