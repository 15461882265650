<div class="row mt-24px">
  <div class="col-4">
    <p class="bs-section-subtitle">Featured Variants</p>
    <div class="bs-section-subtext mt-16px">
      Featured variants will only appear on the menu if they have an image / video assigned to them.
    </div>

    <div fxLayout="row wrap" [style.gap.rem]="1">
      <lib-button-primary
        [style.margin-top.rem]="1"
        (buttonClicked)="addFeatureProductClicked()"
        [hidden]="!(sharedVM?.hasVariants$ | async)"
        [disabled]="(sharedVM?.isTemplatedMenu$ | async) || !formPristine"
        [disabledTooltip]="
          (sharedVM?.isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip) ||
          (formPristine | saveChangesTooltip)
        "
        [iconSrc]="'assets/icons/light/solid/plus.svg'">
        {{
          (this.viewModel?.sharedViewModel.lengthOfFeaturedVariantIds$ | async) < 1
            ? 'Add Featured Product'
            : 'Add More'
        }}
      </lib-button-primary>

      <lib-button-neutral
        [style.margin-top.rem]="1"
        *ngIf="sharedVM?.canReorder$ | async"
        (buttonClicked)="reorderProductsClicked()"
        [disabled]="(sharedVM?.isTemplatedMenu$ | async) || !formPristine"
        [disabledTooltip]="
          (sharedVM?.isTemplatedMenu$ | async | templatedMenuDisabledFormControlTooltip) ||
          (formPristine | saveChangesTooltip)
        ">
        Reorder Products
      </lib-button-neutral>
    </div>
  </div>
  <div class="col-8">
    <lib-reactive-form-group #form [mergeKey]="mergeKey" [bindTo]="viewModel?.sharedViewModel?.menu$ | async">
      <lib-reactive-form-row>
        <lib-reactive-form-text
          [inputName]="'featuredTitle'"
          [label]="'Featured Title'"
          [placeholder]="'Featured Title Here'"
          [inlineLabel]="false"
          [required]="false"
          [maxLength]="128"
          [disabled]="isTemplatedMenu"
          [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
          [tooltip]="'By default the product name appears as the title'"
          [bindingProperty]="'variantFeature.title'">
        </lib-reactive-form-text>
      </lib-reactive-form-row>
    </lib-reactive-form-group>
    <div class="featured-product-wrapper">
      <hr class="no-margin" />
      <!-- Featured Products -->
      <app-featured-product
        *ngFor="
          let v of sharedVM?.orderedVariantsOverTime$ | async;
          trackBy: viewModel.trackById;
          let i = index;
          let last = last
        "
        [mergeKey]="mergeKey"
        [variantId]="v.id"
        [variant]="v"
        [sharedViewModel]="sharedVM"
        [index]="i"
        [last]="last"
        [formPristine]="formPristine">
      </app-featured-product>
      <!-- Placeholder -->
      <div [hidden]="sharedVM?.hasVariants$ | async" class="no-featured-variants-placeholder mt-16px">
        <p class="bs-edit-section-title">
          Featured variants will appear in a list here. You can add, edit, and remove featured variants from here.
        </p>
        <lib-button-primary
          [disabled]="!formPristine"
          [disabledTooltip]="formPristine | saveChangesTooltip"
          (buttonClicked)="addFeatureProductClicked()">
          <img [src]="'assets/icons/light/outline/plus.svg'" alt="" />
          Add Featured Product
        </lib-button-primary>
      </div>
    </div>
  </div>
</div>
