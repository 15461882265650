<div class="asset-preview-container" [fxLayout]="(screenLessThan1200px$ | async) ? 'column' : 'row'">
  <div class="asset-preview" [fxFlex]="(screenLessThan1200px$ | async) ? 'auto' : '0'">
    <app-asset
      #appAsset
      [asset]="asset"
      [scaleFit]="true"
      [showControls]="true"
      [style.width.px]="assetWidth$ | async"
      [size]="options?.previewSize">
    </app-asset>
  </div>

  <div class="asset-info">
    <div class="asset-details">
      <div class="asset-name ellipsis-nowrap-text">
        {{ asset?.fileName | fileNameWithoutTimestampAndExtension }}
      </div>
      <div class="asset-detail">
        <span>Date Uploaded:</span>
        {{ getUploadDate() }}
      </div>
      <div class="asset-detail">
        <span>Asset Type:</span>
        {{ asset?.mediaType }}
      </div>
    </div>
    <div class="asset-preview-buttons" fxLayout="row wrap" [style.gap.rem]="1">
      <lib-button-neutral
        [throttleInMilliSec]="1000"
        [disabled]="!formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        (buttonClicked)="refreshButtonPressed.emit(this.asset?.fileName)"
        [iconSrc]="'assets/icons/dark/solid/refresh.svg'">
      </lib-button-neutral>

      <lib-button-neutral
        *ngIf="!!options?.tertiaryButtonText && options?.tertiaryButtonText !== ''"
        (buttonClicked)="tertiaryButtonPressed.emit()"
        [disabled]="isDisabledByTemplates || !formPristine"
        [disabledTooltip]="
          (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
        ">
        {{ options?.tertiaryButtonText }}
      </lib-button-neutral>

      <lib-button-neutral
        *ngIf="!!options?.secondaryButtonText && options?.secondaryButtonText !== ''"
        (buttonClicked)="secondaryButtonPressed.emit()"
        [disabled]="isDisabledByTemplates || !formPristine"
        [disabledTooltip]="
          (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
        ">
        {{ options?.secondaryButtonText }}
      </lib-button-neutral>

      <!--   Lay out primary button in both default and destructive states   -->

      <lib-button-destructive
        *ngIf="!!options?.primaryButtonText && options?.primaryButtonText !== '' && options?.primaryButtonDestructive"
        (buttonClicked)="primaryButtonPressed.emit()"
        [disabled]="isDisabledByTemplates || !formPristine"
        [disabledTooltip]="
          (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
        ">
        {{ options?.primaryButtonText }}
      </lib-button-destructive>

      <lib-button-neutral
        *ngIf="!!options?.primaryButtonText && options?.primaryButtonText !== '' && !options?.primaryButtonDestructive"
        (buttonClicked)="primaryButtonPressed.emit()"
        [disabled]="isDisabledByTemplates || !formPristine"
        [disabledTooltip]="
          (isDisabledByTemplates | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
        ">
        {{ options?.primaryButtonText }}
      </lib-button-neutral>
    </div>
  </div>
</div>
