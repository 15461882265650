import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFormErrorDisabledTooltip'
})
export class GetFormErrorDisabledTooltipPipe implements PipeTransform {

  transform(value: { [label:string]: string } | null | undefined): string | null {
    const errors = Object.entries(value || {});
    if (errors?.length > 0) {
      const [label, error] = errors[0];
      const errorCompare = error?.toLowerCase();
      const labelCompare = label?.toLowerCase();
      return errorCompare?.includes(labelCompare) ? error : `${label}: ${error}`;
    }
    return null;
  }

}
