import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AllProductsDataTableComponent } from '../all-products-data-table/all-products-data-table.component';
import { ProductPickerDataTableViewModel } from './product-picker-data-table-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';

@Component({
  selector: 'app-product-picker-data-table',
  templateUrl: './product-picker-data-table.component.html',
  styleUrls: ['./product-picker-data-table.component.scss'],
  providers: [
    {
      provide: AllProductsDataTableComponent,
      useExisting: forwardRef(() => ProductPickerDataTableComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPickerDataTableComponent extends AllProductsDataTableComponent
  implements OnChanges, OnDestroy {

  constructor(
    public override viewModel: ProductPickerDataTableViewModel
  ) {
    super(viewModel);
  }

  /**
   * flattenAndThenRemoveProductGroupings - if true, override product groups will be removed, and their sub products
   * will be displayed in the table. If false, override product groups will be displayed as line items in the table.
   */
  @Input() flattenAndThenRemoveProductGroupings: boolean = false;
  @Input() headerHeight: number = 0;
  @Input() hideLabelAndBadgeColumn: boolean = false;
  @Input() productSelectionOnly: boolean = false;
  @Input() showCurrentOverrideGroupColumn: boolean = false;
  @Output() loadingVariants = new EventEmitter<boolean>(true);
  @ViewChild('filterContainer') filterContainer: ElementRef<HTMLDivElement>;
  private _headerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public headerHeight$ = this._headerHeight as Observable<number>;
  private filterDivRO: ResizeObserver;
  private _filterDivHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public filterDivHeight$ = this._filterDivHeight as Observable<number>;

  public idsToBeAdded$ = this.viewModel.idsToBeAdded$;
  public setPreviouslyAddedProductIds = (ids: string[]) => this.viewModel?.setPreviouslyAddedProductIds(ids);
  public setPreviouslyAddedVariantIds = (ids: string[]) => this.viewModel?.setPreviouslyAddedVariantIds(ids);
  public clearSelections = (): void => this.viewModel?.clearSelections();
  public clearPreviouslyAddedIds = (): void => this.viewModel?.clearPreviouslyAddedIds();

  override setupBindings() {
    super.setupBindings();
    this.filterDivRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._filterDivHeight.next(entry.contentRect.height);
      }
    });
    this.filterDivRO.observe(this.filterContainer.nativeElement);
    this.viewModel.variantsFilteredByProductTypeVariantTypeStrainTypeRecentlyUpdated$
      .subscribeWhileAlive({
        owner: this,
        next: variants => this.loadingVariants.emit(variants === null)
      });
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.headerHeight) this._headerHeight.next(this.headerHeight);
    if (changes.hideLabelAndBadgeColumn) this.viewModel.connectToHideLabelAndBadgeColumn(this.hideLabelAndBadgeColumn);
    if (changes.productSelectionOnly) this.viewModel.connectToProductSelectionOnly(this.productSelectionOnly);
    if (changes.flattenAndThenRemoveProductGroupings) {
      this.viewModel.connectToFlattenAndThenRemoveProductGroupings(this.flattenAndThenRemoveProductGroupings);
    }
    if (changes.showCurrentOverrideGroupColumn) {
      this.viewModel.connectToShowCurrentOverrideGroupColumn(this.showCurrentOverrideGroupColumn);
    }
  }

  override ngOnDestroy() {
    this.filterDivRO?.disconnect();
  }

  setHideOutOfStockProducts(hideOutOfStockProducts: boolean) {
    this.filterFormComponent?.setHideOutOfStockProducts(hideOutOfStockProducts);
  }

}
