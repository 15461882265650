import { Component, Input } from '@angular/core';
import { DisplayableItemPreviewComponent } from '../displayable-item-preview/displayable-item-preview.component';
import { TemplatePreviewViewModel } from './template-preview-view-model';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';

@Component({
  selector: 'app-template-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: ['../displayable-item-preview/displayable-item-preview.component.scss'],
  providers: [TemplatePreviewViewModel]
})
export class TemplatePreviewComponent extends DisplayableItemPreviewComponent {

  @Input() override displayableItem: MenuTemplate;

  constructor(
    public override viewModel: TemplatePreviewViewModel
  ) {
    super(viewModel);
  }

}
