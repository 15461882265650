import { Injectable } from '@angular/core';
import { EditCardStackMenuCombinedLoadingOptsViewModel } from '../edit-card-stack-menu/edit-card-stack-menu-combined-loading-opts-view-model';
import { EditLabelStackMenuViewModel } from './edit-label-stack-menu-view-model';
import { EditLabelStackSectionViewModel } from './edit-label-stack-section-view-model';

@Injectable()
export class EditLabelStackMenuCombinedLoadingOptsViewModel
  extends EditCardStackMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editLabelStackMenuViewModel: EditLabelStackMenuViewModel,
    protected editLabelStackSectionViewModel: EditLabelStackSectionViewModel
  ) {
    super(editLabelStackMenuViewModel, editLabelStackSectionViewModel);
  }

}
