import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { PropertyLevel } from '../../../../../../models/enum/shared/property-level.enum';
import { PrimaryCannabinoid } from '../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { ArrayUtils } from '../../../../../../utils/array-utils';
import { CannabinoidFilter } from '../../../../models/cannabinoid-filter';
import { CustomizationFilter } from '../../../../models/customization-filter';
import { CustomizableData } from '../../../../../../models/enum/shared/customizable-data.enum';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { InventoryProvider } from '../../../../../../models/utils/dto/inventory-provider-type';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { TerpeneFilter } from '../../../../models/terpene-filter';
import { exists } from '../../../../../../functions/exists';

// Provided by Logged In Scope
@Injectable()
export class SelectBulkChangePropertiesViewModel extends BaseViewModel {

  constructor(
    private cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel,
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel
  ) {
    super();
    this.letInternalPipesFlow();
  }

  /* ****************************** Data Streams ******************************** */

  public readonly isCompanyAdmin$ = this.userDomainModel.isCompanyAdmin$;
  public readonly inventoryProvider$ = this.companyDomainModel.inventoryProvider$;

  public readonly syncPOSCannabinoid$ = this.companyDomainModel.syncPOSCannabinoid$;
  public readonly syncPOSCannabinoidBannerMessage$ = this.inventoryProvider$.pipe(
    map(provider => SelectBulkChangePropertiesViewModel.getSyncPOSCannabinoidBannerMessage(provider))
  );

  public readonly syncPOSTerpenes$ = this.companyDomainModel.syncPOSTerpene$;
  public readonly syncPOSTerpenesBannerMessage$ = this.inventoryProvider$.pipe(
    map(provider => SelectBulkChangePropertiesViewModel.getSyncPOSTerpenesBannerMessage(provider))
  );

  public readonly showBanner$ = combineLatest([this.syncPOSCannabinoid$, this.syncPOSTerpenes$]).pipe(
    map(([syncPOSCannabinoid, syncPOSTerpene]) => syncPOSCannabinoid || syncPOSTerpene)
  );

  public readonly bannerMessage$: Observable<string> = combineLatest([
    this.syncPOSCannabinoid$,
    this.syncPOSTerpenes$,
    this.syncPOSCannabinoidBannerMessage$,
    this.syncPOSTerpenesBannerMessage$
  ]).pipe(
    map(([syncPOSCannabinoid, syncPOSTerpene, cannabinoidMessage, terpeneMessage]) => {
      switch (true) {
        case syncPOSCannabinoid && syncPOSTerpene:
          return `<ul class="no-margin"><li>${cannabinoidMessage}</li><li>${terpeneMessage}</li></ul>`;
        case syncPOSCannabinoid:
          return cannabinoidMessage;
        case syncPOSTerpene:
          return terpeneMessage;
        default:
          return null;
      }
    })
  );

  /* *** Cannabinoids *** */

  private readonly cannabinoidChangePipes: Map<string, BehaviorSubject<string>> = new Map();
  private readonly _cannabinoidClicked = new Subject<[string|null, string|null]>();
  private readonly cannabinoidClicked$ = this._cannabinoidClicked as Observable<[string|null, string|null]>;

  public readonly locationCannabinoidOptions$ = combineLatest([
    of(SelectBulkChangePropertiesViewModel.getLocationCannabinoids()),
    this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoids$
  ]).pipe(
    map(([locationCannabinoids, enabledSecondaryCannabinoids]) => {
      return [
        ...locationCannabinoids,
        ...(enabledSecondaryCannabinoids?.map(c => new CannabinoidFilter(PropertyLevel.Location, c?.value)) || [])
      ];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly companyCannabinoidOptions$ = combineLatest([
    of(SelectBulkChangePropertiesViewModel.getCompanyCannabinoids()),
    this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoids$
  ]).pipe(
    map(([companyCannabinoids, enabledSecondaryCannabinoids]) => {
      return [
        ...companyCannabinoids,
        ...(enabledSecondaryCannabinoids?.map(c => new CannabinoidFilter(PropertyLevel.Company, c?.value)) || [])
      ];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly locationAndCompanyCannabinoidsOptions$ = combineLatest([
    this.locationCannabinoidOptions$,
    this.companyCannabinoidOptions$
  ]).pipe(
    map(SelectBulkChangePropertiesViewModel.getInterlacedCannabinoids)
  );

  public readonly locationPropertyTitle$ = this.locationDomainModel.locationName$.pipe(map(n => `${n} (Location)`));
  public readonly companyPropertyTitle$ = this.companyDomainModel.companyName$.pipe(map(n => `${n} (Company)`));

  private readonly cannabinoidOptions$: Observable<CannabinoidFilter[]> = this.isCompanyAdmin$.pipe(
    switchMap(isCompanyAdmin => this.filterCannabinoids(isCompanyAdmin))
  );

  public readonly cannabinoidChangePipes$ = this.cannabinoidOptions$.pipe(
    withLatestFrom(this.syncPOSCannabinoid$),
    switchMap(([cannabinoidFilters, syncFromPOS]) => this.getCannabinoidChangePipes(cannabinoidFilters, syncFromPOS)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly selectedCannabinoids$: Observable<string[]> = this.cannabinoidChangePipes$.pipe(
    switchMap(changePipes => combineLatest([...(changePipes?.values() || [])])),
    map(values => values?.filterNulls() ?? []),
    debounceTime(50),
  );

  public readonly cannabinoidsDisabledTooltip$ = this.inventoryProvider$.pipe(
    map(provider => `Manage by ${provider} POS`)
  );

  /* *** Customization *** */

  private readonly customizationChangePipes: Map<string, BehaviorSubject<string>> = new Map();
  private readonly _customizationClicked = new Subject<[string|null, string|null]>();
  private readonly customizationClicked$ = this._customizationClicked as Observable<[string|null, string|null]>;
  public readonly locationCustomizationOptions$ = of(SelectBulkChangePropertiesViewModel.getLocationCustomization());
  public readonly companyCustomizationOptions$ = of(SelectBulkChangePropertiesViewModel.getCompanyCustomization());

  private readonly locationAndCompanyCustomizationOptions$ = combineLatest([
    this.locationCustomizationOptions$,
    this.companyCustomizationOptions$
  ]).pipe(
    map(SelectBulkChangePropertiesViewModel.getInterlacedCustomization)
  );

  public readonly customizationOptions$: Observable<CustomizationFilter[]> = this.isCompanyAdmin$.pipe(
    switchMap(isCompanyAdmin => this.filterCustomization(isCompanyAdmin))
  );

  public readonly customizationChangePipes$ = this.customizationOptions$.pipe(
    switchMap(customizationOptions => this.getCustomizationChangePipes(customizationOptions)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly selectedCustomization$: Observable<string[]> = this.customizationChangePipes$.pipe(
    switchMap(changePipes => combineLatest([...(changePipes?.values() || [])])),
    map(values => values?.filterNulls() ?? []),
  );

  /* *** Terpenes *** */

  private readonly terpeneChangePipes: Map<string, BehaviorSubject<string>> = new Map();
  private readonly _terpeneClicked = new Subject<[string|null, string|null]>();
  private readonly terpeneClicked$ = this._terpeneClicked as Observable<[string|null, string|null]>;
  private readonly locationTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpenes$;
  private readonly companyTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpenes$;

  public readonly locationTerpeneOptions$ = this.locationTerpenes$.pipe(
    map(terpenes => terpenes?.map(t => new TerpeneFilter(PropertyLevel.Location, t?.value)))
  );

  public readonly companyTerpeneOptions$ = this.companyTerpenes$.pipe(
    map(terpenes => terpenes?.map(t => new TerpeneFilter(PropertyLevel.Company, t?.value)))
  );

  private readonly locationAndCompanyTerpeneOptions$ = combineLatest([
    this.locationTerpeneOptions$,
    this.companyTerpeneOptions$
  ]).pipe(
    map(SelectBulkChangePropertiesViewModel.getInterlacedTerpenes)
  );

  private readonly terpeneOptions$ = this.isCompanyAdmin$.pipe(
    switchMap(isCompanyAdmin => this.filterTerpenes(isCompanyAdmin))
  );

  public readonly terpeneChangePipes$: Observable<Map<string, BehaviorSubject<string>>> = this.terpeneOptions$.pipe(
    withLatestFrom(this.syncPOSTerpenes$),
    switchMap(([terpeneOptions, syncFromPOS]) => this.getTerpeneChangePipes(terpeneOptions, syncFromPOS)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly selectedTerpenes$: Observable<string[]> = this.terpeneChangePipes$.pipe(
    switchMap(changePipes => combineLatest([...(changePipes?.values() || [])])),
    map(values => values?.filterNulls() ?? []),
    debounceTime(50),
  );

  public readonly terpeneDisabledTooltip$ = this.inventoryProvider$.pipe(
    map(provider => `Manage by ${provider} POS`)
  );

  /* *************************** Private Static Getters ******************************* */

  private static getLocationCannabinoids(): CannabinoidFilter[] {
    return Object
      .values(PrimaryCannabinoid)
      .map(primaryCannabinoid => new CannabinoidFilter(PropertyLevel.Location, primaryCannabinoid));
  }

  private static getCompanyCannabinoids(): CannabinoidFilter[] {
    return Object
      .values(PrimaryCannabinoid)
      .map(primaryCannabinoid => new CannabinoidFilter(PropertyLevel.Company, primaryCannabinoid));
  }

  private static getLocationCustomization(): CustomizationFilter[] {
    return Object.values(CustomizableData).map(value => new CustomizationFilter(PropertyLevel.Location, value));
  }

  private static getCompanyCustomization(): CustomizationFilter[] {
    return Object.values(CustomizableData).map(value => new CustomizationFilter(PropertyLevel.Company, value));
  }

  private static getInterlacedCannabinoids(
    [locationCannabinoids, companyCannabinoids]: [CannabinoidFilter[], CannabinoidFilter[]]
  ): CannabinoidFilter[] {
    return ArrayUtils.interlace(locationCannabinoids, companyCannabinoids) as CannabinoidFilter[];
  }

  private static getInterlacedCustomization(
    [locationCustomization, companyCustomization]: [CustomizationFilter[], CustomizationFilter[]]
  ): CustomizationFilter[] {
    return ArrayUtils.interlace(locationCustomization, companyCustomization) as CustomizationFilter[];
  }

  private static getInterlacedTerpenes(
    [locationTerpenes, companyTerpenes]: [TerpeneFilter[], TerpeneFilter[]]
  ): TerpeneFilter[] {
    return ArrayUtils.interlace(locationTerpenes, companyTerpenes) as TerpeneFilter[];
  }

  private static getSyncPOSCannabinoidBannerMessage(provider: InventoryProvider): string {
    return 'Updating cannabinoid values is managed '
      + `through ${provider} POS since the ‘Sync THC/CBD `
      + 'from POS’ feature is enabled.';
  }

  private static getSyncPOSTerpenesBannerMessage(provider: InventoryProvider): string {
    return 'Updating terpene values is managed '
      + `through ${provider} POS since the ‘Sync Terpenes `
      + 'from POS’ feature is enabled.';
  }

  /* ************************* Flowing Internal Pipes ************************* */

  private letInternalPipesFlow(): void {
    // listen for cannabinoid check box changes
    this.cannabinoidClicked$.pipe(withLatestFrom(this.cannabinoidChangePipes$)).subscribeWhileAlive({
      owner: this,
      next: ([[key, value], cannabinoidChangePipes]) => {
        if (exists(key)) cannabinoidChangePipes?.get(key)?.next(value);
      }
    });
    // listen for customization check box changes
    this.customizationClicked$.pipe(withLatestFrom(this.customizationChangePipes$)).subscribeWhileAlive({
      owner: this,
      next: ([[key, value], customizationChangePipes]) => {
        if (exists(key)) customizationChangePipes?.get(key)?.next(value);
      }
    });
    // listen for terpene check box changes
    this.terpeneClicked$.pipe(withLatestFrom(this.terpeneChangePipes$)).subscribeWhileAlive({
      owner: this,
      next: ([[key, value], terpeneChangePipes]) => {
        if (exists(key)) terpeneChangePipes?.get(key)?.next(value);
      }
    });
    // listen to sync POS cannabinoid - clear all cannabinoid options if sync changes to true.
    this.cannabinoidChangePipes$.pipe(
      filter(changePipes => changePipes?.size > 0),
      switchMap(() => this.syncPOSCannabinoid$),
      switchMap(sync => iif(() => sync, this.cannabinoidChangePipes$, of(new Map())))
    ).subscribeWhileAlive({
      owner: this,
      next: changePipes => {
        if (changePipes?.size > 0) changePipes?.forEach(changePipe => changePipe?.next(null));
      }
    });
    // listen to sync POS terpenes - clear all terpene options if sync changes to true.
    this.terpeneChangePipes$.pipe(
      filter(changePipes => changePipes?.size > 0),
      switchMap(() => this.syncPOSTerpenes$),
      switchMap(sync => iif(() => sync, this.terpeneChangePipes$, of(new Map())))
    ).subscribeWhileAlive({
      owner: this,
      next: changePipes => {
        if (changePipes?.size > 0) changePipes?.forEach(changePipe => changePipe?.next(null));
      }
    });
  }

  /* ****************************** Connectors ******************************* */

  public cannabinoidClicked = (key: string, value: string|null): void => {
    this.syncPOSCannabinoid$.once(syncFromPOS => {
      const data: [string, string] = syncFromPOS ? [key, null] : [key, value];
      this._cannabinoidClicked.next(data);
    });
  };

  public terpeneClicked = (key: string, val: string|null): void => {
    this.syncPOSTerpenes$.once(syncFromPOS => {
      const data: [string, string] = syncFromPOS ? [key, null] : [key, val];
      this._terpeneClicked.next(data);
    });
  };

  public customizationClicked = (key: string, val: string|null): void => this._customizationClicked.next([key, val]);

  /* *********************** Private Pipe Pieces ***************************** */

  private filterCannabinoids(admin: boolean): Observable<CannabinoidFilter[]> {
    return iif(() => admin, this.locationAndCompanyCannabinoidsOptions$, this.locationCannabinoidOptions$);
  }

  private filterCustomization(admin: boolean): Observable<CustomizationFilter[]> {
    return iif(() => admin, this.locationAndCompanyCustomizationOptions$, this.locationCustomizationOptions$);
  }

  private filterTerpenes(admin: boolean): Observable<TerpeneFilter[]> {
    return iif(() => admin, this.locationAndCompanyTerpeneOptions$, this.locationTerpeneOptions$);
  }

  private getCannabinoidChangePipes(
    opts: CannabinoidFilter[],
    syncPOSCannabinoid: boolean
  ): Observable<Map<string, BehaviorSubject<string>>> {
    opts.forEach(option => {
      const name = option?.name;
      if (exists(name) && !this.cannabinoidChangePipes.has(name)) {
        let pipe: BehaviorSubject<string>;
        if (option?.level === PropertyLevel.Location) {
          pipe = new BehaviorSubject<string>(syncPOSCannabinoid ? null : option?.name);
        } else {
          pipe = new BehaviorSubject<string>(null);
        }
        this.cannabinoidChangePipes.set(name, pipe);
      }
      if (syncPOSCannabinoid) {
        this.cannabinoidChangePipes?.get(name)?.next(null);
      }
    });
    return of(this.cannabinoidChangePipes);
  }

  private getTerpeneChangePipes(
    opts: TerpeneFilter[],
    syncPOSTerpenes: boolean
  ): Observable<Map<string, BehaviorSubject<string>>> {
    opts.forEach(option => {
      const optionWithCamelTerpene = option?.getNameWithCamelizedTerpene();
      if (exists(optionWithCamelTerpene) && !this.terpeneChangePipes.has(optionWithCamelTerpene)) {
        let pipe: BehaviorSubject<string>;
        if (option?.level === PropertyLevel.Location) {
          pipe = new BehaviorSubject<string>(syncPOSTerpenes ? null : optionWithCamelTerpene);
        } else {
          pipe = new BehaviorSubject<string>(null);
        }
        this.terpeneChangePipes.set(optionWithCamelTerpene, pipe);
      }
      if (syncPOSTerpenes) {
        this.terpeneChangePipes?.get(optionWithCamelTerpene)?.next(null);
      }
    });
    return of(this.terpeneChangePipes);
  }

  private getCustomizationChangePipes(opts: CustomizationFilter[]): Observable<Map<string, BehaviorSubject<string>>> {
    opts.forEach(option => {
      if (!this.customizationChangePipes.has(option?.name)) {
        let pipe: BehaviorSubject<string>;
        if (option?.level === PropertyLevel.Location) {
          pipe = new BehaviorSubject<string>(option?.name);
        } else {
          pipe = new BehaviorSubject<string>(null);
        }
        this.customizationChangePipes.set(option?.name, pipe);
      }
    });
    return of(this.customizationChangePipes);
  }

  /* *********************** Public Stream Getters ***************************** */

  public getUpdateCannabinoidPipe(key: string): Observable<string> {
    return this.cannabinoidChangePipes$.pipe(
      switchMap(pipes => exists(pipes?.get(key)) ? pipes.get(key) : of(undefined)),
      debounceTime(100),
      distinctUntilChanged()
    );
  }

  public getUpdateTerpenePipe(key: string): Observable<string> {
    return this.terpeneChangePipes$.pipe(
      switchMap(pipes => exists(pipes?.get(key)) ? pipes.get(key) : of(undefined)),
      debounceTime(100),
      distinctUntilChanged()
    );
  }

  public getUpdateCustomizationPipe(key: string): Observable<string> {
    return this.customizationChangePipes$.pipe(
      switchMap(pipes => exists(pipes?.get(key)) ? pipes.get(key) : of(undefined)),
      debounceTime(100),
      distinctUntilChanged()
    );
  }

}
