import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCannabinoidTerpeneValueFromMap'
})
export class GetCannabinoidTerpeneValueFromMapPipe implements PipeTransform {

  transform(cannabinoidOrTerpeneMap: Map<string, string>, key: string): string {
    return cannabinoidOrTerpeneMap?.get(key);
  }

}
