import { Component, ComponentFactoryResolver, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TabContentContainerDirective } from './tab-content-container.directive';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { Tabbable } from '../../../../../models/protocols/tabbable';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss']
})
export class TabContentComponent implements OnInit, OnChanges {

  @Input() tab: TabBarItem;
  @Input() index: number;
  @Input() selectedTabIndex: number;
  @Input() showContent: boolean = true;
  @Input() useDefaultHeight: boolean = true;
  @ViewChild(TabContentContainerDirective, {static: true}) contentContainer: TabContentContainerDirective;
  private componentRef: ComponentRef<Tabbable>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    const tab: TabBarItem = this.tab;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<Tabbable>(tab.componentType);
    this.componentRef = this.contentContainer.viewContainerRef.createComponent(componentFactory);
    (this.componentRef.instance).iBelongTo = `I'm a child of ${tab.title}`;
    this.checkIfActivated();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTab?.previousValue !== changes.selectedTab?.currentValue) this.checkIfActivated();
  }

  private checkIfActivated() {
    if (this.selectedTabIndex === this.index) {
      (this.componentRef.instance)?.tabActivated?.next(true);
    }
  }

}
