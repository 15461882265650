import { Pipe, PipeTransform } from '@angular/core';
import { SectionSortType } from '../../../models/utils/dto/section-sort-type';

@Pipe({
  name: 'decodeSectionSortingIntoReadableString'
})
export class DecodeSectionSortingIntoReadableStringPipe implements PipeTransform {

  transform(sectionSortTypes: SectionSortType[]): string | null {
    if (!sectionSortTypes?.length) return null;
    return this.buildHTML(sectionSortTypes);
  }

  private buildSpan(sectionSortType: SectionSortType): string {
    return `<span>` + sectionSortType?.nameWithoutDashes() + `</span>`;
  }

  private buildHTML(sectionSortTypes: SectionSortType[]): string {
    let text = 'Sorted by ';
    sectionSortTypes?.forEach((sortingType, index) => {
      text += this.buildSpan(sortingType);
      if (index < (sectionSortTypes?.length - 1)) text += ` then by `;
    });
    return text;
  }

}
