const overflowVideoList = {
    landscape: {
      Default:
        {
          none:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/landscape-menu-none.mp4',
          steadyscroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/landscape-menu-scroll.mp4',
          scroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/landscape-menu-snap.mp4',
          paging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/'
            + 'landscape-menu-transition.mp4',
          sectionscroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/landscape-section-snap.mp4',
          sectionpaging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/'
            + 'landscape-section-transition.mp4',
        },
      FeatCatCarousel:
        {
          none:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_carousel/'
            + 'landscape-feat-cat-carousel-none.mp4',
          scroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_carousel/'
            + 'landscape-feat-cat-carousel-snap.mp4',
          paging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_carousel/'
            + 'landscape-feat-cat-carousel-transition.mp4',
        },
      FeatCatNonCarousel:
        {
          none:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'landscape-feat-cat-non-carousel-none.mp4',
          steadyscroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'landscape-feat-cat-non-carousel-scroll.mp4',
          scroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'landscape-feat-cat-non-carousel-snap.mp4',
          paging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'landscape-feat-cat-non-carousel-transition.mp4',
        }
    },
    portrait: {
      Default:
        {
          none:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/portrait-menu-none.mp4',
          steadyscroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/portrait-menu-scoll.mp4',
          scroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/portrait-menu-snap.mp4',
          paging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/'
            + 'portrait-menu-transition.mp4',
          sectionscroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/portrait-section-snap.mp4',
          sectionpaging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/product_menu/'
            + 'portrait-section-transition.mp4',
        },
      FeatCatCarousel:
        {
          none:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_carousel/'
            + 'portrait-feat-cat-carousel-none.mp4',
          scroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_carousel/'
            + 'portrait-feat-cat-carousel-snap.mp4',
          paging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_carousel/'
            + 'portrait-feat-cat-carousel-transition.mp4',
        },
      FeatCatNonCarousel:
        {
          none:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'portrait-feat-cat-non-carousel-none.mp4',
          steadyscroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'portrait-feat-cat-non-carousel-scroll.mp4',
          scroll:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'portrait-feat-cat-non-carousel-snap.mp4',
          paging:
            'https://budsense-videos.s3.us-west-2.amazonaws.com/menu_overflow/featured_cat_non_carousel/'
            + 'portrait-feat-cat-non-carousel-transition.mp4',
        }
    },
  };

export default overflowVideoList;
