import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { ColumnOptionsFormViewModel } from './column-options-form-view-model';
import { ReactiveFormDropDownComponent, ReactiveFormNumberComponent, ReactiveFormTextComponent } from '@mobilefirstdev/reactive-form';
import { SectionColumnConfigDefaultState } from '../../../../../../../models/utils/dto/section-column-config-default-state-type';
import { SectionColumnConfigAssetData, SectionColumnConfigDataValue, SectionColumnConfigSecondaryPricingData, SectionColumnConfigStrainTypeData } from '../../../../../../../models/utils/dto/section-column-config-data-value-type';
import { SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Component({
  selector: 'app-column-options-form',
  templateUrl: './column-options-form.component.html',
  styleUrls: ['./column-options-form.component.scss'],
  providers: [ColumnOptionsFormViewModel]
})
export class ColumnOptionsFormComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: ColumnOptionsFormViewModel
  ) {
    super();
  }

  @ViewChild('columnDefaultState') columnDefaultStateDropdown: ReactiveFormDropDownComponent;
  @ViewChild('columnName') columnNameInput: ReactiveFormTextComponent;
  @ViewChild('columnDataValue') columnDataValueDropdown: ReactiveFormDropDownComponent;
  @ViewChild('columnWidth') columnWidthInput: ReactiveFormNumberComponent;

  tabActivated: BehaviorSubject<boolean>;
  public expanded: boolean = false;

  changeToDefaultState(newState: SectionColumnConfigDefaultState) {
    if (newState === SectionColumnConfigDefaultState.On || newState === SectionColumnConfigDefaultState.Auto) {
      const themeDefaults = this.viewModel?.themeColumnConfig?.get(this.viewModel.columnSectionKey);
      const columnWidth = this.columnWidthInput.getMyValue();
      this.columnWidthInput
        .getSelfAsFormItem()
        .setValue(columnWidth || themeDefaults?.columnWidth || themeDefaults?.minColumnWidth || 10);
      const columnName = this.columnNameInput.getMyValue();
      this.columnNameInput
        .getSelfAsFormItem()
        .setValue(columnName || themeDefaults?.columnName);
      if (!!this.columnDataValueDropdown) {
        const columnDataValue = this.columnDataValueDropdown.getMyValue();
        const defaultDropdownValue = this.getDefaultDataValue(this.viewModel.columnSectionKey);
        this.columnDataValueDropdown
          .getSelfAsFormItem()
          .setValue(columnDataValue || themeDefaults?.dataValue || defaultDropdownValue);
      }
    } else {
      this.viewModel.connectToChanges({defaultState: newState});
    }
    this.viewModel.connectToChangedDefaultState(newState);
  }

  getDefaultDataValue(columnConfigKey: SectionColumnConfigKey): SectionColumnConfigDataValue {
    switch (columnConfigKey) {
      case SectionColumnConfigKey.Asset:
        return SectionColumnConfigAssetData.Contain;
      case SectionColumnConfigKey.SecondaryPrice:
        return SectionColumnConfigSecondaryPricingData.SecondaryPrice;
      case SectionColumnConfigKey.StrainType:
        return SectionColumnConfigStrainTypeData.StrainTypeSymbol;
      default:
        return null;
    }
  }

  changeToColumnWidth(newWidth: number) {
    const defaultStateFromForm = this.columnDefaultStateDropdown.getMyValue() as SectionColumnConfigDefaultState;
    this.viewModel.connectToChanges({defaultState: defaultStateFromForm, columnWidth: newWidth});
  }

}
