<!-- Color Palette -->
<div class="row" [style.display]="'relative'">
  <!-- First 3rd -->
  <div class="col-4">
    <p class="bs-semi-bold f16px">{{ rowTitle }}</p>
    <p class="bs-regular f14px">{{ rowDescription }}</p>
  </div>
  <!-- Last 2/3rds -->
  <div class="col-8">
    <div class="color-palette-container" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <div
        *ngFor="let color of viewModel.companyColorPaletteClone$ | async"
        class="color-square"
        fxFlex="0 1 3.5rem"
        [style.background-color]="color">
        <app-remove-button
          appThrottleClick
          class="remove-button"
          [width]="'1rem'"
          [height]="'1rem'"
          [throttleInMilliSec]="1000"
          (throttleClick)="viewModel.remove(color)">
        </app-remove-button>
      </div>
      <app-plus-button
        [popper]="popperContent"
        [popperShowOnStart]="false"
        [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
        [popperModifiers]="popperModifier"
        [popperHideOnClickOutside]="true"
        [popperHideOnScroll]="true"
        [popperPreventOverflow]="true"
        [popperPositionFixed]="true"
        [popperApplyClass]="'settings-color-picker-popper'"
        [popperStyles]="popperStyles"
        [popperPlacement]="ClientTypes.NgxPopperjsPlacements.TOP"
        [borderRadius]="'0.3rem'"
        [width]="'3.5rem'"
        [height]="'3.5rem'">
      </app-plus-button>
      <popper-content #popperContent [style.z-index]="1">
        <color-sketch
          #colorPicker
          [style.display]="'block'"
          [color]="viewModel.selectedColor"
          [presetColors]="viewModel.companyColorPaletteClone$ | async"
          [disableAlpha]="true"
          (onChange)="viewModel.selectedColor = $event.color.hex">
        </color-sketch>
        <button
          appThrottleClick
          class="bs-button preferred-button add-color-button"
          [throttleInMilliSec]="1000"
          (throttleClick)="viewModel.add()">
          Add Color
        </button>
      </popper-content>
    </div>
  </div>
</div>
