import { PendingDisplay } from './pending-display';
import { TemplateStatus } from '../enum/template-status.enum';
import { DisplayableItem } from '../../../views/shared/components/displayable-content/displayable-item-container/displayable-item-preview/displayable-item';
import { BaseDisplay } from '../../display/dto/base-display';
import { MenuTemplate } from './menu-template';
import { Size } from '../../shared/size';
import { Tag } from '../../menu/dto/tag';
import { DisplayableSubItem } from '../../shared/displayable-subitem';
import { environment } from '../../../../environments/environment';
import { SortUtils } from '../../../utils/sort-utils';

export class TemplateCollection extends BaseDisplay implements DisplayableItem {

  public active: boolean;
  public pendingDisplay: PendingDisplay;
  public requiredDisplayIds: string[];
  public activeDisplayIds: string[];
  public status: TemplateStatus;
  public templateIds: string[];
  public templates: MenuTemplate[];
  public tag: string;

  constructor() {
    super();
    this.displaySize = new Size();
  }

  override onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    this.pendingDisplay = Deserialize?.instanceOf(PendingDisplay, this.pendingDisplay);
    this.activeDisplayIds = Array.from(this.activeDisplayIds || []);
    this.templateIds = Array.from(this.templateIds || []);
    this.templates = Deserialize?.arrayOf(MenuTemplate, this.templates) ?? [];
    this.setTemplatesPriorityFromMap();
    this.templates?.sort(SortUtils.sortMenusByDisplayPriority);
  }

  // Expected go model
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/TemplateCollectionDTO.go
  override onSerialize(): TemplateCollection {
    const dto = Object.assign(new TemplateCollection(), super.onSerialize());
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.tag = this.tag;
    dto.status = this.status;
    dto.pendingDisplay = this.pendingDisplay;
    dto.templateIds = this.templateIds;
    dto.requiredDisplayIds = this.requiredDisplayIds;
    return dto;
  }

  getMenus(): MenuTemplate[] {
    return this.templates ?? [];
  }

  getMenuIdsInOrder(): string[] {
    return this.templateIds
      ?.shallowCopy()
      ?.sort((a, b) => this.options?.rotationOrder?.get(a) - this.options?.rotationOrder?.get(b));
  }

  static getUniqueTags(collections: TemplateCollection[]): Tag[] {
    return collections
      ?.map(map => (!!map?.tag?.trim() ? new Tag(map.tag) : null))
      ?.uniqueByProperty('value')
      ?.filterNulls();
  }

  private setTemplatesPriorityFromMap() {
    this.templates?.forEach((template) => {
      template.displayPriority = this.options?.rotationOrder?.get(template.id);
    });
  }

  public override generateDisplayUrl(locationId: number): string {
    const env = (environment.production && environment.environmentName !== 'rc')
      ? ''
      : `${environment.environmentName}.`;
    return `https://${env}display.mybudsense.com/#/templatecollection/${locationId}/${this.id}`;
  }

  isPublished(): boolean {
    return this.status === TemplateStatus.Published;
  }

  getActiveTemplatesAtLocation(locationId: number): MenuTemplate[] {
    return this.templates?.filter(template => template?.isActiveAtLocation(locationId)) ?? [];
  }

  /* *************************** Displayable Item Interface *************************** */

  displayableItemPreviewContentIds(locationId: number): string[] {
    return this.templates?.map(template => template?.id) ?? [];
  }

  displayableItemShouldShowSeeMoreCard(): boolean {
    return false;
  }

  displayableItemTotalCount(): number {
    return this.templates?.length ?? 0;
  }

  displayableItemContainsStackedContent(): boolean {
    return false;
  }

  displayableItemIsActive(): boolean {
    return this.status === TemplateStatus.Published;
  }

  displayableItemHasSmartFilters(): boolean {
    return false;
  }

  displayableItemActiveText(): string {
    return 'Published';
  }

  displayableItemInactiveText(): string {
    return 'Draft';
  }

  displayableItemShowActiveBadge(): boolean {
    return true;
  }

  displayableItemDeployedCountTooltipText(): string {
    return 'Number of displays this collection is active on.';
  }

  displayableItemDeployedCount(): number {
    return this.activeDisplayIds?.length;
  }

  displayableItemShowDeployedCountIndicator(): boolean {
    return true;
  }

  displayableItemDeployedCountIcon(): string {
    return 'assets/icons/dark/solid/device-mobile.svg';
  }

  displayableItemSmartFilterIndicatorTooltip(): string {
    return '';
  }

  displayableItemSubtitle(): string {
    return 'Menu Templates';
  }

  displayableItemSubItemList(): DisplayableSubItem[] {
    const icon = 'assets/icons/dark/solid/template.svg';
    const subItemArray: DisplayableSubItem[] = [];
    const templateNames = this.templates
      .sort((a, b) => a?.displayPriority - b?.displayPriority)
      .map(menu => menu?.name);
    if (templateNames?.length > 5) {
      const truncatedTemplateNames = templateNames.slice(0, 5);
      truncatedTemplateNames?.forEach(name => subItemArray.push(new DisplayableSubItem(name, icon)));
      subItemArray.push(new DisplayableSubItem(`+${templateNames.length - 5} more`, null));
      return subItemArray;
    } else {
      templateNames?.forEach(name => subItemArray.push(new DisplayableSubItem(name, icon)));
      return subItemArray ?? [];
    }
  }

  displayableItemIsTemplatedMenu(): boolean {
    return false;
  }

  /* ********************************************************************************** */

  override getUniqueIdentifier(...opts: any): string {
    return super.getUniqueIdentifier() + `-
      ${this.active}-
      ${this.pendingDisplay?.getUniqueIdentifier()}-
      ${this.requiredDisplayIds?.join(',')}-
      ${this.activeDisplayIds?.join(',')}-
      ${this.status}-
      ${this.templateIds?.join(',')}-
      ${this.templates?.map(c => c.getUniqueIdentifier())?.join(',')}-
      ${this.tag}
    `;
  }

}
