import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-card-stack-size-carousel',
  templateUrl: './card-stack-size-carousel.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardStackSizeCarouselComponent {

  constructor() {
  }

  @Input() card: Card;
  @Input() first: boolean = false;
  @Input() last: boolean = false;

}
