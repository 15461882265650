<thead class="header-container">
  <tr class="header-row">
    <th class="fixed-column">
      <div class="d-flex" (click)="titleSort.clicked()">
        <div class="header-title">Variant</div>
        <app-table-asc-desc-sort #titleSort [ascSort]="viewModel.titleAsc" [descSort]="viewModel.titleDesc">
        </app-table-asc-desc-sort>
      </div>
    </th>
    <th *ngFor="let column of viewModel.columns$ | push; trackBy: viewModel.trackColumnByKey">
      <div class="d-flex" (click)="columnSort.clicked()">
        <div>{{ column.headerTitle }}</div>
        <app-table-asc-desc-sort #columnSort [ascSort]="column.ascFunc" [descSort]="column.descFunc">
        </app-table-asc-desc-sort>
      </div>
    </th>
    <th>
      <div class="d-flex" (click)="priceSort.clicked()">
        <div class="header-title">Price</div>
        <app-table-asc-desc-sort
          #priceSort
          [ascSort]="viewModel.priceAsc$ | push"
          [descSort]="viewModel.priceDesc$ | push">
        </app-table-asc-desc-sort>
      </div>
    </th>
    <th class="fixed-column">
      <div class="d-flex" (click)="visibleSort.clicked()">
        <div class="header-title">Visible</div>
        <app-table-asc-desc-sort
          #visibleSort
          [ascSort]="viewModel.visibleAsc$ | push"
          [descSort]="viewModel.visibleDesc$ | push">
        </app-table-asc-desc-sort>
      </div>
    </th>
  </tr>
</thead>
