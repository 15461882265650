import { Component } from '@angular/core';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { ColumnOptionsFormComponent } from '../column-options-form/column-options-form.component';
import { ColumnOptionsQuantityAndSizeViewModel } from './column-options-quantity-and-size-view-model';

@Component({
  selector: 'app-column-options-quantity-and-size',
  templateUrl: '../column-options-form/column-options-form.component.html',
  styleUrls: ['../column-options-form/column-options-form.component.scss'],
  providers: [ColumnOptionsQuantityAndSizeViewModel]
})
export class ColumnOptionsQuantityAndSizeComponent extends ColumnOptionsFormComponent implements Tabbable {

  constructor(
    public override viewModel: ColumnOptionsQuantityAndSizeViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
