import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../../models/shared/label';
import { Observable } from 'rxjs';
import { LocationConfiguration } from '../../../../models/company/dto/location-configuration';
import { CompanyConfiguration } from '../../../../models/company/dto/company-configuration';
import { DisplayLabelInterface } from '../../label/display-label-interface';
import { map } from 'rxjs/operators';
import { LabelUtils } from '../../utils/label-utils';
import { LabelStyle } from '../../../../models/enum/shared/label-style.enum';

@Pipe({
  name: 'getDisplayLabelInterfaceForCreateCustomLabel'
})
export class GetDisplayLabelInterfaceForCreateCustomLabelPipe implements PipeTransform {

  transform(
    label: Label,
    labelComputationData$: Observable<[LocationConfiguration, CompanyConfiguration, LabelStyle]>
  ): Observable<DisplayLabelInterface> {
    return labelComputationData$.pipe(
      map(labelComputationData => {
        return LabelUtils.getDisplayLabelInterfaceForCreateCustomLabel(label, labelComputationData);
      })
    );
  }

}
