import { Size } from '../../shared/size';
import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class PendingDisplay implements Deserializable, UniquelyIdentifiable {

  public displaySize: Size;
  public locationIds: number[];
  public name: string;

  constructor() {
    this.name = '';
    this.locationIds = [];
    this.displaySize = new Size();
  }

  onDeserialize() {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(Size, this.displaySize);
    this.locationIds = Array.from(this.locationIds || []);
  }

  getUniqueIdentifier(): string {
    return `
      ${this.displaySize?.getUniqueIdentifier()}-
      ${this.locationIds.join(',')}-
      ${this.name}
    `;
  }

}
