import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { map, take } from 'rxjs/operators';

type Activation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({ providedIn: 'root' })
export class TemplateAdminGuard  {

  constructor(
    private userDomainModel: UserDomainModel,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activation {
    return this.userDomainModel.user$.pipe(
      take(1),
      map((user) => {
        const isTemplateAdmin = user?.isTemplateAdmin;
        const companySupportsTemplates = user?.companyFeatures?.supportsTemplates;
        return isTemplateAdmin && companySupportsTemplates;
      }),
    );
  }

}
