import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contains'
})
export class ContainsPipe implements PipeTransform {

  transform(list: any[], item: any): boolean {
    return list?.includes(item) || false;
  }

}
