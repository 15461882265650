import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { MenuType } from '../../../models/utils/dto/menu-type-definition';
import { MarketingTheme } from '../../../models/enum/dto/theme.enum';

@Pipe({
  name: 'supportsDescriptionOptions'
})
export class SupportsDescriptionOptionsPipe implements PipeTransform {

  transform(value: Menu): boolean {
    switch (value?.type) {
      case MenuType.MarketingMenu:
        return value?.theme === MarketingTheme.Combo || value?.theme === MarketingTheme.MarketingProductFeature;
      case MenuType.PrintReportMenu:
        return true;
      case MenuType.PrintCardMenu:
      case MenuType.PrintLabelMenu:
        const visiblePropertiesMap = value?.hydratedTheme?.printConfig?.visiblePropertyMap;
        const menuCardSize = value?.metadata?.printCardSize;
        return visiblePropertiesMap?.get(menuCardSize)?.includes('Description');
      default:
        return false;
    }
  }

}

