<app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #printJobForm
  [mergeKey]="viewModel.mergeKey$ | push"
  (formsSubmitted$)="viewModel.createBulkPrintJob()">
</lib-reactive-form-merge-groups>

<app-print-job-header [title]="viewModel.title$ | push"></app-print-job-header>

<app-choose-bulk-print-job-type
  *rxIf="viewModel.stepIsSelectJobType$"
  (bulkPrintJobTypeSelected)="viewModel.handleBulkPrintJobTypeSelected($event)">
</app-choose-bulk-print-job-type>

<app-choose-card-stack-print-type
  *rxIf="viewModel.stepIsSelectStackPrintType$"
  [stackType]="viewModel.type$ | push"
  (stackPrintTypeSelected)="viewModel.handleStackPrintTypeSelected($event)">
</app-choose-card-stack-print-type>

<div class="modal-body pt-0" id="create-view-bulk-print-job">
  <app-print-menu-bulk-print-job
    *rxIf="viewModel.stepIsPrintMenuBulkPrintJob$"
    [job]="viewModel.bulkPrintJob$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [placeholder]="viewModel.currentDate$ | push"
    [viewOnly]="viewModel.viewOnly$ | push">
  </app-print-menu-bulk-print-job>

  <app-stack-bulk-print-job
    *rxIf="viewModel.selectedBulkPrintJobTypeIsStack$"
    [stackPrintType]="viewModel.selectedStackPrintType$ | push"
    [job]="viewModel.bulkPrintJob$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [placeholder]="viewModel.currentDate$ | push"
    [selectedBulkPrintType]="viewModel.selectedBulkPrintJobType$ | push"
    [step]="viewModel.step$ | push"
    [viewOnly]="viewModel.viewOnly$ | push"
    [stackType]="viewModel.type$ | push">
  </app-stack-bulk-print-job>
</div>

<app-go-back-modal-footer
  [primaryButtonText]="viewModel.primaryButtonText$ | push"
  [showPrimaryButton]="viewModel.showPrimaryButton$ | push"
  [showBackButton]="viewModel.showGoBackButton$ | push"
  [showCancelButton]="!(viewModel.viewOnly$ | push)"
  [disablePrimaryButton]="printJobForm.hasErrors$ | push"
  (primaryButtonClicked)="viewModel.handlePrimaryButtonClicked(printJobForm)"
  (cancel)="cancel()"
  (goBack)="viewModel.handleGoBack()">
</app-go-back-modal-footer>
