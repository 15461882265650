import { Injectable } from '@angular/core';
import { CombinedLoadingOptsViewModel } from '../../../../shared/components/auto-save/combined-loading-opts-view-model';
import { EditTemplateProductMenuViewModel } from './edit-template-product-menu-view-model';
import { EditProductMenuViewModel } from '../../../../menu/viewModels/edit-product-menu-view-model';

@Injectable()
export class EditTemplateProductMenuCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateProductMenuViewModel: EditTemplateProductMenuViewModel,
    protected editProductMenuViewModel: EditProductMenuViewModel
  ) {
    super([editTemplateProductMenuViewModel, editProductMenuViewModel]);
  }

}
