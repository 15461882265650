import { Pipe, PipeTransform } from '@angular/core';
import { BaseDisplay } from '../../../models/display/dto/base-display';
import { TemplateCollection } from '../../../models/template/dto/template-collection';
import { Display } from '../../../models/display/dto/display';

@Pipe({
  name: 'collectionIncludedInDisplay'
})
export class CollectionIncludedInDisplayPipe implements PipeTransform {

  transform(collection: TemplateCollection, display: BaseDisplay): boolean {
    return display instanceof Display && display?.templateCollectionIds?.includes(collection?.id);
  }

}
