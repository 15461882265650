import { User } from '../../account/dto/user';

export class EmployeeAction {

  action: string;
  employee: User;

  constructor(a: string, e: User) {
    this.action = a;
    this.employee = e;
  }

}
