import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { EditCardStackMenuViewModel } from '../edit-card-stack-menu-view-model';
import { EditStaticSectionMenuProductsComponent } from '../../shared/edit-static-section-menu-products/edit-static-section-menu-products.component';
import { EditCardStackSectionViewModel } from '../edit-card-stack-section-view-model';

@Component({
  selector: 'app-edit-card-stack-products',
  templateUrl: '../../shared/edit-static-section-menu-products/edit-static-section-menu-products.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackProductsComponent extends EditStaticSectionMenuProductsComponent {

  constructor(
    public override menuViewModel: EditCardStackMenuViewModel,
    public override sectionViewModel: EditCardStackSectionViewModel,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(menuViewModel, sectionViewModel, ngbModal, injector);
  }

  override promptForRemoveAllProducts() {
    const opts = new ConfirmationOptions();
    opts.title = 'Remove All Card Stack Products';
    opts.bodyText = `Are you sure you want to remove all products from this card stack?`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Remove';
    const confirmation = (cont) => {
      if (cont) {
        this.sectionViewModel.removeAllSectionProducts();
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

}
