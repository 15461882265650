import { AutoSaveViewModel } from './auto-save-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtils } from '../../../../utils/date-utils';

export abstract class CombinedLoadingOptsViewModel {

  protected constructor(
    protected viewModels: AutoSaveViewModel[]
  ) {
  }

  public readonly loadingOpts$ = combineLatest(this.viewModels.map(vm => vm.loadingOpts$)).pipe(
    map(loadingOptions => loadingOptions.reduce((acc, cur) => acc.combineWith(cur)))
  );

  public readonly isLoading$ = this.loadingOpts$.pipe(map(opts => opts?.isLoading));

  public readonly autoSaveLoadingOpts$ = combineLatest(this.viewModels.map(vm => vm.autoSaveLoadingOpts$)).pipe(
    map(autoSaveLoadingOptions => autoSaveLoadingOptions.reduce((acc, cur) => acc.combineWith(cur)))
  );

  public readonly lastAutoSaveTimestamp$ = combineLatest(this.viewModels.map(opts => opts.lastAutoSaveTimestamp$)).pipe(
    map(lastAutoSaveTimestamps => Math.max(...lastAutoSaveTimestamps, 0))
  );

  public readonly lastAutoSavedTimeText$ = this.lastAutoSaveTimestamp$.pipe(
    map(t => {
      return (!!t && DateUtils.unixAfterMinutesAgo(t, 15))
        ? `Last Saved ${DateUtils.formatUnixToTime(t)}`
        : null;
    })
  );

  public autoSaving$ = this.autoSaveLoadingOpts$.pipe(map(opts => opts?.isLoading));

}
