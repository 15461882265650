<div class="edit-page-footer">
  <!--   Action buttons   -->
  <div class="edit-page-action-buttons">
    <lib-button-text
      *rxIf="!isTemplatedSection && !isPrintReportSection"
      [disabled]="removingVariants"
      [textColor]="'#FA5555'"
      (buttonClicked)="deleteSection.emit()">
      Delete Section
    </lib-button-text>
    <lib-button-text
      *rxIf="!isTemplatedSection && !isPrintReportSection"
      [disabled]="removingVariants || !formDisabledSubmit"
      [disabledTooltip]="
        removingVariants || !formDisabledSubmit ? 'Changes must be saved before section can be duplicated.' : ''
      "
      (buttonClicked)="duplicateSection.emit()">
      Duplicate Section
    </lib-button-text>
    <lib-button-primary
      [disabled]="removingVariants || formDisabledSubmit"
      [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
      [loadingOptions]="autoSavingLoadingOpts"
      [infoText]="lastAutoSaveText"
      (buttonClicked)="submitForms.emit(false)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
