<div
  class="size-selection-container"
  [class.selected]="selected"
  [class.disabled]="disabled"
  [class.display-only]="displayOnly">
  <div class="d-flex justify-content-center">
    <img
      class="mb-6px"
      src="{{ cardSize | getImageSrcFromStackSize }}"
      [style.width.rem]="cardSize | cardSizeImageWidthInRem"
      [style.height.rem]="8.25" />
  </div>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div class="bs-medium f16px" [style.line-height.%]="120">{{ cardSize | getStackDimensions }}</div>
      <div class="bs-medium f14px" [style.line-height.%]="120">{{ cardSize | getNameFromStackSize }}</div>
    </div>
    <div *ngIf="selected && !displayOnly">
      <img
        class="check-mark"
        src="assets/icons/green/solid/check-circle.svg"
        alt=""
        [style.height.rem]="1.5"
        [style.width.rem]="1.5" />
    </div>
  </div>
</div>
