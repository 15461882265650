import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';

@Component({
  selector: 'app-title-and-crumbs',
  templateUrl: './title-and-crumbs.component.html',
  styleUrls: ['./title-and-crumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleAndCrumbsComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() headerText: string = '';
  @Input() crumbs: Breadcrumb[] = [];

}
