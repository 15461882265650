import { Pipe, PipeTransform } from '@angular/core';
import { Display } from '../../../../../models/display/dto/display';

@Pipe({
  name: 'isDisplay'
})
export class IsDisplayPipe implements PipeTransform {

  transform(value: any): Display | null {
    return value instanceof Display ? value : null;
  }

}
