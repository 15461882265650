import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../../utils/date-utils';

@Pipe({
  name: 'dateTimeStringIfUnixStringElseOriginal'
})
export class DateTimeStringIfUnixStringElseOriginalPipe implements PipeTransform {

  transform(val: string): string {
    return DateUtils.formatToDateTimeStringIfUnixStringElseOriginal(val);
  }

}
