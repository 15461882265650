import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { MenuSectionBackgroundViewModel } from './menu-section-background-view-model';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { Observable, of } from 'rxjs';
import { ASSET_RETRY_COUNT } from '../../../../../../utils/media-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditProductMenuComponent } from '../../edit-product-menu/edit-product-menu.component';
import { UploadImageInterface } from '../../../../../shared/components/upload-asset/upload-image-interface';
import { UploadAssetComponent } from '../../../../../shared/components/upload-asset/upload-asset.component';
import { ModalChangeMedia } from '../../../../../../modals/modal-change-media';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';

@Component({
  selector: 'app-menu-section-background',
  templateUrl: './menu-section-background.component.html',
  styleUrls: ['./menu-section-background.component.scss'],
  providers: [MenuSectionBackgroundViewModel]
})
export class MenuSectionBackgroundComponent extends BaseComponent implements UploadImageInterface {

  @Input() @ViewModelConnector('connectToTemplateMode') templateMode: boolean = false;
  @Input() allowVideo: boolean = true;
  @Input() formPristine: boolean = true;
  @Output() backgroundDeleted = new EventEmitter<boolean>(true);
  @ViewChild('backgroundImage') backgroundImage: UploadAssetComponent;

  constructor(
    public viewModel: MenuSectionBackgroundViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected menuPreviewService: MenuPreviewService
  ) {
    super();
  }

  handleDeleteBackgroundImage() {
    this.viewModel.deleteBackgroundAsset(this.backgroundDeleted);
  }

  handleChangeBackgroundImage() {
    const opts = EditProductMenuComponent.getChangeBackgroundImageOptions();
    opts.allowVideo$ = of(this.allowVideo);
    const changeMediaOperation = (files: BudsenseFile[]): Observable<string[]> => {
      if (files.length === 1) {
        return this.viewModel.uploadBackgroundAsset(files[0]);
      } else {
        return of(null);
      }
    };
    const onClose = (uploadedFileNames) => {
      if (uploadedFileNames?.length > 0) {
        this.viewModel.getRefreshedBackgroundAsset(uploadedFileNames, ASSET_RETRY_COUNT);
      }
    };
    ModalChangeMedia.open(this.ngbModal, this.injector, true, opts, changeMediaOperation, onClose);
  }

  handleRefreshBackgroundImage() {
    this.viewModel.manualRefreshBackgroundAsset();
  }

  public fileList(files: BudsenseFile[], _: number) {
    if (files?.length > 0) {
      this.backgroundImage.clear();
      this.viewModel.uploadBackgroundAsset(files[0]).once(this.viewModel.afterBackgroundAssetChanged);
    }
  }

}
