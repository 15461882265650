<app-card-stack-details-section [templateMode]="templateMode" [menu]="viewModel.menu$ | push">
  <app-card-stack-section-form
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [section]="sectionViewModel.section$ | push"
    [isTemplatedSection]="sectionViewModel.isTemplatedSection$ | push"
    [companyConfig]="sectionViewModel.companyConfig$ | push"
    [locationConfig]="sectionViewModel.locationConfig$ | push"
    [bindTo]="sectionViewModel.section$ | push"
    (primarySortChanged)="sectionViewModel.primarySortChanged($event)"
    (secondarySortChanged)="sectionViewModel.secondarySortChanged($event)">
  </app-card-stack-section-form>

  <app-menu-additional-options
    #menuAdditionalOptions
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [bindTo]="viewModel.menu$ | push"
    (iFrameHeightChanged)="viewModel.iFrameHeightChanged($event)"
    (iFrameWidthChanged)="viewModel.iFrameWidthChanged($event)">
  </app-menu-additional-options>
</app-card-stack-details-section>

<app-menu-section-location-permissions
  *ngIf="
    (sectionViewModel.section$ | async | isSectionTemplate) &&
    (sectionViewModel.section$ | async | sectionCanContainProducts)
  "
  [mergeKey]="mergeKey"
  [sectionTemplate]="sectionViewModel.section$ | async | castToSectionTemplate">
</app-menu-section-location-permissions>

<app-edit-card-stack-print-section [templateMode]="templateMode" [unsavedChanges]="unsavedChanges">
</app-edit-card-stack-print-section>

<app-edit-card-stack-asset-section
  [templateMode]="templateMode"
  [allowVideo]="false"
  [mergeKey]="mergeKey"
  [section]="sectionViewModel.section$ | push"
  [formPristine]="formPristine"
  (backgroundDeleted)="viewModel.onSectionBackgroundDeleted()">
</app-edit-card-stack-asset-section>

<app-menu-section-smart-filters
  [templateMode]="templateMode"
  [menu]="viewModel.menu$ | push"
  [mergeKey]="mergeKey"
  [section]="sectionViewModel.section$ | push"
  [autoSaving]="viewModel.autoSaving$ | push"
  [formPristine]="formPristine"
  [isTemplatedSection]="viewModel.isTemplatedMenu$ | push">
</app-menu-section-smart-filters>

<app-edit-card-stack-products
  [templateMode]="templateMode"
  [combinedAutoSaving]="sharedLoadingState.autoSaving$ | push"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine"
  (sortedVariantIds)="sortedVariantIds.emit($event)">
</app-edit-card-stack-products>
