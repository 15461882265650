import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getTerpeneWithUnits'
})
export class GetTerpeneWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null, cannabinoid: string): string | null {
    return variant?.getTerpeneWithUnits(cannabinoid) || null;
  }

}
