import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { validUrl } from './url-validator.directive';

@Injectable()
export class EditEmbeddedUrlViewModel extends BaseViewModel {

  public validators = [validUrl()];

}
