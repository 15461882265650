import { Deserializable } from '../../protocols/deserializable';
import { Selectable } from '../../protocols/selectable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class TypeDefinition implements Deserializable, Selectable, UniquelyIdentifiable {

  public value: any;
  public name: string;
  public description: string;
  public metadata: any;

  constructor(value: any = null, name: string = null, description: string = null) {
    this.value = value;
    this.name = name;
    this.description = description;
  }

  onDeserialize() {
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }

  getSelectionValue(): any {
    return this.value;
  }

  getUniqueIdentifier(): string {
    return `
      -${JSON.stringify(this.value)}
      -${this.name}
      -${this.description}
      -${JSON.stringify(this.metadata)}
    `;
  }

}

