import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionBodyComponent } from '../../../../../edit-menu-section/edit-menu-section-body/edit-menu-section-body.component';

@Component({
  selector: 'app-edit-smart-playlist-menu-section-body',
  templateUrl: './edit-smart-playlist-menu-section-body.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSmartPlaylistMenuSectionBodyComponent extends EditMenuSectionBodyComponent {

}
