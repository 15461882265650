import { NavItemViewModel } from '../nav-item-view-model';
import { Router } from '@angular/router';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ProductsNavItemViewModel extends NavItemViewModel {

  constructor(
    protected productDomainModel: ProductDomainModel,
    router: Router,
  ) {
    super(router);
  }

  public incompleteProducts$ = this.productDomainModel.inStockIncompleteVariants$.pipe(
    map(incomplete => {
     const length = incomplete?.length;
      if (!!length) {
          return (length > 100) ? '100+' : length;
      } else {
        return length;
      }
    })
  );
  public hasIncompleteProducts$ = this.incompleteProducts$.pipe(map(n => !!n));

}

