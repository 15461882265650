import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SendCompanyReferral } from '../../../../../models/account/dto/send-company-referral';
import { BehaviorSubject, defer } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { map, startWith } from 'rxjs/operators';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';

@Component({
  selector: 'app-refer-a-friend-modal',
  templateUrl: './refer-a-friend-modal.component.html',
  styleUrls: ['./refer-a-friend-modal.component.scss']
})
export class ReferAFriendModalComponent extends BaseComponent {

  referralReq = new SendCompanyReferral();

  // Loading Options
  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  loadingOpts$ = defer(() => this._loadingOpts);
  isLoading$ = defer(() => this.loadingOpts$).pipe(map(it => it?.isLoading), startWith(false));

  constructor(
    private activeModal: NgbActiveModal,
    private userDomainModel: UserDomainModel,
    private toastService: ToastService
  ) {
    super();
  }

  cancel() {
    this.activeModal.close();
  }

  sendReferral() {
    this._loadingOpts.addRequest('Sending Referral');
    this.userDomainModel.sendCompanyReferral(this.referralReq).subscribe(() => {
      this._loadingOpts.removeRequest('Sending Referral');
      const successMsg = `We have sent a referral to ${this.referralReq.recipientEmail}`;
      this.toastService.publishSuccessMessage(successMsg, 'Success');
      this.activeModal.close();
    }, (err: BsError) => {
      this._loadingOpts.removeRequest('Sending Referral');
      this.toastService.publishErrorMessage(err.message, 'Error');
    });
  }

}
