<label
  class="bs-checkbox-container"
  [ngClass]="{ 'inline-checkbox': inline, disabled: disabled }"
  [ngStyle]="{ margin: customCheckboxMargin ? customCheckboxMargin : null }"
  for="{{ cid }}">
  <input
    type="checkbox"
    class="bs-checkbox"
    id="{{ cid }}"
    name="bs-cb"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onClick()" />
  <span class="bs-checkbox-label">
    <ng-content *ngIf="!label"></ng-content>
    <ng-container *ngIf="label">{{ label }}</ng-container>
  </span>
  <span class="bs-checkmark"></span>
</label>
