import { Injectable } from '@angular/core';
import { EditTemplateCardStackMenuCombinedLoadingOptsViewModel } from '../edit-template-card-stack-menu/edit-template-card-stack-menu-combined-loading-opts-view-model';
import { EditTemplateLabelStackMenuViewModel } from './edit-template-label-stack-menu-view-model';
import { EditLabelStackMenuViewModel } from '../../../../menu/components/edit-menu/edit-label-stack-menu/edit-label-stack-menu-view-model';
import { EditLabelStackSectionViewModel } from '../../../../menu/components/edit-menu/edit-label-stack-menu/edit-label-stack-section-view-model';

@Injectable()
export class EditTemplateLabelStackMenuCombinedLoadingOptsViewModel
  extends EditTemplateCardStackMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateLabelStackMenuViewModel: EditTemplateLabelStackMenuViewModel,
    protected editLabelStackMenuViewModel: EditLabelStackMenuViewModel,
    protected editLabelStackSectionViewModel: EditLabelStackSectionViewModel
  ) {
    super(editTemplateLabelStackMenuViewModel, editLabelStackMenuViewModel, editLabelStackSectionViewModel);
  }

}
