import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { PrintProductTemplatesComponent } from './print-product-templates/print-product-templates.component';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PrintCardTemplatesComponent } from './print-card-templates/print-card-templates.component';
import { PrintReportTemplatesComponent } from './print-report-templates/print-report-templates.component';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { PrintLabelTemplatesComponent } from './print-label-templates/print-label-templates.component';

@Injectable()
export class PrintTemplatesViewModel extends BaseViewModel {

    constructor(
      private userDomainModel: UserDomainModel,
    ) {
      super();
    }

    public tabs$ = this.userDomainModel.canUsePrintCards$.pipe(
      map(canUsePrintCards => {
        return [
          new TabBarItem(PrintProductTemplatesComponent, 'Product Templates', '', false),
          new TabBarItem(PrintReportTemplatesComponent, 'Report Templates', '', false),
          ...canUsePrintCards ? [new TabBarItem(PrintCardTemplatesComponent, 'Print Card Templates', '', false)] : [],
          new TabBarItem(PrintLabelTemplatesComponent, 'Print Label Templates', '', false)
        ];
      })
    );

}
