import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from './modal-confirmation';
import { Section } from '../models/menu/dto/section';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { SectionTemplate } from '../models/template/dto/section-template';

export class ModalPromptForSectionDelete {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    section: Section,
    confirmation: (cont: boolean) => void
  ): void {
    const isTemplate = section instanceof SectionTemplate;
    const opts = new ConfirmationOptions();
    opts.title = isTemplate ? 'Delete Section Template' : 'Delete Section';
    opts.bodyText = `You are about to delete this section: ${section?.getSectionTitle()}.\n\n`
      + `Are you sure? This action cannot be undone.`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete';
    ModalConfirmation.open(ngbModal, injector, opts, confirmation);
  }

}
