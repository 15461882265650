import { Component } from '@angular/core';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';
import { PrintCardsViewModel } from '../print-cards-view-model';

@Component({
  selector: 'app-print-cards-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './print-cards-list.component.scss'
  ]
})
export class PrintCardsListComponent extends DisplayableItemListComponent {

  constructor(
    printCardsViewModel: PrintCardsViewModel // provided by parent component
  ) {
    super(printCardsViewModel);
  }

}
