import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { ReplaceCompanyPhotoComponent } from '../views/settings/components/modals/replace-company-photo/replace-company-photo.component';
import { ModalUtils } from '../utils/modal-utils';
import { LoadingOptions } from '../models/shared/loading-options';
import { LogoTypeEnum } from '../models/company/enum/logo-type.enum';
import { BehaviorSubject, takeWhile } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

export class ModalReplaceCompanyLogo {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    logoType: LogoTypeEnum,
    locationId: number,
    loadingOpts: BehaviorSubject<LoadingOptions>
  ) {
    const modalRef = ngbModal.open(
      ReplaceCompanyPhotoComponent,
      ModalUtils.defaultModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as ReplaceCompanyPhotoComponent;
    compInstance.viewModel.setType(logoType, locationId);
    compInstance.viewModel.uploadStatusChanged.pipe(
      withLatestFrom(loadingOpts),
      map(([_, opts]) => {
        const optsCopy = window?.injector.Deserialize?.instanceOf(LoadingOptions, opts);
        optsCopy.isLoading = !optsCopy.isLoading;
        loadingOpts.next(optsCopy);
        return optsCopy;
      }),
      takeWhile((optsCopy) => optsCopy.isLoading)
    ).subscribe();
  }

}
