import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-added-by-smart-filter-badge',
  templateUrl: './label-added-by-smart-filter-badge.component.html',
  styleUrls: ['./label-added-by-smart-filter-badge.component.scss']
})
export class LabelAddedBySmartFilterBadgeComponent {

  @Input() backgroundColor: string = '#000000';
  @Input() borderRadius: string = '50%';
  @Input() height: string = '1rem';
  @Input() width: string = '1rem';
  @Input() iconScale: number = 0.7;

}
