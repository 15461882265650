import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { WebProductMenusComponent } from './web-product-menus/web-product-menus.component';
import { BaseViewModel } from '../../../../../models/base/base-view-model';

@Injectable()
export class WebViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  public tabs$: Observable<TabBarItem[]> = of([
    new TabBarItem(WebProductMenusComponent,  'Product Menus', '', false)
  ]);

}
