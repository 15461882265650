import { BehaviorSubject, combineLatest, defer, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { Tag } from '../../../../../models/menu/dto/tag';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AnimatorService } from '../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { Injectable } from '@angular/core';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { DisplayableType } from '../../../../../models/enum/shared/displayableType.enum';
import { LocationChangedUtils } from '../../../../../utils/location-changed-utils';
import { DistinctUtils } from '../../../../../utils/distinct-utils';
import { DisplayableItem } from '../displayable-item-container/displayable-item-preview/displayable-item';
import { DisplayableItemFilterByActive } from '../../../../../models/enum/shared/displayable-item-filter-by.active';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { exists } from '../../../../../functions/exists';

@Injectable()
export abstract class DisplayableContentContainerViewModel extends BaseViewModel {

  protected constructor(
    protected locationDomainModel: LocationDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected userDomainModel: UserDomainModel,
    protected animatorService: AnimatorService,
    protected activeRoute: ActivatedRoute
  ) {
    super();
    this.setupBindings();
  }

  public abstract readonly locationText$: Observable<string>;
  public abstract readonly tags$: Observable<Tag[]>;
  protected override _loadingOpts = new BehaviorSubject(this.getLoadingOpts());
  protected loadingMenuTemplates$ = this.templateDomainModel.loadingMenuTemplates$;

  private _title = new BehaviorSubject<string>(null);
  public title$ = this._title as Observable<string>;
  public connectToTitle = (t: string) => this._title.next(t);

  private _itemsToFilter = new BehaviorSubject<DisplayableItem[]>([]);
  public itemsToFilter$ = this._itemsToFilter as Observable<DisplayableItem[]>;
  public connectToItemsToFilter = (items: DisplayableItem[]) => this._itemsToFilter.next(items);

  public menuTypeFilterHint$ = of('Choose a Menu Type');
  public menuTypeFilterLabel$ = of('Filter by Menu Type');

  public inactiveStateFilterHint$ = of('Choose an Active State');
  public inactiveStateFilterLabel$ = of('Filter by Active State');

  private _menuTypeFilterOptions = new BehaviorSubject<DropDownItem[]>(null);
  public menuTypeFilterOptions$ = this._menuTypeFilterOptions as Observable<DropDownItem[]>;
  public connectToMenuTypeFilterOptions = (options: DropDownItem[]) => this._menuTypeFilterOptions.next(options);

  private _inactiveStateFilterOptions = new BehaviorSubject<DropDownItem[]>(null);
  public inactiveStateFilterOptions$ = this._inactiveStateFilterOptions as Observable<DropDownItem[]>;
  public connectToInactiveStateFilterOptions = (opts: DropDownItem[]) => this._inactiveStateFilterOptions.next(opts);

  private _selectedDisplayableType = new BehaviorSubject<DisplayableType>(DisplayableType.All);
  public selectedDisplayableType$ = this._selectedDisplayableType as Observable<DisplayableType>;
  connectToSelectedDisplayableType = (type: DisplayableType) => this._selectedDisplayableType.next(type);

  public isInitialInactiveStateSelection$ = new BehaviorSubject<boolean>(true);

  protected _userSelectedInactiveStateFilter = new BehaviorSubject(DisplayableItemFilterByActive.Active);
  userSelectedInactiveStateFilter$ = this._userSelectedInactiveStateFilter as Observable<DisplayableItemFilterByActive>;
  public connectToUserSelectedInactiveStateFilter = (op: DisplayableItemFilterByActive) => {
    this.isInitialInactiveStateSelection$.next(false);
    this._userSelectedInactiveStateFilter.next(op);
  };

  protected itemsToFilterCountTemplatedAndNonTemplatedAndAll$ = combineLatest([
    this.loadingMenuTemplates$,
    this.itemsToFilter$
  ]).pipe(
    map(([loadingTemplates, items]) => {
      if (loadingTemplates) return [0, 0, 0];
      const templatedCount = items?.filter(item => item?.displayableItemIsTemplatedMenu())?.length ?? 0;
      const nonTemplatedCount = items?.filter(item => !item?.displayableItemIsTemplatedMenu())?.length ?? 0;
      const allCount = items?.length ?? 0;
      return [templatedCount, nonTemplatedCount, allCount];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected hasTemplatedItems$ = this.itemsToFilterCountTemplatedAndNonTemplatedAndAll$.pipe(
    map(([templatedCount, _, allCount]) => exists(templatedCount) && exists(allCount))
  );

  protected hasNonTemplatedItems$ = this.itemsToFilterCountTemplatedAndNonTemplatedAndAll$.pipe(
    map(([_, nonTemplated, allCount]) => exists(nonTemplated) && exists(allCount))
  );

  protected hasItems$ = this.itemsToFilterCountTemplatedAndNonTemplatedAndAll$.pipe(
    map(([_, __, allCount]) => allCount > 0),
  );

  protected hasActiveItems$ = this.itemsToFilter$.pipe(
    map(items => items?.some(item => item.displayableItemIsActive())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected hasInactiveItems$ = this.itemsToFilter$.pipe(
    map(items => items?.some(item => !item.displayableItemIsActive())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public selectFilterByMenuType$ = combineLatest([
    this.hasTemplatedItems$,
    this.hasNonTemplatedItems$
  ]).pipe(
    debounceTime(100),
    map(([hasTemplatedItems, hasNonTemplatedItems]) => {
      switch (true) {
        case !hasTemplatedItems:    return DisplayableType.NonTemplatedMenu;
        case !hasNonTemplatedItems: return DisplayableType.TemplatedMenu;
        default:                    return DisplayableType.All;
      }
    })
  );

  public filterByMenuTypeDisabled$ = combineLatest([
    this.hasTemplatedItems$,
    this.hasNonTemplatedItems$
  ]).pipe(
    debounceTime(100),
    map(([hasTemplatedItems, hasNonTemplatedItems]) => !hasTemplatedItems || !hasNonTemplatedItems),
  );

  public selectFilterByInactiveState$: Observable<DisplayableItemFilterByActive> = combineLatest([
    this.hasInactiveItems$,
    this.hasActiveItems$,
    this.hasItems$,
    this.userSelectedInactiveStateFilter$,
  ]).pipe(
    debounceTime(100),
    withLatestFrom(this.isInitialInactiveStateSelection$),
    map(([[hasInactiveItems, hasActiveItems, hasItems, selectedOption], isInitialSelection]) => {
      switch (true) {
        case !hasItems:
          return DisplayableItemFilterByActive.All;
        case !hasInactiveItems:
          return DisplayableItemFilterByActive.Active;
        case !hasActiveItems && hasInactiveItems:
          return DisplayableItemFilterByActive.Inactive;
        case !isInitialSelection:
          return selectedOption;
        default:
          return DisplayableItemFilterByActive.Active;
      }
    })
  );

  public filterByInactiveStateDisabled$ = combineLatest([this.hasActiveItems$, this.hasInactiveItems$]).pipe(
    debounceTime(100),
    map(([hasActiveItems, hasInactiveItems]) => !hasActiveItems || !hasInactiveItems)
  );

  public connectToMenuTypeFilter = (op: DisplayableType) => {
    const value = !!op ? op : DisplayableType.All;
    this.setSelectedDisplayableContentType(value);
    this.connectToSelectedDisplayableType(value);
  };

  public showMenuTypeDropdown$ = of(true);
  public showTagDropdown$ = of(true);

  private _searchText = new BehaviorSubject<string>(null);
  public searchText$ = this._searchText as Observable<string>;
  public connectToSearchText = (t: string) => this._searchText.next(t);

  private _searchedItems = new BehaviorSubject<DisplayableItem[]>([]);
  public searchedItems$ = this._searchedItems as Observable<DisplayableItem[]>;
  public connectToSearchedItems = (items: DisplayableItem[]) => this._searchedItems.next(items);

  public sortedTags$ = defer(() => this.tags$).pipe(
    map(tags => tags?.sort((a, b) => a?.title.localeCompare(b?.title)))
  );

  public readonly hasTags$ = this.sortedTags$.pipe(
    map(tags => tags?.length > 0)
  );

  private _activeTag = new BehaviorSubject<Tag>(null);
  public activeTag$ = this._activeTag.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));
  public connectToActiveTag = (t: Tag) => this._activeTag.next(t);
  public activeTagString$ = this.activeTag$.pipe(map(tag => tag?.value ?? null));

  public tagHint$ = of('Choose a Tag');

  private clearTagIfLocationChanges = LocationChangedUtils.onLocationChange(
    this,
    this.locationDomainModel.locationId$,
    () => this.connectToActiveTag(null)
  );

  // Tabs
  private _selectedTab = new BehaviorSubject<number>(0);
  public selectedTab$ = this._selectedTab.asObservable();
  public canUsePrintCards$ = this.userDomainModel.canUsePrintCards$;

  public locationName$ = this.locationDomainModel.locationName$;

  // Flags, Signals and Mechanisms
  public addNewMenu = new BehaviorSubject<boolean>(null);
  private addNewMenuSignal = new BehaviorSubject<boolean>(null);
  private waitToShowDialogUntilDoneLoadingMechanism$ = combineLatest([
    this.loadingOpts$.pipe(map(opts => opts?.awaitingRequests), debounceTime(50)),
    this.addNewMenuSignal.pipe(debounceTime((50)))
  ]);

  // Displayable Content Type State
  private _selectedDisplayableContentTypeMap = new BehaviorSubject<Map<number, DisplayableType>>(new Map());
  public selectedDisplayableContentTypeMap$ = this._selectedDisplayableContentTypeMap.asObservable();

  public setSelectedDisplayableContentType(val: DisplayableType) {
    combineLatest([this.selectedTab$, this.selectedDisplayableContentTypeMap$]).once(([tabIndex, typeMap]) => {
      const updateMap = typeMap?.shallowCopy() ?? new Map();
      updateMap.set(tabIndex, val);
      this._selectedDisplayableContentTypeMap.next(updateMap);
    });
  }

  getLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.zIndex = 100;
    return opts;
  }

  setupBindings() {
    // Waits for loading to be done before showing dialog
    const dialogMechanism = this.waitToShowDialogUntilDoneLoadingMechanism$
      .subscribe(([loading, signal]) => {
        if (signal && loading.length === 0) {
          this.addNewMenu.next(true); // show dialog
          this.addNewMenuSignal.next(false); // reset mechanism
        }
      });
    this.pushSub(dialogMechanism);

    // Bind loading options to domain model loading
    const loadingSub = this.menuDomainModel.loadingMenus$.notNull().subscribe((l) => {
      const lm = 'Loading Menus';
      this.updateLoadingOptions(l, lm);
    });
    this.pushSub(loadingSub);

    const templateLoadingSub = this.templateDomainModel.loadingMenuTemplates$.notNull().subscribe((l) => {
      const lm = 'Loading Templates';
      this.updateLoadingOptions(l, lm);
    });
    this.pushSub(templateLoadingSub);
  }

  private updateLoadingOptions(l: boolean, lm: string) {
    if (l) {
      this._loadingOpts.addRequest(lm);
    } else if (this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.removeRequest(lm);
    }
  }

  filterByTag(tagValue: string) {
    this.tags$.once(tags => {
      const tag = tags?.find(t => t?.value === tagValue);
      if (!!tag?.value && tag instanceof Tag) {
        this.connectToActiveTag(tag);
      } else {
        this.connectToActiveTag(null);
      }
    });
  }

  selectActiveTag() {
    combineLatest([
      this.activeTag$,
      this.tags$
    ]).once(([activeTag, tags]) => {
      const at = tags?.find(t => t.value === activeTag?.value);
      this.connectToActiveTag(at);
    });
  }

  tabSelected(n: number) {
    this._selectedTab.next(n);
    this._activeTag.next(null);
  }

  toggleAddNewMenu() {
    this.addNewMenuSignal.next(true);
  }

}
