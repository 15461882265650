import { Pipe, PipeTransform } from '@angular/core';
import { StrainClassification, StrainClassificationType } from '../../../models/utils/dto/strain-classification-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'presentClassificationToUser'
})
export class PresentClassificationToUserPipe implements PipeTransform {

  transform(
    classification: StrainClassification,
    mapping$: Observable<Map<StrainClassification, StrainClassificationType>>
  ): Observable<string> {
    return mapping$.pipe(
      map(classificationMap => classificationMap?.get(classification)?.name)
    );
  }

}
