import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './components/auth/auth.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { NewPasswordComponent } from './components/auth/new-password/new-password.component';
import { ChangePasswordComponent } from './components/modals/change-password/change-password.component';
import { ChangePasswordViewModel } from './viewModels/change-password-view-model';
import { AuthViewModel } from './viewModels/auth-view-model';
import { UploadProfilePictureComponent } from './components/modals/upload-profile-picture/upload-profile-picture.component';
import { UploadProfilePictureViewModel } from './components/modals/upload-profile-picture/upload-profile-picture-view-model';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';
import { RxPush } from '@rx-angular/template/push';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    SignOutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    ChangePasswordComponent,
    UploadProfilePictureComponent
  ],
  imports: [
    AuthRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormModule,
    ReactiveButtonsModule,
    FlexLayoutModule,
    RxPush
  ],
  providers: [
    AuthViewModel,
    ChangePasswordViewModel,
    UploadProfilePictureViewModel
  ]
})
export class AuthModule {
}
