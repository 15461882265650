<div class="reset-default" fxLayout="column" fxLayoutAlign="center stretch">
  <div
    class="text-link"
    [popper]="popperContent"
    [popperShowOnStart]="false"
    [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
    [popperModifiers]="popperModifier"
    [popperHideOnClickOutside]="true"
    [popperHideOnScroll]="false"
    [popperPreventOverflow]="true"
    [popperPositionFixed]="true"
    [popperApplyClass]="'options-picker-popper'"
    [popperStyles]="popperStyles"
    [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMEND">
    {{ relatedVariants?.length }} Variants
  </div>
  <popper-content #popperContent [style.z-index]="1500">
    <div>
      <ng-container *ngFor="let variant of relatedVariants">
        <app-popper-selection
          [data]="variant"
          [disabled]="currentVariantId === variant.id"
          (selected)="variantClicked.emit(variant)">
          <img
            *ngIf="variant?.isMedical"
            class="medical-symbol"
            alt=""
            [src]="'/assets/icons/green/outline/medicinal.svg'" />
          {{ variant.getVariantTitle() }} - {{ variant.getPackagedQuantityAndProductSize() }}
        </app-popper-selection>
      </ng-container>
    </div>
  </popper-content>
</div>
