<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ viewModel.modalTitle$ | async }}
      </div>
      <div class="modal-subtitle-container">
        <div class="modal-subtitle inline-subtitle">
          {{ viewModel.variantName$ | async }}
        </div>
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container pt-24px">
    <!-- Modal Body -->
    <lib-reactive-form-group
      #styleForm
      [bindTo]="viewModel.editableMenuStyle$ | async"
      (formSubmitted$)="viewModel.saveMenuStyle()">
      <lib-reactive-form-number
        *ngIf="viewModel.showFontSize$ | async"
        [inputName]="'fontSize'"
        [label]="'Font Size (%)'"
        [placeholder]="'Increase or decrease the font size'"
        [bindingProperty]="'fontSize'"
        [disabled]="viewModel.viewOnly$ | async"
        [minValue]="50"
        [maxValue]="150"
        [inlineLabel]="true"
        [integersOnly]="true"
        [tooltip]="'Default value is 100%'">
      </lib-reactive-form-number>

      <lib-reactive-form-label [label]="'Font Decoration'"></lib-reactive-form-label>

      <div class="check-mark-container">
        <lib-reactive-form-checkbox
          [inputName]="'bold'"
          [bindingProperty]="'bold'"
          [disabled]="viewModel.viewOnly$ | async"
          (valueChanged)="viewModel.setBold($event)"
          >Bold</lib-reactive-form-checkbox
        >

        <lib-reactive-form-checkbox
          [inputName]="'italics'"
          [bindingProperty]="'italics'"
          [disabled]="viewModel.viewOnly$ | async"
          (valueChanged)="viewModel.setItalics($event)"
          >Italics</lib-reactive-form-checkbox
        >
      </div>

      <hr />
      <lib-reactive-form-color-square
        [inputName]="'fontColor'"
        [label]="'Font Color'"
        [bindingProperty]="'fontColor'"
        [disabled]="viewModel.viewOnly$ | async"
        [inlineLabel]="true"
        [inlinePercentage]="100"
        [presetColors]="viewModel.colorPalette$ | async"></lib-reactive-form-color-square>

      <lib-reactive-form-color-square
        [inputName]="'backgroundColor'"
        [label]="'Background Color'"
        [bindingProperty]="'backgroundColor'"
        [disabled]="viewModel.viewOnly$ | async"
        [inlineLabel]="true"
        [inlinePercentage]="100"
        [presetColors]="viewModel.colorPalette$ | async"></lib-reactive-form-color-square>
      <hr />

      <lib-reactive-form-checkbox
        *ngIf="viewModel.hasSiblings$ | async"
        class="block mb-16px"
        [disabled]="viewModel.viewOnly$ | async"
        [inputName]="'applyToProductVariants'"
        [bindingProperty]="'applyToProductVariants'">
        Apply this style to all variants under this product
      </lib-reactive-form-checkbox>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end pt-0">
    <lib-button-destructive
      *ngIf="viewModel.canRemoveStyle$ | async"
      class="footer-remove-style"
      (buttonClicked)="viewModel.removeStyle()">
      {{ viewModel.removeButtonText$ | async }}
    </lib-button-destructive>
    <div fxLayout="row" [style.gap.rem]="1">
      <lib-button-neutral (buttonClicked)="cancel()">
        {{ viewModel.cancelButtonText$ | async }}
      </lib-button-neutral>
      <lib-button-primary
        *ngIf="!(viewModel.viewOnly$ | async)"
        (buttonClicked)="styleForm.submitForm()"
        [disabled]="styleForm.disableSubmit$ | async">
        Save Styles
      </lib-button-primary>
    </div>
  </div>
</div>
