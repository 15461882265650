import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateCardStackMenuFooterComponent } from '../../edit-template-card-stack-menu/edit-template-card-stack-menu-footer/edit-template-card-stack-menu-footer.component';
import { EditTemplateLabelStackMenuViewModel } from '../edit-template-label-stack-menu-view-model';

@Component({
  selector: 'app-edit-template-label-stack-menu-footer',
  templateUrl: '../../edit-template-card-stack-menu/edit-template-card-stack-menu-footer/edit-template-card-stack-menu-footer.component.html',
  styleUrls: [
    '../../edit-template-card-stack-menu/edit-template-card-stack-menu-footer/edit-template-card-stack-menu-footer.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateLabelStackMenuFooterComponent extends EditTemplateCardStackMenuFooterComponent {

  constructor(
    public override viewModel: EditTemplateLabelStackMenuViewModel
  ) {
    super(viewModel);
  }

}
