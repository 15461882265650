import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-product-menu-body',
  templateUrl: './edit-product-menu-body.component.html',
  styleUrls: ['./edit-product-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProductMenuBodyComponent extends BaseComponent {

  constructor(
    public viewModel: EditProductMenuViewModel
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

}
