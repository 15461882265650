import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StackManualBulkPrintJobMenuListViewModel } from './stack-manual-bulk-print-job-menu-list-view-model';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-stack-manual-bulk-print-job-menu-list',
  templateUrl: './stack-manual-bulk-print-job-menu-list.component.html',
  styleUrls: ['./stack-manual-bulk-print-job-menu-list.component.scss'],
  providers: [StackManualBulkPrintJobMenuListViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackManualBulkPrintJobMenuListComponent extends BaseComponent {

  constructor(
    public viewModel: StackManualBulkPrintJobMenuListViewModel
  ) {
    super();
  }

  @Input() type: string;
  @Input() @ViewModelConnector('connectToStackMenus') stackMenus: Menu[];
  @Input() @ViewModelConnector('connectToSelectedVariantsMap') selectedVariantsMap: Map<string, string[] | null>;
  @Output() idSelected = new EventEmitter<string>(true);

}
