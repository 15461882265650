import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { ThemePickerViewModel } from './theme-picker-view-model';
import { ReactiveFormTextComponent } from '@mobilefirstdev/reactive-form';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import { Tag } from '../../../../../../models/menu/dto/tag';
import { Orientation } from '../../../../../../models/utils/dto/orientation-type';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { MenuSubtype } from '../../../../../../models/enum/dto/menu-subtype';
import { DefaultPrintStackSize } from '../../../../../../models/enum/dto/default-print-stack-size';

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss'],
  providers: [ThemePickerViewModel]
})
export class ThemePickerComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: ThemePickerViewModel
  ) {
    super();
  }

  @Input() menuType: MenuType;
  @Input() menuSubtypes: MenuSubtype[];
  @Input() tags: Tag[];
  @Input() dispersedKey: string;
  @Input() creatingFromExisting: boolean = false;
  @Input() templateMode: boolean = false;
  @Output() loadingOpts = new EventEmitter<LoadingOptions>();
  @Output() themeSelected = new EventEmitter<Theme>();
  @Output() stackSizeSelected = new EventEmitter<DefaultPrintStackSize>();
  @Output() orientationSelected = new EventEmitter<Orientation>();
  @ViewChild('menuName') menuName: ReactiveFormTextComponent;

  override setupViews() {}

  override setupBindings() {
    this.listenForSelectedTheme();
    this.listenForSelectedStackSize();
    this.listenForSelectedOrientation();
    this.updateShowNewTagInput();
  }

  private listenForSelectedTheme(): void {
    this.viewModel.themeSelected$.subscribeWhileAlive({
      owner: this,
      next: themeSelected => this.themeSelected.emit(themeSelected)
    });
  }

  private listenForSelectedStackSize(): void {
    this.viewModel.selectedStackSize$.subscribeWhileAlive({
      owner: this,
      next: selectedStackSize => this.stackSizeSelected.emit(selectedStackSize)
    });
  }

  private listenForSelectedOrientation(): void {
    this.viewModel.orientation$.subscribeWhileAlive({
      owner: this,
      next: orientation => this.orientationSelected.emit(orientation)
    });
  }

  private updateShowNewTagInput(): void {
    this.viewModel.newTagClicked.subscribeWhileAlive({
      owner: this,
      next: () => this.viewModel.showNewTagInput$.once(value => this.viewModel.showNewTagInput$.next(!value))
    });
    this.viewModel.useExistingTagClicked.subscribeWhileAlive({
      owner: this,
      next: () => this.viewModel.showNewTagInput$.once(value => this.viewModel.showNewTagInput$.next(!value))
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuType) this.viewModel.connectToProductMenuType(this.menuType);
    if (changes.menuSubtypes) this.viewModel.connectToProductMenuSubTypes(this.menuSubtypes);
    if (changes.tags) this.viewModel.connectToTags(this.tags);
    if (changes.templateMode) this.viewModel.connectToTemplateMode(this.templateMode);
  }

  toggleUseExistingTags(showNewTagInput: boolean) {
    this.viewModel.toggleShowNewTagInput(showNewTagInput);
  }

}
