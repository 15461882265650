import { Component } from '@angular/core';
import { BaseModalComponent } from 'src/app/models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Asset } from '../../../../models/image/dto/asset';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';
import { Orientation } from '../../../../models/utils/dto/orientation-type';

@Component({
  selector: 'app-lightbox-modal',
  templateUrl: './lightbox-modal.component.html',
  styleUrls: ['./lightbox-modal.component.scss']
})
export class LightboxModalComponent extends BaseModalComponent {

  img: Asset;
  assetSize = AssetSize;
  orientation: Orientation = Orientation.Portrait;
  cardSizeText: string = '';
  _orientation = Orientation;

  constructor(
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

}
