<div [hidden]="viewModel.hideProductSection$ | async" class="row edit-page-children">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Products</p>
    <p class="bs-edit-section-description">
      {{ viewModel.productSectionDesc$ | async }}
    </p>
    <div [hidden]="viewModel.hideAddProductsButton$ | async" class="bs-edit-section-button-container">
      <lib-button-primary
        (buttonClicked)="openAddProductsModal()"
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        [disabled]="(viewModel.disableAddProductsButton$ | async) || !formPristine"
        [disabledTooltip]="
          (viewModel.showDisabledTooltip$ | async | templatedMenuDisabledButtonTooltip) ||
          (formPristine | saveChangesTooltip)
        ">
        Add Products
      </lib-button-primary>
      <lib-button-destructive
        *ngIf="!(viewModel.sectionHasSmartFilters$ | push) && (viewModel.sectionHasProductsThatCanBeRemoved$ | push)"
        (buttonClicked)="promptForRemoveAllProducts()"
        [disabled]="(viewModel.autoSaving$ | push) || (viewModel.removingVariants$ | push) || !formPristine"
        [disabledTooltip]="formPristine | saveChangesTooltip"
        [iconSrc]="'assets/icons/red/outline/trash.svg'"
        [ngClass]="{
          disabled: (viewModel.autoSaving$ | push) || (viewModel.removingVariants$ | push) || !formPristine
        }">
        {{ viewModel.removeAllProductButtonText$ | push }}
      </lib-button-destructive>
    </div>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <app-message-banner *ngIf="viewModel.templateMode$ | async" class="mb-16px">
      All variants shown are based on the current location's pricing, inventory, and product attributes. This
      information can differ between locations.
    </app-message-banner>
    <app-menu-section-variant-groups
      [menu]="viewModel.menu$ | async"
      [section]="viewModel.section$ | async"
      [primarySorting]="viewModel.primarySortOption$ | async"
      [secondarySorting]="viewModel.secondarySortOption$ | async"
      [showWillNotAppearOnMenuSection]="true"
      [showZeroStockItems]="viewModel.showZeroStockProducts$ | async"
      [autoSaving]="viewModel.autoSaving$ | async"
      [removingVariants]="viewModel.removingVariants$ | async"
      [templateMode]="viewModel.templateMode$ | async"
      [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
      [allowAddingProducts]="viewModel.allowAddingProducts$ | async"
      [allowBadgeOverride]="viewModel.allowBadgeOverride$ | async"
      [allowLabelOverride]="viewModel.allowLabelOverride$ | async"
      [allowStyleOverride]="viewModel.allowStyleOverride$ | async"
      [hideBottomDivider]="true"
      (addProductsClicked)="openAddProductsModal()"
      [invalidFormState]="formHasErrors"
      [saveSection]="viewModel.saveSection"
      [formPristine]="formPristine"
      [removeVariantFromSection]="viewModel.removeVariantFromSection"
      (removeAllProductsClicked)="promptForRemoveAllProducts()">
    </app-menu-section-variant-groups>
  </div>
</div>

<hr [hidden]="viewModel.hideProductSection$ | async" class="mt-24px" />
