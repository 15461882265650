import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, defer, of } from 'rxjs';
import { ImageObjectFit } from '../../../../../../../../models/enum/shared/image-object-fit';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DropDownItem } from '../../../../../../../../models/shared/stylesheet/drop-down-item';
import { ImageAlignment } from '../../../../../../../../models/enum/shared/image-alignment';

@Injectable()
export class EditFeaturedCategoryEditAssetViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  imageAlignments$ = defer(() => {
    const alignmentOptions: DropDownItem[] = [];
    alignmentOptions.push(new DropDownItem('Left', ImageAlignment.Left));
    alignmentOptions.push(new DropDownItem('Center', ImageAlignment.Center));
    alignmentOptions.push(new DropDownItem('Right', ImageAlignment.Right));
    return of(alignmentOptions);
  });

  objectFitOptions$ = defer(() => {
    const objectFitOptions: DropDownItem[] = [];
    objectFitOptions.push(new DropDownItem('Cover', ImageObjectFit.Cover));
    objectFitOptions.push(new DropDownItem('Contain', ImageObjectFit.Contain));
    return of(objectFitOptions);
  });

  private _imageWidth: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  public imageWidth$ = this._imageWidth.pipe(distinctUntilChanged());

  private _objectFit: BehaviorSubject<ImageObjectFit> = new BehaviorSubject<ImageObjectFit>(ImageObjectFit.Contain);
  public objectFit$ = this._objectFit.pipe(
    filter(fit => !!fit),
    distinctUntilChanged()
  );

  public imageAlignmentDisabled$ = combineLatest([
    this.imageWidth$,
    this.objectFit$
  ]).pipe(
    map(([imageWidth, objectFit]) => {
      const imageWidthMaxed = imageWidth >= 100;
      const objectFitIsContains = objectFit === ImageObjectFit.Contain;
      return imageWidthMaxed && objectFitIsContains;
    })
  );

  connectToImageWidth = (imageWidth: string) => this._imageWidth.next(Number.parseInt(imageWidth, 10));
  connectToImageObjectFit = (imageObjectFit: ImageObjectFit) => this._objectFit.next(imageObjectFit);

}
