import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-template-collection-indicator',
  templateUrl: './template-collection-indicator.component.html',
  styleUrls: ['./template-collection-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCollectionIndicatorComponent implements OnChanges {

  constructor() { }
  @Input() collectionNames: string;

  private _collectionNames = new BehaviorSubject<string>(null);
  public collectionNames$ = this._collectionNames.asObservable();

  public tooltipText$ = this.collectionNames$.pipe(
    map(names => {
      return 'Belongs to the following collections: ' + names;
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collectionNames) this._collectionNames.next(this.collectionNames);
  }

}
