<button
  (click)="buttonClicked(); $event.stopPropagation()"
  class="datatable-action-button"
  [class.action-button-disabled]="disabled"
  [style.height.rem]="heightInRem"
  [style.width.rem]="widthInRem"
  [style.cursor]="cursor"
  [popper]="popperContent"
  [popperShowOnStart]="false"
  [popperTarget]="icon"
  [popperTrigger]="ClientTypes.NgxPopperjsTriggers.hover"
  [popperModifiers]="popperModifier"
  [popperHideOnScroll]="false"
  [popperPreventOverflow]="false"
  [popperPositionFixed]="true"
  [popperStyles]="popperStyles">
  <popper-content #popperContent [style.z-index]="15">
    <span [style.color]="'white'" [style.white-space]="showNewLines ? 'pre-wrap' : null">{{ tooltip }}</span>
  </popper-content>
  <img #icon [src]="iconUrl" alt="" />
</button>
