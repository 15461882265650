<div class="product-grouping-container mt-3" [class.media-not-visible-on-menu]="!(viewModel.sectionIsVisible$ | async)">
  <div fxLayout="row">
    <div fxLayout="column" fxLayoutAlign="start" (click)="editSection()">
      <div class="title" fxFlex fxLayout="row" [style.cursor]="'pointer'">
        {{ section.title ? section.title : 'Unnamed' }}
      </div>
      <div class="variant-count" fxFlex fxLayout="row">
        {{ viewModel.subTitle$ | async }}
      </div>
    </div>

    <div class="smart-filter-details-container">
      <app-smart-filter-small-sync-button
        *ngIf="!templateMode && (viewModel.hasSmartFilters$ | async)"
        appThrottleClick
        [syncing]="viewModel.section$ | isSectionSyncingSmartFilters | async"
        [formPristine]="formPristine"
        [timestamp]="(viewModel.section$ | async)?.lastSmartFilterSync"
        [disabledThrottleClick]="(viewModel.section$ | isSectionSyncingSmartFilters | push) || !formPristine"
        (throttleClick)="sharedViewModel.syncSectionSmartFilters(menu, section)">
      </app-smart-filter-small-sync-button>
      <app-smart-filters-indicator
        *ngIf="viewModel.hasSmartFilters$ | async"
        [nSmartFilters]="(viewModel.section$ | async)?.smartFilterIds?.length">
      </app-smart-filters-indicator>
      <div
        appThrottleClick
        (throttleClick)="viewModel.toggleHideSection()"
        [disabledThrottleClick]="!formPristine"
        [class.disabled]="(viewModel.isTemplatedSection$ | async) || formHasErrors || !formPristine"
        [ngbTooltip]="formPristine | saveChangesTooltip"
        class="hide-section-button">
        <img *ngIf="!(viewModel.sectionIsHidden$ | async)" [src]="'assets/icons/dark/outline/eye.svg'" alt="" />
        <img *ngIf="viewModel.sectionIsHidden$ | async" [src]="'assets/icons/dark/outline/eye-off.svg'" alt="" />
      </div>
    </div>
  </div>

  <div class="mt-4">
    <app-loading
      [hidden]="viewModel.hideSectionAssetLoading$ | async"
      [options]="viewModel.sectionAssetLoadingOpts$ | async">
    </app-loading>

    <app-section-media
      *ngIf="!!(viewModel.sectionAsset$ | async)"
      [isDisabledByTemplates]="viewModel.isTemplatedSection$ | async"
      [sharedViewModel]="sharedViewModel"
      [mergeKey]="mergeKey"
      [menu]="viewModel.menu$ | async"
      [section]="viewModel.section$ | async"
      [file]="viewModel.sectionAsset$ | async"
      [destructiveButtonText]="'Remove Media'"
      [bottomDivider]="true"
      [formPristine]="formPristine"
      (replaceMedia)="fileList($event, index)"
      (deleteMedia)="viewModel?.deleteMedia($event)"></app-section-media>

    <!-- Upload Asset -->
    <div [hidden]="!!(viewModel.sectionAsset$ | async)" class="section-upload-area">
      <app-upload-asset
        #productGroupingAsset
        [allowImage]="true"
        [allowVideo]="true"
        [displayList]="false"
        [isDisabledByTemplates]="viewModel.isTemplatedSection$ | async"
        [formPristine]="formPristine"
        [maxAssets]="1"
        [parentHandler]="this"
        [loadingOpts]="viewModel.sectionAssetLoadingOpts$ | async"
        [id]="index">
      </app-upload-asset>
    </div>
  </div>

  <div class="bs-section-body-buttons mt-2" fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="end">
    <lib-button-inline-text [disabled]="sharedViewModel?.autoSaving$ | async" (buttonClicked)="editSection()">
      Edit
    </lib-button-inline-text>

    <lib-button-inline-text
      [disabled]="
        (sharedViewModel?.autoSaving$ | async) || (sharedViewModel?.isTemplatedMenu$ | async) || !formPristine
      "
      [disabledTooltip]="
        (sharedViewModel?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="this.duplicateClicked.emit(section)">
      Duplicate
    </lib-button-inline-text>

    <lib-button-inline-text
      [textColor]="'#FA5555'"
      [disabled]="
        (sharedViewModel?.autoSaving$ | async) || (sharedViewModel?.isTemplatedMenu$ | async) || !formPristine
      "
      [disabledTooltip]="
        (sharedViewModel?.isTemplatedMenu$ | async | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      "
      (buttonClicked)="this.deleteClicked.emit(section)">
      Delete
    </lib-button-inline-text>
  </div>
</div>
