import { TypeDefinition } from './type-definition';

export enum DefaultPrintLabelPaperSize {
  DefaultSize_LetterLabel_Uline = 'DefaultSize_LetterLabel_Uline',
  DefaultSize_Letter_CustomCut = 'DefaultSize_Letter_CustomCut'
}

export class DefaultPrintLabelPaperSizeType extends TypeDefinition {

  override value: DefaultPrintLabelPaperSize;

}
