import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Orderable } from '../../../../models/protocols/orderable';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';

@Component({
  selector: 'app-reorder-list-item',
  templateUrl: './reorder-list-item.component.html',
  styleUrls: ['./reorder-list-item.component.scss']
})
export class ReorderListItemComponent implements AfterViewInit, OnDestroy {

  @Input() item: Orderable;
  @Input() showIcons: boolean;
  @ViewChild('reorderItem') reorderItem: ElementRef;
  private ro: ResizeObserver;
  private _reorderItemHeight = new BehaviorSubject<string>(null);
  public reorderItemHeight$ = this._reorderItemHeight as Observable<string>;

  ngAfterViewInit(): void {
    this.ro = new ResizeObserver(_ => {
      const reorderItemHeight = this.reorderItem.nativeElement?.getBoundingClientRect()?.height?.toString();
      this._reorderItemHeight.next(reorderItemHeight);
    });
    this.ro.observe(this.reorderItem.nativeElement);
  }

  ngOnDestroy() {
    this.ro?.disconnect();
  }

}
