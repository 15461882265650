import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { Variant } from '../../../../models/product/dto/variant';

@Component({
  selector: 'app-label-stack-print-job-select-variant-count',
  templateUrl: './label-stack-print-job-select-variant-count.component.html',
  styleUrls: ['./label-stack-print-job-select-variant-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelStackPrintJobSelectVariantCountComponent {

  @Input() dispersedKey: string;
  @Input() variant: Variant;
  @Input() variantCountMap: Map<string, number>;
  @Input() viewOnly: boolean;
  @Output() updateVariantCountMap = new EventEmitter<number>(true);

}
