import { Injectable } from '@angular/core';
import { CombinedLoadingOptsViewModel } from '../../../../shared/components/auto-save/combined-loading-opts-view-model';
import { EditTemplateCardStackMenuViewModel } from './edit-template-card-stack-menu-view-model';
import { EditCardStackMenuViewModel } from '../../../../menu/components/edit-menu/edit-card-stack-menu/edit-card-stack-menu-view-model';
import { EditCardStackSectionViewModel } from '../../../../menu/components/edit-menu/edit-card-stack-menu/edit-card-stack-section-view-model';

@Injectable()
export class EditTemplateCardStackMenuCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateCardStackMenuViewModel: EditTemplateCardStackMenuViewModel,
    protected editCardStackMenuViewModel: EditCardStackMenuViewModel,
    protected editCardStackSectionViewModel: EditCardStackSectionViewModel
  ) {
    super([editTemplateCardStackMenuViewModel, editCardStackMenuViewModel, editCardStackSectionViewModel]);
  }

}
