<div [ngClass]="{ 'truncate-text': truncate }">
  <img *ngIf="addedByTemplate" alt="" [src]="'/assets/icons/dark/solid/template.svg'" ngbTooltip="Added by template" />
  <img *ngIf="isMedical" class="medical-symbol" alt="" [src]="'/assets/icons/green/outline/medicinal.svg'" />
  {{ displayName }}
  <img
    *ngIf="isCultivatorVerified"
    [ngbTooltip]="'Product information is verified by the cultivator'"
    [container]="'body'"
    alt=""
    src="assets/icons/dark/solid/badge-check.svg" />
  <button
    [hidden]="!variantTooltip"
    [ngbTooltip]="variantTooltip"
    [placement]="variantTooltipPlacement"
    class="inline-tooltip">
    <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
  </button>
</div>
