import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { ColumnOptionsFormComponent } from '../column-options-form/column-options-form.component';
import { ColumnOptionsBrandViewModel } from './column-options-brand-view-model';

@Component({
  selector: 'app-column-options-brand',
  templateUrl: '../column-options-form/column-options-form.component.html',
  styleUrls: ['../column-options-form/column-options-form.component.scss'],
  providers: [ColumnOptionsBrandViewModel]
})
export class ColumnOptionsBrandComponent extends ColumnOptionsFormComponent implements Tabbable {

  constructor(
    viewModel: ColumnOptionsBrandViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
