import { SecondaryCannabinoid } from '../../utils/dto/secondary-cannabinoids-type-definition';
import { PrimaryCannabinoid } from '../../enum/shared/primary-cannabinoid.enum';
import { StringUtils } from '../../../utils/string-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';

export class ProductTableColumnConfig implements Deserializable, UniquelyIdentifiable {

  public brand:  boolean;
  public productType:  boolean;
  public strainType:  boolean;
  public quantity:  boolean;
  public price:  boolean;
  public secondaryPrice:  boolean;
  public label:  boolean;
  public badge:  boolean;
  public THC:  boolean;
  public CBD:  boolean;
  public CBDA:  boolean;
  public CBG:  boolean;
  public CBGA:  boolean;
  public CBL:  boolean;
  public CBLA:  boolean;
  public CBN:  boolean;
  public CBNA:  boolean;
  public CBT:  boolean;
  public CBC:  boolean;
  public CBCA:  boolean;
  public CBCV:  boolean;
  public CBDV:  boolean;
  public THC8:  boolean;
  public THC9:  boolean;
  public THCA:  boolean;
  public THCV:  boolean;
  public TAC:  boolean;
  public totalTerpene:  boolean;
  public topTerpene:  boolean;
  public alphaBisabolol:  boolean;
  public alphaPinene:  boolean;
  public betaCaryophyllene:  boolean;
  public betaEudesmol:  boolean;
  public betaMyrcene:  boolean;
  public betaPinene:  boolean;
  public bisabolol:  boolean;
  public borneol:  boolean;
  public camphene:  boolean;
  public caryophylleneOxide:  boolean;
  public delta3Carene:  boolean;
  public eucalyptol:  boolean;
  public geraniol:  boolean;
  public guaiol:  boolean;
  public humulene:  boolean;
  public linalool:  boolean;
  public limonene:  boolean;
  public ocimene:  boolean;
  public threeCarene:  boolean;
  public terpinene:  boolean;
  public terpinolene:  boolean;
  public transNerolidol:  boolean;
  public valencene:  boolean;

  static formatColumnName(key: string): string {
    const upperKey = key.toUpperCase();
    const secondaryCannabinoids = Object.values(SecondaryCannabinoid).includes(upperKey as SecondaryCannabinoid);
    const cannabinoid = Object.values(PrimaryCannabinoid).includes(upperKey as PrimaryCannabinoid);
    return secondaryCannabinoids || cannabinoid
      ? upperKey
      : StringUtils.splitPascalCaseIntoSentence(StringUtils.toPascalCase(key));
  }

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return JSON.stringify(this);
  }

}
