import { EventEmitter, Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { FormInputItem } from '../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../models/shared/stylesheet/form-group-styling';
import { MediaForm } from '../../../models/menu/shared/media-form';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import type { EditMarketingMenuViewModel } from './edit-marketing-menu-view-model';
import { BudsenseFile } from '../../../models/shared/budsense-file';
import { Asset } from '../../../models/image/dto/asset';
import { BsError } from '../../../models/shared/bs-error';
import { ToastService } from '../../../services/toast-service';
import { MarketingTheme } from '../../../models/enum/dto/theme.enum';
import { LoadingSpinnerSize } from '../../../models/enum/shared/loading-spinner-size.enum';
import { delay, map, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, defer, Observable, Subscription, throwError } from 'rxjs';
import { ColorUtils } from '../../../utils/color-utils';
import { DateUtils } from '../../../utils/date-utils';
import { MediaUtils } from '../../../utils/media-utils';

@Injectable()
export class MenuMediaViewModel extends BaseViewModel {

  private sharedViewModel: EditMarketingMenuViewModel;

  // Asset
  public _file = new BehaviorSubject<Asset>(null);
  public file$ = this._file as Observable<Asset>;

  // Form
  public formItems: FormInputItem[] = [];
  public req: MediaForm;
  public styling: FormGroupStyling = new FormGroupStyling();
  public hydrateInputObject: EventEmitter<any> = new EventEmitter<any>();
  public validateForm: EventEmitter<any> = new EventEmitter<any>();
  private _replaceMediaSwitch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public replaceMediaSwitch$ = this._replaceMediaSwitch as Observable<boolean>;
  public hasFileAndReplaceSwitchActivated$ = combineLatest([
    this.file$,
    this.replaceMediaSwitch$
  ]).pipe(
    map(([file, replaceMediaSwitch]) => !!file && !replaceMediaSwitch)
  );
  public uploadVisible$ = combineLatest([
    this.file$,
    this.replaceMediaSwitch$
  ]).pipe(
    map(([file, replaceMediaSwitch]) => !!file && replaceMediaSwitch)
  );
  public showUploadAsset$ = combineLatest([
    this.file$,
    this.replaceMediaSwitch$
  ]).pipe(
    map(([file, replaceMediaSwitch]) => !file || replaceMediaSwitch)
  );

  // Loading
  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(this.getBaseLoadingOpts());
  protected _refreshingMediaLoadingOpts = new BehaviorSubject<LoadingOptions>(this.getRefreshMediaLoadingOpts());
  refreshingMediaLoadingOpts$ = defer(() => this._refreshingMediaLoadingOpts);
  isRefreshingMediaLoading$ = this.refreshingMediaLoadingOpts$.pipe(map(it => it?.isLoading), startWith(false));

  // subs
  private refreshLoadingIndicatorSub: Subscription;
  private deletingLoadingIndicatorSub: Subscription;

  constructor(
    protected domainModel: MenuDomainModel,
    protected toastService: ToastService,
  ) {
    super();
  }

  initSharedModel(sharedViewModel: EditMarketingMenuViewModel) {
    this.sharedViewModel = sharedViewModel;
    //
    this.refreshLoadingIndicatorSub?.unsubscribe();
    this.refreshLoadingIndicatorSub = combineLatest([
      this.file$,
      this.sharedViewModel.uploadedAssetQueue.notNull(),
      this.sharedViewModel.menu$.notNull()
    ]).pipe(takeUntil(this.onDestroy)).subscribe(([file, assetQ, menu]) => {
      if (menu.theme === MarketingTheme.MarketingPlaylist) {
        // Only show loading in menu media component for looping media. this already happens in product variant wrapper
        this.setAssetRefreshingLoadingIndicator(file, assetQ || []);
      }
    });
    this.deletingLoadingIndicatorSub?.unsubscribe();
    this.deletingLoadingIndicatorSub = combineLatest([
      this.file$,
      this.sharedViewModel.deleteAssetQueue.notNull(),
      this.sharedViewModel.menu$.notNull()
    ]).pipe(takeUntil(this.onDestroy)).subscribe(([file, files, menu]) => {
      if (menu.theme === MarketingTheme.MarketingPlaylist) {
        // Only show loading in menu media component for looping media. this already happens in product variant wrapper
        this.setAssetDeletingLoadingIndicator(file, files || []);
      }
    });
  }

  // noinspection JSMethodCanBeStatic
  private getBaseLoadingOpts(): LoadingOptions {
    const loadingOpts = LoadingOptions.default();
    loadingOpts.backgroundColor = ColorUtils.BUDSENSE_OFF_WHITE_COLOR;
    loadingOpts.color = '#2c4058';
    loadingOpts.spinnerColor = '#2c4058';
    loadingOpts.cornerRadiusRem = 0.25;
    loadingOpts.zIndex = 50;
    return loadingOpts;
  }

  // noinspection JSMethodCanBeStatic
  private getRefreshMediaLoadingOpts(): LoadingOptions {
    const refreshingMediaLoadingOpts = LoadingOptions.default();
    refreshingMediaLoadingOpts.showLoadingText = false;
    refreshingMediaLoadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    refreshingMediaLoadingOpts.backgroundColor = 'rgba(255, 255, 255, 0.5)';
    refreshingMediaLoadingOpts.color = '#2c4058';
    refreshingMediaLoadingOpts.spinnerColor = '#2c4058';
    return refreshingMediaLoadingOpts;
  }

  replaceMedia(existingFile: Asset, newFile: BudsenseFile, vId: string, useVariantId: boolean) {
    combineLatest([this.sharedViewModel.menu$, this.sharedViewModel.orderedMedia$]).once(([menu, media]) => {
      const lm = `Uploading ${MediaUtils.getFileNameWithoutTimestamp(newFile.name)}`;
      if (!this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
        let skipDelete = false;
        if (useVariantId && existingFile) {
          const variantsUsingFile = Array.from(menu?.variantFeature?.assetNameMap?.values())
            ?.filter(fn => fn === existingFile.fileName);
          if (variantsUsingFile.length > 1) {
            skipDelete = true;
          }
        }
        this.sharedViewModel.replaceAsset(media, existingFile, newFile, vId, useVariantId, skipDelete).subscribe({
          complete: () => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishSuccessMessage(
              `Successfully uploaded ${MediaUtils.getFileNameWithoutTimestamp(newFile.name)}`,
              'Media Uploaded'
            );
            this.req.date = DateUtils.formatUnixToDateTime(DateUtils.currentTimestamp());
            this.req = window?.injector?.Deserialize?.instanceOf(MediaForm, this.req);
          },
          error: (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  }

  toggleReplaceMediaSwitch() {
    const currentValue = this._replaceMediaSwitch.getValue();
    this._replaceMediaSwitch.next(!currentValue);
  }

  public setReplaceMediaSwitch(replaceMediaSwitch: boolean): void {
    this._replaceMediaSwitch.next(replaceMediaSwitch);
  }

  private setAssetRefreshingLoadingIndicator(asset: Asset, assetQ: BudsenseFile[]) {
    const lm = 'Getting Media';
    const assetToLoad = assetQ?.find(a => a.name === asset?.fileName);
    if (assetToLoad && !this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
    } else if (!assetToLoad && this._loadingOpts.containsRequest(lm)) {
      setTimeout(() => {
        // Add slight delay to ensure the assets loads before the loading spinner disappears
        this._loadingOpts.removeRequest(lm);
      }, 100);
    }
  }

  private setAssetDeletingLoadingIndicator(asset: Asset, assets: Asset[]) {
    const lm = 'Removing Media';
    const assetToDelete = assets.find(a => a.fileName === asset?.fileName);
    if (assetToDelete && !this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
    } else if (!assetToDelete && this._loadingOpts.containsRequest(lm)) {
      setTimeout(() => {
        // Add slight delay to ensure the assets loads before the loading spinner disappears
        this._loadingOpts.removeRequest(lm);
      }, 100);
    }
  }

  public refreshImage() {
    const lm = 'Refreshing Media';
    if (!this._refreshingMediaLoadingOpts.containsRequest(lm)) {
      this._refreshingMediaLoadingOpts.addRequest(lm);
      this.domainModel.refreshImage(this._file.getValue()).pipe(delay(500)).subscribe((refreshedImg) => {
        this._file.next(refreshedImg);
        const refreshDelay = 2500;
        const refreshCount = 3;
        const removeRequest = () => this._refreshingMediaLoadingOpts.removeRequest(lm);
        setTimeout(removeRequest, (refreshDelay * refreshCount));
      }, (error: BsError) => {
        this._refreshingMediaLoadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  connectToFile = (file: Asset) => this._file.next(file);

}
