<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <input hidden #input [formControlName]="inputName" [id]="id" [name]="inputName" />
</div>

<div class="selected-products-container">
  <app-product-grouping-info-list-item
    *rxFor="let productId of productIds$; trackBy: trackByProductId"
    [product]="productId | productFromId | push"
    (deleteFromGroup)="deleteFromGroup($event)">
  </app-product-grouping-info-list-item>
</div>
