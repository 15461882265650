import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditSectionForm } from '../../../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form';
import { EditSmartPlaylistMenuSectionFormViewModel } from './edit-smart-playlist-menu-section-form-view-model';

@Component({
  selector: 'app-edit-smart-playlist-menu-section-form',
  templateUrl: './edit-smart-playlist-menu-section-form.component.html',
  styleUrls: ['./edit-smart-playlist-menu-section-form.component.scss'],
  providers: [EditSmartPlaylistMenuSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSmartPlaylistMenuSectionFormComponent extends EditSectionForm {

  constructor(
    public override viewModel: EditSmartPlaylistMenuSectionFormViewModel
  ) {
    super(viewModel);
  }

}
