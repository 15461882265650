import { Component, Input } from '@angular/core';
import { Orderable } from '../../../../models/protocols/orderable';
import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const DragConfig = {
  zIndex: 1051 // More than a modal z-index
};

@Component({
  selector: 'app-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss'],
  providers: [{provide: CDK_DRAG_CONFIG, useValue: DragConfig}]
})

export class ReorderListComponent {

  @Input() public items: Orderable[];
  @Input() public orderPrefix: number = 0; // amount to append to list items based on section grouping
  @Input() public showIcons: boolean;

  constructor() {
  }

  drop(event: CdkDragDrop<Orderable[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.setSortedPriority();
  }

  setSortedPriority() {
    this.items.forEach((item, index) => {
      item.setOrderableValue(this.orderPrefix + index);
    });
  }

}
