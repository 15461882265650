// noinspection JSUnusedLocalSymbols

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { DisplayAttribute } from '../../../../models/display/dto/display-attribute';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { SortUtils } from '../../../../utils/sort-utils';
import { BadgeUpdate } from '../../../../models/enum/dto/badge-update.enum';
import { InlineBadgePickerMode } from './inline-badge-picker-mode.enum';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements } from 'ngx-popperjs';
import { PopperUtils } from '../../../../utils/popper-utils';

/**
 * Creates an internal deep copy of the passed in displayAttribute.
 * Therefore, the passed in displayAttribute can be modified without affecting the original.
 *
 * If the mode is set to DisplayAttributeInput:
 * - relies on displayAttribute input
 * - changes cause an updated displayAttribute with the attached badges to emit from updatedDisplayAttribute
 *
 * If the mode is set to BadgesInput:
 * - relies on badges input
 * - send in display attribute if you care about finding smart badges
 * - changes cause the updated badge list to emit from updatedBadges
 */
@Component({
  selector: 'app-inline-badge-picker',
  templateUrl: './inline-badge-picker.component.html',
  styleUrls: ['./inline-badge-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineBadgePickerComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @Input() mode: InlineBadgePickerMode = InlineBadgePickerMode.DisplayAttributeInput;
  @Input() badges: HydratedVariantBadge[];
  @Input() displayAttribute: DisplayAttribute;
  @Input() disabled: boolean = false;
  @Input() maxBadgesAllowed: number = 100;
  @Input() popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.LEFT;
  @Input() addText: string = 'Add Badge';
  @Input() removeSmartBadgesFromPicker = false;
  @Input() wrapBadges: boolean = false;
  @Input() contentAliveWhileClosed: boolean = false;
  @Output() badgeUpdate = new EventEmitter<[HydratedVariantBadge, BadgeUpdate]>(true);
  @Output() updatedBadges = new EventEmitter<HydratedVariantBadge[]>(true);
  @Output() updatedDisplayAttribute = new EventEmitter(true);
  @ViewChild(NgxPopperjsContentComponent) popperContent: NgxPopperjsContentComponent;

  private _DA = new BehaviorSubject<DisplayAttribute>(null);
  public DA$ = this._DA as Observable<DisplayAttribute>;
  private listenToDAToSetBadges = this.DA$.subscribeWhileAlive({
    owner: this,
    next: da => {
      if (!!da && this.displayAttributeMode()) this._badges.next(da?.badges ?? []);
    }
  });

  private _badges = new BehaviorSubject<HydratedVariantBadge[]>([]);
  public badges$ = this._badges.pipe(map(badges => badges?.sort(SortUtils.sortBadgesByNameAscending)));
  public badgeIds$ = this.badges$.pipe(map(badges => badges?.map(b => b.id)));

  started: boolean = false;

  // Popper
  public popperModifier = [
    PopperUtils.flipModifier([
      NgxPopperjsPlacements.RIGHT,
      NgxPopperjsPlacements.TOPEND,
      NgxPopperjsPlacements.TOP
    ])
  ];
  public popperStyles = { 'background-color': '#FFFFFF' };

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.popperContent.hide();
    this.started = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayAttribute) {
      this.createNewInstance();
    }
    if (!!changes.badges && this.badgesMode()) this._badges.next(!!this.badges ? [...this.badges] : []);
  }

  private createNewInstance() {
    const newDA = window?.injector?.Deserialize?.instanceOf(DisplayAttribute, this.displayAttribute);
    this._DA.next(newDA);
  }

  private updateBadges(existingBadges: HydratedVariantBadge[], selectedBadge: HydratedVariantBadge): BadgeUpdate {
    let updateType: BadgeUpdate;
    const i = existingBadges?.findIndex(b => b.id === selectedBadge?.id);
    if (i > -1) {
      existingBadges.splice(i, 1);
      updateType = BadgeUpdate.Remove;
    } else {
      existingBadges.push(selectedBadge);
      updateType = BadgeUpdate.Add;
    }
    return updateType;
  }

  updateDisplayAttribute(badge: HydratedVariantBadge) {
    if (this.displayAttributeMode()) {
      const existingDa = this._DA.getValue();
      const existingBadges = existingDa?.badges ?? [];
      const updateType = this.updateBadges(existingBadges, badge);
      existingDa.badgeIds = existingBadges.map(b => b.id);
      existingDa.badges = existingBadges;
      this._DA.next(existingDa);
      this.badgeUpdate.emit([badge, updateType]);
      this.updatedDisplayAttribute.emit(this.DA$);
    } else {
      const updatedBadges = this._badges.getValue() ?? [];
      const updateType = this.updateBadges(updatedBadges, badge);
      this._badges.next([...updatedBadges]);
      this.badgeUpdate.emit([badge, updateType]);
      this.updatedBadges.emit([...updatedBadges]);
    }
  }

  closePopper(): void {
    this.popperContent?.hide();
  }

  private displayAttributeMode = (): boolean => this.mode === InlineBadgePickerMode.DisplayAttributeInput;
  private badgesMode = (): boolean => this.mode === InlineBadgePickerMode.BadgesInput;
  public smartBadgeTooltipText = `The item cannot be deleted because it was added using smart badge functionality.`;

}
