import { Component } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { DashboardViewModel } from './dashboard-view-model';
import { TypeService } from '../../../../services/type-service';
import { domainModelsGloballyScopedToLoginContext, servicesGloballyScopedToLoginContext, viewModelsGloballyScopedToLoginContext } from '../../../../di/logged-in-scope';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    ...domainModelsGloballyScopedToLoginContext,
    ...viewModelsGloballyScopedToLoginContext,
    ...servicesGloballyScopedToLoginContext,
    DashboardViewModel
  ]
})
export class DashboardComponent extends BaseComponent {

  /**
   * Do not remove typeService from constructor, or else type service does not work.
   * Do not remove smartFiltersDomainModel from constructor, we want to init the SF domain model on login.
   */
  constructor(
    private typeService: TypeService,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private userDomainModel: UserDomainModel,
    public viewModel: DashboardViewModel,
    private ngbModal: NgbModal
  ) {
    super();
  }

  public _loadingOpts = new BehaviorSubject(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));

  public sidebarMinimized = false;

  override setupBindings() {
    const refSub = this.userDomainModel.refreshingSessionUser$.notNull().subscribe((ref) => {
      const lm = 'Refreshing Session';
      if (!this._loadingOpts.containsRequest(lm) && ref) {
        this._loadingOpts.addRequest(lm);
      } else if (this._loadingOpts.containsRequest(lm) && !ref) {
        this._loadingOpts.removeRequest(lm);
      }
    });
    this.pushSub(refSub);
    const sessSub = this.userDomainModel.user$.subscribe((user) => {
      if (!user) {
        this.sidebarMinimized = true;
      } else {
        this.sidebarMinimized = !user?.session?.validSession();
      }
    });
    this.pushSub(sessSub);
  }

  override setupViews() {
    this.setupLoadingOpts();
  }

  setupLoadingOpts() {
    this.loadingOpts$.once(opts => {
      opts.zIndex = 100;
      this._loadingOpts.next(opts);
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  /**
   * Dismiss all open models when logged in context dies.
   */
  override destroy() {
    super.destroy();
    this.ngbModal.hasOpenModals() && this.ngbModal.dismissAll();
  }

}
