import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReorderOptions } from '../models/shared/stylesheet/reorder-options';
import { OrderableMenuAsset } from '../models/menu/shared/orderable-menu-asset';
import { Observable } from 'rxjs';
import { ReorderModalComponent } from '../views/shared/components/reorder-modal/reorder-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { SortUtils } from '../utils/sort-utils';
import { Injector } from '@angular/core';

export class ModalReorderProductMenuAssets {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    reOrderOptions: ReorderOptions,
    orderedMenuAssets: OrderableMenuAsset[],
    reorderOperation: (ordered: OrderableMenuAsset[]) => Observable<any>,
    onClose?: (returnedValue: any) => void
  ) {
    const modalRef = ngbModal.open(ReorderModalComponent, ModalUtils.reorderModalOptions(injector));
    const inst = modalRef.componentInstance as ReorderModalComponent;
    inst.setReorderOptions(reOrderOptions);
    const order = orderedMenuAssets.sort(SortUtils.menuAssets);
    inst.setReorderItems(order);
    inst.reorderOperation = reorderOperation;
    modalRef.result
      .then(returnedValue => {
        onClose?.call(returnedValue);
      })
      .catch(() => {});
  }

}
