// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { AutomationAPI } from '../api/automation-api';
import { BehaviorSubject, combineLatest, EMPTY, iif, merge, Observable, of, Subject, throwError } from 'rxjs';
import { SmartFilter } from '../models/automation/smart-filter';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { LocationDomainModel } from './location-domain-model';
import { Section } from '../models/menu/dto/section';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { Menu } from '../models/menu/dto/menu';
import { SelectableSmartFilter } from '../models/automation/protocols/selectable-smart-filter';
import { SmartFilterGrouping } from '../models/automation/smart-filter-grouping';
import { SortUtils } from '../utils/sort-utils';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from '../services/toast-service';
import { ProductDomainModel } from './product-domain-model';
import { exists } from '../functions/exists';
import { iiif } from '../utils/observable.extensions';
import { fromWorker } from 'observable-webworker';
import type { SmartFilterWorkerInput, SmartFilterWorkerOutput } from '../worker/smart-filter-variant-matcher.worker';
import { StringifyUtils } from '../utils/stringify-utils';
import { CompanyDomainModel } from './company-domain-model';

export const STALE_SMART_FILTER_TIMEOUT = 300000; // 5 min

// Provided by Logged In Scope
@Injectable()
export class SmartFiltersDomainModel extends BaseDomainModel {

  constructor(
    private automationAPI: AutomationAPI,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private productDomainModel: ProductDomainModel,
    private toastService: ToastService,
  ) {
    super();
    this.fetchSmartFiltersForLocation();
  }

  private readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  private readonly showDiscontinuedProducts$ = this.companyConfig$.pipe(map(c => c?.showDiscontinuedProducts));
  private readonly locationId$ = this.locationDomainModel.locationId$;
  private inventoryProviderSupportsIndividualTerpeneValues$ = this
    .companyDomainModel
    .inventoryProviderSupportsIndividualTerpeneValues$;

  private readonly currentLocationAllVariantsStringified$ = this.productDomainModel.currentLocationVariants$.pipe(
    map(variants => !variants ? null : JSON.stringify(variants, StringifyUtils.webWorkerReplacer)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly currentLocationStringifiedActiveVariants$ = this
    .productDomainModel
    .currentLocationActiveVariants$
    .pipe(
      map(variants => !variants ? null : JSON.stringify(variants, StringifyUtils.webWorkerReplacer)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  public readonly stringifiedVariantSourceForSmartFilterMatching$ = iiif(
    this.showDiscontinuedProducts$,
    this.currentLocationAllVariantsStringified$,
    this.currentLocationStringifiedActiveVariants$
  );

  // Smart filters that they have created
  private _clientLocationSmartFilters = new BehaviorSubject<HydratedSmartFilter[]|null>(null);
  public clientLocationSmartFilters$ = this.buildLocationSmartFilters$('location', this._clientLocationSmartFilters);

  public clientLocationSmartFilterIds$ = this.clientLocationSmartFilters$.pipe(
    map(filters => filters?.map(smartFilter => smartFilter?.id))
  );
  public clientLocationGroupedSmartFilters$ = this.clientLocationSmartFilters$.pipe(
    map(clientLocationSmartFilters => this.getGroupedSmartFilters(clientLocationSmartFilters))
  );

  // Curated Smart Filters
  private readonly _curatedSmartFilters = new BehaviorSubject<HydratedSmartFilter[]|null>(null);
  public readonly curatedSmartFilters$ = this.buildLocationSmartFilters$('curated', this._curatedSmartFilters);
  public readonly curatedDefaultSmartFilters$ = this.curatedSmartFilters$.pipe(
    map(smartFilters => smartFilters?.filter(f => !f.advancedFilter))
  );
  public readonly curatedAdvancedSmartFilters$ = this.curatedSmartFilters$.pipe(
    map(smartFilters => smartFilters?.filter(f => !!f.advancedFilter))
  );
  public readonly curatedGroupedSmartFilters$ = this.curatedSmartFilters$.pipe(
    map(curatedSmartFilter => this.getGroupedSmartFilters(curatedSmartFilter))
  );
  public readonly curatedDefaultGroupedSmartFilters$ = this.curatedDefaultSmartFilters$.pipe(
    map(curatedDefaultSmartFilters => this.getGroupedSmartFilters(curatedDefaultSmartFilters))
  );
  public readonly curatedAdvancedGroupedSmartFilters$ = this.curatedAdvancedSmartFilters$.pipe(
    map(curatedAdvancedSmartFilters => this.getGroupedSmartFilters(curatedAdvancedSmartFilters))
  );

  // All Smart Filters
  public allSmartFilters$ = combineLatest([
    this.clientLocationSmartFilters$,
    this.curatedSmartFilters$
  ]).pipe(
    map(([location, curated]) => (location ?? []).concat(curated ?? [])),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Listen to all Smart Filters and products &
  // update the Smart Filters if either a product or smart filter has been updated
  private _fetchAllSmartFilters = new Subject<void>();
  private listenToUpdateSmartFiltersMech = this._fetchAllSmartFilters.pipe(
    debounceTime(5000)
  ).subscribe(() => {
    this.getSmartFiltersForLocation().once();
    this.getCuratedSmartFiltersForLocation().once();
  });

  private _fetchingSmartFilters = new BehaviorSubject<boolean>(false);
  public fetchingSmartFilters$ = this._fetchingSmartFilters as Observable<boolean>;
  private _lastFetchedSmartFilters = new BehaviorSubject<number>(null);
  public lastFetchedSmartFilters$ = this._lastFetchedSmartFilters as Observable<number>;
  private _lastFetchedCuratedSmartFilters = new BehaviorSubject<number>(null);
  public lastFetchedCuratedSmartFilters$ = this._lastFetchedCuratedSmartFilters as Observable<number>;

  private _currentlySyncingSections = new BehaviorSubject<Section[]>([]);
  public currentlySyncingSections$ = this._currentlySyncingSections as Observable<Section[]>;

  public isSectionBeingSynced$(section$: Observable<Section>): Observable<boolean> {
    return combineLatest([
      section$,
      this.currentlySyncingSections$
    ]).pipe(
      map(([section, sectionsBeingSynced]) => {
        return !!sectionsBeingSynced?.find(s => s?.id === section?.id);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private readonly _smartFilterWorkerMessage = new Subject<SmartFilterWorkerInput>();
  public readonly smartFilterWorkerMessage$ = this._smartFilterWorkerMessage as Observable<SmartFilterWorkerInput>;
  connectToSmartFilterWorkerMessage = (msg: SmartFilterWorkerInput) => this._smartFilterWorkerMessage.next(msg);

  protected readonly smartFilterWorkerOutput$ = fromWorker<SmartFilterWorkerInput, SmartFilterWorkerOutput>(
    () => new Worker(
      new URL('./../worker/smart-filter-variant-matcher.worker', import.meta.url),
      { name: 'Products Table Smart Filter Matcher', type: 'module' }
    ),
    this.smartFilterWorkerMessage$
  ).pipe(
    shareReplay(1)
  );

  protected buildLocationSmartFilters$(
    groupId: string,
    smartFilters$: Observable<HydratedSmartFilter[]>,
  ): Observable<HydratedSmartFilter[]> {
    const sortedSmartFilters$ = smartFilters$.pipe(
      map(filters => filters?.sort(SortUtils.sortHydratedSmartFiltersByName))
    );
    const buildFilters$ = combineLatest([
      sortedSmartFilters$,
      this.stringifiedVariantSourceForSmartFilterMatching$,
      this.locationId$.pipe(distinctUntilChanged()),
      this.companyConfig$,
      this.inventoryProviderSupportsIndividualTerpeneValues$
    ]).pipe(
      debounceTime(1000),
      switchMap(([filters, locVariantsStringified, locationId, companyConfig, supportsIndividualTerpeneValues]) => {
        if (!filters) return of(null);
        const stringify = (str: any) => JSON.stringify(str, StringifyUtils.webWorkerReplacer);
        setTimeout(() => {
          this.connectToSmartFilterWorkerMessage({
            groupingId: groupId,
            smartFilters: stringify(filters),
            locationVariants: locVariantsStringified,
            locationId,
            companyConfig: stringify(companyConfig),
            supportsIndividualTerpeneValues
          });
        });
        return this.smartFilterWorkerOutput$.pipe(
          filter(({ groupingId }) => groupingId === groupId),
          map(({ smartFilterMatches }) => {
            return filters?.map(smartFilter => {
              const match = smartFilterMatches?.find(({ smartFilterId }) => smartFilterId === smartFilter?.id);
              const inStockVariantIds = match?.inStockVariantIds?.split(',')?.filterFalsies();
              const outOfStockVariantIds = match?.outOfStockVariantIds?.split(',')?.filterFalsies();
              return smartFilter?.setSmartFilterVariantMatches(inStockVariantIds, outOfStockVariantIds);
            });
          })
        );
      })
    );
    const waitForVariantsAndSmartFiltersAndLocationId$ = combineLatest([
      this.stringifiedVariantSourceForSmartFilterMatching$,
      sortedSmartFilters$,
      this.locationId$
    ]).pipe(
      map(([variants, smartFilters, locationId]) => !variants || !smartFilters || !locationId),
      distinctUntilChanged()
    );
    return iiif(waitForVariantsAndSmartFiltersAndLocationId$, of(null), buildFilters$).pipe(
      shareReplay(1),
      takeUntil(this.onDestroy)
    );
  }

  fetchSmartFiltersForLocation() {
    // fetch smart filters based on locationId
    const fetchSmartFiltersSub = this.locationDomainModel.locationId$.pipe(
      tap(_ => this._fetchingSmartFilters.next(true)),
      distinctUntilChanged(),
      debounceTime(100),
      switchMap(locationId => {
        if (!locationId) return of(null);
        else return merge(this.getSmartFiltersForLocation(), this.getCuratedSmartFiltersForLocation());
      })
    ).subscribe({
      next: () => this._fetchingSmartFilters.next(false),
      error: (err: BsError) => {
        this._fetchingSmartFilters.next(false);
        this.toastService.publishError(err);
      }
    });
    this.pushSub(fetchSmartFiltersSub);
  }

  private getGroupedSmartFilters(filters: HydratedSmartFilter[]): SelectableSmartFilter[] {
    if (!filters) return null;
    const groups: SmartFilterGrouping[] = [];
    const noGroup: HydratedSmartFilter[] = filters?.filter(sf => !sf?.getStandardizedCategoryName()) ?? [];
    const categories = filters
      ?.map(smartFilter => smartFilter?.getStandardizedCategoryName() ?? '')
      ?.unique()
      ?.filter(category => !!category);
    categories?.forEach(category => {
      const group = new SmartFilterGrouping(category);
      group.addToGroup(filters?.filter(smartFilter => smartFilter?.getStandardizedCategoryName() === category) ?? []);
      groups.push(group);
    });
    return [
      ...groups.sort(SortUtils.sortSmartFilterGroupingsByPriority),
      ...noGroup.sort(SortUtils.sortSelectableSmartFilterByName)
    ];
  }

  createSmartFilter(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        return this.automationAPI.createSmartFilter(smartFilter, id).pipe(
          map(createdSmartFilter => {
            const existing = (this._clientLocationSmartFilters.getValue() || []).shallowCopy();
            existing.push(createdSmartFilter);
            this._clientLocationSmartFilters.next(existing);
            return createdSmartFilter;
          })
        );
      })
    );
  }

  // For use in smart filter duplication, does not update 'clientLocationSmartFilters'
  createSmartFilterForDifferentLocation(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        return this.automationAPI.createSmartFilter(smartFilter, id);
      })
    );
  }

  getSmartFiltersForLocation(): Observable<HydratedSmartFilter[]> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        this._lastFetchedSmartFilters.next(Date.now());
        return this.automationAPI.getSmartFilters(String(id)).pipe(
          tap(filters => {
            const uniqueFilters = filters.uniqueByProperty('id');
            this._clientLocationSmartFilters.next(uniqueFilters);
          })
        );
      })
    );
  }

  getCuratedSmartFiltersForLocation(): Observable<HydratedSmartFilter[]> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        this._lastFetchedCuratedSmartFilters.next(Date.now());
        return this.automationAPI.getCuratedSmartFilters(String(id)).pipe(
          tap(filters => this._curatedSmartFilters.next(filters))
        );
      })
    );
  }

  private updatedAndReplaceSmartFilterPipe(
    topOfPipe$: Observable<HydratedSmartFilter[]>,
    _whichFilterGroup: BehaviorSubject<HydratedSmartFilter[]>
  ): Observable<HydratedSmartFilter[]> {
    return topOfPipe$.pipe(
      take(1),
      map(filters => {
        const existingCopy = _whichFilterGroup.getValue().shallowCopy();
        let updated = false;
        filters?.forEach(smartFilter => {
          const i = existingCopy?.indexOf(existingCopy.find(v => v.id === smartFilter.id));
          if (i > -1) {
            existingCopy.splice(i, 1, smartFilter);
            updated = true;
          }
        });
        if (updated) {
          _whichFilterGroup.next(existingCopy);
          this._fetchAllSmartFilters.next();
        }
        return filters;
      })
    );
}

  updateSmartFilter(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.updatedAndReplaceSmartFilterPipe(
          this.automationAPI.updateSmartFilter(smartFilter, locationId),
          this._clientLocationSmartFilters
        ).pipe(
          map(filters => filters?.firstOrNull()),
          switchMap(updated => this.checkIfSyncsAreNeededFromSmartFilterUpdate(locationId, updated))
        );
      })
    );
  }

  /**
   * These need to happen synchronously, so that when this call completes and a section is fetched, it will have the
   * correct data.
   */
  private checkIfSyncsAreNeededFromSmartFilterUpdate(
    locId: number,
    smartFilter: HydratedSmartFilter
  ): Observable<HydratedSmartFilter> {
    return of(smartFilter).pipe(
      switchMap(() => {
        // if smart filter is applied to a menu, then force sync location smart filters
        const sync = () => exists(smartFilter?.appliedMenuIds.length);
        const syncLocationSmartFilters$ = this.syncLocationSmartFilters(true).pipe(
          tap(() => this.toastService.publishSuccessMessage(`Location Smart Filters Synced`, `Success`))
        );
        return iif(sync, syncLocationSmartFilters$, of(null));
      }),
      switchMap(() => {
        // if smart filter is applied to a badge or label, then force sync smart display attributes
        const sync = () => exists(smartFilter?.appliedLabelIds.length) || exists(smartFilter?.appliedBadgeIds.length);
        const syncSmartDAs$ = this.locationDomainModel.syncSmartDisplayAttributes([`${locId}`], true).pipe(
          tap(() => this.toastService.publishSuccessMessage(`Smart Data Synced`, `Success`))
        );
        return iif(sync, syncSmartDAs$, of(null));
      }),
      map(() => smartFilter)
    );
  }

  deleteSmartFilter(smartFilter: SmartFilter): Observable<string> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        return this.automationAPI.deleteSmartFilter(smartFilter, id).pipe(
          map(s => {
            const existing = (this._clientLocationSmartFilters.getValue() || []).shallowCopy();
            const i = existing?.indexOf(existing.find(v => v.id === smartFilter.id));
            if (i > -1) {
              existing.splice(i, 1);
              this._clientLocationSmartFilters.next(existing);
            }
            return s;
          })
        );
      })
    );
  }

  syncSectionSmartFilters(menu: Menu, section: Section): Observable<HydratedSection> {
    return this.currentlySyncingSections$.pipe(
      take(1),
      switchMap(currentlySyncing => {
        const alreadySyncing = currentlySyncing?.some((s) => s?.id === section?.id);
        if (alreadySyncing) return EMPTY;
        this.addSectionBeingSynced(section);
        return this.automationAPI.syncSectionSmartFilters(menu?.id, section?.id).pipe(
          tap(_ => this.removeSectionBeingSynced(section)),
          catchError(err => {
            this.removeSectionBeingSynced(section);
            return throwError(() => err);
          })
        );
      })
    );
  }

  private getSpecificLocationSmartFilters(locationId: string, ids: string[]): Observable<HydratedSmartFilter[]> {
    return this.automationAPI.getSmartFilters(locationId, ids);
  }

  public getSpecificCuratedSmartFilters(locationId: string, ids: string[]): Observable<HydratedSmartFilter[]> {
    return this.automationAPI.getCuratedSmartFilters(locationId, ids).pipe(
      tap(filters => this.updateCuratedSmartFilters(filters))
    );
  }

  private updateCuratedSmartFilters(filtersToAdd: HydratedSmartFilter[]): void {
    this._curatedSmartFilters.once(existing => {
      const updated = (existing ?? []).shallowCopy();
      filtersToAdd.forEach(filterToAdd => {
        const i = updated?.indexOf(updated.find(sf => sf.id === filterToAdd.id));
        if (i > -1) {
          updated.splice(i, 1, filterToAdd);
        } else {
          updated.push(filterToAdd);
        }
      });
      this._curatedSmartFilters.next(updated);
    });
  }

  syncLocationSmartFilters(forceSync?: boolean): Observable<LocationConfiguration> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        return this.automationAPI.syncLocationSmartFilters(String(id), forceSync).pipe(
          tap(locationConfig => this.locationDomainModel.setLocationConfig(locationConfig))
        );
      })
    );
  }

  /**
   * @param menuIds
   * @param async should be false if the job contains stacked content (cards/labels/etc.)
   */
  syncSmartFiltersBeforePrintJob(menuIds: string[], async: boolean): Observable<LocationConfiguration> {
    return this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(id => {
        return this.automationAPI.syncSmartFiltersBeforePrintJob(String(id), menuIds, async).pipe(
          tap(locationConfig => this.locationDomainModel.setLocationConfig(locationConfig))
        );
      })
    );
  }

  fetchAllSmartFiltersAfterDelay(): void {
    this._fetchAllSmartFilters.next();
  }

  private addSectionBeingSynced(section: Section) {
    this.currentlySyncingSections$.once(sections => {
      const updateList = sections?.shallowCopy() || [];
      const sectionIndex = updateList?.findIndex(s => s?.id === section?.id);
      if (sectionIndex < 0) {
        updateList?.push(section);
        this._currentlySyncingSections.next(updateList);
      }
    });
  }

  private removeSectionBeingSynced(section: Section) {
    this.currentlySyncingSections$.once(sections => {
      const updateList = sections?.shallowCopy() || [];
      const sectionIndex = updateList?.findIndex(s => s?.id === section?.id);
      if (sectionIndex >= 0) {
        updateList?.splice(sectionIndex, 1);
        this._currentlySyncingSections.next(updateList);
      }
    });
  }

}
