import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Input, OnInit, ViewRef } from '@angular/core';
import { ReactiveTableRowBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { BehaviorSubject, Observable } from 'rxjs';
import { OverrideProductGroup } from '../../../../models/product/dto/override-product-group';
import { ProductGroupingTableViewModel } from '../product-grouping-table-view-model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-product-grouping-table-item',
  templateUrl: './product-grouping-table-item.component.html',
  styleUrls: ['./product-grouping-table-item.component.scss'],
  providers: [
    {
      provide: ReactiveTableRowBluePrintComponent,
      useExisting: forwardRef(() => ProductGroupingTableItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupingTableItemComponent extends ReactiveTableRowBluePrintComponent implements OnInit {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    changeDetector: ChangeDetectorRef,
    public viewModel: ProductGroupingTableViewModel // provided by parent component
  ) {
    super(viewRef, changeDetector);
  }

  @Input() override rowData: OverrideProductGroup;
  private readonly _rowData = new BehaviorSubject<OverrideProductGroup|null>(this.rowData);
  public readonly rowData$ = this._rowData as Observable<OverrideProductGroup|null>;

  public readonly productCount$ = this.rowData$.pipe(
    map(rowData => rowData?.productIds?.length || 0)
  );

  public readonly childVariantCount$ = this.rowData$.pipe(
    map(rowData => rowData?.products?.reduce((acc, curr) => acc + curr?.variants?.length ?? 0, 0) || 0)
  );

  ngOnInit() {
    this._rowData.next(this.rowData);
  }

  rowDataChanged(newRowData: any): void {
    this._rowData.next(newRowData);
  }

}
