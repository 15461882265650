import { Pipe, PipeTransform } from '@angular/core';
import { TemplateStatus } from '../../../../../models/template/enum/template-status.enum';
import { ColorUtils } from '../../../../../utils/color-utils';

@Pipe({
  name: 'statusLightColor'
})
export class StatusLightColorPipe implements PipeTransform {

  transform(templateStatus: TemplateStatus): string {
    switch (templateStatus) {
      case TemplateStatus.Published:
        return ColorUtils.BUDSENSE_GREEN;
    }
    return ColorUtils.BUDSENSE_SECONDARY_EXTRA_DARK_GRAY;
  }

}
