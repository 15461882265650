<div class="modal-body">
  <div class="modal-title">Choose a location</div>
  <lib-reactive-form-group>
    <lib-reactive-form-drop-down
      [inputName]="'activeLocation'"
      [programmaticallyChangeValue]="viewModel.activeLocationId$ | async"
      [dropdowns]="viewModel.sortedLocations$ | async"
      (valueChanged)="viewModel.connectToActiveLocationId($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-checkbox
      [inputName]="'defaultLocation'"
      [label]="'Set as default location'"
      (valueChanged)="viewModel.connectToRemember($event)">
    </lib-reactive-form-checkbox>
  </lib-reactive-form-group>
  <div class="footer-button">
    <button (click)="cancel()" class="bs-button outlined-button">Cancel</button>
    <button (click)="viewModel.save()" class="bs-button preferred-button">Save</button>
  </div>
</div>
