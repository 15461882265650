import { TypeDefinition } from './type-definition';

export enum SectionSortProductInfo {
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  CBDAsc = 'CBD_ASC',
  CBDDesc = 'CBD_DESC',
  ClassificationAsc = 'CLASSIFICATION_ASC',
  ClassificationDesc = 'CLASSIFICATION_DESC',
  ManufacturerAsc = 'MANUFACTURER_ASC',
  ManufacturerDesc = 'MANUFACTURER_DESC',
  PackagedQuantityAsc = 'PACKAGED_QUANTITY_ASC',
  PackagedQuantityDesc = 'PACKAGED_QUANTITY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  SecondaryPriceAsc = 'SECONDARY_PRICE_ASC',
  SecondaryPriceDesc = 'SECONDARY_PRICE_DESC',
  StockAsc = 'STOCK_ASC',
  StockDesc = 'STOCK_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  THCAsc = 'THC_ASC',
  THCDesc = 'THC_DESC',
  TopTerpeneAsc = 'TOP_TERPENE_ASC',
  TopTerpeneDesc = 'TOP_TERPENE_DESC',
  TotalTerpenesAsc = 'TOTAL_TERPENES_ASC',
  TotalTerpenesDesc = 'TOTAL_TERPENES_DESC',
  UnitSizeAsc = 'UNIT_SIZE_ASC',
  UnitSizeDesc = 'UNIT_SIZE_DESC',
  VariantTypeAsc = 'VARIANT_TYPE_ASC',
  VariantTypeDesc = 'VARIANT_TYPE_DESC',
}

export enum SectionSortSecondaryCannabinoids {
  CBDAAsc = 'CBDA_ASC',
  CBDADesc = 'CBDA_DESC',
  CBGAsc = 'CBG_ASC',
  CBGDesc = 'CBG_DESC',
  CBGAAsc = 'CBGA_ASC',
  CBGADesc = 'CBGA_DESC',
  CBLAsc = 'CBL_ASC',
  CBLDesc = 'CBL_DESC',
  CBLAAsc = 'CBLA_ASC',
  CBLADesc = 'CBLA_DESC',
  CBNAsc = 'CBN_ASC',
  CBNDesc = 'CBN_DESC',
  CBNAAsc = 'CBNA_ASC',
  CBNADesc = 'CBNA_DESC',
  CBTAsc = 'CBT_ASC',
  CBTDesc = 'CBT_DESC',
  CBCAsc = 'CBC_ASC',
  CBCDesc = 'CBC_DESC',
  CBCAAsc = 'CBCA_ASC',
  CBCADesc = 'CBCA_DESC',
  CBCVAsc = 'CBCV_ASC',
  CBCVDesc = 'CBCV_DESC',
  CBDVAsc = 'CBDV_ASC',
  CBDVDesc = 'CBDV_DESC',
  THC8Asc = 'THC8_ASC',
  THC8Desc = 'THC8_DESC',
  THC9Asc = 'THC9_ASC',
  THC9Desc = 'THC9_DESC',
  THCAAsc = 'THCA_ASC',
  THCADesc = 'THCA_DESC',
  THCVAsc = 'THCV_ASC',
  THCVDesc = 'THCV_DESC',
  TACAsc = 'TAC_ASC',
  TACDesc = 'TAC_DESC',
}

export enum SectionSortTerpenes {
  AlphaBisabololAsc = 'ALPHA_BISABOLOL_ASC',
  AlphaBisabololDesc = 'ALPHA_BISABOLOL_DESC',
  AlphaPineneAsc = 'ALPHA_PINENE_ASC',
  AlphaPineneDesc = 'ALPHA_PINENE_DESC',
  BetaCaryophylleneAsc = 'BETA_CARYOPHYLLENE_ASC',
  BetaCaryophylleneDesc = 'BETA_CARYOPHYLLENE_DESC',
  BetaEudesmolAsc = 'BETA_EUDESMOL_ASC',
  BetaEudesmolDesc = 'BETA_EUDESMOL_DESC',
  BetaMyrceneAsc = 'BETA_MYRCENE_ASC',
  BetaMyrceneDesc = 'BETA_MYRCENE_DESC',
  BetaPineneAsc = 'BETA_PINENE_ASC',
  BetaPineneDesc = 'BETA_PINENE_DESC',
  BisabololAsc = 'BISABOLOL_ASC',
  BisabololDesc = 'BISABOLOL_DESC',
  BorneolAsc = 'BORNEOL_ASC',
  BorneolDesc = 'BORNEOL_DESC',
  CampheneAsc = 'CAMPHENE_ASC',
  CampheneDesc = 'CAMPHENE_DESC',
  CaryophylleneOxideAsc = 'CARYOPHYLLENE_OXIDE_ASC',
  CaryophylleneOxideDesc = 'CARYOPHYLLENE_OXIDE_DESC',
  Delta3CareneAsc = 'DELTA3_CARENE_ASC',
  Delta3CareneDesc = 'DELTA3_CARENE_DESC',
  EucalyptolAsc = 'EUCALYPTOL_ASC',
  EucalyptolDesc = 'EUCALYPTOL_DESC',
  GeraniolAsc = 'GERANIOL_ASC',
  GeraniolDesc = 'GERANIOL_DESC',
  GuaiolAsc = 'GUAIOL_ASC',
  GuaiolDesc = 'GUAIOL_DESC',
  HumuleneAsc = 'HUMULENE_ASC',
  HumuleneDesc = 'HUMULENE_DESC',
  LinaloolAsc = 'LINALOOL_ASC',
  LinaloolDesc = 'LINALOOL_DESC',
  LimoneneAsc = 'LIMONENE_ASC',
  LimoneneDesc = 'LIMONENE_DESC',
  OcimeneAsc = 'OCIMENE_ASC',
  OcimeneDesc = 'OCIMENE_DESC',
  ThreeCareneAsc = 'THREE_CARENE_ASC',
  ThreeCareneDesc = 'THREE_CARENE_DESC',
  TerpineneAsc = 'TERPINENE_ASC',
  TerpineneDesc = 'TERPINENE_DESC',
  TerpinoleneAsc = 'TERPINOLENE_ASC',
  TerpinoleneDesc = 'TERPINOLENE_DESC',
  TransNerolidolAsc = 'TRANS_NEROLIDOL_ASC',
  TransNerolidolDesc = 'TRANS_NEROLIDOL_DESC',
  ValenceneAsc = 'VALENCENE_ASC',
  ValenceneDesc = 'VALENCENE_DESC',
}

export type SectionSortOption = SectionSortProductInfo | SectionSortSecondaryCannabinoids | SectionSortTerpenes;

export class SectionSortType extends TypeDefinition {

  override value: SectionSortOption;

  nameWithoutDashes(): string {
    return this.name?.replace(/ - /g, ' ');
  }

}
