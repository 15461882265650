import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { DateRangeFromNowViewModel } from './date-range-from-now-view-model';

@Component({
  selector: 'app-date-range-from-now',
  templateUrl: './date-range-from-now.component.html',
  styleUrls: ['./date-range-from-now.component.scss'],
  providers: [DateRangeFromNowViewModel]
})
export class DateRangeFromNowComponent extends BaseComponent implements OnChanges {

  @Input() lengthInSeconds: number;

  constructor(
    public viewModel: DateRangeFromNowViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.lengthInSeconds) this.viewModel.connectToLengthInSeconds(this.lengthInSeconds);
  }

}
