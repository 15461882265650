<hr class="" />
<div class="bs-bold f14px mt-8px mb-16px">
  {{ description }}
</div>
<div *rxFor="let stack of stacks" class="flex-container flex-align-center mb-16px gap-4px">
  <span *rxIf="stack?.templateId; else stackIcon">
    <img src="assets/icons/dark/solid/template.svg" alt="Templated Menu" [style.height]="'1rem'" />
  </span>
  <ng-template #stackIcon>
    <span>
      <img src="assets/icons/dark/outline/category-cards.svg" alt="Stacked Menu" [style.height]="'1rem'" />
    </span>
  </ng-template>
  <span class="f12px bs-medium">{{ stack?.name }}</span>
</div>
