<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Card Colors</p>
    <p class="bs-edit-section-description">Change the colors of the different elements on the card.</p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8">
    <!-- Form -->
    <lib-reactive-form-group
      #formGroupComponent
      [bindTo]="section"
      [mergeKey]="mergeKey"
      [globalDisabledTooltip]="viewModel.isTemplatedSection$ | async | templatedMenuDisabledFormControlTooltip">
      <lib-reactive-form-column-layout [nColumns]="3">
        <lib-reactive-form-color-square
          [inputName]="'sectionProductsContainerColor'"
          [label]="'Card Color'"
          [presetColors]="viewModel.colorPalette$ | async"
          [bindingProperty]="'metadata.productsContainerBackgroundColor'"
          [inlineLabel]="false"
          [disabled]="viewModel.isTemplatedSection$ | async"></lib-reactive-form-color-square>
        <lib-reactive-form-color-square
          [inputName]="'sectionHeaderColor'"
          [label]="viewModel.secondaryColorLabel$ | async"
          [tooltip]="viewModel.secondaryColorTooltip$ | async"
          [presetColors]="viewModel.colorPalette$ | async"
          [bindingProperty]="'metadata.productsHeaderBackgroundColor'"
          [inlineLabel]="false"
          [disabled]="viewModel.isTemplatedSection$ | async"></lib-reactive-form-color-square>
        <lib-reactive-form-color-square
          *ngIf="viewModel.supportsThirdColor$ | async"
          [inputName]="'productsInfoBackgroundColor'"
          [label]="viewModel.tertiaryColorLabel$ | async"
          [tooltip]="viewModel.tertiaryColorTooltip$ | async"
          [presetColors]="viewModel.colorPalette$ | async"
          [bindingProperty]="'metadata.productsInfoBackgroundColor'"
          [inlineLabel]="false"
          [disabled]="viewModel.isTemplatedSection$ | async"></lib-reactive-form-color-square>
        <lib-reactive-form-number
          [inputName]="'sectionProductsContainerCardOpacity'"
          [label]="'Card Background Opacity (%)'"
          [bindingProperty]="'metadata.cardOpacity'"
          [inlineLabel]="false"
          [customValueParser]="parseNumberOpacityToString"
          [minValue]="0"
          [maxValue]="100"
          [integersOnly]="true"
          [placeholder]="'100'"
          [programmaticallyChangeValue]="viewModel.cardOpacity$ | async"
          [required]="true"
          [disabled]="viewModel.isTemplatedSection$ | async"></lib-reactive-form-number>
      </lib-reactive-form-column-layout>
      <hr />
      <lib-reactive-form-column-layout [nColumns]="3">
        <lib-reactive-form-color-square
          [inputName]="'cardHeaderTextColor'"
          [label]="'Header Text Color'"
          [tooltip]="'This color will be applied to both card title and subtitle text'"
          [presetColors]="viewModel.colorPalette$ | async"
          [bindingProperty]="'metadata.sectionHeaderTextColor'"
          [inlineLabel]="false"
          [disabled]="viewModel.isTemplatedSection$ | async"></lib-reactive-form-color-square>
        <lib-reactive-form-color-square
          [inputName]="'cardBodyTextColor'"
          [label]="'Body Text Color'"
          [tooltip]="'This color will be applied to the body text of the card'"
          [presetColors]="viewModel.colorPalette$ | async"
          [bindingProperty]="'metadata.sectionBodyTextColor'"
          [inlineLabel]="false"
          [disabled]="viewModel.isTemplatedSection$ | async"></lib-reactive-form-color-square>
      </lib-reactive-form-column-layout>
    </lib-reactive-form-group>
  </div>
</div>
<hr class="mt-24px" />
