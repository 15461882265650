import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrimaryCannabinoid } from '../../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { exists } from '../../../../../../../../functions/exists';
import { CannabisUnitOfMeasure } from '../../../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { EditVariantCannabinoidsViewModel } from '../edit-variant-cannabinoids-view-model';

interface CannabinoidPreviewInfo {
  cannabinoid: string;
  title: string;
  value: string;
  source: string;
}

@Injectable()
export class CannabinoidsPreviewViewModel extends BaseViewModel {

  constructor(
    private editVariantCannabinoidsViewModel: EditVariantCannabinoidsViewModel
  ) {
    super();
  }

  public variant$ = this.editVariantCannabinoidsViewModel.container.variant$;
  public companyDA$ = this.editVariantCannabinoidsViewModel.updatedCompanyDA$;
  public locationDA$ = this.editVariantCannabinoidsViewModel.updatedLocationDA$;
  public useRange$ = this.editVariantCannabinoidsViewModel.container.useRange$;
  public cannabinoidUnitOfMeasure$ = this.editVariantCannabinoidsViewModel.selectedCUOM$;
  public enabledSecondaryCannabinoidNames$ = this.editVariantCannabinoidsViewModel.enabledSecondaryCannabinoidNames$;

  public primaryCannabinoids$ = of([PrimaryCannabinoid.THC, PrimaryCannabinoid.CBD]);

  public primaryCannabinoidPreviewInfo$ = combineLatest([
    this.primaryCannabinoids$,
    this.useRange$
  ]).pipe(
    switchMap(([primaryCannabinoids, useRange]) => {
      return this.createCannabinoidPreviewsFromList$(primaryCannabinoids, useRange);
    })
  );

  public secondaryCannabinoidPreviewInfo$ = combineLatest([
    this.enabledSecondaryCannabinoidNames$,
    this.useRange$
  ]).pipe(
    switchMap(([secondaryCannabinoids, useRange]) => {
      return this.createCannabinoidPreviewsFromList$(secondaryCannabinoids, useRange);
    })
  );

  private createCannabinoidPreviewsFromList$(
    cannabinoids: string[],
    useRange: boolean
  ): Observable<CannabinoidPreviewInfo[]> {
    if (useRange) {
      const rangePreviewObservables = cannabinoids?.flatMap(cannabinoid => [
        this.createCannabinoidPreviewInfo$(
          'min' + cannabinoid,
          'Min ' + cannabinoid
        ),
        this.createCannabinoidPreviewInfo$(
          'max' + cannabinoid,
          'Max ' + cannabinoid
        ),
      ]);
      return combineLatest(rangePreviewObservables);
    } else {
      const previewObservables = cannabinoids.map(cannabinoid => {
        return this.createCannabinoidPreviewInfo$(
          cannabinoid,
          cannabinoid
        );
      });
      return combineLatest(previewObservables);
    }
  }

  private createCannabinoidPreviewInfo$(cannabinoid: string, title: string): Observable<CannabinoidPreviewInfo> {
    return combineLatest([
      this.variant$,
      this.companyDA$,
      this.locationDA$,
      this.cannabinoidUnitOfMeasure$
    ]).pipe(
      map(([variant, companyDA, locationDA, cuom]) => {
        const defaultCannabinoid = variant?.[cannabinoid];
        const companyDACannabinoid = companyDA?.[cannabinoid];
        const locationDACannabinoid = locationDA?.[cannabinoid];
        let val: string;
        let source: string;
        if (exists(locationDACannabinoid)) {
          val = locationDACannabinoid;
          source = '(Location)';
        } else if (exists(companyDACannabinoid)) {
          val = companyDACannabinoid;
          source = '(Company)';
        } else {
          val = defaultCannabinoid;
          source = '(Variant Default)';
        }
        const info = (exists(val) && cuom !== CannabisUnitOfMeasure.NA && cuom !== CannabisUnitOfMeasure.UNKNOWN)
          ? val + cuom
          : '--';
        if (info === '--') source = '';
        return {
          cannabinoid: cannabinoid,
          title: title,
          value: info,
          source: source,
        };
      })
    );
  }

}
