import { Pipe, PipeTransform } from '@angular/core';
import { SmartDataType } from '../../../../models/enum/shared/smart-data-type.enum';

@Pipe({
  name: 'getRemoveFromExistingProductsTooltip'
})
export class GetRemoveFromExistingProductsTooltipPipe implements PipeTransform {

  transform(smartFilterIds: string[], smartDataType: SmartDataType): string {
    const item = smartDataType.toLowerCase().slice(0, -1);
    const enabled = 'Remove this ' + item + ' from products that '
                  + 'don\'t meet the criteria of the applied smart filter'
                  + ((smartFilterIds?.length > 1) ? 's' : '')
                  + '.';
    const disabled = 'Editable only after adding smart filters.';
    return smartFilterIds?.length ? enabled : disabled;
  }

}
