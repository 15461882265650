import { BehaviorSubject, iif, interval, Observable, of, Subject } from 'rxjs';
import { AutoSaveUtils } from '../../../../utils/auto-save-utils';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { map, switchMap } from 'rxjs/operators';
import { DateUtils } from '../../../../utils/date-utils';
import { LoadingOptions } from '../../../../models/shared/loading-options';

export abstract class AutoSaveViewModel extends BaseViewModel {

  connectToAddRequest = (addRequest: string) => this._loadingOpts.addRequest(addRequest);

  private _allowAutoSaving = new BehaviorSubject<boolean>(true);
  public allowAutoSaving$ = this._allowAutoSaving as Observable<boolean>;
  connectToAllowAutoSaving = (allowAutoSaving: boolean) => this._allowAutoSaving.next(allowAutoSaving);

  protected _autoSaveLoadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultInButton());
  public autoSaveLoadingOpts$ = this._autoSaveLoadingOpts as Observable<LoadingOptions>;
  public autoSaving$ = this.autoSaveLoadingOpts$.pipe(map(it => it.isLoading));

  public triggerAutoFormSubmission = new Subject<void>();
  public autoSaveTimer$: Observable<number> = new Observable<number>(null);
  public unsavedChanges: boolean = false;

  private _canBeAutoSaved = new BehaviorSubject<boolean>(false);
  public canBeAutoSaved$ = this._canBeAutoSaved as Observable<boolean>;

  private _lastAutoSaveTimestamp = new BehaviorSubject<number>(null);
  public lastAutoSaveTimestamp$ = this._lastAutoSaveTimestamp as Observable<number>;
  connectToLastAutoSaveTimestamp = (timestamp: number) => this._lastAutoSaveTimestamp.next(timestamp);
  setLastAutoSavedTimestampToNow() {
    this._lastAutoSaveTimestamp.next(DateUtils.currentTimestamp());
  }

  public lastAutoSavedTimeText$ = this.lastAutoSaveTimestamp$.pipe(
    map(t => {
      return (!!t && DateUtils.unixAfterMinutesAgo(t, 15))
        ? `Last Saved ${DateUtils.formatUnixToTime(t)}`
        : null;
    })
  );

  /**
   * connect to unsavedChanges$ output on lib-reactive-form-merge-groups
   */
  setUnsavedChanges(unsavedChanges: boolean) {
    this.unsavedChanges = unsavedChanges;
  }

  public canDeactivate(): boolean | Promise<any> {
    return !this.unsavedChanges;
  }

  /**
   * connect to disableSubmit$ output on lib-reactive-form-merge-groups
   */
  setAutoSaveTimer(disabled: boolean) {
    this._canBeAutoSaved.next(!disabled);
    if (!disabled) {
      this.restartAutoSaveTimer();
    } else {
      this.killAutoSaveTimer();
    }
  }

  private restartAutoSaveTimer() {
    this.destroyAllTimerSubs();
    this.autoSaveTimer$ = interval(AutoSaveUtils.SECONDS_UNTIL_AUTO_SAVE * 1000);
    const timerSub = this.allowAutoSaving$
      .pipe(switchMap(allow => iif(() => allow, this.autoSaveTimer$, of(-1))))
      .subscribe(timer => {
        if (timer >= 0) {
          this.triggerAutoFormSubmission.next();
          this.destroyAllTimerSubs();
        }
      });
    this.pushTimerSub(timerSub);
  }

  private killAutoSaveTimer() {
    this.destroyAllTimerSubs();
  }

}
