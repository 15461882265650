import { Injectable, Injector } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { Variant } from '../../../../../../models/product/dto/variant';
import { MenuStyle } from '../../../../../../models/menu/dto/menu-style';
import { debounceTime, distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';
import { PriceUtils } from '../../../../../../utils/price-utils';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { NumberUtils } from '../../../../../../utils/number-utils';
import { HydratedVariantBadge } from '../../../../../../models/product/dto/hydrated-variant-badge';
import { LabelUtils } from '../../../../../../modules/product-labels/utils/label-utils';
import { CannabinoidDisplayType } from '../../../../../../models/utils/dto/cannabinoid-display-type-definition';
import { CannabisUnitOfMeasure } from '../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { MenuStyleObject } from '../../../../../../models/utils/dto/menu-style-object-type';
import { SectionColumnConfigKey } from '../../../../../../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigSecondaryPricingData } from '../../../../../../models/utils/dto/section-column-config-data-value-type';
import { SectionSortProductInfo } from '../../../../../../models/utils/dto/section-sort-type';
import { ProviderUtils } from '../../../../../../utils/provider-utils';
import { ModalEditVariant } from '../../../../../../modals/modal-edit-variant';
import { ModalVariantBadge } from '../../../../../../modals/modal-variant-badge';
import { ModalEditLabel } from '../../../../../../modals/modal-edit-label';
import { ModalEditMenuStyle } from '../../../../../../modals/modal-edit-menu-style';
import { SortOrderPosition, SortUtils } from '../../../../../../utils/sort-utils';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { SectionType } from '../../../../../../models/utils/dto/section-type-definition';
import { TerpeneDisplayType } from '../../../../../../models/utils/dto/terpene-display-type-definition';
import { TerpeneUnitOfMeasure } from '../../../../../../models/utils/dto/terpene-unit-of-measure-type';

@Injectable()
export class MenuSectionProductPreviewViewModel extends BaseViewModel {

  constructor(
    protected locationDomainModel: LocationDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected companyDomainModel: CompanyDomainModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  // Company config
  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$.notNull();
  public colorPalette$ = this.companyConfig$.pipe(map(cc => cc?.colorPalette || []));

  // current location
  public quantityText$ = this.locationDomainModel.locationName$;
  public priceFormat$ = this.locationDomainModel.priceFormat$;

  // menu
  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();
  public hideLabel$ = this.menu$.pipe(map((m) => m?.menuOptions?.hideLabel));
  public hideSalePricing$ = this.menu$.pipe(map(m => m?.menuOptions?.hideSale ?? false));

  // section
  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;
  public smartFiltersEnabled$ = this.section$.pipe(map(s => s?.hasSmartFilters()));

  public isProductGroupSection$ = this.section$.pipe(
    map((section) => section?.sectionType === SectionType.ProductGroup)
  );

  public showManageStyleCTA$ = combineLatest([
    this.isProductGroupSection$,
    this.menu$,
  ]).pipe(
    map(([isProductGroupSection, menu]) => !isProductGroupSection && !menu?.containsStackedContent())
  );

  // theme
  private _theme = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme.asObservable();

  // variant
  private _variant = new BehaviorSubject<Variant | null>(null);
  public variant$ = this._variant as Observable<Variant>;
  public variantQtyValue$ = combineLatest([
    this.variant$,
    this.companyDomainModel.inventoryProvider$,
  ]).pipe(map(([v, provider]) => {
    const qty = ProviderUtils.applyVariantInventoryDecorator(provider, v?.inventory?.quantityInStock);
    return `${qty} Qty`;
  }));
  public variantTitle$ = this.variant$.pipe(map(v => v?.getVariantTitle()));
  public isMedical$ = this.variant$.pipe(map(v => v.isMedical));
  public outOfStock$ = this.variant$.pipe(map(v => NumberUtils.floatNumbersEqual(v?.inventory?.quantityInStock, 0)));
  public variantDisplayAttr$ = this.variant$.pipe(map(v => v?.displayAttributes));

  public readonly variantAddedFromTemplate$ = combineLatest([
    this.section$,
    this.variant$,
  ]).pipe(
    map(([section, variant]) => section?.isVariantAddedByTemplate(variant))
  );

  private _templateMode = new BehaviorSubject<boolean>(false);
  public templateMode$ = this._templateMode as Observable<boolean>;

  public isTemplatedSection$ = this.section$.pipe(map(section => section?.isTemplatedSection()));

  public canRemove$ = combineLatest([
    this.section$,
    this.variant$
  ]).pipe(
    map(([section, variant]) => section?.permissionToRemoveVariantFromSection(variant))
  );

  public disabledRemoveTooltip$ = combineLatest([
    this.section$,
    this.variant$,
    this.isTemplatedSection$
  ]).pipe(
    map(([section, variant, templatedSection]) => {
      if (templatedSection && !section?.permissionToRemoveVariantFromSection(variant)) {
        return 'This product can only be removed from the template';
      } else if (section?.hasSmartFilters()) {
        return 'Products can not be manually removed when smart filters are enabled';
      } else {
        return null;
      }
    })
  );

  // Price
  public regularPriceValue$ = combineLatest([
    this.menu$,
    this.variant$,
    this.priceFormat$
  ]).pipe(
    map(([menu, variant, priceStream]) => {
      let price;
      if (variant?.locationPricing?.length > 0) {
        price = PriceUtils.formatPrice(
          variant?.getVisiblePrice(menu?.locationId, menu?.companyId, priceStream, menu?.menuOptions?.hideSale)
        );
      }
      return price || '-';
    }),
    distinctUntilChanged()
  );

  public secondaryPriceValue$ = combineLatest([
    this.menu$,
    this.section$,
    this.variant$,
    this.priceFormat$,
  ]).pipe(
    map(([menu, section, variant, priceStream]) => {
      const secondaryPriceColumnConfig = section?.columnConfig?.get(SectionColumnConfigKey.SecondaryPrice);
      const locId = menu?.locationId;
      const compId = menu?.companyId;
      const hideSale = menu?.menuOptions?.hideSale;
      const configOption = secondaryPriceColumnConfig?.dataValue as SectionColumnConfigSecondaryPricingData;
      return variant?.getSecondaryPriceColumnFormattedPrice(configOption, locId, compId, priceStream, hideSale, true);
    }),
    distinctUntilChanged()
  );

  public salePriceOriginalValue$ = combineLatest([
    this.menu$,
    this.variant$,
    this.priceFormat$
  ]).pipe(
    map(([menu, variant, priceStream]) => {
      const hasLocId = !!menu?.locationId;
      if (hasLocId && (variant?.hasLocationOrCompanySalePricing() || variant?.locationPromotion?.isActive())) {
        return PriceUtils.formatPrice(variant?.getPriceWithoutDiscounts(menu?.locationId, null, priceStream));
      }
      return null;
    }),
    distinctUntilChanged()
  );

  public salePrice$ = combineLatest([
    this.menu$,
    this.variant$,
    this.priceFormat$
  ]).pipe(
    map(([menu, variant, priceStream]) => {
      if (!!menu && !!variant) {
        const price = variant?.getDiscountedPriceOrNull(menu?.locationId, menu?.companyId, priceStream);
        if (price > 0 && !menu?.menuOptions?.hideSale) return PriceUtils.formatPrice(price);
      }
      return null;
    }),
    distinctUntilChanged()
  );

  public calculatedPrice$ = combineLatest([
    this.regularPriceValue$,
    this.salePriceOriginalValue$,
    this.salePrice$
  ]).pipe(
    map(([regular, originalSale, sale]) => (!!sale ? originalSale : regular)),
    distinctUntilChanged()
  );

  public cannabinoidRange$ = this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$;

  public getCannabinoidText$ = (cannabinoid: string): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyDomainModel.companyConfiguration$.notNull(),
    ]).pipe(
      map(([variant, companyConfig]) => {
        if (variant?.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
          return '--';
        }
        if (companyConfig.cannabinoidDisplayType === CannabinoidDisplayType.Exact && !variant?.useCannabinoidRange) {
          const val = variant?.getCannabinoidOrTerpene(cannabinoid);
          return val ? `${val}${variant?.cannabisUnitOfMeasure}` : 'N/A';
        } else {
          const minVal = variant?.getMinCannabinoidOrTerpene(cannabinoid);
          const maxVal = variant?.getMaxCannabinoidOrTerpene(cannabinoid);
          return (minVal || maxVal)
            ? `${minVal ?? 'N/A'}-${maxVal ?? 'N/A'}${variant?.cannabisUnitOfMeasure}`
            : 'N/A';
        }
      }),
      distinctUntilChanged()
    );
  };

  public getTerpeneText$ = (terpeneCamelCased: string): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyDomainModel.companyConfiguration$.notNull(),
    ]).pipe(
      map(([variant, companyConfig]) => {
        if (variant?.terpeneUnitOfMeasure === TerpeneUnitOfMeasure.NA) {
          return '--';
        }
        if (companyConfig.terpeneDisplayType === TerpeneDisplayType.Exact && !variant?.useTerpeneRange) {
          const val = variant?.getCannabinoidOrTerpene(terpeneCamelCased);
          return val ? `${val}${variant?.terpeneUnitOfMeasure}` : 'N/A';
        } else {
          const minVal = variant?.getMinCannabinoidOrTerpene(terpeneCamelCased);
          const maxVal = variant?.getMaxCannabinoidOrTerpene(terpeneCamelCased);
          return (minVal || maxVal)
            ? `${minVal ?? 'N/A'}-${maxVal ?? 'N/A'}${variant?.terpeneUnitOfMeasure}`
            : 'N/A';
        }
      }),
      distinctUntilChanged()
    );
  };

  public getTopTerpeneText$ = (): Observable<string> => {
    return this.variantDisplayAttr$.pipe(
      map((variantDisplayAttribute) => variantDisplayAttribute?.getTopTerpene()),
      distinctUntilChanged()
    );
  };

  public getTotalTerpenesText$ = (): Observable<string> => {
    return this.variantDisplayAttr$.pipe(
      map((variantDisplayAttribute) => variantDisplayAttribute?.getTotalTerpene()),
      distinctUntilChanged()
    );
  };

  public typeText$ = this.variant$.pipe(
    map(v => {
      const typeList = [];
      if (!!v?.productType) typeList.push(`${v?.productType}`);
      if (!!v?.variantType) typeList.push(`${v?.variantType}`);
      return typeList.join(' - ');
    }),
    distinctUntilChanged()
  );

  public unitSizeText$ = this.variant$.pipe(
    map(v => v?.getEditSectionVariantSizeText()),
    distinctUntilChanged()
  );

  public badgeTooltip$ = combineLatest([
    this.section$,
    this.variant$
  ]).pipe(
    map(([section, variant]) => {
      const badges = section?.getBadgesFor(variant.id);
      if (badges && badges.length > 0) {
        return badges.map(b => b.name).join(', ');
      }
      return null;
    }),
    distinctUntilChanged()
  );

  public badgeCountText$ = combineLatest([
    this.section$,
    this.variant$
  ]).pipe(
    map(([section, variant]) => {
      const inheritedBadgeCount = variant?.displayAttributes?.getNumberOfInheritedBadges();
      const badges = section?.variantBadgeIdsMap?.get(variant?.id);
      const badgeCount = (badges ? badges.length : 0) || inheritedBadgeCount;
      if (badgeCount > 0) {
        return badgeCount === 1 ? `${badgeCount} Badge` : `${badgeCount} Badges`;
      }
      return `No Badges`;
    }),
    distinctUntilChanged()
  );

  public badges$: Observable<HydratedVariantBadge[]> = combineLatest([
    this.variant$,
    this.section$,
    this.theme$
  ]).pipe(
    map(([variant, section, theme]) => {
      const badges = variant?.getAllVariantBadges(
        theme?.themeFeatures?.badgeCount ?? 0,
        section?.variantBadgeMap,
      )?.filter(b => b instanceof HydratedVariantBadge) ?? [];
      return badges as HydratedVariantBadge[];
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  private getSortByPrice(sortPosition: SortOrderPosition): Observable<boolean> {
    return this.section$.pipe(
      map(section => {
        const sortOption = SortUtils.getSectionSortOptionFromPosition(sortPosition, section);
        return SortUtils.sharedSortId(sortOption) === SortUtils.sharedSortId(SectionSortProductInfo.PriceAsc);
      }),
      distinctUntilChanged()
    );
  }

  public primarySortByPrice$ = this.getSortByPrice(SortOrderPosition.Primary);
  public secondarySortByPrice$ = this.getSortByPrice(SortOrderPosition.Secondary);

  private getSortStrokePrice(sortByPrice$: Observable<boolean>): Observable<boolean> {
    return combineLatest([
      sortByPrice$,
      this.salePrice$,
    ]).pipe(
      map(([sortByPrice, salePrice]) => sortByPrice && !!salePrice),
      distinctUntilChanged()
    );
  }

  public primarySortStrokePrice$ = this.getSortStrokePrice(this.primarySortByPrice$);
  public secondarySortStrokePrice$ = this.getSortStrokePrice(this.secondarySortByPrice$);

  private getSortHasSalePrice(sortByPrice$: Observable<boolean>): Observable<boolean> {
    return combineLatest([
      this.salePrice$,
      sortByPrice$
    ]).pipe(
      map(([salePrice, sortByPrice]) => salePrice && sortByPrice),
      distinctUntilChanged()
    );
  }

  public primarySortHasSalePrice$ = this.getSortHasSalePrice(this.primarySortByPrice$);
  public secondarySortHasSalePrice$ = this.getSortHasSalePrice(this.secondarySortByPrice$);

  private getSortByValue(sortPosition: SortOrderPosition): Observable<any> {
    return combineLatest([
      combineLatest([this.section$, this.variant$]),
      combineLatest([this.variantQtyValue$, this.typeText$, this.regularPriceValue$, this.secondaryPriceValue$]),
    ]).pipe(
      switchMap(data => {
        return SortUtils.getVariantPreviewSortByValue(
          sortPosition,
          this.getCannabinoidText$,
          this.getTerpeneText$,
          this.getTopTerpeneText$,
          this.getTotalTerpenesText$,
          ...data
        );
      }),
      distinctUntilChanged()
    );
  }

  public primarySortByValue$ = this.getSortByValue(SortOrderPosition.Primary);
  public secondarySortByValue$ = this.getSortByValue(SortOrderPosition.Secondary);

  public priceSubText$ = combineLatest([
    this.menu$,
    this.variant$,
    this.priceFormat$
  ]).pipe(
    map(([menu, v, priceStream]) => {
      if (v?.hasLocationOrCompanyPricing()) {
        if (v?.isLocationPrice(menu?.locationId, menu?.companyId, priceStream)) {
          return `Location Price`;
        } else {
          return `Company Price`;
        }
      }
      return 'Price';
    }),
    distinctUntilChanged()
  );

  private getSortBySubText(sortPosition: SortOrderPosition): Observable<string> {
    return combineLatest([
      this.menu$,
      this.section$,
      this.variant$,
      this.priceFormat$
    ]).pipe(
      map(([menu, section, variant, priceFormat, ]) => {
        return SortUtils.getVariantPreviewSortSubtext(sortPosition, menu, section, variant, priceFormat);
      }),
      distinctUntilChanged()
    );
  }

  public primarySortBySubtext$ = this.getSortBySubText(SortOrderPosition.Primary);
  public secondarySortBySubtext$ = this.getSortBySubText(SortOrderPosition.Secondary);

  public variantTooltip$ = this.variant$.pipe(
    map(variant => {
      return variant?.getNameTooltip();
    }),
    distinctUntilChanged()
  );

  public cannabisContentTooltip$ = combineLatest([
    this.variant$,
    this.companyDomainModel.companyConfiguration$,
  ]).pipe(
    map(([variant, companyConfig]) => {
      if (companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Exact) {
        if (variant?.displayAttributes?.getTHC() || variant?.displayAttributes?.getCBD()) {
          const thcVal = variant?.THC ? `${variant?.THC}${variant?.cannabisUnitOfMeasure}` : 'N/A';
          const cbdVal = variant?.CBD ? `${variant?.CBD}${variant?.cannabisUnitOfMeasure}` : 'N/A';
          return `${thcVal} | ${cbdVal}`;
        } else {
          return null;
        }
      } else {
        const hasMinOrMaxCannabinoid = variant?.displayAttributes?.getMinTHC()
          || variant?.displayAttributes?.getMaxTHC()
          || variant?.displayAttributes?.getMinCBD()
          || variant?.displayAttributes?.getMaxCBD();
        if (hasMinOrMaxCannabinoid) {
          const thcVal = (variant?.minTHC || variant?.maxTHC)
            ? `${variant?.minTHC ?? 'N/A'}-${variant?.maxTHC ?? 'N/A'}${variant?.cannabisUnitOfMeasure}`
            : 'N/A';
          const cbdVal = (variant?.minCBD || variant?.maxCBD)
            ? `${variant?.minCBD ?? 'N/A'}-${variant?.maxCBD ?? 'N/A'}${variant?.cannabisUnitOfMeasure}`
            : 'N/A';
          return `${thcVal} | ${cbdVal}`;
        } else {
          return null;
        }
      }
    }),
    distinctUntilChanged()
  );

  private getSortOriginalPriceOrSortByValue(
    [sortStrokePrice$, sortByValue$]: [Observable<boolean>, Observable<any>]
  ): Observable<string> {
    return combineLatest([
      sortStrokePrice$,
      this.salePriceOriginalValue$,
      sortByValue$
    ]).pipe(
      map(([strokePrice, originalSale, sortByValue]) => (strokePrice ? originalSale : sortByValue || 'N/A')),
      distinctUntilChanged()
    );
  }

  public primarySortOriginalPriceOrSortByValue$ = this.getSortOriginalPriceOrSortByValue(
    [this.primarySortStrokePrice$, this.primarySortByValue$]
  );
  public secondarySortOriginalPriceOrSortByValue$ = this.getSortOriginalPriceOrSortByValue(
    [this.secondarySortStrokePrice$, this.secondarySortByValue$]
  );

  private _sectionStyles = new BehaviorSubject<MenuStyle[]>([]);
  public sectionStyles$ = this._sectionStyles as Observable<MenuStyle[]>;

  public variantStyle$ = combineLatest([
    this.variant$,
    this.sectionStyles$
  ]).pipe(
    map(([v, styles]) => {
      if (styles?.length > 0) {
        const variantStyle = styles?.find(s => s.objectId === v.id && s.objectType === MenuStyleObject.Variant);
        if (variantStyle) {
          return variantStyle;
        } else {
          // check for product style
          const productStyle = styles?.find(s => {
            return s.objectId === v.productId && s.objectType === MenuStyleObject.Product;
          });
          if (productStyle) {
            return productStyle;
          }
        }
      }
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public manageStyleText$ = combineLatest([
    this.menu$,
    this.section$,
    this.variant$,
    this.variantStyle$
  ]).pipe(
    map(([menu, section, variant, style]) => {
      switch (true) {
        case style?.viewOnly(section):
          return 'View Style';
        case !!style && !style?.isTemplateStyle && menu?.hasTemplateStyling(section, variant):
          return 'Edit Style Override';
        case !!style:
          return 'Edit Style';
        default:
          return 'Add Style';
      }
    })
  );

  public disableManageStyling$ = combineLatest([
    this.section$,
    this.menu$,
    this.variantStyle$
  ]).pipe(
    map(([section, menu, variantStyle]) => {
      switch (true) {
        case variantStyle?.isTemplatedMenuWithTemplateStyle(menu):
          // The style has been overridden on the template, so can not be overridden on the menu
          return true;
        case !variantStyle && !section?.allowedToOverrideTemplateStyling():
          return true;
        default:
          return false;
      }
    })
  );

  public disableManageStylingTooltip$ = combineLatest([
    this.section$,
    this.menu$,
    this.variantStyle$
  ]).pipe(
    map(([section, menu, variantStyle]) => {
      switch (true) {
        case variantStyle?.isTemplatedMenuWithTemplateStyle(menu):
          return 'This style has been overridden on the template, so can not be overridden on the menu';
        case !variantStyle && !section?.allowedToOverrideTemplateStyling():
          return 'This override must be enabled on the template';
        default:
          return null;
      }
    })
  );

  public _allowBadgeOverride = new BehaviorSubject<boolean>(false);
  public allowBadgeOverride$ = this._allowBadgeOverride.asObservable();

  private templateBadgeOverrideExists$ = combineLatest([
    this.section$,
    this.variant$,
  ]).pipe(
    map(([section, variant]) => {
      const templateSection = section?.templateSection;
      if (!!templateSection) {
        const overrideIds = templateSection.variantBadgeIdsMap?.get(variant.id);
        return overrideIds?.length > 0;
      }
      return false;
    })
  );

  public disableBadgeOverride$ = combineLatest([
    this.templateBadgeOverrideExists$,
    this.allowBadgeOverride$
  ]).pipe(
    map(([templateOverrideExists, allowBadgeOverride]) => {
      return templateOverrideExists || !allowBadgeOverride;
    })
  );

  public disableBadgeOverrideTooltip$ = combineLatest([
    this.templateBadgeOverrideExists$,
    this.allowBadgeOverride$
  ]).pipe(
    map(([templateOverrideExists, allowBadgeOverride]) => {
      if (templateOverrideExists) {
        return 'This badge has been overridden on the template, so can not be overridden on the menu';
      } else if (!allowBadgeOverride) {
        return 'This override must be enabled on the template';
      } else {
        return null;
      }
    })
  );

  public showLabelOverride$ = this.section$.pipe(
    map((section) => section?.sectionType !== SectionType.ProductGroup)
  );

  private _allowLabelOverride = new BehaviorSubject<boolean>(false);
  public allowLabelOverride$ = this._allowLabelOverride.asObservable();

  private templateLabelOverrideExists$ = combineLatest([
    this.section$,
    this.variant$,
  ]).pipe(
    map(([section, variant]) => {
      const templateSection = section?.templateSection;
      if (!!templateSection) {
        const overrideId = templateSection.customLabelMap?.get(variant.id);
        return !!overrideId;
      }
      return false;
    })
  );

  public disableLabelOverride$ = combineLatest([
    this.templateLabelOverrideExists$,
    this.allowLabelOverride$,
    this.hideLabel$
  ]).pipe(
    map(([templateOverrideExists, allowLabelOverride, hideLabel]) => {
      return templateOverrideExists || !allowLabelOverride || hideLabel;
    })
  );

  public disableLabelOverrideTooltip$ = combineLatest([
    this.templateLabelOverrideExists$,
    this.allowLabelOverride$,
    this.hideLabel$,
    this.isTemplatedSection$
  ]).pipe(
    map(([templateOverrideExists, allowLabelOverride, hideLabel, isTemplatedSection]) => {
      switch (true) {
        case (isTemplatedSection && hideLabel):
          return 'Labels are hidden on this menu from the template.';
        case (!isTemplatedSection && hideLabel):
          return 'Labels are hidden on this menu.';
        case templateOverrideExists:
          return 'This label has been overridden on the template, so it cannot be overridden on the menu.';
        case !allowLabelOverride:
          return 'This override must be enabled on the template.';
        default:
          return null;
      }
    })
  );

  public styleText$ = this.variantStyle$.pipe(map(style => style?.objectType || 'No Style'));

  public hideQuantity$ = this.section$.pipe(
    map(sec => {
      const stockId = SortUtils.sharedSortId(SectionSortProductInfo.StockAsc);
      const primaryIsQuantity = SortUtils.sharedSortId(sec?.sorting) === stockId;
      const secondaryIsQuantity = SortUtils.sharedSortId(sec?.secondarySorting) === stockId;
      return primaryIsQuantity || secondaryIsQuantity;
    }),
    distinctUntilChanged()
  );

  public hidePrice$ = this.section$.pipe(
    map(sec => {
      const priceId = SortUtils.sharedSortId(SectionSortProductInfo.PriceAsc);
      const primaryIsPrice = SortUtils.sharedSortId(sec?.sorting) === priceId;
      const secondaryIsPrice = SortUtils.sharedSortId(sec?.secondarySorting) === priceId;
      return primaryIsPrice || secondaryIsPrice;
    }),
    distinctUntilChanged()
  );

  public hidePrimarySortProperty$ = this.section$.pipe(
    map(sec => {
      const primaryId = SortUtils.sharedSortId(sec?.sorting);
      const titleId = SortUtils.sharedSortId(SectionSortProductInfo.TitleAsc);
      return primaryId === titleId;
    }),
    distinctUntilChanged()
  );

  public hideSecondarySortProperty$ = this.section$.pipe(
    map(sec => {
      const secondary = !!sec?.secondarySorting;
      const secondaryId = SortUtils.sharedSortId(sec?.secondarySorting);
      const titleId = SortUtils.sharedSortId(SectionSortProductInfo.TitleAsc);
      return !secondary || (secondaryId === titleId);
    }),
    distinctUntilChanged()
  );

  public variantIsFeatured$ = combineLatest([
    this.menu$,
    this.section$,
    this.variant$
  ]).pipe(
    map(([menu, section, variant]) => {
      // Setup map to track updates
      const enabled = section?.enabledVariantIds?.contains(variant?.id);
      const isFeat = menu?.variantFeature?.variantIds.contains(variant?.id);
      return enabled && isFeat;
    }),
    distinctUntilChanged()
  );

  public computeLabelInterface$ = combineLatest([
    this.menu$,
    this.section$,
    this.variant$,
    this.locationConfig$,
    this.companyConfig$
  ]).pipe(
    map(([menu, section, variant, locConfig, compConfig]) => {
      return LabelUtils.getComputeLabelInterfaceWithinMenuContext(menu, section, [variant], locConfig, compConfig);
    })
  );

  connectToMenu(m: Menu) {
    this._menu.next(m);
  }

  connectToTheme(t: Theme) {
    this._theme.next(t);
  }

  connectToSection(s: HydratedSection) {
    this._section.next(s);
  }

  connectToVariant(v: Variant) {
    this._variant.next(v);
  }

  connectToSectionStyles(s: MenuStyle[]) {
    this._sectionStyles.next(s);
  }

  connectToTemplateMode(tm: boolean) {
    this._templateMode.next(tm);
  }

  connectToAllowBadgeOverride(b: boolean) {
    this._allowBadgeOverride.next(b);
  }

  connectToAllowLabelOverride(b: boolean) {
    this._allowLabelOverride.next(b);
  }

  openBadgeModal(saveSection: (background: boolean, updatedVariantBadgeMap?: Map<string, string[]>) => void) {
    combineLatest([
      this.section$,
      this.theme$,
      this.variant$,
    ]).pipe(
      debounceTime(1),
      take(1)
    ).subscribe(([section, theme, variant]) => {
      const onClose = (selectedBadgeIds: string[]) => {
        const badgeMap = section?.variantBadgeIdsMap?.deepCopy() || new Map<string, string[]>();
        if (selectedBadgeIds === null) {
          // Badge modal was closed without saving selection
          return;
        } else if (selectedBadgeIds.length === 0) {
          badgeMap.delete(variant?.id);
        } else {
          badgeMap.set(variant?.id, selectedBadgeIds);
        }
        saveSection(true, badgeMap);
      };
      ModalVariantBadge.open(this.ngbModal, this.injector, theme, section, variant, onClose);
    });
  }

  openEditVariantModal() {
    this.variant$.pipe(
      debounceTime(1),
      take(1)
    ).subscribe((variant) => {
      ModalEditVariant.open(this.ngbModal, this.injector, variant);
    });
  }

  openEditLabelModal() {
    combineLatest([
      this.section$,
      this.variant$,
      this.menu$,
    ]).pipe(
      debounceTime(1),
      take(1)
    ).subscribe(([section, variant, menu]) => {
      ModalEditLabel.open(this.ngbModal, this.injector, menu, section, variant);
    });
  }

  handleEditStyle() {
    // complete and unsubscribe after first valid value
    combineLatest([
      this.section$,
      this.variant$,
      this.variantStyle$,
      this.colorPalette$,
    ]).pipe(
      debounceTime(1),
      take(1)
    ).subscribe(([section, variant, style, palette]) => {
      ModalEditMenuStyle.open(this.ngbModal, this.injector, section, variant, style, palette);
    });
  }

}
