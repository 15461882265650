import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { EditMenuStyleViewModel } from '../edit-menu-style-modal/edit-menu-style-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { VariantBadgeModalViewModel } from './variant-badge-modal-view-model';
import { DisplayAttribute } from '../../../../../models/display/dto/display-attribute';
import { Theme } from '../../../../../models/menu/dto/theme';
import { Variant } from '../../../../../models/product/dto/variant';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { take } from 'rxjs/operators';
import { Section } from '../../../../../models/menu/dto/section';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';

@Component({
  selector: 'app-variant-badge-modal',
  templateUrl: './variant-badge-modal.component.html',
  styleUrls: ['./variant-badge-modal.component.scss'],
  providers: [EditMenuStyleViewModel, VariantBadgeModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantBadgeModalComponent extends BaseModalComponent implements AfterViewInit {

  @ViewChild('modalBody') modalBody: HTMLDivElement;
  @ViewChild('t') toolTip: NgbTooltip;
  public disableToolTip$ = new BehaviorSubject<boolean>(true);

  constructor(
    protected activeModal: NgbActiveModal,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    public viewModel: VariantBadgeModalViewModel,
    public sharedViewModel: EditMenuStyleViewModel,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
    setTimeout(function() { this.disableToolTip$.next(false); }.bind(this), 500);
  }

  override setupBindings() {
    const s = this.sharedViewModel.dismissModalSubject.subscribe((close) => {
      if (close) {
        this.cancel();
      }
    });
    this.pushSub(s);
  }

  setTheme(t: Theme) {
    this.viewModel.setTheme(t);
  }

  setVariant(v: Variant) {
    this.viewModel.setVariant(v);
  }

  setSection(s: Section) {
    this.viewModel.setSection(s);
  }

  setBadges(da: DisplayAttribute, addedToBackendBadgeIds: string[]) {
    this.viewModel.setBadges(da, addedToBackendBadgeIds);
  }

  promptForSaveBadgeOverride() {
    combineLatest([
      this.viewModel.variant$,
      this.viewModel.inheritedBadgeIds$,
      this.viewModel.section$,
    ]).pipe(
      take(1)
    ).subscribe(([variant, inheritedBadgeIds, section]) => {
      const overrideBadgeCount = section.variantBadgeIdsMap.get(variant.id)?.length ?? 0;
      const inheritedBadgeCount = inheritedBadgeIds.length;
      const overridingDisplayAttribute = overrideBadgeCount === 0 && inheritedBadgeCount > 0;
      overridingDisplayAttribute
        ? this.confirmOverride(inheritedBadgeCount)
        : this.activeModal.close(this.viewModel.getUpdatedBadgeList());
    });
  }

  private confirmOverride(inheritedBadgeCount: number): void {
    const opts = new ConfirmationOptions();
    opts.title = inheritedBadgeCount > 1 ? 'Override Badges' : 'Override Badge';
    const inheritedBadgeText = inheritedBadgeCount > 1
      ? `There are ${inheritedBadgeCount} badges`
      : `There is ${inheritedBadgeCount} badge`;
    opts.bodyText = `${inheritedBadgeText} being inherited from the product's display attributes. `
      + `Are you sure you want to override ${inheritedBadgeCount > 1 ? 'these badges' : 'this badge'} `
      + `on the current menu?`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Override';
    const confirmed = (cont: boolean) => {
      cont ? this.activeModal.close(this.viewModel.getUpdatedBadgeList()) : this.activeModal.close();
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmed);
  }

}
