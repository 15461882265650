import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SingleSelectionItemGrouping } from '../../../../models/shared/stylesheet/single-selection-item-grouping';
import { SingleSelectionItem } from '../../../../models/shared/stylesheet/single-selection-item';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-single-selection-grouped-list',
  templateUrl: './single-selection-grouped-list.component.html',
  styleUrls: ['./single-selection-grouped-list.component.scss']
})
export class SingleSelectionGroupedListComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() groupedItems: SingleSelectionItemGrouping[] = [];
  @Output() itemSelected: EventEmitter<SingleSelectionItem> = new EventEmitter<SingleSelectionItem>();

  private _selectedItem = new BehaviorSubject<SingleSelectionItem>(null);
  public selectedItem$ = this._selectedItem as Observable<SingleSelectionItem>;

  handleItemSelection(item: SingleSelectionItem) {
    this._selectedItem.next(item);
    this.itemSelected.emit(item);
  }

}
