import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../../utils/date-utils';

@Pipe({
  name: 'unixToDate'
})
export class UnixToDatePipe implements PipeTransform {

  transform(timestamp: number): string {
      return DateUtils.formatUnixToDate(timestamp);
  }

}
