<lib-reactive-form-column-layout nColumns="2" rowGap="'2rem'">
  <ng-container *rxIf="!(viewModel.useRange$ | push); else rangeCannabinoids">
    <lib-reactive-form-group [bindTo]="viewModel.companyDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.companyCannabinoidUpdated($event)"
        [inputName]="viewModel.companyCannabinoidInputName$ | push"
        [label]="viewModel.companyCannabinoidLabel$ | push"
        [placeholder]="viewModel.companyCannabinoidPlaceholder$ | push"
        [bindingProperty]="viewModel.cannabinoid$ | push"
        [tooltip]="viewModel.companyCannabinoidTooltip$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push) || (viewModel.disableCannabinoidInputs$ | push)"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>

    <lib-reactive-form-group [bindTo]="viewModel.locationDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.locationCannabinoidUpdated($event)"
        [inputName]="'location' + (viewModel.cannabinoid$ | push)"
        [label]="viewModel.locationCannabinoidLabel$ | push"
        [placeholder]="viewModel.locationCannabinoidPlaceholder$ | push"
        [bindingProperty]="viewModel.cannabinoid$ | push"
        [tooltip]="viewModel.locationCannabinoidTooltip$ | push"
        [disabled]="viewModel.disableCannabinoidInputs$ | push"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>
  </ng-container>
  <ng-template #rangeCannabinoids>
    <lib-reactive-form-group [bindTo]="viewModel.companyDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.companyMinCannabinoidUpdated($event)"
        [inputName]="viewModel.companyMinCannabinoidInputName$ | push"
        [label]="viewModel.companyMinCannabinoidLabel$ | push"
        [placeholder]="viewModel.companyMinCannabinoidPlaceholder$ | push"
        [bindingProperty]="'min' + (viewModel.cannabinoid$ | push)"
        [tooltip]="viewModel.companyMinCannabinoidTooltip$ | push"
        [required]="viewModel.hasCompanyMaxCannabinoid$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push) || (viewModel.disableCannabinoidInputs$ | push)"
        [minValue]="0"
        [maxValue]="viewModel.companyMaxCannabinoidLowerBoundValue$ | push"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>

      <lib-reactive-form-number
        (valueChanged)="viewModel.companyMaxCannabinoidUpdated($event)"
        [inputName]="viewModel.companyMaxCannabinoidInputName$ | push"
        [label]="viewModel.companyMaxCannabinoidLabel$ | push"
        [placeholder]="viewModel.companyMaxCannabinoidPlaceholder$ | push"
        [bindingProperty]="'max' + (viewModel.cannabinoid$ | push)"
        [tooltip]="viewModel.companyMaxCannabinoidTooltip$ | push"
        [required]="viewModel.hasCompanyMinCannabinoid$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push) || (viewModel.disableCannabinoidInputs$ | push)"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>

    <lib-reactive-form-group [bindTo]="viewModel.locationDA$ | push" [mergeKey]="mergeKey">
      <lib-reactive-form-number
        (valueChanged)="viewModel.locationMinCannabinoidUpdated($event)"
        [inputName]="'locationMin' + (viewModel.cannabinoid$ | push)"
        [label]="viewModel.locationMinCannabinoidLabel$ | push"
        [placeholder]="viewModel.locationMinCannabinoidPlaceholder$ | push"
        [bindingProperty]="'min' + (viewModel.cannabinoid$ | push)"
        [tooltip]="viewModel.locationMinCannabinoidTooltip$ | push"
        [required]="viewModel.hasLocationMaxCannabinoid$ | push"
        [disabled]="viewModel.disableCannabinoidInputs$ | push"
        [minValue]="0"
        [maxValue]="viewModel.locationMaxCannabinoidLowerBoundValue$ | push"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>

      <lib-reactive-form-number
        (valueChanged)="viewModel.locationMaxCannabinoidUpdated($event)"
        [inputName]="'locationMax' + (viewModel.cannabinoid$ | push)"
        [label]="viewModel.locationMaxCannabinoidLabel$ | push"
        [placeholder]="viewModel.locationMaxCannabinoidPlaceholder$ | push"
        [bindingProperty]="'max' + (viewModel.cannabinoid$ | push)"
        [tooltip]="viewModel.locationMaxCannabinoidTooltip$ | push"
        [required]="viewModel.hasLocationMinCannabinoid$ | push"
        [disabled]="viewModel.disableCannabinoidInputs$ | push"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>
  </ng-template>
</lib-reactive-form-column-layout>
