<hr *ngIf="showUpperHr" class="mt-none" />

<mat-accordion multi [displayMode]="'flat'" class="menu-additional-options-accordion">
  <mat-expansion-panel class="mat-elevation-z0 menu-additional-options-container">
    <mat-expansion-panel-header class="menu-additional-options-accordion-header">
      <mat-panel-title class="bs-medium f14px">
        {{ accordionTitle }}
        <img class="ms-2" *ngIf="showErrorIndicator" [src]="'assets/icons/red/solid/exclamation-circle.svg'" alt="" />
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
  <hr *ngIf="showBottomHr" class="mt-none" />
</mat-accordion>
