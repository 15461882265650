<div class="mt-24px padding-for-support-button">
  <!-- Menus -->
  <div *ngIf="(viewModel.numberOfItems$ | async) > 0" class="mt-16px">
    <!-- Display Preview Cards -->
    <app-displayable-item-container [items]="viewModel.paginatedItems$ | async"> </app-displayable-item-container>
  </div>
  <!-- No menus -->
  <div *ngIf="viewModel.noItems$ | async" class="mt-32px mb-96px flex-align-center">
    <div class="section-title full-centered-text">
      {{ viewModel.emptyStateTitle$ | async }}
    </div>
    <div class="bs-section-subtext flex-justify-center mt-16px full-centered-text">
      {{ viewModel.emptyStateDesc$ | async }}
    </div>
  </div>

  <hr class="mt-24px" />

  <!--   Pagination   -->
  <lib-reactive-table-paginator
    #menuPaginator
    [hidden]="(viewModel.numberOfItems$ | async) <= viewModel.ITEMS_PER_PAGE"
    [externalData]="viewModel.filteredDisplayableItems$ | async"
    [nPagesInIndicator]="viewModel.MAX_PAGES"
    [itemsPerPage]="viewModel.ITEMS_PER_PAGE">
  </lib-reactive-table-paginator>
</div>
