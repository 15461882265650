<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip">
  <lib-reactive-form-column-layout [nColumns]="2" [reversed]="false">
    <lib-reactive-form-text
      [inputName]="'menuName'"
      [label]="'Menu Name (Internal)' | replaceMenuWithTemplate : templateMode"
      [placeholder]="'Enter an internal name for the menu.' | replaceMenuWithTemplate : templateMode"
      [inlineLabel]="false"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedMenu"
      [bindingProperty]="'name'">
    </lib-reactive-form-text>

    <div *ngIf="isSurfsideTheme$ | async" class="company-logo">
      <img alt="company-logo.png" [src]="'assets/img/menu/surfside.png'" />
    </div>

    <lib-reactive-form-text
      *ngIf="!(hasLoopingContent$ | async)"
      [inputName]="'menuTitle'"
      [label]="'Menu Title' | replaceMenuWithTemplate : templateMode"
      [placeholder]="'Enter a title for this menu.' | replaceMenuWithTemplate : templateMode"
      [inlineLabel]="false"
      [required]="false"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedMenu"
      [bindingProperty]="'configurationTitle'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      #menuOverflowState
      *ngIf="bindTo | showProductOverflow"
      [inputName]="'overflowState'"
      [label]="'Product Overflow'"
      [placeholder]="'How should overflow products be handled'"
      [inlineLabel]="false"
      [disabled]="isTemplatedMenu"
      [required]="true"
      [customTooltipModal]="true"
      [tooltipModalTitle]="'Product Overflow Information'"
      [tooltipModalInfoItems]="tooltipModalInfoItems$ | async"
      [dropdowns]="overflowStates$ | async"
      [bindingProperty]="'overflowState'"
      (tooltipClicked)="openOverflowInfo()"
      (valueChanged)="setOverflowState($event)">
    </lib-reactive-form-drop-down>

    <!-- 3600 seconds is 1 hour -->
    <lib-reactive-form-number
      #sectionOverflowDuration
      [hidden]="sectionOverflowDurationDisabled$ | async"
      [inputName]="'sectionOverflowDuration'"
      [label]="'Section Overflow Duration'"
      [placeholder]="'Enter transition time (seconds)'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [minValue]="5"
      [maxValue]="3600"
      [customValueParser]="ClientTypes.ParserUtils.parseIntAsString"
      [disabled]="(sectionOverflowDurationDisabled$ | async) || isTemplatedMenu"
      [bindOnSubmit]="sectionOverflowDurationRequired$ | async"
      [required]="sectionOverflowDurationRequired$ | async"
      [tooltip]=""
      [bindingProperty]="'metadata.sectionOverflowDuration'">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-row *ngIf="showSubtitle$ | async">
    <lib-reactive-form-text
      [inputName]="'menuSubtitle'"
      [label]="'Menu Subtitle' | replaceMenuWithTemplate : templateMode"
      [placeholder]="'Please enter a subtitle for the menu' | replaceMenuWithTemplate : templateMode"
      [inlineLabel]="false"
      [required]="false"
      [maxLength]="128"
      [disabled]="isTemplatedMenu"
      [bindingProperty]="'subTitle'">
    </lib-reactive-form-text>
  </lib-reactive-form-row>
</lib-reactive-form-group>
