import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../../models/company/dto/location';

@Pipe({
  name: 'numberOfNewRequiredLocations'
})
export class NumberOfNewRequiredLocationsPipe implements PipeTransform {

  transform(original: Location[], updated: Location[]): string {
    const originalIds = original?.map(location => location?.id)?.filterNulls();
    const updatedIds = updated?.map(location => location?.id)?.filterNulls();
    const newLocations = updatedIds?.filter(id => !originalIds?.includes(id));
    return newLocations?.length > 0
      ? `(${newLocations?.length} new required location)`
          .pluralizer()
          .addRule({ listConnection: newLocations, useApostrophe: false, word: 'location' })
          .pluralize()
      : '';
  }

}
