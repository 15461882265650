export enum AddEditSmartFilterModalOpenedFrom {
  Products = 'products',
  Settings = 'settings',
  SectionCreate = 'SectionCreate',
  SectionEdit = 'SectionEdit',
  SmartLabel = 'SmartLabel',
  SmartBadge = 'SmartBadge',
  TemplateSectionCreate = 'TemplateSectionCreate',
  TemplatedSectionEdit = 'TemplatedSectionEdit',
}
