import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-header-subtext',
  templateUrl: './header-subtext.component.html',
  styleUrls: ['./header-subtext.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSubtextComponent {
}
