import { ToastService } from '../../../services/toast-service';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { Menu } from '../../../models/menu/dto/menu';
import { BehaviorSubject, combineLatest, of, Subject, throwError } from 'rxjs';
import { Previewable } from '../../../models/protocols/previewable';
import { BsError } from '../../../models/shared/bs-error';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AssetSize } from '../../../models/enum/dto/asset-size.enum';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { CachePolicy } from '../../../models/enum/shared/cachable-image-policy.enum';
import { DateUtils } from '../../../utils/date-utils';
import { DownloadUtils } from '../../../utils/download-utils';
import { BaseDisplay } from '../../../models/display/dto/base-display';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { MenuTemplate } from '../../../models/template/dto/menu-template';
import { MenuPreviewService } from '../../../services/menu-preview.service';

@Injectable()
export class LiveViewViewModel extends BaseModalViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private toastService: ToastService,
    private templateDomainModel: TemplateDomainModel,
    private menuPreviewService: MenuPreviewService,
    private sanitizer: DomSanitizer,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
    this.loadPreview();
  }

  // Loading
  protected override _loadingOpts = new BehaviorSubject(this.getLoadingSpinner());

  // Display Object
  public activePreview: BehaviorSubject<Previewable> = new BehaviorSubject<Previewable>(null);
  private activePreviewUrl$ = this.activePreview.pipe(
    map(prev => prev?.preview?.urls?.find(u => u?.size === AssetSize.Original)),
    switchMap(orig => (orig ? orig.srcUrl.pipe(map(([_, u]) => u)) : of<string|SafeResourceUrl>('')))
  );

  // Download Link
  public downloadClicked = new Subject<void>();
  private documentDownloader = this.downloadClicked.pipe(
    throttleTime(250),
    switchMap(_ => combineLatest([this.activePreview, this.activePreviewUrl$]).pipe(take(1))),
    takeUntil(this.onDestroy)
  ).subscribe(([preview, url]) => {
    DownloadUtils.downloadPreview(this.sanitizer, this.menu, preview, url);
  });

  public menu: Menu;
  public display: BaseDisplay;

  getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.defaultInButton();
    opts.spinnerColor = '#222';
    return opts;
  }

  loadPreview() {
    if (this.menu) {
      const lm = 'Loading Preview';
      if (!this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
        // Load Menu Preview
        const isTemplate = this.menu instanceof MenuTemplate;
        const existingTemplates$ = isTemplate ? this.templateDomainModel.menuTemplates$ : of(null);
        const getMenuPreview = (existingTemplates: MenuTemplate[]) => {
          // We want to forceUpdate the preview when a user manually opens the live view modal. This allows for
          // background system changes to be captured in previews (ie label color, text, DA names), as well as
          // for preview images to be replaced 'on demand' (once the preview loads, the image swaps out)
          return this.menuPreviewService.getMenuPreview(this.menu, false, 10000, null, true, null);
        };
        existingTemplates$.pipe(
          switchMap(existingTemplates => {
            return getMenuPreview(existingTemplates).pipe(
              tap(preview => this.activePreview.next(preview)),
              map(preview => preview?.preview?.getAssetUrl(AssetSize.Original)),
              tap(origUrl => origUrl?.loadAssetIntoSrcUrlSubject(CachePolicy.Service, DateUtils.unixOneHour() * 12)),
              switchMap(origUrl => origUrl?.srcUrl),
              map(([_, url]) => url),
              filter(url => !!url),
              take(1)
            );
          }),
          take(1) // complete and unsubscribe after first valid value
        ).subscribe({
          complete: () => this._loadingOpts.removeRequest(lm),
          error: (err: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(err);
          }
        });
      }
    }
  }

}

