import { BehaviorSubject, combineLatest, defer, iif, Observable, of, Subject, throwError } from 'rxjs';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Section } from '../../../models/menu/dto/section';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { ToastService } from '../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { AssetSize } from '../../../models/enum/dto/asset-size.enum';
import { DateUtils } from '../../../utils/date-utils';
import { BsError } from '../../../models/shared/bs-error';
import { DownloadUtils } from '../../../utils/download-utils';
import { Size } from '../../../models/shared/size';
import { AutoSaveViewModel } from '../../shared/components/auto-save/auto-save-view-model';
import { Theme } from '../../../models/menu/dto/theme';
import { ModalDuplicateMenu } from '../../../modals/modal-duplicate-menu';
import { ConfirmationOptions } from '../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../modals/modal-confirmation';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { MenuTemplate } from '../../../models/template/dto/menu-template';
import { StringUtils } from '../../../utils/string-utils';
import { SectionTemplate } from '../../../models/template/dto/section-template';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { NavigationService } from '../../../services/navigation.service';
import { MenuPreviewService } from '../../../services/menu-preview.service';
import { PrintPDFService } from '../../../services/print-pdf.service';
import { iiif } from '../../../utils/observable.extensions';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { ModalNewMenuSection } from '../../../modals/modal-new-menu-section';
import { DisplayDomainModel } from '../../../domainModels/display-domain-model';
import { TemplateCollectionDomainModel } from '../../../domainModels/template-collection-domain-model';
import { BulkPrintJobDomainModel } from '../../../domainModels/bulk-print-job-domain-model';
import { LiveViewUtils } from '../../../utils/live-view-utils';

@Injectable()
export abstract class EditMenuViewModel extends AutoSaveViewModel {

  constructor(
    protected bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected displayDomainModel: DisplayDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected productDomainModel: ProductDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected templateCollectionDomainModel: TemplateCollectionDomainModel,
    protected userDomainModel: UserDomainModel,
    protected menuPreviewService: MenuPreviewService,
    protected printPDFService: PrintPDFService,
    protected navigationService: NavigationService,
    protected toastService: ToastService,
    protected router: Router,
    protected ngbModal: NgbModal,
    protected sanitizer: DomSanitizer,
    protected activatedRoute: ActivatedRoute,
    protected injector: Injector
  ) {
    super();
    this.menuMode$.pipe(debounceTime(1)).once(menuMode => {
      if (menuMode) this._loadingOpts.addRequest('Loading Menu');
    });
  }

  public dismissModalSubject: Subject<Section> = new Subject<Section>();

  protected readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly locationId$ = this.locationDomainModel.locationId$;
  protected readonly activeHydratedMenuId$ = this.menuDomainModel.activeHydratedMenuId$;
  protected readonly activeTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  protected readonly activeMenuTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  public readonly templates$ = this.templateDomainModel.menuTemplates$;
  public readonly currentLocationMenus$ = this.menuDomainModel.currentLocationMenus$;
  public readonly userIsTemplateAdmin$ = this.userDomainModel.isTemplateAdmin$;

  override connectToAddRequest = (addRequest: string) => this._loadingOpts.addRequest(addRequest);

  private readonly _formAutoSubmitted = new Subject<any[]>();
  public readonly formAutoSubmitted$ = this._formAutoSubmitted as Observable<any[]>;
  connectToFormAutoSubmitted = (formAutoSubmitted: any[]) => this._formAutoSubmitted.next(formAutoSubmitted);

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  public readonly menuMode$ = this.templateMode$.pipe(map(templateMode => !templateMode));

  private readonly _saveTemplateFlag = new BehaviorSubject<boolean>(null); // true === background, false === foreground
  public readonly saveTemplateFlag$ = this._saveTemplateFlag as Observable<boolean>;
  connectToSaveTemplateFlag = (saveTemplateFlag: boolean) => this._saveTemplateFlag.next(saveTemplateFlag);

  protected _iFrameHeight = new BehaviorSubject<number>(0);
  public iFrameHeight$ = this._iFrameHeight.pipe(distinctUntilChanged());
  protected _iFrameWidth = new BehaviorSubject<number>(0);
  public iFrameWidth$ = this._iFrameWidth.pipe(distinctUntilChanged());
  iFrameHeightChanged(h: number) { this._iFrameHeight.next(h); }
  iFrameWidthChanged(w: number) { this._iFrameWidth.next(w); }

  public menuDuplicatingFlag$ = this.menuDomainModel.menuDuplicatingToNewLocation$;

  /* *************************** Menu Data *************************** */

  public menu$: Observable<MenuTemplate|Menu> = this.templateMode$.pipe(
    switchMap(templateMode => {
      return templateMode
        ? this.templateDomainModel.activeMenuTemplate$
        : this.menuDomainModel.activeHydratedMenu$;
    }),
    tap(menu => {
      this._iFrameHeight.next(menu?.displaySize?.height);
      this._iFrameWidth.next(menu?.displaySize?.width);
      if (menu) {
        this._loadingOpts.removeRequest('Loading Menu');
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableActiveToggleForMenu$ = combineLatest([
    this.menu$,
    this.displayDomainModel.currentLocationDisplays$,
    this.templateCollectionDomainModel.templateCollections$
  ]).pipe(
    map(([menu, locationDisplays, collections]) => {
      let menuIsInTemplateCollection = false;
      if (menu?.isTemplatedMenu()) {
        const collectionsWithTemplate = collections?.filter(c => c?.templateIds.contains(menu?.templateId));
        const collectionsWithTemplateIds = collectionsWithTemplate?.map(tc => tc?.id);
        menuIsInTemplateCollection = locationDisplays?.some(d => {
          return d?.templateCollectionIds?.some(tcId => collectionsWithTemplateIds?.contains(tcId));
        });
      }
      const menuInDisplay = locationDisplays?.some(d => d?.configurationIds?.contains(menu?.id));
      return menuInDisplay || menuIsInTemplateCollection;
    })
  );
  public isPrintableMenu$ = this.menu$.pipe(map(menu => menu?.isPrintableMenu()), distinctUntilChanged());
  public isTemplatedMenu$ = this.menu$.pipe(map(menu => menu?.isTemplatedMenu()), distinctUntilChanged());
  public canDeleteTemplateMenu$ = combineLatest([
    this.menu$,
    this.menuDomainModel.currentLocationMenus$,
    this.locationId$
  ]).pipe(
    map(([menu, currentLocationMenus, locationId]) => {
      return menu?.canDeleteRequiredTemplateMenu(currentLocationMenus, locationId);
    })
  );
  public menuName$ = this.menu$.pipe(map(menu => menu?.name));
  public menuActive$ = this.menu$.pipe(map(menu => menu?.active));
  public menuSections$ = this.menu$.pipe(
    map(menu => {
      const sortByPriority = (a, b) => a.priority - b.priority;
      const sections = menu instanceof MenuTemplate ? menu?.templateSections : menu?.sections;
      return sections?.sort(sortByPriority);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public nMenuSections$ = this.menuSections$.pipe(map(sections => sections?.length ?? 0));
  public menuListNavigationUrl$ = this.menu$.pipe(map(menu => menu?.getViewAllNavigationUrl()));
  public menuListNavigationFragment$ = this.menu$.pipe(map(menu => menu?.getViewAllNavigationFragment()));
  public menuHasSections$ = this.menuSections$.pipe(map(sections => sections?.length > 0));
  public menuSectionsLastIndex$ = this.menuSections$.pipe(map(sections => sections?.length - 1));

  public initialDisplaySize$ = this.menu$.pipe(map(menu => menu?.displaySize));

  /* *************************** User Downloadable Data - PDF *************************** */

  protected _userDownloadableDataLoadingOpts = new BehaviorSubject(this.getUserDownloadableDataLoadingOpts());
  public userDownloadableDataLoadingOpts$ = this._userDownloadableDataLoadingOpts.pipe(
    startWith(this.getUserDownloadableDataLoadingOpts()),
    pairwise(),
    switchMap(([prev, curr]) => {
      const requestRemoved = (prev?.awaitingRequests?.length ?? 0) > curr?.awaitingRequests?.length;
      let opts$ = of(curr);
      if (requestRemoved) opts$ = opts$.pipe(delay(1000));
      return opts$;
    })
  );

  getUserDownloadableDataLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.defaultInButton();
    opts.spinnerColor = '#222';
    return opts;
  }

  public readonly isLoadingUserDownloadableMenuData$ = iiif(
    this.isPrintableMenu$,
    this.printPDFService.isLoadingActivePrintMenuPDF$,
    this.menuPreviewService.isLoadingActiveMenu$
  ).pipe(
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly activeUserDownloadableMenuData$ = iiif(
    this.isPrintableMenu$,
    this.printPDFService.activePrintMenuPDF$,
    this.menuPreviewService.activePreview$
  );

  public readonly activeUserDownloadableMenuDataUrl$ = iiif(
    this.isPrintableMenu$,
    this.printPDFService.activePrintMenuPDFUrl$,
    this.menuPreviewService.activePreviewUrl$
  );

  public readonly activeUserDownloadableMenuDataTimeLabel$ = iiif(
    this.isPrintableMenu$,
    this.printPDFService.activePrintMenuPDFTimeLabel$,
    this.menuPreviewService.activePreviewTimeLabel$
  );

  public showUserDownloadableMenuDataButton$ = combineLatest([
    this.menu$,
    this.isLoadingUserDownloadableMenuData$,
    this.activeUserDownloadableMenuData$,
    this.activeUserDownloadableMenuDataUrl$
  ]).pipe(
    map(([menu, isLoading, preview, previewUrl]) => {
      const validTimeStamp = (preview?.preview?.timestamp !== null)
        && (preview?.preview?.timestamp !== undefined)
        && (preview?.preview?.timestamp > -1);
      return !!previewUrl
        && (menu?.isPrintableMenu())
        && !isLoading
        && validTimeStamp
        && DateUtils.unixAfterHoursAgo(preview?.preview?.timestamp, 6);
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected activeUserDownloadableDataUrl$ = this.activeUserDownloadableMenuData$.pipe(
    map(prev => prev?.preview?.urls?.find(u => u?.size === AssetSize.Original)),
    switchMap(orig => (orig ? orig.srcUrl.pipe(map(([_, u]) => u)) : of<string | SafeResourceUrl>('')))
  );

  public userDownloadableDataClicked = new Subject<void>();

  public generatePdfText$ = combineLatest([
    this.userDownloadableDataLoadingOpts$,
    this.showUserDownloadableMenuDataButton$
  ]).pipe(
    map(([loadingOpts, showDownloadButton]) => {
      switch (true) {
        case loadingOpts?.isLoading: return 'Creating…';
        case !showDownloadButton:    return 'Create';
        default:                     return 'Refresh';
      }
    })
  );

  public refreshMenuPreview(forceUpdate: boolean = false) {
    combineLatest([this.menu$, this.templateDomainModel.menuTemplates$]).once(([menu, templates]) => {
      const refresh$ = this.menuPreviewService.getMenuPreview(menu, false, 10000, null, forceUpdate, false);
      refresh$.pipe(take(1)).subscribe({
        error: (err: BsError) => {
          this.toastService.publishError(err);
          throwError(err);
        }
      });
    });
  }

  public refreshUserDownloadableMenuData(forceUpdate: boolean = true) {
    combineLatest([this.menu$, this.templateDomainModel.menuTemplates$]).once(([menu, templates]) => {
      const lm = 'Loading Menu Preview';
      const opts = this._userDownloadableDataLoadingOpts;
      if (!opts.containsRequest(lm)) {
        const fetchPDF$ = defer(() => this.printPDFService.fetchPrintMenuPDF(menu, forceUpdate));
        const fetchMenuPreview$ = defer(() => {
          return this.menuPreviewService.getMenuPreview(menu, false, 10000, null, forceUpdate, false);
        });
        const fetchData$ = iif(() => menu?.isPrintableMenu(), fetchPDF$, fetchMenuPreview$);
        opts.addRequest(lm);
        fetchData$.pipe(take(1)).subscribe({
          complete: () => opts.removeRequest(lm),
          error: (err: BsError) => {
            opts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(err);
          }
        });
      }
    });
  }

  /* *************************** Local Threads of Execution *************************** */

  private documentDownloader = this.userDownloadableDataClicked.pipe(
    switchMap(_ => {
      return combineLatest([
        this.activeUserDownloadableMenuData$,
        this.activeUserDownloadableDataUrl$,
        this.menu$
      ]).pipe(take(1));
    }),
    takeUntil(this.onDestroy)
  ).subscribe(([preview, url, menu]) => DownloadUtils.downloadPreview(this.sanitizer, menu, preview, url));

  protected fireIfTemplateMode$ = this.templateMode$.pipe(
    debounceTime(100),
    filter(templateMode => templateMode),
    distinctUntilChanged()
  );
  protected fireIfMenuMode$ = this.templateMode$.pipe(debounceTime(100), filter(templateMode => !templateMode));

  private selectActiveMenuTemplateFromRouteParam = this.fireIfTemplateMode$
    .pipe(switchMap(_ => this.templates$))
    .notNull() // wait for templates to load in before trying to select one
    .pipe(switchMap(_ => this.activatedRoute.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const templateId = params?.menuTemplateId;
        if (!!templateId) this.templateDomainModel.selectActiveMenuTemplate(templateId);
      }
    });

  private selectActiveMenuFromRouteParam = this.fireIfMenuMode$
    .pipe(switchMap(_ => this.currentLocationMenus$))
    .notNull()  // wait for menus to load in before trying to select one
    .pipe(switchMap(_ => this.activatedRoute.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const menuId = params?.menuId;
        if (!!menuId) this.menuDomainModel.selectActiveHydratedMenu(menuId);
      }
    });

  private loadHydratedMenuTemplateUponInitialization = this.fireIfTemplateMode$
    .pipe(switchMap(_ => this.activeTemplateId$))
    .subscribeWhileAlive({
      owner: this,
      next: templateId => {
        if (!!templateId) this.loadHydratedMenuTemplate();
      }
    });

  private loadHydratedMenuUponInitialization = this.fireIfMenuMode$
    .pipe(switchMap(_ => this.activeHydratedMenuId$))
    .subscribeWhileAlive({
      owner: this,
      next: menuId => {
        if (!!menuId) this.loadHydratedMenu();
      }
    });

  private menuBelongsToLocationId = this.fireIfMenuMode$.pipe(
      switchMap(_ => combineLatest([this.menu$.notNull(), this.locationId$.notNull()])),
      withLatestFrom(this.menuDuplicatingFlag$)
    )
    .subscribeWhileAlive({
      owner: this,
      next: ([[menu, locationId], duplicating]) => {
        if (!duplicating && menu?.locationId !== locationId) {
          this.router.navigate([menu?.getViewAllNavigationUrl()], { replaceUrl: true }).then(() => {
            this.menuDomainModel.deselectActiveHydratedMenu();
          });
        }
      }
    });

  /* ********************************************************************************** */

  public hideSections$ = combineLatest([this.menuSections$, this.menu$]).pipe(
    map(([sections, menu]) => !sections || sections?.length === 0 || menu?.isPrintReportMenu())
  );
  public hideReorderSections$ = this.nMenuSections$.pipe(map(n => n < 2));

  // Live View
  public allowLiveView$ = combineLatest([
    this.autoSaveLoadingOpts$,
    this.menu$
  ]).pipe(
    map(([loadingOpts, menu]) => LiveViewUtils.allowLiveView(loadingOpts, menu)),
    distinctUntilChanged()
  );

  public menuTheme$ = combineLatest([
    this.menu$,
    this.menuDomainModel.menuThemes$
  ]).pipe(
    map(([menu, themes]) => themes?.find(th => th.id === menu?.theme) as Theme | undefined),
  );

  // Menu warning message
  public menuWarningMessage$ = this.menu$.pipe(map(menu => menu?.getMenuWarningMessage()), startWith(''));
  public menuTooltipWarningMessage$ = this.menu$.pipe(map(menu => menu?.getMenuWarningMessageTooltip()), startWith(''));

  // Background Asset Loading
  public onSectionBackgroundDeleted() {
    this.menu$.once(menu => {
      (menu instanceof MenuTemplate) ? this.loadHydratedMenuTemplate(true) : this.loadHydratedMenu(true);
    });
  }

  navigateToEditSectionView(section: Section) {
    this.menu$.once(menu => this.navigationService.navigateToEditSectionOrTemplateSection(menu, section));
  }

  navigateToEditTemplate(template: MenuTemplate) {
    this.menu$.once(menu => this.navigationService.navigateToEditMenuOrTemplate(template));
  }

  public loadHydratedMenuTemplate(background: boolean = false) {
    combineLatest([this.locationId$.notNull(), this.activeMenuTemplateId$]).once(([locationId, templateId]) => {
      const lm = 'Loading Menu Template';
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      if (!loadingOpts.containsRequest(lm)) loadingOpts.addRequest(lm);
      this.templateDomainModel.getHydratedMenuTemplate(locationId, templateId).subscribe({
        complete: () => loadingOpts.removeRequest(lm),
        error: (err: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(err);
        }
      });
    });
  }

  protected loadHydratedMenu(background: boolean = false) {
    this.menuDomainModel.activeHydratedMenuId$.once(menuId => {
      const lm = 'Loading Menu';
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      if (!loadingOpts.containsRequest(lm)) loadingOpts.addRequest(lm);
      this.menuDomainModel.getHydratedMenu(menuId).subscribe({
        complete: () => {
          loadingOpts.removeRequest(lm);
        },
        error: (err: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(err);
        }
      });
    });
  }

  public saveMenu(background: boolean = true) {
    combineLatest([
      this.initialDisplaySize$,
      this.menu$
    ]).once(([initialDisplaySize, menu]) => {
      if (menu?.isWebMenu()) menu?.setWithWebMenuSizeProperties();
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      const menuType =  StringUtils.capitalizeFirstLetterOfEachWord(menu?.whatTypeOfMenuIsThis());
      const lm = `Saving ${menuType}`;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const saveMenu$ = (menu instanceof MenuTemplate)
          ? this.templateDomainModel.saveMenuTemplate(menu)
          : this.menuDomainModel.saveMenu(menu);
        saveMenu$.subscribe({
          complete: () => {
            loadingOpts.removeRequest(lm);
            this.setLastAutoSavedTimestampToNow();
            this.destroyAllTimerSubs();
            if (!background) {
              const msg = `${StringUtils.sentenceCase(menu?.whatTypeOfMenuIsThis())} successfully updated.`;
              const title = `Update ${menuType}`;
              this.toastService.publishSuccessMessage(msg, title);
            }
            this.checkForPreviewAndUserDownloadableMenuDataRefresh(menu, initialDisplaySize);
          },
          error: (err: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(err);
          }
        });
      }
    });
  }

  public backgroundSaveMenuWithCompletion(): Observable<Menu> {
    return combineLatest([
      this.initialDisplaySize$,
      this.menu$
    ]).pipe(
      take(1),
      switchMap(([initialDisplaySize, menu]) => {
        if (menu?.isWebMenu()) menu.setWithWebMenuSizeProperties();
        const menuType = StringUtils.capitalizeFirstLetterOfEachWord(menu?.whatTypeOfMenuIsThis());
        const lm = `Saving ${menuType}`;
        const loadingOpts = this._autoSaveLoadingOpts;
        if (!loadingOpts.containsRequest(lm)) {
          loadingOpts.addRequest(lm);
          const saveMenu$ = (menu instanceof MenuTemplate)
            ? this.templateDomainModel.saveMenuTemplate(menu)
            : this.menuDomainModel.saveMenu(menu);
          return saveMenu$.pipe(
            tap((m: Menu) => {
              loadingOpts.removeRequest(lm);
              this.checkForPreviewAndUserDownloadableMenuDataRefresh(menu, initialDisplaySize);
              this.setLastAutoSavedTimestampToNow();
              this.destroyAllTimerSubs();
            }),
            catchError((error: BsError) => {
              loadingOpts.removeRequest(lm);
              this.toastService.publishError(error);
              return throwError(error);
            })
          );
        } else {
          return of(null);
        }
      }),
      take(1)
    );
  }

  protected checkForPreviewAndUserDownloadableMenuDataRefresh(menu: Menu, initialDisplaySize: Size): void {
    if (!initialDisplaySize || initialDisplaySize?.hasChanges(menu?.displaySize)) {
      this.refreshMenuPreview(true);
    }
    if (menu?.isPrintMenu() || menu?.isPrintReportMenu()) this.refreshUserDownloadableMenuData();
  }

  public openLiveViewModal(sizeOverride?: Size) {
    combineLatest([this.locationId$, this.menu$]).once(([locationId, menu]) => {
      LiveViewUtils.openLiveViewModal(this.ngbModal, this.injector, locationId, menu, sizeOverride);
    });
  }

  /* Arrow function used to keep lexical scope */
  showLiveView = (
    allPristine: boolean,
    formsAutoSubmitted$: Observable<any[]>,
    submitForms: EventEmitter<boolean>
  ): void => {
    LiveViewUtils.openLiveView(allPristine, formsAutoSubmitted$, submitForms, this.openLiveViewModal.bind(this));
  };

  /* Arrow function used to keep lexical scope */
  toggleActiveState = (active): void => {
    this.menu$.once(menu => {
      menu.active = active;
      this.saveMenu(true);
    });
  };

  /* Arrow function used to keep lexical scope */
  openDuplicateMenuModal = () => this.menu$.once(menu => ModalDuplicateMenu.open(this.ngbModal, this.injector, menu));

  /* Arrow function used to keep lexical scope */
  public deleteMenu = () => {
    this.menu$.once(menu => {
      const opts = new ConfirmationOptions();
      const menuType = StringUtils.capitalizeFirstLetterOfEachWord(menu?.whatTypeOfMenuIsThis());
      opts.title = `Delete ${menuType}`;
      opts.bodyText = (menu instanceof MenuTemplate)
        ? `Are you sure you want to delete the following ${menuType.toLowerCase()}: '${menu?.name}'? `
          + `The templated menu will be deleted from all locations it is used at and removed from any displays `
          + `or template collections.\n\nThis action cannot be undone.`
        : `Are you sure you want to delete the following ${menuType.toLowerCase()}: '${menu?.name}'? `
          + `This action cannot be undone.`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmed = (cont: boolean) => {
        if (cont) this.deleteMenuHelper();
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmed);
    });
  };

  private deleteMenuHelper() {
    combineLatest([
      this.menu$,
      this.menuListNavigationUrl$,
      this.menuListNavigationFragment$
    ]).once(([menu, menuListUrl, menuListFragment]) => {
      const loadingOpts = this._loadingOpts;
      const menuType =  StringUtils.capitalizeFirstLetterOfEachWord(menu?.whatTypeOfMenuIsThis());
      const lm = `Deleting ${menuType}`;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const deleteMenu$ = (menu instanceof MenuTemplate)
          ? this.templateDomainModel.deleteMenuTemplate(menu)
          : this.menuDomainModel.deleteMenu(menu);
        deleteMenu$.pipe(
          // Add small delay so updated menus can propagate to menus-view-model
          delay(250),
          switchMap(() => this.locationId$),
          take(1)
        ).subscribe({
          next: (locationId) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishSuccessMessage('Menu deleted successfully.', 'Menu Deleted');
            this.unsavedChanges = false;
            this.router.navigate([menuListUrl], { replaceUrl: true, fragment: menuListFragment }).then(() => {
              this.menuDomainModel.deselectActiveHydratedMenu();
            });
            if (menu?.containsStackedContent()) {
              this.bulkPrintJobDomainModel.getBulkPrintJobs(locationId.toString()).once();
            }
          },
          error: (error: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(() => error);
          }
        });
      }
    });
  }

  /* ******************************** Section ********************************** */

  public deleteSection(section: Section) {
    const lm = 'Deleting Section';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.menu$.once(menu => {
        this.deleteSectionWithCompletion(section, menu).subscribe({
          complete: () => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishSuccessMessage('Section deleted successfully', 'Section Deleted');
          },
          error: (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      });
    }
  }

  public duplicateSection(section: Section) {
    combineLatest([
      this.menu$,
      this.allowAutoSaving$
    ]).once(([menu, allowAutoSaving]) => {
      if (allowAutoSaving) this.saveMenu(true);
      const onClose = (newSection: Section) => {
        const url = newSection?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalNewMenuSection.open(this.ngbModal, this.injector, menu, section, onClose);
    });
  }

  public deleteSectionWithCompletion(section: Section, menu: Menu | MenuTemplate): Observable<any> {
    return section instanceof SectionTemplate
      ? this.templateDomainModel.deleteMenuSectionTemplate(section, menu as MenuTemplate)
      : this.menuDomainModel.deleteMenuSection(section, menu as Menu);
  }

  public saveMenuSectionOrder(sections: Section[]): Observable<boolean> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        return menu instanceof MenuTemplate
          ? this.templateDomainModel.updateMenuSectionTemplatePriorities(menu, sections as SectionTemplate[])
          : this.menuDomainModel.updateMenuSectionsPriority(menu, sections);
      }),
      map((updatedSections) => updatedSections.length > 0),
      take(1)
    );
  }

  /* **************************************************************************** */

  override destroy() {
    super.destroy();
    this.menuDomainModel.deselectActiveHydratedMenu();
  }

}
