import { Pipe, PipeTransform } from '@angular/core';
import { VariantGroup } from '../../../../../../models/product/shared/variant-group';

@Pipe({
  name: 'showWillNotAppearDivider'
})
export class ShowWillNotAppearDividerPipe implements PipeTransform {

  transform(value: VariantGroup[], index: number): boolean {
    return value?.findIndex(group => !group?.includedOnMenu) === index;
  }

}
