<div class="loading-container">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
  <div class="media-container" [ngClass]="{ 'upload-visible': viewModel.uploadVisible$ | async }">
    <div class="preview-wrapper">
      <!--   Loading spinner for refresh image   -->
      <app-loading
        [hidden]="!(viewModel?.isRefreshingMediaLoading$ | async)"
        [options]="viewModel?.refreshingMediaLoadingOpts$ | async">
      </app-loading>
      <!--   Overlap wrapper for disabled items   -->
      <div [ngClass]="{ disabled: !enabledStorage?.enabled }" class="image-overlay"></div>
      <app-asset
        class="preview"
        [scaleFit]="true"
        [borderRadius]="'0.625rem'"
        [style.max-width]="'200px'"
        [showControls]="true"
        [asset]="file">
      </app-asset>
    </div>
    <div class="media-info-container flex-fill">
      <div *ngIf="viewModel.hasFileAndReplaceSwitchActivated$ | async">
        <div class="flex-container">
          <div class="name">
            {{ fileNameWithoutTimestampAndExtension$ | async }}
          </div>
        </div>
        <div class="mt-16px">
          <app-menu-media-form
            class="combo-card-media-form"
            [mergeKey]="mergeKey"
            [file]="file"
            [menuType]="ClientTypes.MarketingMenuType.DriveThru"
            [isDisabledByTemplates]="isTemplatedMenu"
            [bindTo]="viewModel.req">
          </app-menu-media-form>
        </div>
      </div>
      <div *ngIf="viewModel.showUploadAsset$ | async">
        <app-upload-asset
          [parentHandler]="this"
          [allowImage]="true"
          [allowVideo]="true"
          [maxAssets]="1"
          [displayList]="false"
          [formPristine]="formPristine"
          [isDisabledByTemplates]="isTemplatedMenu"
          [id]="index + 5">
        </app-upload-asset>
      </div>
      <div
        fxLayout="row wrap"
        [style.gap.rem]="0.5"
        class="button-container"
        [ngStyle]="{ bottom: (isFileVideo$ | async) ? '0' : '1rem' }">
        <lib-button-neutral
          (buttonClicked)="refreshMedia()"
          [hidden]="!(viewModel.file$ | async)"
          [disabled]="(viewModel?.isRefreshingMediaLoading$ | async) || !formPristine"
          [disabledTooltip]="formPristine | saveChangesTooltip"
          [iconSrc]="'assets/icons/dark/outline/refresh.svg'">
        </lib-button-neutral>
        <lib-button-neutral
          *ngIf="viewModel.file$ | async | exists"
          [disabled]="isTemplatedMenu || !formPristine"
          [disabledTooltip]="
            (isTemplatedMenu | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
          "
          (buttonClicked)="toggleReplaceMediaSwitch()">
          {{ (viewModel.replaceMediaSwitch$ | async) ? 'Cancel' : 'Replace Media' }}
        </lib-button-neutral>
        <lib-button-destructive
          *ngIf="viewModel.file$ | async | exists"
          [disabled]="!(sharedViewModel.allowDelete$ | async) || isTemplatedMenu || !formPristine"
          [disabledTooltip]="
            (isTemplatedMenu | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)
          "
          (buttonClicked)="toggleRemoveMediaDialog()">
          Remove Asset
        </lib-button-destructive>
      </div>
    </div>
  </div>
</div>
<div class="mt-16px">
  <hr class="no-margin" />
</div>
<div class="mt-16px" fxLayout="row" fxLayoutAlign="space-between center">
  <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="enabledStorage">
    <lib-reactive-form-switch
      [bindingProperty]="'enabled'"
      [inputName]="'enableProduct'"
      [disabled]="isTemplatedMenu"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      (valueChanged)="enableMediaClicked()"
      [inlineLabel]="true">
      Enable Product
    </lib-reactive-form-switch>
  </lib-reactive-form-group>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="0.5rem">
    <lib-button-neutral [throttleInMilliSec]="200" (buttonClicked)="previewCardClicked.emit(this.index)">
      <img [hidden]="showPreview" [src]="'assets/icons/dark/outline/eye.svg'" alt="" />
      <img [hidden]="!showPreview" [src]="'assets/icons/dark/outline/eye-off.svg'" alt="" />
      Preview Card
    </lib-button-neutral>
    <lib-button-destructive
      [disabled]="!(sharedViewModel.allowDelete$ | async) || isTemplatedMenu || !formPristine"
      [disabledTooltip]="(isTemplatedMenu | templatedMenuDisabledButtonTooltip) || (formPristine | saveChangesTooltip)"
      [ngClass]="{ disabled: !(sharedViewModel.allowDelete$ | async) }"
      (buttonClicked)="toggleRemoveProductDialog()"
      style="margin-left: 0">
      Remove Product
    </lib-button-destructive>
  </div>
</div>
