import { Injectable } from '@angular/core';
import { EditSpotlightMenuViewModel } from './edit-spotlight-menu-view-model';
import { EditSpotlightSectionViewModel } from './edit-spotlight-section-view-model';
import { CombinedLoadingOptsViewModel } from '../../../../shared/components/auto-save/combined-loading-opts-view-model';

@Injectable()
export class EditSpotlightMenuCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    private editSpotlightMenuViewModel: EditSpotlightMenuViewModel,
    private editSpotlightSectionViewModel: EditSpotlightSectionViewModel,
  ) {
    super([editSpotlightMenuViewModel, editSpotlightSectionViewModel]);
  }

}
