import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { HydratedVariantBadge } from '../../../../../models/product/dto/hydrated-variant-badge';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Theme } from '../../../../../models/menu/dto/theme';
import { DisplayAttribute } from '../../../../../models/display/dto/display-attribute';
import { Variant } from '../../../../../models/product/dto/variant';
import { Section } from '../../../../../models/menu/dto/section';
import { BadgeDomainModel } from '../../../../../domainModels/badge-domain-model';

@Injectable()
export class VariantBadgeModalViewModel extends BaseViewModel {

  constructor(
    private badgeDomainModel: BadgeDomainModel
  ) {
    super();
  }

  public loadingOpts: LoadingOptions = LoadingOptions.default();

  // Theme
  private theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
  public theme$ = this.theme.asObservable();

  // Section
  private section: BehaviorSubject<Section> = new BehaviorSubject<Section>(null);
  public section$ = this.section.asObservable();

  // Variant
  private variant: BehaviorSubject<Variant> = new BehaviorSubject<Variant>(null);
  public variant$ = this.variant.asObservable();
  public variantName$ = this.variant.pipe(map(v => v?.getVariantTitle()));
  public variantToolTip$ = this.variant.pipe(
    map(v => (v?.displayAttributes?.getDisplayName() ? v.name : null)),
    startWith('')
  );

  // Badges
  public allBadges$ = this.badgeDomainModel.allBadges$;
  private inheritedBadgeIds = new BehaviorSubject<string[]>([]);
  public inheritedBadgeIds$ = this.inheritedBadgeIds.asObservable();
  public inheritedBadges$ = combineLatest([
    this.inheritedBadgeIds$,
    this.allBadges$
  ]).pipe(map(([ids, badges]) => ids.map(id => badges?.find(b => b.id === id)).filterNulls() || []));
  public addToBackendBadgeIds: string[] = [];
  private addedToBackendBadgeIdsSubject = new BehaviorSubject<string[]>([]);
  public addedToBackendBadgeIds$ = this.addedToBackendBadgeIdsSubject.asObservable();
  public addedToBackendBadges$ = combineLatest([
    this.addedToBackendBadgeIds$,
    this.allBadges$
  ]).pipe(map(([ids, badges]) => ids.map(id => badges?.find(b => b.id === id)).filterNulls() || []));

  // Adding and removing new badges that haven't been saved to the backend - <DisplayAttribute.id, Badges[]>
  public addBadges: BehaviorSubject<HydratedVariantBadge[]> = new BehaviorSubject([]);
  public removeBadges: BehaviorSubject<HydratedVariantBadge[]> = new BehaviorSubject([]);

  // live badge list
  public liveBadgeDisplayAttributeUpdates$ = combineLatest([
    this.addBadges,
    this.removeBadges,
    this.addedToBackendBadges$,
  ]).pipe(
    map(([additions, removals, daBadges]) => {
      const badges = daBadges?.filter(b => !removals.map(removal => removal.id).contains(b.id)) ?? [];
      badges.push(...additions);
      return badges;
    }),
    tap(badges => this.updatedBadgeIds = badges.map(b => b.id))
  );
  public addedBadgeIds$ = this.liveBadgeDisplayAttributeUpdates$.pipe(map(badges => badges.map(b => b.id)));

  // Update badgeIds
  public updatedBadgeIds: string[] = [];

  // Description text
  public descText$ = this.theme$.pipe(
    map(theme => {
      return `Selected badges will always be shown on menus that support badges. `
           + `${theme?.themeFeatures?.nBadgesSupportedText()}`;
    })
  );

  setTheme(t: Theme) {
    this.theme.next(t);
  }

  setVariant(v: Variant) {
    this.variant.next(v);
  }

  setSection(s: Section) {
    this.section.next(s);
  }

  setBadges(da: DisplayAttribute, addedToBackendBadgeIds: string[]) {
    this.setAddedToBackendBadgeIds(addedToBackendBadgeIds);
    const daBadges = (da?.badgeIds?.length > 0) ? da?.badgeIds : null;
    const parentDABadges = da?.inheritedDisplayAttribute?.badgeIds?.length > 0
      ? da?.inheritedDisplayAttribute?.badgeIds
      : null;
    this.inheritedBadgeIds.next(Array.from(daBadges ?? parentDABadges ?? []));
  }

  setAddedToBackendBadgeIds(ids: string[]) {
    this.addToBackendBadgeIds = ids;
    this.addedToBackendBadgeIdsSubject.next(this.addToBackendBadgeIds);
  }

  canSave(): boolean {
    return !this.updatedBadgeIds.equals(this.addToBackendBadgeIds);
  }

  /**
   * Adds badge to removal list if already saved to backend.
   * If badge hasn't been added to the backend yet, then delete badge from add badge list.
   */
  setBadgeForRemoval(badge: HydratedVariantBadge) {
    const addBadgeList = this.addBadges.getValue();
    const addListIndex = addBadgeList?.findIndex(b => b.id === badge.id);
    const badgeIsInAddList = addListIndex > -1;
    if (badgeIsInAddList) {
      addBadgeList.splice(addListIndex, 1);
      this.addBadges.next(addBadgeList);
    } else {
      const hasRemoveList = this.removeBadges.getValue();
      if (hasRemoveList) {
        if (!hasRemoveList.contains(badge)) {
          hasRemoveList.push(badge);
          this.removeBadges.next(hasRemoveList);
        }
      } else {
        this.removeBadges.next([badge]);
      }
    }
  }

  /**
   * Adds badge to addition list if not already saved to backend.
   * If badge has already been added to backend, then delete from remove badge list.
   */
  setBadgeToAdditions(badge: HydratedVariantBadge) {
    const theme = this.theme.getValue();
    const maxBadgeCount = theme?.themeFeatures?.badgeCount ?? -1;
    const selectedCount = this?.updatedBadgeIds?.length ?? 0;
    const validMaxBadgeCount = maxBadgeCount > -1;
    const underLimit = selectedCount < maxBadgeCount;
    const noLimit = maxBadgeCount === 0;
    if (validMaxBadgeCount && (underLimit || noLimit)) {
      this.updateAddList(badge);
    }
  }

  /** Helper method for adding new badges - see setBadgeToAdditions */
  private updateAddList(badge: HydratedVariantBadge) {
    const removeBadgeList = this.removeBadges.getValue();
    const removeListIndex = removeBadgeList?.findIndex(b => b.id === badge.id);
    const badgeIsInRemoveList = removeListIndex > -1;
    if (badgeIsInRemoveList) {
      removeBadgeList.splice(removeListIndex, 1);
      this.removeBadges.next(removeBadgeList);
    } else {
      const hasAdditionList = this.addBadges.getValue();
      const addedToBackend = this.addedToBackendBadgeIdsSubject?.getValue()?.contains(badge.id);
      if (!addedToBackend) {
        if (hasAdditionList) {
          if (!hasAdditionList.contains(badge)) {
            hasAdditionList.push(badge);
            this.addBadges.next(hasAdditionList);
          }
        } else {
          this.addBadges.next([badge]);
        }
      }
    }
  }

  getUpdatedBadgeList(): string[] {
    return this.updatedBadgeIds;
  }

}
