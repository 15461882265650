import { Pipe, PipeTransform } from '@angular/core';
import { SmartFiltersDomainModel } from '../../../domainModels/smart-filters-domain-model';
import { Section } from '../../../models/menu/dto/section';
import { Observable } from 'rxjs';

@Pipe({
  name: 'isSectionSyncingSmartFilters'
})
export class IsSectionSyncingSmartFiltersPipe implements PipeTransform {

  constructor(
    private smartFiltersDomainModel: SmartFiltersDomainModel
  ) {
  }

  transform(section$: Observable<Section>): Observable<boolean> {
    return this.smartFiltersDomainModel.isSectionBeingSynced$(section$);
  }

}
