import { Injectable } from '@angular/core';
import { BaseViewModel } from 'src/app/models/base/base-view-model';
import { SectionColumnConfigDefaultState } from 'src/app/models/utils/dto/section-column-config-default-state-type';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { SectionColumnConfigKey, SectionColumnConfigProductInfoKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';
import { map } from 'rxjs/operators';
import { SectionLayoutType } from '../../../../../../../models/utils/dto/section-layout-type';
import { ThemeSectionColumnConfig } from '../../../../../../../models/menu/dto/theme-section-column-config';

// local interface for keeping track of column changes
export interface ConfigChanges { defaultState: SectionColumnConfigDefaultState; columnWidth?: number }

@Injectable()
export class ColumnOptionsFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _changes = new BehaviorSubject<ConfigChanges>(null);
  public changes$ = this._changes as Observable<ConfigChanges>;
  connectToChanges = (changes: ConfigChanges) => {
    this._changes.next(changes);
  };

  private _changedDefaultState = new BehaviorSubject<SectionColumnConfigDefaultState>(null);
  public changedDefaultState$ = this._changedDefaultState as Observable<SectionColumnConfigDefaultState>;
  connectToChangedDefaultState = (defaultState: SectionColumnConfigDefaultState) => {
    this._changedDefaultState.next(defaultState);
  };

  private _formColumnConfig = new BehaviorSubject<SectionColumnConfig>(null);
  public formColumnConfig$ = this._formColumnConfig as Observable<SectionColumnConfig>;
  connectToFormColumnConfig = (formColumnConfig: SectionColumnConfig) => {
    this._formColumnConfig.next(formColumnConfig);
  };

  private _columnSectionKey = new BehaviorSubject<SectionColumnConfigKey>(null);
  public columnSectionKey$ = this._columnSectionKey as Observable<SectionColumnConfigKey>;
  connectToColumnSectionKey = (columnSectionKey: SectionColumnConfigKey) => {
    this._columnSectionKey.next(columnSectionKey);
  };

  private _themeDefaults = new BehaviorSubject<ThemeSectionColumnConfig>(null);
  public themeDefaults$ = this._themeDefaults as Observable<ThemeSectionColumnConfig>;
  connectToThemeDefaults = (themeDefaults: ThemeSectionColumnConfig) => this._themeDefaults.next(themeDefaults);

  private _managingDefault = new BehaviorSubject<boolean>(false);
  public managingDefault$ = this._managingDefault as Observable<boolean>;
  connectToManagingDefault = (managingDefault: boolean) => this._managingDefault.next(managingDefault);

  public inputDisabled$ = combineLatest([
    this.formColumnConfig$,
    this.changedDefaultState$
  ]).pipe(
    map(([formColumnConfig, changedDefaultState]) => {
      let defaultState = formColumnConfig?.defaultState;
      if (!!changedDefaultState) {
        defaultState = changedDefaultState;
      }
      return !defaultState
        || defaultState === SectionColumnConfigDefaultState.Off
        || defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  private _sectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public sectionLayoutType$ = this._sectionLayoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._sectionLayoutType.next(sectionLayoutType);
  };

  public hideTextOptions$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigProductInfoKey.Asset || key === SectionColumnConfigProductInfoKey.Badges;
    })
  );

}
