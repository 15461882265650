import { Component } from '@angular/core';
import { LabelComponent } from '../label/label-component';
import { LabelViewModel } from '../label/label-view-model';

@Component({
  selector: 'app-basic-round-border-label',
  templateUrl: './basic-round-border-label.component.html',
  styleUrls: [
    '../label/label.scss',
    './basic-round-border-label.component.scss'
  ],
  providers: [LabelViewModel]
})
export class BasicRoundBorderLabelComponent extends LabelComponent {

  constructor(
    public override viewModel: LabelViewModel
  ) {
    super(viewModel);
  }

}
