import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { AllProductsDataTableViewModel } from '../../all-products-data-table/all-products-data-table-view-model';
import { SortUtils } from '../../../../utils/sort-utils';
import { OverrideProductGroup } from '../../../../models/product/dto/override-product-group';

@Component({
  selector: 'app-product-grouping-table-header',
  templateUrl: './product-grouping-table-header.component.html',
  styleUrls: ['./product-grouping-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => ProductGroupingTableHeaderComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupingTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent implements OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: AllProductsDataTableViewModel, // global provided by root
  ) {
    super(viewRef);
  }

  public nameAsc = (a: OverrideProductGroup, b: OverrideProductGroup) => SortUtils.nameAscending(a, b);
  public nameDesc = (a: OverrideProductGroup, b: OverrideProductGroup) => SortUtils.nameDescending(a, b);

  public productCountAsc = (a: OverrideProductGroup, b: OverrideProductGroup) => {
    const aProductCount = a?.productIds?.length || 0;
    const bProductCount = b?.productIds?.length || 0;
    return SortUtils.numberAscending(aProductCount, bProductCount);
  };
  public productCountDesc = (a: OverrideProductGroup, b: OverrideProductGroup) => {
    const aProductCount = a?.productIds?.length || 0;
    const bProductCount = b?.productIds?.length || 0;
    return SortUtils.numberDescending(aProductCount, bProductCount);
  };

  public childVariantCountAsc = (a: OverrideProductGroup, b: OverrideProductGroup) => {
    const aChildVariantCount = a?.products?.reduce((acc, curr) => acc + curr?.variants?.length ?? 0, 0) || 0;
    const bChildVariantCount = b?.products?.reduce((acc, curr) => acc + curr?.variants?.length ?? 0, 0) || 0;
    return SortUtils.numberAscending(aChildVariantCount, bChildVariantCount);
  };
  public childVariantCountDesc = (a: OverrideProductGroup, b: OverrideProductGroup) => {
    const aChildVariantCount = a?.products?.reduce((acc, curr) => acc + curr?.variants?.length ?? 0, 0) || 0;
    const bChildVariantCount = b?.products?.reduce((acc, curr) => acc + curr?.variants?.length ?? 0, 0) || 0;
    return SortUtils.numberDescending(aChildVariantCount, bChildVariantCount);
  };

  initializeFromBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

}
