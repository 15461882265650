import { Component } from '@angular/core';
import { CreateViewBulkPrintJobViewModel } from './create-view-bulk-print-job-view-model';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-view-bulk-print-job',
  templateUrl: './create-view-bulk-print-job.component.html',
  styleUrls: ['./create-view-bulk-print-job.component.scss'],
  providers: [CreateViewBulkPrintJobViewModel]
})
export class CreateViewBulkPrintJobComponent extends BaseModalComponent {

  constructor(
    public viewModel: CreateViewBulkPrintJobViewModel,
    protected activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

}
