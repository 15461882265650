import { ChangeDetectorRef, Component, forwardRef, Inject, ViewRef } from '@angular/core';
import { AllProductsTableEmptyStateComponent } from '../../../all-products-data-table/products-table/all-products-table-empty-state/all-products-table-empty-state.component';
import { ProductPickerDataTableViewModel } from '../../product-picker-data-table-view-model';
import { ReactiveTableEmptyStateBluePrintComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-product-picker-table-empty-state',
  templateUrl: '../../../all-products-data-table/products-table/all-products-table-empty-state/all-products-table-empty-state.component.html',
  styleUrls: [
    '../../../all-products-data-table/products-table/all-products-table-empty-state/all-products-table-empty-state.component.scss',
    './product-picker-table-empty-state.component.scss'
  ],
  providers: [
    {
      provide: ReactiveTableEmptyStateBluePrintComponent,
      useExisting: forwardRef(() => ProductPickerTableEmptyStateComponent)
    }
  ]
})
export class ProductPickerTableEmptyStateComponent extends AllProductsTableEmptyStateComponent {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public override viewModel: ProductPickerDataTableViewModel // global - provided by root
  ) {
    super(viewRef, viewModel);
  }

}
