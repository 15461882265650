import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';

@Injectable()
export class MenuSectionColorsViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  public colorPalette$ = this.companyDomainModel.colorPalette$;

}
