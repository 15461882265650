import { Deserializable } from '../protocols/deserializable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class ScheduledEmail implements Deserializable, UniquelyIdentifiable {

  public locationId: number;
  public id: string;
  public enabled: boolean;
  public recipientIds: string[];
  public enabledMenuIds: string[];
  public companyId: number;
  public menuIds: string[];

  onDeserialize() {
    this.recipientIds = Array.from(this.recipientIds || []);
    this.enabledMenuIds = Array.from(this.enabledMenuIds || []);
    this.menuIds = Array.from(this.menuIds || []);
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/MenuScheduleDTO.go
  onSerialize() {
    const dto = Object.create(ScheduledEmail.prototype);
    dto.locationId = this.locationId;
    dto.id = this.id;
    dto.enabled = this.enabled;
    dto.recipientIds = this.recipientIds;
    dto.enabledMenuIds = this.enabledMenuIds;
    dto.companyId = this.companyId;
    return dto;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.locationId}-
      ${this.id}-
      ${this.enabled}-
      ${this.recipientIds?.sort()?.join(',')}-
      ${this.enabledMenuIds?.sort()?.join(',')}-
      ${this.companyId}-
      ${this.menuIds?.sort()?.join(',')}-
    `;
  }

}
