import { Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';
import { DigitalTemplateCollectionsViewModel } from './digital-template-collections-view-model';

@Component({
  selector: 'app-digital-template-collections',
  templateUrl: './digital-template-collections.component.html',
  styleUrls: ['./digital-template-collections.component.scss'],
  providers: [DigitalTemplateCollectionsViewModel]
})
export class DigitalTemplateCollectionsComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: DigitalTemplateCollectionsViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
