import { TypeDefinition } from './type-definition';

export enum DefaultPrintCardPaperSize {
  DefaultSize_LetterPerforated_Uline = 'DefaultSize_LetterPerforated_Uline',
  DefaultSize_Letter_CustomCut = 'DefaultSize_Letter_CustomCut'
}

export class DefaultPrintCardPaperSizeType extends TypeDefinition {

  override value: DefaultPrintCardPaperSize;

}
