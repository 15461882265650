import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import type { PrintCardLiveViewModalViewModel } from './print-card-live-view-modal-view-model';
import type { Product } from '../../../models/product/dto/product';
import type { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'isArrowButtonSelected'
})
export class IsArrowButtonSelectedPipe implements PipeTransform {

  transform(value: PrintCardLiveViewModalViewModel, item: Product | Variant): Observable<boolean> {
    return value?.selected$(item) || of(false);
  }

}
