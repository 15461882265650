import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { SingleStackManualPrintJobViewModel } from './single-stack-manual-print-job-view-model';
import type { StackType } from '../create-view-stack-print-job.component';

@Component({
  selector: 'app-single-stack-manual-print-job',
  templateUrl: './single-stack-manual-print-job.component.html',
  styleUrls: ['./single-stack-manual-print-job.component.scss'],
  providers: [SingleStackManualPrintJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleStackManualPrintJobComponent {

  constructor(
    public viewModel: SingleStackManualPrintJobViewModel
  ) {
  }

  @Input() job: BulkPrintJob;
  @Input() mergeKey: string;
  @Input() placeholder: string;
  @Input() templateMode: boolean;
  @Input() viewOnly: boolean = false;
  @Input() stackType: StackType;

}
