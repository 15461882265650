import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BaseDisplay } from '../../../../../models/display/dto/base-display';

@Component({
  selector: 'app-edit-display-details',
  templateUrl: './edit-display-details.component.html',
  styleUrls: [
    './edit-display-details.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayDetailsComponent extends BaseComponent {

  @Input() display: BaseDisplay;
  @Input() mergeKey: string;
  @Output() copyDisplayUrl = new EventEmitter();

  constructor() {
    super();
  }

}
