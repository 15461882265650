<lib-reactive-form-group [bindTo]="viewModel.recurringDetails$ | push" [mergeKey]="mergeKey">
  <div class="mt-3 recurring-details-header">
    <div>
      <div class="bs-bold f18px">Recurring Details</div>
      <div class="bs-regular f14px">
        Select specific days of the week and times that the menu should appear on the display.
      </div>
    </div>
    <lib-reactive-form-switch
      #IsRecurringSwitch
      [inputName]="'specificTimeOverride'"
      [selectedValue]="true"
      [notSelectedValue]="false"
      [bindOnSubmit]="true"
      [bindingProperty]="'isRecurring'"
      (valueChanged)="switchRecurringTimes($event)">
    </lib-reactive-form-switch>
  </div>
  <div #runsOnTimeInterval class="mt-4">
    <lib-reactive-form-row [gap]="'1rem'">
      <lib-reactive-form-time
        #StartTime
        [inputName]="'startTime'"
        [label]="'Start Time'"
        [placeholder]="'Choose a start time'"
        [inputStep]="'60'"
        [required]="IsRecurringSwitch.getMyValue()"
        [disabled]="!IsRecurringSwitch.getMyValue()"
        [bindOnSubmit]="true"
        [bindingProperty]="'timeDuration.startTime'">
      </lib-reactive-form-time>

      <lib-reactive-form-time
        #EndTime
        [inputName]="'endTime'"
        [label]="'End Time'"
        [placeholder]="'Choose an end time'"
        [inputStep]="'60'"
        [required]="IsRecurringSwitch.getMyValue()"
        [disabled]="!IsRecurringSwitch.getMyValue()"
        [bindOnSubmit]="true"
        [bindingProperty]="'timeDuration.endTime'">
      </lib-reactive-form-time>
    </lib-reactive-form-row>

    <lib-reactive-form-row>
      <lib-reactive-form-checkboxes
        #DaysOfWeekCheckboxes
        [customValueParser]="parseDaysOfWeek"
        [bindingProperty]="'timeDuration.days'"
        [requireMinimumSelection]="IsRecurringSwitch.getMyValue() ? 1 : 0"
        [label]="'Days'"
        [inputName]="'Days'"
        [checkAllText]="'Select all days'"
        [disabled]="!IsRecurringSwitch.getMyValue()"
        [bindOnSubmit]="true"
        [inlineOptions]="true">
        <lib-reactive-form-checkbox
          [inputName]="'sun'"
          [selectedValue]="ClientTypes.Weekday.Sunday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Sunday
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'mon'"
          [selectedValue]="ClientTypes.Weekday.Monday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Monday
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'tues'"
          [selectedValue]="ClientTypes.Weekday.Tuesday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Tuesday
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'wed'"
          [selectedValue]="ClientTypes.Weekday.Wednesday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Wednesday
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'thurs'"
          [selectedValue]="ClientTypes.Weekday.Thursday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Thursday
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'fri'"
          [selectedValue]="ClientTypes.Weekday.Friday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Friday
        </lib-reactive-form-checkbox>

        <lib-reactive-form-checkbox
          [inputName]="'sat'"
          [selectedValue]="ClientTypes.Weekday.Saturday"
          [notSelectedValue]="null"
          [disabled]="!IsRecurringSwitch.getMyValue()">
          Saturday
        </lib-reactive-form-checkbox>
      </lib-reactive-form-checkboxes>
    </lib-reactive-form-row>
  </div>
</lib-reactive-form-group>
