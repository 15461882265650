import { TypeCheckUtils } from './type-check-utils';

export {};
declare global {
  interface Map<K, V> {
    deepCopy(): Map<K, V>;
    shallowCopy(): Map<K, V>;
    containsValue(value: V): boolean;
    getFirstKeyFromValue(value: any): K | undefined;
  }
}

Map.prototype.deepCopy = function<K, V>(): Map<K, V> {
  const copy = new Map<any, any>();
  for (const [key, val] of this) {
    const isPrimitive = TypeCheckUtils.isPrimitive(val);
    if (isPrimitive) {
      copy.set(key, JSON.parse(JSON.stringify(val)));
    } else if (val instanceof Array) {
      copy.set(key, val.deepCopy());
    } else if (val instanceof Map) {
      copy.set(key, val.deepCopy());
    } else if (typeof (val as any)?.onDeserialize === 'function') {
      copy.set(key, window?.injector?.Deserialize?.instanceOf((val as any).constructor, val));
    } else {
      copy.set(key, Object.assign(Object.create((val as any).prototype), val));
    }
  }
  return copy;
};

Map.prototype.shallowCopy = function<K, V>(): Map<K, V> {
  return new Map(this);
};

Map.prototype.containsValue = function<V>(searchVal: V): boolean {
  return [...(this.values() || [])].some(value => {
    return TypeCheckUtils.isPrimitive(value) && value === searchVal
      || (value instanceof Array && value === searchVal)
      || (value instanceof Array && value?.includes(searchVal))
      || (value instanceof Map && value === searchVal)
      || (value instanceof Map && value?.containsValue(searchVal));
  });
};

Map.prototype.getFirstKeyFromValue = function<K>(searchVal: any): K | undefined {
  return [...(this.entries() || [])]?.find(([_, value]) => {
    return TypeCheckUtils.isPrimitive(value) && value === searchVal
      || (value instanceof Array && value === searchVal)
      || (value instanceof Array && value?.includes(searchVal))
      || (value instanceof Map && value === searchVal)
      || (value instanceof Map && value?.getFirstKeyFromValue(searchVal));
  })?.[0];
};
