import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Device } from '../models/device/dto/device';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeviceAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Device';

  // Device

  public GetCompanyDevices(locationId: number, hydrated: boolean): Observable<Device[]> {
    const url = Endpoints.GetCompanyDevices(locationId, hydrated);
    return this.apiClient.getArr<Device>(Device, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyDevices', err));
        return throwError(err);
      })
    );
  }

  public GetDevice(id: string, hydrated: boolean): Observable<Device> {
    const url = Endpoints.GetDevice(id, hydrated);
    return this.apiClient.getObj<Device>(Device, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDevice', err));
        return throwError(err);
      })
    );
  }

  public ResetDevice(device: Device): Observable<Device> {
    const url = Endpoints.ResetDevice();
    return this.apiClient.postObj(Device, url, device).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResetDevice', err));
        return throwError(err);
      })
    );
  }

  public SetupDevice(device: Device): Observable<Device> {
    const url = Endpoints.SetupDevice();
    return this.apiClient.postObj(Device, url, device).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SetupDevice', err));
        return throwError(err);
      })
    );
  }

  public UpdateDevice(device: Device): Observable<Device> {
    const url = Endpoints.UpdateDevice();
    return this.apiClient.postObj(Device, url, device).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateDevice', err));
        return throwError(err);
      })
    );
  }

}
