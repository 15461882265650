<div class="product-grouping-info-list-item">
  <div class="name-and-delete">
    <div class="product-name">
      {{ viewModel.product$ | push | getProductTitle | doubleDashIfEmpty }}
      <app-table-property-changed
        *ngIf="!!((viewModel.product$ | push)?.displayAttributes | getDisplayName)"
        [oldValue]="product?.name">
      </app-table-property-changed>
    </div>
    <lib-button-inline-text [textColor]="'#fa5555'" (buttonClicked)="deleteFromGroup.emit(product?.id)">
      Remove
    </lib-button-inline-text>
  </div>
  <div class="variant-container">
    <div *rxFor="let variant of viewModel.variants$; trackBy: 'id'" class="variant-line-item">
      <ng-container *rxLet="viewModel.loading$ as loading">
        <div *rxIf="loading" class="variant-name">Loading…</div>
        <div *rxIf="!loading" class="variant-name">
          {{ variant | getVariantTitle }}
          <app-table-property-changed
            *ngIf="!!(variant?.displayAttributes | getDisplayName)"
            [oldValue]="variant?.name">
          </app-table-property-changed>
        </div>
      </ng-container>
      <div class="variant-brand">{{ variant?.brand }}</div>
      <div class="variant-type">{{ variant?.variantType }}</div>
      <div class="variant-size">{{ variant | variantFormattedSizing }}</div>
    </div>
  </div>
  <hr class="no-margin" />
</div>
