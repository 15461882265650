<div
  class="list-item-container"
  matRipple
  [class.selected]="stackMenu | isSelectedId : selectedId"
  (click)="idSelected.emit(stackMenu?.id)">
  <img
    class="icon"
    [src]="
      !!stackMenu?.templateId ? ClientTypes.MenuIconSrc.TemplatedMenu : ClientTypes.MenuSectionTypeIcon.CategoryCard
    "
    alt="icon" />
  <div class="menu-name">{{ stackMenu?.name }}</div>
  <img
    *ngIf="stackMenu | getStackVariantErrorTooltip : nSelectedVariants | push as errorTooltip"
    class="problem-icon"
    [ngbTooltip]="errorTooltip"
    [container]="'body'"
    [src]="'assets/icons/red/outline/exclamation-circle.svg'"
    alt="" />
  <div class="n-variants">{{ stackMenu | firstSectionNVariants : locationConfig : nSelectedVariants | push }}</div>
</div>
