export class ApiPagination {

  public count: number;
  public startKey: string;

  constructor(count?: number) {
    this.count = count;
  }

}
