import { TypeDefinition } from './type-definition';

export enum TerpeneUnitOfMeasure {
  Percent = '%',
  MilliGram = 'mg',
  MilliGramPerGram = 'mg/g',
  NA = 'N/A',
  UNKNOWN = '',
  MULTIPLE_VALUES = 'MULTIPLE_VALUES'
}

export class TerpeneUnitOfMeasureType extends TypeDefinition {

  override value: TerpeneUnitOfMeasure;
  hiddenSelectionOption = this.value === TerpeneUnitOfMeasure.MULTIPLE_VALUES;

  override getSelectionTitle(): string {
    if (this.value === TerpeneUnitOfMeasure.MULTIPLE_VALUES) return 'Multiple Selected';
    return super.getSelectionTitle();
  }

}
