import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { DisplayableItemPreviewViewModel } from './displayable-item-preview-view-model';
import { DisplayableItem } from './displayable-item';
import { Swiper } from 'swiper/types';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-displayable-item-preview',
  templateUrl: './displayable-item-preview.component.html',
  styleUrls: ['./displayable-item-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DisplayableItemPreviewComponent extends BaseComponent implements OnChanges, AfterViewInit {

  constructor(
    public viewModel: DisplayableItemPreviewViewModel
  ) {
    super();
  }

  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  @Input() displayableItem: DisplayableItem;
  @Input() imageFitContain: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayableItem) this.viewModel.connectToDisplayableItem(this.displayableItem);
  }

  override ngAfterViewInit(): void {
    register();
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  nextSlide(): void {
    this.swiper.slideNext();
    this.adjustActiveIndex();
  }

  previousSlide(): void {
    this.swiper.slidePrev();
    this.adjustActiveIndex();
  }

  private adjustActiveIndex(): void {
    this.viewModel.connectToActiveSlideIndex(this.swiper?.activeIndex);
  }

}
