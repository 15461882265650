import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { CollectionDisplaysModalContainerViewModel } from '../collection-displays-modal-container/collection-displays-modal-container-view-model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-collection-create-new-display',
  templateUrl: './edit-collection-create-new-display.component.html',
  styleUrls: ['./edit-collection-create-new-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCollectionCreateNewDisplayComponent extends BaseComponent {

  constructor(
    public container: CollectionDisplaysModalContainerViewModel
  ) {
    super();
  }

  @Output() cancel = new EventEmitter();
  public modalTitle$ = this.container.pendingDisplay$.pipe(
    map((pd) => {
      const isEditFlow = !!pd?.locationIds?.length;
      return isEditFlow ? 'Edit New Display' : 'Create New Display';
    })
  );

}
