import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-menu-section-location-permissions',
  templateUrl: './menu-section-location-permissions.component.html',
  styleUrls: ['./menu-section-location-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionLocationPermissionsComponent implements OnChanges {

  @Input() mergeKey: string;
  @Input() sectionTemplate: SectionTemplate;

  private _sectionTemplate: BehaviorSubject<SectionTemplate> = new BehaviorSubject<SectionTemplate>(null);
  public sectionTemplate$ = this._sectionTemplate as BehaviorSubject<SectionTemplate>;
  connectToSectionTemplate = (t: SectionTemplate) => this._sectionTemplate.next(t);

  public disableProductOverrides$ = this.sectionTemplate$.pipe(
    map(s => s?.smartFilterIds?.length > 0)
  );

  public showAllowStyleEditingOption$ = this.sectionTemplate$.pipe(
    map(sectionTemplate => !sectionTemplate?.isStackedContent())
  );

  public disableProductOverridesTooltip$ = this.disableProductOverrides$.pipe(
    map(isDisabled => {
      return isDisabled
        ? `Product overrides are disabled because products are added to this section using smart filters.`
        : undefined;
    })
  );

  public allowAddingProductsTooltip$ = of(
    'Allow for products to be manually added to a templated section at '
    + 'the location level. Products that are added in the template can not be removed at a location’s templated menu.'
  );

  public allowStyleEditingTooltip$ = of(
    'Allow for style overrides to be applied on a templated section at the '
    + 'location level. If a style override exists on the template, it will be disabled for that specific product on '
    + 'the templated section.'
  );

  public allowLabelOverrideTooltip$ = of(
    'Allow for label overrides to be applied on a templated section at the'
    + ' location level. If a label override exists on the template, it will be disabled for that specific product on '
    + 'the templated section.'
  );

  public allowBadgeOverrideTooltip$ = of(
    'Allow for badge overrides to be applied on a templated section at '
    + 'the location level. If a badge override exists on the template, it will be disabled for that specific product '
    + 'on the templated section.'
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sectionTemplate) this.connectToSectionTemplate(this.sectionTemplate);
  }

}
