import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, Inject, Input, OnDestroy, OnInit, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { SortUtils } from '../../../../../../../../utils/sort-utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-print-jobs-table-header',
  templateUrl: './bulk-print-jobs-table-header.component.html',
  styleUrls: ['./bulk-print-jobs-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => BulkPrintJobsTableHeaderComponent)
    }
  ]
})
export class BulkPrintJobsTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent
  implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
  ) {
    super(viewRef);
  }

  @Input() editPrintCardMode: boolean;
  @Input() editPrintLabelMode: boolean;

  private onDestroy$ = new Subject<void>();

  override ngOnInit() {
    super.ngOnInit();
    this._sortBy.next(this.timeCreatedDesc);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sortBy$.pipe(takeUntil(this.onDestroy$)).subscribe(sortBy => {
      if (!sortBy) this._sortBy.next(this.timeCreatedDesc);
    });
  }

  changesFromBluePrint(changes: SimpleChanges): void {
    if (!!changes.editPrintCardMode) this.editPrintCardMode = changes.editPrintCardMode.currentValue;
    if (!!changes.editPrintLabelMode) this.editPrintLabelMode = changes.editPrintLabelMode.currentValue;
  }

  initializeFromBluePrint(bluePrint: BulkPrintJobsTableHeaderComponent): void {
    this.editPrintCardMode = bluePrint.editPrintCardMode;
    this.editPrintLabelMode = bluePrint.editPrintLabelMode;
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  // Name Sort
  public nameAsc = (a: BulkPrintJob, b: BulkPrintJob) => SortUtils.numericStringAsc(a?.name, b?.name);
  public nameDesc = (a: BulkPrintJob, b: BulkPrintJob) => SortUtils.numericStringDesc(a?.name, b?.name);

  // Time Started Sort
  public timeStartedAsc = (a: BulkPrintJob, b: BulkPrintJob) => a?.dateStarted - b?.dateStarted;
  public timeStartedDesc = (a: BulkPrintJob, b: BulkPrintJob) => b?.dateStarted - a?.dateStarted;

  // Time Completed Sort
  public timeCompletedAsc = (a: BulkPrintJob, b: BulkPrintJob) => a?.dateCompleted - b?.dateCompleted;
  public timeCompletedDesc = (a: BulkPrintJob, b: BulkPrintJob) => b?.dateCompleted - a?.dateCompleted;

  // Status Sort
  public statusAsc = (a: BulkPrintJob, b: BulkPrintJob) => a?.jobStatus.localeCompare(b?.jobStatus);
  public statusDesc = (a: BulkPrintJob, b: BulkPrintJob) => b?.jobStatus.localeCompare(a?.jobStatus);

  // Time Created Sort (Not in Header, used for default sort)
  public timeCreatedDesc = (a: BulkPrintJob, b: BulkPrintJob) => b?.dateCreated - a?.dateCreated;

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.onDestroy$.next();
  }

}
