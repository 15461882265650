import { VariantBadgeModalComponent } from '../views/menu/components/modals/variant-badge-modal/variant-badge-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Theme } from '../models/menu/dto/theme';
import { Variant } from '../models/product/dto/variant';
import { Section } from '../models/menu/dto/section';
import { Injector } from '@angular/core';

export class ModalVariantBadge {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    theme: Theme,
    section: Section,
    variant: Variant,
    onClose: (selectedBadgeIds: string[]) => void
  ): void {
    const modalRef = ngbModal.open(
      VariantBadgeModalComponent,
      ModalUtils.sectionVariantBadgeModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as VariantBadgeModalComponent;
    if (theme) compInstance.setTheme(theme);
    if (variant) compInstance.setVariant(variant);
    if (section) compInstance.setSection(section);
    compInstance.setBadges(variant?.displayAttributes, section?.variantBadgeIdsMap?.get(variant?.id) || []);
    modalRef.result.then(onClose).catch(() => {});
  }

}
