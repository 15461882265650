import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CardStackTemplatePreviewViewModel } from './card-stack-template-preview-view-model';
import { MenuPreviewComponent } from '../menu-preview/menu-preview.component';

@Component({
  selector: 'app-card-stack-template-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: [
    '../displayable-item-preview/displayable-item-preview.component.scss',
    './card-stack-template-preview.component.scss'
  ],
  providers: [CardStackTemplatePreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackTemplatePreviewComponent extends MenuPreviewComponent {

  constructor(
    public override viewModel: CardStackTemplatePreviewViewModel
  ) {
    super(viewModel);
  }

}
