<div class="display-menu-options-container flex-take-up-remain-space">
  <!--   Provide some information about the overflow type for each menu   -->
  <div
    class="menu-overflow-type-container pb-25px"
    fxLayout="row"
    [hidden]="menu.type !== ClientTypes.MenuType.DisplayMenu">
    <div class="menu-overflow-type-label">
      Product Overflow: <span class="bs-semi-bold">{{ productOverflowTitle }}</span>
    </div>
    <div class="tooltip-container">
      <img
        class="inline-tooltip"
        (click)="openInformationModal.emit()"
        [src]="'assets/icons/dark/outline/information-circle.svg'"
        alt=""
        style="opacity: 1" />
    </div>
  </div>

  <ng-content></ng-content>
</div>
