import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DisplayableProductCard } from '../menu-cards/displayable-product-card';

@Component({
  selector: 'app-card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPreviewComponent {

  @Input() card: DisplayableProductCard;
  @Input() index: number;
  @Input() cardCount: number;

  constructor(
    public changeDetectorRef: ChangeDetectorRef
  ) {
  }

}
