import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { EditVariantGeneralComponent } from './components/edit-variant-general/edit-variant-general.component';
import { EditVariantCannabinoidsComponent } from './components/edit-variant-cannabinoids/edit-variant-cannabinoids.component';
import { EditVariantPricingComponent } from './components/edit-variant-pricing/edit-variant-pricing.component';
import { EditVariantAvailabilityComponent } from './components/edit-variant-availability/edit-variant-availability.component';
import { EditVariantCustomizationComponent } from './components/edit-variant-customization/edit-variant-customization.component';
import { EditVariantContainer } from './edit-variant-container';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../utils/distinct-utils';
import { Variant } from '../../../../../models/product/dto/variant';
import { ResizeObserver } from '@juggle/resize-observer';
import { EditVariantTerpenesComponent } from './components/edit-variant-terpenes/edit-variant-terpenes.component';

@Component({
  selector: 'app-edit-variant-modal',
  templateUrl: './edit-variant-modal.component.html',
  styleUrls: ['./edit-variant-modal.component.scss'],
  providers: [EditVariantContainer]
})
export class EditVariantModalComponent extends BaseModalComponent implements OnDestroy {

  constructor(
    protected activeModal: NgbActiveModal,
    public container: EditVariantContainer
  ) {
    super(activeModal);
  }

  @ViewChild('header') header: ElementRef<HTMLDivElement>;
  @ViewChild('modalBody') modalBody: ElementRef<HTMLDivElement>;
  @ViewChild('footer') bottomButtons: ElementRef<HTMLDivElement>;
  @ViewChild('tabContainer') tabContainer: ElementRef<HTMLDivElement>;
  @ViewChild('tabController') tabController: TabBarComponent;
  @ViewChild('editVariantFormCapture') formCapture: ReactiveFormMergeGroupsComponent;

  public tabs$ = combineLatest([
    this.container.variantHasCannabinoidsOrTerpenes$,
    this.container.variantHasIncompleteGeneralProperties$,
    this.container.variantHasIncompleteCannabinoidProperties$,
    this.container.currentlySelectedTabIndex$,
  ]).pipe(
    map(([hasCannabinoidsOrTerpenes, incompleteGeneralProperties, incompleteCannabinoidProperties, currentTabId]) => {
      return this.getTabs(
        !hasCannabinoidsOrTerpenes,
        incompleteGeneralProperties,
        incompleteCannabinoidProperties,
        currentTabId
      );
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
  );

  public canChangeTabsErrorMsg = new BehaviorSubject<string>('Please correct the invalid data before changing tabs.');

  private dialogRO: ResizeObserver;
  private tabContainerRO: ResizeObserver;
  private lastSaveTextRO: ResizeObserver;

  override setupBindings(): void {
    this.observeDialog();
    this.observeTabContainer();
    this.observeLastSaveText();
    this.container.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: dismiss => {
        if (dismiss) {
          this.cancel();
        }
      }
    });
  }

  private observeDialog(): void {
    this.dialogRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.container.connectToModalBodyHeight(entry.contentRect.height);
        this.calculateTabComponentHeight();
      }
    });
    this.dialogRO.observe(document.getElementsByClassName('modal-dialog').item(0));
  }

  private calculateTabComponentHeight(): void {
    const dialogRect = document.getElementsByClassName('modal-dialog').item(0)?.getBoundingClientRect();
    const headerRect = document.getElementsByClassName('sticky-header-container').item(0)?.getBoundingClientRect();
    const footerRect = document.getElementsByClassName('sticky-footer-container').item(0)?.getBoundingClientRect();
    const footer = document.getElementsByClassName('sticky-footer-container').item(0);

    const footerTopMargin = window.getComputedStyle(footer, null)?.getPropertyValue('margin-top');
    const footerTopMarginPx = parseInt(footerTopMargin, 10);
    const footerTopPadding = window.getComputedStyle(footer, null)?.getPropertyValue('padding-top');
    const footerTopPaddingPx = parseInt(footerTopPadding, 10);

    const tabLabelsRect = document.getElementsByClassName('mat-tab-header').item(0)?.getBoundingClientRect();
    const lastUpdatedText = document.getElementsByClassName('auto-save-text').item(0)?.getBoundingClientRect();
    const cannotChangeTabsBanner = document.getElementsByClassName('message-banner').item(0)?.getBoundingClientRect();
    const tabErrorBannerHeight = !!cannotChangeTabsBanner ? cannotChangeTabsBanner.height : 0;

    const heightWithoutHeaderAndFooter = dialogRect.height - headerRect.height - footerRect.height - footerTopPaddingPx;
    const heightUntilScrollableContent = heightWithoutHeaderAndFooter - tabLabelsRect.height - footerTopMarginPx;
    const heightAfterAutoSave = heightUntilScrollableContent - lastUpdatedText.height - tabErrorBannerHeight;
    this.container.connectToTabComponentHeight(heightAfterAutoSave);
  }

  private observeTabContainer(): void {
    this.tabContainerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.calculateTabComponentHeight();
        this.calculateBottomButtonContainerPosition(entry.contentRect.height);
      }
    });
    this.tabContainerRO.observe(this.tabContainer.nativeElement);
  }

  private calculateBottomButtonContainerPosition(contentHeight: number): void {
    const dialogRect = document.getElementsByClassName('modal-dialog').item(0)?.getBoundingClientRect();
    const headerRect = document.getElementsByClassName('sticky-header-container').item(0)?.getBoundingClientRect();
    const footerRect = document.getElementsByClassName('sticky-footer-container').item(0)?.getBoundingClientRect();
    const heightUntilScrollableContent = (dialogRect.height - headerRect.height) - footerRect.height;
    const position = contentHeight > heightUntilScrollableContent ? 'sticky' : 'absolute';
    this.container.connectToBottomButtonPosition(position);
  }

  private observeLastSaveText(): void {
    this.lastSaveTextRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.calculateTabComponentHeight();
      }
    });
    this.lastSaveTextRO.observe(document.getElementsByClassName('auto-save-text').item(0));
  }

  public setVariant(v: Variant, saveBeforeSwitching: boolean = false) {
    const changeData = () => {
      this.container.connectToVariantId(v?.id);
      this.container.connectToSelectedSiblingVariants([]);
      this.container.connectToLastAutoSaveTimestamp(null);
    };
    if (saveBeforeSwitching) {
      this.container.canSaveForm$.pipe(take(1)).subscribe(validForm => {
        if (validForm) {
          this.formCapture.submitForms(false);
        } else {
          changeData();
        }
      });
      this.container.saveAttempted$.pipe(take(1)).subscribe(saveAttempted => {
        changeData();
      });
    } else {
      changeData();
    }
  }

  public getTabs(
    disableCannabinoidsOrTerpenes: boolean = false,
    incompleteGeneral: number = null,
    incompleteCannabinoids: number = null,
    currentTabId: number = 0
  ): TabBarItem[] {
    const general = new TabBarItem(
      EditVariantGeneralComponent,
      'General',
      '',
      false,
      undefined,
      incompleteGeneral
    );
    const cannabinoids = new TabBarItem(
      EditVariantCannabinoidsComponent,
      'Cannabinoids',
      '',
      false,
      disableCannabinoidsOrTerpenes,
      incompleteCannabinoids
    );
    const terpenes = new TabBarItem(
      EditVariantTerpenesComponent,
      'Terpenes',
      '',
      false,
      disableCannabinoidsOrTerpenes
    );
    const pricing = new TabBarItem(
      EditVariantPricingComponent,
      'Pricing',
      '',
      false
    );
    const availability = new TabBarItem(
      EditVariantAvailabilityComponent,
      'Availability',
      '',
      false
    );
    const customization = new TabBarItem(
      EditVariantCustomizationComponent,
      'Customization',
      '',
      false
    );
    // const assets = new TabBarItem(EditVariantAssetsComponent,
    //   'Assets', '', false);
    const tabs = [general, cannabinoids, terpenes, pricing, availability, customization];
    TabBarItem.setSelectedTab(tabs, currentTabId);
    return tabs;
  }

  setPreviousTab(id: number) {
    this.container.connectToPreviouslySelectedTabId(id);
  }

  setCurrentTab(id: number) {
    this.container.connectToCurrentlySelectedTabIndex(id);
  }

  checkForAutosave(id: number) {
    combineLatest([this.container.unsavedChanges$, this.container.canSubmitForm$]).pipe(
      take(1),
      map(([hasChanges, canSubmit]) => {
        this.container.connectToCurrentlySelectedTabIndex(id);
        if (hasChanges && canSubmit) {
          this.formCapture.submitForms(true);
        }
      })
    ).subscribe();
  }

  override cancel(): void {
    this.checkForUnsavedChangesAndCancel();
  }

  checkForUnsavedChangesAndCancel() {
    this.container.unsavedChanges$.pipe(take(1)).subscribe(unsavedChanged => {
      if (unsavedChanged) {
        const cancelFunc = function(): void {
          this.activeModal.close(null);
        }.bind(this);
        this.container.unsavedChangesPrompt(cancelFunc);
      } else {
        super.cancel();
      }
    });
  }

  autoSaveVariant(submissions: any[]) {
    this.container.saveVariant(submissions, true);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.dialogRO.disconnect();
    this.tabContainerRO.disconnect();
    this.lastSaveTextRO.disconnect();
  }

}
