import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ReactiveTablePaginatorComponent } from '@mobilefirstdev/reactive-table';
import { DisplayableItemsViewModel } from './displayable-items-view-model';

@Component({ selector: 'app-displayable-item-list', template: '' })
export abstract class DisplayableItemListComponent extends BaseComponent {

  @ViewChild('menuPaginator') menuPaginator: ReactiveTablePaginatorComponent;

  constructor(
    public viewModel: DisplayableItemsViewModel
  ) {
    super();
  }

  override setupBindings() {
    this.menuPaginator.dataToDisplay$.subscribeWhileAlive({
      owner: this,
      next: paginatedMenus => {
        this.viewModel.connectToPaginatedItems(paginatedMenus);
      }
    });
  }

}
