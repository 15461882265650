import { Observable } from 'rxjs';
import { TypeDefinition } from './type-definition';
import { map } from 'rxjs/operators';
import { SizeUnit } from './size-unit-type';

export enum DefaultPrintSize {
  // NA Sizes
  PaperJuniorLegal = 'DefaultSize_JuniorLegal',
  PaperGovernmentLetter = 'DefaultSize_GovernmentLetter',
  PaperLetter = 'DefaultSize_Letter',
  PaperLegal = 'DefaultSize_Legal',
  PaperTabloid = 'DefaultSize_Tabloid',
  PaperLedger = 'DefaultSize_Ledger',
  // International Sizes
  PaperA0 = 'DefaultSize_A0',
  PaperA1 = 'DefaultSize_A1',
  PaperA2 = 'DefaultSize_A2',
  PaperA3 = 'DefaultSize_A3',
  PaperA4 = 'DefaultSize_A4',
  PaperA5 = 'DefaultSize_A5',
  PaperA6 = 'DefaultSize_A6',
  // Custom Sizes
  CustomLargeFormat24x60 = 'DefaultSize_CustomLargeFormat24x60'
}

export class DefaultPrintSizeType extends TypeDefinition {

  override value: DefaultPrintSize;

  static getDefaultPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
    return window?.types?.printSizeTypes$;
  }

  static getNAPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
    return window?.types?.printSizeTypes$.pipe(map(t => this.NAPrintSizes(t)));
  }

  static getInternationalPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
    return window?.types?.printSizeTypes$.pipe(map(t => this.internationalPrintSizes(t)));
  }

  private static NAPrintSizes(t: DefaultPrintSizeType[]): DefaultPrintSizeType[] {
    const naSizes =  [
      DefaultPrintSize.PaperJuniorLegal,
      DefaultPrintSize.PaperGovernmentLetter,
      DefaultPrintSize.PaperLetter,
      DefaultPrintSize.PaperLegal,
      DefaultPrintSize.PaperTabloid,
      DefaultPrintSize.PaperLedger,
    ];
    return t.filter(types => naSizes.includes(types.value));
  }

  private static internationalPrintSizes(t: DefaultPrintSizeType[]): DefaultPrintSizeType[] {
    const intSizes =  [
      DefaultPrintSize.PaperA0,
      DefaultPrintSize.PaperA1,
      DefaultPrintSize.PaperA2,
      DefaultPrintSize.PaperA3,
      DefaultPrintSize.PaperA4,
      DefaultPrintSize.PaperA5,
      DefaultPrintSize.PaperA6
    ];
    return t.filter(types => intSizes.includes(types.value));
  }

  static getUnitTypeFromSize(size: DefaultPrintSizeType): SizeUnit {
    switch (size?.value) {
      case DefaultPrintSize.PaperA0:
      case DefaultPrintSize.PaperA1:
      case DefaultPrintSize.PaperA2:
      case DefaultPrintSize.PaperA3:
      case DefaultPrintSize.PaperA4:
      case DefaultPrintSize.PaperA5:
      case DefaultPrintSize.PaperA6:
        return SizeUnit.Metric;
      case DefaultPrintSize.PaperJuniorLegal:
      case DefaultPrintSize.PaperGovernmentLetter:
      case DefaultPrintSize.PaperLetter:
      case DefaultPrintSize.PaperLegal:
      case DefaultPrintSize.PaperTabloid:
      case DefaultPrintSize.PaperLedger:
      case DefaultPrintSize.CustomLargeFormat24x60:
        return SizeUnit.Imperial;
    }
  }

  static defaultPrintSizes(): DefaultPrintSize[] {
    return [
      DefaultPrintSize.PaperA0,
      DefaultPrintSize.PaperA1,
      DefaultPrintSize.PaperA2,
      DefaultPrintSize.PaperA3,
      DefaultPrintSize.PaperA4,
      DefaultPrintSize.PaperA5,
      DefaultPrintSize.PaperA6,
      DefaultPrintSize.PaperJuniorLegal,
      DefaultPrintSize.PaperGovernmentLetter,
      DefaultPrintSize.PaperLetter,
      DefaultPrintSize.PaperLegal,
      DefaultPrintSize.PaperTabloid,
      DefaultPrintSize.PaperLedger,
      DefaultPrintSize.CustomLargeFormat24x60
    ];
  }

}
