import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { Breadcrumb } from '../../../../../../models/shared/stylesheet/breadcrumb';
import { Observable } from 'rxjs';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { EditMenuHeaderViewModel } from './edit-menu-header-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-menu-header',
  templateUrl: './edit-menu-header.component.html',
  styleUrls: [
    './edit-menu-header.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditMenuHeaderViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuHeaderComponent extends BaseComponent {

  constructor(
    public viewModel: EditMenuHeaderViewModel
  ) {
    super();
  }

  @Input() menu: Menu;
  @Input() crumbs: Breadcrumb[] = [];
  @Input() allowLiveView: boolean = true;
  @Input() autoSaveLoadingOpts: LoadingOptions;
  @Input() disableSave: boolean = false;
  @Input() disableActiveToggle: boolean = false;
  @Input() headerText: string = '';
  @Input() headerSubtext: string = '';
  @Input() hideLiveViewButton: boolean = false;
  @Input() lastSavedTimeText: string = '';
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() mergeKey: string;
  @Input() showSaveButton: boolean = true;
  @Input() userIsTemplateAdmin: boolean = false;
  @Input() showLiveView: (pristine: boolean, autoSubmitted$: Observable<any[]>, submit: EventEmitter<boolean>) => void;
  @Input() toggleActiveState: (active: boolean) => void;
  // emit true if you want the form to auto submit in the background
  @Output() submitForms = new EventEmitter<boolean>(true);
  @Output() editTemplateClicked = new EventEmitter<MenuTemplate>(true);

}
