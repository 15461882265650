<div class="menus-container header-padding h100p">
  <!-- Header -->
  <div class="row">
    <div class="col-12">
      <div class="bs-headline d-inline-flex">
        {{ title }}
      </div>
      <div class="mt-7px float-right">
        <lib-button-output-portal [portalKey]="'displayableContentKey'"></lib-button-output-portal>
      </div>
      <app-location class="location-list" [customText]="locationText"> </app-location>
      <ng-content select="[filter-form]"></ng-content>
    </div>
  </div>

  <div class="row mt-14px">
    <div class="col-12">
      <ng-content></ng-content>
    </div>
  </div>
</div>
