import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMenuHeaderComponent } from '../../edit-template-menu-header.component';
import { EditTemplateProductMenuViewModel } from '../edit-template-product-menu-view-model';

@Component({
  selector: 'app-edit-template-product-menu-header',
  templateUrl: './edit-template-product-menu-header.component.html',
  styleUrls: [
    './edit-template-product-menu-header.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateProductMenuHeaderComponent extends EditTemplateMenuHeaderComponent {

  constructor(
    public override viewModel: EditTemplateProductMenuViewModel
  ) {
    super(viewModel);
  }

}
