import { BehaviorSubject, Observable, of } from 'rxjs';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { Location } from '../../../../../models/company/dto/location';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class TemplateViewLocationsViewModel extends BaseModalViewModel {

  constructor(
    ngbModal: NgbModal,
    router: Router,
  ) {
    super(router, ngbModal);
  }

  public stickyZIndex$ = of(2);

  private _template = new BehaviorSubject<MenuTemplate>(null);
  public template$ = this._template as Observable<MenuTemplate>;
  connectToTemplate = (template: MenuTemplate) => this._template.next(template);

  private _requiredLocationIds = new BehaviorSubject<number[]>(null);
  public requiredLocationIds$ = this._requiredLocationIds as Observable<number[]>;
  connectToRequiredLocationIds = (requiredLocationIds: number[]) => this._requiredLocationIds.next(requiredLocationIds);

  private _province = new BehaviorSubject<string>(null);
  public province$ = this._province as Observable<string>;
  connectToProvince = (province: string) => this._province.next(province);

  private _locations = new BehaviorSubject<Location[]>(null);
  public locations$ = this._locations as Observable<Location[]>;
  connectToLocations = (locations: Location[]) => this._locations.next(locations);

  private _searchString = new BehaviorSubject<string>(null);
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _searchedLocations = new BehaviorSubject<Location[]>(null);
  public searchedLocations$ = this._searchedLocations as Observable<Location[]>;
  connectToSearchedLocations = (locations: Location[]) => this._searchedLocations.next(locations);

  trackById = (index, item) => item.id;

}
