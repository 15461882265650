import { Pipe, PipeTransform } from '@angular/core';
import { Display, LocationDisplayGrouping } from '../../../models/display/dto/display';

@Pipe({
  name: 'getDisplaysForProvince'
})
export class GetDisplaysForProvincePipe implements PipeTransform {

  transform(locationGroupings: LocationDisplayGrouping[]): Display[] {
    const displays = [];
    for (const grouping of locationGroupings) {
      for (const display of grouping.displays) {
        displays.push(display);
      }
    }
    return displays;
  }

}
