import { Component, Input } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent extends BaseComponent {

  @Input() card: Card;
  @Input() cardClass: string;

  constructor() {
    super();
  }

}
