import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Theme } from '../../../../../../../models/menu/dto/theme';
import { CacheService } from '../../../../../../../services/cache-service';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../../../domainModels/product-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { Router } from '@angular/router';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';

@Injectable()
export class EditFeaturedCategoryCardsViewModel extends BaseViewModel {

  constructor(
    public cacheService: CacheService,
    protected menuDomainModel: MenuDomainModel,
    protected productDomainModel: ProductDomainModel,
    protected toastService: ToastService,
    protected router: Router
  ) {
    super();
  }

  private _theme = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme.asObservable();

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();

  private readonly _formAutoSubmitted = new Subject<any[]>();
  public readonly formAutoSubmitted$ = this._formAutoSubmitted as Observable<any[]>;
  connectToFormAutoSubmitted = (formAutoSubmitted: any[]) => this._formAutoSubmitted.next(formAutoSubmitted);

  connectToTheme(t: Theme) {
    this._theme.next(t);
  }

  connectToMenu(m: Menu) {
    this._menu.next(m);
  }

}
