import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HydratedSmartFilter } from '../../../models/automation/hydrated-smart-filter';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements } from 'ngx-popperjs';
import { ClientTypeUtils } from '../../../utils/client-type-utils';
import { PopperUtils } from '../../../utils/popper-utils';

export const MAX_LABELS_ON_SF = 5;

/**
 * Requires dispersedKey to be set and a parent reactive form to be present.
 */
@Component({
  selector: 'app-reactive-form-inline-labels-picker',
  templateUrl: './reactive-form-inline-labels-picker.component.html',
  styleUrls: ['./reactive-form-inline-labels-picker.component.scss']
})
export class ReactiveFormInlineLabelsPickerComponent implements OnChanges {

  @Input() disabled: boolean = false;
  @Input() bindTo: HydratedSmartFilter;
  @Input() dispersedKey: string;
  @Input() maxSelectedLabels: number = MAX_LABELS_ON_SF;
  @Input() popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.TOP;
  @Input() useAtLeastNItemsFilledInPool = false;
  @Input() inlineText = 'Add Labels';
  @Input() useInlineText: boolean = true;

  @Output() updatedLabelIds = new EventEmitter<string[]>(true);

  @ViewChild(NgxPopperjsContentComponent) popperContent: NgxPopperjsContentComponent;

  private _labelIds = new BehaviorSubject<string[]>([]);
  public labelIds$ = this._labelIds as Observable<string[]>;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) this._labelIds.next(this.bindTo?.labelIds);
  }

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['left'])];
  public popperStyles = {
    'background-color': '#FFFFFF',
  };

  public setUpdatedLabelIds(ids: string[]) {
    const copy = ids.deepCopy();
    this.updatedLabelIds.emit(copy);
    this._labelIds.next(copy);
  }

  protected readonly ClientTypeUtils = ClientTypeUtils;

}
