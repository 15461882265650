<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <input hidden #input [formControlName]="inputName" [id]="id" [name]="inputName" />
</div>

<app-label-stack-print-job-select-variant-count
  *rxFor="let variant of stackVariants; trackBy: 'id'"
  [hidden]="!(searchedVariantIds | includes : variant.id)"
  [dispersedKey]="dispersedKey"
  [variant]="variant"
  [variantCountMap]="inputMap"
  [viewOnly]="viewOnly"
  (updateVariantCountMap)="updateVariantCountMap.emit([variant?.id, $event])">
</app-label-stack-print-job-select-variant-count>
