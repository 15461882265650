import { Pipe, PipeTransform } from '@angular/core';
import { InventoryRoom } from '../../../models/company/dto/inventory-room';

@Pipe({
  name: 'inventoryRoomsUsePurpose'
})
export class InventoryRoomsUsePurposePipe implements PipeTransform {

  transform(irs: InventoryRoom[], truncateToChars: number): string {
    const usePurpose = irs?.map(ir => ir.usePurpose).uniqueInstance(true);
    if (!!truncateToChars && isFinite(truncateToChars) && truncateToChars < usePurpose.length) {
      return usePurpose?.slice(0, truncateToChars);
    } else {
      return usePurpose;
    }
  }

}
