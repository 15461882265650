import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { map, switchMap } from 'rxjs/operators';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';
import { Menu } from '../../../../../models/menu/dto/menu';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';

@Injectable()
export class PrintJobSelectStacksViewModel {

  constructor(private menuDomainModel: MenuDomainModel) {
  }

  public readonly currentLocationCardStackMenus$ = this.menuDomainModel.currentLocationPrintCards$;
  public readonly currentLocationLabelStackMenus$ = this.menuDomainModel.currentLocationPrintLabels$;

  private readonly _stackPrintType = new BehaviorSubject<StackPrintType>(null);
  public readonly stackPrintType$ = this._stackPrintType as Observable<StackPrintType>;
  connectToStackPrintType = (stackPrintType: StackPrintType) => this._stackPrintType.next(stackPrintType);

  public readonly menuStacksToSelectFrom$ = this.stackPrintType$.notNull().pipe(
    switchMap(stackPrintType => {
      const compareWith = stackPrintType?.toLowerCase();
      switch (true) {
        case compareWith?.includes('label'):
          return this.currentLocationLabelStackMenus$;
        default:
          return this.currentLocationCardStackMenus$;
      }
    })
  );

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    this._job.next(job);
    this._selectedStackIds.next(job?.menuIds ?? null);
  };

  private _searchedMenus = new BehaviorSubject<Menu[]>([]);
  public searchedMenus$ = this._searchedMenus as Observable<Menu[]>;
  connectToSearchedMenus = (searchedMenus: Menu[]) => this._searchedMenus.next(searchedMenus);

  private _selectedStackIds = new BehaviorSubject<string[]>([]);
  public selectedStackIds$ = this._selectedStackIds as Observable<string[]>;
  connectToSelectedStackIds = (ids: string[]) => this._selectedStackIds.next(ids);

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public menusAsSelectionItem$ = this.searchedMenus$.pipe(
    map(menus => {
      return new (class implements HasChildIds {

        getId = (): string => '';
        getChildIds = (): string[] => menus?.map(menu => menu?.getId()) ?? [];

      })();
    })
  );

}
