import { Pipe, PipeTransform } from '@angular/core';
import { DefaultStackedSizeType } from '../../../../models/utils/dto/default-stacked-size-type';

@Pipe({
  name: 'getImageSrcFromStackSize'
})
export class GetImageSrcFromStackSizePipe implements PipeTransform {

  transform(value: DefaultStackedSizeType): string | null {
    return value?.getImageSrcFromSize() ?? null;
  }

}
