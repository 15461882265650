import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { EventEmitter, Injectable } from '@angular/core';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { LogoTypeEnum } from '../../../../../models/company/enum/logo-type.enum';
import { UploadFilePath } from '../../../../../models/enum/dto/upload-file.path';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable()
export class ReplaceCompanyPhotoViewModel extends BaseModalViewModel {

  public type: LogoTypeEnum;
  public locationId: number;
  public headerTitle: string;
  protected override _loadingOpts = new BehaviorSubject(this.getLoadingSpinner());
  public file: BudsenseFile;

  public uploadStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
    this.setType(LogoTypeEnum.Default, 0);
  }

  setType(t: LogoTypeEnum, locationId: number) {
    this.type = t;
    this.locationId = locationId;
    const alternative = t === LogoTypeEnum.Alternative ? 'Alternative' : '';
    this.headerTitle = `Replace ${alternative} ${locationId > 0 ? 'Location' : 'Company'}  Logo`;
  }

  private getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = 'Uploading';
    opts.isLoading = false;
    opts.fullscreen = false;
    return opts;
  }

  public uploadLogo() {
    this.uploadStatusChanged.emit(true);
    const alt = this.type === LogoTypeEnum.Alternative;
    const logoType = alt ? UploadFilePath.CompanyAltLogoPath : UploadFilePath.CompanyLogoPath;
    const fileCopy = this.file; // Required as this.file is undefined when we close the modal
    this.dismissModalSubject.next(true);
    this.companyDomainModel.uploadCompanyPicture(fileCopy, logoType, this.locationId).subscribe((_) => {
      this.toastService.publishSuccessMessage(
        `${this.locationId > 0 ? 'Location' : 'Company'} logo successfully uploaded.`,
        'Logo Uploaded'
      );
      this.uploadStatusChanged.emit(false);
    }, (error: BsError) => {
      this.toastService.publishError(error);
      this.uploadStatusChanged.emit(false);
      throwError(error);
    });
  }

}
