<div
  matRipple
  fxLayout="row"
  fxLayoutAlign="center center"
  class="remove-button-container"
  [style.borderRadius.%]="50"
  [style.height]="height"
  [style.width]="width"
  [style.backgroundColor]="backgroundColor">
  <img
    class="label-added-by-smart-filter-icon"
    alt="added by smart filter icon"
    [src]="'assets/icons/light/outline/lightning-bolt.svg'"
    [style.transform]="'scale(' + iconScale + ')'" />
</div>
