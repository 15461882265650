import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isSmartLabelWithRemoveExistingOnSyncEnabled'
})
export class IsSmartLabelWithRemoveExistingOnSyncEnabledPipe implements PipeTransform {

  transform(
    label: Label,
    calculationData$: Observable<[Label[], number]>
  ): Observable<boolean> {
    return calculationData$.pipe(
      map(([companyCustomLabels, companyId]) => {
        if (label?.isCompanyLabel(companyId)) {
          return label?.smartLabelWithRemoveExistingOnSyncEnabled();
        } else if (label?.isCompanyManaged) {
          const companyEquivalent = companyCustomLabels?.find(companyLabel => companyLabel?.id === label?.id);
          return companyEquivalent?.smartLabelWithRemoveExistingOnSyncEnabled();
        }
        return false;
      })
    );
  }

}
