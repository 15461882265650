import { Deserializable } from '../protocols/deserializable';
import { ObjectHasSelectableWrapper } from '../protocols/object-has-selectable-wrapper';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { Orientation } from '../utils/dto/orientation-type';
import { DefaultDigitalSize } from '../utils/dto/default-digital-size-type';
import { SizeUnit } from '../utils/dto/size-unit-type';

export class Size implements Deserializable, UniquelyIdentifiable, ObjectHasSelectableWrapper {

  public name: string;
  public unit: SizeUnit;
  public orientation: Orientation;
  public height: number;
  public width: number;
  // Display Name
  public displayName: string;

  constructor() {
    this.name = '';
    this.orientation = Orientation.NA;
    this.width = 0;
    this.height = 0;
  }

  public static sevenTwentyP(orientation: Orientation): Size {
    const size = new Size();
    size.name = '720p';
    size.unit = SizeUnit.Digital;
    size.displayName = DefaultDigitalSize.Digital720p;
    size.orientation = orientation;
    size.width = orientation === Orientation.Landscape ? 1280 : 720;
    size.height = orientation === Orientation.Landscape ? 720 : 1280;
    return size;
  }

  public onDeserialize() {
    this.setDisplayName();
  }

  getSelectionUniqueIdentifier(): string {
    return this.name;
  }

  private setDisplayName() {
    switch (this.name) {
      case DefaultDigitalSize.Digital720p:
        this.displayName = '720p';
        break;
      case DefaultDigitalSize.Digital1080p:
        this.displayName = '1080p';
        break;
      case DefaultDigitalSize.Digital4k:
        this.displayName = '4k';
        break;
      case DefaultDigitalSize.Digital8k:
        this.displayName = '8k';
        break;
    }
  }

  public isLandscape(): boolean {
    return this.orientation === Orientation.Landscape;
  }

  public isPortrait(): boolean {
    return this.orientation === Orientation.Portrait || this.orientation === Orientation.ReversePortrait;
  }

  public hasChanges(u: Size): boolean {
    return this.orientation !== u.orientation || this.height !== u.height || this.width !== u.width;
  }

  static getDigitalSizeFromPaper(s: Size): Size {
    const defaultDotsPerInch: number = 150; // default DPI is 150 - could be as low as 72 though
    let digitalHeight; let digitalWidth: number;
    if (s.unit === SizeUnit.Metric) {
      // convert mm to pixel - 1 inch = 25.4mm
      const imperialHeight = s.height / 25.4;
      const imperialWidth = s.width / 25.4;
      digitalHeight = imperialHeight * defaultDotsPerInch;
      digitalWidth = imperialWidth * defaultDotsPerInch;
    } else if (s.unit === SizeUnit.Imperial) {
      // convert inch to pixel
      digitalHeight = s.height * defaultDotsPerInch;
      digitalWidth = s.width * defaultDotsPerInch;
    } else {
      return s;
    }
    const newSize = new Size();
    newSize.height = Math.round(digitalHeight);
    newSize.width = Math.round(digitalWidth);
    newSize.orientation = s.orientation;
    newSize.name = s.name;
    newSize.displayName = s.displayName;
    return newSize;
  }

  getUniqueIdentifier(...opts: any): string {
    return `
      ${this.name}-
      ${this.unit}-
      ${this.orientation}-
      ${this.height}-
      ${this.width}-
      ${this.displayName}-
    `;
  }

}
