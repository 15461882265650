import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { Display } from '../../../../../models/display/dto/display';
import { CompactMenu } from '../../../../../models/menu/dto/compact-menu';
import { map } from 'rxjs/operators';
import { DisplayMenuOptions } from '../../../../../models/display/shared/display-menu-options';
import { LocalizedDateRange } from '../../../../../models/shared/localized-date-range';
import { DisplayDomainModel } from '../../../../../domainModels/display-domain-model';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { StringUtils } from '../../../../../utils/string-utils';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { RecurringDetails } from './recurring-details';

@Injectable()
export class EditSpecificDateTimeModalViewModel extends BaseModalViewModel {

  constructor(
    public displayDomainModel: DisplayDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public editDisplayViewModel: EditDisplayViewModel;
  public saveDisplay: null | ((display: Display|TemplateCollection) => Observable<Display[]>);

  public mergeKey$ = of('date-time-modal');

  public closeModalSubject = new Subject<void>();

  private _display = new BehaviorSubject<Display|TemplateCollection>(null);
  public display$ = this._display as Observable<Display|TemplateCollection>;
  connectToDisplay = (display: Display|TemplateCollection) => this._display.next(display);

  private _compactMenu = new BehaviorSubject<CompactMenu>(null);
  public compactMenu$ = this._compactMenu as Observable<CompactMenu>;
  connectToCompactMenu = (compactMenu: CompactMenu) => this._compactMenu.next(compactMenu);

  private _displayOptions = new BehaviorSubject<DisplayMenuOptions>(new DisplayMenuOptions());
  public displayOptions$ = this._displayOptions as Observable<DisplayMenuOptions>;
  connectToDisplayOptions = (displayOptions: DisplayMenuOptions) => this._displayOptions.next(displayOptions);

  private _dateTimeWindows = new BehaviorSubject<LocalizedDateRange[]>([]);
  public dateTimeWindows$ = this._dateTimeWindows as Observable<LocalizedDateRange[]>;
  connectToDateTimeWindows = (dateTimeWindows: LocalizedDateRange[]) => this._dateTimeWindows.next(dateTimeWindows);

  public timeDuration$ = this.displayOptions$.pipe(
    map(displayOptions => displayOptions?.override)
  );

  public recurringDetails$ = this.timeDuration$.pipe(
    map(timeDuration => {
      const recurringDetails: RecurringDetails = {
        isRecurring: timeDuration?.isRecurring() ?? false,
        timeDuration
      };
      return recurringDetails;
    })
  );

  private _canSubmit = new BehaviorSubject<boolean>(false);
  public canSubmit$ = this._canSubmit as Observable<boolean>;
  connectToCanSubmit = (canSubmit: boolean) => {
    this._canSubmit.next(canSubmit);
  };

  private _unsavedChanges = new BehaviorSubject<boolean>(false);
  public unsavedChanges$ = this._unsavedChanges as Observable<boolean>;
  connectToUnsavedChanges = (unsavedChanges: boolean) => this._unsavedChanges.next(unsavedChanges);

  private _dateTimeWindowAdded = new BehaviorSubject<boolean>(false);
  public dateTimeWindowAdded$ = this._dateTimeWindowAdded as Observable<boolean>;

  private _dateTimeWindowRemoved = new BehaviorSubject<boolean>(false);
  public dateTimeWindowRemoved$ = this._dateTimeWindowRemoved as Observable<boolean>;

  public menuId$ = this.compactMenu$.pipe(
    map(menu => menu?.id)
  );

  public menuName$ = this.compactMenu$.pipe(
    map(menu => menu?.name)
  );

  public canSave$ = combineLatest([
    this.canSubmit$,
    this.unsavedChanges$,
    this.dateTimeWindowAdded$,
    this.dateTimeWindowRemoved$
  ]).pipe(
    map(([canSubmit, unsavedChanges, dateTimeWindowAdded, dateTimeWindowRemoved]) => {
      if (dateTimeWindowAdded) {
        return unsavedChanges ? canSubmit : false;
      }
      if (dateTimeWindowRemoved) {
        return unsavedChanges ? canSubmit : true;
      }
      return canSubmit;
    })
  );

  addDateTimeWindow(): void {
    this.dateTimeWindows$.once(dateTimeWindows => {
      const dateTimeWindowsCopy = dateTimeWindows?.shallowCopy();
      dateTimeWindowsCopy?.push(new LocalizedDateRange());
      this._dateTimeWindows.next(dateTimeWindowsCopy);
      this._dateTimeWindowAdded.next(true);
    });
  }

  removeDateTimeWindow(index: number): void {
    this.dateTimeWindows$.once(dateTimeWindows => {
      const dateTimeWindowsCopy = dateTimeWindows?.shallowCopy();
      dateTimeWindowsCopy?.splice(index, 1);
      this._dateTimeWindows.next(dateTimeWindowsCopy);
      this._dateTimeWindowRemoved.next(true);
    });
  }

  saveDateTimeInformation() {
    combineLatest([
      this.display$,
      this.menuId$,
      this.timeDuration$,
      this.dateTimeWindows$
    ]).once(([display, menuId, timeDuration, dateTimeWindows]) => {
      timeDuration.dateTimeWindows = dateTimeWindows;
      const isCollection = display instanceof TemplateCollection;
      const displayCopy = isCollection
        ? window.injector.Deserialize.instanceOf(TemplateCollection, display)
        : window.injector.Deserialize.instanceOf(Display, display);
      displayCopy?.options?.overrides?.set(menuId, timeDuration);
      const loadingOpts = this._loadingOpts;
      const stringReplacer = StringUtils.replaceDisplayWithTemplateCollection;
      const lm = stringReplacer('Updating Display', isCollection);
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        this.saveDisplay(displayCopy).subscribe({
          complete: () => {
            const successMessage = stringReplacer('Display successfully updated.', isCollection);
            const successTitle = stringReplacer('Update Display', isCollection);
            this.toastService.publishSuccessMessage(successMessage, successTitle);
            loadingOpts.removeRequest(lm);
            this.closeModalSubject.next();
          },
          error: (err: BsError) => {
            this.toastService.publishError(err);
            loadingOpts.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

}
