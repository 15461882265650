import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Theme } from '../../../../models/menu/dto/theme';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import { Card } from '../../../../models/shared/stylesheet/card';
import { CardStyle } from '../../../../models/shared/stylesheet/card-style.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseComponent } from '../../../../models/base/base-component';
import { Orientation } from '../../../../models/utils/dto/orientation-type';
import { MenuType, MenuTypeDefinition } from '../../../../models/utils/dto/menu-type-definition';
import { ModalThemeDetails } from '../../../../modals/modal-theme-details';
import { Asset } from '../../../../models/image/dto/asset';
import { DefaultPrintStackSize } from '../../../../models/enum/dto/default-print-stack-size';
import { SortUtils } from '../../../../utils/sort-utils';

@Component({
  selector: 'app-theme-preview',
  templateUrl: './theme-preview.component.html',
  styleUrls: ['./theme-preview.component.scss'],
})
export class ThemePreviewComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() companyId: number;
  @Input() menuType: MenuType = MenuType.DisplayMenu;
  @Input() selectedCardStackSize: DefaultPrintStackSize;
  @Input() themes: Theme[] = [];

  @Output() orientationSelected = new EventEmitter<Orientation>();
  @Output() themeSelected = new EventEmitter<Theme>();

  public selectedThemeId: string;
  public orientationControlOptions: SegmentedControlOption[] = [];
  public selectedOrientationControl: SegmentedControlOption;
  public previewCards: Card[] = [];
  public maxHeightRem: number = 24;
  public scrollToCardId = new EventEmitter<string>();

  private layoutDebouncer = new Subject<boolean>();

  private orientationMap = new Map<string, Orientation>();

  constructor(
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
  }

  override setupViews() {
    this.setupOrientationSegmentedControl();
    this.setupThemeCards();
  }

  override setupBindings() {
    this.layoutDebouncer
      .pipe(debounceTime(250))
      .subscribe(_ => {
        this.setupThemeCards();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.layoutDebouncer.next(true);
  }

  orientationSegmentedControlOptionSelected(updatedOpts: SegmentedControlOption[]) {
    this.selectedOrientationControl = updatedOpts[0];
    this.orientationSelected.emit(updatedOpts[0].value);
    this.setupThemeCards();
    setTimeout(() => {
      // Let new view layout before scrolling to card
      this.scrollToCardId.emit(this.previewCards.find(c => c.selected)?.id);
    }, 50);
  }

  hideOrientationSegmentedControl(): boolean {
    return this.menuType === MenuType.WebMenu
      || this.menuType === MenuType.PrintMenu
      || this.menuType === MenuType.PrintReportMenu;
  }

  themeCardSelected(card: Card) {
    this.previewCards.forEach(c => {
      c.selected = (c.id === card.id);
      return c;
    });
    this.selectedThemeId = card.id;
    this.themeSelected.emit(card.data);
    this.scrollToCardId.emit(card.id);
    this.setupThemeCards(false);
  }

  openCardDetails(card: Card) {
    ModalThemeDetails.open(
      this.ngbModal,
      this.injector,
      card.data as Theme,
      this.menuType,
      this.selectedOrientationControl.value
    );
  }

  private setupOrientationSegmentedControl() {
    // Setup portrait option
    const p = new SegmentedControlOption('Portrait', Orientation.Portrait);
    p.selected = true;
    this.orientationControlOptions.push(p);
    this.selectedOrientationControl = p;
    this.orientationSelected.emit(p.value);
    // Setup landscape option
    const l = new SegmentedControlOption('Landscape', Orientation.Landscape);
    this.orientationControlOptions.push(l);
  }

  private setupThemeCards(performSort: boolean = true) {
    const tempCards = this.previewCards;
    this.maxHeightRem = (this.selectedOrientationControl.value === Orientation.Portrait) ? 26 : 16;
    const sortedThemes = this.themes?.sort((a, b) => SortUtils.sortThemes(a, b)) || [];
    this.previewCards = sortedThemes.map((theme) => {
      const previewImages = this.setStackPreviewImages(theme);
      const card = new Card(theme.description, theme.name);
      card.id = `${theme.id}-${this.selectedCardStackSize}`;
      card.cardStyle = CardStyle.ThemePreview;
      card.data = theme;
      card.customClass = MenuTypeDefinition.containsStackedContent(this.menuType)
        ? this.getPrintStackCustomClass(theme.id)
        : this.getCardCustomClass();
      card.selected = theme.id === this.selectedThemeId;
      if (!this.selectedThemeId) {
        // If no selected theme, then set theme to selected if its custom company theme
        this.selectedThemeId = theme.id;
        card.selected = true;
      }
      if (previewImages?.length > 0) {
        card.asset = previewImages?.sort((a, b) => b.timestamp - a.timestamp)[0];
      }
      return card;
    });
    if (!performSort) {
      // Ensures that cards maintain sorted order, instead of the order in this.themes
      this.previewCards = tempCards;
    }
    // If a card is selected, fire off output
    if (this.selectedThemeId) {
      this.themeSelected.next(this.themes.find(t => t.id === this.selectedThemeId));
    }
  }

  private setStackPreviewImages(theme: Theme): Asset[] {
    if (MenuTypeDefinition.containsStackedContent(this.menuType)) {
      return this.setPrintStackPreviewImages(theme);
    }
    return this.selectedOrientationControl.value === Orientation.Portrait
      ? theme.portraitPreviewImages
      : theme.landscapePreviewImages;
  }

  private setPrintStackPreviewImages(theme: Theme): Asset[] {
    const cardOrientation = theme.printConfig.cardOrientationMap.get(this.selectedCardStackSize);
    this.orientationMap.set(theme.id, cardOrientation);
    const previewHashes = theme.printConfig.previewCardMap.get(this.selectedCardStackSize);
    return theme?.getStackPreviewImages()?.filter(asset => previewHashes?.includes(asset.md5Hash));
  }

  private getCardCustomClass(): string {
    switch (this.menuType) {
      case MenuType.DisplayMenu:
      case MenuType.MarketingMenu:
        return (this.selectedOrientationControl.value === Orientation.Portrait)
          ? 'portrait-preview'
          : 'landscape-preview';
      case MenuType.WebMenu:
        return 'web-preview';
      case MenuType.PrintMenu:
      case MenuType.PrintReportMenu:
        return 'portrait-print-preview';
    }
  }

  private getPrintStackCustomClass(themeId: string): string {
    const orientation = this.orientationMap.get(themeId);
    const cardSize = this.selectedCardStackSize?.toLowerCase();
    return `${orientation}-${cardSize}`;
  }

}
