<app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #printJobForm
  [mergeKey]="viewModel.mergeKey$ | push"
  (formsSubmitted$)="viewModel.createBulkPrintJob()">
</lib-reactive-form-merge-groups>

<app-print-job-header [showBottomBorder]="viewModel.stepIsChooseOption$ | push" [title]="viewModel.title$ | push">
</app-print-job-header>

<app-choose-card-stack-print-type
  *rxIf="viewModel.stepIsChooseOption$"
  [stackType]="viewModel.stackType$ | push"
  (stackPrintTypeSelected)="viewModel.connectToSelectedCardStackPrintType($event)">
</app-choose-card-stack-print-type>

<app-edit-stack-print-job-details
  *rxIf="viewModel.stepIsEditDetails$"
  [fixedHeight]="viewModel.fixedHeightModal$ | push">
  <app-single-stack-smart-print-job
    *rxIf="viewModel.selectedPrintTypeIsSmart$"
    [job]="viewModel.bulkPrintJob$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [placeholder]="viewModel.currentDate$ | push"
    [templateMode]="viewModel.templateMode$ | push"
    [viewOnly]="viewModel.viewOnly$ | push"
    [stackType]="viewModel.stackType$ | push">
  </app-single-stack-smart-print-job>
  <app-single-stack-manual-print-job
    *rxIf="viewModel.selectedPrintTypeIsManual$"
    [job]="viewModel.bulkPrintJob$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [placeholder]="viewModel.currentDate$ | push"
    [templateMode]="viewModel.templateMode$ | push"
    [viewOnly]="viewModel.viewOnly$ | push"
    [stackType]="viewModel.stackType$ | push">
  </app-single-stack-manual-print-job>
</app-edit-stack-print-job-details>

<app-go-back-modal-footer
  [disablePrimaryButton]="printJobForm.hasErrors$ | push"
  [primaryButtonText]="'Done'"
  [showCancelButton]="!(viewModel.viewOnly$ | push)"
  [showBackButton]="viewModel.showGoBackButton$ | push"
  [showPrimaryButton]="viewModel.stepIsEditDetails$ | push"
  (cancel)="cancel()"
  (goBack)="viewModel.handleGoBack()"
  (primaryButtonClicked)="viewModel.handlePrimaryButtonClicked(printJobForm)">
</app-go-back-modal-footer>
