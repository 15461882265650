<div
  class="combo-product-container mt-24px"
  [style]="{ border: (viewModel.variantOutOfStock$ | async) ? '1px solid red' : null }">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
  <div class="combo-product-name">
    <div
      class="combo-product-number"
      [ngStyle]="{ color: cardNumberTextColor$ | async, 'background-color': cardNumberBackgroundColor$ | async }">
      {{ viewModel.cardNumber$ | async }}.
    </div>
    <img
      *ngIf="viewModel.variantOutOfStock$ | async"
      class="product-problem"
      [ngbTooltip]="'This product is out of stock. It will not show up on your menu.'"
      src="assets/icons/red/solid/exclamation-circle.svg"
      alt="" />
    <div class="product-display-name">
      <app-display-name
        [displayName]="viewModel.variantName$ | async"
        [isMedical]="viewModel.isMedical$ | async"></app-display-name>
    </div>
    <button
      [hidden]="!(viewModel.variantToolTip$ | async)"
      [ngbTooltip]="viewModel.variantToolTip$ | async"
      class="inline-tooltip">
      <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
    </button>
    <span>{{ viewModel.variantSize$ | async }}</span>
    <!--   Label   -->
    <div class="label-preview-container">
      <app-label-preview [computeLabelInterface]="viewModel.computeLabelInterface$ | async"> </app-label-preview>
    </div>

    <app-asset
      *ngFor="let badge of viewModel.cardBadges$ | async"
      class="combo-product-badge"
      [asset]="badge?.image"></app-asset>
    <!--   Display Attributes Button   -->
    <lib-button-inline-text
      class="text-link"
      [disabled]="(viewModel?.isLoading$ | async) || !formPristine"
      [disabledTooltip]="formPristine | saveChangesTooltip"
      [textColor]="'#2c4058'"
      (buttonClicked)="openDisplayAttributes()">
      Edit Variant
    </lib-button-inline-text>
  </div>
  <app-drive-thru-product-form
    class="combo-product"
    [bindTo]="viewModel?.formData$ | async"
    [mergeKey]="nestedGroupKey"
    [menu]="sharedViewModel.menu$ | async"
    [variant]="viewModel.variant$ | async"
    [isTemplatedMenu]="isTemplatedMenu"
    (formChanged)="viewModel.comboCardDataChanged($event)">
  </app-drive-thru-product-form>
  <div class="combo-product-form-container mt-16px" fxLayout="column">
    <app-drive-thru-product-media
      class="combo-product-media-info"
      [sharedViewModel]="sharedViewModel"
      [mergeKey]="nestedGroupKey"
      [comboVariantId]="variantId"
      [index]="index"
      [menu]="sharedViewModel?.menu$ | async"
      [isTemplatedMenu]="isTemplatedMenu"
      [showPreview]="viewModel.openPreview$ | async"
      [file]="viewModel.asset$ | async"
      [formPristine]="formPristine"
      (previewCardClicked)="this.selectedPreviewIndex.emit($event); viewModel.openPreviewClicked()"
      (deleteMedia)="viewModel?.deleteMedia($event)"
      (deleteComboProduct)="deleteFeaturedVariant($event)"
      [theme]="ClientTypes.MarketingTheme.MarketingProductFeature"
      [comboVariantName]="viewModel.variantName$ | async">
    </app-drive-thru-product-media>
  </div>
  <div class="preview-card" [ngClass]="{ 'open-card-tray': viewModel.openPreview$ | async }">
    <app-card-preview [card]="viewModel.card$ | async" [index]="index" [cardCount]="viewModel.cardCount$ | async">
    </app-card-preview>
  </div>
</div>
