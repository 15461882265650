import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroupComponent } from '../../../../../shared/components/form-group/form-group.component';
import { Asset } from '../../../../../../models/image/dto/asset';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MarketingTheme } from '../../../../../../models/enum/dto/theme.enum';
import { MenuMediaViewModel } from '../../../../viewModels/menu-media-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { DEFAULT_ROTATION_INTERVAL } from '../../../../../../models/shared/display-options';
import { FormInputItem } from '../../../../../../models/shared/stylesheet/form-input-item';
import { MediaForm } from '../../../../../../models/menu/shared/media-form';
import { DateUtils } from '../../../../../../utils/date-utils';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { EditDriveThruMenuViewModel } from '../../../edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu-view-model';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { MediaUtils } from '../../../../../../utils/media-utils';

@Component({
  selector: 'app-drive-thru-product-media',
  templateUrl: './drive-thru-product-media.component.html',
  styleUrls: ['./drive-thru-product-media.component.scss'],
  providers: [MenuMediaViewModel]
})
export class DriveThruProductMediaComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @ViewChild(FormGroupComponent) form: FormGroupComponent;
  @Input() sharedViewModel: EditDriveThruMenuViewModel;
  @Input() mergeKey: string = 'editMarketingMenu';
  @Input() file: Asset;
  @Input() menu: Menu;
  @Input() index: number;
  @Input() comboVariantId: string;
  @Input() comboVariantName: string;
  @Input() theme: MarketingTheme = MarketingTheme.Combo;
  @Input() showPreview: boolean;
  @Input() isTemplatedMenu: boolean = false;
  @Input() formPristine: boolean = true;
  @Output() previewCardClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteMedia: EventEmitter<Asset> = new EventEmitter<Asset>();
  @Output() deleteComboProduct: EventEmitter<string> = new EventEmitter<string>();

  public replaceMediaSwitch: boolean = false;
  public enabledStorage = { enabled: false };

  public fileNameWithoutTimestampAndExtension$ = new BehaviorSubject<string>(null);
  public isFileVideo$ = new BehaviorSubject<boolean>(null);

  private fileSub: Subscription;

  constructor(
    public viewModel: MenuMediaViewModel,
    private ngbModal: NgbModal,
    private injector: Injector,
    private toastService: ToastService,
  ) {
    super();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.setFormInParentViewModel(this.file, this.getMediaIdentifier(), this.viewModel.formItems, true);
  }

  override setupViews() {
    this.viewModel.initSharedModel(this.sharedViewModel);
    this.setInitialToggledState();
    this.setupForm();
    this.bindToFileAsset();
  }

  override setupBindings() {
    this.bindToFileAsset();
  }

  bindToFileAsset() {
    this.bindToFile();
  }

  bindToFile() {
    this.fileSub?.unsubscribe();
    this.fileSub = this.viewModel.file$.notNull().subscribe((file) => {
      this.file = file;
      this.fileNameWithoutTimestampAndExtension$.next(
        MediaUtils.getFileNameWithoutTimestampAndExtension(this.file.fileName)
      );
      this.isFileVideo$.next(this.file.isVideo());
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file) {
      this.viewModel.connectToFile(this.file);
    }
    this.setupViews();
  }

  getMediaIdentifier(): string {
    return this.comboVariantId;
  }

  replaceMedia(f: BudsenseFile) {
    this.viewModel.replaceMedia(this.file, f, this.comboVariantId, true);
    this.viewModel.setReplaceMediaSwitch(false);
  }

  toggleReplaceMediaSwitch() {
    this.viewModel.toggleReplaceMediaSwitch();
  }

  getInterval(): [string, number] {
    this.form?.getPopulatedObject();
    return [this.getMediaIdentifier(), this.viewModel.req.interval];
  }

  setInitialToggledState() {
    if (this.menu) {
      const rotInterval = this.menu.options?.rotationInterval?.get(this.getMediaIdentifier()) || 0;
      this.enabledStorage.enabled = rotInterval > 0;
    }
  }

  enableMediaClicked() {
    this.enabledStorage.enabled = !this.enabledStorage.enabled;
    if (this.enabledStorage.enabled && this.viewModel.req.interval === 0) {
      this.viewModel.req.interval = DEFAULT_ROTATION_INTERVAL;
    } else if (!this.enabledStorage.enabled) {
      this.viewModel.req.interval = 0;
    }
    this.setupForm();
  }

  fileList(f: BudsenseFile[], id?: number) {
    if (f && f.length === 1) {
      this.replaceMedia(f[0]);
    } else if (f.length > 1) {
      this.toastService.publishErrorMessage('Must provide 1 file in order to replace.', 'Too many files!');
    }
  }

  /**
   * Toggles Twice, once on create, and once after view init.
   */
  private setFormInParentViewModel(
    file: Asset,
    mediaIdentifier: string,
    items: FormInputItem[],
    afterInit: boolean = false
  ) {
    if (afterInit) {
      this.viewModel.connectToFile(file);
      if (items && this.menu) {
        this.sharedViewModel.menuAssetItems.set(mediaIdentifier, items);
      }
      const compIndex = this.sharedViewModel.comboAssetComponents.findIndex(it => {
        return it.getMediaIdentifier() === mediaIdentifier;
      });
      if (compIndex < 0) {
        this.sharedViewModel.comboAssetComponents.push(this);
      } else {
        this.sharedViewModel.comboAssetComponents.splice(compIndex, 1);
        this.sharedViewModel.comboAssetComponents.push(this);
      }
    }
  }

  refreshMedia() {
    this.viewModel.refreshImage();
  }

  private setupForm() {
    if (!this.viewModel.req) {
      this.sharedViewModel.menu$.once(menu => {
        const interval = menu?.options?.rotationInterval?.get(this.getMediaIdentifier())
          || (this.enabledStorage.enabled ? this.viewModel.req?.interval || DEFAULT_ROTATION_INTERVAL : 0);
        const date = this.file ? DateUtils.formatUnixToDateTime(this.file.timestamp) : '';
        this.viewModel.req = new MediaForm(5, interval, date); // Media duration just needs to be larger than 0
      });
    }

    this.setFormInParentViewModel(this.file, this.getMediaIdentifier(), this.viewModel.formItems);
  }

  toggleRemoveMediaDialog() {
    const opts = new ConfirmationOptions();
    opts.title = 'Delete Media';
    opts.bodyText = `Are you sure you want to delete ${this?.file?.fileName}?`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete';
    const confirmation = (cont) => {
      if (cont) {
        this.deleteMedia.next(this.file);
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

  toggleRemoveProductDialog() {
    const opts = new ConfirmationOptions();
    opts.title = 'Delete Combo Product';
    opts.bodyText = `Are you sure you want to delete ${this?.comboVariantName}?`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete';
    const confirmation = (cont) => {
      if (cont) {
        this.deleteComboProduct.emit(this.comboVariantId);
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

  override destroy() {
    super.destroy();
    this.fileSub?.unsubscribe();
  }

}
