import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Menu } from '../../../../../models/menu/dto/menu';
import { EditSmartPlaylistMenuViewModel } from '../../edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-view-model';
import { ProductGroupingViewModel } from './product-grouping-view-model';
import { Section } from '../../../../../models/menu/dto/section';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from 'src/app/models/shared/budsense-file';
import { UploadAssetComponent } from '../../../../shared/components/upload-asset/upload-asset.component';
import { ToastService } from '../../../../../services/toast-service';
import { ASSET_RETRY_COUNT } from '../../../../../utils/media-utils';
import { IsSectionSyncingSmartFiltersPipe } from '../../../../smart-filters/pipes/is-section-syncing-smart-filters.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-product-grouping',
  templateUrl: './product-grouping.component.html',
  styleUrls: ['./product-grouping.component.scss'],
  providers: [ProductGroupingViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGroupingComponent extends BaseComponent implements OnChanges, UploadImageInterface {

  constructor(
    public viewModel: ProductGroupingViewModel,
    private toastService: ToastService
  ) {
    super();
  }

  @ViewChild('productGroupingAsset') productGroupingAsset: UploadAssetComponent;
  @Input() templateMode: boolean;
  @Input() sharedViewModel: EditSmartPlaylistMenuViewModel;
  @Input() mergeKey: string;
  @Input() section: Section;
  @Input() menu: Menu;
  @Input() index: number;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Output() deleteClicked = new EventEmitter<Section>(true);
  @Output() duplicateClicked = new EventEmitter(true);

  public readonly replacingImage$ = this.viewModel.sectionAssetLoadingOpts$.pipe(map(opts => opts?.isLoading));

  editSection() {
    this.sharedViewModel.navigateToEditSectionView(this.section);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templateMode) this.viewModel.connectToTemplateMode(this.templateMode);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.sharedViewModel) this.viewModel.init(this.sharedViewModel);
  }

  fileList(f: BudsenseFile[], id?: number) {
    if (!!f && f.length > 0) {
      const lm = `Uploading Product Group Asset`;
      if (!this.sharedViewModel.fileUploadLoadingOpts.containsRequest(lm)) {
        this.sharedViewModel.fileUploadLoadingOpts.addRequest(lm);
        this.viewModel.uploadSectionAsset(f?.firstOrNull()).subscribe(uploadedFile => {
          this.sharedViewModel.fileUploadLoadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Successfully uploaded asset', 'Media Uploaded');
          this.productGroupingAsset.clear();
          if (!!uploadedFile) {
            this.viewModel.getRefreshedSectionAsset(uploadedFile, ASSET_RETRY_COUNT);
          }
        }, error => {
          this.sharedViewModel.fileUploadLoadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
        });
      }
      this.productGroupingAsset.clear();
    }
  }

  protected readonly IsSectionSyncingSmartFiltersPipe = IsSectionSyncingSmartFiltersPipe;

}
