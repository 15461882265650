import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { LoggingService } from './services/logging-service';
import { CanDeactivateState } from './services/guards/can-deactivate.guard';
import { BaseComponent } from './models/base/base-component';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ScreenService } from './services/screen-service.service';
import { InjectorService } from './services/injector.service';
import { fromEvent } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends BaseComponent {

  public isMobile$ = this.screenService.isMobile$;
  public isNotMobile$ = this.screenService.isMobile$.pipe(map(it => !it));

  constructor(
    private router: Router,
    private loggingService: LoggingService,
    private screenService: ScreenService,
    private injectorService: InjectorService, // Do not remove, or else injector service does not work.
    private spinner: NgxSpinnerService,
    private el: ElementRef,
    private ngbModal: NgbModal
  ) {
    super();
    // Config for Firebase
    const firebaseApp = firebase.initializeApp(environment.firebaseConfig);
    const userProperties: { [key: string]: any } = {};
    userProperties.Platform = 'Dashboard';
    firebase.analytics().setUserProperties(userProperties);
    this.loggingService.SetFirebaseAppInstance(firebaseApp);
    // Config for Abandon changes on back button
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false),
      takeUntil(this.onDestroy)
    ).subscribe();
  }

  override setupViews() {
    this.onResize(window?.innerWidth);
    this.spinner.show();
  }

  override setupBindings() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.ngbModal.dismissAll();
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    fromEvent(window, 'resize').pipe(takeUntil(this.onDestroy)).subscribe(_ => this.onResize());
  }

  private onResize(windowInnerWidth?: number) {
    const width = windowInnerWidth || (this.el.nativeElement as HTMLElement).getBoundingClientRect().width;
    if (width > 0) {
      this.screenService.setWidth(width);
      this.screenService.setIsMobile(width < ScreenService.MOBILE_WIDTH);
    }
  }

  override destroy() {
    super.destroy();
    this.spinner.hide();
  }

}
