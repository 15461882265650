import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseReactiveFormComponent, ReactiveFormNumberComponent } from '@mobilefirstdev/reactive-form';
import { MediaForm } from '../../../../../models/menu/shared/media-form';
import { Asset } from '../../../../../models/image/dto/asset';
import { MarketingMenuType } from '../../../../../models/enum/dto/marketing-menu-type.enum';
import { MenuMediaFormViewModel } from './menu-media-form-view-model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-menu-media-form',
  templateUrl: './menu-media-form.component.html',
  styleUrls: ['./menu-media-form.component.scss'],
  providers: [MenuMediaFormViewModel]
})
export class MenuMediaFormComponent extends BaseReactiveFormComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() override bindTo: MediaForm;
  @Input() isDisabledByTemplates: boolean = false;
  @Input() includeNameAndEnableSwitch: boolean = false;
  @Input() mediaDuration: number;
  @Input() file: Asset;
  @Input() menuType: MarketingMenuType;
  @Output() enabledChanged: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @ViewChild('displayDuration') displayDuration: ReactiveFormNumberComponent;

  // custom value parsers
  public scopedParseLoopContent: (s: string) => number = this.parseLoopContent.bind(this) as (s: string) => number;
  private enabled: boolean = true;

  private onDestroy = new Subject<void>();

  constructor(
    public viewModel: MenuMediaFormViewModel
  ) {
    super();
  }

  ngOnInit() {
    this.viewModel.setEnabled(this.bindTo?.enabled);
    this.viewModel.setFile(this.file);
    this.viewModel.setMenuType(this.menuType);
    this.viewModel.enabled$.pipe(takeUntil(this.onDestroy)).subscribe(enabled => this.enabled = enabled);
  }

  ngAfterViewInit() {
    this.viewModel.changeDuration$.pipe(takeUntil(this.onDestroy)).subscribe(duration => {
      if (duration !== undefined) this.displayDuration.getSelfAsFormItem().setValue(duration);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bindTo) this.viewModel.setEnabled(this.bindTo?.enabled);
    if (changes.file) this.viewModel.setFile(this.file);
    if (changes.menuType) this.viewModel.setMenuType(this.menuType);
    if (changes.mediaDuration) this.viewModel.setChangeDuration(this.mediaDuration);
  }

  enableMediaClicked(enabled: boolean) {
    this.enabledChanged.emit(enabled);
    this.viewModel.setEnabled(enabled);
    if (enabled) {
      this.viewModel.setLoopCount(1);
    } else {
      this.viewModel.setLoopCount(0);
    }
  }

  public parseLoopContent(s: string): number {
    if (!!s) {
      const nLoops = parseInt(s, 10);
      if (this.enabled) {
        const duration = this.bindTo?.mediaLength * nLoops;
        if (isFinite(duration)) {
          this.viewModel.setChangeDuration(duration);
        }
      } else {
        this.viewModel.setChangeDuration(0);
      }
      return nLoops;
    }
    return -1;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}
