<ng-container *ngFor="let province of groupedRequiredDisplays?.provinces">
  <ng-container *ngFor="let locationGrouping of province?.locations">
    <app-location-display-list-item
      *ngFor="let display of locationGrouping?.displays"
      [display]="display"
      [displayStatusText]="
        display | displayChangesString : originalRequiredDisplayIds : updatedRequiredDisplayIds : collectionIsPublished
      "
      [location]="locationGrouping?.location">
    </app-location-display-list-item>
  </ng-container>
</ng-container>
