<app-loading *ngIf="!hideLoading" [options]="loadingOptions"> </app-loading>

<!-- Asset Preview -->
<app-asset-preview
  (primaryButtonPressed)="primaryButtonPressed.emit()"
  (secondaryButtonPressed)="secondaryButtonPressed.emit()"
  (refreshButtonPressed)="refreshButtonPressed.emit($event)"
  [asset]="asset"
  [hidden]="hideAsset"
  [options]="assetPreviewOptions"
  [formPristine]="formPristine"
  [isDisabledByTemplates]="isTemplatedSection">
</app-asset-preview>

<!-- Upload Asset -->
<div [hidden]="hideUploadAsset" class="section-upload-area">
  <app-upload-asset
    #uploadAsset
    [allowImage]="true"
    [allowVideo]="false"
    [id]="uploadAssetId"
    [maxAssets]="1"
    [parentHandler]="this"
    [formPristine]="formPristine"
    [isDisabledByTemplates]="isTemplatedSection">
  </app-upload-asset>
</div>
<div [hidden]="!hideUploadAsset">
  <lib-reactive-form-group
    [mergeKey]="'editSection'"
    [bindTo]="bindTo"
    [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip">
    <lib-reactive-form-column-layout nColumns="3">
      <lib-reactive-form-number
        [inputName]="'width'"
        [label]="'Image Width'"
        [placeholder]="'Enter image width (%)'"
        [inlineLabel]="false"
        [integersOnly]="true"
        [required]="true"
        [hidden]="hideImageOptions"
        [minValue]="1"
        [maxValue]="100"
        [disabled]="isTemplatedSection"
        [bindingProperty]="isSecondaryImage ? 'metadata.secondaryWidth' : 'metadata.width'"
        [customValueParser]="parseAsString"
        (valueChanged)="viewModel.connectToImageWidth($event)">
      </lib-reactive-form-number>

      <lib-reactive-form-drop-down
        #imageAlignment
        [inputName]="'alignment'"
        [label]="'Image Alignment'"
        [placeholder]="'Select Image Alignment'"
        [inlineLabel]="false"
        [disabled]="(viewModel.imageAlignmentDisabled$ | async) || isTemplatedSection"
        [required]="true"
        [hidden]="hideImageOptions"
        [tooltip]="'Determines where the image should be horizontally aligned.'"
        [dropdowns]="viewModel.imageAlignments$ | async"
        [bindingProperty]="isSecondaryImage ? 'metadata.secondaryAlignment' : 'metadata.alignment'">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-drop-down
        [inputName]="'objectFit'"
        [label]="'Image Fit'"
        [placeholder]="'Select Image Fit'"
        [inlineLabel]="false"
        [disabled]="isTemplatedSection"
        [required]="true"
        [tooltip]="'Determines if the image should cover or fit within the container.'"
        [dropdowns]="viewModel.objectFitOptions$ | async"
        [bindingProperty]="isSecondaryImage ? 'metadata.secondaryObjectFit' : 'metadata.objectFit'"
        (valueChanged)="viewModel.connectToImageObjectFit($event)">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</div>
