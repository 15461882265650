import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-edit-display-main-section-form',
  templateUrl: './edit-display-main-section-form.component.html',
  styleUrls: ['./edit-display-main-section-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDisplayMainSectionFormComponent extends BaseComponent {

  @Input() mergeKey: string;
  @Input() bindTo: any;
  public displaySizeDropDowns$ = window?.types?.digitalSizeTypes$;
  public orientationDropDowns$ = window?.types?.orientations$;

  constructor() {
    super();
  }

}
