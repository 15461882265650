<div
  class="template-collection"
  [style.margin-bottom]="isLastItem ? '0' : '1.5rem'"
  [style.border-bottom]="isLastItem ? '' : '1px solid #F2F2F2'">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>
  <div class="collection-bar"></div>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-space-between pb-24px pt-14px">
      <div class="d-flex" [style.gap.rem]="0.5">
        <img src="assets/icons/dark/outline/template-collection.svg" alt="" />
        <div class="f16px bs-bold">{{ collection?.name }}</div>
      </div>
      <div class="ml-auto template-collection-actions">
        <lib-button-inline-text
          *ngIf="!collectionIsRequired"
          [textColor]="'#FA5555'"
          (buttonClicked)="viewModel.removeCollectionFromDisplay(collection)">
          Remove Collection
        </lib-button-inline-text>
        <lib-button-inline-text
          *ngIf="viewModel?.isTemplateAdmin$ | async"
          class="text-link f14px"
          (buttonClicked)="viewModel.navigateToEditTemplateCollectionPage(collection)">
          Edit Collection
        </lib-button-inline-text>
      </div>
    </div>
    <div *ngIf="!collection?.compactMenus?.length" class="empty-collection-text">
      There are no templates assigned to this collection.
    </div>
    <app-display-menu-section-inside-template-collection
      *ngFor="let menu of collection?.compactMenus as menus; let i = index; trackBy: editDisplayViewModel.trackById"
      [menuIndex]="i"
      [nMenusOnDisplay]="menus?.length"
      [containerId]="collection.id"
      [menuIsPartOfCollection]="true"
      [menu]="menu">
    </app-display-menu-section-inside-template-collection>
  </div>
</div>
