import { Injectable } from '@angular/core';
import { PrintCardLiveViewModalViewModel } from '../print-card-live-view-modal/print-card-live-view-modal-view-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';

@Injectable()
export class PrintLabelLiveViewModalViewModel extends PrintCardLiveViewModalViewModel {

  constructor(
    protected override companyDomainModel: CompanyDomainModel,
    protected override locationDomainModel: LocationDomainModel,
    protected override productDomainModel: ProductDomainModel
  ) {
    super(companyDomainModel, locationDomainModel, productDomainModel);
  }

}
