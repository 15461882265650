import { MediaType } from '../models/enum/dto/media-type.enum';
import { from, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DateUtils } from './date-utils';

export const ASSET_RETRY_COUNT = 10;
export const ASSET_RETRY_DELAY = 10;
export const ASSET_DELETE_DELAY = 10;

export const ASSET_TIMESTAMP_LEADER = '__bs__';

export class MediaUtils {

  static getMediaType(name: string): MediaType {
    switch (name.split('.').pop().toLowerCase()) {
      case 'jpg':
        return MediaType.JPG;
      case 'jpeg':
        return MediaType.JPEG;
      case 'png':
        return MediaType.PNG;
      case 'gif':
        return MediaType.GIF;
      case 'webp':
        return MediaType.WEBP;
      case 'bmp':
        return MediaType.BMP;
      case 'mp4':
        return MediaType.MP4;
      case 'webm':
        return MediaType.WEBM;
      case 'ogg':
        return MediaType.OGV;
      case 'avi':
        return MediaType.AVI;
      case 'mpeg':
        return MediaType.MPEG;
      case 'mpg':
        return MediaType.MPG;
      case 'mov':
        return MediaType.MOV;
      case 'pdf':
        return MediaType.PDF;
      default:
        return null;
    }
  }

  static isImage(mediaType: MediaType): boolean {
    const imageTypes: MediaType[] = [MediaType.JPG, MediaType.JPEG, MediaType.PNG, MediaType.GIF];
    return imageTypes.includes(mediaType);
  }

  static isVideo(mediaType: MediaType): boolean {
    const videoTypes: MediaType[] = [MediaType.MP4, MediaType.WEBM, MediaType.OGV, MediaType.AVI,
      MediaType.MPEG, MediaType.MOV, MediaType.MPG];
    return videoTypes.includes(mediaType);
  }

  static getRefreshAssetLoadingMessage(remainingRetries: number) {
    switch (remainingRetries) {
      case 0:
        return 'Reloading Asset';
      case 1:
        return 'Reloading Asset';
      case 2:
        return 'Reloading Asset';
      case 3:
        return 'Reloading Asset';
      case 4:
        return 'Resizing Asset';
      case 5:
        return 'Resizing Asset';
      case 6:
        return 'Resizing Asset';
      case 7:
        return 'Compressing Asset';
      case 8:
        return 'Compressing Asset';
      case 9:
        return 'Compressing Asset';
      case 10:
        return 'Compressing Asset';
      default:
        return `Loading Asset (${remainingRetries})`;
    }
  }

  static stripFileContents(file: string): string {
    const newFileContents = file.replace(/^data:image\/\w+;base64,/, '');
    return newFileContents.replace(/^data:video\/\w+;base64,/, '');
  }

  static getSHA256FromBlob(blob: Blob): Observable<string> {
    return from(blob.arrayBuffer()).pipe(
      switchMap(arrayBuffer => {
        return from(crypto.subtle.digest('SHA-256', arrayBuffer)).pipe(
          map(hash => {
            return new TextDecoder().decode(new Uint8Array(hash));
          })
        );
      }),
      take(1),
    );
  }

  static getFileNameWithoutExtension(fileName: string): string {
    const nameComps = fileName?.split('.');
    nameComps?.pop();
    return nameComps?.join('');
  }

  static appendTimestampToFileName(fileName: string): string {
    const extension = fileName?.substring(fileName.lastIndexOf('.'));
    const timestamp = DateUtils.currentTimestamp();
    return `${fileName.substring(0, fileName.lastIndexOf('.'))}${ASSET_TIMESTAMP_LEADER}${timestamp}${extension}`;
  }

  static getFileNameWithoutTimestamp(fileName: string): string {
    const pattern = ASSET_TIMESTAMP_LEADER + '\\d{10}';
    const regex = new RegExp(pattern);
    return regex.test(fileName) ? fileName.replace(regex, '') : fileName;
  }

  static getFileNameWithoutTimestampAndExtension(fileName: string): string {
    return MediaUtils.getFileNameWithoutTimestamp(MediaUtils.getFileNameWithoutExtension(fileName));
  }

}
