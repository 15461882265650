<thead class="header-container" [style.top.px]="headerStickyOffset$ | async">
  <tr #trow>
    <th class="header-selection-container">
      <div class="header-title" fxLayout="row" fxLayoutAlign=" center">
        <app-group-selection
          #groupSelectionComponent
          [selection]="viewModel.nestedIds$ | async"
          [previouslyAddedIds]="viewModel.previouslyAddedIds$ | async"
          [selectedIds]="viewModel.idsToBeAdded$ | async"
          (bulkAdd)="viewModel.bulkAdd($event)"
          (bulkRemove)="viewModel.bulkRemove($event)">
        </app-group-selection>
        <div *ngIf="(viewModel.nIdsToBeAdded$ | async) > 0" class="checked-count">
          ({{ viewModel.nIdsToBeAdded$ | async }})
        </div>
        <div class="header-selection-container-cover-transparent-spot"></div>
      </div>
    </th>
    <th resizable>
      <div class="header-title" (click)="nameSort.clicked()">Name</div>
      <lib-reactive-table-asc-desc-sort
        #nameSort
        fxFlex
        [ascSort]="nameAsc"
        [descSort]="nameDesc"></lib-reactive-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="brandSort.clicked()">Brand</div>
      <app-table-asc-desc-sort #brandSort fxFlex [ascSort]="brandAsc" [descSort]="brandDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="typeSort.clicked()">Type</div>
      <app-table-asc-desc-sort #typeSort fxFlex [ascSort]="typeAsc" [descSort]="typeDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="strainSort.clicked()">Strain Type</div>
      <app-table-asc-desc-sort
        #strainSort
        fxFlex
        [ascSort]="strainAsc"
        [descSort]="strainDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="quantitySort.clicked()">Quantity</div>
      <app-table-asc-desc-sort #quantitySort fxFlex [ascSort]="qtyAsc" [descSort]="qtyDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="minTHCSort.clicked()">Min THC</div>
      <app-table-asc-desc-sort
        #minTHCSort
        fxFlex
        [ascSort]="minTHCAsc"
        [descSort]="minTHCDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="maxTHCSort.clicked()">Max THC</div>
      <app-table-asc-desc-sort
        #maxTHCSort
        fxFlex
        [ascSort]="maxTHCAsc"
        [descSort]="maxTHCDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="minCBDSort.clicked()">Min CBD</div>
      <app-table-asc-desc-sort
        #minCBDSort
        fxFlex
        [ascSort]="minCBDAsc"
        [descSort]="minCBDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.displaysUnitsInRanges$ | async" resizable>
      <div class="header-title" (click)="maxCBDSort.clicked()">Max CBD</div>
      <app-table-asc-desc-sort
        #maxCBDSort
        fxFlex
        [ascSort]="maxCBDAsc"
        [descSort]="maxCBDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="!(viewModel.displaysUnitsInRanges$ | async)" resizable>
      <div class="header-title" (click)="THCSort.clicked()">THC</div>
      <app-table-asc-desc-sort #THCSort fxFlex [ascSort]="thcAsc" [descSort]="thcDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="!(viewModel.displaysUnitsInRanges$ | async)" resizable>
      <div class="header-title" (click)="CBDSort.clicked()">CBD</div>
      <app-table-asc-desc-sort #CBDSort fxFlex [ascSort]="cbdAsc" [descSort]="cbdDesc"></app-table-asc-desc-sort>
    </th>
    <th resizable>
      <div class="header-title" (click)="priceSort.clicked()">Price</div>
      <app-table-asc-desc-sort #priceSort fxFlex [ascSort]="priceAsc" [descSort]="priceDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="!(viewModel.hideLabelAndBadgeColumn$ | async)" resizable>
      <div class="header-title" (click)="labelSort.clicked()">Label</div>
      <app-table-asc-desc-sort #labelSort fxFlex [ascSort]="labelAsc" [descSort]="labelDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="!(viewModel.hideLabelAndBadgeColumn$ | async)" resizable>
      <div class="header-title" (click)="badgeSort.clicked()">Badge</div>
      <app-table-asc-desc-sort #badgeSort fxFlex [ascSort]="badgeAsc" [descSort]="badgeDesc"></app-table-asc-desc-sort>
    </th>
    <th *ngIf="viewModel.showCurrentOverrideGroupColumn$ | async" resizable>
      <div class="header-title" (click)="overrideGroupSort.clicked()">Current Override Group</div>
      <app-table-asc-desc-sort #overrideGroupSort fxFlex [ascSort]="overrideGroupAsc" [descSort]="overrideGroupDesc">
      </app-table-asc-desc-sort>
    </th>
  </tr>
</thead>
<!-- -1px so that this sits directly on top of other bottom border
      not sure why, but borders gets scrolled away with position: sticky
      hence why I had to add this tomfoolery -->
<div
  [style.visibility]="(loading$ | async) ? 'hidden' : null"
  [style.top.px]="trow?.getBoundingClientRect()?.bottom - 1"
  [style.left.px]="headerBottomBorderLeftOffset$ | async"
  [style.right.px]="headerBottomBorderRightOffset$ | async"
  class="header-bottom-border"></div>
