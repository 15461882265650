export enum NavItemId {
  Home,
  Products,
  Locations,
  Templates,
  Menus,
  Displays,
  Settings,
  Profile,
  Help,
  CurrentLocation,
  SignOut,
}
