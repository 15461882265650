<div
  matRipple
  class="arrow-button-container"
  [class.selected]="selected"
  [style.padding-left]="paddingLeft"
  [style.padding-right]="paddingRight">
  <div
    class="arrow-button-content"
    fxLayout="row"
    fxLayoutAlign="center center"
    appThrottleClick
    (throttleClick)="clicked.emit(true)">
    <div fxFlex [style.padding-left.rem]="0.25">
      <div class="font-title" [style.fontSize]="titleFontSize" [style.font-weight]="titleFontWeight">
        {{ title | titlecase }}
      </div>
      <div class="font-sub-title" [style.fontSize]="subtitleFontSize" [style.font-weight]="subtitleFontWeight">
        {{ subtitle }}
      </div>
    </div>
    <div class="arrow" fxFlex="none">
      <img [src]="'assets/icons/dark/solid/chevron-right.svg'" alt="" />
    </div>
  </div>
  <hr class="no-margin" />
</div>
