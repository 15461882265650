import { Previewable } from '../models/protocols/previewable';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Menu } from '../models/menu/dto/menu';
import { SecurityContext } from '@angular/core';

export class PrintUtils {

  static printMenuPDF(
    sanitizer: DomSanitizer,
    menu: Menu,
    preview: Previewable,
    url: string|SafeResourceUrl
  ) {
    if (!!preview && !!url) {
      const pdfWindow = window.open(sanitizer.sanitize(SecurityContext.RESOURCE_URL, url), '_blank');
      pdfWindow.focus();
    }
  }

}
