import { AuthFlow } from '../models/account/enum/auth-flow.enum';
import { ConfirmationFlow } from '../models/account/enum/confirmation-flow.enum';
import { LogoTypeEnum } from '../models/company/enum/logo-type.enum';
import { MediaType } from '../models/enum/dto/media-type.enum';
import { MarketingTheme } from '../models/enum/dto/theme.enum';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { DisplayableAction } from '../models/menu/enum/menu-action.enum';
import { NewMenuFlow } from '../models/menu/enum/new-menu-flow.enum';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { MediaUtils } from './media-utils';
import { LoadingTechnique } from '../views/shared/components/asset/loading-technique.enum';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { FormGroupTypes } from '../views/shared/components/form-group/form-group-types.enum';
import { DateUtils } from './date-utils';
import { WeekDay } from '@angular/common';
import { AddEditSmartFilterModalOpenedFrom } from '../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { SmartFilterCategoryAllProducts } from '../models/enum/shared/smart-filter-category-all-products';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MarketingMenuType } from '../models/enum/dto/marketing-menu-type.enum';
import { ColorUtils } from './color-utils';
import { TableLayoutType } from '@mobilefirstdev/reactive-table';
import { CustomizationProperty } from '../models/enum/dto/customization-property.enum';
import { InlineBadgePickerMode } from '../views/shared/components/inline-badge-picker/inline-badge-picker-mode.enum';
import { InlineLabelPickerMode } from '../modules/product-labels/inline-label-picker/inline-label-picker-mode.enum';
import { ParserUtils } from './parser-utils';
import { ProviderUtils } from './provider-utils';
import { EditVariantModalTabId } from '../models/enum/shared/edit-variant-modal-tab-id.enum';
import { EditLabelPropertyKey } from '../models/enum/shared/edit-label-property-key';
import { CannabinoidUtils } from './cannabinoid-utils';
import { SaleLabelFormat } from '../models/utils/dto/sale-label-format-type';
import { TextTransform } from '@mobilefirstdev/reactive-form';
import { StrainClassification } from '../models/utils/dto/strain-classification-type';
import { VariantType } from '../models/utils/dto/variant-type-definition';
import { CannabinoidDisplayType } from '../models/utils/dto/cannabinoid-display-type-definition';
import { ProductType } from '../models/utils/dto/product-type-definition';
import { CannabisUnitOfMeasure } from '../models/utils/dto/cannabis-unit-of-measure-type';
import { UnitOfMeasure } from '../models/utils/dto/unit-of-measure-type';
import { CardType } from '../models/utils/dto/card-type-definition';
import { OptionScale } from '../models/utils/dto/option-scale-type';
import { FontStyle } from '../models/utils/dto/font-style-type';
import { MenuPreviewJobStatus } from '../models/utils/dto/menu-preview-job-status-type';
import { InventoryProvider } from '../models/utils/dto/inventory-provider-type';
import { MenuLabel } from '../models/utils/dto/menu-label-type';
import { MenuStyleObject } from '../models/utils/dto/menu-style-object-type';
import { Orientation } from '../models/utils/dto/orientation-type';
import { OverflowState } from '../models/utils/dto/overflow-state-type';
import { ColumnOptionName, SectionColumnConfigKey } from '../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../models/utils/dto/section-column-config-default-state-type';
import { SectionSortProductInfo } from '../models/utils/dto/section-sort-type';
import { SectionType } from '../models/utils/dto/section-type-definition';
import { SizeUnit } from '../models/utils/dto/size-unit-type';
import { MenuType } from '../models/utils/dto/menu-type-definition';
import { SmartDataType } from '../models/enum/shared/smart-data-type.enum';
import { SyncJobStatus } from '../models/utils/dto/sync-job-status-type';
import { TemplateStatus } from '../models/template/enum/template-status.enum';
import { MenuIconSrc } from '../models/enum/shared/menu-icon-src.enum';
import { DisplayableItemFilterByActive } from '../models/enum/shared/displayable-item-filter-by.active';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { VariantGroup } from '../models/product/shared/variant-group';
import { DisplayContentOption } from '../models/enum/shared/display-content-option.enum';
import { StackPrintType } from '../models/automation/enum/card-stack-print-type.enum';
import { MenuSectionTypeIcon } from '../models/menu/enum/menu-section-type-icon.enum';
import { PropertyLevel } from '../models/enum/shared/property-level.enum';
import { TerpeneUtils } from './terpene-utils';

export class ClientTypeUtils {

  static AddEditSmartFilterModalOpenedFrom: typeof AddEditSmartFilterModalOpenedFrom
    = AddEditSmartFilterModalOpenedFrom;
  static AssetSize: typeof AssetSize = AssetSize;
  static AuthFlow: typeof AuthFlow = AuthFlow;
  static CachePolicy: typeof CachePolicy = CachePolicy;
  static CannabinoidDisplayType: typeof CannabinoidDisplayType = CannabinoidDisplayType;
  static CannabinoidUtils: typeof CannabinoidUtils = CannabinoidUtils;
  static CannabisUnitOfMeasure: typeof CannabisUnitOfMeasure = CannabisUnitOfMeasure;
  static CardStackPrintType: typeof StackPrintType = StackPrintType;
  static CardType: typeof CardType = CardType;
  static ColorUtils: typeof ColorUtils = ColorUtils;
  static ColumnOptionName: typeof ColumnOptionName = ColumnOptionName;
  static ConfirmationFlow: typeof ConfirmationFlow = ConfirmationFlow;
  static CustomizationProperty: typeof CustomizationProperty = CustomizationProperty;
  static DateUtils: typeof DateUtils = DateUtils;
  static DisplayableItemFilterByActive: typeof DisplayableItemFilterByActive = DisplayableItemFilterByActive;
  static DisplayContentOption: typeof DisplayContentOption = DisplayContentOption;
  static EditLabelPropertyKey: typeof EditLabelPropertyKey = EditLabelPropertyKey;
  static EditVariantModalTabId: typeof EditVariantModalTabId = EditVariantModalTabId;
  static FormGroupTypes: typeof FormGroupTypes = FormGroupTypes;
  static InlineBadgePickerMode: typeof InlineBadgePickerMode = InlineBadgePickerMode;
  static InlineLabelPickerMode: typeof InlineLabelPickerMode = InlineLabelPickerMode;
  static InventoryProvider: typeof InventoryProvider = InventoryProvider;
  static LoadingTechnique: typeof LoadingTechnique = LoadingTechnique;
  static LogoType: typeof LogoTypeEnum = LogoTypeEnum;
  static MarketingMenuType: typeof MarketingMenuType = MarketingMenuType;
  static MarketingTheme: typeof MarketingTheme = MarketingTheme;
  static MediaType: typeof MediaType = MediaType;
  static MediaUtils: typeof MediaUtils = MediaUtils;
  static MenuAction: typeof DisplayableAction = DisplayableAction;
  static MenuIconSrc: typeof MenuIconSrc = MenuIconSrc;
  static MenuLabel: typeof MenuLabel = MenuLabel;
  static MenuPreviewJobStatus: typeof MenuPreviewJobStatus = MenuPreviewJobStatus;
  static MenuStyleObject: typeof MenuStyleObject = MenuStyleObject;
  static MenuType: typeof MenuType = MenuType;
  static MenuSectionTypeIcon: typeof MenuSectionTypeIcon = MenuSectionTypeIcon;
  static NewMenuFlow: typeof NewMenuFlow = NewMenuFlow;
  static NgbToolTip: typeof NgbTooltip = NgbTooltip;
  static NgxPopperjsPlacements: typeof NgxPopperjsPlacements = NgxPopperjsPlacements;
  static NgxPopperjsTriggers: typeof NgxPopperjsTriggers = NgxPopperjsTriggers;
  static OptionScale: typeof OptionScale = OptionScale;
  static Orientation: typeof Orientation = Orientation;
  static OverflowState: typeof OverflowState = OverflowState;
  static ParserUtils: typeof ParserUtils = ParserUtils;
  static ProductType: typeof ProductType = ProductType;
  static PropertyLevel: typeof PropertyLevel = PropertyLevel;
  static ProviderUtils: typeof ProviderUtils = ProviderUtils;
  static SaleLabelFormat: typeof SaleLabelFormat = SaleLabelFormat;
  static SectionColumnConfigDefaultState: typeof SectionColumnConfigDefaultState = SectionColumnConfigDefaultState;
  static SectionColumnConfigFontStyle: typeof FontStyle = FontStyle;
  static SectionColumnConfigKey: typeof SectionColumnConfigKey = SectionColumnConfigKey;
  static SectionSortProductInfo: typeof SectionSortProductInfo = SectionSortProductInfo;
  static SectionType: typeof SectionType = SectionType;
  static SizeUnit: typeof SizeUnit = SizeUnit;
  static SmartDataType: typeof SmartDataType = SmartDataType;
  static SmartFilterCategoryAllProducts: typeof SmartFilterCategoryAllProducts = SmartFilterCategoryAllProducts;
  static StrainClassification: typeof StrainClassification = StrainClassification;
  static SyncJobStatus: typeof SyncJobStatus = SyncJobStatus;
  static TableLayoutType: typeof TableLayoutType = TableLayoutType;
  static TemplateStatus: typeof TemplateStatus = TemplateStatus;
  static TerpeneUtils: typeof TerpeneUtils = TerpeneUtils;
  static TextTransform: typeof TextTransform = TextTransform;
  static UnitOfMeasure: typeof UnitOfMeasure = UnitOfMeasure;
  static UploadFilePath: typeof UploadFilePath = UploadFilePath;
  static VariantType: typeof VariantType = VariantType;
  static VariantGroup: typeof VariantGroup = VariantGroup;
  static Weekday: typeof WeekDay = WeekDay;

}
