import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'variantFormattedSizing'
})
export class VariantFormattedSizingPipe implements PipeTransform {

  transform(variant: Variant|null, wrapInBrackets = false): string {
    return variant?.formattedSizing(wrapInBrackets);
  }

}
