import { Pipe, PipeTransform } from '@angular/core';
import { EditVariantAvailabilityViewModel, LocationVariantInventory } from '../components/edit-variant-availability/edit-variant-availability-view-model';

@Pipe({
  name: 'formatInventoryAtOtherLocations'
})
export class FormatInventoryAtOtherLocationsPipe implements PipeTransform {

  transform(value: EditVariantAvailabilityViewModel, locationVariantInventory: LocationVariantInventory): unknown {
    return value?.formatInventoryForDisplay(locationVariantInventory);
  }

}
