<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'editMarketingMenu'"
  (formsAutoSubmitted$)="viewModel.saveMenu(true)"
  (formsSubmitted$)="viewModel.saveMenu(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-container>
  <app-edit-menu-header
    [menu]="viewModel.menu$ | async"
    [crumbs]="viewModel.breadcrumbs$ | async"
    [allowLiveView]="viewModel.allowLiveView$ | async"
    [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
    [disableActiveToggle]="viewModel.disableActiveToggleForMenu$ | async"
    [headerText]="'Edit Featured Product Menu'"
    [headerSubtext]="'Create visually stunning menus built specifically for your in-store displays.'"
    [lastSavedTimeText]="viewModel.lastAutoSavedTimeText$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [mergeKey]="'editMarketingMenu'"
    [showLiveView]="viewModel.showLiveView"
    [toggleActiveState]="viewModel.toggleActiveState"
    [userIsTemplateAdmin]="viewModel.userIsTemplateAdmin$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    (editTemplateClicked)="viewModel.navigateToEditTemplate($event)"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-menu-header>

  <app-edit-featured-product-menu-body
    [templateMode]="viewModel.templateMode$ | async"
    [mergeKey]="'editMarketingMenu'"
    [formPristine]="formCapture.allPristine$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async">
  </app-edit-featured-product-menu-body>

  <app-edit-menu-footer
    [menu]="viewModel.menu$ | async"
    [allowLiveView]="viewModel.allowLiveView$ | async"
    [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
    [canDeleteTemplateMenu]="viewModel.canDeleteTemplateMenu$ | async"
    [lastSavedTimeText]="viewModel.lastAutoSavedTimeText$ | async"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | async"
    [formDisableSubmit]="formCapture.disableSubmit$ | async"
    [formErrorMessages]="formCapture.errorMessages$ | async"
    [formHasErrors]="formCapture.hasErrors$ | async"
    [formPristine]="formCapture.allPristine$ | async"
    [deleteMenu]="viewModel.deleteMenu"
    [openDuplicateMenuModal]="viewModel.openDuplicateMenuModal"
    [showLiveView]="viewModel.showLiveView"
    [toggleActiveState]="viewModel.toggleActiveState"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-menu-footer>
</app-edit-menu-container>
