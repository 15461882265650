import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewCollectionDisplaysViewModel } from './view-collection-displays-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-collection-displays',
  templateUrl: './view-collection-displays.component.html',
  styleUrls: ['./view-collection-displays.component.scss'],
  providers: [ViewCollectionDisplaysViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewCollectionDisplaysComponent extends BaseModalComponent {

  constructor(
    public viewModel: ViewCollectionDisplaysViewModel,
    public activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

}
