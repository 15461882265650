import { Pipe, PipeTransform } from '@angular/core';
import { MenuPreviewJob } from '../../../../../../../../models/automation/menu-preview-job';

@Pipe({
  name: 'getCardStackPreviewJob'
})
export class GetCardStackPreviewJobPipe implements PipeTransform {

  transform(previewJobs: MenuPreviewJob[], cardStackId: string): MenuPreviewJob {
    return previewJobs?.find(previewJob => previewJob?.menuId === cardStackId);
  }

}
