import { Component } from '@angular/core';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { ColumnOptionsSecondaryPriceViewModel } from './column-options-secondary-price-view-model';
import { ColumnOptionsFormComponent } from '../column-options-form/column-options-form.component';

@Component({
  selector: 'app-column-options-secondary-price',
  templateUrl: '../column-options-form/column-options-form.component.html',
  styleUrls: ['../column-options-form/column-options-form.component.scss'],
  providers: [ColumnOptionsSecondaryPriceViewModel]
})
export class ColumnOptionsSecondaryPriceComponent extends ColumnOptionsFormComponent implements Tabbable {

  constructor(
    public override viewModel: ColumnOptionsSecondaryPriceViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
