import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixedDecimal'
})
export class ToFixedDecimalPipe implements PipeTransform {

  transform(value: number|string|null, nDecimalPlaces: number): string|null {
    let fixed = '';
    if (!!value) {
      if (typeof value === 'number') {
        fixed = value.toFixed(nDecimalPlaces);
      } else if (typeof value === 'string') {
        if (value.includes('-')) {
          const prices = value.split('-')?.map(price => Number(price.trim()));
          if (prices?.length > 1) {
            fixed = `${prices.firstOrNull().toFixed(nDecimalPlaces)} - ${prices.last().toFixed(nDecimalPlaces)}`;
          } else {
            fixed = prices.firstOrNull().toFixed(nDecimalPlaces);
          }
        } else {
          fixed = Number(value).toFixed(nDecimalPlaces);
        }
      }
    }
    return fixed;
  }

}
