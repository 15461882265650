<div class="bs-headline" style="line-height: 1">{{ headerText }}</div>
<app-breadcrumbs [breadcrumbs]="viewModel.breadcrumbs$ | async"></app-breadcrumbs>

<div class="row edit-page-header">
  <div class="col-4">
    <div class="d-flex flex-column">
      <div class="edit-page-name">
        {{ (viewModel.display$ | async)?.name }}
      </div>
      <div class="edit-page-sub-header">
        {{ headerSubtext }}
      </div>
    </div>
  </div>
  <div class="col-8">
    <app-edit-display-actions
      [disableSave]="viewModel.autoSaving$ | async"
      [formDisableSubmit]="formDisableSubmit"
      [formErrorMessages]="formErrorMessages"
      [formHasErrors]="formHasErrors"
      [formPristine]="formPristine"
      [lastSavedTimeText]="viewModel.lastAutoSavedTimeText$ | async"
      [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | async"
      (showLiveView)="viewModel.showLiveView()"
      (submitForms)="submitForms.emit(false)">
    </app-edit-display-actions>
  </div>
</div>
