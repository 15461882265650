import { TypeDefinition } from './type-definition';

export enum SizeUnit {
  Imperial = 'imperial',
  Metric = 'metric',
  Digital = 'digital'
}

export class SizeUnitType extends TypeDefinition {

  override value: SizeUnit;

}
