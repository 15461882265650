import { SystemLabel } from './system-label';

export class FeaturedSystemLabel extends SystemLabel {

  getSortOrderTitle(): string {
    return 'Featured';
  }

  override getLabelPickerTitle(): string {
    return 'Featured';
  }

}
