import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Observable, of } from 'rxjs';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { WebProductTemplatesComponent } from './web-product-templates/web-product-templates.component';

export class WebTemplatesViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  public tabs$: Observable<TabBarItem[]> = of([
    new TabBarItem(WebProductTemplatesComponent, 'Product Templates', '', false),
  ]);

}
