import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionSectionDetailsComponent } from '../../../edit-menu-section/edit-menu-section-section-details/edit-menu-section-section-details.component';
import { EditSpotlightSectionViewModel } from '../edit-spotlight-section-view-model';

@Component({
  selector: 'app-edit-spotlight-menu-section-details',
  templateUrl: '../../../edit-menu-section/edit-menu-section-section-details/edit-menu-section-section-details.component.html',
  styleUrls: [
    './edit-spotlight-menu-section-details.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSpotlightMenuSectionDetailsComponent extends EditMenuSectionSectionDetailsComponent {

  // Override EditMenuSectionSectionDetailsComponent so EditSpotlightSectionViewModel can be injected
  constructor(
    public override viewModel: EditSpotlightSectionViewModel
  ) {
    super(viewModel);
  }

}
