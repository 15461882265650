import { Component } from '@angular/core';
import { CollectionPreviewViewModel } from './collection-preview-view-model';
import { DisplayableItemPreviewComponent } from '../displayable-item-preview/displayable-item-preview.component';

@Component({
  selector: 'app-collection-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: ['../displayable-item-preview/displayable-item-preview.component.scss'],
  providers: [CollectionPreviewViewModel]
})
export class CollectionPreviewComponent extends DisplayableItemPreviewComponent {

  constructor(
    public override viewModel: CollectionPreviewViewModel
  ) {
    super(viewModel);
  }

}
