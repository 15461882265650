import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Variant } from '../../../../models/product/dto/variant';
import { Checkbox } from '../../../../models/shared/stylesheet/checkbox';

@Component({
  selector: 'app-select-variants-modal',
  templateUrl: './select-variants-modal.component.html',
  styleUrls: ['./select-variants-modal.component.scss']
})
export class SelectVariantsModalComponent extends BaseModalComponent implements AfterViewInit {

  public loadingOpts: LoadingOptions = LoadingOptions.default();
  public variantCheckboxes: Checkbox[] = [];
  @ViewChild('modalBody') modalBody: HTMLDivElement;
  private variants: Variant[] = [];
  private activeVariants: Variant[] = [];
  private disableVariantIds: string[] = [];

  constructor(
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  setVariants(variants, activeVariants: Variant[]) {
    this.variants = variants;
    this.activeVariants = activeVariants;
    this.setupVariantCheckboxes();
  }

  setupVariantCheckboxes() {
    this.variantCheckboxes = this.variants.map(v => new Checkbox(
      v.name,
      !!this.activeVariants.find(av => av.id === v.id),
      !!this.disableVariantIds.find(id => id === v.id)
    ));
  }

  setDisabledVariantIds(ids: string[]) {
    this.disableVariantIds = ids;
  }

  skip() {
    this.activeModal.close([]);
  }

  override cancel() {
    this.activeModal.close(false);
  }

  saveChanges() {
    const selectedVariants = this.variants.filter(v => {
      return !!this.variantCheckboxes.find(vc => vc.checked && vc.label === v.name);
    });
    this.activeModal.close(selectedVariants);
  }

}
