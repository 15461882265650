import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CollectionDisplaysModalContainerViewModel } from './collection-displays-modal-container-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-collection-displays-modal-container',
  templateUrl: './collection-displays-modal-container.component.html',
  styleUrls: ['./collection-displays-modal-container.component.scss'],
  providers: [CollectionDisplaysModalContainerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionDisplaysModalContainerComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: CollectionDisplaysModalContainerViewModel,
  ) {
    super(activeModal);
  }

}
