import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { StickyAutoPositionDirective } from '../views/shared/directives/sticky-auto-position.directive';

@Injectable({ providedIn: 'root' })
export class StickyAutoPositionService {

  constructor() { }

  private _stickyCollections = new BehaviorSubject<Map<string, StickyAutoPositionDirective[]>>(new Map());
  public stickyCollections$ = this._stickyCollections as Observable<Map<string, StickyAutoPositionDirective[]>>;

  addToCollection(item: StickyAutoPositionDirective): void {
    const groups = this._stickyCollections.getValue();
    const collectionKey = item?.stickyCollectionKey;
    const group = groups?.get(collectionKey) ?? [];
    if (!group?.includes(item)) {
      groups?.set(collectionKey, [...group, item]);
      this._stickyCollections.next(groups);
    }
  }

  /**
   * Trigger pipeline for position recalculations
   */
  positionUpdated(item: StickyAutoPositionDirective): void {
    const groups = this._stickyCollections.getValue();
    this._stickyCollections.next(groups);
  }

  removeFromCollection(item: StickyAutoPositionDirective, previousKey?: string): void {
    const groups = this._stickyCollections.getValue();
    const collectionKey = previousKey || item?.stickyCollectionKey;
    const group = groups?.get(collectionKey) ?? [];
    if (group?.includes(item)) {
      const index = group?.indexOf(item);
      if (index > -1) {
        group?.splice(index, 1);
        if (!group?.length) groups?.delete(collectionKey);
        this._stickyCollections.next(groups);
      }
    }
  }

}
