<div class="auth-flow-wrapper">
  <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

  <div class="auth-flow-container">
    <div class="auth-flow-title">
      {{ viewModel.getAuthFlowTitle() }}
    </div>

    <div class="auth-flow-subtext">
      {{ viewModel.getAuthFlowSubtext() }}
    </div>

    <lib-reactive-form-group #form [bindTo]="req" (formSubmitted$)="formSubmitted(req)">
      <lib-reactive-form-password
        [inputName]="'password'"
        [required]="true"
        [creatingOrUpdatingPassword]="true"
        [confirmPassword]="true"
        [label]="'New Password'"
        [placeholder]="'Enter a new password.'"
        [bindingProperty]="'password'">
      </lib-reactive-form-password>

      <lib-reactive-form-password
        [inputName]="'confirmPassword'"
        [required]="true"
        [creatingOrUpdatingPassword]="true"
        [confirmPassword]="true"
        [label]="'Confirm Password'"
        [placeholder]="'Retype your new password.'"
        [bindingProperty]="'confirmPassword'">
      </lib-reactive-form-password>
    </lib-reactive-form-group>

    <lib-button-primary [disabled]="form.disableSubmit$ | async" (buttonClicked)="form.submitForm()">
      Update Password
    </lib-button-primary>
  </div>
</div>
