<div class="label-picker-container" [style.padding]="'0.5rem 1rem'">
  <div class="label-picker-title">Select labels for Smart Filter</div>
  <div class="label-picker-info-text">
    Products that currently have any of the selected labels applied will be included on the Smart Filter.
  </div>
  <lib-reactive-form-checkboxes
    #formItem
    [bindingProperty]="'labelIds'"
    [inputName]="'labelIds'"
    [hideBulkCheckbox]="true"
    [inlineOptions]="true"
    [dispersedKey]="dispersedKey"
    [customValueParser]="parseLabelIds">
    <ng-container *ngIf="viewModel?.showCustomLabels$ | async">
      <div class="divider-block">
        <hr class="radio-divider" />
        <div class="label-picker-subtitle">Your Labels</div>
      </div>
      <div class="selection-container" *ngFor="let l of viewModel.companyCustomLabels$ | async">
        <lib-reactive-form-checkbox
          #customLabel
          [inputName]="l?.text"
          [addToAtLeastNItemsFilledInPool]="useAtLeastNItemsFilledInPool"
          [disabled]="(disableCheckboxes$ | async) && !(customLabel | isReactiveFormCheckboxSelected)"
          [selectedValue]="l?.id"
          [programmaticallyChangeValue]="selectedLabelIds$ | async | includes : l?.id"
          [notSelectedValue]="null"
          [dispersedKey]="dispersedKey"
          (clicked)="checkboxSelected(l?.id)">
          {{ l?.text }}
        </lib-reactive-form-checkbox>
        <app-label-inflator
          [zoom]="0.8"
          [displayLabelInterface]="l | labelInterfaceForLabelPicker : viewModel.configurations$ : false | async">
        </app-label-inflator>
      </div>
    </ng-container>
    <ng-container>
      <div class="divider-block">
        <hr class="radio-divider" />
        <div class="label-picker-subtitle">System Labels</div>
      </div>
      <div class="selection-container" *ngFor="let l of viewModel.companySystemLabels$ | async">
        <lib-reactive-form-checkbox
          #systemLabel
          [inputName]="l?.text"
          [addToAtLeastNItemsFilledInPool]="useAtLeastNItemsFilledInPool"
          [disabled]="(disableCheckboxes$ | async) && !(systemLabel | isReactiveFormCheckboxSelected)"
          [selectedValue]="(l?.id | lowercase) || l?.id"
          [programmaticallyChangeValue]="selectedLabelIds$ | async | includes : (l?.id | lowercase)"
          [notSelectedValue]="null"
          [dispersedKey]="dispersedKey"
          (clicked)="checkboxSelected(l?.id.toLowerCase())">
          {{ l?.text }}
        </lib-reactive-form-checkbox>
        <app-label-inflator
          [zoom]="0.8"
          [displayLabelInterface]="
            l
              | labelInterfaceForLabelPicker
                : viewModel.configurations$
                : false
                : (l | isCompanyOrphan : viewModel.locationCustomLabels$ | async)
              | async
          ">
        </app-label-inflator>
      </div>
    </ng-container>
    <ng-container *ngIf="viewModel?.showPOSLabels$ | async">
      <div class="divider-block">
        <hr class="radio-divider" />
        <div class="label-picker-subtitle">POS Labels</div>
      </div>
      <div class="selection-container" *ngFor="let l of viewModel.companyPOSLabels$ | async">
        <lib-reactive-form-checkbox
          #posLabel
          [inputName]="l?.text"
          [disabled]="(disableCheckboxes$ | async) && !(posLabel | isReactiveFormCheckboxSelected)"
          [selectedValue]="l?.id"
          [programmaticallyChangeValue]="selectedLabelIds$ | async | includes : l?.id"
          [notSelectedValue]="null"
          [addToAtLeastNItemsFilledInPool]="useAtLeastNItemsFilledInPool"
          [dispersedKey]="dispersedKey"
          (clicked)="checkboxSelected(l?.id)">
          {{ l?.text }}
        </lib-reactive-form-checkbox>
        <app-label-inflator
          [zoom]="0.8"
          [displayLabelInterface]="
            l
              | labelInterfaceForLabelPicker
                : viewModel.configurations$
                : false
                : (l | isCompanyOrphan : viewModel.locationCustomLabels$ | async)
              | async
          ">
        </app-label-inflator>
      </div>
    </ng-container>
  </lib-reactive-form-checkboxes>
</div>
