import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Injector, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableEmptyStateBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartFilterVariantsTableViewModel } from '../../smart-filter-variants-table-view-model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-smart-filter-variants-table-empty-state',
  templateUrl: './smart-filter-variants-table-empty-state.component.html',
  styleUrls: ['./smart-filter-variants-table-empty-state.component.scss'],
  providers: [{
    provide: ReactiveTableEmptyStateBluePrintComponent,
    useExisting: forwardRef(() => SmartFilterVariantsTableEmptyStateComponent)
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterVariantsTableEmptyStateComponent extends ReactiveTableEmptyStateBluePrintComponent {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    public viewModel: SmartFilterVariantsTableViewModel
  ) {
    super(viewRef);
  }

  public title$ = this.viewModel.searchString$.pipe(
    map(searchText => this.getTitleText(searchText))
  );
  public desc$ = this.viewModel.searchString$.pipe(
    map(searchText => this.getDescText(searchText))
  );

  private getTitleText(searchString: string): string {
    if (!!searchString && searchString?.length > 0) {
      return `No results for "${searchString}"`;
    } else {
      return `No results matching the active filters.`;
    }
  }

  private getDescText(searchString: string): string {
    if (!!searchString && searchString?.length > 0) {
      return `It doesn't look like this product exists in your inventory. Try a different search term.`;
    } else {
      return `It doesn't look like this product exists in your inventory. Try modifying your filters.`;
    }
  }

  resetSearch() {
    this.viewModel.connectToSearchString('');
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  initializeFromBluePrint(bluePrint: ReactiveTableEmptyStateBluePrintComponent): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableEmptyStateBluePrintComponent): void {
  }

}
