import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { map, shareReplay } from 'rxjs/operators';
import { FeaturedCategoryMenuCardType } from '../../../../../../models/utils/dto/featured-category-menu-card-type';
import { CardType } from '../../../../../../models/utils/dto/card-type-definition';

export class MenuSectionProductCardColorViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;
  connectToSection = (section: HydratedSection) => this._section.next(section);

  private _cardType = new BehaviorSubject<FeaturedCategoryMenuCardType>(null);
  public cardType$ = this._cardType as Observable<FeaturedCategoryMenuCardType>;
  connectToCardType = (cardType: FeaturedCategoryMenuCardType) => this._cardType.next(cardType);

  private _colorPalette = new BehaviorSubject<string[]>(null);
  public colorPalette$ = this._colorPalette as Observable<string[]>;
  connectToColorPalette = (colorPalette: string[]) => this._colorPalette.next(colorPalette);

  public isTemplatedSection$ = this.section$.pipe(
    map(section => section?.isTemplatedSection()),
    shareReplay({bufferSize: 1, refCount: true})
  );

  cardOpacity$ = this.section$.pipe(
    map(section => {
      if (!!section?.metadata?.cardOpacity && parseInt(section?.metadata?.cardOpacity, 10) <= 1) {
        return (parseFloat(section?.metadata?.cardOpacity) * 100).toString();
      } else {
        return section?.metadata?.cardOpacity || '100';
      }
    })
  );

  secondaryColorLabel$ = this.cardType$.pipe(
    map(cardType => {
      switch (cardType?.value) {
        case CardType.HeaderImage:
        case CardType.HeaderImageCarousel:
          return 'Number Accent Color';
        default:
          // FeaturedCategoryMenuCardType.ProductDealCarousel:
          return 'Accent Color';
      }
    })
  );

  secondaryColorTooltip$ = this.cardType$.pipe(
    map(cardType => {
      switch (cardType?.value) {
        case CardType.HeaderImage:
        case CardType.HeaderImageCarousel:
          return 'The card number circle will fill with this color.';
        case CardType.ProductDealCarousel:
          return 'The card number and product price will fill with this color.';
        default:
          return null;
      }
    })
  );

  public supportsThirdColor$ = this.cardType$.pipe(map(cardType => cardType?.supportsThirdColor()));

  public tertiaryColorLabel$ = this.cardType$.pipe(
    map(cardType => {
      switch (cardType?.value) {
        default: return 'Product Information Color';
      }
    })
  );

  public tertiaryColorTooltip$ = this.cardType$.pipe(
    map(cardType => {
      switch (cardType?.value) {
        default: return 'The product information area will fill with this color.';
      }
    })
  );

}
