import * as moment from 'moment';
import { WeekDay } from '@angular/common';

export class DateUtils {

  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return (DateUtils.currentTimestamp() - days * DateUtils.unixOneDay()) < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return (DateUtils.currentTimestamp() - hours * DateUtils.unixOneHour()) < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return (DateUtils.currentTimestamp() - minutes * 60) < ts;
  }

  private static isBefore(a: WeekDay, aTime: string, b: WeekDay, bTime: string) {
    return a < b || a === b && aTime < bTime;
  }

  private static addOneWeek(day: WeekDay) {
    return day + 7;
  }

  static stringDateAndTimeBetween(
    startDay: WeekDay,
    endDay: WeekDay,
    nowDay: WeekDay,
    startTime: string,
    endTime: string,
    nowTime: string
  ) {
    // Is end day/time before start time (End = Sunday, Now = Tuesday) then overflow
    if (this.isBefore(endDay, endTime, startDay, startTime)) endDay = this.addOneWeek(endDay);
    // Is now day/time before start time (Now = Sunday, Start = Tuesday) then overflow
    if (this.isBefore(nowDay, nowTime, startDay, startTime)) nowDay = this.addOneWeek(nowDay);
    return !this.isBefore(endDay, endTime, nowDay, nowTime);
  }

  // Formatters

  static formatUnixToLastSyncTime(d: number): string {
    if (d === 0) {
      return '--';
    } else {
      // June 21, 2021 at 9:41 PM
      const utcMoment = moment.unix(d);
      return moment(utcMoment).local().format('MMM D[,] YYYY [at] LT');
    }
  }

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    // Jan 7, 2022 10:30 AM
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    // 8:30 PM
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToTimeAndSeconds(d: number): string {
    const utcMoment = moment.unix(d);
    // 8:30:00 PM
    return moment(utcMoment).local().format('LTS');
  }

  static formatUnixToExplicitTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('HH:mm:ss');
  }

  static formatUnixToDate(d: number): string | null {
    if (!d) return null;
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixToShorthandDateWithYear(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D, YYYY');
  }

  static formatUnixToShorthandDateYearTime(d: number): string {
    const utcMoment = moment.unix(d);
    // June 21, 2021 at 9:41 PM
    return moment(utcMoment).local().format('MMM D[,] YYYY [at] LT');
  }

  static formatToDateTimeStringIfUnixStringElseOriginal(val: string): string {
    const isTimestamp = /^[0-9]{9,10}$/;
    return isTimestamp.test(val) ? DateUtils.formatUnixToDateTime(parseInt(val, 10)) : val;
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  static formatUnixRangeToShorthandDateWithYear(sd: number, ed: number): string {
    const startMoment = moment.unix(sd).local();
    const endMoment = moment.unix(ed).local();
    const start = startMoment.format('MMM D, YYYY');
    const end = endMoment.format('MMM D, YYYY');
    if (startMoment.isSame(endMoment, 'day')) {
      return start;
    } else {
      return `${start} - ${end}`;
    }
  }

  static formatUnixTimeRange(sd: number, ed: number): string {
    const startMoment = moment.unix(sd).local();
    const endMoment = moment.unix(ed).local();
    const start = startMoment.format('hh:mm A');
    const end = endMoment.format('hh:mm A');
    return `${start} - ${end}`;
  }

  static formatCreatedAt(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('[created] [at] LT');
  }

  // Reference

  static hoursMinutesSince(d: number): string {
    const differenceSeconds = this.currentTimestamp() - d;
    const hoursAndMinutes = moment.utc(differenceSeconds * 1000).format('H m');
    const hours = hoursAndMinutes.split(' ')[0];
    const mins = hoursAndMinutes.split(' ')[1];
    return `${hours} ${hours === '1' ? 'hr' : 'hrs'} ${mins} ${mins === '1' ? 'min' : 'mins'}`;
  }

  static weekDayToShorthandString(wd: WeekDay): string {
    switch (wd) {
      case WeekDay.Sunday:
        return 'Sun';
      case WeekDay.Monday:
        return 'Mon';
      case WeekDay.Tuesday:
        return 'Tues';
      case WeekDay.Wednesday:
        return 'Wed';
      case WeekDay.Thursday:
        return 'Thurs';
      case WeekDay.Friday:
        return 'Fri';
      case WeekDay.Saturday:
        return 'Sat';
    }
  }

  // Unix Helpers

  static nowInUnixMilliseconds(): number {
    return moment().valueOf();
  }

  static nowInUnixSeconds(): number {
    return moment().unix();
  }

  static currentTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static currentWeekday(): number {
    return new Date().getDay();
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }

  static unixOneMinute(): number {
    return 60;
  }

  static getSecondsSinceStartOfDay() {
    const timeNow = new Date();
    const hrsInSecs = timeNow.getHours() * 3600;
    const minsInSecs = timeNow.getMinutes() * 60;
    const secs = timeNow.getSeconds();
    return (hrsInSecs + minsInSecs + secs);
  }

  static convert12HourTo24HourTime(time12Hour: string) {
    if (!time12Hour) {
      return time12Hour;
    }
    const [time, modifier] = time12Hour.toUpperCase().split(' ');
    const timeSplit = time.split(':');
    // using firstOrNull here breaks our testing framework
    let hoursString = timeSplit[0] ?? null;
    const [, minutes] = timeSplit;
    if (hoursString === '12') {
      hoursString = '00';
    }
    if (modifier === 'PM') {
      hoursString = `${parseInt(hoursString, 10) + 12}`;
    }
    return `${hoursString}:${minutes}`;
  }

  static convert24HourTo12HourTime(time24Hour: string) {
    if (!time24Hour) {
      return time24Hour;
    }
    let ts = time24Hour.toUpperCase();
    const H = parseInt(ts.substr(0, 2), 10);
    const h = (H % 12) || 12;
    const hourString = (h < 10) ? ('0' + h) : h;  // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? ' AM' : ' PM';
    ts = hourString + ts.substr(2, 3) + ampm;
    return ts;
  }

  // Conversions
  static daysToSeconds(days: number): number {
    return days * 24 * 60 * 60;
  }

  static hoursToSeconds(hours: number): number {
    return hours * 60 * 60;
  }

}
