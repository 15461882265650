import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { combineLatest, Observable, of } from 'rxjs';
import { LocationConfiguration } from '../../../models/company/dto/location-configuration';

@Injectable()
export class ConfirmationModalViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  // Pass in an empty locationConfig since we want to show what the label will look like when restored to company style
  private readonly emptyLocationConfig$: Observable<LocationConfiguration> = of(null);
  private readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly configurations$ = combineLatest([this.emptyLocationConfig$, this.companyConfig$]);

}
