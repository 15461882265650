import { Component } from '@angular/core';
import { Viewable } from '../../../../models/protocols/viewable';
import { BaseComponent } from '../../../../models/base/base-component';
import { AllDisplaysViewModel } from '../../viewModels/all-displays-view-model';

@Component({
  selector: 'app-all-displays',
  templateUrl: './all-displays.component.html',
  styleUrls: [
    './all-displays.component.scss',
    '../../../shared/styles/all-items-styles.scss'
  ]
})
export class AllDisplaysComponent extends BaseComponent implements Viewable {

  constructor(
    public viewModel: AllDisplaysViewModel,
  ) {
    super();
  }

}
