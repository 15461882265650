<div class="smart-filter-variants-table-container mt-16px">
  <app-smart-filter-variants-preview-table
    [data]="variants"
    [nItemsToDisplay]="nItemsToDisplay"
    [nPagesInsidePaginationIndicator]="7"
    [resetPageOnNewData]="resetPageOnNewData"
    [resetPageOnSortingChange]="resetPageOnSortingChange">
    <app-smart-filter-variants-table-header></app-smart-filter-variants-table-header>
    <app-smart-filter-variants-table-item></app-smart-filter-variants-table-item>
    <app-smart-filter-variants-table-empty-state></app-smart-filter-variants-table-empty-state>
    <app-table-loading></app-table-loading>
    <lib-reactive-table-paginator></lib-reactive-table-paginator>
  </app-smart-filter-variants-preview-table>
</div>
