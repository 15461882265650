import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Section } from '../../../../../../models/menu/dto/section';
import { ModalAddSection } from '../../../../../../modals/modal-add-section';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalReorderSections } from '../../../../../../modals/modal-reorder-sections';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-product-menu-sections',
  templateUrl: './edit-product-menu-sections.component.html',
  styleUrls: ['./edit-product-menu-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProductMenuSectionsComponent extends BaseComponent {

  constructor(
    public viewModel: EditProductMenuViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected router: Router,
    protected activeRoute: ActivatedRoute
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

  openAddSectionModal() {
    if (this.formPristine) {
      this.openNewMenuSectionModal();
    } else {
      this.viewModel.formAutoSubmitted$.once(_ => this.openNewMenuSectionModal());
      this.submitForms.emit(true);
    }
  }

  private openNewMenuSectionModal() {
    this.viewModel.menu$.once(menu => {
      const onClose = (section: Section) => {
        const url = section?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalAddSection.open(this.ngbModal, this.injector, menu, this.viewModel.menuTheme$, onClose);
    });
  }

  reorderSections() {
    this.viewModel.menuSections$.once(menuSections => {
      const reorderOperation = (sections: Section[]): Observable<boolean> => {
        // Apply new menu priority
        return this.viewModel.saveMenuSectionOrder(sections);
      };
      ModalReorderSections.open(this.ngbModal, this.injector, menuSections, reorderOperation);
    });
  }

}
