import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Display, LocationDisplayGrouping } from '../../../../../models/display/dto/display';
import { Location } from '../../../../../models/company/dto/location';

@Injectable()
export class ViewCollectionDisplaysViewModel extends BaseModalViewModel {

  constructor(
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private _province = new BehaviorSubject<string>(null);
  public province$ = this._province as Observable<string>;
  connectToProvince = (province: string) => this._province.next(province);

  private _locationDisplayGroupings = new BehaviorSubject<LocationDisplayGrouping[]>(null);
  public locationDisplayGroupings$ = this._locationDisplayGroupings as Observable<LocationDisplayGrouping[]>;
  connectToLocationDisplayGroupings = (locations: LocationDisplayGrouping[]) => {
    return this._locationDisplayGroupings.next(locations);
  };

  private _requiredDisplayIds = new BehaviorSubject<string[]>([]);
  public requiredDisplayIds$ = this._requiredDisplayIds as Observable<string[]>;
  connectToRequiredDisplayIds = (ids: string[]) => this._requiredDisplayIds.next(ids);

  public displays$ = this.locationDisplayGroupings$.pipe(
    map((locations) => locations?.flatMap(location => location?.displays || []))
  );

  private _searchString = new BehaviorSubject<string>(null);
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _searchedDisplays = new BehaviorSubject<Display[]>(null);
  public searchedDisplays$ = this._searchedDisplays as Observable<Display[]>;
  connectToSearchedDisplays = (displays: Display[]) => this._searchedDisplays.next(displays);

  public searchedDisplaysGroupedByLocation$ = combineLatest([
    this.searchedDisplays$,
    this.locationDisplayGroupings$
  ]).pipe(
    map(([searchedDisplays, displayGroupings]) => {
      const filteredDisplayGroupings = displayGroupings?.filter((dg) => {
        return dg?.displays?.some(d => searchedDisplays?.some(sd => sd?.id === d?.id));
      });
      filteredDisplayGroupings.forEach((dg) => {
        dg.displays = dg?.displays?.filter((display => searchedDisplays.some((sd) => sd?.id === display?.id)));
      });
      return filteredDisplayGroupings;
    })
  );

  public trackDisplayGroupingById(index, grouping: {location: Location; displays: Display[]}) {
    return grouping?.location?.id;
  }

  public trackByDisplayId(index, display: Display) {
    return display.id;
  }

}
