<lib-reactive-form-group>
  <div fxLayout="column" fxLayoutGap="0.5rem">
    <!-- Top Row -->
    <div fxLayout="row" fxLayoutAlign=" end" fxLayoutGap="2rem">
      <!-- Left Column 50% -->
      <div fxFlex fxLayout="row" fxLayoutGap="1rem">
        <!-- text field with clear button overlay on right side-->
        <div fxFlex class="search-container">
          <lib-reactive-form-text
            #searchBar
            class="search-products"
            [style.display]="'block'"
            [inputName]="'searchText'"
            [label]="'Search'"
            [placeholder]="'Search to filter results'"
            [inlineLabel]="false"
            [required]="false"
            [disabled]="false"
            [programmaticallyChangeValue]="viewModel.programmaticallySetSearchText$ | async"
            (valueChanged)="viewModel.setSearchText($event)">
          </lib-reactive-form-text>
          <button
            *ngIf="(viewModel.searchText$ | async)?.length > 0"
            (click)="searchBar?.getSelfAsFormItem()?.setValue('')"
            type="button"
            class="clear-search">
            CLEAR
          </button>
        </div>
      </div>
      <!-- Right Column 50% -->
      <div fxFlex>
        <lib-reactive-form-column-layout nColumns="2" columnGap="2rem">
          <app-segmented-control
            *ngIf="viewModel.displayAllRecMedControl$ | async"
            [options]="viewModel.usePurposeOptions$ | async"
            (optionSelected)="viewModel.setUsePurpose($event)"></app-segmented-control>

          <lib-reactive-form-switch
            class="out-of-stock-switch"
            [style.display]="'block'"
            [style.padding-bottom]="'0.5rem'"
            [inputName]="'datatableCheckBox'"
            [selectedValue]="true"
            [notSelectedValue]="false"
            [labelOnRight]="true"
            [programmaticallyChangeValue]="
              hideRecentlyUpdatedProductsMode
                ? (viewModel.hideRecentlyUpdatedProducts$ | async)
                : (viewModel.hideOutOfStockProducts$ | async)
            "
            (valueChanged)="
              hideRecentlyUpdatedProductsMode
                ? viewModel.setHideRecentlyUpdatedProducts($event)
                : viewModel.setHideOutOfStockProducts($event)
            ">
            {{ hideRecentlyUpdatedProductsMode ? 'Hide recently updated' : 'Hide out of stock products' }}
          </lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </div>
    </div>
    <!-- Bottom Row - Columns spaced equally -->
    <lib-reactive-form-row [style.display]="'block'" [gap]="'2rem'">
      <lib-reactive-form-drop-down
        #filterByProductType
        [inputName]="'filterByProductType'"
        [label]="'Filter by product type'"
        [placeholder]="'Choose a product type'"
        [inlineLabel]="false"
        [disabled]="viewModel.productTypeDisabled$ | async"
        [required]="false"
        [clearable]="viewModel.filterByProductTypeClearable$ | async"
        [dropdowns]="viewModel.selectableProductTypes$ | async"
        [programmaticallyChangeValue]="viewModel.filterByProductType$ | async"
        (valueChanged)="
          filterByVariantType.clear(true); filterByStrainType?.clear(true); viewModel.setFilterByProductType($event)
        ">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        #filterByVariantType
        [inputName]="'filterByVariantType'"
        [label]="'Filter by variant type'"
        [placeholder]="'Choose a variant type'"
        [inlineLabel]="false"
        [disabled]="viewModel.variantTypeDisabled$ | async"
        [required]="false"
        [clearable]="viewModel.filterByVariantTypeClearable$ | async"
        [dropdowns]="viewModel.selectableVariantTypes$ | async"
        [programmaticallyChangeValue]="viewModel.filterByVariantType$ | async"
        (valueChanged)="filterByStrainType.clear(true); viewModel.setFilterByVariantType($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        #filterByStrainType
        [inputName]="'filterByStrainType'"
        [label]="'Filter by strain type'"
        [placeholder]="'Choose a strain type'"
        [inlineLabel]="false"
        [disabled]="viewModel.strainTypeDisabled$ | async"
        [required]="false"
        [clearable]="viewModel.filterByStrainTypeClearable$ | async"
        [dropdowns]="viewModel.selectableStrainTypes$ | async"
        [programmaticallyChangeValue]="viewModel.filterByStrainType$ | async"
        (valueChanged)="viewModel.setFilterByStrainType($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        [inputName]="'numberOfResults'"
        [label]="'Number of Results'"
        [placeholder]="'Number of Results'"
        [inlineLabel]="false"
        [disabled]="false"
        [required]="false"
        [dropdowns]="viewModel.nProductsPerPageOptions$ | async"
        [programmaticallyChangeValue]="viewModel.numberOfProductsPerPage$ | async"
        (valueChanged)="viewModel.setNumberOfProductsPerPage($event); resetPageNumber.emit()">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-row>
  </div>
  <hr *ngIf="includeBottomBorder" class="no-margin pt-4px" />
</lib-reactive-form-group>
