// noinspection JSUnusedLocalSymbols

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../models/base/base-component';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';
import { Asset } from '../../../../models/image/dto/asset';
import { CachePolicy } from '../../../../models/enum/shared/cachable-image-policy.enum';
import { LoadingTechnique } from './loading-technique.enum';
import { DateUtils } from '../../../../utils/date-utils';
import { AssetUrl } from '../../../../models/image/dto/asset-url';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent extends BaseComponent implements OnChanges {

  @ViewChild('image') private img: ElementRef;
  @ViewChild('video') private video: ElementRef;
  @Input() asset: Asset;
  @Input() autoplay: boolean = true;
  @Input() borderRadius: string = '';
  @Input() cachePolicy: CachePolicy = CachePolicy.Service;
  @Input() cacheForNSeconds: number = (DateUtils.unixOneHour() * 12);
  @Input() easeInFromTop: boolean = false; // animation on load
  @Input() loadingTechnique: LoadingTechnique = LoadingTechnique.SHIMMER_THUMB;
  @Input() loopVideo: boolean = true;
  @Input() reset: boolean = false;
  @Input() scaleFit: boolean = false;
  @Input() showControls: boolean = false;
  @Input() size: AssetSize = AssetSize.Medium;
  @Input() styleOverrides: any;
  @Input() sweepFromBottomToTop: boolean = false; // animation on load
  @Input() sweepFromMiddleToTop: boolean = false; // animation on load
  @Input() objectPosition: string = '';
  @Output() duration: EventEmitter<number> = new EventEmitter<number>();
  @Output() ratio: EventEmitter<number> = new EventEmitter<number>();
  @Output() videoEnded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private resetSubject = new BehaviorSubject<boolean>(false);
  private isVideo = new BehaviorSubject<boolean>(false);

  // Reset
  private resetMech = combineLatest([
      this.resetSubject.pipe(distinctUntilChanged()),
      this.isVideo.pipe(distinctUntilChanged()),
      this.duration.asObservable().pipe(distinctUntilChanged())
  ]).pipe(debounceTime(1), takeUntil(this.onDestroy))
    .subscribe(([reset, isVid, duration]) => {
      if (isVid) {
        if (reset && duration > 0) {
          this.video?.nativeElement?.pause();
          this.video?.nativeElement?.load();
        } else if (!reset && duration > 0) {
          this.video?.nativeElement?.play().then();
        }
      }
    });

  // Asset URL
  private subject: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public distinctAsset$ = this.subject.pipe(
    startWith('assets/placeholder/list-image.jpg'),
    map(val => (!val ? 'assets/placeholder/list-image.jpg' : val)),
    distinctUntilChanged(),
  );

  // Loading
  private loading: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private loadingTechniqueSubject = new BehaviorSubject<LoadingTechnique>(LoadingTechnique.SHIMMER_THUMB);
  private loadingEnabled$ = this.loadingTechniqueSubject.pipe(map(technique => technique !== LoadingTechnique.THUMB));
  public loading$ = combineLatest([
    this.loadingEnabled$.pipe(distinctUntilChanged()),
    this.loading.pipe(startWith(true), distinctUntilChanged()),
  ]).pipe(map(([enabled, loading]) => enabled && loading));
  public notLoading$ = this.loading$.pipe(map(it => !it));

  constructor(
    public el: ElementRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.forceVideosToOriginalSize();
    const previousValue = !!changes?.asset?.previousValue;
    const oldHash = changes?.asset?.previousValue?.md5Hash;
    const newHash = changes?.asset?.currentValue?.md5Hash;
    const hashChanged = (oldHash !== newHash);
    const previouslyNoUrls = !changes?.asset?.previousValue?.urls || changes?.asset?.previousValue?.urls?.length < 1;
    const currentHasUrls = changes?.asset?.currentValue?.urls?.length > 0;
    const noUrlsToHavingUrls = previousValue && previouslyNoUrls && currentHasUrls;
    const md5Changed = previousValue && hashChanged;
    if (md5Changed || noUrlsToHavingUrls || !previousValue) this.setupImageBinding();
    if (!!changes.reset) this.checkReset();
    if (!!changes.loadingTechnique) this.checkLoadingTechnique();
  }

  override setupViews() {
    this.ratio.emit(1);
  }

  private checkReset() {
    this.resetSubject.next(this.reset);
  }

  private checkLoadingTechnique() {
    this.loadingTechniqueSubject.next(this.loadingTechnique);
  }

  private setupImageBinding() {
    this.forceVideosToOriginalSize();
    this.fetchAsset();
    const imgKey = 'assetKey';
    const imgLoadingKey = 'loadingKey';
    this.destroyImageSub(imgKey);
    this.destroyImageSub(imgLoadingKey);
    let blobSub;
    let loadingSub;
    let loadingAssetUrl: AssetUrl|null|undefined;
    if (this.loadingTechnique === LoadingTechnique.SHIMMER) {
      loadingAssetUrl = this?.asset?.isVideo()
        ? this?.asset?.getAssetUrl(AssetSize.Original)
        : this?.asset?.getAssetUrl(this.size);
    } else if (this.loadingTechnique === LoadingTechnique.SHIMMER_THUMB) {
      loadingAssetUrl = this?.asset?.isVideo()
        ? this?.asset?.getAssetUrl(AssetSize.Original)
        : this?.asset?.getAssetUrl(AssetSize.Thumb);
    }
    if (!!loadingAssetUrl) {
      loadingSub = this.loading.bind(loadingAssetUrl.loading);
    } else {
      this.loading.next(false);
    }
    if (!!this.asset?.sizePriorityUrl$) {
      blobSub = this.asset.sizePriorityUrl$.subscribe((val) => this.subject.next(val));
    }
    if (blobSub) this.pushImageSub(imgKey, blobSub);
    if (loadingSub) this.pushImageSub(imgLoadingKey, loadingSub);
  }

  private forceVideosToOriginalSize() {
    if (this.asset) {
      if (this.asset.isVideo() || !this.asset.fileName) {
        // if video force use original file size
        this.size = AssetSize.Original;
      }
    }
  }

  private fetchAsset() {
    const asset = this.asset;
    const size = this.size;
    const cacheForNSeconds = this.cacheForNSeconds;
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (!!asset && !!size && validCacheTime) {
      const policy = this.cachePolicy ?? CachePolicy.Service;
      asset.getAsset(policy, size, cacheForNSeconds);
    }
  }

}
