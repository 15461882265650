<div class="card-selection-container" fxLayout="row wrap" fxLayoutGap="1rem">
  <div
    *ngFor="let card of viewModel.cardsToDisplay$ | async"
    fxFlex
    class="card-selection"
    (click)="handleCardClick(card.value)"
    [ngbTooltip]="disabled | templatedMenuDisabledFormControlTooltip"
    [openDelay]="500"
    [ngClass]="{ selected: selectedType === card.value, disabled: disabled }">
    <img
      *ngIf="selectedType === card.value"
      class="check-mark"
      src="assets/icons/green/solid/check-circle.svg"
      alt="" />
    <div fxLayout="column" fxLayoutGap="0.5rem">
      <img [src]="card?.getCardImageUrl()" alt="" />
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="card-text">{{ card.name }}</div>
      </div>
    </div>
  </div>
</div>
