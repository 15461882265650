import { Component, ViewChild } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { Router } from '@angular/router';
import { ToastService } from '../../../../../services/toast-service';
import { ForgotPasswordRequest } from '../../../../../models/account/requests/forgot-password-request';
import { BsError } from '../../../../../models/shared/bs-error';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ReactiveFormEmailComponent } from '@mobilefirstdev/reactive-form';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../../../../services/navigation.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    './forgot-password.component.scss',
    '../auth.component.scss'
  ]
})
export class ForgotPasswordComponent extends BaseComponent {

  @ViewChild('resetCodeEmail') resetCodeEmail: ReactiveFormEmailComponent;

  // View
  public _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this._loadingOpts.asObservable().pipe(map(opts => opts.isLoading));
  // Variables
  public readonly req$ = this.viewModel.preFillEmail$.pipe(
    map(email => new ForgotPasswordRequest(email))
  );

  constructor(
    private router: Router,
    private toastService: ToastService,
    public viewModel: AuthViewModel,
    private navigationService: NavigationService
  ) {
    super();
  }

  formSubmitted(req: ForgotPasswordRequest) {
    const loadingMess = 'Sending forgot password email.';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.forgotPassword(req).subscribe((_) => {
        this._loadingOpts.removeRequest(loadingMess);
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  backToSignIn() {
    const tempEmail = this.resetCodeEmail.getMyValue();
    this.navigationService.signIn({ queryParams: { email: tempEmail }});
  }

}
