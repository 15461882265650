import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditSectionForm } from '../edit-section-form/edit-section-form';
import { EditFeaturedCategorySectionFormViewModel } from './edit-featured-category-section-form-view-model';

@Component({
  selector: 'app-edit-featured-category-section-form',
  templateUrl: './edit-featured-category-section-form.component.html',
  styleUrls: ['./edit-featured-category-section-form.component.scss'],
  providers: [EditFeaturedCategorySectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategorySectionFormComponent extends EditSectionForm {

  constructor(public override viewModel: EditFeaturedCategorySectionFormViewModel) {
    super(viewModel);
  }

}
