import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Asset } from '../../../../models/image/dto/asset';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LogoTypeEnum } from '../../../../models/company/enum/logo-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { UploadImageInterface } from '../upload-asset/upload-image-interface';
import { BaseComponent } from '../../../../models/base/base-component';
import { UploadAssetComponent } from '../upload-asset/upload-asset.component';
import { delay, map } from 'rxjs/operators';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { ModalReplaceCompanyLogo } from '../../../../modals/modal-replace-company-logo';
import { ModalConfirmation } from '../../../../modals/modal-confirmation';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent extends BaseComponent implements OnChanges, UploadImageInterface {

  @ViewChild('uploadContainer') uploadContainer: UploadAssetComponent;

  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(this.getLoadingSpinner());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(it => it?.isLoading));

  @Input() id: number;
  @Input() locationId: number = 0;
  @Input() rowTitle: string;
  @Input() rowDescription: string;
  @Input() logoType: LogoTypeEnum;
  @Input() logo: Asset;
  @Input() logoToUpload: BudsenseFile;
  @Input() clearImages: Subject<boolean> = new Subject<boolean>();
  @Input() imageStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() deleteLogo: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadLogo: EventEmitter<BudsenseFile> = new EventEmitter<BudsenseFile>();

  constructor(
    private ngbModal: NgbModal,
    private injector: Injector
  ) {
    super();
  }

  override setupBindings(): void {
    this.bindToClearImages();
    this.imageStatusChanged.subscribe((loading: boolean) => {
      const opts = window?.injector.Deserialize?.instanceOf(LoadingOptions, this._loadingOpts.getValue());
      opts.isLoading = loading;
      this._loadingOpts.next(opts);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.clearImages) {
      this.bindToClearImages();
    }
  }

  private getLoadingSpinner(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.loadingText = '';
    opts.isLoading = false;
    opts.fullscreen = false;
    opts.zIndex = 100;
    opts.backgroundColor = 'transparent';
    return opts;
  }

  bindToClearImages() {
    const key = 'clear-images';
    this.destroyImageSub(key);
    const s = this.clearImages.pipe(delay(100)).subscribe(clear => {
      if (clear) {
        this.uploadContainer?.clear();
      }
    });
    this.pushImageSub(key, s);
  }

  openReplaceLogoModal(): void {
    ModalReplaceCompanyLogo.open(this.ngbModal, this.injector, this.logoType, this.locationId, this._loadingOpts);
  }

  openDeleteLogoModal(): void {
    const alt = this.logoType === LogoTypeEnum.Alternative;
    const opts = new ConfirmationOptions();
    opts.title = `Delete ${alt ? 'Alternate' : ''} ${this.locationId > 0 ? 'Location' : 'Company'} Photo`;
    opts.bodyText = 'Are you sure you want to delete this logo?';
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete Logo';
    const confirmation = (cont) => {
      if (cont) {
        this.deleteLogo.emit(true);
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
  }

  fileList(f: BudsenseFile[], id: number) {
    if (f.length > 0) {
      this.logoToUpload = f[0];
      this.uploadLogo.emit(f[0]);
    } else {
      this.logoToUpload = undefined;
    }
  }

}
