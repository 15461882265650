<app-full-image-card
  *ngIf="card | isFullImageCard"
  [card]="card | castToProductCard"
  [index]="index"
  [cardCount]="cardCount">
</app-full-image-card>
<app-half-image-card
  *ngIf="card | isHalfImageCard"
  [card]="card | castToProductCard"
  [index]="index"
  [cardCount]="cardCount">
</app-half-image-card>
<app-embedded-image-card
  *ngIf="card | isEmbeddedImageCard"
  [card]="card | castToProductCard"
  [index]="index"
  [cardCount]="cardCount">
</app-embedded-image-card>
