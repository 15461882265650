import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'disableGridColumnCheckbox'
})
export class DisableGridColumnCheckboxPipe implements PipeTransform {

  transform(
    colName: string,
    selectedGridColumns$: Observable<string[]>,
    maximumColsReached$: Observable<boolean>
  ): Observable<boolean> {
    return combineLatest([selectedGridColumns$, maximumColsReached$]).pipe(
      map(([selectedGridColumns, maximumColsReached]) => {
        return maximumColsReached && !selectedGridColumns?.includes(colName);
      })
    );
  }

}
