<div
  appThrottleClick
  (throttleClick)="navItemSelected()"
  [ngClass]="{
    'passive-selection': true,
    active: viewModel.isNavItemActive$ | async,
    animating: animating$ | async
  }"
  class="nav-item active bs-nav-item"
  fxLayout="row"
  fxLayoutAlign="center center">
  <div fxFlex class="current-loc-nav-item-container">
    <div class="nav-item-icon">
      <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon" />
      <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled" />
    </div>
    <div class="nav-item-label" fxFlex [ngClass]="{ 'multi-line-label': viewModel.multiLineNavItem$ | async }">
      {{ viewModel.location$ | async }}
    </div>
  </div>
  <img alt="icon" class="nav-bar-icon" src="/assets/icons/light/outline/selector.svg" />
</div>
