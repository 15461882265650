import { EditMarketingMenuViewModel } from '../../../../viewModels/edit-marketing-menu-view-model';
import { Injectable } from '@angular/core';
import { OrderableMenuAsset } from '../../../../../../models/menu/shared/orderable-menu-asset';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { of } from 'rxjs';

@Injectable()
export class EditPlaylistMenuViewModel extends EditMarketingMenuViewModel {

  showSaveButton$ = of(true);

  protected override subToOrderedMedia() {
    this.orderableMediaSubject$.subscribeWhileAlive({
      owner: this,
      next: ([options, media]) => {
        let orderedMedia = media?.assets?.map(m => {
          const mediaPosition = options?.rotationOrder?.get(m.fileName);
          return new OrderableMenuAsset(m.fileName, m, mediaPosition);
        });
        this.tempUploadedMedia = this.tempUploadedMedia?.filter(tm => !orderedMedia?.find(om => om?.id === tm?.id));
        orderedMedia = orderedMedia?.concat(this.tempUploadedMedia ?? [])?.sort(SortUtils.menuAssets);
        this.connectToOrderedMedia(orderedMedia);
      }
    });
  }

  updateTempMedia(spoofedMenuAssets: OrderableMenuAsset[]) {
    this.tempUploadedMedia = spoofedMenuAssets;
    this.orderedMedia$.once(current => {
      const orderedMedia = current.concat(spoofedMenuAssets).sort(SortUtils.menuAssets);
      this.connectToOrderedMedia(orderedMedia);
    });
  }

}
