import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../services/guards/can-deactivate.guard';
import { EditProductMenuComponent } from './components/edit-menu/edit-product-menu/edit-product-menu.component';
import { EditMenuSectionComponent } from './components/edit-menu-section/edit-menu-section.component';
import { EditSpotlightMenuComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu.component';
import { AuthGuard } from '../../services/guards/auth.guard';
import { PermissionGuard } from '../../services/guards/permission.guard';
import { EditPlaylistMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu.component';
import { EditFeaturedProductMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu.component';
import { EditDriveThruMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu.component';
import { ClientTypeUtils } from '../../utils/client-type-utils';
import { DigitalComponent } from './components/menus/digital/digital.component';
import { WebComponent } from './components/menus/web/web.component';
import { PrintComponent } from './components/menus/print/print.component';
import { EditFeaturedCategoryMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu.component';
import { EditFeaturedCategorySectionComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section.component';
import { EditSmartPlaylistMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu.component';
import { EditSmartPlaylistMenuSectionComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-section/edit-smart-playlist-menu-section.component';
import { EditCardStackMenuComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-menu.component';
import { EditLabelStackMenuComponent } from './components/edit-menu/edit-label-stack-menu/edit-label-stack-menu.component';
import { EditUrlPlaylistMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'digital',
    pathMatch: 'full'
  },
  {
    path: 'digital',
    component: DigitalComponent,
    data: {
      type: ClientTypeUtils.MenuType.DisplayMenu
    },
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'digital/product/:menuId',
    component: EditProductMenuComponent,
    data: {
      title: 'Edit Product Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'digital/product/:menuId/section/:sectionId',
    component: EditMenuSectionComponent,
    data: {
      title: 'Edit Product Menu Section'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/spotlight/:menuId',
    component: EditSpotlightMenuComponent,
    data: {
      title: 'Edit Spotlight Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/playlist/:menuId',
    component: EditPlaylistMenuComponent,
    data: {
      title: 'Edit Marketing Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/smart-playlist/:menuId',
    component: EditSmartPlaylistMenuComponent,
    data: {
      title: 'Edit Marketing Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/smart-playlist/:menuId/section/:sectionId',
    component: EditSmartPlaylistMenuSectionComponent,
    data: {
      title: 'Edit Product Grouping'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/featured/:menuId',
    component: EditFeaturedProductMenuComponent,
    data: {
      title: 'Edit Marketing Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/drive-thru/:menuId',
    component: EditDriveThruMenuComponent,
    data: {
      title: 'Edit Marketing Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/category/:menuId',
    component: EditFeaturedCategoryMenuComponent,
    data: {
      title: 'Edit Marketing Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'digital/marketing/category/:menuId/section/:sectionId',
    component: EditFeaturedCategorySectionComponent,
    data: {
      title: 'Edit Featured Category Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path:'digital/marketing/url-playlist/:menuId',
    component: EditUrlPlaylistMenuComponent,
    data: {
      title: 'Edit URL Playlist'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'web',
    component: WebComponent,
    data: {
      type: ClientTypeUtils.MenuType.WebMenu
    },
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: 'web/product/:menuId',
    component: EditProductMenuComponent,
    data: {
      title: 'Edit Product Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'web/product/:menuId/section/:sectionId',
    component: EditMenuSectionComponent,
    data: {
      title: 'Edit Product Menu Section'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'web/spotlight/:menuId',
    component: EditSpotlightMenuComponent,
    data: {
      title: 'Edit Spotlight Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print',
    component: PrintComponent,
    data: {
      type: ClientTypeUtils.MenuType.PrintMenu
    },
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: 'print/product/:menuId',
    component: EditProductMenuComponent,
    data: {
      title: 'Edit Product Menu'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/product/:menuId/section/:sectionId',
    component: EditMenuSectionComponent,
    data: {
      title: 'Edit Product Menu Section'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/stack/:menuId',
    component: EditCardStackMenuComponent,
    data: {
      title: 'Edit Card Stack'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/labels/:menuId',
    component: EditLabelStackMenuComponent,
    data: {
      title: 'Edit Label Stack'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/report/:menuId',
    component: EditProductMenuComponent,
    data: {
      title: 'Edit Report'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'print/report/:menuId/section/:sectionId',
    component: EditMenuSectionComponent,
    data: {
      title: 'Edit Report Section'
    },
    canActivate: [AuthGuard, PermissionGuard],
    canDeactivate: [CanDeactivateGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenusRoutingModule {
}
