<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">
      {{ primaryAssetTitle }}
    </p>
    <p class="bs-edit-section-description">
      {{ primaryAssetDesc }}
    </p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8 menu-background-image-container position-relative">
    <app-loading *ngIf="!hideLoading" [options]="loadingOpts"> </app-loading>
    <!-- Asset Preview -->
    <app-asset-preview
      (primaryButtonPressed)="deleteSectionAssetClicked.emit()"
      (secondaryButtonPressed)="changeSectionAssetClicked.emit()"
      (refreshButtonPressed)="refreshSectionAssetClicked.emit($event)"
      [templateMode]="templateMode"
      [asset]="sectionAsset"
      [hidden]="!sectionAsset"
      [formPristine]="formPristine"
      [options]="sectionAssetPreview"
      [isDisabledByTemplates]="isTemplatedSection">
    </app-asset-preview>

    <!-- Upload Asset -->
    <div [hidden]="!!sectionAsset" class="section-upload-area">
      <app-upload-asset
        #sectionHeaderImage
        [allowImage]="allowImage"
        [allowVideo]="allowVideo"
        [displayList]="false"
        [formPristine]="formPristine"
        [isDisabledByTemplates]="isTemplatedSection"
        [maxAssets]="1"
        [parentHandler]="parentHandler">
      </app-upload-asset>
    </div>
  </div>
</div>

<hr class="mt-24px" />
