import { TypeDefinition } from './type-definition';

/**
 * Do not delete this enum. The enum is used to BUILD properties on display-attributes and smart-filters.
 * Order matters here. It is used to sort the terpenes in the UI.
 * This list is accessed via Object.values(Terpene).
 */
export enum Terpene {
  AlphaBisabolol = 'Alpha Bisabolol',
  AlphaPinene = 'Alpha Pinene',
  BetaCaryophyllene = 'Beta Caryophyllene',
  BetaEudesmol = 'Beta Eudesmol',
  BetaMyrcene = 'Beta Myrcene',
  BetaPinene = 'Beta Pinene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Camphene = 'Camphene',
  CaryophylleneOxide = 'Caryophyllene Oxide',
  Delta3Carene = 'Delta 3 Carene',
  Eucalyptol = 'Eucalyptol',
  Geraniol = 'Geraniol',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Ocimene = 'Ocimene',
  ThreeCarene = 'Three Carene',
  Terpinene = 'Terpinene',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'Trans Nerolidol',
  Valencene = 'Valencene',
}

export class TerpeneTypeDefinition extends TypeDefinition {

  override value: Terpene;

}
