import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceDotWithDash'
})
export class ReplaceDotWithDashPipe implements PipeTransform {

  transform(value: string): unknown {
    return value?.replace(/./g, '-');
  }

}
