import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuAddedToDisplayFormComponent } from '../menu-added-to-display-form/menu-added-to-display-form.component';
import { MenuWithinCollectionAddedToDisplayFormViewModel } from './menu-within-collection-added-to-display-form-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-menu-within-collection-added-to-display-form',
  templateUrl: '../menu-added-to-display-form/menu-added-to-display-form.component.html',
  styleUrls: ['./menu-within-collection-added-to-display-form.component.scss'],
  providers: [MenuWithinCollectionAddedToDisplayFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuWithinCollectionAddedToDisplayFormComponent extends MenuAddedToDisplayFormComponent {

  constructor(public override viewModel: MenuWithinCollectionAddedToDisplayFormViewModel) {
    super(viewModel);
  }

  @Input() @ViewModelConnector('connectToMenuIsPartOfCollection') override menuIsPartOfCollection = true;

}
