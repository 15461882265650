import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { NumberUtils } from '../../../utils/number-utils';

export class VariantInventory implements Deserializable, UniquelyIdentifiable {

  public locationId: number;
  public variantId: string;
  public quantityInStock: number;
  public quantityOnOrder: number;
  public quantityTransferIn: number;
  public quantityTransferOut: number;
  public firstInventory: number;
  public lastRestock: number;
  public lastThresholdRestock: number;
  public lastModified: number;

  public onDeserialize() {
  }

  inStock(): boolean {
    return NumberUtils.floatFirstGreaterThanSecond(this.quantityInStock, 0);
  }

  lastRestockedTimeInSeconds(): number {
    return this.lastRestock || this.firstInventory;
  }

  restockedWithin(timestampInThePast: number): boolean {
    const lastRestockHit = this.lastRestock >= timestampInThePast;
    const firstInventoryHit = this.firstInventory >= timestampInThePast;
    return lastRestockHit || firstInventoryHit;
  }

  getUniqueIdentifier(): string {
    return `${this.variantId}
      -${this.quantityInStock}
      -${this.quantityOnOrder}
      -${this.quantityTransferIn}
      -${this.quantityTransferOut}
      -${this.firstInventory}
      -${this.lastRestock}
      -${this.lastModified}`;
  }

}
