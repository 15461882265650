import { Deserializable } from '../protocols/deserializable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import * as moment from 'moment';

export class LocalizedDateRange implements Deserializable, UniquelyIdentifiable {

  public startDate: number; // stored in seconds
  public endDate: number;   // stored in seconds

  // Front end only properties
  public startDateString: string;
  public startTimeString: string;
  public endDateString: string;
  public endTimeString: string;

  onDeserialize() {
    // Convert startDate to startDateString & startTimeString
    const startDate = moment.utc(this.startDate * 1000); // utc initialization requires milliseconds
    this.startDateString = startDate.format('YYYY-MM-DD');
    this.startTimeString = startDate.format('HH:mm:ss');
    // Convert endDate to endDateString & endTimeString
    const endDate = moment.utc(this.endDate * 1000); // utc initialization requires milliseconds
    this.endDateString = endDate.format('YYYY-MM-DD');
    this.endTimeString = endDate.format('HH:mm:ss');
  }

  /**
   * uses RFC3339 format when switching data back to timestamps
   * YYYY-MM-DDTHH:mm:ss
   */
  onSerialize(): LocalizedDateRange {
    // Convert startDateString & startTimeString to startDate
    const dto = Object.create(LocalizedDateRange.prototype);
    const startDate = moment.utc(`${this.startDateString}T${this.startTimeString}`);
    dto.startDate = startDate.unix(); // backend saves timestamps in seconds
    // Convert endDateString & endTimeString to endDate
    const endDate = moment.utc(`${this.endDateString}T${this.endTimeString}`);
    dto.endDate = endDate.unix(); // backend saves timestamps in seconds
    return dto;
  }

  /**
   * This does not take timezones into account. It compares unix timestamps directly.
   */
  public isWithinUnixDateTimeWindow(unixDate: number): boolean {
    return unixDate >= (this.startDate ?? 0) && unixDate <= (this.endDate ?? 0);
  }

  getUniqueIdentifier(): string {
    return `${this.startDate}-${this.endDate}`;
  }

}
