import { Injectable } from '@angular/core';
import { ColumnOptionsFormViewModel } from '../column-options-form/column-options-form-view-model';
import { ColumnOptionName, SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Injectable()
export class ColumnOptionsStockViewModel extends ColumnOptionsFormViewModel {

  public override columnSectionKey = SectionColumnConfigKey.Stock;
  public override columnSectionName = ColumnOptionName.Stock;
  public override tooltip = 'Show in stock quantity in report';

}
