import { Injectable, Injector } from '@angular/core';
import { MenuDomainModel } from '../domainModels/menu-domain-model';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { Menu } from '../models/menu/dto/menu';
import { Section } from '../models/menu/dto/section';
import { Observable, of } from 'rxjs';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';
import { switchMap, take } from 'rxjs/operators';
import { SmartFiltersDomainModel } from '../domainModels/smart-filters-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmation } from '../modals/modal-confirmation';

// Provided by Logged In Scope
@Injectable()
export class SyncSmartFilterService {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private locationDomainModel: LocationDomainModel,
    private ngbModal: NgbModal,
    private injector: Injector,
    private smartFiltersDomainModel: SmartFiltersDomainModel
  ) {
  }

  public syncSectionSmartFilters(menu: Menu, section: Section): Observable<HydratedSection> {
    return this.shouldShowWarningModal().pipe(
      take(1),
      switchMap(shouldShow => {
        if (shouldShow) {
          return this.menuDomainModel.syncSectionSmartFilters(menu, section);
        } else {
          return of(null);
        }
      })
    );
  }

  public syncLocationSmartFilters(): Observable<LocationConfiguration> {
    return this.shouldShowWarningModal().pipe(
      take(1),
      switchMap(shouldShow => {
        if (shouldShow) {
          return this.smartFiltersDomainModel.syncLocationSmartFilters();
        } else {
          return of(null);
        }
      })
    );
  }

  private shouldShowWarningModal(): Observable<boolean> {
    return this.locationDomainModel.locationConfig$.pipe(
      switchMap(config => {
          return config?.inventoryReceivingWindow?.withinTimeWindow()
            ? this.attemptSyncWhileInInventoryWindow()
            : of(true);
      })
    );
  }

  private attemptSyncWhileInInventoryWindow(): Observable<boolean> {
    const opts = new ConfirmationOptions();
    opts.title = 'You are in your Inventory Receiving Window!';
    opts.bodyText = `You are currently within your location's defined Inventory Receiving Window, and`
      + ` indicated that you do not want Smart Filters to run. Syncing now may result in some of your new products`
      + ` missing. Would you like to proceed?`;
    opts.cancelText = 'Cancel';
    opts.showContinue = true;
    opts.continueText = 'Sync Smart Filters';
    opts.continueButtonClass = 'preferred-button';
    return ModalConfirmation.open(this.ngbModal, this.injector, opts, null, true) as Observable<boolean>;
  }

}
