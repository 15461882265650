import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { EditVariantTerpenesViewModel } from '../edit-variant-terpenes-view-model';
import { exists } from '../../../../../../../../functions/exists';
import { ProviderUtils } from '../../../../../../../../utils/provider-utils';
import { TerpeneUtils } from '../../../../../../../../utils/terpene-utils';

@Injectable()
export class TerpenesFormViewModel extends BaseViewModel {

  constructor(
    private editVariantTerpenesViewModel: EditVariantTerpenesViewModel
  ) {
    super();
  }

  public variant$ = this.editVariantTerpenesViewModel.container.variant$;
  public companyDA$ = this.editVariantTerpenesViewModel.container.variantCompanyDisplayAttribute$;
  public updatedCompanyDA$ = this.editVariantTerpenesViewModel.updatedCompanyDA$;
  public locationDA$ = this.editVariantTerpenesViewModel.container.variantLocationDisplayAttribute$;
  public updatedLocationDA$ = this.editVariantTerpenesViewModel.updatedLocationDA$;
  public useRange$ = this.editVariantTerpenesViewModel.container.useRange$;
  public disableTerpeneInputs$ = this.editVariantTerpenesViewModel.disableTerpeneInputs$;
  public isCompanyAdmin$ = this.editVariantTerpenesViewModel.container.isCompanyAdmin$;
  public syncPOSCannabinoid$ = this.editVariantTerpenesViewModel.container.syncCannabinoidsFromPOS$;
  public inventoryProvider$ = this.editVariantTerpenesViewModel.container.inventoryProvider$;
  public companyName$ = this.editVariantTerpenesViewModel.container.companyName$;
  public locationName$ = this.editVariantTerpenesViewModel.container.currentLocationName$;

  private _terpene = new BehaviorSubject<string>(null);
  public terpene$ = this._terpene as Observable<string>;
  connectToTerpene = (terpene: string) => this._terpene.next(terpene);

  private _terpenePascalCased = new BehaviorSubject<string>(null);
  public terpenePascalCased$ = this._terpenePascalCased as Observable<string>;
  connectToTerpenePascalCased = (terpene: string) => this._terpenePascalCased.next(terpene);

  private _terpeneCamelCased = new BehaviorSubject<string>(null);
  public terpeneCamelCased$ = this._terpeneCamelCased as Observable<string>;
  connectToTerpeneCamelCased = (terpene: string) => this._terpeneCamelCased.next(terpene);

  private _pluralizeLabel = new BehaviorSubject<boolean>(false);
  public pluralizeLabel$ = this._pluralizeLabel as Observable<boolean>;
  connectToPluralizeLabel = (pluralizeLabel: boolean) => this._pluralizeLabel.next(pluralizeLabel);

  public terpeneAccessor$ = this.terpeneCamelCased$;

  public terpeneMinAccessor$ = this.terpenePascalCased$.pipe(
    map(terpene => {
      return 'min' + terpene;
    }),
    distinctUntilChanged()
  );

  public terpeneMaxAccessor$ = this.terpenePascalCased$.pipe(
    map(terpene => {
      return 'max' + terpene;
    }),
    distinctUntilChanged()
  );

  public readonly defaultMaxTerpene$ = combineLatest([
    this.variant$,
    this.terpeneMaxAccessor$
  ]).pipe(
    map(([variant, terpeneMaxAccessor]) => {
      return variant?.[terpeneMaxAccessor];
    })
  );

  public companyTerpeneInputName$ = this.terpenePascalCased$.pipe(
    map(terpene => {
      return 'company' + terpene;
    })
  );

  public terpeneLabel$ = combineLatest([
    this.terpene$,
    this.pluralizeLabel$
  ]).pipe(
    map(([terpene, pluralizeLabel]) => {
      return pluralizeLabel
        ? terpene?.pluralizer()?.addRule({ listConnection: null, useApostrophe: false, word: terpene })?.pluralize()
        : terpene;
    })
  );

  private labelTextPipe$ = combineLatest([
    this.terpeneLabel$,
    this.isCompanyAdmin$,
    this.companyName$
  ]).pipe(shareReplay({bufferSize: 1, refCount: true}));

  public companyTerpeneLabel$: Observable<string> = this.labelTextPipe$.pipe(
    map(([terpene, isAdmin, name]) => {
      return isAdmin ? `${terpene} - ${name} (Company)` : `${terpene} - ${name} (Company)     Admin Only`;
    })
  );

  public companyTerpenePlaceholder$ = combineLatest([
    this.variant$,
    this.terpene$,
    this.terpeneAccessor$,
  ]).pipe(
    map(([variant, terpene, terpeneAccessor]) => {
      const defaultTerpene = variant?.[terpeneAccessor];
      if (exists(defaultTerpene)) {
        return `${defaultTerpene} (Variant Default)`;
      }
      return `Enter the default ${terpene} content`;
    })
  );

  public companyTerpeneTooltip$ = this.terpene$.pipe(
    map(terpene => {
      return `This is the override ${terpene} value that will appear on all menus across `
        + `your company. This value will not be modified on the product or in your POS.`;
    })
  );

  public companyMinTerpeneInputName$ = this.terpenePascalCased$.pipe(
    map(terpene => 'companyMin' + terpene)
  );

  public companyMinTerpenePlaceholder$ = combineLatest([
    this.variant$,
    this.terpene$,
    this.terpeneMinAccessor$
  ]).pipe(
    map(([variant, terpene, terpeneMinAccessor]) => {
      const defaultTerpene = variant?.[terpeneMinAccessor];
      if (exists(defaultTerpene)) {
        return `${defaultTerpene} (Variant Default)`;
      }
      return `Enter the default minimum ${terpene} content`;
    })
  );

  public companyMinTerpeneLabel$: Observable<string> = this.labelTextPipe$.pipe(
    map(([terpene, isAdmin, name]) => {
      return isAdmin
        ? `Minimum ${terpene} - ${name} (Company)`
        : `Minimum ${terpene} - ${name} (Company)     Admin Only`;
    })
  );

  public companyMinTerpeneTooltip$ = this.terpene$.pipe(
    map(terpene => {
      return `This is the override minimum ${terpene} value that will appear on all menus `
        + `across your company. This value will not be modified on the product or in your POS.`;
    })
  );

  public companyMaxTerpeneInputName$ = this.terpenePascalCased$.pipe(
    map(terpene => 'companyMax' + terpene)
  );

  public companyMaxTerpenePlaceholder$ = combineLatest([
    this.variant$,
    this.terpene$,
    this.terpeneMaxAccessor$
  ]).pipe(
    map(([variant, terpene, terpeneMaxAccessor]) => {
      const defaultTerpene = variant?.[terpeneMaxAccessor];
      if (exists(defaultTerpene)) {
        return `${defaultTerpene} (Variant Default)`;
      }
      return `Enter the default maximum ${terpene} content`;
    })
  );

  public companyMaxTerpeneLabel$: Observable<string> = this.labelTextPipe$.pipe(
    map(([terpene, isAdmin, name]) => {
      return isAdmin
        ? `Maximum ${terpene} - ${name} (Company)`
        : `Maximum ${terpene} - ${name} (Company)     Admin Only`;
    })
  );

  public companyMaxTerpeneTooltip$ = this.terpene$.pipe(
    map(terpene => {
      return `This is the override maximum ${terpene} value that will appear on all menus `
        + `across your company. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationTerpeneInputName$ = this.terpenePascalCased$.pipe(
    map(terpene => 'location' + terpene)
  );

  public locationTerpeneLabel$ = combineLatest([
    this.terpeneLabel$,
    this.locationName$
  ]).pipe(
    map(([terpene, locationName]) => `${terpene} - ${locationName} (Location)`)
  );

  public locationTerpenePlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.terpene$,
    this.terpeneAccessor$,
    this.syncPOSCannabinoid$,
    this.inventoryProvider$
  ]).pipe(
    map(([companyDA, terpene, terpeneAccessor, syncPOSCannabinoid, inventoryProvider]) => {
      return ProviderUtils.getDisplayAttributeCannabinoidPlaceholder(
        inventoryProvider,
        syncPOSCannabinoid,
        companyDA,
        companyDA?.[terpeneAccessor],
        `${terpene}`
      );
    })
  );

  public locationTerpeneTooltip$ = this.terpene$.pipe(
    map(terpene => {
      return `This is the override ${terpene} value that will appear on all menus for `
        + `this location. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationMinTerpeneInputName$ = this.terpenePascalCased$.pipe(
    map(terpene => 'locationMin' + terpene)
  );

  public locationMinTerpeneLabel$ = combineLatest([
    this.terpeneLabel$,
    this.locationName$
  ]).pipe(
    map(([terpene, locationName]) => `Minimum ${terpene} - ${locationName} (Location)`)
  );

  public locationMinTerpenePlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.terpene$,
    this.terpeneMinAccessor$,
    this.syncPOSCannabinoid$,
    this.inventoryProvider$
  ]).pipe(
    map(([companyDA, terpene, terpeneMinAccessor, syncPOSCannabinoid, inventoryProvider]) => {
      return ProviderUtils.getDisplayAttributeCannabinoidPlaceholder(
        inventoryProvider,
        syncPOSCannabinoid,
        companyDA,
        companyDA?.[terpeneMinAccessor],
        `${terpene}`
      );
    })
  );

  public locationMinTerpeneTooltip$ = this.terpene$.pipe(
    map(terpene => {
      return `This is the override minimum ${terpene} value that will appear on all menus for `
        + `this location. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationMaxTerpeneInputName$ = this.terpenePascalCased$.pipe(
    map(terpene => 'locationMax' + terpene)
  );

  public locationMaxTerpeneLabel$ = combineLatest([
    this.terpeneLabel$,
    this.locationName$
  ]).pipe(
    map(([terpene, locationName]) => `Maximum ${terpene} - ${locationName} (Location)`)
  );

  public locationMaxTerpenePlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.terpene$,
    this.terpeneMaxAccessor$,
    this.syncPOSCannabinoid$,
    this.inventoryProvider$
  ]).pipe(
    map(([companyDA, terpene, terpeneMaxAccessor, syncPOSCannabinoid, inventoryProvider]) => {
      return ProviderUtils.getDisplayAttributeCannabinoidPlaceholder(
        inventoryProvider,
        syncPOSCannabinoid,
        companyDA,
        companyDA?.[terpeneMaxAccessor],
        `${terpene}`
      );
    })
  );

  public locationMaxTerpeneTooltip$ = this.terpene$.pipe(
    map(terpene => {
      return `This is the override maximum ${terpene} value that will appear on all menus for `
        + `this location. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationMaxTerpeneLimit$ = combineLatest([
    this.terpeneMaxAccessor$,
    this.variant$,
    this.updatedCompanyDA$,
    this.updatedLocationDA$
  ]).pipe(
    map(([terpeneMaxAccessor, variant, companyDA, locationDA]) => {
      const defaultTerpene = variant?.[terpeneMaxAccessor];
      const companyDATerpene = companyDA?.[terpeneMaxAccessor];
      const locationDATerpene = locationDA?.[terpeneMaxAccessor];
      return locationDATerpene ?? companyDATerpene ?? defaultTerpene ?? '';
    })
  );

  public companyMaxTerpeneLimit$ = combineLatest([
    this.terpeneMaxAccessor$,
    this.updatedCompanyDA$,
    this.defaultMaxTerpene$
  ]).pipe(
    map(([cannabinoid, companyDA, defaultMaxCannabinoid]) => {
      const companyMaxCannabinoid = companyDA?.['max' + cannabinoid];
      return exists(companyMaxCannabinoid) ? companyMaxCannabinoid : defaultMaxCannabinoid;
    })
  );

  public hasCompanyMinTerpene$ = combineLatest([
    this.terpeneMinAccessor$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([terpeneMinAccessor, companyDA]) => exists(companyDA?.[terpeneMinAccessor]))
  );

  public hasCompanyMaxTerpene$ = combineLatest([
    this.terpeneMaxAccessor$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([terpeneMaxAccessor, companyDA]) => exists(companyDA?.[terpeneMaxAccessor]))
  );

  public hasLocationMinTerpene$ = combineLatest([
    this.terpeneMinAccessor$,
    this.updatedLocationDA$
  ]).pipe(
    map(([terpeneMinAccessor, locationDA]) => exists(locationDA?.[terpeneMinAccessor]))
  );

  public hasLocationMaxTerpene$ = combineLatest([
    this.terpeneMaxAccessor$,
    this.updatedLocationDA$
  ]).pipe(
    map(([terpeneMaxAccessor, locationDA]) => exists(locationDA?.[terpeneMaxAccessor]))
  );

  public locationMaxTerpeneLowerBoundValue$ = this.getLowerBoundForMaxValue(this.locationMaxTerpeneLimit$);
  public companyMaxTerpeneLowerBoundValue$ = this.getLowerBoundForMaxValue(this.companyMaxTerpeneLimit$);

  private getLowerBoundForMaxValue(cannabinoidMaxValue$: Observable<string>): Observable<number> {
    return cannabinoidMaxValue$.pipe(
      map(maxValue => {
        if (!maxValue) {
          return TerpeneUtils.MAX_TERPENE_NUMBER;
        } else {
          const maxValueAsNumber = Number.parseFloat(maxValue);
          return Number.isFinite(maxValueAsNumber) ? maxValueAsNumber : TerpeneUtils.MAX_TERPENE_NUMBER;
        }
      })
    );
  }

  companyTerpeneUpdated(value: string) {
    this.terpeneAccessor$.once(terpene => {
      this.editVariantTerpenesViewModel.companyDAUpdated(value, terpene);
    });
  }

  companyMinTerpeneUpdated(value: string) {
    this.terpeneMinAccessor$.once(minTerpeneAccessor => {
      this.editVariantTerpenesViewModel.companyDAUpdated(value, minTerpeneAccessor);
    });
  }

  companyMaxTerpeneUpdated(value: string) {
    this.terpeneMaxAccessor$.once(maxTerpeneAccessor => {
      this.editVariantTerpenesViewModel.companyDAUpdated(value, maxTerpeneAccessor);
    });
  }

  locationTerpeneUpdated(value: string) {
    this.terpeneAccessor$.once(terpene => {
      this.editVariantTerpenesViewModel.locationDAUpdated(value, terpene);
    });
  }

  locationMinTerpeneUpdated(value: string) {
    this.terpeneMinAccessor$.once(minTerpeneAccessor => {
      this.editVariantTerpenesViewModel.locationDAUpdated(value, minTerpeneAccessor);
    });
  }

  locationMaxTerpeneUpdated(value: string) {
    this.terpeneMaxAccessor$.once(maxTerpeneAccessor => {
      this.editVariantTerpenesViewModel.locationDAUpdated(value, maxTerpeneAccessor);
    });
  }

}
