import { Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { BehaviorSubject } from 'rxjs';
import { PrintCardsViewModel } from './print-cards-view-model';

@Component({
  selector: 'app-print-cards',
  templateUrl: './print-cards.component.html',
  styleUrls: ['./print-cards.component.scss'],
  providers: [PrintCardsViewModel]
})
export class PrintCardsComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintCardsViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
