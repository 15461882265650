import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveTableComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-smart-filter-variants-preview-table',
  templateUrl: './smart-filter-variants-preview-table.component.html',
  styleUrls: ['./smart-filter-variants-preview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterVariantsPreviewTableComponent extends ReactiveTableComponent {}
