import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartDataType } from '../models/enum/shared/smart-data-type.enum';
import { SmartFilterSelectionModalComponent } from '../views/smart-filters/modal/smart-filter-selection-modal/smart-filter-selection-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalSelectSmartFilter {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    previouslySelectedIds: string[],
    onClose: (smartFilterIds: string[]|null) => void,
    onDismiss: (reason: string) => void,
    forSmartData: SmartDataType = null,
    forTemplateSection = false
  ) {
    const modalRef = ngbModal.open(
      SmartFilterSelectionModalComponent,
      ModalUtils.selectSmartFiltersModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as SmartFilterSelectionModalComponent;
    compInstance.alreadyAddedSmartFilterIds(previouslySelectedIds);
    if (forSmartData) compInstance.forSmartData(forSmartData);
    compInstance.forTemplateSection(forTemplateSection);
    modalRef.result.then(onClose).catch(onDismiss);
  }

}
