<div
  *ngIf="reactiveFormGroup$ | async as formGroup"
  class="no-select"
  [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <label class="reactive-form-checkbox-container no-select" [class.disabled]="disabled" [htmlFor]="id">
    <span class="reactive-form-checkbox-label no-select">
      <ng-container *ngIf="!label"><ng-content></ng-content></ng-container>
      <ng-container *ngIf="label">{{ label }}</ng-container>
    </span>
    <input
      #input
      [hidden]="true"
      class="reactive-form-checkbox no-select"
      type="checkbox"
      [id]="id"
      (keydown)="handleKeyPress($event)"
      (ngModelChange)="handleInputChange($event)"
      (focus)="inputFocus.emit($event)"
      (blur)="inputBlur.emit($event)"
      [formControlName]="getControlInputName()"
      [readOnly]="!editable"
      [attr.disabled]="disabled ? true : null"
      (click)="itemClicked()" />
    <span *ngIf="!!tooltip" class="reactive-form-tooltip">
      <img
        [ngbTooltip]="tooltip"
        class="tooltip-icon"
        [triggers]="'hover'"
        [placement]="['top', 'bottom', 'auto']"
        [container]="'body'"
        alt="" />
    </span>
    <span
      class="reactive-form-checkmark"
      [ngbTooltip]="disabledTooltip$ | async"
      [openDelay]="disabledTooltipDelayMs"
      [disableTooltip]="disableTooltip$ | async"
      [container]="'body'">
    </span>
  </label>
</div>
