import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getStackVariantErrorTooltip'
})
export class GetStackVariantErrorTooltipPipe implements PipeTransform {

  constructor(
    private productDomainModel: ProductDomainModel,
  ) {
  }

  transform(menu: Menu, nSelected: number): Observable<string> {
    return this.productDomainModel.currentLocationVariants$.pipe(
      map(currentLocationVariants => {
        const ids = menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.enabledVariantIds;
        const variants = ids?.map(id => currentLocationVariants?.find(v => v.id === id))?.filterNulls();
        const n = variants?.length ?? 0;
        switch (true) {
          case n === 0:
            return 'Stacks without variants will be ignored';
          case nSelected === 0:
            return 'Stacks without selected variants will be ignored';
          default:
            return null;
        }
      })
    );
  }

}
