import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BulkCardStackPrintJobAppliedProductsViewModel } from './bulk-card-stack-print-job-applied-products-view-model';

@Component({
  selector: 'app-bulk-card-stack-print-job-applied-products',
  templateUrl: './bulk-card-stack-print-job-applied-products.component.html',
  providers: [BulkCardStackPrintJobAppliedProductsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkCardStackPrintJobAppliedProductsComponent {

  constructor(public viewModel: BulkCardStackPrintJobAppliedProductsViewModel) {
  }

  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() title = 'Applied Products';

}
