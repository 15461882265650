<!-- hidden label to calculate if there is a label or not -->
<app-label-inflator #labelCalculator hidden [computeLabelInterface]="card"></app-label-inflator>

<div class="embedded-image-card-container" #productCard>
  <!-- card content -->
  <div
    class="marketing-card-container embed-image-card"
    fxLayout="column"
    fxLayoutAlign="space-between"
    fxLayoutGap="0.5rem"
    [style.backgroundColor]="card?.getCardColor() || '#F1F2F3'">
    <div class="top-row" fxLayout="row" fxFlex="16">
      <app-card-number
        #num
        [style.width]="num.width$ | push"
        [card]="card"
        [changeBackgroundColor]="false"
        [index]="index">
      </app-card-number>
      <app-asset
        *rxFor="let badge of card.getBadges()"
        class="embedded-card-badge-asset"
        [style.width]="num.width$ | push"
        [asset]="badge.image"></app-asset>
      <app-card-price
        class="card-price"
        [ngClass]="{ 'black-shadow': !isDarkTextColor(), 'white-shadow': isDarkTextColor() }"
        fxFlex
        [card]="card"></app-card-price>
    </div>
    <div
      class="name-desc"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="start stretch"
      [ngStyle]="{
        color: card.getCardTextColor()
      }">
      <div
        class="marketing-card-product-name"
        appFitText
        [percentageOfParent]="getProductNameTextPercentage()"
        [parentContainer]="productCard">
        {{ card?.getProductName() }}
      </div>
      <div fxFlex>
        <div
          *rxIf="card?.getProductDesc()"
          class="marketing-card-product-desc"
          [ngStyle]="{
            '-webkit-line-clamp': cardCount < 9 ? '4' : '3'
          }"
          appFitText
          [percentageOfParent]="getProductDescTextPercentage()"
          [parentContainer]="productCard">
          {{ card?.getProductDesc() }}
        </div>
      </div>
    </div>
    <div class="product-info" fxFlex="40" fxLayout="row" fxLayoutGap="0.5rem">
      <div fxFlex="50" class="position-relative">
        <app-asset
          *rxIf="viewModel.hasAsset$ | push; else fallbackIcon"
          class="embedded-card-image-asset"
          [asset]="card.getAsset()"></app-asset>
        <ng-template #fallbackIcon>
          <img class="embedded-card-image-asset" [src]="viewModel.assetFallBackIconUrl$ | push" alt="" />
        </ng-template>
        <div class="label-container" fxLayout="row" fxLayoutAlign="center end">
          <app-label-inflator *rxIf="!!(labelCalculator.computedLabelText$ | push)" [computeLabelInterface]="card">
          </app-label-inflator>
        </div>
      </div>
      <div
        fxFlex="50"
        fxLayout="column"
        fxLayoutAlign="space-evenly stretch"
        #infoContainer
        [ngStyle]="{
          color: card.getCardTextColor()
        }">
        <div [fxFlex]="20">
          <div
            class="marketing-card-product-brand"
            appFitText
            [percentageOfParent]="getProductInfoTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getProductBrand() }}
          </div>
        </div>
        <div
          [fxFlex]="20"
          *rxIf="
            card?.getProductType() !== ClientTypes.ProductType.Edible &&
            card?.getProductType() !== ClientTypes.ProductType.Beverage
          ">
          <div
            *rxIf="card.getIsThcAndCbdProduct()"
            class="marketing-card-product-size"
            appFitText
            [percentageOfParent]="getProductInfoTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getSizeString() }}
          </div>
        </div>
        <div [fxFlex]="20">
          <div
            *rxIf="card.getIsThcAndCbdProduct()"
            class="marketing-card-product-thc"
            appFitText
            [percentageOfParent]="getProductCannabinoidTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getThcString() }}
          </div>
        </div>
        <div [fxFlex]="20">
          <div
            *rxIf="card.getIsThcAndCbdProduct()"
            class="marketing-card-product-cbd"
            appFitText
            [percentageOfParent]="getProductCannabinoidTextPercentage()"
            [parentContainer]="productCard">
            {{ card?.getCbdString() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
