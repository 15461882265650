import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditTemplateMenuComponent } from '../edit-template-menu.component';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { EditTemplateSpotlightMenuViewModel } from './edit-template-spotlight-menu-view-model';
import { EditSpotlightMenuViewModel } from '../../../../menu/components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-view-model';
import { EditTemplateSpotlightMenuCombinedLoadingOptsViewModel } from './edit-template-spotlight-menu-combined-loading-opts-view-model';
import { EditSpotlightSectionViewModel } from '../../../../menu/components/edit-menu/edit-spotlight-menu/edit-spotlight-section-view-model';

@Component({
  selector: 'app-edit-template-spotlight-menu',
  templateUrl: './edit-template-spotlight-menu.component.html',
  styleUrls: ['./edit-template-spotlight-menu.component.scss'],
  providers: [
    EditTemplateSpotlightMenuViewModel,
    EditSpotlightMenuViewModel,
    EditSpotlightSectionViewModel,
    EditTemplateSpotlightMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateSpotlightMenuComponent extends EditTemplateMenuComponent implements ComponentCanDeactivate {

  constructor(
    public override viewModel: EditTemplateSpotlightMenuViewModel,
    public combinedLoadingOptsViewModel: EditTemplateSpotlightMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel);
  }

  @Input() mergeAndDispersedKey: string = 'editTemplateSpotlightMenu';

}

