import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { ApiErrorLog } from '../models/shared/api-error-log';

@Injectable({ providedIn: 'root' })
export class LoggingService {

  public firebaseApp: firebase.app.App;
  public analytics: firebase.analytics.Analytics;

  SetFirebaseAppInstance(i: firebase.app.App): void {
    this.firebaseApp = i;
    this.analytics = firebase.analytics(this.firebaseApp);
  }

  LogAPIError(errLog: ApiErrorLog): void {
    this.analytics.logEvent('API_Error', errLog.logParams());
  }

}
