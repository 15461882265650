<div class="no-results-container">
  <div class="no-results-title">{{ title$ | async }}</div>
  <div class="no-results-body" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1.5rem">
    <div>{{ desc$ | async }}</div>
    <div fxLayout="row" fxLayoutGap="1rem">
      <button
        appThrottleClick
        (throttleClick)="viewModel.clearExternalFilters()"
        class="bs-button preferred-button no-margin">
        Reset Search
      </button>
      <button
        *ngIf="clearOutOfStockOption$ | async"
        appThrottleClick
        (throttleClick)="viewModel.setHideOutOfStockProducts(false)"
        class="bs-button neutral-button no-margin">
        Show Out of Stock Products
      </button>
    </div>
  </div>
</div>
