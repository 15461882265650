<hr *ngIf="viewModel.showWebLink$ | async" />
<div *ngIf="viewModel.showWebLink$ | async" class="url-container" style="margin: 0.5rem 0">
  <label class="control-label bs-textfield-label"> Menu Url </label>
  <div class="label-group-container">
    <div (click)="viewModel.copyMenuUrl()" fxLayout class="label-item url-value">
      <pre fxFlex>{{ viewModel.menuUrl$ | async }}</pre>
      <img [src]="'assets/icons/dark/outline/duplicate.svg'" alt="" />
    </div>
  </div>
</div>

<div *ngIf="viewModel.showWebLink$ | async" class="url-container" style="margin: 0.5rem 0">
  <label class="control-label bs-textfield-label"> Menu iFrame Embed Code </label>
  <div class="label-group-container">
    <div (click)="viewModel.copyIFrameCode()" fxLayout class="label-item url-value">
      <pre fxFlex>{{ viewModel.iFrameCode$ | async }}</pre>
      <img [src]="'assets/icons/dark/outline/duplicate.svg'" alt="" />
    </div>
  </div>
</div>
