import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { map } from 'rxjs/operators';

@Injectable()
export class DefaultColumnOptionsPickerViewModel extends BaseViewModel {

  public defaultColumnOptions$ = this.companyDomainModel.companyConfiguration$.notNull().pipe(
    map(companyConfig => companyConfig.defaultColumnConfigs)
  );

  constructor(
    public companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

}
