import { Deserializable } from '../../protocols/deserializable';

export class SendCompanyReferral implements Deserializable {

  public converted: boolean;
  public dateConverted: number;
  public dateCreated: number;
  public recipientName: string;
  public recipientEmail: string;
  public recipientStoreName: string;
  public referralId: string;
  public referringCompanyId: number;
  public referringUserId: string;

  onDeserialize() {
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-account-service/blob/dev/models/CompanyReferralReq.go
  onSerialize() {
    const dto = Object.create(SendCompanyReferral.prototype);
    dto.recipientName = this.recipientName;
    dto.recipientEmail = this.recipientEmail;
    dto.recipientStoreName = this.recipientStoreName;
    return dto;
  }

}
