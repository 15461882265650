import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductTitle'
})
export class GetProductTitlePipe implements PipeTransform {

  transform(product: Product): string {
    return product?.getProductTitle() ?? '';
  }

}
