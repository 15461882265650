import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { HydratedVariantBadge } from '../../../../../models/product/dto/hydrated-variant-badge';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Injectable()
export class BadgeWithinPickerViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _badge = new BehaviorSubject<HydratedVariantBadge>(null);
  public readonly badge$ = this._badge as Observable<HydratedVariantBadge>;
  public readonly badgeId$ = this.badge$.pipe(map(badge => badge?.id));
  public readonly badgeName$ = this.badge$.pipe(map(badge => badge?.name));

  private _addedBadgeIds = new BehaviorSubject<string[]>([]);
  public readonly addedBadgeIds$ = this._addedBadgeIds as Observable<string[]>;

  private _preselectedBadgeIds = new BehaviorSubject<string[]>([]);
  public readonly preselectedBadgeIds$ = this._preselectedBadgeIds as Observable<string[]>;

  private _asListItems = new BehaviorSubject<boolean>(false);
  public readonly asListItems$ = this._asListItems as Observable<boolean>;

  private _maximumNumberOfBadges = new BehaviorSubject<number>(0);
  public readonly maximumNumberOfBadges$ = this._maximumNumberOfBadges as Observable<number>;

  public alreadyAdded$ = combineLatest([
    this.badgeId$,
    this.maximumNumberOfBadges$,
    this.preselectedBadgeIds$,
  ]).pipe(
    debounceTime(100),
    map(([id, maximumNumberOfBadges, preselectedBadgeIds]) => {
      return preselectedBadgeIds?.includes(id) || maximumNumberOfBadges <= preselectedBadgeIds?.length;
    })
  );

  public readonly nonCuratedSmartBadgeWithRemoveFromExistingEnabled$ = this.badge$.pipe(
    map(badge => badge?.nonCuratedSmartBadgeWithRemoveExistingOnSyncEnabled())
  );

  private disabledData$ = combineLatest([
    this.alreadyAdded$,
    this.nonCuratedSmartBadgeWithRemoveFromExistingEnabled$
  ]);

  public readonly disableBadge$ = this.disabledData$.pipe(
    map(([alreadyAdded, nonCuratedSmartBadgeWithRemoveFromExistingEnabled]) => {
      return alreadyAdded || nonCuratedSmartBadgeWithRemoveFromExistingEnabled;
    })
  );

  public readonly badgeTooltip$ = combineLatest([
    this.badgeName$,
    this.disabledData$
  ]).pipe(
    map(([badgeName, [alreadyAdded, nonCuratedSmartBadgeWithRemoveFromExistingEnabled]]) => {
      switch (true) {
        case nonCuratedSmartBadgeWithRemoveFromExistingEnabled: {
          return `This badge is added and removed using smart filters and can not be manually applied.`;
        }
        case alreadyAdded: {
          return `${badgeName} has already been added`;
        }
      }
      return null;
    })
  );

  public connectToBadge = (badge: HydratedVariantBadge) => {
    this._badge.next(badge);
  };

  connectToAddedBadgeIds = (addedBadgeIds: string[]) => {
    this._addedBadgeIds.next(!!addedBadgeIds ? [...addedBadgeIds] : []);
  };

  connectToPreselectedBadgeIds = (preselectedBadgeIds: string[]) => {
    this._preselectedBadgeIds.next(!!preselectedBadgeIds ? [...preselectedBadgeIds] : []);
  };

  connectToAsListItems = (asListItems: boolean) => {
    this._asListItems.next(asListItems);
  };

  connectToMaximumNumberOfBadges = (maximumNumberOfBadges: number) => {
    this._maximumNumberOfBadges.next(maximumNumberOfBadges);
  };

}
