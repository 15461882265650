import { Injectable } from '@angular/core';
import { ColumnOptionsFormViewModel } from '../column-options-form/column-options-form-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { ColumnOptionName, SectionColumnConfigKey } from '../../../../../../../models/utils/dto/section-column-config-key-type';

@Injectable()
export class ColumnOptionsThcViewModel extends ColumnOptionsFormViewModel {

  constructor(
    public override container: ColumnOptionsViewModel
  ) {
    super(container);
  }

  public override columnSectionKey = SectionColumnConfigKey.THC;
  public override columnSectionName = ColumnOptionName.Thc;
  public override tooltip = `Auto - only if 1 or more variants in section have cannabinoids and with `
                          + `a value of at least 1`;

}
