import { Component } from '@angular/core';
import { DisplayableItemsComponent } from '../../../../../shared/components/displayable-content/displayable-items/displayable-items.component';
import { PrintReportTemplatesViewModel } from './print-report-templates-view-model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-print-report-templates',
  templateUrl: './print-report-templates.component.html',
  styleUrls: ['./print-report-templates.component.scss'],
  providers: [PrintReportTemplatesViewModel],
})
export class PrintReportTemplatesComponent extends DisplayableItemsComponent {

  constructor(
    public override viewModel: PrintReportTemplatesViewModel
  ) {
    super(viewModel);
  }

  override tabActivated: BehaviorSubject<boolean>;

}
