<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (hasErrors$)="setHasError($event)">
  <lib-reactive-form-column-layout [nColumns]="2" [reversed]="false">
    <lib-reactive-form-number
      [inputName]="'productZoom'"
      [label]="'Product Zoom (%)'"
      [placeholder]="'Enter a zoom value (%)'"
      [tooltip]="
        'Product zoom is used to increase or decrease the overall size of product information on the menu. 100 is default.'
      "
      [bindingProperty]="'menuOptions.productZoom'"
      [integersOnly]="true"
      [required]="true"
      [disabled]="isTemplatedMenu"
      [percentageMode]="true"
      [minValue]="75"
      [maxValue]="125"
      [inlinePercentage]="50">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      [inputName]="'titleZoom'"
      [label]="'Title Zoom (%)'"
      [placeholder]="'Enter a zoom value (%)'"
      [tooltip]="
        'Title zoom is used to increase or decrease the overall size of the title on the menu. 100 is default.'
      "
      [bindingProperty]="'menuOptions.titleZoom'"
      [integersOnly]="true"
      [required]="true"
      [disabled]="isTemplatedMenu"
      [percentageMode]="true"
      [minValue]="75"
      [maxValue]="125"
      [inlinePercentage]="50">
    </lib-reactive-form-number>

    <ng-container *ngIf="menuShouldShowMarginOptions$ | async">
      <lib-reactive-form-number
        [inputName]="'sectionMarginTop'"
        [label]="'Section Margin Top (px)'"
        [placeholder]="'Enter a value in pixels'"
        [tooltip]="'Add extra spacing to the top of the area that contains the menu sections.'"
        [bindingProperty]="'menuOptions.sectionMarginTop'"
        [integersOnly]="true"
        [required]="true"
        [disabled]="isTemplatedMenu"
        [minValue]="0"
        [maxValue]="500"
        [inlinePercentage]="50">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'sectionMarginBottom'"
        [label]="'Section Margin Bottom (px)'"
        [placeholder]="'Enter a value in pixels'"
        [tooltip]="'Add extra spacing to the bottom of the area that contains the menu sections.'"
        [bindingProperty]="'menuOptions.sectionMarginBottom'"
        [integersOnly]="true"
        [required]="true"
        [disabled]="isTemplatedMenu"
        [minValue]="0"
        [maxValue]="500"
        [inlinePercentage]="50">
      </lib-reactive-form-number>
    </ng-container>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
