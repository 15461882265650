import { Injectable } from '@angular/core';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { PrintProductMenusComponent } from './print-product-menus/print-product-menus.component';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BulkPrintJobsListComponent } from './bulk-print-jobs-list/bulk-print-jobs-list.component';
import { PrintCardsComponent } from './print-cards/print-cards.component';
import { map } from 'rxjs/operators';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { PrintReportMenusComponent } from './print-report-menus/print-report-menus.component';
import { PrintLabelsComponent } from './print-labels/print-labels.component';

@Injectable()
export class PrintViewModel extends BaseViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
  ) {
    super();
  }

  public tabs$ = this.userDomainModel.canUsePrintCards$.pipe(
    map(canUsePrintCards => {
      const tabs: TabBarItem[] = [
        new TabBarItem(PrintProductMenusComponent, 'Product Menus', '', false),
        new TabBarItem(PrintReportMenusComponent, 'Reports', '', false),
        new TabBarItem(PrintLabelsComponent, 'Print Labels', '', false),
        new TabBarItem(BulkPrintJobsListComponent,  'Bulk Prints', '', false)
      ];
      if (canUsePrintCards) {
        const printCardTab = new TabBarItem(PrintCardsComponent, 'Print Cards', '', false);
        tabs.splice(2, 0, printCardTab);
      }
      return tabs;
    })
  );

}
