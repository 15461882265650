import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SmartFilterAddedToSectionViewModel } from './smart-filter-added-to-section-view-model';
import { HydratedSection } from '../../../../models/menu/dto/hydrated-section';
import { Menu } from '../../../../models/menu/dto/menu';

@Component({
  selector: 'app-smart-filter-added-to-section',
  templateUrl: './smart-filter-added-to-section.component.html',
  styleUrls: ['./smart-filter-added-to-section.component.scss'],
  providers: [SmartFilterAddedToSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterAddedToSectionComponent extends BaseComponent implements OnChanges {

  @Input() autoSaving: boolean;
  @Input() index: number;
  @Input() last: boolean;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() templateMode = false;
  @Input() smartFilterId: string;
  @Input() syncing: boolean = false;
  @Input() formPristine: boolean = true;

  constructor(
    public viewModel: SmartFilterAddedToSectionViewModel
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToSection(this.section);
    this.viewModel.connectToSmartFilterId(this.smartFilterId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.smartFilterId) this.viewModel.connectToSmartFilterId(this.smartFilterId);
  }

}
