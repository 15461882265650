<div class="edit-page-footer">
  <!--   Action buttons   -->
  <div class="edit-page-action-buttons" [style.gap.rem]="1">
    <lib-button-text
      [textColor]="'#FA5555'"
      [disabled]="autoSaveLoadingOpts?.isLoading"
      (buttonClicked)="deleteTemplate()">
      Delete Template
    </lib-button-text>
    <lib-button-text
      [disabled]="autoSaveLoadingOpts?.isLoading || !formPristine"
      [disabledTooltip]="formPristine | duplicateButtonDisabledTooltip"
      (buttonClicked)="openDuplicateTemplateModal()">
      Duplicate Template
    </lib-button-text>
    <lib-button-neutral
      [iconSrc]="'assets/icons/dark/outline/eye.svg'"
      [disabled]="!allowLiveView || formHasErrors || !formPristine"
      [disabledTooltip]="(formErrorMessages | getFormErrorDisabledTooltip) || (formPristine | saveChangesTooltip)"
      (buttonClicked)="showLiveView(formPristine, viewModel.formAutoSubmitted$, submitForms)">
      Live View
    </lib-button-neutral>
    <lib-button-primary
      [infoText]="lastSavedTimeText"
      [disabled]="autoSaveLoadingOpts?.isLoading || formDisableSubmit"
      [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
      [loading]="autoSaveLoadingOpts?.isLoading"
      (buttonClicked)="submitForms.emit(false)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
