import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Size } from '../../../../../models/shared/size';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { LoadingSpinnerSize } from '../../../../../models/enum/shared/loading-spinner-size.enum';
import { RangeSliderOptions } from '../../../../../models/shared/stylesheet/range-slider-options';
import { Menu } from '../../../../../models/menu/dto/menu';
import { LiveViewViewModel } from '../../../viewModels/live-view-view-model';
import { BehaviorSubject } from 'rxjs';
import { MarketingMenuType } from '../../../../../models/enum/dto/marketing-menu-type.enum';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { BaseDisplay } from '../../../../../models/display/dto/base-display';

@Component({
  selector: 'app-live-view-modal',
  templateUrl: './live-view-modal.component.html',
  styleUrls: ['./live-view-modal.component.scss'],
  providers: [LiveViewViewModel]
})
export class LiveViewModalComponent extends BaseModalComponent implements AfterViewInit {

  public loadingOpts: LoadingOptions = LoadingOptions.default();
  public url: SafeUrl | string;
  public iFrameName = Date.now().toString(10);
  public liveViewName: string;
  public size: Size;
  public scaleRangeOptions$ = new BehaviorSubject<RangeSliderOptions>(new RangeSliderOptions());
  public scaleIsUnchanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public enableHorizontalScroll: boolean = false;
  public enableVerticalScroll: boolean = false;

  @ViewChild('modalBody') modalBody: ElementRef;
  @ViewChild('liveViewWrapper') liveViewWrapper: ElementRef;

  private scale: number = 1.0;
  private originalScale: number;
  private locationId: number;

  constructor(
    public viewModel: LiveViewViewModel,
    protected activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.setLiveViewScale();
    this.showLoadingSpinner(3000);
    this.setupRangeOptions();
  }

  showLoadingSpinner(duration: number) {
    const lm = 'Loading Live View';
    if (!this.loadingOpts.containsRequest(lm)) {
      setTimeout(() => { this.loadingOpts.addRequest(lm); });
      setTimeout(() => { this.loadingOpts.removeRequest(lm); }, duration);
    }
  }

  override setupViews() {
    this.setupLoadingOptions();
  }

  setupLoadingOptions() {
    this.loadingOpts.backgroundColor = '#FFFFFF';
    this.loadingOpts.showLoadingText = false;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Large;
  }

  setupRangeOptions() {
    const newScaleRangeOptions = new RangeSliderOptions();
    newScaleRangeOptions.value = Math.round((this.scale * 100)) / 100;
    newScaleRangeOptions.min = 0.0;
    newScaleRangeOptions.max = 1.0;
    newScaleRangeOptions.step = 0.01;
    newScaleRangeOptions.label = 'Scale:';
    this.scaleRangeOptions$.next(newScaleRangeOptions);
  }

  userSetNewScale(newScale: number) {
    this.liveViewWrapper.nativeElement.scrollTop = 0;
    this.liveViewWrapper.nativeElement.scrollLeft = 0;
    this.scale = newScale;
    this.enableHorizontalScroll = (this.scale > this.originalScale);
    this.enableVerticalScroll = (this.scale > this.originalScale);
    this.scaleIsUnchanged();
  }

  scaleIsUnchanged() {
    this.scaleIsUnchanged$.next(this.scale === this.originalScale);
  }

  setupDisplayLiveView(display: BaseDisplay, size: Size, locationId: number) {
    this.viewModel.display = display;
    this.viewModel.menu = null;
    this.locationId = locationId;
    const url = display.generateDisplayUrl(locationId);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.liveViewName = display.name ?? '';
    this.size = size;
  }

  setupMenuLiveView(menu: Menu, size: Size, locationId: number) {
    this.viewModel.display = null;
    this.viewModel.menu = menu;
    const url = menu.generateMenuUrl(locationId);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.liveViewName = menu.name ?? '';
    if (menu.type === MenuType.PrintMenu) {
      this.size = Size.getDigitalSizeFromPaper(size);
      this.viewModel.loadPreview();
    } else {
      const loadPreview = menu?.isDisplayMenu() || menu?.isMarketingMenu();
      if (loadPreview) {
        this.viewModel.loadPreview();
      }
      this.size = size;
    }
    this.locationId = locationId;
  }

  resetScale() {
    this.liveViewWrapper.nativeElement.scrollTop = 0;
    this.liveViewWrapper.nativeElement.scrollLeft = 0;
    this.scale = this.originalScale;
    this.scaleRangeOptions$.getValue().value = Math.round(this.scale * 100) / 100;
    this.enableHorizontalScroll = (this.scale > this.originalScale);
    this.enableVerticalScroll = (this.scale > this.originalScale);
    this.scaleIsUnchanged();
  }

  refreshLiveView() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.showLoadingSpinner(3000);
    setTimeout(() => {
      let url = '';
      if (this.viewModel.display?.id) {
        url = this.viewModel.display?.generateDisplayUrl(this.locationId);
      } else if (this.viewModel.menu?.id) {
        url = this.viewModel.menu?.generateMenuUrl(this.locationId);
      }
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }, 2000);
  }

  showDownloadButton(): boolean {
    if (this.viewModel.display) {
      return false;
    } else {
      const subType = this.viewModel?.menu?.getSubType();
      const isMarketingAndNotPlaylist = this.viewModel?.menu?.isMarketingMenu()
        && subType !== MarketingMenuType.Playlist
        && subType !== MarketingMenuType.SmartPlaylist;
      return this.viewModel?.menu?.isPrintMenu()
        || this.viewModel?.menu?.isDisplayMenu()
        || this.viewModel?.menu?.isSpotlightMenu()
        || isMarketingAndNotPlaylist;
    }
  }

  public getIFrameStyle() {
    return {
      zoom: 1,
      transform: `scale(${this.scale})`,
      '-moz-transform': `scale(${this.scale})`,
      '-moz-transform-origin': '0 0',
      '-o-transform': `scale(${this.scale})`,
      '-o-transform-origin': '0 0',
      '-webkit-transform': `scale(${this.scale})`,
      '-webkit-transform-origin': '0 0',
      width: this.getLiveViewWidth(),
      height: this.getLiveViewHeight(),
    };
  }

  private setLiveViewScale() {
    if (this.size.orientation === Orientation.Landscape) {
      // 10vw margin and 3 rem padding
      const calculatedModalWidth = this.modalBody.nativeElement.offsetWidth * 0.90 - (3 * 16);
      // 10vh margin
      const calculatedModalHeight = this.modalBody.nativeElement.offsetHeight * 0.90;
      // calculate scale to maximize width
      let widthScale = Math.round((calculatedModalWidth / this.size.width) * 100) / 100;
      let expectedHeight = this.size.height * widthScale;
      // Choose the scale that maximizes width without exceeding modal height
      if (expectedHeight > (calculatedModalHeight)) {
        while (expectedHeight > (calculatedModalHeight)) {
          widthScale = widthScale - 0.01;
          expectedHeight = this.size.height * widthScale;
        }
      }
      this.scale = widthScale;
    } else {
      const calculatedModalHeight = this.modalBody.nativeElement.offsetHeight * 0.95;
      this.scale = Math.round((calculatedModalHeight / this.size.height) * 100) / 100;
    }
    if (!this.originalScale) {
      this.originalScale = this.scale;
    }
    this.enableHorizontalScroll = (this.scale > this.originalScale);
    this.enableVerticalScroll = (this.scale > this.originalScale);
    // Reset the scale for the slider
    this.setupRangeOptions();
  }

  private getLiveViewHeight(): string {
    if (this.size.orientation === Orientation.Landscape) {
      // height is less than height
      return `${Math.min(this.size.height, this.size.width)}px`;
    } else {
      // height is greater than height
      return `${Math.max(this.size.height, this.size.width)}px`;
    }
  }

  private getLiveViewWidth(): string {
    if (this.size.orientation === Orientation.Landscape) {
      // width is greater than height
      return `${Math.max(this.size.height, this.size.width)}px`;
    } else {
      // width is less than height
      return `${Math.min(this.size.height, this.size.width)}px`;
    }
  }

}
