import { MenuPreviewViewModel } from '../menu-preview/menu-preview-view-model';
import { inject, Injectable, Injector } from '@angular/core';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { debounceTime, delay, map, pairwise, switchMap, take } from 'rxjs/operators';
import { PrintCardPreviewService } from '../../../../../../services/print-card-preview.service';
import { combineLatest, throwError } from 'rxjs';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { MenuDisplayableSubItemService } from '../displayable-item-preview/menu-displayable-sub-item.service';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '../../../../../../utils/date-utils';
import { REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES } from '../displayable-item-preview/displayable-item-preview-view-model';
import { BsError } from '../../../../../../models/shared/bs-error';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { exists } from '../../../../../../functions/exists';
import { MenuPreview } from '../../../../../../models/menu/shared/menu-preview';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';

@Injectable()
export class CardStackPreviewViewModel extends MenuPreviewViewModel {

  constructor(
    protected bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected printCardPreviewService: PrintCardPreviewService,
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    menuSubItemService: MenuDisplayableSubItemService,
    menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      templateCollectionDomainModel,
      menuSubItemService,
      menuPreviewService,
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngbModal,
      injector
    );
    this.listenForSlideChange();
  }

  protected previewRefreshMap = new Map<string, boolean>();

  /* ************************************* Drop Down Selections ************************************ */

  public override dropDownMenuSections$ = combineLatest([
    this.displayableItem$,
    this.locationId$
  ]).pipe(
    map(([cardStackMenu, locationId]) => this.getMenuDropDownOptions(cardStackMenu, locationId))
  );

  protected override getMenuDropDownOptions(menu: Menu, locId: number): DropDownMenuSection[] {
    const dropDownMenuSections = [];
    const sectionItems = [];
    const deletable = !(menu?.isTemplatedMenu() && menu?.template?.requiredLocationIds.contains(locId));
    sectionItems.push(new DropDownMenuItem('Edit', DisplayableAction.Edit));
    sectionItems.push(new DropDownMenuItem('Duplicate', DisplayableAction.Duplicate));
    if (deletable) {
      const deleteOpts = new Set<string>().add('red-text');
      sectionItems.push(new DropDownMenuItem('Delete Menu', DisplayableAction.Delete, deleteOpts));
    }
    const section = new DropDownMenuSection(null, sectionItems);
    dropDownMenuSections.push(section);
    return dropDownMenuSections;
  }

  /* ************************************* Preview Logic ************************************ */

  protected override listenForPreviewChanges(): void {
    combineLatest([
      this.displayableItem$,
      inject(PrintCardPreviewService).previews$,
      this.locationId$
    ]).pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: ([menu, printCardPreviews, locationId]) => {
        const contentIds = menu?.displayableItemPreviewContentIds(locationId);
        const keys = contentIds?.map(ids => {
          const keyIds = ids instanceof Array ? ids : [ids];
          return PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locationId, keyIds);
        });
        const previews = keys?.map(key => printCardPreviews?.get(key))?.filterNulls();
        this._itemPreviews.next(previews ?? null);
      }
    });
  }

  /**
   * Previews are lazy loaded, so we need to listen for when the slide changes,
   * and then fetch the preview if it hasn't been loaded.
   */
  protected listenForSlideChange(): void {
    this.activeSlideIndex$.pipe(
      pairwise()
    ).subscribeWhileAlive({
      owner: this,
      next: ([prevIndex, currIndex]) => {
        if (prevIndex !== currIndex) {
          this.fetchItemPreviewAtIndexIfDoesntExist(currIndex);
        }
      }
    });
  }

  /**
   * The parent class calls this.fetchItemPreview() within its constructor.
   * Which means this override will not have context to any additional properties injected into the child class
   * when it's being called.
   *
   * IE. fetchItemPreview() won't have access to templateCollectionDeletionService and templateDomainModel.
   * If you try and access those properties from within here, you'll get a null reference error.
   * I can get around this by using Angular's inject statement, so that I can get direct access to the
   * TemplateDomainModel when this method is being called in this weird context.
   *
   * Lazy loading is used for card stack previews, so that is why we only fetch the first contentId.
   */
  protected override fetchItemPreviews(): void {
    combineLatest([
      this.displayableItem$.distinctUniquelyIdentifiable(),
      this.locationId$.notNull(),
      inject(TemplateDomainModel).menuTemplates$.notNull().distinctUniquelyIdentifiableArray(),
    ]).subscribeWhileAlive({
      owner: this,
      next: ([menu, locId, menuTemplates]) => {
        combineLatest([
          this.previewLoadingBundle$,
          this.refreshLoadingBundle$
        ]).once(([previewLoadingBundle, refreshLoadingBundle]) => {
          const contentIds = menu?.displayableItemPreviewContentIds(locId);
          if (contentIds?.length) {
            this.initializePreviewLoadingOptions(previewLoadingBundle, contentIds);
            this.initializeRefreshPreviewLoadingOptions(refreshLoadingBundle, contentIds);
            const contentId = contentIds?.firstOrNull();
            this.loadPrintCardPreview(menu, locId, contentId);
          } else {
            this.loadPlaceholder(menu);
          }
        });
      }
    });
  }

  protected fetchItemPreviewAtIndexIfDoesntExist(index: number): void {
    combineLatest([
      this.displayableItem$,
      this.locationId$,
      this.previewLoadingBundle$,
      this.refreshLoadingBundle$,
      this.itemPreviews$
    ]).once(([menu, locId, previewLoadingBundle, refreshLoadingBundle, previews]) => {
      const contentId: string | string[] = menu?.displayableItemPreviewContentIds(locId)?.[index];
      if (exists(contentId)) {
        this.initializePreviewLoadingOptions(previewLoadingBundle, [contentId]);
        this.initializeRefreshPreviewLoadingOptions(refreshLoadingBundle, [contentId]);
        const preview = previews?.[index];
        if (!preview) this.loadPrintCardPreview(menu, locId, contentId);
      }
    });
  }

  protected loadPrintCardPreview(
    menu: Menu,
    locId: number,
    idBundle: (string | string[])
  ) {
    combineLatest([
      this._itemPreviews,
      this.previewLoadingBundle$
    ]).once(([currPreviews, loadingMap]) => {
      const content = Array.isArray(idBundle) ? idBundle : [idBundle];
      const previewContentIds = menu?.displayableItemPreviewContentIds(locId);
      const loadingKey = Array.isArray(idBundle) ? idBundle?.firstOrNull() : idBundle;
      const key = PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locId, content);
      const returnLastSaved = !this.printCardPreviewService.isPreviewInProgress(key);
      const lm = 'Getting Preview';
      const loadingOpts = loadingMap?.get(loadingKey);
      if (!loadingOpts?.containsRequest(lm) && !this.previewRefreshMap.get(key)) {
        this.previewRefreshMap.set(key, true);
        loadingOpts?.addRequest(lm);
        const cacheDelay = returnLastSaved ? 500 : 12000;
        const getPrintCardPreview = this.printCardPreviewService.getPrintCardPreview;
        getPrintCardPreview(menu, content, returnLastSaved, cacheDelay, false, false, true).subscribe({
          next: (printCardPreview) => {
            if (exists(printCardPreview)) {
              this.setItemPreviews(previewContentIds, currPreviews, printCardPreview);
              // Add delay to account for saving of new preview
              this.previewRefreshMap.set(key, false);
              loadingOpts?.removeRequest(lm);
              const timestamp = printCardPreview?.preview?.timestamp;
              const refresh = !DateUtils.unixAfterMinutesAgo(timestamp, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES);
              if (refresh) this.refreshPrintCardPreviewInBackground(menu, locId, idBundle);
            }
          },
          error: (err: BsError) => {
            this.previewRefreshMap.set(key, false);
            loadingOpts?.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  protected refreshPrintCardPreviewInBackground(
    menu: Menu,
    locId: number,
    idBundle: (string | string[])
  ) {
    combineLatest([
      this._itemPreviews,
      this.refreshLoadingBundle$
    ]).once(([currPreviews, refreshLoadingBundle]) => {
      const content = Array.isArray(idBundle) ? idBundle : [idBundle];
      const previewContentIds = menu?.displayableItemPreviewContentIds(locId);
      const loadingKey = Array.isArray(idBundle) ? idBundle?.firstOrNull() : idBundle;
      const key = PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locId, content);
      const lm = 'Refreshing Preview';
      const loadingOpts = refreshLoadingBundle?.get(loadingKey);
      if (!loadingOpts?.containsRequest(lm)) {
        this.previewRefreshMap.set(key, true);
        loadingOpts?.addRequest(lm);
        const imageDelay = 12000;
        const getPrintCardPreview = this.printCardPreviewService.getPrintCardPreview;
        getPrintCardPreview(menu, content, false, imageDelay, undefined, false, true).subscribe({
          next: (printCardPreview) => {
            this.setItemPreviews(previewContentIds, currPreviews, printCardPreview);
            this.previewRefreshMap.set(key, false);
            loadingOpts?.removeRequest(lm);
          },
          error: (err: BsError) => {
            this.previewRefreshMap.set(key, false);
            loadingOpts?.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

  protected setItemPreviews(
    idsInOrder: (string | string[])[],
    currPreviews: MenuPreview[],
    newPreview: MenuPreview
  ): void {
    const updatedPreviews = currPreviews?.shallowCopy() ?? [];
    const currentIndex = updatedPreviews?.findIndex(p => p?.preview?.id === newPreview?.preview?.id);
    if (currentIndex > -1) updatedPreviews.splice(currentIndex, 1);
    updatedPreviews.push(newPreview);
    updatedPreviews?.sort((a, b) => {
      const aIndex = idsInOrder?.indexOf(a?.preview?.id);
      const bIndex = idsInOrder?.indexOf(b?.preview?.id);
      return aIndex - bIndex;
    });
    this._itemPreviews.next(updatedPreviews);
  }

  protected loadPlaceholder(menu: Menu) {
    this._previewPlaceholderSrc.next('assets/img/card-stack-placeholder/card-empty-state-wide.jpg');
  }

  protected override loadItemPreviews(menu: Menu, locId: number) {
    // Don't use this function, use loadPrintCardPreview instead
  }

  protected override refreshItemPreviewInBackground(m: Menu) {
    // Don't use this function, use refreshPrintCardPreviewInBackground instead
  }

  /* ************************************* CTAs ************************************ */

  public override openDeleteItemModal() {
    this.displayableItem$.once(menu => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Card Stack';
      opts.bodyText = `Are you sure you want to delete the following card stack: '${menu?.name}'? `
        + `This action cannot be undone.`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmation = (cont: boolean) => cont && this.deleteItem(menu);
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  protected override deleteItem(menu: Menu) {
    const lm = 'Deleting Card Stack';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.menuDomainModel.deleteMenu(menu).pipe(
        // Add small delay so updated menus can propagate to menus-view-model
        delay(250),
        switchMap(() => this.locationId$),
        take(1)
      ).subscribe({
        next: (locationId) => {
          this.bulkPrintJobDomainModel.getBulkPrintJobs(locationId.toString()).once();
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Card stack deleted successfully.', 'Card Stack Deleted');
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(() => err);
        }
      });
    }
  }

}
