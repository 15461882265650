import { TypeDefinition } from './type-definition';

export enum ThemeSubtype {
  Product = 'PRODUCT_MENU',
  Spotlight = 'SPOTLIGHT_MENU',
  Playlist = 'LOOPING_CONTENT_MENU',
  FeaturedProduct = 'FEATURED_PRODUCT_MENU',
  FeaturedCategory = 'FEATURED_CATEGORY',
  DriveThru = 'FEATURED_PRODUCT_COMBO_MENU'
}

export class ThemeSubtypeType extends TypeDefinition {

  override value: ThemeSubtype;

}
