import { Deserializable } from '../../protocols/deserializable';
import { DateUtils } from '../../../utils/date-utils';

export class LocationStoreHours implements Deserializable {

  public day: number = null;
  public openTime: string = '';
  public closeTime: string = '';

  // Not From API
  public isOpen = false;
  public openTime24Hour: string = '';
  public closeTime24Hour: string = '';

  public onDeserialize() {
    this.isOpen = this.openTime?.length > 0 && this.closeTime?.length > 0;
    this.openTime24Hour = DateUtils.convert12HourTo24HourTime(this.openTime);
    this.closeTime24Hour = DateUtils.convert12HourTo24HourTime(this.closeTime);
  }

  set12HourTimeFrom24HourTimes() {
    this.openTime = DateUtils.convert24HourTo12HourTime(this.openTime24Hour);
    this.closeTime = DateUtils.convert24HourTo12HourTime(this.closeTime24Hour);
  }

}
