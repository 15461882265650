import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormSmartFilterListComponent } from './reactive-form-smart-filter-list/reactive-form-smart-filter-list.component';
import { SmartFiltersModule } from '../../views/smart-filters/smart-filters.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveFormRangeSliderComponent } from './reactive-form-range-slider/reactive-form-range-slider.component';
import { RxPush } from '@rx-angular/template/push';
import { SharedModule } from '../../views/shared/shared.module';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';

/*
 * ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' })
 * see https://github.com/angular/angular/issues/48350
 */
@NgModule({
  declarations: [
    ReactiveFormSmartFilterListComponent,
    ReactiveFormRangeSliderComponent
  ],
  exports: [
    ReactiveFormSmartFilterListComponent,
    ReactiveFormRangeSliderComponent
  ],
  imports: [
    CommonModule,
    SmartFiltersModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    RxPush,
    SharedModule,
    FlexLayoutModule,
    ReactiveFormModule
  ]
})
export class ReactiveFormExtensionsModule { }
