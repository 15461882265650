<div class="single-selection-container">
  <div (click)="handleSelection(item)" *ngFor="let item of items; let i = index">
    <div
      class="flex-container"
      [ngbTooltip]="item.tooltip"
      [openDelay]="150"
      [container]="'body'"
      [class.disabled]="item.disabled">
      <div class="single-selection-radio">
        <div class="bs-radio-option" [class.disabled]="item.disabled">
          <input
            [checked]="item.selected"
            [disabled]="item.disabled"
            class="bs-radio"
            id="{{ sid }}-{{ item.getUniqueIdentifier() }}"
            name="{{ sid }}-{{ item.getUniqueIdentifier() }}"
            [style.position]="'inherit'"
            type="radio" />
          <span [style.margin-left.rem]="-1" class="bs-custom-radio"></span>
          <label class="bs-radio-label" for="{{ sid }}-{{ item.getUniqueIdentifier() }}" style="display: none"></label>
        </div>
      </div>

      <div class="single-selection-text-container ellipsis-nowrap-text" [class.disabled]="item.disabled">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="body">
          {{ item.body }}
        </div>
      </div>

      <div class="single-selection-icon" *ngIf="!!item.iconSrc">
        <img [src]="item.iconSrc" [ngbTooltip]="item.tooltip" [container]="'body'" alt="icon" />
      </div>
    </div>
    <hr class="no-margin" />
  </div>
</div>
